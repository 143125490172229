export default {
  send: 'Send',
  cancel: 'Cancel',
  copy: 'Copy',
  nothingHere: 'There is nothing here',
  save: 'Save',
  yes: 'Yes',
  no: 'No',
  on: 'On',
  off: 'Off',
  not: 'Nothing',
  edit: 'Edit',
  delete: 'Delete',
  statistics: 'Statistics',
  apply: 'Apply',
  next: 'Next',
  read: 'Read',
  familiarized: 'Familiarized',
  notEmpty: 'This field is required',
  recordsPerPage: 'Records per page',
  gotoPage: 'Go to page',
  close: 'Close',
  refresh: 'Update',
  alerts: {
    success: {
      heading: 'Success'
    },
    warning: {
      heading: 'Warning'
    },
    error: {
      heading: 'Error'
    }
  },
  logout: {
    title: 'Do you want to log out?',
    message: 'Are you sure you want to log out of your account?',
    okButton: 'Logout'
  }
}
