import _ from 'lodash'
import * as components from '../components'
import {TableSortHead, filters} from '@/components'

export default {
  mixins: [TableSortHead],
  components: {
    TableSortHead,
    'filters-countries': components.filters.Countries,
    'filters-tags': components.filters.Tags,
    'filters-pwas': components.filters.PWAs,
    'filters-date': filters.Date,
    'filters-groups': components.filters.Groups,
    'filters-pepper': components.filters.Pepper,
    'filters-welcomep': components.filters.WelcomeP
  },
  data: () => ({
    pwas: [],
    trackers: [],
    pepper: {},
    welcomep: {},
    data: [],
    filters: {
      groups: {
        // eslint-disable-next-line no-undef
        dateBegin: moment().subtract(14, 'd').format('YYYY-MM-DD'),
        // eslint-disable-next-line no-undef
        dateEnd: moment().format('YYYY-MM-DD'),
        country: '',
        pwa: '',
        tracker: '',
        tags: []
      },
      pepper: {
        a: '',
        o: '',
        link_id: '',
        sub_id3: '',
        sub_id4: ''
      },
      welcomep: {
        wp: '',
        wp_sub: ''
      }
    },
    sort: '-date'
  }),
  created: function () {
    this.asyncData = _.debounce(this.asyncData, 500)
    for (const filter in this.filters) {
      for (const prop in this.filters[filter]) {
        this.$watch(`filters.${filter}.${prop}`, async function (n) {
          await this.asyncData()
        })
      }
    }
  },
  watch: {
    sort: async function () {
      await this.asyncData()
    }
  },
  mounted: async function () {
    await this.asyncData()
    this.initTooltip()
  },
  computed: {
    user () { return this.$store.getters['identity'] },
    filter: function () {
      let filter = {}
      for (const index in this.filters) {
        for (const index2 in this.filters[index]) {
          if (this.filters[index][index2] !== '') {
            filter[index2] = this.filters[index][index2]
          }
        }
      }
      return filter
    }
  },
  methods: {
    clear: function () {
      this.filters.groups.country = ''
      this.filters.groups.tags = []
      this.filters.groups.pwa = ''
      this.filters.groups.tracker = ''
      // eslint-disable-next-line no-undef
      this.filters.groups.dateBegin = moment().subtract(14, 'd').format('YYYY-MM-DD')
      // eslint-disable-next-line no-undef
      this.filters.groups.dateEnd = moment().format('YYYY-MM-DD')
    }
  }
}
