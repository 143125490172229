<template>
  <div id="content-pwa" class="tab-pane fade in active" v-if="accesses.statistics.view">
    <filters v-if="options?.filter" :loading="loading" :value="options" @optionsChange="optionsChange" @filterChange="asyncData"/>
    <data-table
      :models="sortedModels"
      :count="count"
      :value="options"
      :columns="columns"
      :loading="loading"
      :errorAsyncData="timeOutError"
      :errorChunk="timeOutErrorChunk"
      @optionsChange="optionsChange"
      @reload="reloadData"
    >
      <div class="d-md-flex mobile-grid-2 mt-2 mt-sm-0">
        <modal v-model="dialog" id="columns-setting-modal">
          <columns-setting-form :value="options" :columns="columns" @optionsChange="optionsChange"/>
        </modal>
        <ReportForm ref="reportForm"/>
        <button
          @click="openHandler"
          class="btn_green_g ml-sm-2"
          :class="{ 'one-column-mobile': count < 1 }"
        >{{ $t('analytics.columns') }}</button>
        <button
          v-if="count > 0"
          @click="$refs.reportForm.handleOpen()"
          class="btn_green_g ml-md-2"
        >{{ $t('analytics.report.button') }}</button>
      </div>
    </data-table>
  </div>
</template>
<script>
import _ from 'lodash'
import tooltipMixin from '@/mixins/tooltip-mixin'
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import statisticsMixin from '@/mixins/statistics-mixin'
import {DataTable, Filters, columns, Modal} from './components'
import ReportForm from '@/components/analytics/ReportForm.vue'
import {mapGetters, mapActions} from 'vuex'
import {daysBetweenTwoDates, splitDateOnChunks} from '@/utils/dates.js'

export default {
  name: 'Groups',
  title () {
    return this.$t('titles.analyticsGroups')
  },
  mixins: [tooltipMixin, titleMixin, accessesMixin, statisticsMixin],
  components: {Filters, DataTable, ColumnsSettingForm: columns.setting.Form, Modal, ReportForm},
  data () {
    return {
      dialog: false,
      loading: true,
      sortedModels: [],
      chunkSize: 7,
      timeOutError: null,
      timeOutErrorChunk: null,
      options: {
        range: {},
        sort: 'id'
      },
      dateChunks: []
    }
  },
  computed: {
    ...mapGetters('analytics', ['count', 'columns', 'models']),
    isSortByDecrease () {
      return this.options.sort.toString().search('-') === -1
    },
    sortValue () {
      return this.isSortByDecrease ? this.options.sort : this.options.sort.substring(1)
    },
    calculateColumns () {
      let arr = []
      for (const [prop, value] of Object.entries(this.columns)) {
        if (value.group === 'calculate') {
          arr.push(prop)
        }
      }
      return arr
    }
  },
  watch: {
    'options.sort': function (newSort) {
      if (newSort) {
        this.sortModels()
      }
    },
    'options.range': function (newVal) {
      this.getFilters()
      this.getGroupsBy(newVal)
      this.asyncData()
    },
    'options.columns': function (newVal, oldVal) {
      if (newVal && oldVal && this.checkIsNewColumns(newVal, oldVal, this.calculateColumns)) {
        this.asyncData()
      }
    },
    models: {
      handler: function (newModels) {
        this.sortedModels = newModels.map(item => {
          if (!item.hasOwnProperty('id') || item.id === null) {
            item.id = ''
          }
          return item
        })
      }
    }
  },
  async created () {
    await this.$store.dispatch('analytics/resetState')
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.getOptions()
    this.asyncData(this.options)
    this.sortedModels = [...this.models]
    this.setOptionsWatch()
  },
  methods: {
    ...mapActions('analytics', [
      'getGroupsBy',
      'getFilters',
      'getAnalyticsByGroup'
    ]),
    optionsChange (payload) {
      this.timeOutError = null
      this.timeOutErrorChunk = null

      for (const [key, value] of Object.entries(payload)) {
        this.options[key] = value
      }
      this.$store.commit('analytics/setOptions', JSON.parse(JSON.stringify({...this.options})))
      if (!payload.hasOwnProperty('sort')) {
        this.$store.dispatch('analytics/saveAnalyticsOptionsGroups')
      }
    },
    openHandler () {
      this.dialog = true
    },
    async asyncData (payload) {
      this.loading = true
      try {
        if (payload) {
          this.$store.commit('analytics/setOptions', JSON.parse(JSON.stringify({...payload})))
        }
        await this.$store.dispatch('analytics/asyncData', {type: 'groups'})
      } catch (error) {
        if (error.response.code === 504) {
          this.timeOutError === 504 ? this.timeOutErrorChunk = 504 : this.timeOutError = 504
        }
        await this.$store.dispatch('alerts/error', error.response.data.errors[0].msg)
      }
      this.loading = false
    },
    async reloadData () {
      if (daysBetweenTwoDates(this.options.range.begin, this.options.range.end) <= this.chunkSize) {
        this.asyncData()
      } else {
        this.loading = true
        this.timeOutError = null
        this.dateChunks = splitDateOnChunks(this.options.range.begin, this.options.range.end, this.chunkSize)
        this.$store.commit('analytics/resetModels')
        try {
          await this.$store.dispatch('analytics/asyncDataChunks', this.dateChunks)
        } catch (error) {
          if (error.response.code === 504) {
            this.timeOutErrorChunk = 504
          }
        }
        this.loading = false
      }
    },
    async getOptions () {
      await this.getAnalyticsByGroup()
      this.options = this.$store.getters['analytics/options']
    },
    setOptionsWatch () {
      if (this.options.sort != null && this.options.sort.length > 1) {
        ['group', 'limit', 'page'].forEach(item => {
          this.$watch(`options.${item}`, function (n, o) {
            if (+n.page > 1 && +n.page === +o.page) {
              n.page = this.options.page = 1
            }
            this.asyncData(n)
          })
        })
      }
    },
    sortModels () {
      if (this.sortedModels.length === 0) return

      const searchField = this.sortedModels.find(item => `${this.sortValue}` in item && item[this.sortValue] !== null)[this.sortValue]
      if (this.options.group === 'date' && this.sortValue === 'id') {
        this.sortByDate() // from statisticsMixin
      } else if (this.options.group === 'pwa' && this.sortValue === 'id' && typeof searchField === 'object') {
        this.sortByPWA() // from statisticsMixin
      } else if (typeof searchField === 'number') {
        this.sortByNumber() // from statisticsMixin
      } else if (typeof searchField === 'string') {
        this.sortByText() // from statisticsMixin
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .dashboard-error {
  flex-direction: row;
  align-items: center;
}
</style>
