<template>
  <div class="card">
    <div class="circle_roundg_g"></div>
    <div class="card-body refer__bg_grad has_big_pt">
      <div class="descr_ref_g">
        <h4 class="title_refer_sf">{{$t('referrals.title')}}</h4>
      </div>
      <div class="form_copy_sh_g d_flex align_center mb-4">
        <div class="inp_inp_copy flex_grow_1">
          <input type="text" class="form-control" :placeholder="$t('referrals.title')" id="ref_link" readonly :value="ref_link">
        </div>
        <div class="button_copy_g flex_grow_0">
          <button class="ful_butt_green d_flex align_center justify_center btn-clipboard" type="button" data-clipboard-action="copy" data-clipboard-target="#ref_link">
            <span class="icon_left_g icon_copy_g">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            {{$t('general.copy')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars,no-undef
const clipboard = new Clipboard('.btn-clipboard')

export default {
  name: 'RefLinkForm',
  props: {
    ref_link: ''
  }
}
</script>
