export default {
  navigation: 'Settings',
  title: 'Settings PWA #{id}',
  message: 'Customize your apps',
  tags: 'PWA Tags',
  tagsInfo: 'A set of tags for PWA filtering',
  alias: 'Unique Alias in the system',
  aliasInfo: 'This is the unique system name of the PWA system, to make it easier to understand where the right PWA is.',
  installMessage: 'Fake download will go from <strong>{stepMin}</strong> to <strong>{stepMax}</strong> steps with an interval of <strong>{intervalMin} ms</strong>. to <strong>{intervalMax} ms</strong>. In this case, the minimum possible time will be <strong>{minInstallingTime} sec.</strong>, and the maximum possible <strong>{maxInstallingTime} sec.</strong> On average, a fake download will take <strong>{avgInstallingTime} sec.</strong>',
  minStep: 'Min. steps',
  minStepInfo: 'This parameter indicates the minimum possible number of download steps',
  maxStep: 'Max. steps',
  maxStepInfo: 'This parameter indicates the maximum possible number of download steps',
  minInterval: 'Min. interval',
  minIntervalInfo: 'This parameter indicates the minimum possible time between download steps',
  maxInterval: 'Max. interval',
  maxIntervalInfo: 'This parameter indicates the maximum possible time between download steps',
  customJs: 'Custom JS',
  customJsInfo: 'To add custom JS to your PWA, contact the service administration'
}
