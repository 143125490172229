<template>
  <div class="page-full-fluid-width parent_tooltip PWAs page-pwas" v-if="accesses.pwas.view">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <info-modal id="domain-info" ref="emailInfo"/>
            <confirm-modal ref="confirmModal" id="pwa-confirm-modal"></confirm-modal>
            <ModalAddMeta ref="metaForm"/>
            <ModalCustomJs ref="customJSForm"/>
            <ModalDownloadJs ref="cloakForm"/>
            <div class="pwa___custom_head_sg custom_head_sg d_flex align_center justify_between">
              <h1 class="page-pwas__title">PWA
                <router-link
                    v-if="loading === false"
                    :to="{ name: 'PWAsTrash' }"
                    class="simple_butt_afg page-pwas__title-link">({{
                    $tc('titles.pwa.trashBadge', trash, {count: trash})
                  }})
                </router-link>
              </h1>
              <div class="d-md-flex">
                <div class="flex-grow-1">
                  <form class="forms-sample">
                    <div class="item_filt_g row_filters grid_items_filter">
                      <div class="item_silter_sjhf_g">
                        <input type="text" class="form-control" id="text" tabindex="0" name="text"
                               v-model="filter.search"
                               :placeholder="$t('pwas.filters.search')" v-on:keydown.enter.prevent/>
                      </div>
                      <div class="item_silter_sjhf_g">
                        <BaseSelect
                          v-model="filter.tags"
                          id="tags"
                          :multiple="true"
                          :taggable="true"
                          :placeholder="$t('pwas.filters.tag')"
                          :options="filter.tags"
                          :create-option="option => option"
                        />
                      </div>
                      <div class="item_silter_sjhf_g">
                        <BaseSelect
                          v-model="filter.status"
                          id="filter.status"
                          :placeholder="$t('pwas.filters.status')"
                          :options="statusOptions"
                        />
                      </div>
                      <div class="item_silter_sjhf_g">
                        <BaseSelect
                          v-model="filter.country"
                          id="country"
                          showIcon
                          :placeholder="$t('trackers.form.country')"
                          :options="countryOptions"
                        />
                      </div>
                      <div class="item_silter_sjhf_g one-column-mobile">
                        <BaseSelect
                          v-model="filter.preLandingPage"
                          id="filter.preLandingPage"
                          placeholder="Pre-landing"
                          endpoint="/landing-pages/select2"
                          sort="id"
                        />
                      </div>
                      <div class="item_silter_sjhf_g one-column-mobile">
                        <BaseSelect
                          v-model="filter.postLandingPage"
                          id="filter.postLandingPage"
                          placeholder="Post-landing"
                          endpoint="/landing-pages/select2"
                          sort="id"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="flex-grow-0 mt-2 mt-md-0 ml-md-2" v-if="accesses.pwas.update">
                  <router-link class="btn_green_g mobile-full-w" tag="button" :to="{name: 'PWAsCreate'}">
                    {{ $t('pwas.createNew') }}
                  </router-link>
                </div>
              </div>
              <hr
                  v-if="accesses.pwas.update && pageCount && !loading"
                  class="my-4"/>
              <div
                  v-if="accesses.pwas.update && pageCount && !loading"
                  class="col-xl-10 col-lg-10 col-md-8 col-sm-12 pl-0 pr-0">
                <div class="row">
                  <GroupAction
                      :value="group"
                      @input="handleActionGroup"
                      :class="[
                'col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-2',
                group.length < 1 ? 'disabled' : ''
              ]"
                      trash
                      :remove='accesses.pwas.delete'
                      copyPwa
                      play
                      stop
                      meta
                      :customJs="user.customJs !=null && user.customJs===true"
                      paid
                      isPwa
                  />
                </div>
              </div>
            </div>
            <div v-if="loading === false && pageCount === 0" class="no_pwa_here">
              <div class="in_to_nothing_pwa text_center">
                <PersonReadyToWork/>
                <div class="texts_pwa_no">
                  <div class="text_this_no_pw" v-if="!isEmptyFilter">{{ $t('pwas.notFoundByFilters') }}</div>
                  <div class="text_this_no_pw" v-if="isEmptyFilter">{{ $t('pwas.noHave') }}</div>
                  <div class="butt_nos_g" v-if="isEmptyFilter">
                    <router-link class="btn_green_g" tag="button" :to="{name: 'PWAsCreate'}">{{
                        $t('pwas.createNew')
                      }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <loading v-model="loading" class="wrapper-table">
              <div class="table-responsive">
                <table v-if="pageCount" class="table table-hover">
                  <thead>
                  <tr>
                    <th>
                      <div class="form-check form-check-primary mb-0 mt-1">
                        <label class="form-check-label ml-0">
                          <input type="checkbox" class="form-check-input" v-model="checkAll" id="checkAll">
                          <green-checkbox-icon :isChecked="checkAll" style="margin-top: 3px;"/>
                        </label>
                      </div>
                    </th>
                    <th></th>
                    <th>
                      PWA
                      <info :message="$t('pwas.pwaTitle')"/>
                    </th>
                    <th>
                      {{ $t('pwas.status') }}
                      <info :message="$t('pwas.statusTitle')"/>
                    </th>
                    <th>
                      {{ $t('pwas.headers.domain') }}
                      <info :message="$t('pwas.headers.domainInfo')"/>
                    </th>
                    <th>
                      {{ $t('pwas.tags') }}
                      <info :message="$t('pwas.tagsTitle')"/>
                    </th>
                    <th class="text-center">
                      {{ $t('pwas.trackerHead') }}
                      <info :message="$t('pwas.trackerTitle')"/>
                    </th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in models" :key="item.id">
                    <td>
                      <div class="form-check form-check-primary mb-1">
                        <label class="form-check-label ml-0">
                          <input
                              :id="`check-${item.id}`"
                              @input="handlerGroup(item.id)" :value="item.id"
                              type="checkbox"
                              class="form-check-input"
                              v-model="item.checked"
                          >
                          <green-checkbox-icon :isChecked="item.checked"/>
                        </label>
                      </div>
                    </td>
                    <td class="thumb_usegb">
                      <img :src="item.images" alt="image">
                    </td>
                    <td class="name_tbs_g">
                      {{ item.alias || item.name }}
                    </td>
                    <td class="status_lab_g">
                      <span :class="`text-${item.status.class}`">{{ $t(item.status.label) }}</span>
                    </td>
                    <td class="name_tbs_g">
                      {{ item.domain }}
                      <span
                        v-if="item.mirrors > 0"
                        class="page-pwas__item-domain-count"
                      >
                        {{ item.mirrors }}
                      </span>
                    </td>
                    <td class="tags_tbs_g table-body-tags-td">
                      <BaseTags
                        v-if="item?.tags?.length"
                        :tags="item.tags"
                        clickable
                        @tag-click="onTagClick"
                      />
                    </td>
                    <td class="name_tbs_g text-center" v-if="item?.tracker">
                      <router-link class="text-white" :to="{name: 'PWAsTracker', params: {id: item.id} }">
                        {{ $t(item.tracker.redirectMode) }}
                        <template v-if="item?.tracker?.filters?.length">
                          <br>
                          <template v-for="country of item.tracker.filters">
                            <i class="flag-icon mt-1" :key="country"
                               :class="index < 0 ? getFlagIcon(country) : `${getFlagIcon(country)} ml-1`"
                               data-toggle="tooltip"
                               :title="getCountryName(country)" style="cursor: pointer"></i>
                          </template>
                        </template>
                      </router-link>
                    </td>
                    <td class="name_tbs_g text-center" v-else>
                      <span class="text-gray">( {{ $t('general.not') }} )</span>
                    </td>
                    <td class="actiosn_ahgg text-right">
                      <div class="d-flex justify-content-end">
                        <div class="item_butt_act_g" v-if="item.status.id === 3 && accesses.pwas.update">
                          <button @click="handlerPlay(item)" class="d_flex align_center justify_center btn_icon_rounded"
                                  data-toggle="tooltip" :data-original-title="$t('pwas.buttons.run')" type="button">
                            <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <circle cx="14" cy="14.3131" r="14" fill="#45B172"/>
                              <path
                                  d="M10.167 8.64478C10.167 7.85361 11.0422 7.37577 11.7077 7.8036L20.5252 13.4719C21.1375 13.8656 21.1375 14.7607 20.5252 15.1543L11.7077 20.8226C11.0422 21.2505 10.167 20.7726 10.167 19.9814V8.64478Z"
                                  stroke="#2A2E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </button>
                        </div>
                        <div class="item_butt_act_g" v-if="item.status.id === 4 && accesses.pwas.update"
                             data-toggle="tooltip" :data-original-title="$t('pwas.buttons.stop')">
                          <button class="d_flex align_center justify_center btn_icon_rounded" @click="handlerStop(item)"
                                  type="button">
                            <svg width="14" height="17" viewBox="0 0 14 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M1.29688 1.75061C1.29688 1.23284 1.74459 0.81311 2.29688 0.81311H4.50026C5.05255 0.81311 5.50026 1.23284 5.50026 1.75061V14.8756C5.50026 15.3934 5.05255 15.8131 4.50026 15.8131H2.29688C1.74459 15.8131 1.29688 15.3934 1.29688 14.8756V1.75061Z"
                                  stroke="#2A2E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path
                                  d="M8.50024 1.75061C8.50024 1.23284 8.94796 0.81311 9.50024 0.81311H11.7036C12.2559 0.81311 12.7036 1.23284 12.7036 1.75061V14.8756C12.7036 15.3934 12.2559 15.8131 11.7036 15.8131H9.50024C8.94796 15.8131 8.50024 15.3934 8.50024 14.8756V1.75061Z"
                                  stroke="#2A2E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </button>
                        </div>
                        <div class="item_butt_act_g" v-if="item.status.id === 2 && accesses.pwas.update">
                          <button type="button" class="d_flex align_center justify_center btn_icon_rounded green_butt_g"
                                  @click="handlerPaid(item)"
                                  data-toggle="tooltip" :data-original-title="$t('pwas.buttons.pay')">
                            <span class="icon_dollar_g"></span>
                          </button>
                        </div>
                        <div class="item_butt_act_g" v-if="accesses.statistics.view">
                          <button @click="analyticsHandler(item)"
                                  class="d_flex align_center justify_center btn_icon_rounded"
                                  data-toggle="tooltip" :data-original-title="$t('general.statistics')">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M13.25 8H8V2.75C8 2.3 7.7 2 7.25 2C3.5 2 0.5 5 0.5 8.75C0.5 12.5 3.5 15.5 7.25 15.5C11 15.5 14 12.5 14 8.75C14 8.3 13.7 8 13.25 8ZM8 13.925C5.15 14.375 2.45 12.35 2.075 9.5C1.625 6.65 3.65 3.95 6.5 3.575V8.75C6.5 9.2 6.8 9.5 7.25 9.5H12.425C12.125 11.825 10.325 13.625 8 13.925ZM10.25 0.5C9.8 0.5 9.5 0.8 9.5 1.25V5.75C9.5 6.2 9.8 6.5 10.25 6.5H14.75C15.2 6.5 15.5 6.2 15.5 5.75C15.5 2.825 13.175 0.5 10.25 0.5ZM11 5V2.075C12.5 2.375 13.625 3.5 13.925 5H11Z"
                                  fill="#2A2E32"/>
                            </svg>
                          </button>
                        </div>
                        <div class="item_butt_act_g" data-toggle="tooltip" :data-original-title="$t('general.edit')"
                             v-if="accesses.pwas.update">
                          <router-link class="d_flex align_center justify_center btn_icon_rounded update_btn_icon"
                                       :to="{name: 'PWAsUpdate', params: {id: item.id} }">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M15.5 4.43001C15.5006 4.33131 15.4817 4.23346 15.4443 4.14208C15.407 4.0507 15.352 3.96759 15.2825 3.89751L12.1025 0.717513C12.0324 0.648001 11.9493 0.593008 11.8579 0.555684C11.7666 0.518361 11.6687 0.499442 11.57 0.500013C11.4713 0.499442 11.3735 0.518361 11.2821 0.555684C11.1907 0.593008 11.1076 0.648001 11.0375 0.717513L8.91501 2.84001L0.717513 11.0375C0.648001 11.1076 0.593008 11.1907 0.555684 11.2821C0.518361 11.3735 0.499442 11.4713 0.500013 11.57V14.75C0.500013 14.9489 0.57903 15.1397 0.719682 15.2803C0.860335 15.421 1.0511 15.5 1.25001 15.5H4.43001C4.53496 15.5057 4.63993 15.4893 4.73813 15.4518C4.83632 15.4144 4.92555 15.3567 5.00001 15.2825L13.1525 7.08501L15.2825 5.00001C15.351 4.92732 15.4067 4.84366 15.4475 4.75251C15.4547 4.69273 15.4547 4.63229 15.4475 4.57251C15.451 4.5376 15.451 4.50242 15.4475 4.46751L15.5 4.43001ZM4.12251 14H2.00001V11.8775L9.44751 4.43001L11.57 6.55251L4.12251 14ZM12.6275 5.49501L10.505 3.37251L11.57 2.31501L13.685 4.43001L12.6275 5.49501Z"
                                  fill="white"/>
                            </svg>
                          </router-link>
                        </div>
                        <BaseDropdownToggle>
                          <button
                            v-if="item.status.id === 4"
                            @click="onMetaClick(item)"
                            class="item_action_menu"
                          >
                            <InlineSvg src="/images/svg-icons/meta-icon.svg"/>
                            <span>{{ $t('pwas.buttons.meta.buttonTitle') }}</span>
                          </button>
                          <button
                            v-if="item.status.id > 2 && user.customJs"
                            @click="handleCustomJSEdit(item)"
                            class="item_action_menu"
                          >
                            <InlineSvg src="/images/svg-icons/js-icon.svg"/>
                            <span>{{ $t('pwas.buttons.customJs.buttonTitle') }}</span>
                          </button>
                          <button
                            v-if="item.status.id > 2"
                            @click="onDownloadJsClick(item)"
                            class="item_action_menu"
                          >
                            <InlineSvg src="/images/svg-icons/download-icon.svg"/>
                            <span>{{ $t('pwas.buttons.cloak.buttonTitle') }}</span>
                          </button>
                          <button
                            v-if="item.status.id > -1"
                            @click="pwaPage(item)"
                            class="item_action_menu"
                          >
                            <InlineSvg src="/images/svg-icons/preview-icon.svg"/>
                            <span>{{ $t('pwas.buttons.preview') }}</span>
                          </button>
                          <button
                            v-if="item.paid && accesses.pwas.update"
                            @click="handlerCopy(item)"
                            class="item_action_menu"
                          >
                            <InlineSvg src="/images/svg-icons/copy-icon.svg"/>
                            <span>{{ $t('general.copy') }}</span>
                          </button>
                          <button
                            v-if="accesses.pwas.delete"
                            @click="handlerDelete(item)"
                            class="item_action_menu"
                          >
                            <InlineSvg src="/images/svg-icons/trash-icon.svg"/>
                            <span>{{ $t('pwas.trash.move') }}</span>
                          </button>
                        </BaseDropdownToggle>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </loading>
            <NavPaginate
                v-if="accesses.pwas.view && pageCount && !loading"
                v-model="page"
                :pageCount="pageCount"
                :inputPage="+inputPage"
                @inputPage="inputPage=$event"
                @pagination="handlerPagination"
                @changePage="debounceChangePage"
                :limit="+limit"
                @limit="limit=$event"
                :count="count"
            >
              <GroupAction
                  v-if="accesses.pwas.update && models.length > 0"
                  :value="group"
                  @input="handleActionGroup"
                  :class="['col-md-auto p-md-0 form-group pagination-input mr-lg-3 mb-3',
                  group.length < 1 ? 'disabled' : '']"
                  trash
                  copyPwa
                  :remove='accesses.pwas.delete'
                  play
                  stop
                  meta
                  :customJs="user.customJs !=null && user.customJs===true"
                  paid
                  isPwa
              ><label for="">{{ $t('group.pagination') }}</label></GroupAction>
            </NavPaginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import tooltipMixin from '@/mixins/tooltip-mixin'
import * as baseComponents from '@/components'
import titleMixin from '@/mixins/title-mixin'
import trackerMixin from '@/mixins/tracker-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import InfoModal from '@/components/modal/Info.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'
import Select2 from '@/components/Select2.vue'
import PersonReadyToWork from '@/components/animatedSvgs/PersonReadyToWork.vue'
import Loading from '@/components/Loading.vue'
import {mapActions, mapGetters} from 'vuex'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import paginationMixin from '@/mixins/pagination-mixin'
import GreenCheckboxIcon from '@/components/GreenCheckboxIcon.vue'
import groupMixin from '@/pages/pwas/mixins/group-mixin'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import middleware from '@/store/modules/middleware'
import ModalAddMeta from '@/components/modal/ModalAddMeta.vue'
import ModalCustomJs from '@/components/modal/ModalCustomJs.vue'
import ModalDownloadJs from '@/components/modal/ModalDownloadJs.vue'
import BaseTags from '@/components/base/BaseTags/BaseTags.vue'
import BaseDropdownToggle from '@/components/base/BaseDropdownToggle.vue'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'PWAs',
  title () {
    return this.$t('titles.pwa.my')
  },
  components: {
    BaseSelect,
    GreenCheckboxIcon,
    Info: baseComponents.Info,
    InfoModal: InfoModal,
    ConfirmModal,
    Select2,
    PersonReadyToWork,
    Loading,
    ModalAddMeta,
    ModalCustomJs,
    ModalDownloadJs,
    BaseTags,
    InlineSvg,
    BaseDropdownToggle
  },
  mixins: [tooltipMixin, titleMixin, trackerMixin, accessesMixin, select2AjaxMixin, paginationMixin, groupMixin],
  data () {
    return {
      filter: {
        search: '',
        country: '',
        status: '',
        tags: [],
        preLandingPage: '',
        postLandingPage: ''
      },
      landingSettings: {}
    }
  },
  computed: {
    ...mapGetters({
      models: 'pwas/models',
      count: 'pwas/count',
      trash: 'pwas/trash'
    }),
    isEmptyFilter () {
      return this.filter.country === '' && this.filter.status === '' && this.filter.search === '' && !this.filter.tags.length > 0 && this.filter.preLandingPage === '' && this.filter.postLandingPage === ''
    },
    statusOptions () {
      return [
        ...this.$store.getters['pwas/statuses'].map(status => ({
          id: status.id,
          text: this.$t(status.label)
        }))
      ]
    }
  },
  watch: {
    limit () {
      this.handlerPagination()
    }
  },
  created () {
    for (const prop in this.filter) {
      this.$watch(`filter.${prop}`, () => this.handlerPagination(), {deep: true})
    }
    this.landingSettings = {
      ...this.getSelect2AjaxSettings('landing-pages/select2', 'id'),
      allowClear: true
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'pwas/fetchData',
      patch: 'pwas/patch',
      update: 'pwas/updateMeta',
      updateCustomJs: 'pwas/updateCustomJs'
    }),
    debounceFetchData: _.debounce(async function (options) {
      try {
        await this.fetchData(options)
      } catch (error) {
        if (this.accesses.pwas.view !== true) {
          for (const [access, rules] of Object.entries(this.accesses)) {
            for (const [rule, value] of Object.entries(rules)) {
              if (rule === 'view' && value) {
                switch (access) {
                  case 'statistics':
                    await this.$router.push({name: 'Dashboard'})
                    break
                  case 'teams':
                    await this.$router.push({name: 'Teams'})
                    break
                  case 'finance':
                    await this.$router.push({name: 'Bills'})
                    break
                  case 'pushes':
                    await this.$router.push({name: 'PWAsPushes'})
                    break
                  case 'landings':
                    await this.$router.push({name: 'LandingPages'})
                    break
                  case 'trackers':
                    await this.$router.push({name: 'FAQ'})
                    break
                  default:
                    middleware.validateAut(error)
                    break
                }
              }
            }
          }
        } else {
          middleware.validateAut(error)
        }
      }
      this.loading = false
      this.initTooltip()
    }, 200),
    onTagClick (tag) {
      this.filter.tags.push(tag)
    },
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        page: this.page,
        limit: this.limit,
        sort: '-createdAt',
        filter: this.filter,
        trash: 1
      })
    },
    pwaPage (pwa) {
      if (pwa.status.id > 2) {
        window.open(`https://${pwa.domain}/`, '_blank')
      } else {
        window.open(`${import.meta.env.VITE_APP_PREVIEW_BASE_URL}/${pwa.id}`, '_blank')
      }
    },
    async handlerDelete (item) {
      const relations = await this.$store.dispatch('pwas/getRelations', [item.id])
      const message = relations.length > 0 ? this.$t('pwas.trash.moveMessageRelation', {name: (item.alias || item.name)}) : this.$t('pwas.trash.moveMessage', {name: (item.alias || item.name)})
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('pwas.trash.move'),
        message,
        okButton: this.$t('pwas.trash.moveButton')
      })
      if (ok) {
        await this.patch({id: item.id, expired: 'true'})
        await this.$store.dispatch('alerts/success', this.$t('pwas.trash.moveSuccess'))
        this.fetchPage()
      }
    },
    async handlerCopy (item) {
      const name = item.alias || item.name
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('pwas.copy.title'),
        message: this.$t('pwas.copy.message', {name}),
        okButton: this.$t('pwas.copy.button')
      })
      if (ok) {
        setTimeout(async () => {
          const withTracker = await this.$refs.confirmModal.show({
            title: this.$t('pwas.copy.title'),
            message: this.$t('pwas.copy.tracker.message', {name}),
            okButton: this.$t('pwas.copy.tracker.okButton'),
            cancelButton: this.$t('pwas.copy.tracker.cancelButton')
          })
          if (withTracker) {
            await this.$store.dispatch('pwas/copy', {id: item.id, withTracker})
          } else {
            await this.$store.dispatch('pwas/copy', {id: item.id, withTracker})
          }
          this.$nextTick(async () => {
            await this.handlerPagination()
            await this.$store.dispatch('alerts/success', this.$t('pwas.copy.success'))
          })
        }, 500)
      }
    },
    async handlerPaid (item) {
      try {
        let activated = false
        const user = this.$store.getters['identity']

        if (user.tariffInstalled) {
          const ok = await this.$refs.confirmModal.show({
            okButton: this.$t('pwas.paid.button'),
            title: this.$t('pwas.paid.title'),
            message: this.$t('pwas.paid.paidInstalls'),
            warning: this.$t('pwas.paid.warning')
          })
          if (ok) {
            await this.$store.dispatch('pwas/paidInstalls', {id: item.id})
            activated = true
          }
        } else {
          await this.$store.dispatch('pwas/paidCheck', item.id)
          const check = this.$store.getters['pwas/check']
          if (!check.haveTariff) {
            const ok = await this.$refs.confirmModal.show({
              okButton: this.$t('menu.tariffs'),
              title: this.$t('pwas.paid.title'),
              message: this.$t('pwas.paid.noTariff')
            })
            if (ok) this.$router.push({name: 'Tariffs'})
          } else if (check.limits && check.activations) {
            const ok = await this.$refs.confirmModal.show({
              okButton: this.$t('pwas.paid.button'),
              title: this.$t('pwas.paid.title'),
              message: this.$tc('pwas.paid.paidTariff', check.activations - 1, {
                count: check.activations,
                countLeft: check.activations - 1
              }),
              warning: this.$t('pwas.paid.warning')
            })
            if (ok) {
              await this.$store.dispatch('pwas/paidTariff', {id: item.id})
              activated = true
            }
          } else if (!check.canBuyActivation && check.pwaActivationCost) {
            const ok = await this.$refs.confirmModal.show({
              okButton: this.$t('menu.bills'),
              title: this.$t('pwas.paid.title'),
              message: this.$t('pwas.paid.noMoney', {amount: check.pwaActivationCost})
            })
            if (ok) this.$router.push({name: 'Bills'})
          } else if (check.canBuyActivation && check.pwaActivationCost) {
            const ok = await this.$refs.confirmModal.show({
              okButton: this.$t('pwas.paid.button'),
              title: this.$t('pwas.paid.title'),
              message: this.$t('pwas.paid.paidBalance', {amount: check.pwaActivationCost}),
              warning: this.$t('pwas.paid.warning')
            })
            if (ok) {
              await this.$store.dispatch('pwas/paidBalance', {id: item.id})
              activated = true
            }
          }
        }

        if (activated) {
          item.status = this.$t(this.$store.getters['pwas/statuses'][4])
          item.paid = true
          await this.$store.dispatch('alerts/success', this.$t('pwas.paid.success'))
        }
        await this.fetchPage()
      } catch (error) {
        if (error.response === undefined) {
          await this.$store.dispatch('alerts/error', this.$t('pwas.paid.error'))
        } else {
          await this.$store.dispatch('alerts/error', error.response.data.message)
        }
      }
    },
    async handlerPlay (item) {
      const user = this.$store.getters['identity']

      const ok = await this.$refs.confirmModal.show({
        title: this.$t('pwas.play.title'),
        message: this.$t('pwas.play.message', {name: (item.alias || item.name)}),
        okButton: this.$t('pwas.play.button')
      })
      if (ok) {
        try {
          const endpoint = user.tariffInstalled ? 'pwas/playInstalls' : 'pwas/play'
          await this.$store.dispatch(endpoint, item.id)
          item.status = this.$t(this.$store.getters['pwas/statuses'][5])
          await this.$store.dispatch('alerts/success', this.$t('pwas.play.success'))
          await this.fetchPage()
        } catch (error) {
          await this.$store.dispatch('alerts/error', error.response.data === undefined ? error.message : error.response.data)
        }
      }
    },
    async handlerStop (item) {
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('pwas.stop.title'),
        message: this.$t('pwas.stop.message', {name: (item.alias || item.name)}),
        okButton: this.$t('pwas.stop.button')
      })
      if (ok) {
        try {
          await this.$store.dispatch('pwas/stop', item.id)
          item.status = this.$t(this.$store.getters['pwas/statuses'][4])
          await this.$store.dispatch('alerts/success', this.$t('pwas.stop.success'))
          await this.fetchPage()
        } catch (error) {
          await this.$store.dispatch('alerts/error', error.message)
        }
      }
    },
    async analyticsHandler (model) {
      // this.count = 0
      await this.$store.dispatch('analytics/getAnalyticsByGroup')
      const options = this.$store.getters['analytics/options']
      if (options.sort != null && options.sort.length > 1) {
        options.filter = {pwa: model.id}
        options.group = 'date'
        options.limit = 20
        options.page = 1
        options.sort = '-id'
        options.range = {
          // eslint-disable-next-line no-undef
          begin: moment().subtract(14, 'd').format('YYYY-MM-DD'),
          // eslint-disable-next-line no-undef
          end: moment().format('YYYY-MM-DD')
        }
        localStorage.setItem(`pwa-filter-0-${model.id}`, model.alias || model.name)
        this.$store.commit('analytics/setOptions', JSON.parse(JSON.stringify(options)))
        await this.$store.dispatch('analytics/saveAnalyticsOptionsGroups')
        this.$router.push({name: 'AnalyticsGroups'})
      }
    },
    async onMetaClick ({id, meta}) {
      const ok = await this.$refs.metaForm.show({
        title: this.$t('pwas.buttons.meta.title'),
        message: this.$t('pwas.buttons.meta.body'),
        id: id,
        meta: meta,
        action: this.update
      })
      if (ok) {
        await this.alertSuccess(this.$t('pwas.buttons.meta.success'))
        this.fetchPage()
      }
    },
    async handleCustomJSEdit ({id, customJs}) {
      const ok = await this.$refs.customJSForm.show({
        title: this.$t('pwas.buttons.customJs.title'),
        message: this.$t('pwas.buttons.customJs.body'),
        id: id,
        customJs: customJs,
        action: this.updateCustomJs
      })
      if (ok) {
        await this.alertSuccess(this.$t('pwas.buttons.customJs.success'))
        this.fetchPage()
      }
    },
    async onDownloadJsClick (item) {
      await this.$refs.cloakForm.show({
        title: this.$t('pwas.buttons.cloak.title'),
        message: this.$t('pwas.buttons.cloak.body'),
        id: item.id,
        domain: item.domain
      })
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.card-body
  padding-top: 0
.background-fixed
  position: fixed
  right: 0
  top: 0
  z-index: 0
  height: 100%
  width: auto
  @media screen and (max-width: 1900px)
    display: none
.wrapper-table
  width: 100%
.page-pwas
  padding-top: 10px
  &__title
    margin-bottom: 24px
    font-size: 20px
    +font(400)
  &__title-link
    text-transform: uppercase
  &__item-domain-count
    border-radius: 200px
    background: #45B172
    font-weight: 500
    font-size: 10px
    color: #121214
    margin-left: 8px
    padding: 4px
    min-width: 20px
    height: 20px
    display: inline-flex
    align-items: center
    justify-content: center
.table-body-tags-td
  width: 100%
</style>
