export default {
  navigation: 'Аналитика',
  title: 'Аналитика PWA #{id}',
  message: 'Интегрируйте свой код аналитики в PWA',
  YMInfo: 'Вставьте короткий идентификатор YM. Мы автоматически отслеживаем действие "Лид". Лидом является открытие Pwa',
  nativePush: 'Собирать PUSH в pwa.group',
  nativePushInfo: 'Активируйте для сбора и отправки PUSH через сервис pwa.group',
  POSInfo: 'Идентификатор One Signal',
  forwardingSignalUserId: 'Прокидывать OneSignal user id и External User Id (в сигнал и урл os_user_id)',
  forwardingSignalUserIdInfo: 'Если нужно прокинуть OneSignal user id в PWA в ссылку в формате ..&onesignalid=SIG_USER_ID',
  sendPush: 'Отправка пушей с <a href="https://push.express/">PUSH.express</a>'
}
