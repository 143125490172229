<template>
  <div class="content-wrapper">
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <loading v-model="loading" class="card-body">
            <h4 class="card-title">{{ title }}</h4>
            <p class="card-description">Сторінка містить перелік всіх підписок на тарифи користувача
              @{{ user.username }}. Спочатку показані найновіші підписки</p>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-dark">
                <li class="breadcrumb-item">
                  <router-link :to="{name: 'AdminUsers'}">Користувачі</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="{name: 'AdminUser', params: {id: $route.params.id}}">@{{ user.username }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ title }}</li>
              </ol>
            </nav>
            <div class="table-responsive parent_tooltip">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>
                    <button
                      @click="asyncData()"
                      data-original-title="Оновлення данних"
                      data-toggle="tooltip"
                      class="btn btn-icons btn-inverse-light">
                      <i class="icon-refresh"></i>
                    </button>
                    <router-link
                      :to="{name: 'AdminUserTariffsCreate', params: {id: $route.params.id}}"
                      data-original-title="Додати підписку"
                      class="btn btn-icons btn-inverse-primary"
                      data-toggle="tooltip"
                    ><i class="icon-plus"></i></router-link>
                  </th>
                  <th>ID</th>
                  <th>Истек</th>
                  <th>Истекает</th>
                  <th>Активаций</th>
                  <th>Запущено</th>
                  <th>Тариф</th>
                  <th>Заплатил</th>
                  <th>Ваучер</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model.id">
                  <td>
                    <router-link
                      :to="{name: 'AdminUserTariffsUpdate', params: {id: $route.params.id, tariff: model.id} }"
                      data-original-title="Редагування підписки"
                      class="btn btn-icons btn-inverse-success"
                      data-toggle="tooltip"
                    ><i class="icon-pencil"></i></router-link>
                    <button
                      v-if="model.expired"
                      @click="handlerDelete(model)"
                      class="btn btn-icons btn-inverse-danger"
                      data-original-title="Видалення підписки"
                      data-toggle="tooltip"><i class="icon-trash"></i></button>
                  </td>
                  <td>{{ model.id }}</td>
                  <td>{{ model.expired | formatBoolean }}</td>
                  <td>{{ model.expires | date }}</td>
                  <td>{{ model.activations }} / {{ model.tariff.pwas }}</td>
                  <td>{{ model.used }} / {{ model.limits }}</td>
                  <td>{{ model.tariff.name }}</td>
                  <td>{{ model.total | amount }}</td>
                  <td v-html="$options.filters.formatNull(model.voucher)"></td>
                </tr>
                <tr v-if="count === 0">
                  <td colspan="9" class="text-center"><span class="text-gray">( нет )</span></td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td>Всего</td>
                  <td colspan="8">{{ count }}</td>
                </tr>
                </tfoot>
              </table>
              <nav v-if="pageCount > 1">
                <paginate ref="paginate"
                          v-model="page"
                          :page-count="pageCount"
                          :click-handler="pagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          :container-class="'pagination mt-3'"
                          :page-class="'page-item'"
                          :page-link-class="'page-link'"
                          :prev-class="'page-item'"
                          :next-class="'page-item'"
                          :prev-link-class="'page-link'"
                          :next-link-class="'page-link'"
                          :active-class="'active'">
                </paginate>
              </nav>
            </div>
          </loading>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import Loading from '@/pages/admin/components/Loading.vue'
import tooltipMixin from '@/mixins/tooltip-mixin'

export default {
  name: 'AdminUserTariffs',
  components: {Loading},
  mixins: [tooltipMixin],
  data () {
    return {
      loading: true,
      page: 1,
      limit: 20
    }
  },
  computed: {
    title () {
      return `Підписки @${this.user.username}`
    },
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    ...mapGetters({
      user: 'adminUsers/user',
      count: 'adminUserTariffs/count',
      models: 'adminUserTariffs/models'
    })
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  methods: {
    ...mapActions({
      getUser: 'adminUsers/read',
      getTariffs: 'adminUserTariffs/getTariffs',
      deleteTariff: 'adminUserTariffs/deleteTariff'
    }),
    async asyncData () {
      this.loading = true
      await this.getUser(this.$route.params.id)
      await this.getTariffs({
        limit: this.limit,
        page: this.page,
        sort: '-expires',
        owner: this.$route.params.id
      })
      this.$nextTick(() => {
        this.loading = false
        this.initTooltip()
      })
    },
    async pagination (page) {
      this.page = page
      await this.asyncData()
    },
    async handlerDelete (model) {
      let message = `Видалити підписку користувачу ${this.user.username}`
      if (confirm(message)) {
        await this.deleteTariff({
          owner: this.$route.params.id,
          tariff: model.id
        })
        await this.pagination(1)
      }
    }
  }
}
</script>
