<template>
  <div class="card grid-margin">
    <div class="card-body">
      <h4 class="card-title">Настройки валюты {{ rate.toUpperCase() }}</h4>
      <form class="forms-sample" @submit.prevent="handler">
        <div class="form-group" :class="apiValidationErrors.value ? 'has-danger' : ''">
          <label for="value">Курс $ к {{ rate.toUpperCase() }}</label>
          <input type="number" step="0.000000001" id="value" placeholder="0.00001862 or 75.4" v-model="value"
                 class="form-control"
                 :class="apiValidationErrors.value ? 'form-control-danger' : ''"
                 @focus="clearError('value')">
          <small class="form-text text-muted">Десятичная дробь, курс $ к {{ rate.toUpperCase() }}</small>
          <label v-if="apiValidationErrors.value" class="error mt-2 text-danger" for="value">
            {{ apiValidationErrors.value[0] }}
          </label>
        </div>
        <div class="form-group" :class="apiValidationErrors.url ? 'has-danger' : ''">
          <label for="url">Ссылка на получения курса</label>
          <div class="input-group-append">
            <input type="text" id="url" placeholder="http://example.com"
                   v-model="url"
                   class="form-control"
                   :class="apiValidationErrors.url ? 'form-control-danger' : ''"
                   @focus="clearError('url')">
            <div class="input-group-append">
              <span class="input-group-text"><i class="icon-link"></i></span>
            </div>
          </div>
          <small class="form-text text-muted">Ссылка, должна вернуть на GET запрос 1 строку с числом</small>
          <label v-if="apiValidationErrors.url" class="error mt-2 text-danger"
                 for="url">
            {{ apiValidationErrors.url[0] }}
          </label>
        </div>
        <div class="form-group" :class="apiValidationErrors.timer ? 'has-danger' : ''">
          <label for="timer">Интервал обновления курса</label>
          <div class="input-group-append">
            <input type="number" step="1" id="timer" placeholder="15"
                   v-model="timer"
                   class="form-control" min="1" max="59"
                   :class="apiValidationErrors.timer ? 'form-control-danger' : ''"
                   @focus="clearError('timer')">
            <div class="input-group-append">
              <span class="input-group-text">минуты</span>
            </div>
          </div>
          <small class="form-text text-muted">Целое число, время в минутах</small>
          <label v-if="apiValidationErrors.timer" class="error mt-2 text-danger"
                 for="timer">
            {{ apiValidationErrors.timer[0] }}
          </label>
        </div>
        <div class="form-group">
          <div class="form-check form-check-primary">
            <label class="form-check-label">
              <input type="checkbox" class="form-check-input" v-model="auto">
              Автообновления курса
              <i class="input-helper"></i></label>
          </div>
        </div>
        <button type="submit" class="btn btn-success mr-2">Сохранить</button>
      </form>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'RateForm',
  props: ['data', 'rate'],
  mixins: [formMixin],
  data () {
    return this.data
  },
  computed: {
    ...mapGetters({
      billing: 'adminConfig/billing'
    })
  },
  methods: {
    ...mapActions({
      update: 'adminConfig/update',
      alertSuccess: 'alerts/success'
    }),
    async handler () {
      try {
        this.resetApiValidation()
        const rates = {}
        rates[this.rate] = {
          value: Number(this.value),
          url: String(this.url),
          auto: Boolean(this.auto),
          timer: Number(this.timer)
        }
        await this.update({billing: {rates: rates}})
        this.resetApiValidation()
        await this.alertSuccess(
          'alerts/success',
          `Настройки валюты ${this.rate} обновлены`
        )
      } catch (error) {
        const data = error.response.data.errors
        data.forEach((value, index) => {
          const params = value.param.split('.')
          data[index].param = params[params.length - 1]
        })
        this.setApiValidation(data)
      }
    }
  }
}
</script>
