<template>
  <div class="item_silter_sjhf_g this_is_selector_count">
    <select class="form-control w-100" :id="id" v-model="values">
      <template v-for="[value, id] of values" >
        <option v-if="value !== ''" :value="value" selected="selected" :key="id"><i :class="`flag-icon flag-icon-${value.toLowerCase()}`"></i> {{ getCountryName(value) }}</option>
      </template>
    </select>
  </div>
</template>
<script>
import * as dictionary from '@pwa.group/pwa.dictionary'
import {getCurrentLang} from '@/utils/i18n'

export default {
  name: 'CountryFilter',
  props: {
    values: Array,
    id: String,
    label: String
  },
  watch: {
    values () {
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  updated () {
    this.init()
  },
  methods: {
    getCountryName (code) {
      const index = dictionary.countries.findIndex(country => country.id.toLowerCase() === code.toLowerCase())
      return this.$t(dictionary.countries[index].text)
    },
    init () {
      const self = this
      const format = state => {
        if (state.id === '' || state.id === undefined) {
          return state.text
        } else {
          const index = dictionary.countries.findIndex(country => country.id.toLowerCase() === state.id.toLowerCase())
          return `<i class="flag-icon flag-icon-${state.id.toLowerCase()}"></i> ${this.$t(dictionary.countries[index].text)}`
        }
      }
      // eslint-disable-next-line no-undef
      jQuery('#' + self.id).select2({
        placeholder: self.$t(`analytics.filtersLabel.country`),
        templateResult: format,
        templateSelection: format,
        multiple: 'multiple',
        escapeMarkup: function (m) {
          return m
        },
        allowClear: true,
        ajax: {
          dataType: 'json',
          url: import.meta.env.VITE_APP_API_BASE_URL + '/pwas/pushes/country',
          delay: 500,
          transport: function (params, success, failure) {
            // eslint-disable-next-line no-undef
            const $request = $.ajax({
              ...params,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('vue-authenticate.vueauth_accessToken'),
                'Accept-Language': getCurrentLang()
              }
            })
            $request.then(success)
            $request.fail(failure)
            return $request
          },
          data: params => {
            const urlSearchParams = new URLSearchParams()
            urlSearchParams.set('page', params.page || '1')
            urlSearchParams.set('limit', '5')
            urlSearchParams.set('sort', 'text')
            urlSearchParams.set('columns', 'text')
            if (params.term != null) {
              urlSearchParams.set('text', params.term)
            }
            return decodeURI(urlSearchParams.toString())
          }
        }
      }).on('change', function (event) {
        self.$emit('input', event.target.value)
      })
    }
  }
}
</script>
