import api from './api'

const getParams = (payload) => {
  const searchParams = new URLSearchParams()
  searchParams.set('page', payload.page)
  searchParams.set('limit', payload.limit)
  if (payload.group != null) {
    searchParams.set('group', payload.group)
  }
  searchParams.set('sort', payload.sort)
  // eslint-disable-next-line no-undef
  searchParams.append('range[begin]', moment(payload.range.begin).format('YYYY-MM-DD'))
  // eslint-disable-next-line no-undef
  searchParams.append('range[end]', moment(payload.range.end).format('YYYY-MM-DD'))

  if (payload.columns.length) {
    let columns = payload.columns.map(value => value)
    if (columns.includes('uniquesToInstalls')) {
      columns.push('uniques')
      columns.push('installs')
    }
    if (columns.includes('uniquesToPush')) {
      columns.push('uniques')
      columns.push('pushes')
    }
    if (columns.includes('installsToPush')) {
      columns.push('pushes')
      columns.push('installs')
    }
    columns = columns.filter((value, index, self) => self.indexOf(value) === index)
    for (const column of columns) {
      searchParams.append('columns', column)
    }
  }
  if (payload.filter != null && Object.keys(payload.filter).length) {
    for (const [prop, value] of Object.entries(payload.filter)) {
      const part = prop.split('.')
      if (part.length > 1) {
        searchParams.append(`params[${part[1]}]`, value)
      } else {
        searchParams.append(`filter[${prop}]`, value)
      }
    }
  }
  return decodeURI(searchParams.toString())
}

export default {
  groups: (payload) => api.get('analytics/groups?' + getParams(payload)),
  groupsParams: (payload) => api.get('analytics/groups/params?' + getParams(payload)),
  hosts: (payload) => api.get('analytics/hosts?' + getParams(payload)),
  filters: (payload) => api.get('analytics/filters?' + getParams(payload)),
  createReport: (payload) => api.post('analytics/report?' + getParams(payload)),
  saveOptionsGroups: (payload) => api.post('users/analytics/groups?' + getParams(payload)),
  saveOptionsHosts: (payload) => api.post('users/analytics/hosts?' + getParams(payload))
}
