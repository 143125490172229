<template>
  <div class="Transactions page-full-fluid-width parent_tooltip" v-if="accesses.finance.view">
    <div class="row">
      <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card bills_class_g">
        <div class="card">
          <div class="card-body pt-0">
            <FinanceTabs />
            <div v-if="loading || (loading === false && models.length > 0)">
              <loading v-model="loading" id="data_table_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                <div class="wrapper-table no-hover">
                  <div class="table-responsive">
                    <table id="finance-listing" class="table_border_style table">
                      <table-sort-head :items="heads" v-model="sort"/>
                      <tbody>
                      <tr v-for="item in models" :key="item.id">
                        <td class="item_fina_col_1">{{ item.createdAt | formatDate }}</td>
                        <td class="item_fina_col_2">
                          <label :class="item.amount > 0 ? 'text-success' : 'text-danger'">{{
                              item.amount | amount
                            }}</label>
                        </td>
                        <td class="item_fina_col_3 text-md-right">{{ $t(item.purpose, item) }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </loading>
              <nav v-if="pageCount > 1">
                <paginate ref="paginate"
                          v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                          :page-class="'page_item_gd'"
                          :page-link-class="'page_link_g d_flex justify_center align_center'"
                          :prev-class="'page-item_go'"
                          :next-class="'page-item_go'"
                          :prev-link-class="'page-link_go d_flex justify_center align_center'"
                          :next-link-class="'page-link_go d_flex justify_center align_center'"
                          :active-class="'active'">
                </paginate>
              </nav>
            </div>
            <div v-else class="col-12">
              <div class="no_pwa_here no_pwa_transac">
                <div class="in_to_nothing_pwa text_center">
                  <div class="image_no_pw anim_mobs_go">
                    <person-in-chair-with-cigar/>
                  </div>
                  <div class="texts_pwa_no">
                    <div class="text_this_no_pw">{{ $t('finance.transactions.noHave') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import Loading from '@/components/Loading.vue'
import tooltipMixin from '@/mixins/tooltip-mixin'
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import PersonInChairWithCigar from '@/components/animatedSvgs/PersonInChairWithCigar.vue'
import {mapActions, mapGetters} from 'vuex'
import TableSortHead from '@/components/TableSortHead.vue'
import FinanceTabs from '@/pages/finance/components/FinanceTabs.vue'

export default {
  name: 'Transactions',
  components: {FinanceTabs, Loading, PersonInChairWithCigar, TableSortHead},
  title: function () {
    return this.$t('titles.transactions')
  },
  mixins: [tooltipMixin, titleMixin, accessesMixin],
  data () {
    return {
      loading: true,
      limit: 20,
      page: 1,
      sort: '-createdAt',
      heads: [
        {label: 'finance.transactions.created', title: 'finance.transactions.createdTitle', sort: 'createdAt'},
        {label: 'finance.transactions.amount', title: 'finance.transactions.amountTitle', sort: 'amount'},
        {label: 'finance.transactions.purpose', title: 'finance.transactions.purposeTitle', class: 'text-md-right'}
      ]
    }
  },
  created () {
    this.fetchPage()
  },
  computed: {
    ...mapGetters({
      models: 'transactions/models',
      count: 'transactions/count'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  watch: {
    sort: {
      handler () {
        this.fetchPage()
      }
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'transactions/fetchData'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
      this.initTooltip()
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        limit: this.limit,
        page: this.page,
        sort: this.sort
      })
    },
    async handlerPagination (page) {
      this.page = page
      await this.fetchPage()
    }
  }
}
</script>
