<template>
  <modal ref="modal" :id="id">
    <div class="info_blk_g_mod text-center">
      <div class="icon_sinf_g">
        <img class="modal_attention_icon" src="/images/icon_info_attention.png" alt="attention icon">
      </div>
      <div v-html="title" class="title_inf_glbk" style="color: #F1C62D;"></div>
      <div v-html="message" class="descr_inf_ico_g"></div>
      <div v-if="warning" v-html="warning" class="descr_inf_ico_g" style="color: #F1C62D;"></div>
    </div>
    <div class="foot_inf_sg_g">
      <div class="in_to_foot_br_g">
        <div class="row has_big_rog">
          <div class="col-6 item_pwa_shs_g">
            <a href="javascript:void(0);" class="btn_simple_border d_flex align_center justify_center no_underline yell_col"
               @click="handlerConfirm">{{ okButton }}
            </a>
          </div>
          <div class="col-6 item_pwa_shs_g">
            <a href="javascript:void(0);" class="btn_simple_border d_flex align_center justify_center no_underline full_plin yell_col"
               @click="handlerCancel">{{ cancelButton }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from './Modal.vue'

export default {
  name: 'ConfirmModal',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {Modal},
  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: undefined, // text for cancel button

    warning: undefined,

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined
  }),
  methods: {
    show (opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.okButton = opts.okButton
      this.path = opts.path
      this.warning = opts.warning
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      } else {
        this.cancelButton = this.$t('general.cancel')
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.modal.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerConfirm () {
      this.$refs.modal.close()
      this.resolvePromise(true)
    },
    handlerCancel () {
      this.$refs.modal.close()
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    }
  }
}
</script>

<style scoped>
.modal_attention_icon {
  /* Reset all parent styles for img */
  width: initial !important;
  height: initial !important;
  border-radius: initial !important;
}
</style>
