<template>
  <div>
    <bot-offer-upload-modal ref="botOfferUploadModal"/>
    <confirm-modal ref="confirmModal" id="bot-offers-confirm-modal"></confirm-modal>
    <div class="style_descr_g">
      <div class="container_w_sm_la">
        <h4 class="title_sf_site">Креативи офера {{ offerName }}</h4>
      </div>
      <p class="descr_site_shg mb_small">
        Завантажте набори креативів які будуть використовуватися для генерації PWA
      </p>
    </div>
    <loading v-model="loading" class="style_form_lab mt-4">
      <div class="container_w_sm_la">
        <form class="forms-sample position-relative trackers_form_g">
          <div class="mb-4">
            <button
              @click="handlerUpload"
              type="button"
              class="d_flex align_center justify_center button_upload_mr">
              <span class="icon_uploas_g small_ic_g"></span>
              <span>{{ $t('pwas.components.vertical.load') }}</span>
            </button>
          </div>
          <div class="block_seldctiosn_g mb-4">
            <div v-for="(images, index) in models" :key="index" class="item_Selcj_go"
                 :class="focus === index ? 'bg-warning' : ''">
              <label class="form-check-label sr-only" :for="`image-${index}`">
                <input
                  :value="index"
                  :id="`image-${index}`"
                  @input="focus = index"
                  type="radio"
                  class="form-check-input"
                  name="image">
              </label>
              <div class="owl-carousel owl-theme nonloop">
                <div v-for="(src, index2) in images" :key="index2" class="item">
                  <img :src="src | APIImage" alt="">
                </div>
              </div>
              <button
                v-if="focus === index"
                @click="handlerDelete(index)"
                type="button"
                class="close_log_g remove_icon">
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
                    fill="white"></path>
                </svg>
              </button>
            </div>
          </div>
          <div class="d-md-flex align-items-center mobile-grid-2 mt-4">
            <div class="mr-md-4 mb-4 mb-md-0">
              <button
                @click="$router.push({name: 'BotOffersCountries', params: $route.params})"
                type="button"
                class="ful_butt_green d_flex align_center justify_center mobile-full-w">{{ $t('general.next') }}
              </button>
            </div>
            <div class="mr-md-4 mb-4 mb-md-0">
              <button
                @click="$router.push({name: 'BotOffers'})" class="simple_butt_afg mobile-full-w">{{
                  $t('general.cancel')
                }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </loading>
  </div>
</template>
<script>
import offerMixin from './offer-mixins'
import BotOfferUploadModal from './_upload_modal.vue'
import {mapActions} from 'vuex'
import ConfirmModal from '@/components/modal/Confirm.vue'

export default {
  name: 'BotOffersImages',
  components: {BotOfferUploadModal, ConfirmModal},
  mixins: [offerMixin],
  data () {
    return {
      loading: true,
      models: [],
      focus: 0
    }
  },
  async created () {
    // eslint-disable-next-line no-undef
    jQuery.fn.andSelf = function () {
      return this.addBack.apply(this, arguments)
    }
    await this.getOffer(this.$route.params.id)
    this.models = this.offer.images
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  updated () {
    this.$nextTick(() => {
      this.initCarousel()
    })
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      update: 'bot/updateOffer',
      delete: 'bot/deleteImage'
    }),
    handlerPagination (page = 1) {
      this.page = page
    },
    initCarousel () {
      // eslint-disable-next-line no-undef
      const $nonloop = jQuery('.nonloop')
      if ($nonloop.length) {
        $nonloop.owlCarousel({
          items: 5,
          loop: false,
          margin: 10,
          autoplay: false
        })
      }
      const carousels = document.querySelectorAll('.owl-carousel')
      const clickOnCarousel = function () {
        this.parentNode.querySelector('.form-check-input').click()
      }
      for (const carousel of carousels) {
        carousel.addEventListener('click', clickOnCarousel)
      }
    },
    async handlerUpload () {
      const images = await this.$refs.botOfferUploadModal.upload()
      if (images !== false) {
        this.models = [images].concat(this.models)
        await this.update({...this.offer, images: this.models})
        await this.alertSuccess('Офер успішно оновлено')
        this.handlerPagination(1)
        this.$nextTick(() => {
          this.initCarousel()
        })
      }
    },
    async handlerDelete (index) {
      const ok = await this.$refs.confirmModal.show({
        title: 'Видалити набір картинок',
        message: 'Ви впевнені що хочете видалити набір картинок?',
        okButton: 'Видалити'
      })
      if (ok) {
        try {
          for (const image of this.models[index]) {
            await this.delete({id: this.$route.params.id, image: image.split('/').reverse()[0]})
          }
          this.models.splice(index, 1)
          await this.update({...this.offer, images: this.models})
          await this.alertSuccess('Набір картинок успішно видалено')
          this.focus = 0
        } catch (e) {
        }
      }
    }
  }
}
</script>
<style scoped>
.item_Selcj_go {
  position: relative;
}

.remove_icon {
  position: absolute;
  right: -35px;
  top: 37%;
  z-index: 2;
}
</style>
