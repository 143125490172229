<template>
  <div class="content-wrapper parent_tooltip PWAsSetting" v-if="accesses.pwas.update">
    <navigation />
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body pt-0 ">
            <div class="style_descr_g">
              <div class="container_w_sm_la">
                <h4 class="title_sf_site">{{ $t('pwas.components.setting.title', {id: $route.params.id}) }}</h4>
                <p class="descr_site_shg mb_small">{{ $t('pwas.components.setting.message') }}</p>
              </div>
            </div>
            <div v-if="!loading" class="style_form_lab mt-4 pwa-setting-container">
              <div class="container_w_sm_la">
                <form class="forms-sample" @submit.prevent="handleSave" @change="onChange">
                  <BaseFromGroup
                    id="tags"
                    :label="$t('pwas.components.setting.tags')"
                    :info-text="$t('pwas.components.setting.tagsInfo')"
                    :has-error="apiValidationErrors.tags"
                    :error-text="apiValidationErrors?.tags ? apiValidationErrors?.tags[0] : ''"
                  >
                    <BaseSelect
                      v-model="tags"
                      id="tags"
                      :multiple="true"
                      :taggable="true"
                      :placeholder="$t('pwas.components.setting.tags')"
                      :options="tags"
                      :create-option="option => option"
                      @input="onChange"
                    />
                  </BaseFromGroup>
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <BaseFromGroup
                        label="Pre-landing"
                        id="preLandingPage"
                        infoText="Pre-landing"
                        :has-error="apiValidationErrors.preLandingPage"
                        :error-text="apiValidationErrors?.preLandingPage ? apiValidationErrors?.preLandingPage[0] : ''"
                      >
                        <BaseSelect
                          v-model="preLandingPage"
                          id="preLandingPage"
                          placeholder="Choose Pre-landing page"
                          :options="preLandingPageOptions"
                          endpoint="/landing-pages/select2"
                          sort="id"
                        />
                      </BaseFromGroup>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <BaseFromGroup
                        label="Post-landing"
                        id="postLandingPage"
                        infoText="Post-landing"
                        :has-error="apiValidationErrors.postLandingPage"
                        :error-text="apiValidationErrors?.postLandingPage ? apiValidationErrors?.postLandingPage[0] : ''"
                      >
                        <BaseSelect
                          v-model="postLandingPage"
                          id="postLandingPage"
                          :options="postLandingPageOptions"
                          placeholder="Choose Post-landing page"
                          endpoint="/landing-pages/select2"
                          sort="id"
                        />
                      </BaseFromGroup>
                    </div>
                  </div>
                  <div class="form-group" :class="apiValidationErrors.alias ? 'has-danger' : ''">
                    <label for="alias">{{ $t('pwas.components.setting.alias') }}
                      <info :message="$t('pwas.components.setting.aliasInfo')"/>
                    </label>
                    <input type="text" id="alias" class="form-control select_white_g" name="alias" v-model="alias"
                           tabindex="1"
                           :class="apiValidationErrors.alias ? 'form-control-danger' : ''" placeholder="Apolon 23"
                           @focus="clearError('alias')"/>
                    <label v-if="apiValidationErrors.alias" class="error mt-2 text-danger" for="alias">
                      {{ apiValidationErrors.alias[0] }}
                    </label>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <p class="descr_site_shg" style="font-size: 14px" v-html="
                      $t('pwas.components.setting.installMessage', {
                          stepMin: step.min,
                          stepMax: step.max,
                          intervalMin: interval.min,
                          intervalMax: interval.max,
                          minInstallingTime: msTos(minInstallingTime),
                          maxInstallingTime: msTos(maxInstallingTime),
                          avgInstallingTime: msTos(avgInstallingTime)
                        })"></p>
                    </div>
                    <div class="form-group col-6 col-md-3"
                         :class="apiValidationErrors['setting.installing.ranges.step.min'] ? 'has-danger' : ''">
                      <label for="setting-installing-ranges-step-min">{{ $t('pwas.components.setting.minStep') }}
                        <info :message="$t('pwas.components.setting.minStepInfo')"/>
                      </label>
                      <input type="number" step="1" id="setting-installing-ranges-step-min" tabindex="2"
                             :class="apiValidationErrors['setting.installing.ranges.step.min'] ? 'form-control-danger' : ''"
                             class="form-control select_white_g" v-model="step.min"
                             placeholder="15" min="1" max="100"
                             @focus="clearError('setting.installing.ranges.step.min')"/>
                      <label v-if="apiValidationErrors['setting.installing.ranges.step.min']"
                             class="error mt-2 text-danger" for="setting-installing-ranges-step-min">
                        {{ apiValidationErrors['setting.installing.ranges.step.min'][0] }}
                      </label>
                    </div>
                    <div class="form-group col-6 col-md-3"
                         :class="apiValidationErrors['setting.installing.ranges.step.max'] ? 'has-danger' : ''">
                      <label for="setting-installing-ranges-step-max">{{ $t('pwas.components.setting.maxStep') }}
                        <info :message="$t('pwas.components.setting.maxStepInfo')"/>
                      </label>
                      <input type="number" step="1" id="setting-installing-ranges-step-max" tabindex="2"
                             :class="apiValidationErrors['setting.installing.ranges.step.max'] ? 'form-control-danger' : ''"
                             class="form-control select_white_g" v-model="step.max"
                             placeholder="20" min="1" max="100"
                             @focus="clearError('setting.installing.ranges.step.max')"/>
                      <label v-if="apiValidationErrors['setting.installing.ranges.step.max']"
                             class="error mt-2 text-danger" for="setting-installing-ranges-step-max">
                        {{ apiValidationErrors['setting.installing.ranges.step.max'][0] }}
                      </label>
                    </div>
                    <div class="form-group col-6 col-md-3"
                         :class="apiValidationErrors['setting.installing.ranges.interval.min'] ? 'has-danger' : ''">
                      <label for="setting-installing-ranges-interval-min">{{ $t('pwas.components.setting.minInterval') }}
                        <info :message="$t('pwas.components.setting.minIntervalInfo')"/>
                      </label>
                      <input type="number" step="100" id="setting-installing-ranges-interval-min" tabindex="4"
                             :class="apiValidationErrors['setting.installing.ranges.interval.min'] ? 'form-control-danger' : ''"
                             class="form-control select_white_g" v-model="interval.min"
                             placeholder="1500" min="100" max="99999"
                             @focus="clearError('setting.installing.ranges.interval.min')"/>
                      <label v-if="apiValidationErrors['setting.installing.ranges.interval.min']"
                             class="error mt-2 text-danger" for="setting-installing-ranges-interval-min">
                        {{ apiValidationErrors['setting.installing.ranges.interval.min'][0] }}
                      </label>
                    </div>
                    <div class="form-group col-6 col-md-3"
                         :class="apiValidationErrors['setting.installing.ranges.interval.max'] ? 'has-danger' : ''">
                      <label for="setting-installing-ranges-interval-max">{{ $t('pwas.components.setting.maxInterval') }}
                        <info :message="$t('pwas.components.setting.maxIntervalInfo')"/>
                      </label>
                      <input type="number" step="100" id="setting-installing-ranges-interval-max" tabindex="5"
                             :class="apiValidationErrors['setting.installing.ranges.interval.max'] ? 'form-control-danger' : ''"
                             class="form-control select_white_g" v-model="interval.max"
                             placeholder="1500" min="100" max="99999"
                             @focus="clearError('setting.installing.ranges.interval.max')"/>
                      <label v-if="apiValidationErrors['setting.installing.ranges.interval.max']"
                             class="error mt-2 text-danger" for="setting-installing-ranges-interval-max">
                        {{ apiValidationErrors['setting.installing.ranges.interval.max'][0] }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="customJs">{{ $t('pwas.components.setting.customJs') }}
                      <info :message="$t('pwas.components.setting.customJsInfo')"/>
                    </label>
                    <textarea id="customJs" rows="6" class="form-control" v-model="customJs" readonly></textarea>
                  </div>
                  <div class="d-md-flex align-items-center mt-sm-3 mobile-grid-2">
                    <div class="mr-md-4 mb-4 mb-md-0 one-column-mobile">
                      <button
                        type="submit"
                        class="ful_butt_green d_flex align_center justify_center mobile-full-w"
                      >
                        {{ $t('general.save') }}
                      </button>
                    </div>
                    <only-pwa-form />
                    <pwas-templates-template v-model="pwa"/>
                    <div class="mr-md-4 mb-4 mb-md-0 one-column-mobile">
                      <router-link :to="{name: 'PWAs'}" class="simple_butt_afg mobile-full-w">{{
                          $t('general.cancel')
                        }}
                      </router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <AnimePersonInChair />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import pwaMixin from './mixins/pwa-mixin'
import * as components from './components'
import {OnlyPWAForm, PwasTemplatesTemplate} from './templates'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import AnimePersonInChair from '@/components/animatedSvgs/PersonInChair.vue'
import confirmMixin from '@/mixins/confirm-leave-mixin'

const defaultFields = () => ({
  tags: [],
  alias: '',
  step: {
    min: 15,
    max: 20
  },
  interval: {
    min: 1500,
    max: 2000
  },
  preLandingPage: '',
  postLandingPage: '',
  customJs: ''
})

export default {
  name: 'PWAsSetting',
  components: {
    BaseSelect,
    BaseFromGroup,
    Select2,
    navigation: components.Navigation,
    Info,
    PwasTemplatesTemplate,
    'only-pwa-form': OnlyPWAForm,
    AnimePersonInChair
  },
  mixins: [formMixin, titleMixin, accessesMixin, pwaMixin, select2AjaxMixin, confirmMixin],
  title () {
    return this.$t('titles.pwa.setting', {id: this.$route.params.id})
  },
  data () {
    return {
      ...defaultFields(),
      loading: true,
      preLandingPageOptions: [],
      postLandingPageOptions: []
    }
  },
  async created () {
    await this.$store.dispatch('pwas/asyncSettingPage', this.$route.params.id)
    for (const [prop, value] of Object.entries(this.$store.getters['pwas/settingPage'])) {
      if (this[prop] != null) {
        this[prop] = value
      }
    }
    if (this.preLandingPage != null) {
      await this.$store.dispatch('landingPages/view', this.preLandingPage)
      const landingPage = this.$store.getters['landingPages/model']
      this.preLandingPageOptions.push({id: landingPage.id, text: landingPage.name})
    }
    if (this.postLandingPage != null) {
      await this.$store.dispatch('landingPages/view', this.postLandingPage)
      const landingPage = this.$store.getters['landingPages/model']
      this.postLandingPageOptions.push({id: landingPage.id, text: landingPage.name})
    }
    this.$nextTick(() => {
      this.loading = false
    })
  },
  computed: {
    landingSettings () {
      return {
        ...this.getSelect2AjaxSettings('landing-pages/select2', 'id'),
        allowClear: true
      }
    },
    minInstallingTime: function () {
      return this.step.min * this.interval.min
    },
    maxInstallingTime: function () {
      return this.step.max * this.interval.max
    },
    avgInstallingTime: function () {
      return (this.minInstallingTime + this.maxInstallingTime) / 2
    }
  },
  watch: {
    preLandingPage: {
      handler (oldValue, newValue) {
        if (newValue || (oldValue && newValue === null)) this.changedData = true
      },
      immediate: false
    },
    postLandingPage: {
      handler (oldValue, newValue) {
        if (newValue || (oldValue && newValue === null)) this.changedData = true
      },
      immediate: false
    }
  },
  methods: {
    msTos: value => (value / 1000).toFixed(0),
    handleSave: async function () {
      try {
        this.resetApiValidation()
        await this.$store.dispatch('pwas/updateSettings', {
          pwaId: this.$route.params.id,
          tags: this.tags,
          alias: this.alias,
          step: this.step,
          interval: this.interval,
          preLandingPage: this.preLandingPage,
          postLandingPage: this.postLandingPage
        })
        this.changedData = false
        this.resetApiValidation()
        await this.$store.dispatch(
          'alerts/success',
          this.$t('pwas.save')
        )
        this.changed = {}
        await this.$router.push({name: 'PWAs'})
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    onChange () {
      this.changedData = true
    }
  }
}
</script>

<style>
textarea#customJs {
  min-height: 86px;
  max-height: 250px;
}
.pwa-setting-container {
  position: relative;
  z-index: 4;
}
</style>
