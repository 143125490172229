import Texts from './Texts.vue'

export default (meta, header) => {
  return [
    {
      path: 'pwas/generator/texts',
      name: 'AdminPWAsTexts',
      components: {default: Texts, header},
      meta
    }
  ]
}
