<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-md-6">
        <gateways v-bind:merchantId="merchantId"/>
      </div>
      <div class="col-md-6">
        <payment-methods/>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentMethods from './components/PaymentMethods.vue'
import Gateways from './components/Gateways.vue'

export default {
  name: 'AdminBillingMerchantsGateways',
  components: {PaymentMethods, Gateways},
  computed: {
    merchantId: function () {
      return this.$route.params.id
    }
  }
}
</script>
