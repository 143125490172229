export default {
  menu: {
    keys: 'Keys',
    offers: 'Offers'
  },
  heads: {
    key: {label: 'Key', title: 'The key to activate the bot'},
    member: {label: 'Web master', title: 'Web master which uses this key to access'},
    date: {label: 'Date', title: 'Date of last key update'}
  },
  alert: {
    copy: 'Key {key} added to the clipboard',
    delete: 'Key <strong> {key} </strong> for web master <strong> {username} </strong> deleted'
  },
  confirm: {
    delete: {
      title: 'Remove the key',
      message: 'Do you really want to delete the key licenses <strong>{key}</strong> for the web master <strong>{username}</strong>?',
      button: 'Remove'
    }
  },
  latinAmerica: 'Latin America',
  america: 'America',
  europe: 'Europe',
  asia: 'Asia',
  australiaAndOceania: 'Australia and Oceania',
  addOffer: 'Add an offer'
}
