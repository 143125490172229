<template>
  <div class="FinanceTabs">
    <BaseRouterTabs :tabs="tabs" />
  </div>
</template>
<script>
import BaseRouterTabs from '@/components/base/BaseRouterTabs.vue'

export default {
  name: 'FinanceTabs',
  components: {BaseRouterTabs},
  data: function () {
    return {
      tabs: [
        {to: {name: 'Bills'}, label: 'finance.bills.title'},
        {to: {name: 'Transactions'}, label: 'finance.transactions.title'}
      ]
    }
  }
}
</script>
