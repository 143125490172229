import index from './index.vue'
import Create from './Create.vue'
import Update from './Update.vue'
import FormLayout from '@/pages/landing-pages/FormLayout.vue'

export default (meta, header) => {
  return [
    {
      path: 'landing-pages',
      name: 'LandingPages',
      components: {default: index, header},
      meta
    },
    {
      path: 'landing-page',
      name: 'LandingPagesForms',
      components: {default: FormLayout, header},
      meta,
      redirect: {name: 'LandingPages'},
      children: [
        {
          path: 'create',
          name: 'LandingPageCreate',
          components: {default: Create, header},
          meta
        },
        {
          path: ':id/update/:type',
          name: 'LandingPageUpdate',
          components: {default: Update, header},
          meta
        }
      ]
    }
  ]
}
