<template>
  <div class="PushTemplate page-full-fluid-width parent_tooltip">
    <div v-if="!loading" class="row has_big_rog">
      <div class="col-lg-7 item_pwa_shs_g grid-margin grid-margin-md-0 stretch-card mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body has_big_pt d-flex flex-column justify-content-between">
            <div>
              <div class="descr_ref_g">
                <h4 class="title_refer_sf">{{ $t('pushes.templates.form.formTitle') }}</h4>
                <p class="subtit_e_gray">
                  {{ $t('pushes.templates.form.message') }}
                </p>
              </div>
              <div class="style_form_lab">
                <div class="row mb-0">
                  <div class="form-group"
                    :class="{'has-danger':apiValidationErrors.name, 'col-sm-6': !user.memberId, 'col-12': user.memberId}">
                    <label for="name">{{ $t('pushes.templates.form.name.title') }}
                      <info :message="$t('pushes.templates.form.name.info')" />
                    </label>
                    <input type="text" class="form-control" id="name" name="name"
                      :placeholder="$t('pushes.templates.form.name.placeholder')" v-model="name"
                      @focus="clearError('name')" :class="apiValidationErrors.name ? 'form-control-danger' : ''">
                    <label v-if="apiValidationErrors.name" class="error mt-2 text-danger" for="name">
                      {{ apiValidationErrors.name[0] }}
                    </label>
                  </div>
                  <BaseFromGroup id="tags" class="col-sm-6" :label="$t('pushes.form.tags.title')"
                    :info-text="$t('pushes.form.tags.info')" :has-error="apiValidationErrors.tags"
                    :error-text="apiValidationErrors?.tags ? apiValidationErrors?.tags[0] : ''">
                    <BaseSelect v-model="tags" id="tags" :multiple="true" :taggable="true"
                      :placeholder="$t('pushes.form.tags.title')" :options="tags" :create-option="option => option" />
                  </BaseFromGroup>
                </div>
                <!-- <select2
                v-if="user.isAdmin"
                class="item_fds_ggo pr-1 pl-1"
                inputClass="form-control select_white_g"
                id="owner"
                name="owner"
                v-model="owner"
                :options="ownerOptions"
                :settings="ownerSettings"
                :placeholder="$t('templates.form.ownerPlaceholder')"
                >
                  <template v-slot:before>
                    <div class="sibmti_sdf text-muted">{{ $t('templates.form.owner') }}</div>
                  </template>
                  <template v-slot:after>
                    <label v-if="apiValidationErrors.owner" class="error mt-2 info_warning_gdg" for="owner">
                      {{ apiValidationErrors.owner[0] }}
                    </label>
                  </template>
                </select2> -->
                <hr class="mt-0">
                <div class="form-group" :class="apiValidationErrors.title ? 'has-danger' : ''">
                  <label for="title">{{ $t('pushes.templates.form.title.title') }}
                    <info :message="$t('pushes.templates.form.title.info')" />
                  </label>
                  <input type="text" class="form-control" id="title" name="title"
                    :placeholder="$t('pushes.templates.form.title.placeholder')" v-model="title"
                    @focus="clearError('title')" :class="apiValidationErrors.title ? 'form-control-danger' : ''">
                  <label v-if="apiValidationErrors.title" class="error mt-2 text-danger" for="title">
                    {{ apiValidationErrors.title[0] }}
                  </label>
                </div>
                <div class="form-group" :class="apiValidationErrors.body ? 'has-danger' : ''">
                  <label for="body">{{ $t('pushes.templates.form.body.title') }}
                    <info :message="$t('pushes.templates.form.body.info')" />
                  </label>
                  <textarea type="text" id="body" v-model="body" rows="3" cols="3"
                    class="form-control textarea_restyle_g" :placeholder="$t('pushes.templates.form.body.placeholder')"
                    :class="apiValidationErrors.body ? 'form-control-danger' : ''" @focus="clearError('body')">
                </textarea>
                  <label v-if="apiValidationErrors.body" class="error mt-2 text-danger" for="body">
                    {{ apiValidationErrors.body[0] }}
                  </label>
                </div>
                <div class="form-group" :class="apiValidationErrors['data.url'] ? 'has-danger' : ''">
                  <label for="url">{{ $t('pushes.templates.form.url.title') }}
                    <info :message="$t('pushes.templates.form.url.info')" />
                  </label>
                  <input type="text" class="form-control" id="url" name="url"
                    :placeholder="$t('pushes.templates.form.url.placeholder')" v-model="data.url"
                    @focus="clearError('data.url')"
                    :class="apiValidationErrors['data.url'] ? 'form-control-danger' : ''">
                  <label v-if="apiValidationErrors['data.url']" class="error mt-2 text-danger" for="url">
                    {{ apiValidationErrors['data.url'][0] }}
                  </label>
                </div>
                <div class="row">
                  <!-- <div class="form-group col-12 col-md-4">
                    <label>{{ $t('pushes.templates.form.badge.title') }}
                      <info :message="$t('pushes.templates.form.badge.info')"/>
                    </label>
                    <div class="avatar_upload_g">
                      <form enctype="multipart/form-data" id="badge">
                        <label class="upload_herE_verg file_upload_g d_flex align_center justify_center">
                          <input type="file" @change="handlerUpload('badge', $event)" @click="clearError('badge')" accept="image/png, image/jpeg">
                          <div class="icon_uploas_g"></div>
                          <div>{{ $t('pushes.templates.form.badge.placeholder') }}</div>
                        </label>
                      </form>
                    </div>
                    <label v-if="apiValidationErrors.badge" class="error mt-2 text-danger" for="badge">
                      {{ apiValidationErrors.badge[0] }}
                    </label>
                    <div v-if="badge != null && badge !== ''">
                      <div class="d_inline_block in_ts_avat">
                        <img :src="badge | APIImage" width="100" height="100">
                        <button class="close_log_g" @click="handlerDelete('badge')">
                          <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
                              fill="white"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div> -->
                  <div class="form-group col-12 col-md-6">
                    <label>{{ $t('pushes.templates.form.icon.title') }}
                      <info :message="$t('pushes.templates.form.icon.info')" />
                    </label>
                    <div class="avatar_upload_g">
                      <form enctype="multipart/form-data" id="icon">
                        <label class="upload_herE_verg file_upload_g d_flex align_center justify_center">
                          <input type="file" @change="handleImageCrop('icon', $event)" @click="clearError('icon')"
                            accept="image/png, image/jpeg">
                          <div class="icon_uploas_g"></div>
                          <div>{{ $t('pushes.templates.form.icon.placeholder') }}</div>
                        </label>
                      </form>
                    </div>
                    <label v-if="apiValidationErrors.icon" class="error mt-2 text-danger" for="icon">
                      {{ apiValidationErrors.icon[0] }}
                    </label>
                    <div v-if="icon != null && icon !== ''">
                      <div class="d_inline_block in_ts_avat">
                        <img :src="icon | APIImage">
                        <button class="close_log_g" @click="handlerDelete('icon')">
                          <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
                              fill="white"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label>{{ $t('pushes.templates.form.image.title') }}
                      <info :message="$t('pushes.templates.form.image.info')" />
                    </label>
                    <div class="avatar_upload_g">
                      <form enctype="multipart/form-data" id="image">
                        <label class="upload_herE_verg file_upload_g d_flex align_center justify_center">
                          <input type="file" @change="handleImageCrop('image', $event)" @click="clearError('image')"
                            accept="image/png, image/jpeg">
                          <div class="icon_uploas_g"></div>
                          <div>{{ $t('pushes.templates.form.image.placeholder') }}</div>
                        </label>
                      </form>
                    </div>
                    <label v-if="apiValidationErrors.image" class="error mt-2 text-danger" for="image">
                      {{ apiValidationErrors.image[0] }}
                    </label>
                    <div v-if="image != null && image !== ''">
                      <div class="d_inline_block in_ts_avat">
                        <img :src="image | APIImage">
                        <button class="close_log_g" @click="handlerDelete('image')">
                          <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
                              fill="white"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="mt-0">
                <div class="form-group">
                  <BaseFromGroup id="dir" :label="$t('pushes.form.dir.title')" :info-text="$t('pushes.form.dir.info')">
                    <BaseSelect v-model="dir" id="dir" :clearable="false" :options="dirOptions" />
                  </BaseFromGroup>
                </div>
                <div>
                  <div class="d-md-inline-block mr-md-4 mb-2">
                    <label class="labek_check_sg" for="renotify">
                      <input type="checkbox" class="form-check-input" id="renotify" name="renotify" v-model="renotify">
                      <div class="check_icd_G"></div>
                      <span class="txt_chek_xg">{{ $t('pushes.templates.form.renotify.title') }}</span>
                    </label>
                    <info :message="$t('pushes.templates.form.renotify.info')" />
                  </div>
                  <div class="d-md-inline-block mr-md-4 mb-2">
                    <label class="labek_check_sg" for="requireInteraction">
                      <input type="checkbox" class="form-check-input" id="requireInteraction" name="requireInteraction"
                        v-model="requireInteraction">
                      <div class="check_icd_G"></div>
                      <span class="txt_chek_xg">{{ $t('pushes.templates.form.requireInteraction.title') }}</span>
                    </label>
                    <info :message="$t('pushes.templates.form.requireInteraction.info')" />
                  </div>
                  <div class="d-md-inline-block mr-md-4 mb-2">
                    <label class="labek_check_sg" for="silent">
                      <input type="checkbox" class="form-check-input" id="silent" name="silent" v-model="silent">
                      <div class="check_icd_G"></div>
                      <span class="txt_chek_xg">{{ $t('pushes.templates.form.silent.title') }}</span>
                    </label>
                    <info :message="$t('pushes.templates.form.silent.info')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-md-flex align-items-center mobile-grid-2 mt-4">
              <div class="mr-md-4 mb-4 mb-md-0 one-column-mobile">
                <button @click="handlerSave()" type="button"
                  class="ful_butt_green d_flex align_center justify_center mobile-full-w">{{ $t('general.save') }}
                </button>
              </div>
              <div class="mr-md-4 mb-4 mb-md-0">
                <a class="simple_butt_afg mobile-full-w" href="javascript:void(0);" @click="handlerTemplate">{{
                  $t('pushes.templates.template.button') }}</a>
                <push-template-modal ref="pushTemplateModal" />
              </div>
              <div class="mr-md-4 mb-4 mb-md-0">
                <router-link :to="{name: 'PushTemplates'}" class="simple_butt_afg mobile-full-w">{{
                  $t('general.cancel')
    }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 item_pwa_shs_g  grid-margin grid-margin-md-0 stretch-card">
        <Preview dark>
          <div class="white_int_mock">
            <div class="preview_itmb_g">
              <div class="site_preview_push">
                <div class="title_preview_sit d_flex align_center">
                  <span class="icon_previsg">
                    <img :src="icon | APIImage" alt="" width="15">
                  </span>
                  <div class="arrow_toggle_dg transition d_flex align_center justify_center"
                    :class="{ 'active': previewPushesMore }" @click="previewPushesMore = !previewPushesMore">
                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 2V1H7V2H9Z"
                        fill="#000" />
                    </svg>
                  </div>
                  dat1.marketpoland.fun &#8226; now
                </div>
              </div>
              <div class="title_pushe">{{ titlePreview }}</div>
              <div :class="{ hidden: previewPushesMore }">
                <div class="descr_pushe">{{ bodyPreview }}</div>
                <div class="image_previe_g">
                  <img :src="image | APIImage" alt="">
                </div>
              </div>
            </div>
          </div>
        </Preview>
      </div>
    </div>
    <div v-else class="loading_cont_go">
      <div class="in_to_load_sf d_flex justify_center">
        <img src="/images/loader.svg" alt="" width="100">
      </div>
    </div>
    <ImageCropper v-if="imageToCrop" :image="imageToCrop" @close="imageToCrop = null" @imageCropped="handlerUpload" />
  </div>
</template>
<script>
import Spinner from 'node-spintax'
import titleMixin from '@/mixins/title-mixin'
import formMixin from '@/mixins/form-mixin'
import uploadMixin from '@/mixins/upload-mixin'
import Info from '@/components/Info.vue'
import PushTemplateModal from './_modal.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import Preview from '@/components/Preview.vue'

const defaultValues = () => ({
  name: '',
  owner: '',
  title: '',
  body: '',
  // badge: '',
  image: '',
  icon: '',
  dir: 'auto',
  renotify: true,
  requireInteraction: true,
  silent: false,
  data: { url: '' },
  tags: []
})

export default {
  name: 'PushTemplate',
  mixins: [titleMixin, formMixin, uploadMixin],
  components: {BaseSelect, BaseFromGroup, Info, PushTemplateModal, ImageCropper, Preview},
  title () {
    return this.id ? this.$t('titles.pushes.edit', {id: this.id}) : this.$t('titles.pushes.create')
  },
  data () {
    return {
      ...defaultValues(),
      loading: true,
      previewPushes: false,
      previewPushesMore: false,
      ownerSettings: {},
      ownerOptions: [],
      earningReportChart: null,
      imageToCrop: null,
      uploadImageType: 'icon'
    }
  },
  computed: {
    id () {
      return this.$route.params.id || null
    },
    user () {
      return this.$store.getters['identity']
    },
    titlePreview () {
      return new Spinner(this.title).unspinRandom(1)[0]
    },
    bodyPreview () {
      return new Spinner(this.body).unspinRandom(1)[0]
    },
    dirOptions () {
      return [ { id: 'auto', text: this.$t('pushes.form.dir.options[0]') },
        { id: 'ltr', text: this.$t('pushes.form.dir.options[1]') },
        { id: 'rtl', text: this.$t('pushes.form.dir.options[2]') }]
    }
  },
  async created () {
    if (this.id != null) {
      await this.$store.dispatch('pushTemplates/read', this.id)
      for (const [prop, value] of Object.entries(this.$store.getters['pushTemplates/model'])) {
        if (this[prop] != null) {
          this[prop] = value
        }
      }
    }
    // if (this.user.isAdmin) {
    //   this.ownerSettings = {...this.getSelect2AjaxSettings('users/filter'), allowClear: true}
    //   this.ownerOptions = await this.getSelect2AjaxOptions(this.owner, 'users/filter')
    // }
    this.$nextTick(() => {
      this.loading = false
    })
  },
  methods: {
    async handlerSave () {
      try {
        this.resetApiValidation()
        const payload = {
          name: this.name,
          tags: this.tags,
          title: this.title,
          body: this.body,
          image: this.image,
          // badge: this.badge,
          dir: this.dir,
          icon: this.icon,
          renotify: this.renotify,
          requireInteraction: this.requireInteraction,
          silent: this.silent,
          data: this.data
        }
        // if (this.user.isAdmin) {
        //   payload.owner = this.owner
        // }
        if (this.id) {
          await this.$store.dispatch('pushTemplates/update', {
            ...payload,
            id: this.id
          })
        } else {
          await this.$store.dispatch('pushTemplates/create', payload)
        }
        this.resetApiValidation()
        await this.$store.dispatch(
          'alerts/success',
          this.$t('pushes.templates.form.success')
        )
        await this.$router.push({name: 'PushTemplates'})
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    handleImageCrop (type, event) {
      const file = event.target.files[0]
      if (!file) return
      const validation = this.validateFile(file)
      if (!validation.isValid) {
        this.setApiValidation([{ param: type, msg: validation.errorMessage }])
        return
      }
      this.imageToCrop = file
      this.uploadImageType = type
      event.currentTarget.value = null
    },
    async handlerUpload (file) {
      await this.$store.dispatch('pushTemplates/upload', {
        type: this.uploadImageType,
        payload: file
      })
      this[this.uploadImageType] = this.$store.getters['pushTemplates/lastPath']
      document.getElementById(this.uploadImageType).reset()
    },
    async handlerDelete (type) {
      await this.$store.dispatch('pwasPushes/removeImage', {name: this[type], type})
      this[type] = ''
      document.getElementById(type).reset()
    },
    async handlerTemplate () {
      const source = await this.$refs.pushTemplateModal.handlerTemplate('pwasPushes')
      for (const [prop, value] of Object.entries(source)) {
        this[prop] = value
      }
    }
  }
}
</script>

<style scoped lang="sass">
.in_ts_avat img
  object-fit: contain
  max-height: 80px
  width: 100%
  max-width: 100%
</style>
