<template>
  <div class="col-12 grid-margin">
    <div class="card card-statistics">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="d-flex justify-content-between border-right card-statistics-item">
              <div>
                <h2>{{ monthAmount | amount }}</h2>
                <p class="text-muted mb-0">Сумма за месяц</p>
              </div>
              <i class="icon-refresh text-primary icon-lg"></i>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="d-flex justify-content-between border-right card-statistics-item">
              <div><h2>{{ countMonth }}</h2>
                <p class="text-muted mb-0">Количество созданных</p>
              </div>
              <i class="icon-wallet text-primary icon-lg"></i>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="d-flex justify-content-between border-right card-statistics-item">
              <div><h2>{{ countPaid }}</h2>
                <p class="text-muted mb-0">Количество оплаченных</p>
              </div>
              <i class="icon-credit-card text-primary icon-lg"></i>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="d-flex justify-content-between card-statistics-item">
              <div>
                <h2>{{ averageBill | amount }}</h2>
                <p class="text-muted mb-0">Средний счёт</p>
              </div>
              <i class="icon-pie-chart text-primary icon-lg"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  name: 'BillsStatistics',
  computed: {
    ...mapGetters({
      monthAmount: 'adminBilling/monthAmount',
      countMonth: 'adminBilling/countMonth',
      countPaid: 'adminBilling/countPaid'
    }),
    averageBill () {
      return this.monthAmount / this.countPaid || 0
    }
  }
}
</script>
