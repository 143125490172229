import service from '@/store/services/admin/transactions-service'
import middleware from '../middleware'

const state = {
  models: [],
  count: 0
}

const mutations = {
  setIndex: (state, payload) => {
    state.models = payload.models
    state.count = payload.count
  }
}

const actions = {
  index ({commit, dispatch}, params) {
    return service.index(params)
      .then(response => {
        commit('setIndex', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  delete ({commit, dispatch}, payload) {
    return service.delete(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  correction ({commit, dispatch}, payload) {
    return service.correction(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => {
    for (let item of state.models) {
      item.purpose = ''
      switch (item.type) {
        case 'pwa':
          item.purpose = `Оплата за PWA`
          break
        case 'domain':
          item.purpose = `Покупка домена`
          break
        case 'referral':
          item.purpose = `Рефералка`
          break
        case 'bill':
          item.purpose = `Пополнения баланса`
          break
        case 'tariff':
          item.purpose = `Подписка на тариф`
          break
        case 'install':
          item.purpose = `Оплата установки`
          break
        case 'correction':
          item.purpose = `Коррекция`
          break
        case 'reject':
          item.purpose = `Отмена сета`
          break
      }
    }
    return state.models
  },
  count: state => Number(state.count)
}

const adminTransactions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminTransactions
