import create from './create/uk'

/* eslint-disable no-template-curly-in-string */
export default {
  title: 'Дзеркала',
  headers: {
    domain: 'Домен Дзеркала',
    domainInfo: 'Ім\'я домену вашого Дзеркала',
    expired: 'Видалення',
    expiredInfo: 'Час до автоматичного, безповоротного видалення Дзеркала'
  },
  trash: {
    title: 'Кошик дзеркал',
    move: 'Перемістити в кошик',
    empty: 'Кошик порожній',
    moveMessage: 'Ви впевнені, що хочете перенести в кошик Дзеркало {name}? На відновлення у вас буде 2 дні',
    moveButton: 'В кошик',
    moveSuccess: 'Дзеркало успішно перенесено в кошик',
    restore: 'Відновити Дзеркало',
    restoreTitle: 'Відновити Дзеркало {name}?',
    restoreMessage: 'Ви впевнені, що хочете відновити Дзеркало {name}?',
    restoreButton: 'Відновити',
    restoreSuccess: 'Дзеркало успішно відновлено',
    goToMirrors: 'Перейти до Дзеркал'
  },
  createNew: 'Створити Дзеркало',
  pwaName: 'Назва PWA',
  pwaTitle: 'Назва вашого PWA',
  mirrorName: 'Назва',
  mirrorTitle: 'Назва вашого Дзеркала',
  status: 'Статус',
  statusTitle: 'Статус вашого Дзеркала',
  tags: 'Теги PWA',
  tagsTitle: 'Теги призначення PWA',
  trackerHead: 'Трекер',
  trackerTitle: 'Інформація про поточний трекер підключеного до Дзеркала',
  add: 'Додати',
  filters: {
    search: 'Фільтр за доменом',
    tag: 'Фільтр за тегом',
    addTag: 'Додати новий тег',
    status: 'Фільтр за статусом',
    vertical: 'Фільтр за вертикаллю',
    category: 'Фільтр за категорією',
    name: 'Фільтр за назвою',
    pwa: 'Фільтр за PWA'
  },
  buttons: {
    checkDomain: 'Перевірити домен',
    run: 'Запустити',
    stop: 'Зупинити',
    pay: 'Оплатити',
    preview: 'Перегляд',
    cloak: {
      buttonTitle: 'Завантажити JS скрипт',
      title: 'JS для використання Cloak',
      body: `Додайте цей JS код в кінці секції тега <body> перед тегом, що закривається </body>`
    },
    customJs: {
      buttonTitle: 'Вставте свій JS скрипт',
      title: 'Користувацький JS',
      body: 'Цей JS код буде додано до вашого Дзеркала',
      bulkBody: 'Цей JS код буде додано до ваших Дзеркала',
      success: 'Користувацький JS збережено'
    },
    meta: {
      buttonTitle: 'Вставте свій META tags',
      title: 'Користувацький META tags',
      body: 'Ці META tags буде додано до вашого Дзеркала',
      bulkBody: 'Ці META tags буде додано до ваших Дзеркала',
      success: 'Користувацький META tags збережено'
    }
  },
  checkDomain: {
    title: 'Перевірка домену',
    body: [
      'Ваш домен ще не перевірено. Перевірка домену може тривати до 2-х годин',
      'Домен <strong>{domain}</strong> не має протоколу https. Для коректної роботи увімкніть https Rewrite',
      'Домен <strong>{domain}</strong> перевірено',
      'Домен <strong>{domain}</strong> не обернений у <strong>CloudFlare</strong>'
    ]
  },
  delete: {
    title: 'Видалити Дзеркало',
    message: 'Ви впевнені, що хочете видалити Дзеркало {name}?',
    button: 'Видалити',
    success: 'Дзеркало видалено'
  },
  copy: {
    title: 'Копіювати Дзеркало',
    message: 'Ви впевнені, що хочете скопіювати Дзеркало {name}?',
    button: 'Копіювати',
    success: 'Дзеркало скопійовано',
    tracker: {
      message: 'Копіювати {name} з трекером чи без?',
      okButton: 'З трекером',
      cancelButton: 'Без трекера'
    }
  },
  paid: {
    title: 'Активація Дзеркала',
    warning: 'Після активації Дзеркала змінити домен неможливо!',
    button: 'Активувати',
    success: 'Дзеркало активовано',
    cancel: 'Активація скасована',
    error: 'Помилка активації',
    noTariff: 'Для роботи з Дзеркалом необхідно оформити тариф',
    paidTariff: `У вас є одна активація в тарифі. Після активації цього Дзеркала у вас не залишиться активацій. Активувати Дзеркало?
    | У вас є {count} активації в тарифі. Після активації цього Дзеркала у вас залишиться одна активація. Активувати Дзеркало?
    | У вас є {count} активацій в тарифі. Після активації цього Дзеркала у вас залишиться {countLeft} активації. Активувати Дзеркало?
    | У вас є {count} активацій в тарифі. Після активації цього Дзеркала у вас залишиться {countLeft} активацій. Активувати Дзеркало?`,
    paidBalance: 'У вас закінчилися активації в тарифі, ви можете активувати Дзеркало понад тарифа за ${amount}.',
    noMoney: 'У вас закінчилися активації в тарифі, ви можете активувати Дзеркало понад тарифа за ${amount}, або перейти на тариф вище. Для цього необхідно поповнити баланс.',
    paidInstalls: 'Активація в тарифі установок безкоштовна, але за кожну встановлення Дзеркала стягуватиметься плата'
  },
  play: {
    title: 'Запустити Дзеркало',
    message: 'Ви впевнені, що хочете запустити Дзеркало {name}?',
    button: 'Запустити',
    success: 'Дзеркало запущено'
  },
  stop: {
    title: 'Зупинити Дзеркало',
    message: 'Ви впевнені, що хочете зупинити Дзеркало {name}?',
    button: 'Зупинити',
    success: 'Дзеркало зупинено'
  },
  notFoundByFilters: 'Не знайдено Дзеркало, що відповідають умовам фільтрації',
  noHave: 'У Вас ще немає створених Дзеркал. Натисніть кнопку "Додати"',
  insufficientFunds: 'Недостатньо коштів',
  save: 'Дзеркало збережено',
  verticals: ['Нутра', 'Бетинг', 'Дейтинг', 'Гемблінг', 'Трейдинг', 'Крипта'],
  statuses: ['Заблокований', 'Перевірка домену', 'Перевірка HTTPs', 'Не оплачений', 'Зупинено', 'Працює', 'Технічна зупинка'],
  categories: [
    'Автомобілі та транспорт',
    'Бібліотеки та демоверсії',
    'Бізнес',
    'Відеоплеєри та редактори',
    'Мистецтво та дизайн',
    'Їжа та напої',
    'Ігри',
    'Інструменти',
    'Житло та будинок',
    'Здоров\'я та фітнес',
    'Знайомства',
    'Карти та навігація',
    'Книги',
    'Комікси',
    'Краса',
    'Материнство та дитинство',
    'Медицина',
    'Заходи',
    'Музика',
    'Новини та журнали',
    'Освіта',
    'Спосіб життя',
    'Спілкування',
    'Персоналізація',
    'Погода',
    'Покупки',
    'Подорожі',
    'Робота',
    'Розваги',
    'Соціальні',
    'Спорт',
    'Стиль життя',
    'Фінанси',
    'Світлина'
  ],
  create,
  group: {
    successRemove: 'Дзеркала успішно вилучені',
    successCopy: 'Дзеркала успішно скопійовані',
    successTrash: 'Дзеркала були успішно переміщені в кошик',
    successRestore: 'Дзеркала були успішно відновлені',
    successPlay: 'Дзеркала запущені',
    successStop: 'Дзеркала зупинені',
    successMeta: 'Користувацькі META tags збережено',
    successCustomJs: 'Користувацький JS збережено'
  }
}
