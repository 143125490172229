import PWAs from './PWAs.vue'
import Trash from './Trash.vue'
import Create from './Create.vue'
import Update from './Update.vue'
import Analytics from './analytics/index.vue'
import App from './App.vue'
import Comments from './Comments.vue'
import Vertical from './Vertical.vue'
import Setting from './Setting.vue'
import * as templates from './templates'
import * as pushes from '../pushes'
import trackerRouter from './tracker/router'
import autoSave from '@/middleware/auto-save'

export default (meta, header) => {
  return [
    ...templates.router(meta, header),
    ...pushes.router(meta, header),
    {
      path: 'pwas',
      name: 'PWAs',
      components: {default: PWAs, header: header},
      meta: meta
    },
    {
      path: 'pwas/trash',
      name: 'PWAsTrash',
      components: {default: Trash, header: header},
      meta: meta
    },
    {
      path: 'pwas/create',
      name: 'PWAsCreate',
      components: {default: Create, header: header},
      meta: meta
    },
    {
      path: 'pwas/:id',
      name: 'PWAsUpdate',
      components: {default: Update, header: header},
      meta: {...meta, before: autoSave}
    },
    {
      path: 'pwas/:id/vertical',
      name: 'PWAsVertical',
      components: {default: Vertical, header: header},
      meta: {...meta, before: autoSave}
    },
    {
      path: 'pwas/:id/app',
      name: 'PWAsApp',
      components: {default: App, header: header},
      meta: {...meta, before: autoSave}
    },
    {
      path: 'pwas/:id/app/:language',
      name: 'LPWAsApp',
      components: {default: App, header: header},
      meta: {...meta, before: autoSave}
    },
    {
      path: 'pwas/:id/comments',
      name: 'PWAsComments',
      components: {default: Comments, header: header},
      meta: {...meta, before: autoSave}
    },
    {
      path: 'pwas/:id/comments/:language',
      name: 'LPWAsComments',
      components: {default: Comments, header: header},
      meta: {...meta, before: autoSave}
    },
    ...trackerRouter(meta, header),
    {
      path: 'pwas/:id/analytics',
      name: 'PWAsAnalytics',
      components: {default: Analytics, header: header},
      meta: {...meta, before: autoSave}
    },
    {
      path: 'pwas/:id/setting',
      name: 'PWAsSetting',
      components: {default: Setting, header: header},
      meta: {...meta, before: autoSave}
    }
  ]
}
