<template>
  <div class="content-wrapper">
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div v-if="!loading" class="card-body">
            <h4 class="card-title">{{ title }}</h4>
            <p class="card-description">Форма для створення нової підписки на тариф для користувача @{{ user.username }}</p>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-dark">
                <li class="breadcrumb-item">
                  <router-link :to="{name: 'AdminUsers'}">Користувачі</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="{name: 'AdminUser', params: {id: $route.params.id}}">@{{ user.username }}</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="{name: 'AdminUserTariffs', params: {id: $route.params.id}}">Підписки @{{ user.username }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ title }}</li>
              </ol>
            </nav>
            <create-form
              :tariffs="tariffs"
              :vouchers="vouchers"
              @cancel="handlerCancel"
              @submit="handlerSave"
              />
          </div>
          <div v-else class="dot-opacity-loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import CreateForm from './_form.vue'

export default {
  name: 'AdminUserTariffsCreate',
  components: {CreateForm},
  data () {
    return {
      loading: true
    }
  },
  computed: {
    title () {
      return `Додати підписку`
    },
    ...mapGetters({
      user: 'adminUsers/user',
      tariffs: 'adminUserTariffs/tariffs',
      vouchers: 'adminVouchers/list'
    })
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  methods: {
    ...mapActions({
      getUser: 'adminUsers/read',
      getListTariffs: 'adminUserTariffs/getListTariffs',
      getVouchers: 'adminVouchers/list',
      createTariff: 'adminUserTariffs/createTariff'
    }),
    async asyncData () {
      this.loading = true
      await this.getUser(this.$route.params.id)
      await this.getListTariffs({owner: this.$route.params.id})
      await this.getVouchers()
      this.$nextTick(() => {
        this.loading = false
      })
    },
    handlerCancel (cancel) {
      if (cancel) {
        this.$router.push({name: 'AdminUserTariffs', params: {id: this.$route.params.id}})
      }
    },
    async handlerSave (payload) {
      try {
        await this.createTariff({ payload, owner: this.$route.params.id })
        await this.$router.push({name: 'AdminUserTariffs', params: {id: this.$route.params.id}})
      } catch ({message}) {
        alert(message)
      }
    }
  }
}
</script>
