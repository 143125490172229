import PageMirrors from './PageMirrors.vue'
import PageMirrorsTrash from './PageMirrorsTrash.vue'

export default (meta, header) => {
  return [
    {
      path: 'mirrors',
      name: 'Mirrors',
      components: {default: PageMirrors, header: header},
      meta: meta
    },
    {
      path: 'mirrors/trash',
      name: 'MirrorsTrash',
      components: {default: PageMirrorsTrash, header: header},
      meta: meta
    }
  ]
}
