<template>
  <modal class="ModalAddMeta" :id="'meta-modal-' + _uid" ref="modal">
    <div class="info_blk_g_mod text-center">
      <div class="icon_sinf_g">
        <img src="/images/icon_info.png" alt="">
      </div>
      <div class="title_inf_glbk" style="color: #21BF73;">{{ title }}</div>
      <div class="descr_inf_ico_g" style="white-space: normal;">
        {{ message }}
      </div>
      <div
        class="form-group mt-2 mb-0"
        :class="apiValidationErrors.meta ? 'has-danger' : ''"
      >
        <textarea
          :id="inputId"
          rows="10"
          class="form-control p-2"
          v-model="metaTextarea"
          @focus="clearError('meta')"
          @blur="validateMeta"
        >
        </textarea>
        <label v-if="apiValidationErrors.meta" class="error mt-2 text-danger" :for="inputId">
          {{ apiValidationErrors.meta[0] }}
        </label>
      </div>
    </div>
    <div class="foot_inf_sg_g">
      <div class="in_to_foot_br_g">
        <div class="row has_big_rog">
          <div class="col-6 item_pwa_shs_g">
            <button
              type="button"
              class="btn-clipboard border_butt_green d_flex align_center justify_center w-100"
              @click="handleSubmit"
            >
              {{ $t('general.save') }}
            </button>
          </div>
          <div class="col-6 item_pwa_shs_g">
            <a @click="handleCancel" class="ful_butt_green d_flex align_center justify_center">
              {{ $t('general.cancel') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'
import Modal from '@/components/modal/Modal.vue'

export default {
  name: 'ModalAddMeta',
  mixins: [formMixin],
  components: {Modal, Info},
  data () {
    return {
      id: '' || [],
      meta: [],
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
      title: undefined,
      message: undefined, // Main text content
      metaTextarea: '',
      action: undefined
    }
  },
  computed: {
    inputId () {
      return `meta-modal-input-${this._uid}`
    }
  },
  methods: {
    show (opts = {}) {
      this.title = opts.title
      this.message = opts.message
      this.id = opts.id
      this.meta = opts.meta
      this.action = opts.action
      this.fetchMetaFromServer()
      this.$refs.modal.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    fetchMetaFromServer () {
      if (this.meta) {
        const array = this.meta.map(({ name, content }) => `<meta name="${name}" content="${content}" />`)
        this.metaTextarea = array.join('')
      }
    },
    fetchMetaToServer () {
      const regex = /<meta(?:\s+\w+="[^"]+")*\s+name="([^"]+)"\s+content="([^"]+)"\s*\/?>/g
      const metaObjects = []
      let match
      while ((match = regex.exec(this.metaTextarea)) !== null) {
        metaObjects.push({
          name: match[1],
          content: match[2]
        })
      }
      this.meta = metaObjects
    },
    async handleSubmit () {
      try {
        this.resetApiValidation()
        const valid = await this.validateMeta()
        if (!valid) return
        if (this.metaTextarea.length) await this.fetchMetaToServer()
        if (Array.isArray(this.id)) {
          await this.action({
            ids: this.id,
            meta: this.meta
          })
        } else {
          await this.action({
            id: this.id,
            meta: this.meta
          })
        }
        this.resetApiValidation()
        this.$refs.modal.close()
        this.resolvePromise(true)
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    async handleCancel () {
      this.$refs.modal.close()
      this.resolvePromise(false)
      this.resetApiValidation()
    },
    validateMeta () {
      const regex = /<meta(?:\s+\w+="[^"]+")*\s+name="([^"]+)"\s+content="([^"]+)"\s*\/?>/g
      const isValid = regex.test(this.metaTextarea)
      if (!isValid) {
        this.setApiValidation([{
          param: 'meta',
          msg: this.$t('validations.invalid'),
          value: '',
          location: 'body'
        }])
        return false
      }
      return isValid
    }
  }
}
</script>
