<template>
  <div class="d-flex align-items-end mb-4">
    <div class="flex-grow-1">
      <div class="form-group mb-0"
           :class="apiValidationErrors[`s2sPostback.${event}.url`] ? 'has-danger' : ''">
        <label for="postback.url">{{
            $t(`pwas.tracker.s2sPostback.${event}.label`, {enable: this.value.enable ? $t('trackers.form.active') : $t('trackers.form.notActive')})
          }}
          <info
            :message="$t(`pwas.tracker.s2sPostback.${event}.info`)"/>
        </label>
        <input type="text" id="postback.url" placeholder="https://exampla.com/" v-model="url" class="form-control"
               :class="apiValidationErrors[`s2sPostback.${event}.url`] ? 'form-control-danger' : ''"
               @focus="clearError(`s2sPostback.${event}.url`)">
        <label v-if="apiValidationErrors[`s2sPostback.${event}.url`]" class="error mt-2 text-danger"
               for="postback.url">
          {{ apiValidationErrors[`s2sPostback.${event}.url`][0] }}
        </label>
      </div>
    </div>
    <event-form
      v-model="value"
      :apiValidationErrors="apiValidationErrors"
      :event="event"
      @save="handlerSave"/>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import EventForm from './EventForm.vue'

export default {
  name: 'EventInput',
  components: {Info, EventForm},
  props: {
    value: {
      type: Object,
      required: true
    },
    apiValidationErrors: {
      type: Object,
      required: true
    },
    event: {
      type: String,
      required: true
    }
  },
  computed: {
    url: {
      get () {
        return this.value.url
      },
      set (input) {
        this.value.url = input
        this.$emit('input', this.value)
      }
    }
  },
  methods: {
    clearError (attribute) {
      if (this.apiValidationErrors[attribute] !== undefined) {
        this.apiValidationErrors[attribute] = undefined
      }
    },
    handlerSave () {
      this.$emit('save', true)
    }
  }
}
</script>
