export default {
  all: 'All',
  add: 'Add employee',
  deleteEmployee: 'Are you sure you want to delete the user?',
  employeeDeleted: 'Entry deleted',
  employeeSaved: 'Entry saved',
  accessData: 'Employee access data',
  link: 'Employee link:',
  password: 'Password:',
  name: 'Employee name',
  groups: 'Select or create a group',
  privacySettings: 'Privacy settings',
  isPWAsOwner: 'Own PWAs only',
  customPWATag: 'Custom tag for PWA',
  isCompaniesOwner: 'Only own PUSH',
  customCompaniesTag: 'Custom tag for PUSH',
  isLandingsOwner: 'Only own Landings',
  customLandingsTag: 'Custom tag for Landings',
  isTrackersOwner: 'Own trackers only',
  customTrackerTag: 'Custom tracker tag',
  accessSettings: 'Access Settings',
  view: 'View',
  edit: 'Change',
  delete: 'Delete',
  tracker: 'Tracker',
  statistics: 'Statistics',
  landings: 'Landings',
  teams: 'Teamwork',
  finance: 'Tariffs and finances',
  logs: {
    title: 'Logging',
    country: 'Country',
    device: 'Device',
    os: 'OS',
    browser: 'Browser',
    login: 'Login date'
  },
  validator: {
    username: {
      maxLength: 'The username must be less than 30 characters'
    },
    access: {
      isOneAccess: 'The employee must have at least one permit'
    }
  },
  trash: {
    delete: 'Delete employee',
    deleteMessage: 'Are you sure you want to delete the employee {username}?',
    deleteButton: 'Delete'
  }
}
