<template>
  <div class="card-body">
    <h4 class="card-title">Ключі Tg ботів</h4>
    <p class="card-description">Керування всіма ключами для ботів в системі</p>
    <div class="mb-2">
      <bot-key-form ref="botKeyForm"/>
      <button
        :disabled="!filter.owner"
        @click="handlerCreate"
        type="button" class="btn btn-outline-primary btn-fw">
        <i class="icon-plus"></i> Додати ключі
      </button>
    </div>
    <div class="row">
      <owner-filter v-model="filter.owner" class="col-md-6 mb-2"/>
      <div class="col-md-6 mb-2">
        <input v-model="filter.key" class="form-control" placeholder="Пошук за ключем"/>
      </div>
    </div>
    <div class="table-responsive">
      <table v-if="!loading" class="table table-bordered table-hover">
        <thead>
        <tr>
          <th>
            <button
              @click="fetchPage()"
              class="btn btn-icons btn-inverse-light">
              <i class="icon-refresh"></i>
            </button>
          </th>
          <th>Ключ</th>
          <th style="min-width: 220px;">Владелец</th>
          <th>Веб</th>
          <th>Створено</th>
          <th>Оновлено</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="model in models" :key="model.id">
          <td>
            <button @click="handlerDelete(model)" class="btn btn-icons btn-inverse-danger">
              <i class="icon-trash"></i>
            </button>
          </td>
          <td>{{ model.id }}</td>
          <td>
            <table-owner :data="model.owner"/>
          </td>
          <td v-html="$options.filters.formatNull(model.member ? model.member.username : null)"></td>
          <td>{{ model.createdAt | dateFromNow }}</td>
          <td>{{ model.updatedAt | dateFromNow }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="2">Всього: <span style="font-weight: bold;">{{ count }}</span></td>
        </tr>
        </tfoot>
      </table>
      <div v-else class="dot-opacity-loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav v-if="pageCount > 1">
        <paginate v-model="page"
                  :page-count="pageCount"
                  :click-handler="handlerPagination"
                  :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                  :next-text="'<i class=\'icon-arrow-right\'></i>'"
                  container-class="pagination mt-3"
                  page-class="page-item"
                  page-link-class="page-link"
                  prev-class="page-item"
                  next-class="page-item"
                  prev-link-class="page-link"
                  next-link-class="page-link"
                  active-class="active"/>
      </nav>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import Loading from '@/pages/admin/components/Loading.vue'
import {mapActions, mapGetters} from 'vuex'
import {OwnerFilter} from '../components'
import * as adminComponents from '@/pages/admin/components'
import BotKeyForm from '@/components/admin/bot/BotKeyForm.vue'

export default {
  name: 'AdminBotKeys',
  components: {OwnerFilter, Loading, TableOwner: adminComponents.tables.Owner, BotKeyForm},
  data () {
    return {
      loading: true,
      filter: {
        owner: '',
        key: ''
      },
      page: 1,
      limit: 20,
      sort: '-createdAt'
    }
  },
  computed: {
    ...mapGetters({
      models: 'bot/models',
      count: 'bot/count'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  created () {
    for (const prop in this.filter) {
      this.$watch(`filter.${prop}`, function () {
        this.handlerPagination()
      })
    }
    this.handlerPagination()
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      alertError: 'alerts/error',
      fetchData: 'bot/adminKeysFetchData',
      delete: 'bot/adminDeleteKey'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        page: this.page,
        limit: this.limit,
        sort: this.sort,
        filter: this.filter
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    async handlerDelete (model) {
      if (confirm('Видалити ключ?')) {
        try {
          await this.delete(model.id)
          await this.alertSuccess('Ключ успішно видалено')
          this.fetchPage()
        } catch (e) {
          await this.alertError(e.message)
        }
      }
    },
    async handlerCreate () {
      const ok = await this.$refs.botKeyForm.handlerOpen(this.filter.owner)
      if (ok) {
        await this.alertSuccess('Ключі успішно сгенеровано')
        this.handlerPagination()
      }
    }
  }
}
</script>
