<template>
  <div class="wrapper-table no-hover parent_tooltip">
    <div class="table-responsive">
      <confirm-modal ref="confirmModal" id="bot-offers-confirm-modal"></confirm-modal>
      <loading v-model="loading" tag="table" class="table_border_style table">
        <thead>
        <tr>
          <th></th>
          <th>
            Вкл./Викл.
            <info message="Доступність відображення офера в боті"/>
          </th>
          <th>
            PWA
            <info message="Інформація про PWA для генерації PWA"/>
          </th>
          <th>
            Вертикать
            <info message="Інформація про PWA для генерації PWA"/>
          </th>
          <th>
            Шаблон трекер
            <info message="Шаблон трекер для генерації PWA"/>
          </th>
          <th>
            ГЕО
            <info message="Регіони на з якими дозволено створювати трекер для PWA"/>
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="model of models" :key="model.id">
          <td class="thumb_usegb">
            <img
              v-if="model.images.length > 0"
              :src="model.images[0][0] | APIImage"
              :alt="model.name">
          </td>
          <td class="name_tbs_g">
            {{ model.enable | formatBoolean }}
          </td>
          <td class="name_tbs_g">
            {{ model.pwa.name }}
          </td>
          <td class="name_tbs_g">
            {{ getVerticalName(model.pwa.vertical) }}
          </td>
          <td class="name_tbs_g">
            {{ model.tracker.template.name }}
          </td>
          <td class="name_tbs_g">
            {{ getCountriesLabel(model.countries) }}
          </td>
          <td class="actiosn_ahgg text-right">
            <div class="flex_actions_ghg d_flex justify_end">
              <div class="item_butt_act_g" data-toggle="tooltip" data-original-title="Перейти до редагування офер">
                <router-link class="d_flex align_center justify_center btn_icon_rounded update_btn_icon"
                             :to="{name: 'BotOffersUpdate', params: {id: model.id} }">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.5 4.43001C15.5006 4.33131 15.4817 4.23346 15.4443 4.14208C15.407 4.0507 15.352 3.96759 15.2825 3.89751L12.1025 0.717513C12.0324 0.648001 11.9493 0.593008 11.8579 0.555684C11.7666 0.518361 11.6687 0.499442 11.57 0.500013C11.4713 0.499442 11.3735 0.518361 11.2821 0.555684C11.1907 0.593008 11.1076 0.648001 11.0375 0.717513L8.91501 2.84001L0.717513 11.0375C0.648001 11.1076 0.593008 11.1907 0.555684 11.2821C0.518361 11.3735 0.499442 11.4713 0.500013 11.57V14.75C0.500013 14.9489 0.57903 15.1397 0.719682 15.2803C0.860335 15.421 1.0511 15.5 1.25001 15.5H4.43001C4.53496 15.5057 4.63993 15.4893 4.73813 15.4518C4.83632 15.4144 4.92555 15.3567 5.00001 15.2825L13.1525 7.08501L15.2825 5.00001C15.351 4.92732 15.4067 4.84366 15.4475 4.75251C15.4547 4.69273 15.4547 4.63229 15.4475 4.57251C15.451 4.5376 15.451 4.50242 15.4475 4.46751L15.5 4.43001ZM4.12251 14H2.00001V11.8775L9.44751 4.43001L11.57 6.55251L4.12251 14ZM12.6275 5.49501L10.505 3.37251L11.57 2.31501L13.685 4.43001L12.6275 5.49501Z"
                      fill="white"/>
                  </svg>
                </router-link>
              </div>
              <div class="item_butt_act_g">
                <button
                  @click="handlerDelete(model)"
                  type="button" class="d_flex align_center justify_center btn_icon_rounded remove_butt_g"
                  data-toggle="tooltip" data-original-title="Видалити офер">
                  <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
                      fill="white"/>
                  </svg>
                </button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </loading>
      <nav v-if="pageCount > 1">
        <paginate ref="paginate"
                  v-model="page"
                  :page-count="pageCount"
                  :click-handler="handlerPagination"
                  :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                  :next-text="'<i class=\'icon-arrow-right\'></i>'"
                  :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                  :page-class="'page_item_gd'"
                  :page-link-class="'page_link_g d_flex justify_center align_center'"
                  :prev-class="'page-item_go'"
                  :next-class="'page-item_go'"
                  :prev-link-class="'page-link_go d_flex justify_center align_center'"
                  :next-link-class="'page-link_go d_flex justify_center align_center'"
                  :active-class="'active'">
        </paginate>
      </nav>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import Loading from '@/components/Loading.vue'
import titleMixin from '@/mixins/title-mixin'
import ConfirmModal from '@/components/modal/Confirm.vue'
import Info from '@/components/Info.vue'
import tooltipMixin from '@/mixins/tooltip-mixin'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'BotOffers',
  title () {
    return this.$t('titles.bot.offers')
  },
  mixins: [titleMixin, tooltipMixin],
  components: {Loading, ConfirmModal, Info},
  data () {
    return {
      loading: false,
      page: 1,
      limit: 20,
      sort: '-createdAt'
    }
  },
  computed: {
    ...mapGetters({
      models: 'bot/models',
      count: 'bot/count',
      verticals: 'verticals/verticals'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  created () {
    this.handlerPagination()
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      fetchData: 'bot/offersFetchData',
      delete: 'bot/deleteOffer'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
      this.initTooltip()
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        page: this.page,
        limit: this.limit,
        sort: this.sort
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    async handlerDelete (model) {
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('bot.confirm.delete.title'),
        message: this.$t('bot.confirm.delete.message', {key: model.id, username: model.username}),
        okButton: this.$t('bot.confirm.delete.button')
      })
      if (ok) {
        try {
          await this.delete(model.id)
          await this.alertSuccess(this.$t('bot.alert.delete', {key: model.id, username: model.username}))
          this.fetchPage()
        } catch (e) {
          await this.alertError(e.message)
        }
      }
    },
    getVerticalName (id) {
      const index = this.verticals.findIndex(vertical => +vertical.id === +id)
      if (index === -1) return null
      return this.$t(this.verticals[index].label)
    },
    getCountriesLabel (countries) {
      let label = []
      for (const [region, value] of Object.entries(countries)) {
        if (value.length > 0) {
          label.push(this.$t(`bot.${region}`) + ` (${value.length})`)
        }
      }
      return label.join(', ')
    }
  }
}
</script>
