import analytics from './analytics/uk'
import app from './app/uk'
import comment from './comment/uk'
import domain from './domain/uk'
import setting from './setting/uk'
import tracker from './tracker/uk'
import vertical from './vertical/uk'

export default {
  translate: {
    title: 'Переклад PWA<span class="url_ti_soig">{id}</span>',
    commentTitle: 'Переклад коментарів для PWA<span class="url_ti_soig">{id}</span>',
    from: 'Мова, яку перекладаємо',
    to: 'Мова, на яку перекладаємо',
    translate: 'Перекласти',
    backToRu: 'Повернути RU',
    delete: 'Видалити переклад',
    default: 'За замовчуванням',
    newApp: 'Створення нової локалізації<span class="url_ti_soig">{id}</span>',
    newComments: 'Створення перекладу коментарів для «{title}»<span class="url_ti_soig">Ця дія створить точну копію з коментарів <strong>«за замовчуванням»</strong> і переведе їх на <strong>{langTo} мову. Важливо пам\'ятати</strong>, що всі коментарі на різних мовах <strong>не залежні між собою</strong>! Зміни коментарів «за замовчуванням» не будуть перенесені на перекладені копії</span>',
    process: '<small>Іде копіювання та переклад коментарів, не закривайте форму!</small><small>Процес: {count}/{total}</small>',
    success: 'Копіювання та переклад успішно завершено',
    returnSuccess: 'Написи успішно скинуті'
  },
  google: {
    delete: 'Видалити аналітику?',
    settings: {
      title: 'Налаштування Google Conversion',
      code: 'Ярлик конверсії',
      value: 'Цінність',
      currency: 'Валюта',
      currencyInfo: 'Валюта цінності, 3 великі літери(наприклад USD)'
    },
    events: ['Встановлення', 'Відкриття', 'PUSH підписка', 'Реєстрація', 'Депозит']
  },
  facebook: {
    title: 'Налаштування Facebook Pixel',
    label: 'Потрібен для надсилання s2s подій у Facebook',
    pixelInfo: 'Вставте ваш Facebook піксель. Ми автоматично відстежуємо дію "Лід". Лідом є відкриття Pwa',
    lead: 'Що рахувати Lead?',
    leadInfo: 'Вибір події Lead',
    dontUse: 'Не використовувати',
    events: ['Встановлення', 'Відкриття', 'PUSH підписка', 'Реєстрація', 'Депозит'],
    delete: 'Видалити піксель?',
    deleteMessage: 'Ви впевнені, що хочете видалити піксель {id}?',
    deleteButton: 'Видалити',
    noPixel: 'Спочатку введіть піксель',
    multiForm: {
      title: 'Групове додавання FB pixel',
      message: 'Додайте пікселі рядково у форматі <strong>pixel:lead</strong>, де <strong>pixel</strong> - це ваші FB pixel\'лі, а <strong>lead</strong> - події ліда яка може приймати значення <strong>install</strong> або <strong>registration</strong>',
      label: 'Пікселі по рядках',
      errors: [
        'Введіть піксель та подію',
        'На рядку {errorLine} помилка! Не вказано піксель',
        'На рядку {errorLine} помилка! Не вказано подію для ліда',
        'На рядку {errorLine} помилка! Неправильно вказано події для ліда'
      ]
    }
  },
  tt: {
    dontUse: 'Не використовувати',
    events: ['Встановлення', 'Відкриття', 'PUSH підписка', 'Реєстрація s2s', 'Депозит s2s'],
    title: 'Налаштування TikTok Pixel',
    label: 'Потрібен для надсилання s2s подій у TikTok',
    delete: 'Видалити піксель?',
    noPixel: 'Спочатку введіть піксель'
  },
  generator: {
    title: 'Генерація контенту',
    langFrom: 'Основна мова',
    mode: {
      title: 'Режими генерації',
      random: 'Рандомні тексти',
      googlePlay: 'Google play',
      template: 'З шаблону'
    },
    texts: 'Текст',
    textsInfo: 'Генерувати текст',
    comments: 'Коментарі',
    commentsInfo: 'Генерувати коментарі',
    media: 'Картинки',
    mediaInfo: 'Генерувати картинки',
    button: 'Згенерувати',
    confirm: 'Ці дії замінять всі тексти та коментарі, ви впевнені, що хочете продовжити?',
    failed: 'Генерація провалилася, повторіть спробу',
    success: 'Тексти для PWA успішно згенеровані',
    pwaGenerateStart: '<small>Іде підготовка до генерації, не закривайте форму!</small><small>Процес: {count}/{total}</small>',
    pwaGenerateBeginDownload: '<small>Завантаження сторінки Google Play <strong>({lang})</strong>, не закривайте форму!</small><small>Процес: {count}/{total}</small>',
    pwaGenerateFinishDownload: '<small>Обробка сторінки Google Play завершена <strong>({lang})</strong>, не закривайте форму!</small><small>Процес: {count}/{total}</small>',
    pwaGenerateBeginText: '<small>Генерації тексту <strong>({lang})</strong>, не закривайте форму!</small><small>Процес: {count}/{total}</small>',
    pwaGenerateFinishText: '<small>Генерація тексту завершена <strong>({lang})</strong>, не закривайте форму!</small><small>Процес: {count}/{total}</small>',
    pwaGenerateBeginComment: '<small>Генерація коментарів <strong>({lang})</strong>, не закривайте форму!</small><small>Процес: {count}/{total}</small>',
    pwaGenerateFinishComment: '<small>Генерація коментарів завершена <strong>({lang})</strong>, не закривайте форму!</small><small>Процес: {count}/{total}</small>',
    pwaGenerateBeginTemplate: '<small>Обробка шаблону <strong>({lang})</strong>, не закривайте форму!</small><small>Процес: {count}/{total}</small>',
    pwaGenerateFinishTemplate: '<small>Обробка шаблону завершена <strong>({lang})</strong>, не закривайте форму!</small><small>Процес: {count}/{total}</small>'
  },
  analytics,
  app,
  comment,
  domain,
  setting,
  tracker,
  vertical
}
