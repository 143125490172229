<template>
  <div class="additional_serv">
    <div class="title_adit">{{ $t('tariffs.historyTitle') }}</div>
    <div class="blk_adit_g clearfix">
      <div class="item_additional_gg" v-for="tariff of tariffs" :key="tariff.id">
        <div class="in_to_item_Aditt d_flex align_center justify_between">
          <div class="left_tit_adidd flex_grow_1">
            <div class="totlt_adidd_g">{{
                new Date(tariff.expires) > new Date() ? $options.filters.dateFromNow(tariff.expires) : $options.filters.date(tariff.expires)
              }}
            </div>
          </div>
          <div class="right_tit_adidd d_flex align_center flex_grow_0">
            <div class="price_adigg_g">{{ tariff.total | amount }}</div>
            <div class="butt_pay_gghhd_g">
              <div class="d_inline_block">
                <a href="javascript:void(0);" @click="show(tariff)"
                   class="border_butt_green d_flex align_center justify_center">{{ $t('tariffs.info') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal modal_center_g modal_tariff_go fade" id="history-modal" tabindex="-1">
      <div class="modal-dialog big_modal_w" role="document">
        <div class="modal-content modal_padd_normal" v-if="tariff">
          <div class="style_descr_go">
            <div class="modal_title_g">{{ $t('tariffs.beforeDate', {date: $options.filters.date(tariff.expires)}) }}</div>
            <div class="methods_payment_g">
              <p class="bigger_p_go">{{ $t('tariffs.contained', {name: tariff.tariff.name[$i18n.locale]}) }}</p>
              <ul class="list_green">
                <li>{{ $tc('tariffs.pwas', tariff.tariff.pwas, {num: tariff.tariff.pwas}) }}</li>
                <li>{{ $t('tariffs.limit', {num: tariff.limits}) }}</li>
                <li>{{ tariff.tariff.description[$i18n.locale] }}</li>
              </ul>
              <p class="bigger_p_go">{{ $t('tariffs.forStart') }}</p>
            <p v-html="$t('tariffs.messages[0]')"></p>
            <p class="descr_gray_ggg">{{ $t('tariffs.messages[1]') }}</p>
            </div>
          </div>
          <div class="foot_payment_to_go">
            <div class="d_flex align_center dj_djghjd_g clearfix">
              <div class="item_jwejweffew">
                <div class="tit_soghs">{{ $t('tariffs.paid') }}</div>
                <div class="final_paym_jsg_G">{{ tariff.total | amount }}
                </div>
              </div>
              <div class="item_jwejweffew">
                <div class="in_topshgdjf_g d_flex align_center justify_end">
                  <a href="javascript:void(0);" class="w_full border_butt_green d_flex align_center justify_center"
                     @click="hide">{{ $t('general.cancel') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav v-if="pageCount > 1">
      <paginate ref="paginate"
                v-model="paginate.page"
                :page-count="pageCount"
                :click-handler="handlerPaginate"
                :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                :next-text="'<i class=\'icon-arrow-right\'></i>'"
                :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                :page-class="'page_item_gd'"
                :page-link-class="'page_link_g d_flex justify_center align_center'"
                :prev-class="'page-item_go'"
                :next-class="'page-item_go'"
                :prev-link-class="'page-link_go d_flex justify_center align_center'"
                :next-link-class="'page-link_go d_flex justify_center align_center'"
                :active-class="'active'">
      </paginate>
    </nav>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'History',
  data: () => ({
    tariffs: [],
    tariff: false,
    paginate: {
      sort: '-expires',
      limit: 10,
      page: 1
    },
    loading: false
  }),
  watch: {
    async 'paginate.page' (n) {
      await this.handlerAsync()
    }
  },
  async created () {
    this.tariffs = this.$store.getters['tariffs/history']
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.paginate.limit)
    },
    count () {
      return this.$store.getters['tariffs/count']
    }
  },
  methods: {
    show: function (tariff = false) {
      this.tariff = tariff
      if (this.tariff) {
        // eslint-disable-next-line no-undef
        jQuery('#history-modal').modal('show')
      }
    },
    hide: function () {
      this.tariff = false
      // eslint-disable-next-line no-undef
      jQuery('#history-modal').modal('hide')
    },
    asyncData: _.debounce(async function () {
      this.loading = true
      try {
        await this.$store.dispatch('tariffs/index', this.paginate)
        this.tariffs = this.$store.getters['tariffs/history']
        this.count = this.$store.getters['tariffs/count']
      } catch (e) {
        this.tariffs = []
        this.count = 0
        await this.$store.dispatch('alerts/error', e.response.data.errors[0].msg)
      }
      this.$nextTick(() => {
        this.loading = false
      })
    }, 500),
    async handlerPaginate (page) {
      this.paginate.page = page
      await this.handlerAsync()
    },
    async handlerAsync () {
      if (!this.loading) {
        await this.asyncData()
      }
    }
  }
}
</script>
