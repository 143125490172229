<template>
  <modal
    ref="modal"
    id="link-settings-form"
    class="fade LinkSettingForm"
    classContent="modal-content" classDialog="modal-dialog"
  >
    <div class="title_mod_uplo">{{ title }}</div>
    <div class="link_ths_ig">{{ redirect }}</div>
    <div class="tabs_ling_sg" v-if="open">
      <Tabs :tabs="tabs" v-model="activeTab" small />
      <div class="tab-content" v-if="open">
        <div id="tab_link_1" v-if="activeTab === 0">
          <div class="content_tba_jg style_form_lab pt-3">
            <div class="style_form_lab">
              <div class="form-group" :class="apiValidationErrors.redirect ? 'has-danger' : ''">
                <label for="link-redirect">{{ $t('trackers.linkSettings.redirect') }}</label>
                <input class="form-control" id="link-redirect" name="link-redirect" type="text" tabindex="1"
                       v-model="redirect"
                       :class="apiValidationErrors.redirect ? 'form-control-danger' : ''"
                       readonly="readonly"
                       @focus="clearError('redirect')" autofocus>
                <label v-if="apiValidationErrors.redirect" id="redirect-error" class="error mt-2 text-danger"
                       for="link-redirect">{{ apiValidationErrors.redirect[0] }}</label>
              </div>
              <postback-field v-model="template"/>
              <div v-if="target !== null && locale != null" class="form-group"
                   :class="apiValidationErrors.locale ? 'has-danger' : ''">
                <label for="locale">{{ $t('trackers.linkSettings.locale') }}
                  <info class="button_inf_sg mb-1"
                        :message="$t('trackers.linkSettings.localeInfo')"/>
                </label>
                <select class="form-control" id="locale" name="locale" type="text" tabindex="2"
                        v-model="locale"
                        :class="apiValidationErrors.locale ? 'form-control-danger' : ''"
                        @focus="clearError('locale')"
                        autofocus>
                  <option v-bind:value="code" v-for="(name,code) of locales"
                          v-bind:key="code">{{ $t(name) }}
                  </option>
                </select>
                <label v-if="apiValidationErrors.locale" id="locale-error"
                       class="error mt-2 text-danger"
                       for="locale">{{ apiValidationErrors.locale[0] }}</label>
              </div>
              <div v-if="target !== null && whitePageSource !== ''" class="form-group">
                <div class="checkbox_item_g">
                  <label class="labek_check_sg" for="link-notAndroidIsBot">
                    <input type="checkbox" class="form-qcheck-input" id="link-notAndroidIsBot"
                           name="link-notAndroidIsBot"
                           v-model="notAndroidIsBot">
                    <div class="check_icd_G"></div>
                    <span class="txt_chek_xg">{{ $t('trackers.linkSettings.android') }}</span>
                    <span class="txt_chek_xg"></span>
                  </label>
                  <info
                    :message="$t('trackers.linkSettings.androidInfo')"/>
                </div>
              </div>
              <BaseFromGroup
                v-if="target !== null && whitePageSource === 'generatePage'"
                :label="$t('trackers.linkSettings.whiteLang')"
                :info-text="$t('trackers.linkSettings.whiteLangInfo')"
                id="link-clLang"
                name="link-clLang"
                :has-error="apiValidationErrors.clLang"
                :error-text="apiValidationErrors?.clLang ? apiValidationErrors?.clLang[0] : ''"
              >
                <BaseSelect
                  v-model="clLang"
                  id="link-clLang"
                  name="link-clLang"
                  :options="whitePageLanguages"
                  :clearable="false"
                />
              </BaseFromGroup>
              <div v-if="target !== null && whitePageSource === 'generatePage'" class="form-group"
                   :class="apiValidationErrors.clUrl ? 'has-danger' : ''">
                <label for="link-clUrl">{{ $t('trackers.linkSettings.clUrl') }}
                  <info :message="$t('trackers.linkSettings.appInfo')"/>
                </label>
                <div class="d_flex">
                  <div class="flex_grow_1">
                    <input type="text" id="link-clUrl" v-model="clUrl"
                           class="form-control"
                           required
                           :class="apiValidationErrors.clUrl ? 'form-control-danger' : ''"
                           @focus="clearError('clUrl')">
                  </div>
                  <div class="flex_grow_0 ml_10">
                    <button
                      @click="handlerGenWP"
                      type="button"
                      class="border_butt_green d_flex align_center justify_center"
                      :disabled="processing"
                    ><span v-if="!processing">{{ $t('trackers.wp.generate') }}</span>
                      <hollow-dots-spinner
                        v-if="processing"
                        :animation-duration="1000"
                        :dot-size="15"
                        :dots-num="3"
                        :color="'#ff1d5e'"
                      />
                    </button>
                  </div>
                </div>
                <label v-if="apiValidationErrors.clUrl" class="error mt-2 text-danger" for="link-clUrl">
                  {{ apiValidationErrors.clUrl[0] }}
                </label>
                <label
                  v-if="!apiValidationErrors.clUrl && wpExists"
                  class="mt-2 text-success"
                  for="link-clUrl">{{ $t('trackers.wp.success') }}</label>
                <label
                  v-if="!apiValidationErrors.clUrl && !wpExists"
                  class="mt-2 text-warning"
                  for="link-clUrl">{{ $t('trackers.wp.failed') }}</label>
              </div>
              <div v-if="target !== null && whitePageSource === 'generatePage'" class="form-group"
                   :class="apiValidationErrors.clFooter ? 'has-danger' : ''">
                <label for="link-clFooter">{{ $t('trackers.linkSettings.footer') }}
                  <info :message="$t('trackers.linkSettings.footerInfo')"/>
                </label>
                <textarea id="link-clFooter" v-model="clFooter" rows="5"
                          class="form-control"
                          :class="apiValidationErrors.clFooter ? 'form-control-danger' : ''"
                          @focus="clearError('clFooter')">
                    </textarea>
                <label v-if="apiValidationErrors.clFooter" class="error mt-2 text-danger" for="link-clFooter">
                  {{ apiValidationErrors.clFooter[0] }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div id="tab_link_2" v-if="activeTab === 1">
          <div class="content_tba_jg style_form_lab pt-3">
            <split-testing-form
              v-if="open"
              :errors="errors"
              v-model="splitTestingLinks">
              <div class="col-sm-6 item_group_label_g">
                <label class="labek_check_sg" for="useSplitTesting">
                  <input type="checkbox" class="form-check-input" name="useSplitTesting" id="useSplitTesting"
                         v-model="useSplitTesting">
                  <div class="check_icd_G"></div>
                  <span class="txt_chek_xg">{{ $t('pwas.tracker.splitTesting.use') }}
                          <info
                            :message="$t('pwas.tracker.splitTesting.useInfo')"/>
                          </span>
                </label>
              </div>
            </split-testing-form>
          </div>
        </div>
      </div>
      <div class="footer_modl_akdhg">
        <div class="form-group">
          <div class="in_topshgdjf_g d_flex align_center">
            <div class="item_avt_butt_jg">
              <button
                @click="handlerSave"
                type="button" class="ful_butt_green d_flex align_center justify_center">
                {{ $t('general.save') }}
              </button>
            </div>
            <div class="item_avt_butt_jg">
              <a href="javascript:void(0);" @click="handlerCancel"
                 class="border_butt_green d_flex align_center justify_center">{{ $t('general.cancel') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import {whitePageLanguages, locales} from '@pwa.group/pwa.dictionary'
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'
import trackerMixin from '@/mixins/tracker-mixin'
import Select2 from '@/components/Select2.vue'
import {HollowDotsSpinner} from 'epic-spinners'
import mainConnector from '@/connectors/main.connector'
import Modal from '@/components/modal/Modal.vue'
import PostbackField from './PostbackField.vue'
import SplitTestingForm from './split-testing/SplitTestingForm.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import Tabs from '@/components/Tabs.vue'
// eslint-disable-next-line no-unused-vars,no-undef
const clipboard = new Clipboard('.btn-clipboard')

const defaultFields = () => ({
  redirect: '',
  template: null,
  whitePageSource: '',
  clLang: '',
  clUrl: '',
  clFooter: '',
  notAndroidIsBot: false,
  locale: null,
  useSplitTesting: false,
  splitTestingLinks: []
})

export default {
  name: 'LinkSettingForm',
  mixins: [formMixin, trackerMixin],
  components: {Tabs, BaseSelect, BaseFromGroup, Info, Select2, HollowDotsSpinner, Modal, PostbackField, SplitTestingForm},
  props: {
    errors: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ...defaultFields(),
      target: null,
      processing: false,
      locales,
      whitePageLanguages: whitePageLanguages.map(lang => ({
        id: lang.id,
        text: this.$t(lang.text)
      })),
      open: false,
      saveMode: null,
      activeTab: 0
    }
  },
  computed: {
    wpExists () {
      return this.$store.getters['pwas/trackerPage'].checkedWP[this.$parent.filters[this.target]._id.split('_')[1].toLowerCase().trim()]
    },
    templates () {
      return this.$store.getters['pwas/trackerPage'].templates
    },
    title () {
      if (this.target != null) {
        const [locale, country] = this.$parent.filters[this.target]._id.split('_')
        return this.$t('trackers.linkSettings.link', {
          locale: this.$t(`dictionary.locales.${locale}`),
          country: this.$t(`dictionary.countries.${country.toLowerCase()}`)
        })
      } else {
        return this.$t('trackers.linkSettings.title')
      }
    },
    tabs () {
      const list = [this.$t('trackers.linkSettings.postback')]
      if (this.saveMode !== 'trafficBack') list.push('Split testing')
      return list
    }
  },
  watch: {
    errors: {
      handler (n) {
        if (Object.keys(n).length > 0) {
          if (this.target != null) {
            const errors = {}
            for (const [prop, value] of Object.entries(n)) {
              const param = prop.split(`filters[${this.target}].`)
              if (param.length > 1) {
                errors[param[1]] = value
              }
            }
            this.apiValidationErrors = errors
          } else {
            this.apiValidationErrors = n
          }
        }
      },
      deep: true
    },
    template (n, o) {
      if (this.redirect !== '') {
        let url = this.redirect
        let oldParams = ''
        let newParams = ''
        let index = this.templates.findIndex(value => value._id === o)
        if (index > -1) {
          oldParams = this.templates[index].params
        }
        index = this.templates.findIndex(value => value._id === n)
        if (index !== -1) {
          const template = this.templates[index]
          newParams = template.params
        }
        url = this.applyTemplate(url, oldParams, true)
        url = this.applyTemplate(url, newParams)
        this.redirect = url
      }
    }
  },
  methods: {
    skip () {
      this.saveMode = null
      this.target = null
      this.processing = false
      this.resetApiValidation()
      const values = Object.entries(defaultFields())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    handlerTracker ({redirect, template, useSplitTesting, splitTestingLinks, whitePageSource}) {
      this.skip()
      this.redirect = redirect
      this.template = template
      this.whitePageSource = whitePageSource
      this.useSplitTesting = useSplitTesting
      this.splitTestingLinks = splitTestingLinks
      this.handlerOpen()
    },
    handlerTrafficBack ({bindPrevButton, bindPrevTemplate}) {
      this.skip()
      this.redirect = bindPrevButton
      this.template = bindPrevTemplate
      this.saveMode = 'trafficBack'
      this.handlerOpen()
    },
    handlerLink ({
      target,
      redirect,
      template,
      whitePageSource,
      clLang,
      clUrl,
      clFooter,
      notAndroidIsBot,
      locale,
      useSplitTesting,
      splitTestingLinks
    }) {
      this.skip()
      this.target = target
      this.redirect = redirect
      this.template = template || ''
      this.whitePageSource = whitePageSource
      this.clLang = clLang || ''
      this.clUrl = clUrl || ''
      this.clFooter = clFooter || ''
      this.notAndroidIsBot = notAndroidIsBot
      this.locale = locale
      this.useSplitTesting = useSplitTesting || false
      this.splitTestingLinks = splitTestingLinks || []
      this.handlerOpen()
    },
    async handlerGenWP () {
      this.processing = true
      try {
        this.resetApiValidation()
        const data = await mainConnector.post(`white-pages`, {
          target: this.$parent.filters[this.target]._id.split('_')[1].toLowerCase().trim(),
          clUrl: this.clUrl,
          clLang: this.clLang,
          clFooter: this.clFooter,
          tracker: this.$store.getters['pwas/trackerPage'].tracker._id,
          pwa: this.$route.params.id
        })
        const checkedWP = {...this.$store.getters['pwas/trackerPage'].checkedWP, ...data}
        await this.$store.dispatch('pwas/setCheckedWP', checkedWP)
        this.resetApiValidation()
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
      this.$nextTick(() => {
        this.processing = false
      })
    },
    async handlerSave () {
      let data = {target: this.target}
      if (this.target != null) {
        for (const [prop] of Object.entries(defaultFields())) {
          data[prop] = this[prop]
        }
      } else {
        data.redirect = this.redirect
        data.template = this.template
        data.useSplitTesting = this.useSplitTesting
        data.splitTestingLinks = this.splitTestingLinks
      }
      if (this.saveMode === 'trafficBack') {
        data = {
          target: null,
          bindPrevButton: this.redirect,
          bindPrevTemplate: this.template
        }
      }
      const success = await this.$parent.handlerSave(data)
      if (success) {
        this.handlerCancel()
      }
    },
    handlerOpen () {
      this.$nextTick(() =>
        this.$refs.modal.open())
      this.open = true
      setTimeout(() => {
        this.initSelect2()
      }, 500)
    },
    handlerCancel () {
      this.skip()
      this.resetApiValidation()
      this.destroySelect2()
      this.open = false
      this.$refs.modal.close()
    },
    destroySelect2 () {
      // eslint-disable-next-line no-undef
      if (jQuery('#locale').hasClass('select2-hidden-accessible')) {
        // eslint-disable-next-line no-undef
        jQuery('#locale').select2('destroy')
      }
    },
    initSelect2 () {
      const self = this
      const format = state => {
        if (!state.id) {
          return state.text
        }
        return `${state.id.toUpperCase()} - ${state.text}`
      }
      const select2Options = {
        placeholder: this.$t('trackers.linkSettings.locale'),
        templateResult: format,
        templateSelection: format,
        escapeMarkup: function (m) {
          return m
        }
      }
      // eslint-disable-next-line no-undef
      jQuery('#locale').select2(select2Options).on('select2:select', e => {
        self.locale = e.params.data.id
      })
    }
  }
}
</script>

<style scoped>
.footer_modl_akdhg {
  bottom: 0;
  padding-bottom: 15px;
  margin-bottom: -15px;
}
</style>
