<template>
  <div class="SelectFilter item_silter_sjhf_g">
    <BaseSelect
      :placeholder="placeholder"
      @input="onInput"
      :value="value"
      :options="endpoint"
    >
    </BaseSelect>
  </div>
</template>
<script>
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  name: 'SelectFilter',
  components: { BaseSelect },
  props: ['value', 'endpoint', 'label', 'id', 'disabled'],
  computed: {
    placeholder () {
      const self = this
      return this.$t(`analytics.filtersLabel.${self.id.split('-')[0]}`, {param: self.id.split('-')[1]})
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>
