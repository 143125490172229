import create from './create/ru'
import components from './components/ru'
import tracker from './tracker/ru'

/* eslint-disable no-template-curly-in-string */
export default {
  headers: {
    domain: 'Домен',
    domainInfo: 'Доменное имя вашего PWA',
    expired: 'Удаления',
    expiredInfo: 'Время до автоматического, безвозвратного удаления PWA'
  },
  trash: {
    move: 'Переместить в корзину',
    empty: 'Корзина PWA пустая',
    moveMessage: 'Вы уверены, что хотите перенести в корзину PWA {name}? На восстановление у вас будет 2 дня',
    moveMessageRelation: 'Вы уверены, что хотите перенести в корзину PWA {name}? На восстановление у вас будет 2 дня. Это PWA также будет удалено из Топ игр!',
    moveButton: 'В корзину',
    moveSuccess: 'PWA успешно перенесен в корзину',
    restore: 'Восстановить PWA',
    restoreTitle: 'Восстановить PWA {name}?',
    restoreMessage: 'Вы уверены, что хотите восстановить PWA {name}?',
    restoreButton: 'Восстановить',
    restoreSuccess: 'PWA успешно восстановлено',
    goToPWA: 'Перейти к PWA'
  },
  createNew: 'Создать PWA',
  pwaTitle: 'Название вашего PWA',
  status: 'Статус',
  statusTitle: 'Статус вашего PWA',
  tags: 'Теги',
  tagsTitle: 'Теги назначение PWA',
  trackerHead: 'Трекер',
  trackerTitle: 'Информация о текущем трекере подключенному к PWA',
  filters: {
    search: 'Поиск по домену',
    tag: 'Фильтр по тегу',
    addTag: 'Добавить новый тег',
    status: 'Фильтр по статусу',
    vertical: 'Фильтр по вертикали',
    category: 'Фильтр по категории'
  },
  buttons: {
    checkDomain: 'Проверить домен',
    run: 'Запустить',
    stop: 'Остановить',
    pay: 'Оплатить',
    preview: 'Предпросмотр',
    cloak: {
      buttonTitle: 'Скачать JS скрипт',
      title: 'JS для использования Cloak',
      body: `Добавьте этот JS код в конце секции тега <body> перед закрывающимся тегом </body>`
    },
    customJs: {
      buttonTitle: 'Вставьте свой JS скрипт',
      title: 'Пользовательский JS',
      body: 'Этот JS код будет добавлен в ваш PWA',
      bulkBody: 'Этот JS код будет добавлен в ваши PWA',
      success: 'Пользовательский JS сохранен'
    },
    meta: {
      buttonTitle: 'Вставьте свои META tags',
      title: 'Пользовательский META tags',
      body: 'Эти META tags будут добавлены на ваш PWA',
      bulkBody: 'Эти META tags будут добавлены на ваши PWA',
      success: 'Пользовательский META tags сохранены'
    }
  },
  checkDomain: {
    title: 'Проверка домена',
    body: [
      'Ваш домен ещё не проверен. Проверка домена может занимать до 2-ух часов',
      'Домен <strong>{domain}</strong> не имеет https протокола. Для корректной работы включите https Rewrite',
      'Домен <strong>{domain}</strong> проверен',
      'Домен <strong>{domain}</strong> не обернут в <strong>CloudFlare</strong>'
    ]
  },
  delete: {
    title: 'Удалить PWA',
    message: 'Вы уверены что хотите удалить PWA {name}?',
    button: 'Удалить',
    success: 'PWA удалено'
  },
  copy: {
    title: 'Копировать PWA',
    message: 'Вы уверены что хотите скопировать PWA {name}?',
    button: 'Копировать',
    success: 'PWA скопировано',
    tracker: {
      message: 'Копировать {name} c трекером или без?',
      okButton: 'C трекером',
      cancelButton: 'Без трекера'
    }
  },
  paid: {
    title: 'Активация PWA',
    warning: 'После активации PWA изменить домен невозможно!',
    button: 'Активировать',
    success: 'PWA активировано',
    cancel: 'Активация отменена',
    error: 'Ошибка активации',
    noTariff: 'Для работы с PWA необходимо оформить тариф',
    paidTariff: `У вас есть одна активация в тарифе. После активации PWA у вас не останется активаций. Активировать PWA?
    | У вас есть {count} активации в тарифе. После активации этого PWA у вас остается одна активация. Активировать PWA?
    | У вас есть {count} активаций в тарифе. После активации этого PWA у вас остается {countLeft} активации. Активировать PWA?
    | У вас есть {count} активаций в тарифе. После активации этого PWA у вас остается {countLeft} активаций. Активировать PWA?`,
    paidBalance: 'У вас закончились активации в тарифе, вы можете активировать PWA сверх тарифа за ${amount}.',
    noMoney: 'У вас закончились активации в тарифе, вы можете активировать PWA сверх тарифа за ${amount}, или перейти на тариф выше. Для этого необходимо пополнить баланс.',
    paidInstalls: 'Активация в тарифе установок бесплатная, но за каждую установку PWA будет взиматься плата'
  },
  play: {
    title: 'Запустить PWA',
    message: 'Вы уверены что хотите запустить PWA {name}?',
    button: 'Запустить',
    success: 'PWA запущен'
  },
  stop: {
    title: 'Остановить PWA',
    message: 'Вы уверены что хотите остановить PWA {name}?',
    button: 'Остановить',
    success: 'PWA остановлен'
  },
  notFoundByFilters: 'Не найдены PWA удовлетворяющие условиям фильтрации',
  noHave: 'У Вас ещё нет созданных PWA. Нажмите кнопку "Создать PWA"',
  insufficientFunds: 'Не достаточно средств',
  save: 'PWA сохранено',
  verticals: ['Нутра', 'Бетинг', 'Дейтинг', 'Гемблинг', 'Трейдинг', 'Крипта'],
  statuses: ['Заблокирован', 'Проверка домена', 'Проверка HTTPs', 'Не оплачен', 'Остановлен', 'Работает', 'Техническая остановка'],
  categories: [
    'Автомобили и транспорт',
    'Библиотеки и демоверсии',
    'Бизнес',
    'Видеоплееры и редакторы',
    'Искусство и дизайн',
    'Еда и напитки',
    'Игры',
    'Инструменты',
    'Жилье и дом',
    'Здоровье и фитнес',
    'Знакомства',
    'Карты и навигация',
    'Книги',
    'Комиксы',
    'Красота',
    'Материнство и детство',
    'Медицина',
    'Мероприятия',
    'Музыка',
    'Новости и журналы',
    'Образование',
    'Образ жизни',
    'Общение',
    'Персонализация',
    'Погода',
    'Покупки',
    'Путешествия',
    'Работа',
    'Развлечения',
    'Социальные',
    'Спорт',
    'Стиль жизни',
    'Финансы',
    'Фотография'
  ],
  create,
  components,
  tracker,
  group: {
    successRemove: 'PWA были успешно удалены',
    successCopy: 'PWA успешно скопированы',
    successTrash: 'PWA были успешно перемещены в корзину',
    successRestore: 'PWA были успешно восстановлены',
    successPlay: 'PWA запущены',
    successStop: 'PWA остановлены',
    successMeta: 'Пользовательский META tags сохранены',
    successCustomJs: 'Пользовательский JS сохранен'
  },
  pushPlacement: {
    pageIsLoaded: 'При загрузке страницы',
    trafficBackIsTriggered: 'При срабатывании трафик бек',
    afterInstall: 'После установки',
    afterOpen: 'После открытия PWA'
  },
  pushPlacementTooltips: {
    pageIsLoaded: 'При загрузке страницы',
    trafficBackIsTriggered: 'При срабатывании трафик бек',
    afterInstall: 'После установки',
    afterOpen: 'После открытия PWA'
  }
}
