<template>
  <modal
    ref="modal"
    id="correction-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <select2
        ref="select2"
        class="form-group"
        inputClass="form-control select_white_g"
        id="owner"
        name="owner"
        v-model="owner"
        placeholder="Пользователь"
        :options="ownerOptions"
        :settings="ownerSettings"
      >
        <template v-slot:before>
          <div class="sibmti_sdf">Пользователь</div>
        </template>
        <template v-slot:after>
          <label v-if="apiValidationErrors.owner" class="error mt-2 info_warning_gdg" for="owner">
            {{ apiValidationErrors.owner[0] }}
          </label>
        </template>
      </select2>
      <div class="form-group" :class="apiValidationErrors.amount ? 'has-danger' : ''">
        <label for="amount">Сумма</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">0.00</span>
          </div>
          <input type="number" step="0.01" id="amount" placeholder="10" v-model="amount"
                 class="form-control" min="1" max="10000"
                 :class="apiValidationErrors.amount ? 'form-control-danger' : ''"
                 @focus="clearError('amount')">
        </div>
        <label v-if="apiValidationErrors.amount" class="error mt-2 text-danger" for="amount">
          {{ apiValidationErrors.amount[0] }}
        </label>
      </div>
      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label" for="enablePwas">
            <input
              v-model="enablePwas"
              id="enablePwas"
              type="checkbox"
              class="form-check-input">
            Активувати технічно зупинені PWA
            <i class="input-helper"></i>
          </label>
        </div>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import formMixin from '@/mixins/form-mixin'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'

const defaultValues = () => ({
  amount: null,
  owner: null,
  enablePwas: false
})

export default {
  name: 'CorrectionForm',
  mixins: [formMixin, select2AjaxMixin],
  components: {Modal, SubmitModalForm, Select2},
  data () {
    return {
      processing: false,
      ...defaultValues(),
      ownerSettings: {},
      ownerOptions: [],
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  async created () {
    this.ownerSettings = {...this.getSelect2AjaxSettings('users/filter'), allowClear: true}
  },
  computed: {
    title () {
      return 'Коррекция'
    }
  },
  methods: {
    async handlerCreate () {
      this.skip()
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        const payload = {
          amount: this.amount,
          owner: this.owner,
          enablePwas: this.enablePwas
        }
        await this.$store.dispatch('adminTransactions/correction', payload)
        this.resetApiValidation()
        this.resolvePromise(true)
        await this.$store.dispatch('alerts/success', 'Коррекция успешно создана')
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
      this.processing = false
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
