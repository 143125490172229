import axios from 'axios'
import middleware from '@/store/modules/middleware'

const state = {
  data: [],
  count: 0,
  filterValues: []
}

const mutations = {
  setIndex: (state, payload) => {
    state.data = payload.data
    state.count = payload.count
    state.filterValues = payload.filterValues
  }
}

const actions = {
  index: ({commit, dispatch}, {
    url,
    secret,
    ...payload
  }) => axios.get(url + '/links?' + new URLSearchParams(payload).toString(), {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Auth': secret
    }
  })
    .then(res => commit('setIndex', res.data))
    .catch(error => {
      throw error
    }),
  create: ({commit, dispatch}, {url, secret, ...payload}) => axios.post(url + '/links', payload, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Auth': secret
    }
  })
    .then(response => response.data)
    .catch(error => {
      throw error
    }),
  update: ({commit, dispatch}, {url, secret, id, ...payload}) => axios.patch(url + '/links/' + id, payload, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Auth': secret
    }
  })
    .then(response => response.data)
    .catch(error => {
      throw error
    }),
  async delete (cxt, {url, secret, id}) {
    try {
      await axios.delete(url + '/links/' + id, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Auth': secret
        }
      })
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  data: state => state.data,
  count: state => Number(state.count),
  filterValues: state => state.filterValues
}

const adminAds = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminAds
