<template>
  <div class="content-wrapper">
    <modal
      id="admin-postback-logs-modal"
      ref="modal"
      class="modal fade"
      classDialog="modal-dialog"
      classContent="modal-content"
    >
      <div class="modal-header">
        <h5 class="modal-title">Детальна інформація</h5>
        <button type="button" class="close" @click="handlerCancel">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="modalInfo">Дані в форматі JSON</label>
          <div class="input-group-append">
            <textarea id="modalInfo" rows="6" v-model="modalInfo" class="form-control"/>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info btn-clipboard mr-2" data-clipboard-action="copy"
                data-clipboard-target="#modalInfo"><i class="icon-docs"></i> Копировать
        </button>
        <button @click="handlerCancel" class="btn btn-danger btn-wf"><i class="icon-shield"></i> Отмена</button>
      </div>
    </modal>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">API лог постбеків</h4>
            <p class="card-description">Доступний тільки для перегляда</p>
            <div class="mb-2">
              <button @click="asyncData" type="button" class="btn btn-inverse-light btn-fw">
                <i class="icon-refresh"></i> Оновить
              </button>
              <button @click="skipFilter()" type="button" class="btn btn-inverse-info btn-fw">
                <i class="icon-fire"></i> Скинуть фільтр
              </button>
            </div>
            <postback-logs-filter v-if="!loading" @change="asyncData"/>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>Дата</th>
                  <th>ID</th>
                  <th>ID уніка</th>
                  <th class="text-center align-middle" style="min-width: 220px;">Владелец</th>
                  <th class="text-center align-middle">PWA</th>
                  <th class="text-center align-middle">Шаблон</th>
                  <th class="text-center align-middle">Домен</th>
                  <th class="text-center align-middle">Статус</th>
                  <th class="text-center align-middle">Тип</th>
                  <th class="text-center align-middle">Дані</th>
                  <th class="text-center align-middle">Запит</th>
                  <th class="text-center align-middle">Відповідь</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model.id">
                  <td>{{ model.date | formatDate }}</td>
                  <td>{{ model.id }}</td>
                  <td>{{ model.host }}</td>
                  <td>
                    <div v-if="model.owner" class="row">
                      <div class="col-md-4">
                        <img :src="model.owner.photo_url ? model.owner.photo_url : '/images/no-avatar.jpg'">
                      </div>
                      <div class="col-md-8">
                        <span class="d-block">@{{
                            model.owner.username | truncate(13, '...')
                          }}</span>
                        <span class="text-gray mt-2 d-block">{{
                            model.owner.first_name | truncate(7)
                          }} {{ model.owner.last_name | truncate(7) }}</span>
                      </div>
                    </div>
                    <span v-else class="text-gray">( нет )</span>
                  </td>
                  <td>
                    <div v-if="model.pwa" class="row">
                      <div class="col-md-4">
                        <img :src="getPWALogo(model.pwa.images, model.pwa.vertical, model.pwa.gallery)">
                      </div>
                      <div class="col-md-8">
                        <span class="d-block">{{ model.domain }}</span>
                        <span class="text-gray mt-2 d-block">{{ model.pwa.name }}</span>
                      </div>
                    </div>
                    <span v-else class="text-gray">( нет )</span>
                  </td>
                  <td>
                    <span v-if="model.trackerTemplate">{{ model.trackerTemplate.name }}</span>
                    <span v-else class="text-gray">( нет )</span>
                  </td>
                  <td>{{ model.domain }}</td>
                  <td>{{ wrapStatus(model.status) }}</td>
                  <td>{{ wrapType(model.type) }}</td>
                  <td>
                    <button class="btn btn-inverse-info" @click="handlerShowAlert(JSON.stringify(model.data))">
                      Показати
                    </button>
                  </td>
                  <td>
                    <button class="btn btn-inverse-info" @click="handlerShowAlert(model.request)">Показати</button>
                  </td>
                  <td>
                    <button class="btn btn-inverse-info" @click="handlerShowAlert(model.response)">Показати</button>
                  </td>
                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import PostbackLogsFilter from './PostbackLogsFilter.vue'
import Owner from '@/pages/admin/components/tables/Owner.vue'
import Modal from '@/components/modal/Modal.vue'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import pwasMixin from '@/mixins/pwas-mixin'

export default {
  name: 'PostbackLogs',
  components: {SubmitModalForm, Owner, PostbackLogsFilter, Modal},
  mixins: [pwasMixin],
  data () {
    return {
      loading: true,
      page: 1,
      limit: 20,
      count: 0,
      models: [],
      modalInfo: ''
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  async created () {
    await this.skipFilter(false)
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  methods: {
    async asyncData () {
      this.loading = true
      await this.$store.dispatch('adminPostbacks/asyncDataLogs',
        {
          limit: this.limit,
          page: this.page,
          sort: '-createdAt',
          filter: this.$store.getters['adminPostbacks/logFilter']
        })
      this.models = this.$store.getters['adminPostbacks/models']
      this.count = this.$store.getters['adminPostbacks/count']
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async handlerPagination (page) {
      this.page = page
      await this.asyncData()
    },
    handlerShowAlert (json) {
      this.modalInfo = json
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.modalInfo = ''
      this.$refs.modal.close()
    },
    wrapType (type) {
      return 'Facebook API'
    },
    wrapStatus (status) {
      return status === 'error' ? 'Помилка' : 'Пройшов'
    },
    async skipFilter (async = true) {
      await this.$store.dispatch('adminPostbacks/setLogFilter', {
        owner: '',
        host: '',
        pwa: '',
        trackerTemplate: '',
        domain: '',
        status: '',
        type: '',
        dateBegin: '',
        dateEnd: ''
      })
      if (async === true) {
        await this.asyncData()
      }
    }
  }
}
</script>
