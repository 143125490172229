<template>
  <td>{{ vertical }}</td>
</template>
<script>
export default {
  name: 'VerticalColumn',
  props: ['value'],
  computed: {
    vertical () {
      const verticals = this.$store.getters['verticals/verticals']
      const index = verticals.findIndex(vertical => vertical.id === this.value)
      return this.$t(verticals[index]?.label)
    }
  }
}
</script>
