export default {
  title: 'Modelos de PWA',
  create: 'Criar um modelo',
  deleteTitle: 'Remoção de modelo de PWA',
  deleteMessage: 'Tem certeza que quer apagar o modelo de PWA?',
  name: 'Nome',
  owner: 'Utilizador',
  googlePlayURL: 'Play Market URL',
  pwaId: 'PWA',
  createdAt: 'Criado',
  updatedAt: 'Atualizado',
  idTitle: 'ID do modelo',
  nameTitle: 'Nome do modelo na lista',
  ownerTitle: 'Proprietário do modelo',
  googlePlayURLTitle: 'URL de importação',
  pwaIdTitle: 'PWA de importação',
  createdAtTitle: 'Data de criação do modelo',
  updatedAtTitle: 'Data de atualização do modelo',
  actionsTitle: 'Movimentos em um modelo',
  deleteButton: 'Apagar',
  deleted: 'Modelo foi apagado',
  filters: {
    id: 'Busca por ID',
    name: 'Busca por nome',
    owner: 'Busca por proprietário',
    googlePlayURL: 'Busca por URL',
    pwaId: 'Busca por PWA'
  },
  form: {
    createTitle: 'Criação de um modelo de PWA',
    editTitle: 'Editar um modelo {name}',
    name: 'Nome do modelo',
    namePlaceholder: 'Introduza o nome do modelo',
    owner: 'Proprietário do modelo',
    ownerPlaceholder: 'Indica o proprietário do modelo',
    pwaPlaceholder: 'Indica o PWA para importar',
    googlePlayURL: 'URL Play Market',
    googlePlayURLPlaceholder: 'Indica o URL para importar',
    lang: 'Idioma da aplicação',
    langPlaceholder: 'Indica o idioma da aplicação'
  },
  apps: {
    title: 'Aplicar o modelo',
    your: 'Seus modelos',
    notSelected: 'Modelo não foi escolhido'
  },
  success: 'Modelo foi guardado com sucesso'
}
