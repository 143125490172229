<template>
  <div class="container-fluid page-body-wrapper">
    <link rel="stylesheet" href="/css/dark/style.css">
    <div class="main-panel">
      <router-view name="header"></router-view>
      <router-view/>
      <footer class="footer">
        <div class="w-100 clearfix">
                        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2021 <a
                          href="http://www.bootstrapdash.com/"
                          target="_blank">Bootstrapdash</a>. All rights reserved.</span>
          <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i
            class="icon-heart text-danger"></i></span>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdminLayout',
  created () {
    document
      .querySelectorAll('.custom-style')
      .forEach(value => value.remove())
  }
}
</script>
<style>
.picker_frm_inp {
  background-color: #252933 !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #cec6c6;
}

.btn + .btn {
  margin-left: 0.25rem;
}
</style>
