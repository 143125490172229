export default {
  form: {
    title: 'Передача событий',
    subtitle: 'События для отстука',
    info: 'Максимальное предварительное количество событий для отправки - 50 на каждое событие',
    hint: 'Отстук начнется мгновенно, но события будут отстукиваться в течение 6-24 часов, иногда возможно дольше',
    country: {
      placeholder: 'Выберите страну',
      title: 'Гео',
      errors: {
        required: 'Необходимо выбрать страну для отстука'
      }
    },
    accessToken: {
      errors: {
        required: `У пикселя <strong>{pixel}</strong> отсутствует Access Token. Настройте все пиксели перед использованием API Conversions`
      }
    },
    conversion: {
      title: 'Тип конверсии'
    },
    registrations: {
      title: 'Регистрация'
    },
    deposits: {
      title: 'Депозит'
    },
    success: {
      create: 'События для отстука успешно поставлены в очередь'
    }
  },
  conversions: {
    meta: 'Meta pixel',
    api: 'API Conversion'
  }
}
