<template>
  <div class="FormLayout content-wrapper parent_tooltip form-layout">
    <div class="row">
      <div class="col-xl-8 col-lg-7 col-sm-12 grid-margin grid-margin-md-0">
        <div class="card">
          <router-view/>
        </div>
      </div>
      <div class="col-xl-4 col-lg-5 col-sm-12 grid-margin grid-margin-md-0 preview_this_blk" :class="{ active: isActive }">
        <div class="button_back_to_edit" @click="isActive = false">
          <div class="green_cirs_g d_flex align_center justify_center">
            <EditIcon />
          </div>
        </div>
        <Preview id="landing-pages" :previewURL="previewURL" ref="preview"/>
      </div>
      <div class="button_preview_sh" @click="isActive = true">
        <div class="show_preview_green d_flex align_center justify_center">
          <PlayMarketIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preview from '@/components/Preview.vue'
import {mapGetters} from 'vuex'
import PlayMarketIcon from '@/components/icons/PlayMarketIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

export default {
  name: 'FormLayout',
  components: {Preview, PlayMarketIcon, EditIcon},
  data () {
    return {
      isActive: false
    }
  },
  watch: {
    preview: {
      handler: function (newValue) {
        this.$refs.preview.updatePreview(newValue)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({preview: 'landingPages/preview'}),
    previewURL () {
      return `${import.meta.env.VITE_APP_PREVIEW_BASE_URL}/landing-pages/preview?t=` + (new Date().getTime())
    }
  }
}
</script>

<style lang="scss" scoped>
.form-layout {
  padding-right: 60px !important;

  .preview-container {
    padding: 26px 25px 22px 28px;
  }
}
.landing-phone-preview {
  position: sticky;
  top: 5px;
  width: 100%;
  height: 100%;
  max-width: 320px;
  max-height: 630px;
}
.in_to_previs_g iframe {
  height: 100%;
  margin-top: 0;
}
.in_to_previs_g {
  border-radius: 25px;
}
.preview_sljf_g {
  background-image: url('/images/phone.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 23px 27px 25px;
}

@media only screen and (max-width: 991px) {
  .landing-phone-preview {
    margin: 0 auto;
  }
  .in_to_previs_g {
    max-width: 379px;
    height: calc(96% + 30px);
  }
  .preview_this_blk {
    bottom: 0;
    overflow-y: auto;
    padding: 30px 0;
    display: flex;
    margin: 0;
  }
  .preview_sljf_g {
    margin: auto;
  }
  .form-layout {
    padding-right: 0 !important;
  }
}
@media only screen and (max-width: 479px) {
  .preview_sljf_g {
    zoom: .8;
  }
}
</style>
