<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Кассы</h4>
      <p class="card-description">Для добавления шлюзов "провалитесь" в кассу</p>
      <div class="mb-2">
        <button type="button" class="btn btn-outline-success btn-fw" @click="handlerCreate"><i
          class="icon-plus"></i> Добавить
        </button>
      </div>
      <merchant-form ref="form"/>
      <div class="table-responsive">
        <table class="table table-bordered table-hover">
          <thead>
          <tr>
            <th>
              Вкл./Выкл.
            </th>
            <th>
              Названия
            </th>
            <th>
              Кл. шлюзов
            </th>
            <th>Создана</th>
            <th>Обновлена</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <merchant-field v-bind:item="item" v-for="item in merchants" v-bind:key="item._id"/>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import MerchantForm from './MerchantForm.vue'
import MerchantField from './MerchantField.vue'

export default {
  name: 'Merchants',
  components: {MerchantForm, MerchantField},
  data: function () {
    return {
      merchants: []
    }
  },
  created: async function () {
    await this.getData()
  },
  methods: {
    handlerCreate: function () {
      this.$refs.form.enable = false
      this.$refs.form.name = ''
      this.$refs.form.data = ''
      this.$refs.form.url = ''
      this.$refs.form.id = false
      this.$refs.form.initAceEditor()
      // eslint-disable-next-line no-undef
      jQuery('#merchant-modal').modal('show')
    },
    getData: async function () {
      this.merchants = []
      await this.$store.dispatch('adminMerchants/index')
      this.merchants = this.$store.getters['adminMerchants/merchants']
    }
  }
}
</script>
