import service from '@/store/services/analytics-service'
import middleware from './middleware'
import api from '@/store/services/api'
import { mergeArrays } from '../../utils/common'

const groupsLabels = {
  date: 'analytics.groupsLabels.date',
  tag: 'analytics.groupsLabels.tag',
  host: 'analytics.groupsLabels.host',
  country: 'analytics.groupsLabels.country',
  pwa: 'analytics.groupsLabels.pwa',
  vertical: 'analytics.groupsLabels.vertical',
  userAgentDeviceType: 'analytics.groupsLabels.userAgentDeviceType',
  userAgentDeviceVendor: 'analytics.groupsLabels.userAgentDeviceVendor',
  userAgentDeviceModel: 'analytics.groupsLabels.userAgentDeviceModel',
  userAgentBrowserName: 'analytics.groupsLabels.userAgentBrowserName',
  userAgentOs: 'analytics.groupsLabels.userAgentOs',
  preLandingPage: 'analytics.groupsLabels.preLandingPage',
  postLandingPage: 'analytics.groupsLabels.postLandingPage'
}

const settings = {
  columns: {
    id: {
      label: 'ID',
      type: 'string',
      group: ''
    },
    uniques: {
      label: 'analytics.columnsLabels.uniques',
      type: 'NumberColumn',
      group: 'actions',
      title: 'analytics.columnsLabels.uniquesTitle'
    },
    hits: {
      label: 'analytics.columnsLabels.hits',
      type: 'NumberColumn',
      group: 'actions',
      title: 'analytics.columnsLabels.hitsTitle'
    },
    installs: {
      label: 'analytics.columnsLabels.installs',
      type: 'NumberColumn',
      group: 'actions',
      title: 'analytics.columnsLabels.installsTitle'
    },
    pushes: {
      label: 'analytics.columnsLabels.pushes',
      type: 'NumberColumn',
      group: 'actions',
      title: 'analytics.columnsLabels.pushesTitle'
    },
    registrations: {
      label: 'analytics.columnsLabels.registrations',
      type: 'NumberColumn',
      group: 'postback',
      title: 'analytics.columnsLabels.registrationsTitle'
    },
    deposits: {
      label: 'analytics.columnsLabels.deposits',
      type: 'NumberColumn',
      group: 'postback',
      title: 'analytics.columnsLabels.depositsTitle'
    },
    cpi: {label: 'analytics.columnsLabels.cpi', type: 'AmountColumn', group: 'actions'},
    opens: {
      label: 'analytics.columnsLabels.opens',
      type: 'NumberColumn',
      group: 'actions',
      title: 'analytics.columnsLabels.opensTitle'
    },
    reopens: {
      label: 'analytics.columnsLabels.reopens',
      type: 'NumberColumn',
      group: 'actions',
      title: 'analytics.columnsLabels.reopensTitle'
    },
    country: {label: 'analytics.columnsLabels.country', type: 'CountryColumn', group: 'pwa'},
    createdAt: {
      label: 'analytics.columnsLabels.createdAt',
      type: 'DateTimeColumn',
      group: 'unique',
      title: 'analytics.columnsLabels.createdAtTitle'
    },
    uniquesToInstalls: {
      label: 'analytics.columnsLabels.uniquesToInstalls',
      type: 'UniquesToInstallsColumn',
      group: 'calculate',
      title: 'analytics.columnsLabels.uniquesToInstallsTitle'
    },
    uniquesToPush: {
      label: 'analytics.columnsLabels.uniquesToPush',
      type: 'UniquesToPush',
      group: 'calculate',
      title: 'analytics.columnsLabels.uniquesToPushTitle'
    },
    installsToPush: {
      label: 'analytics.columnsLabels.installsToPush',
      type: 'InstallsToPush',
      group: 'calculate',
      title: 'analytics.columnsLabels.installsToPushTitle'
    },
    date: {label: 'analytics.columnsLabels.date', type: 'DateColumn', group: 'unique'},
    ip: {label: 'IP', type: 'StringColumn', group: 'unique'},
    host: {label: 'analytics.columnsLabels.host', type: 'StringColumn', group: 'pwa'},
    referer: {label: 'analytics.columnsLabels.referer', type: 'StringColumn', group: 'unique'},
    pwa: {label: 'PWA', type: 'PwaColumn', group: 'pwa'},
    vertical: {label: 'analytics.columnsLabels.vertical', type: 'VerticalColumn', group: 'pwa'},
    redirect: {label: 'analytics.columnsLabels.redirect', type: 'StringColumn', group: 'unique'},
    userAgentDeviceType: {label: 'analytics.columnsLabels.userAgentDeviceType', type: 'StringColumn', group: 'unique'},
    userAgentDeviceVendor: {
      label: 'analytics.columnsLabels.userAgentDeviceVendor',
      type: 'StringColumn',
      group: 'unique'
    },
    userAgentDeviceModel: {
      label: 'analytics.columnsLabels.userAgentDeviceModel',
      type: 'StringColumn',
      group: 'unique'
    },
    userAgentBrowserName: {
      label: 'analytics.columnsLabels.userAgentBrowserName',
      type: 'StringColumn',
      group: 'unique'
    },
    userAgentOs: {label: 'analytics.columnsLabels.userAgentOs', type: 'StringColumn', group: 'unique'},
    preLandingPage: {label: 'analytics.columnsLabels.preLandingPage', type: 'StringColumn', group: 'landings'},
    postLandingPage: {label: 'analytics.columnsLabels.postLandingPage', type: 'StringColumn', group: 'landings'},
    preLandingPageUnique: {label: 'analytics.columnsLabels.preLandingPageUnique', type: 'NumberColumn', group: 'landings'},
    postLandingPageUnique: {label: 'analytics.columnsLabels.postLandingPageUnique', type: 'NumberColumn', group: 'landings'}
  }
}

const state = () => ({
  page: 1,
  limit: 20,
  range: {begin: '', end: ''},
  filter: {},
  sort: null,
  group: null,
  columns: [],
  models: [],
  count: 0,
  settings: {
    columns: [],
    filters: [],
    groups: []
  },
  report: {
    csv: null,
    processing: false
  }
})

const mutations = {
  setOptions (state, payload) {
    for (const [prop, value] of Object.entries(payload)) {
      if (prop === 'columns') {
        if (!value.includes('id')) {
          value.unshift('id')
        }
        const sort = payload.sort.substring(0, 1) === '-' ? payload.sort.substring(1) : payload.sort
        if (!value.includes(sort)) {
          value.push(sort)
        }
      }
      if (prop === 'filter' && value.params) {
        for (const [paramProp, paramValue] of Object.entries(value.params)) {
          payload.filter[`params.${paramProp}`] = paramValue
        }
        payload.filter.params = undefined
      }
      state[prop] = value
    }
  },
  setData (state, {columns, count, models}) {
    state.count = count
    state.models = models
    state.settings.columns = columns
  },
  setFilters (state, filters) {
    state.settings.filters = filters
  },
  resetState (state) {
    state.page = 1
    state.limit = 20
    state.range = {begin: '', end: ''}
    state.filter = {}
    state.sort = null
    state.group = null
    state.columns = []
    state.models = []
    state.count = 0
    state.settings = {
      columns: [],
      filters: [],
      groups: []
    }
  },
  setReport (state, {csv, processing}) {
    csv = csv || null
    processing = processing || false
    state.report = {csv, processing}
  },
  setGroups (state, groups) {
    state.settings.groups = groups
  },
  setRange (state, range) {
    state.range = range
  },
  setModelChunk (state, {count, models, columns}) {
    state.count = count
    state.models = mergeArrays(state.models, models)
    state.settings.columns = columns
  },
  resetModels (state) {
    state.models = []
  }
}

const actions = {
  async asyncData ({commit, getters}, {type}) {
    try {
      const payload = await service[type](getters.asyncParams)
      commit('setData', payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async asyncDataChunks ({commit, getters}, dateChunks) {
    try {
      for (const chunk of dateChunks) {
        const data = await service.groups({...getters.asyncParams, range: chunk})
        commit('setModelChunk', data)
      }
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  resetState ({commit}) {
    commit('resetState')
  },
  skipReport ({commit}) {
    commit('setReport', {processing: false, url: null})
  },
  async createReport ({commit}, {type, page, limit, sort, group, columns, range, filter}) {
    commit('setReport', {processing: true})
    const payload = JSON.parse(JSON.stringify({type, page, limit, sort, group, columns, range, filter}))
    try {
      const csv = await service.createReport(payload)
      commit('setReport', {processing: false, csv})
    } catch (error) {
      middleware.validateAut(error)
      throw new Error(error)
    }
  },
  async getGroupsBy ({commit, getters}) {
    try {
      const response = await service.groupsParams(getters.asyncParams)
      commit('setGroups', response)
    } catch (error) {
      middleware.validateAut(error)
      throw new Error(error)
    }
  },
  async getAnalyticsByGroup ({ commit }) {
    return api.get('users/analytics')
      .then((res) => {
        const { group } = res
        commit('setOptions', group)
      }).catch(error => {
        throw error
      })
  },
  async getAnalyticsByHosts ({ commit }) {
    return api.get('users/analytics')
      .then((res) => {
        const { hosts } = res
        commit('setOptions', hosts)
      }).catch(error => {
        throw error
      })
  },
  async saveAnalyticsOptionsGroups ({getters}) {
    try {
      const response = await service.saveOptionsGroups(getters.asyncParams)
      return response
    } catch (error) {
      throw error
    }
  },
  async saveAnalyticsOptionsHosts ({getters}) {
    try {
      const response = await service.saveOptionsHosts(getters.asyncParams)
      return response
    } catch (error) {
      throw error
    }
  },
  async getFilters ({getters, commit}) {
    try {
      const response = await service.filters(getters.asyncParams)
      commit('setFilters', response)
      return response
    } catch (error) {
      throw error
    }
  }
}

const getters = {
  reportProcessing: state => state.report.processing,
  reportReady: state => state.report.csv != null,
  reportCsv: state => state.report.csv,
  options: state => ({
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    group: state.group,
    columns: state.columns,
    range: state.range,
    filter: state.filter
  }),
  asyncParams: state => ({
    page: state.page,
    limit: state.limit,
    range: state.range,
    sort: state.sort,
    group: state.group,
    columns: state.columns,
    filter: state.filter
  }),
  models: state => state.models,
  csv: state => {
    let models = state.models
    if (state.group === 'pwa') {
      models = state.models.map((model) => ({ ...model, id: model.id.name }))
    }
    if (state.group === 'owner') {
      models = state.models.map((model) => ({ ...model, id: model.id.username }))
    }

    const {domains, profits} = state.report.csv

    let csv = ''

    if (models.length > 0) {
      const head = Object.keys(models[0])
      head[0] = state.group
      csv += [head]
        .concat(models)
        .map((it) => Object.values(it).toString())
        .join('\n') + '\n'
    }

    if (domains != null && domains.length > 0) {
      csv += 'Domains\n' + [Object.keys(domains[0])]
        .concat(domains)
        .map((it) => Object.values(it).toString())
        .join('\n') + '\n'
    }

    if (profits != null && profits.length > 0) {
      csv += 'Profits\n' + [Object.keys(profits[0])]
        .concat(profits)
        .map((it) => Object.values(it).toString())
        .join('\n')
    }

    return csv
  },
  count: state => state.count,
  columns: state => {
    const columns = {}
    for (const column of state.settings.columns) {
      columns[column] = settings.columns[column]
    }
    return columns
  },
  range: state => state.range,
  groups: state => {
    const payload = {}
    for (const group of state.settings.groups) {
      payload[group] = groupsLabels[group] != null ? groupsLabels[group] : {param: group.substring(7)}
    }
    return payload
  },
  filters: state => {
    return state.settings.filters.map(filter => {
      const parts = filter.alias.split('.')
      switch (parts[0]) {
        case 'installs':
          filter.label = 'установкам'
          break
        case 'opens':
          filter.label = 'открытия'
          break
        case 'reopens':
          filter.label = 'повторам'
          break
        case 'pushes':
          filter.label = 'подпискам'
          break
        case 'deposits':
          filter.label = 'депозитам'
          break
        case 'registrations':
          filter.label = 'регистрациям'
          break
        case 'id':
          filter.label = 'ID'
          break
        case 'ip':
          filter.label = 'IP'
          break
        case 'referer':
          filter.label = 'рефке'
          break
        case 'redirect':
          filter.label = 'редику'
          break
        case 'vertical':
          filter.label = 'вертикале'
          break
        case 'host':
          filter.label = 'домену'
          break
        case 'params':
          filter.label = parts[1]
          break
        case 'pwa':
          filter.label = 'PWA'
          break
        case 'tracker':
          filter.label = 'трекеру'
          break
        case 'userAgentBrowserName':
          filter.label = 'браузеру'
          break
        case 'userAgentOs':
          filter.label = 'ОС'
          break
        case 'userAgentDeviceType':
          filter.label = 'типу устройства'
          break
        case 'userAgentDeviceVendor':
          filter.label = 'бренду устройства'
          break
        case 'userAgentDeviceModel':
          filter.label = 'моделе устройства'
          break
        case 'tag':
          filter.label = 'тегу'
          break
      }
      return filter
    })
  }
}

const analytics = {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
}

export default analytics
