<template>
  <modal
    ref="modal"
    id="comment-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="vertical">Вертикаль</label>
        <select class="form-control" id="vertical" tabindex="2" name="vertical"
                v-model="vertical">
          <option v-for="item in $store.getters['verticals/verticals']" :key="item.id" :value="item.id">
            {{ $t(item.label) }}
          </option>
        </select>
      </div>
      <div class="form-group" :class="apiValidationErrors['sex'] ? 'has-danger' : ''">
        <label for="sex">Пол</label>
        <select class="form-control" name="sex" id="sex" v-model="sex">
          <option value="male">Мужской</option>
          <option value="female">Женский</option>
        </select>
        <label v-if="apiValidationErrors['sex']" class="error mt-2 text-danger"
               for="sex">{{ apiValidationErrors['sex'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['_id'] ? 'has-danger' : ''">
        <label for="comment">Комментарий</label>
        <textarea class="form-control" id="comment" name="comment" type="text" tabindex="1"
               v-model="comment"
               rows="6"
               :class="apiValidationErrors['comment'] ? 'form-control-danger' : ''"
               placeholder="Введите комментарий"
               @focus="clearError('comment')"></textarea>
        <label v-if="apiValidationErrors['comment']" class="error mt-2 text-danger"
               for="comment">{{ apiValidationErrors['comment'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['answer_comment'] ? 'has-danger' : ''">
        <label for="answer_comment">Ответ на комментарий</label>
        <textarea class="form-control" id="answer_comment" name="answer_comment" type="text" tabindex="1"
               v-model="answer_comment"
               rows="6"
               :class="apiValidationErrors['answer_comment'] ? 'form-control-danger' : ''"
               placeholder="Введите ответ на комментарий"
               @focus="clearError('answer_comment')"></textarea>
        <label v-if="apiValidationErrors['answer_comment']" class="error mt-2 text-danger"
               for="answer_comment">{{ apiValidationErrors['answer_comment'][0] }}</label>
      </div>
      <p class="mt-2 card-description">
        Доступные макросы {name} - название PWA, {author} - название разработчика и {com_author} - автор комментария
      </p>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'

const defaultValues = () => ({
  comment: '',
  answer_comment: '',
  sex: 'male',
  vertical: 1
})

export default {
  name: 'CommentForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      isCreate: true,
      processing: false,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    title () {
      return this.isCreate ? 'Создание комментария' : 'Редактирование комментария'
    }
  },
  methods: {
    handlerCreate () {
      this.skip()
      this.isCreate = true
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerUpdate ({ id, comment, answer_comment, sex, vertical }) {
      this.skip()
      this.isCreate = false
      this.id = id
      this.comment = comment
      this.answer_comment = answer_comment
      this.sex = sex
      this.vertical = vertical
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.$store.dispatch(this.isCreate ? 'adminComments/create' : 'adminComments/update', {
          id: this.id,
          comment: this.comment,
          answer_comment: this.answer_comment,
          sex: this.sex,
          vertical: this.vertical
        })
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
