export default {
  navigation: 'Домен',
  title: 'Налаштування домену PWA #{id}',
  settingTitle: 'Для роботи з системою домен повинен бути прив\'язаний до CloudFlare і мати наступні настройки',
  settings: [
    'Вказати у реєстратора NS від Cloudflare',
    'Вказати в налаштуваннях домену А-запису'
  ],
  change: 'Змінити',
  warningCloudflare: 'Для роботи з системою домен повинен бути прив\'язаний до <a href="https://www.cloudflare.com/" target="_blank" class="link_green">CloudFlare</a>. Якщо NS записи не відображаються або PWA працює не коректно, спробуйте заново запустити процес за допомогою кнопки',
  getNS: 'Отримати NS',
  noCloudflare: 'Домен не обернутий у <a href="https://www.cloudflare.com/" target="_blank">CloudFlare</a>, без цієї процедури ви не зможете використовувати програми',
  checkInfo: 'Після заміни домену його потрібно буде повторно перевірити',
  ns: {
    success: 'Запит на отримання NS записів успішний',
    failed: 'Запит на отримання NS записів провалений. Зверніться на підтримку'
  }
}
