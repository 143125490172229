<template>
  <div class="form-group mb-0 flex-grow-1">
    <label>TikTok Pixel
      <info
        message="TikTok Pixel"/>
    </label>
    <input type="text" placeholder="XXXXXXXXXXXXXXXXXXXX" v-model="pixel"
           class="form-control">
  </div>
</template>
<script>
import Info from '@/components/Info.vue'

export default {
  name: 'Config',
  props: ['value'],
  components: {Info},
  computed: {
    pixel: {
      get () {
        return this.value._id
      },
      set (value) {
        this.$emit('input', {
          ...this.value,
          _id: value
        })
      }
    }
  }
}
</script>
