export default {
  title: 'PUSH mailings',
  add: 'Add mailing',
  reload: 'Refresh',
  types: {
    postponed: 'Postponed',
    interval: 'Interval'
  },
  filters: {
    name: 'Search by name',
    status: 'Filter by status',
    range: {
      placeholder: 'Filter by date',
      today: 'Today',
      tomorrow: 'Tomorrow',
      nextSevenDay: 'Next 7 days'
    },
    pwa: 'Filter by PWA',
    domains: 'Filter by domains',
    country: 'Filter by country',
    conversions: {
      placeholder: 'Filter by conversions',
      options: ['With conversions', 'Without conversions']
    },
    type: 'Filter by type'
  },
  form: {
    formTitle: 'Distribution settings form',
    message: 'Setting up mailing and content to send',
    success: 'PUSH campaign saved successfully',
    preview: 'Preview',
    tags: {
      title: 'Mailing tags',
      info: 'Set of tags for mailing filtering'
    },
    name: {
      title: 'Title',
      info: 'System mailing name',
      placeholder: 'Enter the name of the newsletter'
    },
    publishedAt: {
      title: 'Start',
      info: 'Start date of mailing',
      placeholder: 'Enter date'
    },
    title: {
      title: 'Title',
      info: 'Header of the PUSH message. The recommended length is up to 37 characters. Supported spintax',
      placeholder: 'Enter title'
    },
    body: {
      title: 'Text',
      info: 'The text of the PUSH message. The recommended length is up to 50 characters. Supported spintax',
      placeholder: 'Enter text'
    },
    url: {
      title: 'Link',
      info: 'Link to open when clicked. If not specified, it will automatically open the URL installed in PWA',
      placeholder: 'Enter link'
    },
    badge: {
      title: 'Badge',
      info: 'The image used to present the notification when there is not enough space to display the notification itself. We will crop the picture to the recommended size (96 x 96)',
      placeholder: 'Upload Badge'
    },
    icon: {
      title: 'Icon',
      info: 'The image to use as the notification icon. We will crop the picture to the recommended size (192 x 192)',
      placeholder: 'Upload icon'
    },
    image: {
      title: 'Image',
      info: 'The image that will be displayed as part of the notification. We will crop the picture to the recommended size (492 x 225)',
      placeholder: 'Upload image'
    },
    dir: {
      title: 'Text Direction',
      info: 'Indicates the direction of the PUSH notification text',
      options: ['Auto', 'Left to Right', 'Right to Left']
    },
    renotify: {
      title: 'Re-Notify',
      info: 'Should the user be notified after a new notification replaces an old one'
    },
    requireInteraction: {
      title: 'Expect Interaction',
      info: 'The notification should remain active until the user clicks it or closes it, not close automatically'
    },
    silent: {
      title: 'Quiet Mode',
      info: 'Indicates whether the notification should be silent, i.e. no sounds or vibrations should be emitted, regardless of device settings'
    },
    segmentation: {
      title: 'PUSH Segmentation',
      message: 'If you do not fill in anything, then the mailing will be across your entire database',
      total: 'Total',
      showedCount: 'Impressions',
      clickedCount: 'Clicks',
      cappedCount: 'Closes',
      registeredCount: 'Registrations',
      depositedCount: 'Deposits',
      pwas: {
        placeholder: 'Select one or more PWAs'
      },
      domains: {
        title: 'Domains',
        placeholder: 'Select one or more domains'
      },
      tags: {
        title: 'PWA Tags',
        placeholder: 'Select one or more PWA tags'
      },
      countries: {
        title: 'Countries',
        placeholder: 'Select one or more countries'
      },
      registered: {
        title: 'Registration',
        info: 'Send to those users who are registered in the application or to those who are not registered',
        options: ['Not used', 'Registered', 'Not registered']
      },
      deposited: {
        title: 'Deposit',
        info: 'Send to users who have deposited the app or not',
        options: ['Not used', 'Deposited', 'Did not']
      },
      showed: {
        title: 'Shows',
        info: 'Send to those users who have already received notifications or to those who have not',
        options: ['Not used', 'Received', 'Not received']
      },
      capped: {
        title: 'Closed',
        info: 'Send notifications to those users who closed or to those who did not close',
        options: ['Not used', 'Closed', 'Not closed']
      },
      clicked: {
        title: 'Clicked',
        info: 'Send to those users who clicked on the notification or to those who did not',
        options: ['Not used', 'Clicked', 'Not clicked']
      }
    },
    timezone: {
      label: 'Timezone',
      info: 'Timezone corrector'
    },
    country: {
      label: 'Country',
      info: 'Select a country to filter time zones',
      error: 'Could not find the time zone, select it yourself'
    }
  },
  heads: {
    count: {
      label: 'All companies',
      title: 'Total interval companies'
    },
    name: {
      label: 'Name',
      title: 'System mailing Title'
    },
    tags: {
      label: 'Tags',
      title: 'Tags for mailing filtering'
    },
    status: {
      label: 'Status',
      title: 'Distribution Status'
    },
    type: {
      label: 'Type',
      title: 'Mailing type'
    },
    publishedAt: {
      label: 'Start',
      title: 'Distribution Start Date'
    },
    interval: {
      label: 'Next',
      title: 'Next mailing launch'
    },
    total: {
      label: 'Total',
      title: 'Total'
    },
    remaining: {
      label: 'Remaining',
      title: 'Remaining'
    },
    delivered: {
      label: 'Delivered',
      title: 'Delivered'
    },
    showed: {
      label: 'Shows',
      title: 'Shows'
    },
    clicked: {
      label: 'Clicks',
      title: 'Clicks'
    },
    ctr: {
      label: 'CTR',
      title: 'Percent click/delivered. Calculated by the formula CTR = clicks/delivered*100'
    },
    unsubscribed: {
      label: 'Unsubscribed',
      title: 'Unsubscribed'
    },
    registered: {
      label: 'Regs',
      title: 'Registered'
    },
    deposited: {
      label: 'Deps',
      title: 'Made a Deposit'
    },
    countries: {
      label: 'Countries',
      title: 'Countries to which mailing is made',
      all: 'By all'
    }
  },
  statuses: {
    suspended: 'Suspended',
    launched: 'Launched',
    postponed: 'Postponed',
    sending: 'Sending',
    done: 'Done'
  },
  copy: {
    title: 'Copy PUSH mailings',
    message: 'Are you sure you want to copy the {name} mailing',
    button: 'Copy',
    success: 'PUSH mailings copied'
  },
  delete: {
    title: 'Delete PUSH mailings',
    message: 'Are you sure you want to delete {name}',
    button: 'Delete',
    success: 'PUSH mailings removed'
  },
  template: {
    title: 'PUSH mailing templates',
    source: 'Template',
    button: 'Mailing template'
  },
  templates: {
    title: 'Templates',
    add: 'Add Template',
    success: 'Mailing template successfully saved',
    filters: {
      name: 'Search by name',
      title: 'Search by title',
      body: 'Search by text'
    },
    form: {
      formTitle: 'Distribution template setup form',
      message: 'Setting up mailing and content to send',
      tags: {
        title: 'Distribution Tags',
        info: 'A set of tags for mailing filtering'
      },
      name: {
        title: 'Title',
        info: 'System template name',
        placeholder: 'Enter template name'
      },
      title: {
        title: 'Title',
        info: 'Header of the PUSH message. The recommended length is up to 37 characters. Supported spintax',
        placeholder: 'Enter title'
      },
      body: {
        title: 'Text',
        info: 'The text of the PUSH message. The recommended length is up to 50 characters. Supported spintax',
        placeholder: 'Enter text'
      },
      url: {
        title: 'Link',
        info: 'Link to open when clicked. If not specified, it will automatically open the CNC installed in PWA',
        placeholder: 'Enter link'
      },
      badge: {
        title: 'Icon',
        info: 'The image used to present the notification when there is not enough space to display the notification itself. We will crop the image to the recommended dimensions (96 x 96)',
        placeholder: 'Upload Icon'
      },
      icon: {
        title: 'Icon',
        info: 'The image to use as the notification icon. We will crop the image to the recommended dimensions (192 x 192)',
        placeholder: 'Upload icon'
      },
      image: {
        title: 'Image',
        info: 'The image that will be displayed as part of the notification. We will crop the image to the recommended dimensions (492 x 225)',
        placeholder: 'Upload image'
      },
      dir: {
        title: 'Text Direction',
        info: 'Indicates the direction of the PUSH notification text',
        options: ['Auto', 'Left to Right', 'Right to Left']
      },
      renotify: {
        title: 'Re-notify',
        info: 'Should the user be notified after a new notification replaces an old one'
      },
      requireInteraction: {
        title: 'Wait for Click',
        info: 'The notification should remain active until the user clicks it or closes it, not close automatically'
      },
      silent: {
        title: 'Quiet Mode',
        info: 'Indicates whether the notification should be silent, i.e. no sounds or vibrations should be emitted, regardless of device settings'
      }
    },
    heads: {
      name: {
        label: 'Name',
        title: 'System Template Title'
      },
      tags: {
        label: 'Tags',
        title: 'Template Filtering Tags'
      },
      title: {
        label: 'Title',
        title: 'Template title'
      },
      body: {
        label: 'Text',
        title: 'Template Text'
      },
      createdAt: {
        label: 'Created',
        title: 'Template Creation Date'
      }
    },
    delete: {
      title: 'Delete PUSH Template',
      message: 'Are you sure you want to delete template {name}',
      button: 'Delete',
      success: 'Mailing template deleted'
    },
    template: {
      title: 'PUSH mailings',
      source: 'Mailing',
      button: 'From mailing'
    }
  },
  intervals: {
    title: 'Interval mailing',
    intervalError: 'Select at least one day',
    chooseCountry: 'Please, choose a country!',
    status: 'Status',
    heads: {
      name: {
        label: 'Name',
        title: 'System name of mailing(Mailing done)'
      }
    }
  },
  statistics: {
    count: {
      title: 'Number of PUSH',
      info: 'Number of active PUSH subscriptions for all time'
    },
    registered: {
      title: 'Number of Registrations',
      info: 'Number of uniques who registered while being subscribed to PUSH'
    },
    deposited: {
      title: 'Number of deposits',
      info: 'Number of uniques who have made deposits while being subscribed to PUSH'
    }
  },
  actions: {
    title: 'By action',
    interval: {
      title: 'After some time',
      type: {
        minutes: 'Minutes | Minute | Minutes | Minutes',
        hours: 'Hours | Hour | Hours | Hours',
        days: 'Days | Day | Days | Days',
        months: 'Months | Month | Months | Months'
      }
    },
    heads: {
      after: {
        label: 'After Action',
        title: 'The mailing will work after the configured action'
      }
    },
    after: {
      minutes: 'In {count} minutes | An minute | In {count} minutes',
      hours: 'In {count} hours | An hour | In {count} hours',
      days: 'In {count} days | In a day | In {count} days',
      months: 'In {count} months | in a month | In {count} months'
    }
  },
  group: {
    successRemove: 'PUSH mailings removed',
    successCopy: 'PUSH mailings copied'
  },
  table: {
    publishedAt: 'Server send time in UTC 0 {time}'
  }
}
