<template>
  <div class="form-group">
    <slot/>
    <select
        v-model="action"
        @change="handleSubmit"
        class="form-control">
      <option value="">{{ $t('group.actions.nothing') }}</option>
      <option v-if="remove" value="remove">{{ $t('group.remove.action') }}</option>
      <option v-if="copy" value="copy">{{ $t('group.copy.action') }}</option>
      <option v-if="copyPwa" value="copyPwa">{{ $t('group.copyPwa.action') }}</option>
      <option v-if="trash" value="trash">{{ $t('group.trash.action') }}</option>
      <option v-if="restore" value="restore">{{ $t('group.restore.action') }}</option>
      <option v-if="play" value="play">{{ $t('group.play.action') }}</option>
      <option v-if="stop" value="stop">{{ $t('group.stop.action') }}</option>
      <option v-if="meta" value="meta">{{ $t('group.meta.action') }}</option>
      <option v-if="customJs" value="customJs">{{ $t('group.customJs.action') }}</option>
      <option v-if="paid" value="paid">{{ $t('pwas.paid.button') }}</option>
    </select>
    <Confirm :id="`group-confirm-${_uid}`" ref="confirm"/>
    <ModalAddMeta ref="modalAddMeta"/>
    <ModalCustomJs ref="customJSForm"/>
  </div>
</template>
<script>
import Confirm from '@/components/modal/Confirm.vue'
import ModalAddMeta from '@/components/modal/ModalAddMeta.vue'
import ModalCustomJs from '@/components/modal/ModalCustomJs.vue'

import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'GroupAction',
  props: {
    value: {type: Array, required: true},
    remove: {type: Boolean, default: false},
    copy: {type: Boolean, default: false},
    copyPwa: {type: Boolean, default: false},
    trash: {type: Boolean, default: false},
    restore: {type: Boolean, default: false},
    play: {type: Boolean, default: false},
    stop: {type: Boolean, default: false},
    meta: {type: Boolean, default: false},
    customJs: {type: Boolean, default: false},
    paid: {type: Boolean, default: false},
    isPwa: {type: Boolean, default: false}
  },
  components: {ModalCustomJs, Confirm, ModalAddMeta},
  data () {
    return {
      action: ''
    }
  },
  computed: {
    ...mapGetters({
      bulkPaymentCheck: 'pwas/bulkPaymentChecking'
    })
  },
  methods: {
    ...mapActions({
      alertError: 'alerts/error',
      bulkPaymentChecking: 'pwas/bulkPaymentChecking',
      bulkMeta: 'pwas/bulkMeta',
      bulkCustomJs: 'pwas/bulkCustomJs'
    }),
    async handleSubmit () {
      if (this.action !== '') {
        switch (this.action) {
          case 'meta':
            try {
              const result = await this.$refs.modalAddMeta.show({
                id: this.value,
                title: this.$t('pwas.buttons.meta.title'),
                message: this.$t('pwas.buttons.meta.bulkBody'),
                action: this.bulkMeta
              })
              if (result === true) this.loading = true
              this.$emit('input', result ? this.action : 'cancel')
            } catch (e) {
              await this.alertError(e.response.data)
            }
            break
          case 'customJs':
            try {
              const result = await this.$refs.customJSForm.show({
                id: this.value,
                title: this.$t('pwas.buttons.customJs.title'),
                message: this.$t('pwas.buttons.meta.bulkBody'),
                action: this.bulkCustomJs
              })
              if (result === true) this.loading = true
              this.$emit('input', result ? this.action : 'cancel')
            } catch (e) {
              await this.alertError(e.response.data)
            }
            break
          case 'paid':
            try {
              await this.bulkPaymentChecking({ids: this.value})
              const {payment, amount} = await this.bulkPaymentCheck
              switch (payment) {
                case 'installs':
                  try {
                    const ok = await this.$refs.confirm.show({
                      okButton: this.$t('pwas.paid.button'),
                      title: this.$t('pwas.paid.title'),
                      message: this.$t('pwas.paid.paidInstalls'),
                      warning: this.$t('pwas.paid.warning')
                    })
                    if (ok) {
                      this.loading = true
                      this.$emit('input', ok ? payment : 'cancel')
                    }
                  } catch (e) {
                    await this.alertError(e.response.data)
                  }
                  break
                case 'tariffs':
                  try {
                    const ok = await this.$refs.confirm.show({
                      okButton: this.$t('pwas.paid.button'),
                      title: this.$t('pwas.paid.title'),
                      message: this.$tc('pwas.paid.paidTariff'),
                      warning: this.$t('pwas.paid.warning')
                    })
                    if (ok) {
                      this.loading = true
                      this.$emit('input', ok ? payment : 'cancel')
                    }
                  } catch (e) {
                    await this.alertError(e.response.data)
                  }
                  break
                case 'balance':
                  try {
                    const ok = await this.$refs.confirm.show({
                      okButton: this.$t('pwas.paid.button'),
                      title: this.$t('pwas.paid.title'),
                      message: this.$t('pwas.paid.paidBalance', {amount}),
                      warning: this.$t('pwas.paid.warning')
                    })
                    if (ok) {
                      this.loading = true
                      this.$emit('input', ok ? payment : 'cancel')
                    }
                  } catch (e) {
                    await this.alertError(e.response.data)
                  }
                  break
              }
            } catch (e) {
              await this.alertError(e.response.data)
            }
            break
          case 'trash':
            if (this.isPwa) {
              const relations = await this.$store.dispatch('pwas/getRelations', this.value)
              const message = relations.length > 0 ? this.$t('group.trash.messageRelation') : this.$t('group.trash.message')
              try {
                const ok = await this.$refs.confirm.show({
                  title: this.$t(`group.${this.action}.title`, {count: this.value.length}),
                  message,
                  okButton: this.$t(`group.${this.action}.okButton`, {count: this.value.length})
                })
                if (ok === true) this.loading = true
                this.$emit('input', ok ? this.action : 'cancel')
              } catch (e) {
                await this.alertError(e.response.data)
              }
            } else {
              try {
                const ok = await this.$refs.confirm.show({
                  title: this.$t(`group.${this.action}.title`, {count: this.value.length}),
                  message: this.$t(`group.${this.action}.message`, {count: this.value.length}),
                  okButton: this.$t(`group.${this.action}.okButton`, {count: this.value.length})
                })
                if (ok === true) this.loading = true
                this.$emit('input', ok ? this.action : 'cancel')
              } catch (e) {
                await this.alertError(e.response.data)
              }
            }
            break
          default:
            try {
              const ok = await this.$refs.confirm.show({
                title: this.$t(`group.${this.action}.title`, {count: this.value.length}),
                message: this.$t(`group.${this.action}.message`, {count: this.value.length}),
                okButton: this.$t(`group.${this.action}.okButton`, {count: this.value.length})
              })
              if (ok === true) this.loading = true
              this.$emit('input', ok ? this.action : 'cancel')
            } catch (e) {
              await this.alertError(e.response.data)
            }
        }
      }

      this.action = ''
    }
  }
}
</script>
