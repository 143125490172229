import {useStore} from '@/store'

export default function auth ({ next, router }) {
  const store = useStore()
  if (store.getters.isAuthenticated) {
    next()
  } else {
    router.push({ name: 'Auth' })
  }
}
