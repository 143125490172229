/* eslint-disable no-template-curly-in-string */
export default {
  bills: {
    title: 'Рахунки',
    noHave: 'У вас немає створених рахунків. Поповніть рахунок',
    amountTopUp: 'Сума поповнення',
    topUp: 'Поповнити',
    status: 'Стан',
    statusTitle: 'Поточний стан рахунку',
    amount: 'Сума',
    amountTitle: 'Сума, яка надійде на рахунок',
    total: 'Разом',
    totalTitle: 'Сума в $ яку потрібно буде сплатити',
    method: 'Спосіб оплати',
    methodTitle: 'Спосіб оплати рахунку',
    created: 'Створено',
    createdTitle: 'Дата створення рахунку',
    actions: 'Дії',
    paidSuccess: 'Рахунок оплачений',
    paid: 'Оплачений',
    expiresIn: 'Спливає через:',
    overdue: 'Прострочений',
    rejected: 'Відхилено',
    info: 'Інфо',
    modal: {
      title: 'Рахунок на оплату #{id}',
      info: [
        `Рахунок підтверджений на <label class="text-warning">{confirmation}/6</label>, до
            закінчення роботи автоматичної перевірки залишилося
            <label class="text-warning">{expired}</label>, після цього буде доступне лише ручне
            підтвердження`,
        `Очікуємо на повідомлення про оплату. Якщо ви не змогли оплатити рахунок, можете зробити це повторно за <a href="{pay_url}">посиланням</a>`,
        'Рахунок успішно оплачений'
      ]
    },
    bitcoin: {
      title: 'Внесіть оплату на вказаний рахунок',
      confirmed: 'Підтверджений',
      wallet: 'Гаманець',
      paid: 'Оплачено',
      check: 'Перевірити'
    },
    tether: {
      title: 'Внесіть оплату на вказаний рахунок',
      wallet: 'Гаманець',
      hash: {
        title: 'Хеш(txid) транзакції',
        placeholder: 'Вставте сюди хеш транзакції',
        notEmpty: 'Не може бути пустим'
      },
      paid: 'Оплачено'
    }
  },
  transactions: {
    title: 'Транзакції',
    noHave: 'У Вас немає транзакцій для відображення',
    created: 'Створено',
    createdTitle: 'Дата створення транзакції',
    amount: 'Сума',
    amountTitle: 'Сума в $ транзакції',
    purpose: 'Призначення',
    purposeTitle: 'Призначення транзакції',
    type: {
      pwa: 'Оплата за PWA #{pwa}',
      domain: 'Покупка домену {domain} для PWA #{pwa}',
      referral: 'Партнерські роялті',
      bill: 'Поповнення балансу на ${amount} під час сплати рахунку #{bill}',
      tariff: 'Підписка на тариф #{tariff}',
      install: 'Вартість встановлення',
      bypass: 'Вартість встановлення через Bypass',
      ios: 'Вартість встановлення якщо iOS',
      correction: 'Корекція рахунку',
      reject: 'Рахунок #{bill} скасовано'
    }
  },
  payment: {
    minAmount: 'Мінімальна сума платежу ${num}',
    maxAmount: 'Максимальна сума платежу ${num}',
    title: 'Внесіть оплату на вказаний рахунок',
    method: 'Виберіть платіжну систему:',
    amount: 'Сума до оплати:',
    pay: 'Оплатити'
  }
}
