import api from '../api'

export default {
  getList: payload => api.get('admin/users?' + new URLSearchParams(payload).toString()),
  tariffs: ({owner, ...payload}) => api.get(`admin/users/${owner}/tariffs?` + new URLSearchParams(payload).toString()),
  read: id => api.get(`admin/users/${id}`),
  update: (id, payload) => api.put(`admin/users/${id}`, payload),
  patch: ({id, ...payload}) => api.patch(`admin/users/${id}`, payload),
  delete: id => api.delete(`admin/users/${id}`),
  tariffCreate: ({id, ...payload}) => api.post(`admin/users/${id}/tariffs`, payload),
  tariffRemove: ({id, tariffId}) => api.delete(`admin/users/${id}/tariffs/${tariffId}`),
  tariffUpdate: ({id, _id, ...payload}) => api.patch(`admin/users/${id}/tariffs/${_id}`, payload),
  move: ({from, to}) => api.put(`admin/users/moves`, {from, to}),
  authUser: (owner) => api.post(`admin/auth/user`, {owner}),
  authMember: ({owner, member}) => api.post(`admin/auth/member`, {owner, member})
}
