export default {
  navigation: 'Âmbito',
  title: 'Âmbito do PWA #{id}',
  load: 'Carregar próprios',
  message: 'Escolher um conjunto de imagens para PWA ou carregar próprias',
  imageSet: 'Conjunto de imagens',
  verticalLabel: 'Âmbito',
  topGames: 'Escolha seus principais jogos',
  pwa: 'Somente PWAs funcionais serão exibidos.',
  loading: {
    title: 'Carregar imagens <br>próprias',
    message: 'Escolher o ficheiro que você precisa no formato jpg, png. O tamanho do ficheiro não é superior a 2 mb',
    icon: 'Ícone',
    preview: 'Miniatura',
    button: 'Usar'
  },
  youtube: {
    title: 'YouTube vídeo',
    placeholder: 'Introduza o link ou frame para YouTube vídeo',
    require: 'Vídeo de YouTube é obrigatório para uso do modelo novo'
  },
  template: {
    title: 'Modelo',
    templates: ['Padrão', 'Novo 2022', 'PlayMarket (Beta)', 'Padrão (Beta)']
  },
  muted: {
    title: 'Som no vídeo',
    info: 'Som terá apenassem caso o utilizador cooperar com a página',
    on: 'Ligado',
    off: 'Desligado'
  }
}
