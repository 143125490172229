<template>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead>
      <tr>
        <th>Статус</th>
        <th>Названия</th>
        <th>Домен</th>
        <th>Создан</th>
        <th>Обновлен</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in data" :key="item._id">
        <td><span :class="`text-${item.status.class}`">{{ $t(item.status.label) }}</span></td>
        <td><router-link :to="link(item._id)"><img :src="item.images" :alt="item.alias || item.name"> {{ item.alias || item.name }}</router-link></td>
        <td>{{ item.domain }}</td>
        <td>{{ item.createdAt | dateFromNow }}</td>
        <td>{{ item.updatedAt | dateFromNow }}</td>
      </tr>
      </tbody>
    </table>
    <nav v-if="pageCount > 1">
      <paginate ref="paginate"
                :page-count="pageCount"
                :click-handler="pagination"
                :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                :next-text="'<i class=\'icon-arrow-right\'></i>'"
                :container-class="'pagination mt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item'"
                :next-class="'page-item'"
                :prev-link-class="'page-link'"
                :next-link-class="'page-link'"
                :active-class="'active'">
      </paginate>
    </nav>
  </div>
</template>
<script>

export default {
  name: 'PWAs',
  data: () => {
    return {
      pageSize: 10,
      count: 0,
      data: [],
      pageIndex: 1,
      filters: {
        owner: ''
      }
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.pageSize)
    }
  },
  async created () {
    await this.getData()
  },
  methods: {
    async getData () {
      await this.$store.dispatch('adminPWAs/asyncData', {
        limit: this.pageSize,
        page: this.pageIndex,
        sort: '-createdAt',
        filter: {
          owner: this.$route.params.id
        }
      })
      this.data = this.$store.getters['adminPWAs/models']
      this.count = this.$store.getters['adminPWAs/count']
    },
    async pagination (pageIndex) {
      this.pageIndex = pageIndex
      await this.getData()
    },
    link (id) {
      return {name: 'AdminPWA', params: {id}}
    }
  }
}
</script>
