export default (input) => {
  if (!input) return ''
  try {
    if (input.length === 10) {
      return input.substring(2).split('-').reverse().join('/')
    } else {
      // eslint-disable-next-line no-undef
      return moment(String(input)).utc().format('DD/MM/YY')
    }
  } catch (e) {
    return input
  }
}
