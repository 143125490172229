import api from '../api'
import { getSearchParams } from '../../list'

export default {
  getPWAsList: payload => api.get('admin/pwas?' + getSearchParams(payload)),
  copyPWA: id => api.post(`admin/pwas/${id}/copy`),
  updatePWA: ({id, ...payload}) => api.patch(`admin/pwas/${id}`, payload),
  getPWA: id => api.get(`admin/pwas/${id}`),
  getPWATracker: id => api.get(`admin/pwas/${id}/tracker`)
}
