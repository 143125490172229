<template>
  <form class="DomainForm domain-form forms-sample">
    <div class="domain-form__form-group">
      <BaseInput
        :class="apiValidationErrors.name ? 'has-danger' : ''"
        name="name"
        :error="Boolean(apiValidationErrors.name) ? apiValidationErrors.name[0] : ''"
        :is-error="Boolean(apiValidationErrors.name)"
        @input="clearError('name')"
        v-model="name"
        :value="name"
        maxlength="65"
        :message="$t('mirrors.create.internalName')"
        :placeholder="$t('mirrors.create.internalName')"
      />
    </div>
    <div class="domain-form__form-group">
      <BaseFromGroup
        id="pwa"
        :has-error="Boolean(apiValidationErrors.pwa)"
        :error-text="apiValidationErrors?.pwa ? apiValidationErrors?.pwa[0] : ''"
        :field-info-text="$t('mirrors.create.pwaSelect')"
      >
        <BaseSelect
          v-model="pwa"
          :placeholder="$t('mirrors.create.pwaSelect')"
          :filter="{ paid: true }"
          endpoint="/pwas/select"
          sort="-createdAt"
          @input="clearError('pwa')"
          :error="Boolean(apiValidationErrors.pwa)"
        />
      </BaseFromGroup>
    </div>
    <DomainFormNs
      v-if="isEditMode && isModeSelf && ns != null"
      :data="ns"
      :status="status"
      :domain="activeMirror.domain"
      :id="activeMirror.id"
      @rebuild="$emit('rebuild')"
    />
    <template v-if="!isEditMode">
      <p class="descr_site_shg domain-form__description" v-html="$t('mirrors.create.body')"></p>
      <BaseRadioButton
        :value="modes.self"
        :label="$t('mirrors.create.self')"
        :name="modes.self"
        v-model="mode"
        v-bind="$attrs"
      />
      <BaseInput
        v-if="isModeSelf"
        :class="apiValidationErrors.domain ? 'has-danger' : ''"
        name="domain"
        :error="Boolean(apiValidationErrors.domain) ? apiValidationErrors.domain[0] : ''"
        :is-error="Boolean(apiValidationErrors.domain)"
        @input="clearError('domain')"
        v-model="domain"
        :value="domain"
        :message="$t('mirrors.create.domainTitle')"
        :placeholder="$t('mirrors.create.domain')"
      />
      <div
        v-if="isModeSelf && isCloudflare && !showAdd"
        class="form-group mb-4"
        :class="apiValidationErrors.key ? 'has-danger' : ''"
      >
        <div class="flex-grow-1 d-flex align-items-end">
          <BaseSelect
            class="flex-grow-1"
            v-model="savedCredentials"
            id="savedCredentials"
            :options="cloudflareOptions"
            :clearable="false"
            @update:modelValue="clearError('key')"
            :error="apiValidationErrors.key"
          />
          <div class="ml-2">
            <DomainFormDeleteButton @click="handlerDelete()"/>
          </div>
          <div class="d-flex flex-grow-0 ml-2">
            <DomainFormAddButton @click="handlerAdd" />
            <info
              class="mx_6 mr-0"
              :message="$t('mirrors.create.pairTitle')"
            />
          </div>
        </div>
        <label
          v-if="apiValidationErrors.key"
          id="saved-credentials-error"
          class="error mt-2 text-danger"
          for="savedCredentials">{{ apiValidationErrors.key[0] }}
        </label>
      </div>
      <BaseInput
        v-if="isModeSelf && showAdd"
        :class="apiValidationErrors.email ? 'has-danger' : ''"
        name="email"
        :error="Boolean(apiValidationErrors.email) ? apiValidationErrors.email[0] : ''"
        :is-error="Boolean(apiValidationErrors.email)"
        @input="clearError('email')"
        v-model="email"
        :value="email"
        :message="$t('mirrors.create.apiEmailTitle')"
        :placeholder="$t('mirrors.create.apiEmail')"
      />
      <BaseInput
        v-if="isModeSelf && showAdd"
        :class="apiValidationErrors.key ? 'has-danger' : ''"
        name="email"
        :error="Boolean(apiValidationErrors.key) ? apiValidationErrors.key[0] : ''"
        :is-error="Boolean(apiValidationErrors.key)"
        @input="clearError('key')"
        v-model="key"
        :value="key"
        :message="$t('mirrors.create.apiKeyTitle')"
        :placeholder="$t('mirrors.create.apiKey')"
      />
      <div v-if="isModeSelf && showAdd" class="cont_ama_max">
        <p
          class="card-description domain-form__description api-description"
          v-html="$t('mirrors.create.apiHelp')">
        </p>
      </div>
      <button
        v-if="isModeSelf && showAdd && isCloudflare"
        type="button"
        @click="handlerReturn"
        class="ful_butt_green small_btn d_flex align_center justify_center mb-3"
      >{{ $t('mirrors.create.chooseAccount') }}</button>
      <DomainFormNs
        v-if="isModeSelf && ns != null"
        :data="ns"
        :status="status"
        :domain="activeMirror.domain"
        :id="activeMirror.id"
        @rebuild="$emit('rebuild')"
      />
      <BaseRadioButton
        v-if="boughtDomains.length > 0"
        v-bind="$attrs"
        :value="modes.bought"
        :label="$t('mirrors.create.bought')"
        :name="modes.bought"
        v-model="mode"
      />
      <template v-if="isModeBought">
        <BaseFromGroup
          id="domainBought"
          :has-error="Boolean(apiValidationErrors.domain)"
          :error-text="apiValidationErrors?.domain ? apiValidationErrors?.domain[0] : ''"
        >
          <BaseSelect
            v-model="domain"
            id="domainBought"
            filter-id="status"
            filter-id-value="5"
            sort="-createdAt"
            endpoint="/domains/select2"
            :error="Boolean(apiValidationErrors.domain)"
            @input="clearError('domain')"
          />
        </BaseFromGroup>
        <div
          class="cont_ama_max mt-2 domain-form__description"
          v-html="$t('mirrors.create.boughtInfo')">
        </div>
      </template>
      <BaseRadioButton
        v-if="domains.length > 0"
        v-bind="$attrs"
        :value="modes.marker"
        :label="$t('mirrors.create.buyDomain', {pwaDomainCost})"
        :name="modes.marker"
        v-model="mode"
      />
      <template v-if="isModeMarker">
        <BaseFromGroup
          id="domain"
          :has-error="Boolean(apiValidationErrors.domain)"
          :error-text="apiValidationErrors?.domain ? apiValidationErrors?.domain[0] : ''"
        >
          <BaseSelect
            v-model="domain"
            id="domain"
            :key="isModeMarker"
            filter-id="status"
            filter-id-value="2"
            sort="-createdAt"
            custom-filter="filter[text]"
            endpoint="/domains/select2"
            :error="Boolean(apiValidationErrors.domain)"
            @input="clearError('domain')"
          />
        </BaseFromGroup>
        <div
          class="cont_ama_max domain-form__description"
          v-html="$t('mirrors.create.marketInfo', {pwaDomainCost})">
        </div>
      </template>
    </template>
    <div>
      <div class="d-md-flex align-items-center mt-4 mobile-grid-2">
        <div class="mr-md-4 mb-2 mb-md-0">
          <button
            type="submit"
            @click="handlerSave"
            class="ful_butt_green d_flex align_center justify_center mobile-full-w"
            :disabled="processing || isEmptyRequiredFields">
            <span v-if="!processing">{{ $t('general.save') }}</span>
            <hollow-dots-spinner
              v-if="processing"
              :animation-duration="1000"
              :dot-size="15"
              :dots-num="3"
              :color="'#ff1d5e'"
            />
          </button>
        </div>
        <div class="mr-md-4 mb-2 mb-md-0">
          <button
            :disabled="processing"
            @click.prevent="handlerCancel"
            class="simple_butt_afg mobile-full-w"
          >
            {{ $t('general.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import domainMixins from '@/mixins/domain-mixin'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import {HollowDotsSpinner} from 'epic-spinners'
import Info from '@/components/Info.vue'
import Select2 from '@/components/Select2.vue'
import BaseRadioButton from '@/components/base/BaseRadioButton.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import DomainFormAddButton from './DomainFormAddButton.vue'
import DomainFormDeleteButton from './DomainFormDeleteButton.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import DomainFormNs from './DomainFormNs.vue'
import {mapActions, mapGetters} from 'vuex'

const modes = {
  self: 'self',
  bought: 'bought',
  marker: 'market'
}

const defaultValues = () => ({
  domain: '',
  key: '',
  email: '',
  savedCredentials: '',
  mode: 'self',
  name: '',
  pwa: null,
  ns: null,
  status: null,
  selfCreated: false
})

export default {
  name: 'DomainForm',
  mixins: [formMixin, domainMixins, select2AjaxMixin],
  components: {
    BaseFromGroup,
    BaseSelect,
    BaseRadioButton,
    BaseInput,
    Info,
    HollowDotsSpinner,
    Select2,
    DomainFormAddButton,
    DomainFormDeleteButton,
    DomainFormNs
  },
  data () {
    return {
      modes,
      ...defaultValues(),
      showAdd: false,
      processing: false,
      serverId: null
    }
  },
  computed: {
    ...mapGetters('mirrorsStore', [
      'activeMirror'
    ]),
    cloudflare () {
      return this.$store.getters['pwas/domainPage'].cloudflare
    },
    pwaDomainCost () {
      return this.$store.getters['pwas/domainPage'].pwaDomainCost
    },
    domains () {
      return this.$store.getters['pwas/domainPage'].domains
    },
    boughtDomains () {
      return this.$store.getters['pwas/domainPage'].boughtDomains
    },
    isModeSelf () {
      return this.mode === modes.self
    },
    isModeBought () {
      return this.mode === modes.bought
    },
    isModeMarker () {
      return this.mode === modes.marker
    },
    isCloudflare () {
      return this.cloudflare?.length > 0
    },
    cloudflareOptions () {
      return this.cloudflare.map(account => { return {id: (account.email + '###' + account.key), text: `${account.email}(${account.key})`} })
    },
    id () {
      return this.activeMirror?.id || this.serverId || ''
    },
    isEditMode () {
      return Boolean(this.id)
    },
    isEmptyRequiredFields () {
      return this.name === '' || this.pwa === null || this.domains === ''
    }
  },
  watch: {
    domain (n) {
      this.domain = this.sanitizerDomain(n)
    },
    mode () {
      this.domain = ''
    },
    activeMirror: {
      handler: 'setActiveMirror',
      immediate: true
    }
  },
  async mounted () {
    await this.initForm()
    this.setActiveMirror()
  },
  beforeDestroy () {
    this.resetApiValidation()
  },
  methods: {
    ...mapActions('mirrorsStore', [
      'createMirror',
      'updateMirror'
    ]),
    skip () {
      this.showAdd = false
      this.processing = false
      this.resetApiValidation()
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    setActiveMirror () {
      // коли редагування
      if (this.isEditMode) {
        this.name = this.activeMirror?.name
        this.pwa = {
          id: this.activeMirror?.pwa.id,
          text: this.activeMirror?.pwa.name
        }
        this.ns = this.activeMirror?.ns
        this.status = this.activeMirror?.status
      }
    },
    async initForm () {
      this.skip()
      if (this.isCloudflare) {
        this.showAdd = false
        this.savedCredentials = `${this.cloudflare[0].email}###${this.cloudflare[0].key}`
      } else this.showAdd = true
    },
    handlerCancel () {
      this.$emit('skip')
      this.resetApiValidation()
    },
    checkSavedCredentials (payload) {
      if (this.savedCredentials !== '') {
        const [email, key] = this.savedCredentials.split('###')
        payload.email = email
        payload.key = key
      } else {
        if (this.showAdd === false) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: {
              data: {
                errors: [{
                  param: 'savedCredentials',
                  msg: this.$t('pwas.create.apiRequired'),
                  value: '',
                  location: 'body'
                }]
              }
            }
          }
        }
      }
    },
    async onUpdate () {
      const payload = {
        id: this.id,
        name: this.name,
        pwa: this.pwa?.id || this.pwa
      }
      await this.updateMirror(payload)
    },
    async onCreateUpdate (payload, endpoint) {
      if (this.isEditMode) {
        await this.onUpdate()
        await this.$store.dispatch('alerts/success', this.$t('mirrors.create.updated'))
      } else {
        const data = await this.createMirror({...payload, url: endpoint})
        await this.$store.dispatch('alerts/success', this.$t('mirrors.create.success'))
        return data
      }
    },
    async onHandlerSelfMode () {
      const payload = {
        domain: this.domain,
        email: this.email,
        key: this.key,
        name: this.name,
        pwa: this.pwa
      }
      if (!this.isEditMode) await this.checkSavedCredentials(payload)
      const data = await this.onCreateUpdate(payload, 'cloudflare')
      if (data?.ns) {
        this.ns = data?.ns
        this.serverId = data?.id
        this.selfCreated = true
      }
    },
    async onHandleMarkerMode () {
      const payload = {
        domain: this.domain,
        name: this.name,
        pwa: this.pwa
      }
      await this.onCreateUpdate(payload, 'purchase')
    },
    async onHandlerBoughtMode () {
      const payload = {
        domain: this.domain,
        name: this.name,
        pwa: this.pwa
      }
      await this.onCreateUpdate(payload, 'bought')
    },
    async handlerSave () {
      try {
        this.processing = true
        this.$emit('processing', this.processing)
        this.resetApiValidation()
        if (this.isModeSelf) await this.onHandlerSelfMode()
        if (this.isModeMarker) await this.onHandleMarkerMode()
        if (this.isModeBought) await this.onHandlerBoughtMode()
        this.resetApiValidation()
        this.processing = false
        if (this.selfCreated) {
          this.$emit('created-self')
        } else this.$emit('created')
        this.$emit('processing', this.processing)
        this.selfCreated = false
      } catch (error) {
        this.processing = false
        this.$emit('processing', this.processing)
        this.setApiValidation(error.response.data.errors)
      }
    },
    handlerAdd () {
      this.resetApiValidation()
      this.showAdd = true
      this.savedCredentials = ''
      this.email = ''
      this.key = ''
    },
    handlerReturn () {
      this.resetApiValidation()
      this.showAdd = false
      this.savedCredentials = `${this.cloudflare[0].email}###${this.cloudflare[0].key}`
    },
    async handlerDelete () {
      if (confirm(this.$t('pwas.create.deleteCloudflare'))) {
        const [email, key] = this.savedCredentials.split('###')
        await this.$store.dispatch('pwas/deleteCloudflare', {email, key})
        await this.$store.dispatch('pwas/asyncDomainPage')
        if (this.isCloudflare) {
          this.showAdd = false
          this.savedCredentials = `${this.cloudflare[0].email}###${this.cloudflare[0].key}`
        } else {
          this.showAdd = true
        }
      }
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.domain-form
  &__form-group
    margin-bottom: 35px
  &__description
    font-size: 14px
    +font(400)
    font-style: normal
    line-height: normal
    &.api-description
      margin-top: 15px
</style>
