import create from './create/ru'
/* eslint-disable no-template-curly-in-string */
export default {
  title: 'Зеркала',
  headers: {
    domain: 'Домен Зеркала',
    domainInfo: 'Доменное имя вашего Зеркала',
    expired: 'Удаления',
    expiredInfo: 'Время до автоматического, безвозвратного удаления Зеркала'
  },
  trash: {
    title: 'Корзина зеркал',
    move: 'Переместить в корзину',
    empty: 'Корзина пустая',
    moveMessage: 'Вы уверены, что хотите перенести в корзину Зеркала {name}? На восстановление у вас будет 2 дня',
    moveButton: 'В корзину',
    moveSuccess: 'Зеркало успешно перенесен в корзину',
    restore: 'Восстановить Зеркало',
    restoreTitle: 'Восстановить Зеркало {name}?',
    restoreMessage: 'Вы уверены, что хотите восстановить Зеркало {name}?',
    restoreButton: 'Восстановить',
    restoreSuccess: 'Зеркало успешно восстановлено',
    goToMirrors: 'Перейти к Зеркалам'
  },
  createNew: 'Создать Зеркало',
  pwaName: 'Название PWA',
  pwaTitle: 'Название вашего PWA',
  mirrorName: 'Название',
  mirrorTitle: 'Название вашого Зеркало',
  status: 'Статус',
  statusTitle: 'Статус вашего Зеркала',
  tags: 'Теги PWA',
  tagsTitle: 'Теги назначение PWA',
  trackerHead: 'Трекер',
  trackerTitle: 'Информация о текущем трекере подключенному к Зеркала',
  add: 'Добавить',
  filters: {
    search: 'Поиск по домену',
    tag: 'Фильтр по тегу',
    addTag: 'Добавить новый тег',
    status: 'Фильтр по статусу',
    vertical: 'Фильтр по вертикали',
    category: 'Фильтр по категории',
    pwa: 'Фильтр по PWA'
  },
  buttons: {
    checkDomain: 'Проверить домен',
    run: 'Запустить',
    stop: 'Остановить',
    pay: 'Оплатить',
    preview: 'Предпросмотр',
    cloak: {
      buttonTitle: 'Скачать JS скрипт',
      title: 'JS для использования Cloak',
      body: `Добавьте этот JS код в конце секции тега <body> перед закрывающимся тегом </body>`
    },
    customJs: {
      buttonTitle: 'Вставьте свой JS скрипт',
      title: 'Пользовательский JS',
      body: 'Этот JS код будет добавлен в ваше Зеркало',
      bulkBody: 'Этот JS код будет добавлен в ваши Зеркала',
      success: 'Пользовательский JS сохранен'
    },
    meta: {
      buttonTitle: 'Вставьте свои META tags',
      title: 'Пользовательский META tags',
      body: 'Эти META tags будут добавлены на ваше Зеркало',
      bulkBody: 'Эти META tags будут добавлены на ваши Зеркала',
      success: 'Пользовательский META tags сохранены'
    }
  },
  checkDomain: {
    title: 'Проверка домена',
    body: [
      'Ваш домен ещё не проверен. Проверка домена может занимать до 2-ух часов',
      'Домен <strong>{domain}</strong> не имеет https протокола. Для корректной работы включите https Rewrite',
      'Домен <strong>{domain}</strong> проверен',
      'Домен <strong>{domain}</strong> не обернут в <strong>CloudFlare</strong>'
    ]
  },
  delete: {
    title: 'Удалить Зеркало',
    message: 'Вы уверены что хотите удалить Зеркало {name}?',
    button: 'Удалить',
    success: 'Зеркало удалено'
  },
  copy: {
    title: 'Копировать Зеркало',
    message: 'Вы уверены что хотите скопировать Зеркало {name}?',
    button: 'Копировать',
    success: 'Зеркало скопировано',
    tracker: {
      message: 'Копировать {name} c трекером или без?',
      okButton: 'C трекером',
      cancelButton: 'Без трекера'
    }
  },
  paid: {
    title: 'Активация Зеркала',
    warning: 'После активации Зеркала изменить домен невозможно!',
    button: 'Активировать',
    success: 'Зеркало активировано',
    cancel: 'Активация отменена',
    error: 'Ошибка активации',
    noTariff: 'Для работы с Зеркалом необходимо оформить тариф',
    paidTariff: `У вас есть одна активация в тарифе. После активации Зеркала у вас не останется активаций. Активировать Зеркало?
    | У вас есть {count} активации в тарифе. После активации этого Зеркала у вас остается одна активация. Активировать Зеркало?
    | У вас есть {count} активаций в тарифе. После активации этого Зеркала у вас остается {countLeft} активации. Активировать Зеркало?
    | У вас есть {count} активаций в тарифе. После активации этого Зеркала у вас остается {countLeft} активаций. Активировать Зеркало?`,
    paidBalance: 'У вас закончились активации в тарифе, вы можете активировать Зеркало сверх тарифа за ${amount}.',
    noMoney: 'У вас закончились активации в тарифе, вы можете активировать Зеркало сверх тарифа за ${amount}, или перейти на тариф выше. Для этого необходимо пополнить баланс.',
    paidInstalls: 'Активация в тарифе установок бесплатная, но за каждую установку Зеркала будет взиматься плата'
  },
  play: {
    title: 'Запустить Зеркало',
    message: 'Вы уверены что хотите запустить Зеркало {name}?',
    button: 'Запустить',
    success: 'Зеркало запущен'
  },
  stop: {
    title: 'Остановить Зеркало',
    message: 'Вы уверены что хотите остановить Зеркало {name}?',
    button: 'Остановить',
    success: 'Зеркало остановлен'
  },
  notFoundByFilters: 'Не найдены Зеркала удовлетворяющие условиям фильтрации',
  noHave: 'У Вас ещё нет созданных Зеркал. Нажмите кнопку "Добавить"',
  insufficientFunds: 'Не достаточно средств',
  save: 'Зеркало сохранено',
  verticals: ['Нутра', 'Бетинг', 'Дейтинг', 'Гемблинг', 'Трейдинг', 'Крипта'],
  statuses: ['Заблокирован', 'Проверка домена', 'Проверка HTTPs', 'Не оплачен', 'Остановлен', 'Работает', 'Техническая остановка'],
  categories: [
    'Автомобили и транспорт',
    'Библиотеки и демоверсии',
    'Бизнес',
    'Видеоплееры и редакторы',
    'Искусство и дизайн',
    'Еда и напитки',
    'Игры',
    'Инструменты',
    'Жилье и дом',
    'Здоровье и фитнес',
    'Знакомства',
    'Карты и навигация',
    'Книги',
    'Комиксы',
    'Красота',
    'Материнство и детство',
    'Медицина',
    'Мероприятия',
    'Музыка',
    'Новости и журналы',
    'Образование',
    'Образ жизни',
    'Общение',
    'Персонализация',
    'Погода',
    'Покупки',
    'Путешествия',
    'Работа',
    'Развлечения',
    'Социальные',
    'Спорт',
    'Стиль жизни',
    'Финансы',
    'Фотография'
  ],
  create,
  group: {
    successRemove: 'Зеркала были успешно удалены',
    successCopy: 'Зеркала успешно скопированы',
    successTrash: 'Зеркала были успешно перемещены в корзину',
    successRestore: 'Зеркала были успешно восстановлены',
    successPlay: 'Зеркала запущены',
    successStop: 'Зеркала остановлены',
    successMeta: 'Пользовательский META tags сохранены',
    successCustomJs: 'Пользовательский JS сохранен'
  }
}
