<template>
  <div class="PageAnalytics page-full-fluid-width parent_tooltip">
    <div class="row">
      <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
        <div class="card">
          <div class="card-body pt-0">
            <BaseRouterTabs :tabs="items"/>
            <div class="tab-content">
              <router-view/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseRouterTabs from '@/components/base/BaseRouterTabs.vue'

export default {
  name: 'Layout',
  components: {BaseRouterTabs},
  data () {
    return {
      items: [
        { to: {name: 'AnalyticsGroups'}, label: 'analytics.analyticsGroups' },
        { to: {name: 'AnalyticsUniques'}, label: 'analytics.analyticsUniques' }
      ]
    }
  }
}
</script>
