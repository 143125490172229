<template>
  <div class="UrlSelectFilter item_silter_sjhf_g checker-select">
    <BaseSelect
      :endpoint="endpoint"
      :placeholder="placeholder"
      @input="onInput"
      :value="value"
      :filter="filter"
      :filter-id="filterId"
      :disabled="disabled"
    >
    </BaseSelect>
  </div>
</template>
<script>

import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  name: 'UrlSelectFilter',
  components: { BaseSelect },
  props: {
    value: String,
    endpoint: String,
    label: String,
    id: String,
    filterId: String,
    disabled: Boolean
  },
  computed: {
    filter () {
      return {
        'range[begin]': this.$store.getters['analytics/range'].begin,
        'range[end]': this.$store.getters['analytics/range'].end
      }
    },
    cacheLabel () {
      return localStorage.getItem(`${this.id}-${this.value}`)
    },
    placeholder () {
      const self = this
      return this.$t(`analytics.filtersLabel.${self.id.split('-')[0]}`, {param: self.id.split('-')[1]})
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>
