import service from '@/store/services/push-templates-service'
import middleware from './middleware'

const state = () => ({
  count: 0,
  models: [],
  model: null,
  lastPath: '',
  filterModels: []
})

const mutations = {
  setData (state, { models, count }) {
    state.models = models
    state.count = count
  },
  setModel (state, { template }) {
    state.model = template
  },
  setLastPath: (state, payload) => {
    state.lastPath = payload
  },
  setFilterModels: (state, payload) => {
    state.filterModels = payload.models
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create ({commit, dispatch}, params) {
    return service.create(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  read ({commit, dispatch}, params) {
    return service.read(params)
      .then(res => {
        commit('setModel', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  delete ({commit}, params) {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  copy ({commit, dispatch}, params) {
    return service.copy(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  upload: ({commit, dispatch}, params) => {
    return service.upload(params)
      .then(res => {
        commit('setLastPath', res)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  removeImage: ({commit, dispatch}, params) => {
    return service.removeImage(params)
      .then(() => {
        commit('setLastPath', '')
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  filter: ({commit, dispatch}, params) => {
    return service.filter(params)
      .then((res) => {
        commit('setFilterModels', res)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  model: state => state.model,
  statuses: state => state.statuses,
  lastPath: state => state.lastPath,
  filterModels: state => state.filterModels
}

const adminDomains = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminDomains
