import create from './create/en'
/* eslint-disable no-template-curly-in-string */
export default {
  title: 'Mirrors',
  headers: {
    domain: 'Domain Mirror',
    domainInfo: 'Domain name of your Mirror',
    expired: 'Deleting',
    expiredInfo: 'Time to automatically, permanently deleting the Mirror'
  },
  trash: {
    title: 'Mirror`s trash',
    move: 'Move to trash',
    empty: 'Trash empty',
    moveMessage: 'Are you sure you want to trash Mirror {name}? You will have 2 days to recover',
    moveButton: 'To trash',
    moveSuccess: 'The Mirror has been successfully moved to trash',
    restore: 'Restore the Mirror',
    restoreTitle: 'Restore Mirror {name}?',
    restoreMessage: 'Are you sure you want to restore Mirror {name}?',
    restoreButton: 'Restore',
    restoreSuccess: 'The Mirror has been successfully restored',
    goToMirrors: 'Go to Mirrors'
  },
  createNew: 'Create Mirror',
  pwaName: 'PWA Name',
  pwaTitle: 'Name of your PWA',
  mirrorName: 'Name',
  mirrorTitle: 'Name of your Mirror',
  status: 'Status',
  statusTitle: 'Status of your Mirror',
  tags: 'Tags PWA',
  tagsTitle: 'PWA destination tags',
  trackerHead: 'Tracker',
  trackerTitle: 'Information about the current tracker connected to Mirror',
  add: 'Add',
  filters: {
    search: 'Search by domain',
    tag: 'Filter by tag',
    addTag: 'Add a new tag',
    status: 'Filter by status',
    vertical: 'Vertical filter',
    category: 'Filter by Category',
    pwa: 'Filter by PWA'
  },
  buttons: {
    checkDomain: 'Check domain',
    run: 'Run',
    stop: 'Stop',
    pay: 'Pay',
    preview: 'Preview',
    cloak: {
      buttonTitle: 'Download JS script',
      title: 'JS to use Cloak',
      body: `Add this JS code at the end of the <body> tag section before the closing </body> tag`
    },
    customJs: {
      buttonTitle: 'Insert your JS script',
      title: 'Custom JS',
      body: 'This JS code will be added to your Mirror',
      bulkBody: 'This JS code will be added to your Mirrors',
      success: 'Custom JS saved'
    },
    meta: {
      buttonTitle: 'Insert your META tags',
      title: 'Custom META tags',
      body: 'This META tags will be added to your Mirror',
      bulkBody: 'This META tags will be added to your Mirrors',
      success: 'Custom META tags saved'
    }
  },
  checkDomain: {
    title: 'Domain Check',
    body: [
      'Your domain has not yet been verified. Domain verification can take up to 2 hours',
      'Domain <strong>{domain}</strong> does not have https protocol. For correct work enable https Rewrite',
      'Domain <strong>{domain}</strong> verified',
      'Domain <strong>{domain}</strong> is not wrapped in <strong>CloudFlare</strong>'
    ]
  },
  delete: {
    title: 'Remove Mirror',
    message: 'Are you sure you want to remove Mirror {name}?',
    button: 'Delete',
    success: 'Mirror Removed'
  },
  copy: {
    title: 'Copy Mirror',
    message: 'Are you sure you want to copy Mirror {name}?',
    button: 'Copy',
    success: 'Mirror copied',
    tracker: {
      message: 'Copy {name} with or without tracker?',
      okButton: 'With tracker',
      cancelButton: 'No tracker'
    }
  },
  paid: {
    title: 'Mirror Activation',
    warning: 'After Mirror activation, it is impossible to change the domain!',
    button: 'Activate',
    success: 'Mirror activated',
    cancel: 'Activation cancelled',
    error: 'Activation error',
    noTariff: 'A tariff must be issued to work with Mirror',
    paidTariff: `You have one activation in the tariff. After activating this Mirror, you will have no activations left. Activate Mirror?
    | You have {count} activations in the plan. After activating this Mirror, you will have one activation left. Activate Mirror?
    | You have {count} activations in the tariff. After activating this Mirror, you will have {countLeft} activations left. Activate Mirror?`,
    paidBalance: 'You have run out of activations in the tariff, you can activate Mirrors above the tariff for ${amount}.',
    noMoney: 'You have run out of activations in the tariff, you can activate Mirror above the tariff for ${amount}, or switch to a higher tariff. For this, it is necessary to top up the balance.',
    paidInstalls: 'Activation in the installation plan is free, but there will be a charge for each Mirror installation'
  },
  play: {
    title: 'Launch Mirror',
    message: 'Are you sure you want to run Mirror {name}?',
    button: 'Start',
    success: 'Mirror launched'
  },
  stop: {
    title: 'Stop Mirror',
    message: 'Are you sure you want to stop Mirror {name}?',
    button: 'Stop',
    success: 'Mirror stopped'
  },
  notFoundByFilters: 'No Mirrors found matching filter criteria',
  noHave: 'You don\'t have any Mirrors created yet. Click the "Add"',
  insufficientFunds: 'Not enough funds',
  save: 'Mirror saved',
  verticals: ['Nutra', 'Betting', 'Dating', 'Gambling', 'Trading', 'Crypto'],
  statuses: ['Blocked', 'Domain Check', 'HTTPs Check', 'Not Paid', 'Stopped', 'Working', 'Technical stop'],
  categories: [
    'Automobiles and transport',
    'Libraries and demos',
    'Business',
    'Video players and editors',
    'Art and Design',
    'Food and drinks',
    'Games',
    'Tools',
    'Housing and home',
    'Health and Fitness',
    'Acquaintance',
    'Maps and Navigation',
    'Books',
    'Comics',
    'The beauty',
    'Motherhood and childhood',
    'The medicine',
    'Events',
    'Music',
    'News and magazines',
    'Education',
    'Lifestyle',
    'Communication',
    'Personalization',
    'Weather',
    'Purchases',
    'Travels',
    'Work',
    'Entertainment',
    'Social',
    'Sport',
    'Life style',
    'Finance',
    'Photo'
  ],
  create,
  group: {
    successRemove: 'The Mirrors have been successfully removed',
    successCopy: 'The Mirrors have been successfully copied',
    successTrash: 'The Mirrors have been successfully moved to trash',
    successRestore: 'The Mirrors have been successfully restored',
    successPlay: 'Mirrors are launched',
    successStop: 'Mirrors have been stopped',
    successMeta: 'Custom META tags saved',
    successCustomJs: 'Custom JS has been saved'
  }
}
