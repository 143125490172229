import Layout from './Layout.vue'
import {Dates, Uniques, Countries, PepperA, WelcomePWP} from './pages'

export default (meta, header) => {
  return [
    {
      path: 'statistics',
      name: 'Statistics',
      components: {default: Layout, header: header},
      meta: meta,
      redirect: '/',
      children: [
        {
          path: 'dates',
          name: 'StatisticDates',
          components: {default: Dates, header: header},
          meta: meta
        },
        {
          path: 'uniques',
          name: 'StatisticUniques',
          components: {default: Uniques, header: header},
          meta: meta
        },
        {
          path: 'countries',
          name: 'StatisticCountries',
          components: {default: Countries, header: header},
          meta: meta
        },
        {
          path: 'pepper/a',
          name: 'StatisticPepperA',
          components: {default: PepperA, header: header},
          meta: meta
        },
        {
          path: 'welcomep/wp',
          name: 'StatisticWelcomepWp',
          components: {default: WelcomePWP, header: header},
          meta: meta
        }
      ]
    }
  ]
}
