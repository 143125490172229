import messagesService from '@/store/services/messages-service'
import middleware from './middleware'

const state = () => ({
  mails: [],
  notifications: []
})

const mutations = {
  socket_getMessages (state, {mails, notifications}) {
    state.mails = mails
    state.notifications = notifications
  },
  update (state, {id, ...payload}) {
    let index = state.notifications.findIndex(notification => notification.id === id)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        state.notifications[index][prop] = value
      }
    }
    index = state.mails.findIndex(mail => mail.id === id)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        state.mails[index][prop] = value
      }
    }
  }
}

const actions = {
  send ({commit}, {id, title, body}) {
    return messagesService
      .send({id, title, body})
      .then(() => { })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  sendAll ({commit}, {title, body, filter}) {
    return messagesService
      .sendAll({title, body, filter})
      .then(() => { })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit}, {id, ...payload}) {
    return messagesService
      .update(id, payload)
      .then(() => {
        commit('update', {id, ...payload})
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  messages: state => state.mails.concat(state.notifications).reverse(),
  notifications: state => state.notifications,
  mails: state => state.mails
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
