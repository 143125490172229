export default {
  menu: {
    keys: 'Chaves',
    offers: 'Ofertas'
  },
  heads: {
    key: {label: 'Chave', title: 'Chave para ativação do bot'},
    member: {label: 'Web', title: 'Web, que está a usar esta chave para acesso'},
    date: {label: 'Data', title: 'Data da última atualização da chave'}
  },
  alert: {
    copy: 'Chave {key} foi adicionada à área de transferência',
    delete: 'Chave <strong>{key}</strong> para Web <strong>{username}</strong> foi apagada'
  },
  confirm: {
    delete: {
      title: 'Apagar chave',
      message: 'Tem certeza que quer apagar as licenças de chave <strong>{key}</strong> para Web <strong>{username}</strong>?',
      button: 'Apagar'
    }
  },
  latinAmerica: 'América Latina',
  america: 'América',
  europe: 'Europa',
  asia: 'Ásia',
  australiaAndOceania: 'Austrália e Oceânia',
  addOffer: 'Adicione uma oferta'
}
