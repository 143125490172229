<template>
  <div class="modal-footer">
    <button v-if="processing" type="submit" class="btn btn-success btn-wf" disabled="disabled">
      <div class="dot-opacity-loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <button v-else @click="handlerSave" type="submit" class="btn btn-success btn-wf"><i class="icon-disc"></i> Сохранить</button>
    <button @click="handlerCancel" class="btn btn-info btn-wf" :disabled="processing"><i class="icon-shield"></i> Отмена</button>
  </div>
</template>
<script>
export default {
  name: 'SubmitModalForm',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    processing () {
      return this.value
    }
  },
  methods: {
    handlerSave () {
      this.$emit('input', true)
      this.$emit('save', true)
    },
    handlerCancel () {
      if (!this.processing) {
        this.$emit('cancel', true)
      }
    }
  }
}
</script>
<style scoped>
.modal-footer > .btn-success > .dot-opacity-loader {
  width: 73px;
  height: 22px;
}

.modal-footer .btn-success .dot-opacity-loader span {
  width: 10px;
  height: 10px;
  margin: 0 5px;
}
</style>
