import ActionsPush from './_form.vue'

export default (meta, header) => {
  return [
    {
      path: 'pwas/pushes/actions/create',
      name: 'ActionsPushCreate',
      components: {default: ActionsPush, header: header},
      meta: meta
    },
    {
      path: 'pwas/pushes/actions/:id',
      name: 'ActionsPushUpdate',
      components: {default: ActionsPush, header: header},
      meta: meta
    }
  ]
}
