import Vouchers from './Vouchers.vue'

export default (meta, header) => {
  return [
    {
      path: 'tariffs/vouchers',
      name: 'AdminTariffsVouchers',
      components: {default: Vouchers, header},
      meta
    }
  ]
}
