<template>
  <div class="card-body pt-0">
    <div>
      <confirm-modal ref="confirmModal" id="confirm-modal"></confirm-modal>
      <div class="mt-3 mb-3">
        <div class="d-lg-flex">
          <div class="flex-grow-1">
            <div class="item_filt_g row_filters grid_items_filter">
              <div class="item_silter_sjhf_g">
                <input type="text" class="form-control" id="text" tabindex="0" name="text"
                      v-model="filter.name"
                      :placeholder="$t('pushes.filters.name')" v-on:keydown.enter.prevent/>
              </div>
              <div class="item_silter_sjhf_g">
                <BaseSelect
                  v-model="filter.status"
                  id="filter.status"
                  :placeholder="$t('pushes.filters.status')"
                  :options="pushStatusesOptions"
                />
              </div>
              <range-filter v-model="range" :clearable="true"
                            class="item_silter_sjhf_g"/>

              <div class="item_silter_sjhf_g">
                <BaseSelect
                  v-model="filter.tags"
                  id="filter.tags"
                  :multiple="true"
                  :taggable="true"
                  :placeholder="$t('pwas.filters.tag')"
                  :options="filter.tags"
                  :create-option="option => option"
                />
              </div>
              <div class="item_silter_sjhf_g">
                <BaseSelect
                  v-model="filter.type"
                  id="filter.type"
                  :placeholder="$t('pushes.filters.type')"
                  :options="typesOptions"
                />
              </div>
              <div class="item_silter_sjhf_g">
                <BaseSelect
                  v-model="filter.conversions"
                  id="filter.conversions"
                  :placeholder="$t('pushes.filters.conversions.placeholder')"
                  :options="conversionsOptions"
                />
              </div>
              <BaseSelect
                v-model="filter.pwas"
                id="filter.pwas"
                :placeholder="$t('pushes.filters.pwa')"
                endpoint="/pwas/pushes/pwas"
              />
              <BaseSelect
                v-model="filter.countries"
                id="match.countries"
                :placeholder="$t('pushes.filters.country')"
                endpoint="/pwas/pushes/countries"
                :options="countriesOptions"
              />
            </div>
          </div>
          <div class="ml-lg-2 mt-2 mt-lg-0 mobile-grid-2 col-lg-2 p-0">
            <router-link
              v-if="accesses.pushes.update"
              class="btn_green_g mb-2 w-100" tag="button"
              :to="{name: 'PWAsPushCreate'}">
              {{ $t('pushes.add') }}
            </router-link>
            <button class="btn_green_g w-100" type="button" @click="fetchPage">{{
                $t('pushes.reload')
              }}
            </button>
          </div>
        </div>
        <hr class="mb-4"/>
        <div class="col-xl-10 col-lg-10 col-md-8 col-sm-12 pl-0 pr-0">
          <div class="row">
            <GroupAction
              v-if="accesses.pushes.delete && models.length > 0"
              :value="group"
              @input="handleActionGroup"
              :class="[
                  'col-xl-4 col-lg-4 col-md-6 col-sm-12 form-group mb-2',
                  group.length < 1 ? 'disabled' : ''
                ]"
              remove
              copy
            />
          </div>
        </div>
      </div>
      <loading v-model="loading" class="wrapper-table">
        <div class="table-responsive">
          <table class="table table-hover">
            <table-sort-head :items="heads" v-model="sort">
              <template v-slot:checkAll>
                <th>
                  <div class="form-check form-check-primary mb-0 mt-1">
                    <label class="form-check-label ml-1">
                      <input type="checkbox" class="form-check-input" v-model="checkAll" id="checkAll">
                      <green-checkbox-icon :isChecked="checkAll" style="margin-top: 3px;"/>
                    </label>
                  </div>
                </th>
              </template>
            </table-sort-head>
            <tbody>
            <tr role="row" v-for="model in models" :key="model.id">
              <td>
                <div class="form-check form-check-primary mb-1">
                  <label class="form-check-label ml-1">
                    <input
                      :id="`check-${model.id}`"
                      @change="handlerGroup(model.id)" :value="model.id"
                      type="checkbox"
                      class="form-check-input"
                      v-model="model.checked"
                    >
                    <green-checkbox-icon :isChecked="model.checked"/>
                  </label>
                </div>
              </td>
              <td class="thumb_usegb">
                <img :src="model.icon | APIImage" alt="image">
              </td>
              <td class="name_tbs_g">{{ model.name }}</td>
              <td class="name_tbs_g">
                <ul class="tags_link_list">
                  <li v-for="tag of model.tags" :key="tag">
                    <a @click="filter.tags = [tag]">
                      <span>{{ tag }}</span>
                    </a>
                  </li>
                </ul>
              </td>
              <td class="status_lab_g">
                <span :class="`text-${model.status.class}`">{{ $t(model.status.label) }}</span>
              </td>
              <td class="status_lab_g">
                <span class="text-info">{{ $t(`pushes.types.${model.type}`) }}</span>
              </td>
              <td class="name_tbs_g text-left" :data-original-title="$t('pushes.table.publishedAt', {time: $options.filters.formatDate(model.publishedAt)})"
                  data-toggle="tooltip">
                  TZ: {{ model.timezone || 'Europe/London' }}<br>{{model.publishedAtLocal}}
              </td>
              <td v-if="model.countries.length > 0" class="name_tbs_g text-center">
                <i class="flag-icon" v-for="(country, index) of model.countries" :key="country"
                  :class="index < 0 ? getFlagIcon(country) : `${getFlagIcon(country)} ml-1`"
                  data-toggle="tooltip"
                  :title="getCountryName(country)" style="cursor: pointer"></i>
              </td>
              <td v-else class="name_tbs_g text-center">{{ $t('pushes.heads.countries.all') }}</td>
              <td class="name_tbs_g text-center">{{ model.total }}</td>
              <td class="name_tbs_g text-center">{{ model.remaining }}</td>
              <td class="name_tbs_g text-center">{{ model.delivered }}</td>
              <td class="name_tbs_g text-center">{{ model.showed }}</td>
              <td class="name_tbs_g text-center">{{ model.clicked }}</td>
              <td class="name_tbs_g text-center">{{ calculateCTR(model) }}%</td>
              <td class="name_tbs_g text-center">{{ model.unsubscribed }}</td>
              <td class="name_tbs_g text-center">{{ model.registered }}</td>
              <td class="name_tbs_g text-center">{{ model.deposited }}</td>
              <td class="actiosn_ahgg text-right">
                <div class="flex_actions_ghg d_flex">
                  <div
                    v-if="accesses.pushes.update"
                    class="item_butt_act_g"
                    data-toggle="tooltip"
                    :data-original-title="$t('general.edit')">
                    <router-link class="d_flex align_center justify_center btn_icon_rounded update_btn_icon"
                                :to="getUpdateRoute(model)">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.5 4.43001C15.5006 4.33131 15.4817 4.23346 15.4443 4.14208C15.407 4.0507 15.352 3.96759 15.2825 3.89751L12.1025 0.717513C12.0324 0.648001 11.9493 0.593008 11.8579 0.555684C11.7666 0.518361 11.6687 0.499442 11.57 0.500013C11.4713 0.499442 11.3735 0.518361 11.2821 0.555684C11.1907 0.593008 11.1076 0.648001 11.0375 0.717513L8.91501 2.84001L0.717513 11.0375C0.648001 11.1076 0.593008 11.1907 0.555684 11.2821C0.518361 11.3735 0.499442 11.4713 0.500013 11.57V14.75C0.500013 14.9489 0.57903 15.1397 0.719682 15.2803C0.860335 15.421 1.0511 15.5 1.25001 15.5H4.43001C4.53496 15.5057 4.63993 15.4893 4.73813 15.4518C4.83632 15.4144 4.92555 15.3567 5.00001 15.2825L13.1525 7.08501L15.2825 5.00001C15.351 4.92732 15.4067 4.84366 15.4475 4.75251C15.4547 4.69273 15.4547 4.63229 15.4475 4.57251C15.451 4.5376 15.451 4.50242 15.4475 4.46751L15.5 4.43001ZM4.12251 14H2.00001V11.8775L9.44751 4.43001L11.57 6.55251L4.12251 14ZM12.6275 5.49501L10.505 3.37251L11.57 2.31501L13.685 4.43001L12.6275 5.49501Z"
                          fill="white"/>
                      </svg>
                    </router-link>
                  </div>
                  <div v-if="accesses.pushes.update && !model.parentId" class="item_butt_act_g">
                    <button type="button" class="d_flex align_center justify_center btn_icon_rounded"
                            v-on:click="handlerCopy(model)"
                            data-toggle="tooltip" :data-original-title="$t('general.copy')">
                      <svg width="14" height="18" viewBox="0 0 14 18" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.545 0.75C8.6139 0.757812 8.68178 0.772896 8.7475 0.795H8.815C8.89212 0.83106 8.963 0.879162 9.025 0.9375V0.9375L13.525 5.4375C13.5833 5.4995 13.6314 5.57039 13.6675 5.6475V5.715C13.7112 5.79119 13.7392 5.87533 13.75 5.9625L13.75 12C13.75 12.5967 13.5129 13.169 13.091 13.591C12.669 14.0129 12.0967 14.25 11.5 14.25H10.75V15C10.75 15.5967 10.5129 16.169 10.091 16.591C9.66903 17.0129 9.09674 17.25 8.5 17.25H2.5C1.90326 17.25 1.33097 17.0129 0.909009 16.591C0.487053 16.169 0.25 15.5967 0.25 15L0.25 6C0.25 5.40326 0.487053 4.83097 0.909009 4.40901C1.33097 3.98705 1.90326 3.75 2.5 3.75H3.25V3C3.25 2.40326 3.48705 1.83097 3.90901 1.40901C4.33097 0.987053 4.90326 0.75 5.5 0.75H8.5C8.5 0.75 8.5 0.75 8.545 0.75ZM11.1925 5.25L9.25 3.3075V4.5C9.25 4.69891 9.32902 4.88968 9.46967 5.03033C9.61032 5.17098 9.80109 5.25 10 5.25H11.1925ZM2.5 5.25C2.30109 5.25 2.11032 5.32902 1.96967 5.46967C1.82902 5.61032 1.75 5.80109 1.75 6L1.75 15C1.75 15.1989 1.82902 15.3897 1.96967 15.5303C2.11032 15.671 2.30109 15.75 2.5 15.75L8.5 15.75C8.69891 15.75 8.88968 15.671 9.03033 15.5303C9.17098 15.3897 9.25 15.1989 9.25 15V14.25H5.5C4.90326 14.25 4.33097 14.0129 3.90901 13.591C3.48705 13.169 3.25 12.5967 3.25 12L3.25 5.25H2.5ZM5.5 2.25C5.30109 2.25 5.11032 2.32902 4.96967 2.46967C4.82902 2.61032 4.75 2.80109 4.75 3L4.75 12C4.75 12.1989 4.82902 12.3897 4.96967 12.5303C5.11032 12.671 5.30109 12.75 5.5 12.75H11.5C11.6989 12.75 11.8897 12.671 12.0303 12.5303C12.171 12.3897 12.25 12.1989 12.25 12L12.25 6.75H10C9.40326 6.75 8.83097 6.51295 8.40901 6.09099C7.98705 5.66903 7.75 5.09674 7.75 4.5V2.25L5.5 2.25Z"
                          fill="#2A2E32"/>
                      </svg>
                    </button>
                  </div>
                  <div v-if="accesses.pushes.delete && !model.parentId" class="item_butt_act_g">
                    <button type="button"
                            class="d_flex align_center justify_center btn_icon_rounded remove_butt_g"
                            v-on:click="handlerDelete(model)"
                            data-toggle="tooltip" :data-original-title="$t('general.delete')">
                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
                          fill="white"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </loading>
    </div>
    <div>
      <hr/>
      <NavPaginate
          v-model="page"
          :pageCount="pageCount"
          :inputPage="+inputPage"
          @inputPage="inputPage=$event"
          @pagination="handlerPagination"
          @changePage="debounceChangePage"
          :limit="+limit"
          @limit="limit=$event"
          :count="count"
      >
        <GroupAction
            v-if="accesses.pushes.delete && models.length > 0"
            :value="group"
            @input="handleActionGroup"
            :class="[
              'col-md-auto p-md-0 form-group pagination-input mr-lg-3 mb-3',
              group.length < 1 ? 'disabled' : ''
            ]"
            remove
            copy
        ><label for="">{{ $t('group.pagination') }}</label></GroupAction>
      </NavPaginate>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import * as dictionary from '@pwa.group/pwa.dictionary'
import titleMixin from '@/mixins/title-mixin'
import tooltipMixin from '@/mixins/tooltip-mixin'
import {TableSortHead} from '@/components'
import ConfirmModal from '@/components/modal/Confirm.vue'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import RangeFilter from '../components/RangeFilter.vue'
import Loading from '@/components/Loading.vue'
import GreenCheckboxIcon from '@/components/GreenCheckboxIcon.vue'
import {mapActions, mapGetters} from 'vuex'
import paginationMixin from '@/mixins/pagination-mixin'
import groupMixin from '../mixins/group-mixin'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  name: 'PWAsPushes',
  mixins: [titleMixin, select2AjaxMixin, accessesMixin, paginationMixin, groupMixin, tooltipMixin],
  components: {
    BaseSelect,
    GreenCheckboxIcon,
    TableSortHead,
    ConfirmModal,
    RangeFilter,
    Select2,
    Loading
  },
  title () {
    return this.$t('titles.pushes.pushes')
  },
  data () {
    return {
      sort: '-createdAt',
      heads: [
        {label: ''},
        {label: 'pushes.heads.name.label', title: 'pushes.heads.name.title', sort: 'name'},
        {label: 'pushes.heads.tags.label', title: 'pushes.heads.tags.title'},
        {label: 'pushes.heads.status.label', title: 'pushes.heads.status.title', sort: 'status', class: 'text-center'},
        {label: 'pushes.heads.type.label', title: 'pushes.heads.type.title', class: 'text-center'},
        {
          label: 'pushes.heads.publishedAt.label',
          title: 'pushes.heads.publishedAt.title',
          sort: 'publishedAt',
          class: 'text-center'
        },
        {label: 'pushes.heads.countries.label', title: 'pushes.heads.countries.title', class: 'text-center'},
        {label: 'pushes.heads.total.label', title: 'pushes.heads.total.title', sort: 'total', class: 'text-center'},
        {
          label: 'pushes.heads.remaining.label',
          title: 'pushes.heads.remaining.title',
          sort: 'remaining',
          class: 'text-center'
        },
        {
          label: 'pushes.heads.delivered.label',
          title: 'pushes.heads.delivered.title',
          sort: 'delivered',
          class: 'text-center'
        },
        {label: 'pushes.heads.showed.label', title: 'pushes.heads.showed.title', sort: 'showed', class: 'text-center'},
        {
          label: 'pushes.heads.clicked.label',
          title: 'pushes.heads.clicked.title',
          sort: 'clicked',
          class: 'text-center'
        },
        {label: 'pushes.heads.ctr.label', title: 'pushes.heads.ctr.title', class: 'text-center'},
        {
          label: 'pushes.heads.unsubscribed.label',
          title: 'pushes.heads.unsubscribed.title',
          sort: 'unsubscribed',
          class: 'text-center'
        },
        {
          label: 'pushes.heads.registered.label',
          title: 'pushes.heads.registered.title',
          sort: 'registered',
          class: 'text-center'
        },
        {
          label: 'pushes.heads.deposited.label',
          title: 'pushes.heads.deposited.title',
          sort: 'deposited',
          class: 'text-center'
        },
        {label: ''}
      ],
      filter: {
        name: '',
        status: '',
        owner: '',
        begin: '',
        end: '',
        pwas: '',
        countries: '',
        conversions: '',
        type: '',
        tags: []
      },
      pwaSettings: {},
      countrySettings: {},
      conversionsOptions: [
        {id: 'true', text: this.$t('pushes.filters.conversions.options[0]')},
        {id: 'false', text: this.$t('pushes.filters.conversions.options[1]')}
      ]
    }
  },
  watch: {
    filter: {
      handler () {
        this.fetchPage()
      },
      deep: true
    },
    sort () {
      this.fetchPage()
    }
  },
  computed: {
    ...mapGetters({
      models: 'pwasPushes/models',
      count: 'pwasPushes/count'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    range: {
      get () {
        return [new Date(this.filter.begin), new Date(this.filter.end)]
      },
      set (value) {
        this.filter.begin = value[0]
        this.filter.end = value[1]
      }
    },
    typesOptions () {
      return this.$store.getters['pwasPushes/types'].map(type => ({
        id: type.id,
        text: this.$t(type.label)
      }))
    },
    pushStatusesOptions () {
      return this.$store.getters['pwasPushes/statuses'].map(status => ({
        id: status.id,
        text: this.$t(status.label)
      }))
    },
    countriesOptions () {
      return this.$store.getters['pwasPushes/countriesOptions']
    }
  },
  created () {
    this.pwaSettings = {...this.getSelect2AjaxSettings('pwas/pushes/pwas'), allowClear: true}
    this.countrySettings = {...this.getSelect2AjaxSettings('pwas/pushes/countries'), allowClear: true}
    this.fetchPage()
  },
  methods: {
    ...mapActions({
      asyncData: 'pwasPushes/asyncData',
      alertSuccess: 'alerts/success',
      alertError: 'alerts/error'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.asyncData(options)
      this.initTooltip()
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        page: this.page,
        limit: this.limit,
        sort: this.sort,
        filter: this.filter
      })
    },
    getUpdateRoute (model) {
      if (model.parentId != null) {
        return {name: 'IntervalPushUpdate', params: {id: model.parentId}}
      } else {
        return {name: 'PWAsPushUpdate', params: {id: model.id}}
      }
    },
    async handlerDelete (model) {
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('pushes.delete.title'),
        message: this.$t('pushes.delete.message', {name: model.name}),
        okButton: this.$t('pushes.delete.button')
      })
      if (ok) {
        await this.$store.dispatch('pwasPushes/delete', model.id)
        if (this.pageCount > 1) {
          if (this.models.length === 0) {
            this.page = 1
          } else if (this.count - 1 <= this.page * this.limit) {
            this.page = Math.ceil((this.count - 1) / this.limit)
          }
        }
        await this.alertSuccess(this.$t('pushes.delete.success'))
        this.fetchPage()
      }
    },
    async handlerCopy (model) {
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('pushes.copy.title'),
        message: this.$t('pushes.copy.message', {name: model.name}),
        okButton: this.$t('pushes.copy.button')
      })
      if (ok) {
        await this.$store.dispatch('pwasPushes/copy', {id: model.id})
        this.$nextTick(async () => {
          this.handlerPagination(1)
          await this.$store.dispatch('alerts/success', this.$t('pushes.copy.success'))
        })
      }
    },
    calculateCTR (model) {
      const ctr = +(model.clicked / model.delivered * 100).toFixed(2)
      return ctr || 0
    },
    getCountryName (code) {
      const country = dictionary.countries.find((v) => v.id === code)
      return this.$t(country.text)
    },
    getFlagIcon (code) {
      return 'flag-icon-' + code.toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
