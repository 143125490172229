<template>
  <div class="BaseRadioButton form-group mb-3">
    <div class="d_flex align_center checkbox_item_g">
      <label class="labek_check_sg" :for="`mode-${name}`">
        <input
          type="radio"
          class="form-qcheck-input"
          :id="`mode-${name}`"
          :name="name"
          :value="value"
          :checked="isChecked"
          @change="$emit('change', $event.target.value)"
        >
        <div class="check_icd_G"></div>
        <span class="txt_chek_xg">{{ label }}</span>
        <span class="txt_chek_xg"></span>
      </label>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BaseRadioButton',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: String,
    name: String,
    label: String,
    modelValue: { default: '' }
  },
  computed: {
    isChecked () {
      return this.modelValue === this.value
    }
  }
}
</script>
