<template>
  <div class="modal modal_center_g modal_tariff_go fade" id="activate-form" tabindex="-1">
    <div class="modal-dialog big_modal_w" role="document">
      <div class="modal-content modal_padd_normal">
        <button type="button" data-dismiss="modal" class="close_sjgs_to d_flex align_center">
          <span aria-hidden="true">x</span>
        </button>
        <div v-if="tariff" class="modal_tarrifs_gg">
          <div class="modal_title_g">{{ $t('tariffs.subscribe', {name: tariff.name[$i18n.locale]}) }}</div>
          <div class="style_descr_go">
            <p class="bigger_p_go">{{ $t('tariffs.contains', {name: tariff.name[$i18n.locale]}) }}</p>
            <ul class="list_green">
              <li>{{ $tc('tariffs.pwas', tariff.pwas, {num: tariff.pwas}) }}</li>
              <li>{{ $t('tariffs.limit', {num: tariff.limit}) }}</li>
              <li>{{ $t('tariffs.month') }}</li>
              <li>{{ tariff.description[$i18n.locale] }}</li>
            </ul>
            <p class="bigger_p_go">{{ $t('tariffs.forStart') }}</p>
            <p v-html="$t('tariffs.messages[0]')"></p>
            <p class="descr_gray_ggg">{{ $t('tariffs.messages[1]') }}</p>
            <div class="form_promocode_go">
              <div class="in_to_pfprm_ft" :class="apiValidationErrors.voucher ? 'has-danger' : ''">
                <label for="voucher">{{ $t('tariffs.promoCode') }}</label>
                <div class="wrap_sins_inpp d_flex align_center">
                  <div class="left_inp_sjs_g">
                    <input type="text" id="voucher" class="form-control select_white_g" name="voucher" v-model="voucher"
                           :class="apiValidationErrors.voucher ? 'form-control-danger' : ''" placeholder="Oks3msdI"
                           @focus="clearError('voucher')"/>
                  </div>
                  <div class="submit_right_sihg_g">
                    <button class="border_butt_green d_flex align_center justify_center" type="button"
                            @click="handlerVoucher">
                            <span class="icon_prim_cegg">
                              <svg class="d_flex" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.4173 9.27148V9.99981C17.4163 11.707 16.8635 13.3681 15.8414 14.7354C14.8192 16.1027 13.3824 17.103 11.7453 17.5871C10.1082 18.0711 8.35848 18.013 6.75711 17.4214C5.15573 16.8297 3.7885 15.7363 2.85933 14.3042C1.93015 12.872 1.48882 11.1779 1.60115 9.47439C1.71347 7.77092 2.37344 6.1494 3.48262 4.85166C4.59181 3.55392 6.09077 2.64949 7.75597 2.27326C9.42116 1.89703 11.1634 2.06916 12.7227 2.76398" stroke="#21BF73" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.4167 3.6665L9.5 11.5911L7.125 9.21609" stroke="#21BF73" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </span>
                            {{ $t('general.apply') }}
                    </button>
                  </div>
                </div>
                <label v-if="apiValidationErrors.voucher" class="error text-danger" for="voucher">
                  {{ apiValidationErrors.voucher[0] }}</label>
              </div>
            </div>
          </div>
          <div class="foot_payment_to_go">
            <div class="d_flex align_center dj_djghjd_g clearfix">
              <div class="item_jwejweffew">
                <div class="tit_soghs">{{ $t('tariffs.amount') }}</div>
                <div class="final_paym_jsg_G">{{ total | amount }}
                </div>
              </div>
              <div class="item_jwejweffew">
                <div class="in_topshgdjf_g d_flex align_center">
                  <div class="item_avt_butt_jg">
                    <button class="ful_butt_green d_flex align_center justify_center" type="button" @click="subscribe">
                      {{ $t('tariffs.pay') }}
                    </button>
                  </div>
                  <div class="item_avt_butt_jg">
                    <a href="javascript:void(0);" class="border_butt_green d_flex align_center justify_center"
                       @click="hide">{{ $t('general.cancel') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'

export default {
  name: 'Activate',
  mixins: [formMixin],
  data: () => ({
    tariff: false,
    voucher: '',
    saleCost: 0,
    types: {
      individual: 'Индивидуальный',
      command: 'Командный',
      commercial: 'Коммерческий'
    }
  }),
  computed: {
    total: function () {
      return this.saleCost > 0 ? this.saleCost : this.tariff.cost
    }
  },
  methods: {
    show: function (tariff) {
      this.voucher = ''
      this.saleCost = 0
      this.resetApiValidation()
      if (tariff) {
        this.tariff = tariff
        // eslint-disable-next-line no-undef
        jQuery('#activate-form').modal('show')
      }
    },
    hide: function () {
      this.tariff = false
      // eslint-disable-next-line no-undef
      jQuery('#activate-form').modal('hide')
    },
    subscribe: async function () {
      try {
        await this.$store.dispatch('tariffs/subscribe', {
          id: this.tariff.id,
          voucher: this.voucher
        })
        const { type } = this.tariff
        const identity = this.$store.getters['identity']
        if (type === this.types.individual) {
          this.$store.dispatch('setItem', {key: 'identity', payload: {...identity, team: false}})
        }
        if (
          type === this.types.command ||
          type === this.types.commercial
        ) {
          this.$store.dispatch('setItem', {key: 'identity', payload: {...identity, team: true}})
        }
        await this.$store.dispatch('alerts/success', this.$t('tariffs.success'))
        this.$parent.$parent.asyncData()
      } catch (e) {
        await this.$store.dispatch('alerts/error', e.response.data)
      }
      this.hide()
    },
    handlerVoucher: async function () {
      if (this.voucher === '') {
        this.apiValidationErrors = {voucher: [this.$t('tariffs.voucherRequired')]}
      } else {
        try {
          this.resetApiValidation()
          await this.$store.dispatch('tariffs/voucher', {
            id: this.tariff.id,
            voucher: this.voucher
          })
          this.resetApiValidation()
          this.saleCost = this.$store.getters['tariffs/voucher']
        } catch (error) {
          this.setApiValidation(error.response.data.errors)
        }
      }
    }
  }
}
</script>
