<template>
  <div v-if="!clGeoWhite" class="form-group" :class="apiValidationErrors.redirect ? 'has-danger' : ''">
    <label
      for="redirect">{{
        $t(useMultiGEO ? 'trackers.form.linkDefault' : 'trackers.form.link')
      }}
      <info v-if="useMultiGEO" :message="$t('trackers.form.linkInfo')"/>
    </label>
    <div class="d-flex">
      <div class="flex-grow-1">
        <input type="text" class="form-control" id="redirect" name="redirect"
               :placeholder="$t('trackers.form.link')"
               v-model="redirect" @focus="clearError('redirect')"
               :class="apiValidationErrors.redirect ? 'form-control-danger' : ''">
      </div>
      <div class="flex-grow-0 ml-2">
        <slot />
      </div>
    </div>
    <label v-if="apiValidationErrors.redirect" class="error mt-2 text-danger" for="redirect">
      {{ apiValidationErrors.redirect[0] }}
    </label>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import formMixin from '@/mixins/form-mixin'

export default {
  name: 'RedirectField',
  components: {Info},
  mixins: [formMixin],
  props: {
    clGeoWhite: {
      type: Boolean,
      default: false
    },
    useMultiGEO: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    redirect: {
      get () {
        return this.value
      },
      set (input) {
        this.$emit('input', input)
      }
    }
  },
  watch: {
    errors: {
      handler (n) {
        if (Object.keys(n).length > 0) {
          const errors = {}
          for (const [prop, value] of Object.entries(n)) {
            if (prop === 'redirect') {
              errors[prop] = value
            }
          }
          this.apiValidationErrors = errors
        }
      },
      deep: true
    }
  }
}
</script>
