<template>
  <div class="card-body">
    <h4 class="title_sf_site">{{ $t('tariffs.installations.header') }}</h4>
    <p class="descr_site_shg" v-html="$t('tariffs.installations.text', {tgHelpdesk: tgHelpdesk})">
    </p>
    <div class="tier-container">
      <div class="tier-col">
        <h2 class="tier-title">{{ $t(installations[0].name) }}</h2>
        <div
          v-for="country of installations[0].countries"
          :key="country.name"
          class="tier-item">
          <span>{{ country.name | truncate(18) }}</span>
          <span
            v-html="country.custom ? country.custom : country.rate"
            :data-original-title="country.custom ? `Ціна без знижки ${country.rate}` : ''"
            data-toggle="tooltip"
            :class="{active: country.custom}"
          ></span>
        </div>
      </div>
      <div class="tier-line"></div>
      <div class="tier-col">
        <h2 class="tier-title">{{ $t(installations[1].name) }}</h2>
        <div
          v-for="country of installations[1].countries"
          :key="country.name"
          class="tier-item">
          <span>{{ country.name | truncate(18) }}</span>
          <span
            v-html="country.custom ? country.custom : country.rate"
            :data-original-title="country.custom ? `Ціна без знижки ${country.rate}` : ''"
            data-toggle="tooltip"
            :class="{active: country.custom}"
          ></span>
        </div>
      </div>
      <div class="tier-line"></div>
      <div class="tier-col">
        <h2 class="tier-title">{{ $t(installations[2].name) }}</h2>
        <div
          v-for="country of installations[2].countries"
          :key="country.name"
          class="tier-item">
          <span>{{ country.name | truncate(18) }}</span>
          <span
            v-html="country.custom ? country.custom : country.rate"
            data-toggle="tooltip"
            :data-original-title="country.custom ? `Ціна без знижки ${country.rate}` : ''"
            :class="{active: country.custom}"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import tooltipMixin from '@/mixins/tooltip-mixin'
import titleMixin from '@/mixins/title-mixin'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'InstallationTariffs',
  mixins: [tooltipMixin, titleMixin],
  title: function () {
    return this.$t('tariffs.installations.title')
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      installations: 'tariffs/installations'
    }),
    tgHelpdesk () {
      return import.meta.env.VITE_TELEGRAM_HELPDESK
    }
  },
  created () {
    this.fetchPage()
  },
  methods: {
    ...mapActions({
      getInstallations: 'tariffs/getInstallations'
    }),
    debounceFetchData: _.debounce(async function () {
      await this.getInstallations()
      this.loading = false
      this.initTooltip()
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData()
    }
  }
}
</script>
<style scoped>
.descr_site_shg {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.tier-container {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.tier-line {
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  margin: 0 80px;
}

.tier-title {
  font-size: 20px;
  color: #21BF73;
  line-height: 36px;
}

.tier-col {
  width: 100%;
}

.tier-item {
  margin-top: 1rem !important;
  display: flow-root;
  cursor: pointer;
}

.tier-item span:first-child {
  float: left
}

.tier-item span:last-child {
  float: right;
}

.tier-item span.active {
  color: #F1C62D;
}

@media only screen and (max-width: 992px) {
  .tier-line {
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin: 30px 0 20px;
  }

  .tier-container {
    display: block;
  }
}
</style>
