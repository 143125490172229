<template>
  <div class="content-wrapper">
    <settings-form ref="settingsForm"/>
    <domain-form ref="domainForm"/>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card card-statistics">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <div class="d-flex justify-content-between border-right card-statistics-item">
                  <div>
                    <h1>{{ statisticCountAll }}</h1>
                    <p class="text-muted mb-0">Доменов всего</p>
                  </div>
                  <i class="icon-layers text-primary icon-lg"></i>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="d-flex justify-content-between border-right card-statistics-item">
                  <div><h1>{{ statisticNotSoldCount > statisticCountAll ? 0 : statisticNotSoldCount }}</h1>
                    <p class="text-muted mb-0">Осталось</p>
                  </div>
                  <i class="icon-pin text-primary icon-lg"></i>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="d-flex justify-content-between border-right card-statistics-item">
                  <div><h1>{{ statisticSales }}</h1>
                    <p class="text-muted mb-0">Продано</p>
                  </div>
                  <i class="icon-calculator text-primary icon-lg"></i>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="d-flex justify-content-between card-statistics-item">
                  <div>
                    <h1>{{ statisticAmount | amount }}</h1>
                    <p class="text-muted mb-0">Оборот</p>
                  </div>
                  <i class="icon-refresh text-primary icon-lg"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Домены</h4>
            <p class="card-description">Управления доменами</p>
            <div class="mb-2">
              <button @click="handlerCreate" type="button" class="btn btn-outline-success btn-fw">
                <i class="icon-plus"></i> Створити
              </button>
              <button
                @click="$refs.settingsForm.handlerOpen()"
                type="button" class="btn btn-outline-secondary btn-fw">
                <i class="icon-settings"></i> Налаштування
              </button>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg mb-2">
                <select v-model="filter.status" class="form-control">
                  <option value="" selected>Фильтр по статусу</option>
                  <option v-for="(label, value) of statuses" :key="value" :value="value">{{ label }}</option>
                </select>
              </div>
              <div class="col-md-6 col-lg mb-2">
                <input v-model="filter.domain" class="form-control" placeholder="Поиск по домену или сабу"/>
              </div>
              <owner-filter v-model="filter.owner" class="col-md-6 col-lg mb-2"/>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg mb-2">
                <select v-model="filter.cloudflare" class="form-control">
                  <option value="" selected>Фильтр по Cloudflare</option>
                  <option v-for="model of cloudflares" :key="model._id" :value="model._id">{{ model._id }}</option>
                </select>
              </div>
              <select2
                v-model="filter.ip"
                :options="ipsOptions"
                :settings="{allowClear: true}"
                class="col-md-6 col-lg mb-2"
                id="ip"
                name="ip"
                inputClass="form-control"
                placeholder="Поиск по IP"
              />
              <div class="col-md-6 col-lg mb-2">
                <select v-model="filter.self" class="form-control">
                  <option value="" selected>Все домены</option>
                  <option value="general">Только общие</option>
                  <option value="self">Только личные</option>
                </select>
              </div>
            </div>
            <loading v-model="loading" class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th>Статус</th>
                  <th>Домен</th>
                  <th>NS</th>
                  <th style="min-width: 220px;">Владелец</th>
                  <th>Cloudflare</th>
                  <th>IP</th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model._id">
                  <td>
                    <button @click="handlerUpdate(model)" class="btn btn-icons btn-inverse-primary">
                      <i class="icon-pencil"></i>
                    </button>
                    <button @click="handlerDelete(model)" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                  </td>
                  <td>{{ statuses[model.status] }}</td>
                  <td>{{ model._id }} ({{ model.subs.length }})</td>
                  <td v-html="model.NS.join('<br>')"></td>
                  <td>
                    <table-owner
                      v-if="model.owner"
                      :data="{...model.owner, id: model.owner._id}"
                    />
                    <span v-else class="text-gray">( нет )</span>
                  </td>
                  <td v-html="$options.filters.cloudflareFilter(model.cloudflare)"></td>
                  <td>{{ getIpName(model.ip) }}</td>
                  <td>{{ model.createdAt | dateFromNow }}</td>
                  <td>{{ model.updatedAt | dateFromNow }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td>Всього</td>
                  <td colspan="9">{{ count }}</td>
                </tr>
                </tfoot>
              </table>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {tables, OwnerFilter} from '@/pages/admin/components'
import DomainForm from './_form.vue'
import SettingsForm from '@/components/admin/domains/SettingsForm.vue'
import {mapActions, mapGetters} from 'vuex'
import Loading from '@/pages/admin/components/Loading.vue'
import Select2 from '@/components/Select2.vue'

export default {
  name: 'AdminDomains',
  components: {TableOwner: tables.Owner, DomainForm, SettingsForm, Loading, OwnerFilter, Select2},
  data () {
    return {
      loading: true,
      page: 1,
      limit: 20,
      filter: {
        status: '',
        domain: '',
        owner: '',
        cloudflare: '',
        ip: '',
        self: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      ipsOptions: 'ips/options',
      statisticNotSoldCount: 'adminDomains/notSoldCount',
      statisticCountAll: 'adminDomains/countAll',
      statisticSales: 'adminDomains/sales',
      statisticAmount: 'adminDomains/amount',
      models: 'adminDomains/models',
      count: 'adminDomains/count',
      cloudflares: 'adminDomains/cloudflares',
      statuses: 'adminDomains/statuses'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  filters: {
    cloudflareFilter (input) {
      return input ? input._id : '<span class="text-gray">( нет )</span>'
    }
  },
  async created () {
    for (const prop in this.filter) {
      this.$watch(`filter.${prop}`, function () {
        this.handlerPagination()
      })
    }
    await this.fetchIPs({
      page: 1,
      limit: 100,
      sort: 'id'
    })
    this.handlerPagination(1)
  },
  methods: {
    ...mapActions({
      fetchIPs: 'ips/adminFetchSelect2',
      fetchData: 'adminDomains/asyncData',
      delete: 'adminDomains/delete'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        limit: this.limit,
        page: this.page,
        sort: '-createdAt',
        filter: this.filter
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    getIpName (input) {
      const index = this.ipsOptions.findIndex(({id}) => id === input)
      return index === -1 ? input : this.ipsOptions[index].text
    },
    async handlerCreate () {
      if (await this.$refs.domainForm.handlerCreate()) {
        await this.handlerPagination(1)
      }
    },
    async handlerUpdate (model) {
      if (await this.$refs.domainForm.handlerUpdate(model)) {
        await this.handlerPagination(1)
      }
    },
    async handlerDelete (model) {
      if (model.status === 'USING' || model.status === 'HIDE') {
        alert('Этот домен уже используется, его нельзя удалять!')
      } else {
        if (confirm(`Удалить домен ${model._id}`)) {
          await this.delete(model._id)
          await this.handlerPagination(1)
        }
      }
    }
  }
}
</script>
