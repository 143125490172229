<template>
  <div v-if="messagesToShow.length > 0" class="HeaderMessages header-messages">
    <div class="message-wrap" v-for="message in activeMessage" :key="message.id">
      <div class="message-wrap__icon">
        <InlineSvg src="/images/svg-icons/icon-info-banner.svg" />
      </div>
      <div class="description">
        <div v-html="message.text" />
        <button
          @click="onMessage(message)"
          class="btn-alert"
        >
          {{ $t('general.familiarized') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'HeaderMessages',
  components: {InlineSvg},
  computed: {
    ...mapState('bannersStore', {
      messages: state => state.messages
    }),
    messagesToShow () {
      return this.messages.filter(item => !item.shown)
    },
    activeMessage () {
      return [this.messagesToShow[0]] // for list animation we need return array
    }
  },
  methods: {
    ...mapMutations('bannersStore', ['hideMessage']),
    onMessage (message) {
      this.hideMessage(message.id)
    }
  }
}
</script>
<style scoped lang="scss">
.header-messages {
  max-width: 1560px;
  margin: 0 20px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.message-wrap {
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;

  &__icon {
    background: #FD5E53;
    display: flex;
    align-items: center;
    padding: 18px 30px;

    svg {
      color: #FFFFFF;
    }
  }
}
.description {
  font-size: 14px;
  line-height: 130%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  gap: 30px;
  background-color: #35383A;

}
.btn-alert {
  color: #707175;
  font-size: 15px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid #707175;
  background: transparent;
  display: inline-block;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
}
.btn-alert:hover {
  background-color: #FFFFFF;
  color: #707175;
}
</style>
