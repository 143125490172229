<template>
  <Modal class="ModalAddDomainMirror modal-mirror" id="ModalAddDomainMirror" ref="ModalAddDomainMirror">
    <button
      type="button"
      @click="onSkip"
      data-dismiss="modal"
      class="close_sjgs_to d_flex align_center"
      :disabled="loading"
    >
      <span aria-hidden="true">x</span>
    </button>
    <h2 class="modal-mirror__title">{{ $t('mirrors.create.title') }}</h2>
    <DomainForm
      v-if="showForm"
      @skip="onSkip"
      @created="onCreated"
      @created-self="onCreatedSelf"
      @processing="onProcessing"
      @rebuild="$emit('rebuild')"
    />
  </Modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import DomainForm from '../forms/DomainForm/DomainForm.vue'

export default {
  name: 'ModalAddDomainMirror',
  components: {
    Modal,
    DomainForm
  },
  data () {
    return {
      showForm: false,
      loading: false
    }
  },
  methods: {
    show () {
      this.$refs.ModalAddDomainMirror.open()
      this.showForm = true
    },
    onSkip () {
      this.$refs.ModalAddDomainMirror.close()
      this.showForm = false
    },
    onCreated () {
      this.$emit('created')
      this.$refs.ModalAddDomainMirror.close()
      this.showForm = false
    },
    onCreatedSelf () {
      this.$emit('created')
    },
    onProcessing (data) {
      this.loading = data
    }
  }
}
</script>
<style scoped lang="sass">
.modal-mirror
  &__title
    margin-bottom: 40px
  :deep(.modal-content)
    padding-bottom: 70px
  :deep(.modal-dialog)
    width: 100%
    max-width: 625px
</style>
