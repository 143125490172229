import service from '@/store/services/admin/domains-service'
import middleware from '../middleware'

const state = () => ({
  count: 0,
  countAll: 0,
  notSoldCount: 0,
  amount: 0,
  sales: 0,
  models: [],
  cloudflares: []
})

const mutations = {
  setData (state, payload) {
    const { models, count, cloudflares, countAll, notSoldCount, amount, sales } = payload
    state.models = models
    state.count = count
    state.cloudflares = cloudflares
    state.notSoldCount = notSoldCount
    state.amount = amount
    state.sales = sales
    state.countAll = countAll
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create ({commit, dispatch}, params) {
    return service.create(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  delete ({commit}, params) {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  cloudflares: state => state.cloudflares,
  statuses: () => ({
    '-1': 'Не прошел проверку',
    0: 'Новый',
    1: 'Обрабатывается',
    2: 'Готовый',
    3: 'Используется',
    4: 'Скрытый',
    5: 'Куплен'
  }),
  notSoldCount: state => state.notSoldCount,
  amount: state => state.amount,
  sales: state => state.sales,
  countAll: state => state.countAll
}

const adminDomains = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminDomains
