<template>
  <div class="head_action_stats_g">
    <hr>
    <div class="mt-2">
      <div class="row justify-content-between">
        <div class="d-flex col-lg-3">
          <div class="d-flex-md align-items-center picker_ranger_wrap w-100">
            <range-filter v-model="range" class="w-100"/>
          </div>
        </div>
        <div class="col-lg-9 mt-2 mt-md-3 mt-lg-0 d-md-flex justify-content-lg-end">
          <div class="d-sm-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <div v-if="group != null" class="flex-grow-1">
              <select class="form-control" v-model="group">
                <option :key="id" v-for="(label, id) of groups" :value="id">
                  {{ label.param != null ? $t("analytics.groupsLabels.param", label) : $t(label) }}
                </option>
              </select>
            </div>
            <div v-if="group == null" class="flex-grow-1">
              <select class="form-control" v-model="limit">
                <option :key="item" v-for="item of [10, 20, 50, 100]" :value="item">
                  {{ $t('analytics.groupsLabels.by') + ' ' + item }}
                </option>
              </select>
            </div>
            <slot/>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div v-if="loading" class="loading_cont_go">
          <div class="in_to_load_sf d_flex justify_center">
            <img src="/images/loader.svg" alt="" width="100">
          </div>
        </div>
        <div v-if="pageCount && !error && !loading" class="wrapper-table no-hover">
          <div class="table-responsive">
            <table class="table table_border_style">
              <table-sort-head :items="head" v-model="sort"/>
              <tbody>
              <tr role="row" class="odd" v-for="(model, index) in models" :key="getID(model, index)">
                <component v-for="(row, index) in rows" :key="row.column"
                           v-bind:is="row.type"
                           :class="index === 0 ? '' : ((Object.keys(rows).length - 1) === index ? 'text-right' : 'text-center')"
                           :value="row.group === 'calculate' ? model : model[row.column]"
                />
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Error v-if="!loading && error" :error="errorChunk" @reload="$emit('reload')"/>
        <div class="no_pwa_here" v-if="!pageCount && !loading && !error">
          <div class="in_to_nothing_pwa text_center">
            <PersonReadyToWork/>
            <div class="texts_pwa_no">
              <div class="text_this_no_pw">{{ $t('analytics.empty') }}</div>
              <div class="butt_nos_g">
                <router-link class="btn_green_g" tag="button" :to="{name: 'PWAs'}">{{
                    $t('pwas.trash.goToPWA')
                  }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <nav v-if="limit && pageCount > 1">
          <paginate ref="paginate"
                    v-model="page"
                    :page-count="pageCount"
                    :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                    :next-text="'<i class=\'icon-arrow-right\'></i>'"
                    :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                    :page-class="'page_item_gd'"
                    :page-link-class="'page_link_g d_flex justify_center align_center'"
                    :prev-class="'page-item_go'"
                    :next-class="'page-item_go'"
                    :prev-link-class="'page-link_go d_flex justify_center align_center'"
                    :next-link-class="'page-link_go d_flex justify_center align_center'"
                    :active-class="'active'">
          </paginate>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import {TableSortHead} from '@/components'
import * as ColumnsComponent from './columns'
import RangeFilter from './filters/RangeFilter.vue'
import PersonReadyToWork from '@/components/animatedSvgs/PersonReadyToWork.vue'
import Error from '@/components/RequestError.vue'
import {mapActions} from 'vuex'

const groups = {
  date: {label: 'analytics.groupsLabels.dateLabel', title: 'analytics.groupsLabels.dateTitle', sort: 'id'},
  tag: {label: 'analytics.groupsLabels.tag', title: 'analytics.groupsLabels.trackerTitle', sort: 'id'},
  host: {label: 'analytics.groupsLabels.hostLabel', title: 'analytics.groupsLabels.hostTitle', sort: 'id'},
  pwa: {label: 'analytics.groupsLabels.pwaLabel', title: 'analytics.groupsLabels.pwaTitle', sort: 'id'},
  country: {label: 'analytics.groupsLabels.countryLabel', title: 'analytics.groupsLabels.countryTitle', sort: 'id'},
  vertical: {label: 'analytics.groupsLabels.verticalLabel', title: 'analytics.groupsLabels.verticalTitle', sort: 'id'},
  userAgentDeviceType: {label: 'analytics.groupsLabels.userAgentDeviceTypeLabel', title: 'analytics.groupsLabels.userAgentDeviceTypeTitle', sort: 'id'},
  userAgentDeviceVendor: {label: 'analytics.groupsLabels.userAgentDeviceVendorLabel', title: 'analytics.groupsLabels.userAgentDeviceVendorTitle', sort: 'id'},
  userAgentDeviceModel: {label: 'analytics.groupsLabels.userAgentDeviceModelLabel', title: 'analytics.groupsLabels.userAgentDeviceModelTitle', sort: 'id'},
  userAgentBrowserName: {label: 'analytics.groupsLabels.userAgentBrowserNameLabel', title: 'analytics.groupsLabels.userAgentBrowserNameTitle', sort: 'id'},
  userAgentOs: {label: 'analytics.groupsLabels.userAgentOsLabel', title: 'analytics.groupsLabels.userAgentOsTitle', sort: 'id'},
  preLandingPage: {label: 'analytics.groupsLabels.preLandingPage', title: 'analytics.groupsLabels.preLandingPageTitle', sort: 'id'},
  postLandingPage: {label: 'analytics.groupsLabels.postLandingPage', title: 'analytics.groupsLabels.postLandingPageTitle', sort: 'id'}
}

export default {
  runtimeCompiler: true,
  name: 'DataTable',
  components: {PersonReadyToWork, TableSortHead, ...ColumnsComponent, RangeFilter, Error},
  props: {
    value: Object,
    models: Array,
    count: Number,
    columns: Object,
    errorAsyncData: Number,
    errorChunk: Number,
    loading: {
      type: Boolean
    }
  },
  computed: {
    parseColumns () {
      return this.value.columns.filter(column => this.columns[column] != null)
    },
    error () {
      return this.errorAsyncData || this.errorChunk
    },
    page: {
      get () {
        return Number(this.value.page)
      },
      set (page) {
        this.$emit('optionsChange', {page})
      }
    },
    range: {
      get () {
        return [new Date(this.value.range?.begin), new Date(this.value.range?.end)]
      },
      set (value) {
        this.$emit('optionsChange', {range: {begin: value[0], end: value[1]}})
      }
    },
    rows () {
      const rows = []
      for (const column of this.parseColumns) {
        if (column === 'id') {
          rows.push({
            column: 'id',
            type: ['country', 'date', 'pwa', 'vertical', 'tracker'].includes(this.group) ? this.group.charAt(0).toUpperCase() + this.group.slice(1) + 'Column' : 'StringColumn',
            group: ''
          })
        } else {
          rows.push({...this.columns[column], column})
        }
      }
      return rows
    },
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    head () {
      const header = this.parseColumns.map(column => {
        if (column === 'id' && this.group != null) {
          return groups[this.group] != null ? groups[this.group] : {
            label: this.group,
            title: 'pagers.analytics.columnsLabel.analyticsBy',
            param: this.group,
            sort: 'id',
            class: 'text-center'
          }
        }
        return {
          ...this.columns[column],
          sort: this.columns[column].group === 'calculate' ? false : column,
          class: 'text-center'
        }
      })
      header[0]['class'] = ''
      if (header.length > 1) {
        header[header.length - 1]['class'] = 'text-right'
      }
      return header
    },
    limit: {
      get () {
        return this.value.limit
      },
      set (limit) {
        this.$emit('optionsChange', {limit})
      }
    },
    group: {
      get () {
        return this.value.group
      },
      set (group) {
        this.$emit('optionsChange', {group})
      }
    },
    sort: {
      get () {
        return this.value.sort
      },
      set (sort) {
        this.$emit('optionsChange', {sort})
      }
    },
    groups () {
      return this.$store.getters['analytics/groups']
    }
  },
  methods: {
    ...mapActions('analytics', [
      'getGroupsBy'
    ]),
    getID (model, index) {
      try {
        return model.id.id != null ? model.id.id : index
      } catch (e) {
        return model.id
      }
    }
  }
}
</script>
