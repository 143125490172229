import index from './index.vue'
import create from './create.vue'
import update from './update.vue'

export default (meta, header) => {
  return [
    {
      path: 'users/:id/tariffs',
      name: 'AdminUserTariffs',
      components: {default: index, header},
      meta
    },
    {
      path: 'users/:id/tariffs/create',
      name: 'AdminUserTariffsCreate',
      components: {default: create, header},
      meta
    },
    {
      path: 'users/:id/tariffs/:tariff',
      name: 'AdminUserTariffsUpdate',
      components: {default: update, header},
      meta
    }
  ]
}
