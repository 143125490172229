<template>
  <div class="in_to_pickerd">
    <input type="text" class="picker_frm_inp form-control" :name="name" :id="name" readonly>
  </div>
</template>
<script>
export default {
  name: 'Dates',
  props: ['name', 'value'],
  watch: {
    value: function () {
      this.init()
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init: function () {
      const self = this
      // eslint-disable-next-line no-undef
      jQuery('#' + self.name).datepicker({
        maxViewMode: 0,
        format: 'dd/mm/yy',
        autoclose: true,
        container: '.main-panel',
        ignoreReadonly: true,
        allowInputToggle: true
      })
        .datepicker('setDate', new Date(self.value))
        .on('changeDate', function () {
          // eslint-disable-next-line no-undef
          self.$emit('input', '20' + jQuery('#' + self.name).datepicker('getFormattedDate').split('/').reverse().join('-'))
        })
    }
  }
}
</script>
