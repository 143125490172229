<template>
  <component :is="tag" class="badge_testd" :class="`text-${type}`">
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'badge',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    type: {
      type: String,
      default: 'default',
      validator: value => {
        let acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger',
          'secondary'
        ]
        return acceptedValues.indexOf(value) !== -1
      }
    }
  }
}
</script>
