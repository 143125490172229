import formatDate from './format-date'
import date from './date'
import formatNull from './format-null'
import truncate from './truncate'
import formatBoolean from './format-boolean'
import APIImage from './api-image'
import dateAgo from './date-ago'
import formatDevice from './format-device'
import formatOS from './format-os'
import formatBrowser from './format-browser'
import formatFlag from './format-flag'
import dateFromNow from './date-from-now'
import percent from './percent'
import amount from './amount'
import number from './number'
import formatMoney from './format-money'
import time from './time'
import localTime from './local-time'

export default {
  install (Vue) {
    Vue.filter('formatDate', formatDate)
    Vue.filter('date', date)
    Vue.filter('formatNull', formatNull)
    Vue.filter('truncate', truncate)
    Vue.filter('formatBoolean', formatBoolean)
    Vue.filter('APIImage', APIImage)
    Vue.filter('dateAgo', dateAgo)
    Vue.filter('formatDevice', formatDevice)
    Vue.filter('formatOS', formatOS)
    Vue.filter('formatBrowser', formatBrowser)
    Vue.filter('formatFlag', formatFlag)
    Vue.filter('dateFromNow', dateFromNow)
    Vue.filter('percent', percent)
    Vue.filter('amount', amount)
    Vue.filter('number', number)
    Vue.filter('formatMoney', formatMoney)
    Vue.filter('time', time)
    Vue.filter('localTime', localTime)
  }
}
