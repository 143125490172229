<template>
  <div class="row">
    <button @click="$router.go(-1)" class="btn btn-icons btn-rounded btn-outline-success col-2"><i class="icon-action-undo"></i></button>
    <nav aria-label="breadcrumb" class="col-10">
      <ol class="breadcrumb bg-dark">
        <li class="breadcrumb-item">
          <router-link :to="{name: 'AdminPWAs'}">PWAs</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{name: 'AdminPWA', param: {id: $route.params.id}}">PWA ({{$route.params.id}})</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page"><span>Трекер</span></li>
      </ol>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>
