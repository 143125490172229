import create from './create/uk'
import components from './components/uk'
import tracker from './tracker/uk'

/* eslint-disable no-template-curly-in-string */
export default {
  headers: {
    domain: 'Домен',
    domainInfo: 'Ім\'я домену вашого PWA',
    expired: 'Видалення',
    expiredInfo: 'Час до автоматичного, безповоротного видалення PWA'
  },
  trash: {
    move: 'Перемістити в кошик',
    empty: 'Кошик PWA порожній',
    moveMessage: 'Ви впевнені, що хочете перенести в кошик PWA {name}? На відновлення у вас буде 2 дні',
    moveMessageRelation: 'Ви впевнені, що хочете перенести в кошик PWA {name}? На відновлення у вас буде 2 дні. Це PWA також буде видалене з Топ ігор!',
    moveButton: 'В кошик',
    moveSuccess: 'PWA успішно перенесено в кошик',
    restore: 'Відновити PWA',
    restoreTitle: 'Відновити PWA {name}?',
    restoreMessage: 'Ви впевнені, що хочете відновити PWA {name}?',
    restoreButton: 'Відновити',
    restoreSuccess: 'PWA успішно відновлено',
    goToPWA: 'Перейти до PWA'
  },
  createNew: 'Створити PWA',
  pwaTitle: 'Назва вашого PWA',
  status: 'Статус',
  statusTitle: 'Статус вашого PWA',
  tags: 'Теги',
  tagsTitle: 'Теги призначення PWA',
  trackerHead: 'Трекер',
  trackerTitle: 'Інформація про поточний трекер підключеного до PWA',
  filters: {
    search: 'Пошук домену',
    tag: 'Фільтр за тегом',
    addTag: 'Додати новий тег',
    status: 'Фільтр за статусом',
    vertical: 'Фільтр за вертикаллю',
    category: 'Фільтр за категорією'
  },
  buttons: {
    checkDomain: 'Перевірити домен',
    run: 'Запустити',
    stop: 'Зупинити',
    pay: 'Оплатити',
    preview: 'Перегляд',
    cloak: {
      buttonTitle: 'Завантажити JS скрипт',
      title: 'JS для використання Cloak',
      body: `Додайте цей JS код в кінці секції тега <body> перед тегом, що закривається </body>`
    },
    customJs: {
      buttonTitle: 'Вставте свій JS скрипт',
      title: 'Користувацький JS',
      body: 'Цей JS код буде додано до вашого PWA',
      bulkBody: 'Цей JS код буде додано до ваших PWA',
      success: 'Користувацький JS збережено'
    },
    meta: {
      buttonTitle: 'Вставте свій META tags',
      title: 'Користувацький META tags',
      body: 'Ці META tags буде додано до вашого PWA',
      bulkBody: 'Ці META tags буде додано до ваших PWA',
      success: 'Користувацький META tags збережено'
    }
  },
  checkDomain: {
    title: 'Перевірка домену',
    body: [
      'Ваш домен ще не перевірено. Перевірка домену може тривати до 2-х годин',
      'Домен <strong>{domain}</strong> не має протоколу https. Для коректної роботи увімкніть https Rewrite',
      'Домен <strong>{domain}</strong> перевірено',
      'Домен <strong>{domain}</strong> не обернений у <strong>CloudFlare</strong>'
    ]
  },
  delete: {
    title: 'Видалити PWA',
    message: 'Ви впевнені, що хочете видалити PWA {name}?',
    button: 'Видалити',
    success: 'PWA видалено'
  },
  copy: {
    title: 'Копіювати PWA',
    message: 'Ви впевнені, що хочете скопіювати PWA {name}?',
    button: 'Копіювати',
    success: 'PWA скопійовано',
    tracker: {
      message: 'Копіювати {name} з трекером чи без?',
      okButton: 'З трекером',
      cancelButton: 'Без трекера'
    }
  },
  paid: {
    title: 'Активація PWA',
    warning: 'Після активації PWA змінити домен неможливо!',
    button: 'Активувати',
    success: 'PWA активовано',
    cancel: 'Активація скасована',
    error: 'Помилка активації',
    noTariff: 'Для роботи з PWA необхідно оформити тариф',
    paidTariff: `У вас є одна активація в тарифі. Після активації цього PWA у вас не залишиться активацій. Активувати PWA?
    | У вас є {count} активації в тарифі. Після активації цього PWA у вас залишиться одна активація. Активувати PWA?
    | У вас є {count} активацій в тарифі. Після активації цього PWA у вас залишиться {countLeft} активації. Активувати PWA?
    | У вас є {count} активацій в тарифі. Після активації цього PWA у вас залишиться {countLeft} активацій. Активувати PWA?`,
    paidBalance: 'У вас закінчилися активації в тарифі, ви можете активувати PWA понад тарифа за ${amount}.',
    noMoney: 'У вас закінчилися активації в тарифі, ви можете активувати PWA понад тарифа за ${amount}, або перейти на тариф вище. Для цього необхідно поповнити баланс.',
    paidInstalls: 'Активація в тарифі установок безкоштовна, але за кожну встановлення PWA стягуватиметься плата'
  },
  play: {
    title: 'Запустити PWA',
    message: 'Ви впевнені, що хочете запустити PWA {name}?',
    button: 'Запустити',
    success: 'PWA запущено'
  },
  stop: {
    title: 'Зупинити PWA',
    message: 'Ви впевнені, що хочете зупинити PWA {name}?',
    button: 'Зупинити',
    success: 'PWA зупинено'
  },
  notFoundByFilters: 'Не знайдено PWA, що відповідають умовам фільтрації',
  noHave: 'У Вас ще немає створених PWA. Натисніть кнопку "Створити PWA"',
  insufficientFunds: 'Недостатньо коштів',
  save: 'PWA збережено',
  verticals: ['Нутра', 'Бетинг', 'Дейтинг', 'Гемблінг', 'Трейдинг', 'Крипта'],
  statuses: ['Заблокований', 'Перевірка домену', 'Перевірка HTTPs', 'Не оплачений', 'Зупинено', 'Працює', 'Технічна зупинка'],
  categories: [
    'Автомобілі та транспорт',
    'Бібліотеки та демоверсії',
    'Бізнес',
    'Відеоплеєри та редактори',
    'Мистецтво та дизайн',
    'Їжа та напої',
    'Ігри',
    'Інструменти',
    'Житло та будинок',
    'Здоров\'я та фітнес',
    'Знайомства',
    'Карти та навігація',
    'Книги',
    'Комікси',
    'Краса',
    'Материнство та дитинство',
    'Медицина',
    'Заходи',
    'Музика',
    'Новини та журнали',
    'Освіта',
    'Спосіб життя',
    'Спілкування',
    'Персоналізація',
    'Погода',
    'Покупки',
    'Подорожі',
    'Робота',
    'Розваги',
    'Соціальні',
    'Спорт',
    'Стиль життя',
    'Фінанси',
    'Світлина'
  ],
  create,
  components,
  tracker,
  group: {
    successRemove: 'PWA успішно вилучені',
    successCopy: 'PWA успішно скопійовані',
    successTrash: 'PWA були успішно переміщені в кошик',
    successRestore: 'PWA були успішно відновлені',
    successPlay: 'PWA запущені',
    successStop: 'PWA зупинені',
    successMeta: 'Користувацькі META tags збережено',
    successCustomJs: 'Користувацький JS збережено'
  },
  pushPlacement: {
    pageIsLoaded: 'При завантажені сторінки',
    trafficBackIsTriggered: 'При спрацюванні трафік бек',
    afterInstall: 'Після інсталу',
    afterOpen: 'Після відкриття PWA'
  },
  pushPlacementTooltips: {
    pageIsLoaded: 'При завантажені сторінки',
    trafficBackIsTriggered: 'При спрацюванні трафік бек',
    afterInstall: 'Після інсталу',
    afterOpen: 'Після відкриття PWA'
  }
}
