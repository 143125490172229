<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">IP серверів</h4>
            <p class="card-description">Керування IP серверів які використовуються в системі</p>
            <div class="mb-2">
              <ip-form ref="ipForm"/>
              <button
                @click="handlerCreate"
                type="button" class="btn btn-outline-primary btn-fw">
                <i class="icon-plus"></i> Додати IP
              </button>
            </div>
            <div class="row">
              <div class="col-md-4 mb-2">
                <input v-model="filter.ip" class="form-control" placeholder="Пошук за IP"/>
              </div>
              <div class="col-md-4 mb-2">
                <input v-model="filter.description" class="form-control" placeholder="Пошук за приміткою"/>
              </div>
              <select2
                v-model="filter.status"
                :options="statuses"
                :settings="{allowClear: true}"
                class="col-md-4 mb-2"
                id="status"
                name="status"
                inputClass="form-control"
                placeholder="Фільтр за статусом"
              />
            </div>
            <loading v-model="loading" class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th rowspan="2">
                    <button
                      @click="fetchPage()"
                      class="btn btn-icons btn-inverse-light">
                      <i class="icon-refresh"></i>
                    </button>
                  </th>
                  <th rowspan="2" class="text-center align-middle">Статус</th>
                  <th rowspan="2" class="text-center align-middle">IP</th>
                  <th rowspan="2" class="text-center align-middle">Кл. PWA</th>
                  <th rowspan="2" class="text-center align-middle">Примітка</th>
                  <th rowspan="2" class="text-center align-middle">Uptime</th>
                  <th colspan="2" class="text-center align-middle">CPU</th>
                  <th colspan="2" class="text-center align-middle">Пам'ять</th>
                  <th colspan="2" class="text-center align-middle">Диск</th>
                  <th rowspan="2" class="text-center align-middle">Створено</th>
                  <th rowspan="2" class="text-center align-middle">Оновлено</th>
                  <th rowspan="2" class="text-center align-middle">Лишилося</th>
                </tr>
                <tr>
                  <th class="text-center align-middle">Cors</th>
                  <th class="text-center align-middle">Load</th>
                  <th class="text-center align-middle">Всього</th>
                  <th class="text-center align-middle">Зайнято</th>
                  <th class="text-center align-middle">Всього</th>
                  <th class="text-center align-middle">Зайнято</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model.id">
                  <td>
                    <button @click="handlerUpdate(model)" class="btn btn-icons btn-inverse-primary">
                      <i class="icon-pencil"></i>
                    </button>
                    <button @click="handlerDelete(model)" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                  </td>
                  <td><span class="dot" :class="getDot(model.id)"></span> {{ getStatus(model.status) }}</td>
                  <td>
                    {{ model.id }}
                  </td>
                  <td>
                    {{ model.pwasCount }}
                  </td>
                  <td>{{ model.description }}</td>
                  <td
                    v-html="getUptime(model.id)"
                    class="text-center align-middle">
                  </td>
                  <td
                    v-html="getCPUCount(model.id)"
                    class="text-center align-middle">
                  </td>
                  <td
                    v-html="getCPULoad(model.id)"
                    class="text-center align-middle">
                  </td>
                  <td
                    v-html="getMemoryTotal(model.id)"
                    class="text-center align-middle">
                  </td>
                  <td
                    v-html="getMemoryFree(model.id)"
                    class="text-center align-middle">
                  </td>
                  <td
                    v-html="getDiskTotal(model.id)"
                    class="text-center align-middle">
                  </td>
                  <td
                    v-html="getDiskFree(model.id)"
                    class="text-center align-middle">
                  </td>
                  <td>{{ model.createdAt | dateFromNow }}</td>
                  <td>{{ model.updatedAt | dateFromNow }}</td>
                  <td>{{ model.expiryDate | dateAgo }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td>Всього</td>
                  <td colspan="2">{{ count }}</td>
                  <td>{{ totalPWAs }}</td>
                  <td colspan="11"></td>
                </tr>
                </tfoot>
              </table>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import Loading from '@/pages/admin/components/Loading.vue'
import IpForm from './_form.vue'
import Select2 from '@/components/Select2.vue'

export default {
  name: 'AdminIPs',
  components: {Loading, IpForm, Select2},
  data () {
    return {
      loading: true,
      page: 1,
      limit: 20,
      groups: [],
      sort: '-createdAt',
      filter: {
        ip: '',
        status: '',
        description: ''
      },
      intervalMonit: null
    }
  },
  computed: {
    ...mapGetters({
      models: 'ips/models',
      count: 'ips/count',
      statuses: 'ips/statuses',
      monit: 'adminConfig/monit'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    totalPWAs () {
      let total = 0
      for (const model of this.models) {
        total += model.pwasCount
      }
      return total
    }
  },
  created () {
    this.fetchMonit()
    this.intervalMonit = setInterval(() => {
      this.fetchMonit()
    }, 15000)
    for (const prop in this.filter) {
      this.$watch(`filter.${prop}`, function () {
        this.handlerPagination()
      })
    }
    this.handlerPagination()
  },
  beforeDestroy () {
    clearInterval(this.intervalMonit)
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      alertError: 'alerts/error',
      fetchData: 'ips/adminFetchData',
      delete: 'ips/adminDelete',
      fetchMonit: 'adminConfig/fetchMonit'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        page: this.page,
        limit: this.limit,
        sort: this.sort,
        filter: this.filter
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    async handlerCreate () {
      const ok = await this.$refs.ipForm.handlerCreate()
      if (ok) {
        await this.alertSuccess('IP успішно створено')
        this.handlerPagination()
      }
    },
    async handlerUpdate (model) {
      const ok = await this.$refs.ipForm.handlerUpdate(model)
      if (ok) {
        await this.alertSuccess('IP успішно оновлено')
        this.fetchPage()
      }
    },
    async handlerDelete (model) {
      if (confirm('Видалити IP?')) {
        try {
          await this.delete(model.id)
          await this.alertSuccess('IP успішно видалено')
          this.fetchPage()
        } catch (e) {
          await this.alertError(e.message)
        }
      }
    },
    getStatus (id) {
      const index = this.statuses.findIndex(status => status.id === id)
      return this.statuses[index].text
    },
    getDot (ip) {
      return this.monit[ip] != null && this.monit[ip].node.up ? 'green-dot' : 'red-dot'
    },
    getUptime (ip) {
      if (this.monit[ip] == null) return '<span class="text-gray">( нет )</span>'
      // eslint-disable-next-line no-undef
      const start = moment(new Date(this.monit[ip].node.uptime * 1000))
      // eslint-disable-next-line no-undef
      const end = moment(new Date())

      return end.from(start).replace('через', '')
    },
    getCPUCount (ip) {
      if (this.monit[ip] == null) return '<span class="text-gray">( нет )</span>'

      return this.monit[ip].node.cpu.length + ' шт.'
    },
    getCPULoad (ip) {
      if (this.monit[ip] == null) return '<span class="text-gray">( нет )</span>'

      return ((1 - (this.monit[ip].node.cpu.reduce((a, b) => a + b, 0) / this.monit[ip].node.cpu.length)) * 100).toFixed(2) + ' %'
    },
    getMemoryTotal (ip) {
      if (this.monit[ip] == null) return '<span class="text-gray">( нет )</span>'

      return (new Intl.NumberFormat('ru-RU')).format(Math.round(this.monit[ip].node.memory.total / 1000000)) + ' MB'
    },
    getMemoryFree (ip) {
      if (this.monit[ip] == null) return '<span class="text-gray">( нет )</span>'

      return ((1 - this.monit[ip].node.memory.free) * 100).toFixed(2) + ' %'
    },
    getDiskTotal (ip) {
      if (this.monit[ip] == null) return '<span class="text-gray">( нет )</span>'

      return (new Intl.NumberFormat('ru-RU')).format(Math.round(this.monit[ip].node.disk.total / 1000000000)) + ' GB'
    },
    getDiskFree (ip) {
      if (this.monit[ip] == null) return '<span class="text-gray">( нет )</span>'

      return this.monit[ip].node.disk.free.toFixed(2) + ' %'
    }
  }
}
</script>
<style scoped>
.dot {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 5px;
}

.green-dot {
  background: #669236;
}

.red-dot {
  background: #923c3c;
}
</style>
