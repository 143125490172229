export default {
  title: 'PWA Templates',
  create: 'Create template',
  deleteTitle: 'Delete PWA template',
  deleteMessage: 'Are you sure you want to delete the PWA template?',
  name: 'Name',
  owner: 'User',
  googlePlayURL: 'Play Market URL',
  pwaId: 'PWA',
  createdAt: 'Created',
  updatedAt: 'Updated',
  idTitle: 'Template ID',
  nameTitle: 'Template titles in lists',
  ownerTitle: 'Template owner',
  googlePlayURLTitle: 'URL from which import was made',
  pwaIdTitle: 'PWA that was imported from',
  createdAtTitle: 'Template creation date',
  updatedAtTitle: 'Template update date',
  actionsTitle: 'Actions on the template',
  deleteButton: 'Delete',
  deleted: 'Template deleted',
  filters: {
    id: 'Search by ID',
    name: 'Search by name',
    owner: 'Search by owner',
    googlePlayURL: 'URL search',
    pwaId: 'PWA Search'
  },
  form: {
    createTitle: 'Create PWA Template',
    editTitle: 'Edit template {name}',
    name: 'Template names',
    namePlaceholder: 'Enter a template name',
    owner: 'Template owner',
    ownerPlaceholder: 'Specify the owner of the template',
    pwaPlaceholder: 'Specify the PWA to import',
    googlePlayURL: 'URL Play Market',
    googlePlayURLPlaceholder: 'Specify URL to import',
    lang: 'Application language',
    langPlaceholder: 'Specify application language'
  },
  apps: {
    title: 'Apply Template',
    your: 'Your templates',
    notSelected: 'Template not selected'
  },
  success: 'Template successfully saved'
}
