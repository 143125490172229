<template>
  <modal
    id="admin-bot-key-form-modal"
    ref="modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Створення ключів для бота на #{{ owner }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div
        :class="apiValidationErrors.count ? 'has-danger' : ''"
        class="form-group">
        <label for="count">Кількість ключів</label>
        <div class="input-group-append">
          <input type="number" step="0" id="count" placeholder="Введіть кількість ключів" v-model="count"
                 class="form-control" min="1" max="100000"
                 :class="apiValidationErrors.count ? 'form-control-danger' : ''"
                 @focus="clearError('count')">
          <div class="input-group-append">
            <span class="input-group-text">0</span>
          </div>
        </div>
        <small class="form-text text-muted">Кількість ключів яка буде сгенерована для бота користувача</small>
        <label v-if="apiValidationErrors.count" class="error mt-2 text-danger" for="method">
          {{ apiValidationErrors.count[0] }}
        </label>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import {mapActions} from 'vuex'

const defaultValues = () => ({
  owner: '',
  count: 0
})

export default {
  name: 'AdminBotKeyForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      processing: false,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  methods: {
    ...mapActions({
      create: 'bot/adminCreateKey'
    }),
    handlerOpen (owner) {
      this.skip()
      this.owner = owner
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.$refs.modal.close()
      this.resolvePromise(false)
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.create({
          owner: this.owner,
          count: this.count
        })
        this.resetApiValidation()
        this.resolvePromise(true)
        this.processing = false
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
