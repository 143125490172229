export default {
  title: 'Кампанії',
  add: 'Створити кампанію',
  noHave: 'У Вас ще немає створених кампаній у системі трекера. Натисніть "Додати кампанію"',
  companyInfo: 'Ідентифікатор кампанії',
  enable: 'Вкл/Викл',
  enableInfo: 'Стан кампанії',
  name: 'Назва',
  nameInfo: 'Назва кампанії',
  country: 'Країни',
  countryInfo: 'ГЕО, яке використовується в даній кампанії',
  pwaInfo: 'PWA, в якому використовується дана кампанія',
  tag: 'Теги',
  tagInfo: 'Теги призначені Трекеру',
  filters: {
    name: 'Пошук за назвою',
    id: 'Пошук за ID',
    enable: 'Фільтр за станом',
    country: 'Фільтр за країною',
    pwa: 'Фільтр за PWA',
    tag: 'Фільтр за тегом',
    noHave: 'Пошук не дав результату, спробуйте змінити параметри фільтра'
  },
  form: {
    title: 'Компанія',
    setting: 'Налаштування кампанії',
    nameInfo: 'Назва Вашої кампанії. Вводіть для своєї зручності',
    link: 'Посилання на офер',
    linkDefault: 'Посилання для невідповідного трафіку',
    linkInfo: 'Введіть посилання для редіректу, які буде задіяно, якщо Гео не підходить',
    active: 'Активна',
    notActive: 'Не активна',
    activeInfo: 'Стан кампанії',
    tags: 'Теги трекера',
    tagsInfo: 'Набір тегів для фільтрації трекера',
    newTag: 'Додати новий тег',
    filters: 'Силка',
    filtersInfo: 'Виберіть ГЕО і призначте посилання на перенаправлення для цієї країни',
    info: 'Для перенаправлення вкажіть пару "країна - редірект"',
    country: 'Виберіть країну',
    created: 'Кампанія успішно збережена',
    deletePair: 'Видалити пару?'
  },
  linkSettings: {
    link: 'Налаштування фільтра {country}({locale})',
    title: 'Додаткові налаштування',
    postback: 'Постбеки',
    postbackTemplate: 'Шаблон постбеку',
    postbackTemplateSelect: 'Виберіть шаблон Postback',
    postbackLink: 'Посилання постбека',
    postbackInfo: 'Вставте цей URL у свою партнерку',
    postbackRegLink: 'Посилання постбека для реєстрації',
    postbackRegInfo: 'Якщо ви не розумієте, що це, зверніться до підтримки {name}',
    postbackDepositLink: 'Посилання постбека для депозиту',
    postbackDepositInfo: 'Якщо ви не розумієте, що це, зверніться до підтримки {name}',
    get: 'Прокидати GET',
    getInfo: 'Якщо потрібно прокинути всі GET параметри PWA на посилання',
    fullscreen: 'Відкрив. Fullscreen',
    fullscreenInfo: 'Вибір відображення PWA під час запуску',
    bindPrevButton: 'Traffic back',
    bindPrevButtonInfo: 'Якщо не вказати посилання то буде використовуватися сгенероване для редіректу. Користувача направлять на цей URL у випадку якщо він захоче покинути сторінку натиснувши кнопку \'назад\'',
    bindPrevButtonPlaceholder: 'URL для traffic back',
    redirect: 'Редірект',
    landingSettings: 'Налаштування Landing',
    locale: 'Локаль лендинга',
    localeInfo: 'Вкажіть локалізацію лендинга. PWA, яке має потрібний переклад, також буде відображатися з перекладом',
    cloakTitle: 'Клоака',
    cloak: 'Активувати клоаку',
    cloakInfo: 'Включити/вимкнути використання клоаки',
    android: 'Тільки Android',
    androidInfo: 'Весь трафік, який не є ОС Android, буде відзначатись ботом. Як наслідок буде показуватися white page, якщо він налаштований',
    white: 'Відправляти інші ГЕО на вайт',
    whiteInfo: 'Якщо включено, всіх, хто не підходить під зазначені ГЕО, відправляти на White page',
    whiteRedirect: 'Редірект на свій вайт',
    whiteRedirectInfo: 'Якщо включено, всіх, хто не підходить під зазначені ГЕО, відправляти на вказаний урл',
    geoRedirect: 'Урл для редіректу з ГЕО',
    whiteLang: 'Мова white page',
    whiteLangInfo: 'Мова генерації white page',
    whiteLangPlaceholder: 'Виберіть мову генерації white page',
    app: 'Посилання на програму',
    appInfo: 'Посилання на програму з play.google.com/store/apps/',
    footer: 'Блок тексту у футері',
    footerInfo: 'Допускаються теги A B BR P STRONG',
    url: 'URL',
    clUrl: 'Посилання на програму',
    useCookies: 'Файли cookies',
    useCookiesInfo: 'Показувати користувачам напис для згоду з використанням файлів cookies. Це потрібно як фіктивний клік по сторінці для активації деяких скриптів',
    ignoreCloak: 'Ігнорування клоаки',
    ignoreCloakInfo: 'Якщо ввімкнути цю опцію, то стане доступним ігнорування вбудованої клоаки за допомогою GET параметра <strong>?ignore_cloak=1</strong>. Використовуйте цю функцію виключно для теста. Не рекомендується використовувати під час роботи рекламної кампанії!'
  },
  delete: {
    title: 'Видалити трекер',
    message: 'Ви впевнені, що хочете видалити трекер {name}?',
    button: 'Видалити',
    success: 'Трекер видалено'
  },
  copy: {
    title: 'Копіювати трекер',
    message: 'Ви впевнені, що хочете скопіювати трекер {name}?',
    button: 'Копіювати',
    success: 'Трекер скопійований'
  },
  wp: {
    generate: 'Згенерувати',
    success: 'Вайтпейдж згенерований',
    failed: 'Вайтпейдж не згенерований',
    notTracker: 'Налаштування клоаки будуть доступними лише після створення компанії'
  }
}
