export default {
  logout: 'Sair',
  mail: 'Não tem mensagens novas | Tem uma mensagem nova | Tem {count} mensagens novas | Tem {count} mensagens novas',
  notifications: 'Não tem notificações novas | Tem uma notificação nova | Tem {count} notificações novas | Tem {count} notificações novas',
  banners: {
    telegram: {
      title: 'Subscreva o nosso canal no Telegram <br>e telegram chat',
      desc: 'Mantenha-se atualizado com as últimas notícias de PWA GROUP. Participe ativamente na discussão sobre software!',
      chanelButton: 'Telegram canal',
      chatButton: 'Telegram chat'
    }
  }
}
