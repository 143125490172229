import Tariffs from './index.vue'

export default (meta, header) => {
  return [
    {
      path: 'tariffs',
      name: 'Tariffs',
      components: {default: Tariffs, header: header},
      meta: meta
    }
  ]
}
