import {useStore} from '@/store'
import {ACCESS_ROUTES} from '@/const'
import router from '@/routes'
import {i18n} from '@/plugins/i18n'
import _ from 'lodash'

export default {
  validateAut: _.debounce(error => {
    const store = useStore()
    if (error.response !== undefined && error.response.status !== undefined) {
      if (error.response.status === 401) {
        const element = document.getElementById('logout')
        element.click()
      } else if (error.response.status === 403) {
        const permissions = store.state.config.accesses
        if (permissions.statistics.view) {
          router.push('/')
        } else {
          let availableRoute = Object.keys(permissions).find(page => {
            return Object.keys(permissions[page]).some(permission => permissions[page][permission])
          })
          router.push(ACCESS_ROUTES[availableRoute])
        }
        store.dispatch('alerts/error', i18n.t('validations.accessDenied'))
      }
    }
  }, 500),
  validate401 (error) {
    if (error.response !== undefined && error.response.status !== undefined) {
      if (error.response.status === 401) {
        const element = document.getElementById('logout')
        element.click()
      }
    }
  }
}
