import AdminTransactions from './AdminTransactions.vue'

export default (meta, header) => {
  return [
    {
      path: 'transactions',
      name: 'AdminTransactions',
      components: {default: AdminTransactions, header},
      meta
    }
  ]
}
