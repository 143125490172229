import service from '@/store/services/admin/pwas-service'
import middleware from '../middleware'
import pwas from '../pwas-module'

const state = () => ({
  models: [],
  count: 0,
  pwa: {},
  tracker: {},
  trackerTemplates: []
})

const mutations = {
  setPWAsList: (state, {models, count}) => {
    state.models = models || []
    state.count = count || 0
  },
  setPWA: (state, payload) => {
    state.pwa = payload
  },
  setTracker: (state, {tracker, templates}) => {
    state.tracker = tracker
    state.trackerTemplates = templates
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.getPWAsList(params)
      .then(response => {
        commit('setPWAsList', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  copyPWA ({commit, dispatch}, params) {
    return service.copyPWA(params)
      .then()
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  updatePWA ({commit, dispatch}, params) {
    return service.updatePWA(params)
      .then()
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  getPWA ({commit, dispatch}, params) {
    return service.getPWA(params)
      .then(response => {
        commit('setPWA', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  getPWATracker ({commit, dispatch}, params) {
    return service.getPWATracker(params)
      .then(response => {
        commit('setTracker', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models (state) {
    const models = []
    const _statuses = pwas.getters.statuses(pwas.state())
    for (const model of state.models) {
      if (model.images === -1) {
        model.images = import.meta.env.VITE_APP_API_BASE_URL + model.gallery[0]
      } else {
        const image = model.images + 1
        model.images = `${import.meta.env.VITE_APP_API_BASE_URL}/verticals/${model.vertical}/${image}/${image}.jpg`
      }
      model.status = _statuses.findIndex(value => value.id === model.status)
      model.status = _statuses[model.status]
      models.push(model)
    }
    return models
  },
  count: state => +state.count,
  pwa: state => state.pwa,
  tracker: state => state.tracker,
  trackerTemplates: state => state.trackerTemplates
}

const adminPWAs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminPWAs
