<template>
  <div id="content-pwa" class="tab-pane fade in active" v-if="accesses.statistics.view">
    <div class="head_action_stats_g d_flex align_center justify_between" v-if="!loading">
      <div class="filters_hed_top">
        <form class="forms-sample">
          <div class="d_flex align_center in_to_djsbgdjg">
            <filters-tags v-model="filters.groups.tags"/>
            <filters-countries v-model="filters.groups.country"/>
            <filters-pwas v-model="filters.groups.pwa"/>
          </div>
        </form>
      </div>
      <div class="d_flex align_center right_act_sjg_g">
        <div class="item_picker_g">
          <filters-date name="date_begin" v-model="filters.groups.dateBegin"/>
        </div>
        <div class="item_picker_g">
          <filters-date name="date_end" v-model="filters.groups.dateEnd"/>
        </div>
        <div class="div button_save_g">
          <button class="btn_green_g" @click="clear">Скинуть</button>
        </div>
      </div>
    </div>
    <filters-pepper v-model="filters.pepper" :groups="{...pepper}" v-if="!loading && user.id === 1516381314"/>
    <filters-welcomep v-model="filters.welcomep" :groups="{...welcomep}" v-if="!loading && user.id === 731778160"/>
    <div class="row">
      <div class="col-sm-12">
        <div class="loading_cont_go" v-if="loading">
          <div class="in_to_load_sf d_flex justify_center">
            <img src="/images/loader.svg" alt="" width="100">
          </div>
        </div>
        <div class="table-responsive" v-if="!loading">
          <table id="pwa-statistics-listing" class="table table_border_style">
            <table-sort-head :items="heads" v-model="sort"/>
            <tbody>
            <tr role="row" class="odd" v-for="item in data" :key="item._id">
              <td>{{ item.date | date }}</td>
              <td v-html="$options.filters.formatFlag(item.country)"></td>
              <td>{{ item.ua | formatDevice }}</td>
              <td>{{ item.ua | formatOS }}</td>
              <td>{{ item.ua | formatBrowser }}</td>
              <td>{{ item.hits }}</td>
              <td>{{ item.installs }}</td>
              <td>{{ item.opens }}</td>
              <td>{{ item.reopens }}</td>
              <td>{{ item.pushes }}</td>
              <td>{{ item.online | formatBoolean }}</td>
              <td>{{ item.registration | formatBoolean }}</td>
              <td>{{ item.deposit | formatBoolean }}</td>
              <!--                          <td>{{ item.canInstall | formatBoolean }}</td>-->
              <td>{{ item.referer }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <nav v-if="pageCount > 1">
          <paginate ref="paginate"
                    :page-count="pageCount"
                    :click-handler="pagination"
                    :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                    :next-text="'<i class=\'icon-arrow-right\'></i>'"
                    :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                    :page-class="'page_item_gd'"
                    :page-link-class="'page_link_g d_flex justify_center align_center'"
                    :prev-class="'page-item_go'"
                    :next-class="'page-item_go'"
                    :prev-link-class="'page-link_go d_flex justify_center align_center'"
                    :next-link-class="'page-link_go d_flex justify_center align_center'"
                    :active-class="'active'">
          </paginate>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import tooltipMixin from '@/mixins/tooltip-mixin'
import statisticsMixin from '@/mixins/statistics-mixin'
import titleMixin from '@/mixins/title-mixin'
import * as mixins from '../mixins'
import accessesMixin from '@/mixins/accesses-mixin'

export default {
  name: 'Uniques',
  title: 'Статистика по уникам',
  mixins: [tooltipMixin, statisticsMixin, titleMixin, mixins.groups, accessesMixin],
  data: () => {
    return {
      loading: true,
      pageSize: 20,
      pageIndex: 1,
      count: 0,
      heads: [
        {label: 'Дата', title: 'Дата статистики', sort: 'date'},
        {label: 'Страна', title: 'Уникальные переходы', sort: 'country'},
        {label: 'Устройство', title: 'Неуникальные переходы'},
        {label: 'ОС', title: 'Количество установок PWA'},
        {label: 'Браузер', title: 'Количество подписок на Push'},
        {
          label: 'Хитов',
          title: 'Процентное соотношение уник/инстал. Рассчитывается по формуле CR = Инстал/Уник*100',
          sort: 'hits'
        },
        {
          label: 'Установок',
          title: 'Процентное соотношение уник/пуш. Рассчитывается по формуле CR = Пуш/Уник*100',
          sort: 'installs'
        },
        {
          label: 'Открытий',
          title: 'Процентное соотношение инстал/пуш. Рассчитывается по формуле CR = Пуш/Инстал*100',
          sort: 'opens'
        },
        {
          label: 'Повторно',
          title: 'Процентное соотношение инстал/пуш. Рассчитывается по формуле CR = Пуш/Инстал*100',
          sort: 'reopens'
        },
        {label: 'Пушей', title: 'Количество входов в PWA', sort: 'pushes'},
        {label: 'Онлайн', title: 'Количество входов в PWA', sort: 'online'},
        {label: 'Рег', title: 'Регнулся', sort: 'registration'},
        {label: 'Деп', title: 'Депнул', sort: 'deposit'},
        {label: 'Первая рефка', title: 'Количество входов в PWA', sort: 'referer', class: 'text-right last_tbs_gg pr-0'}
      ]
    }
  },
  computed: {
    pageCount: function () {
      return Math.ceil(this.count / this.pageSize)
    }
  },
  methods: {
    asyncData: async function () {
      try {
        await this.$store.dispatch('statistics/uniques', {
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          ...this.filter,
          sort: this.sort
        })
        const list = this.$store.getters['statistics/uniques']
        this.count = list.count
        this.data = list.data
        this.pepper = list.pepper
        this.welcomep = list.welcomep
        if (this.loading === false) {
          await this.$store.dispatch('alerts/success', 'Статистика обновлена')
        } else {
          this.loading = false
        }
      } catch (e) {
        await this.$store.dispatch('alerts/error', e.response.data)
      }
    },
    pagination: async function (pageIndex) {
      this.pageIndex = pageIndex
      await this.asyncData()
    }
  }
}
</script>
