import service from '@/store/services/admin/vouchers-service'
import middleware from '../middleware'

const state = {
  vouchers: [],
  count: 0,
  voucher: {},
  tariffs: [],
  list: []
}

const mutations = {
  setVouchers: (state, payload) => {
    const {tariffs, vouchers, count} = payload
    state.vouchers = vouchers
    state.count = count
    state.tariffs = tariffs
  },
  setVoucher: (state, payload) => {
    state.voucher = payload
  },
  setList: (state, payload) => {
    state.list = payload
  }
}

const actions = {
  index: ({commit, dispatch}, params) => service.index(params)
    .then(res => {
      commit('setVouchers', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  view: ({commit, dispatch}, params) => service.view(params)
    .then(res => {
      commit('setVoucher', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  create: ({commit, dispatch}, params) => service.create(params)
    .then(res => {
      commit('setVoucher', res)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  update: ({commit, dispatch}, params) => service.update(params)
    .then()
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  delete: ({commit, dispatch}, params) => service.delete(params)
    .then()
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  list: ({commit, dispatch}, params) => service.list(params)
    .then(({vouchers}) => {
      commit('setList', vouchers)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  vouchers: state => state.vouchers,
  count: state => state.count,
  tariffs: state => state.tariffs,
  voucher: state => state.voucher,
  list: state => state.list
}

const adminVouchers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminVouchers
