<template>
  <button class="button_inf_sg" data-toggle="tooltip" :data-original-title="message" @click="handler" data-html="true">
                        <span :class="icon_class ? icon_class : 'icon_inf_sg'">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg"> <path
                            d="M8.55 4.45V5.55H7.45V4.45H8.55ZM8.55 7.45V11.55H7.45V7.45H8.55ZM0.7 8C0.7 3.97046 3.97046 0.7 8 0.7C12.0295 0.7 15.3 3.97046 15.3 8C15.3 12.0295 12.0295 15.3 8 15.3C3.97046 15.3 0.7 12.0295 0.7 8ZM1.8 8C1.8 11.418 4.58204 14.2 8 14.2C11.418 14.2 14.2 11.418 14.2 8C14.2 4.58204 11.418 1.8 8 1.8C4.58204 1.8 1.8 4.58204 1.8 8Z"
                            fill="white" stroke="#707175" stroke-width="0.4"/></svg>
                        </span>
  </button>
</template>
<script>
import tooltipMixin from '@/mixins/tooltip-mixin'

export default {
  name: 'Info',
  props: { message: String, icon_class: String },
  mixins: [tooltipMixin],
  methods: {
    handler (event) {
      event.preventDefault()
    }
  },
  mounted: function () {
    this.initTooltip()
  }
}
</script>
