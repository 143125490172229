<template>
  <tr>
    <td>
      <label v-if="item.status === 2" class="text-success">{{ $t('finance.bills.paid') }}</label>
      <label v-if="item.status === 0 || item.status === 1" class="text-warning">{{ $t('finance.bills.expiresIn') }}
        {{ item.expired | dateAgo }}</label>
      <label v-if="item.status === -1" class="text-danger">{{ $t('finance.bills.overdue') }}</label>
      <label v-if="item.status === -2" class="text-danger">{{ $t('finance.bills.rejected') }}</label>
    </td>
    <td>${{ item.amount }}</td>
    <td>{{ filterTotal(item.total, item.rate) }} {{ item.rate.toUpperCase() }}</td>
    <td><img v-bind:src="item.method.image" v-bind:alt="item.method.name" class="mr-1"> {{ item.method.name }}</td>
    <td>{{ item.createdAt | formatDate }}</td>
    <td class="text-right">
      <div class="d_inline_block">
        <button class="btn_green_g" v-if="item.status === 0" @click="handlerPaid(item)">
          {{ $t('finance.payment.pay') }}
        </button>
        <button class="btn_green_g" v-if="item.status > 0"
                @click="handlerInfo(item)">{{ $t('finance.bills.info') }}
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'BillField',
  props: ['item'],
  data: function () {
    return {
      ...this.item
    }
  },
  methods: {
    filterTotal: function (amount, rate) {
      if (rate === 'btc') {
        return amount.toFixed(8)
      } else {
        return amount.toFixed(2)
      }
    },
    handlerPaid: function (item) {
      if (item.method.name === 'Bitcoin') {
        this.$parent.$refs['bitcoin-form'].bill = {
          ...this.item,
          total: this.filterTotal(this.item.total, this.item.rate)
        }
        this.$parent.$refs['bitcoin-form'].show()
      } else if (item.method.name === 'Tether') {
        this.$parent.$refs['trc-form'].show({...this.item,
          total: this.filterTotal(this.item.total, this.item.rate)})
      } else {
        window.location = item.pay_url
      }
    },
    handlerInfo: function (item) {
      this.$parent.$refs['modal-info'].title = this.$t('finance.bills.modal.title', {id: item._id})
      switch (item.status) {
        case 1:
          if (item.method.name === 'Bitcoin') {
            this.$parent.$refs['modal-info'].body = this.$t('finance.bills.modal.info[0]', { confirmation: item.confirmation, expired: this.$options.filters.dateAgo(item.expired) })
          } else {
            this.$parent.$refs['modal-info'].body = this.$t('finance.bills.modal.info[1]', {pay_url: item.pay_url})
          }
          break
        case 2:
          this.$parent.$refs['modal-info'].body = this.$t('finance.bills.modal.info[2]')
          break
      }
      this.$parent.$refs['modal-info'].show()
    }
  }
}
</script>
