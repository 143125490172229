<template>
  <div class="BaseDropdownToggle dropdown animate base-dropdown-toggle">
    <div
      class="dropdown-toggle toggle_points"
      data-toggle="dropdown"
      data-boundary="viewport"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <InlineSvg src="/images/svg-icons/dots-icon.svg"/>
    </div>
    <div class="dropdown-menu dropdown-menu-right">
      <div class="wrapp-inner-menu">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import InlineSvg from 'vue-inline-svg'
export default {
  name: 'BaseDropdownToggle',
  components: {InlineSvg}
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
:deep(.item_action_menu)
  color: $grey-storm-gray
  +font(400)
  font-size: 14px
  padding: 10px
  &:hover
    color: white
    svg
      path
        stroke: white
  svg
    margin-right: 10px
    width: 20px
    height: 20px
</style>
