import api from '@/store/services/api'
import { getParams } from '@/store/list'

import {
  SET_ALL,
  REMOVE_MIRROR,
  UPDATE_MIRROR_STATUS,
  REFRESH_MIRROR,
  DELETE_MIRROR
} from './mutation-types'

export default {
  getMirrorsList ({ commit }, params) {
    commit(SET_ALL, { mirrorsListLoading: true, mirrorsListLoaded: false })
    return api.get('mirrors?' + getParams(params) + (params?.trash != null ? `&trash=${params.trash}` : ''))
      .then((res) => {
        commit(SET_ALL, {
          mirrorsList: res.models,
          mirrorsListCount: res.count,
          mirrorsListLoading: false,
          mirrorsListLoaded: true,
          mirrorsListTrash: res.trash
        })
      }).catch(error => {
        throw error
      })
  },
  getTrashMirrorsList ({ commit }, params) {
    commit(SET_ALL, { mirrorsTrashListLoading: true, mirrorsTrashListLoaded: false })
    return api.get('mirrors?' + getParams(params) + (params?.trash != null ? `&trash=${params.trash}` : ''))
      .then((res) => {
        commit(SET_ALL, {
          mirrorsTrashList: res.models,
          mirrorsTrashListCount: res.count,
          mirrorsTrashListLoading: false,
          mirrorsTrashListLoaded: true,
          mirrorsTrashListTrash: res.trash
        })
      }).catch(error => {
        throw error
      })
  },
  async createMirror ({ commit }, data) {
    return api.post(`mirrors/${data.url}`, data)
      .then((res) => {
        return res
      }).catch(error => {
        throw error
      })
  },
  async updateMirror ({ commit }, data) {
    try {
      await api.patch(`mirrors/${data.id}`, data)
    } catch (error) {
      throw error
    }
  },
  async rebuildMirror ({ commit }, data) {
    try {
      const res = await api.post(`mirrors/${data.id}/rebuild`, data)
      return res
    } catch (error) {
      throw error
    }
  },
  async deleteMirror ({ commit, state }, data) {
    try {
      if (data.expired) {
        await api.patch(`mirrors/${data.id}`, {expired: data.expired})
        commit(SET_ALL, { mirrorsListTrash: (state.mirrorsListTrash + 1) })
        commit(REMOVE_MIRROR, {id: data.id})
      } else {
        await api.delete(`mirrors/${data.id}`)
        commit(DELETE_MIRROR, {id: data.id})
      }
    } catch (error) {
      throw error
    }
  },
  async refreshMirror ({ commit, state }, data) {
    try {
      await api.patch(`mirrors/${data.id}`, {expired: null})
      commit(REFRESH_MIRROR, {id: data.id})
    } catch (error) {
      throw error
    }
  },
  setActiveMirror ({ commit }, data) {
    commit(SET_ALL, {
      activeMirror: data
    })
  },
  resetActiveMirror ({ commit }) {
    commit(SET_ALL, {
      activeMirror: {}
    })
  },
  async playMirror ({ commit }, data) {
    try {
      await api.patch(`mirrors/launch/${data.id}/play`)
      commit(UPDATE_MIRROR_STATUS, {id: data.id, status: 4})
    } catch (error) {
      throw error
    }
  },
  async stopMirror ({ commit }, data) {
    try {
      await api.patch(`mirrors/launch/${data.id}/stop`)
      commit(UPDATE_MIRROR_STATUS, {id: data.id, status: 3})
    } catch (error) {
      throw error
    }
  }

}
