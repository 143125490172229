export default {
  mounted () {
    const script = document.createElement('script')
    script.src = '/vendors/ace-builds/src-min/ace.js'
    script.id = 'ace-script'
    document.head.appendChild(script)
  },
  destroyed () {
    const elem = document.getElementById('ace-script')
    elem.parentNode.removeChild(elem)
  },
  methods: {
    initAceEditor () {
      const interval = setInterval(() => {
        try {
          const self = this
          // eslint-disable-next-line no-undef
          $(function () {
            // eslint-disable-next-line no-undef
            const editor = ace.edit('data')
            editor.setTheme('ace/theme/monokai')
            editor.getSession().setMode('ace/mode/json')
            editor.getSession().setValue(self.data)
            editor.on('change', function () {
              self.data = editor.getSession().getValue()
            })
          })
          clearInterval(interval)
        } catch (e) {
        }
      }, 500)
    }
  }
}
