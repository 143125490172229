import axios from 'axios'
import {getCurrentLang} from '@/utils/i18n'

const url = import.meta.env.VITE_APP_API_BASE_URL

const options = () => {
  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('vue-authenticate.vueauth_accessToken'),
      'Accept-Language': getCurrentLang()
    },
    timeout: 30000
  }
}

export default {
  get: endpoint => {
    return axios.get(`${url}/${endpoint}`, options())
      .then(response => {
        return response.data
      })
  },
  post: (endpoint, payload) => {
    return axios.post(`${url}/${endpoint}`, payload, options())
      .then(response => {
        return response.data
      })
  },
  patch: (endpoint, payload) => {
    return axios.patch(`${url}/${endpoint}`, payload, options())
      .then(response => {
        return response.data
      })
  },
  put: (endpoint, payload) => {
    return axios.put(`${url}/${endpoint}`, payload, options())
      .then(response => {
        return response.data
      })
  },
  delete: (endpoint, payload = undefined) => {
    const config = {...options(), url: `${url}/${endpoint}`, method: 'DELETE'}

    if (payload != null) config.data = payload

    return axios.request(config)
      .then(response => {
        return response.data
      })
  },
  file: (endpoint, payload) => {
    let formData = new FormData()
    formData.append('file', payload)
    return axios.post(`${url}/${endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('vue-authenticate.vueauth_accessToken'),
        'Accept-Language': getCurrentLang()
      }
    })
      .then(response => {
        return response.data
      })
  }
}
