<template>
  <div class="item_toggsl_g">
    <div class="head_tit_sl_togg">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ $t('pwas.components.app.additionalInfo.title') }}</span>
        <info :message="$t('pwas.components.app.additionalInfo.titleInfo')"/>
      </div>
    </div>
    <div class="content_dropd_g">
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.changes_label ? 'has-danger' : ''">
          <label for="changes_label">{{ $t('pwas.components.app.description.update') }}
            <info :message="$t('pwas.components.app.description.updateInfo')"/>
          </label>
          <input type="text" id="changes_label" :placeholder="$t('pwas.components.app.description.update')"
                 v-model="value.languages[lang].changes_label" class="form-control"
                 :class="apiValidationErrors.changes_label ? 'form-control-danger' : ''"
                 @focus="clearError('changes_label')" v-if="lang">
          <input type="text" id="changes_label" :placeholder="$t('pwas.components.app.description.update')"
                 v-model="value.changes_label"
                 class="form-control"
                 :class="apiValidationErrors.changes_label ? 'form-control-danger' : ''"
                 @focus="clearError('changes_label')" v-else>
          <label v-if="apiValidationErrors.changes_label" class="error mt-2 text-danger"
                 for="changes_label">
            {{ apiValidationErrors.changes_label[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.changes_recent ? 'has-danger' : ''">
          <label for="changes_recent">{{ $t('pwas.components.app.description.updateDesc') }}
            <info :message="$t('pwas.components.app.description.updateDescInfo')"/>
          </label>
          <textarea id="changes_recent" rows="4" class="form-control"
                    v-model="value.languages[lang].changes_recent"
                    :placeholder="$t('pwas.components.app.description.updateDesc')"
                    :class="apiValidationErrors.changes_recent ? 'form-control-danger' : ''"
                    @focus="clearError('changes_recent')" v-if="lang"></textarea>
          <textarea id="changes_recent" rows="4" class="form-control"
                    v-model="value.changes_recent"
                    :placeholder="$t('pwas.components.app.description.updateDesc')"
                    :class="apiValidationErrors.changes_recent ? 'form-control-danger' : ''"
                    @focus="clearError('changes_recent')" v-else></textarea>
          <label v-if="apiValidationErrors.changes_recent" class="error mt-2 text-danger"
                 for="changes_recent">
            {{ apiValidationErrors.changes_recent[0] }}
          </label>
        </div>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.complain ? 'has-danger' : ''">
        <label for="complain">{{ $t('pwas.components.app.additionalInfo.complain') }}
          <info :message="$t('pwas.components.app.additionalInfo.complainInfo')"/>
        </label>
        <input v-if="lang" type="text" id="complain" :placeholder="$t('pwas.components.app.description.complain')"
               v-model="value.languages[lang].complain" class="form-control"
               :class="apiValidationErrors.complain ? 'form-control-danger' : ''"
               @focus="clearError('complain')">
        <input v-else type="text" id="complain" :placeholder="$t('pwas.components.app.additionalInfo.complain')"
               v-model="value.complain" class="form-control"
               :class="apiValidationErrors.complain ? 'form-control-danger' : ''"
               @focus="clearError('complain')">
        <label v-if="apiValidationErrors.complain" class="error mt-2 text-danger"
               for="complain">
          {{ apiValidationErrors.complain[0] }}
        </label>
      </div>
      <hr>
      <div class="form-group"
           :class="apiValidationErrors.developerContact ? 'has-danger' : ''">
        <label for="developerContact">{{ $t('pwas.components.app.additionalInfo.developerContact') }}
          <info :message="$t('pwas.components.app.additionalInfo.developerContactInfo')"/>
        </label>
        <input v-if="lang" type="text" id="developerContact"
               :placeholder="$t('pwas.components.app.description.developerContact')"
               v-model="value.languages[lang].developerContact" class="form-control"
               :class="apiValidationErrors.developerContact ? 'form-control-danger' : ''"
               @focus="clearError('developerContact')">
        <input v-else type="text" id="developerContact" :placeholder="$t('pwas.components.app.additionalInfo.developerContact')"
               v-model="value.developerContact" class="form-control"
               :class="apiValidationErrors.developerContact ? 'form-control-danger' : ''"
               @focus="clearError('developerContact')">
        <label v-if="apiValidationErrors.developerContact" class="error mt-2 text-danger"
               for="developerContact">
          {{ apiValidationErrors.developerContact[0] }}
        </label>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.developerSite ? 'has-danger' : ''">
          <label for="developerSite">{{ $t('pwas.components.app.additionalInfo.developerSite') }}
            <info :message="$t('pwas.components.app.additionalInfo.developerSiteInfo')"/>
          </label>
          <input v-if="lang" type="text" id="developerSite"
                 :placeholder="$t('pwas.components.app.description.developerSite')"
                 v-model="value.languages[lang].developerSite" class="form-control"
                 :class="apiValidationErrors.developerSite ? 'form-control-danger' : ''"
                 @focus="clearError('developerSite')">
          <input v-else type="text" id="developerSite" :placeholder="$t('pwas.components.app.additionalInfo.developerSite')"
                 v-model="value.developerSite" class="form-control"
                 :class="apiValidationErrors.developerSite ? 'form-control-danger' : ''"
                 @focus="clearError('developerSite')">
          <label v-if="apiValidationErrors.developerSite" class="error mt-2 text-danger"
                 for="developerSite">
            {{ apiValidationErrors.developerSite[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.developerUrl ? 'has-danger' : ''">
          <label for="developerUrl">{{ $t('pwas.components.app.additionalInfo.developerUrl') }}
            <info :message="$t('pwas.components.app.additionalInfo.developerUrlInfo')"/>
          </label>
          <input v-if="lang" type="text" id="developerUrl"
                 :placeholder="$t('pwas.components.app.description.developerUrl')"
                 v-model="value.languages[lang].developerUrl" class="form-control"
                 :class="apiValidationErrors.developerUrl ? 'form-control-danger' : ''"
                 @focus="clearError('developerUrl')">
          <input v-else type="text" id="developerUrl" :placeholder="$t('pwas.components.app.additionalInfo.developerUrl')"
                 v-model="value.developerUrl" class="form-control"
                 :class="apiValidationErrors.developerUrl ? 'form-control-danger' : ''"
                 @focus="clearError('developerUrl')">
          <label v-if="apiValidationErrors.developerUrl" class="error mt-2 text-danger"
                 for="developerUrl">
            {{ apiValidationErrors.developerUrl[0] }}
          </label>
        </div>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.developerEmailLabel ? 'has-danger' : ''">
          <label for="developerEmailLabel">{{ $t('pwas.components.app.additionalInfo.developerEmailLabel') }}
            <info :message="$t('pwas.components.app.additionalInfo.developerEmailLabelInfo')"/>
          </label>
          <input v-if="lang" type="text" id="developerEmailLabel"
                 :placeholder="$t('pwas.components.app.description.developerEmailLabel')"
                 v-model="value.languages[lang].developerEmailLabel" class="form-control"
                 :class="apiValidationErrors.developerEmailLabel ? 'form-control-danger' : ''"
                 @focus="clearError('developerEmailLabel')">
          <input v-else type="text" id="developerEmailLabel"
                 :placeholder="$t('pwas.components.app.additionalInfo.developerEmailLabel')"
                 v-model="value.developerEmailLabel" class="form-control"
                 :class="apiValidationErrors.developerEmailLabel ? 'form-control-danger' : ''"
                 @focus="clearError('developerEmailLabel')">
          <label v-if="apiValidationErrors.developerEmailLabel" class="error mt-2 text-danger"
                 for="developerEmailLabel">
            {{ apiValidationErrors.developerEmailLabel[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.developerEmail ? 'has-danger' : ''">
          <label for="developerEmail">{{ $t('pwas.components.app.additionalInfo.developerEmail') }}
            <info :message="$t('pwas.components.app.additionalInfo.developerEmailInfo')"/>
          </label>
          <input v-if="lang" type="text" id="developerEmail"
                 :placeholder="$t('pwas.components.app.description.developerEmail')"
                 v-model="value.languages[lang].developerEmail" class="form-control"
                 :class="apiValidationErrors.developerEmail ? 'form-control-danger' : ''"
                 @focus="clearError('developerEmail')">
          <input v-else type="text" id="developerEmail" :placeholder="$t('pwas.components.app.additionalInfo.developerEmail')"
                 v-model="value.developerEmail" class="form-control"
                 :class="apiValidationErrors.developerEmail ? 'form-control-danger' : ''"
                 @focus="clearError('developerEmail')">
          <label v-if="apiValidationErrors.developerEmail" class="error mt-2 text-danger"
                 for="developerEmail">
            {{ apiValidationErrors.developerEmail[0] }}
          </label>
        </div>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.developerAddressLabel ? 'has-danger' : ''">
          <label for="developerAddressLabel">{{ $t('pwas.components.app.additionalInfo.developerAddressLabel') }}
            <info :message="$t('pwas.components.app.additionalInfo.developerAddressLabelInfo')"/>
          </label>
          <input v-if="lang" type="text" id="developerAddressLabel"
                 :placeholder="$t('pwas.components.app.description.developerAddressLabel')"
                 v-model="value.languages[lang].developerAddressLabel" class="form-control"
                 :class="apiValidationErrors.developerAddressLabel ? 'form-control-danger' : ''"
                 @focus="clearError('developerAddressLabel')">
          <input v-else type="text" id="developerAddressLabel"
                 :placeholder="$t('pwas.components.app.additionalInfo.developerAddressLabel')"
                 v-model="value.developerAddressLabel" class="form-control"
                 :class="apiValidationErrors.developerAddressLabel ? 'form-control-danger' : ''"
                 @focus="clearError('developerAddressLabel')">
          <label v-if="apiValidationErrors.developerAddressLabel" class="error mt-2 text-danger"
                 for="developerAddressLabel">
            {{ apiValidationErrors.developerAddressLabel[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.developerAddress ? 'has-danger' : ''">
          <label for="developerAddress">{{ $t('pwas.components.app.additionalInfo.developerAddress') }}
            <info :message="$t('pwas.components.app.additionalInfo.developerAddressInfo')"/>
          </label>
          <input v-if="lang" type="text" id="developerAddress"
                 :placeholder="$t('pwas.components.app.description.developerAddress')"
                 v-model="value.languages[lang].developerAddress" class="form-control"
                 :class="apiValidationErrors.developerAddress ? 'form-control-danger' : ''"
                 @focus="clearError('developerAddress')">
          <input v-else type="text" id="developerAddress" :placeholder="$t('pwas.components.app.additionalInfo.developerAddress')"
                 v-model="value.developerAddress" class="form-control"
                 :class="apiValidationErrors.developerAddress ? 'form-control-danger' : ''"
                 @focus="clearError('developerAddress')">
          <label v-if="apiValidationErrors.developerAddress" class="error mt-2 text-danger"
                 for="developerAddress">
            {{ apiValidationErrors.developerAddress[0] }}
          </label>
        </div>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.politicsPrivacy ? 'has-danger' : ''">
          <label for="politicsPrivacy">{{ $t('pwas.components.app.additionalInfo.politicsPrivacy') }}
            <info :message="$t('pwas.components.app.additionalInfo.politicsPrivacyInfo')"/>
          </label>
          <input v-if="lang" type="text" id="politicsPrivacy"
                 :placeholder="$t('pwas.components.app.description.politicsPrivacy')"
                 v-model="value.languages[lang].politicsPrivacy" class="form-control"
                 :class="apiValidationErrors.politicsPrivacy ? 'form-control-danger' : ''"
                 @focus="clearError('politicsPrivacy')">
          <input v-else type="text" id="politicsPrivacy" :placeholder="$t('pwas.components.app.additionalInfo.politicsPrivacy')"
                 v-model="value.politicsPrivacy" class="form-control"
                 :class="apiValidationErrors.politicsPrivacy ? 'form-control-danger' : ''"
                 @focus="clearError('politicsPrivacy')">
          <label v-if="apiValidationErrors.politicsPrivacy" class="error mt-2 text-danger"
                 for="politicsPrivacy">
            {{ apiValidationErrors.politicsPrivacy[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.politicsPrivacyUrl ? 'has-danger' : ''">
          <label for="politicsPrivacyUrl">{{ $t('pwas.components.app.additionalInfo.politicsPrivacyUrl') }}
            <info :message="$t('pwas.components.app.additionalInfo.politicsPrivacyUrlInfo')"/>
          </label>
          <input v-if="lang" type="text" id="politicsPrivacyUrl"
                 :placeholder="$t('pwas.components.app.description.politicsPrivacyUrl')"
                 v-model="value.languages[lang].politicsPrivacyUrl" class="form-control"
                 :class="apiValidationErrors.politicsPrivacyUrl ? 'form-control-danger' : ''"
                 @focus="clearError('politicsPrivacyUrl')">
          <input v-else type="text" id="politicsPrivacyUrl"
                 :placeholder="$t('pwas.components.app.additionalInfo.politicsPrivacyUrl')"
                 v-model="value.politicsPrivacyUrl" class="form-control"
                 :class="apiValidationErrors.politicsPrivacyUrl ? 'form-control-danger' : ''"
                 @focus="clearError('politicsPrivacyUrl')">
          <label v-if="apiValidationErrors.politicsPrivacyUrl" class="error mt-2 text-danger"
                 for="politicsPrivacyUrl">
            {{ apiValidationErrors.politicsPrivacyUrl[0] }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'

export default {
  name: 'NewAdditionInfoApp',
  mixins: [formMixin],
  components: {Info},
  props: {
    value: {
      type: Object,
      require: true
    },
    errors: {
      type: Object,
      require: true
    }
  },
  computed: {
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  }
}
</script>
