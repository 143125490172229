<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">F.A.Q</h4>
            <p class="card-description">Записи находятся внутри категорий</p>
            <div class="mb-2">
              <button type="button" class="btn btn-outline-success btn-fw" @click="handlerCreate"><i
                class="icon-plus"></i> Добавить
              </button>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th>
                    Вкл./Выкл.
                  </th>
                  <th>
                    Названия
                  </th>
                  <th>
                    Кл. записей
                  </th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                  <th></th>
                </tr>
                </thead>
                <tbody id="dragula">
                <faq-field :item="item" v-for="item in faqs" :key="item._id"/>
                </tbody>
              </table>
            </div>
            <faq-form ref="form"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FAQField from './components/FAQField.vue'
import FAQForm from './components/FAQForm.vue'

export default {
  name: 'AdminFAQ',
  components: {'faq-field': FAQField, 'faq-form': FAQForm},
  data: function () {
    return {
      faqs: []
    }
  },
  created: async function () {
    await this.getData()
  },
  mounted: function () {
    const self = this
    // eslint-disable-next-line no-undef
    dragula([document.getElementById('dragula')])
      .on('drop', async function () {
        const rows = document.querySelectorAll('tr[data-id]')
        let ids = []
        for (const row of rows) {
          if (row.className !== 'gu-mirror') {
            ids.push(row.getAttribute('data-id'))
          }
        }
        await self.$store.dispatch('adminFAQ/sort', {ids})
      })
  },
  methods: {
    handlerCreate: function () {
      this.$refs.form.enable = false
      this.$refs.form.name = ''
      this.$refs.form.id = false
      // eslint-disable-next-line no-undef
      jQuery('#faq-modal').modal('show')
    },
    getData: async function () {
      this.faqs = []
      await this.$store.dispatch('adminFAQ/index')
      this.faqs = this.$store.getters['adminFAQ/faqs']
    }
  }
}
</script>
