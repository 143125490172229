<template>
  <div class="item_toggsl_g">
    <div class="head_tit_sl_togg">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ $t('pwas.components.app.additionalInfo.title') }}</span>
        <info :message="$t('pwas.components.app.additionalInfo.titleInfo')"/>
      </div>
    </div>
    <div class="content_dropd_g">
      <div class="form-group"
           :class="apiValidationErrors.addition_info ? 'has-danger' : ''">
        <label for="addition_info">{{ $t('pwas.components.app.additionalInfo.title') }}
          <info :message="$t('pwas.components.app.additionalInfo.addInfo')"/>
        </label>
        <input type="text" id="addition_info" :placeholder="$t('pwas.components.app.additionalInfo.title')"
               v-model="value.languages[lang].addition_info" class="form-control"
               :class="apiValidationErrors.addition_info ? 'form-control-danger' : ''"
               @focus="clearError('addition_info')" v-if="lang">
        <input type="text" id="addition_info" :placeholder="$t('pwas.components.app.additionalInfo.title')"
               v-model="value.addition_info"
               class="form-control"
               :class="apiValidationErrors.addition_info ? 'form-control-danger' : ''"
               @focus="clearError('addition_info')" v-else>
        <label v-if="apiValidationErrors.addition_info" class="error mt-2 text-danger"
               for="addition_info">
          {{ apiValidationErrors.addition_info[0] }}
        </label>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.author_label ? 'has-danger' : ''">
          <label for="author_label">{{ $t('pwas.components.app.additionalInfo.author') }}
            <info :message="$t('pwas.components.app.additionalInfo.authorInfo')"/>
          </label>
          <input type="text" id="author_label"
                 :placeholder="$t('pwas.components.app.additionalInfo.author')"
                 v-model="value.languages[lang].author_label" class="form-control"
                 :class="apiValidationErrors.author_label ? 'form-control-danger' : ''"
                 @focus="clearError('author_label')" v-if="lang">
          <input type="text" id="author_label"
                 :placeholder="$t('pwas.components.app.additionalInfo.author')" v-model="value.author_label"
                 class="form-control"
                 :class="apiValidationErrors.author_label ? 'form-control-danger' : ''"
                 @focus="clearError('author_label')" v-else>
          <label v-if="apiValidationErrors.author_label" class="error mt-2 text-danger"
                 for="author_label">
            {{ apiValidationErrors.author_label[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob" :class="apiValidationErrors.updated ? 'has-danger' : ''">
          <label for="updated">{{ $t('pwas.components.app.additionalInfo.updated') }}
            <info :message="$t('pwas.components.app.additionalInfo.updatedInfo')"/>
          </label>
          <input type="text" id="updated" :placeholder="$t('pwas.components.app.additionalInfo.updated')"
                 v-model="value.languages[lang].updated" class="form-control"
                 :class="apiValidationErrors.updated ? 'form-control-danger' : ''"
                 @focus="clearError('updated')" v-if="lang">
          <input type="text" id="updated" :placeholder="$t('pwas.components.app.additionalInfo.updated')"
                 v-model="value.updated"
                 class="form-control"
                 :class="apiValidationErrors.updated ? 'form-control-danger' : ''"
                 @focus="clearError('updated')" v-else>
          <label v-if="apiValidationErrors.updated" class="error mt-2 text-danger" for="updated">
            {{ apiValidationErrors.updated[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.updated_at ? 'has-danger' : ''">
          <label for="updated_at">{{ $t('pwas.components.app.additionalInfo.updatedDate') }}
            <info :message="$t('pwas.components.app.additionalInfo.updatedDateInfo')"/>
          </label>
          <input type="text" id="updated_at" :placeholder="$t('pwas.components.app.additionalInfo.updated')"
                 v-model="value.languages[lang].updated_at" class="form-control"
                 :class="apiValidationErrors.total ? 'form-control-danger' : ''"
                 @focus="clearError('updated_at')" v-if="lang">
          <input type="text" id="updated_at" :placeholder="$t('pwas.components.app.additionalInfo.updated')"
                 v-model="value.updated_at"
                 class="form-control"
                 :class="apiValidationErrors.updated_at ? 'form-control-danger' : ''"
                 @focus="clearError('updated_at')" v-else>
          <label v-if="apiValidationErrors.updated_at" class="error mt-2 text-danger" for="updated_at">
            {{ apiValidationErrors.updated_at[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.size_label ? 'has-danger' : ''">
          <label for="size_label">{{ $t('pwas.components.app.additionalInfo.size') }}
            <info :message="$t('pwas.components.app.additionalInfo.sizeInfo')"/>
          </label>
          <input type="text" id="size_label" :placeholder="$t('pwas.components.app.additionalInfo.size')"
                 v-model="value.languages[lang].size_label" class="form-control"
                 :class="apiValidationErrors.size_label ? 'form-control-danger' : ''"
                 @focus="clearError('size_label')" v-if="lang">
          <input type="text" id="size_label" :placeholder="$t('pwas.components.app.additionalInfo.size')"
                 v-model="value.size_label"
                 class="form-control"
                 :class="apiValidationErrors.size_label ? 'form-control-danger' : ''"
                 @focus="clearError('size_label')" v-else>
          <label v-if="apiValidationErrors.size_label" class="error mt-2 text-danger" for="size_label">
            {{ apiValidationErrors.size_label[0] }}
          </label>
        </div>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.installed_label ? 'has-danger' : ''">
          <label for="installed_label">{{ $t('pwas.components.app.additionalInfo.installs') }}
            <info :message="$t('pwas.components.app.additionalInfo.installsInfo')"/>
          </label>
          <input type="text" id="installed_label"
                 :placeholder="$t('pwas.components.app.additionalInfo.installs')"
                 v-model="value.languages[lang].installed_label" class="form-control"
                 :class="apiValidationErrors.installed_label ? 'form-control-danger' : ''"
                 @focus="clearError('installed_label')" v-if="lang">
          <input type="text" id="installed_label"
                 :placeholder="$t('pwas.components.app.additionalInfo.installs')"
                 v-model="value.installed_label"
                 class="form-control"
                 :class="apiValidationErrors.installed_label ? 'form-control-danger' : ''"
                 @focus="clearError('installed_label')" v-else>
          <label v-if="apiValidationErrors.installed_label" class="error mt-2 text-danger"
                 for="installed_label">
            {{ apiValidationErrors.installed_label[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.installed ? 'has-danger' : ''">
          <label for="installed">{{ $t('pwas.components.app.additionalInfo.countInstall') }}
            <info :message="$t('pwas.components.app.additionalInfo.countInstallInfo')"/>
          </label>
          <input type="text" id="installed" :placeholder="$t('pwas.components.app.additionalInfo.installs')"
                 v-model="value.installed"
                 class="form-control"
                 :class="apiValidationErrors.installed ? 'form-control-danger' : ''"
                 @focus="clearError('installed')">
          <label v-if="apiValidationErrors.installed" class="error mt-2 text-danger" for="installed">
            {{ apiValidationErrors.installed[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.current_version_label ? 'has-danger' : ''">
          <label for="current_version_label">{{ $t('pwas.components.app.additionalInfo.currentVersion') }}
            <info :message="$t('pwas.components.app.additionalInfo.currentVersionLabelInfo')"/>
          </label>
          <input type="text" id="current_version_label"
                 :placeholder="$t('pwas.components.app.additionalInfo.currentVersion')"
                 v-model="value.languages[lang].current_version_label"
                 class="form-control"
                 :class="apiValidationErrors.current_version_label ? 'form-control-danger' : ''"
                 @focus="clearError('current_version_label')" v-if="lang">
          <input type="text" id="current_version_label"
                 :placeholder="$t('pwas.components.app.additionalInfo.currentVersion')"
                 v-model="value.current_version_label"
                 class="form-control"
                 :class="apiValidationErrors.current_version_label ? 'form-control-danger' : ''"
                 @focus="clearError('current_version_label')" v-else>
          <label v-if="apiValidationErrors.current_version_label" class="error mt-2 text-danger"
                 for="current_version_label">
            {{ apiValidationErrors.current_version_label[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.current_version ? 'has-danger' : ''">
          <label for="current_version">{{ $t('pwas.components.app.additionalInfo.currentVersion') }}
            <info :message="$t('pwas.components.app.additionalInfo.currentVersionInfo')"/>
          </label>
          <input type="text" id="current_version"
                 :placeholder="$t('pwas.components.app.additionalInfo.currentVersion')"
                 v-model="value.current_version" class="form-control"
                 :class="apiValidationErrors.current_version ? 'form-control-danger' : ''"
                 @focus="clearError('current_version')">
          <label v-if="apiValidationErrors.current_version" class="error mt-2 text-danger"
                 for="current_version">
            {{ apiValidationErrors.current_version[0] }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'

export default {
  name: 'AdditionInfoApp',
  mixins: [formMixin],
  components: {Info},
  props: {
    value: {
      type: Object,
      require: true
    },
    errors: {
      type: Object,
      require: true
    }
  },
  computed: {
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  }
}
</script>
