export default {
  navigation: 'Vertical',
  title: 'PWA Vertical #{id}',
  load: 'Load custom',
  message: 'Choose a set of PWA images or upload your own',
  imageSet: 'Picture Set',
  verticalLabel: 'Vertical',
  topGames: 'Choose your top games',
  pwa: 'Only working PWAs will be displayed.',
  loading: {
    title: 'Upload your <br>images',
    message: 'Select the file you need in jpg, png format. The file size is not more than 2 mb.',
    icon: 'Icon',
    preview: 'Preview',
    button: 'Use'
  },
  youtube: {
    title: 'YouTube video',
    placeholder: 'Enter a YouTube video link or frame',
    require: 'YouTube video is required when using the new template'
  },
  template: {
    title: 'Template',
    templates: ['Standard', 'New 2022', 'PlayMarket (Beta)', 'Standard (Beta)']
  },
  muted: {
    title: 'Sound to Video',
    info: 'The sound will only play if the user has interacted with the page',
    on: 'On',
    off: 'Off'
  }
}
