<template>
  <modal ref="modal" id="pwa-template-form" class="fade modal_teamwork"
         classContent="modal-content modal_padd_normal style_form_lab" classDialog="modal-dialog">
    <button v-if="!processing" type="button" @click="handlerCancel" class="close_sjgs_to d_flex align_center"><span
      aria-hidden="true">×</span>
    </button>
    <div class="title_mod_centr_g">{{ title }}</div>
    <div class="form_add_user_str">
      <form v-if="!loading" @submit.prevent="handlerSave">
        <div class="item_row_usr_add">
          <div>
            <div class="mb-4" :class="apiValidationErrors.name ? 'has-danger' : ''">
              <div class="sibmti_sdf">{{ $t('templates.form.name') }}</div>
              <input
                type="text"
                class="form-control"
                id="name" name="name"
                v-model="name"
                :class="apiValidationErrors.name ? 'form-control-danger' : ''"
                :placeholder="$t('templates.form.namePlaceholder')"
                maxlength="65"
                @focus="clearError('name')"
              >
              <label v-if="apiValidationErrors.name" class="error mt-2 info_warning_gdg" for="name">
                {{ apiValidationErrors.name[0] }}
              </label>
            </div>
            <BaseFromGroup
              v-if="user.isAdmin"
              id="owner"
              name="owner"
              :label="$t('templates.form.owner')"
              :has-error="apiValidationErrors.owner"
              :error-text="apiValidationErrors?.owner ? apiValidationErrors?.owner[0] : ''"
            >
              <BaseSelect
                v-model="owner"
                id="owner"
                name="owner"
                sort="-createdAt"
                endpoint="/users/filter"
                :placeholder="$t('templates.form.ownerPlaceholder')"
              />
            </BaseFromGroup>
            <BaseFromGroup
              id="pwaId"
              name="pwaId"
              label="PWA"
              :has-error="apiValidationErrors.pwaId"
              :error-text="apiValidationErrors?.pwaId ? apiValidationErrors?.pwaId[0] : ''"
            >
              <BaseSelect
                v-model="pwaId"
                id="pwaId"
                name="pwaId"
                endpoint="/pwas/select"
                :filter="{admin : !!user.isAdmin}"
                sort="-createdAt"
                :placeholder="$t('templates.form.pwaPlaceholder')"
              />
            </BaseFromGroup>
            <div class="mb-4" :class="apiValidationErrors.googlePlayURL ? 'has-danger' : ''">
              <div class="sibmti_sdf">{{ $t('templates.form.googlePlayURL') }}</div>
              <input type="text" class="form-control" id="googlePlayURL" name="googlePlayURL"
                     :class="apiValidationErrors.googlePlayURL ? 'form-control-danger' : ''"
                     v-model="googlePlayURL"
                     :disabled="id"
                     :placeholder="$t('templates.form.googlePlayURLPlaceholder')"
                     @focus="clearError('googlePlayURL')">
              <label v-if="apiValidationErrors.googlePlayURL" class="error mt-2 info_warning_gdg" for="googlePlayURL">
                {{ apiValidationErrors.googlePlayURL[0] }}
              </label>
            </div>
            <select2
              v-if="!!googlePlayURL"
              class="mb-4"
              id="lang"
              name="lang"
              v-model="lang"
              :options="whitePageLanguages"
              inputClass="form-control select_white_g"
              :placeholder="$t('templates.form.langPlaceholder')"
            >
              <template v-slot:before>
                <div class="sibmti_sdf">{{ $t('templates.form.lang') }}</div>
              </template>
              <template v-slot:after>
                <label v-if="apiValidationErrors.lang" class="error mt-2 info_warning_gdg" for="lang">
                  {{ apiValidationErrors.lang[0] }}
                </label>
              </template>
            </select2>
          </div>
        </div>
        <div class="logir_ff_form d_flex justify_center">
          <button class="ful_butt_green d_flex align_center justify_center" type="submit" :disabled="processing">
            <span v-if="!processing">{{ $t('general.save') }}</span>
            <hollow-dots-spinner
              v-else
              :animation-duration="1000"
              :dot-size="15"
              :dots-num="3"
              :color="'#ff1d5e'"
            />
          </button>
        </div>
      </form>
    </div>
  </modal>
</template>
<script>
import {HollowDotsSpinner} from 'epic-spinners'
import {whitePageLanguages} from '@pwa.group/pwa.dictionary'
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

const defaultValues = () => ({
  id: null,
  name: '',
  owner: null,
  googlePlayURL: '',
  pwaId: '',
  lang: ''
})

export default {
  name: 'PWATemplateForm',
  mixins: [formMixin, select2AjaxMixin],
  components: {BaseSelect, BaseFromGroup, Modal, Select2, HollowDotsSpinner},
  data () {
    return {
      ...defaultValues(),
      loading: true,
      processing: false,
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
      whitePageLanguages: whitePageLanguages.map(lang => ({
        id: lang.id,
        text: this.$t(lang.text)
      })),
      pwaIdSettings: {},
      pwaIdOptions: [],
      ownerSettings: {},
      ownerOptions: []
    }
  },
  computed: {
    title () {
      return this.id != null ? this.$t('templates.form.editTitle', {name: this.name}) : this.$t('templates.form.createTitle')
    },
    user () {
      return this.$store.getters['identity']
    }
  },
  created () {
    this.pwaIdSettings = {...this.getSelect2AjaxSettings('pwas/filter'), allowClear: true}
    if (this.user.isAdmin) {
      this.ownerSettings = {...this.getSelect2AjaxSettings('users/filter'), allowClear: true}
    }
  },
  methods: {
    handlerCancel () {
      this.skip()
      this.$refs.modal.close()
      this.resolvePromise(false)
    },
    create () {
      this.skip()
      this.$refs.modal.open()
      this.$nextTick(() => {
        this.loading = false
      })
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async update ({id, name, owner, googlePlayURL, pwaId, lang}) {
      this.skip()
      this.id = id
      this.name = name
      this.owner = owner
      this.googlePlayURL = googlePlayURL
      this.pwaId = pwaId
      this.lang = lang
      this.pwaIdOptions = await this.getSelect2AjaxOptions(this.pwaId, 'pwas/filter')
      if (this.user.isAdmin) {
        this.ownerOptions = await this.getSelect2AjaxOptions(this.owner, 'users/filter')
      }
      this.$refs.modal.open()
      this.$nextTick(() => {
        this.loading = false
      })
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    skip () {
      this.loading = true
      this.processing = false
      this.pwaIdOptions = []
      this.ownerOptions = []
      this.resetApiValidation()
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    async handlerSave () {
      if (this.processing === false) {
        try {
          this.processing = true
          this.resetApiValidation()
          const payload = {
            name: this.name
          }
          if (this.user.isAdmin) {
            payload.owner = this.owner
          }
          if (!this.id) {
            payload.googlePlayURL = this.googlePlayURL
            payload.pwaId = this.pwaId
            payload.lang = this.lang
          }
          if (this.id != null) {
            await this.$store.dispatch('pwasTemplates/update', {...payload, id: this.id})
          } else {
            await this.$store.dispatch('pwasTemplates/create', payload)
          }
          this.resetApiValidation()
          this.skip()
          this.$refs.modal.close()
          this.resolvePromise(true)
        } catch (error) {
          this.setApiValidation(error.response.data.errors)
          this.$nextTick(() => {
            this.processing = false
          })
        }
      }
    }
  }
}
</script>
<style scoped>
.item_fds_ggo.has-danger {
  margin-bottom: 4px;
}

.item_fds_ggo.has-danger .form-control {
  border-color: #FD5E53;
}

.item_fds_ggo.has-danger .form-control::placeholder {
  color: #FD5E53;
}
</style>
