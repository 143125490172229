const state = () => ({
  handlerConfirm: false,
  refModal: null
})

const getters = {
  handlerConfirm: state => state.handlerConfirm,
  refModal: state => state.refModal
}

const mutations = {
  setHandlerConfirm: (state, fn) => {
    state.handlerConfirm = fn
  },
  setRefModal: (state, ref) => {
    state.refModal = ref
  }
}

const actions = {
  init ({commit}, payload) {
    commit('setRefModal', payload)
  },

  beenChanges ({commit}, payload) {
    commit('setHandlerConfirm', payload)
  },

  clearChanges ({commit}) {
    commit('setHandlerConfirm', false)
  }
}

const autoSave = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default autoSave
