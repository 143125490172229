<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12 profile-page">
        <div class="card">
          <loading v-model="loading" class="card-body">
            <div class="row">
              <button @click="$router.go(-1)" class="btn btn-icons btn-rounded btn-outline-success col-2"><i
                class="icon-action-undo"></i></button>
              <nav aria-label="breadcrumb" class="col-10">
                <ol class="breadcrumb bg-dark">
                  <li class="breadcrumb-item">
                    <router-link :to="{name: 'Admin'}">Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link :to="{name: 'AdminUsers'}">Пользователи</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><span>{{ user.username }}</span></li>
                </ol>
              </nav>
            </div>
            <profile-header />
            <div class="profile-body">
              <ul class="nav tab-switch" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="logs-tab" data-toggle="pill" href="#logs"
                     role="tab" aria-controls="logs" aria-selected="true">Логи</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pwas-tab" data-toggle="pill" href="#pwas"
                     role="tab" aria-controls="pwas" aria-selected="true">PWAs</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="referrals-tab" data-toggle="pill" href="#referrals"
                     role="tab" aria-controls="referrals" aria-selected="true">Рефералы</a>
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{name: 'AdminUserTariffs', params: {id: $route.params.id}}"
                    class="nav-link"
                  >Підписки</router-link>
                </li>
              </ul>
              <div class="row">
                <div class="col-md-10">
                  <div class="tab-content tab-body" id="profile-log-switch">
                    <div class="tab-pane fade show active pr-3" id="logs" role="tabpanel"
                         aria-labelledby="logs-tab">
                      <logs/>
                    </div>
                    <div class="tab-pane fade show pr-3" id="pwas" role="tabpanel"
                         aria-labelledby="pwas-tab">
                      <pwas/>
                    </div>
                    <div class="tab-pane fade show pr-3" id="referrals" role="tabpanel"
                         aria-labelledby="referrals-tab">
                      <referrals/>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <h5 class="my-4">Счета</h5>
                  <bills/>
                </div>
              </div>
            </div>
          </loading>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import Loading from '@/pages/admin/components/Loading.vue'
import ProfileHeader from './components/ProfileHeader.vue'
import Logs from './components/Logs.vue'
import Referrals from './components/Referrals.vue'
import Bills from './components/Bills.vue'
import PWAs from './components/PWAs.vue'

export default {
  name: 'AdminUser',
  components: {Loading, ProfileHeader, Logs, Referrals, Bills, pwas: PWAs},
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'adminUsers/user'
    })
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  methods: {
    ...mapActions({
      getUser: 'adminUsers/read'
    }),
    async asyncData () {
      this.loading = true
      await this.getUser(this.$route.params.id)
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
