import layout from './layout.vue'
import create from './create.vue'
import update from './update.vue'
import images from './images.vue'
import countries from './countries.vue'

export default (meta, header) => {
  return [
    {
      path: 'offers',
      name: 'BotOffersLayout',
      components: {default: layout, header},
      meta,
      redirect: '/',
      children: [
        {
          path: 'create',
          name: 'BotOffersCreate',
          components: {default: create, header},
          meta
        },
        {
          path: ':id/update',
          name: 'BotOffersUpdate',
          components: {default: update, header},
          meta
        },
        {
          path: ':id/images',
          name: 'BotOffersImages',
          components: {default: images, header},
          meta
        },
        {
          path: ':id/countries',
          name: 'BotOffersCountries',
          components: {default: countries, header},
          meta
        }
      ]
    }
  ]
}
