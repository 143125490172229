import create from './create/en'
import components from './components/en'
import tracker from './tracker/en'

/* eslint-disable no-template-curly-in-string */
export default {
  headers: {
    domain: 'Domain',
    domainInfo: 'Domain name of your PWA',
    expired: 'Deleting',
    expiredInfo: 'Time to automatically, permanently deleting the PWA'
  },
  trash: {
    move: 'Move to trash',
    empty: 'PWA Trash empty',
    moveMessage: 'Are you sure you want to trash PWA {name}? You will have 2 days to recover',
    moveMessageRelation: 'Are you sure you want to trash PWA {name}? You will have 2 days to recover. This PWA will also be removed from the Top Games in other PWAs!',
    moveButton: 'To trash',
    moveSuccess: 'The PWA has been successfully moved to trash',
    restore: 'Restore the PWA',
    restoreTitle: 'Restore PWA {name}?',
    restoreMessage: 'Are you sure you want to restore PWA {name}?',
    restoreButton: 'Restore',
    restoreSuccess: 'The PWA has been successfully restored',
    goToPWA: 'Goto PWA'
  },
  createNew: 'Create PWA',
  pwaTitle: 'Name of your PWA',
  status: 'Status',
  statusTitle: 'Status of your PWA',
  tags: 'Tags',
  tagsTitle: 'PWA destination tags',
  trackerHead: 'Tracker',
  trackerTitle: 'Information about the current tracker connected to PWA',
  filters: {
    search: 'Search by domain',
    tag: 'Filter by tag',
    addTag: 'Add a new tag',
    status: 'Filter by status',
    vertical: 'Vertical filter',
    category: 'Filter by Category'
  },
  buttons: {
    checkDomain: 'Check domain',
    run: 'Run',
    stop: 'Stop',
    pay: 'Pay',
    preview: 'Preview',
    cloak: {
      buttonTitle: 'Download JS script',
      title: 'JS to use Cloak',
      body: `Add this JS code at the end of the <body> tag section before the closing </body> tag`
    },
    customJs: {
      buttonTitle: 'Insert your JS script',
      title: 'Custom JS',
      body: 'This JS code will be added to your PWA',
      bulkBody: 'This JS code will be added to your PWAs',
      success: 'Custom JS saved'
    },
    meta: {
      buttonTitle: 'Insert your META tags',
      title: 'Custom META tags',
      body: 'This META tags will be added to your PWA',
      bulkBody: 'This META tags will be added to your PWAs',
      success: 'Custom META tags saved'
    }
  },
  checkDomain: {
    title: 'Domain Check',
    body: [
      'Your domain has not yet been verified. Domain verification can take up to 2 hours',
      'Domain <strong>{domain}</strong> does not have https protocol. For correct work enable https Rewrite',
      'Domain <strong>{domain}</strong> verified',
      'Domain <strong>{domain}</strong> is not wrapped in <strong>CloudFlare</strong>'
    ]
  },
  delete: {
    title: 'Remove PWA',
    message: 'Are you sure you want to remove PWA {name}?',
    button: 'Delete',
    success: 'PWA Removed'
  },
  copy: {
    title: 'Copy PWA',
    message: 'Are you sure you want to copy PWA {name}?',
    button: 'Copy',
    success: 'PWA copied',
    tracker: {
      message: 'Copy {name} with or without tracker?',
      okButton: 'With tracker',
      cancelButton: 'No tracker'
    }
  },
  paid: {
    title: 'PWA Activation',
    warning: 'After PWA activation, it is impossible to change the domain!',
    button: 'Activate',
    success: 'PWA activated',
    cancel: 'Activation cancelled',
    error: 'Activation error',
    noTariff: 'A tariff must be issued to work with PWA',
    paidTariff: `You have one activation in the tariff. After activating this PWA, you will have no activations left. Activate PWA?
    | You have {count} activations in the plan. After activating this PWA, you will have one activation left. Activate PWA?
    | You have {count} activations in the tariff. After activating this PWA, you will have {countLeft} activations left. Activate PWA?`,
    paidBalance: 'You have run out of activations in the tariff, you can activate PWAs above the tariff for ${amount}.',
    noMoney: 'You have run out of activations in the tariff, you can activate PWA above the tariff for ${amount}, or switch to a higher tariff. For this, it is necessary to top up the balance.',
    paidInstalls: 'Activation in the installation plan is free, but there will be a charge for each PWA installation'
  },
  play: {
    title: 'Launch PWA',
    message: 'Are you sure you want to run PWA {name}?',
    button: 'Start',
    success: 'PWA launched'
  },
  stop: {
    title: 'Stop PWA',
    message: 'Are you sure you want to stop PWA {name}?',
    button: 'Stop',
    success: 'PWA stopped'
  },
  notFoundByFilters: 'No PWAs found matching filter criteria',
  noHave: 'You don\'t have any PWAs created yet. Click the "Create PWA" button',
  insufficientFunds: 'Not enough funds',
  save: 'PWA saved',
  verticals: ['Nutra', 'Betting', 'Dating', 'Gambling', 'Trading', 'Crypto'],
  statuses: ['Blocked', 'Domain Check', 'HTTPs Check', 'Not Paid', 'Stopped', 'Working', 'Technical stop'],
  categories: [
    'Automobiles and transport',
    'Libraries and demos',
    'Business',
    'Video players and editors',
    'Art and Design',
    'Food and drinks',
    'Games',
    'Tools',
    'Housing and home',
    'Health and Fitness',
    'Acquaintance',
    'Maps and Navigation',
    'Books',
    'Comics',
    'The beauty',
    'Motherhood and childhood',
    'The medicine',
    'Events',
    'Music',
    'News and magazines',
    'Education',
    'Lifestyle',
    'Communication',
    'Personalization',
    'Weather',
    'Purchases',
    'Travels',
    'Work',
    'Entertainment',
    'Social',
    'Sport',
    'Life style',
    'Finance',
    'Photo'
  ],
  create,
  components,
  tracker,
  group: {
    successRemove: 'The PWAs have been successfully removed',
    successCopy: 'The PWAs have been successfully copied',
    successTrash: 'The PWAs have been successfully moved to trash',
    successRestore: 'The PWAs have been successfully restored',
    successPlay: 'PWAs are launched',
    successStop: 'PWAs have been stopped',
    successMeta: 'Custom META tags saved',
    successCustomJs: 'Custom JS has been saved'
  },
  pushPlacement: {
    pageIsLoaded: 'When page is loaded',
    trafficBackIsTriggered: 'When traffic back is triggered',
    afterInstall: 'After install',
    afterOpen: 'After PWA open'
  },
  pushPlacementTooltips: {
    pageIsLoaded: 'When page is loaded',
    trafficBackIsTriggered: 'When traffic back is triggered',
    afterInstall: 'After install',
    afterOpen: 'After PWA open'
  }
}
