import AdminCloudflare from './index.vue'

export default (meta, header) => {
  return [
    {
      path: 'cloudflare',
      name: 'AdminCloudflare',
      components: {default: AdminCloudflare, header},
      meta
    }
  ]
}
