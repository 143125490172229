export function daysBetweenTwoDates (begin, end) {
  const beginDate = new Date(begin)
  const endDate = new Date(end)
  const differenceInMilliseconds = endDate - beginDate
  const millisecondsPerDay = 1000 * 60 * 60 * 24

  return differenceInMilliseconds / millisecondsPerDay
}

export function splitDateOnChunks (begin, end, range = 6) {
  const beginDate = new Date(begin)
  const endDate = new Date(end)
  const dateChunks = []
  let currentStartDate = beginDate

  while (currentStartDate < endDate) {
    let currentEndDate = new Date(currentStartDate)

    currentEndDate.setDate(currentStartDate.getDate() + range - 1) // Add n days to get a n+1 day period
    if (currentEndDate > endDate) {
      currentEndDate = endDate // Ensure the end date does not exceed the original end date
    }
    dateChunks.push({
      begin: currentStartDate.toISOString().split('T')[0],
      end: currentEndDate.toISOString().split('T')[0]
    })
    currentStartDate = new Date(currentEndDate)
    currentStartDate.setDate(currentStartDate.getDate() + 1) // Move to the next day after the current chunk

    if (currentStartDate === endDate) {
      dateChunks.push({
        begin: currentStartDate.toISOString().split('T')[0],
        end: currentStartDate.toISOString().split('T')[0]
      })
    }
  }

  return dateChunks
}
