export default {
  navigation: 'Приложение',
  title: 'Приложение PWA #{id}',
  message: 'Проведите текстовые настройки страницы PWA',
  main: {
    title: 'Основное',
    titleInfo: 'Приоритетные настройки, которые проводятся в самом начале',
    name: 'Название',
    nameInfo: 'Введите название Вашего PWA',
    author: 'Автор',
    authorInfo: 'Введите разработчика PWA',
    category: 'Категория',
    categoryInfo: 'Выберите категорию к которому относится Ваш PWA',
    categoryInscription: 'Категория(надпись)',
    categoryInscriptionInfo: 'Если нужно другое названия категории, например для локализации. Если оставить пустым будет взята из названия категории',
    ad: 'Реклама',
    adInfo: 'Присутствует ли в Вашем PWA реклама',
    age: 'Возраст',
    ageInfo: 'Для лиц какого возраста Ваше PWA',
    ratingM: 'Рейтинг текст(мобильное)',
    ratingMInfo: 'Слово "Рейтинг" выводится в мобильной версии',
    sizeM: 'Размер(мобильное)',
    sizeMInfo: 'Размер приложения в Мб который показывается в мобильной версии',
    ageM: 'Возраст текст(мобильное)',
    ageMInfo: 'Слово "Возраст"  в мобильной версии',
    recommendationD: 'Рекомендация(ПК)',
    recommendationDInfo: 'Рекомендация от магазина. Показывается в ПК версии',
    wishlistD: 'Список желаний(ПК)',
    wishlistDInfo: 'Фраза "Добавить в желаемое". Показывается в ПК версии',
    commentLabel: 'Отзывов',
    commentLabelInfo: 'Перевод слова "отзывов"',
    canInstall: 'Доступность приложения',
    canInstallInfo: 'Доступность приложения'
  },
  install: {
    title: 'Кнопка установки',
    titleInfo: 'Текстовые настройки надписей на кнопке и при загрузке',
    install: 'Установить',
    installInfo: 'Текст надписи на кнопке',
    installing: 'Инициализация',
    installingInfo: 'Текст, который показывается после нажатию на кнопку "Установить"',
    download: 'Загрузка',
    downloadInfo: 'Текст, который показывается после "Инициализация"',
    open: 'Открыть',
    openInfo: 'Текст, который показывается после "Загрузка"'
  },
  description: {
    title: 'Описание',
    titleInfo: 'Настройки описания вашего PWA',
    aboutM: 'О приложении(Мобильное)',
    aboutMInfo: 'Фраза "О приложении" в мобильной версии',
    readMore: 'Подробнее',
    readMoreInfo: 'Кликабельный текст "Подробнее"',
    hide: 'Скрыть',
    hideInfo: 'Кликабельный текст "Скрыть". Появляется после того, как нажат текст "Подробнее"',
    description: 'Описание',
    descriptionInfo: 'Описание Вашего PWA',
    descriptionPlaceholder: 'Введите описания PWAs (для пользователей)',
    update: 'Обновления(ПК)',
    updateInfo: 'Фраза "Что нового". Показывается в ПК версии',
    updateDesc: 'Описания обновления(ПК)',
    updateDescInfo: 'Описание последнего обновления',
    dataSecurity: 'Безопасность данных',
    dataSecurityInfo: 'Надпись',
    dataSecurityText: 'Описание "Безопасность данных"',
    dataSecurityTextInfo: 'Описание "Безопасность данных"',
    dataSecurityLink: 'Подчеркнутый текст в "Безопасности данных"',
    dataSecurityLinkInfo: 'Подчеркнутый текст в "Безопасности данных"',
    noneInfo: 'Текст в "Безопасности данных"',
    noneInfoInfo: 'Текст в "Безопасности данных"'
  },
  rating: {
    title: 'Рейтинг',
    titleInfo: 'Настройки и регулировка рейтинга в PWA',
    reviews: 'Отзывы',
    reviewsInfo: 'Текст "Оценки и Отзывы"',
    ratingInfo: 'Поставьте рейтинг Вашего PWA по 5-бальной шкале',
    countComments: 'Кл. отзывов',
    countCommentsInfo: 'Количество оценок Вашего PWA',
    total: 'Всего(ПК)',
    totalInfo: 'Слово "Всего". Показывается в ПК версии',
    count: 'Количество оценок {num}',
    reviewsVerified: 'Надпись "Оценки и отзывы проверены"',
    reviewsVerifiedInfo: 'Надпись "Оценки и отзывы проверены"',
    reviewHelp: 'Надпись "человека отметили этот отзыв как полезный."',
    reviewHelpInfo: 'Надпись "человека отметили этот отзыв как полезный."',
    reviewHelpTitle: 'Надпись "Вам помогла эта информация?"',
    reviewHelpTitleInfo: 'Надпись "Вам помогла эта информация?"',
    reviewHelpYes: 'Ответ "Да"',
    reviewHelpYesInfo: 'Ответ "Да" на предыдущий вопрос',
    reviewHelpNo: 'Ответ "Нет"',
    reviewHelpNoInfo: 'Ответ "Нет" на предыдущий вопрос',
    reviewAll: 'Надпись "Все отзывы"',
    reviewAllInfo: 'Текст кнопки просмотра все отзывов'
  },
  additionalInfo: {
    title: 'Доп. информация',
    titleInfo: 'Дополнительная информация, которая выводится внизу страницы PWA',
    addInfo: 'Надпись "Дополнительная информация"',
    author: 'Разработчик',
    authorInfo: 'Надпись "Разработчик"',
    updated: 'Обновлено',
    updatedInfo: 'Надпись "Обновлено"',
    updatedDate: 'Дата обновления',
    updatedDateInfo: 'Введите дату обновления',
    size: 'Размер',
    sizeInfo: 'Надпись "Размер"',
    installs: 'Установок',
    installsInfo: 'Надпись "Установок"',
    countInstall: 'Количество установок',
    countInstallInfo: 'Введите количество установок',
    currentVersion: 'Текущая версия',
    currentVersionLabelInfo: 'Надпись "Текущая версия"',
    currentVersionInfo: 'Введите текущую версию',
    complain: 'Надпись "Пожаловаться"',
    complainInfo: 'Надпись "Пожаловаться"',
    developerContact: 'Надпись "Связь с разработчиком"',
    developerContactInfo: 'Надпись "Связь с разработчиком"',
    developerSite: 'Надпись "Сайт"',
    developerSiteInfo: 'Надпись "Сайт"',
    developerUrl: 'Сайт',
    developerUrlInfo: 'Ссылка на сайт компании',
    developerEmailLabel: 'Надпись "Электронная почта"',
    developerEmailLabelInfo: 'Надпись "Электронная почта"',
    developerEmail: 'Электронная почта',
    developerEmailInfo: 'Электронная почта компании',
    developerAddressLabel: 'Надпись "Адрес"',
    developerAddressLabelInfo: 'Надпись "Адрес"',
    developerAddress: 'Адрес компании',
    developerAddressInfo: 'Адрес компании',
    politicsPrivacy: 'Надпись "Политика конфиденциальности"',
    politicsPrivacyInfo: 'Надпись "Политика конфиденциальности"',
    politicsPrivacyUrl: 'Ссылка на политику',
    politicsPrivacyUrlInfo: 'Ссылка на политику'
  },
  ttPreland: {
    title: 'Тикток преленд',
    titleInfo: 'Надписи на преленде для TikTok',
    template: 'Шаблон',
    templateInfo: 'Выберите шаблон для преленда',
    variant: 'Вариант {num}',
    ttHeader: 'Вы хотите перейти в',
    ttHeaderInfo: 'Вопрос на странице "TikTok"',
    ttYes: 'Ответ "Да" на странице "TikTok"',
    ttNo: 'Ответ "Нет" на странице "TikTok"',
    ttForce: 'Обязательный преленд для любых хостов',
    ttForceInfo: 'Если не стоит то преленд показывается только для Andriod Chrome WebView хостов'
  },
  cookies: {
    title: 'Cookies',
    titleInfo: 'Использование файлов cookies',
    cookiesText: 'Запрос cookies',
    cookiesTextInfo: 'Текст "Вы согласны с использованием файлов cookies?"',
    cookiesAgree: 'Подтверждение',
    cookiesAgreeInfo: 'Текст кнопки подтверждения',
    cookiesDisagree: 'Отказ',
    cookiesDisagreeInfo: 'Текст кнопки отказа'
  },
  translateDeleted: 'Перевод удален'
}
