export default {
  methods: {
    getPWALogo: (images, vertical, gallery) => {
      if (images === -1) {
        return import.meta.env.VITE_APP_API_BASE_URL + gallery[0]
      } else {
        const image = images + 1
        return `${import.meta.env.VITE_APP_API_BASE_URL}/verticals/${vertical}/${image}/${image}.jpg`
      }
    }
  }
}
