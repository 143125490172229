<template>
  <div>
    <div class="style_descr_g">
      <div class="container_w_sm_la">
        <h4 class="title_sf_site">Офер {{ offerName }}</h4>
        <p class="descr_site_shg mb_small">
          Відредагуйте назву офера на 3-х мовах, якщо вона
          пишеться однаково то продублюйте в усі поля. Також заповніть необхідні дані для PWA генератора
        </p>
      </div>
    </div>
    <loading v-model="loading" class="style_form_lab mt-4">
      <div class="container_w_sm_la">
        <bot-offer-form
          v-model="model"
          :errors="apiValidationErrors"
          @submit="handlerSubmit"
        />
      </div>
    </loading>
  </div>
</template>
<script>
import BotOfferForm from './_form.vue'
import offerMixin from './offer-mixins'
import {mapActions} from 'vuex'
import formMixin from '@/mixins/form-mixin'

export default {
  name: 'BotOffersUpdate',
  components: {BotOfferForm},
  mixins: [offerMixin, formMixin],
  data () {
    return {
      model: {
        enable: false,
        name: {
          uk: '',
          en: '',
          ru: ''
        },
        pwa: {
          name: '',
          vertical: ''
        },
        tracker: {
          template: '',
          redirect: ''
        }
      }
    }
  },
  async created () {
    await this.getOffer(this.$route.params.id)
    setTimeout(() => {
      this.model.name = this.offer.name
      this.model.enable = this.offer.enable
      this.model.pwa = this.offer.pwa
      this.model.tracker = this.offer.tracker
      this.loading = false
    }, 500)
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      update: 'bot/updateOffer'
    }),
    async handlerSubmit (payload) {
      try {
        this.resetApiValidation()
        await this.update({...this.offer, ...payload})
        this.resetApiValidation()
        await this.alertSuccess('Офер успішно оновлено')
        await this.$router.push({name: 'BotOffersImages', params: {id: this.offer.id}})
      } catch (e) {
        this.setApiValidation(e.response.data.errors)
      }
    }
  }
}
</script>
