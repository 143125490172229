export default {
  title: 'Шаблони PWA',
  create: 'Створити шаблон',
  deleteTitle: 'Видалення шаблону PWA',
  deleteMessage: 'Ви впевнені, що хочете видалити шаблон PWA?',
  name: 'Назва',
  owner: 'Користувач',
  googlePlayURL: 'Play Market URL',
  pwaId: 'PWA',
  createdAt: 'Створено',
  updatedAt: 'Оновлено',
  idTitle: 'ID шаблону',
  nameTitle: 'Назви шаблону у списках',
  ownerTitle: 'Власник шаблону',
  googlePlayURLTitle: 'URL з якого робили імпорт',
  pwaIdTitle: 'PWA з якого робили імпорт',
  createdAtTitle: 'Дата створення шаблону',
  updatedAtTitle: 'Дата оновлення шаблону',
  actionsTitle: 'Дії над шаблоном',
  deleteButton: 'Видалити',
  deleted: 'Шаблон видалено',
  filters: {
    id: 'Пошук за ID',
    name: 'Пошук за назвою',
    owner: 'Пошук за власником',
    googlePlayURL: 'Пошук за URL',
    pwaId: 'Пошук PWA'
  },
  form: {
    createTitle: 'Створення шаблону PWA',
    editTitle: 'Редагування шаблону {name}',
    name: 'Назви шаблону',
    namePlaceholder: 'Введіть назву шаблону',
    owner: 'Власник шаблону',
    ownerPlaceholder: 'Вкажіть власника шаблону',
    pwaPlaceholder: 'Вкажіть PWA для імпорту',
    googlePlayURL: 'URL Play Market',
    googlePlayURLPlaceholder: 'Вкажіть URL для імпорту',
    lang: 'Мова програми',
    langPlaceholder: 'Вкажіть мову програми'
  },
  apps: {
    title: 'Застосувати шаблон',
    your: 'Ваші шаблони',
    notSelected: 'Шаблон не вибраний'
  },
  success: 'Шаблон успішно збережено'
}
