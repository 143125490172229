export default {
  navigation: 'Аналітика',
  title: 'Аналітика PWA #{id}',
  message: 'Інтегруйте свій код аналітики в PWA',
  YMInfo: 'Вставте короткий ідентифікатор YM. Ми автоматично відстежуємо дію "Лід". Лідом є відкриття Pwa',
  nativePush: 'Збирати PUSH в pwa.group',
  nativePushInfo: 'Активуйте для збору та відправлення PUSH через сервіс pwa.group',
  POSInfo: 'Ідентифікатор One Signal',
  forwardingSignalUserId: 'Прокидати OneSignal user id і External User Id (у сигнал і урл os_user_id)',
  forwardingSignalUserIdInfo: 'Якщо потрібно прокинути OneSignal user id у PWA на посилання у форматі ..&onesignalid=SIG_USER_ID',
  sendPush: 'Надсилання пушків з <a href="https://push.express/">PUSH.express</a>'
}
