<template>
  <div class="modal modal_center_g" id="bitcoin-form" tabindex="-1">
    <div class="modal-dialog big_modal_w" role="document" v-if="bill">
      <div class="modal-content modal_padd_normal">
        <div class="modal_title_g">{{ $t('finance.bills.bitcoin.title') }}</div>
        <div class="sumb_tot_g">
          <div class="d_flex justify_between">
            <div>
              <div class="subtit_sub_g">{{ $t('finance.bills.amount') }}</div>
              <div class="num_bitcoins_g">
                <div class="d_flex align_center">
                  <div class="total_bitg_g"><span><span id="bitcoins_tot">{{ bill.total }}</span> BTC</span>
                  </div>
                  <div class="icon_cop_mrg">
                    <button class="btn_simple_copy btn-clipboard" data-clipboard-target="#bitcoins_tot"
                            data-clipboard-action="copy"><span class="icon_copu_smg"></span></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="right_sumshg_" v-if="bill.status === 1">
              <div class="subtit_sub_g">{{ $t('finance.bills.bitcoin.confirmed') }}</div>
              <div class="stepshjs_g">{{ bill.confirmation }}/6</div>
            </div>
          </div>
        </div>
        <div class="link_copy_cos">
          <div class="d_flex align_center">
            <div class="flex_grow_1 info_cosjf_bord d_flex align_center">
              <div class="w-100">
                <div class="subtit_sub_g">{{ $t('finance.bills.bitcoin.wallet') }}</div>
                <div class="num_code_cos">
                  <div class="in_to_num_cod_cos">
                    <input type="text" id="number_bitc" readonly="" :value="bill.merchant_id">
                  </div>
                </div>
              </div>
            </div>
            <div class="flex_grow_0 right_but_cop_g">
              <button class="copy_butt_bord d_flex align_center justify_center btn-clipboard"
                      data-clipboard-action="copy" data-clipboard-target="#number_bitc">
                  <span>
                    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M22.75 8.675C22.737 8.56017 22.7118 8.44704 22.675 8.3375V8.225C22.6149 8.09648 22.5347 7.97833 22.4375 7.875L14.9375 0.375C14.8342 0.27777 14.716 0.197601 14.5875 0.1375C14.5502 0.1322 14.5123 0.1322 14.475 0.1375C14.348 0.0646766 14.2078 0.0179303 14.0625 0H9C8.00544 0 7.05161 0.395088 6.34835 1.09835C5.64509 1.80161 5.25 2.75544 5.25 3.75V5H4C3.00544 5 2.05161 5.39509 1.34835 6.09835C0.645088 6.80161 0.25 7.75544 0.25 8.75V21.25C0.25 22.2446 0.645088 23.1984 1.34835 23.9017C2.05161 24.6049 3.00544 25 4 25H14C14.9946 25 15.9484 24.6049 16.6517 23.9017C17.3549 23.1984 17.75 22.2446 17.75 21.25V20H19C19.9946 20 20.9484 19.6049 21.6517 18.9017C22.3549 18.1984 22.75 17.2446 22.75 16.25V8.75C22.75 8.75 22.75 8.75 22.75 8.675ZM15.25 4.2625L18.4875 7.5H16.5C16.1685 7.5 15.8505 7.3683 15.6161 7.13388C15.3817 6.89946 15.25 6.58152 15.25 6.25V4.2625ZM15.25 21.25C15.25 21.5815 15.1183 21.8995 14.8839 22.1339C14.6495 22.3683 14.3315 22.5 14 22.5H4C3.66848 22.5 3.35054 22.3683 3.11612 22.1339C2.8817 21.8995 2.75 21.5815 2.75 21.25V8.75C2.75 8.41848 2.8817 8.10054 3.11612 7.86612C3.35054 7.6317 3.66848 7.5 4 7.5H5.25V16.25C5.25 17.2446 5.64509 18.1984 6.34835 18.9017C7.05161 19.6049 8.00544 20 9 20H15.25V21.25ZM20.25 16.25C20.25 16.5815 20.1183 16.8995 19.8839 17.1339C19.6495 17.3683 19.3315 17.5 19 17.5H9C8.66848 17.5 8.35054 17.3683 8.11612 17.1339C7.8817 16.8995 7.75 16.5815 7.75 16.25V3.75C7.75 3.41848 7.8817 3.10054 8.11612 2.86612C8.35054 2.6317 8.66848 2.5 9 2.5H12.75V6.25C12.75 7.24456 13.1451 8.19839 13.8483 8.90165C14.5516 9.60491 15.5054 10 16.5 10H20.25V16.25Z"
                        fill="#21BF73"/>
                    </svg>
                  </span>
              </button>
            </div>
          </div>
        </div>
        <div class="bitcoin_cgl_g d_flex">
          <div class="qr_cod_g">
            <img class="img-fluid" :src="bill.pay_url | APIImage" alt="">
          </div>
          <div class="info_bit_g">
            <div class="icon_bit_g"><img src="/images/icon_bitcoin.svg" alt=""></div>
            <div class="tit_bit_g">Bitcoin</div>
            <div class="subtit_sub_g">{{ $t('finance.bills.expiresIn') }} <span class="yel_col_g">{{
                bill.expired | dateAgo
              }}</span>
            </div>
            <div class="pric_eis_g d_flex justify_between">
              <div class="subb_bit_g">${{ bill.amount }}</div>
              <!-- <div class="percent_s_g">-4.54%</div> -->
            </div>
            <div class="actions_aus_g">
              <div class="d_flex align_center">
                <div class="d_inline_block item_butt_acat_g">
                  <button class="ful_butt_green d_flex align_center justify_center" @click="handlerCheck">
                    {{ labelCheckButton }}
                  </button>
                </div>
                <div class="d_inline_block item_butt_acat_g">
                  <a href="javascript:void(0);" class="border_butt_green d_flex align_center justify_center" data-dismiss="modal">
                    {{ $t('general.cancel') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BitcoinForm',
  props: ['id'],
  data: function () {
    return {
      bill: false
    }
  },
  computed: {
    labelCheckButton: function () {
      return this.bill.status === 0 ? this.$t('finance.bills.bitcoin.paid') : this.$t('finance.bills.bitcoin.check')
    }
  },
  methods: {
    handlerCheck: async function () {
      await this.$store.dispatch('billing/get', this.bill._id)
      this.bill = this.$store.getters['billing/bill']
      if (this.bill.confirmation === 6) {
        await this.$parent.getData()
        this.hide()
      }
    },
    show: function () {
      // eslint-disable-next-line no-undef
      jQuery(`#${this.id}`).modal('show')
    },
    hide: function () {
      // eslint-disable-next-line no-undef
      jQuery(`#${this.id}`).modal('hide')
    }
  }
}
</script>
