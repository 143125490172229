export default {
  navigation: 'Вертикаль',
  title: 'Вертикаль PWA #{id}',
  load: 'Завантажити свої',
  message: 'Виберіть набір картинок для PWA або завантажте свої',
  imageSet: 'Набір картинок',
  verticalLabel: 'Вертикаль',
  topGames: 'Оберіть свої топ ігри',
  pwa: 'Виведені будуть тільки працюючі PWA',
  loading: {
    title: 'Завантажте ваші зображення ',
    message: 'Виберіть потрібний файл у форматі jpg, png. Розмір файлу не більше 2 мб.',
    icon: 'Іконка',
    preview: 'Прев\'ю',
    button: 'Використовувати'
  },
  youtube: {
    title: 'YouTube відео',
    placeholder: 'Введіть посилання або фрейм на YouTube відео',
    require: 'Відео YouTube є обов\'язковим при використанні нового шаблону'
  },
  template: {
    title: 'Шаблон',
    templates: ['Стандартний', 'Новий 2022', 'PlayMarket (Beta)', 'Стандартний (Beta)']
  },
  muted: {
    title: 'Звук у відео',
    info: 'Звук буде лише, якщо користувач взаємодіяв зі сторінкою',
    on: 'Ввімкнено',
    off: 'Вимкнено'
  }
}
