<template>
  <div class="content-wrapper">
    <proxy-form ref="ProxyForm"/>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Proxies</h4>
            <p class="card-description">Управления проксями</p>
            <div class="row">
              <div class="col-md-6 col-lg mb-2">
                <select
                  :value="filter.status"
                  @input="handlerFilter($event, 'status')"
                  class="form-control">
                  <option value="" selected>Фильтр по статусу</option>
                  <option v-for="(label, value) of statuses" :key="value" :value="value">{{ label }}</option>
                </select>
              </div>
              <div class="col-md-6 col-lg mb-2">
                <input :value="filter.proxy"
                       @input="handlerFilter($event, 'proxy')" class="form-control" placeholder="Поиск по proxy"/>
              </div>
            </div>
            <loading v-model="loading" class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>
                    <div class="form-check form-check-primary">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="checkAll">
                        <i class="input-helper"></i>
                      </label>
                    </div>
                  </th>
                  <th>
                    <button
                      @click="fetchPage()"
                      class="btn btn-icons btn-inverse-light">
                      <i class="icon-refresh"></i>
                    </button>
                    <button
                      @click="handlerCreateMany"
                      class="btn btn-icons btn-inverse-primary"
                    ><i class="icon-plus"></i></button>
                  </th>
                  <th>Статус</th>
                  <th>Proxy</th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model.id">
                  <td>
                    <div class="form-check form-check-primary">
                      <label class="form-check-label">
                        <input
                          :id="`check-${model.id}`"
                          @input="handlerAddToGroups(model.id)" :value="model.id"
                          type="checkbox"
                          class="form-check-input"
                        >
                        <i class="input-helper"></i>
                      </label>
                    </div>
                  </td>
                  <td>
                    <button @click="handlerCheck(model.id)" class="btn btn-icons btn-inverse-warning">
                      <i class="icon-refresh"></i>
                    </button>
                    <button @click="handlerDelete(model)" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                  </td>
                  <td>
                    <select class="form-control" @input="handlerUpdate($event, model)" v-model="model.status">
                      <option v-for="(label, value) of statuses" :key="value" :value="value">{{ label }}</option>
                    </select>
                  </td>
                  <td>{{ model.proxy }}</td>
                  <td>{{ model.createdAt | dateFromNow }}</td>
                  <td>{{ model.updatedAt | dateFromNow }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td v-if="groups.length">Обрано</td>
                  <td v-if="groups.length">{{ groups.length }}</td>
                  <td v-if="groups.length">Групова дія</td>
                  <td v-if="groups.length">
                    <button @click="handlerGroupCheck" class="btn btn-icons btn-inverse-warning">
                      <i class="icon-refresh"></i>
                    </button>
                    <button @click="handlerGroupDelete" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                    <button class="btn btn-sm btn-inverse-primary dropdown-toggle" type="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Статус
                    </button>
                    <div class="dropdown-menu">
                      <a
                        v-for="(label, value) of statuses" :key="value"
                        @click="handlerGroupUpdate(value, label)"
                        href="javascript:void(0);"
                        class="dropdown-item">{{ label }}</a>
                    </div>
                  </td>
                  <td v-if="!groups.length">Всего</td>
                  <td v-if="!groups.length">{{ count }}</td>
                  <td v-if="!groups.length" colspan="2"></td>
                  <td colspan="2">
                    <nav v-if="pageCount > 1">
                      <paginate v-model="page"
                                :page-count="pageCount"
                                :click-handler="handlerPagination"
                                :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                                :next-text="'<i class=\'icon-arrow-right\'></i>'"
                                container-class="pagination mt-3"
                                page-class="page-item"
                                page-link-class="page-link"
                                prev-class="page-item"
                                next-class="page-item"
                                prev-link-class="page-link"
                                next-link-class="page-link"
                                active-class="active"/>
                    </nav>
                  </td>
                </tr>
                </tfoot>
              </table>
            </loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import ProxyForm from './ProxyForm.vue'
import {mapActions, mapGetters} from 'vuex'
import Loading from '@/pages/admin/components/Loading.vue'

export default {
  name: 'Proxies',
  components: {Loading, ProxyForm},
  data () {
    return {
      loading: true,
      page: 1,
      limit: 20,
      groups: []
    }
  },
  computed: {
    ...mapGetters({
      models: 'adminProxies/models',
      count: 'adminProxies/count',
      filter: 'adminProxies/filter'
    }),
    statuses () {
      return this.$store.getters['adminProxies/statuses']
    },
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    checkAll: {
      get () {
        if (this.count === 0) return false
        return this.groups.length >= this.count
      },
      set () {
        this.models.map(model => {
          const el = document.getElementById(`check-${model.id}`)
          el.click()
        })
      }
    }
  },
  created () {
    this.resetFilter()
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      fetchData: 'adminProxies/asyncData',
      update: 'adminProxies/update',
      delete: 'adminProxies/delete',
      setFilter: 'adminProxies/setFilter',
      check: 'adminProxies/check'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      if (this.models.length === 0 && this.page > 1) {
        this.handlerPagination(this.page - 1)
      }
      this.loading = false
    }, 500),
    fetchPage () {
      this.loading = true
      this.groups = []
      this.debounceFetchData({
        limit: this.limit,
        page: this.page,
        sort: '-createdAt',
        filter: this.filter
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    async handlerUpdate ($event, model) {
      await this.update({id: model.id, status: $event.target.value})
      await this.alertSuccess('Proxy обновлена')
    },
    async handlerCreateMany () {
      if (await this.$refs.ProxyForm.handlerCreate()) {
        await this.handlerPagination(1)
      }
    },
    async handlerDelete (model) {
      if (confirm(`Удалить proxy ${model.proxy}`)) {
        this.loading = true
        await this.delete(model.id)
        await this.alertSuccess(`${model.proxy} видалено`)
        await this.fetchPage()
      }
    },
    async setterFilter (filter, input) {
      this.loading = true
      const payload = this.filter
      payload[filter] = input
      await this.setFilter(payload)
      await this.handlerPagination(1)
    },
    async resetFilter () {
      this.loading = true
      await this.setFilter({
        status: '',
        proxy: ''
      })
      await this.handlerPagination(1)
    },
    handlerAddToGroups (id) {
      const index = this.groups.indexOf(id)
      if (index === -1) {
        this.groups.push(id)
      } else {
        this.groups.splice(index, 1)
      }
    },
    async handlerGroupDelete () {
      if (confirm(`Видалити ${this.groups.length} проксей?`)) {
        this.loading = true
        for (const id of this.groups) {
          await this.delete(id)
        }
        await this.alertSuccess(`${this.groups.length} проксей видалено`)
        await this.fetchPage()
      }
    },
    async handlerGroupUpdate (status, label) {
      if (confirm(`Змінити статус для ${this.groups.length} проксі на ${label}?`)) {
        this.loading = true
        for (const id of this.groups) {
          await this.update({id: id, status})
        }
        await this.alertSuccess(`Змінено статус на ${label} для ${this.groups.length} проксі`)
        await this.fetchPage()
      }
    },
    handlerFilter: _.debounce(async function ($event, prop) {
      await this.setterFilter(prop, $event.target.value)
    }, 200),
    async handlerCheck (id) {
      this.loading = true
      await this.check(id)
      await this.fetchPage()
    },
    async handlerGroupCheck () {
      if (confirm(`Розпочати перевірку ${this.groups.length} проксі?`)) {
        this.loading = true
        for (const id of this.groups) {
          await this.check(id)
        }
        await this.alertSuccess(`Перевірку ${this.groups.length} проксі завершено`)
        await this.fetchPage()
      }
    }
  }
}
</script>
