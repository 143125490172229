<template>
  <form @submit.prevent="handlerForm">
    <div class="selections_data_usr restyle_checkbox_go">
      <div class="selections_data_usr">
        <div class="title_this_sel_g">{{ $t('analytics.columnsSettingTitle') }}</div>
        <div class="item_data_sel_us clearfix">
          <div class="row">
            <group v-model="actions"/>
            <group v-model="postback"/>
            <group v-if="Object.keys(pwa.items).length" v-model="pwa"/>
            <group v-if="Object.keys(calculate.items).length" v-model="calculate"/>
            <group v-if="Object.keys(unique.items).length" v-model="unique"/>
            <group v-if="Object.keys(landings.items).length" v-model="landings"/>
          </div>
        </div>
      </div>
    </div>
    <div class="logir_ff_form d_flex justify_center">
      <button class="ful_butt_green d_flex align_center justify_center" type="submit">{{ $t('general.save') }}</button>
    </div>
  </form>
</template>
<script>
import Group from './Group.vue'

export default {
  name: 'ColumnsSettingForm',
  components: {Group},
  props: {
    value: Object,
    columns: Object
  },
  data () {
    return {
      unique: {label: 'analytics.columnsLabels.uniqueLabel', items: this.getItemsByGroup('unique')},
      actions: {label: 'analytics.columnsLabels.actionsLabel', items: this.getItemsByGroup('actions')},
      postback: {label: 'analytics.columnsLabels.postbackLabel', items: this.getItemsByGroup('postback')},
      pwa: {label: 'analytics.columnsLabels.pwaLabel', items: this.getItemsByGroup('pwa')},
      calculate: {label: 'analytics.columnsLabels.calculateLabel', items: this.getItemsByGroup('calculate')},
      landings: {label: 'analytics.columnsLabels.landingsLabel', items: this.getItemsByGroup('landings')}
    }
  },
  methods: {
    getItemsByGroup (group) {
      const items = {}
      for (const [prop, value] of Object.entries(this.columns)) {
        if (value.group === group) {
          items[prop] = {
            label: value.label,
            use: this.value.columns.includes(prop)
          }
        }
      }
      return items
    },
    getColumnFromItems (items) {
      const columns = []
      for (const [prop, value] of Object.entries(items)) {
        if (value.use) {
          columns.push(prop)
        }
      }
      return columns
    },
    handlerForm () {
      let columns = ['id'].concat(this.getColumnFromItems(this.actions.items))
        .concat(this.getColumnFromItems(this.postback.items))
        .concat(this.getColumnFromItems(this.calculate.items))
        .concat(this.getColumnFromItems(this.unique.items))
        .concat(this.getColumnFromItems(this.pwa.items))
        .concat(this.getColumnFromItems(this.landings.items))
      let sort = this.value.sort
      const sortColumn = sort.substring(1, 0) === '-' ? sort.substring(1) : sort
      if (!columns.includes(sortColumn)) {
        sort = '-id'
        this.$emit('optionsChange', {sort})
      }
      this.$emit('optionsChange', {columns})
      this.$parent.$emit('input', false)
    }
  }
}
</script>
