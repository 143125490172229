export default {
  navigation: 'Tracker',
  title: 'PWA tracker #{id}',
  message: '',
  tracker: 'Tracker',
  trackerInfo: 'Select a Tracker campaign that will work with this PWA',
  trackerPlaceholder: 'Select a tracker',
  geo: 'Multi-GEO',
  geoTooltip: 'Multi-GEO works to distribute your traffic across different GEOs. If you\'re using a cloaking system, it is advisable to enable this feature to avoid traffic loss.',
  clGeoRedirect: 'Specify URL to redirect to your white',
  validator: {
    redirect: {
      notEmpty: 'Link for redirect required'
    }
  }
}
