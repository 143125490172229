import analytics from './analytics/uk'
import auth from './auth/uk'
import bot from './bot/uk'
import dashboard from './dashboard/uk'
import dashboardLayout from './dashboardLayout/uk'
import finance from './finance/uk'
import general from './general/uk'
import layouts from './layouts/uk'
import menu from './menu/uk'
import modalSave from './modalSave/uk'
import notFound from './notFound/uk'
import pushes from './pushes/uk'
import pwas from './pwas/uk'
import referrals from './referrals/uk'
import tariffs from './tariffs/uk'
import teams from './teams/uk'
import templates from './templates/uk'
import tickets from './tickets/uk'
import titles from './titles/uk'
import trackers from './trackers/uk'
import landingPages from './landing-pages/uk'
import group from './group/uk'
import pixels from './pixels/uk'
import banners from './banners/uk'
import components from './components/uk'
import validations from './validations/uk'
import mirrors from './mirrors/uk'
import vacancies from './vacancies/uk'

// eslint-disable-next-line no-undef
jQuery.fn.datepicker.dates['uk'] = {
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  daysMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  today: 'Today',
  clear: 'Очистити',
  format: 'mm/dd/yyyy',
  titleFormat: 'MM yyyy', /* Leverages same syntax as 'format' */
  weekStart: 1
}
export default {
  ID: 'ID',
  PWA: 'PWA',
  IP: 'IP',
  PWAs: 'PWAs',
  PUSH: 'PUSH',
  analytics,
  auth,
  bot,
  dashboard,
  dashboardLayout,
  finance,
  general,
  layouts,
  menu,
  modalSave,
  notFound,
  pushes,
  pwas,
  referrals,
  tariffs,
  teams,
  templates,
  tickets,
  titles,
  trackers,
  landingPages,
  group,
  pixels,
  banners,
  components,
  validations,
  mirrors,
  vacancies
}
