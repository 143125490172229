export const getSearchParams = ({filter, page, limit, sort}) => {
  const searchParams = new URLSearchParams()
  searchParams.set('page', page)
  searchParams.set('limit', limit)
  searchParams.set('sort', sort)
  if (filter != null && Object.keys(filter).length) {
    for (const [prop, value] of Object.entries(filter)) {
      if (value != null && value !== '') {
        searchParams.append(`filter[${prop}]`, value)
      }
    }
  }
  return decodeURI(searchParams.toString())
}

export const getParams = ({filter, page, limit, sort}) => {
  const searchParams = new URLSearchParams()
  searchParams.set('page', page)
  searchParams.set('limit', limit)
  searchParams.set('sort', sort)
  if (filter != null && Object.keys(filter).length) {
    for (const [prop, value] of Object.entries(filter)) {
      if ((value && value.length) || typeof value === 'number') {
        searchParams.append(`${prop}`, value)
      }
    }
  }
  return decodeURI(searchParams.toString())
}
