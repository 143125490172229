export default {
  title: 'Лендінги',
  addLandingTitle: 'Додавання лендінгу',
  editLandingTitle: 'Редагування лендінгу',
  filters: {
    name: 'Фільтр за назвою',
    type: 'Фільтр за типом',
    tag: 'Фільтр за тегом',
    addTag: 'Додати новий тег',
    design: 'Фільтр за назвою дизайну'
  },
  buttons: {
    create: 'Створити новий'
  },
  table: {
    headers: {
      name: 'Назва',
      type: 'Тип',
      tags: 'Теги',
      designName: 'Назва дизайну'
    }
  },
  action: {
    removeLanding: 'Видалення лендінгу "{name}"',
    removeMessage: 'Після видалення лендінгу всі PWA, де він використовувався, більше не відображатимуть прелендінг або постлендінг. Так само ви більше не зможете відновити дані',
    successfullRemove: 'Лендінг "{name}" успішно видалено'
  },
  mainForm: {
    header: 'Основне',
    info: 'Головна інформація про лендінг',
    name: 'Назва прелендінгу',
    tags: 'Теги',
    tagsTips: 'Додати новий тег',
    themeSearsh: 'Пошук за назвою гри',
    type: 'Тип',
    wheels: 'Колесо',
    slots: 'Слоти'
  },
  paramsForm: {
    header: 'Параметри',
    info: 'Додаткова інформація про лендінг',
    body: 'Текст',
    buttonGo: 'Текст на кнопці',
    buttonMore: 'Текст на кнопці для повторного оберту',
    revolutions: 'Кількість обертів',
    logo: 'Логотип',
    download: 'Завантажити'
  },
  themesForm: {
    header: 'Дизайн ігрового поля',
    info: 'Оберіть дизайн для ігрового поля'
  },
  sectorForm: {
    header: 'Назва секторів',
    info: 'Введіть назву кожного сектора',
    sector: 'Сектор',
    sectorTips: 'Надпис для сектора'
  },
  modalForm: {
    header: 'Модальне вікно',
    info: 'Введіть текст для модального вікна',
    modalHeader: 'Заголовок в вікні бонусів',
    buttonTitle: 'Текст у вікні бонусів',
    modalButton: 'Текст на кнопці вікна бонусів'
  },
  create: {
    title: 'Новий прелендінг'
  },
  validate: {
    name: {
      notEmpty: 'Ім\'я обов\'язкове',
      wrongFormat: 'Невірний формат',
      minLength: 'Ім\'я має бути довшим за 2 символи',
      maxLength: 'Ім\'я має бути коротшим за 50 символів'
    },
    tags: {
      wrongFormat: 'Невірний формат',
      maxSize: 'Не можна добавити більше 5 тегів',
      maxLength: 'Максимальна довжина тега 40 символів'
    },
    type: {
      notEmpty: 'Тип обов\'язковий',
      wrongFormat: 'Невірний формат'
    },
    theme: {
      notEmpty: 'Тема обов\'язкова',
      wrongFormat: 'Невірний формат'
    },
    logo: {
      notEmpty: 'Логотип обов\'язковий',
      wrongFormat: 'Невірний формат'
    },
    body: {
      notEmpty: 'Текст обов\'язковий',
      wrongFormat: 'Невірний формат',
      minLength: 'Текст має бути довшим за 2 символи',
      maxLength: 'Текст має бути коротшим за 99 символів'
    },
    buttons: {
      go: {
        notEmpty: 'Текст обов\'язковий',
        wrongFormat: 'Невірний формат',
        minLength: 'Текст має бути довшим за 2 символи',
        maxLength: 'Текст має бути коротшим за 8 символів'
      },
      more: {
        notEmpty: 'Текст обов\'язковий',
        wrongFormat: 'Невірний формат',
        minLength: 'Текст має бути довшим за 2 символи',
        maxLength: 'Текст має бути коротшим за 8 символів'
      }
    },
    revolutions: {
      notEmpty: 'Оберти обов\'язкові',
      wrongFormat: 'Невірний формат'
    },
    sector: {
      label: {
        notEmpty: 'Текст обов\'язковий',
        wrongFormat: 'Невірний формат',
        minLength: 'Текст має бути довшим за 2 символи',
        maxLength: 'Текст має бути коротшим за 24 символа'
      },
      isWin: {
        notEmpty: 'Виграшний сектор обов\'язковий',
        wrongFormat: 'Невірний формат'
      }
    },
    modal: {
      header: {
        notEmpty: 'Текст обов\'язковий',
        wrongFormat: 'Невірний формат',
        minLength: 'Текст має бути довшим за 2 символи',
        maxLength: 'Текст має бути коротшим за 16 символів'
      },
      body: {
        notEmpty: 'Текст обов\'язковий',
        wrongFormat: 'Невірний формат',
        minLength: 'Текст має бути довшим за 2 символи',
        maxLength: 'Текст має бути коротшим за 45 символів'
      },
      button: {
        notEmpty: 'Текст обов\'язковий',
        wrongFormat: 'Невірний формат',
        minLength: 'Текст має бути довшим за 2 символи',
        maxLength: 'Текст має бути коротшим за 8 символів'
      }
    }
  }
}
