import service from '@/store/services/translator-service'
import middleware from './middleware'

const state = () => ({
  default: {
    name: 'Название',
    author: 'SuperSoft',
    author_label: 'Разработчик',
    category_label: '',
    ad: 'Без рекламы',
    rating_label: 'Рейтинг',
    age_label: 'Возраст',
    version: 'Выбор редакции',
    wish_list: 'Добавить в желаемое',
    install: 'Установить',
    installing: 'Инициализация...',
    download: 'Загрузка...',
    open: 'Открыть',
    about: 'О приложении',
    read_more: 'Подробнее...',
    hide: 'Меньше',
    text: 'Очередное приложения для Android',
    changes_label: 'Что нового',
    changes_recent: 'Разработчики исправили все баги и добавили много нужных штук',
    reviews_label: 'Оценки и отзывы',
    total: 'Всего',
    addition_info: 'Дополнительная информация',
    updated: 'Обновлено',
    updated_at: '3 сентяря 2021',
    size_label: 'Размер',
    installed_label: 'Установок',
    current_version_label: 'Текущая версия',
    ttHeader: 'Вы хотите перейти в',
    ttYes: 'Да',
    ttNo: 'Нет',
    commentLabel: 'отзывов',
    canInstall: 'Это приложение доступно для некоторых устройств',
    dataSecurity: 'Безопасность данных',
    dataSecurityText:
        'В этом разделе разработчики могут указать, как приложения собирают и используют данные.',
    dataSecurityLink: 'Подробнее о безопасности данных…',
    noneInfo: 'Нет информации',
    reviewsVerified: 'Оценки и отзывы проверены',
    reviewHelp: 'человека отметили этот отзыв как полезный.',
    reviewHelpTitle: 'Вам помогла эта информация?',
    reviewHelpYes: 'Да',
    reviewHelpNo: 'Нет',
    reviewAll: 'Все отзывы',
    complain: 'Пожаловаться',
    developerContact: 'Связь с разработчиком',
    developerSite: 'Сайт',
    developerUrl: 'http://supersoft.com',
    developerEmailLabel: 'Электронная почта',
    developerEmail: 'apps@bagelcode.com',
    developerAddressLabel: 'Адрес',
    developerAddress:
        '16F, Dongwoo Bldg, 328, Teheran-ro, Gangnam-gu, Seoul, 06212, Rep. of KOREA',
    politicsPrivacy: 'Политика конфиденциальности',
    politicsPrivacyUrl: 'http://supersoft.com/policy.html'
  },
  pwa: {},
  comments: {}
})

const mutations = {
  setPWA: (state, payload) => {
    state.pwa = payload
  },

  setComments: (state, payload) => {
    state.comments = payload
  }
}

const actions = {
  pwa: ({commit, dispatch}, params) => service.translate(params)
    .then(response => {
      commit('setPWA', response)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  comments: ({commit, dispatch}, params) => service.translate(params)
    .then(response => {
      commit('setComments', response)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  remove: ({commit, dispatch}, params) => service.remove(params)
    .then(() => {})
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  removeComment: ({commit, dispatch}, params) => service.removeComment(params)
    .then(() => {})
    .catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  pwa: state => state.pwa,
  default: state => state.default,
  comments: state => state.comments
}

const transactions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default transactions
