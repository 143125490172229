export default {
  send: 'Отправить',
  cancel: 'Отмена',
  copy: 'Копировать',
  nothingHere: 'Здесь пока ничего нет',
  save: 'Сохранить',
  yes: 'Да',
  no: 'Нет',
  on: 'Вкл',
  off: 'Выкл',
  not: 'Нет',
  edit: 'Редактировать',
  delete: 'Удалить',
  statistics: 'Статистика',
  apply: 'Применить',
  next: 'Далее',
  read: 'Прочитано',
  familiarized: 'Ознакомлен',
  notEmpty: 'Это поле обязательно',
  recordsPerPage: 'Записей на страницу',
  gotoPage: 'Перейти на страницу',
  close: 'Закрыть',
  refresh: 'Обновить',
  alerts: {
    success: {
      heading: 'Успешно'
    },
    warning: {
      heading: 'Предупреждения'
    },
    error: {
      heading: 'Ошибка'
    }
  },
  logout: {
    title: 'Вы хотите выйти?',
    message: 'Вы точно хотите выйти из своего аккаунта?',
    okButton: 'Выйти'
  }
}
