export default {
  form: {
    title: 'Event Transmission',
    subtitle: 'Events for Tracking',
    info: 'Maximum preliminary number of events for sending - 50 per event',
    hint: 'Tracking will start instantly, but events will be tracked within 6-24 hours, sometimes longer',
    country: {
      placeholder: 'Select a country',
      title: 'Geo',
      errors: {
        required: 'You need to select a country for tracking'
      }
    },
    accessToken: {
      errors: {
        required: `The pixel <strong>{pixel}</strong> is missing an Access Token. Configure all pixels before using the API Conversions`
      }
    },
    conversion: {
      title: 'Conversion Type'
    },
    registrations: {
      title: 'Registration'
    },
    deposits: {
      title: 'Deposit'
    },
    success: {
      create: 'Events for tracking have been successfully queued'
    }
  },
  conversions: {
    meta: 'Meta pixel',
    api: 'API Conversion'
  }
}
