export default {
  title: 'Landings',
  addLandingTitle: 'Adding a landing page',
  editLandingTitle: 'Editing a landing page',
  filters: {
    name: 'Filter by name',
    type: 'Filter by type',
    tag: 'Filter by tag',
    addTag: 'Add new tag',
    design: 'Filter by design name'
  },
  buttons: {
    create: 'Create new'
  },
  table: {
    headers: {
      name: 'Name',
      type: 'Type',
      tags: 'Tags',
      designName: 'Design name'
    }
  },
  action: {
    removeLanding: 'Removing the landing page "{name}"',
    removeMessage: 'After deleting the landing, all PWAs where it was used will no longer display pre- or post-landing. You will also no longer be able to restore the data',
    successfullRemove: 'The landing page "{name}" has been successfully deleted'
  },
  mainForm: {
    header: 'Main',
    info: 'Main information about the landing',
    name: 'Landing Name',
    tags: 'Tags',
    tagsTips: 'Add a new tag',
    themeSearsh: 'Search by game name',
    type: 'Type',
    wheels: 'Wheel',
    slots: 'Slots'
  },
  paramsForm: {
    header: 'Parameters',
    info: 'Additional information about the landing',
    body: 'Text',
    buttonGo: 'Text on the button',
    buttonMore: 'Text on the button for another spin',
    revolutions: 'Number of rotations',
    logo: 'Logo',
    download: 'Download'
  },
  themesForm: {
    header: 'Game Field Design',
    info: 'Choose a design for the game field'
  },
  sectorForm: {
    header: 'Sector Names',
    info: 'Enter the name of each sector',
    sector: 'Sector',
    sectorTips: 'Label for sector'
  },
  modalForm: {
    header: 'Modal Window',
    info: 'Enter text for the modal window',
    modalHeader: 'Header in the bonus window',
    buttonTitle: 'Text in the bonus window',
    modalButton: 'Text on the bonus window button'
  },
  create: {
    title: 'Create a new landing'
  },
  validate: {
    name: {
      notEmpty: 'Name is required',
      wrongFormat: 'Invalid format',
      minLength: 'Name must be longer than 2 characters',
      maxLength: 'Name must be shorter than 50 characters'
    },
    tags: {
      wrongFormat: 'Invalid format',
      maxSize: 'Cannot add more than 5 tags',
      maxLength: 'Tag must be no longer than 40 characters'
    },
    type: {
      notEmpty: 'Type is required',
      wrongFormat: 'Invalid format'
    },
    theme: {
      notEmpty: 'Theme is required',
      wrongFormat: 'Invalid format'
    },
    logo: {
      notEmpty: 'Logo is required',
      wrongFormat: 'Invalid format'
    },
    body: {
      notEmpty: 'Text is required',
      wrongFormat: 'Invalid format',
      minLength: 'Text must be longer than 2 characters',
      maxLength: 'Text must be shorter than 99 characters'
    },
    buttons: {
      go: {
        notEmpty: 'Text is required',
        wrongFormat: 'Invalid format',
        minLength: 'Text must be longer than 2 characters',
        maxLength: 'Text must be shorter than 8 characters'
      },
      more: {
        notEmpty: 'Text is required',
        wrongFormat: 'Invalid format',
        minLength: 'Text must be longer than 2 characters',
        maxLength: 'Text must be shorter than 8 characters'
      }
    },
    revolutions: {
      notEmpty: 'Revolutions are required',
      wrongFormat: 'Invalid format'
    },
    sector: {
      label: {
        notEmpty: 'Text is required',
        wrongFormat: 'Invalid format',
        minLength: 'Text must be longer than 2 characters',
        maxLength: 'Text must be shorter than 24 characters'
      },
      isWin: {
        notEmpty: 'Winning sector is required',
        wrongFormat: 'Invalid format'
      }
    },
    modal: {
      header: {
        notEmpty: 'Text is required',
        wrongFormat: 'Invalid format',
        minLength: 'Text must be longer than 2 characters',
        maxLength: 'Text must be shorter than 16 characters'
      },
      body: {
        notEmpty: 'Text is required',
        wrongFormat: 'Invalid format',
        minLength: 'Text must be longer than 2 characters',
        maxLength: 'Text must be shorter than 45 characters'
      },
      button: {
        notEmpty: 'Text is required',
        wrongFormat: 'Invalid format',
        minLength: 'Text must be longer than 2 characters',
        maxLength: 'Text must be shorter than 8 characters'
      }
    }
  }
}
