<template>
  <div>
    <div class="style_descr_g">
      <div class="container_w_sm_la">
        <h4 class="title_sf_site">ГЕО офера {{ offerName }}</h4>
        <p class="descr_site_shg mb_small">
          Вибір доступних ГЕО для трекера PWA
        </p>
      </div>
    </div>
    <loading v-model="loading" class="style_form_lab mt-4">
      <div class="container_w_sm_la">
        <form class="forms-sample position-relative trackers_form_g" @submit.prevent="handlerSubmit">
          <div class="selections_data_usr">
            <div class="mb-4 clearfix">
              <div
                v-if="regions['europe'] != null">
                <div class="mb-4">
                  <label class="checkbpx_style_ios">
                    <span class="txt_tit_this_g">{{ $t('bot.europe') }}</span>
                    <input type="checkbox" v-model="europe">
                    <span class="icon_checkb_stye"></span>
                  </label>
                </div>
                <div>
                  <div
                    v-for="(model, index) in regions.europe"
                    :key="index"
                    class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input
                        :checked="Object.values(regions.europe[index])[0]"
                        @input="handlerCheckBox($event, regions.europe[index])"
                        type="checkbox">
                      <span class="icon_checkb_inl"></span>
                      <span>{{ getRegionName(model) }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-if="regions['latinAmerica'] != null">
                <div class="mb-4">
                  <label class="checkbpx_style_ios">
                    <span class="txt_tit_this_g">{{ $t('bot.latinAmerica') }}</span>
                    <input type="checkbox" v-model="latinAmerica">
                    <span class="icon_checkb_stye"></span>
                  </label>
                </div>
                <div class="blk_more_optionss">
                  <div
                    v-for="(model, index) in regions.latinAmerica"
                    :key="index"
                    class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input
                        :checked="Object.values(regions.latinAmerica[index])[0]"
                        @input="handlerCheckBox($event, regions.latinAmerica[index])"
                        type="checkbox">
                      <span class="icon_checkb_inl"></span>
                      <span>{{ getRegionName(model) }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-if="regions['america'] != null">
                <div class="mb-4">
                  <label class="checkbpx_style_ios">
                    <span class="txt_tit_this_g">{{ $t('bot.america') }}</span>
                    <input type="checkbox" v-model="america">
                    <span class="icon_checkb_stye"></span>
                  </label>
                </div>
                <div class="blk_more_optionss">
                  <div
                    v-for="(model, index) in regions.america"
                    :key="index"
                    class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input
                        :checked="Object.values(regions.america[index])[0]"
                        @input="handlerCheckBox($event, regions.america[index])"
                        type="checkbox">
                      <span class="icon_checkb_inl"></span>
                      <span>{{ getRegionName(model) }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="regions['asia'] != null">
              <div class="mb-4">
                <label class="checkbpx_style_ios">
                  <span class="txt_tit_this_g">{{ $t('bot.asia') }}</span>
                  <input type="checkbox" v-model="asia">
                  <span class="icon_checkb_stye"></span>
                </label>
              </div>
              <div class="blk_more_optionss">
                <div
                  v-for="(model, index) in regions.asia"
                  :key="index"
                  class="item_inline_checkbxx">
                  <label class="inline_checkbox_fff">
                    <input
                      :checked="Object.values(regions.asia[index])[0]"
                      @input="handlerCheckBox($event, regions.asia[index])"
                      type="checkbox">
                    <span class="icon_checkb_inl"></span>
                    <span>{{ getRegionName(model) }}</span>
                  </label>
                </div>
              </div>
              <div
                v-if="regions['australiaAndOceania'] != null">
                <div class="mb-4">
                  <label class="checkbpx_style_ios">
                    <span class="txt_tit_this_g">{{ $t('bot.australiaAndOceania') }}</span>
                    <input type="checkbox" v-model="australiaAndOceania">
                    <span class="icon_checkb_stye"></span>
                  </label>
                </div>
                <div class="blk_more_optionss">
                  <div
                    v-for="(model, index) in regions.australiaAndOceania"
                    :key="index"
                    class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input
                        :checked="Object.values(regions.australiaAndOceania[index])[0]"
                        @input="handlerCheckBox($event, regions.australiaAndOceania[index])"
                        type="checkbox">
                      <span class="icon_checkb_inl"></span>
                      <span>{{ getRegionName(model) }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 pt-2">
            <button class="ful_butt_green d_flex align_center justify_center mobile-full-w" type="submit">
              {{ $t('general.save') }}
            </button>
          </div>
        </form>
      </div>
    </loading>
  </div>
</template>
<script>
import offerMixin from './offer-mixins'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'BotOffersCountries',
  mixins: [offerMixin],
  data () {
    return {
      latinAmerica: false,
      europe: false,
      asia: false,
      america: false,
      australiaAndOceania: false,
      regions: {
        america: [
          {'en_CA:en_US': false},
          {'fr_CA:fr_CA': false}
        ],
        latinAmerica: [
          {'pt_BR:pt_BR': false},
          {'es_PE:es_ES': false},
          {'es_CL:es_ES': false},
          {'es_UY:es_ES': false},
          {'es_AR:es_ES': false},
          {'es_MX:es_ES': false},
          {'co_CO:es_ES': false}
        ],
        europe: [
          {'de_AT:de_DE': false},
          {'de_DE:de_DE': false},
          {'pl_PL:pl_PL': false},
          {'cs_CZ:cs_CZ': false},
          {'pt_PT:pt_PT': false},
          {'sk_SK:sk_SK': false},
          {'sl_SI:sl_SI': false},
          {'el_GR:el_GR': false},
          {'lv_LV:lv_LV': false},
          {'lt_LT:lt_LT': false},
          {'et_EE:et_EE': false},
          {'ro_RO:ro_RO': false},
          {'da_DK:da_DK': false},
          {'no_NO:no_NO': false},
          {'de_CH:de_DE': false},
          {'de_CH:fr_FR': false},
          {'ga_IE:ga_IE': false},
          {'lb_LU:lb_LU': false},
          {'lb_LU:de_DE': false},
          {'sv_SE:sv_SE': false},
          {'hu_HU:hu_HU': false},
          {'uk_UA:uk_UA': false},
          {'uk_UA:ru_RU': false},
          {'be_BY:be_BY': false},
          {'be_BY:ru_RU': false}
        ],
        asia: [
          {'tr_TR:tr_TR': false},
          {'hi_IN:hi_IN': false},
          {'tl_PH:tl_PH': false},
          {'en_PH:en_US': false},
          {'id_ID:id_ID': false},
          {'ms_MY:ms_MY': false},
          {'th_TH:th_TH': false},
          {'vi_VN:vi_VN': false},
          {'kk_KZ:kk_KZ': false},
          {'kk_KZ:ru_RU': false},
          {'uz_UZ:uz_UZ': false},
          {'bn_BD:bn_BD': false},
          {'en_BD:en_US': false},
          {'ja_JP:ja_JP': false},
          {'ko_KR:ko_KR': false},
          {'ur_PK:ur_PK': false},
          {'en_PK:en_PK': false},
          {'pa_PK:pa_PK': false},
          {'sd_PK:sd_PK': false}
        ],
        australiaAndOceania: [
          {'mi_NZ:mi_NZ': false},
          {'mi_NZ:en_US': false},
          {'en_AU:en_US': false}
        ]
      },
      watchRegion: true
    }
  },
  computed: {
    ...mapGetters({
      locales: 'bot/locales'
    })
  },
  async created () {
    await this.fetchData()
    if (Object.keys(this.locales).length > 0) {
      this.regions = this.locales
    }
    await this.getOffer(this.$route.params.id)
    setTimeout(() => {
      for (const [region, countries] of Object.entries(this.offer.countries)) {
        if (this.regions[region] == null) continue

        for (const index in this.regions[region]) {
          for (const country of countries) {
            if (this.regions[region][index][country] !== undefined) {
              this.regions[region][index][country] = true
            }
          }
        }
        this.groupCheck(region)
        this.$watch(region, (n) => {
          if (this.watchRegion) {
            this.groupCheck(region, n)
          }
        }, {deep: true})
        this.$watch(`regions.${region}`, () => {
          this.groupCheck(region, null)
        }, {deep: true})
      }
      this.loading = false
    }, 500)
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      update: 'bot/updateOffer',
      fetchData: 'bot/localesFetchData'
    }),
    handlerCheckBox ($event, model) {
      for (const [prop, value] of Object.entries(model)) {
        model[prop] = !value
      }
    },
    async handlerSubmit () {
      try {
        const payload = {
          latinAmerica: [],
          europe: [],
          asia: [],
          america: [],
          australiaAndOceania: []
        }
        for (const [prop, value] of Object.entries(this.regions)) {
          let countries = value.filter(input => Object.values(input)[0])
          countries = countries.map(input => Object.keys(input)[0])
          payload[prop] = countries
        }
        await this.update({...this.offer, countries: payload})
        await this.alertSuccess('Офер успішно оновлено')
        await this.$router.push({name: 'BotOffers'})
      } catch (e) {
      }
    },
    getRegionName (model) {
      let [country, lang] = Object.keys(model)[0].split(':')
      country = country.split('_')[1]
      country = this.$t('dictionary.countries.' + country.toLowerCase())

      lang = lang.split('_')[0]
      lang = this.$t('dictionary.locales.' + lang.toLowerCase())

      return `${country}\n(${lang})`
    },
    groupCheck (region, value = null) {
      if (value === null) {
        this.watchRegion = false
        const countAll = this.regions[region].length
        const trusties = []
        this.regions[region].map(input => {
          if (Object.values(input)[0]) {
            trusties.push(true)
          }
        })
        this[region] = trusties.length === countAll
        this.$nextTick(() => {
          this.watchRegion = true
        })
      } else {
        this.regions[region].map((input, index) => {
          this.regions[region][index][Object.keys(input)[0]] = value
        })
      }
    }
  }
}
</script>
