export default {
  navigation: 'Настройки',
  title: 'Настройки PWA #{id}',
  message: 'Настройте приложения под себя',
  tags: 'Теги PWA',
  tagsInfo: 'Набор тегов для фильтрации PWA',
  alias: 'Уникальный Алиас в системе',
  aliasInfo: 'Это системное уникальное системное имя PWA для упрощения понимая где какое PWA',
  installMessage: 'Фейковая загрузка будет проходить от <strong>{stepMin}</strong> до <strong>{stepMax}</strong> шагов с интервалом от <strong>{intervalMin} мс</strong>. до <strong>{intervalMax} мс</strong>. При этом минимально возможное время составит <strong>{minInstallingTime} сек.</strong>, а максимально возможное <strong>{maxInstallingTime} сек.</strong> В среднем фейковая загрузка пройдет за <strong>{avgInstallingTime} сек.</strong>',
  minStep: 'Мин. шагов',
  minStepInfo: 'Этот параметр указывает на минимальное возможное количество шагов загрузки',
  maxStep: 'Макс. шагов',
  maxStepInfo: 'Этот параметр указывает на максимальное возможное количество шагов загрузки',
  minInterval: 'Мин. интервал',
  minIntervalInfo: 'Этот параметр указывает на минимальное возможное время между шагами загрузки',
  maxInterval: 'Макс. интервал',
  maxIntervalInfo: 'Этот параметр указывает на максимальное возможное время между шагами загрузки',
  customJs: 'Пользовательский JS',
  customJsInfo: 'Для того что бы добавить пользовательский JS в ваше PWA обратитесь к администрации сервиса'
}
