import VueSocketIO from 'vue-socket.io'
import SocketIOClient from 'socket.io-client'
import store from '@/store'

export default {
  install (Vue) {
    Vue.use(new VueSocketIO({
      debug: false,
      connection: new SocketIOClient(import.meta.env.VITE_WEBSOCKET_URL, {
        withCredentials: true,
        extraHeaders: {
          Authorization: String(localStorage.getItem('vue-authenticate.vueauth_accessToken'))
        }
      }),
      vuex: {
        store,
        actionPrefix: 'socket_',
        mutationPrefix: 'socket_'
      }
    }))
  }
}
