<template>
  <td>
    <a v-if="ip" @click="handlerFilter" href="javascript:void(0);" :class="{'text-success': ip.id === value}">{{ ip.text }}</a>
    <span v-else class="text-gray">( нет )</span>
  </td>
</template>

<script>
export default {
  props: {
    value: String,
    ip: {
      type: Object,
      default: null
    }
  },
  methods: {
    handlerFilter () {
      if (this.ip.id === this.value) {
        this.$emit('input', '')
      } else {
        this.$emit('input', this.ip.id)
      }
    }
  }
}
</script>
