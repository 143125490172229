import Comments from './Comments.vue'

export default (meta, header) => {
  return [
    {
      path: 'pwas/generator/comments',
      name: 'AdminPWAsComments',
      components: {default: Comments, header},
      meta
    }
  ]
}
