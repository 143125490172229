import Loading from '@/components/Loading.vue'
import {mapActions, mapGetters} from 'vuex'
import {getCurrentLang} from '@/utils/i18n'
import Info from '@/components/Info.vue'

export default {
  components: {Loading, Info},
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      offer: 'bot/offer'
    }),
    offerName () {
      return this.offer != null ? this.offer.name[getCurrentLang()] : ''
    }
  },
  methods: {
    ...mapActions({
      getOffer: 'bot/getOffer'
    })
  }
}
