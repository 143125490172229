import api from './api'
import {getSearchParams} from '../list'

export default {
  adminCreate: (payload) => api.post('admin/ips', payload),
  adminGetPages: (payload) => api.get('admin/ips?' + getSearchParams(payload)),
  adminGetSelect2: (payload) => api.get('admin/ips/select2?' + getSearchParams(payload)),
  adminUpdate: ({id, ...payload}) => api.put(`admin/ips/${id}`, payload),
  adminDelete: id => api.delete(`admin/ips/${id}`)
}
