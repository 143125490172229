export default {
  actions: {
    nothing: 'Select an action'
  },
  remove: {
    action: 'Remove',
    title: 'Deleting selected',
    message: 'Do you really want to delete the highlighted ones?',
    okButton: 'Remove'
  },
  copyPwa: {
    action: 'Copy',
    title: 'Copy selected',
    message: 'Only <span class="text-success">paid</span> ones can be copied. Do you really want to copy the highlighted ones?',
    okButton: 'Copy',
    tracker: {
      message: 'Copy with or without tracker?',
      okButton: 'With tracker',
      cancelButton: 'No tracker'
    }
  },
  copy: {
    action: 'Copy',
    title: 'Copy selected',
    message: 'Do you really want to copy the highlighted ones?',
    okButton: 'Copy'
  },
  restore: {
    action: 'Restore',
    title: 'Restore selected',
    message: 'Do you really want to restore the highlighted ones?',
    okButton: 'Restore'
  },
  trash: {
    action: 'Trash',
    title: 'Move selected to trash',
    message: 'Do you really want to move to trash the highlighted ones? You will have two days to recover',
    messageRelation: 'Do you really want to move to trash the highlighted ones? You will have two days to recover. These PWAs will also be removed from the Top Games!',
    okButton: 'To trash'
  },
  play: {
    action: 'Launch',
    title: 'Launch the highlighted',
    message: 'Are you sure you want to launch dedicated PWAs?',
    okButton: 'Launch'
  },
  stop: {
    action: 'Stop',
    title: 'Stop the highlighted',
    message: 'Are you sure you want to stop the dedicated PWAs?',
    okButton: 'Stop'
  },
  meta: {
    action: 'Custom META tags',
    title: 'Insert your META tags',
    message: 'These META tags will be added on your PWAs',
    okButton: 'Save'
  },
  customJs: {
    action: 'Custom JS',
    title: 'Insert your JS script',
    message: 'This JS code will be added to your PWAs',
    okButton: 'Save'
  },
  pagination: 'Group Actions'
}
