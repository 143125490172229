<template>
  <div class="item_gish_g">
    <button class="button_gmsn_mod" @click="handlerOpen">
      <img class="d_flex" src="/images/icon_plus.svg" alt="">
    </button>
    <modal ref="modal" id="comments-translate-modal" class="fade"
           classContent="modal-content modal_padd_normal" classDialog="modal-dialog">
      <form class="style_form_lab" @submit.prevent="handlerSave">
        <button v-if="total === 0" type="button" class="close_sjgs_to d_flex align_center" @click="handlerCancel"><span
          aria-hidden="true">&times;</span>
        </button>
        <div class="modal_title_g" v-html="$t('pwas.components.translate.newComments', {title, langTo: $t(countries[langTo])})"></div>
        <div v-if="total > 0" class="d-flex justify-content-between mt-2" v-html="$t('pwas.components.translate.process', {count, total})"></div>
        <div v-if="total > 0" class="progress progress-lg mt-2">
          <div class="progress-bar" role="progressbar"
               style="background: #21BF73;box-shadow: 0 4px 8px rgb(33 191 115 / 40%);border-radius: 3px;border: 1px solid #21BF73;"
               :style="{width: progress + '%'}"
               :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <select2
          v-if="total === 0"
          v-model="langFrom"
          :options="options"
          :settings="settings"
          class="form-group"
          id="langFrom"
          name="langFrom">
          <template v-slot:before>
            <label for="langFrom">{{ $t('pwas.components.translate.from') }}</label>
          </template>
        </select2>
        <select2
          v-if="total === 0"
          v-model="langTo"
          class="form-group"
          :options="options"
          :settings="settings"
          id="langTo"
          name="langTo">
          <template v-slot:before>
            <label for="langTo">{{ $t('pwas.components.translate.to') }}</label>
          </template>
        </select2>
        <div v-if="total === 0" class="modal_foot_this translator_foot">
          <div class="in_topshgdjf_g d_flex align_center">
            <div class="item_avt_butt_jg">
              <button type="submit" class="ful_butt_green d_flex align_center justify_center" name="translate">
                {{ $t('pwas.components.translate.translate') }}
              </button>
            </div>
            <div class="item_avt_butt_jg">
              <a href="javascript:void(0);" type="button" class="border_butt_green d_flex align_center justify_center"
                 @click="handlerCancel">
                {{ $t('general.cancel') }}
              </a>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import translatorFormMixin from './translator-form.mixin'
import Select2 from '@/components/Select2.vue'
import mainConnector from '@/connectors/main.connector'

const defaultFields = () => ({
  langFrom: 'ru',
  langTo: 'en',
  progress: 0,
  count: 0,
  total: 0
})

export default {
  name: 'PWAsTranslatorComments',
  mixins: [translatorFormMixin],
  components: {Modal, Select2},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ...defaultFields()
    }
  },
  computed: {
    title () {
      return !this.value.alias ? this.value.name : this.value.alias
    }
  },
  methods: {
    skip () {
      const fields = defaultFields()
      for (const [prop, value] of Object.entries(fields)) {
        this[prop] = value
      }
    },
    async handlerOpen () {
      this.skip()
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        if (this.value.languages[this.langTo] == null) {
          this.value.languages[this.langTo] = {}
        }
        if (this.value.languages[this.langTo].comments == null) {
          this.value.languages[this.langTo].comments = []
        }
        this.total = this.value.comments.length
        for (const comment of this.value.comments) {
          const {answer_author, answer_comment, answer_date, author, avatar, date, likes, rating} = comment
          const translatedComment = await mainConnector.post(`pwas/${this.value._id}/comments/translate/${this.langTo}`, {
            answer_author,
            answer_comment,
            answer_date,
            author,
            avatar,
            date,
            likes,
            rating,
            comment: comment.comment,
            langTo: this.langTo,
            langFrom: this.langFrom,
            rows: {},
            id: comment._id
          })
          this.value.languages[this.langTo].comments.push({...translatedComment, id: 'id' + Math.random().toString(16).slice(2)})
          this.count++
          this.progress = Math.ceil(100 / this.total * this.count)
        }
        this.$parent.languages = this.value.languages
        this.$parent.$parent.changed = {}
        await this.$router.push({name: 'LPWAsComments', params: {id: this.value._id, language: this.langTo}})
        this.handlerCancel()
        await this.$store.dispatch('alerts/success', this.$t('pwas.components.translate.success'))
      } catch ({message}) {
        await this.$store.dispatch('alerts/error', message)
        this.skip()
      }
    }
  }
}
</script>
