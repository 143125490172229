<template>
  <div class="wrapper-table no-hover parent_tooltip">
    <div class="table-responsive">
      <confirm-modal ref="confirmModal" id="bot-keys-confirm-modal"></confirm-modal>
      <loading v-model="loading" tag="table" class="table_border_style table">
        <table-sort-head v-if="models.length > 0" :items="heads" v-model="sort"/>
        <tbody v-if="models.length > 0">
        <tr v-for="model of models" :key="model.id">
          <td>{{ model.id }}</td>
          <td v-html="$options.filters.formatNull(model.member ? model.member.username : null)"></td>
          <td>{{ model.updatedAt | date }}</td>
          <td class="actiosn_ahgg text-right">
            <div class="flex_actions_ghg d_flex justify_end">
              <div class="item_butt_act_g">
                <button
                  :data-clipboard-text="model.id"
                  type="button"
                  class="d_flex align_center justify_center btn_icon_rounded btn-custom-clipboard">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.545 0.75C8.6139 0.757812 8.68178 0.772896 8.7475 0.795H8.815C8.89212 0.83106 8.963 0.879162 9.025 0.9375V0.9375L13.525 5.4375C13.5833 5.4995 13.6314 5.57039 13.6675 5.6475V5.715C13.7112 5.79119 13.7392 5.87533 13.75 5.9625L13.75 12C13.75 12.5967 13.5129 13.169 13.091 13.591C12.669 14.0129 12.0967 14.25 11.5 14.25H10.75V15C10.75 15.5967 10.5129 16.169 10.091 16.591C9.66903 17.0129 9.09674 17.25 8.5 17.25H2.5C1.90326 17.25 1.33097 17.0129 0.909009 16.591C0.487053 16.169 0.25 15.5967 0.25 15L0.25 6C0.25 5.40326 0.487053 4.83097 0.909009 4.40901C1.33097 3.98705 1.90326 3.75 2.5 3.75H3.25V3C3.25 2.40326 3.48705 1.83097 3.90901 1.40901C4.33097 0.987053 4.90326 0.75 5.5 0.75H8.5C8.5 0.75 8.5 0.75 8.545 0.75ZM11.1925 5.25L9.25 3.3075V4.5C9.25 4.69891 9.32902 4.88968 9.46967 5.03033C9.61032 5.17098 9.80109 5.25 10 5.25H11.1925ZM2.5 5.25C2.30109 5.25 2.11032 5.32902 1.96967 5.46967C1.82902 5.61032 1.75 5.80109 1.75 6L1.75 15C1.75 15.1989 1.82902 15.3897 1.96967 15.5303C2.11032 15.671 2.30109 15.75 2.5 15.75L8.5 15.75C8.69891 15.75 8.88968 15.671 9.03033 15.5303C9.17098 15.3897 9.25 15.1989 9.25 15V14.25H5.5C4.90326 14.25 4.33097 14.0129 3.90901 13.591C3.48705 13.169 3.25 12.5967 3.25 12L3.25 5.25H2.5ZM5.5 2.25C5.30109 2.25 5.11032 2.32902 4.96967 2.46967C4.82902 2.61032 4.75 2.80109 4.75 3L4.75 12C4.75 12.1989 4.82902 12.3897 4.96967 12.5303C5.11032 12.671 5.30109 12.75 5.5 12.75H11.5C11.6989 12.75 11.8897 12.671 12.0303 12.5303C12.171 12.3897 12.25 12.1989 12.25 12L12.25 6.75H10C9.40326 6.75 8.83097 6.51295 8.40901 6.09099C7.98705 5.66903 7.75 5.09674 7.75 4.5V2.25L5.5 2.25Z"
                      fill="#2A2E32"/>
                  </svg>
                </button>
              </div>
              <div class="item_butt_act_g">
                <button
                  v-if="model.member"
                  @click="handlerDelete(model)"
                  type="button"
                  class="d_flex align_center justify_center btn_icon_rounded remove_butt_g">
                  <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
                      fill="white"/>
                  </svg>
                </button>
                <span v-else class="empty"></span>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </loading>
      <nav v-if="pageCount > 1">
        <paginate ref="paginate"
                  v-model="page"
                  :page-count="pageCount"
                  :click-handler="handlerPagination"
                  :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                  :next-text="'<i class=\'icon-arrow-right\'></i>'"
                  :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                  :page-class="'page_item_gd'"
                  :page-link-class="'page_link_g d_flex justify_center align_center'"
                  :prev-class="'page-item_go'"
                  :next-class="'page-item_go'"
                  :prev-link-class="'page-link_go d_flex justify_center align_center'"
                  :next-link-class="'page-link_go d_flex justify_center align_center'"
                  :active-class="'active'">
        </paginate>
      </nav>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import Loading from '@/components/Loading.vue'
import {mapActions, mapGetters} from 'vuex'
import {TableSortHead} from '@/components'
import tooltipMixin from '@/mixins/tooltip-mixin'
import titleMixin from '@/mixins/title-mixin'
import ConfirmModal from '@/components/modal/Confirm.vue'

export default {
  name: 'BotKeys',
  title () {
    return this.$t('titles.bot.keys')
  },
  mixins: [titleMixin, tooltipMixin],
  components: {Loading, TableSortHead, ConfirmModal},
  data () {
    return {
      loading: true,
      heads: [
        {label: 'bot.heads.key.label', title: 'bot.heads.key.title'},
        {label: 'bot.heads.member.label', title: 'bot.heads.member.title', sort: 'member'},
        {label: 'bot.heads.date.label', title: 'bot.heads.date.title', sort: 'updatedAt'},
        {label: '', class: 'text-right last_tbs_gg pr-0'}
      ],
      filter: {
        member: ''
      },
      page: 1,
      limit: 20,
      sort: '-updatedAt',
      clipboard: undefined
    }
  },
  computed: {
    ...mapGetters({
      models: 'bot/models',
      count: 'bot/count'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  created () {
    this.handlerPagination()
    this.$watch('sort', () => this.fetchPage())
  },
  beforeDestroy () {
    if (this.clipboard != null) {
      this.clipboard.destroy()
    }
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      alertError: 'alerts/error',
      fetchData: 'bot/keysFetchData',
      delete: 'bot/deleteKey'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
      if (this.clipboard == null) {
        // eslint-disable-next-line no-undef
        this.clipboard = new Clipboard('.btn-custom-clipboard')
        this.clipboard.on('success', e => this.alertSuccess(this.$t('bot.alert.copy', {key: e.text})))
      }
      this.initTooltip()
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        page: this.page,
        limit: this.limit,
        sort: this.sort,
        filter: this.filter
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    async handlerDelete (model) {
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('bot.confirm.delete.title'),
        message: this.$t('bot.confirm.delete.message', {key: model.id, username: model.username}),
        okButton: this.$t('bot.confirm.delete.button')
      })
      if (ok) {
        try {
          await this.delete(model.id)
          await this.alertSuccess(this.$t('bot.alert.delete', {key: model.id, username: model.username}))
          this.fetchPage()
        } catch (e) {
          await this.alertError(e.message)
        }
      }
    }
  }
}
</script>

<style scoped>
.empty {
  width: 28px;
  height: 28px;
  margin-left: 7px;
  display: block;
}
</style>
