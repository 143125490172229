<template>
  <div class="row">
    <div class="col-12 grid-margin">
      <div class="card card-statistics">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div class="d-flex justify-content-between border-right card-statistics-item">
                <div>
                  <h1>{{ txcount }}</h1>
                  <p class="text-muted mb-0">Кл. транз. {{ walletname }}</p>
                </div>
                <i class="icon-layers text-primary icon-lg"></i>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="d-flex justify-content-between border-right card-statistics-item">
                <div>
                  <h1>{{ balance }}</h1>
                  <p class="text-muted mb-0">Баланс {{ walletname }}</p>
                </div>
                <i class="fa fa-btc text-primary icon-lg"></i>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="d-flex justify-content-between border-right card-statistics-item">
                <div>
                  <h1>{{ immature_balance }}</h1>
                  <p class="text-muted mb-0">Не полный баланс {{ walletname }}</p>
                </div>
                <i class="fa fa-btc text-primary icon-lg"></i>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="d-flex justify-content-between card-statistics-item">
                <div>
                  <h1>{{ verificationprogress }}</h1>
                  <p class="text-muted mb-0">Верификация</p>
                </div>
                <i class="icon-like text-primary icon-lg"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BTCWalletInfo',
  data: () => {
    return {
      walletname: 'pwa_hot',
      balance: 0.00000000,
      unconfirmed_balance: 0.00000000,
      immature_balance: 0.00000000,
      txcount: 0,
      paytxfee: 0.00000000,
      verificationprogress: 0.9999993158159729
    }
  },
  async created () {
    await this.$store.dispatch('adminBilling/btc')
    for (const [key, value] of Object.entries(this.$store.getters['adminBilling/btc'])) {
      this[key] = value
    }
  }
}
</script>
