<template>
  <div class="PageMirrors page-mirrors page-full-fluid-width parent_tooltip">
    <div class="page-mirrors__wrapper">
      <h1 class="page-mirrors__title">
        {{ $t('mirrors.trash.title') }}
      </h1>
      <div class="page-mirrors__filters">
        <Filters :model="filter" @change="onFilterChange"/>
      </div>
      <hr class="line-hr page-mirrors__line">
      <div class="page-mirrors__table">
        <Loading v-model="mirrorsTrashListLoading">
          <MirrorsTable
            v-if="!mirrorsTrashListEmpty"
            :trash="true"
          >
            <MirrorsTableItem
              :trash="true"
              v-for="item in mirrorsTrashList"
              :key="item.id"
              :data="item"
              :user="user"
              :accesses="accesses"
              @tag-click="onTagClick"
              @delete="onMirrorDelete"
              @refresh="onMirrorRefresh"
            />
          </MirrorsTable>
          <EmptyPage v-if="mirrorsTrashListEmpty">
            <template #title v-if="isEmptyFilter">{{ $t('mirrors.trash.empty') }}</template>
            <template #title v-else>{{ $t('mirrors.notFoundByFilters') }}</template>
            <template #link>
              <div class="butt_nos_g" v-if="isEmptyFilter">
                <router-link class="btn_green_g" tag="button" :to="{name: 'Mirrors'}">{{
                    $t('mirrors.trash.goToMirrors')
                  }}
                </router-link>
              </div>
            </template>
          </EmptyPage>
        </Loading>
      </div>
    </div>
    <div class="page-mirrors__pagination">
      <NavPaginate
        v-model="page"
        :inputPage="+inputPage"
        :pageCount="pageCount"
        :limit="+limit"
        @limit="limit=$event"
        @changePage="debounceChangePage"
        @pagination="handlerPagination"
        @inputPage="inputPage=$event"
        :count="mirrorsTrashListCount"
      >
      </NavPaginate>
    </div>
    <ConfirmModal ref="confirmModal" id="pwa-confirm-modal" />
  </div>
</template>
<script>
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import NavPaginate from '@/components/NavPaginate.vue'
import paginationMixin from '@/mixins/pagination-mixin'
import ConfirmModal from '@/components/modal/Confirm.vue'
import Loading from '@/components/Loading.vue'
import Filters from './components/Filters.vue'
import MirrorsTable from './components/MirrorsTable/MirrorsTable.vue'
import MirrorsTableItem from './components/MirrorsTable/MirrorsTableItem.vue'
import EmptyPage from './components/EmptyPage.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PageMirrorsTrash',
  title () {
    return this.$t('titles.mirrors.trash')
  },
  mixins: [titleMixin, paginationMixin, accessesMixin],
  components: {
    Filters,
    MirrorsTable,
    BaseFromGroup,
    NavPaginate,
    EmptyPage,
    MirrorsTableItem,
    ConfirmModal,
    Loading
  },
  data () {
    return {
      filter: {
        domain: '',
        country: '',
        status: '',
        tags: [],
        preLandingPage: '',
        postLandingPage: '',
        pwa: '',
        name: ''
      }
    }
  },
  computed: {
    ...mapGetters('mirrorsStore', [
      'mirrorsTrashList',
      'mirrorsTrashListCount',
      'mirrorsTrashListEmpty',
      'mirrorsTrashListLoading',
      'mirrorsTrashListLoaded'
    ]),
    count () {
      return this.mirrorsListCount
    },
    isEmptyFilter () {
      return this.filter.country === '' &&
        this.filter.status === '' &&
        this.filter.domain === '' &&
        !this.filter.tags.length > 0 &&
        this.filter.preLandingPage === '' &&
        this.filter.postLandingPage === '' &&
        this.filter.name === '' &&
        this.filter.pwa === ''
    }
  },
  methods: {
    ...mapActions('mirrorsStore', [
      'getTrashMirrorsList',
      'deleteMirror',
      'refreshMirror'
    ]),
    fetchPage () {
      this.getTrashMirrorsList({
        page: this.page,
        limit: this.limit,
        sort: '-createdAt',
        filter: this.filter,
        trash: true
      })
    },
    onTagClick (tag) {
      this.filter.tags.push(tag)
    },
    async onMirrorDelete (data) {
      const name = data.name || ''
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('mirrors.delete.title'),
        message: this.$t('mirrors.delete.message', {name}),
        okButton: this.$t('mirrors.delete.button')
      })
      if (ok) {
        await this.deleteMirror({id: data.id})
        this.fetchPage()
        await this.$store.dispatch('alerts/success', this.$t('mirrors.delete.success'))
      }
    },
    async onMirrorRefresh (data) {
      await this.refreshMirror({id: data.id})
      await this.$store.dispatch('alerts/success', this.$t('mirrors.trash.restoreSuccess'))
    },
    onFilterChange () {
      this.page = 1
      this.getTrashMirrorsList({
        page: this.page,
        limit: this.limit,
        sort: '-createdAt',
        filter: this.filter,
        trash: true
      })
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.page-mirrors
  padding-left: 30px
  padding-right: 20px
  padding-top: 10px
  padding-bottom: 30px
  @media screen and (max-width: 767px)
    padding-left: 20px
    padding-right: 15px
  &__title
    margin-bottom: 24px
    font-size: 20px
    +font(400)
  &__filters
    display: flex
    flex-direction: row
    justify-content: space-between
    @media screen and (max-width: 767px)
      flex-direction: column
    :deep(.mirrors-filter__filed)
      max-width: 100%
  &__table
    height: 100%
    max-height: calc(100vh - 300px)
    overflow-y: scroll
    @media screen and (max-width: 991px)
      max-height: 100%
      height: auto
</style>
