import AdminPWAs from './Index.vue'
import AdminPWA from './_id/Index.vue'
import AdminPWATracker from './_id/Tracker.vue'
import AdminPWATemplates from './templates/AdminPWATemplates.vue'
import * as texts from './texts'
import * as comments from './comments'

export default (meta, header) => {
  return [
    {
      path: 'pwas',
      name: 'AdminPWAs',
      components: {default: AdminPWAs, header},
      meta
    },
    {
      path: 'pwas/templates',
      name: 'AdminPWATemplates',
      components: {default: AdminPWATemplates, header},
      meta
    },
    {
      path: 'pwas/:id',
      name: 'AdminPWA',
      components: {default: AdminPWA, header},
      meta
    },
    {
      path: 'pwas/:id/tracker',
      name: 'AdminPWATracker',
      components: {default: AdminPWATracker, header},
      meta
    },
    ...texts.router(meta, header),
    ...comments.router(meta, header)
  ]
}
