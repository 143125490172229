import service from '@/store/services/faq-service'
import middleware from './middleware'

const state = {
  faqs: [],
  categories: [],
  articles: []
}

const mutations = {
  setFAQs: (state, payload) => {
    state.faqs = []
    state.categories = []
    state.articles = []
    state.faqs = payload
    state.categories.push({
      _id: null,
      name: 'Все',
      active: true
    })
    for (const faq of state.faqs) {
      state.categories.push({
        _id: faq._id,
        name: faq.name,
        active: false
      })
      for (const item of faq.items) {
        const decode = document.createElement('textarea')
        decode.innerHTML = item.answer
        state.articles.push({
          _id: item._id,
          category_id: faq._id,
          title: item.question,
          body: decode.innerText
        })
      }
    }
  }
}

const actions = {

  index: ({commit, dispatch}, params) => service.index(params)
    .then(res => commit('setFAQs', res))
    .catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  faqs: state => state.faqs,
  categories: state => state.categories,
  articles: state => state.articles
}

const faq = {

  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default faq
