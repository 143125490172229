export const ASPECT_RATIOS = {
  '1:1': 1 / 1,
  '1:2': 1 / 2,
  '3:2': 3 / 2,
  '9:16': 9 / 16,
  '16:9': 16 / 9,
  FREE: 'Free'
}

export const CHANGE_ORDER_TYPE = {
  INCREASE: 'increase',
  DECREASE: 'decrease'
}

export const ACCESS_ROUTES = {
  finance: '/bills',
  landings: '/landing-pages',
  pushes: '/pwas/pushes/',
  pwas: '/pwas',
  statistics: '/analytics/groups',
  teams: '/teams'
}
