export default {
  analyticsGroups: 'Agrupamentos',
  analyticsUniques: 'Únicos',
  columns: 'Colunas',
  filters: 'Filtros',
  filtersSettingTitle: 'Configuração de filtros',
  columnsSettingTitle: 'Configuração de colunas',
  date: 'Data',
  clicks: {
    title: 'Cliques',
    installs: 'Dispositivo',
    pushes: 'Push subscrições',
    opens: 'Entradas a PWA',
    reopens: 'Reentradas'
  },
  postbacks: {
    title: 'Postbacks',
    registrations: 'Registrar-se',
    deposits: 'Depósitos'
  },
  users: {
    title: 'Utilizador',
    ip: 'IP',
    country: 'Países',
    userAgentDeviceType: 'Tipo do dispositivo',
    userAgentDeviceVendor: 'Marca do dispositivo',
    userAgentDeviceModel: 'Modelo do dispositivo',
    userAgentOs: 'OS',
    userAgentBrowserName: 'Navegador'
  },
  trackers: {
    title: 'Tracker',
    tracker: 'Tracker',
    referer: 'Afiliado',
    redirect: 'Redireccionamento',
    tag: 'Tag',
    host: 'Domínio',
    vertical: 'Âmbito'
  },
  params: {
    title: 'Parâmetros',
    param: 'Parâmetro'
  },
  filtersLabel: {
    installs: 'Filtro por definições',
    pushes: 'Filtro por subscrições',
    opens: 'Filtro por entradas',
    reopens: 'Filtro por reentradas',
    registrations: 'Filtro por registos',
    deposits: 'Filtro por depósito',
    ip: 'Filtro por IP',
    id: 'Filtro por ID',
    country: 'Filtro por país',
    userAgentDeviceType: 'Filtro por tipo do dispositivo',
    userAgentDeviceVendor: 'Filtro por marca do dispositivo',
    userAgentDeviceModel: 'Filtro por modelo do dispositivo',
    userAgentOs: ' Filtro por OS',
    userAgentBrowserName: 'Filtro por navegador',
    tracker: 'Filtro por tracker',
    referer: 'Filtro por afiliados',
    redirect: 'Filtro por redireccionamentos',
    pwa: 'Filtro por PWA',
    tag: 'Filtro por tag',
    host: 'Filtro por domínio',
    vertical: 'Filtro por âmbito',
    verticals: ['Nutra', 'Betting', 'Dayting', 'Gambling', 'Trading', 'Criptomoedas'],
    params: 'Filtro por {param}',
    date: 'Filtro por data',
    today: 'Hoje',
    yesterday: 'Ontem',
    prevSevenDay: 'Semana anterior',
    prevMonth: 'Mês anterior',
    prev3Month: '3 meses anteriores',
    thisYear: 'Este ano',
    preLandingPage: 'Pre-landing',
    postLandingPage: 'Post-landing',
    preLandingPageUnique: 'Únic pre-landing',
    postLandingPageUnique: 'Únic post-landing'
  },
  columnsLabels: {
    cpi: 'CPI',
    analyticsBy: 'Análise por',
    uniqueLabel: 'Dados de utilizador',
    actionsLabel: 'Movimentos',
    postbackLabel: 'Informação de Postback',
    pwaLabel: 'Tracking',
    calculateLabel: 'Cálculos',
    uniques: 'Únicos',
    hits: 'Cliques',
    installs: 'Definições',
    pushes: 'PUSH subscrições',
    registrations: 'Registos',
    deposits: 'Depósitos',
    opens: 'Aberturas',
    reopens: 'Reiniciação',
    country: 'Países',
    createdAt: 'Criado',
    uniquesToInstalls: 'CR% único/instalações',
    uniquesToPush: 'CR% único/push',
    installsToPush: 'CR% instalações/push',
    date: 'Data',
    tracker: 'Tracker',
    host: 'Domínio',
    referer: 'Link de referência',
    vertical: 'Âmbito',
    redirect: 'Redireccionamento',
    userAgentDeviceType: 'Tipo do dispositivo',
    userAgentDeviceVendor: 'Marca do dispositivo',
    userAgentDeviceModel: 'Modelo do dispositivo',
    userAgentBrowserName: 'Navegador',
    userAgentOs: 'OS',
    uniquesTitle: 'Acessos únicos',
    hitsTitle: 'Acessos não únicos',
    installsTitle: 'Número de instalações de PWA',
    pushesTitle: 'Número de subscrições para Push',
    registrationsTitle: 'Número de registos',
    depositsTitle: 'Número de depósitos',
    opensTitle: 'Número de entradas a PWA',
    reopensTitle: 'Número de reentradas a PWA',
    createdAtTitle: 'Data do primeiro clique',
    uniquesToInstallsTitle: 'Relação entre único/instalações. Calcula-se por fórmula CR = Instalações/Único*100',
    uniquesToPushTitle: 'Relação entre único/push. Calcula-se por fórmula CR = Push/Único*100/Уник*100',
    installsToPushTitle: 'Relação entre instalações/push. Calcula-se por fórmula CR = Push/Instalações*100',
    landingsLabel: 'Landings',
    preLandingPage: 'Pre-landing',
    postLandingPage: 'Post-landing',
    preLandingPageUnique: 'Únic pre-landing',
    postLandingPageUnique: 'Únic post-landing'
  },
  groupsLabels: {
    date: 'Agrupado por Data',
    tag: 'Agrupado por Tag',
    host: 'Agrupado por Domínio',
    country: 'Agrupado por País',
    pwa: 'Agrupado por PWA',
    vertical: 'Agrupado por Âmbito',
    userAgentDeviceType: 'Agrupado por Dispositivo',
    userAgentDeviceVendor: 'Agrupado por Marca',
    userAgentDeviceModel: 'Agrupado por Modelo',
    userAgentBrowserName: 'Agrupado por Navegador',
    userAgentOs: 'Agrupado por OS',
    param: 'Agrupado por "{param}"',
    by: 'Por',
    dateTitle: 'Análise por Data',
    trackerTitle: 'Análise por Tracker',
    hostTitle: 'Análise por Domínio',
    tagTitle: 'Análise por Tag',
    countryTitle: 'Análise por País',
    pwaTitle: 'Análise por PWA',
    verticalTitle: 'Análise por Âmbito',
    userAgentDeviceTypeTitle: 'Análise por Dispositivo',
    userAgentDeviceVendorTitle: 'Análise por Marca',
    userAgentDeviceModelTitle: 'Análise por Modelo',
    userAgentBrowserNameTitle: 'Análise por Navegador',
    userAgentOsTitle: 'Análise por OS',
    preLandingPageTitle: 'Análise por pre-landing',
    postLandingPageTitle: 'Análise por post-landing',
    dateLabel: 'Data',
    trackerLabel: 'Tracker',
    hostLabel: 'Domínio',
    countryLabel: 'País',
    pwaLabel: 'PWA',
    verticalLabel: 'Âmbito',
    userAgentDeviceTypeLabel: 'Dispositivo',
    userAgentDeviceVendorLabel: 'Marca',
    userAgentDeviceModelLabel: 'Modelo',
    userAgentBrowserNameLabel: 'Navegador',
    userAgentOsLabel: 'OS',
    landingsLabel: 'Landings',
    preLandingPage: 'Agrupado por Pre-landing',
    postLandingPage: 'Agrupado por Post-landing'
  },
  report: {
    button: 'Relatório',
    title: 'Geração de relatórios',
    body: {
      open: 'À análise existente, adicionaremos os custos de compra de domínios no nosso sistema e o lucro do Postback. O relatório estará disponível aqui em formato CSV com codificação UTF-8',
      successful: `O seu relatório está disponível em <a download="report.csv" class="link_green" href="{csv}" target="_blank">a ligação</a>`
    },
    hint: {
      open: 'Para começar a gerar o relatório, clique no botão "Executar"',
      loading: 'O relatório está a ser gerado...',
      successful: 'A produção é garantida',
      error: 'Ocorreu um erro ao criar um relatório'
    }
  },
  landings: {
    title: 'Landings',
    preLandingPage: 'Pre-landing',
    postLandingPage: 'Post-landing',
    preLandingPageUnique: 'Únic pre-landing',
    postLandingPageUnique: 'Únic post-landing'
  },
  empty: 'As estatísticas não estão disponíveis ou não são compiladas atualmente',
  pwaRemoved: 'PWA removido'
}
