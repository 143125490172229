export default {
  subscribe: 'Subscrição do plano "{name}"',
  contains: 'Plano "{name}" inclui:',
  pwas: '0 ativações | Uma ativação | {num} ativação | {num} ativações',
  limit: '{num} PWA ativos',
  month: 'Subscrição mensal',
  onDays: 'Subscrição para {num} dias',
  forStart: 'Para Iniciar',
  pay: 'Pagar',
  messages: [
    'Como uma ativação se entende a transferência de um PWA válido do estatuto <span class="color_red_go">"Não pago"</span> ao <span class="color_green_go">"Ativado"</span>',
    'Como o número de PWA ativos se entende o número de PWA com estatuto "Ativado"'
  ],
  promoCode: 'Código de promoção',
  amount: 'Montante a pagar',
  success: 'Subscreveu um plano novo',
  voucherRequired: 'Introduza o código de promoção',
  beforeDate: 'Informação sobre о plano até {date}',
  contained: 'Plano "{name}" incluía:',
  paid: 'Pagou:',
  info: 'Informação',
  your: 'Seu plano',
  active: '{used} de {pwa} PWA foram ativados',
  activations: 'Ativações disponíveis: {num}',
  expired: 'Um dia até subscrição expirar | {num} dias até subscrição expirar | {num} dias até subscrição expirar',
  upCost: 'Ativar por {num} $',
  auto: 'Subscrição automática está ativada',
  noTariffs: 'Este Plano encontra-se em desenvolvimento',
  individual: 'Individual',
  command: 'Comando',
  commercial: 'Comercial',
  history: 'História',
  historyTitle: 'História de planos',
  additionalServices: 'Serviços complementares',
  installations: {
    tier1: 'Região 1',
    tier2: 'Região 2',
    tier3: 'Região 3',
    title: 'Pagamento por instalação',
    header: 'Pagamento por uma instalação de PWA',
    text: 'Caso tiver dúvidas ou perguntas sobre um plano, o favor de contactar o <a href="https://t.me/{tgHelpdesk}" target="_blank">Serviço de apoio</a> ao cliente'
  }
}
