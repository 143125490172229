import * as pages from '@/pages'
import auth from '@/middleware/auth'
import guest from '@/middleware/guest'
import admin from '@/middleware/admin'
const DashboardHeader = () => import('@/pages/layouts/DashboardHeader.vue')
const DashboardAdminHeader = () => import('@/pages/layouts/DashboardAdminHeader.vue')

const routes = [
  {
    path: '/r/:hash',
    redirect: to => {
      localStorage.setItem('referral', to.params.hash)
      return {name: 'Auth'}
    }
  },
  {
    path: '/auth',
    component: pages.layouts.AuthLayout,
    name: 'Authentication',
    children: [
      {
        path: '/auth',
        name: 'Auth',
        component: pages.auth.Auth,
        meta: {middleware: guest}
      },
      {
        path: '/auth/team/:member',
        name: 'AuthTeam',
        component: pages.auth.AuthTeam,
        meta: {middleware: guest}
      }
    ]
  },
  {
    path: '/',
    component: pages.layouts.DashboardLayout,
    redirect: '/',
    name: 'DashboardLayout',
    children: [
      {
        path: '/',
        name: 'Dashboard',
        components: {default: pages.dashboard.Dashboard, header: DashboardHeader},
        meta: {middleware: auth}
      },
      ...pages.bot.router({middleware: auth}, DashboardHeader),
      ...pages.landingPages.router({middleware: auth}, DashboardHeader),
      ...pages.vacancies.router({middleware: auth}, DashboardHeader),
      ...pages.pwas.routes({middleware: auth}, DashboardHeader),
      ...pages.statistics.statisticsRoutes({middleware: auth}, DashboardHeader),
      ...pages.finance.financeRoutes({middleware: auth}, DashboardHeader),
      ...pages.tariffs.tariffsRoutes({middleware: auth}, DashboardHeader),
      ...pages.referrals.referralsRoutes({middleware: auth}, DashboardHeader),
      ...pages.teams.teamsRoutes({middleware: auth}, DashboardHeader),
      ...pages.analytics.routes({middleware: auth}, DashboardHeader),
      ...pages.mirrors.mirrorsRoutes({middleware: auth}, DashboardHeader),
      {
        path: '/faq',
        name: 'FAQ',
        components: {default: pages.faq.FAQ, header: DashboardHeader},
        meta: {middleware: auth}
      },
      {
        path: '/vacancies',
        name: 'Vacancies',
        components: {default: pages.vacancies.Vacancies, header: DashboardHeader},
        meta: {middleware: auth}
      }
    ]
  },
  ...pages.admin.router({middleware: [admin, auth]}, DashboardAdminHeader),
  {path: '*', component: pages.NotFound}
]

export default routes
