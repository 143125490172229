import service from '@/store/services/verticals-service'
import middleware from './middleware'

const state = () => ({
  vertical: [],
  verticals: [
    {id: 1, label: 'pwas.verticals[0]'},
    {id: 2, label: 'pwas.verticals[1]'},
    {id: 3, label: 'pwas.verticals[2]'},
    {id: 4, label: 'pwas.verticals[3]'},
    {id: 5, label: 'pwas.verticals[4]'}
    // {id: 6, label: 'pwas.verticals[5]'}
  ],
  templates: [
    { id: 'index.ejs', label: 'pwas.components.vertical.template.templates[0]' },
    // { id: 'index2022.ejs', label: 'pwas.components.vertical.template.templates[1]' },
    { id: 'playmarket.ejs', label: 'pwas.components.vertical.template.templates[2]' },
    { id: 'oldbeta.ejs', label: 'pwas.components.vertical.template.templates[3]' }
  ]
})

const mutations = {
  setVertical: (state, vertical) => {
    state.vertical = vertical
  }
}

const actions = {
  read: ({commit, dispatch}, params) => service.read(params)
    .then(tracker => {
      commit('setVertical', tracker)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  vertical: state => state.vertical,
  verticals: state => state.verticals,
  templates: state => state.templates
}

const verticals = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default verticals
