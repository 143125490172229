<template>
  <div class="content-wrapper parent_tooltip">
    <div class="row">
      <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
        <div class="card">
          <div class="card-body pt-0">
            <div class="top-tabs mb-3">
              <ul class="nav tabs_tp_g d-flex align-items-center">
                <li class="nav-item" v-for="item of items" :key="item.label">
                  <router-link :to="item.to" :class="'item_link_tab_g' + (item.active ? ' active' : '')">{{
                      item.label
                    }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="tab-content">
              <router-view/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Layout',
  data: () => ({
    items: [
      {to: {name: 'StatisticDates'}, active: false, label: 'Дням'},
      {to: {name: 'StatisticUniques'}, active: false, label: 'Уникам'},
      {to: {name: 'StatisticCountries'}, active: false, label: 'ГЕО'}
    ]
  }),
  watch: {
    '$route.name': function () {
      this.init()
    }
  },
  computed: {
    user () { return this.$store.getters['identity'] }
  },
  methods: {
    init: function () {
      for (const item of this.items) {
        item.active = item.to.name === this.$route.name
      }
    }
  },
  created: function () {
    if (this.user.id === 1516381314) {
      this.items.push({to: {name: 'StatisticPepperA'}, active: false, label: 'a'})
    }
    if (this.user.id === 731778160) {
      this.items.push({to: {name: 'StatisticWelcomepWp'}, active: false, label: 'wp'})
    }
    this.init()
  }
}
</script>
