import service from '@/store/services/admin/postbacks-service'
import middleware from '../middleware'

const state = () => ({
  count: 0,
  models: [],
  filter: {
    name: '',
    enable: ''
  },
  logFilter: {}
})

const mutations = {
  setData (state, payload) {
    const { models, count } = payload
    state.models = models
    state.count = count
  },
  setFilter (state, payload) {
    const { name, enable } = payload
    state.filter.name = name
    state.filter.enable = enable
  },
  setLogFilter (state, { owner, host, pwa, trackerTemplate, domain, status, type, dateBegin, dateEnd }) {
    state.logFilter.owner = owner || undefined
    state.logFilter.host = host || undefined
    state.logFilter.pwa = pwa || undefined
    state.logFilter.trackerTemplate = trackerTemplate || undefined
    state.logFilter.domain = domain || undefined
    state.logFilter.status = status || undefined
    state.logFilter.type = type || undefined
    state.logFilter.dateBegin = dateBegin || undefined
    state.logFilter.dateEnd = dateEnd || undefined
    state.logFilter = JSON.parse(JSON.stringify(state.logFilter))
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  asyncDataLogs ({commit, dispatch}, params) {
    return service.getPageLogs(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create ({commit, dispatch}, params) {
    return service.create(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  delete ({commit}, params) {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  setFilter ({commit}, payload) {
    commit('setFilter', payload)
  },
  setLogFilter ({commit}, payload) {
    commit('setLogFilter', payload)
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  filter: state => state.filter,
  logFilter: state => state.logFilter
}

const adminDomains = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminDomains
