import AdminFAQ from './AdminFAQ.vue'
import AdminFAQItems from './AdminFAQItems.vue'

export default (meta, header) => {
  return [
    {
      path: 'faq',
      name: 'AdminFAQ',
      components: {default: AdminFAQ, header},
      meta
    },
    {
      path: 'faq/:id',
      name: 'AdminFAQItems',
      components: {default: AdminFAQItems, header},
      meta
    }
  ]
}
