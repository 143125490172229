<template>
  <modal class="ModalDownloadJs" :id="'custom-js-modal-' + _uid" ref="modal">
    <div class="info_blk_g_mod text-center">
      <div class="icon_sinf_g">
        <img src="/images/icon_info.png" alt="">
      </div>
      <div class="title_inf_glbk" style="color: #21BF73;">{{ title }}</div>
      <div class="descr_inf_ico_g" style="white-space: normal;">
        {{ message }}
      </div>
      <div class="form-group mt-2 mb-0">
        <textarea
          readonly="readonly"
          :id="inputId"
          rows="10"
          class="form-control p-2"
          v-model="script"
        />
      </div>
    </div>
    <div class="foot_inf_sg_g">
      <div class="in_to_foot_br_g">
        <div class="row has_big_rog">
          <div class="col-6 item_pwa_shs_g">
            <button
              type="button"
              data-clipboard-action="copy" :data-clipboard-target="'#' + inputId"
              class="btn-clipboard border_butt_green d_flex align_center justify_center w-100"
            >
              {{ $t('general.copy') }}
            </button>
          </div>
          <div class="col-6 item_pwa_shs_g">
            <a @click="handleCancel" class="ful_butt_green d_flex align_center justify_center">
              {{ $t('general.cancel') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Info from '@/components/Info.vue'
import Modal from '@/components/modal/Modal.vue'

export default {
  name: 'ModalDownloadJs',
  components: {Modal, Info},
  data () {
    return {
      id: '',
      domain: '',
      title: undefined,
      message: undefined,
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    inputId () {
      return `cloak-modal-input-${this._uid}`
    },
    script: function () {
      const url = new URL(`https://${this.domain}/a/c/${this.id}.js`)
      // eslint-disable-next-line no-useless-escape
      return `<\script src="${url.toString()}"><\/script>`
    }
  },
  methods: {
    async show ({id, domain, title, message}) {
      this.id = id
      this.domain = domain
      this.title = title
      this.message = message
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handleCancel () {
      this.$refs.modal.close()
      this.resolvePromise(false)
    }
  }
}
</script>
