<template>
  <div class="image-cropper">
    <div class="overlay" @click="close"></div>
    <div class="image-cropper__container">
      <vue-cropper
        ref="cropper"
        :auto-crop-area="1"
        :aspect-ratio="aspectRatio"
      />
      <div class="image-cropper__actions">
        <div class="ratio-actions">
          <ButtonsGroup :actions="actions" :active-button="activeButton"/>
          <ButtonsGroup :actions="actionsZoom"/>
        </div>
        <div>
          <button class="ful_butt_green" @click="cropImage">{{ $t('pwas.components.vertical.loading.button') }}</button>
          <button class="simple_butt_afg ml-5" @click="close">{{ $t('general.cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import ButtonsGroup from './ButtonsGroup.vue'
import {ASPECT_RATIOS} from '@/const/index'

export default {
  name: 'ImageCropper',
  props: {
    image: {
      type: File,
      default: null
    }
  },
  components: {
    VueCropper,
    ButtonsGroup
  },
  data () {
    return {
      aspectRatio: NaN,
      actionsZoom: [
        {
          name: '+',
          action: () => this.setZoom(0.1)
        },
        {
          name: '-',
          action: () => this.setZoom(-0.1)
        }
      ]
    }
  },
  computed: {
    imageType () {
      return this.image.type
    },
    actions () {
      const actions = []
      for (let key in ASPECT_RATIOS) {
        actions.push({
          name: key,
          action: () => this.setAspectRatio(ASPECT_RATIOS[key])
        })
      }

      return actions
    },
    activeButton () {
      return isNaN(this.aspectRatio) ? 'FREE' : Object.keys(ASPECT_RATIOS).find(key => ASPECT_RATIOS[key] === this.aspectRatio)
    }
  },
  mounted () {
    this.setImage(this.image)
  },
  beforeDestroy () {
    this.$refs.cropper.$destroy()
  },
  methods: {
    cropImage () {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.$emit('imageCropped', blob)
        this.close()
      }, this.imageType)
    },
    setImage (file) {
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.$refs.cropper.replace(event.target.result)
        }

        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    setAspectRatio (ratio) {
      this.aspectRatio = +ratio
      this.$refs.cropper.setAspectRatio(+ratio)
    },
    setZoom (scale) {
      this.$refs.cropper.relativeZoom(scale)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.image-cropper {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &__container {
    max-width: 600px;
    max-height: 100vh;
    z-index: 1;

    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: rgba(33, 37, 41, 0.9);
      z-index: -1;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    padding: 30px 0;

    .ratio-actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
  }

  ::v-deep .border_butt_green {
    padding: 0 15px;
  }
}
</style>
