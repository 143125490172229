<template>
  <modal
    id="admin-pwas-settings-form-modal"
    ref="modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Налаштування PWAs</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div v-if="!loading" class="modal-body">
      <div class="form-group" :class="apiValidationErrors.activationCost ? 'has-danger' : ''">
        <label for="activationCost">Цена за доп. активацию</label>
        <div class="input-group-append">
          <input type="number" step="0.01" id="activationCost" placeholder="Введите цену" v-model="activationCost"
                 class="form-control" min="1" max="100"
                 :class="apiValidationErrors.activationCost ? 'form-control-danger' : ''"
                 @focus="clearError('activationCost')">
          <div class="input-group-append">
            <span class="input-group-text">$</span>
          </div>
        </div>
        <small class="form-text text-muted">Цена за доп. активацию 1 PWA</small>
        <label v-if="apiValidationErrors.activationCost" class="error mt-2 text-danger" for="activationCost">
          {{ apiValidationErrors.activationCost[0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.domainCheckerCount ? 'has-danger' : ''">
        <label for="domainCheckerCount">Количество повторных проверок</label>
        <div class="input-group-append">
          <input type="number" step="0" id="domainCheckerCount" placeholder="Введите кл. проверок" v-model="domainCheckerCount"
                 class="form-control" min="1" max="100"
                 :class="apiValidationErrors.domainCheckerCount ? 'form-control-danger' : ''"
                 @focus="clearError('domainCheckerCount')">
          <div class="input-group-append">
            <span class="input-group-text">повторов</span>
          </div>
        </div>
        <small class="form-text text-muted">Попыток проверить домен</small>
        <label v-if="apiValidationErrors.domainCheckerCount" class="error mt-2 text-danger"
               for="domainCheckerCount">
          {{ apiValidationErrors.domainCheckerCount[0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.domainCheckerLimit ? 'has-danger' : ''">
        <label for="domainCheckerLimit">Лимит доменов за раз</label>
        <div class="input-group-append">
          <input type="number" step="0" id="domainCheckerLimit" placeholder="Введите лимит"
                 v-model="domainCheckerLimit" required
                 class="form-control" min="1" max="100"
                 :class="apiValidationErrors.domainCheckerLimit ? 'form-control-danger' : ''"
                 @focus="clearError('domainCheckerLimit')">
          <div class="input-group-append">
            <span class="input-group-text">шт.</span>
          </div>
        </div>
        <small class="form-text text-muted">Количество проверяємих доменов каждые 5 минут</small>
        <label v-if="apiValidationErrors.domainCheckerLimit" class="error mt-2 text-danger"
               for="domainCheckerLimit">
          {{ apiValidationErrors.domainCheckerLimit[0] }}
        </label>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import _ from 'lodash'
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import {mapActions, mapGetters} from 'vuex'

const defaultValues = () => ({
  activationCost: 0,
  domainCheckerCount: 0,
  domainCheckerLimit: 0
})

export default {
  name: 'AdminPWAsSettingsForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      loading: true,
      processing: false,
      ...defaultValues()
    }
  },
  computed: {
    ...mapGetters({
      pwas: 'adminConfig/pwas',
      billing: 'adminConfig/billing'
    })
  },
  methods: {
    ...mapActions({
      fetchData: 'adminConfig/fetchData',
      update: 'adminConfig/update',
      alertSuccess: 'alerts/success'
    }),
    debounceFetchData: _.debounce(async function () {
      await this.fetchData()
      this.activationCost = this.billing.activationCost
      this.domainCheckerCount = this.pwas.domain_checker_count
      this.domainCheckerLimit = this.pwas.domain_checker_limit
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData()
    },
    handlerOpen () {
      this.fetchPage()
      this.skip()
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.update({
          billing: {
            activationCost: this.activationCost
          },
          pwas: {
            domain_checker_count: this.domainCheckerCount,
            domain_checker_limit: this.domainCheckerLimit
          }
        })
        this.resetApiValidation()
        this.processing = false
        this.$refs.modal.close()
        await this.alertSuccess('Налаштування PWA збережено')
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
