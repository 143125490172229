<template>
  <div class="PageLandings page-full-fluid-width" v-if="accesses.landings.view">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <ConfirmModal ref="confirmModal" id="landing-pages-confirm-modal"/>
            <div class="mb-4 pb-2">
              <div class="mb-4">
                <h4 class="card-title">{{ $t('landingPages.title') }}</h4>
              </div>
              <div class="d-md-flex">
                <div class="flex-grow-1">
                  <form class="forms-sample">
                    <div class="item_filt_g row_filters grid_items_filter">
                      <div class="item_silter_sjhf_g">
                        <input type="text" class="form-control" id="text" tabindex="0" name="text"
                               v-model="filter.name"
                               :placeholder="$t('landingPages.filters.name')" v-on:keydown.enter.prevent/>
                      </div>
                      <div class="item_silter_sjhf_g">
                        <BaseSelect
                          v-model="filter.type"
                          id="filter.type"
                          :placeholder="$t('landingPages.filters.type')"
                          endpoint="/landing-pages/types/select2"
                          sort="id"
                        />
                      </div>
                      <div class="item_silter_sjhf_g">
                        <BaseSelect
                          v-model="filter.tags"
                          id="tags"
                          :multiple="true"
                          :taggable="true"
                          :placeholder="$t('landingPages.filters.tag')"
                          :options="filter.tags"
                          :create-option="option => option"
                        />
                      </div>
                      <div
                        v-if="visibleTheme"
                        class="item_silter_sjhf_g">
                        <BaseSelect
                          v-model="filter.theme"
                          id="filter.theme"
                          :placeholder="$t('landingPages.filters.design')"
                          filter-id="type"
                          :filter-id-value="filter.type"
                          endpoint="/landing-pages/themes/select2"
                          sort="id"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="flex-grow-0 mt-2 mt-md-0 ml-md-2" v-if="accesses.landings.update">
                  <router-link class="btn_green_g mobile-full-w" tag="button" :to="{name: 'LandingPageCreate'}">
                    + {{ $t('landingPages.buttons.create') }}
                  </router-link>
                </div>
              </div>
            </div>
            <loading v-model="loading" class="wrapper-table">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th class="col-3">
                      {{ $t('landingPages.table.headers.name') }}
                    </th>
                    <th class="col-3">
                      {{ $t('landingPages.table.headers.type') }}
                    </th>
                    <th class="col-3">
                      {{ $t('landingPages.table.headers.tags') }}
                    </th>
                    <th class="col-3">
                      {{ $t('landingPages.table.headers.designName') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="model of models" :key="model.id">
                    <td>{{ model.name }}</td>
                    <td>{{ model.type.name[locale] }}</td>
                    <td>
                    <span
                      v-for="(tag, index) of model.tags"
                      :key="index"
                      @click="addTag(tag)"
                      class="tag sort_butt_tgus">{{ tag }}</span>
                    </td>
                    <td>{{ model.theme.name[locale] }}</td>
                    <td>
                      <div class="d_flex justify_end">
                        <div v-if="accesses.landings.update">
                          <router-link class="d_flex align_center justify_center btn_icon_rounded update_btn_icon"
                                       tag="button" :to="{name: 'LandingPageUpdate', params: {id: model.id, type: model.type.id}}">
                              <EditPenIcon />
                          </router-link>
                        </div>
                        <div  v-if="accesses.landings.delete">
                          <button
                            @click="handlerDelete(model)"
                            type="button" class="d_flex align_center justify_center btn_icon_rounded remove_butt_g">
                            <TrashIcon />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </loading>
            <nav v-if="pageCount > 1">
              <paginate ref="paginate"
                        v-model="page"
                        :page-count="pageCount"
                        :click-handler="handlerPagination"
                        :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                        :next-text="'<i class=\'icon-arrow-right\'></i>'"
                        :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                        :page-class="'page_item_gd'"
                        :page-link-class="'page_link_g d_flex justify_center align_center'"
                        :prev-class="'page-item_go'"
                        :next-class="'page-item_go'"
                        :prev-link-class="'page-link_go d_flex justify_center align_center'"
                        :next-link-class="'page-link_go d_flex justify_center align_center'"
                        :active-class="'active'">
              </paginate>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18n } from '@/plugins/i18n'
import Loading from '@/components/Loading.vue'
import Select2 from '@/components/Select2.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'
import {mapActions, mapGetters} from 'vuex'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import EditPenIcon from '@/components/icons/EditPenIcon.vue'
import TrashIcon from '@/components/icons/TrashСanIcon.vue'
import {getCurrentLang} from '@/utils/i18n'
import accessesMixin from '@/mixins/accesses-mixin'
import titleMixin from '@/mixins/title-mixin'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  components: { BaseSelect, Loading, Select2, ConfirmModal, EditPenIcon, TrashIcon },
  mixins: [select2AjaxMixin, accessesMixin, titleMixin],
  title: function () {
    return this.$t('landingPages.title')
  },
  data () {
    return {
      locale: getCurrentLang(),
      loading: true,
      visibleTheme: true,
      page: 1,
      limit: 20,
      filter: {
        type: '',
        theme: '',
        name: '',
        tags: []
      }
    }
  },
  computed: {
    ...mapGetters({
      models: 'landingPages/models',
      count: 'landingPages/count'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'landingPages/fetchData',
      delete: 'landingPages/delete',
      alertSuccess: 'alerts/success'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        page: this.page,
        limit: this.limit,
        filter: this.filter,
        sort: '-createdAt'
      })
    },
    handlerPagination (input = 1) {
      this.page = input
      this.fetchPage()
    },
    handlerDelete: async function (model) {
      const ok = await this.$refs.confirmModal.show({
        title: i18n.t('landingPages.action.removeLanding', { name: model.name }),
        message: i18n.t('landingPages.action.removeMessage'),
        okButton: i18n.t('general.delete')
      })
      if (ok) {
        await this.delete(model.id)
        await this.alertSuccess(i18n.t('landingPages.alerts.successfullRemove', { name: model.name }))
        this.fetchPage()
      }
    },
    addTag (input) {
      this.filter.tags.push(input)
    }
  },
  watch: {
    filter: {
      handler () {
        this.handlerPagination()
      },
      deep: true
    },
    'filter.type': function () {
      this.visibleTheme = false
      this.filter.theme = null
      this.$nextTick(() => {
        this.visibleTheme = true
      })
    }
  },
  created () {
    this.handlerPagination()
  }
}
</script>

<style scoped>
.tag {
  padding: 3px 5px;
  background-color: #21BF73;
  border-radius: 3px;
  font-size: 12px;
}

.tag:not(:last-child) {
  margin-right: 5px;
}

</style>
