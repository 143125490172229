<template>
  <div class="d_inline_block item_butt_acat_g hlg_sua_g">
    <modal ref="modal" id="translator-modal" class="fade"
           classContent="modal-content modal_padd_normal" classDialog="modal-dialog">
      <form class="style_form_lab">
        <button v-if="total === 0" type="button" class="close_sjgs_to d_flex align_center" @click="handlerCancel"><span
          aria-hidden="true">&times;</span>
        </button>
        <div class="modal_title_g">{{ $t('pwas.components.generator.title') }}</div>
        <div v-if="total > 0" class="d-flex justify-content-between mt-2"
             v-html="progressLabel"></div>
        <div v-if="total > 0" class="progress progress-lg mt-2">
          <div class="progress-bar" role="progressbar"
               style="background: #21BF73;box-shadow: 0 4px 8px rgb(33 191 115 / 40%);border-radius: 3px;border: 1px solid #21BF73;"
               :style="{width: progress + '%'}"
               :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div v-if="total === 0" class="form-group">
          <BaseFromGroup
            :label="$t('pwas.components.generator.mode.title')"
          >
          <BaseSelect
            v-model="mode"
            id="mode" name="mode"
            :options="modesOptions"
            :clearable="false"
          />
          </BaseFromGroup>
        </div>
        <div class="mb-4">
          <div v-if="total === 0" class="mb-3">
            <label v-if="apiValidationErrors.texts" class="error mt-2 text-danger" for="texts">
              {{ apiValidationErrors.texts[0] }}
            </label>
            <br v-if="apiValidationErrors.texts">
            <label class="labek_check_sg" for="texts">
              <input type="checkbox" class="form-check-input" id="texts"
                     name="texts"
                     v-model="texts" @click="clearError('texts')">
              <div class="check_icd_G"></div>
              <span class="txt_chek_xg">{{ $t('pwas.components.generator.texts') }}</span>
            </label>
            <info
              :message="$t('pwas.components.generator.textsInfo')"/>
          </div>
          <div v-if="total === 0" class="mb-3">
            <label class="labek_check_sg" for="comments">
              <input type="checkbox" class="form-check-input" id="comments"
                     name="comments"
                     v-model="comments" @click="clearError('texts')">
              <div class="check_icd_G"></div>
              <span class="txt_chek_xg">{{ $t('pwas.components.generator.comments') }}</span>
            </label>
            <info
              :message="$t('pwas.components.generator.commentsInfo')"/>
          </div>
          <div v-if="mode !== 'generator' && total === 0" class="mb-3">
            <label class="labek_check_sg" for="media">
              <input type="checkbox" class="form-check-input" id="media"
                     name="media"
                     v-model="media" @click="clearError('texts')">
              <div class="check_icd_G"></div>
              <span class="txt_chek_xg">{{ $t('pwas.components.generator.media') }}</span>
            </label>
            <info
              :message="$t('pwas.components.generator.mediaInfo')"/>
          </div>
        </div>
        <div v-if="total === 0" class="form-group" :class="apiValidationErrors.name ? 'has-danger' : ''">
          <label for="name">{{ $t('pwas.components.app.main.name') }}
            <info :message="$t('pwas.components.app.main.nameInfo')"/>
          </label>
          <input type="text" id="name" :placeholder="$t('pwas.components.app.main.name')" v-model="name"
                 class="form-control"
                 :class="apiValidationErrors.name ? 'form-control-danger' : ''"
                 @focus="clearError('name')">
          <label v-if="apiValidationErrors.name" class="error mt-2 text-danger" for="name">
            {{ apiValidationErrors.name[0] }}
          </label>
        </div>
        <div v-if="total === 0" class="form-group" :class="apiValidationErrors.author ? 'has-danger' : ''">
          <label for="author">{{ $t('pwas.components.app.main.author') }}
            <info :message="$t('pwas.components.app.main.authorInfo')"/>
          </label>
          <input type="text" id="author" :placeholder="$t('pwas.components.app.main.author')"
                 v-model="author" class="form-control"
                 :class="apiValidationErrors.author ? 'form-control-danger' : ''"
                 @focus="clearError('author')">
          <label v-if="apiValidationErrors.author" class="error mt-2 text-danger" for="author">
            {{ apiValidationErrors.author[0] }}
          </label>
        </div>
        <div v-if="total === 0" class="form-group">
          <BaseFromGroup
            :label="$t('pwas.components.vertical.verticalLabel')"
          >
            <BaseSelect
              v-model="vertical"
              id="vertical" name="vertical"
              :options="verticalsOptions"
              :clearable="false"
            />
          </BaseFromGroup>
        </div>
        <BaseFromGroup
          v-if="total === 0"
          :label="$t('pwas.components.generator.langFrom')"
          id="langFrom"
          name="langFrom"
          :has-error="apiValidationErrors.lang"
          :error-text="apiValidationErrors?.lang ? apiValidationErrors?.lang[0] : ''"
        >
          <BaseSelect
            v-model="lang"
            id="langFrom"
            :options="langOptions"
            :clearable="false"
          />
        </BaseFromGroup>
        <div v-if="total === 0 && mode === 'googlePlay'" class="form-group"
             :class="apiValidationErrors.googlePlayURL ? 'has-danger' : ''">
          <label for="googlePlayURL">{{ $t('templates.form.googlePlayURL') }}</label>
          <input type="text" class="form-control" id="googlePlayURL" name="googlePlayURL"
                 :class="apiValidationErrors.googlePlayURL ? 'form-control-danger' : ''"
                 v-model="googlePlayURL"
                 :placeholder="$t('templates.form.googlePlayURLPlaceholder')"
                 @focus="clearError('googlePlayURL')">
          <label v-if="apiValidationErrors.googlePlayURL" class="error mt-2 info_warning_gdg" for="googlePlayURL">
            {{ apiValidationErrors.googlePlayURL[0] }}
          </label>
        </div>
        <BaseFromGroup
          v-if="total === 0 && mode === 'template'"
          :label="$t('templates.apps.your')"
          id="template"
          name="template"
          :has-error="apiValidationErrors.template"
          :error-text="apiValidationErrors?.template ? apiValidationErrors?.template[0] : ''"
        >
          <BaseSelect
            v-model="template"
            id="template"
            :options="templates"
            :clearable="false"
            :placeholder="$t('templates.filters.name')"
          />
        </BaseFromGroup>
        <div v-if="total === 0" class="modal_foot_this translator_foot">
          <div class="in_topshgdjf_g d_flex align_center">
            <div class="item_avt_butt_jg">
              <button type="button" class="ful_butt_green d_flex align_center justify_center" @click="handlerGenerate">
                {{ $t('pwas.components.generator.button') }}
              </button>
            </div>
            <div class="item_avt_butt_jg">
              <a href="javascript:void(0);" type="button" class="border_butt_green d_flex align_center justify_center"
                 @click="handlerCancel">
                {{ $t('general.cancel') }}
              </a>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Modal from '@/components/modal/Modal.vue'
import Select2 from '@/components/Select2.vue'
import {locales, trackers} from '@pwa.group/pwa.dictionary'
import Info from '@/components/Info.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'

const defaultFields = () => ({
  lang: 'ru',
  mode: 'generator',
  googlePlayURL: '',
  vertical: '',
  template: '',
  name: '',
  author: '',
  progressLabel: '',
  texts: true,
  comments: true,
  media: true
})

export default {
  name: 'GeneratorForm',
  mixins: [formMixin],
  components: {BaseFromGroup, BaseSelect, Modal, Select2, Info},
  props: {
    filters: {
      type: Array,
      required: true
    },
    pwa: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ...defaultFields(),
      modes: [
        {id: 'generator', label: 'pwas.components.generator.mode.random'},
        {id: 'googlePlay', label: 'pwas.components.generator.mode.googlePlay'},
        {id: 'template', label: 'pwas.components.generator.mode.template'}
      ],
      templates: []
    }
  },
  computed: {
    total () {
      return this.$store.getters['pwas/generator'].total
    },
    count () {
      return this.$store.getters['pwas/generator'].count
    },
    progress () {
      return Math.ceil(100 / this.total * this.count)
    },
    langOptions () {
      const options = []
      if (this.mode === 'googlePlay') {
        for (const [id, input] of Object.entries(trackers)) {
          if (input.whitepage === true) {
            options.push({id, text: `${this.$t(input.country)} (${this.$t(input.locale)})`})
          }
        }
      } else {
        for (const [id, text] of Object.entries(locales)) {
          options.push({id, text: this.$t(text)})
        }
      }
      return options
    },
    modesOptions () {
      return this.modes.map(mode => ({ id: mode.id, text: this.$t(mode.label) }))
    },
    verticalsOptions () {
      return this.$store.getters['verticals/verticals'].map(vertical => ({ id: vertical.id, text: this.$t(vertical.label) }))
    }
  },
  watch: {
    mode (n, o) {
      if (n === 'googlePlay') {
        this.lang = 'ru_RU'
      }
      if (o === 'googlePlay') {
        this.lang = 'ru'
      }
    }
  },
  methods: {
    skip () {
      const fields = defaultFields()
      for (const [prop, value] of Object.entries(fields)) {
        this[prop] = value
      }
      this.vertical = this.$store.getters['verticals/verticals'][0].id
      this.resetApiValidation()
    },
    async handlerOpen () {
      this.skip()
      await this.$store.dispatch('pwasTemplates/filter', {filter: {}, page: 1, limit: 100, sort: '-createdAt'})
      await this.$store.dispatch('pwas/setGenerator', {count: 0, total: 0})
      this.templates = this.$store.getters['pwasTemplates/models']
      this.name = this.pwa.name
      this.author = this.pwa.author
      this.vertical = this.pwa.vertical
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
    },
    async handlerGenerate () {
      if (this.mode === 'generator' && !this.texts && !this.comments) {
        return this.setApiValidation([{ param: 'texts', msg: 'Not selected' }])
      }
      if (!this.texts && !this.comments && !this.media) {
        return this.setApiValidation([{ param: 'texts', msg: 'Not selected' }])
      }

      if (confirm(this.$t('pwas.components.generator.confirm'))) {
        try {
          this.resetApiValidation()
          const payload = {
            mode: this.mode,
            lang: this.lang,
            name: this.name,
            author: this.author,
            vertical: this.vertical,
            locales: this.filters.map(filter => {
              if (this.mode === 'googlePlay') {
                return filter._id
              } else {
                return filter._id.split('_')[0].toLowerCase()
              }
            }),
            texts: this.texts,
            comments: this.comments
          }
          if (this.mode !== 'generator') {
            payload.media = this.media
          }
          if (this.mode === 'template') {
            payload.template = this.template
          } else {
            payload.googlePlayURL = this.googlePlayURL
          }
          this.sockets.subscribe('pwaGenerateBeginDownload', async ({locale, total}) => {
            this.progressLabel = this.$t('pwas.components.generator.pwaGenerateBeginDownload', {
              count: this.count,
              total: total || this.total,
              lang: this.getLocaleName(locale)
            })
          })
          this.sockets.subscribe('pwaGenerateFinishDownload', async ({locale, total}) => {
            await this.plusCount(total)
            this.progressLabel = this.$t('pwas.components.generator.pwaGenerateFinishDownload', {
              count: this.count,
              total: this.total,
              lang: this.getLocaleName(locale)
            })
          })
          this.sockets.subscribe('pwaGenerateBeginText', async ({locale, total}) => {
            this.progressLabel = this.$t('pwas.components.generator.pwaGenerateBeginText', {
              count: this.count,
              total: total || this.total,
              lang: this.getLocaleName(locale)
            })
          })
          this.sockets.subscribe('pwaGenerateFinishText', async ({locale, total}) => {
            await this.plusCount(total)
            this.progressLabel = this.$t('pwas.components.generator.pwaGenerateFinishText', {
              count: this.count,
              total: this.total,
              lang: this.getLocaleName(locale)
            })
          })
          this.sockets.subscribe('pwaGenerateBeginComment', async ({locale, total}) => {
            this.progressLabel = this.$t('pwas.components.generator.pwaGenerateBeginComment', {
              count: this.count,
              total: total || this.total,
              lang: this.getLocaleName(locale)
            })
          })
          this.sockets.subscribe('pwaGenerateFinishComment', async ({locale, total}) => {
            await this.plusCount(total)
            this.progressLabel = this.$t('pwas.components.generator.pwaGenerateFinishComment', {
              count: this.count,
              total: this.total,
              lang: this.getLocaleName(locale)
            })
          })
          this.sockets.subscribe('pwaGenerateBeginTemplate', async ({locale, total}) => {
            this.progressLabel = this.$t('pwas.components.generator.pwaGenerateBeginTemplate', {
              count: this.count,
              total: total || this.total,
              lang: this.getLocaleName(locale)
            })
          })
          this.sockets.subscribe('pwaGenerateFinishTemplate', async ({locale, total}) => {
            await this.plusCount()
            this.progressLabel = this.$t('pwas.components.generator.pwaGenerateFinishTemplate', {
              count: this.count,
              total: this.total,
              lang: this.getLocaleName(locale)
            })
          })
          this.sockets.subscribe('pwaGenerateFailed', async () => {
            await this.$store.dispatch('pwas/setGenerator', {count: 0, total: 0})
            await this.$store.dispatch('alerts/error', this.$t('pwas.components.generator.failed'))
          })
          this.sockets.subscribe('pwaGenerateFinish', async () => {
            this.sockets.unsubscribe('pwaGenerateFinish')
            this.sockets.unsubscribe('pwaGenerateBeginDownload')
            this.sockets.unsubscribe('pwaGenerateFinishDownload')
            this.sockets.unsubscribe('pwaGenerateBeginText')
            this.sockets.unsubscribe('pwaGenerateFinishText')
            this.sockets.unsubscribe('pwaGenerateBeginComment')
            this.sockets.unsubscribe('pwaGenerateFinishComment')
            this.sockets.unsubscribe('pwaGenerateBeginTemplate')
            this.sockets.unsubscribe('pwaGenerateFinishTemplate')
            await this.$store.dispatch('alerts/success', this.$t('pwas.components.generator.success'))
            this.handlerCancel()
            await this.$router.push({name: 'PWAsVertical', params: {id: this.$route.params.id}})
          })
          await this.$store.dispatch('pwas/pwaGenerate', {
            id: this.$route.params.id,
            payload
          })
          if (this.progressLabel === '') {
            this.progressLabel = this.$t('pwas.components.generator.pwaGenerateStart', {
              count: this.count,
              total: this.total
            })
          }
          this.resetApiValidation()
        } catch (error) {
          this.setApiValidation(error.response.data.errors)
        }
      }
    },
    async plusCount (total = null) {
      await this.$store.dispatch('pwas/setGenerator', {
        count: this.count + 1,
        total: total || this.total
      })
    },
    getLocaleName (locale) {
      if (locale === 'default') {
        locale = this.lang
      }
      return this.$t(locales[locale.split('_')[0]])
    }
  }
}
</script>
