export default {
  navigation: 'Вертикаль',
  title: 'Вертикаль PWA #{id}',
  load: 'Загрузить свои',
  message: 'Выберите набор картинок для PWA или загрузите свои',
  imageSet: 'Набор картинок',
  verticalLabel: 'Вертикаль',
  topGames: 'Выберите свои лучшие игры',
  pwa: 'Будут отображаться только работающие PWA.',
  loading: {
    title: 'Загрузите ваши <br>изображения',
    message: 'Выберите нужный вам файл в формате jpg, png. Размер файла не больше 2 мб.',
    icon: 'Иконка',
    preview: 'Превью',
    button: 'Использовать'
  },
  youtube: {
    title: 'YouTube видео',
    placeholder: 'Введите ссылку или фрейм на YouTube видео',
    require: 'Видео YouTube обязательное при использовании нового шаблона'
  },
  template: {
    title: 'Шаблон',
    templates: ['Стандартний', 'Новый 2022', 'PlayMarket (Beta)', 'Стандартний (Beta)']
  },
  muted: {
    title: 'Звук в видео',
    info: 'Звук будет только, если пользователь взаимодействовал со страницей',
    on: 'Включен',
    off: 'Выключен'
  }
}
