<template>
  <div class="profile-header text-white">
    <div class="d-md-flex justify-content-around">
      <div class="profile-info d-flex align-items-center">
        <img class="rounded-circle img-lg"
             :src="user.photo_url ? user.photo_url : '/images/no-avatar.jpg'" alt="profile image">
        <div class="wrapper pl-4">
          <p class="profile-user-name">{{ user.username }} ({{
              getLabelPermissionLevel(user.permissionLevel)
            }})</p>
          <div class="wrapper d-flex align-items-center">
            <p class="profile-user-designation">{{ user.auth_date * 1000 | dateFromNow }}</p>
          </div>
          <div class="wrapper d-flex align-items-center">
            <p class="profile-user-designation">Баланс: {{ user.balance | amount }}</p>
          </div>
        </div>
      </div>
      <div class="details">
        <div class="detail-col">
          <p>Активаций</p>
          <p>{{ user.tariffActivations }}/{{ user.tariffLimits }}</p>
        </div>
        <div class="detail-col">
          <p>Запущено</p>
          <p>{{ user.pwaActive }}/{{ user.pwasLimit }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import usersMixin from '../mixins/users-mixin'
import {mapGetters} from 'vuex'

export default {
  name: 'ProfileHeader',
  mixins: [usersMixin],
  computed: {
    ...mapGetters({user: 'adminUsers/user'})
  }
}
</script>
<style scoped>
.profile-page .profile-header {
  background: none !important;
}

.profile-page .profile-header .details .detail-col {
  padding-right: 8% !important;
  margin-right: 8% !important;
}

.profile-page .profile-header .details .detail-col p {
  text-align: center;
}
</style>
