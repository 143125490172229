<template>
  <form class="Filters mirrors-filter">
    <BaseInput
      name="name"
      v-model="model.name"
      :value="model.name"
      :placeholder="$t('mirrors.filters.name')"
      class="mirrors-filter__filed"
    />
    <BaseSelect
      v-model="model.status"
      :placeholder="$t('mirrors.filters.status')"
      :options="statusOptions"
      class="mirrors-filter__filed"
    />
    <BaseSelect
      v-model="model.pwa"
      :placeholder="$t('mirrors.filters.pwa')"
      class="mirrors-filter__filed"
      endpoint="/pwas/select"
      sort="createdAt"
    />
    <BaseInput
      v-model="model.domain"
      :placeholder="$t('mirrors.filters.search')"
      class="mirrors-filter__filed"
    />
    <BaseSelect
      v-model="model.tags"
      id="tags"
      :multiple="true"
      :taggable="true"
      :placeholder="$t('mirrors.filters.tag')"
      :options="model.tags"
      :create-option="option => option"
      class="mirrors-filter__filed"
    />
    <BaseSelect
      v-model="model.country"
      :placeholder="$t('trackers.form.country')"
      :options="countryOptions"
      show-icon
      class="mirrors-filter__filed"
    />
    <BaseSelect
      v-model="model.preLandingPage"
      placeholder="Pre-landing"
      endpoint="/landing-pages/select2"
      class="mirrors-filter__filed"
      sort="id"
    />
    <BaseSelect
      v-model="model.postLandingPage"
      placeholder="Post-landing"
      endpoint="/landing-pages/select2"
      sort="id"
      class="mirrors-filter__filed"
    />
  </form>
</template>
<script>
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { DOMAIN_STATUSES } from '@/config/statuses'
import * as dictionary from '@pwa.group/pwa.dictionary'

export default {
  name: 'Filters',
  components: {BaseSelect, BaseInput},
  props: {
    model: Object
  },
  computed: {
    statusOptions () {
      return DOMAIN_STATUSES.map(status => ({
        id: status.id,
        text: this.$t(status.label)
      }))
    },
    countryOptions () {
      const countries = []
      for (const [id, datum] of Object.entries(dictionary.trackers)) {
        let text = this.$t(datum.country)
        text += ` (${this.$t(datum.locale)})`
        countries.push({id, text})
      }
      return countries
    }
  },
  watch: {
    model: {
      handler () {
        this.$emit('change')
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="sass">
.mirrors-filter
  width: 100%
  display: flex
  flex-direction: row
  flex-wrap: wrap
  gap: 10px
  @media screen and (max-width: 767px)
    margin-bottom: 20px
  &__filed
    width: 100%
    flex-basis: calc(25% - 8px)
    max-width: 340px
    margin-bottom: 0 !important
    @media screen and (max-width: 991px)
      flex-basis: calc(33% - 5px)
      max-width: 100%
    @media screen and (max-width: 767px)
      flex-basis: calc(50% - 5px)
    @media screen and (max-width: 576px)
      flex-basis: 100%
</style>
