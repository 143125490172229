<template>
  <div class="HeaderAlert header-alert" :class="{hidden: banners?.notUsePublicPersonInAds}" style="min-height: auto;">
    <div class="message-wrap">
      <div class="message-wrap__icon">
        <InlineSvg src="/images/svg-icons/icon-info-banner.svg" />
      </div>
      <div class="description">
        <div v-html="description" />
        <button
          @click="onClick"
          class="btn-alert"
        >
          {{ $t('general.familiarized') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'HeaderAlert',
  components: {InlineSvg},
  computed: {
    button () { return this.$t('general.familiarized') },
    description () { return this.$t('banners.message') },
    ...mapGetters('bannersStore', [
      'banners'
    ]),
    ...mapGetters({
      user: 'identity'
    })
  },
  methods: {
    ...mapActions('bannersStore', [
      'setUserPrivacyPolicyBanner',
      'setMemberPrivacyPolicyBanner'
    ]),
    onClick () {
      if (this.user?.memberId) this.setMemberPrivacyPolicyBanner(this.user?.memberId)
      else this.setUserPrivacyPolicyBanner()
    }
  }
}
</script>
<style scoped lang="scss">
.header-alert {
  max-width: 1560px;
  margin: 0 20px;
  margin-bottom: 20px;
}
.message-wrap {
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;

  &__icon {
    background: #FD5E53;
    display: flex;
    align-items: center;
    padding: 18px 30px;

    svg {
      color: #FFFFFF;
    }
  }
}
.description {
  font-size: 14px;
  line-height: 130%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  gap: 30px;
  background-color: #35383A;

}
.btn-alert {
  color: #707175;
  font-size: 15px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid #707175;
  background: transparent;
  display: inline-block;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
}
.btn-alert:hover {
  background-color: #FFFFFF;
  color: #707175;
}
</style>
