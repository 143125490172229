<template>
  <div class="modal fade" id="gateway-modal">
    <div class="modal-dialog mt-0">
      <form class="modal-content" @submit.prevent="handler">
        <div class="modal-header">
          <h5 class="modal-title">Форма шлюза</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group" :class="apiValidationErrors.enable ? 'has-danger' : ''">
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" id="enable" name="enable" v-model="enable">
                Вкл./Выкл.
                <i class="input-helper"></i>
              </label>
            </div>
            <label v-if="apiValidationErrors.enable" id="enable-error" class="error mt-2 text-danger"
                   for="name">{{ apiValidationErrors.enable[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.name ? 'has-danger' : ''">
            <label for="name">Название</label>
            <input class="form-control" id="name" name="name" type="text" tabindex="1" v-model="name"
                   :class="apiValidationErrors.name ? 'form-control-danger' : ''"
                   placeholder="Введите название шлюза"
                   @focus="clearError('name')" autofocus>
            <label v-if="apiValidationErrors.name" id="name-error" class="error mt-2 text-danger"
                   for="name">{{ apiValidationErrors.name[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.method ? 'has-danger' : ''">
            <label for="method">Метод оплаты</label>
            <select class="form-control" id="method" name="method" type="text" tabindex="2" v-model="method"
                    :class="apiValidationErrors.method ? 'form-control-danger' : ''" @focus="clearError('method')"
                    autofocus>
              <option v-bind:value="value" v-for="(label,value) in this.$store.getters['adminPaymentMethods/list']"
                      v-bind:key="value">{{ label }}
              </option>
            </select>
            <label v-if="apiValidationErrors.method" id="method-error" class="error mt-2 text-danger"
                   for="method">{{ apiValidationErrors.method[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.rate ? 'has-danger' : ''">
            <label for="rate">Валюта шлюза</label>
            <select class="form-control" id="rate" name="rate" type="text" tabindex="2" v-model="rate"
                    :class="apiValidationErrors.rate ? 'form-control-danger' : ''" @focus="clearError('rate')"
                    autofocus>
              <option v-bind:key="id" v-for="(rate,id) in this.$store.getters['adminConfig/billing'].rates" :value="id">
                {{ id.toUpperCase() }}
              </option>
            </select>
            <label v-if="apiValidationErrors.rate" id="rate-error" class="error mt-2 text-danger"
                   for="rate">{{ apiValidationErrors.rate[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.data ? 'has-danger' : ''">
            <label for="data">Данные шлюза</label>
            <div id="data">
            </div>
            <label v-if="apiValidationErrors.data" id="data-error" class="error mt-2 text-danger"
                   for="data">{{ apiValidationErrors.data[0] }}</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success">Сохранить</button>
          <a href="javascript:void(0);" class="btn btn-light" data-dismiss="modal">Отмена</a>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import aceEditorMixin from '@/mixins/ace-editor-mixin'

export default {
  name: 'GatewayForm',
  props: ['merchantId'],
  mixins: [formMixin, aceEditorMixin],
  data: function () {
    return {
      id: false,
      name: '',
      enable: false,
      method: '',
      rate: '',
      data: ''
    }
  },
  created: function () {
    this.initAceEditor()
  },
  methods: {
    handler: async function () {
      try {
        this.resetApiValidation()
        if (this.id) {
          await this.$store.dispatch('adminGateways/update', {
            gateway_id: this.id,
            _id: this.merchantId,
            enable: this.enable,
            name: this.name,
            method: this.method,
            rate: this.rate,
            data: this.data
          })
          await this.$store.dispatch(
            'alerts/success',
            'Шлюз успешно обновлён'
          )
        } else {
          await this.$store.dispatch('adminGateways/create', {
            _id: this.merchantId,
            enable: this.enable,
            name: this.name,
            method: this.method,
            rate: this.rate,
            data: this.data
          })
          await this.$store.dispatch(
            'alerts/success',
            'Шлюз успешно создан'
          )
        }
        this.resetApiValidation()
        await this.$parent.getData()
        await this.$store.dispatch('adminGateways/list')
        this.enable = false
        this.name = ''
        this.method = ''
        this.rate = ''
        this.id = false
        this.data = ''
        // eslint-disable-next-line no-undef
        jQuery('#gateway-modal').modal('hide')
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    }
  }
}
</script>
