/* eslint-disable no-template-curly-in-string */
export default {
  method: 'Метод',
  install: {
    label: 's2s постбек при інсталі ({enable})',
    info: 'URL на який надсилатимуть запити за подіями, детально про налаштування можна ознайомитись у нашому F.A.Q.',
    setting: 'Налаштування інстала'
  },
  open: {
    label: 's2s постбек при відкритті ({enable})',
    info: 'URL на який надсилатимуть запити за подіями, детально про налаштування можна ознайомитись у нашому F.A.Q.',
    setting: 'Налаштування відкриття'
  },
  reopen: {
    label: 's2s постбек при повторному заході ({enable})',
    info: 'URL на який надсилатимуть запити за подіями, детально про налаштування можна ознайомитись у нашому F.A.Q.',
    setting: 'Налаштування повторного входу'
  },
  push: {
    label: 's2s постбек при PUSH підписці ({enable})',
    info: 'URL на який надсилатимуть запити за подіями, детально про налаштування можна ознайомитись у нашому F.A.Q.',
    setting: 'Налаштування підписки PUSH'
  },
  deposit: {
    label: 's2s постбек при депозиті ({enable})',
    info: 'URL на який надсилатимуть запити за подіями, детально про налаштування можна ознайомитись у нашому F.A.Q.',
    setting: 'Налаштування депозиту'
  },
  registration: {
    label: 's2s постбек під час реєстрації ({enable})',
    info: 'URL на який надсилатимуть запити за подіями, детально про налаштування можна ознайомитись у нашому F.A.Q.',
    setting: 'Налаштування реєстрації'
  }
}
