import service from '@/store/services/pwas-templates-service'
import middleware from './middleware'

const state = () => ({
  models: [],
  model: {},
  count: 0
})

const mutations = {
  setList: (state, {models, count}) => {
    state.models = models
    state.count = count
  },
  setModel: (state, model) => {
    state.model = model
  }
}

const actions = {
  create (ctx, payload) {
    return service.create(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update (ctx, payload) {
    return service.update(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  delete (ctx, payload) {
    return service.delete(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  view ({commit}, payload) {
    return service.view(payload)
      .then((res) => {
        commit('setModel', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  list ({commit}, payload) {
    return service.list(payload)
      .then((res) => {
        commit('setList', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  filter ({commit}, payload) {
    return service.filter(payload)
      .then((res) => {
        commit('setList', {models: res.results, count: 0})
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  model: state => state.model
}

const pwasTemplates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default pwasTemplates
