import api from './api'

export default {
  create: ({ pwaId, commentId, language, payload }) => {
    if (language) {
      return api.file(`pwas/${pwaId}/comments/translate/${language}/${commentId}/avatar`, payload)
    }
    return api.file(`pwas/${pwaId}/comments/${commentId}/avatar`, payload)
  },
  delete: ({ pwaId, commentId, language }) => {
    if (language) {
      return api.delete(`pwas/${pwaId}/comments/translate/${language}/${commentId}/avatar`)
    }
    return api.delete(`pwas/${pwaId}/comments/${commentId}/avatar`
    )
  }
}
