<template>
  <div class="block_auth_log">
    <div class="title_log_sist">{{ $t("auth.title") }}</div>
    <form class="telegramdhs_gio" @submit.prevent="handle">
      <BaseInput disabled v-model="username" />
      <BaseInput
        v-model="password"
        type="password"
        name="password-input"
        :placeholder="$t('auth.enterPassPlaceholder')"
        :is-error="Boolean(apiValidationErrors.password)"
        :error="Boolean(apiValidationErrors.password) ? apiValidationErrors.password[0] : ''"
        autofocus
        @focus="clearError('password')"
      />
      <button
        class="ful_butt_green d_flex align_center justify_center"
        type="button"
        @click="handle"
      >
        {{ $t("auth.button") }}
      </button>
    </form>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import BaseInput from '@/components/base/BaseInput.vue'
import {setI18nLanguage} from '@/plugins/i18n'

export default {
  name: 'AuthTeam',
  mixins: [formMixin],
  components: {
    BaseInput
  },
  data: () => ({
    id: '',
    username: '',
    password: ''
  }),
  created: async function () {
    try {
      await this.$store.dispatch('teams/member', this.$route.params.member)
      const member = this.$store.getters['teams/member']
      this.setI18nLanguage(member?.settings?.lang || 'en')
      this.id = member._id
      this.username = member.username
    } catch (err) {
      this.$router.push({ name: 'Auth' })
    }
  },
  methods: {
    setI18nLanguage,
    handle: async function () {
      const requestOptions = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
      try {
        this.resetApiValidation()
        await this.$store.dispatch('authTeam', {
          user: { id: this.id, password: this.password },
          requestOptions
        })
        this.resetApiValidation()
        await this.$store.dispatch('alerts/success', this.$t('auth.success'))
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.block_auth_log {
  min-width: 264px;

  .title_log_sist {
    font-size: 24px;
  }
}

.telegramdhs_gio {
  position: relative;
  margin-top: 30px;

  .username-input {
    color: #707175;
    margin-bottom: 30px;
  }

  ::v-deep #password-input {
    position: relative;

    &:focus {
      border-color: #45b172;
    }
  }

  ::v-deep .form-group {

    .form-control {
      font-size: 13px;
    }
    .error {
      display: block;
      text-align: left;
      font-size: 12px;
    }
  }

  .ful_butt_green {
    width: 100%;
    margin-top: 30px;
  }
}
</style>
