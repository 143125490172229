import axios from 'axios'
import {getCurrentLang} from '@/utils/i18n'

const mainConnector = {
  url: import.meta.env.VITE_APP_API_BASE_URL,
  headers: () => ({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('vue-authenticate.vueauth_accessToken'),
    'Accept-Language': getCurrentLang()
  }),
  timeout: 10000,
  getUrl: endpoint => `${mainConnector.url}/${endpoint}`,
  get: endpoint => {
    return axios.get(mainConnector.getUrl(endpoint), {
      headers: mainConnector.headers(),
      timeout: mainConnector.timeout
    })
      .then(response => {
        return response.data
      })
  },
  post: (endpoint, payload) => {
    return axios.post(mainConnector.getUrl(endpoint), payload, {
      headers: mainConnector.headers(),
      timeout: mainConnector.timeout
    })
      .then(response => {
        return response.data
      })
  },
  patch: (endpoint, payload) => {
    return axios.patch(mainConnector.getUrl(endpoint), payload, {
      headers: mainConnector.headers(),
      timeout: mainConnector.timeout
    })
      .then(response => {
        return response.data
      })
  },
  put: (endpoint, payload) => {
    return axios.put(mainConnector.getUrl(endpoint), payload, {
      headers: mainConnector.headers(),
      timeout: mainConnector.timeout
    })
      .then(response => {
        return response.data
      })
  },
  delete: endpoint => {
    return axios.delete(mainConnector.getUrl(endpoint), {
      headers: mainConnector.headers(),
      timeout: mainConnector.timeout
    })
      .then(response => {
        return response.data
      })
  },
  file: (endpoint, payload) => {
    let formData = new FormData()
    formData.append('file', payload)
    return axios.post(mainConnector.getUrl(endpoint), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...mainConnector.headers()
      },
      timeout: mainConnector.timeout
    })
      .then(response => {
        return response.data
      })
  }
}

export default mainConnector
