import mode from './mode/ru'
import s2sPostback from './s2s-postback/ru'
import splitTesting from './split-testing/ru'
import whitePageSource from './whitePageSource/ru'

export default {
  mode,
  s2sPostback,
  splitTesting,
  whitePageSource
}
