import api from '../api'
import {getSearchParams} from '../../list'

export default {
  index: payload => api.get('admin/statistics?' + getSearchParams(payload)),
  uniques: payload => api.get('admin/statistics/uniques?' + getSearchParams(payload)),
  countries: payload => api.get('admin/statistics/countries?' + getSearchParams(payload)),
  owners: payload => api.get('admin/statistics/owners?' + getSearchParams(payload)),
  hosts: payload => api.get('admin/statistics/hosts?' + getSearchParams(payload))
}
