<template>
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <breadcrumb :id="pwa._id" nav-class="col-9">
          <div class="col-2">
            <button class="btn btn-icons btn-rounded btn-outline-success" @click="handler"><i class="fa fa-save"></i></button>
            <arrow-button v-model="shs" classs="btn btn-icons btn-rounded btn-outline-success"/>
          </div>
        </breadcrumb>
        <h4 class="card-title">{{ pwa.alias || pwa.name }}</h4>
        <div class="table table-responsive" v-if="shs">
          <table class="table table-borderless">
            <tbody>
            <tr>
              <th>Оплачено</th>
              <td>
                <div class="form-group mb-0" :class="apiValidationErrors.paid ? 'has-danger' : ''">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" id="paid" name="paid" v-model="paid">
                      Оплачено
                      <i class="input-helper"></i>
                    </label>
                  </div>
                  <label v-if="apiValidationErrors.paid" id="paid-error" class="error mt-2 text-danger"
                         for="paid">{{ apiValidationErrors.paid[0] }}</label>
                </div>
              </td>
            </tr>
            <tr>
              <th>Статус</th>
              <td><div class="form-group mb-0" :class="apiValidationErrors.status ? 'has-danger' : ''">
                <label for="status" class="sr-only">Статус</label>
                <select class="form-control" id="status" type="text" tabindex="2" v-model="status"
                        :class="apiValidationErrors.status ? 'form-control-danger' : ''" @focus="clearError('status')"
                        autofocus>
                  <option v-bind:value="item.id" v-for="(item) in this.$store.getters['pwas/statuses']"
                          v-bind:key="item.id">{{ $t(item.label) }}
                  </option>
                </select>
                <label v-if="apiValidationErrors.status" id="status-error" class="error mt-2 text-danger"
                       for="status">{{ apiValidationErrors.status[0] }}</label>
              </div></td>
            </tr>
            <tr>
              <th>Домен</th>
              <td>
                <div class="form-group mb-0" :class="apiValidationErrors.domain ? 'has-danger' : ''">
                  <label for="domain" class="sr-only">Домен</label>
                  <input class="form-control" id="domain" name="domain" type="text" tabindex="1" v-model="domain"
                         :class="apiValidationErrors.domain ? 'form-control-danger' : ''"
                         placeholder="Введите домен"
                         @focus="clearError('domain')" autofocus>
                  <label v-if="apiValidationErrors.domain" id="domain-error" class="error mt-2 text-danger"
                         for="domain">{{ apiValidationErrors.domain[0] }}</label>
                </div>
              </td>
            </tr>
            <tr>
              <th>Владелец</th>
              <td><div class="form-group mb-0" :class="apiValidationErrors.owner ? 'has-danger' : ''">
                <label for="owner" class="sr-only">Владелец</label>
                <owner-filter v-model="owner" id="owner" v-bind:data="users" ref="ownerFilter"
                              :init="false"/>
                <label v-if="apiValidationErrors.owner" id="owner-error" class="error mt-2 text-danger"
                       for="owner">{{ apiValidationErrors.owner[0] }}</label>
              </div></td>
            </tr>
            <tr>
              <th>Пользовательский JS</th>
              <td><div class="form-group mb-0" :class="apiValidationErrors.customJs ? 'has-danger' : ''">
                <label for="customJs" class="sr-only">Пользовательский JS</label>
                <textarea class="form-control" id="customJs" v-model="customJs"
                          :class="apiValidationErrors.customJs ? 'form-control-danger' : ''"
                          placeholder="Введите пользовательский JS" rows="6" v-html="customJs"
                          @focus="clearError('customJs')">
            </textarea>
                <label v-if="apiValidationErrors.customJs" id="customJs-error" class="error mt-2 text-danger"
                       for="customJs">{{ apiValidationErrors.customJs[0] }}</label>
              </div></td>
            </tr>
            <tr>
              <th>Вертикаль</th>
              <td>{{ getVertical(pwa.vertical) }}</td>
            </tr>
            <tr>
              <th>Креатив</th>
              <td>
                <images :data="{images: pwa.images, vertical: pwa.vertical, gallery: pwa.gallery}"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import domainMixins from '@/mixins/domain-mixin'
import * as adminComponents from '@/pages/admin/components'
import Breadcrumb from '../Breadcrumb.vue'
import ArrowButton from '../ArrowButton.vue'
import Images from '../Images.vue'

export default {
  name: 'Setting',
  props: ['pwa'],
  mixins: [formMixin, domainMixins],
  components: {'owner-filter': adminComponents.OwnerFilter, ArrowButton, Breadcrumb, Images},
  data: () => ({
    shs: true,
    paid: false,
    status: 0,
    domain: '',
    owner: '',
    users: [],
    customJs: ''
  }),
  created: async function () {
    for (const [prop, value] of Object.entries(this.pwa)) {
      if (this[prop] != null) {
        this[prop] = value
      }
    }
    await this.$store.dispatch('adminUsers/read', this.pwa.owner)
    this.users.push(this.$store.getters['adminUsers/user'])
    setTimeout(() => {
      this.$refs.ownerFilter.initSelect2()
    }, 500)
  },
  methods: {
    getVertical: function (value) {
      const index = this.$store.getters['verticals/verticals'].findIndex(vertical => vertical.id === Number(value))
      if (index === -1) {
        return 'Своя'
      } else {
        return this.$t(this.$store.getters['verticals/verticals'][index].label)
      }
    },
    handler: async function () {
      try {
        this.domain = this.sanitizerDomain(this.domain)
        this.resetApiValidation()
        await this.$store.dispatch('adminPWAs/updatePWA', {
          id: this.pwa._id,
          paid: this.paid,
          owner: this.owner,
          status: this.status,
          domain: this.domain,
          customJs: this.customJs
        })
        this.resetApiValidation()
        await this.$store.dispatch(
          'alerts/success',
          'PWA успешно обновлено'
        )
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    }
  }
}
</script>
