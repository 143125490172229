export default {
  invalid: 'Valor inválido',
  domain: {
    wrongFormat: 'Domínio incorreto ou formato de zona de domínio'
  },
  image: {
    maxSize: 'O tamanho do ficheiro não deve exceder 2MB.',
    required: 'Esta imagem é necessária'
  },
  accessDenied: 'Você não tem acesso, por favor, entre em contato com seu administrador',
  dateOutOfRange: 'A data selecionada está fora do mês permitido. Escolha uma data dentro de um mês da inicial.',
  dashboardAccessDenied: 'O acesso a estes dados está restrito para a sua conta',
  requestError: 'Atualmente não foi possível carregar os dados para exibição.',
  requestError504: 'Algo deu errado, por favor entre em contato com o administrador!'
}
