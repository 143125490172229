import mode from './mode/uk'
import s2sPostback from './s2s-postback/uk'
import splitTesting from './split-testing/uk'
import whitePageSource from './whitePageSource/uk'

export default {
  mode,
  s2sPostback,
  splitTesting,
  whitePageSource
}
