<template>
  <modal
    ref="modal"
    id="text-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="vertical">Вертикаль</label>
        <select class="form-control" id="vertical" tabindex="2" name="vertical"
                v-model="vertical">
          <option v-for="item in $store.getters['verticals/verticals']" :key="item.id" :value="item.id">
            {{ $t(item.label) }}
          </option>
        </select>
      </div>
      <div class="form-group" :class="apiValidationErrors['_id'] ? 'has-danger' : ''">
        <label for="text">Описание</label>
        <textarea class="form-control" id="text" name="text" type="text" tabindex="1"
               v-model="text"
               rows="10"
               :class="apiValidationErrors['text'] ? 'form-control-danger' : ''"
               placeholder="Введите описание"
               @focus="clearError('text')"></textarea>
        <label v-if="apiValidationErrors['text']" class="error mt-2 text-danger"
               for="text">{{ apiValidationErrors['text'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['changes_recent'] ? 'has-danger' : ''">
        <label for="changes_recent">Последние изменения</label>
        <textarea class="form-control" id="changes_recent" name="changes_recent" type="text" tabindex="1"
               v-model="changes_recent"
               rows="10"
               :class="apiValidationErrors['changes_recent'] ? 'form-control-danger' : ''"
               placeholder="Введите текст последних изменений"
               @focus="clearError('changes_recent')"></textarea>
        <label v-if="apiValidationErrors['changes_recent']" class="error mt-2 text-danger"
               for="changes_recent">{{ apiValidationErrors['changes_recent'][0] }}</label>
      </div>
      <p class="mt-2 card-description">
        Доступные макросы {name} - название PWA и {author} - название разработчика
      </p>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'

const defaultValues = () => ({
  text: '',
  changes_recent: '',
  vertical: 1
})

export default {
  name: 'TextForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      isCreate: true,
      processing: false,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    title () {
      return this.isCreate ? 'Создание текстов' : 'Редактирование текстов'
    }
  },
  methods: {
    handlerCreate () {
      this.skip()
      this.isCreate = true
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerUpdate ({ id, text, changes_recent, vertical }) {
      this.skip()
      this.isCreate = false
      this.id = id
      this.text = text
      this.changes_recent = changes_recent
      this.vertical = vertical
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.$store.dispatch(this.isCreate ? 'adminTexts/create' : 'adminTexts/update', {
          id: this.id,
          text: this.text,
          changes_recent: this.changes_recent,
          vertical: this.vertical
        })
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
