export default {
  title: 'Campanhas',
  add: 'Criar uma campanha',
  noHave: 'Ainda não tem campanhas criadas no sistema de tracker. Clicar em "Adicionar uma campanha"',
  companyInfo: 'Identificador da campanha',
  enable: 'Ligar/Desligar',
  enableInfo: 'Estado da campanha',
  name: 'Nome',
  nameInfo: 'Nome da campanha',
  country: 'Países',
  countryInfo: 'GEO da campanha',
  pwaInfo: 'onde se usa esta campanha',
  tag: 'Tags',
  tagInfo: 'Tags definidos para Tracker',
  filters: {
    name: 'Busca por nome',
    id: 'Busca por ID',
    enable: 'Filtro por estado',
    country: 'Filtro por país',
    pwa: 'Filtro por PWA',
    tag: 'Filtro por tag',
    noHave: 'Busca não deu o resultado, tente alterar os parâmetros de filtro'
  },
  form: {
    title: 'Campanha',
    setting: 'Configuração da campanha',
    nameInfo: 'Nome da sua campanha. Introduza para seu conforto',
    link: 'Link para oferta',
    linkDefault: 'Link para o tráfego impróprio',
    linkInfo: 'Introduza o link para redireccionamento, que seja usado caso o GEO falhar',
    active: 'Ativa',
    notActive: 'Não ativa',
    activeInfo: 'Estado da campanha',
    tags: 'Tags do tracker',
    tagsInfo: 'Conjunto de tags para filtração de tracker',
    newTag: 'Adicionar um tag novo',
    filters: 'Link',
    filtersInfo: 'Escolher um GEO e definir o link de redireccionamento para estes países',
    info: 'Para redireccionamento indica o par "país - redireccionamento"',
    country: 'Escolher um país',
    created: 'Campanha foi guardada com sucesso',
    deletePair: 'Apagar este par?'
  },
  linkSettings: {
    link: 'Configuração de filtros {country}({locale})',
    title: 'Configurações complementares',
    postback: 'Postbacks',
    postbackTemplate: 'Modelo do postback',
    postbackTemplateSelect: 'Escolher o modelo do Postbac',
    postbackLink: 'Link de postback',
    postbackInfo: 'Inserir este URL na sua conta de parceria',
    postbackRegLink: 'Link de postback para registos',
    postbackRegInfo: 'Caso não entender o que é, o favor de contactar o Serviço de apoio {name}',
    postbackDepositLink: 'Link de postback para depósito',
    postbackDepositInfo: 'Caso não entender o que é, o favor de contactar o Serviço de apoio {name}',
    get: 'Direcionar o GET',
    getInfo: 'Quando é necessário direcionar todos os GET parâmetros de PWA no link',
    fullscreen: 'Abrir Fullscreen, Abrir Fullscreen',
    fullscreenInfo: 'Escolher a visualização de PWA com ativação',
    bindPrevButton: 'Traffic back',
    bindPrevButtonInfo: 'Se nenhum link for fornecido, será usado o link gerado para o redirecionamento. O usuário será direcionado para este URL se quiser sair da página ao pressionar o botão \'voltar\'',
    bindPrevButtonPlaceholder: 'URL para traffic back',
    redirect: 'Redireccionamento',
    landingSettings: 'Configuração de Landing',
    locale: 'Localização de landing',
    localeInfo: 'Indica a localização de landing. PWA com tradução apropriada visualizar-se-á com a tradução',
    cloakTitle: 'Cloaca',
    cloak: 'Usar a cloaca',
    cloakInfo: 'Ligar/desligar o uso da cloaca',
    android: 'Apenas Android',
    androidInfo: 'Todo o tráfego fora de OS Android será afastado por bot. Assim será visualizada a white page, se for configurada',
    white: 'Enviar outros GEOs para wight',
    whiteInfo: 'Caso estiver ligado enviar para White page todos fora de GEO indicado',
    whiteRedirect: 'Redireccionamento para seu white',
    whiteRedirectInfo: 'Caso estiver ligado, enviar para URL indicado todos fora de GEO indicado',
    geoRedirect: 'URL para redireccionamento por GEO',
    whiteLang: 'Idioma de white page',
    whiteLangInfo: 'Idioma para gerar o white page',
    whiteLangPlaceholder: 'Escolher o idioma para gerar o white page',
    app: 'Link para aplicação',
    appInfo: 'Link para aplicação de play.google.com/store/apps/',
    footer: 'Bloco texto em futuro',
    footerInfo: 'Permitidos os tags A B BR P STRONG',
    url: 'URL',
    clUrl: 'Link para aplicação',
    useCookies: 'Ficheiros cookies',
    useCookiesInfo: 'Mostrar aos utilizadores a inscrição de concordância para uso de ficheiros cookies. É necessário como um clique falso para ativação de determinados scripts',
    ignoreCloak: 'Ignore o сloak',
    ignoreCloakInfo: 'Se você ativar esta opção, será possível ignorar o cloak integrada usando o parâmetro GET <strong>?ignore_cloak=1</strong>. Use esta função apenas para teste. Não é recomendado o uso durante a campanha publicitária!'
  },
  delete: {
    title: 'Apagar o tracker',
    message: 'Tem certeza que quer apagar o tracker {name}?',
    button: 'Apagar',
    success: 'Tracker foi removido'
  },
  copy: {
    title: 'Copiar o tracker',
    message: 'Tem certeza que quer copiar o tracker {name}?',
    button: 'Copiar',
    success: 'Tracker foi copiado'
  },
  wp: {
    generate: 'Gerar',
    success: 'Whitepage foi gerado',
    failed: 'Whitepage não foi gerada',
    notTracker: 'O acesso à configuração de cloaca será disponível apenas depois de criação da campanha'
  }
}
