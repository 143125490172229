<template>
  <div class="upload-container">
    <div class="images-upload-modal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="head_modla_ag d_flex justify_between">
            <div class="left_text_eh_mog">
              <div class="title_mod_uplo" v-html="$t('pwas.components.vertical.loading.title')"></div>
              <div class="descr_mod_uplo">{{ $t('pwas.components.vertical.loading.message') }}
              </div>
            </div>
            <div class="logo_uploading_g">
              <div class="form_upload_log">
                <form enctype="multipart/form-data" id="avatar-0">
                  <label class="file_upload_g d_flex align_center justify_center" @click="clearError('icon')">
                    <input type="file" @change="handleImageCrop('icon', $event)" accept="image/png, image/jpeg">
                    <div class="no_poin_ev">
                      <div class="icon_uploas_g small_ic_g no_marg"></div>
                      <div>{{ $t('pwas.components.vertical.loading.icon') }}</div>
                    </div>
                  </label>
                  <label v-if="apiValidationErrors.icon" class="error mt-2 descr_mod_uplo text-danger" for="image">
                    {{ apiValidationErrors.icon[0] }}
                  </label>
                </form>
              </div>
              <div v-if="logo" class="uploaded_icon_g d_flex align_center justify_center">
                <div>
                  <img :src="logo | APIImage" class="uploaded_icon_g__image">
                  <button @click="handlerDeleteLogo" class="close_log_g">
                    <img src="/images/svg-icons/icon-remove.svg" alt="icon-remove">
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="form_preview_g">
            <form enctype="multipart/form-data" :id="`avatar--1`">
              <label class="file_upload_g d_flex align_center justify_center" @click="clearError('image')">
                <input type="file" @change="handleImageCrop('image', $event)" accept="image/png, image/jpeg">
                <div class="no_poin_ev">
                  <div class="icon_uploas_g small_ic_g no_marg"></div>
                  <div>{{ $t('pwas.components.vertical.loading.preview') }}</div>
                </div>
              </label>
              <label v-if="apiValidationErrors.image" class="error mt-2 descr_mod_uplo text-danger" for="image">
                {{ apiValidationErrors.image[0] }}
              </label>
            </form>
          </div>
          <draggable v-model="previewGallery">
            <div class="item_previ_img" v-for="image in previewGallery" :key="image">
              <img :src="image | APIImage">
              <button @click="handlerDeleteImage(image)" class="close_log_g">
                <img src="/images/svg-icons/icon-remove.svg" alt="icon-remove">
              </button>
            </div>
          </draggable>
          <div class="actions_foot_g">
            <div class="d_flex align_center justify_end">
              <div class="d_inline_block item_butt_acat_g">
                <button  class="simple_butt_afg d_flex align_center justify_center"
                   @click="handlerCancel">
                  {{ $t('general.cancel') }}
                </button>
              </div>
              <div class="d_inline_block item_butt_acat_g">
                <button class="ful_butt_green d_flex align_center justify_center apply-button" @click="handlerUse">
                  <loading v-model="loading" white> {{ $t('pwas.components.vertical.loading.button') }}</loading>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ImageCropper v-if="imageToCrop" :image="imageToCrop" @close="imageToCrop = null" @imageCropped="handlerUpload"/>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ImageCropper from '@/components/ImageCropper.vue'
import formMixin from '@/mixins/form-mixin'
import uploadMixin from '@/mixins/upload-mixin'
import Loading from '@/components/Loading.vue'

export default {
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  mixins: [formMixin, uploadMixin],
  components: {
    draggable,
    ImageCropper,
    Loading
  },
  data () {
    return {
      imageToCrop: null,
      uploadImageType: 'icon',
      previewGallery: [],
      logo: null,
      imagesToDelete: [],
      loading: false
    }
  },
  computed: {
    PWAId () {
      return this.$route.params.id
    }
  },
  created () {
    this.logo = this.value[0]
    this.previewGallery = this.value.slice(1)
  },
  methods: {
    handleImageCrop (type, event) {
      const file = event.target.files[0]
      if (!file) return
      const validation = this.validateFile(file)
      if (!validation.isValid) {
        this.setApiValidation([{ param: type, msg: validation.errorMessage }])
        return
      }
      this.imageToCrop = file
      this.uploadImageType = type
      event.currentTarget.value = null
    },
    async handlerDeleteLogo () {
      if (!this.logo.includes('blob')) {
        this.imagesToDelete.push(this.logo)
      }
      this.logo = null
    },
    async handlerDeleteImage (image) {
      if (!image.includes('blob')) {
        this.imagesToDelete.push(image)
      }
      this.previewGallery = this.previewGallery.filter(item => item !== image)
    },
    async handlerUpload (file) {
      if (this.uploadImageType === 'icon') {
        this.logo = URL.createObjectURL(file)
      } else if (this.uploadImageType === 'image') {
        this.previewGallery.push(URL.createObjectURL(file))
      }
    },
    handlerCancel () {
      this.imagesToDelete = []
      this.$emit('cancel')
    },
    async handlerUse () {
      if (this.logo) {
        this.loading = true
        const uploadedImages = await this.uploadImages([this.logo, ...this.previewGallery])
        this.deleteImages()
        this.$emit('apply', uploadedImages)
        this.loading = false
      } else {
        this.setApiValidation([{param: 'icon', msg: this.$t('validations.image.required')}])
      }
    },
    async uploadImages (images) {
      const urls = []
      for (let image of images) {
        if (image.includes('blob')) {
          const blob = await fetch(image).then(r => r.blob())
          const url = await this.$store.dispatch('pwas/gallery', {
            _id: this.PWAId,
            payload: blob
          })
          urls.push(url)
        } else {
          urls.push(image)
        }
      }
      return urls
    },
    deleteImages () {
      this.imagesToDelete.forEach(image => {
        this.$store.dispatch('pwas/galleryDelete', {
          _id: this.PWAId,
          payload: image
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;

  .uploaded_icon_g {
    .uploaded_icon_g__image {
      width: 100%;
      height: 100%;
      max-height: 90px;
    }
  }

  .item_previ_img {
    display: inline-block;

    .close_log_g {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .form_upload_log form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }

  .apply-button {
    width: 170px;
  }
}
</style>
