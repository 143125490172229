<script>
import formMixin from '@/pages/landing-pages/form.mixin'
import {mapActions} from 'vuex'

export default {
  mixins: [formMixin],
  title: function () {
    return this.$t('landingPages.addLandingTitle')
  },
  async created () {
    this.processing = true
    await this.init()
    this.formData.type = this.types[0].id
    this.formData.theme = this.themes[0].id
    await this.updatePreview(this.formData)
    this.$nextTick(async () => { this.processing = this.loading = false })
  },
  computed: {
    type () {
      return this.$store.getters['landingPages/types'].filter(type => type.id === this.formData.type)[0]
    },
    theme () {
      return this.$store.getters['landingPages/themes'].filter(theme => theme.id === this.formData.theme)[0]
    }
  },
  methods: {
    ...mapActions({
      create: 'landingPages/create',
      alertError: 'alerts/error'
    }),
    async handleSubmit () {
      this.processing = true
      try {
        this.resetApiValidation()
        await this.create({
          ...this.formData,
          type: this.type,
          theme: this.theme
        })
        await this.$router.push({name: 'LandingPages'})
      } catch (e) {
        await this.alertError(this.$t(e.response.data.errors[0].msg))
        this.setApiValidation(e.response.data.errors)
      }
      this.$nextTick(() => {
        this.processing = false
      })
    }
  }
}
</script>

<template>
  <div class="card-body pt-0" v-if="accesses.landings.view">
    <div class="style_descr_g">
      <h4 class="title_sf_site mt-4">{{ $t('landingPages.create.title') }}</h4>
    </div>
    <Loading v-model="loading" class="blk_tabs_geg style_form_lab">
      <LandingForm
        v-model="formData"
        :errors="apiValidationErrors"
        @submit="handleSubmit"
      />
    </Loading>
  </div>
</template>
