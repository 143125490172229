<template>
  <div class="BaseTags base-tags">
    <div
      :class="['base-tags__wrapper']"
      >
      <div class="base-tags__wrapper-hidden" ref="tabsWrapper">
        <BaseTag
          v-for="(tag, i) in tags"
          :key="`${tag}-visible${i}`"
          :data="tag"
          :clickable="clickable"
          @tag-click="$emit('tag-click', $event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BaseTag from './BaseTag.vue'
export default {
  name: 'BaseTags',
  props: {
    tags: Array,
    clickable: {
      type: Boolean,
      default: false
    }
  },
  components: {BaseTag}
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.base-tags
  display: flex
  flex-direction: row
  &__wrapper
    overflow: hidden
    display: flex
    flex-direction: row
    flex-wrap: wrap
  &__wrapper-hidden
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 5px
  &__dropdown
    :deep(.dropdown-toggle)
      margin-left: 0
  &__dropdown-tags
    padding: 0px 10px
    display: flex
    flex-wrap: wrap
    gap: 5px
    max-width: 280px
</style>
