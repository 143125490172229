<template>
  <div class="PagePush page-full-fluid-width parent_tooltip page-push">
    <div class="row has_big_rog" v-if="!user.memberId || !user.isCompaniesOwner">
      <div class="col-md-4 item_pwa_shs_g info_ahg_abt">
        <stat
          :icon="'/images/icon_treker_2.svg'"
          :header="count"
          :text="$t('pushes.statistics.count.title')"
          :type="'text-primary'"
          :tooltip="$t('pushes.statistics.count.info')"
        />
      </div>
      <div class="col-md-4 item_pwa_shs_g info_ahg_abt">
        <stat
          :icon="'/images/pwa_icon_nr_2.svg'"
          :header="registrations"
          :text="$t('pushes.statistics.registered.title')"
          :type="'text-primary'"
          :tooltip="$t('pushes.statistics.registered.info')"
        />
      </div>
      <div class="col-md-4 item_pwa_shs_g info_ahg_abt">
        <stat
          :icon="'/images/icon_treker_3.svg'"
          :header="deposits"
          :text="$t('pushes.statistics.deposited.title')"
          :type="'text-primary'"
          :tooltip="$t('pushes.statistics.deposited.info')"
        />
      </div>
    </div>
    <BaseRouterTabs
      class="page-push__tabs"
      :tabs="tabs"
      :with-translators="true"
    />
    <div class="row page-push__table">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatistic from '@/components/MiniStatistic.vue'
import {mapActions, mapGetters} from 'vuex'
import BaseRouterTabs from '@/components/base/BaseRouterTabs.vue'

export default {
  name: 'PWAsPushesLayout',
  components: {BaseRouterTabs, 'stat': MiniStatistic},
  async created () {
    if (!this.user.memberId || !this.user.isCompaniesOwner) {
      await this.asyncData()
    }
  },
  computed: {
    ...mapGetters({
      statistic: 'pwasPushes/statistic',
      user: 'identity'
    }),
    count () {
      return this.statistic.count
    },
    registrations () {
      return this.statistic.registrations
    },
    deposits () {
      return this.statistic.deposits
    },
    tabs () {
      return [
        {to: {name: 'PWAsPushes'}, label: 'pushes.title'},
        {to: {name: 'IntervalPushes'}, label: 'pushes.intervals.title'},
        {to: {name: 'ActionsPushesPushes'}, label: 'pushes.actions.title'},
        {to: {name: 'PushTemplates'}, label: 'pushes.templates.title'}
      ]
    }
  },
  methods: {
    ...mapActions({
      asyncData: 'pwasPushes/statistic'
    })
  }
}
</script>

<style lang="sass" scoped>
.page-push
  &__tabs
    padding: 0 30px
.page-full-fluid-width
  justify-content: flex-start

.page-push__table
  flex-grow: 1

</style>
