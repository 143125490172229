<template>
  <form class="forms-sample position-relative trackers_form_g" @submit.prevent="handlerSubmit">
    <div class="checkbox_item_g">
      <label class="labek_check_sg">
        <input type="checkbox" class="form-check-input" name="enable" v-model="enable">
        <div class="check_icd_G"></div>
        <span class="txt_chek_xg">Включити</span>
      </label>
      <info message="Доступність відображення офера в боті для вебів"/>
    </div>
    <div class="row">
      <div class="form-group col-sm-6" :class="apiValidationErrors['name.uk'] ? 'has-danger' : ''">
        <label for="name-uk">Назва українською
          <info message="Назва оферу для користувачів з українською локалізацією"/>
        </label>
        <input type="text" id="name-uk" placeholder="Заповніть назву українською" v-model="name.uk"
               class="form-control"
               :class="apiValidationErrors['name.uk'] ? 'form-control-danger' : ''"
               @focus="clearError('name.uk')">
        <label v-if="apiValidationErrors['name.uk']" class="error mt-2 text-danger"
               for="name-uk">{{ apiValidationErrors['name.uk'][0] }}</label>
      </div>
      <div class="form-group col-sm-6" :class="apiValidationErrors['name.pt'] ? 'has-danger' : ''">
        <label for="name-pt">Nomear os portugueses
          <info message="Nome da oferta para utilizadores com localização ucraniana"/>
        </label>
        <input type="text" id="name-pt" placeholder="Preencher o nome em ucraniano" v-model="name.uk"
               class="form-control"
               :class="apiValidationErrors['name.pt'] ? 'form-control-danger' : ''"
               @focus="clearError('name.pt')">
        <label v-if="apiValidationErrors['name.pt']" class="error mt-2 text-danger"
               for="name-pt">{{ apiValidationErrors['name.pt'][0] }}</label>
      </div>
      <div class="form-group col-sm-6" :class="apiValidationErrors['name.en'] ? 'has-danger' : ''">
        <label for="name-en">Name by English
          <info message="The name of the offer for users with English localization"/>
        </label>
        <input type="text" id="name-en" placeholder="Input name by English" v-model="name.en"
               class="form-control"
               :class="apiValidationErrors['name.en'] ? 'form-control-danger' : ''"
               @focus="clearError('name.en')">
        <label v-if="apiValidationErrors['name.en']" class="error mt-2 text-danger"
               for="name-en">{{ apiValidationErrors['name.en'][0] }}</label>
      </div>
      <div class="form-group col-sm-6"
           :class="apiValidationErrors['name.ru'] ? 'has-danger' : ''">
        <label for="name-ru">Название офера
          <info
            message="Название офера для пользователей с русской локализацией"/>
        </label>
        <input type="text" id="name-ru" placeholder="Введите названия на русском" v-model="name.ru"
               class="form-control"
               :class="apiValidationErrors['name.ru'] ? 'form-control-danger' : ''"
               @focus="clearError('name.ru')">

        <label v-if="apiValidationErrors['name.ru']" class="error mt-2 text-danger"
               for="name-ru">{{ apiValidationErrors['name.ru'][0] }}</label>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6"
           :class="apiValidationErrors['pwa.name'] ? 'has-danger' : ''">
        <label for="pwa-name">Назва PWA
          <info
            message="Назва PWA яка буде використовуватися для генерації PWA для веба"/>
        </label>
        <input type="text" id="pwa-name" placeholder="Заповніть назву PWA" v-model="pwa.name"
               class="form-control"
               :class="apiValidationErrors['pwa.name'] ? 'form-control-danger' : ''"
               @focus="clearError('pwa.name')">
        <label v-if="apiValidationErrors['pwa.name']" class="error mt-2 text-danger"
               for="pwa-name">{{ apiValidationErrors['pwa.name'][0] }}</label>
      </div>
      <div class="form-group col-sm-6">
        <label for="pwa-vertical">Вертикаль
          <info
            message="Вертикаль яка буде використовуватися для генерації PWA для веба"/>
        </label>
        <select id="pwa-vertical" name="pwa-vertical"
                class="form-control"
                v-model="pwa.vertical">
          <option v-for="item in $store.getters['verticals/verticals']" :key="item.id" :value="item.id">
            {{ $t(item.label) }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group"
         :class="apiValidationErrors['tracker.redirect'] ? 'has-danger' : ''">
      <label for="tracker-redirect">Редірект
        <info
          message="Типове посилання на вешу CPA на її основі буде проводитися валідація редіректів вебів"/>
      </label>
      <input type="text" id="tracker-redirect" placeholder="Заповніть редірект" v-model="tracker.redirect"
             class="form-control"
             :class="apiValidationErrors['tracker.redirect'] ? 'form-control-danger' : ''"
             @focus="clearError('tracker.redirect')">
      <label v-if="apiValidationErrors['tracker.redirect']" class="error mt-2 text-danger"
             for="tracker-redirect">{{ apiValidationErrors['tracker.redirect'][0] }}</label>
    </div>
    <select2
      class="form-group"
      inputClass="form-control select_white_g"
      id="tracker-template"
      name="tracker-template"
      v-model="tracker.template"
      :settings="trackerTemplateSettings"
      :options="templateAjaxOptions"
      :placeholder="$t('trackers.linkSettings.postbackTemplateSelect')"
    >
      <template v-slot:before>
        <label for="tracker-template">{{ $t('trackers.linkSettings.postbackTemplate') }} <info
          message="Оберіть шаблон постбеку вашої CPA. Якщо подібної немає в списку, зверніться до адміністрації сервісу"/></label>
      </template>
      <template v-slot:after>
        <label v-if="apiValidationErrors['tracker.template']" class="error mt-2 text-danger"
               for="tracker-template">{{ apiValidationErrors['tracker.template'][0] }}</label>
      </template>
    </select2>
    <div class="d-md-flex align-items-center mobile-grid-2 mt-4">
      <div class="mr-md-4 mb-4 mb-md-0">
        <button type="submit" class="ful_butt_green d_flex align_center justify_center mobile-full-w">{{ $t('general.save') }}
        </button>
      </div>
      <div class="mr-md-4 mb-4 mb-md-0">
        <button @click="handlerCancel" class="simple_butt_afg mobile-full-w">{{
            $t('general.cancel')
          }}
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import mainConnector from '@/connectors/main.connector'

export default {
  name: 'BotOfferForm',
  mixins: [formMixin, select2AjaxMixin],
  props: ['value', 'errors'],
  components: {Info, Select2},
  data () {
    return {
      enable: false,
      name: {
        uk: '',
        en: '',
        ru: '',
        pt: ''
      },
      pwa: {
        name: '',
        vertical: ''
      },
      tracker: {
        template: '',
        redirect: ''
      },
      templateAjaxOptions: []
    }
  },
  computed: {
    trackerTemplateSettings () {
      const settings = this.getSelect2AjaxSettings('trackers/templates', 'id')
      settings.allowClear = true
      return settings
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  },
  async created () {
    this.name = this.value.name
    this.enable = this.value.enable
    this.pwa.name = this.value.pwa.name
    this.pwa.vertical = this.value.pwa.vertical || this.$store.getters['verticals/verticals'][0].id
    this.tracker.redirect = this.value.tracker.redirect
    this.tracker.template = this.value.tracker.template
    if (this.tracker.template != null) {
      const template = await mainConnector.get(`trackers/templates/${this.tracker.template}`)
      this.templateAjaxOptions.push(template)
    }
  },
  methods: {
    async handlerSubmit () {
      this.$emit('submit', {
        enable: this.enable,
        name: this.name,
        pwa: this.pwa,
        tracker: this.tracker
      })
    },
    handlerCancel () {
      this.$router.push({name: 'BotOffers'})
    }
  }
}
</script>
