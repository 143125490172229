<template>
  <div class="modal fade modal_center_g modal_teamwork" id="modal_data_user">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal_padd_normal style_form_lab">
        <button type="button" data-dismiss="modal" class="close_sjgs_to d_flex align_center"><span
          aria-hidden="true">x</span>
        </button>
        <div class="title_mod_centr_g">{{ $t('teams.accessData') }}</div>
        <div class="form_user_str">
          <div class="item_fds_ggo">
            <div class="copy_blk_add d_flex flex_sub">
              <div class="flex_grow_1">
                <div class="sibmti_sdf">{{ $t('teams.link') }}</div>
                <input type="text" v-model="link" readonly class="inp_style_skcpg"
                       id="url_user_data">
              </div>
              <div class="flex_grow_0 right_butt_cop_sg">
                <button class="d_flex align_center justify_center button_copy_sd btn-clipboard"
                        data-clipboard-action="copy" data-clipboard-target="#url_user_data">
                    <span class="icon_copy_sdd">
                      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.625 7.57766C16.6168 7.50494 16.6008 7.43329 16.5775 7.36391V7.29266C16.5394 7.21126 16.4887 7.13644 16.4271 7.071L11.6771 2.321C11.6116 2.25942 11.5368 2.20864 11.4554 2.17058C11.4318 2.16722 11.4078 2.16722 11.3842 2.17058C11.3037 2.12446 11.2149 2.09485 11.1229 2.0835H7.91667C7.28678 2.0835 6.68269 2.33372 6.23729 2.77912C5.79189 3.22452 5.54167 3.82861 5.54167 4.4585V5.25016H4.75C4.12011 5.25016 3.51602 5.50039 3.07062 5.94578C2.62522 6.39118 2.375 6.99527 2.375 7.62516V15.5418C2.375 16.1717 2.62522 16.7758 3.07062 17.2212C3.51602 17.6666 4.12011 17.9168 4.75 17.9168H11.0833C11.7132 17.9168 12.3173 17.6666 12.7627 17.2212C13.2081 16.7758 13.4583 16.1717 13.4583 15.5418V14.7502H14.25C14.8799 14.7502 15.484 14.4999 15.9294 14.0545C16.3748 13.6091 16.625 13.0051 16.625 12.3752V7.62516C16.625 7.62516 16.625 7.62516 16.625 7.57766ZM11.875 4.78308L13.9254 6.8335H12.6667C12.4567 6.8335 12.2553 6.75009 12.1069 6.60162C11.9584 6.45316 11.875 6.25179 11.875 6.04183V4.78308ZM11.875 15.5418C11.875 15.7518 11.7916 15.9532 11.6431 16.1016C11.4947 16.2501 11.2933 16.3335 11.0833 16.3335H4.75C4.54004 16.3335 4.33867 16.2501 4.19021 16.1016C4.04174 15.9532 3.95833 15.7518 3.95833 15.5418V7.62516C3.95833 7.4152 4.04174 7.21384 4.19021 7.06537C4.33867 6.9169 4.54004 6.8335 4.75 6.8335H5.54167V12.3752C5.54167 13.0051 5.79189 13.6091 6.23729 14.0545C6.68269 14.4999 7.28678 14.7502 7.91667 14.7502H11.875V15.5418ZM15.0417 12.3752C15.0417 12.5851 14.9583 12.7865 14.8098 12.935C14.6613 13.0834 14.46 13.1668 14.25 13.1668H7.91667C7.7067 13.1668 7.50534 13.0834 7.35687 12.935C7.20841 12.7865 7.125 12.5851 7.125 12.3752V4.4585C7.125 4.24853 7.20841 4.04717 7.35687 3.8987C7.50534 3.75024 7.7067 3.66683 7.91667 3.66683H10.2917V6.04183C10.2917 6.67172 10.5419 7.27581 10.9873 7.72121C11.4327 8.16661 12.0368 8.41683 12.6667 8.41683H15.0417V12.3752Z"
                          fill="#21BF73"/>
                      </svg>
                    </span>
                  <span>{{ $t('general.copy') }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="item_fds_ggo">
            <div class="copy_blk_add d_flex flex_sub">
              <div class="flex_grow_1">
                <div class="sibmti_sdf">{{ $t('teams.password') }}</div>
                <input type="text" v-model="password" readonly class="inp_style_skcpg" id="password_data">
              </div>
              <div class="flex_grow_0 right_butt_cop_sg">
                <button class="d_flex align_center justify_center button_copy_sd btn-clipboard"
                        data-clipboard-action="copy" data-clipboard-target="#password_data">
                    <span class="icon_copy_sdd">
                      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M16.625 7.57766C16.6168 7.50494 16.6008 7.43329 16.5775 7.36391V7.29266C16.5394 7.21126 16.4887 7.13644 16.4271 7.071L11.6771 2.321C11.6116 2.25942 11.5368 2.20864 11.4554 2.17058C11.4318 2.16722 11.4078 2.16722 11.3842 2.17058C11.3037 2.12446 11.2149 2.09485 11.1229 2.0835H7.91667C7.28678 2.0835 6.68269 2.33372 6.23729 2.77912C5.79189 3.22452 5.54167 3.82861 5.54167 4.4585V5.25016H4.75C4.12011 5.25016 3.51602 5.50039 3.07062 5.94578C2.62522 6.39118 2.375 6.99527 2.375 7.62516V15.5418C2.375 16.1717 2.62522 16.7758 3.07062 17.2212C3.51602 17.6666 4.12011 17.9168 4.75 17.9168H11.0833C11.7132 17.9168 12.3173 17.6666 12.7627 17.2212C13.2081 16.7758 13.4583 16.1717 13.4583 15.5418V14.7502H14.25C14.8799 14.7502 15.484 14.4999 15.9294 14.0545C16.3748 13.6091 16.625 13.0051 16.625 12.3752V7.62516C16.625 7.62516 16.625 7.62516 16.625 7.57766ZM11.875 4.78308L13.9254 6.8335H12.6667C12.4567 6.8335 12.2553 6.75009 12.1069 6.60162C11.9584 6.45316 11.875 6.25179 11.875 6.04183V4.78308ZM11.875 15.5418C11.875 15.7518 11.7916 15.9532 11.6431 16.1016C11.4947 16.2501 11.2933 16.3335 11.0833 16.3335H4.75C4.54004 16.3335 4.33867 16.2501 4.19021 16.1016C4.04174 15.9532 3.95833 15.7518 3.95833 15.5418V7.62516C3.95833 7.4152 4.04174 7.21384 4.19021 7.06537C4.33867 6.9169 4.54004 6.8335 4.75 6.8335H5.54167V12.3752C5.54167 13.0051 5.79189 13.6091 6.23729 14.0545C6.68269 14.4999 7.28678 14.7502 7.91667 14.7502H11.875V15.5418ZM15.0417 12.3752C15.0417 12.5851 14.9583 12.7865 14.8098 12.935C14.6613 13.0834 14.46 13.1668 14.25 13.1668H7.91667C7.7067 13.1668 7.50534 13.0834 7.35687 12.935C7.20841 12.7865 7.125 12.5851 7.125 12.3752V4.4585C7.125 4.24853 7.20841 4.04717 7.35687 3.8987C7.50534 3.75024 7.7067 3.66683 7.91667 3.66683H10.2917V6.04183C10.2917 6.67172 10.5419 7.27581 10.9873 7.72121C11.4327 8.16661 12.0368 8.41683 12.6667 8.41683H15.0417V12.3752Z"
                          fill="#21BF73"/>
                      </svg>
                    </span>
                  <span>{{ $t('general.copy') }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="logir_ff_form">
            <button class="ful_butt_green d_flex align_center justify_center" data-dismiss="modal">{{ $t('general.cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AuthData',
  data: () => ({
    password: '',
    id: ''
  }),
  computed: {
    link: function () {
      return window.location.origin + '/auth/team/' + this.id
    }
  },
  methods: {
    show: function (payload) {
      this.password = payload.password
      this.id = payload._id
      // eslint-disable-next-line no-undef
      jQuery('#modal_data_user').modal('show')
    },
    hide: function () {
      // eslint-disable-next-line no-undef
      jQuery('#modal_data_user').modal('hide')
    }
  }
}
</script>
