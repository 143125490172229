<template>
  <div>
    <BaseFromGroup
      :label="$t('trackers.linkSettings.postbackTemplate')"
    >
      <BaseSelect
        class="item_fds_ggo select_multiple"
        v-model="template"
        :name="id"
        :id="id"
        :options="templatesOptions"
        :placeholder="$t('trackers.linkSettings.postbackTemplateSelect')"
      />
    </BaseFromGroup>
    <div class="form-group" v-for="(item, index) in items" :key="item.url">
      <label :for="`${id}-postback-${index}`">{{ item.label }}
        <info :message="item.info" v-if="item.info" class="icon_class mb-1"/>
      </label>
      <div class="d_flex">
        <div class="flex_grow_1">
          <input class="form-control" :id="`${id}-postback-${index}`" :name="`${id}-postback-${index}`" type="text"
                 v-model="item.url"
                 :placeholder="$t('trackers.linkSettings.postbackLink')" readonly="readonly">
        </div>
        <div class="flex_grow_0 ml_10">
          <button type="button"
                  class="copy_small_bhs_g ful_butt_green d_flex align_center justify_center btn-clipboard"
                  data-clipboard-action="copy"
                  :data-clipboard-target="`#${id}-postback-${index}`">
                  <span class="icon_left_g icon_copy_g">
                    <svg class="d_block" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg"><path
                      d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path
                      d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </span>
            {{ $t('general.copy') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import Select2 from '@/components/Select2.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'

export default {
  name: 'PostbackField',
  components: {BaseFromGroup, BaseSelect, Select2, Info},
  props: {
    value: {
      type: String,
      require: true
    },
    id: {
      type: String,
      default: 'template'
    }
  },
  computed: {
    templates () {
      return this.$store.getters['pwas/trackerPage'].templates || []
    },
    templatesSettings () {
      const format = state => {
        return state.text
      }
      return {
        templateResult: format,
        templateSelection: format,
        allowClear: true
      }
    },
    templatesOptions () {
      const templatesList = []
      if (this.templates) {
        for (const {_id, name} of this.templates) {
          templatesList.push({id: _id, text: name})
        }
      }
      return templatesList
    },
    template: {
      get () {
        return this.value
      },
      set (input) {
        this.$emit('input', input)
      }
    },
    items () {
      if (this.value) {
        const index = this.templates.findIndex(value => this.value === value._id)
        const postback = this.templates[index].postback
        const name = this.templates[index].name
        try {
          const postbacks = JSON.parse(postback)
          return [
            {
              url: postbacks[0].url,
              label: this.$t('trackers.linkSettings.postbackRegLink'),
              info: this.$t('trackers.linkSettings.postbackRegInfo', {name})
            },
            {
              url: postbacks[1].url,
              label: this.$t('trackers.linkSettings.postbackDepositLink'),
              info: this.$t('trackers.linkSettings.postbackDepositInfo', {name})
            }
          ]
        } catch (e) {
          return [{
            url: postback,
            label: this.$t('trackers.linkSettings.postbackLink'),
            info: this.$t('trackers.linkSettings.postbackInfo')
          }]
        }
      }
      return []
    }
  }
}
</script>
