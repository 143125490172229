<template>
  <div>
    <table class="table table-borderless hr" v-for="comment of data" :key="comment._id">
      <tbody>
      <tr>
        <th>Аватар</th>
        <td><img :src="comment.avatar | APIImage" alt="" v-if="comment.avatar"><span v-else class="text-gray">( нет )</span></td>
      </tr>
      <tr>
        <th>Автор</th>
        <td>{{ comment.author }}</td>
      </tr>
      <tr>
        <th>Рейтинг</th>
        <td>{{ comment.rating }}</td>
      </tr>
      <tr>
        <th>Лайки</th>
        <td>{{ comment.likes }}</td>
      </tr>
      <tr>
        <th>Дата</th>
        <td>{{ comment.date }}</td>
      </tr>
      <tr>
        <th>Комментарий</th>
        <td>{{ comment.comment }}</td>
      </tr>
      <tr>
        <th>Автор (Ответ)</th>
        <td>{{ comment.answer_author }}</td>
      </tr>
      <tr>
        <th>Дата (Ответ)</th>
        <td>{{ comment.answer_date }}</td>
      </tr>
      <tr>
        <th>Комментарий (Ответ)</th>
        <td>{{ comment.answer_comment }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'Comments',
  props: ['data']
}
</script>
<style scoped>
.hr + .hr {
  border-top: 1px solid #ffffff !important;
}
</style>
