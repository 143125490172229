import PWATemplates from './PWATemplates.vue'

export default (meta, header) => {
  return [
    {
      path: 'pwas/templates',
      name: 'PWATemplates',
      components: {default: PWATemplates, header: header},
      meta: meta
    }
  ]
}
