export default {
  form: {
    title: 'Transmissão de Eventos',
    subtitle: 'Eventos para Bater',
    info: 'Número máximo preliminar de eventos a serem enviados - 50 por cada evento',
    hint: 'A batida começará instantaneamente, mas os eventos serão batendo por 6-24 horas, às vezes mais',
    country: {
      placeholder: 'Selecione um país',
      title: 'Geo',
      errors: {
        required: 'É necessário selecionar um país para bater'
      }
    },
    accessToken: {
      errors: {
        required: `O Access Token está ausente para o pixel <strong>{pixel}</strong>. Configure todos os pixels antes de usar a API de Conversões`
      }
    },
    conversion: {
      title: 'Tipo de Conversão'
    },
    registrations: {
      title: 'Registro'
    },
    deposits: {
      title: 'Depósito'
    },
    success: {
      create: 'Eventos para bater foram colocados na fila com sucesso'
    }
  },
  conversions: {
    meta: 'Meta pixel',
    api: 'API Conversion'
  }
}
