<template>
  <div class="card-body">
    <h4 class="card-title">Локалі Tg офферів</h4>
    <p class="card-description">Керування локалями які доступні в офферах Tg ботів</p>
    <div class="mb-2">
      <bot-locale-form ref="botLocaleForm"/>
      <button
        :disabled="!filter.owner"
        @click="handlerCreate"
        type="button" class="btn btn-outline-primary btn-fw">
        <i class="icon-plus"></i> Додати локаль
      </button>
    </div>
    <div class="row">
      <owner-filter v-model="filter.owner" class="col-md-6 mb-2"/>
      <div class="col-md-6 mb-2">
        <select v-model="filter.region" class="form-control">
          <option value="">Фільтр за регіоном</option>
          <option value="europe">{{ $t('bot.europe') }}</option>
          <option value="australiaAndOceania">{{ $t('bot.australiaAndOceania') }}</option>
          <option value="asia">{{ $t('bot.asia') }}</option>
          <option value="latinAmerica">{{ $t('bot.latinAmerica') }}</option>
          <option value="america">{{ $t('bot.america') }}</option>
        </select>
      </div>
    </div>
    <div class="table-responsive">
      <table v-if="!loading" class="table table-bordered table-hover">
        <thead>
        <tr>
          <th>
            <button
              @click="fetchPage()"
              class="btn btn-icons btn-inverse-light">
              <i class="icon-refresh"></i>
            </button>
          </th>
          <th style="min-width: 220px;">Владелец</th>
          <th>Регіон</th>
          <th>Трекер</th>
          <th>Вайт</th>
          <th>Створено</th>
          <th>Оновлено</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="model in models" :key="model._id">
          <td>
            <button @click="handlerDelete(model)" class="btn btn-icons btn-inverse-danger">
              <i class="icon-trash"></i>
            </button>
          </td>
          <td>
            <table-owner :data="model.owner"/>
          </td>
          <td>{{ $t(`bot.${model.region}`) }}</td>
          <td>{{ getTrackerLabel(model.tracker) }}</td>
          <td>{{ getWhitePageLabel(model.whitePage) }}</td>
          <td>{{ model.createdAt | dateFromNow }}</td>
          <td>{{ model.updatedAt | dateFromNow }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="2">Всього: <span style="font-weight: bold;">{{ count }}</span></td>
        </tr>
        </tfoot>
      </table>
      <div v-else class="dot-opacity-loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav v-if="pageCount > 1">
        <paginate v-model="page"
                  :page-count="pageCount"
                  :click-handler="handlerPagination"
                  :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                  :next-text="'<i class=\'icon-arrow-right\'></i>'"
                  container-class="pagination mt-3"
                  page-class="page-item"
                  page-link-class="page-link"
                  prev-class="page-item"
                  next-class="page-item"
                  prev-link-class="page-link"
                  next-link-class="page-link"
                  active-class="active"/>
      </nav>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import Loading from '@/pages/admin/components/Loading.vue'
import {mapActions, mapGetters} from 'vuex'
import {OwnerFilter} from '../components'
import * as adminComponents from '@/pages/admin/components'
import BotLocaleForm from '@/components/admin/bot/BotLocaleForm.vue'
import {whitePageLanguages, trackers} from '@pwa.group/pwa.dictionary'

export default {
  name: 'AdminBotLocales',
  components: {OwnerFilter, Loading, TableOwner: adminComponents.tables.Owner, BotLocaleForm},
  data () {
    return {
      whitePageLanguages,
      trackers,
      loading: true,
      filter: {
        owner: '',
        region: ''
      },
      page: 1,
      limit: 20,
      sort: '-createdAt'
    }
  },
  computed: {
    ...mapGetters({
      models: 'bot/models',
      count: 'bot/count'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  created () {
    for (const prop in this.filter) {
      this.$watch(`filter.${prop}`, function () {
        this.handlerPagination()
      })
    }
    this.handlerPagination()
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      alertError: 'alerts/error',
      fetchData: 'bot/adminLocalesFetchData',
      delete: 'bot/adminDeleteLocale'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        page: this.page,
        limit: this.limit,
        sort: this.sort,
        filter: this.filter
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    async handlerDelete (model) {
      if (confirm('Видалити локаль?')) {
        try {
          await this.delete(model._id)
          await this.alertSuccess('Локаль успішно видалено')
          this.fetchPage()
        } catch (e) {
          await this.alertError(e.message)
        }
      }
    },
    async handlerCreate () {
      const ok = await this.$refs.botLocaleForm.handlerOpen(this.filter)
      if (ok) {
        await this.alertSuccess('Локаль успішно створена')
        this.handlerPagination()
      }
    },
    getTrackerLabel (input) {
      const tracker = this.trackers[input]
      let text = this.$t(tracker.country)
      text += ` (${this.$t(tracker.locale)})`
      return text
    },
    getWhitePageLabel (input) {
      const index = this.whitePageLanguages.findIndex(({id}) => id === input)
      return this.$t(this.whitePageLanguages[index].text)
    }
  }
}
</script>
