import UAParser from 'ua-parser-js'

export default input => {
  input = new UAParser(input)
  const OS = input.getOS()
  let result = ''
  if (OS.name) {
    result += OS.name
  }
  if (OS.version) {
    result += ' ' + OS.version
  }
  return result === '' ? 'Не понятно' : result
}
