import api from './api'
import { getSearchParams } from '../list'

export const getParams = ({filter}) => {
  const searchParams = new URLSearchParams()
  if (filter != null && Object.keys(filter).length) {
    for (const [prop, value] of Object.entries(filter)) {
      if (value != null && value !== '' && typeof value === 'string') {
        searchParams.append(`filter[${prop}]`, value)
      } else if (value != null && value.length !== 0) {
        searchParams.append(`filter[${prop}]`, value)
      }
    }
  }
  return decodeURI(searchParams.toString())
}

export default {
  list: (payload) => api.get('pwas/pushes?' + getSearchParams(payload)),
  create: payload => api.post('pwas/pushes', payload),
  read: id => api.get(`pwas/pushes/${id}`),
  update: ({id, ...payload}) => api.put(`pwas/pushes/${id}`, payload),
  delete: id => api.delete(`pwas/pushes/${id}`),
  copy: ({ id, payload }) => api.post(`pwas/pushes/${id}/copy`, payload),
  upload: ({ type, payload }) => api.file(`pwas/pushes/upload/${type}`, payload),
  removeImage: ({ name, type }) => api.patch(`pwas/pushes/upload/${type}`, { name }),
  segment: (payload) => api.get(`pwas/pushes/segment?` + getParams(payload).toString()),
  statistic: (payload) => api.get(`pwas/pushes/statistic`),
  filter: (payload) => api.get('pwas/pushes/filter?' + getSearchParams(payload)),
  groupCopy: (ids) => api.post('web-pushes/push-companies/copy', {ids}),
  groupRemove: (ids) => api.delete('web-pushes/push-companies', {ids})
}
