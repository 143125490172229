import _ from 'lodash'
import NavPaginate from '@/components/NavPaginate.vue'

export default {
  components: {NavPaginate},
  data () {
    return {
      loading: true,
      limit: 10,
      page: 1,
      inputPage: 1
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    user () {
      return this.$store.getters['identity']
    }
  },
  watch: {
    limit () {
      this.handlerPagination()
    },
    loading (n) {
      if (n === true) this.checkAll = false
    }
  },
  created () {
    this.handlerPagination()
  },
  methods: {
    debounceChangePage: _.debounce(function () {
      if (this.inputPage > this.pageCount || +this.inputPage < 1) {
        this.inputPage = 1
      }
      this.handlerPagination(+this.inputPage)
    }, 200),
    handlerPagination (page = 1) {
      this.page = +page
      this.inputPage = +page
      this.fetchPage()
    }
  }
}
