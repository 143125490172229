export default {
  navigation: 'Трекер',
  title: 'Трекер PWA #{id}',
  message: '',
  tracker: 'Трекер',
  trackerInfo: 'Виберіть кампанію з Трекера, яка буде працювати з даними PWA',
  trackerPlaceholder: 'Виберіть трекер',
  geo: 'Мульти-ГЕО',
  geoTooltip: 'Мульти-ГЕО працює для розподілу Вашого трафіку по ГЕО. Якщо ви використовуєте клоаку, цю функцію бажано включати, щоб уникнути втрати трафіку',
  clGeoRedirect: 'Вкажіть URL для редагування на свій вайт',
  validator: {
    redirect: {
      notEmpty: "Посилання для редиректу є обов'язковим"
    }
  }
}
