<template>
  <button
    class="DomainFormAddButton btn_round_act d_flex align_center justify_center"
    type="button"
    @click="$emit('click')"
  >
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 2V16"
        stroke="#21BF73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"/>
      <path
        d="M1.79102 9H15.2077"
        stroke="#21BF73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"/>
    </svg>
  </button>
</template>
<script>

export default {
  name: 'DomainFormAddButton'
}
</script>
