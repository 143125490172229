<template>
  <div class="PageTeams page-full-fluid-width parent_tooltip" v-if="accesses.teams.view">
    <confirm-modal ref="confirmModal" id="teams-confirm-modal"></confirm-modal>
    <div class="row">
      <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
        <div class="card">
          <div class="card-body pt-0">
            <TeamsTabs :groups="groups" />
            <div class="action-group-tab" v-if="accesses.teams.update">
              <button class="btn_green_g mobile-full-w" @click="$refs.modalForm.show()">{{ $t('teams.add') }}</button>
            </div>
            <div class="tab-content">
              <div class="wrapper-table no-hover">
                <div class="table-responsive">
                  <table class="table_team_work table_border_style table" v-if="!loading">
                    <table-row v-for="member of members" :key="member._id" :data="member"/>
                  </table>
                  <div class="loading_cont_go" v-else>
                    <div class="in_to_load_sf d_flex justify_center">
                      <img src="/images/loader.svg" alt="" width="100">
                    </div>
                  </div>
                  <nav v-if="pageCount > 1">
                    <paginate ref="paginate"
                              v-model="pageIndex"
                              :page-count="pageCount"
                              :click-handler="pagination"
                              :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                              :next-text="'<i class=\'icon-arrow-right\'></i>'"
                              :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                              :page-class="'page_item_gd'"
                              :page-link-class="'page_link_g d_flex justify_center align_center'"
                              :prev-class="'page-item_go'"
                              :next-class="'page-item_go'"
                              :prev-link-class="'page-link_go d_flex justify_center align_center'"
                              :next-link-class="'page-link_go d_flex justify_center align_center'"
                              :active-class="'active'">
                    </paginate>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-logs ref="modalLogs" v-if="!loading"/>
    <modal-auth-data ref="modalAuthData" v-if="!loading"/>
    <modal-form ref="modalForm" :groups="groups" v-if="!loading"/>
  </div>
</template>
<script>
import * as components from './components'
import accessesMixin from '@/mixins/accesses-mixin'
import titleMixin from '@/mixins/title-mixin'
import ConfirmModal from '@/components/modal/Confirm.vue'
import TeamsTabs from './components/TeamsTabs.vue'
// eslint-disable-next-line no-unused-vars,no-undef
const clipboard = new Clipboard('.btn-clipboard')

export default {
  name: 'Teams',
  title: function () {
    return this.$t('titles.teams')
  },
  mixins: [accessesMixin, titleMixin],
  components: {
    ConfirmModal,
    TeamsTabs,
    'table-row': components.table.Row,
    'modal-logs': components.modals.Logs,
    'modal-auth-data': components.modals.AuthData,
    'modal-form': components.modals.Form
  },
  watch: {
    '$route.params.group': {
      handler: async function () {
        await this.asyncData(true)
      }
    }
  },
  data: () => ({
    groups: [],
    loading: true,
    pageSize: 10,
    count: 0,
    members: [],
    pageIndex: 1
  }),
  computed: {
    pageCount: function () {
      return Math.ceil(this.count / this.pageSize)
    },
    user () {
      return this.$store.getters['identity']
    }
  },
  created: function () {
    if (!this.user.team) return this.$router.go(-1)
    this.asyncData()
  },
  methods: {
    pagination: async function (pageIndex) {
      this.pageIndex = pageIndex
      await this.asyncData()
    },
    asyncData: async function (force) {
      this.loading = true
      const options = {
        limit: force ? 10 : this.pageSize,
        page: force ? 1 : this.pageIndex,
        sort: '-createdAt'
      }
      const group = this.$route.params.group
      if (group) options['group'] = group
      await this.$store.dispatch('teams/index', options)
      this.members = this.$store.getters['teams/members']
      this.groups = this.$store.getters['teams/groups']
      this.count = this.$store.getters['teams/count']
      this.loading = false
    }
  }
}
</script>
<style lang="sass" scoped>
.action-group-tab
  margin-bottom: 20px
  padding-bottom: 30px
  display: flex
  justify-content: flex-end
  border-bottom: 1px solid rgba(255, 255, 255, .15)
</style>
