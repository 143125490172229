<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Рекламные ссылки</h4>
            <div class="mb-2">
              <div class="modal fade" id="ads-modal">
                <div class="modal-dialog mt-0">
                  <form class="modal-content" @submit.prevent="handler">
                    <div class="modal-header">
                      <h5 class="modal-title">Форма ссылок</h5>
                      <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="form-group" :class="apiValidationErrors.company ? 'has-danger' : ''">
                        <label for="company">Компания</label>
                        <input class="form-control" id="company" name="company" type="text" tabindex="1"
                               v-model="form.company"
                               :class="apiValidationErrors.company ? 'form-control-danger' : ''"
                               placeholder="Введите названия компании"
                               @focus="clearError('company')" :autofocus="form.id === null"
                               :readonly="form.id !== null">
                        <label v-if="apiValidationErrors.company" id="company-error" class="error mt-2 text-danger"
                               for="company">{{ apiValidationErrors.company[0] }}</label>
                      </div>
                      <div class="form-group" :class="apiValidationErrors.group ? 'has-danger' : ''">
                        <label for="group">Группа объявлений</label>
                        <input class="form-control" id="group" name="group" type="text" tabindex="2"
                               v-model="form.group"
                               :class="apiValidationErrors.group ? 'form-control-danger' : ''"
                               placeholder="Введите группу объявлений"
                               @focus="clearError('group')" :readonly="form.id !== null">
                        <label v-if="apiValidationErrors.group" id="group-error" class="error mt-2 text-danger"
                               for="group">{{ apiValidationErrors.group[0] }}</label>
                      </div>
                      <div class="form-group" :class="apiValidationErrors.ad ? 'has-danger' : ''">
                        <label for="ad">Объявления</label>
                        <input class="form-control" id="ad" name="ad" type="text" tabindex="3" v-model="form.ad"
                               :class="apiValidationErrors.ad ? 'form-control-danger' : ''"
                               placeholder="Введите названия объявления"
                               @focus="clearError('ad')" :readonly="form.id !== null">
                        <label v-if="apiValidationErrors.ad" id="ad-error" class="error mt-2 text-danger"
                               for="ad">{{ apiValidationErrors.ad[0] }}</label>
                      </div>
                      <div class="form-group" :class="apiValidationErrors.source ? 'has-danger' : ''">
                        <label for="source">Источник</label>
                        <input class="form-control" id="source" name="source" type="text" tabindex="4"
                               v-model="form.source"
                               :class="apiValidationErrors.source ? 'form-control-danger' : ''"
                               placeholder="Введите источник"
                               @focus="clearError('source')" :readonly="form.id !== null">
                        <label v-if="apiValidationErrors.source" id="source-error" class="error mt-2 text-danger"
                               for="source">{{ apiValidationErrors.source[0] }}</label>
                      </div>
                      <div class="form-group" :class="apiValidationErrors.redirect ? 'has-danger' : ''">
                        <label for="redirect">Редирект</label>
                        <input class="form-control" id="redirect" name="redirect" type="text" tabindex="5"
                               v-model="form.redirect"
                               :class="apiValidationErrors.redirect ? 'form-control-danger' : ''"
                               placeholder="Введите редирект"
                               @focus="clearError('redirect')">
                        <label v-if="apiValidationErrors.redirect" id="redirect-error" class="error mt-2 text-danger"
                               for="redirect">{{ apiValidationErrors.redirect[0] }}</label>
                      </div>
                      <div class="form-group" :class="apiValidationErrors.cost ? 'has-danger' : ''">
                        <label for="cost">Затраты</label>
                        <input class="form-control" id="cost" name="cost" type="text" tabindex="1" v-model="form.cost"
                               :class="apiValidationErrors.cost ? 'form-control-danger' : ''"
                               placeholder="Введите затраты"
                               @focus="clearError('cost')" :autofocus="form.id !== null">
                        <label v-if="apiValidationErrors.cost" id="cost-error" class="error mt-2 text-danger"
                               for="cost">{{ apiValidationErrors.cost[0] }}</label>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="submit" class="btn btn-success">Сохранить</button>
                      <a href="javascript:void(0);" class="btn btn-light" data-dismiss="modal">Отмена</a>
                    </div>
                  </form>
                </div>
              </div>
              <button type="button" class="btn btn-outline-success btn-fw" @click="openCreate"><i
                class="icon-plus"></i> Добавить
              </button>
            </div>
            <div class="row mb-2">
              <div class="col">
                <select class="form-control" v-model="filter.company">
                  <option value="" selected>Фильтр по компании</option>
                  <option :key="company" v-for="company in filterValues.companies" :value="company">{{
                      company
                    }}
                  </option>
                </select>
              </div>
              <div class="col">
                <select class="form-control" v-model="filter.group">
                  <option value="" selected>Фильтр по группе</option>
                  <option :key="group" v-for="group in filterValues.groups" :value="group">{{
                      group
                    }}
                  </option>
                </select>
              </div>
              <div class="col">
                <select class="form-control" v-model="filter.ad">
                  <option value="" selected>Фильтр по объявлению</option>
                  <option :key="ad" v-for="ad in filterValues.ads" :value="ad">{{
                      ad
                    }}
                  </option>
                </select>
              </div>
              <div class="col">
                <select class="form-control" v-model="filter.source">
                  <option value="" selected>Фильтр по источнику</option>
                  <option :key="source" v-for="source in filterValues.sources" :value="source">{{
                      source
                    }}
                  </option>
                </select>
              </div>
            </div>
            <loading v-model="loading" class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th rowspan="2" style="vertical-align : middle;text-align:center;"></th>
                  <th colspan="4" style="vertical-align : middle;text-align:center;">UTM</th>
                  <th colspan="5" style="vertical-align : middle;text-align:center;">Статистика</th>
                  <th rowspan="2" style="vertical-align : middle;text-align:center;">Затраты</th>
                  <th rowspan="2" style="vertical-align : middle;text-align:center;">Редик</th>
                  <th rowspan="2" style="vertical-align : middle;text-align:center;">Создана</th>
                  <th rowspan="2" style="vertical-align : middle;text-align:center;">Обновлена</th>
                </tr>
                <tr>
                  <th>Компания</th>
                  <th>Группа</th>
                  <th>Объявления</th>
                  <th>Источник</th>
                  <th>Хиты</th>
                  <th>Хосты</th>
                  <th>Реги</th>
                  <th>Депы</th>
                  <th>Сумма</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item of data" :key="item._id">
                  <td>
                    <button class="btn btn-icons btn-inverse-success btn-clipboard" type="button"
                            data-clipboard-action="copy" :data-clipboard-text="'https://kdjdm1d.pwa.group/' + item._id">
                      <span class="icon_left_g">
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path
                          d="M16.625 7.57742C16.6168 7.50469 16.6008 7.43304 16.5775 7.36367V7.29242C16.5394 7.21102 16.4887 7.1362 16.4271 7.07075V7.07075L11.6771 2.32075C11.6116 2.25917 11.5368 2.2084 11.4554 2.17034C11.4318 2.16698 11.4078 2.16698 11.3842 2.17034C11.3037 2.12421 11.2149 2.09461 11.1229 2.08325H7.91667C7.28678 2.08325 6.68269 2.33347 6.23729 2.77887C5.79189 3.22427 5.54167 3.82836 5.54167 4.45825V5.24992H4.75C4.12011 5.24992 3.51602 5.50014 3.07062 5.94554C2.62522 6.39094 2.375 6.99503 2.375 7.62492V15.5416C2.375 16.1715 2.62522 16.7756 3.07062 17.221C3.51602 17.6664 4.12011 17.9166 4.75 17.9166H11.0833C11.7132 17.9166 12.3173 17.6664 12.7627 17.221C13.2081 16.7756 13.4583 16.1715 13.4583 15.5416V14.7499H14.25C14.8799 14.7499 15.484 14.4997 15.9294 14.0543C16.3748 13.6089 16.625 13.0048 16.625 12.3749V7.62492C16.625 7.62492 16.625 7.62492 16.625 7.57742ZM11.875 4.78284L13.9254 6.83325H12.6667C12.4567 6.83325 12.2553 6.74984 12.1069 6.60138C11.9584 6.45291 11.875 6.25155 11.875 6.04159V4.78284ZM11.875 15.5416C11.875 15.7515 11.7916 15.9529 11.6431 16.1014C11.4947 16.2498 11.2933 16.3333 11.0833 16.3333H4.75C4.54004 16.3333 4.33867 16.2498 4.19021 16.1014C4.04174 15.9529 3.95833 15.7515 3.95833 15.5416V7.62492C3.95833 7.41496 4.04174 7.21359 4.19021 7.06513C4.33867 6.91666 4.54004 6.83325 4.75 6.83325H5.54167V12.3749C5.54167 13.0048 5.79189 13.6089 6.23729 14.0543C6.68269 14.4997 7.28678 14.7499 7.91667 14.7499H11.875V15.5416ZM15.0417 12.3749C15.0417 12.5849 14.9583 12.7862 14.8098 12.9347C14.6613 13.0832 14.46 13.1666 14.25 13.1666H7.91667C7.7067 13.1666 7.50534 13.0832 7.35687 12.9347C7.20841 12.7862 7.125 12.5849 7.125 12.3749V4.45825C7.125 4.24829 7.20841 4.04693 7.35687 3.89846C7.50534 3.74999 7.7067 3.66659 7.91667 3.66659H10.2917V6.04159C10.2917 6.67147 10.5419 7.27557 10.9873 7.72096C11.4327 8.16636 12.0368 8.41659 12.6667 8.41659H15.0417V12.3749Z"
                          fill="#21BF73"/></svg>
                      </span>
                    </button>
                    <button class="btn btn-icons btn-inverse-warning" @click="openUpdate(item)"><i
                      class="icon-pencil"></i></button>
                    <button @click="handlerDelete(item)" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                  </td>
                  <td>{{ item.utm.company }}</td>
                  <td>{{ item.utm.group }}</td>
                  <td>{{ item.utm.ad }}</td>
                  <td>{{ item.utm.source }}</td>
                  <td>{{ item.statistics.hits }}</td>
                  <td>{{ item.statistics.hosts }}</td>
                  <td>{{ item.statistics.registration }}</td>
                  <td>{{ item.statistics.deposits }}</td>
                  <td>{{ item.statistics.totals | amount }}</td>
                  <td>{{ item.cost | amount }}</td>
                  <td>{{ item.redirect }}</td>
                  <td>{{ item.createdAt | dateFromNow }}</td>
                  <td>{{ item.updatedAt | dateFromNow }}</td>
                </tr>
                </tbody>
              </table>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import Loading from '../components/Loading.vue'

export default {
  name: 'AdminDashboard',
  components: {Loading},
  mixins: [formMixin],
  data () {
    return {
      limit: 20,
      page: 1,
      filter: {
        company: '',
        group: '',
        ad: '',
        source: ''
      },
      form: {
        company: '',
        group: '',
        ad: '',
        source: '',
        redirect: '',
        id: null,
        cost: 0
      },
      loading: true
    }
  },
  watch: {
    filter: {
      handler () {
        this.fetchPage()
      },
      deep: true
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    ...mapGetters({
      sdk: 'adminConfig/sdk',
      data: 'adminAds/data',
      count: 'adminAds/count',
      filterValues: 'adminAds/filterValues'
    })
  },
  async created () {
    this.$socket.emit('getSDKConfig')
    const intervalId = setInterval(() => {
      if (this.sdk.url.length > 0) {
        clearInterval(intervalId)
        this.fetchPage()
      }
    }, 100)
  },
  methods: {
    ...mapActions({
      fetchData: 'adminAds/index',
      delete: 'adminAds/delete',
      alertError: 'alerts/error',
      alertSuccess: 'alerts/success'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      let filter = {}
      for (const [key, value] of Object.entries(this.filter)) {
        if (value && value !== '') {
          filter[key] = value
        }
      }
      filter = JSON.parse(JSON.stringify(filter))
      this.debounceFetchData({
        url: this.sdk.url,
        secret: this.sdk.secret,
        limit: this.limit,
        page: this.page,
        ...filter
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    openUpdate (item) {
      this.form = {
        company: item.utm.company,
        group: item.utm.group,
        ad: item.utm.ad,
        source: item.utm.source,
        redirect: item.redirect,
        id: item._id,
        cost: item.cost
      }
      // eslint-disable-next-line no-undef
      jQuery('#ads-modal').modal('show')
    },
    openCreate () {
      this.form = {
        company: '',
        group: '',
        ad: '',
        source: '',
        redirect: '',
        id: null,
        cost: 0
      }
      // eslint-disable-next-line no-undef
      jQuery('#ads-modal').modal('show')
    },
    async handler () {
      try {
        this.resetApiValidation()
        if (this.form.redirect) {
          if (this.form.redirect.split('?').length > 1) {
            const urlSearchParams = new URLSearchParams(this.form.redirect.split('?')[1])
            urlSearchParams.set('utm_uid', '{uid}')
            this.form.redirect = this.form.redirect.split('?')[0] + '?' + decodeURI(urlSearchParams.toString())
          } else {
            this.form.redirect += '?utm_uid={uid}'
          }
        }
        if (this.form.id) {
          await this.$store.dispatch('adminAds/update', {
            url: this.sdk.url,
            secret: this.sdk.secret,
            ...this.form
          })
          await this.$store.dispatch('alerts/success', 'Ссылка успешно обновлёна')
        } else {
          await this.$store.dispatch('adminAds/create', {
            url: this.sdk.url,
            secret: this.sdk.secret,
            ...this.form
          })
          await this.$store.dispatch('alerts/success', 'Ссылка успешно создана')
        }
        this.resetApiValidation()
        await this.fetchPage()
        // eslint-disable-next-line no-undef
        jQuery('#ads-modal').modal('hide')
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    async handlerDelete (model) {
      if (confirm('Видалити реламне посилання?')) {
        this.loading = true
        try {
          await this.delete({
            url: this.sdk.url,
            secret: this.sdk.secret,
            id: model._id
          })
          await this.alertSuccess(`Реламне посилання видалено`)
          await this.fetchPage()
        } catch (e) {
          this.loading = false
          await this.alertError(e.response.data.message)
        }
      }
    }
  }
}
</script>
