<template>
  <form class="FiltersSettingForm filters-setting-form" @submit.prevent="handler">
    <Loading v-if="loading" :value="loading" class="page-dashboard__loading" />
    <p v-else-if="error" class="error">
      <InlineSvg class="button-refresh-icon" src="/images/svg-icons/icon-warning-banner.svg"/>
      {{ error.msg }}</p>
    <div v-else class="filters-setting-container">
      <div class="selections_data_usr restyle_checkbox_go">
        <div class="selections_data_usr">
          <div class="title_this_sel_g">{{ $t('analytics.filtersSettingTitle') }}</div>
          <div class="item_data_sel_us">
            <div class="filters-grid">
              <div>
                <BaseSwitch
                  class="mb-3 mb-sm-4"
                  :label="$t('analytics.clicks.title')"
                  :value="allClicks"
                  @input="setAll($event, 'allClicks')"
                />
                <div class="blk_more_optionss">
                  <div v-if="checkView('id')" class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="clicks.id">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': clicks.id}">ID</span>
                    </label>
                  </div>
                  <div v-if="checkView('installs')" class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="clicks.installs">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': clicks.installs}">{{ $t('analytics.clicks.installs') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="clicks.pushes">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': clicks.pushes}">{{ $t('analytics.clicks.pushes') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="clicks.opens">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': clicks.opens}">{{ $t('analytics.clicks.opens') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="clicks.reopens">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': clicks.reopens}">{{ $t('analytics.clicks.reopens') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <BaseSwitch
                  class="checkbox_ios_style"
                  :label="$t('analytics.postbacks.title')"
                  :value="allPostback"
                  @input="setAll($event, 'allPostback')"
                />
                <div class="blk_more_optionss">
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="postback.registrations">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': postback.registrations}">{{ $t('analytics.postbacks.registrations') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="postback.deposits">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': postback.deposits}">{{ $t('analytics.postbacks.deposits') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <BaseSwitch
                  class="checkbox_ios_style"
                  :label="$t('analytics.users.title')"
                  :value="allUser"
                  @input="setAll($event, 'allUser')"
                />
                <div class="blk_more_optionss">
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="user.ip">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': user.ip}">IP</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="user.country">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': user.country}">{{ $t('analytics.users.country') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="user.userAgentDeviceType">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': user.userAgentDeviceType}">{{ $t('analytics.users.userAgentDeviceType') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="user.userAgentDeviceVendor">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': user.userAgentDeviceVendor}">{{ $t('analytics.users.userAgentDeviceVendor') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="user.userAgentDeviceModel">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': user.userAgentDeviceModel}">{{ $t('analytics.users.userAgentDeviceModel') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="user.userAgentOs">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': user.userAgentOs}">{{ $t('analytics.users.userAgentOs') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="user.userAgentBrowserName">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': user.userAgentBrowserName}">{{ $t('analytics.users.userAgentBrowserName') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <BaseSwitch
                  class="checkbox_ios_style"
                  :label="$t('analytics.trackers.title')"
                  :value="allTracker"
                  @input="setAll($event, 'allTracker')"
                />
                <div class="blk_more_optionss">
                  <!-- <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="tracker.tracker">
                      <span class="icon_checkb_inl"></span>
                      <span>{{ $t('analytics.trackers.tracker') }}</span>
                    </label>
                  </div> -->
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="tracker.referer">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': tracker.referer}">{{ $t('analytics.trackers.referer') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="tracker.redirect">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': tracker.redirect}">{{ $t('analytics.trackers.redirect') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="tracker.pwa">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': tracker.pwa}">PWA</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="tracker.tag">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': tracker.tag}">{{ $t('analytics.trackers.tag') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="tracker.host">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': tracker.host}">{{ $t('analytics.trackers.host') }}</span>
                    </label>
                  </div>
                  <div class="item_inline_checkbxx">
                    <label class="inline_checkbox_fff">
                      <input type="checkbox" v-model="tracker.vertical">
                      <span class="icon_checkb_inl"></span>
                      <span :class="{'active': tracker.vertical}">{{ $t('analytics.trackers.vertical') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="Object.keys(params).length">
                <div>
                  <BaseSwitch
                    class="checkbox_ios_style"
                    :label="$t('analytics.params.title')"
                    v-model="allParams"
                  />
                  <div v-if="paramsLoad">
                    <div class="blk_more_optionss" v-for="(value, param) of params" :key="param">
                      <div class="item_inline_checkbxx">
                        <label class="inline_checkbox_fff">
                          <input type="checkbox" :checked="value" @input="setParam(param, $event)">
                          <span class="icon_checkb_inl"></span>
                          <span :class="{'active': value}">{{ $t('analytics.params.param') + ' ' + param }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="Object.keys(landings).length">
                <div>
                  <BaseSwitch
                    class="checkbox_ios_style"
                    :label="$t('analytics.landings.title')"
                    :value="allLandings"
                    @input="setAll($event, 'allLandings')"
                  />
                  <div class="blk_more_optionss">
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="landings.preLandingPage">
                        <span class="icon_checkb_inl"></span>
                        <span :class="{'active': landings.preLandingPage}">{{ $t('analytics.landings.preLandingPage') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="landings.postLandingPage">
                        <span class="icon_checkb_inl"></span>
                        <span :class="{'active': landings.postLandingPage}">{{ $t('analytics.landings.postLandingPage') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="landings.preLandingPageUnique">
                        <span class="icon_checkb_inl"></span>
                        <span :class="{'active': landings.preLandingPageUnique}">{{ $t('analytics.landings.preLandingPageUnique') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="landings.postLandingPageUnique">
                        <span class="icon_checkb_inl"></span>
                        <span :class="{'active': landings.postLandingPageUnique}">{{ $t('analytics.landings.postLandingPageUnique') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="logir_ff_form d_flex justify_center">
        <button class="ful_butt_green d_flex align_center justify_center" type="submit">{{ $t('general.save') }}</button>
      </div>
    </div>
  </form>
</template>
<script>
import BaseSwitch from '@/components/base/BaseSwitch.vue'
import Loading from '@/components/Loading.vue'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'FiltersSettingForm',
  props: {
    value: Array
  },
  components: { BaseSwitch, Loading, InlineSvg },
  data () {
    return {
      error: null,
      clicks: {
        id: false,
        uniques: false,
        hits: false,
        installs: false,
        pushes: false,
        opens: false,
        reopens: false
      },
      postback: {
        registrations: false,
        deposits: false
      },
      user: {
        country: false,
        ip: false,
        userAgentBrowserName: false,
        userAgentOs: false,
        userAgentDeviceType: false,
        userAgentDeviceVendor: false,
        userAgentDeviceModel: false
      },
      tracker: {
        pwa: false,
        tag: false,
        tracker: false,
        host: false,
        referer: false,
        redirect: false,
        vertical: false
      },
      landings: {
        preLandingPage: false,
        postLandingPage: false,
        preLandingPageUnique: false,
        postLandingPageUnique: false
      },
      params: {},
      paramsLoad: true,
      allParams: false,
      loading: true
    }
  },
  async created () {
    this.removeNotAvailableFilters()
    try {
      for (const filter of this.availableFilters) {
        const parts = filter.alias.split('.')
        if (parts.length === 2) {
          this.setParam(parts[1], false)
        }
      }
      if (this.value != null && this.value.length > 0) {
        for (const filter of this.value) {
          const parts = filter.alias.split('.')
          for (const category of ['clicks', 'postback', 'user', 'tracker', 'params', 'landings']) {
            if (category === 'params') {
              if (this[category][parts[1]] != null) {
                this.setParam(parts[1], true)
              }
            } else {
              if (this[category][parts[0]] != null) {
                this[category][parts[0]] = true
              }
            }
          }
        }
      }
    } catch (error) {
      this.error = error.response?.data?.errors[0]
    }
    this.loading = false
  },
  computed: {
    availableFilters () {
      return this.$store.getters['analytics/filters']
    },
    allClicks: {
      get () {
        return Object.values(this.clicks).every((value) => value)
      },
      set (value) {
        for (let key in this.clicks) {
          if (this.clicks.hasOwnProperty(key)) {
            this.clicks[key] = value
          }
        }
      }
    },
    allPostback: {
      get () {
        return Object.values(this.postback).every((value) => value)
      },
      set (value) {
        for (let key in this.postback) {
          if (this.postback.hasOwnProperty(key)) {
            this.postback[key] = value
          }
        }
      }
    },
    allUser: {
      get () {
        return Object.values(this.user).every((value) => value)
      },
      set (value) {
        for (let key in this.user) {
          if (this.user.hasOwnProperty(key)) {
            this.user[key] = value
          }
        }
      }
    },
    allTracker: {
      get () {
        return Object.values(this.tracker).every((value) => value)
      },
      set (value) {
        for (let key in this.tracker) {
          if (this.tracker.hasOwnProperty(key)) {
            this.tracker[key] = value
          }
        }
      }
    },
    allLandings: {
      get () {
        return Object.values(this.landings).every((value) => value)
      },
      set (value) {
        for (let key in this.landings) {
          if (this.landings.hasOwnProperty(key)) {
            this.landings[key] = value
          }
        }
      }
    }
  },
  watch: {
    allParams (value) {
      if (this.paramsLoad) {
        Object.entries(this.params).forEach((prop) => {
          this.params[prop[0]] = value
        })
        this.paramsLoad = false
        this.$nextTick(() => {
          this.paramsLoad = true
        })
      }
    }
  },
  methods: {
    handler () {
      const filters = []
      for (const category of ['clicks', 'postback', 'user', 'tracker', 'params', 'landings']) {
        for (const [prop, value] of Object.entries(this[category])) {
          if (value) {
            const alias = category === 'params' ? `params.${prop}` : prop
            const index = this.availableFilters.findIndex(filter => filter.alias === alias)
            if (index !== -1) {
              filters.push(this.availableFilters[index])
            }
          }
        }
      }
      this.$emit('input', filters)
      this.$parent.$emit('input', false)
    },
    checkView (alias) {
      return this.availableFilters.findIndex(filter => filter.alias === alias) !== -1
    },
    setParam (param, event) {
      this.paramsLoad = false
      this.params[param] = typeof event === 'object' ? event.target.checked : event
      let allParams = true
      Object.entries(this.params).forEach((prop) => {
        if (this.checkView('params.' + prop[0]) && !prop[1]) {
          allParams = false
        }
      })
      this.allParams = allParams
      this.$nextTick(() => {
        this.paramsLoad = true
      })
    },
    removeNotAvailableFilters () {
      for (const category of ['clicks', 'postback', 'user', 'tracker', 'landings']) {
        for (const key of Object.keys(this[category])) {
          if (!this.checkView(key)) {
            delete this[category][key]
          }
        }
      }
    },
    setAll (value, category) {
      this[category] = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.sass";

.filters-setting-container {
  width: 100%;
}

.filters-setting-form {
  min-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707175;
  width: 100%;

  .error {
    color: $yellow;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .inline_checkbox_fff {
    color: #707175;
  }

  .item_inline_checkbxx {
    margin-bottom: 12px;
  }

  .active {
    color: #45B172;
  }

  .filters-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    width: 100%;

    @include media(sm) {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }

  &::v-deep {
    .base-switch {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #35383A;
      width: 100%;

      &__toggle {
        background-color: #35383A;
        border: none;
      }

      label {
        margin-bottom: 0;
        margin-left: 8px;
        color: #707175;
        font-size: 14px;
      }
    }
  }
}
</style>
