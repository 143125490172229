<template>
  <div class="image_updatd_icon">
    <div class="image_upd_pw_g">
      <div>
        <a href="/images/image_cloudflare_big.JPG" data-spzoom>
          <img src="/images/image_cloudflar.png" alt="">
        </a>
      </div>
    </div>
    <div class="blic_pwa_update">
      <div class="gold-blink gold-blink_medium gold-blink_1"></div>
    </div>
    <div class="smoke_pwa_update">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
           viewBox="0 0 270 340" class="ag-sherlock_smoke">
        <mask id="smokeBlick1" x="0" y="0" width="100%" height="100%">
          <image xlink:href="/images/smoke-1.png" width="168" height="238" x="58" y="92"></image>
        </mask>
        <mask id="smokeBlick2" x="0" y="0" width="100%" height="100%">
          <image xlink:href="/images/smoke-3.png" width="124" height="246" x="69" y="79"></image>
        </mask>
        <image id="blickSmoke1" mask="url(#smokeBlick1)" xlink:href="/images/smoke-2.png" width="143"
               height="397" x="83" y="112.564" style="opacity: 0.5;">
          <animate attributeName="y" attributeType="XML" from="332" to="-280" dur="12s" begin="0s"
                   repeatCount="indefinite"></animate>
        </image>
        <image id="blickSmoke2" mask="url(#smokeBlick2)" xlink:href="/images/smoke-4.png" width="169"
               height="287" x="69" y="19.2447" style="opacity: 0.5;">
          <animate attributeName="y" attributeType="XML" from="305" to="-180" dur="9s" begin="0s"
                   repeatCount="indefinite"></animate>
        </image>
      </svg>
    </div>
    <svg width="459" height="436" viewBox="0 0 459 436" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
      <g filter="url(#filter0_f)">
        <rect x="266" y="105" width="96" height="140" fill="#3EA76B" fill-opacity="0.21"/>
      </g>
      <g filter="url(#filter1_f)">
        <rect x="338" y="125" width="65" height="95" fill="#3EA76B" fill-opacity="0.21"/>
      </g>
      <g filter="url(#filter2_f)">
        <rect x="84" y="102" width="22" height="172" fill="#3EA76B" fill-opacity="0.21"/>
      </g>
      <g filter="url(#filter3_f)">
        <rect x="33" y="131" width="16" height="191" fill="#3EA76B" fill-opacity="0.21"/>
      </g>
      <path
        d="M208.21 261.6C208.864 261.6 209.573 261.6 210.391 261.654C217.042 261.927 223.748 262.254 230.399 262.581C234.488 262.744 238.85 263.017 242.285 265.306C245.774 267.649 247.628 271.682 249.209 275.552C257.387 296.153 260.222 318.389 263.002 340.352C263.493 344.058 262.13 347.274 261.966 351.034C261.912 352.124 261.857 353.323 261.312 354.25C260.222 356.266 257.605 356.811 255.315 357.193C246.919 358.392 238.523 359.645 230.181 360.844C229.255 360.953 228.219 361.117 227.455 360.572C226.965 360.19 226.638 359.645 226.365 359.046C218.078 341.824 210.827 324.111 204.666 306.017C200.795 294.681 201.831 282.037 202.431 270.265C202.813 263.235 202.485 261.654 208.21 261.6Z"
        fill="url(#paint0_linear)"/>
      <g opacity="0.22">
        <g opacity="0.22">
          <path opacity="0.22" d="M362.446 85.238H227.564V215.82H362.446V85.238Z" fill="#42AF71"/>
        </g>
      </g>
      <g opacity="0.22">
        <g opacity="0.22">
          <path opacity="0.22" d="M377.221 57.988H316.595V116.685H377.221V57.988Z" fill="#42AF71"/>
        </g>
      </g>
      <g opacity="0.22">
        <g opacity="0.22">
          <path opacity="0.22" d="M142.787 55.0449H124.032V215.765H142.787V55.0449Z" fill="#42AF71"/>
        </g>
      </g>
      <g opacity="0.22">
        <g opacity="0.22">
          <path opacity="0.22" d="M87.7765 123.824H69.0217V284.544H87.7765V123.824Z" fill="#42AF71"/>
        </g>
      </g>
      <path
        d="M176.153 166.388C176.262 166.715 176.262 167.097 176.371 167.424C176.589 167.86 177.079 168.078 177.461 168.35C178.988 169.495 178.879 171.784 178.606 173.691C178.442 174.781 178.115 176.089 177.025 176.307C176.425 176.471 175.771 176.144 175.226 175.871C172.554 174.454 169.774 173.201 166.939 172.111C164.976 171.402 162.85 170.585 161.923 168.677C161.814 168.459 161.705 168.187 161.759 167.914C161.868 167.369 162.414 167.097 162.904 166.879C164.485 166.17 165.903 165.135 167.102 163.881C168.084 162.846 169.229 161.32 170.81 161.32C172.609 161.265 172.445 162.9 173.208 164.099C173.972 165.135 175.716 165.189 176.153 166.388Z"
        fill="#EAB18B" stroke="#91533D" stroke-width="0.7976" stroke-miterlimit="10"/>
      <path
        d="M177.406 176.089C177.079 175.98 176.643 175.926 176.425 175.653C176.153 175.272 176.316 174.781 176.425 174.345C176.698 173.419 176.643 172.383 176.371 171.457C176.207 170.966 175.825 170.367 175.28 170.421C175.008 170.421 174.79 170.585 174.571 170.803C173.59 171.62 172.827 172.656 172.227 173.8C172.282 171.838 173.699 170.04 173.427 168.078C173.318 167.369 172.718 166.552 172.064 166.824C171.846 166.933 171.682 167.097 171.518 167.315C171.082 167.914 170.864 168.677 170.483 169.331C170.101 169.985 169.556 170.585 168.847 170.748C169.229 169.713 169.883 168.732 170.428 167.751C170.973 166.77 171.355 165.625 171.191 164.481C171.028 163.336 170.155 162.301 169.065 162.192V162.137C168.356 162.682 167.757 163.391 167.266 163.936C166.066 165.189 164.649 166.225 163.068 166.933C162.577 167.151 161.977 167.424 161.923 167.969C161.868 168.241 161.977 168.514 162.087 168.732C162.959 170.585 165.14 171.402 167.102 172.165C169.937 173.255 172.718 174.509 175.389 175.926C175.934 176.198 176.589 176.525 177.188 176.362C177.243 176.253 177.352 176.198 177.406 176.089C177.461 176.089 177.461 176.089 177.406 176.089Z"
        fill="#B06D4D"/>
      <path
        d="M172.937 163.882C172.828 164.699 172.501 165.462 172.173 166.171C171.901 166.77 171.628 167.37 171.356 168.024C171.083 168.623 170.81 169.223 170.429 169.713C170.102 170.149 169.666 170.476 169.284 170.858C168.902 171.239 168.575 171.73 168.575 172.275"
        fill="#EAB18B"/>
      <path
        d="M172.936 163.882C172.881 164.699 172.609 165.462 172.336 166.225C172.064 166.988 171.736 167.697 171.409 168.46C171.246 168.841 171.028 169.223 170.81 169.55C170.537 169.877 170.264 170.204 169.937 170.422C169.61 170.694 169.338 170.912 169.065 171.239C168.792 171.512 168.629 171.893 168.574 172.275C168.52 171.893 168.683 171.457 168.901 171.13C169.119 170.803 169.447 170.476 169.719 170.204C169.992 169.931 170.264 169.659 170.482 169.332C170.701 169.005 170.864 168.678 171.028 168.296C171.355 167.588 171.682 166.825 172.064 166.116C172.391 165.353 172.718 164.645 172.936 163.882Z"
        fill="#91533D"/>
      <path
        d="M176.371 167.969C176.207 168.623 175.716 169.059 175.389 169.549C175.062 170.04 174.735 170.585 174.408 171.13C173.808 172.166 173.209 173.31 172.282 174.237C172.881 173.147 173.372 172.057 174.026 170.912C174.353 170.367 174.681 169.822 175.117 169.386C175.498 168.786 176.044 168.514 176.371 167.969Z"
        fill="#91533D"/>
      <path
        d="M165.085 170.53C165.358 169.876 165.63 169.277 165.903 168.623C166.176 167.969 166.394 167.369 166.612 166.715C166.83 166.061 166.993 165.407 167.211 164.753C167.321 164.426 167.484 164.099 167.648 163.772C167.811 163.5 168.084 163.173 168.356 163.009C167.92 163.554 167.702 164.208 167.539 164.862C167.375 165.516 167.211 166.225 166.993 166.824C166.775 167.478 166.503 168.132 166.176 168.732C165.848 169.44 165.521 169.985 165.085 170.53Z"
        fill="#91533D"/>
      <path
        d="M173.808 154.29C174.135 154.235 174.462 154.126 174.844 154.072C175.335 153.963 181.823 153.254 181.768 153.527L181.386 159.467C177.734 159.631 174.244 159.304 170.973 158.541C170.537 158.432 169.774 157.941 169.719 157.505C169.665 156.851 170.919 156.197 171.246 155.707C171.627 155.216 171.736 154.835 172.391 154.671C172.827 154.508 173.318 154.399 173.808 154.29Z"
        fill="#585757" stroke="#2B2B2B" stroke-width="0.7565" stroke-miterlimit="10"/>
      <path
        d="M173.154 157.069C173.099 157.015 173.099 156.96 173.045 156.96C172.881 156.742 172.827 156.47 172.609 156.306C172.391 156.143 172.118 156.088 171.9 156.143C171.791 156.197 171.682 156.252 171.573 156.306C171.3 156.415 171.028 156.47 170.755 156.579C170.482 156.633 170.21 156.742 169.992 156.96C169.774 157.178 169.719 157.505 169.828 157.723C169.883 157.887 170.046 157.996 170.155 158.105C170.864 158.704 171.573 158.759 172.5 158.922C173.426 159.086 174.408 159.249 175.389 159.358C177.406 159.576 179.424 159.631 181.441 159.522L181.55 158.05C179.914 158.05 178.333 157.941 176.698 157.723C175.934 157.614 175.117 157.505 174.353 157.342C173.808 157.233 173.481 157.396 173.154 157.069Z"
        fill="#2B2B2B"/>
      <path
        d="M182.422 153.308C182.531 153.472 182.586 153.962 182.586 154.453C182.586 154.889 182.695 155.434 182.695 155.87C182.695 156.47 182.531 157.342 182.477 157.941C182.422 158.377 182.368 158.813 182.259 159.195C182.041 160.012 181.659 159.794 181.441 159.74C181.332 159.685 181.223 159.685 181.168 159.467C181.059 159.249 181.059 158.922 181.005 158.541C180.95 157.505 181.005 155.107 181.332 154.181C181.55 153.635 182.204 153.09 182.422 153.308Z"
        fill="#EF5E10" stroke="#EF5E10" stroke-width="0.4065" stroke-miterlimit="10"/>
      <path
        d="M182.15 154.726C182.204 154.835 182.259 155.107 182.259 155.38C182.259 155.652 182.313 155.925 182.313 156.197C182.313 156.524 182.204 157.015 182.204 157.342C182.204 157.56 182.15 157.832 182.095 158.05C181.986 158.541 181.768 158.377 181.659 158.377C181.604 158.377 181.55 158.323 181.495 158.214C181.441 158.105 181.441 157.887 181.441 157.723C181.386 157.124 181.441 155.816 181.604 155.271C181.659 154.889 182.041 154.617 182.15 154.726Z"
        fill="#EF5E10" stroke="#1D1D1B" stroke-width="0.2274" stroke-miterlimit="10"/>
      <path
        d="M162.795 166.77C164.158 167.315 165.521 167.642 166.721 166.443C168.738 164.372 169.883 160.993 170.864 158.322C172.118 154.998 173.154 151.51 174.026 148.076C174.354 146.768 174.899 144.643 174.245 143.389C174.081 143.117 173.863 142.844 173.754 142.572C173.372 141.809 173.536 140.882 173.917 140.065C174.299 139.302 174.79 138.593 175.117 137.776C175.935 135.759 175.226 133.47 174.517 131.454C172.663 134.288 169.174 135.269 165.903 136.195C155.435 139.084 144.15 137.939 133.627 140.828C132.101 141.209 130.629 141.754 129.32 142.626C128.012 143.498 126.976 144.806 126.703 146.332C126.485 147.531 126.758 148.73 126.976 149.929C127.576 152.763 128.23 155.597 129.593 158.104C129.811 158.54 130.084 158.976 130.52 159.249C130.956 159.576 131.447 159.685 131.937 159.794C137.28 161.047 142.623 162.246 147.966 163.5C151.892 164.372 155.817 165.298 159.797 165.734C160.724 165.843 161.76 166.388 162.795 166.77Z"
        fill="#EAB18B" stroke="#91533D" stroke-width="0.7976" stroke-miterlimit="10"/>
      <path
        d="M140.497 160.557C140.824 161.265 141.587 161.592 142.35 161.919C145.022 163.009 147.748 164.099 150.637 164.426C151.891 164.535 153.091 164.372 154.345 164.535C155.653 164.644 156.689 165.189 157.834 165.843C158.815 166.443 160.124 167.26 161.323 167.206C162.032 167.206 162.959 166.824 162.741 165.952C162.686 165.789 162.632 165.625 162.523 165.462C162.032 164.426 161.705 163.282 161.705 162.083C161.705 161.81 161.705 161.483 161.596 161.265C161.541 161.102 161.378 160.993 161.323 160.829C161.214 160.611 161.105 160.284 160.887 160.175C160.778 160.121 160.669 160.121 160.56 160.175C159.851 160.284 159.47 161.047 159.033 161.538C158.597 162.028 157.561 162.682 156.907 162.519C156.198 162.355 155.98 161.429 155.98 160.72C155.98 159.358 156.198 157.995 156.58 156.742C157.016 155.434 157.616 154.071 157.343 152.763C157.343 152.654 157.289 152.545 157.234 152.491C157.071 152.327 156.798 152.436 156.58 152.545C155.653 153.09 154.945 153.799 154.072 154.344C153.254 154.834 152.219 155.216 151.292 155.488C150.583 155.706 149.82 155.87 149.056 156.142C147.748 156.578 146.548 157.232 145.349 157.886C144.586 158.322 143.768 158.758 143.005 159.194C141.969 159.739 140.933 160.393 140.497 161.483"
        fill="#B06D4D"/>
      <path
        d="M166.285 159.576C166.285 160.884 166.339 162.192 166.339 163.445C166.339 163.99 166.339 164.535 166.503 165.026C166.666 165.516 166.884 166.007 166.612 166.443C166.448 166.661 166.176 166.77 165.903 166.824C165.358 166.988 164.813 167.097 164.267 167.042C162.523 166.824 162.032 165.081 162.032 163.609C162.087 162.247 161.432 160.993 161.16 159.63C160.942 158.595 160.887 157.559 160.887 156.524C160.833 154.616 160.778 152.709 160.724 150.747C160.724 150.365 160.724 149.929 160.942 149.602C161.16 149.275 161.65 149.112 161.923 149.33C162.087 149.493 162.141 149.711 162.196 149.929C162.359 150.801 162.414 151.728 162.632 152.6C162.85 153.472 163.341 154.344 164.049 154.834C165.194 154.126 165.794 152.654 165.63 151.346C165.412 149.766 164.158 148.567 164.595 146.823C164.649 146.605 164.758 146.387 164.922 146.278C165.085 146.169 165.412 146.223 165.467 146.441C165.521 146.605 165.412 146.823 165.576 146.877C165.63 146.877 165.685 146.877 165.739 146.823C166.176 146.605 166.448 146.114 166.557 145.678C166.666 145.188 166.612 144.697 166.612 144.207C166.557 143.716 166.939 143.553 167.43 143.389C167.92 143.226 168.465 143.444 168.738 143.88C168.902 144.207 168.902 144.643 168.847 145.024C168.465 149.33 166.503 153.472 166.339 157.777C166.285 158.486 166.285 159.031 166.285 159.576Z"
        fill="#D29872"/>
      <path
        d="M168.683 143.008C167.92 143.934 166.612 144.479 165.521 143.989C165.14 143.825 164.758 143.498 164.322 143.553C163.777 143.607 163.504 144.207 163.286 144.697C162.686 146.278 162.196 147.913 161.051 149.166C159.688 150.638 157.561 151.292 156.035 152.6C154.89 153.635 154.072 155.052 153.254 156.306C153.2 156.415 153.091 156.524 152.982 156.633C152.873 156.687 152.655 156.687 152.6 156.578C152.546 156.524 152.546 156.469 152.546 156.36C152.491 155.761 152.437 155.488 152.055 154.998C151.837 155.597 151.564 156.142 151.346 156.742C151.292 156.905 151.237 157.014 151.074 157.123C150.801 157.287 150.474 157.014 150.365 156.742C150.256 156.469 150.201 156.142 149.983 155.924C149.656 156.851 149.274 157.832 148.893 158.758C148.838 158.922 148.729 159.085 148.566 159.14C147.857 159.358 148.021 157.941 147.748 157.614C147.312 157.178 147.312 157.614 147.148 157.995C146.985 158.431 146.821 158.813 146.439 159.085C146.276 159.194 146.058 159.303 145.894 159.412C145.785 159.521 145.676 159.685 145.513 159.685C145.349 159.685 145.24 159.467 145.131 159.303C145.076 159.14 144.913 158.922 144.749 158.976C144.586 159.031 144.531 159.194 144.422 159.358C144.259 159.576 143.932 159.63 143.659 159.576C143.168 159.521 143.059 159.358 142.623 159.521C142.296 159.63 142.023 159.903 141.696 160.066C141.042 160.284 140.333 160.339 139.625 160.339C138.534 160.284 137.498 160.012 136.408 160.012C135.317 160.012 134.227 160.284 133.137 160.175C131.065 159.957 129.32 158.377 128.339 156.524C127.358 154.671 127.14 152.6 126.867 150.529C126.758 149.875 126.703 149.166 127.085 148.676C127.303 148.349 127.685 148.131 128.066 147.913C129.702 147.041 131.447 146.278 133.191 145.624C134.99 144.97 136.789 144.425 138.643 143.88C148.293 141.1 158.161 138.975 168.138 137.503C169.065 137.394 170.319 137.503 170.428 138.375C170.483 138.975 169.883 139.956 169.719 140.555C169.447 141.482 169.283 142.245 168.683 143.008Z"
        fill="#716C69" stroke="#3B3A38" stroke-width="0.822" stroke-miterlimit="10"/>
      <path
        d="M131.065 154.235C132.646 154.344 134.281 154.453 135.863 154.562C137.607 154.671 139.352 154.834 141.096 154.562C142.95 154.289 144.749 153.635 146.494 152.981C150.801 151.346 155.053 149.711 159.36 148.076C160.614 147.586 161.923 147.095 162.741 146.005C163.122 145.46 163.395 144.861 163.94 144.479C164.485 144.098 165.194 144.152 165.848 144.043C167.756 143.771 169.392 142.354 169.992 140.501C168.901 139.683 167.375 140.446 166.175 141.155C162.086 143.553 157.343 144.534 152.6 145.079C147.857 145.624 143.114 145.624 138.425 146.114C136.953 146.278 135.535 146.496 134.118 146.714C131.828 147.041 128.121 147.531 127.03 150.038C125.831 152.763 128.993 154.071 131.065 154.235Z"
        fill="#33312F"/>
      <path opacity="0.11" d="M299.476 262.853L127.303 237.129L192.672 218L389.215 244.105L299.476 262.853Z"
            fill="white"/>
      <path opacity="0.11"
            d="M299.476 269.666L127.303 243.887L192.672 224.812L389.215 250.863L299.476 269.666Z"
            fill="white"/>
      <path
        d="M340.584 250.591L317.903 252.008C317.903 252.008 306.727 250.809 306.4 250.809C306.073 250.809 282.847 244.269 282.847 244.269L304.056 237.402L340.147 247.811L340.584 250.591Z"
        fill="#202428" fill-opacity="0.58"/>
      <path d="M223.312 237.674L288.572 247.321L277.941 254.297L213.717 244.65L223.312 237.674Z"
            fill="url(#paint1_linear)"/>
      <path
        d="M201.395 239.255C201.232 238.383 201.341 237.456 201.722 236.693C202.213 235.603 203.194 234.84 203.794 233.859C204.176 233.26 204.285 232.606 204.666 232.006C205.211 231.08 205.866 230.262 206.465 229.39C206.684 229.063 206.902 228.736 207.229 228.518C207.719 228.191 208.374 228.137 208.973 228.191C209.573 228.246 210.173 228.409 210.772 228.464C212.135 228.573 213.062 227.701 214.371 228.028C214.916 228.191 215.407 228.409 215.843 228.682C216.333 229.009 216.497 229.445 216.824 229.935C217.424 230.753 218.242 230.589 219.168 230.698C220.204 230.807 221.24 231.025 222.058 231.625C222.494 231.952 222.876 232.388 223.312 232.715C224.348 233.478 225.765 233.696 226.474 234.731C227.074 235.549 227.074 236.748 226.747 237.729C226.42 238.71 225.765 239.582 225.22 240.399C224.839 240.999 224.457 241.544 224.075 242.143C223.585 242.797 223.094 243.451 222.549 244.105C222.167 244.596 221.731 245.086 221.349 245.631C220.531 246.612 219.768 247.593 218.95 248.574C218.46 249.174 218.024 249.719 217.424 250.155C215.788 251.354 213.553 251.299 211.917 252.444C211.209 250.645 210.173 248.956 208.919 247.539C208.755 247.593 208.537 247.43 208.428 247.266C207.065 245.086 204.23 244.214 203.031 241.98C202.758 241.489 202.595 240.89 202.213 240.454C201.94 240.018 201.504 239.745 201.395 239.255Z"
        fill="#EAB18B" stroke="#91533D" stroke-width="0.8167" stroke-miterlimit="10"/>
      <path
        d="M204.939 232.442C205.157 232.006 205.375 231.57 205.648 231.134C206.193 230.317 206.793 229.499 207.665 229.009C208.537 228.518 209.573 228.3 210.5 228.682C210.336 229.445 209.573 229.935 208.864 230.317C207.229 231.243 205.975 232.715 204.394 233.641C204.612 233.26 204.775 232.824 204.939 232.442Z"
        fill="#D29872"/>
      <path
        d="M217.424 230.426C218.351 230.699 219.332 231.026 220.041 231.625C220.477 232.007 220.859 232.497 221.295 232.933C222.712 234.241 224.784 234.95 224.457 237.348C224.348 238.22 223.912 239.037 223.421 239.8C221.84 242.307 219.823 244.651 217.151 246.013C216.879 246.177 216.606 246.286 216.279 246.286C215.407 246.34 214.861 245.468 214.371 244.76C213.88 243.997 213.28 243.343 212.681 242.689C211.917 241.926 210.881 241.272 209.791 241.326C209.573 241.326 209.355 241.381 209.246 241.544C209.137 241.708 209.137 241.926 209.191 242.089C209.409 243.724 209.573 245.305 209.791 246.94C209.846 247.267 209.9 247.648 209.955 247.975C210.064 248.357 210.282 248.738 210.5 249.12C210.881 249.774 211.263 250.428 211.645 251.082C211.754 251.245 211.863 251.409 212.026 251.518C212.244 251.627 212.463 251.627 212.681 251.627C213.444 251.572 214.207 251.354 214.861 250.973C215.625 250.537 216.224 249.937 216.824 249.338C218.95 247.212 221.131 245.087 223.257 242.961C223.694 242.58 224.075 242.144 224.293 241.599C224.402 241.272 224.457 240.945 224.675 240.672C225.002 240.182 225.602 239.964 225.983 239.473C226.256 239.037 226.529 238.383 226.747 237.893C226.91 237.566 227.019 237.239 227.019 236.912C227.019 236.53 226.801 236.203 226.583 235.876C225.384 234.078 223.803 232.334 221.731 231.789C221.349 231.68 220.913 231.625 220.531 231.462C219.877 231.135 219.277 230.481 218.623 230.808"
        fill="#D29872"/>
      <path
        d="M219.823 232.115C220.422 232.333 220.968 232.66 221.458 233.042C222.004 233.423 222.331 233.968 222.821 234.241C223.094 234.35 223.367 234.459 223.694 234.622C224.021 234.786 224.293 235.004 224.457 235.276C224.839 235.821 225.002 236.475 225.166 237.075C224.893 236.53 224.62 235.93 224.184 235.494C223.966 235.276 223.748 235.113 223.476 235.004C223.203 234.895 222.93 234.786 222.603 234.677C222.276 234.513 222.058 234.241 221.84 234.023C221.622 233.805 221.404 233.587 221.186 233.369C220.804 232.878 220.313 232.497 219.823 232.115Z"
        fill="#91533D"/>
      <path
        d="M212.299 241.707C212.899 242.361 213.444 243.015 213.989 243.724C214.534 244.432 215.08 245.141 215.407 246.013C215.788 246.83 216.006 247.702 216.17 248.574C216.334 249.446 216.388 250.318 216.388 251.19C216.224 250.318 216.061 249.446 215.843 248.629C215.625 247.811 215.352 246.939 214.971 246.176C214.698 245.359 214.153 244.65 213.717 243.942C213.28 243.233 212.79 242.47 212.299 241.707Z"
        fill="#91533D"/>
      <path
        d="M202.976 235.276C203.849 234.459 204.721 233.696 205.593 232.878C206.466 232.115 207.392 231.352 208.265 230.589C209.192 229.826 210.064 229.118 211.045 228.355C211.481 227.973 212.136 227.755 212.735 227.755C213.335 227.755 213.935 227.864 214.534 227.919C213.935 227.919 213.335 227.864 212.79 227.973C212.19 228.028 211.699 228.246 211.263 228.627C210.391 229.39 209.464 230.153 208.592 230.916C207.665 231.679 206.793 232.388 205.866 233.151C204.885 233.859 203.903 234.622 202.976 235.276Z"
        fill="#91533D"/>
      <path
        d="M215.461 230.644C215.134 230.644 214.807 230.753 214.534 230.971C214.262 231.134 214.044 231.407 213.771 231.625C213.28 232.115 212.79 232.606 212.299 233.096C211.808 233.587 211.372 234.077 210.882 234.568C210.391 235.058 209.955 235.603 209.464 236.094C209.791 235.494 210.173 234.895 210.609 234.35C211.045 233.805 211.481 233.26 211.972 232.769C212.463 232.279 212.953 231.788 213.499 231.352C213.771 231.134 214.044 230.916 214.371 230.753C214.753 230.644 215.134 230.535 215.461 230.644Z"
        fill="#91533D"/>
      <path
        d="M207.719 246.176C206.847 246.939 205.975 247.648 204.939 248.193C204.83 248.247 204.666 248.356 204.557 248.465C204.285 248.847 204.721 249.337 205.048 249.61C206.302 250.645 207.283 251.953 207.937 253.425C208.919 253.316 209.846 252.88 210.609 252.171C211.209 251.626 211.645 250.918 212.299 250.427C212.681 250.1 213.117 249.882 213.28 249.392C213.498 248.738 213.008 248.138 212.572 247.702C211.754 246.885 210.99 246.122 210.118 245.359C209.627 244.868 209.627 244.759 209.028 245.032C208.592 245.195 208.101 245.795 207.719 246.176Z"
        fill="#A2851E"/>
      <path
        d="M207.774 246.176C207.12 246.721 206.465 247.266 205.811 247.757C205.648 247.866 205.484 247.975 205.266 248.084C205.102 248.193 204.884 248.302 204.721 248.411C204.557 248.52 204.557 248.738 204.612 248.956C204.666 249.119 204.83 249.283 204.993 249.446C205.648 249.937 206.302 250.536 206.793 251.245C207.338 251.899 207.774 252.662 208.101 253.425L207.992 253.37C209.246 253.207 210.336 252.553 211.1 251.572C211.481 251.081 211.917 250.591 212.463 250.209C212.735 250.046 212.953 249.828 213.062 249.61C213.171 249.392 213.171 249.119 213.062 248.847C212.844 248.302 212.354 247.92 211.917 247.43C211.481 246.994 211.045 246.558 210.609 246.067L209.955 245.359C209.846 245.25 209.737 245.141 209.628 245.032C209.518 244.977 209.355 245.032 209.246 245.032C208.646 245.195 208.265 245.74 207.774 246.176ZM207.719 246.122C208.21 245.686 208.537 245.141 209.191 244.868C209.355 244.814 209.518 244.705 209.682 244.814C209.846 244.923 209.955 245.032 210.064 245.141L210.772 245.795C211.263 246.231 211.699 246.667 212.19 247.103C212.626 247.539 213.171 247.975 213.444 248.629C213.607 248.956 213.607 249.392 213.389 249.719C213.226 250.046 212.899 250.264 212.681 250.427C212.19 250.754 211.754 251.245 211.372 251.735C210.554 252.716 209.355 253.479 208.046 253.588C207.992 253.588 207.937 253.588 207.937 253.534C207.61 252.771 207.174 252.062 206.683 251.354C206.193 250.7 205.593 250.1 204.939 249.555C204.775 249.392 204.666 249.228 204.557 249.01C204.448 248.792 204.503 248.52 204.666 248.356C204.83 248.193 205.048 248.138 205.211 248.029C205.375 247.92 205.593 247.811 205.757 247.702C206.465 247.266 207.065 246.667 207.719 246.122Z"
        fill="#3B3A38"/>
      <path
        d="M208.755 246.067C207.883 246.83 207.01 247.539 205.974 248.084C205.865 248.138 205.702 248.247 205.593 248.356C205.32 248.738 205.756 249.228 206.083 249.501C207.228 250.427 207.992 251.572 208.755 252.825C209.464 254.079 211.045 253.37 212.244 253.098C212.789 252.989 213.389 252.825 213.662 252.335C213.825 252.062 213.825 251.735 213.825 251.463C213.771 250.809 213.607 250.209 213.444 249.555C213.171 248.738 212.898 247.92 212.571 247.103C212.244 246.285 211.863 245.849 211.208 245.25C210.718 244.759 210.718 244.65 210.118 244.923C209.627 245.141 209.191 245.74 208.755 246.067Z"
        fill="#F1C62C"/>
      <path
        d="M208.81 246.122C207.992 246.831 207.174 247.539 206.247 248.084C206.029 248.248 205.757 248.357 205.702 248.52C205.647 248.684 205.811 248.956 205.975 249.12C206.138 249.283 206.356 249.447 206.574 249.61C206.792 249.774 207.01 249.992 207.174 250.155C207.937 250.918 208.592 251.899 209.137 252.771C209.409 253.153 209.846 253.262 210.336 253.207C210.827 253.153 211.318 252.989 211.863 252.88C212.408 252.717 212.899 252.662 213.226 252.39C213.389 252.281 213.444 252.117 213.498 251.954C213.553 251.79 213.498 251.518 213.498 251.3C213.389 250.319 212.953 249.283 212.626 248.302C212.462 247.812 212.299 247.267 212.135 246.831C211.917 246.395 211.645 245.959 211.263 245.577C211.099 245.414 210.881 245.141 210.718 245.032C210.609 244.923 210.336 245.032 210.118 245.141C209.573 245.359 209.246 245.795 208.81 246.122ZM208.755 246.068C209.137 245.686 209.464 245.25 209.955 244.923C210.064 244.869 210.173 244.76 210.336 244.705C210.445 244.651 210.663 244.596 210.827 244.705C211.099 244.869 211.263 245.032 211.481 245.196C211.917 245.523 212.353 245.959 212.626 246.449C212.953 246.94 213.117 247.485 213.335 247.975L213.934 249.501C214.098 250.046 214.262 250.591 214.262 251.136C214.262 251.409 214.316 251.736 214.207 252.063C214.153 252.39 213.88 252.717 213.607 252.935C213.062 253.316 212.462 253.371 211.972 253.48C211.427 253.589 210.936 253.752 210.336 253.752C210.064 253.752 209.736 253.752 209.409 253.589C209.082 253.48 208.864 253.262 208.701 252.989C208.155 252.008 207.61 251.136 206.901 250.373C206.738 250.155 206.52 249.992 206.356 249.828C206.193 249.665 205.92 249.501 205.757 249.229C205.593 249.011 205.375 248.684 205.484 248.357C205.538 248.193 205.702 248.084 205.811 248.03C205.92 247.975 206.084 247.921 206.193 247.866C207.12 247.43 207.937 246.776 208.755 246.068Z"
        fill="#3B3A38"/>
      <path
        d="M197.033 244.542C197.197 244.051 197.306 243.615 197.633 243.234C197.96 242.798 198.505 242.525 198.996 242.253C199.868 241.871 200.741 241.544 201.613 241.272C201.777 241.217 201.94 241.163 202.104 241.163C202.322 241.163 202.54 241.272 202.703 241.326C203.303 241.599 203.903 241.817 204.503 242.089C205.32 242.416 206.193 242.798 206.792 243.452C206.847 243.506 206.902 243.615 206.902 243.67C206.902 243.724 206.847 243.779 206.847 243.833C205.866 245.196 204.23 248.03 202.322 246.831C201.613 246.395 201.122 245.741 200.359 245.359C200.087 245.196 197.142 244.16 197.033 244.542Z"
        fill="#A2851E"/>
      <path
        d="M197.033 244.542C197.197 244.051 197.306 243.561 197.633 243.179C197.96 242.798 198.396 242.525 198.887 242.307C199.814 241.871 200.741 241.49 201.722 241.217C201.94 241.108 202.267 241.108 202.485 241.217C202.758 241.272 202.976 241.381 203.194 241.49L204.612 242.035C205.102 242.198 205.539 242.416 205.975 242.688C206.193 242.797 206.411 242.961 206.629 243.125C206.738 243.179 206.847 243.288 206.902 243.397C206.956 243.452 207.011 243.506 207.065 243.561C207.12 243.615 207.12 243.779 207.065 243.833C206.465 244.705 205.975 245.523 205.211 246.286C204.83 246.667 204.448 246.994 203.903 247.158C203.412 247.376 202.758 247.321 202.322 246.994C201.395 246.449 200.85 245.577 199.868 245.305C199.378 245.141 198.942 244.978 198.451 244.814C198.233 244.76 197.96 244.651 197.742 244.596C197.633 244.542 197.524 244.542 197.361 244.542C197.306 244.487 197.142 244.433 197.033 244.542ZM197.033 244.542C197.142 244.433 197.306 244.433 197.415 244.433C197.524 244.433 197.688 244.433 197.797 244.487C198.069 244.542 198.287 244.596 198.56 244.651C199.051 244.76 199.541 244.923 200.032 245.087C201.068 245.359 201.722 246.286 202.54 246.722C202.922 246.94 203.412 246.994 203.848 246.831C204.285 246.667 204.666 246.34 204.993 246.013C205.702 245.305 206.247 244.487 206.847 243.67C206.847 243.615 206.847 243.56 206.738 243.506C206.629 243.397 206.574 243.342 206.465 243.233C206.302 243.07 206.084 242.961 205.866 242.798C205.429 242.525 204.993 242.361 204.503 242.143L203.14 241.544C202.649 241.381 202.213 241.054 201.777 241.272C200.795 241.544 199.868 241.871 198.942 242.307C198.505 242.525 198.015 242.743 197.742 243.125C197.361 243.561 197.197 244.051 197.033 244.542Z"
        fill="#3B3A38"/>
      <path
        d="M197.306 243.779C197.524 242.907 197.742 241.926 198.505 241.381C199.05 240.999 199.705 240.836 200.359 240.727C202.376 240.4 205.32 240.454 206.956 241.926C207.01 241.98 207.119 242.089 207.065 242.144C207.065 242.198 207.01 242.253 207.01 242.307C206.138 243.724 204.666 246.722 202.703 245.686C201.994 245.305 201.395 244.705 200.631 244.378C200.359 244.215 197.415 243.397 197.306 243.779Z"
        fill="#F1C62C"/>
      <path
        d="M197.251 243.778C197.361 243.288 197.47 242.797 197.688 242.307C197.906 241.816 198.233 241.38 198.669 241.108C199.596 240.563 200.632 240.454 201.613 240.29C202.649 240.181 203.63 240.236 204.666 240.454C205.157 240.563 205.702 240.726 206.138 240.944C206.356 241.053 206.629 241.217 206.847 241.38L207.174 241.653C207.229 241.707 207.338 241.762 207.392 241.925C207.447 242.089 207.392 242.307 207.338 242.416C206.847 243.288 206.356 244.214 205.702 245.032C205.375 245.413 204.939 245.849 204.394 246.122C203.848 246.394 203.085 246.394 202.594 246.122C201.559 245.577 201.013 244.759 200.086 244.541C199.596 244.378 199.16 244.214 198.669 244.105C198.451 244.051 198.178 243.942 197.96 243.887C197.851 243.833 197.742 243.833 197.579 243.833C197.47 243.833 197.306 243.833 197.251 243.778ZM197.251 243.778C197.306 243.669 197.361 243.669 197.415 243.615C197.47 243.615 197.524 243.56 197.633 243.56C197.742 243.56 197.906 243.56 198.015 243.56C198.287 243.56 198.505 243.615 198.778 243.615C199.269 243.669 199.759 243.778 200.305 243.887C200.414 243.942 200.577 243.942 200.686 243.996C200.85 244.051 200.959 244.105 201.068 244.16C201.34 244.269 201.559 244.432 201.777 244.541C202.213 244.814 202.594 245.141 202.976 245.25C203.685 245.577 204.448 245.141 205.048 244.432C205.702 243.724 206.193 242.852 206.738 242.034C206.738 241.98 206.738 242.089 206.738 242.143C206.738 242.198 206.738 242.143 206.738 242.143L206.465 241.925C206.302 241.762 206.084 241.653 205.866 241.544C205.429 241.326 204.993 241.162 204.557 241.053C203.63 240.835 202.649 240.726 201.668 240.781C200.686 240.835 199.65 240.89 198.833 241.38C198.396 241.598 198.069 241.98 197.851 242.416C197.633 242.852 197.47 243.342 197.361 243.833L197.251 243.778Z"
        fill="#3B3A38"/>
      <path
        d="M205.943 251.177C209.404 251.051 212.122 248.559 212.014 245.612C211.907 242.664 209.015 240.377 205.555 240.502C202.094 240.628 199.376 243.12 199.484 246.068C199.591 249.015 202.483 251.303 205.943 251.177Z"
        fill="#A2851E"/>
      <path
        d="M199.596 246.286C199.65 247.049 199.923 247.757 200.305 248.411C200.741 249.065 201.286 249.556 201.886 249.992C203.14 250.809 204.666 251.136 206.138 251.027C207.61 250.918 209.082 250.319 210.118 249.338C211.209 248.357 211.808 246.885 211.645 245.468C211.481 244.051 210.609 242.743 209.409 241.98C208.21 241.217 206.738 240.836 205.32 240.89C203.903 240.999 202.431 241.435 201.286 242.362C200.741 242.852 200.25 243.397 199.923 244.106C199.705 244.76 199.541 245.523 199.596 246.286ZM199.487 246.286C199.323 244.76 199.978 243.179 201.068 242.035C202.158 240.89 203.739 240.182 205.32 240.073C206.902 239.964 208.592 240.345 209.9 241.272C211.209 242.198 212.19 243.67 212.299 245.305C212.408 246.94 211.645 248.52 210.5 249.61C209.3 250.7 207.774 251.245 206.193 251.354C204.612 251.409 203.031 250.973 201.777 250.101C200.577 249.229 199.65 247.812 199.487 246.286Z"
        fill="#3B3A38"/>
      <path
        d="M206.04 249.982C209.465 249.744 212.09 247.384 211.904 244.712C211.718 242.04 208.791 240.066 205.367 240.304C201.943 240.542 199.317 242.902 199.503 245.574C199.689 248.246 202.616 250.22 206.04 249.982Z"
        fill="#F1C62C"/>
      <path
        d="M199.596 245.631C199.65 246.34 199.923 247.048 200.359 247.648C200.795 248.247 201.34 248.683 201.995 249.01C203.249 249.664 204.721 249.882 206.138 249.773C207.556 249.61 208.919 249.119 209.955 248.247C210.99 247.375 211.699 246.067 211.59 244.759C211.481 243.451 210.554 242.307 209.355 241.707C208.755 241.38 208.155 241.162 207.447 240.999C206.792 240.835 206.084 240.781 205.429 240.835C204.066 240.944 202.703 241.271 201.559 242.089C201.013 242.47 200.468 243.015 200.141 243.615C199.705 244.16 199.541 244.923 199.596 245.631ZM199.487 245.631C199.323 244.16 200.032 242.688 201.177 241.653C202.267 240.617 203.794 239.963 205.32 239.854C206.084 239.8 206.847 239.854 207.61 240.018C208.373 240.181 209.082 240.454 209.791 240.89C210.445 241.326 211.045 241.816 211.536 242.525C211.972 243.179 212.299 243.942 212.299 244.759C212.353 245.577 212.135 246.394 211.808 247.048C211.427 247.757 210.936 248.356 210.336 248.792C209.137 249.719 207.61 250.209 206.138 250.318C204.666 250.373 203.14 250.046 201.886 249.228C200.577 248.411 199.596 247.103 199.487 245.631Z"
        fill="#3B3A38"/>
      <g opacity="0.65">
        <path opacity="0.65"
              d="M205.953 248.732C208.477 248.556 210.41 246.807 210.272 244.826C210.135 242.844 207.977 241.379 205.454 241.555C202.931 241.73 200.997 243.479 201.135 245.461C201.273 247.442 203.43 248.907 205.953 248.732Z"
              fill="white"/>
        <path opacity="0.65"
              d="M201.123 245.523C201.232 246.613 201.94 247.539 202.867 248.084C203.794 248.629 204.885 248.793 205.975 248.738C207.011 248.629 208.047 248.248 208.864 247.594C209.682 246.94 210.227 245.904 210.118 244.869C210.064 243.833 209.355 242.907 208.428 242.416C207.501 241.871 206.466 241.653 205.375 241.708C204.339 241.762 203.249 242.144 202.431 242.798C201.668 243.397 201.068 244.433 201.123 245.523ZM201.123 245.523C201.014 244.433 201.613 243.397 202.431 242.689C203.249 241.98 204.339 241.599 205.43 241.49C206.52 241.381 207.611 241.599 208.592 242.144C209.519 242.689 210.336 243.67 210.391 244.814C210.5 245.959 209.846 247.049 208.973 247.703C208.101 248.411 207.011 248.738 205.92 248.793C204.83 248.847 203.74 248.629 202.813 248.084C201.94 247.539 201.232 246.613 201.123 245.523Z"
              fill="#3B3A38"/>
      </g>
      <g opacity="0.65">
        <path opacity="0.65"
              d="M205.626 249.004C208.149 248.829 210.083 247.08 209.945 245.098C209.807 243.116 207.65 241.652 205.127 241.827C202.604 242.003 200.67 243.751 200.808 245.733C200.946 247.715 203.103 249.179 205.626 249.004Z"
              fill="white"/>
        <path opacity="0.65"
              d="M200.795 245.795C200.904 246.885 201.613 247.812 202.54 248.357C203.467 248.902 204.557 249.065 205.648 249.011C206.683 248.902 207.719 248.52 208.537 247.866C209.355 247.212 209.9 246.177 209.791 245.141C209.736 244.106 209.028 243.179 208.101 242.689C207.174 242.144 206.138 241.926 205.048 241.98C204.012 242.035 202.922 242.416 202.104 243.07C201.286 243.615 200.686 244.705 200.795 245.795ZM200.795 245.795C200.686 244.705 201.286 243.67 202.104 242.961C202.922 242.253 204.012 241.871 205.102 241.762C206.193 241.653 207.283 241.871 208.264 242.416C209.191 242.961 210.009 243.942 210.064 245.087C210.173 246.231 209.518 247.321 208.646 247.975C207.774 248.684 206.683 249.011 205.593 249.065C204.503 249.12 203.412 248.902 202.485 248.357C201.559 247.812 200.85 246.885 200.795 245.795Z"
              fill="#3B3A38"/>
      </g>
      <path
        d="M205.672 245.687C206.003 245.671 206.261 245.44 206.248 245.169C206.235 244.898 205.957 244.692 205.626 244.707C205.295 244.723 205.037 244.955 205.05 245.225C205.062 245.496 205.341 245.703 205.672 245.687Z"
        fill="#191918"/>
      <path d="M205.429 244.978L201.504 244.923" stroke="#3B3A38" stroke-width="0.6715"
            stroke-miterlimit="10"
            stroke-dasharray="7.2 7.2"/>
      <path
        d="M202.267 244.324L201.613 244.923L202.213 245.523H201.668L201.068 244.869L201.722 244.269L202.267 244.324Z"
        fill="#3B3A38"/>
      <path d="M205.811 245.086L205.92 242.143" stroke="#3B3A38" stroke-width="0.4397"
            stroke-miterlimit="10"
            stroke-dasharray="5.41 5.41"/>
      <path
        d="M206.302 242.634L205.92 242.252L205.539 242.634V242.252L205.975 241.871L206.356 242.307L206.302 242.634Z"
        fill="#3B3A38"/>
      <path
        d="M205.734 245.524C206.064 245.501 206.319 245.287 206.302 245.047C206.285 244.807 206.004 244.631 205.673 244.654C205.343 244.677 205.089 244.89 205.105 245.13C205.122 245.37 205.404 245.547 205.734 245.524Z"
        fill="#3B3A38"/>
      <path d="M208.428 243.233L207.828 243.615" stroke="#3B3A38" stroke-width="0.6023"
            stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M202.758 242.906L203.358 243.451" stroke="#3B3A38" stroke-width="0.6023"
            stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M207.665 246.994L208.265 247.539" stroke="#3B3A38" stroke-width="0.6023"
            stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M202.758 247.103L202.158 247.539" stroke="#3B3A38" stroke-width="0.6023"
            stroke-miterlimit="10"
            stroke-linecap="round" stroke-linejoin="round"/>
      <path
        d="M200.359 249.337L200.904 248.629C201.286 249.174 201.831 249.555 202.485 249.882L201.995 250.7L200.359 249.392"
        fill="#A2851E"/>
      <path
        d="M200.359 249.337L200.904 248.629C201.286 249.174 201.831 249.555 202.485 249.882L201.995 250.7L200.359 249.392"
        stroke="#3B3A38" stroke-width="0.3011" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round"/>
      <path
        d="M201.9 250.793C202.173 250.513 202.045 249.945 201.614 249.525C201.183 249.105 200.611 248.992 200.338 249.272C200.065 249.552 200.193 250.12 200.624 250.54C201.056 250.96 201.627 251.074 201.9 250.793Z"
        fill="#F1C62C"/>
      <path
        d="M200.25 249.392C200.141 249.61 200.195 249.883 200.25 250.101C200.359 250.319 200.523 250.482 200.686 250.646C200.85 250.809 201.068 250.864 201.34 250.918C201.558 250.973 201.777 250.864 201.886 250.7C201.995 250.537 201.94 250.264 201.886 250.046C201.777 249.828 201.667 249.665 201.504 249.501C201.34 249.338 201.122 249.229 200.904 249.174C200.686 249.12 200.414 249.174 200.25 249.392ZM200.25 249.392C200.359 249.174 200.632 249.065 200.904 249.12C201.122 249.174 201.395 249.229 201.558 249.392C201.777 249.556 201.94 249.719 202.049 249.992C202.158 250.21 202.213 250.482 202.049 250.755C201.886 251.027 201.613 251.082 201.34 251.027C201.068 250.973 200.85 250.864 200.686 250.7C200.523 250.537 200.359 250.319 200.25 250.101C200.195 249.883 200.141 249.61 200.25 249.392Z"
        fill="#3B3A38"/>
      <path
        d="M160.124 165.353C160.233 165.407 160.396 165.462 160.506 165.516C161.269 165.843 162.032 166.17 162.795 166.497C162.904 166.552 162.959 166.606 163.068 166.661C163.122 166.77 163.122 166.879 163.122 167.042C163.013 169.277 163.777 171.402 164.54 173.473C161.051 172.002 157.561 170.857 154.072 169.277C151.401 168.078 148.511 167.533 145.676 166.933C142.296 166.279 138.807 165.898 135.372 165.516C132.319 165.189 129.429 164.099 126.322 163.99C126.976 163.99 128.121 159.194 128.284 158.649C133.736 158.976 139.134 159.794 144.368 161.265C149.602 162.737 155.108 163.336 160.124 165.353Z"
        fill="white" stroke="#3B3A38" stroke-width="1.0604" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round"/>
      <path
        d="M97.808 239.146C97.7535 239.146 97.7535 239.091 97.699 239.091C96.9902 238.655 96.8267 237.729 96.6631 236.966C93.1739 217.891 94.1552 203.884 97.9716 185.027C98.4077 182.956 100.752 176.144 101.733 174.291C104.459 169.222 111.438 167.26 116.781 166.061C127.521 163.663 137.662 163.391 148.348 165.135C159.143 166.879 169.665 171.838 179.26 176.198C182.313 177.561 185.475 179.032 187.329 181.43C188.583 182.956 189.128 184.809 189.673 186.608C191.963 194.728 193.163 203.012 193.163 211.351C193.163 220.071 193.708 227.864 196.434 236.312C196.979 238.056 197.851 239.963 199.923 240.617C200.523 240.781 201.232 240.944 201.831 240.835C202.431 240.781 202.54 240.181 203.358 240.617C204.884 241.435 206.356 242.361 207.719 243.451C209.519 244.868 210.227 247.048 210.391 249.283C210.5 250.863 210.391 252.444 210.227 254.024C210.227 254.242 210.173 254.515 210.009 254.678C209.846 254.842 209.573 254.896 209.355 254.951C207.338 255.441 205.375 256.422 203.358 256.967C200.632 257.73 197.797 258.166 195.125 259.311C190.873 261.109 187.111 264.706 182.313 264.216C180.46 264.052 178.824 263.235 177.298 262.417C173.536 260.346 170.155 257.73 167.375 254.787C147.53 253.534 130.356 243.015 110.784 240.127C107.513 239.691 100.752 240.999 97.808 239.146Z"
        fill="#3B3A38" stroke="#0B0B0B" stroke-opacity="0.46" stroke-width="1.5111" stroke-miterlimit="10"/>
      <path opacity="0.42"
            d="M124.523 166.77C125.831 166.77 127.14 166.879 128.394 167.315C128.721 167.424 129.048 167.533 129.32 167.805C129.811 168.296 129.702 169.113 129.375 169.713C128.993 170.312 128.448 170.694 127.903 171.13C124.032 174.182 120.434 180.667 120.325 185.627C120.216 189.224 122.396 193.039 126.431 194.674C130.465 196.254 135.59 195.491 139.407 197.453C141.533 198.543 142.95 200.342 143.932 202.195C149.166 212.114 143.877 223.777 147.366 234.132C149.874 241.598 156.58 247.648 163.068 253.37C150.692 248.302 137.88 244.378 124.85 241.544C118.307 240.127 111.711 238.982 105.059 238.11C103.424 237.892 100.698 238.383 99.2801 237.347C97.5355 236.039 98.1352 233.423 98.0262 231.407C97.6445 225.194 97.3719 218.981 97.2629 212.768C97.0448 200.505 98.7894 188.57 101.952 176.689C102.333 175.326 102.715 173.855 103.642 172.765C104.623 171.62 106.041 170.966 107.458 170.312C112.801 167.914 118.253 166.77 124.196 166.824C124.25 166.715 124.414 166.715 124.523 166.77Z"
            fill="#1F1F1E"/>
      <path opacity="0.42"
            d="M168.793 226.72C168.793 231.025 170.81 236.257 172.554 240.127C172.991 241.053 173.372 241.98 173.754 242.906C174.681 245.413 174.899 248.029 175.662 250.536C175.88 251.245 176.207 251.844 176.589 252.444C178.333 255.278 180.46 257.894 183.186 259.856C184.93 261.109 186.893 262.145 189.019 262.69C191.691 263.398 192.236 263.071 194.144 261.382C196.107 259.638 198.397 258.275 200.85 257.349C203.521 256.368 205.866 256.313 208.21 254.515C208.319 254.406 208.428 254.351 208.537 254.242C208.701 254.024 208.701 253.697 208.755 253.425C208.919 251.626 209.628 249.882 209.519 248.084C209.355 245.359 207.447 247.321 206.138 248.084C204.612 249.01 202.976 249.719 201.232 250.1C198.179 250.809 194.962 250.809 191.854 250.754C191.091 250.754 190.328 250.754 189.674 250.373C189.019 250.046 188.583 249.501 188.147 248.956C181.986 240.89 178.442 230.862 178.224 220.67C178.17 216.692 178.552 212.713 178.606 208.735C178.606 204.211 178.115 199.688 177.134 195.328C176.643 193.039 175.008 185.354 171.519 186.662C168.684 187.698 168.629 196.363 168.302 198.925C167.102 208.135 167.266 217.509 168.793 226.72Z"
            fill="#1F1F1E"/>
      <path
        d="M177.407 255.223C175.226 250.209 173.1 245.195 171.409 239.963C169.665 234.786 168.302 229.445 167.539 223.995C167.211 221.27 167.048 218.49 166.993 215.765C166.993 214.403 166.993 213.095 166.775 211.787C166.557 210.533 166.012 209.389 165.303 208.244C164.595 207.1 163.722 206.064 163.013 204.811C162.305 203.612 161.76 202.304 161.432 200.941C160.778 198.216 160.778 195.382 161.596 192.766C161.105 195.437 161.214 198.216 162.032 200.778C162.414 202.031 163.013 203.285 163.722 204.375C164.431 205.465 165.303 206.5 166.121 207.645C166.503 208.19 166.884 208.844 167.211 209.498C167.539 210.152 167.757 210.86 167.866 211.569C168.138 212.986 168.193 214.403 168.193 215.765C168.247 218.49 168.411 221.161 168.793 223.831C169.501 229.172 170.81 234.459 172.336 239.691C173.917 244.868 175.662 250.046 177.407 255.223Z"
        fill="#3B3A38"/>
      <path
        d="M172.609 182.084C171.355 184.155 170.701 186.499 170.21 188.788C169.937 189.932 169.719 191.131 169.501 192.33C169.283 193.529 169.065 194.674 168.847 195.873C167.975 200.614 167.266 205.356 166.503 210.152C166.557 207.754 166.666 205.301 166.939 202.903C167.211 200.505 167.539 198.107 167.975 195.709C168.411 193.311 168.902 190.968 169.556 188.624C170.156 186.335 171.082 183.992 172.609 182.084Z"
        fill="#3B3A38"/>
      <path
        d="M145.131 233.532C145.24 228.191 146.276 222.905 147.912 217.782C149.602 212.713 151.892 207.808 154.945 203.448C152.491 208.19 150.365 213.04 148.729 218.054C147.148 223.123 145.949 228.246 145.131 233.532Z"
        fill="#3B3A38"/>
      <path
        d="M196.979 239.309C197.906 239.909 198.724 240.563 199.596 241.217C200.414 241.871 201.232 242.579 201.995 243.397C202.758 244.214 203.412 245.086 203.958 246.067C204.448 247.048 204.83 248.138 204.83 249.283C204.285 248.302 203.739 247.484 203.085 246.667C202.431 245.849 201.777 245.086 201.068 244.323C200.359 243.56 199.596 242.797 198.887 241.98C198.178 241.162 197.524 240.29 196.979 239.309Z"
        fill="#3B3A38"/>
      <path
        d="M72.7835 250.591C73.8193 240.999 77.4176 234.187 85.323 233.151C90.5023 232.497 95.7907 233.097 100.97 233.751C114.818 235.44 128.775 237.13 142.023 241.163C148.784 243.179 155.272 245.85 161.487 248.847C164.922 250.482 168.247 252.335 171.573 254.079C174.081 255.442 175.825 257.622 178.279 259.148C178.878 259.529 179.478 259.856 180.078 260.183C181.659 261.055 183.24 261.927 184.821 262.854C185.203 263.072 185.639 263.29 186.075 263.453C188.528 264.216 191.582 262.363 193.762 261.491C197.252 260.129 200.686 258.766 204.176 257.349C205.866 256.695 207.992 256.041 209.518 256.968C210.336 257.458 210.772 258.33 211.1 259.148C215.952 270.157 218.405 284.381 217.696 296.371C216.661 314.193 204.884 317.735 189.237 322.967C171.791 328.799 153.418 331.687 134.99 331.524C128.993 331.469 85.9227 329.834 84.5597 323.076C82.3244 311.522 70.3301 273.372 72.7835 250.591Z"
        fill="url(#paint2_linear)"/>
      <path
        d="M156.254 147.534C173.865 140.168 184.137 124.63 179.197 112.829C174.257 101.028 155.977 97.4332 138.367 104.799C120.756 112.165 110.484 127.703 115.424 139.504C120.364 151.305 138.644 154.9 156.254 147.534Z"
        fill="#3B3A38" stroke="#242424" stroke-width="1.6994" stroke-miterlimit="10"/>
      <path
        d="M126.158 133.252L118.743 118.646C116.617 114.45 118.307 109.272 122.505 107.092L149.492 93.3584C153.69 91.2329 158.87 92.9224 161.051 97.1189L168.465 111.725C177.297 127.094 134.827 149.221 126.158 133.252Z"
        fill="#3B3A38" stroke="#262626" stroke-width="1.5111" stroke-miterlimit="10"/>
      <path
        d="M132.21 117.774C132.809 117.883 133.409 117.883 134.009 117.829C134.609 117.774 135.208 117.665 135.808 117.556C137.007 117.338 138.207 117.12 139.352 116.793C141.696 116.194 143.986 115.376 146.112 114.341C148.293 113.305 150.31 111.997 152.109 110.417C153.908 108.836 155.38 106.874 156.362 104.64C155.708 106.983 154.29 109.163 152.545 110.853C150.801 112.597 148.675 113.959 146.439 115.049C144.204 116.085 141.86 116.902 139.461 117.393C138.261 117.665 137.062 117.829 135.808 117.938C135.208 117.992 134.609 117.992 133.954 117.992C133.409 118.047 132.755 117.992 132.21 117.774Z"
        fill="#0B0B0B"/>
      <path
        d="M163.831 165.952C163.613 166.17 163.286 166.225 163.013 166.116C162.741 166.007 162.523 165.789 162.359 165.516C162.032 164.971 161.868 164.426 161.759 163.881C161.541 162.682 161.759 161.538 161.65 160.393C161.65 159.249 161.432 158.159 161.16 157.014C160.887 155.924 160.451 154.834 159.96 153.799C160.614 154.725 161.105 155.815 161.487 156.905C161.868 157.995 162.032 159.194 162.086 160.339C162.141 161.538 161.923 162.682 162.086 163.772C162.141 164.317 162.305 164.862 162.523 165.407C162.632 165.625 162.795 165.898 163.068 166.007C163.231 166.116 163.558 166.116 163.831 165.952Z"
        fill="#91533D"/>
      <path
        d="M161.868 148.458C162.196 149.875 162.468 151.346 162.741 152.818C162.795 153.199 162.85 153.526 162.959 153.908C163.068 154.235 163.231 154.562 163.449 154.725C163.94 155.161 164.703 154.78 165.085 154.235C165.412 153.69 165.412 152.927 165.412 152.218C165.412 151.837 165.412 151.455 165.412 151.128C165.412 150.801 165.303 150.474 165.14 150.147C164.812 149.493 164.54 148.73 164.485 147.967C164.431 147.586 164.431 147.204 164.485 146.823C164.485 146.659 164.54 146.387 164.649 146.223C164.703 146.114 164.758 146.005 164.867 145.951C164.976 145.896 165.14 145.842 165.303 145.896L165.412 145.951V146.06C165.412 146.605 165.685 147.204 166.121 147.586L165.957 147.64C166.557 146.659 166.721 145.46 166.448 144.316C166.83 145.406 166.775 146.714 166.175 147.749L166.121 147.858L166.012 147.749C165.521 147.368 165.194 146.714 165.14 146.06L165.249 146.223C165.194 146.169 165.085 146.278 165.031 146.387C164.976 146.55 164.922 146.659 164.922 146.877C164.922 147.259 164.922 147.586 164.976 147.967C165.085 148.676 165.303 149.33 165.63 149.984C165.794 150.311 165.957 150.747 165.957 151.128C165.957 151.51 165.957 151.891 165.957 152.218C165.957 152.927 165.957 153.744 165.521 154.453C165.303 154.78 164.976 155.052 164.594 155.216C164.213 155.379 163.722 155.325 163.395 155.052C163.068 154.78 162.904 154.398 162.795 154.017C162.686 153.635 162.686 153.254 162.632 152.927C162.25 151.401 162.032 149.929 161.868 148.458Z"
        fill="#91533D"/>
      <path
        d="M173.699 139.302C173.863 139.411 173.972 139.574 174.135 139.683C174.353 139.792 174.572 139.683 174.79 139.629C175.062 139.52 175.335 139.411 175.607 139.302C175.662 139.302 175.716 139.247 175.771 139.193C175.825 139.138 175.825 139.029 175.825 138.92C176.044 137.721 176.589 136.577 176.425 135.378C175.335 136.522 172.009 136.958 172.282 139.738C172.663 139.356 173.263 138.866 173.699 139.302Z"
        fill="#33312F"/>
      <path
        d="M266.601 226.611V216.801C266.601 216.801 255.86 216.637 255.642 216.419V224.921C255.424 228.082 250.844 229.772 247.519 231.897L242.503 236.312L268.781 239.854L269.218 234.459C267.745 231.57 266.492 229.336 266.601 226.611Z"
        fill="url(#paint3_linear)"/>
      <path
        d="M208.973 132.871L304.219 128.456C307.108 128.347 309.507 130.636 309.507 133.47V216.91C309.507 219.907 306.945 222.196 303.946 221.924L210.118 212.277C207.61 212.005 205.648 209.934 205.593 207.372L204.176 137.939C204.121 135.269 206.247 133.034 208.973 132.871Z"
        fill="url(#paint4_linear)"/>
      <path
        d="M309.507 209.716V133.525C309.507 130.636 307.108 128.347 304.219 128.511L208.973 132.925C206.247 133.034 204.121 135.323 204.176 138.048L205.484 202.086L309.507 209.716Z"
        fill="#0B0B0B"/>
      <path
        d="M79.8167 255.441C79.8167 252.008 80.0347 248.629 80.8525 245.468C84.0692 232.551 100.643 238.71 109.911 239.636C123.814 241.053 137.498 244.051 150.692 248.629C161.923 252.553 170.919 260.183 179.369 268.303C160.996 265.088 144.204 259.311 125.231 260.673C111.983 261.6 112.365 293.973 113.074 303.837C113.673 311.467 115.2 319.043 116.781 326.509C114.218 325.583 111.493 325.365 108.767 325.147C104.296 324.765 99.8253 324.384 95.3002 324.002C94.3734 323.948 93.3375 323.839 92.6287 323.185C92.0835 322.749 91.8109 322.04 91.5384 321.386C83.1969 300.731 79.9257 277.568 79.8167 255.441Z"
        fill="url(#paint5_linear)"/>
      <path
        d="M341.02 250.482L309.507 241.489C309.453 241.489 310.271 237.783 310.325 237.783L321.611 237.347L353.886 245.086L341.02 250.482Z"
        fill="#338C5A"/>
      <path
        d="M339.82 245.522L310.38 237.838C310.325 237.838 310.325 237.783 310.38 237.729L321.72 234.023L351.923 240.672L339.82 245.522Z"
        fill="#42AF71"/>
      <path d="M339.875 244.978L311.47 237.675L321.611 234.405L350.451 240.781L339.875 244.978Z"
            fill="#202428"/>
      <path
        d="M342.546 240.563C341.456 240.291 340.856 239.909 340.802 239.528L326.136 236.203C324.882 236.312 323.573 236.258 322.647 236.04L319.866 235.385L314.905 237.02L317.631 237.729C318.558 237.947 318.939 238.329 318.83 238.656L333.441 242.362C334.75 242.307 336.113 242.362 337.149 242.634L341.51 243.724L346.962 241.598L342.546 240.563Z"
        fill="#42AF71"/>
      <path
        d="M333.714 240.072C331.26 240.944 327.553 241.271 325.427 240.726C323.355 240.181 323.682 239.091 326.081 238.274C328.425 237.456 331.969 237.184 334.096 237.674C336.222 238.11 336.113 239.2 333.714 240.072Z"
        fill="#202428"/>
      <path
        d="M323.301 237.783C322.374 238.11 320.902 238.165 319.975 237.947C319.103 237.729 319.103 237.293 320.029 237.02C320.956 236.693 322.374 236.639 323.301 236.857C324.227 237.075 324.227 237.511 323.301 237.783Z"
        fill="#202428"/>
      <path
        d="M339.711 241.707C338.784 242.089 337.094 242.143 336.004 241.871C334.913 241.598 334.859 241.108 335.786 240.781C336.712 240.454 338.348 240.345 339.384 240.617C340.474 240.89 340.638 241.38 339.711 241.707Z"
        fill="#202428"/>
      <path d="M339.82 245.522L351.923 240.672L353.886 245.086L341.02 250.482L339.82 245.522Z"
            fill="#1C6C40"/>
      <path d="M318.012 239.855L324.118 241.435L336.004 237.184L329.734 235.822L318.012 239.855Z"
            fill="white"/>
      <path d="M318.012 239.855L318.83 240.073L330.606 235.985L329.734 235.822L318.012 239.855Z"
            fill="#F1C62C"/>
      <path d="M323.246 241.217L324.173 241.435L336.004 237.184L335.131 236.966L323.246 241.217Z"
            fill="#F1C62C"/>
      <path d="M318.012 239.854L317.631 243.833L324.228 245.74L324.118 241.435L318.012 239.854Z"
            fill="#CACACA"/>
      <path d="M318.012 239.854L317.631 243.833L318.557 244.105L318.83 240.018L318.012 239.854Z"
            fill="#B0901F"/>
      <path d="M323.301 241.217L323.246 245.414L324.227 245.741L324.173 241.435L323.301 241.217Z"
            fill="#B0901F"/>
      <path
        d="M327.172 243.942L340.256 247.375H340.202L341.02 247.048L341.837 246.721L343.473 246.122C344.018 245.904 344.563 245.74 345.109 245.522C345.654 245.304 346.199 245.141 346.69 244.923C346.199 245.141 345.654 245.359 345.109 245.577C344.563 245.795 344.073 246.013 343.527 246.231L341.892 246.885L341.074 247.212L340.256 247.539H340.202H340.147L327.172 243.942Z"
        fill="#202428"/>
      <path d="M309.616 240.89L313.542 241.98L309.507 240.944L309.616 240.89Z" fill="#202428"/>
      <path
        d="M317.958 252.008L286.936 243.07C286.882 243.07 287.699 239.364 287.754 239.364L298.876 238.928L330.606 246.667L317.958 252.008Z"
        fill="#338C5A"/>
      <path
        d="M316.758 247.048L287.808 239.364C287.754 239.364 287.754 239.309 287.808 239.255L298.93 235.549L328.644 242.198L316.758 247.048Z"
        fill="#42AF71"/>
      <path d="M316.813 246.558L288.899 239.255L298.821 235.93L327.226 242.361L316.813 246.558Z"
            fill="#202428"/>
      <path
        d="M319.43 242.089C318.339 241.816 317.794 241.435 317.74 241.053L303.292 237.729C302.038 237.838 300.784 237.783 299.857 237.565L297.131 236.911L292.225 238.546L294.951 239.255C295.823 239.473 296.205 239.854 296.095 240.181L310.489 243.887C311.743 243.833 313.106 243.887 314.141 244.16L318.394 245.25L323.737 243.124L319.43 242.089Z"
        fill="#42AF71"/>
      <path
        d="M310.761 241.653C308.362 242.525 304.655 242.852 302.583 242.307C300.566 241.762 300.893 240.672 303.237 239.854C305.527 239.037 309.017 238.764 311.088 239.255C313.215 239.691 313.106 240.781 310.761 241.653Z"
        fill="#202428"/>
      <path
        d="M300.512 239.364C299.585 239.691 298.167 239.745 297.24 239.527C296.368 239.309 296.423 238.873 297.295 238.601C298.167 238.274 299.585 238.219 300.512 238.437C301.384 238.601 301.384 239.037 300.512 239.364Z"
        fill="#202428"/>
      <path
        d="M316.595 243.288C315.668 243.669 314.033 243.724 312.997 243.451C311.961 243.179 311.852 242.688 312.779 242.361C313.705 242.034 315.286 241.925 316.322 242.198C317.413 242.416 317.522 242.906 316.595 243.288Z"
        fill="#202428"/>
      <path d="M316.758 247.048L328.644 242.252L330.606 246.612L317.958 252.008L316.758 247.048Z"
            fill="#1C6C40"/>
      <path d="M295.332 241.38L301.329 242.961L312.997 238.71L306.836 237.347L295.332 241.38Z"
            fill="white"/>
      <path d="M295.332 241.38L296.096 241.598L307.654 237.565L306.836 237.347L295.332 241.38Z"
            fill="#F1C62C"/>
      <path d="M300.457 242.743L301.329 242.961L312.997 238.71L312.124 238.546L300.457 242.743Z"
            fill="#F1C62C"/>
      <path d="M295.332 241.38L294.95 245.359L301.384 247.266L301.329 242.961L295.332 241.38Z"
            fill="#CACACA"/>
      <path d="M295.332 241.38L294.95 245.359L295.823 245.686L296.15 241.598L295.332 241.38Z"
            fill="#B0901F"/>
      <path d="M300.457 242.743V246.994L301.384 247.266L301.329 242.961L300.457 242.743Z" fill="#B0901F"/>
      <path
        d="M304.328 245.522L317.195 248.956H317.14L317.958 248.629L318.776 248.302L320.357 247.702C320.902 247.484 321.393 247.321 321.938 247.103C322.483 246.885 322.974 246.721 323.519 246.503C323.028 246.721 322.483 246.939 321.992 247.157C321.502 247.375 320.956 247.593 320.411 247.811L318.83 248.465L318.012 248.792L317.195 249.119H317.14H317.085L304.328 245.522Z"
        fill="#202428"/>
      <path d="M287.045 242.416L290.916 243.506L286.936 242.525L287.045 242.416Z" fill="#202428"/>
      <path
        d="M304.764 251.899L330.17 237.075C330.225 237.075 328.207 233.968 328.153 233.914L317.74 235.985L291.08 249.937L304.764 251.899Z"
        fill="#1C6C40"/>
      <path
        d="M304.055 247.266L328.098 234.023C328.153 233.968 328.098 233.968 328.044 233.968L316.431 233.151L291.243 245.686L304.055 247.266Z"
        fill="#42AF71"/>
      <path d="M303.837 246.83L327.062 234.132L316.649 233.369L292.606 245.413L303.837 246.83Z"
            fill="#202428"/>
      <path
        d="M299.803 243.451C300.73 242.961 301.111 242.525 301.002 242.143L313.215 235.93C314.414 235.767 315.559 235.44 316.322 235.004L318.612 233.805L323.737 234.186L321.447 235.385C320.684 235.767 320.466 236.203 320.684 236.475L308.689 242.961C307.49 243.179 306.291 243.56 305.418 243.996L301.874 245.904L296.15 245.195L299.803 243.451Z"
        fill="#42AF71"/>
      <path
        d="M307.654 241.108C310.162 241.38 313.705 240.781 315.45 239.854C317.14 238.928 316.431 238.001 313.978 237.783C311.525 237.565 308.199 238.11 306.454 238.982C304.655 239.909 305.2 240.835 307.654 241.108Z"
        fill="#202428"/>
      <path
        d="M316.322 236.748C317.249 236.802 318.666 236.584 319.375 236.203C320.084 235.821 319.92 235.44 318.994 235.385C318.067 235.331 316.704 235.549 315.995 235.93C315.232 236.312 315.395 236.693 316.322 236.748Z"
        fill="#202428"/>
      <path
        d="M302.801 243.887C303.783 243.996 305.309 243.724 306.236 243.233C307.108 242.743 306.999 242.307 306.018 242.198C305.037 242.089 303.565 242.361 302.638 242.852C301.765 243.288 301.82 243.778 302.801 243.887Z"
        fill="#202428"/>
      <path d="M304.055 247.266L291.243 245.631L291.08 249.937L304.764 251.899L304.055 247.266Z"
            fill="#338C5A"/>
      <path d="M321.829 237.456L316.867 240.181L304.491 238.982L309.725 236.366L321.829 237.456Z"
            fill="white"/>
      <path d="M321.829 237.456L321.174 237.783L309.017 236.748L309.725 236.366L321.829 237.456Z"
            fill="#F1C62C"/>
      <path d="M317.576 239.8L316.813 240.181L304.491 238.982L305.255 238.655L317.576 239.8Z"
            fill="#F1C62C"/>
      <path d="M321.828 237.456L323.573 240.89L318.285 243.996L316.867 240.181L321.828 237.456Z"
            fill="#CACACA"/>
      <path d="M321.829 237.456L323.573 240.89L322.864 241.38L321.174 237.783L321.829 237.456Z"
            fill="#B0901F"/>
      <path d="M317.576 239.8L319.048 243.56L318.285 244.051L316.813 240.181L317.576 239.8Z"
            fill="#B0901F"/>
      <path
        d="M315.014 243.125L304.382 249.065H304.328H304.273L303.401 248.956L302.529 248.847L300.839 248.629C300.293 248.574 299.748 248.465 299.149 248.411C298.603 248.356 298.058 248.247 297.513 248.193C298.058 248.247 298.603 248.302 299.203 248.356C299.748 248.411 300.348 248.465 300.893 248.52L302.583 248.738L303.456 248.847L304.328 248.956H304.273L315.014 243.125Z"
        fill="#202428"/>
      <path d="M329.952 236.585L326.681 238.383L329.843 236.53L329.952 236.585Z" fill="#202428"/>
      <rect x="208" y="134" width="97" height="70" fill="url(#pattern0)"/>
      <g clip-path="url(#clip0)">
        <path d="M355.896 369.963L230 363.293L261.104 338.099L352.637 342.767L355.896 369.963Z"
              fill="#212529"
              fill-opacity="0.65"/>
        <path d="M283.098 281.41L311.98 274L385 282.151L355.526 290.895L283.098 281.41Z"
              fill="url(#paint6_linear)"/>
        <path d="M358.34 369.37L382.334 347.584L384.926 282.151L357.525 290.229L358.34 369.37Z"
              fill="url(#paint7_linear)"/>
        <path
          d="M364.264 314.312C362.117 312.385 359.821 310.458 357.969 308.235L358.34 369.37L382.26 347.658L382.852 330.54C376.706 325.057 370.485 319.721 364.264 314.312Z"
          fill="url(#paint8_linear)"/>
        <path
          d="M284.802 280.669L289.986 279.706L357.007 287.709C360.191 288.079 362.339 291.859 362.339 294.971L361.82 365.739L358.34 369.147L284.802 280.669Z"
          fill="#2D2D2C" stroke="#424241" stroke-miterlimit="10"/>
        <path
          d="M354.637 370.555L287.468 357.513C285.172 357.069 283.247 354.697 283.247 352.252L282.802 284.671C282.802 282.225 284.654 280.447 286.949 280.743L353.896 289.191C356.488 289.487 358.636 291.933 358.636 294.526L359.302 366.702C359.377 369.296 357.229 371.074 354.637 370.555Z"
          fill="#424241" stroke="#424241" stroke-miterlimit="10"/>
        <g opacity="0.55">
          <path opacity="0.55"
                d="M357.969 291.933C357.229 291.414 356.414 291.044 355.526 290.895L288.356 282.3C286.061 282.003 284.209 283.782 284.209 286.227L284.654 353.956C284.654 354.846 284.876 355.661 285.246 356.402C284.061 355.364 283.247 353.808 283.247 352.178L282.802 284.597C282.802 282.151 284.654 280.373 286.949 280.669L353.896 289.117C355.6 289.339 357.081 290.451 357.969 291.933Z"
                fill="url(#paint9_linear)"/>
        </g>
        <path
          d="M348.638 362.552L292.726 352.03C290.8 351.659 289.171 349.659 289.171 347.658L288.875 291.044C288.875 288.969 290.43 287.487 292.356 287.783L348.12 295.119C350.268 295.416 352.045 297.416 352.045 299.565L352.563 359.366C352.563 361.515 350.786 362.997 348.638 362.552Z"
          fill="#DAD5EB"/>
        <path
          d="M348.638 362.552L292.726 352.03C290.8 351.659 289.171 349.659 289.171 347.658L288.875 291.044C288.875 288.969 290.43 287.487 292.356 287.783L348.12 295.119C350.268 295.416 352.045 297.416 352.045 299.565L352.563 359.366C352.563 361.515 350.786 362.997 348.638 362.552Z"
          fill="#2A2D32"/>
        <path
          d="M282.506 306.531C281.691 306.383 280.951 305.568 280.951 304.753L280.877 294.971C280.877 294.082 281.543 293.489 282.432 293.637C283.321 293.785 283.987 294.526 283.987 295.416L284.061 305.197C283.987 306.086 283.321 306.679 282.506 306.531Z"
          fill="url(#paint10_linear)"/>
        <g opacity="0.55">
          <path opacity="0.55"
                d="M283.913 295.416V297.935C283.913 297.491 283.765 297.12 283.469 296.749C283.173 296.453 282.802 296.231 282.358 296.157C281.543 296.008 280.803 296.601 280.803 297.491V294.971C280.803 294.082 281.469 293.489 282.358 293.637C282.802 293.711 283.173 293.934 283.469 294.23C283.765 294.601 283.913 294.971 283.913 295.416Z"
                fill="white"/>
        </g>
        <path
          d="M282.802 345.583C281.914 345.435 281.247 344.62 281.247 343.731L281.173 333.949C281.173 333.06 281.84 332.541 282.728 332.615C283.617 332.763 284.283 333.578 284.283 334.468L284.358 344.323C284.358 345.138 283.691 345.731 282.802 345.583Z"
          fill="url(#paint11_linear)"/>
        <g opacity="0.55">
          <path opacity="0.55"
                d="M284.283 334.468V338.025C284.283 337.58 284.135 337.135 283.839 336.839C283.543 336.542 283.173 336.246 282.728 336.172C281.84 336.024 281.173 336.617 281.173 337.432V333.875C281.173 332.986 281.84 332.467 282.728 332.541C283.173 332.615 283.543 332.837 283.839 333.208C284.061 333.578 284.283 334.023 284.283 334.468Z"
                fill="white"/>
        </g>
        <path
          d="M292.281 287.783C290.356 287.561 288.801 288.969 288.801 291.044L289.245 347.658C289.245 347.954 289.319 348.251 289.319 348.473L334.271 329.206L334.642 293.341L292.281 287.783Z"
          fill="url(#paint12_linear)"/>
        <path
          d="M351.971 299.565C351.971 297.416 350.194 295.416 348.046 295.119L334.345 293.341V337.728H352.341L351.971 299.565Z"
          fill="#262624"/>
        <path
          d="M281.988 288.969V348.695C281.988 350.918 280.581 352.845 278.507 353.438L248.662 362.775C248.366 362.849 248.07 362.923 247.848 362.923C246.366 362.923 245.033 361.737 245.033 360.107V277.409C245.033 275.778 246.366 274.593 247.848 274.593H247.774C248.07 274.593 248.292 274.667 248.588 274.741L278.433 284.226C280.581 284.893 281.988 286.82 281.988 288.969Z"
          fill="#424241" stroke="#424241" stroke-miterlimit="10"/>
        <path
          d="M281.988 288.969V291.192C281.988 289.043 280.581 287.116 278.507 286.449L248.662 276.89C248.366 276.816 248.07 276.742 247.848 276.742H247.774C246.292 276.742 244.959 277.928 244.959 279.558V277.335C244.959 275.704 246.292 274.519 247.774 274.519C248.07 274.519 248.292 274.593 248.588 274.667L278.433 284.152C280.581 284.893 281.988 286.82 281.988 288.969Z"
          fill="url(#paint13_linear)"/>
        <path
          d="M247.773 362.923L243.774 362.849H242.664C241.405 362.552 240.442 361.515 240.442 360.107V277.409V277.335C240.442 275.927 241.553 274.815 242.812 274.593H243.552H247.625C246.144 274.593 244.885 275.778 244.811 277.335V277.409V360.107C244.959 361.737 246.292 362.923 247.773 362.923Z"
          fill="#2D2D2C"/>
        <path
          d="M236.665 355.142H240.516V283.485H236.665C235.628 283.485 234.814 284.3 234.814 285.338V353.29C234.814 354.327 235.628 355.142 236.665 355.142Z"
          fill="#424241" stroke="#424241" stroke-miterlimit="10"/>
        <path
          d="M240.442 283.485V287.264H236.665C235.628 287.264 234.814 288.079 234.814 289.117V285.338C234.814 284.3 235.628 283.485 236.665 283.485H240.442Z"
          fill="url(#paint14_linear)"/>
        <path
          d="M277.915 293.415V344.249C277.915 346.027 276.878 347.584 275.323 348.102L253.402 354.994C253.18 355.068 252.958 355.068 252.809 355.068C251.699 355.068 250.736 354.105 250.736 352.771V284.671C250.736 283.337 251.699 282.374 252.809 282.374C253.032 282.374 253.18 282.374 253.402 282.448L275.323 289.561C276.878 290.08 277.915 291.636 277.915 293.415Z"
          fill="url(#paint15_linear)"/>
        <path
          d="M277.915 293.415V295.341C277.915 293.563 276.878 292.007 275.397 291.488L253.476 284.448C253.254 284.374 253.032 284.374 252.883 284.374C251.699 284.374 250.736 285.338 250.736 286.597V284.671C250.736 283.337 251.699 282.374 252.809 282.374C253.032 282.374 253.18 282.374 253.402 282.448L275.323 289.561C276.878 290.08 277.915 291.636 277.915 293.415Z"
          fill="url(#paint16_linear)"/>
        <g opacity="0.55">
          <path opacity="0.55"
                d="M247.773 274.593C246.292 274.593 245.033 275.778 244.959 277.335H240.442C240.442 275.927 241.553 274.815 242.812 274.593H243.552H247.773Z"
                fill="url(#paint17_linear)"/>
        </g>
        <path d="M291.615 349.288L303.982 351.437L322.793 342.026L310.796 340.248L291.615 349.288Z"
              fill="url(#paint18_linear)"/>
        <path d="M292.355 343.953L301.094 345.361L319.46 336.098L312.054 334.986L292.355 343.953Z"
              fill="url(#paint19_linear)"/>
        <path
          d="M292.355 343.879L296.799 344.546C298.28 344.768 299.687 344.99 301.168 345.212H301.094C302.649 344.397 304.13 343.656 305.686 342.915L310.277 340.692C313.387 339.21 316.424 337.654 319.46 336.024V336.246L311.98 335.135H312.054C308.796 336.617 305.538 338.099 302.279 339.507L292.355 343.879ZM292.355 343.953L302.057 339.358C305.315 337.802 308.648 336.32 311.98 334.838H312.054H312.129L319.534 336.098L319.83 336.172L319.534 336.32C316.498 337.802 313.387 339.358 310.351 340.915L305.76 343.286C304.204 344.101 302.723 344.842 301.168 345.583H301.094H301.02C299.539 345.361 298.058 345.064 296.577 344.768L292.355 343.953Z"
          fill="#333333"/>
        <path d="M301.094 345.36L304.131 351.363L322.793 342.026L319.46 336.098L301.094 345.36Z"
              fill="url(#paint20_linear)"/>
        <path
          d="M301.094 345.361L304.131 351.289H304.056C307.167 349.733 310.277 348.176 313.388 346.62C316.498 345.064 319.608 343.508 322.719 341.952V342.026L319.386 336.172H319.534L310.351 340.766L301.094 345.361ZM301.094 345.361L310.203 340.692L319.386 336.024H319.46L319.534 336.098L322.793 341.952V342.026H322.719C319.608 343.582 316.498 345.138 313.388 346.694C310.277 348.251 307.167 349.881 304.056 351.363H303.982V351.289L301.094 345.361Z"
          fill="#333333"/>
        <path d="M292.356 343.953L291.615 349.288L303.982 351.437L301.094 345.361L292.356 343.953Z"
              fill="url(#paint21_linear)"/>
        <path
          d="M292.356 343.953C292.133 345.805 291.911 347.584 291.689 349.362L291.615 349.288C293.689 349.585 295.688 350.029 297.762 350.4C299.835 350.77 301.835 351.067 303.908 351.437L303.834 351.511C302.872 349.51 301.909 347.51 300.946 345.509L301.02 345.583C299.539 345.361 298.132 345.064 296.651 344.842L292.356 343.953ZM292.356 343.879L296.725 344.546C298.206 344.768 299.687 344.99 301.168 345.287H301.242V345.361C302.205 347.361 303.168 349.362 304.056 351.437L304.131 351.511H303.982C301.909 351.141 299.909 350.77 297.836 350.474C295.762 350.103 293.689 349.807 291.689 349.436H291.615V349.362C291.837 347.51 292.059 345.657 292.356 343.879Z"
          fill="#333333"/>
        <path d="M298.206 342.248L310.499 344.323L329.31 334.912L317.386 333.208L298.206 342.248Z"
              fill="url(#paint22_linear)"/>
        <path d="M298.872 336.839L307.685 338.321L326.051 329.058L318.571 327.872L298.872 336.839Z"
              fill="url(#paint23_linear)"/>
        <path
          d="M298.872 336.839L303.316 337.506C304.797 337.728 306.204 337.95 307.685 338.173H307.611C309.166 337.357 310.647 336.616 312.202 335.875L316.794 333.652C319.904 332.17 322.941 330.614 325.977 328.984V329.206L318.571 328.095H318.645C315.387 329.577 312.128 331.059 308.87 332.467L298.872 336.839ZM298.872 336.839L308.574 332.244C311.832 330.688 315.165 329.206 318.497 327.724H318.571H318.645L326.051 328.984L326.347 329.058L326.051 329.206C323.015 330.688 319.904 332.244 316.868 333.801L312.277 336.172C310.721 336.987 309.24 337.728 307.685 338.469H307.611H307.537C306.056 338.247 304.575 337.95 303.094 337.654L298.872 336.839Z"
          fill="#333333"/>
        <path d="M307.685 338.321L310.647 344.249L329.31 334.912L326.051 329.058L307.685 338.321Z"
              fill="url(#paint24_linear)"/>
        <path
          d="M307.685 338.321L310.721 344.249H310.647C313.758 342.693 316.868 341.137 319.978 339.581C323.089 338.024 326.199 336.468 329.31 334.912V334.986L325.977 329.132H326.125L316.942 333.726L307.685 338.321ZM307.685 338.321L316.794 333.652L325.977 328.984H326.051L326.125 329.058L329.384 334.912V334.986H329.31C326.199 336.542 323.089 338.098 319.978 339.655C316.868 341.211 313.758 342.841 310.647 344.323H310.573V344.249L307.685 338.321Z"
          fill="#333333"/>
        <path d="M298.872 336.839L298.206 342.248L310.499 344.323L307.685 338.321L298.872 336.839Z"
              fill="url(#paint25_linear)"/>
        <path
          d="M298.872 336.839C298.65 338.691 298.428 340.47 298.206 342.248V342.174C300.279 342.47 302.279 342.915 304.353 343.286C306.426 343.656 308.426 343.952 310.499 344.323L310.425 344.397C309.462 342.396 308.5 340.396 307.537 338.395L307.611 338.469C306.13 338.247 304.723 337.95 303.242 337.728L298.872 336.839ZM298.872 336.839L303.242 337.506C304.723 337.728 306.204 337.95 307.685 338.247H307.759V338.321C308.722 340.321 309.685 342.322 310.573 344.397L310.647 344.471H310.499C308.426 344.101 306.426 343.73 304.353 343.434C302.279 343.063 300.205 342.767 298.206 342.396H298.132V342.322C298.354 340.396 298.65 338.617 298.872 336.839Z"
          fill="#333333"/>
        <path d="M304.797 351.289L317.164 353.364L335.975 343.953L323.977 342.248L304.797 351.289Z"
              fill="url(#paint26_linear)"/>
        <path d="M305.537 345.879L314.276 347.361L332.642 338.098L325.236 336.913L305.537 345.879Z"
              fill="url(#paint27_linear)"/>
        <path
          d="M305.537 345.879L309.981 346.546C311.462 346.768 312.869 346.991 314.35 347.213H314.276C315.831 346.398 317.312 345.657 318.868 344.916L323.459 342.693C326.569 341.211 329.606 339.655 332.642 338.024V338.247L325.162 337.135H325.236C321.978 338.617 318.719 340.099 315.461 341.507L305.537 345.879ZM305.537 345.879L315.239 341.285C318.497 339.729 321.83 338.247 325.162 336.765H325.236H325.31L332.716 338.024L333.012 338.098L332.716 338.247C329.68 339.729 326.569 341.285 323.533 342.841L318.942 345.212C317.386 346.027 315.905 346.768 314.35 347.509H314.276H314.202C312.721 347.287 311.24 346.991 309.759 346.694L305.537 345.879Z"
          fill="#333333"/>
        <path d="M314.276 347.361L317.312 353.363L335.975 343.952L332.642 338.098L314.276 347.361Z"
              fill="url(#paint28_linear)"/>
        <path
          d="M314.276 347.361L317.312 353.29H317.238C320.349 351.733 323.459 350.177 326.569 348.621C329.68 347.065 332.79 345.509 335.901 343.953V344.027L332.568 338.173H332.716L323.533 342.767L314.276 347.361ZM314.276 347.361L323.385 342.693L332.568 338.024H332.642L332.716 338.099L335.975 343.953V344.027H335.901C332.79 345.583 329.68 347.139 326.569 348.695C323.459 350.251 320.349 351.882 317.238 353.364H317.164V353.29L314.276 347.361Z"
          fill="#333333"/>
        <path d="M305.537 345.879L304.797 351.289L317.164 353.363L314.276 347.361L305.537 345.879Z"
              fill="url(#paint29_linear)"/>
        <path
          d="M305.537 345.879C305.315 347.732 305.093 349.51 304.871 351.289L304.797 351.215C306.87 351.511 308.87 351.956 310.944 352.326C313.017 352.697 315.017 352.993 317.09 353.363L317.016 353.438C316.053 351.437 315.091 349.436 314.128 347.435L314.202 347.509C312.721 347.287 311.314 346.991 309.833 346.768L305.537 345.879ZM305.537 345.879L309.907 346.546C311.388 346.768 312.869 346.991 314.35 347.287H314.424V347.361C315.387 349.362 316.35 351.363 317.238 353.438L317.312 353.512H317.164C315.091 353.141 313.091 352.771 311.018 352.474C308.944 352.104 306.87 351.807 304.871 351.437H304.797V351.363C305.019 349.51 305.241 347.658 305.537 345.879Z"
          fill="#333333"/>
        <path d="M318.571 353.882L330.865 355.957L349.675 346.546L337.678 344.768L318.571 353.882Z"
              fill="url(#paint30_linear)"/>
        <path d="M319.238 348.473L327.976 349.881L346.342 340.692L338.937 339.507L319.238 348.473Z"
              fill="url(#paint31_linear)"/>
        <path
          d="M319.238 348.473L323.681 349.14C325.162 349.362 326.569 349.585 328.05 349.807H327.976C329.532 348.992 331.013 348.251 332.568 347.51L337.159 345.287C340.27 343.805 343.306 342.248 346.342 340.618V340.84L338.863 339.655H338.937C335.678 341.137 332.42 342.619 329.161 344.027L319.238 348.473ZM319.238 348.473L328.939 343.879C332.198 342.322 335.53 340.84 338.863 339.358H338.937H339.011L346.416 340.618L346.713 340.692L346.416 340.84C343.38 342.322 340.27 343.879 337.233 345.435L332.642 347.806C331.087 348.621 329.606 349.362 328.05 350.103H327.976H327.902C326.421 349.881 324.94 349.585 323.459 349.288L319.238 348.473Z"
          fill="#333333"/>
        <path d="M327.977 349.881L331.013 355.883L349.675 346.546L346.343 340.692L327.977 349.881Z"
              fill="url(#paint32_linear)"/>
        <path
          d="M328.051 349.881L331.087 355.809H331.013C334.123 354.253 337.234 352.697 340.344 351.141C343.454 349.584 346.565 348.028 349.675 346.472V346.546L346.268 340.692H346.417L337.234 345.286L328.051 349.881ZM327.977 349.881L337.086 345.212L346.268 340.544H346.343L346.417 340.618L349.675 346.472V346.546H349.601C346.491 348.102 343.38 349.659 340.27 351.215C337.16 352.771 334.049 354.401 330.939 355.883H330.865V355.809L327.977 349.881Z"
          fill="#333333"/>
        <path d="M319.238 348.473L318.571 353.882L330.865 355.957L327.976 349.881L319.238 348.473Z"
              fill="url(#paint33_linear)"/>
        <path
          d="M319.238 348.473C319.016 350.325 318.794 352.104 318.571 353.882L318.497 353.808C320.571 354.105 322.57 354.549 324.644 354.92C326.718 355.29 328.717 355.587 330.791 355.957L330.717 356.031C329.754 354.031 328.791 352.03 327.828 350.029L327.902 350.103C326.421 349.881 325.014 349.584 323.533 349.362L319.238 348.473ZM319.238 348.473L323.607 349.14C325.088 349.362 326.569 349.584 328.051 349.881H328.125V349.955C329.087 351.956 330.05 353.956 330.939 356.031L331.013 356.105H330.865C328.791 355.735 326.792 355.364 324.718 355.068C322.644 354.697 320.571 354.401 318.571 354.031H318.497V353.956C318.719 352.03 318.942 350.177 319.238 348.473Z"
          fill="#333333"/>
        <path d="M334.716 356.995L347.009 359.069L351.674 356.846V349.214L334.716 356.995Z"
              fill="url(#paint34_linear)"/>
        <path d="M335.382 351.585L344.121 353.067L351.674 349.14L351.6 345.805L335.382 351.585Z"
              fill="url(#paint35_linear)"/>
        <path
          d="M335.382 351.585C336.863 351.807 338.344 352.03 339.825 352.252C341.307 352.474 342.714 352.697 344.121 352.919H344.047C344.639 352.548 345.306 352.252 345.898 351.956C346.565 351.659 347.157 351.363 347.824 351.066C349.083 350.474 350.341 349.807 351.6 349.14L351.526 349.214C351.452 348.102 351.378 346.991 351.378 345.879L351.6 346.027C348.934 346.991 346.268 347.954 343.602 348.843C340.936 349.658 338.196 350.622 335.382 351.585ZM335.382 351.585C337.974 350.622 340.714 349.584 343.38 348.621C346.12 347.584 348.86 346.62 351.6 345.657L351.823 345.583V345.805C351.823 346.917 351.823 348.028 351.823 349.14V349.214H351.749C350.49 349.807 349.231 350.474 347.972 351.14C347.379 351.511 346.713 351.807 346.12 352.178C345.528 352.548 344.861 352.845 344.269 353.141H344.195H344.121C342.64 352.919 341.158 352.622 339.677 352.326C338.196 352.104 336.789 351.807 335.382 351.585Z"
          fill="#333333"/>
        <path d="M344.121 353.067L347.157 358.995L351.675 356.772V349.214L344.121 353.067Z"
              fill="url(#paint36_linear)"/>
        <path
          d="M344.195 353.067L347.231 358.995H347.157C347.898 358.625 348.638 358.254 349.379 357.884C350.119 357.513 350.86 357.143 351.601 356.772V356.846V349.288L351.749 349.362L348.046 351.289L344.195 353.067ZM344.121 353.067L347.824 351.066L351.601 349.14L351.749 349.066V349.214V356.772C351.008 357.143 350.268 357.513 349.527 357.884C348.786 358.254 348.046 358.699 347.305 359.069H347.231V358.995L344.121 353.067Z"
          fill="#333333"/>
        <path d="M335.382 351.585L334.716 356.994L347.009 359.069L344.121 353.067L335.382 351.585Z"
              fill="url(#paint37_linear)"/>
        <path
          d="M335.382 351.585C335.16 353.438 334.938 355.216 334.716 356.994L334.642 356.92C336.715 357.217 338.715 357.661 340.788 358.032C342.862 358.402 344.861 358.699 346.935 359.069L346.861 359.143C345.898 357.143 344.935 355.142 343.973 353.141L344.047 353.215C342.566 352.993 341.159 352.696 339.677 352.474L335.382 351.585ZM335.382 351.585L339.751 352.252C341.233 352.474 342.714 352.696 344.195 352.993H344.269V353.067C345.232 355.068 346.194 357.069 347.083 359.143L347.157 359.218H347.009C344.935 358.847 342.936 358.476 340.862 358.18C338.789 357.81 336.715 357.513 334.716 357.143H334.642V357.069C334.864 355.142 335.086 353.363 335.382 351.585Z"
          fill="#333333"/>
        <path d="M314.202 344.916L326.57 346.991L345.38 337.58L333.383 335.801L314.202 344.916Z"
              fill="url(#paint38_linear)"/>
        <path d="M314.943 339.506L323.681 340.988L342.047 331.726L334.642 330.54L314.943 339.506Z"
              fill="url(#paint39_linear)"/>
        <path
          d="M314.943 339.506L319.386 340.173C320.867 340.396 322.274 340.618 323.755 340.84H323.681C325.236 340.025 326.718 339.284 328.273 338.543L332.864 336.32C335.975 334.838 339.011 333.282 342.047 331.652V331.874L334.568 330.762H334.642C331.383 332.244 328.125 333.726 324.866 335.134L314.943 339.506ZM314.943 339.506L324.644 334.912C327.902 333.356 331.235 331.874 334.568 330.392H334.642H334.716L342.121 331.652L342.418 331.726L342.121 331.874C339.085 333.356 335.975 334.912 332.938 336.468L328.347 338.84C326.792 339.655 325.311 340.396 323.755 341.137H323.681H323.607C322.126 340.914 320.645 340.618 319.164 340.322L314.943 339.506Z"
          fill="#333333"/>
        <path d="M323.681 340.988L326.718 346.917L345.38 337.58L342.047 331.726L323.681 340.988Z"
              fill="url(#paint40_linear)"/>
        <path
          d="M323.681 340.988L326.718 346.917H326.644C329.754 345.36 332.864 343.804 335.975 342.248C339.085 340.692 342.195 339.136 345.306 337.58V337.654L341.973 331.8H342.121L332.938 336.394L323.681 340.988ZM323.681 340.914L332.79 336.246L341.973 331.577H342.047L342.121 331.651L345.38 337.506V337.58H345.306C342.195 339.136 339.085 340.692 335.975 342.248C332.864 343.804 329.754 345.435 326.644 346.917H326.57V346.842L323.681 340.914Z"
          fill="#333333"/>
        <path d="M314.943 339.506L314.202 344.916L326.57 346.991L323.681 340.988L314.943 339.506Z"
              fill="url(#paint41_linear)"/>
        <path
          d="M314.943 339.506C314.721 341.359 314.498 343.137 314.276 344.916L314.202 344.842C316.276 345.138 318.275 345.583 320.349 345.953C322.422 346.324 324.422 346.62 326.496 346.991L326.421 347.065C325.459 345.064 324.496 343.063 323.533 341.062L323.607 341.137C322.126 340.914 320.719 340.618 319.238 340.396L314.943 339.506ZM314.943 339.506L319.312 340.173C320.793 340.396 322.274 340.618 323.755 340.914H323.829V340.988C324.792 342.989 325.755 344.99 326.644 347.065L326.718 347.139H326.57C324.496 346.768 322.496 346.398 320.423 346.101C318.349 345.731 316.276 345.435 314.276 345.064H314.202V344.99C314.424 343.063 314.646 341.285 314.943 339.506Z"
          fill="#333333"/>
        <path d="M330.939 348.176L343.306 350.251L351.6 346.176V338.469L330.939 348.176Z"
              fill="url(#paint42_linear)"/>
        <path d="M331.679 342.767L340.418 344.175L351.674 338.469L351.526 333.801L331.679 342.767Z"
              fill="url(#paint43_linear)"/>
        <path
          d="M331.679 342.767L336.123 343.434C337.604 343.656 339.011 343.878 340.492 344.101H340.418C341.381 343.582 342.269 343.137 343.232 342.693L346.046 341.359C347.972 340.47 349.823 339.506 351.674 338.469L351.6 338.543C351.526 336.987 351.452 335.431 351.378 333.801L351.6 333.949C348.342 335.431 345.083 336.913 341.751 338.395L331.679 342.767ZM331.679 342.767L341.529 338.173C344.861 336.616 348.194 335.06 351.526 333.578L351.748 333.504V333.801C351.748 335.357 351.748 336.987 351.823 338.543V338.617H351.748C349.897 339.581 347.972 340.47 346.12 341.433L343.306 342.915C342.343 343.434 341.455 343.878 340.492 344.323H340.418H340.344C338.863 344.101 337.382 343.804 335.9 343.508L331.679 342.767Z"
          fill="#333333"/>
        <path d="M340.418 344.175L343.454 350.177L351.675 346.102L351.6 338.617L340.418 344.175Z"
              fill="url(#paint44_linear)"/>
        <path
          d="M340.418 344.175L343.454 350.103H343.38C344.713 349.362 346.12 348.769 347.453 348.102C348.86 347.435 350.193 346.768 351.526 346.101V346.176L351.452 338.617L351.6 338.691L346.046 341.507L340.418 344.175ZM340.418 344.175L345.972 341.285L351.6 338.469L351.749 338.395V338.543V346.101C350.342 346.768 349.009 347.435 347.675 348.176C346.342 348.843 344.935 349.584 343.602 350.251H343.528V350.177L340.418 344.175Z"
          fill="#333333"/>
        <path d="M331.679 342.767L330.939 348.176L343.306 350.251L340.418 344.175L331.679 342.767Z"
              fill="url(#paint45_linear)"/>
        <path
          d="M331.679 342.767C331.457 344.619 331.235 346.398 331.013 348.176L330.939 348.102C333.012 348.399 335.012 348.843 337.085 349.214C339.159 349.584 341.158 349.881 343.232 350.251L343.158 350.325C342.195 348.325 341.233 346.324 340.27 344.323L340.344 344.397C338.863 344.175 337.456 343.878 335.975 343.656L331.679 342.767ZM331.679 342.767L336.049 343.434C337.53 343.656 339.011 343.878 340.492 344.175H340.566V344.249C341.529 346.25 342.492 348.25 343.38 350.325L343.454 350.399H343.306C341.233 350.029 339.233 349.658 337.159 349.362C335.086 348.991 333.012 348.695 331.013 348.325H330.939V348.25C331.161 346.324 331.383 344.545 331.679 342.767Z"
          fill="#333333"/>
        <path d="M306.796 336.246L319.164 338.321L337.974 328.91L325.977 327.206L306.796 336.246Z"
              fill="url(#paint46_linear)"/>
        <path d="M307.537 330.837L316.276 332.319L334.642 323.056L327.236 321.87L307.537 330.837Z"
              fill="url(#paint47_linear)"/>
        <path
          d="M307.537 330.837L311.98 331.503C313.461 331.726 314.868 331.948 316.35 332.17H316.276C317.831 331.355 319.312 330.614 320.867 329.873L325.459 327.65C328.569 326.168 331.605 324.612 334.641 322.982V323.204L327.162 322.092H327.236C323.977 323.574 320.719 325.057 317.46 326.464L307.537 330.837ZM307.537 330.837L317.238 326.242C320.497 324.686 323.829 323.204 327.162 321.722H327.236H327.31L334.716 322.982L335.012 323.056L334.716 323.204C331.679 324.686 328.569 326.242 325.533 327.798L320.941 330.17C319.386 330.985 317.905 331.726 316.35 332.467H316.276H316.201C314.72 332.244 313.239 331.948 311.758 331.652L307.537 330.837Z"
          fill="#333333"/>
        <path d="M316.276 332.318L319.312 338.321L337.974 328.91L334.642 323.056L316.276 332.318Z"
              fill="url(#paint48_linear)"/>
        <path
          d="M316.276 332.319L319.312 338.247H319.238C322.348 336.691 325.459 335.134 328.569 333.578C331.679 332.022 334.79 330.466 337.9 328.91V328.984L334.568 323.13H334.716L325.533 327.724L316.276 332.319ZM316.276 332.319L325.385 327.65L334.568 322.982H334.642L334.716 323.056L337.974 328.91V328.984H337.9C334.79 330.54 331.679 332.096 328.569 333.652C325.459 335.209 322.348 336.839 319.238 338.321H319.164V338.247L316.276 332.319Z"
          fill="#333333"/>
        <path d="M307.537 330.836L306.796 336.246L319.164 338.321L316.276 332.318L307.537 330.836Z"
              fill="url(#paint49_linear)"/>
        <path
          d="M307.537 330.836C307.315 332.689 307.093 334.467 306.87 336.246L306.796 336.172C308.87 336.468 310.869 336.913 312.943 337.283C315.017 337.654 317.016 337.95 319.09 338.321L319.016 338.395C318.053 336.394 317.09 334.393 316.127 332.393L316.202 332.467C314.72 332.244 313.313 331.948 311.832 331.726L307.537 330.836ZM307.537 330.836L311.906 331.503C313.387 331.726 314.869 331.948 316.35 332.244H316.424V332.318C317.386 334.319 318.349 336.32 319.238 338.395L319.312 338.469H319.164C317.09 338.098 315.091 337.728 313.017 337.432C310.944 337.061 308.87 336.765 306.87 336.394H306.796V336.32C307.019 334.467 307.241 332.615 307.537 330.836Z"
          fill="#333333"/>
        <path d="M323.533 339.507L335.901 341.581L351.378 333.949L342.714 330.466L323.533 339.507Z"
              fill="url(#paint50_linear)"/>
        <path d="M324.274 334.097L333.012 335.579L351.378 326.316L343.973 325.131L324.274 334.097Z"
              fill="url(#paint51_linear)"/>
        <path
          d="M324.274 334.097L328.717 334.764C330.198 334.986 331.605 335.209 333.086 335.431H333.012C334.568 334.616 336.049 333.875 337.604 333.134L342.195 330.911C345.306 329.429 348.342 327.872 351.378 326.242V326.464L343.899 325.353H343.973C340.714 326.835 337.456 328.317 334.197 329.725L324.274 334.097ZM324.274 334.097L333.975 329.503C337.234 327.947 340.566 326.464 343.899 324.982H343.973H344.047L351.452 326.242L351.749 326.316L351.452 326.464C348.416 327.947 345.306 329.503 342.269 331.059L337.678 333.43C336.123 334.245 334.642 334.986 333.086 335.727H333.012H332.938C331.457 335.505 329.976 335.209 328.495 334.912L324.274 334.097Z"
          fill="#333333"/>
        <path d="M333.012 335.579L336.049 341.581L351.601 333.801V326.316L333.012 335.579Z"
              fill="url(#paint52_linear)"/>
        <path
          d="M333.012 335.579L336.049 341.507H335.975C338.567 340.173 341.159 338.914 343.751 337.654C346.343 336.394 348.935 335.06 351.527 333.801V333.875L351.452 326.39L351.601 326.464L342.344 331.059L333.012 335.579ZM333.012 335.579L342.269 330.911L351.601 326.242L351.749 326.168V326.316V333.801C349.157 335.06 346.565 336.394 343.973 337.654C341.381 338.988 338.789 340.247 336.197 341.581H336.123V341.507L333.012 335.579Z"
          fill="#333333"/>
        <path d="M324.274 334.097L323.533 339.506L335.901 341.581L333.012 335.579L324.274 334.097Z"
              fill="url(#paint53_linear)"/>
        <path
          d="M324.274 334.097C324.052 335.949 323.829 337.728 323.607 339.506L323.533 339.432C325.607 339.729 327.606 340.173 329.68 340.544C331.753 340.914 333.753 341.211 335.827 341.581L335.753 341.655C334.79 339.655 333.827 337.654 332.864 335.653L332.938 335.727C331.457 335.505 330.05 335.208 328.569 334.986L324.274 334.097ZM324.274 334.097L328.643 334.764C330.124 334.986 331.605 335.208 333.086 335.505H333.161V335.579C334.123 337.58 335.086 339.581 335.975 341.655L336.049 341.729H335.901C333.827 341.359 331.828 340.988 329.754 340.692C327.68 340.322 325.607 340.025 323.607 339.655H323.533V339.581C323.755 337.654 323.978 335.875 324.274 334.097Z"
          fill="#333333"/>
        <path d="M316.276 330.688L328.569 332.837L347.379 323.426L335.382 321.648L316.276 330.688Z"
              fill="url(#paint54_linear)"/>
        <path d="M316.942 325.279L325.681 326.761L344.047 317.498L336.641 316.387L316.942 325.279Z"
              fill="url(#paint55_linear)"/>
        <path
          d="M316.942 325.279L321.386 325.946C322.867 326.168 324.274 326.39 325.755 326.613H325.681C327.236 325.798 328.717 325.057 330.272 324.316L334.864 322.092C337.974 320.61 341.01 319.054 344.047 317.424V317.646L336.641 316.535H336.715C333.457 318.017 330.198 319.499 326.94 320.907L316.942 325.279ZM316.942 325.279L326.644 320.685C329.902 319.128 333.235 317.646 336.567 316.164H336.641H336.715L344.121 317.424L344.417 317.498L344.121 317.646C341.085 319.128 337.974 320.685 334.938 322.241L330.346 324.612C328.791 325.427 327.31 326.168 325.755 326.909H325.681H325.607C324.126 326.687 322.644 326.39 321.163 326.094L316.942 325.279Z"
          fill="#333333"/>
        <path d="M325.681 326.761L328.717 332.763L347.379 323.426L344.047 317.498L325.681 326.761Z"
              fill="url(#paint56_linear)"/>
        <path
          d="M325.755 326.761L328.791 332.689H328.717C331.828 331.133 334.938 329.577 338.048 328.021C341.159 326.465 344.269 324.908 347.379 323.352V323.426L344.047 317.572H344.195L334.938 322.167L325.755 326.761ZM325.681 326.761L334.79 322.093L343.973 317.424H344.047L344.121 317.498L347.379 323.352V323.426H347.305C344.195 324.983 341.085 326.539 337.974 328.095C334.864 329.651 331.754 331.281 328.643 332.763H328.569V332.689L325.681 326.761Z"
          fill="#333333"/>
        <path d="M316.942 325.279L316.276 330.688L328.569 332.837L325.681 326.761L316.942 325.279Z"
              fill="url(#paint57_linear)"/>
        <path
          d="M316.942 325.353C316.72 327.205 316.498 328.984 316.276 330.762L316.202 330.688C318.275 330.985 320.275 331.429 322.348 331.8C324.422 332.17 326.421 332.467 328.495 332.837L328.421 332.911C327.458 330.911 326.495 328.91 325.533 326.909L325.607 326.983C324.126 326.761 322.719 326.464 321.237 326.242L316.942 325.353ZM316.942 325.279L321.312 325.946C322.793 326.168 324.274 326.39 325.755 326.687H325.829V326.761C326.792 328.762 327.754 330.762 328.643 332.837L328.717 332.911H328.569C326.495 332.541 324.496 332.17 322.422 331.874C320.349 331.503 318.275 331.207 316.276 330.836H316.202V330.762C316.424 328.91 316.646 327.057 316.942 325.279Z"
          fill="#333333"/>
      </g>
      <defs>
        <filter id="filter0_f" x="255" y="94" width="118" height="162" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="5.5" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter1_f" x="327" y="114" width="87" height="117" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="5.5" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter2_f" x="73" y="91" width="44" height="194" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="5.5" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter3_f" x="22" y="120" width="38" height="213" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="5.5" result="effect1_foregroundBlur"/>
        </filter>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlink:href="#image0" transform="translate(-0.00246903) scale(0.000866326 0.00120048)"/>
        </pattern>
        <linearGradient id="paint0_linear" x1="229.499" y1="231.392" x2="233.048" y2="330.791"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#3C3C3B"/>
          <stop offset="1" stop-color="#3C3C3B" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="250.928" y1="234.684" x2="251.372" y2="257.906"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#868686"/>
          <stop offset="0.2322" stop-color="#6C6C6C"/>
          <stop offset="0.5311" stop-color="#525251"/>
          <stop offset="0.7967" stop-color="#424241"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="139.073" y1="217.396" x2="150.788" y2="328.977"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#868686"/>
          <stop offset="0.2322" stop-color="#6C6C6C"/>
          <stop offset="0.5311" stop-color="#525251"/>
          <stop offset="0.7967" stop-color="#424241"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="252.292" y1="214.682" x2="258.65" y2="248.111"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#868686"/>
          <stop offset="0.2322" stop-color="#6C6C6C"/>
          <stop offset="0.5311" stop-color="#525251"/>
          <stop offset="0.7967" stop-color="#424241"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="261.901" y1="98.3117" x2="249.717" y2="288.829"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#868686"/>
          <stop offset="0.2322" stop-color="#6C6C6C"/>
          <stop offset="0.5311" stop-color="#525251"/>
          <stop offset="0.7967" stop-color="#424241"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint5_linear" x1="111.593" y1="212.761" x2="139.097" y2="309.061"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#868686"/>
          <stop offset="0.2322" stop-color="#6C6C6C"/>
          <stop offset="0.5311" stop-color="#525251"/>
          <stop offset="0.7967" stop-color="#424241"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint6_linear" x1="326.799" y1="301.504" x2="338.661" y2="269.274"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint7_linear" x1="348.873" y1="336.694" x2="388.467" y2="306.523"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint8_linear" x1="377.223" y1="257.904" x2="368.82" y2="340.898"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint9_linear" x1="315.825" y1="266.099" x2="322.004" y2="338.472"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint10_linear" x1="280.83" y1="300.1" x2="284.001" y2="300.1"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint11_linear" x1="281.175" y1="339.086" x2="284.352" y2="339.086"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint12_linear" x1="288.843" y1="318.128" x2="334.571" y2="318.128"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint13_linear" x1="262.322" y1="270.667" x2="268.501" y2="343.04"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint14_linear" x1="236.505" y1="272.871" x2="242.684" y2="345.244"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint15_linear" x1="252.22" y1="276.077" x2="269.52" y2="343.011"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint16_linear" x1="262.686" y1="270.636" x2="268.865" y2="343.009"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint17_linear" x1="243.803" y1="272.248" x2="249.982" y2="344.621"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint18_linear" x1="305.927" y1="340.69" x2="308.156" y2="349.317"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint19_linear" x1="304.901" y1="336.141" x2="306.701" y2="343.107"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint20_linear" x1="310.346" y1="337.493" x2="313.021" y2="347.844"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint21_linear" x1="295.673" y1="342.13" x2="298.167" y2="351.779"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint22_linear" x1="312.478" y1="333.617" x2="314.707" y2="342.244"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint23_linear" x1="311.452" y1="329.068" x2="313.252" y2="336.034"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint24_linear" x1="316.897" y1="330.42" x2="319.572" y2="340.771"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint25_linear" x1="302.224" y1="335.057" x2="304.717" y2="344.706"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint26_linear" x1="319.113" y1="342.667" x2="321.343" y2="351.293"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint27_linear" x1="318.087" y1="338.117" x2="319.887" y2="345.083"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint28_linear" x1="323.532" y1="339.469" x2="326.207" y2="349.82"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint29_linear" x1="308.859" y1="344.106" x2="311.353" y2="353.755"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint30_linear" x1="332.822" y1="345.224" x2="335.052" y2="353.851"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint31_linear" x1="331.796" y1="340.674" x2="333.596" y2="347.641"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint32_linear" x1="337.241" y1="342.027" x2="339.916" y2="352.378"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint33_linear" x1="322.568" y1="346.664" x2="325.062" y2="356.313"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint34_linear" x1="342.586" y1="350.805" x2="344.643" y2="358.763"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint35_linear" x1="343.199" y1="347.502" x2="344.462" y2="352.389"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint36_linear" x1="347.49" y1="349.532" x2="349.546" y2="357.483"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint37_linear" x1="338.716" y1="349.803" x2="341.21" y2="359.451"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint38_linear" x1="328.523" y1="336.273" x2="330.753" y2="344.899"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint39_linear" x1="327.497" y1="331.723" x2="329.298" y2="338.689"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint40_linear" x1="332.943" y1="333.076" x2="335.618" y2="343.427"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint41_linear" x1="318.269" y1="337.713" x2="320.763" y2="347.361"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint42_linear" x1="340.542" y1="340.479" x2="342.83" y2="349.33"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint43_linear" x1="340.986" y1="335.84" x2="342.782" y2="342.786"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint44_linear" x1="345.501" y1="339.338" x2="347.755" y2="348.06"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint45_linear" x1="334.999" y1="340.968" x2="337.493" y2="350.616"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint46_linear" x1="321.118" y1="327.628" x2="323.347" y2="336.254"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint47_linear" x1="320.091" y1="323.078" x2="321.892" y2="330.044"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint48_linear" x1="325.537" y1="324.43" x2="328.212" y2="334.781"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint49_linear" x1="310.864" y1="329.067" x2="313.357" y2="338.716"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint50_linear" x1="336.089" y1="331.337" x2="338.319" y2="339.964"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint51_linear" x1="336.821" y1="326.333" x2="338.622" y2="333.299"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint52_linear" x1="341.542" y1="327.93" x2="344.204" y2="338.233"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint53_linear" x1="327.593" y1="332.322" x2="330.087" y2="341.971"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint54_linear" x1="330.534" y1="322.083" x2="332.764" y2="330.71"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#878787"/>
          <stop offset="0.2416" stop-color="#6E6D6D"/>
          <stop offset="0.5368" stop-color="#545453"/>
          <stop offset="0.7992" stop-color="#434242"/>
          <stop offset="1" stop-color="#3C3C3B"/>
        </linearGradient>
        <linearGradient id="paint55_linear" x1="329.508" y1="317.533" x2="331.309" y2="324.5"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#BE8800"/>
          <stop offset="0.1492" stop-color="#DAAF2E"/>
          <stop offset="0.3046" stop-color="#F1D35B"/>
          <stop offset="0.433" stop-color="#FFEB77"/>
          <stop offset="0.5176" stop-color="#FFF482"/>
          <stop offset="0.5964" stop-color="#FFEE79"/>
          <stop offset="0.7117" stop-color="#F8DD5F"/>
          <stop offset="0.8494" stop-color="#E8C335"/>
          <stop offset="1" stop-color="#D3A200"/>
        </linearGradient>
        <linearGradient id="paint56_linear" x1="334.954" y1="318.886" x2="337.629" y2="329.237"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <linearGradient id="paint57_linear" x1="320.28" y1="323.523" x2="322.774" y2="333.172"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#986E00"/>
          <stop offset="0.0435448" stop-color="#A47900"/>
          <stop offset="0.197" stop-color="#CCA01C"/>
          <stop offset="0.3326" stop-color="#E9BE33"/>
          <stop offset="0.4441" stop-color="#FAD042"/>
          <stop offset="0.5176" stop-color="#FFD747"/>
          <stop offset="0.5854" stop-color="#FBD242"/>
          <stop offset="0.6845" stop-color="#EBC234"/>
          <stop offset="0.8029" stop-color="#D1AA1F"/>
          <stop offset="0.9351" stop-color="#AC8900"/>
          <stop offset="1" stop-color="#977600"/>
        </linearGradient>
        <clipPath id="clip0">
          <rect width="155" height="97" fill="white" transform="translate(230 274)"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'PersonSettingDns',
  mounted () {
    (function ($) {
      'use strict'
      $(function () {
        $('[data-spzoom]').spzoom({
          position: 'left',
          margin: 20,
          width: 900,
          height: 500
        })
      })
      // eslint-disable-next-line no-undef
    })(jQuery)
  }
}
</script>
