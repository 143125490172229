import analytics from './analytics/en'
import auth from './auth/en'
import bot from './bot/en'
import dashboard from './dashboard/en'
import dashboardLayout from './dashboardLayout/en'
import finance from './finance/en'
import general from './general/en'
import layouts from './layouts/en'
import menu from './menu/en'
import modalSave from './modalSave/en'
import notFound from './notFound/en'
import pushes from './pushes/en'
import pwas from './pwas/en'
import referrals from './referrals/en'
import tariffs from './tariffs/en'
import teams from './teams/en'
import templates from './templates/en'
import tickets from './tickets/en'
import titles from './titles/en'
import trackers from './trackers/en'
import landingPages from './landing-pages/en'
import group from './group/en'
import pixels from './pixels/en'
import components from './components/en'
import banners from './banners/en'
import validations from './validations/en'
import mirrors from './mirrors/en'
import vacancies from './vacancies/en'

export default {
  ID: 'ID',
  PWA: 'PWA',
  IP: 'IP',
  PWAs: 'PWAs',
  PUSH: 'PUSH',
  analytics,
  auth,
  bot,
  dashboard,
  dashboardLayout,
  finance,
  general,
  layouts,
  menu,
  modalSave,
  notFound,
  pushes,
  pwas,
  referrals,
  tariffs,
  teams,
  templates,
  tickets,
  titles,
  trackers,
  landingPages,
  group,
  pixels,
  banners,
  components,
  validations,
  mirrors,
  vacancies
}
