<template>
  <div class="d-flex flex-grow-1">
    <div class="flex-grow-1">
      <div class="form-group mb-0">
        <label :for="`google-analytics-code-${index}`">Google Conversion ID
          <info
            message="Google Conversion ID"/>
        </label>
        <input type="text" :id="`google-analytics-code-${index}`" placeholder="AW-XXXXX" v-model="code" class="form-control">
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'

export default {
  name: 'Config',
  props: ['index', '_id'],
  components: {Info},
  data: () => ({code: ''}),
  created () {
    this.code = this._id
    this.$watch('code', this.emitData)
  },
  methods: {
    emitData: function () {
      this.$emit('input', this.code)
    }
  }
}
</script>
