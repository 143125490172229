import layout from './layout.vue'
import keys from './keys.vue'
import {router, offers} from './offers'

export default (meta, header) => {
  return [
    {
      path: 'bot',
      name: 'BotLayout',
      components: {default: layout, header},
      meta,
      redirect: '/',
      children: [
        {
          path: 'keys',
          name: 'BotKeys',
          components: {default: keys, header},
          meta
        },
        {
          path: 'offers',
          name: 'BotOffers',
          components: {default: offers, header},
          meta
        }
      ]
    },
    ...router(meta, header)
  ]
}
