import service from '@/store/services/admin/members-service'
import middleware from '../middleware'

const state = () => ({
  count: 0,
  models: [],
  groups: [],
  filter: {
    owner: '',
    username: '',
    group: ''
  }
})

const mutations = {
  setData (state, payload) {
    const { models, count, groups } = payload
    state.models = models
    state.count = count
    state.groups = groups
  },
  setFilter (state, payload) {
    const {owner, username, group} = payload
    state.filter = {owner, username, group}
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  setFilter ({commit}, payload) {
    commit('setFilter', payload)
  },
  delete ({commit}, params) {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  groups: state => state.groups,
  filter: state => state.filter
}

const adminMembers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminMembers
