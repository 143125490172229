<template>
  <VueSelect
    v-bind="$attrs"
    :options="loadedOptions"
    class="BaseSelectInline"
    v-model="modelValue"
    label="text"
    @input="onInput"
    ref="select"
    :searchable="false"
    :clearable="false"
    :id="uniqId"
  >
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes" class="open-arrow-indicator "></span>
    </template>
    <template #no-options="{ search }">
      <span v-show="!loading && !loadedOptions.length || search">{{$t('components.baseSelect.noOptions')}}</span>
    </template>
  </VueSelect>
</template>
<script>
import { VueSelect } from 'vue-select'

export default {
  name: 'BaseSelectInline',
  components: { VueSelect },
  model: {
    event: 'update:modelValue'
  },
  props: {
    name: String,
    options: Array,
    value: [String, Array, Object, Number, Boolean],
    id: [String, Number]
  },
  data () {
    return {
      loadedOptions: this.options || []
    }
  },
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('update:modelValue', newValue)
      }
    },
    uniqId () {
      return this.id || this._uid
    }
  },
  watch: {
    modelValue: {
      handler () {
        if (this.modelValue && this.modelValue.length > 0 && !this.options) this.fetchOptions()
      },
      immediate: true
    },
    options: {
      handler: 'setOptionsFromProps',
      immediate: true
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value === null ? '' : value)
    },
    setOptionsFromProps () {
      this.loadedOptions = this.options
    }
  }
}
</script>
<style>
@import "vue-select/dist/vue-select.css";
</style>
<style scoped>
.flag-icon {
  margin-right: 5px;
}

:deep(.vs__dropdown-option) {
  color: #fff;
  padding: 8px 10px;
  display: flex;
}

:deep(.vs__dropdown-option) .flag-icon {
  flex-shrink: 0;
}

:deep(.vs__dropdown-toggle) {
  width: 100%;
  font-weight: 400;
  background: transparent;
  border-radius: 3px;
  padding: 0 10px;
  padding-right: 15px;
  font-size: 12px;
  color: #fff;
  border-color: rgba(255, 255, 255, .15);
  min-height: 38px;
  overflow-x: hidden;
}

:deep(.vs__search::placeholder) {
  color: #999;
}

:deep(.vs__selected-options) {
  font-size: inherit;
  padding: 0;
  font-weight: 500;
}

:deep(.vs__search) {
  font-size: inherit;
  padding: 0;
  margin: 0;
}

.vs--open :deep(.vs__dropdown-toggle) {
  border-color: rgba(255, 255, 255, .3);
}

.vs--open :deep(.vs__selected) {
  display: none;
}

:deep(.vs__dropdown-menu) {
  font-size: 12px;
  background: linear-gradient(245.12deg, rgba(255, 255, 255, 0.0045) -14.19%, rgba(255, 255, 255, 0.09) 100%);
  box-shadow: -25px 45px 25px rgb(0 0 0 / 5%);
  backdrop-filter: blur(25px);
  border-radius: 5px;
  top: calc(100% + 4px);
  border: 1px solid rgba(255, 255, 255, .15);
  background: rgba(42, 46, 50, 0.75);
  padding: 0;
  max-height: 205px;
}

:deep(.vs__selected) {
  color: #cec6c6;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

:deep(.vs__dropdown-option--selected) {
  background: #21BF73;
}

:deep(.vs__dropdown-option--highlight:not(.vs__dropdown-option--selected)) {
  background: rgba(255, 255, 255, .1);
}

:deep(.vs__no-options) {
  padding: 10px;
}

:deep(.vs__clear) {
  fill: #cec6c6;
  display: flex;
  margin-right: 5px;
  opacity: 0.5;
}

:deep(.vs__clear svg) {
  transform: scale(0.6);
}

.open-arrow-indicator {
  background: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.3331 1.1133C7.20819 0.989128 7.03922 0.919434 6.8631 0.919434C6.68697 0.919434 6.518 0.989128 6.3931 1.1133L3.99976 3.4733L1.63976 1.1133C1.51485 0.989128 1.34589 0.919434 1.16976 0.919434C0.993638 0.919434 0.824671 0.989128 0.699762 1.1133C0.637277 1.17527 0.58768 1.249 0.553835 1.33024C0.519989 1.41148 0.502563 1.49862 0.502563 1.58663C0.502563 1.67464 0.519989 1.76177 0.553835 1.84301C0.58768 1.92425 0.637277 1.99799 0.699762 2.05996L3.52643 4.88663C3.5884 4.94911 3.66214 4.99871 3.74338 5.03256C3.82462 5.0664 3.91175 5.08383 3.99976 5.08383C4.08777 5.08383 4.17491 5.0664 4.25615 5.03256C4.33739 4.99871 4.41112 4.94911 4.4731 4.88663L7.3331 2.05996C7.39558 1.99799 7.44518 1.92425 7.47902 1.84301C7.51287 1.76177 7.5303 1.67464 7.5303 1.58663C7.5303 1.49862 7.51287 1.41148 7.47902 1.33024C7.44518 1.249 7.39558 1.17527 7.3331 1.1133Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat center center;
  opacity: .3;
  width: 8px;
  height: 8px;
  background-size: 100%;
}

:deep(.vs__actions) {
  padding: 0;
}

.vs--multiple :deep(.vs__selected-options .vs__selected) {
  color: #fff;
  background-color: #21BF73;
  margin: 5px;
  border-radius: 3px;
  padding: 6px;
  font-size: 0.625rem;
  font-family: inherit;
  line-height: 1;
  display: flex;
}

.vs--multiple :deep(.vs__selected-options .vs__selected .vs__deselect) {
  order: -1;
  margin-left: 0;
  margin-right: 2px;
}

.vs--multiple :deep(.vs__selected-options .vs__selected .vs__deselect svg) {
  transform: scale(.6);
  fill: #fff;
}

.option-title {
  white-space: pre-wrap;
  display: inline-flex;
}
</style>
