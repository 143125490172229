<template>
  <modal
    ref="modal"
    id="proxies-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Добавления Proxy</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group" :class="apiValidationErrors['proxy'] ? 'has-danger' : ''">
        <label for="proxies">Proxies</label>
        <textarea class="form-control" id="proxies" name="proxies" type="text" tabindex="1"
                  v-model="proxies"
                  :class="apiValidationErrors['proxy'] ? 'form-control-danger' : ''"
                  placeholder="Введите прокси, каждую с новой строки"
                  rows="7"
                  @focus="clearError('proxy')" :disabled="processing">
        </textarea>
        <label v-if="apiValidationErrors['proxy']" class="error mt-2 text-danger"
               for="proxies">{{ apiValidationErrors['proxy'][0] }}</label>
      </div>
      <template v-if="processing">
        <div class="d-flex justify-content-between">
          <small>Добавлено Proxy</small>
          <small>{{ row }}/{{ total }}</small>
        </div>
        <div class="progress progress-lg mt-2">
          <div class="progress-bar bg-success"
               role="progressbar"
               :style="{width: percent + '%'}"
               :aria-valuenow="percent"
               aria-valuemin="0" aria-valuemax="100">
            {{ percent }}%
          </div>
        </div>
      </template>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'

const defaultValues = () => ({
  proxies: '',
  proxiesArray: [],
  row: 0,
  percent: 0,
  added: 0
})

export default {
  name: 'ProxyForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      processing: false,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    total () {
      return this.proxiesArray.length
    }
  },
  methods: {
    handlerCreate () {
      this.skip()
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      let wrongProxies = []
      const addedProxies = []
      const errorsPull = []
      this.proxiesArray = this.proxies.split('\n')
      for (let proxy of this.proxiesArray) {
        if (proxy !== '') {
          try {
            await this.$store.dispatch('adminProxies/create', { proxy: proxy.trim() })
            addedProxies.push(proxy)
          } catch (error) {
            const errors = error.response.data.errors || []
            errors.filter(v => v.param === 'proxy')
            errorsPull.push(`${errors[0].value} - ${errors[0].msg}`)
            wrongProxies.push(proxy)
          }
        }
        this.row += 1
        this.percent = Math.ceil(100 / this.total * this.row)
      }
      if (!Object.keys(this.apiValidationErrors).length) {
        if (wrongProxies.length) {
          for (const addedProxy of addedProxies) {
            wrongProxies = wrongProxies.filter(v => v !== addedProxy)
          }
          this.proxies = wrongProxies.join('\n')
          this.added = this.total - wrongProxies.length
          const ok = confirm(`Добавлено ${this.added} из ${this.total} proxy.\nProxy которые ещё не добавлены остались в поле\nПоказать ошибки?`)
          if (ok) {
            alert(errorsPull.join('\n'))
          }
          this.processing = false
          this.row = 0
          this.percent = 0
        } else {
          this.resetApiValidation()
          this.$refs.modal.close()
          this.resolvePromise(true)
        }
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
