import middleware from '@/store/modules/middleware'
import api from '@/store/services/api'

export default {
  async fetchData ({commit, dispatch}, params) {
    try {
      const data = await api.get('transactions?' + new URLSearchParams(params).toString())
      commit('setPageData', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}
