import service from '@/store/services/admin/cloudflares-service'
import middleware from '../middleware'

const state = () => ({
  count: 0,
  models: []
})

const mutations = {
  setData (state, payload) {
    const {models, count} = payload
    state.models = models
    state.count = count
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create ({commit, dispatch}, params) {
    return service.create(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  delete ({commit, dispatch}, params) {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count
}

const adminCloudflares = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminCloudflares
