import Vue from 'vue'
import {
  SET_ALL,
  ADD_FAQ_CONTENT_BY_ID
} from './mutation-types'

export default {
  [SET_ALL] (state, data) {
    Object.assign(state, data)
    return state
  },
  [ADD_FAQ_CONTENT_BY_ID] (state, data) {
    const itemObject = state.faqList?.find(item => item.id === data?.id)
    if (itemObject) {
      Vue.set(itemObject, 'content', data?.content)
      Vue.set(itemObject, 'loaded', true)
    }
  }
}
