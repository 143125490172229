export default {
  title: 'PUSH розсилки',
  add: 'Додати розсилку',
  reload: 'Оновити',
  types: {
    postponed: 'Відкладена',
    interval: 'Інтервальна'
  },
  filters: {
    name: 'Пошук за назвою',
    status: 'Фільтр за статусом',
    range: {
      placeholder: 'Фільтр за датою',
      today: 'Сьогодні',
      tomorrow: 'Завтра',
      nextSevenDay: 'Наступні 7 днів'
    },
    pwa: 'Фільтр PWA',
    domains: 'Фильтр за доменом',
    country: 'Фільтр за країною',
    conversions: {
      placeholder: 'Фільтр за конверсіями',
      options: ['З конверсіями', 'Без конверсій']
    },
    type: 'Фільтр за типом'
  },
  form: {
    formTitle: 'Форма налаштування розсилки',
    message: 'Налаштування розсилки та контенту для надсилання',
    success: 'PUSH розсилка успішно збережена',
    preview: 'Прев\'ю',
    tags: {
      title: 'Теги розсилки',
      info: 'Набір тегів для фільтрації розсилки'
    },
    name: {
      title: 'Назва',
      info: 'Системна назва розсилки',
      placeholder: 'Введіть назву розсилки'
    },
    publishedAt: {
      title: 'Початок',
      info: 'Дата початку розсилки',
      placeholder: 'Введіть дату'
    },
    title: {
      title: 'Заголовок',
      info: 'Заголовок PUSH сповіщення. Рекомендована довжина до 37 символів. Підтримується spintax',
      placeholder: 'Введіть заголовок'
    },
    body: {
      title: 'Текст',
      info: 'Текст PUSH сповіщення. Рекомендована довжина до 50 символів. Підтримується spintax',
      placeholder: 'Введіть текст'
    },
    url: {
      title: 'Посилання',
      info: 'Посилання, яке відкриється при натисканні. Якщо не вказати, то буде автоматично відкривати URL встановлений в PWA',
      placeholder: 'Введіть посилання'
    },
    badge: {
      title: 'Значок',
      info: 'Зображення, яке використовується для подання сповіщення, коли для відображення самого сповіщення недостатньо місця. Ми обріжемо картинку до рекомендованих розмірів (96 x 96)',
      placeholder: 'Завантажте значок'
    },
    icon: {
      title: 'Іконка',
      info: 'Зображення використовуване як іконка сповіщення. Ми обріжемо картинку до рекомендованих розмірів (192 x 192)',
      placeholder: 'Завантажте іконку'
    },
    image: {
      title: 'Зображення',
      info: 'Зображення, яке буде відображатися як частина сповіщення. Ми обріжемо картинку до рекомендованих розмірів (492 x 225)',
      placeholder: 'Завантажте зображення'
    },
    dir: {
      title: 'Напрямок тексту',
      info: 'Вказує напрям тексту PUSH сповіщення',
      options: ['Авто', 'Ліво вправо', 'Вправо вліво']
    },
    renotify: {
      title: 'Повторно сповіщати',
      info: 'Чи слід сповіщати користувача після того, як нове сповіщення замінить старе'
    },
    requireInteraction: {
      title: 'Чекати взаємодії',
      info: 'Сповістка повинна залишатися активною доти, доки користувач не клацне його або не закриє, а не закривається автоматично'
    },
    silent: {
      title: 'Тихий режим',
      info: 'Вказує, чи повідомлення має бути беззвучним, тобто не повинні видаватися звуки або вібрації, незалежно від налаштувань пристрою'
    },
    segmentation: {
      title: 'Сегментація PUSH',
      message: 'Якщо нічого не заповнювати, то розсилка буде по всій вашій базі',
      total: 'Всього',
      showedCount: 'Показів',
      clickedCount: 'Кліків',
      cappedCount: 'Закриттів',
      registeredCount: 'Реєстрацій',
      depositedCount: 'Депозитів',
      pwas: {
        placeholder: 'Виберіть одне або кілька PWA'
      },
      domains: {
        title: 'Домени',
        placeholder: 'Виберіть один або кілька доменів'
      },
      tags: {
        title: 'Теги PWA',
        placeholder: 'Виберіть один або кілька тегів PWA'
      },
      countries: {
        title: 'Країни',
        placeholder: 'Виберіть одну або кілька країн'
      },
      registered: {
        title: 'Реєстрація',
        info: 'Надіслати тим користувачам, які зареєстровані в додатку або тим, хто не зареєстрований',
        options: ['Не використовується', 'Зареєстровані', 'Не зареєстровані']
      },
      deposited: {
        title: 'Депозит',
        info: 'Надіслати тим користувачам, які зробили депозит програми або тим, хто ні',
        options: ['Не використовується', 'Зробили депозит', 'Не зробили']
      },
      showed: {
        title: 'Покази',
        info: 'Надіслати тим користувачам які вже отримували сповіщення або тим, хто не отримував',
        options: ['Не використовується', 'Отримували', 'Не отримували']
      },
      capped: {
        title: 'Закриття',
        info: 'Надіслати тим користувачам які закривали сповіщення або тим, хто не закривав',
        options: ['Не використовується', 'Закривали', 'Не закривали']
      },
      clicked: {
        title: 'Кліки',
        info: 'Надіслати тим користувачам які клікали на сповіщення або тим, хто не клікав',
        options: ['Не використовується', 'Клікали', 'Не клікали']
      }
    },
    timezone: {
      label: 'Часовий пояс',
      info: 'Коректор часових поясів'
    },
    country: {
      label: 'Країна',
      info: 'Оберіть країну щоб відфільтрувати часові пояси',
      error: 'Невдалося знайти часовий пояс, оберіть його самі'
    }
  },
  heads: {
    count: {
      label: 'Кл. розсилок',
      title: 'Загальна кількість всіх розсилок'
    },
    name: {
      label: 'Назва',
      title: 'Системна назва розсилки'
    },
    tags: {
      label: 'Теги',
      title: 'Теги для фільтрації розсилки'
    },
    status: {
      label: 'Статус',
      title: 'Статус розсилки'
    },
    type: {
      label: 'Тип',
      title: 'Тип розсилки'
    },
    publishedAt: {
      label: 'Початок',
      title: 'Дата початку розсилки'
    },
    interval: {
      label: 'Наступний',
      title: 'Наступний запуск розсилки'
    },
    total: {
      label: 'Всього',
      title: 'Всього'
    },
    remaining: {
      label: 'Залишилось',
      title: 'Залишилось'
    },
    delivered: {
      label: 'Доставлено',
      title: 'Доставлено'
    },
    showed: {
      label: 'Показів',
      title: 'Показів'
    },
    clicked: {
      label: 'Кліків',
      title: 'Кліків'
    },
    ctr: {
      label: 'CTR',
      title: 'Відсоткове співвідношення кліки/доставлено. Розраховується за формулою CTR = кліки/доставлено*100'
    },
    unsubscribed: {
      label: 'Відписалося',
      title: 'Відписалося'
    },
    registered: {
      label: 'Реги',
      title: 'Зареєструвалося'
    },
    deposited: {
      label: 'Депи',
      title: 'Зробило депозит'
    },
    countries: {
      label: 'Країни',
      title: 'Країни по яких робиться розсилка',
      all: 'По всіх'
    }
  },
  statuses: {
    suspended: 'Призупинено',
    launched: 'Запущено',
    postponed: 'Відкладено',
    sending: 'Надсилання',
    done: 'Виконано'
  },
  copy: {
    title: 'Копіювання PUSH розсилки',
    message: 'Ви дійсно хочете копіювати розсилку {name}',
    button: 'Копіювати',
    success: 'PUSH розсилка скопійована'
  },
  delete: {
    title: 'Видалення PUSH розсилки',
    message: 'Ви дійсно хочете видалити розсилку {name}',
    button: 'Видалити',
    success: 'PUSH розсилка видалена'
  },
  template: {
    title: 'Шаблони PUSH розсилки',
    source: 'Шаблон',
    button: 'Шаблон розсилки'
  },
  templates: {
    title: 'Шаблони',
    add: 'Додати шаблон',
    success: 'Шаблон розсилки успішно збережено',
    filters: {
      name: 'Пошук за назвою',
      title: 'Пошук по заголовку',
      body: 'Пошук за текстом'
    },
    form: {
      formTitle: 'Форма налаштування шаблону розсилки',
      message: 'Налаштування розсилки та контенту для надсилання',
      tags: {
        title: 'Теги розсилки',
        info: 'Набір тегів для фільтрації розсилки'
      },
      name: {
        title: 'Назва',
        info: 'Системна назва шаблону',
        placeholder: 'Введіть назву шаблону'
      },
      title: {
        title: 'Заголовок',
        info: 'Заголовок PUSH повідомлення. Рекомендована довжина до 37 символів. Підтримується spintax',
        placeholder: 'Введіть заголовок'
      },
      body: {
        title: 'Текст',
        info: 'Текст PUSH повідомлення. Рекомендована довжина до 50 символів. Підтримується spintax',
        placeholder: 'Введіть текст'
      },
      url: {
        title: 'Посилання',
        info: 'Посилання, яке відкриється при натисканні. Якщо не вказати, то автоматично відкриватиме URL встановлений у PWA',
        placeholder: 'Введіть посилання'
      },
      badge: {
        title: 'Значок',
        info: 'Зображення, яке використовується для подання повідомлення, коли для відображення самого повідомлення недостатньо місця. Ми обріжемо картинку до рекомендованих розмірів (96 x 96)',
        placeholder: 'Завантажте значок'
      },
      icon: {
        title: 'Іконка',
        info: 'Зображення використовуване як іконка сповіщення. Ми обріжемо картинку до рекомендованих розмірів (192 x 192)',
        placeholder: 'Завантажте значок'
      },
      image: {
        title: 'Зображення',
        info: 'Зображення, яке буде відображатися як частина сповіщення. Ми обріжемо картинку до рекомендованих розмірів (492 x 225)',
        placeholder: 'Завантажте зображення'
      },
      dir: {
        title: 'Напрямок тексту',
        info: 'Вказує напрям тексту PUSH повідомлення',
        options: ['Авто', 'Ліво вправо', 'Вправо вліво']
      },
      renotify: {
        title: 'Повторно сповіщати',
        info: 'Чи слід повідомляти користувача після того, як нове повідомлення замінить старе'
      },
      requireInteraction: {
        title: 'Чекати кліка',
        info: 'Сповістка повинна залишатися активною доти, доки користувач не клацне його або не закриє, а не закривається автоматично'
      },
      silent: {
        title: 'Тихий режим',
        info: 'Вказує, чи повідомлення має бути беззвучним, тобто не повинні видаватися звуки або вібрації, незалежно від налаштувань пристрою'
      }
    },
    heads: {
      name: {
        label: 'Назва',
        title: 'Системна назва шаблону'
      },
      tags: {
        label: 'Теги',
        title: 'Теги для фільтрації шаблону'
      },
      title: {
        label: 'Заголовок',
        title: 'Заголовок шаблону'
      },
      body: {
        label: 'Текст',
        title: 'Текст шаблону'
      },
      createdAt: {
        label: 'Створено',
        title: 'Дата створення шаблону'
      }
    },
    delete: {
      title: 'Видалення шаблону PUSH розсилки',
      message: 'Ви дійсно хочете видалити шаблон {name}',
      button: 'Видалити',
      success: 'Шаблон розсилки видалений'
    },
    template: {
      title: 'PUSH розсилки',
      source: 'Розсилка',
      button: 'З розсилки'
    }
  },
  intervals: {
    title: 'Інтервальна розсилка',
    intervalError: 'Виберіть хоча б один день',
    chooseCountry: 'Будь ласка, оберіть країну!',
    status: 'Статус',
    heads: {
      name: {
        label: 'Назва',
        title: 'Системна назва розсилки(Розсилок зроблено)'
      }
    }
  },
  statistics: {
    count: {
      title: 'Кількість PUSH',
      info: 'Кількість активних PUSH підписок за весь час'
    },
    registered: {
      title: 'Кількість реєстрацій',
      info: 'Кількість уніків які зареєструвалися будучи при цьому підписані на PUSH'
    },
    deposited: {
      title: 'Кількість депозитів',
      info: 'Кількість уніків які внесли депозити, будучи при цьому підписані на PUSH'
    }
  },
  actions: {
    title: 'За дією',
    interval: {
      title: 'Через певний час',
      type: {
        minutes: 'Хвилин | Хвилину | Хвилини | Хвилин',
        hours: 'Годин | Годину | Години | Годин',
        days: 'Днів | День | Дні | Днів',
        months: 'Місяців | Місяць | Місяці | Місяців'
      }
    },
    heads: {
      after: {
        label: 'Після дії',
        title: 'Розсилка спрацює після налаштованої дії'
      }
    },
    after: {
      minutes: 'Через {count} хвилин | Через хвилину | Через {count} хвилин | Через {count} хвилин',
      hours: 'Через {count} годин | Через годину | Через {count} години | Через {count} годин',
      days: 'Через {count} днів | Через день | Через {count} дні | Через {count} днів',
      months: 'Через {count} місяців | Через місяць | Через {count} місяці | Через {count} місяців'
    }
  },
  group: {
    successRemove: 'PUSH розсилка видалена',
    successCopy: 'PUSH розсилка скопійована'
  },
  table: {
    publishedAt: 'Серверний час відправки за UTC 0 {time}'
  }
}
