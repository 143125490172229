import create from './create/pt'
import components from './components/pt'
import tracker from './tracker/pt'

/* eslint-disable no-template-curly-in-string */
export default {
  headers: {
    domain: 'Domínio',
    domainInfo: 'Nome de domínio de seu PWA',
    expired: 'Remoção',
    expiredInfo: 'Falta para eliminação automática, permanente de PWA'
  },
  trash: {
    move: 'Transferir para o cesto',
    empty: 'Cesto de PWA está vazio',
    moveMessage: 'Tem certeza que quer transferir para o cesto o PWA {name}? Terá 2 dias para recuperar',
    moveMessageRelation: 'Tem certeza que quer transferir para o cesto o PWA {name}? Terá 2 dias para recuperar. Este PWA também será removido dos Jogos Principais em outros PWAs.',
    moveButton: 'Para o cesto',
    moveSuccess: 'PWA foi transferido para o cesto com sucesso',
    restore: 'Recuperar o PWA',
    restoreTitle: 'Recuperar o PWA {name}?',
    restoreMessage: 'Tem certeza que quer recuperar o PWA {name}?',
    restoreButton: 'Recuperar',
    restoreSuccess: 'PWA foi recuperado com sucesso',
    goToPWA: 'Ir ao PWA'
  },
  createNew: 'Criar o PWA',
  pwaTitle: 'Nome do seu PWA',
  status: 'Estatuto',
  statusTitle: 'Estatuto do seu PWA',
  tags: 'Tags',
  tagsTitle: 'Tags da finalidade de PWA',
  trackerHead: 'Tracker',
  trackerTitle: 'Informação sobre o tracker corrente ligado ao PWA',
  filters: {
    search: 'Busca por domínio',
    tag: 'Filtro por tag',
    addTag: 'Adicionar uma nova tag',
    status: 'Filtro por estatuto',
    vertical: 'Filtro por âmbito',
    category: 'Filtro por categoria'
  },
  buttons: {
    checkDomain: 'Verificar domínio',
    run: 'Ativar',
    stop: 'Parar',
    pay: 'Pagar',
    preview: 'Pré-visualização',
    cloak: {
      buttonTitle: 'Baixar JS script',
      title: 'JS para usar Cloak',
      body: `Adiciona esta código JS no final da secção de tag <body> antes de tag de encerramento </body>`
    },
    customJs: {
      buttonTitle: 'Inserir seu JS script',
      title: 'JS de utilizador',
      body: 'Este código JS será adicionado ao seu PWA',
      bulkBody: 'Este código JS será adicionado aos seus PWAs',
      success: 'JS de utilizador foi guardado'
    },
    meta: {
      buttonTitle: 'Inserir seus META tags',
      title: 'META tags de utilizador',
      body: 'Estes META tags serão adicionados ao seu PWA',
      bulkBody: 'Estas etiquetas META serão adicionadas aos seus PWAs',
      success: 'META tags de utilizador foram guardados'
    }
  },
  checkDomain: {
    title: 'Verificação de domínio',
    body: [
      'Seu domínio ainda não foi verificado. A verificação do domínio pode demorar até 2 horas',
      'Domínio <strong>{domain}</strong> não tem https protocolo. Para funcionamento correto deve ativar https Rewrite',
      'Domínio <strong>{domain}</strong> foi verificado',
      'Domínio <strong>{domain}</strong> não está coberto com <strong>CloudFlare</strong>'
    ]
  },
  delete: {
    title: 'Apagar o PWA',
    message: 'Tem certeza que quer apagar PWA {name}?',
    button: 'Apagar',
    success: 'PWA foi removido'
  },
  copy: {
    title: 'Copiar PWA',
    message: 'Tem certeza que quer copiar PWA {name}?',
    button: 'Copiar',
    success: 'PWA foi copiado',
    tracker: {
      message: 'Copiar {name} com tracker ou sem?',
      okButton: 'Com tracker',
      cancelButton: 'Sem tracker'
    }
  },
  paid: {
    title: 'Ativação do PWA',
    warning: 'Depois da ativação do PWA seria impossível alterar o domínio!',
    button: 'Ativar',
    success: 'PWA foi ativado',
    cancel: 'Ativação foi cancelada',
    error: 'Erro de ativação',
    noTariff: 'Para trabalhar com PWA é necessário ativar um plano',
    paidTariff: `Tem uma ativação no plano. Depois da ativação da PWA não terá mais ativações. Ativar o PWA?
| Tem uma {count} ativação no plano. Depois da ativação da PWA ainda terá uma ativação. Ativar o PWA?
| Tem {count} ativações no plano. Depois da ativação da PWA ainda terá {countLeft} ativação. Ativar o PWA?
| Tem {count} ativações no plano. Depois da ativação da PWA terá {countLeft} ativações. Ativar o PWA?`,
    paidBalance: 'Caducou a sua ativação do plano, pode ativar o PWA além do plano por ${amount}.',
    noMoney: 'Caduco a ativação no plano, pode ativar o PWA fora do plano por ${amount} ou adquirir outro plano. Para o tal é necessário carregar o saldo.',
    paidInstalls: 'Ativação no plano de instalações é grátis, mas cada instalação de PWA é paga'
  },
  play: {
    title: 'Ativar o PWA',
    message: 'Tem certeza que quer Ativar o PWA {name}?',
    button: 'Ativar',
    success: 'PWA está ativo'
  },
  stop: {
    title: 'Parar PWA',
    message: 'Tem certeza que quer parar PWA {name}?',
    button: 'Parar',
    success: 'PWA foi parado'
  },
  notFoundByFilters: 'Não foram encontrados PWA que atendem os termos de filtração de',
  noHave: 'Ainda não tem PWA criados. Clicar em "Criar o PWA"',
  insufficientFunds: 'Falta de meios',
  save: 'PWA foi guardado',
  verticals: ['Nutra', 'Betting', 'Dayting', 'Gambling', 'Trading', 'Criptomoedas'],
  statuses: ['Bloqueado', 'Verificação de domínio', 'Verificação de HTTPs', 'Não pago', 'Parado', 'A funcionar', 'Paragem técnica'],
  categories: [
    'Automóveis e transportes',
    'Bibliotecas e demoversões',
    'Negócios',
    'Leitores e editores de vídeo',
    'Arte e design',
    'Comida e bebidas',
    'Jogos',
    'Ferramentas',
    'Habitação e casas',
    'Saúde e fitness',
    'Encontros',
    'Mapas e navegação',
    'Livros',
    'Banda desenhada',
    'Beleza',
    'Maternidade e infância',
    'Medicina',
    'Eventos',
    'Música',
    'Notícias e revistas',
    'Educação',
    'Estilo de vida',
    'Comunicação',
    'Personalização',
    'Tempo',
    'Compras',
    'Viagens',
    'Emprego',
    'Entretenimento',
    'Social',
    'Desporto',
    'Estilo de vida',
    'Finanças',
    'Fotografia'
  ],
  create,
  components,
  tracker,
  group: {
    successRemove: 'Os PWAs foram removidos com sucesso',
    successCopy: 'Os PWAs foram copiados com sucesso',
    successTrash: 'Os PWAs foram transferidos com sucesso para o lixo',
    successRestore: 'Os PWAs foram restaurados com sucesso',
    successPlay: 'Os PWAs estão a funcionar',
    successStop: 'Os PWAs foram interrompidos',
    successMeta: 'Etiquetas META personalizadas guardadas',
    successCustomJs: 'O JS personalizado é guardado'
  },
  pushPlacement: {
    pageIsLoaded: 'Quando a página é carregada',
    trafficBackIsTriggered: 'Quando o tráfego de retorno é acionado',
    afterInstall: 'Após a instalação',
    afterOpen: 'Após a abertura do PWA'
  },
  pushPlacementTooltips: {
    pageIsLoaded: 'Quando a página é carregada',
    trafficBackIsTriggered: 'Quando o tráfego de retorno é acionado',
    afterInstall: 'Após a instalação',
    afterOpen: 'Após a abertura do PWA'
  }
}
