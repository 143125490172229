import service from '@/store/services/bills-service'
import middleware from './middleware'

const state = {
  list: {},
  bill: {}
}

const mutations = {
  setList: (state, payload) => {
    state.list = payload
  },
  setBill: (state, payload) => {
    state.bill = payload
  }
}

const actions = {
  index: ({commit, dispatch}, params) => {
    return service.index(params)
      .then(response => {
        commit('setList', {
          data: response.data,
          itemsCount: response.itemsCount
        })
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create: ({commit, dispatch}, params) => {
    return service.create(params)
      .then(response => {
        commit('setBill', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  get: ({commit, dispatch}, params) => {
    return service.get(params)
      .then(response => {
        commit('setBill', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  list: state => state.list,
  bill: state => state.bill
}

const bills = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default bills
