<template>
  <div class="item_toggsl_g active">
    <div class="head_tit_sl_togg">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ $t('pwas.components.app.main.title') }}</span>
        <info :message="$t('pwas.components.app.main.titleInfo')"/>
      </div>
    </div>
    <div class="content_dropd_g" style="display: block">
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob" :class="apiValidationErrors.name ? 'has-danger' : ''">
          <label for="name">{{ $t('pwas.components.app.main.name') }}
            <info :message="$t('pwas.components.app.main.nameInfo')"/>
          </label>
          <input type="text" id="name" :placeholder="$t('pwas.components.app.main.name')"
                 v-model="value.languages[lang].name" class="form-control"
                 :class="apiValidationErrors.name ? 'form-control-danger' : ''"
                 @focus="clearError('name')" v-if="lang">
          <input type="text" id="name" :placeholder="$t('pwas.components.app.main.name')" v-model="value.name"
                 class="form-control"
                 :class="apiValidationErrors.name ? 'form-control-danger' : ''"
                 @focus="clearError('name')" v-else>
          <label v-if="apiValidationErrors.name" class="error mt-2 text-danger" for="name">
            {{ apiValidationErrors.name[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob" :class="apiValidationErrors.author ? 'has-danger' : ''">
          <label for="author">{{ $t('pwas.components.app.main.author') }}
            <info :message="$t('pwas.components.app.main.authorInfo')"/>
          </label>
          <input type="text" id="author" :placeholder="$t('pwas.components.app.main.author')"
                 v-model="value.languages[lang].author" class="form-control"
                 :class="apiValidationErrors.author ? 'form-control-danger' : ''"
                 @focus="clearError('author')" v-if="lang">
          <input type="text" id="author" :placeholder="$t('pwas.components.app.main.author')"
                 v-model="value.author" class="form-control"
                 :class="apiValidationErrors.author ? 'form-control-danger' : ''"
                 @focus="clearError('author')" v-else>
          <label v-if="apiValidationErrors.author" class="error mt-2 text-danger" for="author">
            {{ apiValidationErrors.author[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob" :class="apiValidationErrors.ad ? 'has-danger' : ''">
          <label for="ad">{{ $t('pwas.components.app.main.ad') }}
            <info :message="$t('pwas.components.app.main.adInfo')"/>
          </label>
          <input type="text" id="ad" :placeholder="$t('pwas.components.app.main.ad')"
                 v-model="value.languages[lang].ad" class="form-control"
                 :class="apiValidationErrors.ad ? 'form-control-danger' : ''"
                 @focus="clearError('ad')" v-if="lang">
          <input type="text" id="ad" :placeholder="$t('pwas.components.app.main.ad')" v-model="value.ad"
                 class="form-control"
                 :class="apiValidationErrors.ad ? 'form-control-danger' : ''"
                 @focus="clearError('ad')" v-else>
          <label v-if="apiValidationErrors.ad" class="error mt-2 text-danger" for="ad">
            {{ apiValidationErrors.ad[0] }}
          </label>
        </div>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.category ? 'has-danger' : ''">
          <label for="category">{{ $t('pwas.components.app.main.category') }}
            <info :message="$t('pwas.components.app.main.categoryInfo')"/>
          </label>
          <select type="text" id="category" v-model="value.category" class="form-control"
                  :class="apiValidationErrors.category ? 'form-control-danger' : ''"
                  @focus="clearError('category')">
            <option :key="category" v-for="category in $store.getters['pwas/categories']"
                    :value="$t(category)">{{ $t(category) }}
            </option>
          </select>
          <label v-if="apiValidationErrors.category" class="error mt-2 text-danger" for="category">
            {{ apiValidationErrors.category[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.category_label ? 'has-danger' : ''">
          <label for="category_label">{{ $t('pwas.components.app.main.categoryInscription') }}
            <info
              :message="$t('pwas.components.app.main.categoryInscriptionInfo')"/>
          </label>
          <input type="text" id="category_label"
                 v-model="value.languages[lang].category_label" class="form-control"
                 :class="apiValidationErrors.category_label ? 'form-control-danger' : ''"
                 @focus="clearError('category_label')" v-if="lang">
          <input type="text" id="category_label" v-model="value.category_label" class="form-control"
                 :class="apiValidationErrors.category_label ? 'form-control-danger' : ''"
                 @focus="clearError('category_label')" v-else>
          <label v-if="apiValidationErrors.category_label" class="error mt-2 text-danger"
                 for="category_label">
            {{ apiValidationErrors.category_label[0] }}
          </label>
        </div>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob" :class="apiValidationErrors.rating ? 'has-danger' : ''">
          <label for="main-rating">{{ $t('pwas.components.app.rating.title') }}
            <info :message="$t('pwas.components.app.rating.ratingInfo')"/>
          </label>
          <input type="number" step="0.1" min="1" max="5" id="main-rating"
                 :placeholder="$t('pwas.components.app.rating.title')"
                 v-model="value.rating" class="form-control"
                 :class="apiValidationErrors.rating ? 'form-control-danger' : ''"
                 @focus="clearError('rating')">
          <label v-if="apiValidationErrors.rating" class="error mt-2 text-danger" for="main-rating">
            {{ apiValidationErrors.rating[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.count_comments ? 'has-danger' : ''">
          <label for="main-count_comments">{{ $t('pwas.components.app.rating.countComments') }}
            <info :message="$t('pwas.components.app.rating.countCommentsInfo')"/>
          </label>
          <input type="number" step="1" min="0" id="main-count_comments"
                 :placeholder="$t('pwas.components.app.rating.countComments')"
                 v-model="value.count_comments"
                 class="form-control"
                 :class="apiValidationErrors.count_comments ? 'form-control-danger' : ''"
                 @focus="clearError('count_comments')">
          <label v-if="apiValidationErrors.count_comments" class="error mt-2 text-danger"
                 for="main-count_comments">
            {{ apiValidationErrors.count_comments[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.commentLabel ? 'has-danger' : ''">
          <label for="commentLabel">{{ $t('pwas.components.app.main.commentLabel') }}
            <info :message="$t('pwas.components.app.main.commentLabelInfo')"/>
          </label>
          <input v-if="lang" type="text" id="commentLabel" :placeholder="$t('pwas.components.app.main.commentLabel')"
                 v-model="value.languages[lang].commentLabel" class="form-control"
                 :class="apiValidationErrors.commentLabel ? 'form-control-danger' : ''"
                 @focus="clearError('commentLabel')">
          <input v-else type="text" id="commentLabel" :placeholder="$t('pwas.components.app.main.commentLabel')"
                 v-model="value.commentLabel" class="form-control"
                 :class="apiValidationErrors.commentLabel ? 'form-control-danger' : ''"
                 @focus="clearError('commentLabel')">
          <label v-if="apiValidationErrors.commentLabel" class="error mt-2 text-danger"
                 for="commentLabel">
            {{ apiValidationErrors.commentLabel[0] }}
          </label>
        </div>
        <BaseFromGroup
          id="size"
          class="col col_half_mob"
          :label="$t('pwas.components.app.main.sizeM')"
          :info-text="$t('pwas.components.app.main.sizeM')"
          :has-error="apiValidationErrors.size"
          :error-text="apiValidationErrors?.size ? apiValidationErrors?.size[0] : ''"
        >
        <BaseInput
          v-if="lang"
            :class="apiValidationErrors.size ? 'has-danger' : ''"
            name="size"
            :error="Boolean(apiValidationErrors.size) ? apiValidationErrors.size[0] : ''"
            :is-error="Boolean(apiValidationErrors.size)"
            @focus="clearError('size')"
            v-model="value.languages[lang].size"
            :value="value.languages[lang].size"
            :message="$t('pwas.components.app.main.sizeM')"
            :placeholder="$t('pwas.components.app.main.sizeM')"
          />
          <BaseInput
            v-else
            :class="apiValidationErrors.size ? 'has-danger' : ''"
            name="size"
            :error="Boolean(apiValidationErrors.size) ? apiValidationErrors.size[0] : ''"
            :is-error="Boolean(apiValidationErrors.size)"
            @focus="clearError('size')"
            v-model="value.size"
            :value="value.size"
            :message="$t('pwas.components.app.main.sizeM')"
            :placeholder="$t('pwas.components.app.main.sizeM')"
          />
        </BaseFromGroup>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.installed ? 'has-danger' : ''">
          <label for="main-installed">{{ $t('pwas.components.app.additionalInfo.countInstall') }}
            <info :message="$t('pwas.components.app.additionalInfo.countInstallInfo')"/>
          </label>
          <input v-if="lang" type="text" id="main-installed" :placeholder="$t('pwas.components.app.additionalInfo.installs')"
                 v-model="value.languages[lang].installed"
                 class="form-control"
                 :class="apiValidationErrors.installed ? 'form-control-danger' : ''"
                 @focus="clearError('installed')">
          <input v-else type="text" id="main-installed" :placeholder="$t('pwas.components.app.additionalInfo.installs')"
                 v-model="value.installed"
                 class="form-control"
                 :class="apiValidationErrors.installed ? 'form-control-danger' : ''"
                 @focus="clearError('installed')">
          <label v-if="apiValidationErrors.installed" class="error mt-2 text-danger" for="main-installed">
            {{ apiValidationErrors.installed[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.installed_label ? 'has-danger' : ''">
          <label for="main-installed_label">{{ $t('pwas.components.app.additionalInfo.installs') }}
            <info :message="$t('pwas.components.app.additionalInfo.installsInfo')"/>
          </label>
          <input type="text" id="main-installed_label"
                 :placeholder="$t('pwas.components.app.additionalInfo.installs')"
                 v-model="value.languages[lang].installed_label" class="form-control"
                 :class="apiValidationErrors.installed_label ? 'form-control-danger' : ''"
                 @focus="clearError('installed_label')" v-if="lang">
          <input type="text" id="main-installed_label"
                 :placeholder="$t('pwas.components.app.additionalInfo.installs')"
                 v-model="value.installed_label"
                 class="form-control"
                 :class="apiValidationErrors.installed_label ? 'form-control-danger' : ''"
                 @focus="clearError('installed_label')" v-else>
          <label v-if="apiValidationErrors.installed_label" class="error mt-2 text-danger"
                 for="main-installed_label">
            {{ apiValidationErrors.installed_label[0] }}
          </label>
        </div>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob" :class="apiValidationErrors.age ? 'has-danger' : ''">
          <label for="age">{{ $t('pwas.components.app.main.age') }}
            <info :message="$t('pwas.components.app.main.ageInfo')"/>
          </label>
          <input v-if="lang" type="text" id="age" :placeholder="$t('pwas.components.app.main.age')" v-model="value.languages[lang].age"
                 class="form-control"
                 :class="apiValidationErrors.age ? 'form-control-danger' : ''"
                 @focus="clearError('age')">
          <input v-else type="text" id="age" :placeholder="$t('pwas.components.app.main.age')" v-model="value.age"
                 class="form-control"
                 :class="apiValidationErrors.age ? 'form-control-danger' : ''"
                 @focus="clearError('age')">
          <label v-if="apiValidationErrors.age" class="error mt-2 text-danger" for="age">
            {{ apiValidationErrors.age[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.age_label ? 'has-danger' : ''">
          <label for="age_label">{{ $t('pwas.components.app.main.ageM') }}
            <info :message="$t('pwas.components.app.main.ageMInfo')"/>
          </label>
          <input type="text" id="age_label" :placeholder="$t('pwas.components.app.main.ageM')"
                 v-model="value.languages[lang].age_label" class="form-control"
                 :class="apiValidationErrors.age_label ? 'form-control-danger' : ''"
                 @focus="clearError('age_label')" v-if="lang">
          <input type="text" id="age_label" :placeholder="$t('pwas.components.app.main.ageM')"
                 v-model="value.age_label"
                 class="form-control"
                 :class="apiValidationErrors.age_label ? 'form-control-danger' : ''"
                 @focus="clearError('age_label')" v-else>
          <label v-if="apiValidationErrors.age_label" class="error mt-2 text-danger" for="age_label">
            {{ apiValidationErrors.age_label[0] }}
          </label>
        </div>
      </div>
      <hr>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.version ? 'has-danger' : ''">
          <label for="version">{{ $t('pwas.components.app.main.recommendationD') }}
            <info :message="$t('pwas.components.app.main.recommendationDInfo')"/>
          </label>
          <input type="text" id="version" :placeholder="$t('pwas.components.app.main.recommendationD')"
                 v-model="value.languages[lang].version" class="form-control"
                 :class="apiValidationErrors.version ? 'form-control-danger' : ''"
                 @focus="clearError('version')" v-if="lang">
          <input type="text" id="version" :placeholder="$t('pwas.components.app.main.recommendationD')"
                 v-model="value.version" class="form-control"
                 :class="apiValidationErrors.version ? 'form-control-danger' : ''"
                 @focus="clearError('version')" v-else>
          <label v-if="apiValidationErrors.version" class="error mt-2 text-danger" for="version">
            {{ apiValidationErrors.version[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.wish_list ? 'has-danger' : ''">
          <label for="wish_list">{{ $t('pwas.components.app.main.wishlistD') }}
            <info :message="$t('pwas.components.app.main.wishlistDInfo')"/>
          </label>
          <input type="text" id="wish_list" :placeholder="$t('pwas.components.app.main.wishlistD')"
                 v-model="value.languages[lang].wish_list" class="form-control"
                 :class="apiValidationErrors.wish_list ? 'form-control-danger' : ''"
                 @focus="clearError('wish_list')" v-if="lang">
          <input type="text" id="wish_list" :placeholder="$t('pwas.components.app.main.wishlistD')"
                 v-model="value.wish_list"
                 class="form-control"
                 :class="apiValidationErrors.wish_list ? 'form-control-danger' : ''"
                 @focus="clearError('wish_list')" v-else>
          <label v-if="apiValidationErrors.wish_list" class="error mt-2 text-danger" for="wish_list">
            {{ apiValidationErrors.wish_list[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.canInstall ? 'has-danger' : ''">
          <label for="canInstall">{{ $t('pwas.components.app.main.canInstall') }}
            <info :message="$t('pwas.components.app.main.canInstallInfo')"/>
          </label>
          <input v-if="lang" type="text" id="canInstall" :placeholder="$t('pwas.components.app.main.canInstall')"
                 v-model="value.languages[lang].canInstall" class="form-control"
                 :class="apiValidationErrors.canInstall ? 'form-control-danger' : ''"
                 @focus="clearError('wish_list')">
          <input v-else type="text" id="canInstall" :placeholder="$t('pwas.components.app.main.canInstall')"
                 v-model="value.canInstall" class="form-control"
                 :class="apiValidationErrors.canInstall ? 'form-control-danger' : ''"
                 @focus="clearError('canInstall')">
          <label v-if="apiValidationErrors.canInstall" class="error mt-2 text-danger"
                 for="canInstall">
            {{ apiValidationErrors.canInstall[0] }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'

export default {
  name: 'NewMainApp',
  mixins: [formMixin],
  components: {Info, BaseInput, BaseFromGroup},
  props: {
    value: {
      type: Object,
      require: true
    },
    errors: {
      type: Object,
      require: true
    }
  },
  computed: {
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  }
}
</script>
