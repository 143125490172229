import service from '@/store/services/admin/system-service'
import middleware from '../middleware'
import pwas from '@/store/modules/pwas-module'

const state = () => ({
  queues: [],
  queue: {
    active: 0,
    delayed: 0,
    failed: 0,
    newestJob: 0,
    succeeded: 0,
    waiting: 0
  },
  migrationIPs: {
    models: [],
    count: 0
  }
})

const mutations = {
  setQueues (state, payload) {
    state.queues = payload
  },
  setQueue (state, payload) {
    state.queue = payload
  },
  setMigrationIPs (state, payload) {
    state.migrationIPs = payload
  }
}

const actions = {
  async asyncDataQueues ({commit, dispatch}, params) {
    try {
      const queues = await service.getQueues(params)

      commit('setQueues', queues)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async asyncDataQueuesCloudflareMigrate ({commit, dispatch}, params) {
    try {
      const queue = await service.getCloudflareMigrateQueue(params)

      commit('setQueue', queue)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async asyncMigrationIPs ({commit, dispatch}, params) {
    try {
      const migrationIPs = await service.getMigrationIPs(params)

      commit('setMigrationIPs', migrationIPs)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async migrationIPs ({commit, dispatch}, params) {
    try {
      await service.migrationIPs(params)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  queues: state => state.queues,
  migrationIPs (state) {
    const migrationIPs = []
    const _statuses = pwas.getters.statuses(pwas.state())
    for (const model of state.migrationIPs.models) {
      model.status = _statuses.findIndex(value => value.id === model.status)
      model.status = _statuses[model.status]
      migrationIPs.push(model)
    }
    return migrationIPs
  },
  migrationIPsCount: state => state.migrationIPs.count,
  queue: state => state.queue
}

const adminSystem = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminSystem
