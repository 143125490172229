import * as dictionary from '@pwa.group/pwa.dictionary'

export default {
  computed: {
    select2FiltersSettings () {
      const format = state => {
        if (!state.id) {
          return state.text
        }
        if (state.wp) {
          return `<i class="flag-icon ${this.getFlagIcon(state.id)}"></i> ${state.text}`
        } else {
          return `<span class="text-warning"><i class="flag-icon ${this.getFlagIcon(state.id)}"></i> ${state.text}</span>`
        }
      }
      return {
        placeholder: this.$t('trackers.form.country'),
        templateResult: format,
        templateSelection: format,
        escapeMarkup: function (m) {
          return m
        }
      }
    },
    select2FiltersOptions () {
      const countries = []
      for (const [id, datum] of Object.entries(dictionary.trackers)) {
        let text = this.$t(datum.country)
        text += ` (${this.$t(datum.locale)})`
        countries.push({id, text, wp: datum.whitepage})
      }
      return countries
    },
    countryOptions () {
      const countries = []
      for (const [id, datum] of Object.entries(dictionary.trackers)) {
        let text = this.$t(datum.country)
        text += ` (${this.$t(datum.locale)})`
        countries.push({id, text})
      }
      return countries
    }
  },
  methods: {
    getCountryName (code) {
      if (code === 'be_BE') {
        code = 'nl_BE'
      }
      return this.$t(dictionary.trackers[code].country)
    },
    getFlagIcon (code) {
      if (code === 'be_BE') {
        code = 'nl_BE'
      }
      return 'flag-icon-' + code.split('_')[1].toLowerCase()
    },
    applyTemplate (url, params, remove = false) {
      if (url === '' || url == null) {
        return ''
      }
      url = new URL(url)
      const searchParams = new URLSearchParams(params)
      searchParams.forEach((value, key) => {
        if (remove) {
          url.searchParams.delete(key)
        } else {
          url.searchParams.set(key, value)
        }
      })
      return decodeURI(url.toString()).replaceAll('%3A', ':')
    }
  }
}
