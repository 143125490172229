import Tracker from './tracker.vue'
import autoSave from '@/middleware/auto-save'

export default (meta, header) => {
  return [
    {
      path: 'pwas/:id/tracker',
      name: 'PWAsTracker',
      components: {default: Tracker, header: header},
      meta: {...meta, before: autoSave}
    }
  ]
}
