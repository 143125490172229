<template>
  <div>
    <div class="row">
      <div class="col-md-6 col-lg mb-2">
        <input v-model="username" class="form-control" placeholder="Поиск по имени"/>
      </div>
      <owner-filter v-model="owner" class="col-md-6 col-lg mb-2"/>
      <!-- <div class="col-md-6 col-lg mb-2">
         <select v-model="group" class="form-control">
          <option value="" selected>Поиск по группе</option>
          <option v-for="group of $store.getters['adminMembers/groups']" :key="group" :value="group">{{ group }}</option>
        </select>
      </div> -->
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {OwnerFilter} from '@/pages/admin/components'

export default {
  name: 'MemberFilter',
  components: {OwnerFilter},
  created () {
    this.resetFilter()
  },
  computed: {
    username: {
      get () {
        return this.$store.getters['adminMembers/filter'].username
      },
      set: _.debounce(function (input) {
        this.setterFilter('username', input)
      }, 500)
    },
    owner: {
      get () {
        return this.$store.getters['adminMembers/filter'].owner
      },
      set: _.debounce(function (input) {
        this.setterFilter('owner', input)
      }, 500)
    }
    // group: {
    //   get () {
    //     return this.$store.getters['adminMembers/filter'].group
    //   },
    //   set: _.debounce(function (input) {
    //     this.setterFilter('group', input)
    //   }, 500)
    // }
  },
  methods: {
    setterFilter (filter, input) {
      const payload = this.$store.getters['adminMembers/filter']
      payload[filter] = input
      this.$store.dispatch('adminMembers/setFilter', payload)
      this.$emit('change', true)
    },
    resetFilter () {
      this.$store.dispatch('adminMembers/setFilter', {
        username: '',
        owner: ''
      })
      this.$emit('change', true)
    }
  }
}
</script>
