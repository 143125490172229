<template>
  <div class="CheckboxFilter item_silter_sjhf_g">
    <BaseSelect
      :placeholder="placeholder"
      @input="onInput"
      :value="updateValue(value)"
      :options="options"
      :key="placeholder"
      :disabled="disabled"
    >
    </BaseSelect>
  </div>
</template>
<script>
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
export default {
  name: 'CheckboxFilter',
  components: { BaseSelect },
  props: ['value', 'endpoint', 'label', 'id', 'disabled'],
  computed: {
    placeholder () {
      const self = this
      return this.$t(`analytics.filtersLabel.${self.id.split('-')[0]}`, {param: self.id.split('-')[1]})
    },
    options () {
      return [
        {
          id: 'yes',
          text: this.$t('general.yes')
        },
        {
          id: 'no',
          text: this.$t('general.no')
        }
      ]
    }
  },
  methods: {
    updateValue (v) {
      if (v === '') return ''
      return v === 1 ? 'yes' : 'no'
    },
    onInput (value) {
      if (value === '') this.$emit('input', '')
      else this.$emit('input', value === 'yes' ? 1 : 0)
    }
  }
}
</script>
