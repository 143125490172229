import api from '@/store/services/api'
import middleware from '../modules/middleware'

export default {
  async topPWAOnline () {
    try {
      return await api.get('dashboard/top-pwa-online')
    } catch (error) {
      middleware.validate401(error)
      throw error
    }
  },
  async topOnline () {
    try {
      return await api.get('dashboard/top-online')
    } catch (error) {
      middleware.validate401(error)
      throw error
    }
  },
  async activity () {
    try {
      return await api.get('dashboard/activity')
    } catch (error) {
      middleware.validate401(error)
      throw error
    }
  },
  async countUniques () {
    try {
      return await api.get('dashboard/count-uniques')
    } catch (error) {
      middleware.validate401(error)
      throw error
    }
  },
  async progress () {
    try {
      return await api.get('dashboard/progress')
    } catch (error) {
      middleware.validate401(error)
      throw error
    }
  },
  async transactions () {
    try {
      return await api.get('dashboard/transactions')
    } catch (error) {
      middleware.validate401(error)
      throw error
    }
  }
}
