<template>
  <div class="VacanciesPage page-full-fluid-width">
    <div class="row">
      <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="mb-10">
              <h4 class="card-title">{{ $t('vacancies.title') }}</h4>
            </div>
            <div class="vacancies">
              <div class="vacancies__item" v-for="(item, index) in items" :key="index">
                <div class="flex-none position_vacancy">
                  <div class="vacancies__item-title">{{ item.function }}</div>
                  <div class="vacancies__item-subtitle">{{ item.direction }}</div>
                </div>
                <div class="vacancies__item-description">
                  <ul>
                    <li v-for="(skill, index) in item.skills" :key="index">
                      {{ skill }}
                    </li>
                  </ul>
                </div>
                <div>
                  <a :href="item.action" target="_blank"
                    class="ful_butt_green d_flex align_center justify_center mobile-full-w">
                    {{ $t('vacancies.action') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleMixin from '@/mixins/title-mixin'

export default {
  name: 'Vacancies',
  title () {
    return this.$t('menu.vacancies')
  },
  mixins: [titleMixin],
  data () {
    return {
      items: [
        {
          function: 'Buyer',
          direction: 'Запрошуємо до команди якщо маєш:',
          skills: [
            'Досвід роботи від 1,5 роки',
            'Досвід роботи з лінкою від 4 місяців',
            'Досвід працювати з бюджетами',
            'Скрінисвоїх заливів та кейсів',
            'Вміння працювати з антидетектами, автозаливами, таблицями',
            'Навички розбанів аків, проходження модерацій, передач РК і тощо'
          ],
          action: 'https://t.me/pwagroup_ceo'
        },
        {
          function: 'TeamLead',
          direction: 'Запрошуємо до команди якщо маєш:',
          skills: [
            'Місцепроживання - Київ',
            'Лідерські якості',
            'Вміння працювати з людьми та вирішувати конфліктні ситуіції',
            'Вміння находити рішення для заливу в скрутні моменти',
            'Досвід роботи з лінкою від 1 року',
            'Досвід працювати з бюджетами',
            'Скріни своїх заливів та кейсів',
            'Вміння працювати з антидетектами, автозаливами, таблицями',
            'Навички розбанів аків, проходження модерацій, передач РК і тощо'
          ],
          action: 'https://t.me/pwagroup_ceo'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.vacancies {
  margin-top: 40px;

  &__item {
    border-bottom: 1px solid #3F4347;
    padding-bottom: 40px;
    margin-bottom: 40px;
    display: grid;
    column-gap: 40px;
    grid-template-columns: 255px 1fr auto;

    &-title {
      color: #45B172;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: 130%;
    }

    &-subtitle {
      color: #707175;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
    }

    &-description {
      font-size: 14px;
      font-weight: 400;

      ul {
        list-style-type: disc;

        li {
          line-height: 17px;
        }
      }
    }

    &:last-child {
      padding-bottom: 0;
      border: 0;
    }
  }

  @media only screen and (max-width: 991px) {
    margin-top: 30px;

    &__item {
      display: block;
      padding-bottom: 30px;
      margin-bottom: 30px;

      &-title {
        font-size: 17px;
      }

      &-description {
        margin: 20px 0;

        ul {
          padding-left: 12px;

          li {
            line-height: 17px;
            margin-bottom: 8px;
          }
        }
      }
    }

    .position_vacancy {
      margin-bottom: 20px;
    }
  }
}
</style>
