import service from '@/store/services/billing-service'
import middleware from './middleware'

const state = () => ({
  bills: [],
  pay_url: '',
  count: 0,
  bill: {},
  amounts: [],
  paymentMethods: [],
  rates: {},
  minInstalledAmount: 0
})

const mutations = {
  setBills: (state, payload) => {
    state.bills = payload.bills
    state.count = payload.count
    state.amounts = payload.amounts
    state.paymentMethods = payload.paymentMethods
    state.rates = payload.rates
    state.minInstalledAmount = payload.minInstalledAmount
  },
  setPayUrl: (state, payload) => {
    state.pay_url = payload
  },
  setBill: (state, payload) => {
    state.bill = payload
  }
}

const actions = {

  index: ({commit, dispatch}, params) => service.index(params)
    .then(res => {
      commit('setBills', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  async create ({commit, dispatch}, params) {
    try {
      const res = await service.create(params)
      commit('setPayUrl', res)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },

  get: ({commit, dispatch}, params) => service.get(params)
    .then(res => {
      commit('setBill', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  setHash: ({ commit, dispatch }, params) => service.setHash(params)
    .then()
    .catch(error => {
      middleware.validateAut(error)
      throw error
    })

}

const getters = {
  bills: state => state.bills,
  count: state => state.count,
  payUrl: state => state.pay_url,
  bill: state => state.bill,
  amounts: state => state.amounts,
  paymentMethods: state => state.paymentMethods,
  rates: state => state.rates,
  minInstalledAmount: state => state.minInstalledAmount
}

const billing = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default billing
