<template>
  <div class="mr-md-4 mb-4 mb-md-0">
    <button type="button" class="simple_butt_afg mobile-full-w" @click="handlerOpen">{{ $t('pwas.components.translate.translate') }}
    </button>
    <modal ref="modal" id="translator-modal" class="fade"
           classContent="modal-content modal_padd_normal" classDialog="modal-dialog">
      <form class="style_form_lab">
        <button v-if="total === 0" type="button" class="close_sjgs_to d_flex align_center" @click="handlerCancel"><span
          aria-hidden="true">&times;</span>
        </button>
        <div class="modal_title_g" v-html="title"></div>
        <div v-if="total > 0" class="d-flex justify-content-between mt-2" v-html="$t('pwas.components.translate.process', {count, total})"></div>
        <div v-if="total > 0" class="progress progress-lg mt-2">
          <div class="progress-bar" role="progressbar"
               style="background: #21BF73;box-shadow: 0 4px 8px rgb(33 191 115 / 40%);border-radius: 3px;border: 1px solid #21BF73;"
               :style="{width: progress + '%'}"
               :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <select2
          v-if="total === 0"
          v-model="langFrom"
          :options="options"
          :settings="settings"
          class="form-group"
          id="langFrom"
          name="langFrom">
          <template v-slot:before>
            <label for="langFrom">{{ $t('pwas.components.translate.from') }}</label>
          </template>
        </select2>
        <select2
          v-if="total === 0"
          v-model="langTo"
          class="form-group"
          :options="options"
          :settings="settings"
          id="langTo"
          name="langTo">
          <template v-slot:before>
            <label for="langTo">{{ $t('pwas.components.translate.to') }}</label>
          </template>
        </select2>
        <div v-if="total === 0" class="modal_foot_this translator_foot">
          <div class="in_topshgdjf_g d_flex align_center">
            <div class="item_avt_butt_jg">
              <button type="button" class="ful_butt_green d_flex align_center justify_center" @click="handlerTranslate">
                {{ $t('pwas.components.translate.translate') }}
              </button>
            </div>
            <div v-if="!comments" class="item_avt_butt_jg has_comment">
              <button @click="handlerReturn" type="button" class="ful_butt_green d_flex align_center justify_center">
                {{ $t('pwas.components.translate.backToRu') }}
              </button>
            </div>
            <div class="item_avt_butt_jg">
              <a href="javascript:void(0);" type="button" class="border_butt_green d_flex align_center justify_center"
                 @click="handlerCancel">
                {{ $t('general.cancel') }}
              </a>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import translatorFormMixin from './translator-form.mixin'
import mainConnector from '@/connectors/main.connector'
import Select2 from '@/components/Select2.vue'

const defaultFields = () => ({
  langFrom: 'ru',
  langTo: 'en',
  progress: 0,
  count: 0,
  total: 0
})

export default {
  name: 'Translator',
  mixins: [translatorFormMixin],
  components: {Modal, Select2},
  props: {
    value: {
      type: Object,
      required: true
    },
    comments: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ...defaultFields()
    }
  },
  computed: {
    title () {
      return this.comments ? this.$t('pwas.components.translate.commentTitle', {id: this.value._id}) : this.$t('pwas.components.translate.title', {id: this.value._id})
    }
  },
  methods: {
    skip () {
      const fields = defaultFields()
      for (const [prop, value] of Object.entries(fields)) {
        this[prop] = value
      }
    },
    async handlerOpen () {
      this.skip()
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
    },
    async handlerReturn () {
      this.$emit('translate', this.$store.getters['translator/default'])
      this.handlerCancel()
    },
    async handlerTranslate () {
      try {
        if (this.comments) {
          const comments = []
          this.total = this.value.comments.length
          for (const comment of this.value.comments) {
            const {answer_author, answer_comment, author} = comment
            const translatedComment = await mainConnector.post(`pwas/${this.value._id}/comments/translate/`, {
              answer_author,
              answer_comment,
              author,
              comment: comment.comment,
              langTo: this.langTo,
              langFrom: this.langFrom,
              rows: {},
              id: this.count
            })
            comments.push({...comment, ...translatedComment})
            this.count++
            this.progress = Math.ceil(100 / this.total * this.count)
          }
          this.$emit('translate', comments)
        } else {
          await this.$store.dispatch('translator/pwa', {
            langFrom: this.langFrom,
            langTo: this.langTo,
            rows: {
              name: this.$parent.pwa.name || '',
              author: this.$parent.pwa.author || '',
              author_label: this.$parent.pwa.author_label || '',
              category_label: !this.$parent.pwa.category_label || this.$parent.pwa.category_label === '' ? this.$parent.pwa.category : this.$parent.pwa.category_label,
              ad: this.$parent.pwa.ad || '',
              rating_label: this.$parent.pwa.rating_label || '',
              age_label: this.$parent.pwa.age_label || '',
              version: this.$parent.pwa.version || '',
              wish_list: this.$parent.pwa.wish_list || '',
              install: this.$parent.pwa.install || '',
              installing: this.$parent.pwa.installing || '',
              download: this.$parent.pwa.download || '',
              open: this.$parent.pwa.open || '',
              about: this.$parent.pwa.about || '',
              read_more: this.$parent.pwa.read_more || '',
              hide: this.$parent.pwa.hide || '',
              text: this.$parent.pwa.text || '',
              changes_label: this.$parent.pwa.changes_label || '',
              changes_recent: this.$parent.pwa.changes_recent || '',
              reviews_label: this.$parent.pwa.reviews_label || '',
              total: this.$parent.pwa.total || '',
              addition_info: this.$parent.pwa.addition_info || '',
              updated: this.$parent.pwa.updated || '',
              updated_at: this.$parent.pwa.updated_at || '',
              size_label: this.$parent.pwa.size_label || '',
              installed_label: this.$parent.pwa.installed_label || '',
              current_version_label: this.$parent.pwa.current_version_label || '',
              ttHeader: this.$parent.pwa.ttHeader || '',
              ttYes: this.$parent.pwa.ttYes || '',
              ttNo: this.$parent.pwa.ttNo || '',
              commentLabel: this.$parent.pwa.commentLabel || '',
              canInstall: this.$parent.pwa.canInstall || '',
              dataSecurity: this.$parent.pwa.dataSecurity || '',
              dataSecurityText: this.$parent.pwa.dataSecurityText || '',
              dataSecurityLink: this.$parent.pwa.dataSecurityLink || '',
              noneInfo: this.$parent.pwa.noneInfo || '',
              reviewsVerified: this.$parent.pwa.reviewsVerified || '',
              reviewHelp: this.$parent.pwa.reviewHelp || '',
              reviewHelpTitle: this.$parent.pwa.reviewHelpTitle || '',
              reviewHelpYes: this.$parent.pwa.reviewHelpYes || '',
              reviewHelpNo: this.$parent.pwa.reviewHelpNo || '',
              reviewAll: this.$parent.pwa.reviewAll || '',
              complain: this.$parent.pwa.complain || '',
              developerContact: this.$parent.pwa.developerContact || '',
              developerSite: this.$parent.pwa.developerSite || '',
              developerUrl: this.$parent.pwa.developerUrl || '',
              developerEmailLabel: this.$parent.pwa.developerEmailLabel || '',
              developerEmail: this.$parent.pwa.developerEmail || '',
              developerAddressLabel: this.$parent.pwa.developerAddressLabel || '',
              developerAddress: this.$parent.pwa.developerAddress || '',
              politicsPrivacy: this.$parent.pwa.politicsPrivacy || '',
              politicsPrivacyUrl: this.$parent.pwa.politicsPrivacyUrl || '',
              cookiesText: this.$parent.pwa.cookiesText || '',
              cookiesAgree: this.$parent.pwa.cookiesAgree || '',
              cookiesDisagree: this.$parent.pwa.cookiesDisagree || ''
            }
          })
          this.$emit('translate', this.$store.getters['translator/pwa'])
        }
      } catch (error) {
        if (error.response) {
          await this.$store.dispatch('alerts/error', error.response.data.errors[0].msg)
        } else {
          await this.$store.dispatch('alerts/error', error.message)
        }
      }
      this.handlerCancel()
    }
  }
}
</script>
