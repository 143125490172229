<template>
  <div class="access-denied-dashboard">
    <img src="/images/dashboard/access-denied.png" alt="access denied">
    <p>{{ $t('validations.dashboardAccessDenied') }}</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.sass';
.access-denied-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  p {
    padding: 40px 32px;
    color: $yellow;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.00);
    background: linear-gradient(245deg, rgba(255, 255, 255, 0.00) -14.19%, rgba(255, 255, 255, 0.09) 100%);
    position: relative;
    top: -50px;
    left: -90px;
  }
}
</style>
