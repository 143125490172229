<template>
  <div class="style_form_lab">
    <div class="container_w_sm_la">
      <div class="style_descr_g">
        <div class="container_w_sm_la">
          <h4 class="title_sf_site">Створіть новий офери</h4>
          <p class="descr_site_shg mb_small">
            Для створення нового оферу вам необхідновнести назву офера на 3-х мовах, якщо вона
            пишеться однаково то продублюйте в усі поля. Також заповніть необхідні дані для PWA генератора
          </p>
        </div>
      </div>
      <bot-offer-form
        v-model="model"
        :errors="apiValidationErrors"
        @submit="handlerSubmit"
      />
    </div>
  </div>
</template>
<script>
import BotOfferForm from './_form.vue'
import {mapActions, mapGetters} from 'vuex'
import formMixin from '@/mixins/form-mixin'

export default {
  name: 'BotOffersCreate',
  components: {BotOfferForm},
  mixins: [formMixin],
  data () {
    return {
      model: {
        enable: false,
        name: {
          uk: '',
          en: '',
          ru: '',
          pt: ''
        },
        pwa: {
          name: '',
          vertical: ''
        },
        tracker: {
          template: '',
          redirect: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({offer: 'bot/offer'})
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      create: 'bot/createOffer'
    }),
    async handlerSubmit (payload) {
      try {
        this.resetApiValidation()
        await this.create(payload)
        this.resetApiValidation()
        await this.alertSuccess('Офер успішно створено')
        await this.$router.push({name: 'BotOffersImages', params: {id: this.offer.id}})
      } catch (e) {
        this.setApiValidation(e.response.data.errors)
      }
    }
  }
}
</script>
