<template>
  <div class="item_toggsl_g">
    <div class="head_tit_sl_togg">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ $t('pwas.components.app.cookies.title') }}</span>
        <info :message="$t('pwas.components.app.cookies.titleInfo')"/>
      </div>
    </div>
    <div class="content_dropd_g">
      <div class="row row_mob_sg">
        <div class="form-group col-12 col_half_mob" :class="apiValidationErrors.cookiesText ? 'has-danger' : ''">
          <label for="cookiesText">{{ $t('pwas.components.app.cookies.cookiesText') }}
            <info :message="$t('pwas.components.app.cookies.cookiesTextInfo')"/>
          </label>
          <input type="text" id="cookiesText" :placeholder="$t('pwas.components.app.cookies.cookiesText')"
                 v-model="value.languages[lang].cookiesText" class="form-control"
                 :class="apiValidationErrors.cookiesText ? 'form-control-danger' : ''"
                 @focus="clearError('cookiesText')" v-if="lang">
          <input type="text" id="cookiesText" :placeholder="$t('pwas.components.app.cookies.cookiesText')"
                 v-model="value.cookiesText"
                 class="form-control"
                 :class="apiValidationErrors.cookiesText ? 'form-control-danger' : ''"
                 @focus="clearError('cookiesText')" v-else>
          <label v-if="apiValidationErrors.cookiesText" class="error mt-2 text-danger" for="cookiesText">
            {{ apiValidationErrors.cookiesText[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.cookiesAgree ? 'has-danger' : ''">
          <label for="cookiesAgree">{{ $t('pwas.components.app.cookies.cookiesAgree') }}
            <info :message="$t('pwas.components.app.cookies.cookiesAgreeInfo')"/>
          </label>
          <input type="text" id="cookiesAgree" :placeholder="$t('pwas.components.app.cookies.cookiesAgree')"
                 v-model="value.languages[lang].cookiesAgree" class="form-control"
                 :class="apiValidationErrors.cookiesAgree ? 'form-control-danger' : ''"
                 @focus="clearError('cookiesAgree')" v-if="lang">
          <input type="text" id="cookiesAgree" :placeholder="$t('pwas.components.app.cookies.cookiesAgree')"
                 v-model="value.cookiesAgree"
                 class="form-control"
                 :class="apiValidationErrors.cookiesAgree ? 'form-control-danger' : ''"
                 @focus="clearError('cookiesAgree')" v-else>
          <label v-if="apiValidationErrors.cookiesAgree" class="error mt-2 text-danger" for="cookiesAgree">
            {{ apiValidationErrors.cookiesAgree[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob" :class="apiValidationErrors.cookiesDisagree ? 'has-danger' : ''">
          <label for="cookiesDisagree">{{ $t('pwas.components.app.cookies.cookiesDisagree') }}
            <info
              :message="$t('pwas.components.app.cookies.cookiesDisagreeInfo')"/>
          </label>
          <input type="text" id="cookiesDisagree" :placeholder="$t('pwas.components.app.cookies.cookiesDisagree')"
                 v-model="value.languages[lang].cookiesDisagree" class="form-control"
                 :class="apiValidationErrors.cookiesDisagree ? 'form-control-danger' : ''"
                 @focus="clearError('cookiesDisagree')" v-if="lang">
          <input type="text" id="cookiesDisagree" :placeholder="$t('pwas.components.app.cookies.cookiesDisagree')"
                 v-model="value.cookiesDisagree" class="form-control"
                 :class="apiValidationErrors.cookiesDisagree ? 'form-control-danger' : ''"
                 @focus="clearError('cookiesDisagree')" v-else>
          <label v-if="apiValidationErrors.cookiesDisagree" class="error mt-2 text-danger" for="cookiesDisagree">
            {{ apiValidationErrors.cookiesDisagree[0] }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'

export default {
  name: 'CookiesApp',
  mixins: [formMixin],
  components: {Info},
  props: {
    value: {
      type: Object,
      require: true
    },
    errors: {
      type: Object,
      require: true
    }
  },
  computed: {
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  }
}
</script>
