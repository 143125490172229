import service from '@/store/services/pwas-service'
import api from '../services/api'
import middleware from './middleware'
import Vue from 'vue'

const state = () => ({
  PWA: {},
  models: [],
  count: 0,
  trash: 0,
  statuses: [
    {id: -1, label: 'pwas.statuses[0]', class: 'danger'},
    {id: 0, label: 'pwas.statuses[1]', class: 'warning'},
    {id: 1, label: 'pwas.statuses[2]', class: 'warning'},
    {id: 2, label: 'pwas.statuses[3]', class: 'warning'},
    {id: 3, label: 'pwas.statuses[4]', class: 'info'},
    {id: 4, label: 'pwas.statuses[5]', class: 'primary'},
    {id: -2, label: 'pwas.statuses[6]', class: 'danger'}
  ],
  categories: [
    'pwas.categories[0]',
    'pwas.categories[1]',
    'pwas.categories[2]',
    'pwas.categories[3]',
    'pwas.categories[4]',
    'pwas.categories[5]',
    'pwas.categories[6]',
    'pwas.categories[7]',
    'pwas.categories[8]',
    'pwas.categories[9]',
    'pwas.categories[10]',
    'pwas.categories[11]',
    'pwas.categories[12]',
    'pwas.categories[13]',
    'pwas.categories[14]',
    'pwas.categories[15]',
    'pwas.categories[16]',
    'pwas.categories[17]',
    'pwas.categories[18]',
    'pwas.categories[19]',
    'pwas.categories[20]',
    'pwas.categories[21]',
    'pwas.categories[22]',
    'pwas.categories[23]',
    'pwas.categories[24]',
    'pwas.categories[25]',
    'pwas.categories[26]',
    'pwas.categories[27]',
    'pwas.categories[28]',
    'pwas.categories[29]',
    'pwas.categories[30]',
    'pwas.categories[31]',
    'pwas.categories[32]',
    'pwas.categories[33]'
  ],
  gallery: '',
  domainPage: {},
  trackerPage: {},
  settingPage: {},
  analyticsPage: {},
  verticalPage: {},
  commentsPage: {},
  check: {},
  generator: {
    total: 0,
    count: 0
  },
  bulkPaymentChecking: {payment: null}
})

const mutations = {
  setPageData (state, {models, count, trash}) {
    state.models = []
    for (const model of models) {
      if (model.images === -1) {
        model.images = model.gallery[0].length > 0 ? import.meta.env.VITE_APP_API_BASE_URL + model.gallery[0] : ''
      } else {
        const image = model.images + 1
        model.images = `${import.meta.env.VITE_APP_API_BASE_URL}/verticals/${model.vertical}/${image}/${image}.jpg`
      }

      model.status = state.statuses.findIndex(value => value.id === model.status)
      model.status = state.statuses[model.status]

      state.models.push(model)
    }
    state.count = count
    state.trash = trash
  },
  setPWA: (state, PWA) => {
    state.PWA = PWA
  },
  setGallery: (state, payload) => {
    state.gallery = payload
  },
  setDomainPage (state, payload) {
    state.cloudflare = payload.cloudflare
    state.domainPage = payload
  },
  setTrackerPage (state, payload) {
    state.trackerPage = payload
  },
  setSettingPage (state, payload) {
    const {tags, alias, setting, customJs, preLandingPage, postLandingPage} = payload
    state.settingPage.tags = tags
    state.settingPage.preLandingPage = preLandingPage
    state.settingPage.postLandingPage = postLandingPage
    state.settingPage.alias = alias
    state.settingPage.customJs = customJs
    if (setting) {
      state.settingPage.step = setting.installing.ranges.step
      state.settingPage.interval = setting.installing.ranges.interval
    }
  },
  setAnalyticsPage (state, payload) {
    state.analyticsPage = payload
  },
  setVerticalPage (state, payload) {
    state.verticalPage = payload
  },
  setCommentsPage (state, payload) {
    state.commentsPage = payload
  },
  setCheck (state, payload) {
    state.check = payload
  },
  setCheckedWP: (state, payload) => {
    state.trackerPage.checkedWP = payload
  },
  setGenerator: (state, payload) => {
    state.generator = payload
  },
  setBulkPaymentChecking (state, payload) {
    state.bulkPaymentChecking = payload
  },
  setClUrl (state, payload) {
    Vue.set(state.trackerPage.tracker, 'clUrl', payload)
  }
}

const actions = {
  setClUrl: ({commit}, payload) => {
    commit('setClUrl', payload)
  },
  setCheckedWP: ({commit}, payload) => {
    commit('setCheckedWP', payload)
  },
  asyncDomainPage ({commit}, payload) {
    return service.getDomainPage(payload)
      .then(payload => {
        commit('setDomainPage', payload)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  deleteCloudflare ({commit}, payload) {
    return service.deleteCloudflare(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  patch ({commit}, options) {
    return service.patch(options)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  asyncTrackerPage ({commit}, payload) {
    return service.getTrackerPage(payload)
      .then(payload => {
        commit('setTrackerPage', payload)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  updateTracker: ({commit, dispatch}, payload) => {
    return service.updateTracker(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  asyncSettingPage ({commit}, payload) {
    return service.getSettingPage(payload)
      .then(payload => {
        commit('setSettingPage', payload)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  updateSettings: ({commit, dispatch}, payload) => {
    return service.updateSettings(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  asyncAnalyticsPage ({commit}, payload) {
    return service.getAnalyticsPage(payload)
      .then(payload => {
        commit('setAnalyticsPage', payload)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  updateAnalytics: ({commit, dispatch}, payload) => {
    return service.updateAnalytics(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  asyncVerticalPage ({commit}, payload) {
    return service.getVerticalPage(payload)
      .then(payload => {
        commit('setVerticalPage', payload)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  updateVertical: ({commit, dispatch}, payload) => {
    return service.updateVertical(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  asyncCommentsPage ({commit}, payload) {
    return service.getCommentsPage(payload)
      .then(payload => {
        commit('setCommentsPage', payload)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  updateComments: ({commit, dispatch}, payload) => {
    return service.updateComments(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  async gallery ({commit, dispatch}, params) {
    try {
      const payload = await service.gallery(params)
      commit('setGallery', payload)
      return payload
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },

  galleryDelete: ({commit, dispatch}, params) => {
    return service.galleryDelete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  async fetchData ({commit}, options) {
    try {
      const data = await service.getList(options)
      commit('setPageData', data)
    } catch (error) {
      throw error
    }
  },

  createSelf: ({commit, dispatch}, payload) => {
    return service.createSelf(payload)
      .then(pwa => {
        commit('setPWA', pwa)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  createMarket: ({commit, dispatch}, payload) => {
    return service.createMarket(payload)
      .then(pwa => {
        commit('setPWA', pwa)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  createBought: ({commit, dispatch}, payload) => {
    return service.createBought(payload)
      .then(pwa => {
        commit('setPWA', pwa)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  updateDomainSelf: ({commit, dispatch}, payload) => {
    return service.updateDomainSelf(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  updateDomainMarket: ({commit, dispatch}, payload) => {
    return service.updateDomainMarket(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  updateDomainBought: ({commit, dispatch}, payload) => {
    return service.updateDomainBought(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  rebuildDomain: ({commit, dispatch}, payload) => {
    return service.rebuildDomain(payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  read: ({commit, dispatch}, params) => {
    return service.read(params)
      .then(pwa => {
        commit('setPWA', pwa)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  update: ({commit, dispatch}, params) => {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  delete: ({commit, dispatch}, params) => {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  comment: ({commit, dispatch}, params) => {
    return service.comment(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  verification: ({commit, dispatch}, params) => {
    return service.verification(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  fixCloudflare: ({commit, dispatch}, params) => {
    return service.fixCloudflare(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  paidCheck ({commit, dispatch}, id) {
    return service.paidCheck(id)
      .then((check) => {
        commit('setCheck', check)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  paidTariff ({commit, dispatch}, params) {
    return service.paidTariff(params)
      .then(() => {
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  paidBalance ({commit, dispatch}, params) {
    return service.paidBalance(params)
      .then(() => {
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  paidInstalls ({commit, dispatch}, params) {
    return service.paidInstalls(params)
      .then(() => {
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  play: ({commit, dispatch}, params) => {
    return service.play(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  playInstalls: ({commit, dispatch}, params) => {
    return service.playInstalls(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  stop: ({commit, dispatch}, params) => {
    return service.stop(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  copy: ({commit, dispatch}, params) => {
    return service.copy(params)
      .then(() => {
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  pwaGenerate ({commit, dispatch}, params) {
    return service.pwaGenerate(params)
      .then(({total}) => {
        commit('setGenerator', {total, count: 0})
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  setGenerator ({commit, dispatch}, payload) {
    commit('setGenerator', payload)
    return true
  },
  updateCustomJs ({commit, dispatch}, params) {
    return service.updateCustomJs(params)
      .then(() => {
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  async updateMeta ({commit, dispatch}, options) {
    try {
      await service.updateMeta(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkCopy (ctx, payload) {
    try {
      await service.bulkCopy(payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkRemove (ctx, ids) {
    try {
      await service.bulkRemove(ids)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkTrash ({commit}, options) {
    try {
      await service.bulkTrash(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkRestore ({commit}, options) {
    try {
      await service.bulkRestore(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkPlay ({commit}, options) {
    return service.bulkPlay(options)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  async bulkStop ({commit}, options) {
    return service.bulkStop(options)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  async bulkMeta ({commit}, options) {
    try {
      await service.bulkMeta(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkCustomJs ({commit}, options) {
    try {
      await service.bulkCustomJs(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkPaymentChecking ({commit}, options) {
    try {
      const bulkPaymentChecking = await service.bulkPaymentChecking(options)
      commit('setBulkPaymentChecking', bulkPaymentChecking)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkPaymentInstalls ({commit}, options) {
    try {
      await service.bulkPaymentInstalls(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkPaymentTariffs ({commit}, options) {
    try {
      await service.bulkPaymentTariffs(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async bulkPaymentBalance ({commit}, options) {
    try {
      await service.bulkPaymentBalance(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async getRelations (_, ids) {
    try {
      return api.post(`pwas/bulk/relation`, {
        ids
      })
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  domainPage: (state) => state.domainPage,
  generator: (state) => state.generator,
  trackerPage: (state) => state.trackerPage,
  settingPage: (state) => state.settingPage,
  analyticsPage: (state) => state.analyticsPage,
  verticalPage: (state) => state.verticalPage,
  commentsPage: (state) => state.commentsPage,
  models: state => state.models,
  count: state => state.count,
  trash: state => state.trash,
  PWA: state => state.PWA,
  statuses: state => state.statuses,
  categories: state => state.categories,
  gallery: state => state.gallery,
  check: state => state.check,
  bulkPaymentChecking: state => state.bulkPaymentChecking
}

const pwas = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default pwas
