<template>
  <div>
    <select class="form-control w-100" :id="id" v-model="value" style="width:100%!important;">
      <option value=""></option>
      <option :value="item._id" v-for="item of data" :key="item._id">
        {{ format(item) }}
      </option>
    </select>
  </div>
</template>
<script>
import {getCurrentLang} from '@/utils/i18n'

export default {
  name: 'OwnerFilter',
  props: {
    id: {
      type: String,
      default: 'owner-filter',
      required: false
    },
    placeholder: {
      type: String,
      default: 'Фильтр по пользователю'
    },
    data: {
      type: Array,
      default () {
        return []
      },
      required: false
    },
    init: {
      type: Boolean,
      default: true,
      required: false
    },
    value: [String, Number],
    ajaxUrl: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      selection: {}
    }
  },
  mounted () {
    if (this.init) {
      this.initSelect2()
    }
  },
  updated () {
    if (this.init) {
      this.initSelect2()
    }
  },
  methods: {
    format: repo => {
      if (!repo.first_name) {
        return repo.text
      }
      return '<img style="width: 40px;height: 40px;border-radius: 100%;margin-right: 5px;" src=\'' + (repo.photo_url || '/images/no-avatar.jpg') + '\' />' + `${repo.text} (${repo.first_name} ${repo.last_name || ''})`
    },
    initSelect2 () {
      const self = this
      const options = {
        placeholder: self.placeholder,
        templateResult: this.format,
        templateSelection (repo) {
          if (repo.first_name) {
            self.selection = {
              '_id': repo.id,
              text: repo.text,
              photo_url: repo.photo_url,
              first_name: repo.first_name,
              last_name: repo.last_name
            }
            return `${repo.text} (${repo.first_name} ${repo.last_name})`
          }
          return repo.text
        },
        escapeMarkup (m) {
          return m
        },
        allowClear: true
      }
      if (self.ajaxUrl != null && self.ajaxUrl !== '') {
        options.ajax = {
          dataType: 'json',
          url: import.meta.env.VITE_APP_API_BASE_URL + this.ajaxUrl,
          delay: 500,
          transport (params, success, failure) {
            // eslint-disable-next-line no-undef
            const $request = $.ajax({
              ...params,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('vue-authenticate.vueauth_accessToken'),
                'Accept-Language': getCurrentLang()
              }
            })
            $request.then(success)
            $request.fail(failure)
            return $request
          },
          data: params => {
            const filter = {}
            for (const [key, value] of Object.entries(self.$parent.filter)) {
              filter[`filter[${key}]`] = value
            }
            return {
              page: params.page || 1,
              limit: 5,
              sort: 'username',
              ...filter,
              text: params.term
            }
          }
        }
      } else {
        options.minimumInputLength = 2
        options.ajax = {
          dataType: 'json',
          url: import.meta.env.VITE_APP_API_BASE_URL + '/admin/users/list',
          delay: 500,
          transport (params, success, failure) {
            // eslint-disable-next-line no-undef
            const $request = $.ajax({
              ...params,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('vue-authenticate.vueauth_accessToken'),
                'Accept-Language': getCurrentLang()
              }
            })

            $request.then(success)
            $request.fail(failure)

            return $request
          }
        }
      }
      // eslint-disable-next-line no-undef
      jQuery('#' + this.id).select2(options).on('change', function (event) {
        self.$emit('input', event.target.value)
      })
    }
  }
}
</script>
