<template>
  <div class="item_silter_sjhf_g CountryFilter">
    <BaseSelect
      :endpoint="endpoint"
      :placeholder="$t(`analytics.filtersLabel.country`)"
      :filter="filter"
      @input="onInput"
      :value="value"
      :disabled="disabled"
      show-icon
      :translate="true"
    >
      <template v-if="value !== ''" #selected="{ text, id }">{{ getCountryName(value) }}</template>
      <template v-else #selected="{ text, id }">{{ getCountryName(id) }}</template>
      <template #option="{ text, id }">{{ getCountryName(id) }}</template>
    </BaseSelect>
  </div>
</template>
<script>
import * as dictionary from '@pwa.group/pwa.dictionary'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  name: 'CountryFilter',
  components: { BaseSelect },
  props: {
    value: String,
    endpoint: String,
    label: String,
    id: String,
    filterId: String,
    disabled: Boolean
  },
  computed: {
    filter () {
      return {
        'range[begin]': this.$store.getters['analytics/range'].begin,
        'range[end]': this.$store.getters['analytics/range'].end
      }
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    },
    getCountryName (code) {
      if (code) {
        const index = dictionary.countries.findIndex(country => country.id === code)
        return this.$t(dictionary.countries[index].text)
      }
    }
  }
}
</script>
