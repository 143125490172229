import service from '@/store/services/admin/gateways-service'
import middleware from '../middleware'

const state = {
  gateways: [],
  merchant: {},
  gateway: {},
  list: []
}

const mutations = {
  setGateways: (state, payload) => {
    state.gateways = payload
  },
  setGateway: (state, payload) => {
    state.gateway = payload
  },
  setMerchant: (state, payload) => {
    state.merchant = payload
  },
  setList: (state, payload) => {
    state.list = payload
  }
}

const actions = {
  index: ({commit, dispatch}, params) => service.index(params)
    .then(res => {
      commit('setGateways', res.gateways)
      commit('setMerchant', res.merchant)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  list: ({commit, dispatch}, params) => service.list()
    .then(res => {
      commit('setList', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  create: ({commit, dispatch}, params) => service.create(params)
    .then(res => {
      commit('setGateway', res)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  update: ({commit, dispatch}, params) => service.update(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  delete: ({commit, dispatch}, params) => service.delete(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  gateways: state => state.gateways,
  gateway: state => state.gateway,
  merchant: state => state.merchant,
  list: state => state.list
}

const adminGateways = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminGateways
