import Vue from 'vue'
import {
  SET_ALL,
  UPDATE_USER_MESSAGE
} from './mutation-types'

export default {
  [SET_ALL] (state, data) {
    Object.assign(state, data)
    return state
  },
  [UPDATE_USER_MESSAGE] (state, {id, ...payload}) {
    let index = state.notifications.findIndex(notification => notification.id === id)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        state.notifications[index][prop] = value
      }
    }
    index = state.mails.findIndex(mail => mail.id === id)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        state.mails[index][prop] = value
      }
    }
  },
  socket_getMessages (state, {mails, notifications}) {
    state.mails = mails
    state.notifications = notifications
  },
  socket_getBalance: (state, payload) => {
    state.balance = payload
  }
}
