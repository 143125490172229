<template>
  <div class="new-accounts">
    <ul class="chats" v-if="count > 0">
      <li class="chat-persons" v-for="item of data" :key="item._id">
        <div class="user">
          <p class="u-name">#{{ item._id }} ({{ filterStatus(item.status) }})</p>
          <p class="u-designation">${{ item.amount }} через {{ filterGateway(item.gateway) }}</p>
          <span class="d-flex align-items-center mt-2">
                                      <span class="btn btn-xs btn-rounded btn-outline-success mr-2" @click="handlerApprove(item._id)" v-if="[0, 1].includes(item.status)">Одобрить</span>
                                      <span class="btn btn-xs btn-rounded btn-outline-danger" @click="handlerReject(item._id)" v-if="[0, 1, 2].includes(item.status)">Отменить</span>
                                    </span>
        </div>
      </li>
    </ul>
    <span class="text-gray" v-else>( нет )</span>
  </div>
</template>
<script>
export default {
  name: 'Bills',
  data: function () {
    return {
      data: [],
      count: 0,
      statuses: {
        '0': 'Новый',
        '1': 'Оплачивается',
        '2': 'Оплачен',
        '-1': 'Просрочен',
        '-2': 'Отменён'
      }
    }
  },
  created: async function () {
    await this.$store.dispatch('adminGateways/list')
    await this.getData()
  },
  methods: {
    filterStatus: function (value) {
      return this.statuses[value.toString()]
    },
    filterGateway: function (gateway) {
      const index = this.$store.getters['adminGateways/list'].findIndex(value => value._id === gateway)
      return index === -1 ? 'Старая' : this.$store.getters['adminGateways/list'][index].name
    },
    getData: async function () {
      const filter = {owner: this.$route.params.id}
      await this.$store.dispatch('adminBilling/index', {
        limit: 5,
        page: 1,
        sort: '-updatedAt',
        filter
      })
      this.data = this.$store.getters['adminBilling/data']
      this.count = this.$store.getters['adminBilling/count']
    },
    handlerApprove: async function (_id) {
      if (confirm('Одобрить счет?')) {
        try {
          await this.$store.dispatch('adminBilling/approve', {_id})
          await this.$store.dispatch('alerts/success', 'Счет одобрен')
          await this.getData()
        } catch (e) {
          await this.$store.dispatch('alerts/error', e.message)
        }
      }
    },
    handlerReject: async function (_id) {
      if (confirm('Отклонить счет?')) {
        try {
          await this.$store.dispatch('adminBilling/reject', {_id})
          await this.$store.dispatch('alerts/success', 'Счет отклонён')
          await this.getData()
        } catch (e) {
          await this.$store.dispatch('alerts/error', e.message)
        }
      }
    }
  }
}
</script>
