<template>
  <div
    class="BaseInput form-group mb-3"
  >
    <div class="d_flex align_center">
      <input
        :type="inputType"
        :id="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :autofocus="autofocus"
        v-model="model"
        class="form-control mg_0"
        :class="isError ? 'form-control-danger' : ''"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      >
      <info
        v-if="message"
        class="mx_6 mr-0"
        :message="message"/>
    </div>
    <img
      v-if="type === 'password'"
      class="icon-eye"
      :src="`/images/svg-icons/${iconName}.svg`"
      alt="icon-eye"
      @click="togglePassword"
    />
    <label
      v-if="isError"
      :id="`${name}-error`"
      class="error mt-2 text-danger"
      :for="name"
    >
      {{ error }}
    </label>
  </div>
</template>
<script>

import Info from '@/components/Info.vue'

export default {
  name: 'BaseInput',
  props: {
    name: String,
    error: String,
    message: String,
    placeholder: String,
    isError: Boolean,
    disabled: Boolean,
    autofocus: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      required: true
    }
  },
  components: {
    Info
  },
  data () {
    return {
      iconName: 'icon-eye-open',
      inputType: this.type
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    togglePassword () {
      this.inputType = this.inputType === 'password' ? 'text' : 'password'
      this.iconName =
        this.inputType === 'password' ? 'icon-eye-open' : 'icon-eye-closed'
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;

  .icon-eye {
    position: absolute;
    top: 9px;
    right: 15px;
    cursor: pointer;
  }
}
input:disabled {
  color: #707175;
}
</style>
