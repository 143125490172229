<template>
  <modal
    ref="modal"
    id="ip-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div v-if="loading === false" class="modal-body">
      <div
        v-if="isCreate === true"
        :class="apiValidationErrors['ip'] ? 'has-danger' : ''"
        class="form-group">
        <label for="name">IP</label>
        <input class="form-control" id="ip" name="ip" type="text" tabindex="1"
               v-model="ip"
               :class="apiValidationErrors['ip'] ? 'form-control-danger' : ''"
               placeholder="Введите IP для домена"
               @focus="clearError('ip')">
        <label v-if="apiValidationErrors['ip']" class="error mt-2 text-danger"
               for="name">{{ apiValidationErrors['ip'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['description'] ? 'has-danger' : ''">
        <label for="name">Примітка</label>
        <input class="form-control" id="description" name="description" type="text" tabindex="1"
               v-model="description"
               :class="apiValidationErrors['description'] ? 'form-control-danger' : ''"
               placeholder="Введите description для домена"
               @focus="clearError('description')">
        <label v-if="apiValidationErrors['description']" class="error mt-2 text-danger"
               for="name">{{ apiValidationErrors['description'][0] }}</label>
      </div>
      <select2
        v-if="isCreate === false"
        v-model="status"
        :options="statuses"
        class="form-group"
        id="status"
        name="status"
        inputClass="form-control"
      >
        <template v-slot:before>
          <label for="status">Статус IP</label>
        </template>
      </select2>
      <datepicker
        id="expiryDate"
        v-model="expiryDate"
        container=".main-panel"
        inputClass="form-control"
        class="form-group"
        placeholder="Введіть дату закінчення"
        :class="apiValidationErrors['expiryDate'] ? 'has-danger' : ''"
      >
        <template v-slot:before>
          <label for="expiryDate">Дата закінчення</label>
        </template>
        <template v-slot:after>
        <label v-if="apiValidationErrors['expiryDate']" class="error mt-2 text-danger"
               for="name">{{ apiValidationErrors['expiryDate'][0] }}</label>
        </template>
      </datepicker>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import formMixin from '@/mixins/form-mixin'
import {mapActions, mapGetters} from 'vuex'
import Select2 from '@/components/Select2.vue'
import Datepicker from '@/components/Datepicker.vue'

const defaultValues = () => {
  const expiryDate = new Date()
  expiryDate.setMonth(expiryDate.getMonth() + 1)
  return {
    ip: '',
    status: 'new',
    description: '',
    expiryDate: expiryDate.toISOString().substring(0, 10),
    loading: true
  }
}

export default {
  name: 'AdminIpForm',
  components: {Datepicker, Modal, SubmitModalForm, Select2},
  mixins: [formMixin],
  data () {
    return {
      loading: true,
      processing: false,
      isCreate: true,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'ips/statuses'
    }),
    title () {
      return this.isCreate ? 'Створення IP' : `Редагування ${this.ip}`
    }
  },
  methods: {
    ...mapActions({
      create: 'ips/adminCreate',
      update: 'ips/adminUpdate'
    }),
    async handlerCreate () {
      this.skip()
      this.isCreate = true
      this.loading = false
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerUpdate ({id, status, description, expiryDate}) {
      this.skip()
      this.isCreate = false
      this.ip = id
      this.status = status
      this.description = description
      this.expiryDate = expiryDate.substring(0, 10)
      this.loading = false
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        if (this.isCreate) {
          await this.create({
            ip: this.ip,
            description: this.description,
            expiryDate: this.expiryDate
          })
        } else {
          await this.update({
            id: this.ip,
            description: this.description,
            status: this.status,
            expiryDate: this.expiryDate
          })
        }
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    handlerCancel () {
      this.$refs.modal.close()
    },
    async skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
