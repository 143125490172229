/* eslint-disable no-template-curly-in-string */
export default {
  bills: {
    title: 'Invoices',
    noHave: 'You have no accounts created. Top up your account',
    amountTopUp: 'TopUp Amount',
    topUp: 'Top up',
    status: 'Status',
    statusTitle: 'Current account status',
    amount: 'Amount',
    amountTitle: 'Amount to be credited to the account',
    total: 'Total',
    totalTitle: 'Amount in $ to be paid',
    method: 'Payment method',
    methodTitle: 'Bill payment method',
    created: 'Created',
    createdTitle: 'Invoice Creation Date',
    actions: 'Actions',
    paidSuccess: 'Invoice paid',
    paid: 'Paid',
    expiresIn: 'Expires in:',
    overdue: 'Overdue',
    rejected: 'Rejected',
    info: 'Info',
    modal: {
      title: 'Invoice #{id}',
      info: [
        `Invoice confirmed for <label class="text-warning">{confirmation}/6</label>, until
        the end of the automatic check remains
        <label class="text-warning">{expired}</label>, after that only manual
        confirmation`,
        `Waiting for notification of payment. If you were unable to pay the bill, you can do it again using the <a href="{pay_url}">link</a>`,
        'Invoice paid successfully'
      ]
    },
    bitcoin: {
      title: 'Make a payment to the specified account',
      confirmed: 'Confirmed',
      wallet: 'Wallet',
      paid: 'Paid',
      check: 'Check'
    },
    tether: {
      title: 'Make a payment to the specified account',
      wallet: 'Wallet',
      hash: {
        title: 'Transaction hash(txid)',
        placeholder: 'Insert transaction hash here',
        notEmpty: 'Cannot be empty'
      },
      paid: 'Paid'
    }
  },
  transactions: {
    title: 'Transactions',
    noHave: 'You have no transactions to display',
    created: 'Created',
    createdTitle: 'Transaction creation date',
    amount: 'Amount ',
    amountTitle: 'Transaction Amount in $',
    purpose: 'Purpose',
    purposeTitle: 'Transaction purpose',
    type: {
      pwa: 'Paying for PWA #{pwa}',
      domain: 'Purchasing a domain {domain} for PWA #{pwa}',
      referral: 'Affiliate royalties',
      bill: 'Add ${amount} to balance when paying bill #{bill}',
      tariff: 'Subscription to tariff #{tariff}',
      install: 'Install payment',
      bypass: 'Cost of installation through Bypass',
      ios: 'Installation cost if iOS',
      correction: 'Account correction',
      reject: 'Bill #{bill} canceled'
    }
  },
  payment: {
    minAmount: 'Minimum payment amount ${num}',
    maxAmount: 'Maximum payment amount ${num}',
    title: 'Make a payment to the specified account',
    method: 'Select payment system:',
    amount: 'Amount due:',
    pay: 'Pay'
  }
}
