export default {
  invalid: 'Неверное значение',
  domain: {
    wrongFormat: 'Не правильный формат домена или доменной зоны'
  },
  image: {
    maxSize: 'Размер файла не должен превышать 2 МБ.',
    required: 'Это изображение необходимо'
  },
  accessDenied: 'У вас нет доступа, пожалуйста, обратитесь к вашему администратору',
  dateOutOfRange: 'Выбранная дата за пределами месяца. Выберите дату в пределах месяца от начальной.',
  dashboardAccessDenied: 'Доступ к этим данным ограничен для вашей учетной записи',
  requestError: 'В настоящее время не удалось загрузить данные для отображения',
  requestError504: 'Что-то пошло не так, обратитесь к администратору!'
}
