<template>
  <div class="item_toggsl_g" :class="{ active: active }">
    <div class="head_tit_sl_togg" @click.prevent="handleSlide">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ header }}</span>
        <Info :message="info"/>
      </div>
    </div>
    <div class="content_dropd_g" style="display: block">
      <slot/>
    </div>
  </div>
</template>

<script>
import Info from '@/components/Info.vue'

export default {
  components: { Info },
  props: {
    header: { type: String, required: true },
    info: { type: String, required: true }
  },
  data () {
    return {
      active: true
    }
  },
  computed: {
    uniqId () {
      return `toggle_${this._uid}`
    }
  },
  methods: {
    handleSlide () {
      this.active = !this.active
      // eslint-disable-next-line no-undef
      const contentDropdown = $(this.$el).find('.content_dropd_g')
      if (this.active) {
        contentDropdown.slideDown(200)
      } else {
        contentDropdown.slideUp(200)
      }
    }
  }
}
</script>
