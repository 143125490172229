<template>
  <loading v-model="loading" class="content-wrapper">
    <div class="row grid-margin" v-if="pwa">
      <setting :pwa="pwa"/>
      <tracker :pwa="pwa"/>
    </div>
    <app :pwa="pwa"/>
    <comments :pwa="pwa"/>
  </loading>
</template>
<script>
import _ from 'lodash'
import * as components from '../components'
import {mapActions, mapGetters} from 'vuex'
import Loading from '@/pages/admin/components/Loading.vue'

export default {
  name: 'AdminPWA',
  components: {
    tracker: components.widgets.Tracker,
    app: components.widgets.App,
    comments: components.widgets.Comments,
    setting: components.widgets.Setting,
    Loading
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      pwa: 'adminPWAs/pwa'
    })
  },
  async created () {
    this.fetch = _.debounce(this.fetch, 500)
    await this.fetch()
  },
  methods: {
    ...mapActions({
      getPWA: 'adminPWAs/getPWA'
    }),
    async fetch () {
      this.loading = true
      await this.getPWA(this.$route.params.id)
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
