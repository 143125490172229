<template>
  <div class="item_toggsl_g">
    <div class="head_tit_sl_togg">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ $t('pwas.components.app.description.title') }}</span>
        <info :message="$t('pwas.components.app.description.titleInfo')"/>
      </div>
    </div>
    <div class="content_dropd_g">
      <div class="form-group" :class="apiValidationErrors.about ? 'has-danger' : ''">
        <label for="about">{{ $t('pwas.components.app.description.aboutM') }}
          <info :message="$t('pwas.components.app.description.aboutMInfo')"/>
        </label>
        <input type="text" id="about" :placeholder="$t('pwas.components.app.description.aboutM')"
               v-model="value.languages[lang].about" class="form-control"
               :class="apiValidationErrors.about ? 'form-control-danger' : ''"
               @focus="clearError('about')" v-if="lang">
        <input type="text" id="about" :placeholder="$t('pwas.components.app.description.aboutM')"
               v-model="value.about"
               class="form-control"
               :class="apiValidationErrors.about ? 'form-control-danger' : ''"
               @focus="clearError('about')" v-else>
        <label v-if="apiValidationErrors.about" class="error mt-2 text-danger" for="about">
          {{ apiValidationErrors.about[0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.text ? 'has-danger' : ''">
        <label for="text">{{ $t('pwas.components.app.description.description') }}
          <info :message="$t('pwas.components.app.description.descriptionInfo')"/>
        </label>
        <textarea id="text" rows="4" class="form-control"
                  v-model="value.languages[lang].text"
                  :placeholder="$t('pwas.components.app.description.descriptionPlaceholder')"
                  :class="apiValidationErrors.text ? 'form-control-danger' : ''"
                  @focus="clearError('text')" v-if="lang"></textarea>
        <textarea id="text" rows="4" class="form-control"
                  v-model="value.text"
                  :placeholder="$t('pwas.components.app.description.descriptionPlaceholder')"
                  :class="apiValidationErrors.text ? 'form-control-danger' : ''"
                  @focus="clearError('text')" v-else></textarea>
        <label v-if="apiValidationErrors.text" class="error mt-2 text-danger" for="text">
          {{ apiValidationErrors.text[0] }}
        </label>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob" :class="apiValidationErrors.updated ? 'has-danger' : ''">
          <label for="updated">{{ $t('pwas.components.app.additionalInfo.updated') }}
            <info :message="$t('pwas.components.app.additionalInfo.updatedInfo')"/>
          </label>
          <input type="text" id="updated" :placeholder="$t('pwas.components.app.additionalInfo.updated')"
                 v-model="value.languages[lang].updated" class="form-control"
                 :class="apiValidationErrors.updated ? 'form-control-danger' : ''"
                 @focus="clearError('updated')" v-if="lang">
          <input type="text" id="updated" :placeholder="$t('pwas.components.app.additionalInfo.updated')"
                 v-model="value.updated"
                 class="form-control"
                 :class="apiValidationErrors.updated ? 'form-control-danger' : ''"
                 @focus="clearError('updated')" v-else>
          <label v-if="apiValidationErrors.updated" class="error mt-2 text-danger" for="updated">
            {{ apiValidationErrors.updated[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.updated_at ? 'has-danger' : ''">
          <label for="updated_at">{{ $t('pwas.components.app.additionalInfo.updatedDate') }}
            <info :message="$t('pwas.components.app.additionalInfo.updatedDateInfo')"/>
          </label>
          <input type="text" id="updated_at" :placeholder="$t('pwas.components.app.additionalInfo.updated')"
                 v-model="value.languages[lang].updated_at" class="form-control"
                 :class="apiValidationErrors.total ? 'form-control-danger' : ''"
                 @focus="clearError('updated_at')" v-if="lang">
          <input type="text" id="updated_at" :placeholder="$t('pwas.components.app.additionalInfo.updated')"
                 v-model="value.updated_at"
                 class="form-control"
                 :class="apiValidationErrors.updated_at ? 'form-control-danger' : ''"
                 @focus="clearError('updated_at')" v-else>
          <label v-if="apiValidationErrors.updated_at" class="error mt-2 text-danger" for="updated_at">
            {{ apiValidationErrors.updated_at[0] }}
          </label>
        </div>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.category ? 'has-danger' : ''">
          <label for="category">{{ $t('pwas.components.app.main.category') }}
            <info :message="$t('pwas.components.app.main.categoryInfo')"/>
          </label>
          <select type="text" id="category" v-model="value.category" class="form-control"
                  :class="apiValidationErrors.category ? 'form-control-danger' : ''"
                  @focus="clearError('category')">
            <option :key="category" v-for="category in $store.getters['pwas/categories']"
                    :value="$t(category)">{{ $t(category) }}
            </option>
          </select>
          <label v-if="apiValidationErrors.category" class="error mt-2 text-danger" for="category">
            {{ apiValidationErrors.category[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.category_label ? 'has-danger' : ''">
          <label for="category_label">{{ $t('pwas.components.app.main.categoryInscription') }}
            <info
              :message="$t('pwas.components.app.main.categoryInscriptionInfo')"/>
          </label>
          <input type="text" id="category_label"
                 v-model="value.languages[lang].category_label" class="form-control"
                 :class="apiValidationErrors.category_label ? 'form-control-danger' : ''"
                 @focus="clearError('category_label')" v-if="lang">
          <input type="text" id="category_label" v-model="value.category_label" class="form-control"
                 :class="apiValidationErrors.category_label ? 'form-control-danger' : ''"
                 @focus="clearError('category_label')" v-else>
          <label v-if="apiValidationErrors.category_label" class="error mt-2 text-danger"
                 for="category_label">
            {{ apiValidationErrors.category_label[0] }}
          </label>
        </div>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.dataSecurity ? 'has-danger' : ''">
        <label for="dataSecurity">{{ $t('pwas.components.app.description.dataSecurity') }}
          <info :message="$t('pwas.components.app.description.dataSecurityInfo')"/>
        </label>
        <input v-if="lang" type="text" id="dataSecurity" :placeholder="$t('pwas.components.app.description.dataSecurity')"
               v-model="value.languages[lang].dataSecurity" class="form-control"
               :class="apiValidationErrors.dataSecurity ? 'form-control-danger' : ''"
               @focus="clearError('dataSecurity')">
        <input v-else type="text" id="dataSecurity" :placeholder="$t('pwas.components.app.description.dataSecurity')"
               v-model="value.dataSecurity" class="form-control"
               :class="apiValidationErrors.dataSecurity ? 'form-control-danger' : ''"
               @focus="clearError('dataSecurity')">
        <label v-if="apiValidationErrors.dataSecurity" class="error mt-2 text-danger"
               for="dataSecurity">
          {{ apiValidationErrors.dataSecurity[0] }}
        </label>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.dataSecurityText ? 'has-danger' : ''">
          <label for="dataSecurityText">{{ $t('pwas.components.app.description.dataSecurityText') }}
            <info :message="$t('pwas.components.app.description.dataSecurityTextInfo')"/>
          </label>
          <textarea v-if="lang" type="text" id="dataSecurityText"
                    :placeholder="$t('pwas.components.app.description.dataSecurityText')"
                    v-model="value.languages[lang].dataSecurityText" class="form-control" rows="6"
                    :class="apiValidationErrors.dataSecurityText ? 'form-control-danger' : ''"
                    @focus="clearError('dataSecurityText')">
          </textarea>
          <textarea v-else type="text" id="dataSecurityText" :placeholder="$t('pwas.components.app.description.dataSecurityText')"
                    v-model="value.dataSecurityText" class="form-control" rows="6"
                    :class="apiValidationErrors.dataSecurityText ? 'form-control-danger' : ''"
                    @focus="clearError('dataSecurityText')">
          </textarea>
          <label v-if="apiValidationErrors.dataSecurityText" class="error mt-2 text-danger"
                 for="dataSecurityText">
            {{ apiValidationErrors.dataSecurityText[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.dataSecurityLink ? 'has-danger' : ''">
          <label for="dataSecurityLink">{{ $t('pwas.components.app.description.dataSecurityLink') }}
            <info :message="$t('pwas.components.app.description.dataSecurityLinkInfo')"/>
          </label>
          <input v-if="lang" type="text" id="dataSecurityLink" :placeholder="$t('pwas.components.app.description.dataSecurityLink')"
                 v-model="value.languages[lang].dataSecurityLink" class="form-control"
                 :class="apiValidationErrors.dataSecurityLink ? 'form-control-danger' : ''"
                 @focus="clearError('dataSecurityLink')">
          <input v-else type="text" id="dataSecurityLink" :placeholder="$t('pwas.components.app.description.dataSecurityLink')"
                 v-model="value.dataSecurityLink" class="form-control"
                 :class="apiValidationErrors.dataSecurityLink ? 'form-control-danger' : ''"
                 @focus="clearError('dataSecurityLink')">
          <label v-if="apiValidationErrors.dataSecurityLink" class="error mt-2 text-danger"
                 for="dataSecurityLink">
            {{ apiValidationErrors.dataSecurityLink[0] }}
          </label>
        </div>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.noneInfo ? 'has-danger' : ''">
        <label for="noneInfo">{{ $t('pwas.components.app.description.noneInfo') }}
          <info :message="$t('pwas.components.app.description.noneInfoInfo')"/>
        </label>
        <input v-if="lang" type="text" id="noneInfo" :placeholder="$t('pwas.components.app.description.noneInfo')"
               v-model="value.languages[lang].noneInfo" class="form-control"
               :class="apiValidationErrors.noneInfo ? 'form-control-danger' : ''"
               @focus="clearError('noneInfo')">
        <input v-else type="text" id="noneInfo" :placeholder="$t('pwas.components.app.description.noneInfo')"
               v-model="value.noneInfo" class="form-control"
               :class="apiValidationErrors.noneInfo ? 'form-control-danger' : ''"
               @focus="clearError('noneInfo')">
        <label v-if="apiValidationErrors.noneInfo" class="error mt-2 text-danger"
               for="noneInfo">
          {{ apiValidationErrors.noneInfo[0] }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'

export default {
  name: 'NewDescriptionApp',
  mixins: [formMixin],
  components: {Info},
  props: {
    value: {
      type: Object,
      require: true
    },
    errors: {
      type: Object,
      require: true
    }
  },
  computed: {
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  }
}
</script>
