import service from '@/store/services/actions-push-service'
import middleware from './middleware'

const state = () => ({
  count: 0,
  models: [],
  statuses: [
    {id: 'suspended', label: 'pushes.statuses.suspended', class: 'info'},
    {id: 'launched', label: 'pushes.statuses.launched', class: 'primary'}
  ]
})

const mutations = {
  setData (state, { models, count }) {
    state.models = models
    state.count = count
  },
  socket_PWAPushCompanyUpdate (state, {company, ...payload}) {
    const index = state.models.findIndex(input => input.id === company)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        if (prop === 'status') {
          const statusIndex = state.statuses.findIndex(input => input.id === value)
          state.models[index][prop] = state.statuses[statusIndex]
        } else {
          state.models[index][prop] = value
        }
      }
    }
  },
  socket_PWAPushCompanyInc (state, {company, ...payload}) {
    const index = state.models.findIndex(input => input.id === company)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        if (prop === 'status') {
          const statusIndex = state.statuses.findIndex(input => input.id === value)
          state.models[index][prop] = state.statuses[statusIndex]
        } else {
          state.models[index][prop] += value
        }
      }
    }
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create ({commit, dispatch}, params) {
    return service.create(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => {
    const models = []
    for (const model of state.models) {
      const index = state.statuses.findIndex(value => value.id === model.status)
      models.push({
        ...model,
        status: state.statuses[index]
      })
    }
    return models
  },
  count: state => state.count,
  statuses: state => state.statuses
}

const actionsPushes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default actionsPushes
