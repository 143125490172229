export default {
  navigation: 'Configuração',
  title: 'Configuração do PWA #{id}',
  message: 'Faça uma configuração própria da aplicação',
  tags: 'Tags de PWA',
  tagsInfo: 'Conjunto de tags para filtração de PWA',
  alias: 'Alias único no sistema',
  aliasInfo: 'É um nome de sistema único de PWA para facilitar a identificação de PWA',
  installMessage: 'Download falso será feito de <strong>{stepMin}</strong> a <strong>{stepMax}</strong> passos com intervalo de <strong>{intervalMin} mseg</strong>. a <strong>{intervalMax} mseg</strong>. Neste caso, o tempo mínimo possível será de <strong>{minInstallingTime} seg.</strong>, o tempo máximo possível será de <strong>{maxInstallingTime} seg.</strong> Downland falso em média terá de <strong>{avgInstallingTime} seg.</strong>',
  minStep: 'Mínimo de passos',
  minStepInfo: 'Este parâmetro indica o número mínimo possível de passos de download',
  maxStep: 'Máximo de passos',
  maxStepInfo: 'Este parâmetro indica o número máximo possível de passos de download',
  minInterval: 'Intervalo mínimo',
  minIntervalInfo: 'Este parâmetro indica o tempo mínimo possível entre os passos de download',
  maxInterval: 'Intervalo máximo',
  maxIntervalInfo: 'Este parâmetro indica o tempo máximo possível entre os passos',
  customJs: 'JS de utilizador',
  customJsInfo: 'Para adicionar JS de utilizador no seu PWA contacta a administração do serviço'
}
