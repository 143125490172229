<template>
  <modal
    ref="modal"
    id="tier-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Изменить цену tier {{ tier }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <select2
        ref="select2"
        class="form-group"
        inputClass="form-control select_white_g"
        id="user"
        name="user"
        v-model="user"
        placeholder="Пользователь"
        :options="ownerOptions"
        :settings="userSettings"
      >
        <template v-slot:before>
          <div class="sibmti_sdf">Пользователь</div>
        </template>
        <template v-slot:after>
          <label v-if="apiValidationErrors.user" class="error mt-2 info_warning_gdg" for="user">
            {{ apiValidationErrors.user[0] }}
          </label>
        </template>
      </select2>
      <div class="form-group" :class="apiValidationErrors.tier ? 'has-danger' : ''">
        <label for="tier">Тир {{ user ? 'пользователя' : '' }}</label>
        <select class="form-control" id="tier" v-model="tier">
          <option :key="item" v-for="item in tiers" :value="item">
            {{ item }}
          </option>
        </select>
        <small class="form-text text-muted">Тир</small>
        <label v-if="apiValidationErrors.tier" class="error mt-2 text-danger" for="tier">
          {{ apiValidationErrors.tier[0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.rate ? 'has-danger' : ''">
        <label for="rate">Ставка  {{ user ? 'пользователя' : '' }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="number" step="0.01" id="rate" placeholder="0.07" v-model="rate"
                 class="form-control" min="0.01" max="1"
                 :class="apiValidationErrors.rate ? 'form-control-danger' : ''"
                 @focus="clearError('rate')">
        </div>
        <small class="form-text text-muted">Ставка за установку</small>
        <label v-if="apiValidationErrors.rate" class="error mt-2 text-danger" for="rate">
          {{ apiValidationErrors.rate[0] }}
        </label>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import formMixin from '@/mixins/form-mixin'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'

const defaultValues = () => ({
  tier: 1,
  rate: null
})

export default {
  name: 'TierForm',
  mixins: [formMixin, select2AjaxMixin],
  components: {Modal, SubmitModalForm, Select2},
  data () {
    return {
      tiers: [1, 2, 3],
      processing: false,
      ...defaultValues(),
      user: undefined,
      userSettings: {},
      ownerOptions: [],
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  async created () {
    this.userSettings = {...this.getSelect2AjaxSettings('users/filter/installing'), allowClear: true}
  },
  methods: {
    async handlerChangeTierRate () {
      if (this.user) this.ownerOptions = await this.getSelect2AjaxOptions(this.user, 'users/filter')
      this.skip()
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        const payload = {
          rate: this.rate,
          tier: this.tier,
          user: this.user
        }
        await this.$store.dispatch('adminInstallations/updateRate', payload)
        await this.$store.dispatch('alerts/success', 'Успешно обновлён')
        this.resetApiValidation()
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
      this.processing = false
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    setUserId (id) {
      this.user = id
    }
  }
}
</script>
