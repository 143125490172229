import api from '@/store/services/api'

import {
  SET_ALL,
  SET_USER_PRIVACY_READ
} from './mutation-types'

export default {
  socket_getPrivacyPolicy ({ commit }, {...payload}) {
    commit(SET_ALL, {banners: payload})
  },
  setUserPrivacyPolicyBanner ({ commit }) {
    const params = { privacyPolicy: { notUsePublicPersonInAds: true } }
    return api.patch('users/privacy-policy', params)
      .then(() => {
        commit(SET_USER_PRIVACY_READ, params)
      }).catch(error => {
        throw error
      })
  },
  setMemberPrivacyPolicyBanner ({ commit }) {
    const params = { privacyPolicy: { notUsePublicPersonInAds: true } }
    return api.patch(`teams/members`, params)
      .then(() => {
        commit(SET_USER_PRIVACY_READ, params)
      }).catch(error => {
        throw error
      })
  }
}
