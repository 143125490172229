<template>
  <BaseRouterTabs :tabs="items" small params="language">
    <div class="add_new_lang">
      <slot/>
    </div>
  </BaseRouterTabs>
</template>
<script>
import dictionaryMixin from '@/mixins/dictionary-mixin'
import BaseRouterTabs from '@/components/base/BaseRouterTabs.vue'

export default {
  name: 'Navigation',
  components: {BaseRouterTabs},
  mixins: [dictionaryMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    route: {
      type: String,
      required: true
    },
    comments: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    current () {
      return this.$route.params.language || 'default'
    },
    items () {
      let items = [{id: 'default', label: this.$t('pwas.components.translate.default'), class: this.current === 'default' ? 'current' : '', to: {name: this.route, id: this.value.id}}]
      if (this.value.languages != null && Object.keys(this.value.languages).length > 0) {
        for (const [id, data] of Object.entries(this.value.languages)) {
          let useLang = data != null
          if (this.comments) {
            useLang = (data.comments != null && Object.keys(data.comments).length > 0)
          }
          if (useLang) {
            items.push({
              id,
              label: this.getCountryName(id),
              class: this.current === id ? 'current' : '',
              to: {
                name: `L${this.route}`,
                params: {id: this.$route.params.id, language: id}
              }
            })
          }
        }
      }
      return items
    }
  }
}
</script>
<style scoped lang="sass">
.add_new_lang
  ::v-deep img
    width: 20px
</style>
