export default {
  actions: {
    nothing: 'Оберіть дію'
  },
  remove: {
    action: 'Видалити',
    title: 'Видалення виділених',
    message: 'Ви дійсно хочете видалити виділені?',
    okButton: 'Видалити'
  },
  copyPwa: {
    action: 'Копіювати',
    title: 'Копіювати виділені',
    message: 'Скопіювати можна тільки <span class="text-success">оплачені</span>. Ви дійсно хочете копіювати виділені?',
    okButton: 'Копіювати',
    tracker: {
      message: 'Копіювати з трекером чи без?',
      okButton: 'C трекером',
      cancelButton: 'Без трекера'
    }
  },
  copy: {
    action: 'Копіювати',
    title: 'Копіювати виділені',
    message: 'Ви дійсно хочете копіювати виділені?',
    okButton: 'Копіювати'
  },
  restore: {
    action: 'Відновити',
    title: 'Відновити виділені',
    message: 'Ви дійсно хочете відновити виділені?',
    okButton: 'Відновити'
  },
  trash: {
    action: 'У кошик',
    title: 'Перемістити виділені в кошик',
    message: 'Ви дійсно хочете перемістити в кошик виділені PWA? На відновлення у вас буде 2 дні',
    messageRelation: 'Ви дійсно хочете перемістити в кошик виділені PWA? На відновлення у вас буде 2 дні. Ці PWA також будуть видалені з Топ ігор!',
    okButton: 'У кошик'
  },
  play: {
    action: 'Запустити',
    title: 'Запустити виділені',
    message: 'Ви впевнені що хочете запустити виділені PWA?',
    okButton: 'Запустити'
  },
  stop: {
    action: 'Зупинити',
    title: 'Зупинити виділені',
    message: 'Ви впевнені, що хочете зупинити виділені PWA?',
    okButton: 'Зупинити'
  },
  meta: {
    action: 'Користувацький META tags',
    title: 'Вставте свої META tags',
    message: 'Ці META tags будуть додані на ваших PWA',
    okButton: 'Зберегти'
  },
  customJs: {
    action: 'Користувацький JS',
    title: 'Вставте свій JS скрипт',
    message: 'Цей JS код буде додано до ваших PWA',
    okButton: 'Зберегти'
  },
  pagination: 'Массові дії'
}
