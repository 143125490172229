import AdminDomains from './index.vue'

export default (meta, header) => {
  return [
    {
      path: 'domains',
      name: 'AdminDomains',
      components: {default: AdminDomains, header},
      meta
    }
  ]
}
