<template>
  <div class="item_silter_sjhf_g">
    <select class="form-control" :id="id">
      <option value="" selected>{{ label }}</option>
      <option :key="datum" v-for="datum in data" :value="datum" :selected="String(value) === String(datum)">
        {{ datum }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'Select2',
  props: ['data', 'value', 'label', 'id'],
  computed: {
    '$id': function () {
      return `#${this.id}`
    }
  },
  watch: {
    value: function () {
      this.init()
    }
  },
  methods: {
    init: function () {
      const self = this
      // eslint-disable-next-line no-undef
      jQuery(self.$id).select2({
        placeholder: self.label,
        escapeMarkup: function (m) {
          return m
        },
        allowClear: true
      })
        .on('change', function (event) {
          self.$emit('input', event.target.value)
        })
    }
  },
  mounted: function () {
    this.init()
  }
}
</script>
