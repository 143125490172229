import layout from './layout.vue'
import {companies, router as companiesRouter} from './companies'
import {interval, router as intervalRouter} from './interval'
import {actions, router as actionsRouter} from './actions'
import {template, router as templateRouter} from './template'

export default (meta, header) => {
  return [
    {
      path: 'pwas/pushes',
      name: 'PWAsPushesLayout',
      components: {default: layout, header},
      meta,
      children: [
        {
          path: '/',
          name: 'PWAsPushes',
          components: {default: companies, header},
          meta
        },
        {
          path: 'interval',
          name: 'IntervalPushes',
          components: {default: interval, header: header},
          meta: meta
        },
        {
          path: 'actions',
          name: 'ActionsPushesPushes',
          components: {default: actions, header: header},
          meta: meta
        },
        {
          path: 'templates',
          name: 'PushTemplates',
          components: {default: template, header: header},
          meta: meta
        }
      ]
    },
    ...companiesRouter(meta, header),
    ...intervalRouter(meta, header),
    ...actionsRouter(meta, header),
    ...templateRouter(meta, header)
  ]
}
