import UAParser from 'ua-parser-js'
import {i18n} from '@/plugins/i18n'

export default input => {
  input = new UAParser(input)
  const device = input.getDevice()
  let result = ''
  if (device.type) {
    result += device.type
  }
  if (device.vendor) {
    result += ' ' + device.vendor
  }
  if (device.model) {
    result += ' ' + device.model
  }
  return result === '' ? i18n.t('dashboard.online.deviceUnrecognized') : result
}
