export default {
  analyticsGroups: 'Группировки',
  analyticsUniques: 'Уники',
  columns: 'Колонки',
  filters: 'Фильтры',
  filtersSettingTitle: 'Настройка фильтров',
  columnsSettingTitle: 'Настройка колонок',
  date: 'Дата',
  clicks: {
    title: 'Клики',
    installs: 'Установки',
    pushes: 'Пуш подписки',
    opens: 'Входы в PWA',
    reopens: 'Повторные входы'
  },
  postbacks: {
    title: 'Постбэки',
    registrations: 'Регистрация',
    deposits: 'Депозиты'
  },
  users: {
    title: 'Пользователь',
    ip: 'IP',
    country: 'Страны',
    userAgentDeviceType: 'Тип устройства',
    userAgentDeviceVendor: 'Бренд устройства',
    userAgentDeviceModel: 'Модель устройства',
    userAgentOs: 'ОС',
    userAgentBrowserName: 'Браузер'
  },
  trackers: {
    title: 'Трекер',
    tracker: 'Трекер',
    referer: 'Реферал',
    redirect: 'Редирект',
    tag: 'Тег',
    host: 'Домен',
    vertical: 'Вертикаль'
  },
  params: {
    title: 'Параметры',
    param: 'Параметр'
  },
  filtersLabel: {
    installs: 'Фильтр по установкам',
    pushes: 'Фильтр по подпискам',
    opens: 'Фильтр по открытиям',
    reopens: 'Фильтр по повторным открытиям',
    registrations: 'Фильтр по регистрациям',
    deposits: 'Фильтр по депозитам',
    ip: 'Фильтр по IP',
    id: 'Фильтр по ID',
    country: 'Фильтр по странам',
    userAgentDeviceType: 'Фильтр по типу устройства',
    userAgentDeviceVendor: 'Фильтр по бренду устройства',
    userAgentDeviceModel: 'Фильтр по модели устройства',
    userAgentOs: 'Фильтр по ОС',
    userAgentBrowserName: 'Фильтр по браузеру',
    tracker: 'Фильтр по трекеру',
    referer: 'Фильтр по рефералу',
    redirect: 'Фильтр по редиректу',
    pwa: 'Фильтр по PWA',
    tag: 'Фильтр по тегу',
    host: 'Фильтр по домену',
    vertical: 'Фильтр по вертикали',
    verticals: ['Нутра', 'Бетинг', 'Дейтинг', 'Гемблинг', 'Трейдинг', 'Крипта'],
    params: 'Фильтр по {param}',
    date: 'Фильтр по дате',
    today: 'Сегодня',
    yesterday: 'Вчера',
    prevSevenDay: 'Предыдущая неделя',
    prevMonth: 'Предыдущий месяц',
    prev3Month: 'Предыдущие 3 месяця',
    thisYear: 'Этот год',
    preLandingPage: 'Прелендинг',
    postLandingPage: 'Постлендинг',
    preLandingPageUnique: 'Уник прелендинг',
    postLandingPageUnique: 'Уник постлендинг'
  },
  columnsLabels: {
    cpi: 'CPI',
    analyticsBy: 'Аналитика по',
    uniqueLabel: 'Данные пользователя',
    actionsLabel: 'Действия',
    postbackLabel: 'Информация с Postback',
    pwaLabel: 'Трекинг',
    calculateLabel: 'Вычисления',
    uniques: 'Уникальные',
    hits: 'Клики',
    installs: 'Установки',
    pushes: 'PUSH подписки',
    registrations: 'Регистрации',
    deposits: 'Депозиты',
    opens: 'Открытия',
    reopens: 'Повторний запуск',
    country: 'Страны',
    createdAt: 'Создан',
    uniquesToInstalls: 'CR% уник/инстал',
    uniquesToPush: 'CR% уник/пуш',
    installsToPush: 'CR% инстал/пуш',
    date: 'Дата',
    tracker: 'Трекер',
    host: 'Домен',
    referer: 'Реферальная ссылка',
    vertical: 'Вертикаль',
    redirect: 'Редирект',
    userAgentDeviceType: 'Тип устройства',
    userAgentDeviceVendor: 'Бренд устройства',
    userAgentDeviceModel: 'Модель устройства',
    userAgentBrowserName: 'Браузер',
    userAgentOs: 'ОС',
    uniquesTitle: 'Уникальные переходы',
    hitsTitle: 'Неуникальные переходы',
    installsTitle: 'Количество установок PWA',
    pushesTitle: 'Количество подписок на Push',
    registrationsTitle: 'Количество регистраций',
    depositsTitle: 'Количество депозитов',
    opensTitle: 'Количество входов в PWA',
    reopensTitle: 'Количество повторных входов в PWA',
    createdAtTitle: 'Дата первого клика',
    uniquesToInstallsTitle: 'Процентное соотношение уник/инстал. Рассчитывается по формуле CR = Инстал/Уник*100',
    uniquesToPushTitle: 'Процентное соотношение уник/пуш. Рассчитывается по формуле CR = Пуш/Уник*100',
    installsToPushTitle: 'Процентное соотношение инстал/пуш. Рассчитывается по формуле CR = Пуш/Инстал*100',
    landingsLabel: 'Лендинги',
    preLandingPage: 'Прелендинг',
    postLandingPage: 'Постлендинг',
    preLandingPageUnique: 'Уник прелендинг',
    postLandingPageUnique: 'Уник постлендинг'
  },
  groupsLabels: {
    date: 'Группировка по Дате',
    tag: 'Группировка по Тегу',
    host: 'Группировка по Домену',
    country: 'Группировка по Стране',
    pwa: 'Группировка по PWA',
    vertical: 'Группировка по Вертикале',
    userAgentDeviceType: 'Группировка по Устройству',
    userAgentDeviceVendor: 'Группировка по Бренду',
    userAgentDeviceModel: 'Группировка по Моделе',
    userAgentBrowserName: 'Группировка по Браузеру',
    userAgentOs: 'Группировка по ОС',
    param: 'Группировка по "{param}"',
    by: 'По',
    dateTitle: 'Аналитика по Дате',
    trackerTitle: 'Аналитика по Трекеру',
    hostTitle: 'Аналитика по Домену',
    tagTitle: 'Аналитика по Тегу',
    countryTitle: 'Аналитика по Стране',
    pwaTitle: 'Аналитика по PWA',
    verticalTitle: 'Аналитика по Вертикале',
    userAgentDeviceTypeTitle: 'Аналитика по Устройству',
    userAgentDeviceVendorTitle: 'Аналитика по Бренду',
    userAgentDeviceModelTitle: 'Аналитика по Моделе',
    userAgentBrowserNameTitle: 'Аналитика по Браузеру',
    userAgentOsTitle: 'Аналитика по ОС',
    preLandingPageTitle: 'Аналитика по прелендингу',
    postLandingPageTitle: 'Аналитика по постлендингу',
    dateLabel: 'Дата',
    trackerLabel: 'Трекер',
    hostLabel: 'Домен',
    countryLabel: 'Страна',
    pwaLabel: 'PWA',
    verticalLabel: 'Вертикаль',
    userAgentDeviceTypeLabel: 'Устройство',
    userAgentDeviceVendorLabel: 'Бренд',
    userAgentDeviceModelLabel: 'Модель',
    userAgentBrowserNameLabel: 'Браузер',
    userAgentOsLabel: 'ОС',
    landingsLabel: 'Лендинги',
    preLandingPage: 'Группировка по прелендингу',
    postLandingPage: 'Группировка по постлендингу'

  },
  report: {
    button: 'Отчеты',
    title: 'Генерация отчета',
    body: {
      open: 'К уже существующей аналитике будут добавлены расходы на покупку доменов, в нашей системе, и прибыль с Postback. Отчет будет доступен по ссылке в формате CSV с кодировкой UTF-8',
      successful: `Ваш отчет доступен по <a download="report.csv" class="link_green" href="{csv}" target="_blank">ссылке</a>`
    },
    hint: {
      open: 'Для начала генерации отчета нажмите кнопку "Запустить"',
      loading: 'Продолжается генерация отчета...',
      successful: 'Генерация завершена',
      error: 'Во время создания отчета возникла ошибка'
    }
  },
  landings: {
    title: 'Лендинги',
    preLandingPage: 'Прелендинг',
    postLandingPage: 'Постлендинг',
    preLandingPageUnique: 'Уник прелендинг',
    postLandingPageUnique: 'Уник постлендинг'
  },
  empty: 'Статистика отсутствует или несформирована в данный момент',
  pwaRemoved: 'Удалённое PWA'

}
