<template>
  <form class="forms-sample" @submit.prevent="$emit('submit', $event)">
    <MainForm
      v-bind="{name,tags,type,theme}"

      @update:name="name = $event"
      @update:tags="tags = $event"
      @update:type="type = $event"
      @update:theme="theme = $event"
      :errors="apiValidationErrors"
    />
    <ThemesForm
      v-model="theme"
      :errors="apiValidationErrors"
      :type="type"
    />
    <ParamsForm
      v-bind="{body, buttons, revolutions, logo}"

      @update:body="body = $event"
      @update:revolutions="revolutions = +$event"
      @update:logo="logo = $event"
      @update:buttons="buttons = $event"
      :errors="apiValidationErrors"
    />
    <SectorForm
      v-if="typeAlias === 'wheels'"
      v-model="sector"
      :errors="apiValidationErrors"
    />
    <ModalForm
      v-model="modal"
      :errors="apiValidationErrors"
    />
    <div class="foot_actions_shg app_butts_gd">
      <div class="d-md-flex align-items-center mobile-grid-2">
        <div class="mr-md-4 mb-4 mb-md-0">
          <button type="submit" class="ful_butt_green d_flex align_center justify_center mobile-full-w">
            {{ $t('general.save') }}
          </button>
        </div>
        <div class="mr-md-4 mb-4 mb-md-0">
          <router-link :to="{name: 'LandingPages'}" class="simple_butt_afg mobile-full-w">{{
              $t('general.cancel')
            }}
          </router-link>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import MainForm from '@/components/landing-pages/MainForm.vue'
import ParamsForm from '@/components/landing-pages/ParamsForm.vue'
import ThemesForm from '@/components/landing-pages/ThemesForm.vue'
import ModalForm from '@/components/landing-pages/ModalForm.vue'
import SectorForm from '@/components/landing-pages/SectorForm.vue'

export default {
  components: {SectorForm, ModalForm, ThemesForm, ParamsForm, MainForm},
  mixins: [formMixin],
  props: ['value', 'errors'],
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    },
    type () {
      if (this.typeAlias === 'wheels' && this.sector.length === 0) {
        this.sector = [
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: true},
          {label: '', isWin: false}
        ]
      }
    }
  },
  computed: {
    typeAlias () {
      return this.$store.getters['landingPages/types'].find(el => el.id === this.type).alias
    },
    name: {
      get () {
        return this.value.name
      },
      set (input) {
        this.$emit('input', {...this.value, name: input})
      }
    },
    type: {
      get () {
        return this.value.type
      },
      set (input) {
        this.$emit('input', {...this.value, type: input})
      }
    },
    sector: {
      get () {
        return this.value.sector
      },
      set (input) {
        this.$emit('input', {...this.value, sector: input})
      }
    },
    tags: {
      get () {
        return this.value.tags
      },
      set (input) {
        this.$emit('input', {...this.value, tags: input})
      }
    },
    body: {
      get () {
        return this.value.body
      },
      set (input) {
        this.$emit('input', {...this.value, body: input})
      }
    },
    theme: {
      get () {
        return this.value.theme
      },
      set (input) {
        this.$emit('input', {...this.value, theme: input})
      }
    },
    buttons: {
      get () {
        return this.value.buttons
      },
      set (input) {
        this.$emit('input', {...this.value, buttons: input})
      }
    },
    revolutions: {
      get () {
        return this.value.revolutions
      },
      set (input) {
        this.$emit('input', {...this.value, revolutions: input})
      }
    },
    logo: {
      get () {
        return this.value.logo
      },
      set (input) {
        this.$emit('input', {...this.value, logo: input})
      }
    },
    modal: {
      get () {
        return this.value.modal
      },
      set (input) {
        this.$emit('input', {...this.value, modal: input})
      }
    }
  }
}
</script>
