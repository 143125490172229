import api from '@/store/services/api'

import {
  SET_ALL,
  UPDATE_USER_MESSAGE
} from './mutation-types'

export default {
  async getUserBalance ({ commit }) {
    return api.get('users/balance')
      .then((res) => {
        commit(SET_ALL, {balance: res})
      }).catch(error => {
        throw error
      })
  },
  async getUserTariff ({ commit }) {
    return api.get('users/tariff')
      .then((res) => {
        commit(SET_ALL, {tariff: res})
      }).catch(error => {
        throw error
      })
  },
  async getUserMessages ({ commit }) {
    return api.get('users/messages')
      .then((res) => {
        commit(SET_ALL, {
          mails: res?.mails,
          notifications: res?.notifications
        })
      }).catch(error => {
        throw error
      })
  },
  async getUserPrivacyPolicy ({ commit }) {
    return api.get('users/privacy-policy')
      .then((res) => {
        commit(SET_ALL, {
          privacy: res
        })
      }).catch(error => {
        throw error
      })
  },
  async getUserData ({dispatch}) {
    dispatch('getUserBalance')
    dispatch('getUserTariff')
    dispatch('getUserMessages')
    dispatch('getUserPrivacyPolicy')
  },

  updateUserMessages ({commit}, {id, ...payload}) {
    return api.put(`messages/${id}`, payload)
      .then(() => {
        commit(UPDATE_USER_MESSAGE, {id, ...payload})
      })
      .catch(error => {
        throw error
      })
  }
}
