<template>
  <div class="modal modal_center_g" v-bind:id="id">
    <div :class="classDialog" role="document">
      <div :class="classContent">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    id: {
      type: String,
      required: true
    },
    classDialog: {
      type: String,
      default: 'modal-dialog modal_info'
    },
    classContent: {
      type: String,
      default: 'modal-content modal_padd_normal'
    }
  },
  methods: {
    open () {
      // eslint-disable-next-line no-undef
      jQuery(`#${this.id}`).modal({backdrop: 'static', keyboard: false, show: true})
    },
    close () {
      // eslint-disable-next-line no-undef
      jQuery(`#${this.id}`).modal('hide')
    }
  }
}
</script>
