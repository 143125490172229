<template>
  <div class="Transactions card transactions-card">
    <div class="card-body has_big_pt">
      <h4 class="transactions-card__title">{{ $t('dashboard.transactions.title') }}
        <info :message="$t('dashboard.transactions.info')"/>
      </h4>
      <Loading v-if="loading" :value="loading" class="page-dashboard__loading" />
      <Error v-else-if="error" :error="error" @reload="getData"/>
      <div v-else-if="items?.length" class="table-responsive">
        <table class="table">
          <tbody>
          <tr v-for="item of items" :key="item._id">
            <td class="py-1 pl-0">
              {{ $t(`finance.transactions.type.${item.type}`, {...item}) }}
            </td>
            <td>
              <label :class="item.amount > 0 ? 'text-success' : 'text-danger mlm10px'">{{
                  item.amount | amount
                }}</label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="text-center">
        <p class="text-muted">{{$t('general.nothingHere')}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import Loading from '@/components/Loading.vue'
import Error from '@/components/RequestError.vue'

export default {
  name: 'Transactions',
  components: {Info, Loading, Error},
  data () {
    return {
      loading: true,
      error: null,
      items: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      this.loading = true
      this.error = null
      try {
        this.items = await this.$store.dispatch('dashboardStore/transactions')
      } catch (error) {
        if (error.response.status === 504 || error.response.status === 403) {
          this.error = error.response.status
        }
      }
      this.loading = false
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.mlm10px
  margin-left: -10px
.transactions-card
  border-right: solid 1px $grey-outer-space
  margin-right: 20px
  width: 100%
  max-width: 720px
  @media screen and (max-width: 767px)
    max-width: 100%
    margin-right: 0
    border-right: none
  &__title
    margin-bottom: 20px
    font-size: 18px
    +font(400)
</style>
