<template>
  <div class="Activity card activity-card">
    <div class="has_big_pt">
      <h4 class="activity-card__title">{{ $t('dashboard.activity.title') }}
        <Info :message="$t('dashboard.activity.info')"/>
      </h4>
      <Loading v-if="loading" :value="loading" class="page-dashboard__loading" />
      <Error v-else-if="error" :error="error" @reload="getData"/>
      <div v-else-if="uniquesCount" class="activity-card__chart-wrapper">
        <div class="activity-card__numbers">
          <h1 class="activity-card__numbers-title">{{ uniquesCount }}</h1>
          <p class="activity-card__numbers-sub-title">{{ $tc('dashboard.activity.uniques', uniquesCount) }}
            <Info :message="$t('dashboard.activity.uniquesInfo')"/>
          </p>
        </div>
        <div class="mx-n4 activity-card__canvas">
          <canvas id="earning-report" width="100" height="100"></canvas>
        </div>
      </div>
      <div v-else class="text-center">
        <p class="text-muted">{{$t('general.nothingHere')}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import textMixin from '@/mixins/text-mixin'
import Loading from '@/components/Loading.vue'
import Error from '@/components/RequestError.vue'

export default {
  name: 'Activity',
  mixins: [textMixin],
  components: {Info, Loading, Error},
  data () {
    return {
      activity: null,
      uniquesCount: null,
      loading: true,
      error: null
    }
  },
  computed: {
    chartData () {
      if (this.uniquesCount && this.activity) {
        return [
          this.activity.install,
          this.activity.open,
          this.activity.push,
          this.uniquesCount
        ]
      } else {
        return [0, 0, 0, 0]
      }
    }
  },
  updated () {
    this.init()
  },
  async mounted () {
    await this.getData()
    this.init()
  },
  methods: {
    init () {
      const self = this
      const earningReportData = {
        datasets: [{
          data: this.chartData,
          // data: [65, 59, 80],
          backgroundColor: [
            '#FD5E53',
            '#21BF73',
            '#F1C62D'
          ],
          borderWidth: 0
        }],
        labels: [
          // this.$t('dashboard.activity.uniques'),
          'dashboard.activity.installations',
          'dashboard.activity.opens',
          'PUSH'
        ]
      }
      const earningReportOptions = {
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              let label = data.labels[tooltipItem[0].index] || ''
              if (label.includes('installations')) label += 'Full'
              return self.$t(label)
            }
          }
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          y: {
            beginAtZero: true
          },
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              callback: (value) => {
                return self.$t(value)
              }
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: false
        }
      }
      const earningreport = '#earning-report'
      // eslint-disable-next-line no-undef
      if (jQuery(earningreport).get(0) !== undefined) {
        // eslint-disable-next-line no-undef
        const earningReportCanvas = jQuery(earningreport).get(0).getContext('2d')
        // eslint-disable-next-line no-undef, no-unused-vars
        const earningReportChart = new Chart(earningReportCanvas, {
          type: 'bar',
          data: earningReportData,
          options: earningReportOptions
        })
      }
    },
    async getData () {
      this.loading = true
      this.error = null
      try {
        this.activity = await this.$store.dispatch('dashboardStore/activity')
        this.uniquesCount = await this.$store.dispatch('dashboardStore/countUniques')
      } catch (error) {
        if (error.response.status === 504 || error.response.status === 403) {
          this.error = error.response.status
        }
      }

      this.loading = false
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.activity-card
  border-right: solid 1px $grey-outer-space
  @media screen and (max-width: 767px)
    border-right: none
  &__title
    margin-bottom: 10px
    font-size: 18px
    +font(400)
  &__numbers
    display: flex
    align-items: flex-end
  &__numbers-title
    font-size: 24px
    +font(500)
  &__numbers-sub-title
    +font(400)
    font-size: 14px
    margin-left: 5px
  &__canvas
    padding: 20px
    margin-top: 50px
    padding-bottom: 0
    @media screen and (max-width: 767px)
      margin-top: 30px
</style>
