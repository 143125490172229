import PushTemplate from './_form.vue'

export default (meta, header) => {
  return [
    {
      path: 'pwas/pushes/templates/create',
      name: 'PushTemplateCreate',
      components: {default: PushTemplate, header: header},
      meta: meta
    },
    {
      path: 'pwas/pushes/templates/:id',
      name: 'PushTemplateUpdate',
      components: {default: PushTemplate, header: header},
      meta: meta
    }
  ]
}
