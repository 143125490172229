<template>
  <div class="card card_item_pwa">
    <div class="card-body">
      <div class="d-flex flex-row align-items-top">
        <div class="icon_sgd_g">
          <img :src="icon" alt="">
        </div>
        <div class="ml-0">
          <h6 class="title_pwa_item" v-if="tooltip">{{ header }}
            <info :message="tooltip"/>
          </h6>
          <h6 class="title_pwa_item" v-else>{{ header }}</h6>
          <p class="sub_tit_pwa_item">{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Info from './Info.vue'

export default {
  name: 'MiniStatistic',
  components: {Info},
  props: ['icon', 'header', 'text', 'type', 'tooltip']
}
</script>
