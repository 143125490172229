export default {
  online: {
    title: 'Уники онлайн',
    info: 'Уникальные пользователи которые находятся в приложении в данный момент',
    table: ['Дата', 'Страна', 'Устройство', 'ОС', 'Установки', 'Открытия', 'PUSH'],
    deviceUnrecognized: 'Нераспознанный'
  },
  activity: {
    title: 'Активность',
    info: 'Соотношение действий пользователей (Установки PWA, Открытия PWA, оформления подписки на Push уведомления)',
    uniques: 'Уников нет | Уник | Уника | Уников',
    uniquesInfo: 'Количество уников за последние 3 месяца',
    installations: 'Установки',
    installationsFull: 'Установки',
    opens: 'Открытия'
  },
  uniquesAndInstalls: {
    title: 'Уники и установки',
    info: 'Количество уникальных пользователь и установок PWA за последние 14 рабочих дней',
    uniques: 'Уников нет | Уник | Уника | Уников',
    uniquesInfo: 'Количество уников за последние 14 рабочих дней',
    unique: 'Уники',
    installations: 'Установки'
  },
  transactions: {
    title: 'Финансы',
    info: 'Краткая статистика Ваших доходов и расходов'
  },
  topOnline: {
    title: 'Топ онлайн',
    info: 'Топ Pwa по количеству трафика на данный момент'
  }
}
