export default {
  openTicket: 'Open ticket',
  fillForm: 'Fill form',
  theme: 'Theme',
  themeInfo: 'Enter the theme of the request',
  department: 'Department',
  departmentInfo: 'Select one of the directions of the problem',
  departmentList: ['PWA', 'Billing', 'Statistics', 'Tracker', 'Referral program', 'Error'],
  message: 'Message',
  messageInfo: 'Enter your message that reveals the problem',
  manager: 'Manager',
  status: 'Status',
  ticketOpened: 'Ticket opened, wait for response',
  waiting: 'Waiting for response',
  ticketCorrespondence: 'Ticket correspondence #{ticketID}',
  success: 'Message sent, waiting for a response',
  statusList: ['New', 'Manager Assigned', 'Answered', 'New Question', 'Closed', 'Unresolved', 'Rejected']
}
