import Members from './Members.vue'

export default (meta, header) => {
  return [
    {
      path: 'user/members',
      name: 'AdminMembers',
      components: {default: Members, header},
      meta
    }
  ]
}
