/* eslint-disable no-template-curly-in-string */
export default {
  bills: {
    title: 'Contas',
    noHave: 'Não tem contas criadas. Faça um depósito',
    amountTopUp: 'Montante de depósito',
    topUp: 'Carregar',
    status: 'Estado',
    statusTitle: 'Estado atual de contas',
    amount: 'Montante',
    amountTitle: 'Montante creditado na conta',
    total: 'Total',
    totalTitle: 'Montante a pagar, em $',
    method: 'Forma de pagamento',
    methodTitle: 'Forma de pagamento da conta',
    created: 'Criado',
    createdTitle: 'Data de criação da conta',
    actions: 'Movimentos',
    paidSuccess: 'Conta foi paga',
    paid: 'Pago',
    expiresIn: 'Expira dentro de:',
    overdue: 'Fora de prazo',
    rejected: 'Recusado',
    info: 'Informação',
    modal: {
      title: 'Conta a pagar #{id}',
      info: [
        `Conta foi confirmada em <label class="text-warning">{confirmation}/6</label>, até terminar a confirmação automática falta <label class="text-warning">{expired}</label>, após este momento será disponível apenas confirmação manual`,
        `Estamos à espera da notificação de pagamento. Caso não foi possível pagar a conta pode tentar novamente através de <a href="{pay_url}">link</a>`,
        'Conta foi paga com sucesso'
      ]
    },
    bitcoin: {
      title: 'Faça o pagamento para a conta indicada',
      confirmed: 'Confirmado',
      wallet: 'Carteira',
      paid: 'Pago',
      check: 'Verificar'
    },
    tether: {
      title: 'Faça o pagamento para a conta indicada',
      wallet: 'Carteira',
      hash: {
        title: 'Hash(txid)da transação',
        placeholder: 'Inserir aqui a hash da transação',
        notEmpty: 'Preenchimento obrigatório'
      },
      paid: 'Pago'
    }
  },
  transactions: {
    title: 'Transação',
    noHave: 'Não tem transações para visualizar',
    created: 'Criado',
    createdTitle: 'Data de criação da transação',
    amount: 'Montante',
    amountTitle: 'Montante da transação, em $',
    purpose: 'Finalidade',
    purposeTitle: 'Finalidade da transação',
    type: {
      pwa: 'Pagamento de PWA #{pwa}',
      domain: 'Compra do domínio {domain} para PWA #{pwa}',
      referral: 'Royalty de parceiro',
      bill: 'Depósito de ${amount} pagando a conta #{bill}',
      tariff: 'Subscrição do plano #{tariff}',
      install: 'Pagamento de instalação',
      bypass: 'Custo de instalação se iOS',
      ios: 'Custo de instalação se iOS',
      correction: 'Correção da conta',
      reject: 'Conta #{bill} foi anulada'
    }
  },
  payment: {
    minAmount: 'Montante mínimo de pagamento ${num}',
    maxAmount: 'Montante máxima de recarga ${num}',
    title: 'Faça o pagamento para a conta indicada',
    method: 'Escolher um sistema de pagamentos:',
    amount: 'Montante a pagar:',
    pay: 'Pagar'
  }
}
