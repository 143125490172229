<template>
  <div id="multi-pixel-form" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="title_mod_uplo">{{ $t('pwas.components.facebook.multiForm.title') }}</div>
        <div class="link_ths_ig" v-html="$t('pwas.components.facebook.multiForm.message')"></div>
        <form @submit.prevent="handle">
          <div class="form-group col col_half_mob" :class="error ? 'has-danger' : ''">
            <label for="multi-pixel-rows">{{ $t('pwas.components.facebook.multiForm.label') }}</label>
            <textarea id="multi-pixel-rows" rows="10" class="form-control"
                      v-model="rows" placeholder="1231231231231:install"
                      :class="error ? 'form-control-danger' : ''" style="padding: 5px;"
            ></textarea>
            <label v-if="error" class="error mt-2 text-danger" for="multi-pixel-rows"> {{ error }} </label>
          </div>
          <div class="footer_modl_akdhg">
            <div class="form-group">
              <div class="in_topshgdjf_g d_flex align_center">
                <div class="item_avt_butt_jg">
                  <button type="submit" class="ful_butt_green d_flex align_center justify_center">{{ $t('general.save') }}</button>
                </div>
                <div class="item_avt_butt_jg">
                  <a href="javascript:void(0);" @click="hide"
                     class="border_butt_green d_flex align_center justify_center">{{ $t('general.cancel') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiForm',
  data: () => ({
    rows: '',
    error: false
  }),
  watch: {
    rows: {
      handler: function () {
        this.error = false
      },
      deep: true
    }
  },
  methods: {
    show: function () {
      // eslint-disable-next-line no-undef
      jQuery('#multi-pixel-form').modal('show')
    },
    hide: function () {
      // eslint-disable-next-line no-undef
      jQuery('#multi-pixel-form').modal('hide')
    },
    handle: function () {
      this.rows = this.rows.trim()
      const pixels = this.rows.split('\n')
      let newPixels = []
      for (const index in pixels) {
        const errorLine = Number(index) + 1
        const row = pixels[index].trim()
        if (row === '') {
          this.error = this.$t('pwas.components.facebook.multiForm.errors[0]')
          break
        }
        const [_id, event] = row.split(':')
        if (!_id) {
          this.error = this.$t('pwas.components.facebook.multiForm.errors[1]', { errorLine })
          break
        }
        if (!event) {
          this.error = this.$t('pwas.components.facebook.multiForm.errors[2]', { errorLine })
          break
        }
        if (!['install', 'registration'].includes(event)) {
          this.error = this.$t('pwas.components.facebook.multiForm.errors[3]', { errorLine })
          break
        }
        const pixel = {_id: '', install: 'Lead', deposit: 'Purchase', registration: 'CompleteRegistration'}
        pixel._id = _id
        pixel[event] = 'Lead'
        newPixels.push(pixel)
      }
      if (this.error === false) {
        if (newPixels.length) {
          if (this.$parent.pixels[0]._id === '') {
            this.$parent.pixels = []
          }
          for (const pixel of newPixels) {
            this.$parent.pixels.push(pixel)
          }
        }
        this.rows = ''
        this.hide()
      }
    }
  }
}
</script>
