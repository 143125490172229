<script>
import ToggleItem from '@/components/landing-pages/ToggleItem.vue'
import formMixin from '@/mixins/form-mixin'
export default {
  components: {ToggleItem},
  props: {
    value: {
      type: Array,
      required: true
    },
    errors: Object
  },
  mixins: [formMixin],
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  },
  computed: {
    sector: {
      get () {
        return this.value
      },
      set (input) {
        this.$emit('input', input)
      }
    },
    isWin: {
      get () {
        return this.sector.findIndex(sector => sector.isWin === true)
      },
      set (input) {
        this.sector = this.sector.map((value, index) => ({
          ...value,
          isWin: index === input
        }))
      }
    }
  },
  methods: {
    handleSectorText (index, $event) {
      this.sector = this.sector.map((value, i) => {
        if (i === index) {
          return {
            ...value,
            label: $event.target.value
          }
        }
        return value
      })
    }
  }
}
</script>

<template>
  <ToggleItem
    :header="$t('landingPages.sectorForm.header')"
    :info="$t('landingPages.sectorForm.info')"
  >
    <div class="row row_mob_sg">
      <div class="form-group col-12 col-xl-6" v-for="index in 8" :key="index" >
        <label class="label" :class="apiValidationErrors[`sector.[${index-1}].label`] ? 'has-danger' : ''">
          <div class="sector-wrapper" :class="{ 'selected': (index - 1)  === isWin }">
            {{ $t('landingPages.sectorForm.sector') }}
            {{ index }}
            <div class="input-group" >
              <div class="sector-input" >
                <input
                  v-model="isWin"
                  :value="index - 1"
                  type="radio"
                  name="sector"
                >
                <input type="text" name="sectorText" :value="sector[index - 1].label" @input="handleSectorText(index - 1, $event)"
                       :placeholder="$t('landingPages.sectorForm.sectorTips')" class="form-control">
              </div>
              <label v-if="apiValidationErrors[`sector[${index-1}].label`]"
                     class="error mt-2 text-danger"
                     :for="`sector`">
                {{ $t(apiValidationErrors[`sector[${index-1}].label`][0]) }}
              </label>
            </div>
          </div>
        </label>
      </div>
    </div>
  </ToggleItem>
</template>

<style scoped>
.label {
  width: 100%;
}
.input-group {
  display: flex;
  align-items: center;
}

.sector-input {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.sector-wrapper {
  border-radius: 4px;
}

@media only screen and (max-width: 990px) {
  .sector-input {
    width: 100%;
  }
}

.content_dropd_g .row_mob_sg .col-4:first-child {
  margin-bottom: 15px;
}

.label {
  width: 100%;
}

.content_dropd_g .row_mob_sg .col-4:first-child {
  margin-bottom: 15px;
}

.content_dropd_g [class*="col-"] {
  padding-right: 6px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  border: 1px solid #A0A1A4;
  border-radius: 4px;
}

.sector-input input[type="radio"] {
  margin-right: 10px;
  width: 22px;
}

.sector-wrapper.selected input[type="radio"] {
  background-image: url('/landgins-page/checkbox.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #21BF73;
}

.sector-wrapper.selected input[type="text"] {
  outline: 1px solid #21BF73;
  background-color: #253834;
}

@media only screen and (max-width: 768px) {
  .mobile_fulls_gh.three_butssg_g > div:last-child {
    width: 50%;
  }

  .content_dropd_g .row_mob_sg .col-lg-6[data-v-3f25fb41]:nth-child(2), .col-lg-6[data-v-3f25fb41]:nth-child(4), .col-lg-6[data-v-3f25fb41]:nth-child(5) {
    margin-bottom: 15px;
  }
}

.content_dropd_g .row_mob_sg .col-lg-6[data-v-3f25fb41]:nth-child(3), .col-lg-6[data-v-3f25fb41]:first-child {
  margin-bottom: 15px;
}
</style>
