<template>
  <span
    class="custom-radio"
    :class="{ 'green-radio': isChecked }"
    @click="emitToggle"
  />
</template>

<script>
export default {
  props: {
    isChecked: Boolean
  },
  methods: {
    emitToggle () {
      this.$emit('update:isChecked', !this.isChecked)
    }
  }
}
</script>

<style scoped>
.custom-radio {
  display: inline-block;
    margin-left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #A0A1A4;
  border-radius: 4px;
  cursor: pointer;
}

.green-radio {
  background-image: url('/landgins-page/checkbox.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #21BF73;
}
</style>
