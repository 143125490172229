import service from '@/store/services/referrals-service'
import middleware from './middleware'

const state = {
  ref_link: null,
  referrals: []
}

const mutations = {
  setRefLink: (state, payload) => {
    state.ref_link = `${window.location.protocol}//${window.location.host}/r/${payload}`
  },
  setReferrals: (state, payload) => {
    state.referrals = payload
  }
}

const actions = {
  index: ({commit, dispatch}, params) => {
    return service.index(params)
      .then(res => {
        commit('setRefLink', res.ref_link)
        commit('setReferrals', res.referrals)
      }).catch(error => {
        middleware.validateAut(error)
      })
  }
}

const getters = {
  ref_link: state => state.ref_link,
  referrals: state => state.referrals
}

const referrals = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}

export default referrals
