import IntervalPush from './_form.vue'

export default (meta, header) => {
  return [
    {
      path: 'pwas/pushes/interval/create',
      name: 'IntervalPushCreate',
      components: {default: IntervalPush, header: header},
      meta: meta
    },
    {
      path: 'pwas/pushes/interval/:id',
      name: 'IntervalPushUpdate',
      components: {default: IntervalPush, header: header},
      meta: meta
    }
  ]
}
