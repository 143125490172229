import Vue from 'vue'
import {
  SET_ALL,
  REMOVE_MIRROR,
  UPDATE_MIRROR_STATUS,
  REFRESH_MIRROR,
  DELETE_MIRROR
} from './mutation-types'

export default {
  [SET_ALL] (state, data) {
    Object.assign(state, data)
    return state
  },
  [REMOVE_MIRROR] (state, { id }) {
    state.mirrorsList = state.mirrorsList.filter(item => item.id !== id)
    return state.mirrorsList
  },
  [UPDATE_MIRROR_STATUS] (state, {id, status}) {
    const itemObject = state.mirrorsList?.find(item => item.id === id)
    if (itemObject) {
      Vue.set(itemObject, 'status', status)
    }
  },
  [REFRESH_MIRROR] (state, { id }) {
    state.mirrorsTrashList = state.mirrorsTrashList.filter(item => item.id !== id)
    return state.mirrorsTrashList
  },
  [DELETE_MIRROR] (state, { id }) {
    state.mirrorsTrashList = state.mirrorsTrashList.filter(item => item.id !== id)
    return state.mirrorsTrashList
  }
}
