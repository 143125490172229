import service from '@/store/services/landing-pages-service'
import middleware from './middleware'

const state = () => ({
  count: 0,
  models: [],
  model: {},
  preview: {},
  themes: {models: [], count: 0},
  types: {models: [], count: 0},
  theme: {},
  type: {}
})

const mutations = {
  setData (state, {models, count}) {
    state.models = models
    state.count = count
  },
  setModel: (state, model) => {
    state.model = model
  },
  setPreview: (state, payload) => {
    state.preview = payload
  },
  setThemes: (state, {models, count}) => {
    state.themes = {models, count}
  },
  setTypes: (state, {models, count}) => {
    state.types = {models, count}
  },
  setTheme: (state, model) => {
    state.theme = model
  },
  setType: (state, model) => {
    state.type = model
  }
}

const actions = {
  async fetchData ({commit}, payload) {
    try {
      const res = await service.getAll(payload)
      commit('setData', res)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async create (ctx, payload) {
    try {
      await service.create(payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async view ({commit}, id) {
    try {
      const res = await service.view(id)
      commit('setModel', res)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async update (ctx, {id, payload}) {
    try {
      await service.update(id, payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async delete (ctx, id) {
    try {
      await service.delete(id)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  updatePreview ({commit, getters}, {theme, body, type, revolutions, buttons, logo, modal, sector}) {
    const payload = {
      body,
      type: getters.types.find(el => el.id === type).alias,
      theme: getters.themes.find(el => el.id === theme).alias
    }
    if (revolutions != null) {
      payload.revolutions = revolutions
    }
    if (logo != null) {
      payload.logo = logo
    }
    if (buttons != null) {
      payload.buttons = JSON.stringify(buttons)
    }
    if (modal != null) {
      payload.modal = JSON.stringify(modal)
    }
    if (sector != null) {
      payload.sector = JSON.stringify(sector)
    }
    commit('setPreview', payload)
  },
  async fetchDataTypes ({commit}, payload) {
    try {
      const res = await service.getTypes(payload)
      commit('setTypes', res)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async fetchDataThemes ({commit}, payload) {
    try {
      const res = await service.getThemes(payload)
      commit('setThemes', res)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async createTypes (ctx, payload) {
    try {
      await service.createTypes(payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async createThemes (ctx, payload) {
    try {
      await service.createThemes(payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async updateTypes (ctx, {id, ...payload}) {
    try {
      await service.updateTypes(id, payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async updateThemes (ctx, {id, ...payload}) {
    try {
      await service.updateThemes(id, payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async deleteTypes (ctx, id) {
    try {
      await service.deleteTypes(id)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async deleteThemes (ctx, id) {
    try {
      await service.deleteThemes(id)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async fetchType ({commit}, id) {
    try {
      const res = await service.getType(id)
      commit('setType', res)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async fetchTheme ({commit}, id) {
    try {
      const res = await service.getTheme(id)
      commit('setTheme', res)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  model: state => {
    const {theme, type, ...payload} = state.model
    return {
      ...payload,
      type: type.id,
      theme: theme.id
    }
  },
  preview: state => state.preview,
  themes: state => state.themes.models,
  types: state => state.types.models,
  themesData: state => state.themes,
  typesData: state => state.types,
  type: state => state.type,
  theme: state => state.theme
}

const landingPages = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default landingPages
