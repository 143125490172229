<template>
  <div class="blk_tarifs clearfix">
    <div class="item_tarifs_g" v-for="tariff of tariffs" :key="tariff.id" :class="getClass(tariff)">
      <div class="in_to_item_tar_g">
        <div class="heading_tarfi__g">
          <div class="title_this_tari">{{ tariff.name[$i18n.locale] }}</div>
          <div class="subtit_this_tari" v-if="tariff.current">{{ $t('tariffs.your')}}</div>
        </div>
        <div class="line_tar__centr"></div>
        <div class="items__rtarif_g">
          <div class="in_to_items_ptar">
            <ul v-if="tariff.current">
              <li>{{ $t('tariffs.active', { used: tariff.used, pwa: tariff.pwas }) }}</li>
              <li>{{ $t('tariffs.activations', {num: tariff.activations}) }}</li>
              <li>{{ $tc('tariffs.expired', tariff.expired, {num: tariff.expired}) }}</li>
              <li>{{ tariff.description[$i18n.locale] }}</li>
            </ul>
            <ul v-else>
              <li>{{ $tc('tariffs.pwas', tariff.pwas, {num: tariff.pwas}) }}</li>
              <li>{{ $t('tariffs.limit', {num: tariff.limit}) }}</li>
              <li>{{ $t('tariffs.month') }}</li>
              <li>{{ tariff.description[$i18n.locale] }}</li>
            </ul>
          </div>
        </div>
        <div class="line_tar__centr"></div>
        <div class="foot_tar_sig">
          <div class="price_fin_fff">{{ tariff.cost | amount }}</div>
          <div class="butt_pay_ggg">
            <div class="d_inline_block">
              <span v-if="tariff.current">{{ $t('tariffs.auto') }}</span>
              <a href="javascript:void(0);" @click="activate(tariff)" v-if="!tariff.current && accesses.finance.update"
                 class="butt_paym_ggsg ful_butt_green d_flex align_center justify_center">
                 {{ tariff.upCost ? $t('tariffs.upCost', {num: tariff.upCost}) : $t('tariffs.pay') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tariffs.length === 0" class="text-center">
      <p class="text-muted">{{ $t('tariffs.noTariffs') }}</p>
    </div>
  </div>
</template>
<script>
import accessesMixin from '@/mixins/accesses-mixin'
import textMixin from '@/mixins/text-mixin'

export default {
  name: 'Mains',
  props: ['type'],
  mixins: [accessesMixin, textMixin],
  computed: {
    tariffs () {
      const tariffs = []
      for (const tariff of this.$store.getters['tariffs/mains']) {
        if (tariff.type === this.type) {
          tariffs.push(tariff)
        }
      }
      return tariffs
    }
  },
  methods: {
    getClass (tariff) {
      const classes = []
      if (tariff.current) {
        classes.push('our_tarif')
      }
      if (this.tariffs.length > 3) {
        classes.push('col-3')
      }
      return classes.join(' ')
    },
    activate (tariff) {
      if (tariff.upCost) {
        this.$parent.$parent.$refs.activate.show({
          ...tariff,
          cost: tariff.upCost
        })
      } else {
        this.$parent.$parent.$refs.activate.show(tariff)
      }
    }
  }
}
</script>
