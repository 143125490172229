import Vue from 'vue'
import routes from '@/routes'
import VueAuthenticate from 'vue-authenticate'
import service from '../services/users-service'

import axios from 'axios'
import VueAxios from 'vue-axios'
import {setI18nLanguage} from '@/plugins/i18n'

Vue.use(VueAxios, axios)

const vueAuth = VueAuthenticate.factory(Vue.prototype.$http, {
  baseUrl: import.meta.env.VITE_APP_API_BASE_URL,
  tokenName: 'accessToken',
  loginUrl: '/auth',
  tokenPath: 'accessToken'
})

const vueAuthTeam = VueAuthenticate.factory(Vue.prototype.$http, {
  baseUrl: import.meta.env.VITE_APP_API_BASE_URL,
  tokenName: 'accessToken',
  loginUrl: '/auth/teams/members',
  tokenPath: 'accessToken'
})

export default {
  state: () => ({
    isAuthenticated: localStorage.getItem('vue-authenticate.vueauth_accessToken') !== null,
    isAdmin: false,
    isManager: false,
    identity: JSON.parse(localStorage.getItem('identity'))
  }),

  getters: {
    isAuthenticated: state => state.isAuthenticated,
    isAdmin: state => state.isAdmin,
    isManager: state => state.isManager
  },

  mutations: {
    isAuthenticated: (state, payload) => {
      state.isAuthenticated = payload.isAuthenticated
    },
    isAdmin (state, payload) {
      state.isAdmin = payload
    },
    isManager (state, payload) {
      state.isManager = payload
    }
  },

  actions: {
    async auth (context, payload) {
      payload.user.referral = localStorage.getItem('referral')
      const res = await vueAuth.login(payload.user, payload.requestOptions)
      localStorage.setItem('identity', JSON.stringify({
        id: res.data._id,
        username: res.data.username,
        photo_url: payload.user.photo_url,
        ref_link: res.data.ref_link,
        isAdmin: res.data.isAdmin,
        lang: res.data.lang,
        tariffInstalled: res.data.tariffInstalled,
        pushesEnable: res.data.pushesEnable,
        team: res.data.teamEnable,
        customJs: res.data.customJs,
        showTrackerBypass: res.data.showTrackerBypass != null ? res.data.showTrackerBypass : false
      }))
      setI18nLanguage(res.data.lang)
      context.commit('isAuthenticated', {
        isAuthenticated: vueAuth.isAuthenticated()
      })
      localStorage.removeItem('referral')
      window.location.href = '/pwas'
    },

    authTeam: (context, payload) => {
      return vueAuthTeam.login(payload.user, payload.requestOptions).then(res => {
        const {member, ...user} = res.data
        localStorage.setItem('identity', JSON.stringify({
          isAdmin: false,
          memberId: payload.user.id,
          lang: member.lang || user.settings.lang,
          id: user._id,
          username: user.username,
          photo_url: user.photo_url,
          ref_link: user.ref_link,
          team: res.data.teamEnable,
          tariffInstalled: user.tariffInstalled,
          pushesEnable: user.pushesEnable,
          customJs: user.customJs,
          showTrackerBypass: res.data.showTrackerBypass != null ? res.data.showTrackerBypass : false,
          isCompaniesOwner: member.isCompaniesOwner
        }))
        setI18nLanguage(member.lang)
        context.commit('isAuthenticated', {
          isAuthenticated: vueAuth.isAuthenticated()
        })
        localStorage.removeItem('referral')
        window.location.href = '/pwas'
      })
    },

    logout (context) {
      return vueAuth.logout().then(() => {
        localStorage.removeItem('identity')
        localStorage.removeItem('vue-authenticate.vueauth_accessToken')
        context.commit('isAdmin', false)
        context.commit('isAuthenticated', {
          isAuthenticated: vueAuth.isAuthenticated()
        })
        routes.push({name: 'Auth'})
      }).catch(() => {
        localStorage.removeItem('identity')
        localStorage.removeItem('vue-authenticate.vueauth_accessToken')
        context.commit('isAdmin', false)
        context.commit('isAuthenticated', false)
        window.location.href = '/auth'
      })
    },

    admin (context, payload) {
      return service.read()
        .then(user => {
          if (user.permissionLevel >= 7) {
            if (user.permissionLevel === 7) {
              user.isManager = true
              context.commit('isManager', true)
            }
            if (user.permissionLevel === 8) {
              user.isAdmin = true
              context.commit('isAdmin', true)
            }
          } else {
            user.isAdmin = false
            context.commit('isAdmin', false)
            window.location.href = '/auth'
          }
          localStorage.setItem('identity', JSON.stringify({
            id: user._id,
            lang: user.lang,
            username: user.username,
            photo_url: user.photo_url,
            ref_link: user.ref_link,
            isAdmin: user.isAdmin,
            tariffInstalled: user.tariffInstalled,
            pushesEnable: user.pushesEnable,
            team: user.teamEnable,
            customJs: user.customJs,
            showTrackerBypass: false
          }))
        }).catch(async () => {
          await context.dispatch('logout')
        })
    }
  }
}
