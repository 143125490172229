<template>
  <date-picker
    id="dateField"
    v-model="date"
    type="datetime"
    valueType="format"
    :clearable="false"
    :placeholder="$t('pushes.form.publishedAt.placeholder')"
    :open.sync="open"
    format="YYYY-MM-DD HH:mm"
    title-format="YYYY-MM-DD HH:mm"
    :lang="lang"
    :disabledDate="disabledDates"
  >
  </date-picker>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/uk'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/en'
import 'vue2-datepicker/locale/pt'
import 'vue2-datepicker/index.css'

export default {
  name: 'DateField',
  components: {DatePicker},
  props: {value: String},
  data () {
    return {
      open: false
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    date: {
      get () {
        return this.value
      },
      set (input) {
        this.$emit('input', input)
      }
    }
  },
  methods: {
    disabledDates (date) {
      const now = new Date()
      // eslint-disable-next-line no-undef
      return moment(now.setDate(now.getDate() - 1)).utcOffset(0).diff(moment(date).utcOffset(0)) > 0
    }
  }
}
</script>
