export default {
  invalid: 'Invalid value',
  domain: {
    wrongFormat: 'Wrong domain or domain zone format'
  },
  image: {
    maxSize: 'The file size should not exceed 2MB.',
    required: 'This image is required'
  },
  accessDenied: "You don't have access, please contact your administrator",
  dateOutOfRange: 'Date is beyond allowed range. Choose a date within one month.',
  dashboardAccessDenied: 'Access to this data is restricted for your account',
  requestError: 'Currently unable to load data for display.',
  requestError504: 'Something went wrong, please contact the administrator!'
}
