<template>
  <modal
    id="admin-tariffs-settings-form-modal"
    ref="modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Налаштування тарифів</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div v-if="!loading" class="modal-body">
      <div class="form-group" :class="apiValidationErrors['billing.minInstalledAmount'] ? 'has-danger' : ''">
        <label for="minInstalledAmount">Минимальная сумма за инсталы</label>
        <input type="number" step="0.01" id="minInstalledAmount" placeholder="Введите сумму" v-model="minInstalledAmount"
               class="form-control" min="1" max="100"
               :class="apiValidationErrors['billing.minInstalledAmount'] ? 'form-control-danger' : ''"
               @focus="clearError('minInstalledAmount')">
        <small class="form-text text-muted">Десятичная дробь, минимальная сумма пополнения в $, при включенном тарифе за инстали</small>
        <label v-if="apiValidationErrors['billing.minInstalledAmount']" class="error mt-2 text-danger" for="minInstalledAmount">
          {{ apiValidationErrors['billing.minInstalledAmount'][0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.minInstalledBalance ? 'has-danger' : ''">
        <label for="minInstalledBalance">Минимальный баланс за инсталы</label>
        <div class="input-group-append">
          <input type="number" step="0.01" id="minInstalledBalance" placeholder="Введите баланс"
                 v-model="minInstalledBalance"
                 class="form-control" min="1" max="100"
                 :class="apiValidationErrors.minInstalledBalance ? 'form-control-danger' : ''"
                 @focus="clearError('minInstalledBalance')">
          <div class="input-group-append">
            <span class="input-group-text">$</span>
          </div>
        </div>
        <small class="form-text text-muted">Минимальный баланс при котором отправлять уведомления</small>
        <label v-if="apiValidationErrors.minInstalledBalance" class="error mt-2 text-danger"
               for="minInstalledBalance">
          {{ apiValidationErrors.minInstalledBalance[0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors['tariffs.newUserTariffType'] ? 'has-danger' : ''">
        <label for="newUserTariffType">Тип тарифу при реєстрації</label>
        <select class="form-control" id="newUserTariffType" name="newUserTariffType" type="text" tabindex="1"
               v-model="newUserTariffType"
               :class="apiValidationErrors['tariffs.newUserTariffType'] ? 'form-control-danger' : ''"
               @focus="clearError('tariffs.newUserTariffType')">
          <option value="subscribers">За підписку</option>
          <option value="installation">За інстал PWA</option>
        </select>
        <label v-if="apiValidationErrors['tariffs.newUserTariffType']" class="error mt-2 text-danger"
               for="newUserTariffType">{{ apiValidationErrors['tariffs.newUserTariffType'][0] }}</label>
        <small class="form-text text-muted">Оберіть зі списку тип тарифу для нових користувачів</small>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import _ from 'lodash'
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import {mapActions, mapGetters} from 'vuex'

const defaultValues = () => ({
  minInstalledAmount: 0,
  minInstalledBalance: 0,
  newUserTariffType: 'subscribers'
})

export default {
  name: 'AdminTariffsSettingsForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      loading: true,
      processing: false,
      ...defaultValues()
    }
  },
  computed: {
    ...mapGetters({
      tariffs: 'adminConfig/tariffs',
      billing: 'adminConfig/billing'
    })
  },
  methods: {
    ...mapActions({
      fetchData: 'adminConfig/fetchData',
      update: 'adminConfig/update',
      alertSuccess: 'alerts/success'
    }),
    debounceFetchData: _.debounce(async function () {
      await this.fetchData()
      this.minInstalledAmount = this.billing.minInstalledAmount
      this.minInstalledBalance = this.billing.minInstalledBalance
      this.newUserTariffType = this.tariffs.newUserTariffType
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData()
    },
    handlerOpen () {
      this.fetchPage()
      this.skip()
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.update({
          tariffs: {
            newUserTariffType: this.newUserTariffType
          },
          billing: {
            minInstalledAmount: this.minInstalledAmount,
            minInstalledBalance: this.minInstalledBalance
          }
        })
        this.resetApiValidation()
        this.processing = false
        this.$refs.modal.close()
        await this.alertSuccess('Налаштування тарифів збережено')
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
