export default {
  online: {
    title: 'Únicos on-line',
    info: 'Únicos utilizadores ativos na aplicação neste momento',
    table: ['Data', 'País', 'Dispositivo', 'OS', 'Definições', 'Aberturas', 'PUSH'],
    deviceUnrecognized: 'Não reconhecido'
  },
  activity: {
    title: 'Atividade',
    info: 'Relação de movimentos de utilizadores (Definições PWA, Aberturas PWA, subscrições para Push notificações)',
    uniques: 'Sem únicos | Único | Únicos | Únicos',
    uniquesInfo: 'Número de únicos dentro de últimos 3 meses',
    installations: 'Definições',
    installationsFull: 'Definições',
    opens: 'Aberturas'
  },
  uniquesAndInstalls: {
    title: 'Únicos e definições',
    info: 'Número de utilizadores únicos e instalações de PWA dentro de últimos 14 dias úteis',
    uniques: 'Sem únicos | Único | Únicos | Únicos',
    uniquesInfo: 'Número de únicos dentro de últimos 14 dias úteis',
    unique: 'Únicos',
    installations: 'Definições'
  },
  transactions: {
    title: 'Finanças',
    info: 'Breve estatística de suas rendimentos e despesas'
  },
  topOnline: {
    title: 'Top on-line',
    info: 'Top Pwa por tráfego neste momento'
  }
}
