<template>
  <div class="NavPaginate mt-4 d-lg-flex justify-content-between nav-pagination">
    <div class="row mx-md-0 d-md-flex justify-content-between">
      <slot/>
      <!-- <div
        v-if="pageCount > 1"
        class="col-6 col-md-auto p-md-0 form-group pagination-input mr-lg-3">
        <label for="inputPage">{{ $t('general.gotoPage') }}</label>
        <input
          id="inputPage"
          class="form-control"
          type="number" min="1" :max="pageCount" step="1" v-model="handleInputPage">
      </div> -->
    </div>
    <div
      class="d-flex align-items-center justify-content-center nav-pagination__wrapper"
    >
      <div
        v-if="count > 5"
        class="nav-pagination__limit-field"
      >
        <span class="nav-pagination__limit-field-title">{{ $t('general.recordsPerPage') }}</span>
        <BaseSelectSimple
          class="nav-pagination__limit-select"
          v-model="handleLimit"
          :options="[5, 10, 20, 50, 100]"
        />
      </div>
      <paginate
        v-if="pageCount > 1"
        ref="paginate"
        v-model="page"
        :page-count="pageCount"
        :click-handler="handlerPagination"
        :prev-text="'<i class=\'icon-arrow-left\'></i>'"
        :next-text="'<i class=\'icon-arrow-right\'></i>'"
        container-class="pagination_gd_go d-flex align-items-center justify-content-center m-0 p-0"
        :page-class="'page_item_gd nav-pagination__item'"
        :page-link-class="'page_link_g d_flex justify_center align_center nav-pagination__item-link'"
        :prev-class="'page-item_go nav-pagination__next-prev'"
        :next-class="'page-item_go nav-pagination__next-prev'"
        :prev-link-class="'page-link_go nav-pagination__next-prev-link'"
        :next-link-class="'page-link_go nav-pagination__next-prev-link'"
        :active-class="'active'">
      </paginate>
    </div>
  </div>
</template>

<script>
import BaseSelectSimple from '@/components/base/BaseSelect/BaseSelectSimple.vue'

export default {
  name: 'NavPaginate',
  props: {
    pageCount: Number,
    inputPage: Number,
    value: Number,
    limit: Number,
    count: Number
  },
  components: {BaseSelectSimple},
  computed: {
    page: {
      get () {
        return this.value
      },
      set (input) {
        this.$emit('input', input)
      }
    },
    handleLimit: {
      get () {
        return this.limit
      },
      set (input) {
        this.$emit('limit', +input)
      }
    },
    handleInputPage: {
      get () {
        return this.inputPage
      },
      set (input) {
        this.$emit('inputPage', +input)
        this.$emit('changePage', +input)
      }
    }
  },
  methods: {
    handlerPagination (page = 1) {
      this.$emit('pagination', page)
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.nav-pagination
  &__wrapper
    @media screen and (max-width: 576px)
      flex-direction: column
      align-items: center
      justify-content: center
  &__limit-field
    display: flex
    flex-direction: row
    align-items: center
    margin-right: 40px
    @media screen and (max-width: 576px)
      margin-right: 0
      margin-bottom: 20px
  &__limit-field-title
    font-size: 12px
    +font(400)
    color: $grey-santas-gray
    margin-right: 10px
  &__limit-select
    :deep(.vs__selected-options)
      input
        margin: 0
        padding: 0
    :deep(.vs__dropdown-toggle)
      padding: 0
      min-height: 0px
      border: none
    :deep(.vs__selected)
      color: white
      +font(400)
      position: relative !important
      margin-right: 3px
    :deep(.vs__dropdown-menu)
      top: -175px
    &.vs--open
      :deep(.vs__selected)
        display: flex
        opacity: 1
  :deep(.nav-pagination__item-link)
    color: $grey-outer-space
    font-size: 12px
    +font(400)
    border-radius: 3px
    border: 1px solid $grey-outer-space
    &:hover
      border-color: $grey-storm-gray
      color: $grey-storm-gray
  :deep(.nav-pagination__item)
    &.active
      .nav-pagination__item-link
        background: $green
        border-color: $green
        color: white
        &:hover
          border-color: $green
          color: white
  :deep(.nav-pagination__next-prev.disabled)
    opacity: .6
  :deep(.nav-pagination__next-prev-link)
    border: 1px solid $grey-outer-space
    display: flex
    justify-content: center
    align-items: center
    &:hover
      border-color: $grey-storm-gray
      color: $grey-storm-gray
      i
        +mt(.3s)
        color: $grey-storm-gray
    i
      +mt(.3s)
      font-weight: 600
      color: $grey-outer-space
</style>
