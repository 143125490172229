<template>
  <modal ref="modal" id="facebook-events-form" class="fade"
         classContent="modal-content" classDialog="modal-dialog">
    <div class="title_mod_uplo">{{ $t('pwas.components.facebook.title') }}</div>
    <div class="link_ths_ig">{{ pixel }}</div>
    <form @submit.prevent="handleSubmit">
      <div class="div">
        <div class="subtit_esgsgg">Access Token</div>
        <div class="form-group">
          <label for="facebook-pixel-access-token">{{ $t('pwas.components.facebook.label') }}
          </label>
          <input class="form-control" id="facebook-pixel-access-token" v-model="accessToken"
                 placeholder="7102ab285cea9da54688aeca5ac4e01b0f957e37"/>
        </div>
      </div>
      <template v-for="event of events">
        <div class="div" :key="event.id" v-if="event.visible">
          <div class="subtit_esgsgg">{{ $t(event.label) }}</div>
          <BaseFromGroup
            :id="'facebook-pixel-event-' + event.id"
            :name="'facebook-pixel-event-' + event.id"
            :label="getStandardFacebookEventDescription($data[event.id])"
          >
            <BaseSelect
              v-model="$data[event.id]"
              :id="'facebook-pixel-event-' + event.id"
              :name="'facebook-pixel-event-' + event.id"
              :options="standardFacebookEvents"
              :placeholder="$t('pwas.components.facebook.dontUse')"
            />
          </BaseFromGroup>
        </div>
      </template>
      <div class="footer_modl_akdhg">
        <div class="form-group">
          <div class="in_topshgdjf_g d_flex align_center">
            <div class="item_avt_butt_jg">
              <button type="submit" class="ful_butt_green d_flex align_center justify_center">{{
                  $t('general.save')
                }}
              </button>
            </div>
            <div class="item_avt_butt_jg">
              <a href="javascript:void(0);" @click="handlerCancel"
                 class="border_butt_green d_flex align_center justify_center">{{ $t('general.cancel') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>
<script>
import Info from '@/components/Info.vue'
import * as dictionary from '@pwa.group/pwa.dictionary'
import Modal from '@/components/modal/Modal.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

const defaultFields = () => ({
  pixel: '',
  install: null,
  open: '',
  push: '',
  registration: null,
  deposit: null,
  accessToken: ''
})

export default {
  name: 'Events',
  components: {BaseSelect, BaseFromGroup, Info, Modal},
  data () {
    return {
      ...defaultFields(),
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    standardFacebookEvents () {
      return dictionary.Facebook.events.map(event => ({id: event.id, text: this.$t(event.label), description: event.description}))
    },
    events () {
      return [
        {id: 'install', label: 'pwas.components.facebook.events[0]', visible: true},
        {id: 'open', label: 'pwas.components.facebook.events[1]', visible: true},
        {id: 'push', label: 'pwas.components.facebook.events[2]', visible: true},
        {id: 'registration', label: 'pwas.components.facebook.events[3]', visible: true},
        {id: 'deposit', label: 'pwas.components.facebook.events[4]', visible: true}
      ]
    }
  },
  methods: {
    update (pixel) {
      this.pixel = pixel._id
      this.install = pixel.install || null
      this.open = pixel.open || ''
      this.push = pixel.push || ''
      this.accessToken = pixel.accessToken || ''
      this.registration = pixel.registration || null
      this.deposit = pixel.deposit || null

      this.$refs.modal.open()

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handleSubmit () {
      const {pixel, ...result} = defaultFields()
      const fields = ['install', 'open', 'push', 'registration', 'deposit', 'accessToken']
      for (const field of fields) {
        if (this[field] || this[field] !== '') {
          result[field] = this[field]
        } else {
          result[field] = null
        }
      }
      this.resolvePromise({...result, _id: this.pixel})
      this.$refs.modal.close()
    },
    handlerCancel () {
      this.$refs.modal.close()
      this.resolvePromise(false)
    },
    getStandardFacebookEventDescription (event) {
      const index = this.standardFacebookEvents.findIndex(standardFacebookEvent => standardFacebookEvent.id === event)
      if (index === -1) {
        return this.$t('pwas.components.facebook.dontUse')
      } else {
        return this.$t(this.standardFacebookEvents[index].description)
      }
    }
  }
}
</script>
