export default {
  navigation: 'Налаштування',
  title: 'Налаштування PWA #{id}',
  message: 'Налаштуйте програми під себе',
  tags: 'Теги PWA',
  tagsInfo: 'Набір тегів для фільтрації PWA',
  alias: 'Унікальний Аліас в системі',
  aliasInfo: 'Це унікальне системне ім\'я PWA для спрощення розуміючи де яке PWA',
  installMessage: 'Фейкове завантаження буде проходити від <strong>{stepMin}</strong> до <strong>{stepMax}</strong> кроків з інтервалом від <strong>{intervalMin} мс</strong>. до <strong>{intervalMax} мс</strong>. При цьому мінімально можливий час складе <strong>{minInstallingTime} сек.</strong>, а максимально можливий <strong>{maxInstallingTime} сек.</strong> У середньому фейкове завантаження пройде за <strong>{avgInstallingTime} сек.</strong>',
  minStep: 'Мін. кроків',
  minStepInfo: 'Цей параметр вказує на мінімальну можливу кількість кроків завантаження',
  maxStep: 'Макс. кроків',
  maxStepInfo: 'Цей параметр вказує на максимальну можливу кількість кроків завантаження',
  minInterval: 'Мін. інтервал',
  minIntervalInfo: 'Цей параметр вказує на мінімальний можливий час між кроками завантаження',
  maxInterval: 'Макс. інтервал',
  maxIntervalInfo: 'Цей параметр вказує на максимальний можливий час між кроками завантаження',
  customJs: 'Користувацький JS',
  customJsInfo: 'Для того щоб додати користувацький JS у ваше PWA зверніться до адміністрації сервісу'
}
