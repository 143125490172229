<template>
  <div>
    <select class="form-control w-100" id="pwa-filter">
      <option value=""></option>
      <option :key="pwa._id" v-for="pwa in pwas"
              :value="pwa._id">{{ pwa.alias || pwa.name }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'PWA',
  props: {
    pwas: {
      type: Array,
      required: true
    }
  },
  mounted: function () {
    this.initSelect2()
  },
  updated: function () {
    this.initSelect2()
  },
  methods: {
    initSelect2: function () {
      const self = this
      // eslint-disable-next-line no-undef
      jQuery('#pwa-filter')
        .select2({placeholder: 'Фильтр по PWA'})
        .on('change', event => self.$emit('input', event.target.value))
    }
  }
}
</script>
