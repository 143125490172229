<script>
import ToggleItem from '@/components/landing-pages/ToggleItem.vue'
import formMixin from '@/mixins/form-mixin'
import _ from 'lodash'

export default {
  components: {
    ToggleItem
  },
  props: {
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    errors: Object
  },
  mixins: [formMixin],
  data () {
    return {
      pageIndex: 1,
      pageSize: 3,
      selectedThemeId: 1
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    },
    pageIndex () {
      this.updateSelectedTheme(null)
    },
    selectedThemeId: {
      handler (newTheme) {
        this.updateSelectedTheme(newTheme)
        this.goToPageWithTheme(newTheme)
      },
      immediate: true
    },
    theme: {
      handler (newTheme) {
        this.selectedThemeId = newTheme
      },
      immediate: true
    }
  },
  computed: {
    theme: {
      get () {
        return this.value
      },
      set (input) {
        this.$emit('input', input)
      }
    },
    themes () {
      return this.$store.getters['landingPages/themes'].filter(theme => theme.type === this.type)
    },
    pageCount () {
      return Math.ceil(this.themes.length / this.pageSize)
    },
    displayedThemes () {
      const startIndex = (this.pageIndex - 1) * this.pageSize
      const endIndex = startIndex + this.pageSize
      return this.themes.slice(startIndex, endIndex)
    }
  },
  methods: {
    onScreenResize () {
      if (window.innerWidth < 768) {
        this.pageSize = 2
      } else if (window.innerWidth < 1200) {
        this.pageSize = 4
      } else {
        this.pageSize = 3
      }
    },
    updateSelectedTheme (themeId) {
      this.selectedTheme = themeId
    },
    goToPageWithTheme (themeId) {
      const index = this.themes.findIndex(theme => theme.id === themeId)
      if (index !== -1) {
        this.pageIndex = Math.ceil((index + 1) / this.pageSize)
      }
    },
    pagination (page) {
      this.pageIndex = page
      this.selectedThemeId = this.theme
      this.$emit('input', this.selectedThemeId)
    }
  },
  mounted () {
    this.onScreenResize()
    window.addEventListener('resize', _.debounce(this.onScreenResize, 200))
  }
}
</script>

<template>
  <ToggleItem
    :header="$t('landingPages.themesForm.header')"
    :info="$t('landingPages.themesForm.info')"
  >
    <div class="row">
      <div v-for="item in displayedThemes" :key="item.id"
           class="col-xl-4 col-lg-6 col-md-5 col-sm-12 col-12 adaptive-center form-group">
        <div class="image-wrapper" :class="{ 'selected': theme === item.id }">
          <label class="image-label label pb-3 mb-0">
            <div :style="{ backgroundImage: 'url(' + item.preview + ')' }"
                 class="game-image"></div>
            <div class="d_flex align-items-center mt-3">
              <input
                :value="item.id"
                v-model="theme"
                type="radio"
                name="theme"
                class="mr-2 ml-3"
                @change="updateSelectedTheme(item.id)"
              >
              <span>{{ item.name }}</span>
            </div>
          </label>
        </div>
      </div>
    </div>
    <nav v-if="pageCount > 1">
      <paginate ref="paginate"
                v-model="pageIndex"
                :page-count="pageCount"
                :value="pageIndex"
                :click-handler="pagination"
                :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                :next-text="'<i class=\'icon-arrow-right\'></i>'"
                :container-class="'mb-3 mt-2 pagination_gd_go d_flex justify_center align_center'"
                :page-class="'page_item_gd'"
                :page-link-class="'page_link_g d_flex justify_center align_center'"
                :prev-class="'page-item_go'"
                :next-class="'page-item_go'"
                :prev-link-class="'page-link_go d_flex justify_center align_center'"
                :next-link-class="'page-link_go d_flex justify_center align_center'"
                :active-class="'active'">
      </paginate>
    </nav>
  </ToggleItem>
</template>

<style scoped>
.label {
  width: 100%;
}

.pagination_gd_go {
  padding: 0;
}

.image-wrapper {
  position: relative;
  font-size: 12px;
  color: #A0A1A4;
  border: 2px solid transparent;
}

.content_dropd_g .row_mob_sg .col-4:first-child {
  margin-bottom: 15px;
}

.image-wrapper.selected .game-image {
  opacity: 1;
}

.image-wrapper:not(.selected) .game-image {
  opacity: 0.3;
}

.image-wrapper.selected {
  border: 2px solid #21BF73;
  border-radius: 10px;
  overflow: hidden;
}

.image-wrapper.selected .label {
  background-color: #253834;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  border: 1px solid #A0A1A4;
  border-radius: 4px;
}

.image-wrapper.selected input[type="radio"] {
  background-image: url('/landgins-page/checkbox.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid #21BF73;
}

.sector-input input[type="radio"] {
  margin-right: 10px;
  width: 22px;
}

.game-image {
  width: 100%;
  height: 476px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  transition: all .2s;
}

.sector-wrapper.selected input[type="radio"] {
  background-image: url('/landgins-page/checkbox.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #21BF73;
}

.sector-wrapper.selected input[type="text"] {
  outline: 1px solid #21BF73;
  background-color: #253834;
}
.image-label {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .image-wrapper {
    max-width: 220px;
    width: 100%;
  }
  .adaptive-center {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 990px) {
  .adaptive {
    display: flex;
    justify-content: center;
  }
}
</style>
