<script>
import ToggleItem from '@/components/landing-pages/ToggleItem.vue'
import formMixin from '@/mixins/form-mixin'
import LogoInput from '@/components/landing-pages/LogoInput.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  components: {BaseSelect, BaseFromGroup, LogoInput, ToggleItem},
  props: {
    body: {
      type: String,
      required: true
    },
    buttons: {
      type: Object,
      required: true
    },
    revolutions: {
      type: Number,
      default: 1
    },
    logo: {
      type: String
    },
    errors: Object
  },
  mixins: [formMixin],
  data () {
    return {
      revolutionsOptions: [
        {id: 1, text: '1'},
        {id: 2, text: '2'},
        {id: 3, text: '3'},
        {id: 4, text: '4'},
        {id: 5, text: '5'}
      ]
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  },
  computed: {
    handleLogo: {
      get () {
        return this.logo
      },
      set (input) {
        this.$emit('update:logo', input)
      }
    },
    handleBody: {
      get () {
        return this.body
      },
      set (input) {
        this.$emit('update:body', input)
      }
    },
    buttonGo: {
      get () {
        return this.buttons.go
      },
      set (input) {
        this.$emit('update:buttons', {...this.buttons, go: input})
      }
    },
    buttonMore: {
      get () {
        return this.buttons.more
      },
      set (input) {
        this.$emit('update:buttons', {...this.buttons, more: input})
      }
    },
    handleRevolutions: {
      get () {
        return this.revolutions
      },
      set (input) {
        this.$emit('update:revolutions', input)
      }
    }
  }
}
</script>

<template>
  <ToggleItem
    :header="$t('landingPages.paramsForm.header')"
    :info="$t('landingPages.paramsForm.info')"
  >
    <div class="adaptive-row d-flex">
      <div class="col-xl-6 col-12">
        <div class="form-group" :class="apiValidationErrors[`body`] ? 'has-danger' : ''">
          <label for="body">{{ $t('landingPages.paramsForm.body') }}</label>
          <input
            type="text"
            v-model="handleBody"
            class="form-control"
            id="body"
            name="body"
          />
          <label v-if="apiValidationErrors[`body`]"
                 class="error mt-2 text-danger"
                 :for="`body`">
            {{ $t(apiValidationErrors[`body`][0]) }}
          </label>
        </div>
        <div class="form-group" :class="apiValidationErrors[`buttons.go`] ? 'has-danger' : ''">
          <label for="buttonGo">{{ $t('landingPages.paramsForm.buttonGo') }}</label>
          <input
            type="text"
            v-model="buttonGo"
            class="form-control"
            id="buttonGo"
            name="buttonGo"
          />
          <label v-if="apiValidationErrors[`buttons.go`]"
                 class="error mt-2 text-danger"
                 :for="`buttonGo`">
            {{ $t(apiValidationErrors[`buttons.go`][0]) }}
          </label>
        </div>
        <div class="form-group" :class="apiValidationErrors[`buttons.more`] ? 'has-danger' : ''">
          <label for="buttonMore">{{ $t('landingPages.paramsForm.buttonMore') }}</label>
          <input
            type="text"
            v-model="buttonMore"
            class="form-control"
            id="buttonMore"
            name="buttonMore"
          />
          <label v-if="apiValidationErrors[`buttons.more`]"
                 class="error mt-2 text-danger"
                 :for="`buttonMore`">
            {{ $t(apiValidationErrors[`buttons.more`][0]) }}
          </label>
        </div>
      </div>
      <div class="col-xl-6 col-12">
        <LogoInput
          v-model="handleLogo"
          :errors="apiValidationErrors"
        />
        <BaseFromGroup
          id="revolutions"
          name="revolutions"
          :label="$t('landingPages.paramsForm.revolutions')"
          :has-error="apiValidationErrors.revolutions"
          :error-text="apiValidationErrors?.revolutions ? apiValidationErrors?.revolutions[0] : ''"
        >
          <BaseSelect
            v-model="handleRevolutions"
            id="revolutions"
            name="revolutions"
            :clearable="false"
            :options="revolutionsOptions"
          />
        </BaseFromGroup>
      </div>
    </div>
  </ToggleItem>
</template>

<style scoped>
.adaptive-row {
  flex-direction: row;
}

@media only screen and (max-width: 1200px) {
  .adaptive-row {
    flex-direction: column;
  }
}
</style>
