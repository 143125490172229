<template>
  <div class="item_silter_sjhf_g">
    <select class="form-control  w-100" id="PWAs-filter" v-model="value">
      <option value=""></option>
      <option :value="item.id" v-for="item of data" :key="item.id">
        {{ item.text }}
      </option>
    </select>
  </div>
</template>
<script>
import {getCurrentLang} from '@/utils/i18n'

export default {
  name: 'PWAs',
  props: {
    value: {
      type: String
    },
    data: {
      type: Array,
      default () {
        return []
      },
      required: false
    },
    ajaxUrl: {
      type: String,
      default: '/statistics/groups/pwas'
    }
  },
  watch: {
    value: function () {
      this.init()
    }
  },
  methods: {
    init: function () {
      const self = this
      // eslint-disable-next-line no-undef
      jQuery('#PWAs-filter').select2({
        placeholder: 'Фильтр по PWA',
        escapeMarkup: function (m) {
          return m
        },
        allowClear: true,
        ajax: {
          dataType: 'json',
          url: import.meta.env.VITE_APP_API_BASE_URL + this.ajaxUrl,
          delay: 500,
          transport: function (params, success, failure) {
            // eslint-disable-next-line no-undef
            const $request = $.ajax({
              ...params,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('vue-authenticate.vueauth_accessToken'),
                'Accept-Language': getCurrentLang()
              }
            })
            $request.then(success)
            $request.fail(failure)
            return $request
          },
          data: params => {
            const filter = {}
            for (const [key, value] of Object.entries(self.$parent.filter)) {
              filter[`filter[${key}]`] = value
            }
            return {
              page: params.page || 1,
              limit: 5,
              sort: 'text',
              ...filter,
              text: params.term
            }
          }
        }
      })
        .on('change', function (event) {
          self.$emit('input', event.target.value)
        })
    }
  },
  mounted: function () {
    this.init()
  }
}
</script>
