/* eslint-disable no-template-curly-in-string */
export default {
  bills: {
    title: 'Счета',
    noHave: 'У Вас нет созданных счетов. Пополните счёт',
    amountTopUp: 'Сумма пополнения',
    topUp: 'Пополнить',
    status: 'Состояние',
    statusTitle: 'Текущее состояние счета',
    amount: 'Сумма',
    amountTitle: 'Сумма  которая поступит(ла) на счёт',
    total: 'Итого',
    totalTitle: 'Сумма в $ которую нужно будет оплатить',
    method: 'Способ оплаты',
    methodTitle: 'Способ оплаты счёта',
    created: 'Создан',
    createdTitle: 'Дата создания счёта',
    actions: 'Действия',
    paidSuccess: 'Счет оплачен',
    paid: 'Оплачен',
    expiresIn: 'Истекает через:',
    overdue: 'Просрочен',
    rejected: 'Отклонен',
    info: 'Инфо',
    modal: {
      title: 'Счет на оплату #{id}',
      info: [
        `Счет подтвержден на <label class="text-warning">{confirmation}/6</label>, до
        окончания работы автоматической проверки осталось
        <label class="text-warning">{expired}</label>, после этого будет доступно только ручное
        подтверждения`,
        `Ожидаем уведомления об оплате. Если вы не смогли оплатить счёт можете сделать это повторно по <a href="{pay_url}">ссылке</a>`,
        'Счёт успешно оплачен'
      ]
    },
    bitcoin: {
      title: 'Внесите оплату на указанный счет',
      confirmed: 'Подтвержден',
      wallet: 'Кошелёк',
      paid: 'Оплачено',
      check: 'Проверить'
    },
    tether: {
      title: 'Внесите оплату на указанный счет',
      wallet: 'Кошелёк',
      hash: {
        title: 'Хэш(txid) транзакции',
        placeholder: 'Вставте сюда хэш транзакции',
        notEmpty: 'Не может быть пустим'
      },
      paid: 'Оплачено'
    }
  },
  transactions: {
    title: 'Транзакции',
    noHave: 'У Вас нет транзакций для отображения',
    created: 'Создан',
    createdTitle: 'Дата создания транзакции',
    amount: 'Сумма',
    amountTitle: 'Сумма в $ транзакции',
    purpose: 'Назначение',
    purposeTitle: 'Назначение транзакции',
    type: {
      pwa: 'Оплата за PWA #{pwa}',
      domain: 'Покупка домена {domain} для PWA #{pwa}',
      referral: 'Партнёрские роялти',
      bill: 'Пополнения баланса на ${amount} при оплате счёта #{bill}',
      tariff: 'Подписка на тариф #{tariff}',
      install: 'Оплата установки',
      bypass: 'Стоимость установки через Bypass',
      ios: 'Стоимость установки если iOS',
      correction: 'Коррекция счета',
      reject: 'Счёт #{bill} отменен'
    }
  },
  payment: {
    minAmount: 'Минимальная сумма платежа ${num}',
    maxAmount: 'Максимальная сумма платежа ${num}',
    title: 'Внесите оплату на указанный счет',
    method: 'Выберите платежную систему:',
    amount: 'Сумма к оплате:',
    pay: 'Оплатить'
  }
}
