import AdminBilling from './AdminBilling.vue'
import AdminBillingConfig from './AdminBillingConfig.vue'
import AdminBillingMerchants from './AdminBillingMerchants.vue'
import AdminBillingMerchantsGateways from './AdminBillingMerchantsGateways.vue'

export default (meta, header) => {
  return [
    {
      path: 'billing',
      name: 'AdminBilling',
      components: {default: AdminBilling, header},
      meta
    },
    {
      path: 'billing/merchants',
      name: 'AdminBillingMerchants',
      components: {default: AdminBillingMerchants, header},
      meta
    },
    {
      path: 'billing/merchants/:id',
      name: 'AdminBillingMerchantsGateways',
      components: {default: AdminBillingMerchantsGateways, header},
      meta
    },
    {
      path: 'billing/config',
      name: 'AdminBillingConfig',
      components: {default: AdminBillingConfig, header},
      meta
    }
  ]
}
