<template>
  <div class="form-group" :class="error ? 'has-danger' : ''">
    <label :for="id">{{ label }}
      <info :message="infoMessage"/>
    </label>
    <input type="text" :id="id" :placeholder="placeholder"
           @input="handlerInput"
           :class="error ? 'form-control-danger' : ''"
           @focus="error = false"
           class="picker_frm_inp form-control">
    <label v-if="error"
           class="error mt-2 text-danger" :for="id">
      {{ error }}
    </label>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import {getCurrentLang} from '@/utils/i18n'

export default {
  name: 'PwasCommentsDatepicker',
  components: {Info},
  props: {
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    infoMessage: {
      type: String,
      default: ''
    },
    value: null
  },
  data () {
    return {
      error: false,
      datepicker: null
    }
  },
  mounted () {
    const datepickerOptions = {
      maxViewMode: 0,
      format: 'dd.mm.yy',
      autoclose: false,
      container: '.main-panel',
      clearBtn: true,
      todayHighlight: true,
      disableTouchKeyboard: false,
      keyboardNavigation: false,
      forceParse: false,
      language: getCurrentLang()
    }
    // eslint-disable-next-line no-undef
    this.datepicker = jQuery(`#${this.id}`).datepicker(datepickerOptions)
    this.datepicker.datepicker('setDate', this.valueToUTC(this.value))
      .on('change', this.handlerInput)
  },
  beforeDestroy () {
    this.datepicker.datepicker('destroy')
  },
  methods: {
    valueToUTC (value) {
      if (value == null || value === '') {
        return ''
      }
      value = value.split('.')
      value[2] = '20' + value[2]
      value = value.reverse()
      value = value.join('-')
      return new Date(value)
    },
    validateInput (value) {
      if (value.length === 0) return true
      value = value.split('.')
      if (value.length !== 3) return false
      for (let i = 0; i < 3; i++) {
        if (value[i].length !== 2) return false
      }
      return true
    },
    handlerInput ($event) {
      this.datepicker.datepicker('hide')
      const value = $event.target.value
      if (this.validateInput(value)) {
        this.$emit('input', value)
        if (this.error !== false) {
          this.$nextTick(() => {
            this.datepicker.datepicker('update', this.valueToUTC(value))
            this.datepicker.datepicker('setDate', this.valueToUTC(value))
          })
        }
        this.error = false
      } else {
        this.error = this.$t('pwas.components.comment.validate.dateFormat')
      }
    }
  }
}
</script>

<style scoped>
.picker_frm_inp.form-control {
  width: 100%;
}
</style>
