<template>
  <div class="col-md-4 col-6 mb-3">
    <BaseSwitch
      class="mb-3 mb-sm-4"
      :label="$t(value.label)"
      :value="all"
      @input="setAll"
    />
    <div v-for="(item, column) of value.items" :key="column" class="mb-2 mb-sm-3">
      <label class="inline_checkbox_fff">
        <input type="checkbox" :checked="item.use" @input="handlerCheck(column, $event.target.checked)">
        <span class="icon_checkb_inl"></span>
        <span>{{ $t(item.label) }}</span>
      </label>
    </div>
  </div>
</template>
<script>
import BaseSwitch from '@/components/base/BaseSwitch.vue'
export default {
  name: 'Group',
  props: {
    value: Object
  },
  components: {BaseSwitch},
  computed: {
    all: {
      get () {
        return Object.values(this.value.items).every((value) => value.use)
      },
      set (value) {
        for (let key in this.value.items) {
          if (this.value.items.hasOwnProperty(key)) {
            this.value.items[key].use = value
          }
        }
      }
    }
  },
  methods: {
    handlerCheck (column, value) {
      const items = this.value.items
      items[column].use = value
      this.$emit('input', {...this.value, items})
    },
    setAll (value) {
      for (let key in this.value.items) {
        if (this.value.items.hasOwnProperty(key)) {
          this.value.items[key].use = value
        }
      }
    }
  }
}
</script>
