<template>
  <loading v-model="loading" class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12 profile-page">
        <div class="card">
          <div class="card-body">
            <breadcrumb v-model="model"/>
            <h4 class="card-title">{{ model.name }}</h4>
            <p class="card-description">Трекер {{ model.enable ? 'включен' : 'не активен' }}</p>
            <link-setting v-model="model"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="model.filters" class="row">
      <div class="col-md-6" v-for="filter of model.filters" :key="filter._id">
        <div class="card">
          <div class="card-body">
            <breadcrumb v-if="model" v-model="model"/>
            <h4 class="card-title">{{ filter._id }}</h4>
            <link-setting :value="filter"/>
          </div>
        </div>
      </div>
    </div>
  </loading>
</template>
<script>
import _ from 'lodash'
import * as components from './components'
import {mapActions, mapGetters} from 'vuex'
import Loading from '@/pages/admin/components/Loading.vue'

export default {
  name: 'AdminPWAsTracker',
  components: {
    ...components,
    Loading
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      model: 'adminPWAs/tracker'
    })
  },
  async created () {
    this.fetch = _.debounce(this.fetch, 500)
    await this.fetch()
  },
  methods: {
    ...mapActions({
      asyncData: 'adminPWAs/getPWATracker'
    }),
    async fetch () {
      this.loading = true
      await this.asyncData(this.$route.params.id)
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
