<template>
  <div class="item_toggsl_g">
    <div class="head_tit_sl_togg">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ $t('pwas.components.app.description.title') }}</span>
        <info :message="$t('pwas.components.app.description.titleInfo')"/>
      </div>
    </div>
    <div class="content_dropd_g">
      <div class="row row_mob_sg">
        <div class="form-group col-xl-4 col_half_mob" :class="apiValidationErrors.about ? 'has-danger' : ''">
          <label for="about">{{ $t('pwas.components.app.description.aboutM') }}
            <info :message="$t('pwas.components.app.description.aboutMInfo')"/>
          </label>
          <input type="text" id="about" :placeholder="$t('pwas.components.app.description.aboutM')"
                 v-model="value.languages[lang].about" class="form-control"
                 :class="apiValidationErrors.about ? 'form-control-danger' : ''"
                 @focus="clearError('about')" v-if="lang">
          <input type="text" id="about" :placeholder="$t('pwas.components.app.description.aboutM')"
                 v-model="value.about"
                 class="form-control"
                 :class="apiValidationErrors.about ? 'form-control-danger' : ''"
                 @focus="clearError('about')" v-else>
          <label v-if="apiValidationErrors.about" class="error mt-2 text-danger" for="about">
            {{ apiValidationErrors.about[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-4 col_half_mob"
             :class="apiValidationErrors.read_more ? 'has-danger' : ''">
          <label for="read_more">{{ $t('pwas.components.app.description.readMore') }}
            <info :message="$t('pwas.components.app.description.readMoreInfo')"/>
          </label>
          <input type="text" id="read_more" :placeholder="$t('pwas.components.app.description.readMore')"
                 v-model="value.languages[lang].read_more" class="form-control"
                 :class="apiValidationErrors.read_more ? 'form-control-danger' : ''"
                 @focus="clearError('read_more')" v-if="lang">
          <input type="text" id="read_more" :placeholder="$t('pwas.components.app.description.readMore')"
                 v-model="value.read_more"
                 class="form-control"
                 :class="apiValidationErrors.read_more ? 'form-control-danger' : ''"
                 @focus="clearError('read_more')" v-else>
          <label v-if="apiValidationErrors.read_more" class="error mt-2 text-danger" for="read_more">
            {{ apiValidationErrors.read_more[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-4 col_half_mob" :class="apiValidationErrors.hide ? 'has-danger' : ''">
          <label for="hide">{{ $t('pwas.components.app.description.hide') }}
            <info
              :message="$t('pwas.components.app.description.hideInfo')"/>
          </label>
          <input type="text" id="hide" :placeholder="$t('pwas.components.app.description.hide')"
                 v-model="value.languages[lang].hide" class="form-control"
                 :class="apiValidationErrors.hide ? 'form-control-danger' : ''"
                 @focus="clearError('hide')" v-if="lang">
          <input type="text" id="hide" :placeholder="$t('pwas.components.app.description.hide')"
                 v-model="value.hide" class="form-control"
                 :class="apiValidationErrors.hide ? 'form-control-danger' : ''"
                 @focus="clearError('hide')" v-else>
          <label v-if="apiValidationErrors.hide" class="error mt-2 text-danger" for="hide">
            {{ apiValidationErrors.hide[0] }}
          </label>
        </div>
      </div>
      <div class="form-group" :class="apiValidationErrors.text ? 'has-danger' : ''">
        <label for="text">{{ $t('pwas.components.app.description.description') }}
          <info :message="$t('pwas.components.app.description.descriptionInfo')"/>
        </label>
        <textarea id="text" rows="4" class="form-control"
                  v-model="value.languages[lang].text"
                  :placeholder="$t('pwas.components.app.description.descriptionPlaceholder')"
                  :class="apiValidationErrors.text ? 'form-control-danger' : ''"
                  @focus="clearError('text')" v-if="lang"></textarea>
        <textarea id="text" rows="4" class="form-control"
                  v-model="value.text"
                  :placeholder="$t('pwas.components.app.description.descriptionPlaceholder')"
                  :class="apiValidationErrors.text ? 'form-control-danger' : ''"
                  @focus="clearError('text')" v-else></textarea>
        <label v-if="apiValidationErrors.text" class="error mt-2 text-danger" for="text">
          {{ apiValidationErrors.text[0] }}
        </label>
      </div>
      <hr class="mt-1 mb-4">
      <div class="row row_mob_sg">
        <div class="form-group col-xl-6 col_half_mob"
             :class="apiValidationErrors.changes_recent ? 'has-danger' : ''">
          <label for="changes_recent">{{ $t('pwas.components.app.description.updateDesc') }}
            <info :message="$t('pwas.components.app.description.updateDescInfo')"/>
          </label>
          <textarea id="changes_recent" rows="4" class="form-control"
                    v-model="value.languages[lang].changes_recent"
                    :placeholder="$t('pwas.components.app.description.updateDesc')"
                    :class="apiValidationErrors.changes_recent ? 'form-control-danger' : ''"
                    @focus="clearError('changes_recent')" v-if="lang"></textarea>
          <textarea id="changes_recent" rows="4" class="form-control"
                    v-model="value.changes_recent"
                    :placeholder="$t('pwas.components.app.description.updateDesc')"
                    :class="apiValidationErrors.changes_recent ? 'form-control-danger' : ''"
                    @focus="clearError('changes_recent')" v-else></textarea>
          <label v-if="apiValidationErrors.changes_recent" class="error mt-2 text-danger"
                 for="changes_recent">
            {{ apiValidationErrors.changes_recent[0] }}
          </label>
        </div>
        <div class="form-group col-xl-6 col_half_mob"
             :class="apiValidationErrors.changes_label ? 'has-danger' : ''">
          <label for="changes_label">{{ $t('pwas.components.app.description.update') }}
            <info :message="$t('pwas.components.app.description.updateInfo')"/>
          </label>
          <input type="text" id="changes_label" :placeholder="$t('pwas.components.app.description.update')"
                 v-model="value.languages[lang].changes_label" class="form-control"
                 :class="apiValidationErrors.changes_label ? 'form-control-danger' : ''"
                 @focus="clearError('changes_label')" v-if="lang">
          <input type="text" id="changes_label" :placeholder="$t('pwas.components.app.description.update')"
                 v-model="value.changes_label"
                 class="form-control"
                 :class="apiValidationErrors.changes_label ? 'form-control-danger' : ''"
                 @focus="clearError('changes_label')" v-else>
          <label v-if="apiValidationErrors.changes_label" class="error mt-2 text-danger"
                 for="changes_label">
            {{ apiValidationErrors.changes_label[0] }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'

export default {
  name: 'DescriptionApp',
  mixins: [formMixin],
  components: {Info},
  props: {
    value: {
      type: Object,
      require: true
    },
    errors: {
      type: Object,
      require: true
    }
  },
  computed: {
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  }
}
</script>
