import api from '../api'

export default {
  index: payload => api.get('admin/vouchers?' + new URLSearchParams(payload).toString()),
  create: payload => api.post('admin/vouchers', payload),
  view: id => api.get(`admin/vouchers/${id}`),
  update: ({id, ...payload}) => api.patch(`admin/vouchers/${id}`, payload),
  delete: id => api.delete(`admin/vouchers/${id}`),
  list: payload => api.get('admin/vouchers/list?' + new URLSearchParams(payload).toString())
}
