import {i18n} from '@/plugins/i18n'

const state = {
  alerts: []
}

const mutations = {
  success: (state, payload) => {
    state.alerts.push({
      ...payload
    })
  },
  error: (state, payload) => {
    state.alerts.push({
      ...payload
    })
  },
  warning: (state, payload) => {
    state.alerts.push({
      ...payload
    })
  }
}

const actions = {
  success: ({commit}, message) => {
    commit('success', {
      message,
      type: 'success'
    })
  },
  error: ({commit}, message) => {
    commit('error', {
      message,
      type: 'error'
    })
  },
  warning: ({commit, dispatch}, message) => {
    commit('warning', {
      message,
      type: 'warning'
    })
  }
}

const getters = {}

const alerts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default alerts
