// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import {i18n} from '@/plugins/i18n'
import App from './App.vue'
import router from './routes'
import Paginate from 'vuejs-paginate'
import filters from '@/filters'
import socketIO from '@/plugins/socket.io'
import store from './store'
import * as Sentry from '@sentry/vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'

Vue.use(socketIO)
Vue.use(VueToast)

Vue.component('paginate', Paginate)
Vue.use(filters)
Vue.config.productionTip = false

const urlSearchParams = new URLSearchParams(window.location.search)
if (urlSearchParams.get('utm_uid')) {
  document.cookie = 'utm_uid=' + urlSearchParams.get('utm_uid')
}
Sentry.init({
  Vue,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],

  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', import.meta.env.VITE_FRONTEND],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: {App},
  template: '<App/>',
  store,
  i18n
})
