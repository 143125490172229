<template>
  <tr>
    <td>
      <button class="btn btn-icons btn-inverse-primary" @click="handlerEdit"><i class="icon-pencil"></i></button>
      <button class="btn btn-icons btn-inverse-danger" @click="handlerDelete"><i class="icon-trash"></i></button>
    </td>
    <td>
      <select class="form-control" v-model="enable">
        <option :key="item" v-for="item in [true, false]" :value="item">
          {{ item | formatBoolean }}
        </option>
      </select>
    </td>
    <td>{{ name }}</td>
    <td>
      <select class="form-control" v-model="method">
        <option :key="value" v-for="(label,value) in this.$store.getters['adminPaymentMethods/list']" :value="value">
          {{ label }}
        </option>
      </select>
    </td>
    <td>
      <select class="form-control" v-model="rate">
        <option :key="id" v-for="(rate,id) in this.$store.getters['adminConfig/billing'].rates" :value="id">
          {{ id.toUpperCase() }}
        </option>
      </select>
    </td>
    <td>{{ createdAt | dateFromNow }}</td>
    <td>{{ updatedAt | dateFromNow }}</td>
  </tr>
</template>
<script>
export default {
  name: 'GatewayField',
  props: ['item', 'merchantId'],
  data: function () {
    return {
      ...this.item,
      watch: false
    }
  },
  created: function () {
    setTimeout(() => {
      this.watch = true
    }, 500)
  },
  watch: {
    enable: {
      handler: async function (enable) {
        if (this.watch) {
          await this.$store.dispatch('adminGateways/update', {_id: this.merchantId, gateway_id: this.item._id, enable})
          await this.$store.dispatch('alerts/success', 'Видимость обновлена')
        }
      }
    },
    method: {
      handler: async function (method) {
        if (this.watch) {
          await this.$store.dispatch('adminGateways/update', {_id: this.merchantId, gateway_id: this.item._id, method})
          await this.$store.dispatch('adminGateways/list')
          await this.$store.dispatch('alerts/success', 'Метод оплаты обновлен обновлена')
        }
      }
    },
    rate: {
      handler: async function (rate) {
        if (this.watch) {
          await this.$store.dispatch('adminGateways/update', {_id: this.merchantId, gateway_id: this.item._id, rate})
          await this.$store.dispatch('alerts/success', 'Метод оплаты обновлен обновлена')
        }
      }
    }
  },
  methods: {
    handlerDelete: async function () {
      if (confirm('Удалить шлюз?')) {
        await this.$store.dispatch('adminGateways/delete', {_id: this.merchantId, gateway_id: this.item._id})
        await this.$parent.getData()
        await this.$store.dispatch('alerts/success', 'Шлюз удалён')
      }
    },
    handlerEdit: async function () {
      await this.$store.dispatch('adminPaymentMethods/list')
      this.$parent.$refs.form.enable = this.enable
      this.$parent.$refs.form.name = this.name
      this.$parent.$refs.form.rate = this.rate
      this.$parent.$refs.form.method = this.method
      this.$parent.$refs.form.data = this.data
      this.$parent.$refs.form.id = this.item._id
      this.$parent.$refs.form.initAceEditor()
      // eslint-disable-next-line no-undef
      jQuery('#gateway-modal').modal('show')
    }
  }
}
</script>
