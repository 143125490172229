import create from './create/pt'
/* eslint-disable no-template-curly-in-string */
export default {
  title: 'Espelhos',
  headers: {
    domain: 'Domínio Espelho',
    domainInfo: 'Nome de domínio de seu Espelho',
    expired: 'Remoção',
    expiredInfo: 'Falta para eliminação automática, permanente de Espelho'
  },
  trash: {
    title: 'Uma cesta de espelhos',
    move: 'Transferir para o cesto',
    empty: 'Cesto de está vazio',
    moveMessage: 'Tem certeza que quer transferir para o cesto o Espelho {name}? Terá 2 dias para recuperar',
    moveButton: 'Para o cesto',
    moveSuccess: 'Espelho foi transferido para o cesto com sucesso',
    restore: 'Recuperar o Espelho',
    restoreTitle: 'Recuperar o Espelho {name}?',
    restoreMessage: 'Tem certeza que quer recuperar o Espelho {name}?',
    restoreButton: 'Recuperar',
    restoreSuccess: 'Espelho foi recuperado com sucesso',
    goToMirrors: 'Ir ao Espelhos'
  },
  createNew: 'Criar o Espelho',
  pwaName: 'PWA Nome',
  pwaTitle: 'Nome do seu PWA',
  mirrorName: 'Nome',
  mirrorTitle: 'Nome do seu Espelho',
  status: 'Estatuto',
  statusTitle: 'Estatuto do seu Espelho',
  tags: 'Tags PWA',
  tagsTitle: 'Tags da finalidade de PWA',
  trackerHead: 'Tracker',
  trackerTitle: 'Informação sobre o tracker corrente ligado ao Espelho',
  add: 'Adicionar',
  filters: {
    search: 'Busca por domínio',
    tag: 'Filtro por tag',
    addTag: 'Adicionar uma nova tag',
    status: 'Filtro por estatuto',
    vertical: 'Filtro por âmbito',
    category: 'Filtro por categoria',
    pwa: 'Filtro por PWA'
  },
  buttons: {
    checkDomain: 'Verificar domínio',
    run: 'Ativar',
    stop: 'Parar',
    pay: 'Pagar',
    preview: 'Pré-visualização',
    cloak: {
      buttonTitle: 'Baixar JS script',
      title: 'JS para usar Cloak',
      body: `Adiciona esta código JS no final da secção de tag <body> antes de tag de encerramento </body>`
    },
    customJs: {
      buttonTitle: 'Inserir seu JS script',
      title: 'JS de utilizador',
      body: 'Este código JS será adicionado ao seu Espelho',
      bulkBody: 'Este código JS será adicionado aos seus Espelhos',
      success: 'JS de utilizador foi guardado'
    },
    meta: {
      buttonTitle: 'Inserir seus META tags',
      title: 'META tags de utilizador',
      body: 'Estes META tags serão adicionados ao seu Espelho',
      bulkBody: 'Estas etiquetas META serão adicionadas aos seus Espelhos',
      success: 'META tags de utilizador foram guardados'
    }
  },
  checkDomain: {
    title: 'Verificação de domínio',
    body: [
      'Seu domínio ainda não foi verificado. A verificação do domínio pode demorar até 2 horas',
      'Domínio <strong>{domain}</strong> não tem https protocolo. Para funcionamento correto deve ativar https Rewrite',
      'Domínio <strong>{domain}</strong> foi verificado',
      'Domínio <strong>{domain}</strong> não está coberto com <strong>CloudFlare</strong>'
    ]
  },
  delete: {
    title: 'Apagar o Espelho',
    message: 'Tem certeza que quer apagar Espelho {name}?',
    button: 'Apagar',
    success: 'Espelho foi removido'
  },
  copy: {
    title: 'Copiar Espelho',
    message: 'Tem certeza que quer copiar Espelho {name}?',
    button: 'Copiar',
    success: 'Espelho foi copiado',
    tracker: {
      message: 'Copiar {name} com tracker ou sem?',
      okButton: 'Com tracker',
      cancelButton: 'Sem tracker'
    }
  },
  paid: {
    title: 'Ativação do Espelho',
    warning: 'Depois da ativação do Espelho seria impossível alterar o domínio!',
    button: 'Ativar',
    success: 'Espelho foi ativado',
    cancel: 'Ativação foi cancelada',
    error: 'Erro de ativação',
    noTariff: 'Para trabalhar com Espelho é necessário ativar um plano',
    paidTariff: `Tem uma ativação no plano. Depois da ativação da Espelho não terá mais ativações. Ativar o Espelho?
| Tem uma {count} ativação no plano. Depois da ativação da Espelho ainda terá uma ativação. Ativar o Espelho?
| Tem {count} ativações no plano. Depois da ativação da Espelho ainda terá {countLeft} ativação. Ativar o Espelho?
| Tem {count} ativações no plano. Depois da ativação da Espelho terá {countLeft} ativações. Ativar o Espelho?`,
    paidBalance: 'Caducou a sua ativação do plano, pode ativar o Espelho além do plano por ${amount}.',
    noMoney: 'Caduco a ativação no plano, pode ativar o Espelho fora do plano por ${amount} ou adquirir outro plano. Para o tal é necessário carregar o saldo.',
    paidInstalls: 'Ativação no plano de instalações é grátis, mas cada instalação de Espelho é paga'
  },
  play: {
    title: 'Ativar o Espelho',
    message: 'Tem certeza que quer Ativar o Espelho {name}?',
    button: 'Ativar',
    success: 'Espelho está ativo'
  },
  stop: {
    title: 'Parar Espelho',
    message: 'Tem certeza que quer parar Espelho {name}?',
    button: 'Parar',
    success: 'Espelho foi parado'
  },
  notFoundByFilters: 'Não foram encontrados Espelho que atendem os termos de filtração de',
  noHave: 'Ainda não tem Espelho criados. Clicar em "Adicionar"',
  insufficientFunds: 'Falta de meios',
  save: 'Espelho foi guardado',
  verticals: ['Nutra', 'Betting', 'Dayting', 'Gambling', 'Trading', 'Criptomoedas'],
  statuses: ['Bloqueado', 'Verificação de domínio', 'Verificação de HTTPs', 'Não pago', 'Parado', 'A funcionar', 'Paragem técnica'],
  categories: [
    'Automóveis e transportes',
    'Bibliotecas e demoversões',
    'Negócios',
    'Leitores e editores de vídeo',
    'Arte e design',
    'Comida e bebidas',
    'Jogos',
    'Ferramentas',
    'Habitação e casas',
    'Saúde e fitness',
    'Encontros',
    'Mapas e navegação',
    'Livros',
    'Banda desenhada',
    'Beleza',
    'Maternidade e infância',
    'Medicina',
    'Eventos',
    'Música',
    'Notícias e revistas',
    'Educação',
    'Estilo de vida',
    'Comunicação',
    'Personalização',
    'Tempo',
    'Compras',
    'Viagens',
    'Emprego',
    'Entretenimento',
    'Social',
    'Desporto',
    'Estilo de vida',
    'Finanças',
    'Fotografia'
  ],
  create,
  group: {
    successRemove: 'Os Espelhos foram removidos com sucesso',
    successCopy: 'Os Espelhos foram copiados com sucesso',
    successTrash: 'Os Espelhos foram transferidos com sucesso para o lixo',
    successRestore: 'Os Espelhos foram restaurados com sucesso',
    successPlay: 'Os Espelhos estão a funcionar',
    successStop: 'Os Espelhos foram interrompidos',
    successMeta: 'Etiquetas META personalizadas guardadas',
    successCustomJs: 'O JS personalizado é guardado'
  }
}
