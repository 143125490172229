<template>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead>
      <tr>
        <th>Страна</th>
        <th>IP</th>
        <th>Устройство</th>
        <th>Операционка</th>
        <th>Браузер</th>
        <th>Дата логина</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in data" :key="item._id">
        <td><i class="flag-icon" :class="`flag-icon-${item.country}`" style="cursor: pointer"></i></td>
        <td>{{ item.ip }}</td>
        <td>{{ item.ua | formatDevice }}</td>
        <td>{{ item.ua |formatOS }}</td>
        <td>{{ item.ua | formatBrowser }}</td>
        <td>{{ item.createdAt | dateFromNow }}</td>
      </tr>
      </tbody>
    </table>
    <nav v-if="pageCount > 1">
      <paginate ref="paginate"
                :page-count="pageCount"
                :click-handler="pagination"
                :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                :next-text="'<i class=\'icon-arrow-right\'></i>'"
                :container-class="'pagination mt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item'"
                :next-class="'page-item'"
                :prev-link-class="'page-link'"
                :next-link-class="'page-link'"
                :active-class="'active'">
      </paginate>
    </nav>
  </div>
</template>
<script>

export default {
  name: 'Logs',
  data: () => {
    return {
      pageSize: 10,
      count: 0,
      data: [],
      pageIndex: 1,
      filters: {
        owner: ''
      }
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.pageSize)
    }
  },
  async created () {
    await this.getData()
  },
  methods: {
    async getData () {
      await this.$store.dispatch('adminLogs/index', {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        owner: this.$route.params.id
      })
      this.data = this.$store.getters['adminLogs/data']
      this.count = this.$store.getters['adminLogs/itemsCount']
    },
    async pagination (pageIndex) {
      this.pageIndex = pageIndex
      await this.getData()
    }
  }
}
</script>
