export default {
  navigation: 'Домен',
  title: 'Настройка домена PWA #{id}',
  settingTitle: 'Для работы с системой домен должен быть привязан к CloudFlare и иметь следующие настройки',
  settings: [
    'Указать у регистратора NS от Cloudflare',
    'Указать в настройках домена А-записи'
  ],
  change: 'Изменить',
  warningCloudflare: 'Для работы с системой домен должен быть привязан к <a href="https://www.cloudflare.com/" target="_blank" class="link_green">CloudFlare</a>. Если NS записи не отображаются или PWA работает не корректно попробуйте заново запустить процесс при помощи кнопки',
  getNS: 'Получить NS',
  noCloudflare: 'Домен не обернут в <a href="https://www.cloudflare.com/" target="_blank">CloudFlare</a>, без этой процедуры вы не сможете использовать приложения',
  checkInfo: 'После замены домена его нужно будет повторно проверить',
  ns: {
    success: 'Запрос на получения NS записей успешен',
    failed: 'Запрос на получения NS записей провален. Обратитесь в поддержку'
  }
}
