import service from '@/store/services/pwas-pushes-service'
import middleware from './middleware'

const state = () => ({
  count: 0,
  models: [],
  model: null,
  pwas: [],
  countries: [],
  statuses: [
    {id: 'suspended', label: 'pushes.statuses.suspended', class: 'info'},
    {id: 'postponed', label: 'pushes.statuses.postponed', class: 'info'},
    {id: 'sending', label: 'pushes.statuses.sending', class: 'warning'},
    {id: 'done', label: 'pushes.statuses.done', class: 'primary'}
  ],
  types: [
    {id: 'postponed', label: 'pushes.types.postponed', class: 'info'},
    {id: 'interval', label: 'pushes.types.interval', class: 'info'}
  ],
  lastPath: '',
  segmentCount: 0,
  registeredCount: 0,
  depositedCount: 0,
  showedCount: 0,
  cappedCount: 0,
  clickedCount: 0,
  statistic: {},
  pwasOptions: [],
  tagsOptions: [],
  countriesOptions: [],
  filterModels: []
})

const mutations = {
  setData (state, { models, count }) {
    state.models = models
    state.count = count
  },
  setModel (state, { company, pwasOptions, countriesOptions, tagsOptions }) {
    state.model = company
    state.pwasOptions = pwasOptions
    state.countriesOptions = countriesOptions
    state.tagsOptions = tagsOptions
  },
  setLastPath: (state, payload) => {
    state.lastPath = payload
  },
  setSegment: (state, {
    count,
    registeredCount,
    depositedCount,
    showedCount,
    cappedCount,
    clickedCount
  }) => {
    state.segmentCount = count
    state.registeredCount = registeredCount
    state.depositedCount = depositedCount
    state.showedCount = showedCount
    state.cappedCount = cappedCount
    state.clickedCount = clickedCount
  },
  setStatistic: (state, statistic) => {
    state.statistic = statistic
  },
  setFilterModels: (state, payload) => {
    state.filterModels = payload.models
  },
  socket_PWAPushCompanyUpdate (state, {company, ...payload}) {
    const index = state.models.findIndex(input => input.id === company)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        if (prop === 'status') {
          const statusIndex = state.statuses.findIndex(input => input.id === value)
          state.models[index][prop] = state.statuses[statusIndex]
        } else {
          state.models[index][prop] = value
        }
      }
    }
  },
  socket_PWAPushCompanyInc (state, {company, ...payload}) {
    const index = state.models.findIndex(input => input.id === company)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        if (prop === 'status') {
          const statusIndex = state.statuses.findIndex(input => input.id === value)
          state.models[index][prop] = state.statuses[statusIndex]
        } else {
          state.models[index][prop] += value
        }
      }
    }
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create ({commit, dispatch}, params) {
    return service.create(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  read ({commit, dispatch}, params) {
    return service.read(params)
      .then(res => {
        commit('setModel', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  delete ({commit}, params) {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  copy ({commit, dispatch}, params) {
    return service.copy(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  upload: ({commit, dispatch}, params) => {
    return service.upload(params)
      .then(res => {
        commit('setLastPath', res)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  removeImage: ({commit, dispatch}, params) => {
    return service.removeImage(params)
      .then(() => {
        commit('setLastPath', '')
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  segment: ({commit, dispatch}, params) => {
    return service.segment(params)
      .then((res) => {
        commit('setSegment', res)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  filter: ({commit, dispatch}, params) => {
    return service.filter(params)
      .then((res) => {
        commit('setFilterModels', res)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  statistic: ({ commit, dispatch }, params) => {
    return service.statistic(params)
      .then((res) => {
        commit('setStatistic', res)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  async groupCopy (cntx, ids) {
    try {
      await service.groupCopy(ids)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async groupRemove (cntx, ids) {
    try {
      await service.groupRemove(ids)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  models: state => {
    const models = []
    for (const model of state.models) {
      const index = state.statuses.findIndex(value => value.id === model.status)
      models.push({
        ...model,
        status: state.statuses[index]
      })
    }
    return models
  },
  count: state => state.count,
  model: state => state.model,
  pwas: state => state.pwas,
  countries: state => state.countries,
  statuses: state => state.statuses,
  types: state => state.types,
  lastPath: state => state.lastPath,
  segmentCount: state => state.segmentCount,
  registeredCount: state => state.registeredCount,
  depositedCount: state => state.depositedCount,
  showedCount: state => state.showedCount,
  cappedCount: state => state.cappedCount,
  clickedCount: state => state.clickedCount,
  statistic: state => state.statistic,
  pwasOptions: state => state.pwasOptions,
  tagsOptions: state => state.tagsOptions,
  countriesOptions: state => state.countriesOptions,
  filterModels: state => state.filterModels
}

const adminDomains = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminDomains
