<template>
  <div class="card">
    <div class="card-body">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-dark">
          <li class="breadcrumb-item">
            <router-link :to="{name: 'Admin'}">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{name: 'AdminBillingMerchants'}">Кассы</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page"><span>{{ merchant.name }}</span></li>
        </ol>
      </nav>
      <h4 class="card-title">Шлюзы</h4>
      <div class="mb-2">
        <button type="button" class="btn btn-outline-success btn-fw" @click="handlerCreate"><i
          class="icon-plus"></i> Добавить
        </button>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-hover">
          <thead>
          <tr>
            <th></th>
            <th>
              Вкл./Выкл.
            </th>
            <th>
              Названия
            </th>
            <th>
              Метод оплаты
            </th>
            <th style="min-width: 100px">
              Валюта
            </th>
            <th>Создан</th>
            <th>Обновлен</th>
          </tr>
          </thead>
          <tbody>
          <gateway-field v-bind:item="item" v-for="item in gateways" v-bind:key="item._id"
                         v-bind:merchantId="merchantId"/>
          </tbody>
        </table>
      </div>
      <gateway-form ref="form" v-bind:merchantId="merchantId"/>
    </div>
  </div>
</template>
<script>
import GatewayField from './GatewayField.vue'
import GatewayForm from './GatewayForm.vue'

export default {
  name: 'Gateways',
  components: {GatewayField, GatewayForm},
  props: ['merchantId'],
  data: function () {
    return {
      merchant: {},
      gateways: []
    }
  },
  created: async function () {
    await this.$store.dispatch('adminPaymentMethods/list')
    await this.getData()
  },
  methods: {
    handlerCreate: async function () {
      await this.$store.dispatch('adminPaymentMethods/list')
      this.$refs.form.enable = false
      this.$refs.form.name = ''
      this.$refs.form.method = ''
      this.$refs.form.rate = ''
      this.$refs.form.data = ''
      this.$refs.form.id = false
      this.$refs.form.initAceEditor()
      // eslint-disable-next-line no-undef
      jQuery('#gateway-modal').modal('show')
    },
    getData: async function () {
      this.gateways = []
      this.merchant = {}
      await this.$store.dispatch('adminGateways/index', {_id: this.merchantId})
      this.gateways = this.$store.getters['adminGateways/gateways']
      this.merchant = this.$store.getters['adminGateways/merchant']
    }
  }
}
</script>
