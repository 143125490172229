import analytics from './analytics/ru'
import app from './app/ru'
import comment from './comment/ru'
import domain from './domain/ru'
import setting from './setting/ru'
import tracker from './tracker/ru'
import vertical from './vertical/ru'

export default {
  translate: {
    title: 'Перевод PWA<span class="url_ti_soig">{id}</span>',
    commentTitle: 'Перевод комментариев для PWA<span class="url_ti_soig">{id}</span>',
    from: 'Язык который переводим',
    to: 'Язык на который переводим',
    translate: 'Перевести',
    backToRu: 'Вернуть RU',
    delete: 'Удалить перевод',
    default: 'По умолчанию',
    newApp: 'Создания новой локализации<span class="url_ti_soig">{id}</span>',
    newComments: 'Создание перевода комментариев для «{title}»<span class="url_ti_soig">Это действия создаст точную копию из комментариев <strong>«по умолчанию»</strong> и переведет их на <strong>{langTo} язык. Важно помнить</strong> что все комментарии на разных языках <strong>не зависимы между собой</strong>! Изменения комментариев «по умолчанию» не будут перенесены на переведённые рании копии</span>',
    process: '<small>Идет копирования и перевод комментариев, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    success: 'Копирование и перевод успешно завершено',
    returnSuccess: 'Надписи успешно скинуты'
  },
  google: {
    delete: 'Удалить аналитику?',
    settings: {
      title: 'Настройки Google Conversion',
      code: 'Ярлык конверсии',
      value: 'Ценность',
      currency: 'Валюта',
      currencyInfo: 'Валюта ценности, 3 больших буквы(например USD)'
    },
    events: ['Установка', 'Открытие', 'PUSH подписка', 'Регистрация', 'Депозит']
  },
  facebook: {
    title: 'Настройка Facebook Pixel',
    label: 'Нужен для отправки s2s событий в Facebook',
    pixelInfo: 'Вставьте ваш Facebook пиксель. Мы автоматически отслеживаем действие "Лид". Лидом является открытие Pwa',
    lead: 'Что считать Lead?',
    leadInfo: 'Выбор события Lead',
    dontUse: 'Не использовать',
    events: ['Установка', 'Открытие', 'PUSH подписка', 'Регистрация', 'Депозит'],
    delete: 'Удалить пиксель?',
    deleteMessage: 'Вы уверены что хотите удалить пиксель {id}?',
    deleteButton: 'Удалить',
    noPixel: 'Сначала введите пиксель',
    multiForm: {
      title: 'Групповое добавление FB pixel',
      message: 'Добавьте пиксели строго в формате <strong>pixel:lead</strong>, где <strong>pixel</strong> - это ваши FB pixel\'ли, а <strong>lead</strong> - события лида которая может принимать значение <strong>install</strong> или <strong>registration</strong>',
      label: 'Пиксели по строкам',
      errors: [
        'Введите пиксель и событие',
        'На строке {errorLine} ошибка! Не указан пиксель',
        'На строке {errorLine} ошибка! Не указано событие для лида',
        'На строке {errorLine} ошибка! Неправильно указаны события для лида'
      ]
    }
  },
  tt: {
    dontUse: 'Не использовать',
    events: ['Установка', 'Открытие', 'PUSH подписка', 'Регистрация s2s', 'Депозит s2s'],
    title: 'Настройка TikTok Pixel',
    label: 'Нужен для отправки s2s событий в TikTok',
    delete: 'Удалить пиксель?',
    noPixel: 'Сначала введите пиксель'
  },
  generator: {
    title: 'Генерация контента',
    langFrom: 'Основной язык',
    mode: {
      title: 'Режимы генерации',
      random: 'Рандомные текста',
      googlePlay: 'Google play',
      template: 'Из шаблона'
    },
    texts: 'Текст',
    textsInfo: 'Генерировать текст',
    comments: 'Комментарии',
    commentsInfo: 'Генерировать комментарии',
    media: 'Картинки',
    mediaInfo: 'Генерировать картинки',
    button: 'Сгенерировать',
    confirm: 'Это действия заменить все текста и комментарии, вы уверены что хотите продолжить?',
    failed: 'Генерация провалилась повторите попытку',
    success: 'Текста для PWA успешно сгенерированы',
    pwaGenerateStart: '<small>Идет подготовка к генерации, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    pwaGenerateBeginDownload: '<small>Скачивания страницы Google Play <strong>({lang})</strong>, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    pwaGenerateFinishDownload: '<small>Обработка страницы Google Play завершена <strong>({lang})</strong>, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    pwaGenerateBeginText: '<small>Генерации текста <strong>({lang})</strong>, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    pwaGenerateFinishText: '<small>Генерация текста завершена <strong>({lang})</strong>, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    pwaGenerateBeginComment: '<small>Генерации комментариев <strong>({lang})</strong>, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    pwaGenerateFinishComment: '<small>Генерация комментариев завершена <strong>({lang})</strong>, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    pwaGenerateBeginTemplate: '<small>Обработка шаблона <strong>({lang})</strong>, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    pwaGenerateFinishTemplate: '<small>Обработка шаблона завершена <strong>({lang})</strong>, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>'
  },
  analytics,
  app,
  comment,
  domain,
  setting,
  tracker,
  vertical
}
