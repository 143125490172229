export default {
  online: {
    title: 'Уніки онлайн',
    info: 'Унікальні користувачі, які знаходяться у додатку на даний момент',
    table: ['Дата', 'Країна', 'Пристрій', 'ОС', 'Встановлення', 'Відкриття', 'PUSH'],
    deviceUnrecognized: 'Нерозпізнано'
  },
  activity: {
    title: 'Активність',
    info: 'Співвідношення дій користувачів (Встановлення PWA, Відкриття PWA, оформлення підписки на Push-повідомлення)',
    uniques: 'Уніків немає | Унік | Уніка | Уніків',
    uniquesInfo: 'Кількість уніків за останні 3 місяці',
    installations: 'Встанов.',
    installationsFull: 'Встановлення',
    opens: 'Відкриття'
  },
  uniquesAndInstalls: {
    title: 'Уніки та встановлення',
    info: 'Кількість унікальних користувачів та установок PWA за останні 14 робочих днів',
    uniques: 'Уніків немає | Унік | Уніка | Уніків',
    uniquesInfo: 'Кількість уніків за останні 14 робочих днів',
    unique: 'Уніки',
    installations: 'Встановлення'
  },
  transactions: {
    title: 'Фінанси',
    info: 'Коротка статистика Ваших доходів та витрат'
  },
  topOnline: {
    title: 'Топ онлайн',
    info: 'Топ Pwa за кількістю трафіку на даний момент'
  }
}
