<template>
  <form @submit.prevent="handlerSubmit" class="forms-sample">
    <p class="card-description">
      Без використання додаткових дій, данні будуть змінені як є
    </p>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Стан підписки</label>
      <div class="col-sm-4">
        <div class="form-check">
          <label class="form-check-label" for="expired-false">
            <input
              v-model="expired"
              :value="false"
              id="expired-false"
              type="radio" class="form-check-input">
            Дійсна
            <i class="input-helper"></i>
          </label>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="form-check">
          <label class="form-check-label" for="expired-true">
            <input
              v-model="expired"
              :value="true"
              id="expired-true"
              type="radio"
              class="form-check-input">
            Закінчилась
            <i class="input-helper"></i>
          </label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Тариф</label>
      <div class="col-sm-9">
        <select
          v-model="tariff"
          class="form-control">
          <option v-for="model of tariffs" :key="model.id" :value="model.id">{{ model.name }}</option>
        </select>
        <small class="form-text text-muted">Список всіх тарифів</small>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Термін дії</label>
      <div class="col-sm-9">
        <datepicker
          container=".main-panel"
          v-model="expires"
          id="expires"
          required
          placeholder="День припинення дії тарифу"
          inputClass="form-control"
        />
        <small class="form-text text-muted">Дата закінчення терміну діїї підписки</small>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Активації</label>
      <div class="col-sm-9">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Шт.</span>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">0</span>
          </div>
          <input type="number" step="1" id="activations" placeholder="5" v-model="activations"
                 class="form-control" min="0" max="10000">
        </div>
        <small class="form-text text-muted">Кл. активаций и/или смен домена за период</small>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Ліміти</label>
      <div class="col-sm-9">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Шт.</span>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">0</span>
          </div>
          <input type="number" step="1" id="limits" placeholder="5" v-model="limits" class="form-control"
                 min="0"
                 max="10000">
        </div>
        <small class="form-text text-muted">Кл. одновременно работающих PWA</small>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Ваучер</label>
      <div class="col-sm-9">
        <select
          v-model="voucher"
          class="form-control">
          <option value=""></option>
          <option v-for="model of vouchers" :key="model.id" :value="model.id">{{ model.id }}
            ({{ model.amount ? `Знижка $${model.amount}` : `Знижка ${model.percent}%` }})
          </option>
        </select>
        <small class="form-text text-muted">Список ваучерів</small>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Уплочено</label>
      <div class="col-sm-9">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">0.00</span>
          </div>
          <input type="number" step="0.01" id="cost" placeholder="69" v-model="total"
                 class="form-control" min="1" max="10000">
        </div>
        <small class="form-text text-muted">Стоимость за весь период</small>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Додаткові дії</label>
      <div class="col-sm-3">
        <div class="form-check">
          <label class="form-check-label" for="withStopPwas">
            <input
              v-model="withStopPwas"
              id="withStopPwas"
              type="checkbox"
              class="form-check-input"
              :disabled="!expired">
            Зупинка PWA
            <i class="input-helper"></i>
          </label>
        </div>
        <small class="form-text text-muted">Зупинить всі запущені PWA користувача</small>
      </div>
      <div class="col-sm-3">
        <div class="form-check">
          <label class="form-check-label" for="withRunPwas">
            <input
              v-model="withRunPwas"
              id="withRunPwas"
              type="checkbox"
              class="form-check-input"
              :disabled="withStopPwas || expired">
            Старт PWA
            <i class="input-helper"></i>
          </label>
        </div>
        <small class="form-text text-muted">Запустить всі зупинені PWA користувача</small>
      </div>
      <div class="col-sm-3">
        <div class="form-check">
          <label class="form-check-label" for="withUsedAndPwas">
            <input
              v-model="withUsedAndPwas"
              id="withUsedAndPwas"
              type="checkbox"
              class="form-check-input"
              :disabled="withStopPwas || expired">
            Перерахунок
            <i class="input-helper"></i>
          </label>
        </div>
        <small class="form-text text-muted">Перерахує запущені PWA в використані</small>
      </div>
    </div>
    <button type="submit" class="btn btn-inverse-primary mr-2">Зберегти</button>
    <button
      @click="$emit('cancel', true)"
      type="button" class="btn btn-inverse-light">Відміна
    </button>
  </form>
</template>
<script>
import Datepicker from '@/components/Datepicker.vue'

export default {
  name: 'AdminUserTariffsForm',
  components: {Datepicker},
  props: ['tariffs', 'data', 'vouchers'],
  data () {
    return {
      dataLoading: true,
      expired: false,
      expires: new Date(),
      tariff: '',
      voucher: '',
      activations: 0,
      limits: 0,
      total: 0,
      withStopPwas: false,
      withRunPwas: false,
      withUsedAndPwas: false
    }
  },
  created () {
    if (this.data != null) {
      this.expired = this.data.expired
      this.expires = new Date(this.data.expires)
      this.tariff = this.data.tariff.id
      this.activations = +this.data.activations
      this.limits = +this.data.limits
      this.total = +this.data.total
    }
    if (this.tariff === '' && this.tariffs.length > 0) {
      this.tariff = this.tariffs[0].id
    }
  },
  watch: {
    tariff (n) {
      const tariff = this.tariffs.find(v => v.id === n)
      if (tariff) {
        this.limits = tariff.limit
        this.total = tariff.cost
      }
    },
    withStopPwas (n) {
      if (n) {
        this.withRunPwas = false
        this.withUsedAndPwas = false
      }
    },
    expired (n) {
      if (n) {
        this.withRunPwas = false
        this.withUsedAndPwas = false
      } else {
        this.withStopPwas = false
      }
    }
  },
  methods: {
    handlerSubmit () {
      this.$emit('submit', {
        expired: this.expired,
        expires: this.expires,
        tariff: this.tariff,
        activations: this.activations,
        limits: this.limits,
        total: this.total,
        withStopPwas: this.withStopPwas,
        withRunPwas: this.withRunPwas,
        withUsedAndPwas: this.withUsedAndPwas
      })
    }
  }
}
</script>
