export default {
  openTicket: 'Відкрити тікет',
  fillForm: 'Заповніть форму',
  theme: 'Тема',
  themeInfo: 'Введіть тему запиту',
  department: 'Відділ',
  departmentInfo: 'Виберіть одне з напрямків проблеми',
  departmentList: ['PWA', 'Біллінг', 'Статистика', 'Трекер', 'Реферальна програма', 'Помилка'],
  message: 'Повідомлення',
  messageInfo: 'Введіть Ваше повідомлення, яке розкриває суть проблеми',
  manager: 'Менеджер',
  status: 'Статус',
  ticketOpened: 'Тікет відкритий, очікуйте відповіді',
  waiting: 'Очікуємо відповіді',
  ticketCorrespondence: 'Листування по тікету #{ticketID}',
  success: 'Повідомлення надіслано, чекайте відповіді',
  statusList: ['Новий', 'Призначено менеджера', 'Дали відповідь', 'Нове питання', 'Закритий', 'Не вирішений', 'Відхилений']
}
