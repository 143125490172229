<template>
  <button v-on:click="handler" :class="classs"><i :class="arrowClass"></i></button>
</template>
<script>
export default {
  name: 'ArrowButton',
  props: {
    classs: {
      type: String,
      default: 'btn btn-icons btn-rounded btn-outline-success col-1'
    }
  },
  data: () => ({arrowClass: ''}),
  created: function () {
    this.arrowClass = this.$attrs.value ? 'icon-arrow-up' : 'icon-arrow-down'
  },
  methods: {
    handler: function () {
      if (this.$attrs.value) {
        this.$emit('input', false)
        this.arrowClass = 'icon-arrow-down'
      } else {
        this.$emit('input', true)
        this.arrowClass = 'icon-arrow-up'
      }
      this.$parent.$parent.$parent.$forceUpdate()
    }
  }
}
</script>
