<template>
  <div class="table-responsive">
    <table class="table table-bordered table-hover">
      <thead>
      <tr>
        <th>Реферал</th>
        <th>$ за месяц</th>
        <th>$ всего</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in data" :key="item._id">
        <td class="py-1 pl-0">
          <div class="d-flex align-items-center">
            <img :src="item.photo_url || '/images/no-avatar.jpg'" alt="profile">
            <div class="ml-2">
              <p>{{ item.username }}</p>
            </div>
          </div>
        </td>
        <td>
          ${{ item.month ? item.month.toFixed(2) : 0 }}
        </td>
        <td class="text-right">
          ${{ item.all ? item.all.toFixed(2) : 0 }}
        </td>
      </tr>
      </tbody>
    </table>
    <nav v-if="pageCount > 1">
      <paginate ref="paginate"
                :page-count="pageCount"
                :click-handler="pagination"
                :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                :next-text="'<i class=\'icon-arrow-right\'></i>'"
                :container-class="'pagination mt-3'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item'"
                :next-class="'page-item'"
                :prev-link-class="'page-link'"
                :next-link-class="'page-link'"
                :active-class="'active'">
      </paginate>
    </nav>
  </div>
</template>
<script>

export default {
  name: 'Referrals',
  data: () => {
    return {
      pageSize: 10,
      count: 0,
      data: [],
      pageIndex: 1
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.pageSize)
    }
  },
  async created () {
    await this.getData()
  },
  methods: {
    async getData () {
      await this.$store.dispatch('adminReferrals/index', {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        referral: this.$route.params.id
      })
      this.data = this.$store.getters['adminReferrals/data']
      this.count = this.$store.getters['adminReferrals/itemsCount']
    },
    async pagination (pageIndex) {
      this.pageIndex = pageIndex
      await this.getData()
    }
  }
}
</script>
