<template>
  <div
    :class="['BaseStatus base-status', `is--${type}`]"
  >
    <span>{{ data }}</span>
  </div>
</template>
<script>
export default {
  name: 'BaseStatus',
  props: {
    data: String,
    type: {
      validator: value => ['success', 'danger', 'warning', 'info'].includes(value),
      default: 'success'
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.base-status
  line-height: 18px
  font-size: 14px
  +font(400)
  &.is--success
    color: $green
  &.is--danger
    color: $red
  &.is--warning
    color: $yellow
  &.is--info
    color: $grey
</style>
