export default {
  subscribe: 'Подписка на тариф "{name}"',
  contains: 'Тариф "{name}" содержит в себе:',
  pwas: '0 активаций | Одна активация | {num} активации | {num} активаций',
  limit: '{num} активных PWA',
  month: 'Месячная подписка',
  onDays: 'Подписка на {num} дней',
  forStart: 'Для Старта',
  pay: 'Оплатить',
  messages: [
    'Под активацией имеется ввиду перевод валидного PWA из статуса <span class="color_red_go">"Не оплачено"</span> в <span class="color_green_go">"Работает"</span>',
    'Под количество активных PWA имеется ввиду то количество PWA которые имеют статус "Работает"'
  ],
  promoCode: 'Промокод',
  amount: 'Сумма к оплате',
  success: 'Вы подписались на новый тариф',
  voucherRequired: 'Введите промокод',
  beforeDate: 'Информация о тарифе до {date}',
  contained: 'Тариф "{name}" содержал в себе:',
  paid: 'Вы заплатили:',
  info: 'Информация',
  your: 'Ваш тариф',
  active: '{used} из {pwa} PWA запущено',
  activations: 'Активаций доступно: {num}',
  expired: '0 | Один день до конца подписки | {num} дня до конца подписки | {num} дней до конца подписки',
  upCost: 'Перейти за {num} $',
  auto: 'Автоподписка активна',
  noTariffs: 'Данный Тарифный ряд в разработке',
  individual: 'Индивидуальный',
  command: 'Командный',
  commercial: 'Коммерческий',
  history: 'История',
  historyTitle: 'История тарифов',
  additionalServices: 'Дополнительные услуги',
  installations: {
    tier1: 'Регион 1',
    tier2: 'Регион 2',
    tier3: 'Регион 3',
    title: 'Тарификация за установку',
    header: 'Тарификация за один инсталл PWA',
    text: 'Если у вас возникли вопросы или недоразумения относительно тарификации обратитесь в <a href="https://t.me/{tgHelpdesk}" target="_blank">службу поддержки</a> клиентов'
  }
}
