<template>
  <div class="content-wrapper">
    <tariff-form ref="tariffForm"/>
    <settings-form ref="settingsForm"/>
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Тарифы</h4>
            <p class="card-description">Управления тарифами</p>
            <div class="mb-2">
              <button @click="handlerCreate" type="button" class="btn btn-outline-success btn-fw">
                <i class="icon-plus"></i> Добавить
              </button>
              <button
                @click="$refs.settingsForm.handlerOpen()"
                type="button" class="btn btn-outline-secondary btn-fw">
                <i class="icon-settings"></i> Налаштування
              </button>
            </div>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th style="min-width: 170px;">Тип</th>
                  <th>Вкл./Выкл.</th>
                  <th>Названия</th>
                  <th>Описания</th>
                  <th>Стоимость</th>
                  <th>$ в день</th>
                  <th>Кл. PWA</th>
                  <th>Лимиты</th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                </tr>
                </thead>
                <tbody id="dragula">
                <tr v-for="model in models" :key="model.id" :data-id="model.id">
                  <td><i class="icon-options-vertical"></i></td>
                  <td>
                    <button @click="handlerUpdate(model)" class="btn btn-icons btn-inverse-primary">
                      <i class="icon-pencil"></i>
                    </button>
                    <button @click="handlerDelete(model)" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                  </td>
                  <td>
                    <select class="form-control" v-model="model.type" @input="handlerOneUpdate($event, model, 'type')">
                      <option :key="item" v-for="item in types" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select class="form-control" v-model="model.enable" @input="handlerOneUpdate($event, model, 'enable')">
                      <option :key="item" v-for="item in bools" :value="item">
                        {{ item | formatBoolean }}
                      </option>
                    </select>
                  </td>
                  <td>{{ model.name.ru | truncate(20) }}</td>
                  <td>{{ model.description.ru | truncate(30) }}</td>
                  <td>{{ model.cost | amount }}</td>
                  <td>{{ model.perDay | amount }}</td>
                  <td>{{ model.pwas }}</td>
                  <td>{{ model.limit }}</td>
                  <td>{{ model.createdAt | dateFromNow }}</td>
                  <td>{{ model.updatedAt | dateFromNow }}</td>
                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import TariffForm from './TariffForm.vue'
import SettingsForm from '@/components/admin/tariffs/SettingsForm.vue'

export default {
  name: 'Tariffs',
  components: {TariffForm, SettingsForm},
  data () {
    return {
      loading: true,
      models: [],
      bools: [true, false],
      types: ['Индивидуальный', 'Командный', 'Коммерческий']
    }
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  methods: {
    dragula () {
      const self = this
      // eslint-disable-next-line no-undef
      dragula([document.getElementById('dragula')])
        .on('drop', async function () {
          const rows = document.querySelectorAll('tr[data-id]')
          let ids = []
          for (const row of rows) {
            if (row.className !== 'gu-mirror') {
              ids.push(row.getAttribute('data-id'))
            }
          }
          await self.$store.dispatch('adminTariff/sort', {ids})
        })
    },
    async handlerCreate () {
      if (await this.$refs.tariffForm.handlerCreate()) {
        await this.asyncData()
      }
    },
    async handlerUpdate (model) {
      if (await this.$refs.tariffForm.handlerUpdate(model)) {
        await this.asyncData()
      }
    },
    async handlerDelete (model) {
      if (confirm('Удалить тариф?')) {
        await this.$store.dispatch('adminTariff/delete', model.id)
        await this.$store.dispatch('alerts/success', 'Тариф удалён')
        await this.asyncData()
      }
    },
    async asyncData () {
      this.loading = true
      this.tariffs = []
      await this.$store.dispatch('adminTariff/asyncData')
      this.models = this.$store.getters['adminTariff/models']
      this.loading = false
      this.$nextTick(() => {
        this.dragula()
      })
    },
    async handlerOneUpdate ($event, model, prop) {
      const payload = {id: model.id}
      payload[prop] = $event.target.value
      await this.$store.dispatch('adminTariff/oneUpdate', payload)
      const alerts = {
        enable: 'Видимость обновлена',
        type: `Тариф отнесен к типу ${model.type}`
      }
      await this.$store.dispatch('alerts/success', alerts[prop])
    }
  }
}
</script>
