<template>
  <div class="buttons-group">
    <button
      v-for="action in actions"
      :key="action.name"
      @click="action.action"
      :class="activeButton == action.name ? 'ful_butt_green' : 'border_butt_green'"
    >
      {{ action.name }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    activeButton: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-group {
  display: flex;

  .border_butt_green,.ful_butt_green {
    border-radius: 0;
    border-right: 0;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-right: 1px solid #21BF73;
    }

    &:hover {
      border-color: #21BF73;
    }
  }
}
</style>
