export default {
  openTicket: 'Abrir um ticket',
  fillForm: 'Preencher o formulário',
  theme: 'Tema',
  themeInfo: 'Introduza o tema',
  department: 'Divisão',
  departmentInfo: 'Escolher um dos âmbitos do problema',
  departmentList: ['PWA', 'Bolling', 'Estatística', 'Tracker', 'Programa de afiliados', 'Erro'],
  message: 'Mensagem',
  messageInfo: 'Introduza a sua mensagem, indicando o fundo do problema',
  manager: 'Manager',
  status: 'Estatuto',
  ticketOpened: 'Ticket foi aberto, aguarda uma resposta',
  waiting: 'A aguardar uma resposta',
  ticketCorrespondence: 'Comunicação de ticket #{ticketID}',
  success: 'Mensagem foi enviada, aguarda uma resposta',
  statusList: ['Novo', 'Manager foi nomeado', 'Resposta feita', 'Pergunta nova', 'Encerrado', 'Não resolvido', 'Recusado']
}
