<template>
  <div class="item_silter_sjhf_g this_is_selector_count">
    <select class="form-control w-100" id="countries-filter" v-model="value">
      <option value=""></option>
    </select>
  </div>
</template>
<script>
import * as dictionary from '@pwa.group/pwa.dictionary'
import {getCurrentLang} from '@/utils/i18n'

export default {
  name: 'Countries',
  props: {
    value: {
      type: String
    },
    ajaxUrl: {
      type: String,
      default: '/statistics/groups/countries'
    }
  },
  watch: {
    value: function () {
      this.init()
    }
  },
  mounted: function () {
    this.init()
  },
  updated: function () {
    this.init()
  },
  methods: {
    init: function () {
      const self = this
      const format = state => {
        if (state.id === '' || state.id === undefined) {
          return state.text
        } else {
          const index = dictionary.countries.findIndex(country => country.id === state.id)
          return `<i class="flag-icon flag-icon-${state.id.toLowerCase()}"></i> ${this.$t(dictionary.countries[index].text)}`
        }
      }
      // eslint-disable-next-line no-undef
      jQuery('#countries-filter').select2({
        placeholder: 'Фильтр по странам',
        templateResult: format,
        templateSelection: format,
        escapeMarkup: function (m) {
          return m
        },
        allowClear: true,
        ajax: {
          dataType: 'json',
          url: import.meta.env.VITE_APP_API_BASE_URL + this.ajaxUrl,
          delay: 500,
          transport: function (params, success, failure) {
            // eslint-disable-next-line no-undef
            const $request = $.ajax({
              ...params,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('vue-authenticate.vueauth_accessToken'),
                'Accept-Language': getCurrentLang()
              }
            })
            $request.then(success)
            $request.fail(failure)
            return $request
          },
          data: params => {
            const filter = {}
            for (const [key, value] of Object.entries(self.$parent.filter)) {
              filter[`filter[${key}]`] = value
            }
            return {
              page: params.page || 1,
              limit: 5,
              sort: 'text',
              ...filter,
              text: params.term
            }
          }
        }
      })
        .on('change', function (event) {
          self.$emit('input', event.target.value)
        })
    }
  }
}
</script>
