import api from '../api'

export default {
  list: ({page, limit, sort}) => {
    const searchParams = new URLSearchParams()
    searchParams.set('page', page)
    searchParams.set('limit', limit)
    searchParams.set('sort', sort)
    return api.get('admin/pwas/generator/comments?' + decodeURI(searchParams.toString()))
  },
  create: payload => api.post('admin/pwas/generator/comments', payload),
  update: ({id, ...payload}) => api.put(`admin/pwas/generator/comments/${id}`, payload),
  delete: id => api.delete(`admin/pwas/generator/comments/${id}`)
}
