import service from '@/store/services/admin/referrals-service'
import middleware from '../middleware'

const state = {
  data: [],
  itemsCount: 0
}

const mutations = {
  setIndex: (state, payload) => {
    state.data = payload.data
    state.itemsCount = payload.itemsCount
  }
}

const actions = {
  index: ({commit, dispatch}, params) => {
    return service.index(params)
      .then(response => {
        commit('setIndex', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  data: state => state.data,
  itemsCount: state => Number(state.itemsCount)
}

const adminReferrals = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminReferrals
