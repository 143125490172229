<template>
  <div class="content-wrapper parent_tooltip PWAsVertical pwas-vertical" v-if="accesses.pwas.update">
    <navigation />
    <div v-if="!loading" class="row">
      <div class="col-xl-8 col-lg-7 col-sm-12 grid-margin grid-margin-md-0">
        <div class="card">
          <div class="card-body pt-0 ">
            <div class="style_descr_g">
              <div class="head_tit_sg d_flex align_center justify_between">
                <div>
                  <h4 class="title_sf_site no_mb_b">{{ $t('pwas.components.vertical.title', {id: $route.params.id}) }}</h4>
                </div>
                <div class="btn_add_fisg">
                  <button class="d_flex align_center justify_center button_upload_mr" @click="showUploadImages = true">
                    <span class="icon_uploas_g small_ic_g"></span>
                    <span>{{ $t('pwas.components.vertical.load') }}</span>
                  </button>
                </div>
              </div>
              <p class="descr_site_shg mb_small">
                {{ $t('pwas.components.vertical.message') }}
              </p>
            </div>
            <form
              class="forms-sample style_form_lab"
              @submit.prevent="handleSave()"
              @change="onChange"
            >
              <div
                class="form-group">
                <label for="name">{{ $t('pwas.components.vertical.template.title') }}</label>
                <BaseSelect
                  v-model="view"
                  id="view" name="view"
                  :options="templatesOptions"
                  :clearable="false"
                />
              </div>
              <div class="form-group">
                <label for="name">{{ $t('pwas.components.vertical.verticalLabel') }}</label>
                <BaseSelect
                  v-model="vertical"
                  id="vertical" name="vertical"
                  :options="verticalsOptions"
                  :clearable="false"
                />
              </div>
              <you-tube-control
                v-if="pwaCreated"
                v-model="youtube"
                :errors="apiValidationErrors"
              />
              <div class="form-group">
                <label class="mb-2" for="muted">{{ $t('pwas.components.vertical.muted.title') }}
                  <info :message="$t('pwas.components.vertical.muted.info')" />
                </label>
                <BaseSelect
                  v-model="muted"
                  id="muted" name="muted"
                  :options="mutedOptions"
                  :clearable="false"
                />
              </div>
              <BaseFromGroup
                :label="$t('pwas.components.vertical.topGames')"
                :has-error="apiValidationErrors.topGames"
                :info-text="$t('pwas.components.vertical.pwa')"
                :error-text="apiValidationErrors?.topGames ? apiValidationErrors?.topGames[0] : ''"
                :key="topGamesOptions.length"
              >
                <BaseSelect
                  v-model="topGames"
                  multiple
                  :filter="{status: 4, ignore: [pwa._id]}"
                  :options="topGamesOptions"
                  :placeholder="$t('pushes.form.segmentation.pwas.placeholder')"
                  endpoint="/pwas/select"
                  sort="-createdAt"
                />
              </BaseFromGroup>
              <div class="loading_cont_go" v-if="imgLoading">
                <div class="in_to_load_sf d_flex justify_center">
                  <img src="/images/loader.svg" alt="" width="100">
                </div>
              </div>
              <div class="block_seldctiosn_g" v-else>
                <div class="item_Selcj_go" v-for="(imagesFromList, index) in list" :key="index + offset"
                     :class="images === index + offset ? 'bg-warning' : ''">
                  <label class="form-check-label sr-only" :for="`image-${index + offset}`">
                    <input type="radio" class="form-check-input" name="image" v-model="images"
                           :value="index + offset"
                           :id="`image-${index + offset}`">
                    {{ $t('pwas.components.vertical.imageSet') }} №{{ index + offset + 1 }}
                    <i class="input-helper"></i>
                  </label>
                  <div class="owl-carousel owl-theme nonloop" v-if="imagesFromList.length">
                    <div class="item" v-for="(image, index2) in imagesFromList" :key="index2">
                      <img :src="image | APIImage" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <nav v-if="pageCount > 1">
                <paginate ref="paginate"
                          v-model="pageIndex"
                          :page-count="pageCount"
                          :value="pageIndex"
                          :click-handler="pagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          :container-class="'mb-3 mt-2 pagination_gd_go d_flex justify_center align_center'"
                          :page-class="'page_item_gd'"
                          :page-link-class="'page_link_g d_flex justify_center align_center'"
                          :prev-class="'page-item_go'"
                          :next-class="'page-item_go'"
                          :prev-link-class="'page-link_go d_flex justify_center align_center'"
                          :next-link-class="'page-link_go d_flex justify_center align_center'"
                          :active-class="'active'">
                </paginate>
              </nav>
              <div class="d-md-flex align-items-center mt-sm-3 mobile-grid-2">
                <div class="mr-md-4 mb-4 mb-md-0">
                  <button
                    type="submit"
                    class="ful_butt_green d_flex align_center justify_center mobile-full-w"
                  >
                    {{ $t('general.save') }}
                  </button>
                </div>
                <div class="mr-md-4 mb-4 mb-md-0">
                  <router-link :to="{name: 'PWAsApp', params: {id: $route.params.id} }"
                     class="border_butt_green d_flex align_center justify_center mobile-full-w">{{ $t('general.next') }}
                  </router-link>
                </div>
                <pwas-templates-vertical-template v-model="pwa"/>
                <div class="mr-md-4 mb-4 mb-md-0 one-column-mobile">
                  <router-link :to="{name: 'PWAs'}" class="simple_butt_afg mobile-full-w">{{
                      $t('general.cancel')
                    }}
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-5 col-sm-12 grid-margin grid-margin-md-0 preview_this_blk">
        <div class="button_back_to_edit" data-hide-preview>
          <div class="green_cirs_g d_flex align_center justify_center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <preview :id="PWAId" :previewURL="previewURL" ref="preview"/>
      </div>
      <div class="button_preview_sh" data-show-preview>
        <div class="show_preview_green d_flex align_center justify_center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.9192 10.6531C20.6047 10.4832 17.9084 8.91875 16.8967 8.32989L16.8938 8.32789L4.63891 1.25275C4.43013 1.1253 4.19602 1.04501 3.95297 1.01749C3.70992 0.989977 3.46379 1.0159 3.2318 1.09345C3.20562 1.10285 3.18102 1.11231 3.15557 1.12396C3.09648 1.14322 3.03872 1.16634 2.98266 1.19318C2.74834 1.33323 2.55611 1.53385 2.42619 1.77393C2.29627 2.01401 2.23346 2.28468 2.24438 2.55744V21.5428C2.23694 21.7969 2.29713 22.0484 2.41878 22.2717C2.54043 22.4949 2.71918 22.6818 2.93676 22.8133C2.98694 22.8378 3.03864 22.8591 3.09155 22.877C3.12048 22.8904 3.14806 22.902 3.17791 22.9125C3.32112 22.9645 3.47195 22.9923 3.62426 22.9949C3.91675 22.9918 4.2033 22.912 4.45532 22.7635C4.84399 22.5379 16.8938 15.5809 16.8938 15.5809L20.9143 13.2596C21.1629 13.1351 21.3727 12.945 21.5211 12.7099C21.6694 12.4747 21.7507 12.2035 21.7561 11.9256C21.7439 11.6578 21.6597 11.3983 21.5124 11.1744C21.3651 10.9505 21.1602 10.7704 20.9192 10.6531ZM4.24438 19.8391V4.10199L12.1845 11.9611L4.24438 19.8391ZM9.26238 17.6774L13.6059 13.3679L14.7554 14.5057C13.4908 15.236 11.3541 16.4697 9.26238 17.6775V17.6774ZM13.6042 10.5524L9.20631 6.19946L14.76 9.40576L13.6042 10.5524ZM16.551 13.4691L15.0256 11.9594L16.5533 10.4435C17.2736 10.8618 18.3961 11.5129 19.1692 11.9578L16.551 13.4691Z"
              fill="white"/>
          </svg>
        </div>
      </div>
    </div>
    <ImagesUpload v-if="showUploadImages" :value="gallery" @cancel="handlerCancel" @apply="handlerUse"/>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import pwaMixin from './mixins/pwa-mixin'
import Navigation from './components/Navigation.vue'
import Preview from '@/components/Preview.vue'
import _ from 'lodash'
import titleMixin from '@/mixins/title-mixin'
import uploadMixin from '@/mixins/upload-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import YouTubeControl from './components/YouTubeControl.vue'
import {PwasTemplatesVerticalTemplate} from './templates'
import Info from '@/components/Info.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import confirmMixin from '@/mixins/confirm-leave-mixin'
import draggable from 'vuedraggable'
import ImagesUpload from './components/ImagesUpload.vue'

const defaultFields = () => ({
  view: 'index.ejs',
  vertical: 1,
  youtube: '',
  muted: true,
  images: 0,
  gallery: []
})

export default {
  name: 'PWAsVertical',
  title: function () {
    return this.$t('titles.pwa.vertical', {id: this.PWAId})
  },
  components: {
    BaseSelect,
    Info,
    YouTubeControl,
    navigation: Navigation,
    preview: Preview,
    PwasTemplatesVerticalTemplate,
    draggable,
    BaseFromGroup,
    ImagesUpload
  },
  mixins: [formMixin, pwaMixin, titleMixin, accessesMixin, confirmMixin, uploadMixin],
  data () {
    return {
      ...defaultFields(),
      imgLoading: true,
      pageSize: 2,
      count: 0,
      list: [],
      pageIndex: 1,
      verticals: [],
      imageToCrop: null,
      uploadImageType: 'icon',
      pwas: [],
      topGames: [],
      topGamesOptions: [],
      logoError: null,
      showUploadImages: false
    }
  },
  computed: {
    previewURL () {
      return `${import.meta.env.VITE_APP_PREVIEW_BASE_URL}/${this.PWAId}`
    },
    pageCount () {
      return Math.ceil(this.count / this.pageSize)
    },
    offset () {
      return ((this.pageIndex - 1) * this.pageSize)
    },
    showLogo () {
      return this.gallery !== undefined && this.gallery.length !== 0 && this.gallery[0] !== ''
    },
    user () {
      return this.$store.getters['identity']
    },
    payload () {
      return {
        view: this.view,
        vertical: this.vertical,
        youtube: this.youtube,
        muted: this.muted,
        images: this.images,
        gallery: this.gallery,
        topGames: this.topGames
      }
    },
    templatesOptions () {
      return this.$store.getters['verticals/templates'].map(template => ({id: template.id, text: this.$t(template.label)}))
    },
    verticalsOptions () {
      return this.$store.getters['verticals/verticals'].map(template => ({id: template.id, text: this.$t(template.label)}))
    },
    mutedOptions () {
      return [{id: false, text: this.$t('pwas.components.vertical.muted.on')}, {id: true, text: this.$t('pwas.components.vertical.muted.off')}]
    },
    otherGallery: {
      get () {
        return this.gallery.slice(1)
      },
      set (newVal) {
        this.gallery = [this.gallery[0], ...newVal]
      }
    }
  },
  created () {
    this.sync()
  },
  updated () {
    this.$nextTick(() => {
      this.initCarousel()
    })
  },
  methods: {
    async sync () {
      await this.$store.dispatch('pwas/asyncVerticalPage', this.$route.params.id)
      for (const [prop, value] of Object.entries(this.$store.getters['pwas/verticalPage'])) {
        if (this[prop] != null) {
          this[prop] = value
        }
      }
      await this.init()
      this.loading = false
    },
    async init () {
      this.getVerticals = _.debounce(this.getVerticals, 500)
      await this.getVerticals(this.vertical)
      this.pageIndex = Math.ceil((this.images === -1 ? 1 : (this.images + 1)) / this.pageSize)
      // eslint-disable-next-line no-undef
      jQuery.fn.andSelf = function () {
        return this.addBack.apply(this, arguments)
      }
      this.$watch('vertical', async function (n) {
        this.$refs.paginate.handlePageSelected(1)
        await this.getVerticals(n)
        this.images = 0
        this.$refs.preview.updatePreview(this.payload)
        this.onChange()
      })
      this.$watch('images', function () {
        this.$refs.preview.updatePreview(this.payload)
      })
      this.$watch('gallery', function (n) {
        this.$refs.preview.updatePreview(this.payload)
      })
      this.$watch('youtube', () => {
        this.$refs.preview.updatePreview(this.payload)
      })
      this.$watch('muted', () => {
        this.onChange()
        this.$refs.preview.updatePreview(this.payload)
      })
      this.$watch('topGames', () => {
        this.onChange()
        this.$refs.preview.updatePreview(this.payload)
      })
      this.$watch('view', (n) => {
        this.onChange()
        this.$refs.preview.updatePreview(this.payload)
        const youtube = this.youtube
        if (n === 'index2022.ejs' && (youtube === '' || youtube == null)) {
          this.youtube = ''
          this.changed.youtube = ''
          this.apiValidationErrors = {youtube: [this.$t('pwas.components.vertical.youtube.require')]}
        }
      })
    },
    async handleSave () {
      try {
        this.resetApiValidation()
        await this.$store.dispatch('pwas/updateVertical', {
          pwaId: this.$route.params.id,
          view: this.view,
          vertical: this.vertical,
          youtube: this.youtube,
          muted: this.muted,
          images: this.images,
          gallery: this.gallery,
          topGames: this.topGames
        })
        this.resetApiValidation()
        this.$store.dispatch(
          'alerts/success',
          this.$t('pwas.save')
        )
        this.changedData = false
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    async getVerticals (id) {
      await this.$store.dispatch('verticals/read', id)
      this.verticals = this.$store.getters['verticals/vertical']
      this.getList()
    },
    initCarousel () {
      // eslint-disable-next-line no-undef
      const $nonloop = jQuery('.nonloop')
      if ($nonloop.length) {
        $nonloop.owlCarousel({
          items: 5,
          loop: false,
          margin: 10,
          autoplay: false
        })
      }
      const carousels = document.querySelectorAll('.owl-carousel')
      const clickOnCarousel = function () {
        this.parentNode.querySelector('.form-check-input').click()
      }
      for (const carousel of carousels) {
        carousel.addEventListener('click', clickOnCarousel)
      }
    },
    pagination (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      this.count = this.verticals.length
      this.list = this.verticals.slice(this.offset, this.pageIndex * this.pageSize)
      const imageContainer = document.querySelector('.item_Selcj_go')
      this.imgLoading = true
      if (imageContainer) {
        this.checkImage(`${imageContainer.clientHeight * 2}px`)
      } else {
        this.imgLoading = false
      }
    },
    async handlerCancel () {
      this.showUploadImages = false
    },
    async handlerUse (newGallery) {
      this.gallery = newGallery
      this.showUploadImages = false
      this.images = -1
      this.$refs.paginate.handlePageSelected(1)
      this.$refs.preview.updatePreview(this.payload)
      this.onChange()
      this.resetApiValidation()
      this.handleSave()
    },
    checkImage (height) {
      let loadContainer = document.querySelector('.loading_cont_go')
      if (loadContainer === null) {
        setTimeout(() => {
          this.checkImage(height)
        }, 50)
      } else {
        loadContainer.style.height = height
        document.querySelector('.loading_cont_go img').style.height = height
        let imageLoaded = 0
        let allImages = 0
        for (const images of this.list) {
          allImages += images.length
        }
        for (const images of this.list) {
          for (const image of images) {
            const img = new Image()
            img.src = this.$options.filters.APIImage(image)
            img.onload = () => {
              imageLoaded++
              if (imageLoaded === allImages) {
                setTimeout(() => {
                  this.imgLoading = false
                }, 100)
              }
            }
          }
        }
      }
    },
    onChange () {
      this.changedData = true
    }
  }
}
</script>
<style scoped lang="sass">
.pwas-vertical
  @media screen and (min-width: 992px)
    padding-right: 60px !important
.block_seldctiosn_g
  height: fit-content !important

.flip-list-move
  transition: transform 0.5s

</style>
