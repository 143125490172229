import api from '@/store/services/api'
import {getSearchParams} from '@/store/list'
import {
  SET_ALL
} from './mutation-types'

export default {
  async getTemplatesList ({ commit }, params) {
    commit(SET_ALL, { templatesListLoading: true, templatesLoaded: false })
    return api.get('pwa/pwa-templates?' + getSearchParams(params))
      .then((res) => {
        commit(SET_ALL, {
          templatesList: res.models,
          templatesListCount: res.count,
          templatesListLoading: false,
          templatesLoaded: true
        })
      }).catch(error => {
        throw error
      })
  },
  async createTemplate ({ commit }, data) {
    return api.post(`admin/pwa/pwa-templates`, data)
      .then((res) => {
        return res
      }).catch(error => {
        throw error
      })
  },
  async updateTemplate ({ commit }, data) {
    try {
      await api.put(`admin/pwa/pwa-templates/${data.id}`, data)
    } catch (error) {
      throw error
    }
  },
  async deleteTemplate ({ commit, state }, id) {
    try {
      await api.delete(`admin/pwa/pwa-templates/${id}`)
    } catch (error) {
      throw error
    }
  },
  async updateOrderTemplate ({ commit }, data) {
    try {
      await api.patch(`admin/pwa/pwa-templates/order`, data)
    } catch (error) {
      throw error
    }
  }
}
