import Tariffs from './Tariffs.vue'
import * as vouchers from './vouchers'
import * as installations from './installations'

export default (meta, header) => {
  return [
    {
      path: 'tariffs',
      name: 'AdminTariffs',
      components: {default: Tariffs, header},
      meta
    },
    ...vouchers.router(meta, header),
    ...installations.router(meta, header)
  ]
}
