export default {
  navigation: 'Analytics',
  title: 'PWA analytics #{id}',
  message: 'Integrate your analytics code into PWA',
  YMInfo: 'Insert a short YM identifier. We automatically track the Lead action. A lead is the opening of a PWA',
  nativePush: 'Collect PUSH in pwa.group',
  nativePushInfo: 'Activate to collect and send PUSH via the pwa.group service',
  POSInfo: 'Identifier One Signal',
  forwardingSignalUserId: 'Throw OneSignal user id and External User Id (in signal and url os_user_id)',
  forwardingSignalUserIdInfo: 'If you need to flip the OneSignal user id in PWA to a reference in the format ..&onesignalid=SIG_USER_ID',
  sendPush: 'Send push with <a href="https://push.express/">PUSH.express</a>'
}
