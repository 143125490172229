import api from '../api'
import {getSearchParams} from '../../list'

export default {
  list: (payload) => {
    return api.get('admin/postbacks?' + getSearchParams(payload))
  },
  getPageLogs: (payload) => {
    return api.get('admin/postback-logs?' + getSearchParams(payload))
  },
  create: payload => api.post('admin/postbacks', payload),
  update: ({id, ...payload}) => api.put(`admin/postbacks/${id}`, payload),
  delete: id => api.delete(`admin/postbacks/${id}`)
}
