<template>
  <div class="item_toggsl_g">
    <div class="head_tit_sl_togg">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ $t('pwas.components.app.rating.title') }}</span>
        <info :message="$t('pwas.components.app.rating.titleInfo')"/>
      </div>
    </div>
    <div class="content_dropd_g">
      <div class="row row_mob_sg">
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.reviews_label ? 'has-danger' : ''">
          <label for="reviews_label">{{ $t('pwas.components.app.rating.reviews') }}
            <info :message="$t('pwas.components.app.rating.reviewsInfo')"/>
          </label>
          <input type="text" id="reviews_label" :placeholder="$t('pwas.components.app.rating.reviews')"
                 v-model="value.languages[lang].reviews_label" class="form-control"
                 :class="apiValidationErrors.reviews_label ? 'form-control-danger' : ''"
                 @focus="clearError('reviews_label')" v-if="lang">
          <input type="text" id="reviews_label" :placeholder="$t('pwas.components.app.rating.reviews')"
                 v-model="value.reviews_label"
                 class="form-control"
                 :class="apiValidationErrors.reviews_label ? 'form-control-danger' : ''"
                 @focus="clearError('reviews_label')" v-else>
          <label v-if="apiValidationErrors.reviews_label" class="error mt-2 text-danger"
                 for="reviews_label">
            {{ apiValidationErrors.reviews_label[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob" :class="apiValidationErrors.rating ? 'has-danger' : ''">
          <label for="rating">{{ $t('pwas.components.app.rating.title') }}
            <info :message="$t('pwas.components.app.rating.ratingInfo')"/>
          </label>
          <input type="number" step="0.1" min="1" max="5" id="rating"
                 :placeholder="$t('pwas.components.app.rating.title')"
                 v-model="value.rating" class="form-control"
                 :class="apiValidationErrors.rating ? 'form-control-danger' : ''"
                 @focus="clearError('rating')">
          <label v-if="apiValidationErrors.rating" class="error mt-2 text-danger" for="rating">
            {{ apiValidationErrors.rating[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.count_comments ? 'has-danger' : ''">
          <label for="count_comments">{{ $t('pwas.components.app.rating.countComments') }}
            <info :message="$t('pwas.components.app.rating.countCommentsInfo')"/>
          </label>
          <input type="number" step="1" min="0" id="count_comments"
                 :placeholder="$t('pwas.components.app.rating.countComments')"
                 v-model="value.count_comments"
                 class="form-control"
                 :class="apiValidationErrors.count_comments ? 'form-control-danger' : ''"
                 @focus="clearError('count_comments')">
          <label v-if="apiValidationErrors.count_comments" class="error mt-2 text-danger"
                 for="count_comments">
            {{ apiValidationErrors.count_comments[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.total ? 'has-danger' : ''">
          <label for="total">{{ $t('pwas.components.app.rating.total') }}
            <info :message="$t('pwas.components.app.rating.totalInfo')"/>
          </label>
          <input type="text" id="total" :placeholder="$t('pwas.components.app.rating.total')"
                 v-model="value.languages[lang].total" class="form-control"
                 :class="apiValidationErrors.total ? 'form-control-danger' : ''"
                 @focus="clearError('total')" v-if="lang">
          <input type="text" id="total" :placeholder="$t('pwas.components.app.rating.total')"
                 v-model="value.total"
                 class="form-control"
                 :class="apiValidationErrors.total ? 'form-control-danger' : ''"
                 @focus="clearError('total')" v-else>
          <label v-if="apiValidationErrors.total" class="error mt-2 text-danger" for="total">
            {{ apiValidationErrors.total[0] }}
          </label>
        </div>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_5 ? 'has-danger' : ''">
        <label for="rating_bar_5">{{ $t('pwas.components.app.rating.count', {num: 5}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_5"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_5" class="form-control"
               :class="apiValidationErrors.rating_bar_5 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_5')">
        <label v-if="apiValidationErrors.rating_bar_5" class="error mt-2 text-danger"
               for="rating_bar_5">
          {{ apiValidationErrors.rating_bar_5[0] }}
        </label>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_4 ? 'has-danger' : ''">
        <label for="rating_bar_4">{{ $t('pwas.components.app.rating.count', {num: 4}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_4"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_4" class="form-control"
               :class="apiValidationErrors.rating_bar_4 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_4')">
        <label v-if="apiValidationErrors.rating_bar_4" class="error mt-2 text-danger"
               for="rating_bar_4">
          {{ apiValidationErrors.rating_bar_4[0] }}
        </label>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_3 ? 'has-danger' : ''">
        <label for="rating_bar_3">{{ $t('pwas.components.app.rating.count', {num: 3}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_3"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_3" class="form-control"
               :class="apiValidationErrors.rating_bar_3 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_3')">
        <label v-if="apiValidationErrors.rating_bar_3" class="error mt-2 text-danger"
               for="rating_bar_3">
          {{ apiValidationErrors.rating_bar_3[0] }}
        </label>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_2 ? 'has-danger' : ''">
        <label for="rating_bar_2">{{ $t('pwas.components.app.rating.count', {num: 2}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_2"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_2" class="form-control"
               :class="apiValidationErrors.rating_bar_2 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_2')">
        <label v-if="apiValidationErrors.rating_bar_2" class="error mt-2 text-danger"
               for="rating_bar_2">
          {{ apiValidationErrors.rating_bar_2[0] }}
        </label>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_1 ? 'has-danger' : ''">
        <label for="rating_bar_1">{{ $t('pwas.components.app.rating.count', {num: 1}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_1"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_1" class="form-control"
               :class="apiValidationErrors.rating_bar_1 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_1')">
        <label v-if="apiValidationErrors.rating_bar_1" class="error mt-2 text-danger"
               for="rating_bar_1">
          {{ apiValidationErrors.rating_bar_1[0] }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'

export default {
  name: 'RatingApp',
  mixins: [formMixin],
  components: {Info},
  props: {
    value: {
      type: Object,
      require: true
    },
    errors: {
      type: Object,
      require: true
    }
  },
  computed: {
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  },
  mounted () {
    for (let i = 1; i <= 5; i++) {
      // eslint-disable-next-line no-undef
      jQuery(`#rating_bar_${i}`).ionRangeSlider({
        min: 0,
        max: 100,
        from: this.value[`rating_bar_${i}`],
        onChange: data => {
          // Called then slider is changed using Update public method
          this.onChange()
          this.value[`rating_bar_${i}`] = data.from_percent
        }
      })
    }
  },
  methods: {
    onChange () {
      this.$emit('change')
    }
  }
}
</script>
