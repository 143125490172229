import service from '../services/tariffs-service'
import middleware from './middleware'

const state = {
  models: [],
  history: [],
  voucher: 0,
  count: 0,
  installations: [
    {name: 'tariffs.installations.tier1', countries: []},
    {name: 'tariffs.installations.tier2', countries: []},
    {name: 'tariffs.installations.tier3', countries: []}
  ]
}

const mutations = {
  setTariffs: (state, payload) => {
    state.history = payload.history
    state.count = payload.count
    state.models = []
    let expired = 0
    let cost = 0
    for (const tariff of payload.tariffs) {
      if (payload.current != null && payload.current.tariff === tariff.id) {
        tariff.current = payload.current._id
        tariff.activations = payload.current.activations
        tariff.used = payload.current.used
        cost = tariff.cost
        // eslint-disable-next-line no-undef
        const end = moment(payload.current.expires).utc()
        // eslint-disable-next-line no-undef
        const start = moment(new Date()).utc()
        tariff.expired = end.from(start).replace(/\D/g, '')
      } else {
        tariff.current = false
        if (expired > 0) {
          const upCost = Math.ceil((tariff.cost - cost) / 30 * expired)
          tariff.upCost = upCost > tariff.cost ? tariff.cost : upCost
        }
      }
      state.models.push(tariff)
    }
  },
  setVoucher: (state, payload) => {
    state.voucher = payload.total
  },
  setInstallations: (state, payload) => {
    state.installations = payload
  }
}

const actions = {
  index ({commit, dispatch}, params) {
    return service.index(params)
      .then(res => commit('setTariffs', res))
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  subscribe ({commit, dispatch}, params) {
    return service.subscribe(params)
      .then(() => {
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  voucher ({commit, dispatch}, params) {
    return service.voucher(params)
      .then(res => commit('setVoucher', res))
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  async getInstallations ({commit, dispatch}, params) {
    try {
      const installations = await service.installations(params)
      commit('setInstallations', installations)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  voucher: state => state.voucher,
  mains: state => state.models,
  history: state => state.history,
  count: state => state.count,
  installations: state => state.installations
}

const tariffs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default tariffs
