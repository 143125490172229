<template>
  <modal ref="modal" id="analytics-report-modal">
    <div class="info_blk_g_mod text-center">
      <div class="icon_sinf_g">
        <img src="/images/icon_info_attention.png" alt="">
      </div>
      <div v-html="$t(title)" class="title_inf_glbk" style="color: #F1C62D;"/>
      <div v-html="$t(body)" class="descr_inf_ico_g" />
      <div v-html="$t(hint)" class="descr_inf_ico_g" style="color: #F1C62D;"/>
    </div>
    <div class="foot_inf_sg_g">
      <div class="in_to_foot_br_g">
        <div class="row has_big_rog">
          <div class="col-6 item_pwa_shs_g">
            <button
              :disabled="processing || ready"
              @click="buttonClickSubmit"
              v-html="$t(buttonLabelRun)"
              class="btn_simple_border d_flex align_center justify_center no_underline yell_col w-100"
            />
          </div>
          <div class="col-6 item_pwa_shs_g">
            <button
              :disabled="processing"
              @click="buttonClickCancel"
              v-html="$t(buttonLabelCansel)"
              class="btn_simple_border d_flex align_center justify_center no_underline full_plin yell_col w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/modal/Modal.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  components: { Modal },
  data () {
    return {
      title: '',
      body: '',
      hint: '',
      buttonLabelRun: '',
      buttonLabelCansel: ''
    }
  },
  computed: {
    ...mapGetters({
      processing: 'analytics/reportProcessing',
      ready: 'analytics/reportReady',
      options: 'analytics/options',
      csv: 'analytics/csv'
    })
  },
  methods: {
    ...mapActions({
      skipReport: 'analytics/skipReport',
      createReport: 'analytics/createReport'
    }),
    async handleOpen () {
      this.title = 'analytics.report.title'
      this.body = 'analytics.report.body.open'
      this.hint = 'analytics.report.hint.open'

      this.buttonLabelRun = 'pwas.buttons.run'
      this.buttonLabelCansel = 'general.cancel'

      await this.skipReport()
      this.$refs.modal.open()
    },
    async buttonClickSubmit () {
      this.body = ''
      this.hint = 'analytics.report.hint.loading'

      try {
        const payload = this.options
        await this.createReport(payload)
        const csv = this.csv
        this.body = this.$t('analytics.report.body.successful', { csv: 'data:text/csv,' + encodeURIComponent(csv) })
        this.hint = 'analytics.report.hint.successful'
      } catch (e) {
        await this.skipReport()
        this.hint = 'analytics.report.hint.error'
        if (e.response == null) {
          this.body = e.message
        } else {
          this.body = e.response.data.message
        }
      }
    },
    buttonClickCancel () {
      this.$refs.modal.close()
    }
  }
}
</script>
