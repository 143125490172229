import mainConnector from '@/connectors/main.connector'

export default {
  methods: {
    async getSelect2AjaxOptions (value, endpoint) {
      if (value != null && value !== '') {
        const model = await mainConnector.get(`${endpoint}/${value}`)
        return [model]
      }
      return []
    },
    getSelect2AjaxSettings (endpoint, customParams = '-createdAt') {
      return {
        allowClear: false,
        escapeMarkup: function (m) {
          return m
        },
        ajax: {
          dataType: 'json',
          url: mainConnector.getUrl(endpoint),
          delay: 500,
          transport: function (params, success, failure) {
            // eslint-disable-next-line no-undef
            const $request = $.ajax({
              ...params,
              headers: mainConnector.headers()
            })
            $request.then(success)
            $request.fail(failure)
            return $request
          },
          data: params => {
            const urlSearchParams = new URLSearchParams()
            urlSearchParams.set('page', params.page || '1')
            urlSearchParams.set('limit', '5')
            if (typeof customParams === 'object') {
              const {sort, ...payload} = customParams
              urlSearchParams.set('sort', sort || '-createdAt')
              for (const [prop, value] of Object.entries(payload)) {
                urlSearchParams.set(`filter[${prop}]`, value)
              }
            } else {
              urlSearchParams.set('sort', customParams)
            }
            if (params.term != null) {
              urlSearchParams.append('filter[text]', params.term)
            }
            return decodeURI(urlSearchParams.toString())
          }
        }
      }
    }
  }
}
