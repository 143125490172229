<template>
  <div class="content-wrapper">
    <btc-wallet-info/>
    <div v-if="!loading" class="row">
      <amounts-form class="col-md-3" ref="amounts-form"/>
      <expired-form class="col-md-3" ref="expired-form"/>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-3" v-for="(data, rate) of billing.rates" v-bind:key="rate">
        <rate-form v-bind:data="data" v-bind:rate="rate"/>
      </div>
    </div>
    <div v-else class="dot-opacity-loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import RateForm from './components/RateForm.vue'
import AmountsForm from './components/AmountsForm.vue'
import ExpiredForm from './components/ExpiredForm.vue'
import BTCWalletInfo from './components/BTCWalletInfo.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'AdminBillingConfig',
  components: {
    'btc-wallet-info': BTCWalletInfo,
    ExpiredForm,
    AmountsForm,
    RateForm
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      billing: 'adminConfig/billing'
    })
  },
  created () {
    this.fetchPage()
  },
  methods: {
    ...mapActions({
      fetchData: 'adminConfig/fetchData'
    }),
    debounceFetchData: _.debounce(async function () {
      await this.fetchData()
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData()
    }
  }
}
</script>
