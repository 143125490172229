export default {
  actions: {
    nothing: 'Selecione uma ação'
  },
  remove: {
    action: 'Apagar',
    title: 'Apagar selecionado',
    message: 'Quer mesmo apagar os que estão destacados?',
    okButton: 'Apagar'
  },
  copyPwa: {
    action: 'Copiar',
    title: 'Copiar selecções',
    message: 'Apenas os <span class="text-success">pagos</span> podem ser copiados. Quer mesmo copiar os destacados?',
    okButton: 'Copiar',
    tracker: {
      message: 'Copiar com tracker ou sem?',
      okButton: 'Com tracker',
      cancelButton: 'Sem tracker'
    }
  },
  copy: {
    action: 'Copiar',
    title: 'Copiar selecções',
    message: 'Quer mesmo copiar os destacados?',
    okButton: 'Copiar'
  },
  restore: {
    action: 'Restaurar',
    title: 'Restaurar selecionado',
    message: 'Quer mesmo restaurar os que estão destacados?',
    okButton: 'Restaurar'
  },
  trash: {
    action: 'Lixo',
    title: 'Mover a seleção para o lixo',
    message: 'Quer mesmo passar para o lixo os que estão destacados? Terá dois dias para recuperar',
    messageRelation: 'Quer mesmo passar para o lixo os que estão destacados? Terá dois dias para recuperar. Esses PWAs também serão removidos dos Jogos Principais!',
    okButton: 'Para o lixo'
  },
  play: {
    action: 'Arrancar',
    title: 'Executar o ficheiro destacado',
    message: 'Tem a certeza de que pretende executar PWAs dedicados?',
    okButton: 'Arrancar'
  },
  stop: {
    action: 'Parar',
    title: 'Parar o destacado',
    message: 'Tem a certeza de que pretende parar os PWAs dedicados?',
    okButton: 'Parar'
  },
  meta: {
    action: 'Etiquetas META personalizadas',
    title: 'Insira as suas etiquetas META',
    message: 'Estas etiquetas META serão adicionadas aos seus PWAs',
    okButton: 'Guardar'
  },
  customJs: {
    action: 'JS personalizado',
    title: 'Insira o seu script JS',
    message: 'Este código JS será adicionado aos seus PWAs',
    okButton: 'Guardar'
  },
  pagination: 'Ações em massa'
}
