<template>
  <div class="label-negative-form">
    <event-input
      v-for="(data, event) of events"
      :key="event"
      :value="data"
      :apiValidationErrors="apiValidationErrors"
      :event="event"
      @save="handlerSave"
    />
  </div>
</template>
<script>
import EventInput from './EventInput.vue'

export default {
  name: 'S2sPostback',
  components: {EventInput},
  props: {
    value: {
      type: Object,
      required: true
    },
    apiValidationErrors: {
      type: Object,
      required: true
    }
  },
  computed: {
    events () {
      const events = {}
      for (const [event, data] of Object.entries(this.value)) {
        if (event !== '_id') {
          events[event] = data
        }
      }
      return events
    }
  },
  methods: {
    handlerSave () {
      this.$emit('save', true)
    }
  }
}
</script>
