import Vacancies from './Vacancies.vue'

export default (meta, header) => {
  return [
    {
      path: 'vacancies',
      name: 'Vacancies',
      components: {default: Vacancies, header},
      meta
    }
  ]
}
