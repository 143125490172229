export function mergeArrays (arr1, arr2) {
  const mergedMap = new Map()

  function addToMap (array) {
    array.forEach(item => {
      const idString = JSON.stringify(item.id)
      if (!mergedMap.has(idString)) {
        mergedMap.set(idString, {...item})
      } else {
        const existing = mergedMap.get(idString)
        for (const key in item) {
          if (key !== 'id' && item.hasOwnProperty(key)) {
            existing[key] += item[key]
          }
        }
      }
    })
  }

  addToMap(arr1)
  addToMap(arr2)

  return Array.from(mergedMap.values())
}
