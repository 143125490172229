export default {
  notFound: 'Page not found',
  analyticsGroups: 'Daily statistics',
  analyticsUniques: 'Uniques Statistics',
  bills: 'Bills',
  transactions: 'Transactions',
  pwa: {
    trash: 'PWA Trash',
    trashBadge: 'Trash empty | In trash one PWA | In trash {count} PWA | In trash {count} PWA',
    app: 'PWA App #{id}',
    comments: 'PWA comments #{id}',
    create: 'Creating a PWA',
    my: 'My PWAs',
    setting: 'PWA settings #{id}',
    tracker: 'PWA tracker #{id}',
    domain: 'PWA domain #{id}',
    vertical: 'PWA vertical #{id}',
    templates: 'PWA Templates',
    analytics: 'PWA Analytics #{id}'
  },
  referrals: 'Ref. program',
  tariffs: 'Tariffs',
  teams: 'Teamwork',
  trackers: {
    my: 'My trackers',
    create: 'Creating tracker',
    edit: 'Editing tracker #{id}'
  },
  pushes: {
    pushes: 'PUSH mailings',
    create: 'Add mailing',
    edit: 'Editing mailing {id}'
  },
  bot: {
    keys: 'Bot Keys',
    offers: 'Bot Offers'
  },
  mirrors: {
    my: 'My Mirrors',
    trash: 'Mirrors Trash',
    trashBadge: 'Trash empty | In trash one Mirror | In trash {count} Mirrors | In trash {count} Mirrors'
  }
}
