<template>
  <div class="pwa-comment">
    <div class="pwa-comment__header">
      <div class="row text-center">
        <div class="col-sm-6">
          <h4 class="simple_tidg">
            {{ $t("pwas.components.comment.comment.title") }}:
          </h4>
        </div>
        <div class="col-sm-6">
          <h4 class="simple_tidg pwa-comment__header-answer">
            {{ $t("pwas.components.comment.answer.title") }}:
          </h4>
        </div>
      </div>
    </div>
    <div class="pwa-comment__body">
      <div class="pwa-comment__question">
        <div class="form-group">
          <label
            >{{ $t("pwas.components.comment.comment.avatar") }}
            <info
              :message="$t('pwas.components.comment.comment.avatarInfo')"
            />
          </label>
          <div class="avatar_upload_g">
            <form enctype="multipart/form-data" :id="`avatar-${value.id}`">
              <label
                class="file-upload upload_herE_verg file_upload_g d_flex align_center justify_center"
                :class="{ 'has-danger': apiValidationErrors.icon }"
              >
                <input
                  type="file"
                  @change="handleImageCrop(value.id, $event)"
                  accept="image/png, image/jpeg"
                  @input="clearError('icon')"
                />
                <div class="icon_uploas_g"></div>
                <div>
                  {{ $t("pwas.components.comment.comment.loadAvatar") }}
                </div>
              </label>
              <label
                v-if="apiValidationErrors.icon"
                class="error mt-1 text-danger"
              >
                {{ apiValidationErrors.icon[0] }}
              </label>
            </form>
          </div>
          <div v-if="avatar != null && avatar !== ''">
            <div class="d_inline_block in_ts_avat">
              <img :src="avatar | APIImage" />
              <button class="close_log_g" @click="handlerDeleteAvatar()">
                <img src="/images/svg-icons/icon-remove.svg" alt="icon-remove">
              </button>
            </div>
          </div>
        </div>
        <div
          class="form-group"
          :class="apiValidationErrors.author ? 'has-danger' : ''"
        >
          <label :for="`author-${value.id}`"
            >{{ $t("pwas.components.comment.comment.author") }}
            <info
              :message="$t('pwas.components.comment.comment.authorInfo')"
            />
          </label>
          <input
            type="text"
            :id="`author-${value.id}`"
            :placeholder="$t('pwas.components.comment.comment.author')"
            v-model="author"
            class="form-control"
            :class="apiValidationErrors.author ? 'form-control-danger' : ''"
            @focus="clearError('author')"
          />
        </div>
        <div class="row">
          <div class="col-6">
            <div
              class="form-group"
              :class="apiValidationErrors.rating ? 'has-danger' : ''"
            >
              <label :for="`rating-${value.id}`"
                >{{ $t("pwas.components.comment.comment.rating") }}
                <info
                  :message="$t('pwas.components.comment.comment.ratingInfo')"
                />
              </label>
              <input
                type="number"
                step="1"
                min="1"
                max="5"
                :id="`rating-${value.id}`"
                :placeholder="$t('pwas.components.comment.comment.rating')"
                v-model="rating"
                :class="
                  apiValidationErrors.rating ? 'form-control-danger' : ''
                "
                @focus="clearError('rating')"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              :class="apiValidationErrors.likes ? 'has-danger' : ''"
            >
              <label :for="`likes-${value.id}`"
                >{{ $t("pwas.components.comment.comment.likes") }}
                <info
                  :message="$t('pwas.components.comment.comment.likesInfo')"
                />
              </label>
              <input
                type="text"
                :id="`likes-${value.id}`"
                :placeholder="$t('pwas.components.comment.comment.likes')"
                v-model="likes"
                :class="
                  apiValidationErrors.likes ? 'form-control-danger' : ''
                "
                @focus="clearError('likes')"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <pwas-comments-datepicker
          v-model="date"
          :id="`date-${value.id}`"
          :label="$t('pwas.components.comment.comment.date')"
          :placeholder="$t('pwas.components.comment.comment.date')"
          :info-message="$t('pwas.components.comment.comment.dateInfo')"
        />
        <div
          class="form-group"
          :class="apiValidationErrors.comment ? 'has-danger' : ''"
        >
          <label :for="`comment-${value.id}`"
            >{{ $t("pwas.components.comment.comment.title") }}
            <info
              :message="$t('pwas.components.comment.comment.commentInfo')"
            />
          </label>
          <textarea
            :id="`comment-${value.id}`"
            rows="4"
            class="form-control"
            :class="apiValidationErrors.comment ? 'form-control-danger' : ''"
            @focus="clearError('comment')"
            v-model="comment"
            :placeholder="$t('pwas.components.comment.comment.title')"
          ></textarea>
        </div>
      </div>
      <div class="pwa-comment__answer">
        <h4 class="title_comm_repls text-center mt-4 mb-3 d-md-none">
          {{ $t("pwas.components.comment.answer.title") }}:
        </h4>
        <div
          class="form-group"
          :class="apiValidationErrors.answer_author ? 'has-danger' : ''"
        >
          <label :for="`answer-author-${value.id}`"
            >{{ $t("pwas.components.comment.answer.author") }}
            <info
              :message="$t('pwas.components.comment.answer.authorInfo')"
            />
          </label>
          <input
            type="text"
            :id="`answer-author-${value.id}`"
            :placeholder="$t('pwas.components.comment.answer.author')"
            v-model="answer_author"
            :class="
              apiValidationErrors.answer_author ? 'form-control-danger' : ''
            "
            @focus="clearError('answer_author')"
            class="form-control"
          />
        </div>
        <pwas-comments-datepicker
          v-model="answer_date"
          :id="`answer-date-${value.id}`"
          :label="$t('pwas.components.comment.answer.date')"
          :placeholder="$t('pwas.components.comment.answer.date')"
          :info-message="$t('pwas.components.comment.answer.dateInfo')"
        />
        <div
          class="form-group"
          :class="apiValidationErrors.answer_comment ? 'has-danger' : ''"
        >
          <label :for="`answer-comment-${value.id}`"
            >{{ $t("pwas.components.comment.comment.title") }}
            <info
              :message="$t('pwas.components.comment.answer.commentInfo')"
            />
          </label>
          <textarea
            :id="`answer-comment-${value.id}`"
            rows="4"
            class="form-control big_height_text"
            :class="
              apiValidationErrors.answer_comment ? 'form-control-danger' : ''
            "
            @focus="clearError('answer_comment')"
            v-model="answer_comment"
            :placeholder="$t('pwas.components.comment.comment.title')"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="pwa-comment__actions">
      <div class="d_flex align_center">
        <button
          @click="handlerUpdate"
          class="ful_butt_green d_flex align_center justify_center mr-4"
        >
          {{ $t("general.save") }}
        </button>
        <button
          type="button"
          class="simple_butt_afg"
          @click="handlerDelete"
        >
          {{ $t("general.delete") }}
        </button>
      </div>
      <div class="change-order">
        <button class="border_butt_green" :disabled="isFirstElement" @click.stop="orderChange(CHANGE_ORDER_TYPE.INCREASE)">
          <img :class="{disabled: isFirstElement}" src="/images/svg-icons/arrow-up.svg" alt="arrow-up">
        </button>
        <button class="border_butt_green" :disabled="isLastElement" @click.stop="orderChange(CHANGE_ORDER_TYPE.DECREASE)">
          <img :class="{disabled: isLastElement}" src="/images/svg-icons/arrow-down.svg" alt="arrow-down">
        </button>
      </div>
    </div>
    <ImageCropper
      v-if="imageToCrop"
      :image="imageToCrop"
      @close="imageToCrop = null"
      @imageCropped="handlerUploadAvatar"
    />
    <confirm-modal ref="confirmModal" :id="'confirm-remove-modal' + value.id"></confirm-modal>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import formMixin from '@/mixins/form-mixin'
import PwasCommentsDatepicker from '@/components/pwas/comments/Datepicker.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'
import uploadMixin from '@/mixins/upload-mixin'
import { ASPECT_RATIOS, CHANGE_ORDER_TYPE } from '@/const/index'

export default {
  name: 'Comment',
  components: { Info, PwasCommentsDatepicker, ImageCropper, ConfirmModal },
  mixins: [formMixin, uploadMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    comments: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      avatar: '',
      author: '',
      rating: 5,
      date: '',
      likes: 123,
      comment: '',
      answer_author: '',
      answer_date: '',
      answer_comment: '',
      changedData: false,
      imageToCrop: null,
      aspectRatio: ASPECT_RATIOS['1:1'],
      cashedId: null,
      CHANGE_ORDER_TYPE
    }
  },
  computed: {
    isFirstElement () {
      return this.comments[0].id === this.value.id
    },
    isLastElement () {
      return this.comments[this.comments.length - 1].id === this.value.id
    },
    currentComment () {
      return this.comments.find(item => item.id === this.value.id)
    }
  },
  created () {
    for (const [prop, value] of Object.entries(this.value)) {
      if (this[prop] != null) {
        this[prop] = value
      }
    }
    for (const prop of [
      'avatar',
      'author',
      'rating',
      'date',
      'likes',
      'comment',
      'answer_author',
      'answer_date',
      'answer_comment'
    ]) {
      this.$watch(prop, () => {
        this.emitData('change')
      })
    }
  },
  methods: {
    emitData (event) {
      this.changedData = true
      this.$emit(event, {
        _id: this.value._id,
        id: this.currentComment.id,
        author: this.author,
        comment: this.comment,
        answer_author: this.answer_author,
        answer_comment: this.answer_comment,
        date: this.date,
        answer_date: this.answer_date,
        rating: this.rating,
        likes: this.likes,
        avatar: this.avatar
      })
    },
    handlerUpdate () {
      this.emitData('update')
      this.changedData = false
    },
    handlerDelete () {
      this.emitData('delete')
    },
    handleImageCrop (id, event) {
      const file = event.target.files[0]
      if (!file) return
      const validation = this.validateFile(file)
      if (!validation.isValid) {
        this.setApiValidation([
          { param: 'icon', msg: validation.errorMessage }
        ])
        return
      }
      this.imageToCrop = file
      this.cashedId = id
      event.currentTarget.value = null
    },
    async handlerUploadAvatar (file) {
      this.changedData = false
      await this.$store.dispatch('avatars/create', {
        pwaId: this.$route.params.id,
        commentId: this.value._id,
        language: this.$route.params.language,
        payload: file
      })
      this.avatar = this.$store.getters['avatars/lastPath']
      document.getElementById(`avatar-${this.cashedId}`).reset()
    },
    async handlerDeleteAvatar () {
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('pwas.components.comment.comment.deleteAvatar'),
        okButton: this.$t('general.delete')
      })
      if (ok) {
        this.changedData = false
        this.avatar = ''
        document.getElementById(`avatar-${this.value.id}`).reset()
        await this.$store.dispatch('avatars/delete', {
          pwaId: this.$route.params.id,
          commentId: this.value._id,
          language: this.$route.params.language
        })
      }
    },
    orderChange (type) {
      this.$emit('orderChange', {
        id: this.value.id,
        type
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.sass";

.pwa-comment {
  background: linear-gradient(
    245.12deg,
    rgba(255, 255, 255, 0.0045) -14.19%,
    rgba(255, 255, 255, 0.09) 100%
  );
  box-shadow: -25px 45px 25px rgba(0, 0, 0, 0.05);
  // backdrop-filter: blur(45px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 78px;
  position: relative;
  overflow: hidden;

  .file-upload {
    &.has-danger {
      border-color: #fd5e53;
    }
  }

  &__header {
    padding: 20px;
    background-color: #35383A;

    &-answer {
      @include media(mob) {
        display: none;
      }
    }

    h4 {
      font-size: 15px;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    position: relative;
    padding: 35px 30px 30px;

    @include media(lg) {
      gap: 30px;
    }

    @include media(mob) {
      flex-direction: column;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 1px;
      background: #707175;
      top: -72px;
      bottom: 0px;

      @include media(lg) {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 8px;
      height: 8px;
      background: #21bf73;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;

      @include media(lg) {
        display: none;
      }
    }
  }

  &__question,
  &__answer {
    flex: 1;

    h4 {
      display: none;
      @include media(mob) {
        display: block;
      }
    }
  }

  &__actions {
    padding: 20px 30px;
    background-color: #35383A;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .change-order {
      button {
        border: none;
        padding: 0;
        img {
          &.disabled {
            filter: invert(47%) sepia(6%) saturate(220%) hue-rotate(191deg) brightness(91%) contrast(85%);
          }
        }
      }
    }
  }
}
</style>
