export default {
  navigation: 'Análise',
  title: 'Análise de PWA #{id}',
  message: 'Integra o seu código de análise ao PWA',
  YMInfo: 'Inserir breve identificador de YM. Automaticamente controlamos o movimento "Lead". Considerar-se-á como um lead a abertura de Pwa',
  nativePush: 'Recolher PUSH em pwa.group',
  nativePushInfo: 'Ativar para recolha e envio PUSH dentro de serviço pwa.group',
  POSInfo: 'Identificador de One Signal',
  forwardingSignalUserId: 'Direcionar OneSignal user id e External User Id (no sinal e url os_user_id)',
  forwardingSignalUserIdInfo: 'Quando é necessário direcionar OneSignal user id no PWA no link em formato ..&onesignalid=SIG_USER_ID',
  sendPush: 'Envio de push a partir de <a href="https://push.express/">PUSH.express</a>'
}
