<template>
  <modal
    ref="modal"
    id="bot-upload-modal"
    class="modal fade"
    classDialog="modal-dialog modal-lg"
    classContent="modal-content">
    <div class="head_modla_ag d_flex justify_between">
      <div class="left_text_eh_mog">
        <div class="title_mod_uplo" v-html="$t('pwas.components.vertical.loading.title')"></div>
        <div class="descr_mod_uplo">{{ $t('pwas.components.vertical.loading.message') }}
        </div>
      </div>
      <div class="logo_uploading_g">
        <div class="form_upload_log">
          <form enctype="multipart/form-data" id="avatar-0">
            <label class="file_upload_g d_flex align_center justify_center">
              <input type="file" @change="handlerUpload(0, $event)" accept="image/png, image/jpeg">
              <div class="no_poin_ev">
                <div class="icon_uploas_g small_ic_g no_marg"></div>
                <div>{{ $t('pwas.components.vertical.loading.icon') }}</div>
              </div>
            </label>
          </form>
        </div>
        <div class="uploaded_icon_g d_flex align_center justify_center"
             v-if="images.length > 0">
          <div>
            <img :src="images[0] | APIImage" class="uploaded_icon_g__image">
            <button @click="handlerDelete(0)" class="close_log_g">
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
                  fill="white"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form_preview_g">
      <form enctype="multipart/form-data" id="avatar--1">
        <label class="file_upload_g d_flex align_center justify_center">
          <input type="file" @change="handlerUpload(-1, $event)" accept="image/png, image/jpeg">
          <div class="no_poin_ev">
            <div class="icon_uploas_g small_ic_g no_marg"></div>
            <div>{{ $t('pwas.components.vertical.loading.preview') }}</div>
          </div>
        </label>
      </form>
    </div>
    <div class="items_gall_this">
      <div class="item_previ_img" v-for="(image, index) of imagesWithOutLogo" :key="index">
        <img :src="image | APIImage" width="120">
        <button @click="handlerDelete(index + 1)" class="close_log_g">
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
              fill="white"></path>
          </svg>
        </button>
      </div>
    </div>
    <div class="actions_foot_g">
      <div class="d_flex align_center justify_end">
        <div class="d_inline_block item_butt_acat_g">
          <button
            @click="handlerCancel"
            class="simple_butt_afg d_flex align_center justify_center">
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="d_inline_block item_butt_acat_g">
          <button
            @click="handlerSave"
            class="ful_butt_green d_flex align_center justify_center">
            {{ $t('pwas.components.vertical.loading.button') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import {mapActions} from 'vuex'

export default {
  name: 'BotOfferUploadModal',
  components: {Modal},
  data () {
    return {
      images: [],
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    imagesWithOutLogo () {
      return [...this.images].splice(1)
    }
  },
  methods: {
    ...mapActions({
      'uploadImage': 'bot/uploadImage',
      'deleteImage': 'bot/deleteImage'
    }),
    upload () {
      this.images = []
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerUpload (index, $event) {
      const image = await this.uploadImage({
        id: this.$route.params.id,
        file: $event.target.files[0]
      })
      if (index === -1) {
        this.images.splice(this.images.length, 0, image)
      } else {
        this.images.splice(index, 1, image)
      }
      document.getElementById(`avatar-${index}`).reset()
    },
    async handlerDelete (index) {
      await this.deleteImage({
        id: this.$route.params.id,
        image: this.images[index].split('/').reverse()[0]
      })
      this.images.splice(index, 1)
    },
    async handlerSave () {
      this.$refs.modal.close()
      this.resolvePromise(this.images)
    },
    async handlerCancel () {
      for (const index in this.images) {
        await this.deleteImage({
          id: this.$route.params.id,
          image: this.images[index].split('/').reverse()[0]
        })
      }
      this.$refs.modal.close()
      this.resolvePromise(false)
    }
  }
}
</script>

<style scoped>
.uploaded_icon_g .uploaded_icon_g__image {
  width: 100%;
  height: 100%;
  max-height: 90px;
}
</style>
