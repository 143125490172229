export default {
  home: 'Главная',
  statistics: 'Статистика',
  trackers: 'Трекеры',
  bills: 'Финансы',
  tariffs: 'Тарифы',
  referrals: 'Реф. программа',
  teams: 'Командная работа',
  templates: 'Шаблоны',
  landing: 'Лендинг',
  mirrors: 'Зеркала',
  vacancies: 'Вакансии'
}
