<template>
  <div class="Onlines onlines-table">
    <div class="has_big_pt">
      <h4 class="onlines-table__title">{{ $t('dashboard.online.title') }}
        <info :message="$t('dashboard.online.info')"/>
      </h4>
      <div class="row row_reset_mobi">
        <Loading v-if="loading" :value="loading" class="page-dashboard__loading" />
        <Error v-else-if="error" :error="error" @reload="getData"/>
        <div v-else class="col-12 table-responsive">
          <table id="pwa-listing" class="table dashboard_tablef">
            <thead>
            <tr>
              <th>{{ $t('dashboard.online.table[0]') }}</th>
              <th>{{ $t('dashboard.online.table[1]') }}</th>
              <th>{{ $t('dashboard.online.table[2]') }}</th>
              <th>{{ $t('dashboard.online.table[3]') }}</th>
              <th>{{ $t('dashboard.online.table[4]') }}</th>
              <th>{{ $t('dashboard.online.table[5]') }}</th>
              <th>{{ $t('dashboard.online.table[6]') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item of items" :key="item._id">
              <td>{{ item.date | formatDate }}</td>
              <td v-html="$options.filters.formatFlag(item.country)"></td>
              <td>{{ item.device | formatDevice }}</td>
              <td>{{ item.os | formatOS }}</td>
              <td><label :class="item.install ? 'text-success':'text-danger'">{{
                  item.install | formatBoolean
                }}</label></td>
              <td><label :class="item.open ? 'text-success':'text-danger'">{{
                  item.open | formatBoolean
                }}</label></td>
              <td><label :class="item.push ? 'text-success':'text-danger'">{{
                  item.push | formatBoolean
                }}</label></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!loading && !error && items.length === 0" class="col-12 text-center">
          <p class="text-white">{{$t('general.nothingHere')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import Loading from '@/components/Loading.vue'
import Error from '@/components/RequestError.vue'

export default {
  name: 'Onlines',
  components: {Info, Loading, Error},
  data () {
    return {
      items: [],
      loading: true,
      error: null
    }
  },
  methods: {
    async getData () {
      this.loading = true
      this.error = null
      try {
        this.items = await this.$store.dispatch('dashboardStore/topOnline')
      } catch (error) {
        if (error.response.status === 504 || error.response.status === 403) {
          this.error = error.response.status
        }
      }
      this.loading = false
    }
  },
  async created () {
    await this.getData()
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.onlines-table
  padding: 20px
  padding-bottom: 30px
  &__title
    margin-bottom: 20px
    padding-left: 10px
    font-size: 18px
    +font(400)
</style>
