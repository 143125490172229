export default (input) => {
  if (!input) return ''
  try {
    // eslint-disable-next-line no-undef
    const end = moment(String(input)).utc()
    // eslint-disable-next-line no-undef
    const start = moment(new Date()).utc()
    return end.from(start).replace('через', '')
  } catch (e) {
    return input
  }
}
