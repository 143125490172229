<template>
  <div>
    <span class="float-left">{{ data._id }}</span>
    <span class="float-right">{{ data.lead === 'install' ? 'Установка' : 'Регистрация' }}</span>
  </div>
</template>
<script>
export default {
  name: 'FBP',
  props: ['data']
}
</script>
