import Layout from './Layout.vue'
import Groups from './Groups.vue'
import Uniques from './Uniques.vue'

export default (meta, header) => {
  return [{
    path: 'analytics',
    name: 'Analytics',
    components: {default: Layout, header},
    meta,
    redirect: '/',
    children: [
      {
        path: 'groups',
        name: 'AnalyticsGroups',
        components: {default: Groups, header},
        meta
      },
      {
        path: 'uniques',
        name: 'AnalyticsUniques',
        components: {default: Uniques, header},
        meta
      }
    ]
  }]
}
