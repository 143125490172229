<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Країни</h4>
            <p class="card-description">Поиск находится под заголовком</p>
            <button
              @click="asyncData()"
              type="button" class="btn btn-outline-success btn-fw mb-2"><i
              class="icon-reload"></i>Обновить
            </button>
            <div class="row mb-2">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <input class="form-control" id="uniques-filter" v-model="filters.groups.unique" placeholder="По унику"/>
              </div>
            </div>
            <div class="row">
              <owner-filter class="col-md-6 col-lg mb-2" v-model="filters.groups.owner" ajax-url="/admin/statistics/groups/owners"/>
              <filters-countries v-model="filters.groups.country" class="col-md-6 col-lg mb-2" ajax-url="/admin/statistics/groups/countries"/>
              <filters-pwas v-model="filters.groups.pwa" class="col-md-6 col-lg mb-2" ajax-url="/admin/statistics/groups/pwas"/>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg mb-2">
                <input class="form-control" id="host-filter" v-model="filters.groups.host" placeholder="По домену"/>
              </div>
              <datepicker
                id="date_begin"
                v-model="filterDateBegin"
                container=".main-panel"
                inputClass="form-control"
                class="col-md-6 col-lg mb-2 in_to_pickerd"
                useEndDate
              />
              <datepicker
                id="date_end"
                v-model="filterDateEnd"
                container=".main-panel"
                inputClass="form-control"
                class="col-md-6 col-lg mb-2 in_to_pickerd"
                useEndDate
              />
            </div>
            <loading v-model="loading"  class="table-responsive">
              <table class="table table-bordered table-hover">
                <admin-table-sort-head :heads="heads" v-model="sort" />
                <tbody>
                <tr v-for="item in data" :key="item.country">
                  <td v-html="$options.filters.formatFlag(item.id)"></td>
                  <td class="text-center">{{ item.uniques }}</td>
                  <td class="text-center">{{ item.hits }}</td>
                  <td class="text-center">{{ item.installs }}</td>
                  <td class="text-center">{{ item.pushes }}</td>
                  <td class="text-center">{{ item.registrations }}</td>
                  <td class="text-center">{{ item.deposits }}</td>
                  <td class="text-center">{{ item.cpi | formatMoney }}</td>
                  <td class="text-center">{{ uniquesToInstalls(item.uniques, item.installs) | percent }}</td>
                  <td class="text-center">{{ uniquesToPush(item.uniques, item.pushes) | percent }}</td>
                  <td class="text-center">{{ installsToPush(item.installs, item.pushes) | percent }}</td>
                  <td class="text-center">{{ item.opens }}</td>
                </tr>
                </tbody>
              </table>
            </loading>
            <nav v-if="pageCount > 1">
              <paginate v-model="page"
                        :page-count="pageCount"
                        :click-handler="handlerPagination"
                        :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                        :next-text="'<i class=\'icon-arrow-right\'></i>'"
                        container-class="pagination mt-3"
                        page-class="page-item"
                        page-link-class="page-link"
                        prev-class="page-item"
                        next-class="page-item"
                        prev-link-class="page-link"
                        next-link-class="page-link"
                        active-class="active"/>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import statisticsMixin from '@/mixins/statistics-mixin'
import pwasMixin from '@/mixins/pwas-mixin'
import * as mixins from '@/pages/statistics/mixins'
import tooltipMixin from '@/mixins/tooltip-mixin'
import OwnerFilter from './components/OwnerFilter.vue'
import Datepicker from '@/components/Datepicker.vue'
import AdminTableSortHead from '@/components/admin/TableSortHead.vue'
import Loading from '@/pages/admin/components/Loading.vue'

export default {
  name: 'AdminStatisticsCountries',
  components: {
    Loading,
    AdminTableSortHead,
    'owner-filter': OwnerFilter,
    Datepicker
  },
  mixins: [statisticsMixin, pwasMixin, mixins.groups, tooltipMixin],
  data: () => {
    return {
      limit: 30,
      page: 1,
      count: 0,
      filters: {
        groups: {
          owner: '',
          unique: '',
          host: ''
        }
      },
      loading: true,
      sort: 'id',
      heads: [
        {label: 'ГЕО', sort: 'id'},
        {label: 'Уники', sort: 'uniques'},
        {label: 'Хосты', sort: 'hits'},
        {label: 'Установки', sort: 'installs'},
        {label: 'Пуш подписки', sort: 'pushes'},
        {label: 'Реги', sort: 'registrations'},
        {label: 'Депы', sort: 'deposits'},
        {label: 'CPI', sort: 'cpi'},
        {label: 'CR% уник/инстал'},
        {label: 'CR% уник/пуш'},
        {label: 'CR% инстал/пуш'},
        {label: 'Входы в PWA', sort: 'opens'}
      ]
    }
  },
  computed: {
    pageCount: function () {
      return Math.ceil(this.count / this.limit)
    },
    filterDateBegin: {
      get () {
        return this.filters.groups.dateBegin
      },
      set (input) {
        // eslint-disable-next-line no-undef
        this.filters.groups.dateBegin = moment(input).format('YYYY-MM-DD')
      }
    },
    filterDateEnd: {
      get () {
        return this.filters.groups.dateEnd
      },
      set (input) {
        // eslint-disable-next-line no-undef
        this.filters.groups.dateEnd = moment(input).format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    asyncData: async function () {
      this.loading = true
      try {
        await this.$store.dispatch('adminStatistics/countries', {
          limit: this.limit,
          page: this.page,
          filter: this.filter,
          sort: this.sort
        })
        const list = this.$store.getters['adminStatistics/countries']
        this.count = list.count
        this.data = list.data
        this.$nextTick(() => {
          this.loading = false
        })
      } catch (error) {
        this.loading = false
        await this.$store.dispatch('alerts/error', error.response.data)
      }
    },
    async handlerPagination (page = 1) {
      this.page = page
      await this.asyncData()
    }
  }
}
</script>
