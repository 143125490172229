import api from '../api'
import {getSearchParams} from '../../list'

export default {
  list: (payload) => {
    return api.get('admin/members?' + getSearchParams(payload))
  },
  update: ({_id, ...payload}) => api.put(`admin/members/${_id}`, payload),
  delete: _id => api.delete(`admin/members/${_id}`)
}
