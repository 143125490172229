<template>
  <tr :data-id="item._id">
    <td><i class="icon-options-vertical"></i></td>
    <td>
      <select class="form-control" v-model="enable">
        <option :key="item" v-for="item in enables" :value="item">
          {{ item | formatBoolean }}
        </option>
      </select>
    </td>
    <td>{{ question }}</td>
    <td v-html="answer.substring(0, 50)"></td>
    <td>{{ createdAt | dateFromNow }}</td>
    <td>{{ updatedAt | dateFromNow }}</td>
    <td>
      <button class="btn btn-icons btn-inverse-primary" @click="handlerEdit"><i class="icon-pencil"></i></button>
      <button class="btn btn-icons btn-inverse-danger" @click="handlerDelete"><i class="icon-trash"></i></button>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'FAQItemField',
  props: ['item', 'faq_id'],
  data: function () {
    return {
      question: '',
      enable: false,
      answer: '',
      createdAt: '',
      updatedAt: '',
      //
      watch: false,
      //
      enables: [true, false]
    }
  },
  created: function () {
    for (const index in this.item) {
      if (this[index] !== undefined) {
        this[index] = this.item[index]
      }
    }
    const decode = document.createElement('textarea')
    decode.innerHTML = this.answer
    this.answer = decode.innerText
    setTimeout(() => {
      this.watch = true
    }, 500)
  },
  watch: {
    enable: {
      handler: async function (enable) {
        if (this.watch) {
          await this.$store.dispatch('adminFAQItems/update', {_id: this.faq_id, item_id: this.item._id, enable})
          await this.$store.dispatch('alerts/success', 'Видимость обновлена')
        }
      }
    }
  },
  methods: {
    handlerDelete: async function () {
      if (confirm('Удалить запись?')) {
        await this.$store.dispatch('adminFAQItems/delete', {_id: this.faq_id, item_id: this.item._id})
        await this.$parent.getData()
        await this.$store.dispatch('alerts/success', 'запись удалён')
      }
    },
    handlerEdit: function () {
      this.$parent.$refs.form.enable = this.enable
      this.$parent.$refs.form.question = this.question
      this.$parent.$refs.form.answer = this.answer
      this.$parent.$refs.form.id = this.item._id
      // eslint-disable-next-line no-undef
      jQuery('#faq-item-modal').modal('show')
      // eslint-disable-next-line no-undef
      jQuery('#answer').summernote('code', this.answer)
    }
  }
}
</script>
