export default {
  navigation: 'Domain',
  title: 'PWA Domain Setup #{id}',
  settingTitle: 'To work with the system, the domain must be linked to CloudFlare and have the following settings',
  settings: [
    'Specify at Cloudflare NS registrar',
    'Specify A-records in domain settings'
  ],
  change: 'Change',
  warningCloudflare: 'Domain must be linked to <a href="https://www.cloudflare.com/" target="_blank" class="link_green">CloudFlare</a> to work with the system. If NS records are not displayed or PWA does not work correctly, try restarting the process using the button ',
  getNS: 'Get NS',
  noCloudflare: 'Domain is not wrapped in <a href="https://www.cloudflare.com/" target="_blank">CloudFlare</a>, you won\'t be able to use apps without this procedure',
  checkInfo: 'After changing the domain, it will need to be rechecked',
  ns: {
    success: 'Request to get NS records successful',
    failed: 'Request to get NS records failed. Contact support'
  }
}
