<template>
  <div class="Preview preview-container" :class="{'dark': dark}">
    <div class="preview">
      <iframe
        v-if="previewURL"
        :src="
          previewURL.search('\\?') === -1
            ? previewURL + '?t=' + new Date().getTime()
            : previewURL
        "
        frameborder="0"
        class="w-100"
        id="preview"
        name="preview"
      ></iframe>
      <slot v-else></slot>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'Preview',
  props: {
    id: {
      type: String
    },
    previewURL: {
      type: String
    },
    dark: {
      type: Boolean
    }
  },
  created () {
    this.updatePreview = _.debounce(this.updatePreview, 500)
  },
  methods: {
    togglePreview: function () {
      (function ($) {
        'use strict'
        $('[data-show-preview]').on('click', function () {
          $('.preview_this_blk').addClass('active')
        })
        $('[data-hide-preview]').on('click', function () {
          $('.preview_this_blk').removeClass('active')
        })
        // eslint-disable-next-line no-undef
      })(jQuery)
    },
    updatePreview: function (param = { t: new Date().getTime() }) {
      const form = document.createElement('form')
      const previewContainer = document.querySelector('.preview-container')
      if (!previewContainer) return

      form.action = this.previewURL
      form.method = 'post'
      form.target = 'preview'
      form.id = 'preview-form'
      for (const [key, value] of Object.entries(param)) {
        if (key === 'comments') {
          if (value) {
            for (const [k, v] of Object.entries(value)) {
              const input = document.createElement('textarea')
              input.value = JSON.stringify(v)
              input.name = `comments[${k}]`
              form.appendChild(input)
            }
          }
        } else {
          const input = document.createElement('textarea')
          input.value = value
          input.name = key
          form.appendChild(input)
        }
      }
      document.body.appendChild(form)
      form.submit()
      document.getElementById('preview-form').remove()
    }
  },
  mounted: function () {
    this.togglePreview()
  }
}
</script>

<style lang="scss" scoped>
.preview-container {
  background: url(/images/Iphone.png) no-repeat;
  background-size: contain;
  width: 412px;
  height: 816px;
  padding: 26px 22px 23px 28px;
  filter: drop-shadow(0px 35px 46px rgba(33, 191, 115, 0.15));
  background-size: 100%;
  margin: 0 auto;

  &.dark {
    background: url(/images/Iphone_black.png) no-repeat;
    background-size: contain;
  }

  .preview {
    background: transparent;
    border-radius: 26px;
    overflow: hidden;
    height: 100%;
  }
  .preview iframe {
    height: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .preview-container {
    position: sticky;
    top: 5px;
  }
}

@media only screen and (max-width: 991px) {
  .preview:not(.landing) {
    max-width: 369px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 575px) {
  .preview-container :not(.landing) {
    filter: none;
  }
}
</style>
