export default {
  mirrorsList: state => state.mirrorsList,
  mirrorsListCount: state => state.mirrorsListCount,
  mirrorsListLoading: state => state.mirrorsListLoading,
  mirrorsListLoaded: state => state.mirrorsListLoaded,
  mirrorsListTrash: state => state.mirrorsListTrash,
  mirrorsListEmpty: (state, getter) => !getter.mirrorsList.length && getter.mirrorsListLoaded && !getter.mirrorsListLoading,
  activeMirror: state => state.activeMirror || {},
  mirrorsTrashList: state => state.mirrorsTrashList,
  mirrorsTrashListCount: state => state.mirrorsTrashListCount,
  mirrorsTrashListLoading: state => state.mirrorsTrashListLoading,
  mirrorsTrashListLoaded: state => state.mirrorsTrashListLoaded,
  mirrorsTrashListEmpty: (state, getter) => !getter.mirrorsTrashList.length && getter.mirrorsTrashListLoaded && !getter.mirrorsTrashListLoading
}
