<template>
  <td>{{ uniquesToPush(value.uniques, value.pushes) | percent }}</td>
</template>
<script>
import statisticsMixin from '@/mixins/statistics-mixin'

export default {
  name: 'UniquesToPush',
  mixins: [statisticsMixin],
  props: ['value']
}
</script>
