export default {
  invalid: 'Недійсне значення',
  domain: {
    wrongFormat: 'Неправильний формат домену або доменної зони'
  },
  image: {
    maxSize: 'Розмір файлу не повинен перевищувати 2 МБ.',
    required: "Це зображення обов'язкове"
  },
  accessDenied: 'У вас немає доступу зверніться до вашого адміна',
  dateOutOfRange: 'Вибрана дата за межами дозволеного місяця. Оберіть дату в межах місяця від початкової.',
  dashboardAccessDenied: 'Доступ до цих даних обмежено для вашого облікового запису',
  requestError: 'Наразі не вдалося завантажити дані для відображення',
  requestError504: 'Щось пішло не так, зверніться до адміністратора!'
}
