import service from '@/store/services/admin/faq-items-service'
import middleware from '../middleware'

const state = {
  items: [],
  faq: {},
  item: {}
}

const mutations = {
  setItems: (state, payload) => {
    state.items = payload
  },
  setItem: (state, payload) => {
    state.item = payload
  },
  setFAQ: (state, payload) => {
    state.faq = payload
  }
}

const actions = {
  index: ({commit, dispatch}, params) => service.index(params)
    .then(res => {
      commit('setItems', res.items)
      commit('setFAQ', res.faq)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  sort: ({commit, dispatch}, params) => service.sort(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  create: ({commit, dispatch}, params) => service.create(params)
    .then(res => {
      commit('setItem', res)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  update: ({commit, dispatch}, params) => service.update(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  delete: ({commit, dispatch}, params) => service.delete(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  items: state => state.items,
  item: state => state.item,
  faq: state => state.faq
}

const adminFAQItems = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminFAQItems
