import service from '@/store/services/admin/billing-service'
import middleware from '../middleware'

const state = {
  paymentMethods: [],
  models: [],
  count: 0,
  total: 0,
  btc: {},
  countMonth: 0,
  countPaid: 0,
  monthAmount: 0
}

const mutations = {
  setPageData (state, payload) {
    state.models = payload.data
    state.count = payload.count
    state.total = payload.total
    state.countMonth = payload.countMonth
    state.countPaid = payload.countPaid
    state.monthAmount = payload.monthAmount
    state.paymentMethods = payload.paymentMethods
  },

  setBTC: (state, payload) => {
    payload.verificationprogress = String(Math.floor(payload.verificationprogress * 100)) + ' %'
    payload.txcount = Number(payload.txcount)
    payload.balance = Number(payload.balance)
    payload.immature_balance = Number(payload.immature_balance)
    state.btc = payload
  }
}

const actions = {

  btc: ({commit, dispatch}, params) => {
    return service.btc(params)
      .then(response => {
        commit('setBTC', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  fetchData ({commit, dispatch}, params) {
    return service.index(params)
      .then(response => {
        commit('setPageData', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  reject: ({commit, dispatch}, params) => {
    return service.reject(params)
      .then()
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  approve: ({commit, dispatch}, params) => {
    return service.approve(params)
      .then()
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => state.models,
  count: state => Number(state.count),
  total: state => Number(state.total),
  btc: state => state.btc,
  countMonth: state => state.countMonth,
  countPaid: state => state.countPaid,
  monthAmount: state => state.monthAmount,
  paymentMethods: state => state.paymentMethods
}

const adminBilling = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminBilling
