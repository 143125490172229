export default {
  home: 'Home',
  statistics: 'Statistics',
  trackers: 'Trackers',
  bills: 'Finances',
  tariffs: 'Tariffs',
  referrals: 'Ref. program',
  teams: 'Teams',
  templates: 'Templates',
  landing: 'Landing',
  mirrors: 'Mirrors',
  vacancies: 'Vacancies'
}
