import {useStore} from '@/store'

export default function change ({next}) {
  const store = useStore()
  const handlerConfirm = store.getters['autoSave/handlerConfirm']
  if (handlerConfirm) {
    const refModal = store.getters['autoSave/refModal']
    refModal.confirm(handlerConfirm).then(async () => {
      await store.dispatch('autoSave/beenChanges')
      next()
    })
  } else {
    next()
  }
}
