<template>
  <modal
    ref="modal"
    id="admin-user-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Редактирования {{ username }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
          <div class="form-group" :class="apiValidationErrors.permissionLevel ? 'has-danger' : ''">
            <label for="user-form-permissionLevel">Права доступа</label>
            <select class="form-control" id="user-form-permissionLevel" name="user-form-permissionLevel" type="text"
                    v-model="permissionLevel"
                    :class="apiValidationErrors.permissionLevel ? 'form-control-danger' : ''"
                    @focus="clearError('permissionLevel')"
                    autofocus>
              <option v-bind:value="id" v-for="(label, id) in this.$store.getters['adminUsers/permissionLevel']"
                      v-bind:key="id">{{ label }}
              </option>
            </select>
            <label v-if="apiValidationErrors.permissionLevel" class="error mt-2 text-danger"
                   for="user-form-permissionLevel">{{ apiValidationErrors.permissionLevel[0] }}</label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
          <div class="form-group" :class="apiValidationErrors['api.enable'] ? 'has-danger' : ''">
            <label for="user-form-permissionLevel">API</label>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" id="api.enable" name="api.enable"
                       v-model="api.enable">
                api {{ api.enable ? 'активно' : 'не активно' }}
                <i class="input-helper"></i>
              </label>
            </div>
            <label v-if="apiValidationErrors['api.enable']" id="api.enable-error" class="error mt-2 text-danger"
                   for="api.enable">{{ apiValidationErrors['api.enable'][0] }}</label>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
          <div class="form-group" :class="apiValidationErrors['api.key'] ? 'has-danger' : ''">
            <label for="api.key">ключ api</label>
            <div class="input-group">
              <input class="form-control api-key-input" id="api-key" name="api.key" type="text"
                     v-model="api.key"
                     :class="apiValidationErrors['api.key'] ? 'form-control-danger' : ''"
                     placeholder="сгенерируйте ключ api"
                     @focus="clearError('api.key')" autofocus>
              <div class="input-group-append">
                <button class="btn btn-sm btn-primary" type="button" @click="handlerGenerateAPIKey">сгенирить</button>
                <button type="button" class="btn btn-info btn-clipboard" data-clipboard-action="copy"
                        data-clipboard-target="#api-key">копировать
                </button>
              </div>
            </div>
            <label v-if="apiValidationErrors['api.key']" id="api.key-error" class="error mt-2 text-danger"
                   for="api.key">{{ apiValidationErrors['api.key'][0] }}</label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
          <div class="form-group" :class="apiValidationErrors['settings.pwas.cost.domain'] ? 'has-danger' : ''">
            <label for="user-form-settings-pwas-cost-domain">индивидуальная стоимость домена</label>
            <div class="input-group">
              <input class="form-control" id="user-form-settings-pwas-cost-domain"
                     name="user-form-settings-pwas-cost-domain" type="number" step="1"
                     v-model="settings.pwas.cost.domain"
                     :class="apiValidationErrors['settings.pwas.cost.domain'] ? 'form-control-danger' : ''"
                     placeholder="введите стоимость домена"
                     @focus="clearError('settings.pwas.cost.domain')" autofocus>
            </div>
            <label v-if="apiValidationErrors['settings.pwas.cost.domain']" class="error mt-2 text-danger"
                   for="user-form-settings-pwas-cost-domain ">{{
                apiValidationErrors['settings.pwas.cost.domain'][0]
              }}</label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
          <div class="form-group" :class="apiValidationErrors['settings.pwas.cost.activation'] ? 'has-danger' : ''">
            <label for="user-form-settings-pwas-cost-activation">индивидуальная стоимость активации pwa</label>
            <div class="input-group">
              <input class="form-control" id="user-form-settings-pwas-cost-activation"
                     name="user-form-settings-pwas-cost-activation" type="number" step="1"
                     v-model="settings.pwas.cost.activation"
                     :class="apiValidationErrors['settings.pwas.cost.activation'] ? 'form-control-danger' : ''"
                     placeholder="введите стоимость активации pwa"
                     @focus="clearError('settings.pwas.cost.activation')" autofocus>
            </div>
            <label v-if="apiValidationErrors['settings.pwas.cost.activation']" class="error mt-2 text-danger"
                   for="user-form-settings-pwas-cost-activation ">{{
                apiValidationErrors['settings.pwas.cost.activation'][0]
              }}</label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
          <div class="form-group" :class="apiValidationErrors['settings.team.enable'] ? 'has-danger' : ''">
            <div class="form-check">
              <label id="user-form-settings-team-enable" class="form-check-label">
                <input type="checkbox" class="form-check-input" id="user-form-settings-team-enable"
                       name="user-form-settings-team-enable"
                       v-model="settings.team.enable">
                командная работа
                <i class="input-helper"></i>
              </label>
            </div>
            <label v-if="apiValidationErrors['settings.team.enable']" class="error mt-2 text-danger"
                   for="user-form-settings-team-enable">{{ apiValidationErrors['settings.team.enable'][0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors['settings.installing.enable'] ? 'has-danger' : ''">
            <div class="form-check">
              <label for="user-form-settings-installing-enable" class="form-check-label">
                <input type="checkbox" class="form-check-input" id="user-form-settings-installing-enable"
                       name="user-form-settings-installing-enable"
                       v-model="settings.installing.enable">
                лить за установки
                <i class="input-helper"></i>
              </label>
            </div>
            <label v-if="apiValidationErrors['settings.installing.enable']" class="error mt-2 text-danger"
                   for="user-form-settings-installing-enable">{{
                apiValidationErrors['settings.installing.enable'][0]
              }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors['settings.pwas.customJs'] ? 'has-danger' : ''">
            <div class="form-check">
              <label for="user-form-settings-pwas-customJs" class="form-check-label">
                <input v-model="settings.pwas.customJs"
                       id="user-form-settings-pwas-customJs"
                       name="user-form-settings-pwas-customJs"
                       type="checkbox"
                       class="form-check-input"
                >
                разрешить свой js на pwa
                <i class="input-helper"></i>
              </label>
            </div>
            <label
              v-if="apiValidationErrors['settings.pwas.customJs']"
              class="error mt-2 text-danger"
              for="user-form-settings-pwas-customJs"
            >{{ apiValidationErrors['settings.pwas.customJs'][0] }}</label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
          <div class="form-group" :class="apiValidationErrors['settings.trackers.bypass.enable'] ? 'has-danger' : ''">
            <div class="form-check">
              <label for="user-form-settings-trackers-bypass-enable" class="form-check-label">
                <input v-model="settings.trackers.bypass.enable"
                       id="user-form-settings-trackers-bypass-enable"
                       name="user-form-settings-trackers-bypass-enable"
                       type="checkbox"
                       class="form-check-input"
                >
                Дозволяти Bypass в теркері
                <i class="input-helper"></i>
              </label>
            </div>
            <label
              v-if="apiValidationErrors['settings.trackers.bypass.enable']"
              class="error mt-2 text-danger"
              for="user-form-settings-trackers-bypass-enable"
            >{{ apiValidationErrors['settings.trackers.bypass.enable'][0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors['settings.pushes.enable'] ? 'has-danger' : ''">
            <div class="form-check">
              <label for="user-form-settings-pushes-enable" class="form-check-label">
                <input v-model="settings.pushes.enable"
                       name="user-form-settings-pushes-enable"
                       id="user-form-settings-pushes-enable"
                       type="checkbox"
                       class="form-check-input">
                разрешить наши push
                <i class="input-helper"></i>
              </label>
            </div>
            <label v-if="apiValidationErrors['settings.pushes.enable']"
                   for="user-form-settings-pushes-enable"
                   class="error mt-2 text-danger">{{ apiValidationErrors['settings.pushes.enable'][0] }}</label>
          </div>
          <div
            :class="apiValidationErrors['settings.pwas.onlySelfDomains'] ? 'has-danger' : ''"
            class="form-group">
            <div class="form-check">
              <label for="user-form-settings-pwas-onlySelfDomains" class="form-check-label">
                <input type="checkbox" class="form-check-input" id="user-form-settings-pwas-onlySelfDomains"
                       name="user-form-settings-pwas-onlySelfDomains"
                       v-model="settings.pwas.onlySelfDomains">
                только свои домены
                <i class="input-helper"></i>
              </label>
            </div>
            <label v-if="apiValidationErrors['settings.pwas.onlySelfDomains']" class="error mt-2 text-danger"
                   for="user-form-settings-pwas-onlySelfDomains">{{
                apiValidationErrors['settings.pwas.onlySelfDomains'][0]
              }}</label>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
          <select2
            v-model="settings.pwas.customIp"
            :options="ipsOptions"
            :settings="{allowClear: true}"
            class="form-group custom-ip-select"
            id="user-form-settings-pwas-customIp"
            name="user-form-settings-pwas-customIp"
            inputClass="form-control"
          >
            <template v-slot:before>
              <label for="user-form-settings-pwas-customIp">Індевідуальний ip</label>
            </template>
          </select2>
        </div>
      </div>
      <hr>
      <h2 class="h5">Метрика</h2>
      <div class="mt-4 form-group" :class="apiValidationErrors['metrics.manual'] ? 'has-danger' : ''">
        <label for="user-form-metrics-manual">Ручна помітка</label>
        <input class="form-control" id="user-form-metrics-manual" name="user-form-metrics-manual"
               type="text"
               v-model="metrics.manual"
               :class="apiValidationErrors['metrics.manual'] ? 'form-control-danger' : ''"
               placeholder="Заповніть ручна помітка"
               @focus="clearError('metrics.manual')" autofocus>
        <label v-if="apiValidationErrors['metrics.manual']" class="error mt-2 text-danger"
               for="user-form-metrics-manual">{{ apiValidationErrors['metrics.manual'][0] }}</label>
        <small class="form-text text-muted">За її допомагою можна буде фільтрувати рахунки, транзакції та користувачів</small>
      </div>
      <hr>
      <h2 class="h5">Privacy Policy</h2>
      <div
        :class="apiValidationErrors['settings.privacyPolicy.notUsePublicPersonInAds'] ? 'has-danger' : ''"
        class="form-group">
        <div class="form-check">
          <label for="user-form-settings-privacyPolicy-notUsePublicPersonInAds" class="form-check-label">
            <input type="checkbox" class="form-check-input" id="user-form-settings-privacyPolicy-notUsePublicPersonInAds"
                   name="user-form-settings-privacyPolicy-notUsePublicPersonInAds"
                   v-model="settings.privacyPolicy.notUsePublicPersonInAds">
            Not Use Public Person In Ads
            <i class="input-helper"></i>
          </label>
        </div>
        <label v-if="apiValidationErrors['settings.privacyPolicy.notUsePublicPersonInAds']" class="error mt-2 text-danger"
               for="user-form-settings-privacyPolicy-notUsePublicPersonInAds">{{
            apiValidationErrors['settings.privacyPolicy.notUsePublicPersonInAds'][0]
          }}</label>
      </div>
      <hr>
      <h2 class="h5">Кредитний ліміт</h2>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
          <div class="form-group mt-1" :class="apiValidationErrors['settings.installing.credit'] ? 'has-danger' : ''">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <div class="input-group-prepend">
                <span class="input-group-text">0.00</span>
              </div>
              <input type="number" step="0.01" id="amount" placeholder="0" v-model="settings.installing.credit"
                     class="form-control" min="1" max="10000"
                     :class="apiValidationErrors['settings.installing.credit'] ? 'form-control-danger' : ''"
                     @focus="clearError('settings.installing.credit')">
            </div>
            <label v-if="apiValidationErrors['settings.installing.credit']" class="error mt-2 text-danger" for="amount">
              {{ apiValidationErrors['settings.installing.credit'] }}
            </label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
          <div class="form-group">
            <div class="form-check">
              <label class="form-check-label" for="enablePwas">
                <input
                  v-model="enablePwas"
                  id="enablePwas"
                  type="checkbox"
                  class="form-check-input">
                Активувати технічно зупинені PWA
                <i class="input-helper"></i>
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import {mapGetters} from 'vuex'
import Select2 from '@/components/Select2.vue'

const defaultValues = () => ({
  permissionLevel: 1,
  api: {enable: false, key: null},
  settings: {
    team: {enable: false},
    pwas: {
      cost: {domain: null, activation: null},
      customJs: false,
      onlySelfDomains: false,
      customIp: null
    },
    trackers: {
      bypass: {
        enable: false
      }
    },
    installing: {enable: false, credit: 0},
    pushes: {enable: false},
    privacyPolicy: {
      notUsePublicPersonInAds: false
    }
  },
  metrics: {
    manual: null
  },
  credit: 0,
  enablePwas: false
})

export default {
  name: 'AdminUserForm',
  components: {SubmitModalForm, Modal, Select2},
  mixins: [formMixin],
  data () {
    return {
      ...defaultValues(),
      id: null,
      processing: false,
      username: '',
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    ...mapGetters({
      ipsOptions: 'ips/options'
    })
  },
  watch: {
    'installing.enable': function (n) {
      this.settings.team.enable = n
    }
  },
  methods: {
    async handlerUpdate ({
      _id,
      permissionLevel,
      api,
      teamEnable,
      pwaDomainCost,
      pwaActivationCost,
      username,
      installing,
      pushes,
      onlySelfDomains,
      customIp,
      customJs,
      metrics,
      settings
    }) {
      this.skip()
      this.id = _id
      this.username = username
      this.permissionLevel = permissionLevel
      this.api = api || {enable: false, key: null}
      this.settings = {
        team: {enable: teamEnable || false},
        pwas: {
          cost: {domain: pwaDomainCost || null, activation: pwaActivationCost || null},
          customJs: customJs || false,
          onlySelfDomains: onlySelfDomains || false,
          customIp: customIp || null
        },
        installing: {enable: installing || false, credit: 0},
        pushes: {enable: pushes || false},
        trackers: {
          bypass: {
            enable: false
          }
        },
        privacyPolicy: {
          notUsePublicPersonInAds: false
        }
      }
      if (settings != null && settings?.trackers != null) {
        this.settings.trackers = settings.trackers
      }
      if (settings != null && settings?.privacyPolicy != null) {
        this.settings.privacyPolicy = settings.privacyPolicy
      }
      if (settings != null && settings?.installing?.credit != null) {
        this.settings.installing.credit = settings?.installing.credit
      }
      // this.settings.privacyPolicy = settings.privacyPolicy
      this.metrics = metrics || {manual: null}
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.$store.dispatch('adminUsers/update', {
          id: this.id,
          permissionLevel: this.permissionLevel,
          api: this.api,
          settings: this.settings,
          metrics: this.metrics,
          enablePwas: this.enablePwas
        })
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    handlerGenerateAPIKey () {
      let key = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 16; i++) {
        key += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      this.api.key = key
    }
  }
}
</script>
<style scoped>
.api-key-input {
  height: 41px;
}
:deep(.modal-dialog) {
  max-width: 800px;
}
.custom-ip-select :deep(.select2-selection) {
  height: 32px;
}
</style>
