export default {
  subscribe: 'Підписка до тарифу "{name}"',
  contains: 'Тариф "{name}" містить у собі:',
  pwas: '0 активацій | Одна активація |{num} активації | {num} активацій',
  limit: '{num} активних PWA',
  month: 'Місячна підписка',
  onDays: 'Підписка на {num} днів',
  forStart: 'Для Старту',
  pay: 'Оплатити',
  messages: [
    'Під активацією мається на увазі переведення валідного PWA зі статусу <span class="color_red_go">"Не оплачено"</span> у <span class="color_green_go">"Працює"</span>',
    'Під кількість активних PWA мається на увазі та кількість PWA які мають статус "Працює"'
  ],
  promoCode: 'Промокод',
  amount: 'Сума до оплати',
  success: 'Ви підписалися на новий тариф',
  voucherRequired: 'Введіть промокод',
  beforeDate: 'Інформація про тариф до {date}',
  contained: 'Тариф "{name}" містив у собі:',
  paid: 'Ви заплатили:',
  info: 'Інформація',
  your: 'Ваш тариф',
  active: '{used} з {pwa} PWA запущено',
  activations: 'Активацій доступно: {num}',
  expired: '0 | Один день до кінця передплати | {num} дні до кінця підписки | {num} днів до кінця підписки',
  upCost: 'Перейти за {num} $',
  auto: 'Автопідписка активна',
  noTariffs: 'Даний Тарифний ряд у розробці',
  individual: 'Індивідуальний',
  command: 'Командний',
  commercial: 'Комерційний',
  history: 'Історія',
  historyTitle: 'Історія тарифів',
  additionalServices: 'Додаткові сервіси',
  installations: {
    tier1: 'Регіон 1',
    tier2: 'Регіон 2',
    tier3: 'Регіон 3',
    title: 'Тарифікація за інстали',
    header: 'Тарифікація за один інстал PWA',
    text: 'Якщо у вас виникли запитання або непорозуміння щодо тарифікації зверніться в <a href="https://t.me/{tgHelpdesk}" target="_blank">службу підтримки</a> клієнтів'
  }
}
