<template>
  <div class="item_toggsl_g">
    <div class="head_tit_sl_togg">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ $t('pwas.components.app.install.title') }}</span>
        <info :message="$t('pwas.components.app.install.titleInfo')"/>
      </div>
    </div>
    <div class="content_dropd_g">
      <div class="row row_mob_sg">
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.install ? 'has-danger' : ''">
          <label for="install">{{ $t('pwas.components.app.install.install') }}
            <info :message="$t('pwas.components.app.install.installInfo')"/>
          </label>
          <input type="text" id="install" :placeholder="$t('pwas.components.app.install.install')"
                 v-model="value.languages[lang].install" class="form-control"
                 :class="apiValidationErrors.install ? 'form-control-danger' : ''"
                 @focus="clearError('install')" v-if="lang">
          <input type="text" id="install" :placeholder="$t('pwas.components.app.install.install')"
                 v-model="value.install"
                 class="form-control"
                 :class="apiValidationErrors.install ? 'form-control-danger' : ''"
                 @focus="clearError('install')" v-else>
          <label v-if="apiValidationErrors.install" class="error mt-2 text-danger" for="install">
            {{ apiValidationErrors.install[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.installing ? 'has-danger' : ''">
          <label for="installing">{{ $t('pwas.components.app.install.installing') }}
            <info :message="$t('pwas.components.app.install.installingInfo')"/>
          </label>
          <input type="text" id="installing" :placeholder="$t('pwas.components.app.install.installing')"
                 v-model="value.languages[lang].installing" class="form-control"
                 :class="apiValidationErrors.installing ? 'form-control-danger' : ''"
                 @focus="clearError('installing')" v-if="lang">
          <input type="text" id="installing" :placeholder="$t('pwas.components.app.install.installing')"
                 v-model="value.installing"
                 class="form-control"
                 :class="apiValidationErrors.installing ? 'form-control-danger' : ''"
                 @focus="clearError('installing')" v-else>
          <label v-if="apiValidationErrors.installing" class="error mt-2 text-danger" for="installing">
            {{ apiValidationErrors.installing[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob"
             :class="apiValidationErrors.download ? 'has-danger' : ''">
          <label for="download">{{ $t('pwas.components.app.install.download') }}
            <info :message="$t('pwas.components.app.install.downloadInfo')"/>
          </label>
          <input type="text" id="download" :placeholder="$t('pwas.components.app.install.download')"
                 v-model="value.languages[lang].download" class="form-control"
                 :class="apiValidationErrors.download ? 'form-control-danger' : ''"
                 @focus="clearError('download')" v-if="lang">
          <input type="text" id="download" :placeholder="$t('pwas.components.app.install.download')"
                 v-model="value.download"
                 class="form-control"
                 :class="apiValidationErrors.download ? 'form-control-danger' : ''"
                 @focus="clearError('download')" v-else>
          <label v-if="apiValidationErrors.download" class="error mt-2 text-danger" for="download">
            {{ apiValidationErrors.download[0] }}
          </label>
        </div>
        <div class="form-group col-md-6 col-xl-3 col_half_mob" :class="apiValidationErrors.open ? 'has-danger' : ''">
          <label for="open">{{ $t('pwas.components.app.install.open') }}
            <info :message="$t('pwas.components.app.install.openInfo')"/>
          </label>
          <input type="text" id="open" :placeholder="$t('pwas.components.app.install.open')"
                 v-model="value.languages[lang].open" class="form-control"
                 :class="apiValidationErrors.open ? 'form-control-danger' : ''"
                 @focus="clearError('open')" v-if="lang">
          <input type="text" id="open" :placeholder="$t('pwas.components.app.install.open')"
                 v-model="value.open" class="form-control"
                 :class="apiValidationErrors.open ? 'form-control-danger' : ''"
                 @focus="clearError('open')" v-else>
          <label v-if="apiValidationErrors.open" class="error mt-2 text-danger" for="open">
            {{ apiValidationErrors.open[0] }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'

export default {
  name: 'ButtonInstallApp',
  mixins: [formMixin],
  components: {Info},
  props: {
    value: {
      type: Object,
      require: true
    },
    errors: {
      type: Object,
      require: true
    }
  },
  computed: {
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  }
}
</script>
