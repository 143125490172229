<template>
  <thead>
  <tr>
    <th
      v-for="(item, index) of heads"
      :key="index"
      @click="handler(item.sort)"
      :class="getClass(item)"
      :style="item.style"
    >
      {{ !item.param ? $t(item.label) : item.param }}
    </th>
  </tr>
  </thead>
</template>
<script>
export default {
  name: 'AdminTableSortHead',
  props: ['heads', 'value'],
  computed: {
    type () {
      return this.value.toString().search('-') === -1
    },
    current () {
      return this.type ? this.value : this.value.substring(1)
    }
  },
  methods: {
    handler (sort) {
      if (sort) {
        if (this.current === sort) {
          this.$emit('input', this.type ? `-${sort}` : sort)
        } else {
          this.$emit('input', `-${sort}`)
        }
      }
    },
    getClass (item) {
      if (item.sort == null) return ''
      if (item.sort !== this.current) return 'sorting'
      if (item.sort === this.current && this.type) return 'sorting_asc'
      if (item.sort === this.current && !this.type) return 'sorting_desc'
    }
  }
}
</script>
