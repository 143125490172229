<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Методы оплаты</h4>
      <p class="card-description">То что будет видеть пользователь в модалке</p>
      <div class="mb-2">
        <button type="button" class="btn btn-outline-success btn-fw" @click="handlerCreate"><i
          class="icon-plus"></i> Добавить
        </button>
      </div>
      <payment-method-form ref="form"/>
      <div class="table-responsive">
        <table class="table table-bordered table-hover">
          <thead>
          <tr>
            <th></th>
            <th>
              Картинка
            </th>
            <th>
              Вкл./Выкл.
            </th>
            <th>
              Названия
            </th>
            <th style="min-width: 150px;">
              Шлюз
            </th>
            <th>Создан</th>
            <th>Обновлен</th>
            <th></th>
          </tr>
          </thead>
          <tbody id="dragula">
          <payment-method-field v-bind:item="item" v-for="item in paymentMethods" v-bind:key="item._id"/>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentMethodForm from './PaymentMethodForm.vue'
import PaymentMethodField from './PaymentMethodField.vue'

export default {
  name: 'PaymentMethods',
  components: {PaymentMethodForm, PaymentMethodField},
  data: function () {
    return {
      paymentMethods: []
    }
  },
  created: async function () {
    await this.$store.dispatch('adminGateways/list')
    await this.getData()
  },
  mounted: function () {
    const self = this
    // eslint-disable-next-line no-undef
    dragula([document.getElementById('dragula')])
      .on('drop', async function () {
        const rows = document.querySelectorAll('tr[data-id]')
        let ids = []
        for (const row of rows) {
          if (row.className !== 'gu-mirror') {
            ids.push(row.getAttribute('data-id'))
          }
        }
        await self.$store.dispatch('adminPaymentMethods/sort', {ids})
      })
  },
  methods: {
    handlerCreate: async function () {
      await this.$store.dispatch('adminGateways/list')
      this.$refs.form.enable = false
      this.$refs.form.name = ''
      this.$refs.form.image = ''
      this.$refs.form.gateway = ''
      this.$refs.form.id = false
      // eslint-disable-next-line no-undef
      jQuery('#payment-method-modal').modal('show')
    },
    getData: async function () {
      this.paymentMethods = []
      await this.$store.dispatch('adminPaymentMethods/index')
      this.paymentMethods = this.$store.getters['adminPaymentMethods/paymentMethods']
    }
  }
}
</script>
