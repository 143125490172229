<template>
  <div class="MirrorsTable mirrors-table table-responsive">
    <table class="table table-hover">
      <thead>
      <tr>
        <!-- <th>
          <div class="form-check form-check-primary mb-0 mt-1">
            <label class="form-check-label ml-0">
              <input type="checkbox" class="form-check-input" v-model="checkAll" id="checkAll">
              <green-checkbox-icon :isChecked="checkAll" style="margin-top: 3px;"/>
            </label>
          </div>
        </th> -->
        <th>
          {{ $t('mirrors.mirrorName') }}
          <info :message="$t('mirrors.mirrorTitle')"/>
        </th>
        <th v-if="!trash">
          {{ $t('mirrors.status') }}
          <info :message="$t('mirrors.statusTitle')"/>
        </th>
        <th>
          {{ $t('mirrors.pwaName') }}
          <info :message="$t('mirrors.pwaTitle')"/>
        </th>
        <th>
          {{ $t('mirrors.headers.domain') }}
          <info :message="$t('mirrors.headers.domainInfo')"/>
        </th>
        <th>
          {{ $t('mirrors.tags') }}
          <info :message="$t('mirrors.tagsTitle')"/>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
        <slot />
      </tbody>
    </table>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import GreenCheckboxIcon from '@/components/GreenCheckboxIcon.vue'
import tooltipMixin from '@/mixins/tooltip-mixin'

export default {
  name: 'MirrorsTable',
  components: { GreenCheckboxIcon, Info },
  mixins: [tooltipMixin],
  props: {
    trash: Boolean
  },
  data () {
    return {
      checkAll: false
    }
  },
  created () {
    this.initTooltip()
  }
}
</script>
<style scoped lang="sass">
.mirrors-table
  min-height: 300px
  .table
    min-height: auto
</style>
