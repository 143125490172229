<template>
  <div class="PageMirrors page-mirrors page-full-fluid-width parent_tooltip">
    <div class="page-mirrors__wrapper">
      <h1 class="page-mirrors__title">
        {{ $t('mirrors.title') }}
        <router-link
          v-if="mirrorsListLoaded"
          :to="{ name: 'MirrorsTrash' }"
          class="simple_butt_afg page-mirrors__title-link">({{
            $tc('titles.mirrors.trashBadge', trash, {count: trash})
          }})
        </router-link>
      </h1>
      <div class="page-mirrors__filters">
        <Filters :model="filter" @change="onFilterChange"/>
        <button
          class="btn_green_g mobile-full-w page-mirrors__filters-button"
          @click="onMirrorAdd"
        >
          {{ $t('mirrors.add') }}
        </button>
      </div>
      <hr class="line-hr page-mirrors__line">
      <div class="page-mirrors__table">
        <Loading v-model="mirrorsListLoading">
          <MirrorsTable
            v-if="!mirrorsListEmpty"
          >
            <MirrorsTableItem
              :trash="false"
              v-for="(item, i) in mirrorsList"
              :key="`${item.id}-${i}`"
              :data="item"
              :user="user"
              :accesses="accesses"
              @tag-click="onTagClick"
              @edit="onMirrorEdit"
              @delete="onMirrorMoveToTrash"
              @play="onMirrorPlay"
              @stop="onMirrorStop"
              @add-custom-script="onMirrorCustomScript"
              @add-meta="onMirrorAddMeta"
              @download-js="onMirrorDownloadJs"
              @statistics="onStatisticsClick"
            />
          </MirrorsTable>
          <EmptyPage v-if="mirrorsListEmpty">
            <template #title v-if="isEmptyFilter">{{ $t('mirrors.noHave') }}</template>
            <template #title v-else>{{ $t('mirrors.notFoundByFilters') }}</template>
          </EmptyPage>
        </Loading>
      </div>
    </div>
    <div class="page-mirrors__pagination">
      <NavPaginate
        v-model="page"
        :inputPage="+inputPage"
        :pageCount="pageCount"
        :limit="+limit"
        @limit="limit=$event"
        @changePage="debounceChangePage"
        @pagination="handlerPagination"
        @inputPage="inputPage=$event"
        :count="mirrorsListCount"
      >
      </NavPaginate>
    </div>
    <ModalAddDomainMirror
      @created="fetchPage"
      @rebuild="fetchPage"
      ref="ModalAddDomainMirror"
    />
    <ConfirmModal ref="confirmModal" id="pwa-confirm-modal" />
    <ModalCustomJs ref="customJSModal"/>
    <ModalAddMeta ref="metaModal"/>
    <ModalDownloadJs ref="downloadJsModal"/>
  </div>
</template>
<script>
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import NavPaginate from '@/components/NavPaginate.vue'
import paginationMixin from '@/mixins/pagination-mixin'
import ConfirmModal from '@/components/modal/Confirm.vue'
import Loading from '@/components/Loading.vue'
import ModalAddMeta from '@/components/modal/ModalAddMeta.vue'
import ModalCustomJs from '@/components/modal/ModalCustomJs.vue'
import ModalDownloadJs from '@/components/modal/ModalDownloadJs.vue'
import Filters from './components/Filters.vue'
import MirrorsTable from './components/MirrorsTable/MirrorsTable.vue'
import MirrorsTableItem from './components/MirrorsTable/MirrorsTableItem.vue'
import ModalAddDomainMirror from './modals/ModalAddDomainMirror.vue'
import EmptyPage from './components/EmptyPage.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PageMirrors',
  title () {
    return this.$t('titles.mirrors.my')
  },
  mixins: [titleMixin, paginationMixin, accessesMixin],
  components: {
    Filters,
    MirrorsTable,
    ModalAddDomainMirror,
    BaseFromGroup,
    NavPaginate,
    EmptyPage,
    MirrorsTableItem,
    ConfirmModal,
    ModalAddMeta,
    ModalCustomJs,
    ModalDownloadJs,
    Loading
  },
  data () {
    return {
      filter: {
        domain: '',
        country: '',
        status: '',
        tags: [],
        preLandingPage: '',
        postLandingPage: '',
        pwa: '',
        name: ''
      }
    }
  },
  computed: {
    ...mapGetters('mirrorsStore', [
      'mirrorsList',
      'mirrorsListCount',
      'mirrorsListTrash',
      'mirrorsListEmpty',
      'mirrorsListLoading',
      'mirrorsListLoaded'
    ]),
    count () {
      return this.mirrorsListCount
    },
    trash () {
      return this.mirrorsListTrash
    },
    isEmptyFilter () {
      return this.filter.country === '' &&
        this.filter.status === '' &&
        this.filter.domain === '' &&
        !this.filter.tags.length > 0 &&
        this.filter.preLandingPage === '' &&
        this.filter.postLandingPage === ''
    }
  },
  beforeMount () {
    this.$store.dispatch('pwas/asyncDomainPage')
  },
  methods: {
    ...mapActions('mirrorsStore', [
      'getMirrorsList',
      'resetActiveMirror',
      'deleteMirror',
      'playMirror',
      'stopMirror',
      'updateMirror'
    ]),
    ...mapActions({
      alertSuccess: 'alerts/success'
    }),
    fetchPage () {
      this.getMirrorsList({
        page: this.page,
        limit: this.limit,
        sort: '-createdAt',
        filter: this.filter,
        trash: false
      })
    },
    onMirrorAdd () {
      this.resetActiveMirror()
      this.$refs.ModalAddDomainMirror.show()
    },
    onTagClick (tag) {
      this.filter.tags.push(tag)
    },
    onMirrorEdit () {
      this.$refs.ModalAddDomainMirror.show()
    },
    setExpiredDate () {
      const hours = import.meta.env.VITE_EXPIRED_MIRROR_HOURS
      const date = new Date(new Date().getTime() + hours * 60 * 60 * 1000)
      return date.toISOString()
    },
    async onMirrorMoveToTrash (data) {
      const name = data.name || ''
      const expired = this.setExpiredDate()
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('mirrors.trash.move'),
        message: this.$t('mirrors.trash.moveMessage', {name}),
        okButton: this.$t('mirrors.trash.moveButton')
      })
      if (ok) {
        await this.deleteMirror({id: data.id, expired})
        this.fetchPage()
        await this.$store.dispatch('alerts/success', this.$t('mirrors.trash.moveSuccess'))
      }
    },
    async onMirrorPlay (data) {
      const name = data.name || ''
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('mirrors.play.title'),
        message: this.$t('mirrors.play.message', {name: name}),
        okButton: this.$t('mirrors.play.button')
      })
      if (ok) {
        try {
          await this.playMirror(data)
          await this.$store.dispatch('alerts/success', this.$t('mirrors.play.success'))
        } catch (error) {
          await this.$store.dispatch('alerts/error', error.response.data === undefined ? error.message : error.response.data)
        }
      }
    },
    async onMirrorStop (data) {
      const name = data.name || ''
      const ok = await this.$refs.confirmModal.show({
        title: this.$t('mirrors.stop.title'),
        message: this.$t('mirrors.stop.message', {name: name}),
        okButton: this.$t('mirrors.stop.button')
      })
      if (ok) {
        try {
          await this.stopMirror(data)
          await this.$store.dispatch('alerts/success', this.$t('mirrors.stop.success'))
        } catch (error) {
          await this.$store.dispatch('alerts/error', error.response.data === undefined ? error.message : error.response.data)
        }
      }
    },
    async onMirrorCustomScript (data) {
      console.log(data)
      const ok = await this.$refs.customJSModal.show({
        title: this.$t('mirrors.buttons.customJs.title'),
        message: this.$t('mirrors.buttons.customJs.body'),
        id: data.id,
        customJs: data.customJs,
        action: this.updateMirror
      })
      if (ok) {
        await this.alertSuccess(this.$t('mirrors.buttons.customJs.success'))
        this.fetchPage()
      }
    },
    async onMirrorAddMeta (data) {
      const ok = await this.$refs.metaModal.show({
        title: this.$t('mirrors.buttons.meta.title'),
        message: this.$t('mirrors.buttons.meta.body'),
        id: data.id,
        meta: data.meta,
        action: this.updateMirror
      })
      if (ok) {
        await this.alertSuccess(this.$t('mirrors.buttons.meta.success'))
        this.fetchPage()
      }
    },
    async onMirrorDownloadJs (data) {
      await this.$refs.downloadJsModal.show({
        title: this.$t('mirrors.buttons.cloak.title'),
        message: this.$t('mirrors.buttons.cloak.body'),
        id: data.id,
        domain: data.domain
      })
    },
    async onStatisticsClick (data) {
      await this.$store.dispatch('analytics/getAnalyticsByGroup')
      const options = this.$store.getters['analytics/options']
      if (options.sort != null && options.sort.length > 1) {
        options.filter = {host: data.domain}
        options.group = 'date'
        options.limit = 20
        options.page = 1
        localStorage.setItem(`pwa-filter-0-${data.id}`, data.name)
        this.$store.commit('analytics/setOptions', JSON.parse(JSON.stringify(options)))
        await this.$store.dispatch('analytics/saveAnalyticsOptionsGroups')
        this.$router.push({name: 'AnalyticsGroups'})
      }
    },
    onFilterChange () {
      this.page = 1
      this.getMirrorsList({
        page: this.page,
        limit: this.limit,
        sort: '-createdAt',
        filter: this.filter,
        trash: false
      })
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.page-mirrors
  padding-left: 30px
  padding-right: 20px
  padding-top: 10px
  padding-bottom: 30px
  @media screen and (max-width: 767px)
    padding-left: 20px
    padding-right: 15px
  &__title
    margin-bottom: 24px
    font-size: 20px
    +font(400)
  &__filters
    display: flex
    flex-direction: row
    justify-content: space-between
    @media screen and (max-width: 767px)
      flex-direction: column
  &__title-link
    text-transform: uppercase
  &__table
    height: 100%
    max-height: calc(100vh - 300px)
    overflow-y: scroll
    @media screen and (max-width: 991px)
      max-height: 100%
      height: auto
  &__filters-button
    min-width: 145px
    margin-left: 10px
    @media screen and (max-width: 767px)
      margin-left: 0
</style>
