/* eslint-disable no-template-curly-in-string */
export default {
  method: 'Метод',
  install: {
    label: 's2s постбэк при инстале ({enable})',
    info: 'URL на который будут отправлять запросы по событиям, детально о настройке можно ознакомиться в нашем F.A.Q.',
    setting: 'Настройка инстала'
  },
  open: {
    label: 's2s постбэк при открытии ({enable})',
    info: 'URL на который будут отправлять запросы по событиям, детально о настройке можно ознакомиться в нашем F.A.Q.',
    setting: 'Настройка открытия'
  },
  reopen: {
    label: 's2s постбэк при повторном заходе ({enable})',
    info: 'URL на который будут отправлять запросы по событиям, детально о настройке можно ознакомиться в нашем F.A.Q.',
    setting: 'Настройка повторного входа'
  },
  push: {
    label: 's2s постбэк при PUSH подписке ({enable})',
    info: 'URL на который будут отправлять запросы по событиям, детально о настройке можно ознакомиться в нашем F.A.Q.',
    setting: 'Настройка PUSH подписки'
  },
  deposit: {
    label: 's2s постбэк при депозите ({enable})',
    info: 'URL на который будут отправлять запросы по событиям, детально о настройке можно ознакомиться в нашем F.A.Q.',
    setting: 'Настройка депозита'
  },
  registration: {
    label: 's2s постбэк при регистрации ({enable})',
    info: 'URL на который будут отправлять запросы по событиям, детально о настройке можно ознакомиться в нашем F.A.Q.',
    setting: 'Настройка регистрации'
  }
}
