export default {
  data () {
    return {
      pwa: {},
      cashedPWA: {},
      changed: {},
      preview: false,
      pwaCreated: false,
      autoconfigured: false,
      loading: false
    }
  },
  computed: {
    PWAId () {
      return this.$route.params.id
    },
    uploadUrl () {
      return `${import.meta.env.VITE_APP_API_BASE_URL}/pwas/${this.$route.params.id}`
    },
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    lang () {
      this.$refs.preview.updatePreview(this.previewParams({...this.changed, language: this.lang}))
    }
  },
  async created () {
    await this.getPWA()
    for (const prop in this.pwa) {
      if (!['owner', 'updatedAt', 'createdAt', '__v'].includes(prop)) {
        this.$watch(`pwa.${prop}`, function (n) {
          if (['comments', 'gallery'].includes(prop)) {
            this.changed[prop] = n
          } else {
            if (typeof n === 'string') {
              this.changed[prop] = n.toString().trim()
            } else {
              this.changed[prop] = n
            }
          }
          if (prop === 'images') {
            this.changed.vertical = this.pwa.vertical
          }
          if (this.preview) {
            if (Object.keys(this.previewParams(this.changed)).length > 0) {
              this.$refs.preview.updatePreview(this.previewParams({...this.changed, language: this.lang}))
            }
          }
          if (prop === 'POS') {
            if (this.pwa.POS == null || this.pwa.POS.length < 1) {
              this.pwa.forwardingSignalUserId = false
            }
          }
        }, {deep: true})
      }
    }
    this.pwaCreated = true
    if (this.toggleAccordion) {
      this.toggleAccordion()
    }

    this.preview = true
    if (this.lang != null) {
      if (Object.keys(this.previewParams(this.changed)).length > 0) {
        this.$refs.preview.updatePreview(this.previewParams({...this.changed, language: this.lang}))
      }
    }
    this.domain = this.pwa.domain
  },
  methods: {
    previewParams (changed) {
      const params = JSON.parse(JSON.stringify({...changed, languages: undefined}))
      if (this.lang != null && this.pwa.languages != null && this.pwa.languages[this.lang] != null) {
        for (const [prop, value] of Object.entries(this.pwa.languages[this.lang])) {
          params[prop] = value
        }
      }
      if (this.lang != null && this.languages != null && this.languages[this.lang] != null) {
        for (const [prop, value] of Object.entries(this.languages[this.lang])) {
          params[prop] = value
        }
      }
      if (this.lang != null && changed.languages != null && changed.languages[this.lang] != null) {
        for (const [prop, value] of Object.entries(changed.languages[this.lang])) {
          params[prop] = value
        }
      }
      return params
    },
    async getPWA () {
      this.loading = true
      await this.$store.dispatch('pwas/read', this.PWAId)
      this.pwa = this.$store.getters['pwas/PWA']
      this.cashedPWA = JSON.parse(JSON.stringify(this.pwa))

      if (this.pwa.tracker && this.pwa._id !== undefined) {
        this.pwa.tracker = this.pwa.tracker._id
      }
      if (this.pwa.comments === undefined || this.pwa.comments === '') {
        this.pwa.comments = []
      }
      if (this.pwa.gallery === undefined || this.pwa.gallery === '') {
        this.pwa.gallery = []
      }
      if (!this.pwa.postback) {
        this.pwa.postback = {
          enable: false,
          url: '',
          method: 'post'
        }
      }
      if (typeof this.pwa.cloudflare === 'undefined') {
        this.pwa.cloudflare = {}
      }
      if (this.pwa.cloudflare.email) {
        this.autoconfigured = typeof this.pwa.cloudflare.email !== 'undefined'
      }
      if (typeof this.pwa.languages !== 'object') {
        this.pwa.languages = {}
      }
      if (this.pwa.tags == null) {
        this.pwa.tags = []
      }
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async handlerUpdate (preview = true) {
      try {
        this.resetApiValidation()
        await this.$store.dispatch('pwas/update', {
          _id: this.pwa._id,
          ...this.changed
        })
        this.resetApiValidation()
        await this.$store.dispatch(
          'alerts/success',
          this.$t('pwas.save')
        )
        this.cashedPWA = JSON.parse(JSON.stringify(this.pwa))
        this.changed = {}
        if (preview) {
          this.$refs.preview.updatePreview(this.previewParams(this.changed))
        }
        this.changedData = false
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    }
  }
}
