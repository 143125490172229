export default {
  navigation: 'Комментарии',
  title: 'Комментарии PWA #{id}',
  message: 'Добавляйте неограниченное количество комментариев и ответов к ним',
  comment: {
    title: 'Комментарий',
    avatar: 'Аватар',
    avatarInfo: 'Загрузите аватар клиента',
    loadAvatar: 'Загрузите аватар',
    deleteAvatar: 'Вы уверены что хотите удалить аватар?',
    author: 'Автор',
    authorInfo: 'Введите имя автора комментария',
    rating: 'Рейтинг',
    ratingInfo: 'Поставьте рейтинг, который выставил данный автор',
    likes: 'Лайки',
    likesInfo: 'Количество лайков на данный комментарий',
    date: 'Дата',
    dateInfo: 'Выберите дату создания комментария',
    commentInfo: 'Напишите комментарий который оставил данный автор'
  },
  answer: {
    title: 'Ответ',
    author: 'Автор',
    authorInfo: 'Укажите имя отвечающего на комментарий (обычно это Служба поддержки приложения)',
    date: 'Дата',
    dateInfo: 'Выберите дату ответа на комментарий',
    commentInfo: 'Напишите ответ на комментарий'
  },
  add: 'Добавить комментарий',
  deletedTranslate: 'Перевод комментариев удалён',
  validate: {
    dateFormat: 'Введите дату в формате DD.MM.YY'
  }
}
