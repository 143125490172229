import api from '../api'

export default {
  getTariffs: ({owner, ...payload}) => api.get(`admin/users/${owner}/tariffs?` + new URLSearchParams(payload).toString()),
  getListTariffs: ({owner}) => api.get(`admin/users/${owner}/tariffs/list`),
  getTariff: ({owner, tariff}) => api.get(`admin/users/${owner}/tariffs/${tariff}`),
  createTariff: ({owner, payload}) => api.post(`admin/users/${owner}/tariffs`, payload),
  updateTariff: ({owner, tariff, payload}) => api.put(`admin/users/${owner}/tariffs/${tariff}`, payload),
  deleteTariff: ({owner, tariff}) => api.delete(`admin/users/${owner}/tariffs/${tariff}`)
}
