export default {
  menu: {
    keys: 'Ключи',
    offers: 'Офферы'
  },
  heads: {
    key: {label: 'Ключ', title: 'Ключ для активации бота'},
    member: {label: 'Веб', title: 'Веб, который использует этот ключ для доступа'},
    date: {label: 'Дата', title: 'Дата последнего обновления ключа'}
  },
  alert: {
    copy: 'Ключ {key} добавлен в буфер обмена',
    delete: 'Ключ <strong>{key}</strong> для Веб <strong>{username}</strong> удален'
  },
  confirm: {
    delete: {
      title: 'Удалить ключ',
      message: 'Вы действительно хотите удалить ключевые лицензии <strong>{key}</strong> для Веб <strong>{username}</strong>?',
      button: 'Удалить'
    }
  },
  latinAmerica: 'Латинская Америка',
  america: 'Америка',
  europe: 'Европа',
  asia: 'Азия',
  australiaAndOceania: 'Австралия и Океания',
  addOffer: 'Добавить офер'
}
