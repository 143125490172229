<template>
  <Modal ref="modal" id="modal-migrate-ips">
    <div class="modal-header">
      <h5 class="modal-title">Перенесення <strong class="text-success">{{ count }}</strong> PWA на інший IP</h5>
      <button
        :disabled="processing"
        @click="handleClose"
        type="button" class="close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <select2
        :disabled="processing"
        v-model="ip"
        :options="ipsOptions"
        :settings="{allowClear: true}"
        class="form-group"
        id="server-ip"
        name="server-ip"
        inputClass="form-control"
        placeholder="Оберіть новий сервер"
      />
      <div
        v-if="processing"
        class="d-flex justify-content-between">
        <small>Перенесено PWA</small>
        <small>Залишилося {{ queueWait }} із {{ count }}</small>
      </div>
      <div
        v-if="processing && percent < 100"
        class="progress progress-lg mt-2">
        <div class="progress-bar bg-success"
             role="progressbar"
             :style="{width: percent + '%'}"
             :aria-valuenow="percent"
             aria-valuemin="0" aria-valuemax="100">
          {{ percent }}%
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        :disabled="processing"
        @click="handlerSubmit" class="btn btn-success">Перенести</button>
      <button :disabled="processing" @click="handleClose" class="btn btn-light">Отмена</button>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import {OwnerFilter} from '@/pages/admin/components'
import Select2 from '@/components/Select2.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'MigrationIPsForm',
  components: {Select2, OwnerFilter, Modal},
  data () {
    return {
      processing: false,
      ip: '',
      filter: {
        status: '',
        owner: '',
        domain: '',
        server: '',
        ip: ''
      },
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    ...mapGetters({
      count: 'adminSystem/migrationIPsCount',
      queue: 'adminSystem/queue',
      ipsOptions: 'ips/options'
    }),
    percent () {
      return Math.round((this.count - this.queueWait) / this.count * 100)
    },
    queueWait () {
      return this.queue.waiting + this.queue.active
    }
  },
  methods: {
    ...mapActions({
      asyncData: 'adminSystem/asyncDataQueuesCloudflareMigrate',
      migrationIPs: 'adminSystem/migrationIPs',
      alertError: 'alerts/error',
      alertSuccess: 'alerts/success'
    }),
    async handleOpen (filter) {
      this.$store.commit('adminSystem/setQueue', {
        active: 0,
        delayed: 0,
        failed: 0,
        newestJob: 0,
        succeeded: 0,
        waiting: 0
      })
      this.processing = false
      this.filter = filter
      this.ip = ''
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handleClose () {
      this.$refs.modal.close()
      this.resolvePromise(false)
    },
    async handlerSubmit () {
      try {
        this.processing = true
        await this.migrationIPs({
          filter: this.filter,
          payload: {ip: this.ip}
        })
        await this.asyncData()
        const interval = setInterval(async () => {
          await this.asyncData()
          if (this.queueWait === 0) {
            clearInterval(interval)
            await this.alertSuccess(`Перенос на ${this.ip} завешено`)
            this.$refs.modal.close()
            this.resolvePromise(true)
          }
        }, 1000)
      } catch ({message}) {
        await this.alertError(message)
        this.processing = false
      }
    }
  }
}
</script>
