<template>
<div class="mr-md-4 mb-4 mb-md-0 one-column-mobile">
  <modal ref="modal" id="pwa-template-form" class="fade"
         classContent="modal-content modal_padd_normal style_form_lab" classDialog="modal-dialog">
    <button v-if="!processing" type="button" class="close_sjgs_to d_flex align_center" @click="handlerCancel"><span aria-hidden="true">&times;</span>
    </button>
    <div class="title_mod_centr_g">{{ title }}</div>
    <div class="form_add_user_str">
      <form v-if="!loading" @submit.prevent="handlerSave">
        <div class="item_row_usr_add">
          <div class="clearfix row_halff_g">
            <div class="item_fds_ggo pr-1 pl-1" :class="apiValidationErrors.name ? 'has-danger' : ''">
              <div class="sibmti_sdf">{{ $t('templates.form.name') }}</div>
              <input type="text" class="form-control" id="name" name="name"
                    v-model="name"
                    :class="apiValidationErrors.name ? 'form-control-danger' : ''"
                    :placeholder="$t('templates.form.namePlaceholder')"
                    @focus="clearError('name')">
              <label v-if="apiValidationErrors.name" class="error mt-2 info_warning_gdg" for="name">
                {{ apiValidationErrors.name[0] }}
              </label>
            </div>
            <BaseFromGroup
              v-if="user.isAdmin"
              id="owner"
              name="owner"
              :label="$t('templates.form.owner')"
              :has-error="apiValidationErrors.owner"
              :error-text="apiValidationErrors?.owner ? apiValidationErrors?.owner[0] : ''"
            >
              <BaseSelect
                v-model="owner"
                id="owner"
                name="owner"
                sort="-createdAt"
                endpoint="/users/filter"
                :placeholder="$t('templates.form.ownerPlaceholder')"
              />
            </BaseFromGroup>
          </div>
        </div>
        <div class="logir_ff_form">
          <button class="ful_butt_green d_flex align_center justify_center w-100" type="submit" :disabled="processing">
            <span v-if="!processing">{{ $t('general.save') }}</span>
            <hollow-dots-spinner
              v-else
              :animation-duration="1000"
              :dot-size="15"
              :dots-num="3"
              :color="'#ff1d5e'"
            />
          </button>
        </div>
      </form>
    </div>
  </modal>
  <a class="simple_butt_afg mobile-full-w" href="javascript:void(0);"
     @click="create">{{ $t('templates.create') }}</a>
</div>
</template>
<script>
import {HollowDotsSpinner} from 'epic-spinners'
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

const defaultValues = () => ({
  id: null,
  name: '',
  owner: null,
  pwaId: '',
  lang: ''
})

export default {
  name: 'OnlyPWAForm',
  mixins: [formMixin, select2AjaxMixin],
  components: {BaseSelect, BaseFromGroup, Modal, Select2, HollowDotsSpinner},
  data () {
    return {
      ...defaultValues(),
      loading: true,
      processing: false,
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
      pwaIdSettings: {},
      pwaIdOptions: [],
      ownerSettings: {},
      ownerOptions: []
    }
  },
  computed: {
    title () {
      return this.id != null ? this.$t('templates.form.editTitle', {name: this.name}) : this.$t('templates.form.createTitle')
    },
    user () {
      return this.$store.getters['identity']
    }
  },
  created () {
    this.pwaIdSettings = {...this.getSelect2AjaxSettings('pwas/filter'), allowClear: true}
    this.ownerSettings = {...this.getSelect2AjaxSettings('users/filter'), allowClear: true}
  },
  methods: {
    handlerCancel () {
      this.skip()
      this.$refs.modal.close()
      this.resolvePromise(false)
    },
    create () {
      this.skip()
      this.$refs.modal.open()
      this.$nextTick(() => {
        this.loading = false
      })
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async update ({id, name, owner, pwaId}) {
      this.skip()
      this.id = id
      this.name = name
      this.owner = owner
      this.pwaId = pwaId
      this.pwaIdOptions = await this.getSelect2AjaxOptions(this.pwaId, 'pwas/filter')
      this.ownerOptions = await this.getSelect2AjaxOptions(this.owner, 'users/filter')
      this.$refs.modal.open()
      this.$nextTick(() => {
        this.loading = false
      })
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    skip () {
      this.loading = true
      this.processing = false
      this.pwaIdOptions = []
      this.ownerOptions = []
      this.resetApiValidation()
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    async handlerSave () {
      if (this.processing === false) {
        try {
          this.processing = true
          this.resetApiValidation()
          const payload = {
            name: this.name
          }
          if (this.user.isAdmin) {
            payload.owner = this.owner
          }
          if (!this.id) {
            payload.pwaId = this.$route.params.id
          }
          if (this.id != null) {
            await this.$store.dispatch('pwasTemplates/update', {...payload, id: this.id})
          } else {
            await this.$store.dispatch('pwasTemplates/create', payload)
          }
          this.resetApiValidation()
          this.skip()
          this.$refs.modal.close()
          this.resolvePromise(true)
          await this.$store.dispatch('alerts/success', this.$t('templates.success'))
        } catch (error) {
          this.setApiValidation(error.response.data.errors)
          this.$nextTick(() => {
            this.processing = false
          })
        }
      }
    }
  }
}
</script>
