import Postbacks from './Postbacks.vue'
import PostbackLogs from './PostbackLogs.vue'

export default (meta, header) => {
  return [
    {
      path: 'postback',
      name: 'AdminPostbacks',
      components: {default: Postbacks, header},
      meta
    },
    {
      path: 'postback/logs',
      name: 'AdminPostbackLogs',
      components: {default: PostbackLogs, header},
      meta
    }
  ]
}
