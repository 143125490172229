<template>
  <nav aria-label="breadcrumb">
    <BaseRouterTabs :tabs="tabs" show-step-icon :additional-name="name"/>
  </nav>
</template>
<script>
import BaseRouterTabs from '@/components/base/BaseRouterTabs.vue'

export default {
  name: 'Navigation',
  props: {
    name: String
  },
  components: {BaseRouterTabs},
  data: function () {
    const id = this.$route.params.id
    return {
      tabs: [
        {to: {name: 'PWAsUpdate', params: {id: id}}, label: 'pwas.components.domain.navigation'},
        {to: {name: 'PWAsTracker', params: {id: id}}, label: 'pwas.components.tracker.navigation'},
        {to: {name: 'PWAsVertical', params: {id: id}}, label: 'pwas.components.vertical.navigation'},
        {to: {name: 'PWAsApp', params: {id: id}}, label: 'pwas.components.app.navigation'},
        {to: {name: 'PWAsComments', params: {id: id}}, label: 'pwas.components.comment.navigation'},
        {to: {name: 'PWAsAnalytics', params: {id: id}}, label: 'pwas.components.analytics.navigation'},
        {to: {name: 'PWAsSetting', params: {id: id}}, label: 'pwas.components.setting.navigation'}
      ]
    }
  }
}
</script>
<style scoped lang="sass">
.navigation_wrp_sg
  z-index: 3
</style>
