import analytics from './analytics/pt'
import app from './app/pt'
import comment from './comment/pt'
import domain from './domain/pt'
import setting from './setting/pt'
import tracker from './tracker/pt'
import vertical from './vertical/pt'

export default {
  translate: {
    title: 'Tradução de PWA<span class="url_ti_soig">{id}</span>',
    commentTitle: 'Tradução de comentários para PWA<span class="url_ti_soig">{id}</span>',
    from: 'Idioma de origem',
    to: 'Idioma de tradução',
    translate: 'Traduzir',
    backToRu: 'Voltar RU',
    delete: 'Apagar a tradução',
    default: 'Por definição',
    newApp: 'Criação da localização nova<span class="url_ti_soig">{id}</span>',
    newComments: 'Criação da tradução de comentários para «{title}»<span class="url_ti_soig">Com este movimento criar-se-á uma cópia de comentários <strong>«por definição»</strong> e traduzir-se-á para <strong>{langTo}. Importante lembrar</strong> que todos os comentários em diversos idiomas <strong>são independentes</strong>! Alterações de comentários «por definição» não serão transferidos para cópias traduzidas anteriormente </span>',
    process: '<small>O processo de cópia e tradução de comentários está em curso, não fecha a janela!</small><small>Processo: {count}/{total}</small>',
    success: 'Cópia e tradução foram concluídos com sucesso',
    returnSuccess: 'Anotações foram removidas com sucesso'
  },
  google: {
    delete: 'Apagar a análise?',
    settings: {
      title: 'Configuração de Google Conversion',
      code: 'Rótulo de conversão',
      value: 'Valor',
      currency: 'Moeda',
      currencyInfo: 'Moeda do valor, 3 letras maiúsculas (por exemplo, USD)'
    },
    events: ['Instalação', 'Abertura', 'PUSH subscrição', 'Registrar-se', 'Depósito']
  },
  facebook: {
    title: 'Configuração de Facebook Pixel',
    label: 'É necessário para enviar s2s eventos no Facebook',
    pixelInfo: 'Inserir seu Facebook pixel. Automaticamente controlamos o movimento "Lead". Considerar-se-á como um lead a abertura de Pwa',
    lead: 'O que é um Lead?',
    leadInfo: 'Escolha do evento Lead',
    dontUse: 'Não aplicar',
    events: ['Instalação', 'Abertura', 'PUSH subscrição', 'Registrar-se', 'Depósito'],
    delete: 'Apagar o pixel?',
    deleteMessage: 'Tem a certeza de que pretende eliminar o píxel {id}?',
    deleteButton: 'Excluir',
    noPixel: 'Primeiro introduza o pixel',
    multiForm: {
      title: 'Adicionamento agrupado de FB pixel',
      message: 'Adiciona pixel apenas em formato <strong>pixel:lead</strong>, onde <strong>pixel</strong> são os seus FB pixel, e <strong>lead</strong> são os eventos de lead, que pode ter o valor <strong>install</strong> ou <strong>registration</strong>',
      label: 'Pixel por linha',
      errors: [
        'Introduza o pixel e o evento',
        'A linha {errorLine} tem um erro! Falta indicar o pixel',
        'A linha {errorLine} tem um erro! Falta indicar o evento de lead',
        'A linha {errorLine} tem um erro! O evento de lead está inválido'
      ]
    }
  },
  tt: {
    dontUse: 'Não aplicar',
    events: ['Instalação', 'Abertura', 'PUSH subscrição', 'Registrar-se s2s', 'Depósito s2s'],
    title: 'Configuração de TikTok Pixel',
    label: 'É necessário para enviar s2s eventos в TikTok',
    delete: 'Apagar pixel?',
    noPixel: 'Primeiro introduza o pixel'
  },
  generator: {
    title: 'Geração do conteúdo',
    langFrom: 'Idioma principal',
    mode: {
      title: 'Regimes de geração',
      random: 'Texto aleatório',
      googlePlay: 'Google play',
      template: 'Do modelo'
    },
    texts: 'Texto',
    textsInfo: 'Gerar um texto',
    comments: 'Comentário',
    commentsInfo: 'Gerar um comentário',
    media: 'Imagens',
    mediaInfo: 'Gerar uma imagem',
    button: 'Gerar',
    confirm: 'Este movimento é alterar todos os textos e comentários, tem certeza que quer continuar?',
    failed: 'Geração falhou, tente novamente',
    success: 'Texto para PWA foi gerado com sucesso',
    pwaGenerateStart: '<small>A geração em preparação, não fecha a janela!</small><small>Processo: {count}/{total}</small>',
    pwaGenerateBeginDownload: '<small>Скачивания страницы Google Play <strong>({lang})</strong>, не закрывайте форму!</small><small>Процесс: {count}/{total}</small>',
    pwaGenerateFinishDownload: '<small>Downloads da página Google Play <strong>({lang})</strong>, não fecha a janela!</small><small>Processo: {count}/{total}</small>',
    pwaGenerateBeginText: '<small>Geração texto <strong>({lang})</strong>, não fecha a janela!</small><small>Processo: {count}/{total}</small>',
    pwaGenerateFinishText: '<small>Geração de texto foi concluído <strong>({lang})</strong>, não fecha a janela!</small><small>Processo: {count}/{total}</small>',
    pwaGenerateBeginComment: '<small>Geração de comentários <strong>({lang})</strong>, não fecha a janela!</small><small>Processo: {count}/{total}</small>',
    pwaGenerateFinishComment: '<small>Geração de comentários foi concluído <strong>({lang})</strong>, não fecha a janela!</small><small>Processo: {count}/{total}</small>',
    pwaGenerateBeginTemplate: '<small>Processamento do modelo <strong>({lang})</strong>, não fecha a janela!</small><small>Processo: {count}/{total}</small>',
    pwaGenerateFinishTemplate: '<small>Processamento do modelo foi concluído <strong>({lang})</strong>, não fecha a janela!</small><small>Processo: {count}/{total}</small>'
  },
  analytics,
  app,
  comment,
  domain,
  setting,
  tracker,
  vertical
}
