<template>
  <div class="container-scroller" id="app">
    <router-view/>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState({
      alerts: state => state.alerts.alerts
    })
  },
  watch: {
    alerts: function (newValue) {
      let {message, type} = newValue[newValue.length - 1]
      this.$toast.open({
        message,
        type,
        position: 'top-right',
        duration: 2000
      })
    }
  },
  mounted () {
    this.$socket.emit('getPrivacyPolicy')
  }
}
</script>
<style lang="sass">
@import 'styles/index.scss'
.page-body-wrapper
  background-color: #121214
.card
  background-color: transparent
.form-control,
.select2-container,
.multiselect__tags
  background: transparent !important
.select2-selection
  background: transparent !important
.mx-datepicker
  input
    background: transparent !important
.fixed-animated-image
  position: fixed
  right: 0
  top: 50%
  @media screen and (max-width: 1900px)
    display: none
select.form-control
  background: #121214 !important
</style>
