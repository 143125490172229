<template>
  <div class="top-tabs mb-3">
    <ul class="nav tabs_tp_g d-flex align-items-center">
      <li class="nav-item" v-for="item of items" :key="item.label">
        <a :href="item.href" class="item_link_tab_g" data-toggle="tab" role="tab" :class="item.active ? 'active' : ''">{{ $t(item.label) }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'TariffMenu',
  props: ['active'],
  data: () => ({
    items: [
      {href: '#individual', label: 'tariffs.individual', active: true},
      {href: '#command', label: 'tariffs.command', active: false},
      {href: '#commercial', label: 'tariffs.commercial', active: false},
      {href: '#history', label: 'tariffs.history', active: false}
    ]
  })
}
</script>
