<template>
  <div class="row grid-margin" v-if="pwa">
    <div class="col-6 grid-margin">
      <div class="card">
        <div class="card-body">
          <breadcrumb :id="pwa._id">
            <arrow-button v-model="shs"/>
          </breadcrumb>
          <h4 class="card-title">Надписи</h4>
          <pwa-content :data="pwa" v-if="shs"/>
        </div>
      </div>
    </div>
    <div class="col-6 grid-margin" v-for="(data, lang) of pwa.languages" :key="'app-' + lang">
      <div class="card">
        <div class="card-body">
          <breadcrumb v-if="pwa" :id="pwa._id">
            <arrow-button v-model="shsl[lang]"/>
          </breadcrumb>
          <h4 class="card-title">Надписи на {{ lang }}</h4>
          <pwa-content :data="data" v-if="shsl[lang]"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '../Breadcrumb.vue'
import ArrowButton from '../ArrowButton.vue'
import * as components from './components'

export default {
  name: 'App',
  props: ['pwa'],
  components: {Breadcrumb, ArrowButton, 'pwa-content': components.Content},
  data: () => ({shs: false, shsl: {}}),
  created: function () {
    if (this.pwa.languages) {
      for (const [lang] of Object.entries(this.pwa.languages)) {
        this.shsl[lang] = false
      }
    }
  }
}
</script>
