<template>
  <div>
    <span class="float-left">{{ data._id }}</span>
  </div>
</template>
<script>
export default {
  name: 'TTQ',
  props: ['data']
}
</script>
