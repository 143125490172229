<template>
  <div>
    <div class="card grid-margin">
      <div class="card-body">
        <h4 class="card-title">Настройки времени оплаты</h4>
        <form class="forms-sample" @submit.prevent="handlerSubmit">
          <div class="form-group" :class="apiValidationErrors.wait ? 'has-danger' : ''">
            <label for="wait">Время для начала оплаты</label>
            <div class="input-group-append">
              <input type="number" step="0" id="wait" placeholder="30" v-model="wait"
                     class="form-control" min="15" max="1440"
                     :class="apiValidationErrors.wait ? 'form-control-danger' : ''"
                     @focus="clearError('wait')">
              <div class="input-group-append">
                <span class="input-group-text">минут</span>
              </div>
            </div>
            <small class="form-text text-muted">Целое число, время в минутах</small>
            <label v-if="apiValidationErrors.wait" class="error mt-2 text-danger"
                   for="wait">
              {{ apiValidationErrors.wait[0] }}
            </label>
          </div>
          <div class="form-group" :class="apiValidationErrors.pay ? 'has-danger' : ''">
            <label for="pay">Время для ожидания подтверждений</label>
            <div class="input-group-append">
              <input type="number" step="0" id="pay" placeholder="48"
                     v-model="pay" required
                     class="form-control" min="1" max="168"
                     :class="apiValidationErrors.pay ? 'form-control-danger' : ''"
                     @focus="clearError('pay')">
              <div class="input-group-append">
                <span class="input-group-text">часы</span>
              </div>
            </div>
            <small class="form-text text-muted">Целое число, время в часах</small>
            <label v-if="apiValidationErrors.pay" class="error mt-2 text-danger"
                   for="pay">
              {{ apiValidationErrors.pay[0] }}
            </label>
          </div>
          <div class="form-group" :class="apiValidationErrors.trcWallet ? 'has-danger' : ''">
            <label for="trcWallet">Кошелёк TRC20</label>
            <div class="input-group-append">
              <input type="text" id="trcWallet" v-model="trcWallet"
                     class="form-control"
                     :class="apiValidationErrors.trcWallet ? 'form-control-danger' : ''"
                     @focus="clearError('trcWallet')">
            </div>
            <label v-if="apiValidationErrors.trcWallet" class="error mt-2 text-danger"
                   for="trcWallet">
              {{ apiValidationErrors.trcWallet[0] }}
            </label>
          </div>
          <button type="submit" class="btn btn-success mr-2">Сохранить</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'ExpiredForm',
  mixins: [formMixin],
  data () {
    return {
      wait: 0,
      pay: 0,
      trcWallet: ''
    }
  },
  created () {
    this.wait = this.billing.expired.wait
    this.pay = this.billing.expired.pay
    this.trcWallet = this.billing.trcWallet
  },
  computed: {
    ...mapGetters({
      billing: 'adminConfig/billing'
    })
  },
  methods: {
    ...mapActions({
      update: 'adminConfig/update',
      alertSuccess: 'alerts/success'
    }),
    async handlerSubmit () {
      try {
        this.resetApiValidation()
        await this.update({
          billing: {
            expired: {
              wait: this.wait,
              pay: this.pay
            },
            trcWallet: this.trcWallet
          }
        })
        this.resetApiValidation()
        await this.alertSuccess('Настройки времени оплаты обновлены')
      } catch (error) {
        const data = error.response.data.errors
        data.forEach((value, index) => {
          const params = value.param.split('.')
          data[index].param = params[params.length - 1]
        })
        this.setApiValidation(data)
      }
    }
  }
}
</script>
