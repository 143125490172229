<template>
  <div id="content-pwa" class="tab-pane fade in active" v-if="accesses.statistics.view">
    <filters v-if="options?.filter" :loading="loading" :value="options" @optionsChange="optionsChange" @filterChange="asyncData"/>
    <data-table
      :models="models"
      :count="count"
      :loading="loading"
      :value="options"
      :columns="columns"
      @sortChange="changeSort"
      @optionsChange="optionsChange">
      <div class="d-md-flex mobile-grid-2 mt-2 mt-sm-0">
        <modal v-model="dialog" id="columns-setting-modal">
          <columns-setting-form :value="options" @optionsChange="optionsChange" :columns="columns"/>
        </modal>
        <button class="btn_green_g one-column-mobile ml-sm-2" @click="openHandler">{{ $t('analytics.columns') }}</button>
      </div>
    </data-table>
  </div>
</template>
<script>
import _ from 'lodash'
import tooltipMixin from '@/mixins/tooltip-mixin'
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import statisticsMixin from '@/mixins/statistics-mixin'
import {DataTable, Filters, columns, Modal} from './components'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Uniques',
  title: function () {
    return this.$t('titles.analyticsUniques')
  },
  mixins: [tooltipMixin, titleMixin, accessesMixin, statisticsMixin],
  components: {Filters, DataTable, ColumnsSettingForm: columns.setting.Form, Modal},
  data: () => {
    return {
      dialog: false,
      loading: true,
      options: {}
    }
  },
  computed: {
    ...mapGetters('analytics', ['count', 'columns', 'models'])
  },
  async created () {
    await this.$store.dispatch('analytics/resetState')
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.getOptions()
    this.asyncData(this.options)
    this.setOptionsWatch()
  },
  watch: {
    'options.range': function (newVal) {
      this.$store.commit('analytics/setOptions', JSON.parse(JSON.stringify({...this.options})))
      this.getFilters()
      this.asyncData()
    },
    'options.columns': function (newVal, oldVal) {
      if (newVal && oldVal && this.checkIsNewColumns(newVal, oldVal)) {
        this.asyncData()
      }
    }
  },
  methods: {
    ...mapActions('analytics', [
      'getGroupsBy',
      'getFilters',
      'getAnalyticsByGroup'
    ]),
    optionsChange (payload) {
      for (const [key, value] of Object.entries(payload)) {
        this.options[key] = value
      }
      this.$store.commit('analytics/setOptions', JSON.parse(JSON.stringify({...this.options})))
      if (!payload.hasOwnProperty('sort')) {
        this.$store.dispatch('analytics/saveAnalyticsOptionsHosts')
      }
    },
    changeSort (sort) {
      this.options = {...this.options, sort}
    },
    openHandler () {
      this.dialog = true
    },
    async asyncData (payload) {
      this.loading = true
      try {
        this.$store.commit('analytics/setOptions', JSON.parse(JSON.stringify({...payload})))
        await this.$store.dispatch('analytics/asyncData', {type: 'hosts', group: undefined})
      } catch (e) {
        await this.$store.dispatch('alerts/error', e.response.data.errors[0].msg)
      }
      this.loading = false
    },
    async getOptions () {
      await this.$store.dispatch('analytics/getAnalyticsByHosts')
      this.options = this.$store.getters['analytics/options']
    },
    setOptionsWatch () {
      if (this.options.sort != null && this.options.sort.length > 1) {
        ['group', 'limit', 'page', 'sort'].forEach(item => {
          this.$watch(`options.${item}`, function (n, o) {
            if (+n.page > 1 && +n.page === +o.page) {
              n.page = this.options.page = 1
            }
            this.asyncData(n)
          })
        })
      }
    }
  }
}
</script>
