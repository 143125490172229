export default {
  title: 'PUSH рассылки',
  add: 'Добавить рассылку',
  reload: 'Обновить',
  types: {
    postponed: 'Отложено',
    interval: 'Интервальная'
  },
  filters: {
    name: 'Поиск по названию',
    status: 'Фильтр по статусу',
    range: {
      placeholder: 'Фильтр по дате',
      today: 'Сегодня',
      tomorrow: 'Завтра',
      nextSevenDay: 'Следующее 7 дней'
    },
    pwa: 'Фильтр по PWA',
    domains: 'Фильтр по домену',
    country: 'Фильтр по стране',
    conversions: {
      placeholder: 'Фильтр по конверсиям',
      options: ['С конверсиями', 'Без конверсий']
    },
    type: 'Фильтр по типу'
  },
  form: {
    formTitle: 'Форма настройки рассылки',
    message: 'Настройка рассылки и контента для отправки',
    success: 'PUSH рассылка успешно сохранена',
    preview: 'Превью',
    tags: {
      title: 'Теги рассылки',
      info: 'Набор тегов для фильтрации рассылки'
    },
    name: {
      title: 'Название',
      info: 'Системное название рассылки',
      placeholder: 'Введите название рассылки'
    },
    publishedAt: {
      title: 'Начало',
      info: 'Дата начала рассылки',
      placeholder: 'Введите дату'
    },
    title: {
      title: 'Заголовок',
      info: 'Заголовок PUSH сообщения. Рекомендуемая длина до 37 символов. Поддерживается spintax',
      placeholder: 'Введите заголовок'
    },
    body: {
      title: 'Текст',
      info: 'Текст PUSH сообщения. Рекомендуемая длина до 50 символов. Поддерживается spintax',
      placeholder: 'Введите текст'
    },
    url: {
      title: 'Ссылка',
      info: 'Ссылка которая откроется при нажатии. Если не указать, то будет автоматически открывать URL установленный в PWA',
      placeholder: 'Введите ссылку'
    },
    badge: {
      title: 'Значок',
      info: 'Изображение, используемое для представления уведомления, когда для отображения самого уведомления недостаточно места. Мы обрежем картинку до рекомендуемых размеров (96 x 96)',
      placeholder: 'Загрузите значок'
    },
    icon: {
      title: 'Иконка',
      info: 'Изображение используемое качестве иконки уведомления. Мы обрежем картинку до рекомендуемых размеров (192 x 192)',
      placeholder: 'Загрузите иконку'
    },
    image: {
      title: 'Изображение',
      info: 'Изображение которое будет отображаться как часть уведомления. Мы обрежем картинку до рекомендуемых размеров (492 x 225)',
      placeholder: 'Загрузите изображение'
    },
    dir: {
      title: 'Направление текста',
      info: 'Указывает направление текста PUSH уведомления',
      options: ['Авто', 'Слева на право', 'Справа на лево']
    },
    renotify: {
      title: 'Повторно извещать',
      info: 'Следует ли уведомлять пользователя после того, как новое уведомление заменит старое'
    },
    requireInteraction: {
      title: 'Ожидать клика',
      info: 'Уведомление должно оставаться активным до тех пор, пока пользователь не щелкнет его или не закроет, а не закрываться автоматически'
    },
    silent: {
      title: 'Тихий режим',
      info: 'Указывает, должно ли уведомление быть беззвучным, т. е. не должны издаваться звуки или вибрации, независимо от настроек устройства'
    },
    segmentation: {
      title: 'Сегментация PUSH',
      message: 'Если ничего не заполнять то рассылка будет по всей вашей базе',
      total: 'Всего',
      showedCount: 'Показов',
      clickedCount: 'Кликов',
      cappedCount: 'Закрытий',
      registeredCount: 'Регистраций',
      depositedCount: 'Депозитов',
      pwas: {
        placeholder: 'Выберите одно или несколько PWA'
      },
      domains: {
        title: 'Домены',
        placeholder: 'Выберите один или несколько доменов'
      },
      tags: {
        title: 'Теги PWA',
        placeholder: 'Выберите один или несколько тегов PWA'
      },
      countries: {
        title: 'Страны',
        placeholder: 'Выберите одну или несколько стран'
      },
      registered: {
        title: 'Регистрация',
        info: 'Отправить тем пользователям которые зарегистрированы в приложении или тем кто не зарегистрирован',
        options: ['Не используется', 'Зарегистрированы', 'Не зарегистрированы']
      },
      deposited: {
        title: 'Депозит',
        info: 'Отправить тем пользователям которые сделали депозит приложения или тем кто нет',
        options: ['Не используется', 'Сделали депозит', 'Не сделали']
      },
      showed: {
        title: 'Показы',
        info: 'Отправить тем пользователям, которые уже получали уведомления или тем, кто не получал',
        options: ['Не используется', 'Получали', 'Не получали']
      },
      capped: {
        title: 'Закрытия',
        info: 'Отправить тем пользователям, которые закрывали уведомления или тем, кто не закрывал',
        options: ['Не используется', 'Закрывали', 'Не закрывали']
      },
      clicked: {
        title: 'Клики',
        info: 'Отправить тем пользователям, которые кликали на уведомления или тем, кто не кликал',
        options: ['Не используется', 'Кликали', 'Не кликали']
      }
    },
    timezone: {
      label: 'Часовой пояс',
      info: 'Корректор часового пояса'
    },
    country: {
      label: 'Страна',
      info: 'Выберите страну чтобы отфильтровать часовые пояса',
      error: 'Не удалось найти часовой пояс, выберите его сами'
    }
  },
  heads: {
    count: {
      label: 'Кл. рассылок',
      title: 'Общее количество всех рассылок'
    },
    name: {
      label: 'Название',
      title: 'Системное название рассылки'
    },
    tags: {
      label: 'Теги',
      title: 'Теги для фильтрации рассылки'
    },
    status: {
      label: 'Статус',
      title: 'Статус рассылки'
    },
    type: {
      label: 'Тип',
      title: 'Тип рассылки'
    },
    publishedAt: {
      label: 'Начало',
      title: 'Дата начала рассылки'
    },
    interval: {
      label: 'Следующий',
      title: 'Следующий запуск рассылки'
    },
    total: {
      label: 'Всего',
      title: 'Всего'
    },
    remaining: {
      label: 'Осталось',
      title: 'Осталось'
    },
    delivered: {
      label: 'Доставлено',
      title: 'Доставлено'
    },
    showed: {
      label: 'Показов',
      title: 'Показов'
    },
    clicked: {
      label: 'Кликов',
      title: 'Кликов'
    },
    ctr: {
      label: 'CTR',
      title: 'Процентное соотношение клики/доставлено. Рассчитывается по формуле CTR = клики/доставлено*100'
    },
    unsubscribed: {
      label: 'Отписалось',
      title: 'Отписалось'
    },
    registered: {
      label: 'Реги',
      title: 'Зарегистрировалось'
    },
    deposited: {
      label: 'Депы',
      title: 'Сделало депозит'
    },
    countries: {
      label: 'Страны',
      title: 'Страны по которым производится рассылка',
      all: 'По всем'
    }
  },
  statuses: {
    suspended: 'Приостановлено',
    launched: 'Запущено',
    postponed: 'Отложено',
    sending: 'Отправка',
    done: 'Выполнено'
  },
  copy: {
    title: 'Копирование PUSH рассылки',
    message: 'Ви действительно хотите копировать рассылку {name}',
    button: 'Копировать',
    success: 'PUSH рассылка скопирована'
  },
  delete: {
    title: 'Удаление PUSH рассылки',
    message: 'Ви действительно хотите удалить рассылку {name}',
    button: 'Удалить',
    success: 'PUSH рассылка удалена'
  },
  template: {
    title: 'Шаблоны PUSH рассылки',
    source: 'Шаблон',
    button: 'Шаблон рассылки'
  },
  templates: {
    title: 'Шаблоны',
    add: 'Добавить шаблон',
    success: 'Шаблон рассылки успешно сохранена',
    filters: {
      name: 'Поиск по названию',
      title: 'Поиск по заголовку',
      body: 'Поиск по тексту'
    },
    form: {
      formTitle: 'Форма настройки шаблона рассылки',
      message: 'Настройка рассылки и контента для отправки',
      tags: {
        title: 'Теги рассылки',
        info: 'Набор тегов для фильтрации рассылки'
      },
      name: {
        title: 'Название',
        info: 'Системное название шаблона',
        placeholder: 'Введите название шаблона'
      },
      title: {
        title: 'Заголовок',
        info: 'Заголовок PUSH сообщения. Рекомендуемая длина до 37 символов. Поддерживается spintax',
        placeholder: 'Введите заголовок'
      },
      body: {
        title: 'Текст',
        info: 'Текст PUSH сообщения. Рекомендуемая длина до 50 символов. Поддерживается spintax',
        placeholder: 'Введите текст'
      },
      url: {
        title: 'Ссылка',
        info: 'Ссылка которая откроется при нажатии. Если не указать, то будет автоматически открывать URL установленный в PWA',
        placeholder: 'Введите ссылку'
      },
      badge: {
        title: 'Значок',
        info: 'Изображение, используемое для представления уведомления, когда для отображения самого уведомления недостаточно места. Мы обрежем картинку до рекомендуемых размеров (96 x 96)',
        placeholder: 'Загрузите значок'
      },
      icon: {
        title: 'Иконка',
        info: 'Изображение используемое качестве иконки уведомления. Мы обрежем картинку до рекомендуемых размеров (192 x 192)',
        placeholder: 'Загрузите иконку'
      },
      image: {
        title: 'Изображение',
        info: 'Изображение которое будет отображаться как часть уведомления. Мы обрежем картинку до рекомендуемых размеров (492 x 225)',
        placeholder: 'Загрузите изображение'
      },
      dir: {
        title: 'Направление текста',
        info: 'Указывает направление текста PUSH уведомления',
        options: ['Авто', 'Слева на право', 'Справа на лево']
      },
      renotify: {
        title: 'Повторно извещать',
        info: 'Следует ли уведомлять пользователя после того, как новое уведомление заменит старое'
      },
      requireInteraction: {
        title: 'Ожидать клика',
        info: 'Уведомление должно оставаться активным до тех пор, пока пользователь не щелкнет его или не закроет, а не закрываться автоматически'
      },
      silent: {
        title: 'Тихий режим',
        info: 'Указывает, должно ли уведомление быть беззвучным, т. е. не должны издаваться звуки или вибрации, независимо от настроек устройства'
      }
    },
    heads: {
      name: {
        label: 'Название',
        title: 'Системное название шаблона'
      },
      tags: {
        label: 'Теги',
        title: 'Теги для фильтрации шаблона'
      },
      title: {
        label: 'Заголовок',
        title: 'Заголовок шаблона'
      },
      body: {
        label: 'Текст',
        title: 'Текст шаблона'
      },
      createdAt: {
        label: 'Создан',
        title: 'Дата создания шаблона'
      }
    },
    delete: {
      title: 'Удаление шаблона PUSH рассылки',
      message: 'Ви действительно хотите удалить шаблон {name}',
      button: 'Удалить',
      success: 'Шаблон рассылки удален'
    },
    template: {
      title: 'PUSH рассылки',
      source: 'Рассылка',
      button: 'Из рассылки'
    }
  },
  intervals: {
    title: 'Интервальная рассылка',
    intervalError: 'Выберите хотя бы один день',
    chooseCountry: 'Пожалуйста, выберите страну!',
    status: 'Статус',
    heads: {
      name: {
        label: 'Название',
        title: 'Системное название рассылки(Рассылок сделано)'
      }
    }
  },
  statistics: {
    count: {
      title: 'Количество PUSH',
      info: 'Количество активных собранных PUSH подписок за все время'
    },
    registered: {
      title: 'Количество регистраций',
      info: 'Количество уников которые зарегистрировались будучи при этом подписаны на PUSH'
    },
    deposited: {
      title: 'Количество депозитов',
      info: 'Количество уников которые внесли депозиты будучи при этом подписаны на PUSH'
    }
  },
  actions: {
    title: 'По действию',
    interval: {
      title: 'Через некоторое время',
      type: {
        minutes: 'Минут | Минута | Минуты | Минут',
        hours: 'Часов | Час | Часы | Часов',
        days: 'Дней | День | Дни | Дней',
        months: 'Месяцев | Месяц | Месяца | Месяцев'
      }
    },
    heads: {
      after: {
        label: 'После действия',
        title: 'Рассылка сработает после настроенного действия'
      }
    },
    after: {
      minutes: 'Через {count} минут | Через минуту | Через {count} минуты | Через {count} минут',
      hours: 'Через {count} часов | Через час | Через {count} часа | Через {count} часов',
      days: 'Через {count} дней | Через день | Через {count} дня | Через {count} дней',
      months: 'Через {count} месяцев | Через месяц | Через {count} месяца | Через {count} месяцев'
    }
  },
  group: {
    successRemove: 'PUSH рассылка удалена',
    successCopy: 'PUSH рассылка скопирована'
  },
  table: {
    publishedAt: 'Серверное время отправки по UTC 0 {time}'
  }
}
