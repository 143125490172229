export default {
  all: 'Todos',
  add: 'Adicionar um funcionário',
  deleteEmployee: 'Tem certeza que quer apagar utilizador?',
  employeeDeleted: 'Anotação apagada',
  employeeSaved: 'Entrada guardada',
  accessData: 'Dados para acesso do funcionário',
  link: 'Link para funcionário:',
  password: 'Palavra-chave:',
  name: 'Nome do funcionário',
  groups: 'Escolher ou criar um grupo',
  privacySettings: 'Configuração de privacidade',
  isPWAsOwner: 'Apenas seus PWA',
  customPWATag: 'Tag próprio para PWA',
  isCompaniesOwner: 'Apenas seus PUSH',
  customCompaniesTag: 'Seu tag para PUSH',
  isLandingsOwner: 'Apenas desembarques próprios',
  customLandingsTag: 'Etiqueta personalizada para os patamares',
  isTrackersOwner: 'Apenas seus trackers',
  customTrackerTag: 'Tag próprio para tracker',
  accessSettings: 'Configuração de acesso',
  view: 'Visualização',
  edit: 'Alteração',
  delete: 'Apagar',
  tracker: 'Tracker',
  statistics: 'Estatística',
  landings: 'Páginas de destino',
  teams: 'Trabalho de equipe',
  finance: 'Planos e finanças',
  logs: {
    title: 'Gravação de log de dados',
    country: 'País',
    device: 'Dispositivo',
    os: 'OS',
    browser: 'Navegador',
    login: 'Data de login'
  },
  validator: {
    username: {
      maxLength: 'O nome de utilizador deve ter menos de 30 caracteres'
    },
    access: {
      isOneAccess: 'O trabalhador deve ter pelo menos uma autorização'
    }
  },
  trash: {
    delete: 'Excluir funcionário',
    deleteMessage: 'Você tem certeza de que deseja excluir o funcionário {username}?',
    deleteButton: 'Excluir'
  }
}
