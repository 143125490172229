<script>
import ToggleItem from '@/components/landing-pages/ToggleItem.vue'
import formMixin from '@/mixins/form-mixin'

export default {
  components: {ToggleItem},
  props: {
    value: {
      type: Object,
      required: true
    },
    errors: Object
  },
  mixins: [formMixin],
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  },
  computed: {
    body: {
      get () {
        return this.value.body
      },
      set (input) {
        this.$emit('input', {...this.value, body: input})
      }
    },
    header: {
      get () {
        return this.value.header
      },
      set (input) {
        this.$emit('input', {...this.value, header: input})
      }
    },
    button: {
      get () {
        return this.value.button
      },
      set (input) {
        this.$emit('input', {...this.value, button: input})
      }
    }
  }
}
</script>

<template>
  <ToggleItem
    :header="$t('landingPages.modalForm.header')"
    :info="$t('landingPages.modalForm.info')"
  >
    <div class="row row_mob_sg">
      <div class="form-group col-12" :class="apiValidationErrors[`modal.header`] ? 'has-danger' : ''">
        <label class="label" for="modalHeader">{{ $t('landingPages.modalForm.modalHeader') }}</label>
        <input
          type="text"
          v-model="header"
          class="form-control"
          id="modalHeader"
          name="modalHeader"
        />
        <label v-if="apiValidationErrors[`modal.header`]"
               class="error mt-2 text-danger"
               :for="`modalHeader`">
          {{ $t(apiValidationErrors[`modal.header`][0]) }}
        </label>
      </div>
      <div class="form-group col-12" :class="apiValidationErrors[`modal.body`] ? 'has-danger' : ''">
        <label class="label" for="modalBody">{{ $t('landingPages.modalForm.buttonTitle') }}</label>
        <input
          type="text"
          v-model="body"
          class="form-control"
          id="modalBody"
          name="modalBody"
        />
        <label v-if="apiValidationErrors[`modal.body`]"
               class="error mt-2 text-danger"
               :for="`modalBody`">
          {{ $t(apiValidationErrors[`modal.body`][0]) }}
        </label>
      </div>
      <div class="form-group col-12" :class="apiValidationErrors[`modal.button`] ? 'has-danger' : ''">
        <label class="label" for="modalButton">{{ $t('landingPages.modalForm.modalButton') }}</label>
        <input
          type="text"
          v-model="button"
          class="form-control"
          id="modalButton"
          name="modalButton"
        />
        <label v-if="apiValidationErrors[`modal.button`]"
               class="error mt-2 text-danger"
               :for="`modalButton`">
          {{ $t(apiValidationErrors[`modal.button`][0]) }}
        </label>
      </div>
    </div>
  </ToggleItem>
</template>
