import UAParser from 'ua-parser-js'

export default input => {
  input = new UAParser(input)
  const browser = input.getBrowser()
  let result = ''
  if (browser.name) {
    result += browser.name
  }
  if (browser.version) {
    result += ' ' + browser.version
  }
  return result === '' ? 'Не понятно' : result
}
