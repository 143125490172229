<template>
  <tr :data-id="item._id">
    <td>
      <button class="btn btn-clipboard" type="button" data-clipboard-action="copy" :data-clipboard-text="item._id">{{ item._id }}
      </button>
    </td>
    <td>
      <button class="btn btn-icons btn-inverse-primary" @click="$parent.$refs.form.open(item._id)"><i
        class="icon-pencil"></i></button>
      <button class="btn btn-icons btn-inverse-danger" @click="handlerDelete"><i class="icon-trash"></i></button>
    </td>
    <td>
      <select class="form-control" v-model="enable">
        <option :key="item" v-for="item in bools" :value="item">
          {{ item | formatBoolean }}
        </option>
      </select>
    </td>
    <td>
      <select class="form-control" v-model="firstBuy">
        <option :key="item" v-for="item in bools" :value="item">
          {{ item | formatBoolean }}
        </option>
      </select>
    </td>
    <td>{{ item.limit }}</td>
    <td>{{ item.use }}</td>
    <td>{{ item.expires | dateFromNow }}</td>
    <td>{{ item.amount | amount }}</td>
    <td>{{ item.percent.toFixed(2) + ' %' }}</td>
    <td>{{ item.tariffs.length ? item.tariffs.join(', ') : 'Все' }}</td>
    <td>{{ item.createdAt | dateFromNow }}</td>
    <td>{{ item.updatedAt | dateFromNow }}</td>
  </tr>
</template>
<script>
export default {
  name: 'Field',
  props: ['item'],
  data: function () {
    return {
      enable: false,
      firstBuy: false,
      //
      watch: false,
      //
      bools: [true, false],
      types: ['Индивидуальный', 'Командный', 'Коммерческий']
    }
  },
  created: function () {
    for (const index in this.item) {
      if (this[index] !== undefined) {
        this[index] = this.item[index]
      }
    }
    setTimeout(() => {
      this.watch = true
    }, 500)
  },
  watch: {
    enable: {
      handler: async function (enable) {
        if (this.watch) {
          await this.$store.dispatch('adminVouchers/update', {id: this.item._id, enable})
          await this.$store.dispatch('alerts/success', 'Видимость обновлена')
        }
      }
    },
    firstBuy: {
      handler: async function (firstBuy) {
        if (this.watch) {
          await this.$store.dispatch('adminVouchers/update', {id: this.item._id, firstBuy})
          await this.$store.dispatch('alerts/success', firstBuy ? 'Ваучер доступен только при первой покупке' : 'Ваучер доступен всегда')
        }
      }
    }
  },
  methods: {
    handlerDelete: async function () {
      if (confirm('Удалить тариф?')) {
        await this.$store.dispatch('adminVouchers/delete', this.item._id)
        await this.$parent.asyncData()
        await this.$store.dispatch('alerts/success', 'Ваучер удалён')
      }
    }
  }
}
</script>
