import service from '@/store/services/bot-service'
import middleware from './middleware'

const state = () => ({
  models: [],
  count: 0,
  locales: {},
  offer: null
})

const mutations = {
  setPageData (state, {models, count}) {
    state.models = models
    state.count = count
  },
  setOffer (state, payload) {
    state.offer = payload
  },
  setLocales (state, payload) {
    state.locales = payload
  }
}

const actions = {
  async keysFetchData ({commit, dispatch}, options) {
    try {
      const data = await service.getListKey(options)
      commit('setPageData', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async deleteKey ({commit, dispatch}, options) {
    try {
      await service.deleteKey(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async getOffer ({commit, dispatch}, options) {
    try {
      const offer = await service.getOffer(options)
      commit('setOffer', offer)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async offersFetchData ({commit, dispatch}, options) {
    try {
      const data = await service.getListOffer(options)
      commit('setPageData', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async createOffer ({commit, dispatch}, options) {
    try {
      const offer = await service.createOffer(options)
      commit('setOffer', offer)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async updateOffer ({commit, dispatch}, options) {
    try {
      await service.updateOffer(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async uploadImage ({commit, dispatch}, options) {
    try {
      return await service.uploadImage(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async deleteImage ({commit, dispatch}, options) {
    try {
      await service.deleteImage(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async deleteOffer ({commit, dispatch}, options) {
    try {
      await service.deleteOffer(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminKeysFetchData ({commit, dispatch}, options) {
    try {
      const data = await service.adminGetListKeys(options)
      commit('setPageData', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminCreateKey ({commit, dispatch}, options) {
    try {
      return await service.adminCreateKey(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminDeleteKey ({commit, dispatch}, options) {
    try {
      await service.adminDeleteKey(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminLocalesFetchData ({commit, dispatch}, options) {
    try {
      const data = await service.adminGetListLocales(options)
      commit('setPageData', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminCreateLocale ({commit, dispatch}, options) {
    try {
      return await service.adminCreateLocale(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminDeleteLocale ({commit, dispatch}, options) {
    try {
      await service.adminDeleteLocale(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async localesFetchData ({commit, dispatch}, options) {
    try {
      const data = await service.getBotLocales(options)
      commit('setLocales', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  offer: state => state.offer,
  locales: state => state.locales
}

const bot = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default bot
