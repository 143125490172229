<template>
  <div class="content-wrapper">
    <postback-form ref="postbackForm"/>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Шаблоны постбеков</h4>
            <p class="card-description">Управления шаблонами постбеков</p>
            <div class="mb-2">
              <button @click="handlerCreate" type="button" class="btn btn-outline-success btn-fw">
                <i class="icon-plus"></i> Добавить
              </button>
            </div>
            <postbacks-filter @change="asyncData"/>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Название</th>
                  <th>Параметры</th>
                  <th>Состояние</th>
                  <th>Дата создания</th>
                  <th>Дата изменения</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model._id">
                  <td>
                    <button @click="handlerUpdate(model)" class="btn btn-icons btn-inverse-primary">
                      <i class="icon-pencil"></i>
                    </button>
                    <button @click="handlerDelete(model)" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                  </td>
                  <td>{{ model._id }}</td>
                  <td>{{ model.name }}</td>
                  <td>{{ model.params | truncate(20) }}</td>
                  <td>{{ model.enable ? 'Вкл' : 'Выкл' }}</td>
                  <td>{{ model.createdAt | formatDate }}</td>
                  <td>{{ model.updatedAt | formatDate }}</td>
                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import PostbackForm from './PostbackForm.vue'
import PostbacksFilter from './PostbacksFilter.vue'

export default {
  name: 'Postbacks',
  components: {PostbackForm, PostbacksFilter},
  data () {
    return {
      loading: true,
      page: 1,
      limit: 20,
      count: 0,
      models: []
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  methods: {
    async asyncData () {
      this.loading = true
      await this.$store.dispatch('adminPostbacks/asyncData',
        {
          limit: this.limit,
          page: this.page,
          sort: '-createdAt',
          filter: this.$store.getters['adminPostbacks/filter']
        })
      this.models = this.$store.getters['adminPostbacks/models']
      this.count = this.$store.getters['adminPostbacks/count']
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async handlerPagination (page) {
      this.page = page
      await this.asyncData()
    },
    async handlerCreate () {
      if (await this.$refs.postbackForm.handlerCreate()) {
        await this.handlerPagination(1)
      }
    },
    async handlerUpdate (model) {
      if (await this.$refs.postbackForm.handlerUpdate(model)) {
        await this.handlerPagination(1)
      }
    },
    async handlerDelete (model) {
      if (confirm(`Удалить постбек ${model._id}`)) {
        await this.$store.dispatch('adminPostbacks/delete', model._id)
        await this.handlerPagination(1)
      }
    }
  }
}
</script>
