import {mapActions} from 'vuex'
import GroupAction from '@/components/GroupAction.vue'

export default {
  components: {GroupAction},
  data () {
    return {
      group: []
    }
  },
  computed: {
    checkAll: {
      get () {
        if (this.models.length === 0) return false
        return this.group.length >= this.models.length
      },
      set (input) {
        this.models.map(model => {
          const el = document.getElementById(`check-${model.id}`)

          if (el.checked !== input) el.click()
        })
      }
    }
  },
  methods: {
    ...mapActions({
      groupCopy: 'pwasPushes/groupCopy',
      groupRemove: 'pwasPushes/groupRemove'
    }),
    handlerGroup (id) {
      const index = this.group.indexOf(id)
      if (index === -1) {
        this.group.push(id)
      } else {
        this.group.splice(index, 1)
      }
    },
    async handleActionGroup (action) {
      switch (action) {
        case 'remove':
          try {
            await this.groupRemove(this.group)
            this.alertSuccess(this.$t('pushes.group.successRemove', {count: this.group.length}))
            if (this.pageCount > 1) {
              if (this.models.length === 0) {
                this.page = 1
              } else if (this.count - this.group.length <= this.page * this.limit) {
                this.page = Math.ceil((this.count - this.group.length) / this.limit)
              }
            }
            this.fetchPage()
          } catch (e) {
            this.alertError(e.message)
          }
          break
        case 'copy':
          try {
            await this.groupCopy(this.group)
            this.alertSuccess(this.$t('pushes.group.successCopy', {count: this.group.length}))
            this.handlerPagination(1)
          } catch (e) {
            this.alertError(e.message)
          }
          break
      }
    }
  }
}
