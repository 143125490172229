export default {
  logout: 'Выход',
  mail: 'У вас нет новых сообщений | У вас одно новое сообщение | У вас {count} новых сообщения | У вас {count} новых сообщений',
  notifications: 'У вас нет новых уведомлений | У вас одно новое уведомление | У вас {count} новых уведомления | У вас {count} новых уведомлений',
  banners: {
    telegram: {
      title: 'Подпишитесь на наш телеграм канал <br>и телеграм чат',
      desc: 'Будьте в курсе последних новостей от PWA GROUP. Принимайте активное участие в обсуждении ПО!',
      chanelButton: 'Телеграм канал',
      chatButton: 'Телеграм чат'
    }
  }
}
