/* eslint-disable no-template-curly-in-string */
export default {
  method: 'Method',
  install: {
    label: 's2s postback on install ({enable})',
    info: 'The URL to which the event requests will be sent, details about the setting can be found in our F.A.Q.',
    setting: 'Install Settings'
  },
  open: {
    label: 's2s postback on open ({enable})',
    info: 'The URL to which the event requests will be sent, details about the setting can be found in our F.A.Q.',
    setting: 'Open setting'
  },
  reopen: {
    label: 's2s postback on re-entry ({enable})',
    info: 'The URL to which the event requests will be sent, details about the setting can be found in our F.A.Q.',
    setting: 'Relogin setting'
  },
  push: {
    label: 's2s postback on PUSH subscription ({enable})',
    info: 'The URL to which the event requests will be sent, details about the setting can be found in our F.A.Q.',
    setting: 'Setting up a PUSH subscription'
  },
  deposit: {
    label: 's2s postback on deposit ({enable})',
    info: 'The URL to which the event requests will be sent, details about the setting can be found in our F.A.Q.',
    setting: 'Deposit settings'
  },
  registration: {
    label: 's2s postback on registration ({enable})',
    info: 'The URL to which the event requests will be sent, details about the setting can be found in our F.A.Q.',
    setting: 'Registration Settings'
  }
}
