<template>
  <div class="BaseTag base-tag">
    <a
      @click.prevent="onTagClick(data)"
      v-if="clickable"
    >
      <span>{{ data }}</span>
    </a>
    <span v-else>{{ data }}</span>
  </div>
</template>
<script>
export default {
  name: 'BaseTag',
  props: {
    data: [String, Object],
    clickable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onTagClick (tag) {
      console.log('click', tag)
      this.$emit('tag-click', tag)
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.base-tag
  border-radius: 3px
  color: $primary-dark
  background-color: rgba($primary-dark, 0.1)
  padding: 5px 8px
  line-height: 18px
  font-size: 14px
  +font(400)
  cursor: pointer
</style>
