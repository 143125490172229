import {useStore} from '@/store'

export default function guest ({ next, router }) {
  const store = useStore()
  if (store.getters.isAuthenticated) {
    router.push({ name: 'PWAs' })
  } else {
    next()
  }
}
