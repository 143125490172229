import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {lang} from '@pwa.group/pwa.dictionary'
import axios from 'axios'
import ru from '@/locales/ru'
import en from '@/locales/en'
import uk from '@/locales/uk'
import pt from '@/locales/pt'
import { createI18n } from 'vue-i18n-composable'

Vue.use(VueI18n)

export const languages = ['ru', 'en', 'uk', 'pt']

let initLanguage = 'en'
if (languages.includes(navigator.language)) {
  initLanguage = navigator.language
}
const identity = JSON.parse(localStorage.getItem('identity'))

if (identity != null) {
  initLanguage = identity.lang
}

export const i18n = createI18n({
  locale: initLanguage, // set locale
  fallbackLocale: 'en',
  messages: {
    ru: {
      ...lang.ru,
      ...ru
    },
    en: {
      ...lang.en,
      ...en
    },
    uk: {
      ...lang.uk,
      ...uk
    },
    pt: {
      ...lang.pt,
      ...pt
    }
  },
  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    'ru': function (choice, choicesLength) {
      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return (choicesLength < 4) ? 2 : 3
    },
    'uk': function (choice, choicesLength) {
      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return (choicesLength < 4) ? 2 : 3
    }
  }
})

export function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function getFullLocale () {
  switch (i18n.locale) {
    case 'ru':
      return 'ru-RU'
    case 'uk':
      return 'uk-UA'
    case 'pt':
      return 'pt-PT'
    default:
      return 'en-US'
  }
}
