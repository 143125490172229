<template>
  <div class="TeamsTabs">
    <BaseRouterTabs
      v-if="groupsLoaded"
      :tabs="items"
      params="group"
      :with-translators="false"
    />
  </div>
</template>
<script>
import BaseRouterTabs from '@/components/base/BaseRouterTabs.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'TeamsTabs',
  components: {BaseRouterTabs},
  props: ['groups'],
  data: () => ({
    items: []
  }),
  watch: {
    groups: {
      handler () {
        this.init()
      }
    }
  },
  computed: {
    ...mapGetters('teams', [
      'groupsLoaded'
    ])
  },
  methods: {
    init: function () {
      this.items = [{
        label: this.$t('teams.all'),
        id: -1,
        to: {name: 'Teams'}
      }]
      for (const index in this.groups) {
        this.items.push({
          label: this.groups[index],
          id: index,
          to: {name: 'TeamsGroup', params: {group: index}}
        })
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.nav-item
  width: auto
.top-tabs
  margin-left: 0
  width: 100%
  &:before
    content: ''
    width: 41px
    height: 73px
    background: linear-gradient(180deg, #2B2F33 0%, rgba(43, 47, 51, 0) 100%)
    transform: rotate(90deg)
    position: absolute
    right: 0px
    top: 0px
    z-index: 2
    pointer-events: none
</style>
