<template>
  <div class="modal modal_center_g" v-bind:id="id" tabindex="-1">
    <div class="modal-dialog modal_info" role="document">
      <div class="modal-content modal_padd_normal">
        <div class="info_blk_g_mod text-center">
          <div class="icon_sinf_g">
            <img src="/images/icon_info_attention.png" alt="">
          </div>
          <div class="title_inf_glbk">{{ title }}</div>
          <div class="descr_inf_ico_g descr_site_shg mb-0" v-html="body"></div>
        </div>
        <div class="foot_inf_sg_g">
          <div class="in_to_foot_br_g">
            <div class="row has_big_rog">
              <div class="col-12 item_pwa_shs_g">
                <button data-dismiss="modal"
                        class="w-100 btn_simple_border d_flex align_center justify_center no_underline full_plin yell_col">
                  {{$t('general.close')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InfoModal',
  data () {
    return {
      title: '',
      body: ''
    }
  },
  props: ['id'],
  methods: {
    show: function () {
      // eslint-disable-next-line no-undef
      jQuery(`#${this.id}`).modal('show')
    },
    hide: function () {
      // eslint-disable-next-line no-undef
      jQuery(`#${this.id}`).modal('hide')
    }
  }
}
</script>
<style>
.title_inf_glbk {
  color: #F1C62D;
}
</style>
