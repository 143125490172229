import AdminIPs from './index.vue'

export default (meta, header) => {
  return [
    {
      path: 'ips',
      name: 'AdminIPs',
      components: {default: AdminIPs, header},
      meta
    }
  ]
}
