<template>
  <td>{{ installsToPush(value.installs, value.pushes) | percent }}</td>
</template>
<script>
import statisticsMixin from '@/mixins/statistics-mixin'

export default {
  name: 'InstallsToPush',
  mixins: [statisticsMixin],
  props: ['value']
}
</script>
