<template>
  <div>
    <slot name="before"></slot>
    <select :class="inputClass" :id="id" :name="name" :disabled="disabled" :required="required" :style="w100 ? 'width:100%!important;' : ''">
      <option value=""></option>
    </select>
    <slot name="after"></slot>
  </div>
</template>
<script>
export default {
  name: 'Select2',
  data () {
    return {
      select2: null
    }
  },
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    inputClass: {
      type: String,
      default: 'form-control'
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => ({})
    },
    w100: {
      type: Boolean,
      default: true
    },
    value: null
  },
  watch: {
    options (val) {
      this.setOption(val)
    },
    value (val) {
      this.setValue(val)
    }
  },
  methods: {
    setOption (val = []) {
      this.select2.empty()
      this.select2.select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: val
      })
      this.setValue(this.value)
    },
    setValue (val) {
      if (val instanceof Array) {
        this.select2.val([...val])
      } else {
        this.select2.val([val])
      }
      this.select2.trigger('change')
    }
  },
  mounted () {
    const options = {
      placeholder: this.placeholder,
      ...this.settings
    }
    if (this.options.length > 0) options.data = this.options
    // eslint-disable-next-line no-undef
    this.select2 = jQuery(this.$el)
      .find('select')
      .select2(options)
      .on('select2:select select2:unselect', ev => {
        if (ev['params']['_type'] === 'select') {
          this.$emit('change', this.select2.val())
        } else {
          this.$emit('change', this.settings.multiple ? [...this.select2.val()] : '')
        }
        this.$emit('select', ev['params']['data'])
        if (this.settings.ajax != null) {
          this.setValue(ev['params']['data'])
        }
      })
      .on('select2:closing', ev => {
        this.$emit('closing', ev)
      })
      .on('select2:close', ev => {
        this.$emit('close', ev)
      })
      .on('select2:opening', ev => {
        this.$emit('opening', ev)
      })
      .on('select2:open', ev => {
        this.$emit('open', ev)
      })
      .on('select2:clearing', ev => {
        this.$emit('clearing', ev)
      })
      .on('select2:clear', ev => {
        this.$emit('clear', ev)
      })
    this.setValue(this.value)
  },
  beforeDestroy () {
    this.select2.select2('destroy')
  }
}
</script>
