<template>
  <div class="item_toggsl_g">
    <div class="head_tit_sl_togg">
      <div class="in_to_togg_sj_f d_flex align_center">
        <span>{{ $t('pwas.components.app.rating.title') }}</span>
        <info :message="$t('pwas.components.app.rating.titleInfo')"/>
      </div>
    </div>
    <div class="content_dropd_g">
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.reviews_label ? 'has-danger' : ''">
          <label for="reviews_label">{{ $t('pwas.components.app.rating.reviews') }}
            <info :message="$t('pwas.components.app.rating.reviewsInfo')"/>
          </label>
          <input type="text" id="reviews_label" :placeholder="$t('pwas.components.app.rating.reviews')"
                 v-model="value.languages[lang].reviews_label" class="form-control"
                 :class="apiValidationErrors.reviews_label ? 'form-control-danger' : ''"
                 @focus="clearError('reviews_label')" v-if="lang">
          <input type="text" id="reviews_label" :placeholder="$t('pwas.components.app.rating.reviews')"
                 v-model="value.reviews_label"
                 class="form-control"
                 :class="apiValidationErrors.reviews_label ? 'form-control-danger' : ''"
                 @focus="clearError('reviews_label')" v-else>
          <label v-if="apiValidationErrors.reviews_label" class="error mt-2 text-danger"
                 for="reviews_label">
            {{ apiValidationErrors.reviews_label[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob" :class="apiValidationErrors.rating ? 'has-danger' : ''">
          <label for="rating">{{ $t('pwas.components.app.rating.title') }}
            <info :message="$t('pwas.components.app.rating.ratingInfo')"/>
          </label>
          <input type="number" step="0.1" min="1" max="5" id="rating"
                 :placeholder="$t('pwas.components.app.rating.title')"
                 v-model="value.rating" class="form-control"
                 :class="apiValidationErrors.rating ? 'form-control-danger' : ''"
                 @focus="clearError('rating')">
          <label v-if="apiValidationErrors.rating" class="error mt-2 text-danger" for="rating">
            {{ apiValidationErrors.rating[0] }}
          </label>
        </div>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_5 ? 'has-danger' : ''">
        <label for="rating_bar_5">{{ $t('pwas.components.app.rating.count', {num: 5}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_5"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_5" class="form-control"
               :class="apiValidationErrors.rating_bar_5 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_5')">
        <label v-if="apiValidationErrors.rating_bar_5" class="error mt-2 text-danger"
               for="rating_bar_5">
          {{ apiValidationErrors.rating_bar_5[0] }}
        </label>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_4 ? 'has-danger' : ''">
        <label for="rating_bar_4">{{ $t('pwas.components.app.rating.count', {num: 4}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_4"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_4" class="form-control"
               :class="apiValidationErrors.rating_bar_4 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_4')">
        <label v-if="apiValidationErrors.rating_bar_4" class="error mt-2 text-danger"
               for="rating_bar_4">
          {{ apiValidationErrors.rating_bar_4[0] }}
        </label>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_3 ? 'has-danger' : ''">
        <label for="rating_bar_3">{{ $t('pwas.components.app.rating.count', {num: 3}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_3"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_3" class="form-control"
               :class="apiValidationErrors.rating_bar_3 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_3')">
        <label v-if="apiValidationErrors.rating_bar_3" class="error mt-2 text-danger"
               for="rating_bar_3">
          {{ apiValidationErrors.rating_bar_3[0] }}
        </label>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_2 ? 'has-danger' : ''">
        <label for="rating_bar_2">{{ $t('pwas.components.app.rating.count', {num: 2}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_2"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_2" class="form-control"
               :class="apiValidationErrors.rating_bar_2 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_2')">
        <label v-if="apiValidationErrors.rating_bar_2" class="error mt-2 text-danger"
               for="rating_bar_2">
          {{ apiValidationErrors.rating_bar_2[0] }}
        </label>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.rating_bar_1 ? 'has-danger' : ''">
        <label for="rating_bar_1">{{ $t('pwas.components.app.rating.count', {num: 1}) }}</label>
        <input type="number" step="0.01" min="0" max="100" id="rating_bar_1"
               :placeholder="$t('pwas.components.app.rating.title')"
               v-model="value.rating_bar_1" class="form-control"
               :class="apiValidationErrors.rating_bar_1 ? 'form-control-danger' : ''"
               @focus="clearError('rating_bar_1')">
        <label v-if="apiValidationErrors.rating_bar_1" class="error mt-2 text-danger"
               for="rating_bar_1">
          {{ apiValidationErrors.rating_bar_1[0] }}
        </label>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.reviewsVerified ? 'has-danger' : ''">
          <label for="reviewsVerified">{{ $t('pwas.components.app.rating.reviewsVerified') }}
            <info :message="$t('pwas.components.app.rating.reviewsVerifiedInfo')"/>
          </label>
          <input v-if="lang" type="text" id="reviewsVerified" :placeholder="$t('pwas.components.app.rating.reviewsVerified')"
                 v-model="value.languages[lang].reviewsVerified" class="form-control"
                 :class="apiValidationErrors.reviewsVerified ? 'form-control-danger' : ''"
                 @focus="clearError('reviewsVerified')">
          <input v-else type="text" id="reviewsVerified" :placeholder="$t('pwas.components.app.rating.reviewsVerified')"
                 v-model="value.reviewsVerified" class="form-control"
                 :class="apiValidationErrors.reviewsVerified ? 'form-control-danger' : ''"
                 @focus="clearError('reviewsVerified')">
          <label v-if="apiValidationErrors.reviewsVerified" class="error mt-2 text-danger"
                 for="reviewsVerified">
            {{ apiValidationErrors.reviewsVerified[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.reviewHelp ? 'has-danger' : ''">
          <label for="reviewHelp">{{ $t('pwas.components.app.rating.reviewHelp') }}
            <info :message="$t('pwas.components.app.rating.reviewHelpInfo')"/>
          </label>
          <input v-if="lang" type="text" id="reviewHelp" :placeholder="$t('pwas.components.app.rating.reviewHelp')"
                 v-model="value.languages[lang].reviewHelp" class="form-control"
                 :class="apiValidationErrors.reviewHelp ? 'form-control-danger' : ''"
                 @focus="clearError('reviewHelp')">
          <input v-else type="text" id="reviewHelp" :placeholder="$t('pwas.components.app.rating.reviewHelp')"
                 v-model="value.reviewHelp" class="form-control"
                 :class="apiValidationErrors.reviewHelp ? 'form-control-danger' : ''"
                 @focus="clearError('reviewHelp')">
          <label v-if="apiValidationErrors.reviewHelp" class="error mt-2 text-danger"
                 for="reviewHelp">
            {{ apiValidationErrors.reviewHelp[0] }}
          </label>
        </div>
      </div>
      <div class="row row_mob_sg">
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.reviewHelpTitle ? 'has-danger' : ''">
          <label for="reviewHelpTitle">{{ $t('pwas.components.app.rating.reviewHelpTitle') }}
            <info :message="$t('pwas.components.app.rating.reviewHelpTitleInfo')"/>
          </label>
          <input v-if="lang" type="text" id="reviewHelpTitle" :placeholder="$t('pwas.components.app.rating.reviewHelpTitle')"
                 v-model="value.languages[lang].reviewHelpTitle" class="form-control"
                 :class="apiValidationErrors.reviewHelpTitle ? 'form-control-danger' : ''"
                 @focus="clearError('reviewHelpTitle')">
          <input v-else type="text" id="reviewHelpTitle" :placeholder="$t('pwas.components.app.rating.reviewHelpTitle')"
                 v-model="value.reviewHelpTitle" class="form-control"
                 :class="apiValidationErrors.reviewHelpTitle ? 'form-control-danger' : ''"
                 @focus="clearError('reviewHelpTitle')">
          <label v-if="apiValidationErrors.reviewHelpTitle" class="error mt-2 text-danger"
                 for="reviewHelpTitle">
            {{ apiValidationErrors.reviewHelpTitle[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.reviewHelpYes ? 'has-danger' : ''">
          <label for="reviewHelpYes">{{ $t('pwas.components.app.rating.reviewHelpYes') }}
            <info :message="$t('pwas.components.app.rating.reviewHelpYesInfo')"/>
          </label>
          <input v-if="lang" type="text" id="reviewHelpYes" :placeholder="$t('pwas.components.app.rating.reviewHelpYes')"
                 v-model="value.languages[lang].reviewHelpYes" class="form-control"
                 :class="apiValidationErrors.reviewHelpYes ? 'form-control-danger' : ''"
                 @focus="clearError('reviewHelpYes')">
          <input type="text" id="reviewHelpYes" :placeholder="$t('pwas.components.app.rating.reviewHelpYes')"
                 v-model="value.reviewHelpYes" class="form-control"
                 :class="apiValidationErrors.reviewHelpYes ? 'form-control-danger' : ''"
                 @focus="clearError('reviewHelpYes')">
          <label v-if="apiValidationErrors.reviewHelpYes" class="error mt-2 text-danger"
                 for="reviewHelpYes">
            {{ apiValidationErrors.reviewHelpYes[0] }}
          </label>
        </div>
        <div class="form-group col col_half_mob"
             :class="apiValidationErrors.reviewHelpNo ? 'has-danger' : ''">
          <label for="reviewHelpNo">{{ $t('pwas.components.app.rating.reviewHelpNo') }}
            <info :message="$t('pwas.components.app.rating.reviewHelpNoInfo')"/>
          </label>
          <input v-if="lang" type="text" id="reviewHelpNo" :placeholder="$t('pwas.components.app.rating.reviewHelpNo')"
                 v-model="value.languages[lang].reviewHelpNo" class="form-control"
                 :class="apiValidationErrors.reviewHelpNo ? 'form-control-danger' : ''"
                 @focus="clearError('reviewHelpNo')">
          <input v-else type="text" id="reviewHelpNo" :placeholder="$t('pwas.components.app.rating.reviewHelpNo')"
                 v-model="value.reviewHelpNo" class="form-control"
                 :class="apiValidationErrors.reviewHelpNo ? 'form-control-danger' : ''"
                 @focus="clearError('reviewHelpNo')">
          <label v-if="apiValidationErrors.reviewHelpNo" class="error mt-2 text-danger"
                 for="reviewHelpNo">
            {{ apiValidationErrors.reviewHelpNo[0] }}
          </label>
        </div>
      </div>
      <div class="form-group"
           :class="apiValidationErrors.reviewAll ? 'has-danger' : ''">
        <label for="reviewAll">{{ $t('pwas.components.app.rating.reviewAll') }}
          <info :message="$t('pwas.components.app.rating.reviewAllInfo')"/>
        </label>
        <input v-if="lang" type="text" id="reviewAll" :placeholder="$t('pwas.components.app.rating.reviewAll')"
               v-model="value.languages[lang].reviewAll" class="form-control"
               :class="apiValidationErrors.reviewAll ? 'form-control-danger' : ''"
               @focus="clearError('reviewAll')">
        <input v-else type="text" id="reviewAll" :placeholder="$t('pwas.components.app.rating.reviewAll')"
               v-model="value.reviewAll" class="form-control"
               :class="apiValidationErrors.reviewAll ? 'form-control-danger' : ''"
               @focus="clearError('reviewAll')">
        <label v-if="apiValidationErrors.reviewAll" class="error mt-2 text-danger"
               for="reviewAll">
          {{ apiValidationErrors.reviewAll[0] }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'

export default {
  name: 'NewRatingApp',
  mixins: [formMixin],
  components: {Info},
  props: {
    value: {
      type: Object,
      require: true
    },
    errors: {
      type: Object,
      require: true
    }
  },
  computed: {
    lang () {
      return this.$route.params.language
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  }
}
</script>
