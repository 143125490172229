<template>
  <div class="PageFAQ page-full-fluid-width parent_tooltip">
    <div class="container_g">

<!--      <alert />-->

<!--      <div class="header_faq_sjg">-->
<!--        <div class="in_to_faq_go d_flex_center justify_between">-->
<!--          <div class="title_djb_g flex_grow_0">Чем мы можем вам помочь?</div>-->
<!--          <div class="search_khgfg d_flex_center flex_grow_1">-->
<!--            <input type="search" placeholder="Найти ответы" class="form_contr_search" @input="search">-->
<!--            <button class="btn_search_g ful_butt_green d_flex align_center justify_center">Искать</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- <div class="mobile_selecT_cjshs_cat">
        <select class="style_ths_js_gdf" v-model="active">
          <option v-for="category of categories" :key="category._id" v-bind:value="category._id">{{
              category.name
            }}
          </option>
        </select>
      </div> -->
      <div class="content_faq_gdjg clearfix">
        <div class="right_cont_quest">
          <div v-if="faqListLoaded && !faqList.length" class="emtpy_faq">
            <p>Не найдено записей которые удовлетворили бы ваш запрос</p>
          </div>
          <div class="loading_cont_go" v-show="faqListLoading">
            <div class="in_to_load_sf d_flex justify_center">
              <img src="/images/loader.svg" alt="" width="100">
            </div>
          </div>
          <div class="blk_quesiths_g" v-if="!faqListLoading && faqListLoaded">
            <BaseAccordion
              v-for="article of faqList"
              :key="article.id"
              @click.native="onAccordionItemClick(article.id, Boolean(article.content))"
              :delay="100"
            >
              <template #title>
                {{ article.question }}
              </template>
              <template #content>
                <transition>
                  <div class="loading_cont_go" v-if="!article?.loaded && !article?.content">
                    <div class="in_to_load_sf d_flex justify_center">
                      <img src="/images/loader.svg" alt="" width="100">
                    </div>
                  </div>
                  <div v-else v-html="decodeFaqItemContent(article?.content)"></div>
                </transition>
              </template>
            </BaseAccordion>
          </div>
          <nav v-if="isNavigation && !faqListLoading">
            <paginate ref="paginate"
              v-model="page"
              :page-count="pageCount"
              :click-handler="onPaginationClick"
              :prev-text="'<i class=\'icon-arrow-left\'></i>'"
              :next-text="'<i class=\'icon-arrow-right\'></i>'"
              :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
              :page-class="'page_item_gd'"
              :page-link-class="'page_link_g d_flex justify_center align_center'"
              :prev-class="'page-item_go'"
              :next-class="'page-item_go'"
              :prev-link-class="'page-link_go d_flex justify_center align_center'"
              :next-link-class="'page-link_go d_flex justify_center align_center'"
              :active-class="'active'">
            </paginate>
          </nav>
        </div>
        <div class="left_cont_cat">
          <div class="block_Cats_gjh">
            <div class="item_Catdh_g" v-for="category of categoriesNavigation" :key="category.id">
              <a @click="onCategoryClick(category.id)" class="d_flex_center"
                 :class="category.id === activeCategory ? 'current_cat': ''">
                {{ category.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3"></div>
  </div>
</template>
<script>
import titleMixin from '@/mixins/title-mixin'
import BaseAccordion from '@/components/base/BaseAccordion/BaseAccordion.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FAQ',
  components: { BaseAccordion },
  title: 'F.A.Q.',
  mixins: [titleMixin],
  data: function () {
    return {
      loading: true,
      page: 1,
      activeCategory: 'all',
      limit: 10
    }
  },

  async mounted () {
    this.loading = false
    await this.getFaqCategories()
    await this.loadFaqList('all')
  },
  computed: {
    ...mapGetters('faqStore', [
      'categories',
      'faqList',
      'faqListLoading',
      'faqListCount',
      'faqListLoaded'
    ]),
    categoriesNavigation () {
      const all = { id: 'all', name: 'Все' }
      return [ all, ...this.categories ]
    },
    pageCount () {
      return Math.ceil(this.faqListCount / this.limit)
    },
    isNavigation () {
      return this.pageCount > 1 && !this.loading && this.faqList.length
    }
  },
  methods: {
    ...mapActions('faqStore', [
      'getFaqCategories',
      'getFaqByCategory',
      'getFaqItemContentById'
    ]),
    loadFaqList (id) {
      const filter = id === 'all' ? '' : id
      this.getFaqByCategory(
        {
          filter: filter,
          page: this.page,
          limit: this.limit,
          sort: 'order'
        }
      )
    },
    onCategoryClick (id) {
      if (this.activeCategory !== id) this.page = 1
      this.activeCategory = id
      this.loadFaqList(id)
    },
    onPaginationClick (page) {
      this.page = page
      this.onCategoryClick(this.activeCategory)
    },
    onAccordionItemClick (id, isContent) {
      if (!isContent) this.getFaqItemContentById(id)
    },
    decodeFaqItemContent (content) {
      // TODO check another method
      const decode = document.createElement('textarea')
      decode.innerHTML = content
      return decode.innerText
    }
  }
}
</script>
<style scoped>
@import '/css/faq.css';
.right_cont_quest {
  min-height: 30vh
}
.right_cont_quest {
  position: relative;
  height: 100%
}
.loading_cont_go {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9;
  display: flex;
  height: 100%;
  transform: translate(-50%, -50%)
}
.item_Catdh_g {
  cursor: pointer;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.content_faq_gdjg {
  display: flex;
  flex-direction: row;
  background: transparent;
}
.right_cont_quest {
  order: 2
}

</style>
