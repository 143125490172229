<template>
  <modal
    ref="modal"
    id="auth-data-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Данные для доступа сотрудника</h5>
      <button type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group" >
        <label for="link">Ссылка</label>
        <div class="input-group">
          <input class="form-control" id="link" name="link" type="text" tabindex="1"
                 v-model="link" readonly>
          <div class="input-group-append">
            <button type="button" class="btn btn-info btn-clipboard ml-2" data-clipboard-action="copy"
                    data-clipboard-target="#link">Копировать
            </button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="password">Пароль</label>
        <div class="input-group">
          <input class="form-control" id="password" name="password" type="text" tabindex="1"
                 v-model="password" readonly>
          <div class="input-group-append">
            <button type="button" class="btn btn-info btn-clipboard ml-2" data-clipboard-action="copy"
                    data-clipboard-target="#password">Копировать
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button @click="handlerCancel" class="btn btn-info btn-wf"><i class="icon-shield"></i> Отмена</button>
  </div>
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'

export default {
  name: 'AuthData',
  components: {Modal},
  data () {
    return {
      id: '',
      password: ''
    }
  },
  computed: {
    link: function () {
      return window.location.origin + '/auth/team/' + this.id
    }
  },
  methods: {
    show (payload) {
      this.password = payload.password
      this.id = payload._id
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
    }
  }
}
</script>
