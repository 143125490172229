<template>
  <div class="row">
    <div class="col-md-4">
      <router-link :to="link"><img :src="avatar"
                                   :alt="username"></router-link>
    </div>
    <div class="col-md-8">
      <a :href="telegramLink" target="_blank" class="d-block">{{ telegramUsername }}</a>
      <span class="text-gray mt-2 d-block">{{ name }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Owner',
  props: ['data'],
  data: () => ({
    username: '',
    photo_url: '',
    first_name: '',
    last_name: '',
    id: ''
  }),
  created: function () {
    for (const [prop, value] of Object.entries(this.data)) {
      if (this[prop] != null) {
        this[prop] = value
      }
    }
  },
  computed: {
    avatar: function () {
      return this.photo_url ? this.photo_url : '/images/no-avatar.jpg'
    },
    telegramLink: function () {
      return `https://t.me/${this.username}`
    },
    telegramUsername: function () {
      return '@' + this.$options.filters.truncate(this.username, 13, '...')
    },
    name: function () {
      let name = ''
      if (this.first_name) {
        name += this.$options.filters.truncate(this.username, 7, '')
      }
      if (this.last_name) {
        name += ' ' + this.$options.filters.truncate(this.last_name, 7, '')
      }
      return name
    },
    link: function () {
      return {name: 'AdminUser', params: {id: this.id}}
    }
  }
}
</script>
