import api from './api'

export default {
  dates: payload => api.get('statistics/dates?' + new URLSearchParams(payload).toString()),
  uniques: payload => api.get('statistics/uniques?' + new URLSearchParams(payload).toString()),
  countries: payload => api.get('statistics/countries?' + new URLSearchParams(payload).toString()),
  pepperA: payload => api.get('statistics/pepper/a?' + new URLSearchParams(payload).toString()),
  welcomepWp: payload => api.get('statistics/welcomep/wp?' + new URLSearchParams(payload).toString()),
  main: () => api.get('dashboard')
}
