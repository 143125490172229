/* eslint-disable no-template-curly-in-string */
export default {
  title: 'Cria um PWA novo',
  newPwa: 'PWA novo',
  body: 'Para criar um PWA novo é necessário adicionar o domínio da aplicação. O domínio obrigatoriamente deve ser "coberto" de <a href="https://www.cloudflare.com/" target="_blank">CloudFlare</a>',
  domain: 'Introduza o domínio',
  domainTitle: 'Introduza o seu domínio com https protocolo e coberto de Cloudflare',
  auto: 'Configuração automática',
  pair: 'Acessos guardados',
  pairTitle: 'Escolher o par guardado de email(apikey) para adicionar o domínio ou adiciona o novo par',
  apiEmail: 'Introduza Cloudflare do email',
  apiEmailTitle: 'Introduza o seu Cloudflare email, que seja usado para configuração',
  apiKey: 'Introduza Cloudflare Api Key',
  apiKeyTitle: 'Introduza o seu Cloudflare api key, que seja usado para configuração',
  apiHelp: 'Para configuração do domínio é necessário receber API Key no perfil de Cloudflare, para este fim segue o link <a href="https://dash.cloudflare.com/profile/api-tokens" target="_blank" class="link_green">API Keys</a> e receba o Global API Key',
  success: 'PWA foi criado',
  self: 'Associar seu',
  chooseAccount: 'Voltar a escolha da conta',
  buyDomain: 'Comprar o domínio (${pwaDomainCost})',
  buySuccess: 'Domínio foi pago com sucesso',
  bought: 'Domínio adquiridos',
  boughtInfo: '<p class="card-description">Domínios comprados antes e não usados atualmente</p>',
  marketInfo: '<p class="card-description">preço de 1 domínio = <strong>${pwaDomainCost}</strong><br><Strong>Importante!</Strong><br>Adquirindo o nosso domínio concorda que, em caso de bloqueio do domínio por <strong>agente de registo</strong> ou <strong>Cloudflare</strong>, não pode reclamar junto ao serviço</p>',
  apiRequired: 'Acessos de API ao CloudFlare são de preenchimento obrigatório',
  deleteCloudflare: 'Apagar a conta de Cloudflare?'
}
