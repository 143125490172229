<template>
  <td><i :class="`flag-icon flag-icon-${value}`" data-toggle="tooltip" :title="getCountryName(value)" style="cursor: pointer"></i></td>
</template>
<script>
import * as dictionary from '@pwa.group/pwa.dictionary'

export default {
  name: 'CountryColumn',
  props: ['value'],
  methods: {
    getCountryName (code) {
      const index = dictionary.countries.findIndex(country => country.id?.toLowerCase() === code?.toLowerCase())
      return this.$t(dictionary.countries[index]?.text)
    }
  }
}
</script>
