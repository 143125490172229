<template>
  <modal
    ref="modal"
    class="modal modal_center_g modal_telegram"
    id="subscribe_telegram"
    classDialog="modal-dialog"
    classContent="modal-content">
    <button
      @click="$refs.modal.close()"
      type="button" class="close_modal d_flex align_center">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 1L1 17" stroke="#434242" stroke-width="2" stroke-linecap="round"/>
        <path d="M17 17L1 1" stroke="#434242" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </button>
    <div class="image_svg_modl_g">
      <div class="in_to_tel_gdg">
        <telegram-subscribe/>
        <div class="blic_telegram_mod">
          <div class="gold-blink gold-blink_medium gold-blink_1"></div>
        </div>
      </div>
    </div>
    <div class="texts_sub_cont text_center">
      <div class="title_subscrib" v-html="$t('layouts.banners.telegram.title')"></div>
      <div class="descr_subscrib">{{ $t('layouts.banners.telegram.desc') }}</div>
    </div>
    <div class="wrap_btn_sub d_flex align_center justify_center">
      <div class="item_btn_subsc">
        <a href="https://t.me/+zriGtkoAHuNiMmQ6" class="btn_telegram_chanel d_flex align_center justify_center" target="_blank">
          <svg class="icon_tel_cjh" width="31" height="26" viewBox="0 0 31 26" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.13109 11.0598C10.4526 7.43428 16.0015 5.04411 18.778 3.88929C26.7052 0.592062 28.3525 0.019298 29.4261 0.000183357C29.6622 -0.00377469 30.1902 0.0547464 30.5322 0.33226C30.821 0.566587 30.9005 0.88313 30.9385 1.1053C30.9765 1.32747 31.0239 1.83357 30.9862 2.22902C30.5566 6.74268 28.6978 17.6961 27.7522 22.7515C27.3521 24.8906 26.5642 25.6079 25.8014 25.6781C24.1437 25.8306 22.885 24.5826 21.2794 23.5301C18.7671 21.8832 17.3478 20.8581 14.9091 19.251C12.0908 17.3938 13.9178 16.373 15.5239 14.7048C15.9443 14.2683 23.248 7.62495 23.3894 7.0223C23.407 6.94693 23.4235 6.66598 23.2565 6.51763C23.0896 6.36928 22.8433 6.42001 22.6655 6.46035C22.4136 6.51754 18.4001 9.17032 10.625 14.4187C9.48582 15.201 8.45395 15.5821 7.52943 15.5621C6.51023 15.5401 4.54968 14.9859 3.09221 14.5121C1.30457 13.931 -0.116208 13.6238 0.00751004 12.6369C0.0719501 12.1229 0.77981 11.5972 2.13109 11.0598Z"
              fill="white"/>
          </svg>
          {{ $t('layouts.banners.telegram.chanelButton') }}
        </a>
      </div>
      <div class="item_btn_subsc">
        <a href="https://t.me/+xng2A9JyT-E3NTky" class="btn_telegram_chat btn_subsc_goo d_flex align_center justify_center" target="_blank">
          <svg class="icon_tel_cjh" width="32" height="26" viewBox="0 0 32 26" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.63109 11.0598C10.9526 7.43428 16.5015 5.04411 19.278 3.88929C27.2052 0.592062 28.8525 0.019298 29.9261 0.000183357C30.1622 -0.00377469 30.6902 0.0547464 31.0322 0.33226C31.321 0.566587 31.4005 0.88313 31.4385 1.1053C31.4765 1.32747 31.5239 1.83357 31.4862 2.22902C31.0566 6.74268 29.1978 17.6961 28.2522 22.7515C27.8521 24.8906 27.0642 25.6079 26.3014 25.6781C24.6437 25.8306 23.385 24.5826 21.7794 23.5301C19.2671 21.8832 17.8478 20.8581 15.4091 19.251C12.5908 17.3938 14.4178 16.373 16.0239 14.7048C16.4443 14.2683 23.748 7.62495 23.8894 7.0223C23.907 6.94693 23.9235 6.66598 23.7565 6.51763C23.5896 6.36928 23.3433 6.42001 23.1655 6.46035C22.9136 6.51754 18.9001 9.17032 11.125 14.4187C9.98582 15.201 8.95395 15.5821 8.02943 15.5621C7.01023 15.5401 5.04968 14.9859 3.59221 14.5121C1.80457 13.931 0.383792 13.6238 0.50751 12.6369C0.57195 12.1229 1.27981 11.5972 2.63109 11.0598Z"
              fill="#6CA8E5"/>
          </svg>
          {{ $t('layouts.banners.telegram.chatButton') }}
        </a>
      </div>
    </div>
  </modal>
</template>
<script>
import TelegramSubscribe from '@/components/images/TelegramSubscribe.vue'
import Modal from './Modal.vue'

export default {
  name: 'TelegramModal',
  components: {Modal, TelegramSubscribe},
  mounted () {
    setTimeout(() => {
      if (document.cookie.search('TelegramBanner') === -1) {
        this.$refs.modal.open()
        const date = new Date()
        date.setFullYear(date.getFullYear() + 10)
        document.cookie = 'TelegramBanner=true; expires=' + date.toUTCString() + ';'
      }
    }, 10000)
  }
}
</script>
<style>
.title_inf_glbk {
  color: #F1C62D;
}
</style>
