<template>
  <div class="content-wrapper">
    <settings-form ref="settingsForm"/>
    <admin-user-form ref="adminUserForm"/>
    <mail ref="mail"/>
    <moves ref="moves"/>
    <div class="row grid-margin">
      <users-statistics :filters="filters"/>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Пользователи</h4>
            <div v-if="isAdmin" class="mb-2">
              <button type="button" class="btn btn-outline-success btn-fw"
                      @click="$refs.mail.write({ filters, count })"><i
                class="icon-envelope"></i>Написать всем
              </button>
              <!--button type="button" class="btn btn-outline-success btn-fw" @click="$refs.moves.open()"><i
                class="icon-shuffle"></i>Перенос аккаунтов
              </button-->
              <button
                @click="$refs.settingsForm.handlerOpen()"
                type="button" class="btn btn-outline-secondary btn-fw">
                <i class="icon-settings"></i> Налаштування
              </button>
            </div>
            <p class="card-description">Список всіх користувачів в системі</p>
            <div class="row">
              <owner-filter class="col-lg-4 col-md-4 col-sm-6 mb-2" v-model="filters._id"/>
              <div v-if="isAdmin" class="col-lg-4 col-md-4 col-sm-6 mb-2">
                <select class="form-control" v-model="filters.online">
                  <option value="" selected>Фильтр по Online</option>
                  <option value="1">В сети</option>
                  <option value="0">Отдыхает</option>
                </select>
              </div>
              <div v-if="isAdmin" class="col-lg-4 col-md-4 col-sm-6 mb-2">
                <select class="form-control" v-model="filters.tariff">
                  <option value="" selected>Тариф</option>
                  <option v-for="(tariff, id) in $store.getters['adminTariff/models']" :key="id" :value="tariff.id">
                    {{ tariff.name.ru }}
                  </option>
                </select>
              </div>
              <admin-filter-metrics v-model="filters.metrics" class="col-lg-4 col-md-4 col-sm-6 mb-2"/>
              <div v-if="isAdmin" class="col-lg-4 col-md-4 col-sm-6 mb-2">
                <select class="form-control" v-model="filters.permissionLevel">
                  <option value="" selected>Доступ</option>
                  <option v-for="(label, id) in $store.getters['adminUsers/permissionLevel']" :key="id" :value="id">
                    {{ label }}
                  </option>
                </select>
              </div>
              <div v-if="isAdmin" class="col-lg-4 col-md-4 col-sm-6 mb-2">
                <select class="form-control" v-model="filters.state">
                  <option value="" selected>Фильтр по состоянию</option>
                  <option value="0">Активен</option>
                  <option value="1">Истек</option>
                </select>
              </div>
            </div>
            <loading v-model="loading" class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th v-if="isAdmin">Действия</th>
                  <th style="min-width: 220px;">Телеграм</th>
                  <th v-if="isAdmin">Баланс</th>
                  <th>Мітка</th>
                  <th v-if="isAdmin">Тариф</th>
                  <th>Последний вход</th>
                  <th>Зареган</th>
                  <th>Изменен</th>
                  <th v-if="isAdmin">Роль</th>
                  <th v-if="isAdmin">Реф. ссылка</th>
                </tr>
                </thead>
                <tbody>
                <table-row
                  v-for="item in models"
                  :key="item.id"
                  :value="item"
                  @input="fetchPage"
                  @open-privacy="$refs.ModalPrivacy.handlerOpen()"
                />
                </tbody>
                <tfoot>
                <tr>
                  <td colspan="8">Всего: <span style="font-weight: bold;">{{ count }}</span></td>
                </tr>
                </tfoot>
              </table>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </loading>
          </div>
        </div>
      </div>
    </div>
    <ModalPrivacy ref="ModalPrivacy"/>
  </div>
</template>
<script>
import _ from 'lodash'
import Loading from '@/pages/admin/components/Loading.vue'
import {OwnerFilter} from '../components'
import Row from './components/tables/Row.vue'
import Moves from './components/Moves.vue'
import Mail from './components/Mail.vue'
import AdminUserForm from './_form.vue'
import UsersStatistics from './UsersStatistics.vue'
import AdminFilterMetrics from '@/components/admin/filter/Metrics.vue'
import {mapActions, mapGetters} from 'vuex'
import SettingsForm from '@/components/admin/users/SettingsForm.vue'
import ModalPrivacy from './modals/ModalPrivacy.vue'

export default {
  name: 'AdminUsers',
  components: {
    Loading,
    AdminFilterMetrics,
    OwnerFilter,
    'table-row': Row,
    Mail,
    Moves,
    AdminUserForm,
    UsersStatistics,
    SettingsForm,
    ModalPrivacy
  },
  data () {
    return {
      loading: true,
      metrics: [],
      limit: 20,
      page: 1,
      filters: {
        permissionLevel: '',
        _id: '',
        online: '',
        state: '',
        tariff: '',
        createdAt: '',
        metrics: ''
      },
      metricsSettings: {}
    }
  },
  watch: {
    filters: {
      handler () {
        this.handlerPagination()
      },
      deep: true
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    ...mapGetters({
      isAdmin: 'isAdmin',
      models: 'adminUsers/models',
      count: 'adminUsers/count'
    })
  },
  async created () {
    await this.fetchIPs({
      page: 1,
      limit: 100,
      sort: 'id'
    })
    await this.$store.dispatch('adminTariff/asyncData')
    await this.handlerPagination()
  },
  methods: {
    ...mapActions({
      fetchData: 'adminUsers/fetchData',
      fetchIPs: 'ips/adminFetchSelect2'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      let filters = {}
      for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '') {
          filters[key] = value
        }
      }
      this.debounceFetchData({
        pageSize: this.limit,
        pageIndex: this.page,
        ...filters
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    }
  }
}
</script>
<style scoped lang="sass">
.table-responsive
  min-height: 300px
</style>
