<template>
  <modal ref="modal" id="admin-users-moves">
    <div class="modal-header">
      <h5 class="modal-title">Перенос аккаунтов</h5>
      <button type="button" class="close" @click="close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group" :class="apiValidationErrors.from ? 'has-danger' : ''">
        <label for="admin-users-moves-from">С какого аккаунта</label>
        <owner-filter v-model="from" id="admin-users-moves-from" ref="fieldFrom"
                      placeholder="Для выбора аккаунта начните ввод"
                      :init="false"/>
        <label v-if="apiValidationErrors.from" id="from-error" class="error mt-2 text-danger"
               for="admin-users-moves-from">{{ apiValidationErrors.from[0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors.to ? 'has-danger' : ''">
        <label for="admin-users-moves-to">На какой аккаунта</label>
        <owner-filter v-model="to" id="admin-users-moves-to" ref="fieldTo"
                      placeholder="Для выбора аккаунта начните ввод"
                      :init="false"/>
        <label v-if="apiValidationErrors.to" id="to-error" class="error mt-2 text-danger"
               for="admin-users-moves-from">{{ apiValidationErrors.to[0] }}</label>
      </div>
    </div>
    <div class="modal-footer">
      <a href="javascript:void(0);" @click="handler" class="btn btn-success">Перенести</a>
      <a href="javascript:void(0);" class="btn btn-light" @click="close">Отмена</a>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import {OwnerFilter} from '@/pages/admin/components'
import formMixin from '@/mixins/form-mixin'

const defaultFields = {
  from: '',
  to: ''
}

export default {
  name: 'Moves',
  mixins: [formMixin],
  components: {Modal, OwnerFilter},
  data () {
    return {
      ...defaultFields
    }
  },
  methods: {
    open () {
      this.$refs.modal.open()
      this.from = defaultFields.from
      this.to = defaultFields.to
      this.$nextTick(() => {
        this.$refs.fieldFrom.initSelect2()
        this.$refs.fieldTo.initSelect2()
      })
    },
    close () {
      this.from = defaultFields.from
      this.to = defaultFields.to
      this.$refs.modal.close()
    },
    async handler () {
      try {
        this.resetApiValidation()
        await this.$store.dispatch('adminUsers/move', {
          from: this.from,
          to: this.to
        })
        this.resetApiValidation()
        await this.$store.dispatch(
          'alerts/success',
          'Информация между пользователями перенесена'
        )
        this.close()
        await this.$parent.asyncData()
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    }
  }
}
</script>
