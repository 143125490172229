<template>
  <date-picker
    :value="this.value"
    range
    :clearable="false"
    :placeholder="$t('analytics.filtersLabel.date')"
    :open.sync="open"
    :lang="$i18n.locale"
    :disabled-date="isDateMoreThanFourMonthsAway"
    :disabled="disabled"
    @input="handler"
  >
    <template v-slot:footer="{ emit }">
      <button class="mx-btn mx-btn-text" @click="today(emit)">
        {{ $t('analytics.filtersLabel.today') }}
      </button>
      <button class="mx-btn mx-btn-text" @click="yesterday(emit)">
        {{ $t('analytics.filtersLabel.yesterday') }}
      </button>
      <button class="mx-btn mx-btn-text" @click="prevSevenDay(emit)">
        {{ $t('analytics.filtersLabel.prevSevenDay') }}
      </button>
      <button class="mx-btn mx-btn-text" @click="prevMonth(emit)">
        {{ $t('analytics.filtersLabel.prevMonth') }}
      </button>
    </template>
  </date-picker>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'
import 'vue2-datepicker/locale/en'
import 'vue2-datepicker/index.css'

export default {
  name: 'RangeFilter',
  components: {DatePicker},
  props: {value: Array, disabled: Boolean},
  data () {
    return {
      open: false
    }
  },
  methods: {
    prevMonth (emit) {
      this.open = false
      const start = new Date()
      const end = new Date()
      end.setTime(end.getTime() - (3600 * 30) * 1000 * 24)
      const date = [end, start]
      emit(date)
    },
    prev3Month (emit) {
      this.open = false
      const start = new Date()
      const end = new Date()
      end.setTime(end.getTime() - (3600 * 90) * 1000 * 24)
      const date = [end, start]
      emit(date)
    },
    prevSevenDay (emit) {
      this.open = false
      const start = new Date()
      const end = new Date()
      end.setTime(end.getTime() - (3600 * 7) * 1000 * 24)
      const date = [end, start]
      emit(date)
    },
    today (emit) {
      this.open = false
      const start = new Date()
      const end = new Date()
      end.setTime(end.getTime())
      const date = [start, end]
      emit(date)
    },
    yesterday (emit) {
      this.open = false
      const start = new Date()
      const end = new Date()
      end.setTime(end.getTime() - 3600 * 1000 * 24)
      const date = [end, start]
      emit(date)
    },
    handler (range) {
      if (this.isRangeMoreThanOneMonth(range[0], range[1])) {
        this.$store.dispatch(
          'alerts/error',
          this.$t('validations.dateOutOfRange')
        )
      } else {
        this.$emit('input', [this.formatDate(range[0]), this.formatDate(range[1])])
      }
    },
    formatDate (date) {
      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      const year = date.getFullYear()
      if (month.length < 2) { month = '0' + month }
      if (day.length < 2) { day = '0' + day }
      return [year, month, day].join('-')
    },
    isDateMoreThanFourMonthsAway (dateToCheck) {
      const today = new Date()
      const fourMonthsAway = new Date().setMonth(today.getMonth() + 4)
      const fourMonthBehind = new Date().setMonth(today.getMonth() - 4)

      return dateToCheck > fourMonthsAway || dateToCheck < fourMonthBehind
    },
    isRangeMoreThanOneMonth (startDate, endDate) {
      const oneMonthLater = new Date(startDate)
      oneMonthLater.setMonth(startDate.getMonth() + 1)
      return endDate > oneMonthLater
    }
  }
}
</script>
