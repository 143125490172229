import service from '@/store/services/statistics-service'
import middleware from './middleware'

const state = {
  pepperA: {},
  welcomepWp: {},
  dates: {
    data: []
  },
  uniques: {
    data: [],
    count: 0
  },
  countries: {
    data: [],
    count: 0
  },
  main: {
    onlines: [],
    uniquesCount: 0,
    topOnlines: [],
    activity: {
      push: 0,
      open: 0,
      install: 0
    },
    progress: {labels: [], installs: [], uniques: []},
    transactions: []
  }
}

const mutations = {
  setDates: (state, payload) => {
    state.dates = payload
  },
  setMain: (state, main) => {
    state.main = main
  },
  setUniques: (state, payload) => {
    state.uniques = payload
  },
  setCountries: (state, payload) => {
    state.countries = payload
  },
  setPepperA: (state, payload) => {
    state.pepperA = payload
  },
  setWelcomepWp: (state, payload) => {
    state.welcomepWp = payload
  }
}

const actions = {
  uniques: ({commit, dispatch}, params) => {
    return service.uniques(params)
      .then(res => {
        commit('setUniques', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  dates: ({commit, dispatch}, params) => service.dates(params)
    .then(res => {
      commit('setDates', res)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  countries: ({commit, dispatch}, params) => service.countries(params)
    .then(res => {
      commit('setCountries', res)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  main: ({commit, dispatch}, params) => {
    return service.main(params)
      .then(list => {
        commit('setMain', list)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  pepperA: ({commit, dispatch}, params) => service.pepperA(params)
    .then(res => {
      commit('setPepperA', res)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  welcomepWp: ({commit, dispatch}, params) => service.welcomepWp(params)
    .then(res => {
      commit('setWelcomepWp', res)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  dates: state => state.dates,
  main: state => {
    let main = state.main
    if (main.topOnlines.length) {
      for (const index in main.topOnlines) {
        const image = main.topOnlines[index].images + 1
        if (main.topOnlines[index].images === -1) {
          main.topOnlines[index].images = import.meta.env.VITE_APP_API_BASE_URL + main.topOnlines[index].gallery[0]
        } else {
          main.topOnlines[index].images = `${import.meta.env.VITE_APP_API_BASE_URL}/verticals/${main.topOnlines[index].vertical}/${image}/${image}.jpg`
        }
      }
    }
    for (let item of main.transactions) {
      item.purpose = `finance.transactions.type.${item.type}`
    }
    return main
  },
  uniques: state => state.uniques,
  countries: state => state.countries,
  pepperA: state => state.pepperA,
  welcomepWp: state => state.welcomepWp
}

const statistics = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default statistics
