<template>
  <div class="table table-responsive">
    <table class="table table-borderless">
      <tbody>
      <tr>
        <th>Названия</th>
        <td>{{ data.name }}</td>
      </tr>
      <tr>
        <th>Автор</th>
        <td>{{ data.author }}</td>
      </tr>
      <tr>
        <th>Категория</th>
        <td>{{ data.category_label || data.category }}</td>
      </tr>
      <tr>
        <th>Реклама</th>
        <td>{{ data.ad }}</td>
      </tr>
      <tr>
        <th>Возраст</th>
        <td>{{ data.age }}</td>
      </tr>
      <tr>
        <th>Рейтинг текст (Мобильное)</th>
        <td>{{ data.rating_label }}</td>
      </tr>
      <tr>
        <th>Размер (Мобильное)</th>
        <td>{{ data.size }}</td>
      </tr>
      <tr>
        <th>Возраст текст (Мобильное)</th>
        <td>{{ data.age_label }}</td>
      </tr>
      <tr>
        <th>Рекомендация (ПК)</th>
        <td>{{ data.version }}</td>
      </tr>
      <tr>
        <th>Список желаний (ПК)</th>
        <td>{{ data.wish_list }}</td>
      </tr>
      <tr>
        <th>Установить</th>
        <td>{{ data.install }}</td>
      </tr>
      <tr>
        <th>Инициализация</th>
        <td>{{ data.installing }}</td>
      </tr>
      <tr>
        <th>Загрузка</th>
        <td>{{ data.download }}</td>
      </tr>
      <tr>
        <th>Открыть</th>
        <td>{{ data.open }}</td>
      </tr>
      <tr>
        <th>О приложении (Мобильная)</th>
        <td>{{ data.about }}</td>
      </tr>
      <tr>
        <th>Читать далее</th>
        <td>{{ data.read_more }}</td>
      </tr>
      <tr>
        <th>Скрыть</th>
        <td>{{ data.hide }}</td>
      </tr>
      <tr>
        <th>Текст</th>
        <td>{{ data.text }}</td>
      </tr>
      <tr>
        <th>Обновления (ПК)</th>
        <td>{{ data.changes_label }}</td>
      </tr>
      <tr>
        <th>Описания обновления (ПК)</th>
        <td>{{ data.changes_recent }}</td>
      </tr>
      <tr>
        <th>Отзывы</th>
        <td>{{ data.reviews_label }}</td>
      </tr>
      <tr>
        <th>Рейтинг</th>
        <td>{{ data.rating }}</td>
      </tr>
      <tr>
        <th>Кл. отзывов</th>
        <td>{{ data.count_comments }}</td>
      </tr>
      <tr>
        <th>Всего (Только ПК)</th>
        <td>{{ data.total }}</td>
      </tr>
      <tr>
        <th>Кл. оценок 5</th>
        <td>{{ data.rating_bar_5 }}</td>
      </tr>
      <tr>
        <th>Кл. оценок 4</th>
        <td>{{ data.rating_bar_4 }}</td>
      </tr>
      <tr>
        <th>Кл. оценок 3</th>
        <td>{{ data.rating_bar_3 }}</td>
      </tr>
      <tr>
        <th>Кл. оценок 2</th>
        <td>{{ data.rating_bar_2 }}</td>
      </tr>
      <tr>
        <th>Кл. оценок 1</th>
        <td>{{ data.rating_bar_1 }}</td>
      </tr>
      <tr>
        <th>Доп. информация</th>
        <td>{{ data.addition_info }}</td>
      </tr>
      <tr>
        <th>Автор надпись</th>
        <td>{{ data.author_label }}</td>
      </tr>
      <tr>
        <th>Обновлено надпись</th>
        <td>{{ data.updated }}</td>
      </tr>
      <tr>
        <th>Обновлено</th>
        <td>{{ data.updated_at }}</td>
      </tr>
      <tr>
        <th>Размер надпись</th>
        <td>{{ data.size_label }}</td>
      </tr>
      <tr>
        <th>Установок надпись</th>
        <td>{{ data.installed_label }}</td>
      </tr>
      <tr>
        <th>Установок</th>
        <td>{{ data.installed }}</td>
      </tr>
      <tr>
        <th>Текущая версия</th>
        <td>{{ data.current_version_label }}</td>
      </tr>
      <tr>
        <th>Текущая версия</th>
        <td>{{ data.current_version }}</td>
      </tr>
      <tr>
        <th>TikTok Вы хотите перейти в</th>
        <td>{{ data.ttHeader }}</td>
      </tr>
      <tr>
        <th>TikTok Да</th>
        <td>{{ data.ttYes }}</td>
      </tr>
      <tr>
        <th>TikTok Нет</th>
        <td>{{ data.ttNo }}</td>
      </tr>
      <tr>
        <th>TikTok Шаблон</th>
        <td>{{ data.ttpl }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'Content',
  props: ['data']
}
</script>
