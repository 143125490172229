<template>
  <div class="head_action_stats_g d_flex align_center justify_between mt-1">
    <div class="filters_hed_top">
      <form class="forms-sample">
        <div class="d_flex align_center in_to_djsbgdjg">
          <select2 id="wp" :data="groups.wp" v-model="wp" label="Фильтр по WP"/>
          <select2 id="wp_sub" :data="groups.wp_sub" v-model="wp_sub" label="Фильтр по subid"/>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Select2 from './Select2.vue'

export default {
  name: 'WelcomeP',
  props: ['value', 'groups'],
  components: {Select2},
  data: () => ({
    wp: '',
    wp_sub: ''
  }),
  created: function () {
    for (const [prop, value] of Object.entries(this.value)) {
      if (this[prop] != null) {
        this[prop] = value
        this.$watch(prop, function () {
          this.$emit('input', {
            wp: this.wp,
            wp_sub: this.wp_sub
          })
        })
      }
    }
  }
}
</script>
