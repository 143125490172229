<template>
  <div id="tiktok-events-form" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="title_mod_uplo">{{ $t('pwas.components.tt.title')  }}</div>
        <div class="link_ths_ig">{{ pixel }}</div>
        <form @submit.prevent="handle">
          <div class="div">
            <div class="subtit_esgsgg">Access Token</div>
            <div class="form-group">
              <label for="tiktok-pixel-access-token">{{ $t('pwas.components.tt.label') }}
              </label>
              <input class="form-control" id="tiktok-pixel-access-token" v-model="accessToken" placeholder="7102ab285cea9da54688aeca5ac4e01b0f957e37"/>
            </div>
          </div>
          <div class="div" v-for="event of events" :key="event.id" v-if="event.visible">
            <div class="subtit_esgsgg">{{$t(event.label) }}</div>
            <BaseFromGroup
              :id="'tiktok-pixel-event-' + event.id"
              :name="'tiktok-pixel-event-' + event.id"
              :label="getStandardTikTokEventDescription($data[event.id])"
            >
              <BaseSelect
                v-model="$data[event.id]"
                :id="'tiktok-pixel-event-' + event.id"
                :name="'tiktok-pixel-event-' + event.id"
                :options="standardTikTokEvents"
                :placeholder="$t('pwas.components.tt.dontUse')"
              />
            </BaseFromGroup>
          </div>
          <div class="footer_modl_akdhg">
            <div class="form-group">
              <div class="in_topshgdjf_g d_flex align_center">
                <div class="item_avt_butt_jg">
                  <button type="submit" class="ful_butt_green d_flex align_center justify_center">{{ $t('general.save') }}</button>
                </div>
                <div class="item_avt_butt_jg">
                  <a href="javascript:void(0);" data-dismiss="modal"
                     class="border_butt_green d_flex align_center justify_center">{{ $t('general.cancel') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import * as dictionary from '@pwa.group/pwa.dictionary'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  name: 'Events',
  components: {BaseSelect, BaseFromGroup, Info},
  data: () => ({
    pixel: '',
    index: 0,
    install: 'Download',
    open: '',
    push: '',
    registration: '',
    deposit: '',
    accessToken: ''
  }),
  computed: {
    standardTikTokEvents: function () {
      return dictionary.TikTok.events.map(event => ({id: event.id, text: this.$t(event.label), description: event.description}))
    },
    events () {
      return [
        {id: 'install', label: 'pwas.components.tt.events[0]', visible: true},
        {id: 'open', label: 'pwas.components.tt.events[1]', visible: true},
        {id: 'push', label: 'pwas.components.tt.events[2]', visible: true},
        {id: 'registration', label: 'pwas.components.tt.events[3]', visible: this.accessToken && this.accessToken.length > 0},
        {id: 'deposit', label: 'pwas.components.tt.events[4]', visible: this.accessToken && this.accessToken.length > 0}
      ]
    }
  },
  methods: {
    show: function () {
      // eslint-disable-next-line no-undef
      jQuery('#tiktok-events-form').modal('show')
    },
    hide: function () {
      // eslint-disable-next-line no-undef
      jQuery('#tiktok-events-form').modal('hide')
    },
    handle: function () {
      this.$parent.pixels[this.index].accessToken = this.accessToken
      const events = ['install', 'open', 'push', 'registration', 'deposit']
      for (const event of events) {
        if (this[event] || this[event] !== '') {
          this.$parent.pixels[this.index][event] = this[event]
        } else {
          this.$parent.pixels[this.index][event] = null
        }
      }
      this.$emit('change')
      this.hide()
    },
    getStandardTikTokEventDescription: function (event) {
      const index = this.standardTikTokEvents.findIndex(standardTikTokEvent => standardTikTokEvent.id === event)
      if (index === -1) {
        return this.$t('pwas.components.tt.dontUse')
      } else {
        return this.$t(this.standardTikTokEvents[index].description)
      }
    }
  }
}
</script>
