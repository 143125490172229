export default {
  title: 'Landing',
  addLandingTitle: 'Adicionando uma página de destino',
  editLandingTitle: 'Edição de landing page',
  filters: {
    name: 'Filtrar por nome',
    type: 'Filtro por tipo',
    tag: 'Filtro por tags',
    addTag: 'Adicionar um tag novo',
    design: 'Filtrar por nome de desenho'
  },
  buttons: {
    create: 'Criar novo'
  },
  table: {
    headers: {
      name: 'Nome',
      type: 'Tipo',
      tags: 'Тags',
      designName: 'Nome do desenho'
    }
  },
  action: {
    removeLanding: 'Remoção do landing "{name}"',
    removeMessage: 'Após a remoção do landing, todos os PWAs em que ele foi usado não exibirão mais pre-landing ou post-landing. Além disso, você não poderá mais recuperar os dados', // потребує вточнення
    successfullRemove: 'O landing "{name}" foi removido com sucesso'
  },
  mainForm: {
    header: 'Principal',
    info: 'Informações principais sobre a página de destino',
    name: 'Nome da página de destino',
    tags: 'Tags',
    tagsTips: 'Adicionar nova tag',
    themeSearsh: 'Pesquisar por título de jogo',
    type: 'Tipo',
    wheels: 'Roda',
    slots: 'Slots'
  },
  paramsForm: {
    header: 'Parâmetros',
    info: 'Informações adicionais sobre a página de destino',
    body: 'Texto',
    buttonGo: 'Texto no botão',
    buttonMore: 'Texto no botão para mais um giro',
    revolutions: 'Número de giros',
    logo: 'Logotipo',
    download: 'Download'
  },
  themesForm: {
    header: 'Design do Campo de Jogo',
    info: 'Escolha um design para o campo de jogo'
  },
  sectorForm: {
    header: 'Nomes dos Setores',
    info: 'Digite o nome de cada setor',
    sector: 'Setor',
    sectorTips: 'Rótulo para o setor'
  },
  modalForm: {
    header: 'Janela Modal',
    info: 'Digite o texto para a janela modal',
    modalHeader: 'Cabeçalho na janela de bônus',
    buttonTitle: 'Texto na janela de bônus',
    modalButton: 'Texto no botão da janela de bônus'
  },
  create: {
    title: 'Criar uma nova landing'
  },
  validate: {
    name: {
      notEmpty: 'Nome é obrigatório',
      wrongFormat: 'Formato inválido',
      minLength: 'O nome deve ter mais de 2 caracteres',
      maxLength: 'O nome deve ter menos de 50 caracteres'
    },
    tags: {
      wrongFormat: 'Formato inválido',
      maxSize: 'Não é possível adicionar mais do que 5 tags',
      maxLength: 'A tag deve ter no máximo 40 caracteres'
    },
    type: {
      notEmpty: 'Tipo é obrigatório',
      wrongFormat: 'Formato inválido'
    },
    theme: {
      notEmpty: 'Tema é obrigatório',
      wrongFormat: 'Formato inválido'
    },
    logo: {
      notEmpty: 'Logotipo é obrigatório',
      wrongFormat: 'Formato inválido'
    },
    body: {
      notEmpty: 'Texto é obrigatório',
      wrongFormat: 'Formato inválido',
      minLength: 'O texto deve ter mais de 2 caracteres',
      maxLength: 'O texto deve ter menos de 99 caracteres'
    },
    buttons: {
      go: {
        notEmpty: 'Texto é obrigatório',
        wrongFormat: 'Formato inválido',
        minLength: 'O texto deve ter mais de 2 caracteres',
        maxLength: 'O texto deve ter menos de 8 caracteres'
      },
      more: {
        notEmpty: 'Texto é obrigatório',
        wrongFormat: 'Formato inválido',
        minLength: 'O texto deve ter mais de 2 caracteres',
        maxLength: 'O texto deve ter menos de 8 caracteres'
      }
    },
    revolutions: {
      notEmpty: 'Revoluções são obrigatórias',
      wrongFormat: 'Formato inválido'
    },
    sector: {
      label: {
        notEmpty: 'Texto é obrigatório',
        wrongFormat: 'Formato inválido',
        minLength: 'O texto deve ter mais de 2 caracteres',
        maxLength: 'O texto deve ter menos de 24 caracteres'
      },
      isWin: {
        notEmpty: 'Setor vencedor é obrigatório',
        wrongFormat: 'Formato inválido'
      }
    },
    modal: {
      header: {
        notEmpty: 'Texto é obrigatório',
        wrongFormat: 'Formato inválido',
        minLength: 'O texto deve ter mais de 2 caracteres',
        maxLength: 'O texto deve ter menos de 16 caracteres'
      },
      body: {
        notEmpty: 'Texto é obrigatório',
        wrongFormat: 'Formato inválido',
        minLength: 'O texto deve ter mais de 2 caracteres',
        maxLength: 'O texto deve ter menos de 45 caracteres'
      },
      button: {
        notEmpty: 'Texto é obrigatório',
        wrongFormat: 'Formato inválido',
        minLength: 'O texto deve ter mais de 2 caracteres',
        maxLength: 'O texto deve ter menos de 8 caracteres'
      }
    }
  }
}
