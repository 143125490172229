export default {
  logout: 'Вихід',
  mail: 'У вас немає нових повідомлень | У вас одне нове повідомлення | У вас {count} нових повідомлень | У вас {count} нових повідомлень',
  notifications: 'У вас немає нових сповіщень | У вас одне нове сповіщення | У вас {count} нових сповіщень | У вас {count} нових сповіщень',
  banners: {
    telegram: {
      title: 'Підпишіться на наш телеграм канал <br>і телеграм чат',
      desc: 'Будьте в курсі останніх новин від PWA GROUP. Беріть активну участь в обговоренні ПЗ!',
      chanelButton: 'Телеграм канал',
      chatButton: 'Телеграм чат'
    }
  }
}
