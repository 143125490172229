import analytics from './analytics/pt'
import auth from './auth/pt'
import bot from './bot/pt'
import dashboard from './dashboard/pt'
import dashboardLayout from './dashboardLayout/pt'
import finance from './finance/pt'
import general from './general/pt'
import layouts from './layouts/pt'
import menu from './menu/pt'
import modalSave from './modalSave/pt'
import notFound from './notFound/pt'
import pushes from './pushes/pt'
import pwas from './pwas/pt'
import referrals from './referrals/pt'
import tariffs from './tariffs/pt'
import teams from './teams/pt'
import templates from './templates/pt'
import tickets from './tickets/pt'
import titles from './titles/pt'
import trackers from './trackers/pt'
import landingPages from './landing-pages/pt'
import group from './group/pt'
import pixels from './pixels/pt'
import banners from './banners/pt'
import components from './components/pt'
import validations from './validations/pt'
import mirrors from './mirrors/pt'
import vacancies from './vacancies/pt'

// eslint-disable-next-line no-undef
jQuery.fn.datepicker.dates['pt'] = {
  days: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
  daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  daysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  today: 'Hoje',
  clear: 'Limpar',
  format: 'dd/mm/yyyy',
  titleFormat: 'MM yyyy', /* Utiliza a mesma sintaxe que 'format' */
  weekStart: 1
}
export default {
  ID: 'ID',
  PWA: 'PWA',
  IP: 'IP',
  PWAs: 'PWAs',
  PUSH: 'PUSH',
  analytics,
  auth,
  bot,
  dashboard,
  dashboardLayout,
  finance,
  general,
  layouts,
  menu,
  modalSave,
  notFound,
  pushes,
  pwas,
  referrals,
  tariffs,
  teams,
  templates,
  tickets,
  titles,
  trackers,
  landingPages,
  group,
  pixels,
  banners,
  components,
  validations,
  mirrors,
  vacancies
}
