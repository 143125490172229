export default {
  home: 'Головна',
  statistics: 'Статистика',
  trackers: 'Трекери',
  bills: 'Фінанси',
  tariffs: 'Тарифи',
  referrals: 'Реф. програма',
  teams: 'Командна робота',
  templates: 'Шаблони',
  landing: 'Лендінг',
  mirrors: 'Дзеркала',
  vacancies: 'Вакансії'
}
