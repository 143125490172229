import service from '@/store/services/admin/users-service'
import middleware from '../middleware'

const state = () => ({
  models: [],
  count: 0,
  user: {},
  tariffs: [],
  userTariffs: [],
  countTariffs: 0,
  countAll: 0,
  countNew: 0,
  countActive: 0,
  countExpired: 0,
  auth: {}
})

const mutations = {
  setPageData (state, payload) {
    state.models = payload.data
    state.count = payload.itemsCount
    state.countAll = payload.countAll
    state.countNew = payload.countNew
    state.countActive = payload.countActive
    state.countExpired = payload.countExpired
  },
  setUser: (state, payload) => {
    state.user = payload
  },
  setTariffs: (state, payload) => {
    state.userTariffs = payload.userTariffs
    state.countTariffs = payload.count
    state.tariffs = payload.tariffs
  },
  setTariff: (state, payload) => {
    state.user.tariffs.push(payload)
  },
  setAuth: (state, payload) => {
    state.auth = payload
  }
}

const actions = {
  move: ({commit, dispatch}, {from, to}) => {
    return service.move({from, to})
      .then()
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  fetchData ({commit, dispatch}, params) {
    return service.getList(params)
      .then(data => commit('setPageData', data))
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  tariffs: ({ commit, dispatch }, params) => {
    return service.tariffs(params)
      .then(response => {
        commit('setTariffs', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  read: ({commit, dispatch}, params) => {
    return service.read(params)
      .then(response => {
        commit('setUser', response)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  update: ({commit, dispatch}, {id, ...payload}) => {
    return service.update(id, payload)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  delete: ({commit, dispatch}, params) => {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },

  tariffRemove: ({commit, dispatch}, params) => service.tariffRemove(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  tariffCreate: ({commit, dispatch}, params) => service.tariffCreate(params)
    .then(tariff => {
      commit('setTariff', tariff)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  tariffUpdate: ({commit, dispatch}, params) => service.tariffUpdate(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  async patchUpdate ({commit, dispatch}, options) {
    try {
      await service.patch(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async authUser ({commit, dispatch}, payload) {
    try {
      const res = await service.authUser(payload)
      commit('setAuth', res)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async authMember ({commit, dispatch}, payload) {
    try {
      const res = await service.authMember(payload)
      commit('setAuth', res)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  models: state => state.models,
  count: state => Number(state.count),
  countAll: state => Number(state.countAll),
  countNew: state => Number(state.countNew),
  countActive: state => Number(state.countActive),
  countExpired: state => Number(state.countExpired),
  permissionLevel: () => ({
    1: 'Користувач',
    // 2: 'Сделал оплату',
    // 4: 'VIP',
    7: 'Менеджер',
    8: 'Адмін'
    // 666: 'sudo'
  }),
  user: state => state.user,
  states: state => state.states,
  userTariffs: state => state.userTariffs,
  countTariffs: state => state.countTariffs,
  tariffs: state => state.tariffs,
  list: state => {
    let users = {}
    for (const user of state.data) {
      users[user._id] = user.username
    }
    return users
  },
  auth: state => state.auth
}

const adminUsers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminUsers
