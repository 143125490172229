<template>
  <modal
    ref="modal"
    id="template-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="form-check form-check-primary">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" v-model="enable">
            {{ enable ? 'Вкл.' : 'Выкл.' }}
            <i class="input-helper"></i></label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="name">Id</label>
            <input
              type="text" id="id"
              placeholder="index.ejs"
              v-model="id"
              :disabled="!isCreate"
              class="form-control" min="3" max="150"
              :class="apiValidationErrors.id  ? 'form-control-danger' : ''"
              @focus="clearError('id')"
            >
            <label v-if="apiValidationErrors.id" class="error mt-2 text-danger" for="name.ru">
              {{ apiValidationErrors.id[0] }}
            </label>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="name">Картинка</label>
            <input
              type="text" id="image"
              placeholder="/upload/images/template.png"
              v-model="image"
              class="form-control" min="3" max="150"
              :class="apiValidationErrors.image  ? 'form-control-danger' : ''"
              @focus="clearError('image')"
            >
            <small class="form-text text-muted">http://127.0.0.1:8080/images/standart.png</small>
            <label v-if="apiValidationErrors.image" class="error mt-2 text-danger" for="name.ru">
              {{ apiValidationErrors.image[0] }}
            </label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="name">Назва</label>
        <div class="row mb-3">
          <div class="col">
            <input
              type="text"
              id="name.ru"
              placeholder="PlayMarket (Beta)"
              v-model="name.ru"
              class="form-control" min="3" max="150"
              :class="apiValidationErrors['name.ru'] ? 'form-control-danger' : ''"
              @focus="clearError('name.ru')"
            >
            <small class="form-text text-muted">Назва на російській</small>
            <label v-if="apiValidationErrors['name.ru']" class="error mt-2 text-danger" for="name.ru">
              {{ apiValidationErrors['name.ru'][0] }}
            </label>
          </div>
          <div class="col">
            <input
              type="text"
              id="name.en"
              placeholder="PlayMarket (Beta)"
              v-model="name.en"
              class="form-control" min="3" max="150"
              :class="apiValidationErrors['name.en'] ? 'form-control-danger' : ''"
              @focus="clearError('name.en')"
            >
            <small class="form-text text-muted">Назва на Англійській</small>
            <label v-if="apiValidationErrors['name.en']" class="error mt-2 text-danger" for="name.en">
              {{ apiValidationErrors['name.en'][0] }}
            </label>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <input
              type="text"
              id="name.uk"
              placeholder="PlayMarket (Beta)"
              v-model="name.uk"
              class="form-control" min="3" max="150"
              :class="apiValidationErrors['name.uk'] ? 'form-control-danger' : ''"
              @focus="clearError('name.uk')"
            >
            <small class="form-text text-muted">Назва на Українській</small>
            <label v-if="apiValidationErrors['name.uk']" class="error mt-2 text-danger" for="name.uk">
              {{ apiValidationErrors['name.uk'][0] }}
            </label>
          </div>
          <div class="col">
            <input
              type="text"
              id="name.pt"
              placeholder="PlayMarket (Beta)"
              v-model="name.pt"
              class="form-control" min="3" max="150"
              :class="apiValidationErrors['name.pt'] ? 'form-control-danger' : ''"
              @focus="clearError('name.pt')"
            >
            <small class="form-text text-muted">Назва на Португальській</small>
            <label v-if="apiValidationErrors['name.pt']" class="error mt-2 text-danger" for="name.pt">
              {{ apiValidationErrors['name.pt'][0] }}
            </label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="description">Опис</label>
        <textarea
          id="description.ru"
          placeholder="на російській"
          v-model="description.ru"
          class="form-control" rows="4"
          :class="apiValidationErrors['description.ru'] ? 'form-control-danger' : ''"
          @focus="clearError('description.ru')"
        />
        <small class="form-text text-muted">Опис на російській</small>
        <label v-if="apiValidationErrors['description.ru']" class="error mt-2 text-danger" for="description.ru">
          {{ apiValidationErrors['description.ru'][0] }}
        </label>
        <textarea
          id="description.en"
          placeholder="на Англійській"
          v-model="description.en"
          class="form-control mt-3" rows="4"
          :class="apiValidationErrors['description.en'] ? 'form-control-danger' : ''"
          @focus="clearError('description.un')"
        />
        <small class="form-text text-muted">Опис на Англійській</small>
        <label v-if="apiValidationErrors['description.en']" class="error mt-2 text-danger" for="description.en">
          {{ apiValidationErrors['description.en'][0] }}
        </label>
        <textarea
          id="description.uk"
          placeholder="на Українській"
          v-model="description.uk"
          class="form-control mt-3" rows="4"
          :class="apiValidationErrors['description.uk'] ? 'form-control-danger' : ''"
          @focus="clearError('description.uk')"
        />
        <small class="form-text text-muted">Опис на Українській</small>
        <label v-if="apiValidationErrors['description.uk']" class="error mt-2 text-danger" for="description.uk">
          {{ apiValidationErrors['description.uk'][0] }}
        </label>
        <textarea
          id="description.pt"
          placeholder="на Португальській"
          v-model="description.pt"
          class="form-control mt-3" rows="4"
          :class="apiValidationErrors['description.pt'] ? 'form-control-danger' : ''"
          @focus="clearError('description.pt')"
        />
        <small class="form-text text-muted">Опис на Португальській</small>
        <label v-if="apiValidationErrors['description.pt']" class="error mt-2 text-danger" for="description.pt">
          {{ apiValidationErrors['description.pt'][0] }}
        </label>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import formMixin from '@/mixins/form-mixin'

const defaultValues = () => ({
  id: null,
  enable: false,
  name: {},
  description: {},
  image: ''
})

export default {
  name: 'ModalCreateUpdateTemplate',
  mixins: [formMixin],
  props: {
    data: Object
  },
  components: {Modal, SubmitModalForm},
  data () {
    return {
      processing: false,
      ...defaultValues()
    }
  },
  computed: {
    title () {
      return this.isCreate ? 'Створення шаблона' : `Редагування ${this.name.ru}`
    },
    isCreate () {
      return !this.data?.id
    }
  },
  watch: {
    data: {
      handler () {
        if (this.data.id) {
          this.name = this.data.name
          this.id = this.data.id
          this.enable = this.data.enable
          this.name = this.data.name
          this.description = this.data.description
          this.image = this.data.image
          this.order = this.data.order
        }
      },
      immediate: true
    }
  },
  methods: {
    openModal () {
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
      this.$emit('cancel')
      this.skip()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        const payload = {
          name: this.name,
          description: this.description,
          enable: this.enable,
          id: this.id,
          image: this.image
        }
        await this.$store.dispatch(this.isCreate ? 'adminPwasStore/createTemplate' : 'adminPwasStore/updateTemplate', payload)
        await this.$store.dispatch(
          'alerts/success',
          this.isCreate ? 'Шаблон створено' : 'Шаблон оновлено'
        )
        this.resetApiValidation()
        this.processing = false
        this.$emit('success')
        this.skip()
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
<style scoped lang="sass">
::v-deep .modal-dialog
  max-width: 800px
textarea
  min-height: 150px
</style>
