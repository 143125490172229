<template>
  <div>
    <div class="row">
      <div class="col-md-6 col-lg mb-2">
        <input v-model="name" class="form-control" placeholder="Фильтр по названию"/>
      </div>
      <div class="col-md-6 col-lg mb-2">
        <select v-model="enable" class="form-control">
          <option value="" selected>Фильтр по состоянию</option>
          <option :value="true" selected>Включен</option>
          <option :value="false" selected>Выключен</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {OwnerFilter} from '@/pages/admin/components'

export default {
  name: 'PostbacksFilter',
  components: {OwnerFilter},
  created () {
    this.resetFilter()
  },
  computed: {
    name: {
      get () {
        return this.$store.getters['adminPostbacks/filter'].name
      },
      set: _.debounce(function (input) {
        this.setterFilter('name', input)
      }, 500)
    },
    enable: {
      get () {
        return this.$store.getters['adminPostbacks/filter'].enable
      },
      set: _.debounce(function (input) {
        this.setterFilter('enable', input)
      }, 500)
    }
  },
  methods: {
    setterFilter (filter, input) {
      const payload = this.$store.getters['adminPostbacks/filter']
      payload[filter] = input
      this.$store.dispatch('adminPostbacks/setFilter', payload)
      this.$emit('change', true)
    },
    resetFilter () {
      this.$store.dispatch('adminPostbacks/setFilter', {
        name: '',
        enable: ''
      })
      this.$emit('change', true)
    }
  }
}
</script>
