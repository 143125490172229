/* eslint-disable no-template-curly-in-string */
export default {
  title: 'Дзеркала',
  newPwa: 'Нове PWA',
  body: 'Для створення нового PWAs необхідно додати домен, на якому буде працювати програми. Домен обов\'язково має бути "обгорнутий" у <a href="https://www.cloudflare.com/" target="_blank">CloudFlare</a>',
  domain: 'Введіть домен',
  domainTitle: 'Введіть Ваш домен, який повинен мати https протокол і обернутий у Cloudflare',
  auto: 'Автоналаштування',
  pair: 'Збережені доступи',
  pairTitle: 'Виберіть збережену пару email(apikey) для додавання домену або додайте нову пару',
  apiEmail: 'Введіть Cloudflare email',
  apiEmailTitle: 'Введіть Ваш Cloudflare email, який буде використовуватись для налаштування',
  apiKey: 'Введіть Cloudflare Api Key',
  apiKeyTitle: 'Введіть Ваш Cloudflare Api Key, який буде використовуватись для налаштування',
  apiHelp: 'Для налаштування домену необхідно отримати API Key у профілі Cloudflare, для цього перейдіть за посиланням <a href="https://dash.cloudflare.com/profile/api-tokens" target="_blank" class="link_green ">API Keys</a> і отримайте там Global API Key',
  success: 'Дзеркало створено',
  self: 'Прив\'язати свій',
  chooseAccount: 'Повернутися до вибору облікового запису',
  buyDomain: 'Купити домен (${pwaDomainCost})',
  buySuccess: 'Домен успішно оплачений',
  bought: 'Куплені домени',
  boughtInfo: '<p class="card-description">Домени які ви купили раніше, але зараз не використовуєте</p>',
  marketInfo: '<p class="card-description">Вартість 1 домену = <strong>${pwaDomainCost}</strong><br><Strong>Важливо!</Strong><br>Купляючи наші домени ви погоджуєтесь з тим , що у разі блокування домену <strong>реєстратором</strong> або <strong>Cloudflare</strong>, претензій до сервісу не пред\'являються</p>',
  apiRequired: 'API доступи до CloudFlare є обов\'язковими до заповнення',
  deleteCloudflare: 'Видалити аккаунт Cloudflare?',
  internalName: 'Внутрішня назва',
  pwaSelect: 'Виберіть PWA',
  updated: 'Дзеркало оновлено'
}
