<template>
  <tr :data-id="item._id">
    <td><i class="icon-options-vertical"></i></td>
    <td><img v-bind:src="image" v-bind:alt="name"/></td>
    <td>
      <select class="form-control" v-model="enable">
        <option :key="item" v-for="item in [true, false]" :value="item">
          {{ item | formatBoolean }}
        </option>
      </select>
    </td>
    <td>{{ name }}</td>
    <td>
      <select class="form-control" v-model="gateway">
        <option value="null">Не выбрано</option>
        <option :key="gateway._id" v-for="gateway in this.$store.getters['adminGateways/list']" :value="gateway._id" v-if="gateway.method === item._id">
          {{ gateway.name }}
        </option>
      </select>
    </td>
    <td>{{ createdAt | dateFromNow }}</td>
    <td>{{ updatedAt | dateFromNow }}</td>
    <td>
      <button class="btn btn-icons btn-inverse-primary" @click="handlerEdit"><i class="icon-pencil"></i></button>
      <button class="btn btn-icons btn-inverse-danger" @click="handlerDelete"><i class="icon-trash"></i></button>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'PaymentMethodField',
  props: ['item'],
  data: function () {
    return {
      ...this.item,
      watch: false
    }
  },
  created: function () {
    setTimeout(() => {
      this.watch = true
    }, 500)
  },
  watch: {
    enable: {
      handler: async function (enable) {
        if (this.watch) {
          await this.$store.dispatch('adminPaymentMethods/update', {_id: this.item._id, enable})
          await this.$store.dispatch('alerts/success', 'Видимость обновлена')
        }
      }
    },
    gateway: {
      handler: async function (gateway) {
        if (this.watch) {
          await this.$store.dispatch('adminPaymentMethods/update', {_id: this.item._id, gateway})
          await this.$store.dispatch('alerts/success', 'Шлюз обновлен')
        }
      }
    }
  },
  methods: {
    handlerDelete: async function () {
      if (confirm('Удалить метод оплаты?')) {
        await this.$store.dispatch('adminPaymentMethods/delete', this.item._id)
        await this.$parent.getData()
        await this.$store.dispatch('alerts/success', 'Метод оплаты удалён')
      }
    },
    handlerEdit: async function () {
      await this.$store.dispatch('adminGateways/list')
      this.$parent.$refs.form.enable = this.enable
      this.$parent.$refs.form.name = this.name
      this.$parent.$refs.form.image = this.image
      this.$parent.$refs.form.gateway = this.gateway
      this.$parent.$refs.form.id = this.item._id
      // eslint-disable-next-line no-undef
      jQuery('#payment-method-modal').modal('show')
    }
  }
}
</script>
