/* eslint-disable no-template-curly-in-string */
export default {
  title: 'Создайте новое PWA',
  newPwa: 'Новое PWA',
  body: 'Для создания нового PWAs необходимо добавить домен на котором будет работать приложения. Домен обязательно должен быть "обернут" в <a href="https://www.cloudflare.com/" target="_blank">CloudFlare</a>',
  domain: 'Введите домен',
  domainTitle: 'Введите Ваш домен, который должен иметь https протокол и обёрнут в Cloudflare',
  auto: 'Автонастройка',
  pair: 'Сохраненные доступы',
  pairTitle: 'Выберите сохраненную пару email(apikey) для добавления домена или добавьте новую пару',
  apiEmail: 'Введите Cloudflare email',
  apiEmailTitle: 'Введите Ваш Cloudflare email, который будет использоваться для настройки',
  apiKey: 'Введите Cloudflare Api Key',
  apiKeyTitle: 'Введите Ваш Cloudflare api key, который будет использоваться для настройки',
  apiHelp: 'Для настройки домена, необходимо получить API Key в профиле Cloudflare, для этого перейдите по ссылке <a href="https://dash.cloudflare.com/profile/api-tokens" target="_blank" class="link_green">API Keys</a> и получите там Global API Key',
  success: 'PWA создано',
  self: 'Привязать свой',
  chooseAccount: 'Вернуться к выбору аккаунта',
  buyDomain: 'Купить домен (${pwaDomainCost})',
  buySuccess: 'Домен успешно оплачен',
  bought: 'Купленные домены',
  boughtInfo: '<p class="card-description">Домены которые вы купили раньше, но сейчас не используете</p>',
  marketInfo: '<p class="card-description">Стоимость 1 домена = <strong>${pwaDomainCost}</strong><br><Strong>Важно!</Strong><br>Покупая наши домены вы соглашаетесь с тем, что в случае блокировки домена <strong>регистратором</strong> или <strong>Cloudflare</strong>, претензий к сервису не предъявляются</p>',
  apiRequired: 'API доступы к CloudFlare обязательны к заполнению',
  deleteCloudflare: 'Удалить аккаунт Cloudflare?'
}
