import AdminUsers from './AdminUsers.vue'
import AdminUser from './AdminUser.vue'
import tariffsRouter from './tariffs/router'

export default (meta, header) => {
  return [
    {
      path: 'users',
      name: 'AdminUsers',
      components: {default: AdminUsers, header},
      meta
    },
    {
      path: 'users/:id',
      name: 'AdminUser',
      components: {default: AdminUser, header},
      meta
    },
    ...tariffsRouter(meta, header)
  ]
}
