<template>
  <div class="content-wrapper">
    <settings-form ref="settingsForm"/>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">PWA</h4>
            <p class="card-description">Керування всіма PWAs на сервісі</p>
            <div class="mb-2">
              <button
                @click="$refs.settingsForm.handlerOpen()"
                type="button" class="btn btn-outline-secondary btn-fw">
                <i class="icon-settings"></i> Налаштування
              </button>
            </div>
            <div class="row mb-2">
              <div class="col-md-6 col-lg">
                <input class="form-control" v-model="filter.domain" placeholder="Пошук за доменом"/>
              </div>
              <div class="col-md-6 col-lg">
                <input class="form-control" v-model="filter.alias" placeholder="Пошук за псевдо"/>
              </div>
              <div class="col-md-6 col-lg">
                <input class="form-control" v-model="filter.name" placeholder="Пошук за назвою"/>
              </div>
              <div class="col-md-6 col-lg">
                <input class="form-control" v-model="filter.id" placeholder="Пошук за ID"/>
              </div>
            </div>
            <div class="row mb-2">
              <owner-filter
                class="col-md-6 col-lg"
                v-model="filter.owner"
                placeholder="Фільтр за користувачем"
              />
              <div class="col-md-6 col-lg mb-2">
                <select class="form-control" v-model="filter.status">
                  <option value="" selected>Фільтр за статусом</option>
                  <option :key="status.id" v-for="status in $store.getters['pwas/statuses']" :value="status.id">
                    {{ $t(status.label) }}
                  </option>
                </select>
              </div>
              <div class="col-md-6 col-lg">
                <select class="form-control" v-model="filter.paid">
                  <option value="" selected>Фільтр за оплатою</option>
                  <option value="true" selected>Оплачено</option>
                  <option value="false" selected>На оплачено</option>
                </select>
              </div>
              <select2
                v-model="filter.ip"
                :options="ipsOptions"
                :settings="{allowClear: true}"
                class="col-md-6 col-lg"
                id="ip"
                name="ip"
                inputClass="form-control"
                placeholder="Поиск по IP"
              />
            </div>
            <loading v-model="loading" class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>
                    <button @click="fetch()" class="btn btn-icons btn-inverse-light"><i class="icon-refresh"></i>
                    </button>
                  </th>
                  <th class="text-center align-middle">Стан</th>
                  <th class="text-center align-middle">PWA</th>
                  <th class="text-center align-middle" style="min-width: 220px;">Владелец</th>
                  <th class="text-center align-middle">IP</th>
                  <th class="text-center align-middle">Создан</th>
                  <th class="text-center align-middle">Обновлен</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model.id">
                  <td>
                    <div class="dropdown d-inline">
                      <button class="btn btn-icons btn-inverse-primary" type="button" data-toggle="dropdown">
                        <i class="icon-options-vertical"></i>
                      </button>
                      <div class="dropdown-menu">
                        <router-link :to="{name: 'AdminPWA', params: {id: model.id}}"
                                     class="dropdown-item"><i class="link-icon icon-pencil"></i> Редагування
                        </router-link>
                        <button class="dropdown-item" @click="handlerCopy(model)"><i
                          class="icon-docs"></i> Копіювати
                        </button>
                        <router-link v-if="model.tracker" :to="{name: 'AdminPWATracker', params: {id: model.id}}"
                                     class="dropdown-item"><i class="link-icon icon-directions"></i> Трекер
                        </router-link>
                      </div>
                    </div>
                  </td>
                  <td class="p-0">
                    <table>
                      <tr>
                        <th>ID</th>
                        <td class="w-100">{{ model.id }}</td>
                      </tr>
                      <tr>
                        <th>Статус</th>
                        <td class="w-100"><span :class="`text-${model.status.class}`">{{
                            $t(model.status.label)
                          }}</span></td>
                      </tr>
                      <tr>
                        <th>Оплачено</th>
                        <td class="w-100">{{ model.paid | formatBoolean }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="p-0">
                    <table class="table">
                      <tr>
                        <th>Аліас</th>
                        <td class="w-100" v-html="$options.filters.formatNull(model.alias)"></td>
                      </tr>
                      <tr>
                        <th rowspan="3">
                          <img :src="model.images" alt="image">
                        </th>
                      </tr>
                      <tr>
                        <td class="w-100">{{ model.name }}</td>
                      </tr>
                      <tr>
                        <td class="w-100">{{ model.domain }}</td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <owner :data="model.owner"/>
                  </td>
                  <ip-column v-model="filter.ip" :ip="model.ip"/>
                  <td>{{ model.createdAt | dateFromNow }}</td>
                  <td>{{ model.updatedAt | dateFromNow }}</td>
                </tr>
                <tr v-if="count === 0">
                  <td colspan="9" class="text-center"><span class="text-gray">( нет )</span></td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td>Всего</td>
                  <td colspan="8">{{ count }}</td>
                </tr>
                </tfoot>
              </table>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import Loading from '@/pages/admin/components/Loading.vue'
import Owner from '@/pages/admin/components/tables/Owner.vue'
import OwnerFilter from '@/pages/admin/components/OwnerFilter.vue'
import SettingsForm from '@/components/admin/pwas/SettingsForm.vue'
import IpColumn from '@/components/admin/pwas/IpColumn.vue'
import Select2 from '@/components/Select2.vue'

export default {
  name: 'AdminPWAs',
  components: {
    Select2,
    Loading,
    Owner,
    OwnerFilter,
    SettingsForm,
    IpColumn
  },
  data () {
    return {
      loading: true,
      page: 1,
      limit: 10,
      filter: {
        id: '',
        status: '',
        owner: '',
        domain: '',
        name: '',
        alias: '',
        paid: '',
        ip: ''
      }
    }
  },
  watch: {
    filter: {
      handler () {
        this.fetch()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      ipsOptions: 'ips/options',
      models: 'adminPWAs/models',
      count: 'adminPWAs/count'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  async created () {
    this.fetch = _.debounce(this.fetch, 500)
    await this.fetchIPs({
      page: 1,
      limit: 100,
      sort: 'id'
    })
    await this.fetch()
  },
  methods: {
    ...mapActions({
      asyncData: 'adminPWAs/asyncData',
      copyPWA: 'adminPWAs/copyPWA',
      alertSuccess: 'alerts/success',
      fetchIPs: 'ips/adminFetchSelect2'
    }),
    async fetch () {
      this.loading = true
      await this.asyncData({
        limit: this.limit,
        page: this.page,
        sort: '-createdAt',
        filter: this.filter
      })
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async handlerPagination (page) {
      this.page = page
      await this.fetch()
    },
    async handlerCopy (model) {
      if (confirm('Копировать PWA?')) {
        await this.copyPWA(model.id)
        await this.alertSuccess('PWA скопировано на профиль админа')
        await this.fetch()
      }
    }
  }
}
</script>
