import {mapActions} from 'vuex'
import GroupAction from '@/components/GroupAction.vue'

export default {
  components: {GroupAction},
  data () {
    return {
      group: [],
      activated: false
    }
  },
  computed: {
    checkAll: {
      get () {
        if (this.models.length === 0) return false
        return this.group.length >= this.models.length
      },
      set (input) {
        this.models.map(model => {
          const el = document.getElementById(`check-${model.id}`)

          if (el != null && el.checked !== input) el.click()
        })

        const el = document.getElementById('checkAll')
        if (el != null && el.checked !== input) {
          el.checked = input
        }
      }
    }
  },
  methods: {
    ...mapActions({
      bulkCopy: 'pwas/bulkCopy',
      bulkTrash: 'pwas/bulkTrash',
      bulkRestore: 'pwas/bulkRestore',
      bulkRemove: 'pwas/bulkRemove',
      bulkPlay: 'pwas/bulkPlay',
      bulkStop: 'pwas/bulkStop',
      alertSuccess: 'alerts/success',
      alertError: 'alerts/error',
      bulkPaymentChecking: 'pwas/bulkPaymentChecking',
      bulkPaymentInstalls: 'pwas/bulkPaymentInstalls',
      bulkPaymentTariffs: 'pwas/bulkPaymentTariffs',
      bulkPaymentBalance: 'pwas/bulkPaymentBalance'
    }),
    handlerGroup (id) {
      const index = this.group.indexOf(id)
      if (index === -1) {
        this.group.push(id)
      } else {
        this.group.splice(index, 1)
      }
    },
    async handleActionGroup (action) {
      switch (action) {
        case 'installs':
          try {
            await this.bulkPaymentInstalls({ids: this.group})
            this.activated = true
            this.$nextTick(async () => {
              await this.alertSuccess(this.$t('pwas.paid.success', {count: this.group.length}))
              await this.fetchPage()
            })
          } catch (e) {
            await this.alertError(e.response.data)
          }
          break
        case 'tariffs':
          try {
            await this.bulkPaymentTariffs({ids: this.group})
            this.activated = true
            this.$nextTick(async () => {
              await this.alertSuccess(this.$t('pwas.paid.success', {count: this.group.length}))
              await this.fetchPage()
            })
          } catch (e) {
            await this.alertError(e.response.data)
          }
          break
        case 'balance':
          try {
            await this.bulkPaymentBalance({ids: this.group})
            this.activated = true
            this.$nextTick(async () => {
              await this.alertSuccess(this.$t('pwas.paid.success', {count: this.group.length}))
              await this.fetchPage()
            })
          } catch (e) {
            await this.alertError(e.response.data)
          }
          break
        case 'customJs':
          this.$nextTick(async () => {
            await this.alertSuccess(this.$t('pwas.group.successCustomJs', {count: this.group.length}))
            await this.fetchPage()
          })
          break
        case 'meta':
          this.$nextTick(async () => {
            await this.alertSuccess(this.$t('pwas.group.successMeta', {count: this.group.length}))
            await this.fetchPage()
          })
          break
        case 'trash':
          try {
            await this.bulkTrash({ids: this.group, expired: 'true'})
            this.$nextTick(async () => {
              await this.alertSuccess(this.$t('pwas.group.successTrash', {count: this.group.length}))
              await this.fetchPage()
            })
          } catch (e) {
            await this.alertError(e.response.data)
          }
          break
        case 'restore':
          try {
            await this.bulkRestore({ids: this.group, expired: 'false'})
            this.$nextTick(async () => {
              await this.fetchPage()
              await this.alertSuccess(this.$t('pwas.group.successRestore', {count: this.group.length}))
            })
          } catch (e) {
            await this.alertError(e.response.data)
          }
          break
        case 'remove':
          try {
            await this.bulkRemove(this.group)
            this.$nextTick(async () => {
              await this.fetchPage()
              await this.alertSuccess(this.$t('pwas.group.successRemove', {count: this.group.length}))
            })
          } catch (e) {
            await this.alertError(e.response.data)
          }
          break
        case 'copyPwa':
          setTimeout(async () => {
            try {
              const withTracker = await this.$refs.confirmModal.show({
                title: this.$t('group.copyPwa.title'),
                message: this.$t('group.copyPwa.tracker.message'),
                okButton: this.$t('group.copyPwa.tracker.okButton'),
                cancelButton: this.$t('group.copyPwa.tracker.cancelButton')
              })
              if (withTracker) {
                await this.bulkCopy({ids: this.group, withTracker: true})
              } else {
                await this.bulkCopy({ids: this.group, withTracker: false})
              }
              this.$nextTick(async () => {
                await this.handlerPagination()
                await this.alertSuccess(this.$t('pwas.group.successCopy', {count: this.group.length}))
              })
            } catch (e) {
              await this.alertError(e.response.data)
            }
          }, 500)
          break
        case 'play':
          try {
            await this.bulkPlay(this.group)
            this.$nextTick(async () => {
              await this.fetchPage()
              await this.alertSuccess(this.$t('pwas.group.successPlay', {count: this.group.length}))
            })
          } catch (e) {
            await this.alertError(e.response.data)
          }
          break
        case 'stop':
          try {
            await this.bulkStop(this.group)
            this.$nextTick(async () => {
              await this.fetchPage()
              await this.alertSuccess(this.$t('pwas.group.successStop', {count: this.group.length}))
            })
          } catch (e) {
            await this.alertError(e.response.data)
          }
          break
      }
    }
  }
}
