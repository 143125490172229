<template>
  <div class="modal fade" id="faq-item-modal">
    <div class="modal-dialog modal-lg mt-0">
      <form class="modal-content" @submit.prevent="handler">
        <div class="modal-header">
          <h5 class="modal-title">Форма F.A.Q.</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group" :class="apiValidationErrors.enable ? 'has-danger' : ''">
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" id="enable" name="enable" v-model="enable">
                Вкл./Выкл.
                <i class="input-helper"></i>
              </label>
            </div>
            <label v-if="apiValidationErrors.enable" id="enable-error" class="error mt-2 text-danger"
                   for="enable">{{ apiValidationErrors.enable[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.question ? 'has-danger' : ''">
            <label for="question">Вопрос</label>
            <input class="form-control" id="question" name="question" type="text" tabindex="1" v-model="question"
                   :class="apiValidationErrors.question ? 'form-control-danger' : ''"
                   placeholder="Введите вопрос"
                   @focus="clearError('question')" autofocus>
            <label v-if="apiValidationErrors.question" id="question-error" class="error mt-2 text-danger"
                   for="question">{{ apiValidationErrors.question[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.answer ? 'has-danger' : ''">
            <label for="answer">Ответ</label>
            <textarea class="form-control" id="answer" name="answer" type="text" tabindex="2" v-model="answer"
                      :class="apiValidationErrors.answer ? 'form-control-danger' : ''"
                      placeholder="Введите ответ" rows="6" v-html="answer"
                      @focus="clearError('answer')" autofocus>
            </textarea>
            <label v-if="apiValidationErrors.answer" id="answer-error" class="error mt-2 text-danger"
                   for="answer">{{ apiValidationErrors.answer[0] }}</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success">Сохранить</button>
          <a href="javascript:void(0);" class="btn btn-light" data-dismiss="modal">Отмена</a>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import summernoteEditorMixin from '@/mixins/summernote-editor-mixin'

export default {
  name: 'FAQItemForm',
  props: ['faq_id'],
  mixins: [formMixin, summernoteEditorMixin],
  data: function () {
    return {
      id: false,
      question: '',
      answer: '',
      enable: false
    }
  },
  mounted () {
    const script = document.createElement('script')
    script.src = '/vendors/summernote/dist/lang/summernote-ru-RU.min.js'
    script.id = 'summernote-script-2'
    document.head.appendChild(script)
    const self = this
    const interval = setInterval(() => {
      try {
        // eslint-disable-next-line no-unexpected-multiline
        (function ($) {
          'use strict'
          if ($('#answer').length) {
            $('#answer').summernote({
              lang: 'ru-RU',
              height: 300,
              tabsize: 2,
              callbacks: {
                onChange: function (contents) {
                  self.answer = contents
                }
              }
            })
          }
          // eslint-disable-next-line no-undef
        })(jQuery)
        clearInterval(interval)
      } catch (e) {
      }
    }, 500)
  },
  methods: {
    handler: async function () {
      try {
        this.resetApiValidation()
        if (this.id) {
          await this.$store.dispatch('adminFAQItems/update', {
            item_id: this.id,
            _id: this.faq_id,
            enable: this.enable,
            question: this.question,
            answer: this.answer
          })
          await this.$store.dispatch(
            'alerts/success',
            'Запись успешно обновлён'
          )
        } else {
          await this.$store.dispatch('adminFAQItems/create', {
            _id: this.faq_id,
            enable: this.enable,
            question: this.question,
            answer: this.answer
          })
          await this.$store.dispatch(
            'alerts/success',
            'Запись успешно создан'
          )
        }
        this.resetApiValidation()
        await this.$parent.getData()
        this.enable = false
        this.question = ''
        this.answer = ''
        this.id = false
        // eslint-disable-next-line no-undef
        jQuery('#faq-item-modal').modal('hide')
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    }
  }
}
</script>
