import analytics from './analytics/en'
import app from './app/en'
import comment from './comment/en'
import domain from './domain/en'
import setting from './setting/en'
import tracker from './tracker/en'
import vertical from './vertical/en'

export default {
  translate: {
    title: 'PWA Translation<span class="url_ti_soig">{id}</span>',
    commentTitle: 'Translated comments for PWA<span class="url_ti_soig">{id}</span>',
    from: 'Language to translate',
    to: 'Language we\'re translating into',
    translate: 'Translate',
    backToRu: 'Return RU',
    delete: 'Delete translation',
    default: 'Default',
    newApp: 'Creating a new localization<span class="url_ti_soig">{id}</span>',
    newComments: 'Create translation of comments for "{title}"<span class="url_ti_soig">This action will create an exact copy of the <strong>"default"</strong> comments and translate them into the <strong>{langTo} language. It is important to remember</strong> that all comments in different languages ​​are <strong>independent of each other</strong>! Default comment changes will not be carried over to previously translated copies</span>',
    process: '<small>Copying and translating comments in progress, don\'t close the form!</small><small>Process: {count}/{total}</small>',
    success: 'Copy and translation completed successfully',
    returnSuccess: 'Labels dropped successfully'
  },
  google: {
    delete: 'Delete analytics?',
    settings: {
      title: 'Google Conversion Settings',
      code: 'Conversion Label',
      value: 'Value',
      currency: 'Currency',
      currencyInfo: 'Currency of the value, 3 capital letters (e.g. USD)'
    },
    events: ['Install', 'Opens', 'PUSH Subscription', 'Registration', 'Deposit']
  },
  facebook: {
    title: 'Facebook Pixel settings',
    label: 'Needed to send s2s events to Facebook',
    pixelInfo: 'Insert your Facebook pixel. We automatically track the "Lead" action. The lead is the discovery of Pwa',
    lead: 'What is a Lead?',
    leadInfo: 'Lead event selection',
    dontUse: 'Don\'t use',
    events: ['Install', 'Open', 'PUSH subscription', 'Sign up', 'Deposit'],
    delete: 'Delete pixel?',
    deleteMessage: 'Are you sure you want to delete the pixel {id}?',
    deleteButton: 'Delete',
    noPixel: 'Enter pixel first',
    multiForm: {
      title: 'Bulk adding FB pixels',
      message: 'Add pixels inline in <strong>pixel:lead</strong> format, where <strong>pixel</strong> are your FB pixels and <strong>lead</strong> - lead events that can take value <strong>install</strong> or <strong>registration</strong>',
      label: 'Pixels by row',
      errors: [
        'Enter pixel and event',
        'There is an error on the line {errorLine}! No pixel specified',
        'There is an error on the line {errorLine}! No event specified for ice',
        'There is an error on the line {errorLine}! Incorrect events for lead'
      ]
    }
  },
  tt: {
    dontUse: 'Don\'t use',
    events: ['Install', 'Open', 'PUSH subscription', 'Sign up s2s', 'Deposit s2s'],
    title: 'TikTok Pixel settings',
    label: 'Needed to send s2s events to TikTok',
    delete: 'Delete pixel?',
    noPixel: 'Enter pixel first'
  },
  generator: {
    title: 'Content Generation',
    langFrom: 'Main Language',
    mode: {
      title: 'Generation Modes',
      random: 'Random texts',
      googlePlay: 'Google play',
      template: 'From Template'
    },
    texts: 'Text',
    textsInfo: 'Generate text',
    comments: 'Comments',
    commentsInfo: 'Generate comments',
    media: 'Pictures',
    mediaInfo: 'Generate images',
    button: 'Generate',
    confirm: 'This action will replace all text and comments, are you sure you want to continue?',
    failed: 'Generation failed try again',
    success: 'PWA text generated successfully',
    pwaGenerateStart: '<small>Preparing to generate, don\'t close the form!</small><small>Process: {count}/{total}</small>',
    pwaGenerateBeginDownload: '<small>Google Play page download <strong>({lang})</strong>, don\'t close the form!</small><small>Process: {count}/{total}</small>',
    pwaGenerateFinishDownload: '<small>Google Play page completed <strong>({lang})</strong>, don\'t close the form!</small><small>Process: {count}/{total}</small>',
    pwaGenerateBeginText: '<small>Generating text <strong>({lang})</strong>, don\'t close the form!</small><small>Process: {count}/{total}</small>',
    pwaGenerateFinishText: '<small>Text generation completed <strong>({lang})</strong>, don\'t close the form!</small><small>Process: {count}/{total}</small>',
    pwaGenerateBeginComment: '<small>Generating <strong>({lang})</strong> comments, don\'t close the form!</small><small>Process: {count}/{total}</small>',
    pwaGenerateFinishComment: '<small>Comment generation finished <strong>({lang})</strong>, don\'t close the form!</small><small>Process: {count}/{total}</small>',
    pwaGenerateBeginTemplate: '<small>Processing template <strong>({lang})</strong>, don\'t close the form!</small><small>Process: {count}/{total}</small>',
    pwaGenerateFinishTemplate: '<small>Template completed <strong>({lang})</strong>, don\'t close the form!</small><small>Process: {count}/{total}</small>'
  },
  analytics,
  app,
  comment,
  domain,
  setting,
  tracker,
  vertical
}
