/* eslint-disable no-template-curly-in-string */
export default {
  method: 'Método',
  install: {
    label: 's2s postback em caso de instalação ({enable})',
    info: 'URL para o qual serão enviados os pedidos por eventos, para saber mais consulta F.A.Q.',
    setting: 'Configuração de instalações'
  },
  open: {
    label: 's2s postback com abertura ({enable})',
    info: 'URL para o qual serão enviados os pedidos por eventos, para saber mais consulta F.A.Q.',
    setting: 'Configuração de aberturas'
  },
  reopen: {
    label: 's2s postback com nova tentativa ({enable})',
    info: 'URL para o qual serão enviados os pedidos por eventos, para saber mais consulta F.A.Q.',
    setting: 'Configuração de entrada nova'
  },
  push: {
    label: 'postback com PUSH subscrição ({enable})',
    info: 'URL para o qual serão enviados os pedidos por eventos, para saber mais consulta F.A.Q.',
    setting: 'Configuração de PUSH subscrições'
  },
  deposit: {
    label: 's2s postback com depósito ({enable})',
    info: 'URL para o qual serão enviados os pedidos por eventos, para saber mais consulta F.A.Q.',
    setting: 'Configuração de depósito'
  },
  registration: {
    label: 's2s postback com registo ({enable})',
    info: 'URL para o qual serão enviados os pedidos por eventos, para saber mais consulta F.A.Q.',
    setting: 'Configuração de registos'
  }
}
