import {i18n} from '@/plugins/i18n'

export default {
  methods: {
    validateFile (file) {
      const maxFileSize = 2 * 1024 * 1024 // 2MB

      if (file.size > maxFileSize) {
        return { isValid: false, errorMessage: i18n.t('validations.image.maxSize') }
      }

      return { isValid: true }
    } }
}
