<template>
  <div class="modal fade" id="payment-method-modal">
    <div class="modal-dialog mt-0">
      <form class="modal-content" @submit.prevent="handler">
        <div class="modal-header">
          <h5 class="modal-title">Форма метода оплаты</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group" :class="apiValidationErrors.enable ? 'has-danger' : ''">
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" id="enable" name="enable" v-model="enable">
                Вкл./Выкл.
                <i class="input-helper"></i>
              </label>
            </div>
            <label v-if="apiValidationErrors.enable" id="enable-error" class="error mt-2 text-danger"
                   for="name">{{ apiValidationErrors.enable[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.image ? 'has-danger' : ''">
            <label for="image">Картинка</label>
            <input class="form-control" id="image" name="image" type="text" tabindex="1" v-model="image"
                   :class="apiValidationErrors.image ? 'form-control-danger' : ''"
                   placeholder="Введите картинку для метода оплаты"
                   @focus="clearError('image')" autofocus>
            <label v-if="apiValidationErrors.image" id="image-error" class="error mt-2 text-danger"
                   for="image">{{ apiValidationErrors.image[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.name ? 'has-danger' : ''">
            <label for="name">Название</label>
            <input class="form-control" id="name" name="name" type="text" tabindex="1" v-model="name"
                   :class="apiValidationErrors.name ? 'form-control-danger' : ''"
                   placeholder="Введите название метода оплаты"
                   @focus="clearError('name')" autofocus>
            <label v-if="apiValidationErrors.name" id="name-error" class="error mt-2 text-danger"
                   for="name">{{ apiValidationErrors.name[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.gateway ? 'has-danger' : ''">
            <label for="gateway">Шлюз</label>
            <select class="form-control" id="gateway" name="gateway" type="text" tabindex="2" v-model="gateway"
                    :class="apiValidationErrors.gateway ? 'form-control-danger' : ''" @focus="clearError('gateway')"
                    autofocus>
              <option value="null">Не выбрано</option>
              <option :key="gateway._id" v-for="gateway in this.$store.getters['adminGateways/list']" :value="gateway._id" v-if="gateway.method === id">{{ gateway.name }}
              </option>
            </select>
            <label v-if="apiValidationErrors.gateway" id="gateway-error" class="error mt-2 text-danger"
                   for="gateway">{{ apiValidationErrors.gateway[0] }}</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success">Сохранить</button>
          <a href="javascript:void(0);" class="btn btn-light" data-dismiss="modal">Отмена</a>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'

export default {
  name: 'PaymentMethodForm',
  mixins: [formMixin],
  data: function () {
    return {
      id: false,
      name: '',
      image: '',
      gateway: '',
      enable: false
    }
  },
  methods: {
    handler: async function () {
      try {
        this.resetApiValidation()
        if (this.id) {
          await this.$store.dispatch('adminPaymentMethods/update', {
            _id: this.id,
            enable: this.enable,
            name: this.name,
            image: this.image,
            gateway: this.gateway
          })
          await this.$store.dispatch(
            'alerts/success',
            'Метод оплаты успешно обновлён'
          )
        } else {
          await this.$store.dispatch('adminPaymentMethods/create', {
            enable: this.enable,
            name: this.name,
            image: this.image,
            gateway: this.gateway
          })
          await this.$store.dispatch(
            'alerts/success',
            'Метод оплаты успешно создан'
          )
        }
        this.resetApiValidation()
        await this.$parent.getData()
        this.enable = false
        this.name = ''
        this.image = ''
        this.gateway = ''
        this.id = false
        // eslint-disable-next-line no-undef
        jQuery('#payment-method-modal').modal('hide')
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    }
  }
}
</script>
