import service from '@/store/services/admin/tariffs-service'
import middleware from '../middleware'

const state = () => ({
  models: []
})

const mutations = {
  setData (state, payload) {
    state.models = payload
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then(res => {
        commit('setData', res)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  sort ({commit, dispatch}, params) {
    return service.sort(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create ({commit, dispatch}, params) {
    return service.create(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  oneUpdate ({commit, dispatch}, params) {
    return service.patch(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  delete ({commit, dispatch}, params) {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => state.models
}

const adminTariff = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminTariff
