import LandingForm from '@/pages/landing-pages/Form.vue'
import formMixin from '@/mixins/form-mixin'
import titleMixin from '@/mixins/title-mixin'
import Loading from '@/components/Loading.vue'
import {mapActions, mapGetters} from 'vuex'
import accessesMixin from '@/mixins/accesses-mixin'

export default {
  components: {LandingForm, Loading},
  mixins: [titleMixin, formMixin, accessesMixin],
  data () {
    return {
      loading: true,
      formData: {
        sector: [
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: false},
          {label: '', isWin: true},
          {label: '', isWin: false}
        ],
        name: '',
        tags: [],
        type: '',
        body: '',
        theme: '',
        buttons: {
          go: '',
          more: ''
        },
        revolutions: 1,
        logo: null,
        modal: {
          header: '',
          body: '',
          button: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      types: 'landingPages/types',
      themes: 'landingPages/themes'
    })
  },
  watch: {
    formData: {
      async handler (n, o) {
        if (n.type !== o.type) {
          this.formData.theme = this.themes.filter(theme => theme.type === n.type)[0].id
        }
        if (this.processing === false && n.name === o.name && JSON.stringify(n.tags) === JSON.stringify(o.tags)) {
          await this.updatePreview(n)
        }
      }
    }
  },
  methods: {
    ...mapActions({
      updatePreview: 'landingPages/updatePreview',
      fetchDataTypes: 'landingPages/fetchDataTypes',
      fetchDataThemes: 'landingPages/fetchDataThemes'
    }),
    async init () {
      await this.fetchDataTypes({page: 1, limit: 100, sort: 'id'})
      await this.fetchDataThemes({page: 1, limit: 100, sort: 'id'})
    }
  }
}
