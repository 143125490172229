<template>
  <loading v-model="loading" class="card-body pt-0">
    <tariff-menu/>
    <activate ref="activate"/>
    <div class="tab-content">
      <div :id="id" class="tab-pane" :class="id==='individual'?'fade in active':''"
           v-for="(type, id) of types" :key="id">
        <div :class="getClass(type)">
          <mains :type="type" v-if="id!=='history'"/>
          <history v-else/>
        </div>
      </div>
    </div>
  </loading>
</template>
<script>
import * as components from './components'
import titleMixin from '@/mixins/title-mixin'
import Loading from '@/components/Loading.vue'

export default {
  name: 'SubscribeTariffs',
  title: function () {
    return this.$t('titles.tariffs')
  },
  components: {Loading, ...components},
  mixins: [titleMixin],
  data () {
    return {
      loading: false,
      types: {
        individual: 'Индивидуальный',
        command: 'Командный',
        commercial: 'Коммерческий',
        history: 'history'
      }
    }
  },
  created () {
    this.asyncData()
  },
  methods: {
    async asyncData () {
      this.loading = true
      await this.$store.dispatch('tariffs/index', {
        page: 1, limit: 10, sort: '-expires'
      })
      this.loading = false
    },
    getClass (type) {
      const tariffs = []
      for (const tariff of this.$store.getters['tariffs/mains']) {
        if (tariff.type === type) {
          tariffs.push(tariff)
        }
      }
      return tariffs.length > 3 ? 'important_zero_padding' : ''
    }
  }
}
</script>
<style scoped>
.important_zero_padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
