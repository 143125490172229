import Teams from './Teams.vue'

export default (meta, header) => {
  return [
    {
      path: 'teams',
      name: 'Teams',
      components: {default: Teams, header: header},
      meta: meta
    },
    {
      path: 'teams/:group',
      name: 'TeamsGroup',
      components: {default: Teams, header: header},
      meta: meta
    }
  ]
}
