<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-dark">
                <li class="breadcrumb-item">
                  <router-link :to="{name: 'Admin'}">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link :to="{name: 'AdminFAQ'}">F.A.Q.</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page"><span>{{ faq.name }}</span></li>
              </ol>
            </nav>
            <h4 class="card-title">Записи</h4>
            <div class="mb-2">
              <button type="button" class="btn btn-outline-success btn-fw" @click="handlerCreate"><i
                class="icon-plus"></i> Добавить
              </button>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th>
                    Вкл./Выкл.
                  </th>
                  <th>
                    Вопрос
                  </th>
                  <th>
                    Ответ
                  </th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                  <th></th>
                </tr>
                </thead>
                <tbody id="dragula">
                <faq-item-field :item="item" v-for="item in items" :key="item._id" :faq_id="FAQID"/>
                </tbody>
              </table>
            </div>
            <faq-item-form ref="form" :faq_id="FAQID"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FAQItemField from './components/FAQItemField.vue'
import FAQItemForm from './components/FAQItemForm.vue'

export default {
  name: 'AdminFAQItems',
  components: {'faq-item-field': FAQItemField, 'faq-item-form': FAQItemForm},
  computed: {
    FAQID: function () {
      return this.$route.params.id
    }
  },
  data: function () {
    return {
      faq: {},
      items: []
    }
  },
  created: async function () {
    await this.getData()
  },
  mounted: function () {
    const self = this
    // eslint-disable-next-line no-undef
    dragula([document.getElementById('dragula')])
      .on('drop', async function () {
        const rows = document.querySelectorAll('tr[data-id]')
        let ids = []
        for (const row of rows) {
          if (row.className !== 'gu-mirror') {
            ids.push(row.getAttribute('data-id'))
          }
        }
        await self.$store.dispatch('adminFAQItems/sort', {_id: self.FAQID, ids})
      })
  },
  methods: {
    handlerCreate: function () {
      this.$refs.form.enable = false
      this.$refs.form.answer = ''
      this.$refs.form.question = ''
      this.$refs.form.id = false
      // eslint-disable-next-line no-undef
      jQuery('#faq-item-modal').modal('show')
      // eslint-disable-next-line no-undef
      jQuery('#answer').summernote('code', '')
    },
    getData: async function () {
      this.items = []
      await this.$store.dispatch('adminFAQItems/index', {_id: this.FAQID})
      this.items = this.$store.getters['adminFAQItems/items']
      this.faq = this.$store.getters['adminFAQItems/faq']
    }
  }
}
</script>
