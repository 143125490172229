export default {
  banners: {
    notUsePublicPersonInAds: true
  },
  messages: [
    // {
    //   id: 0,
    //   shown: false,
    //   text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using'
    // }
  ]
}
