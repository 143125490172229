import service from '@/store/services/teams-service'
import middleware from './middleware'

const state = {
  members: [],
  groups: [],
  count: 0,
  member: {},
  logs: [],
  logsCount: 0,
  groupsLoaded: false
}

const mutations = {
  setMembers: (state, payload) => {
    state.members = payload
  },
  setGroups: (state, payload) => {
    state.groups = payload
    state.groupsLoaded = true
  },
  setCount: (state, payload) => {
    state.count = payload
  },
  setMember: (state, payload) => {
    state.member = payload
  },
  setLogs: (state, payload) => {
    state.logs = payload
  },
  setLogsCount: (state, payload) => {
    state.logsCount = payload
  }
}

const actions = {
  index: ({commit, dispatch}, params) => service.index(params)
    .then(response => {
      const {models, groups, count} = response
      commit('setMembers', models)
      commit('setGroups', groups)
      commit('setCount', count)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  create: ({commit, dispatch}, params) => service.create(params)
    .then(res => {
      commit('setMember', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  update: ({commit, dispatch}, params) => service.update(params)
    .then(() => {
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  delete: ({commit, dispatch}, params) => service.delete(params)
    .then(() => {
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  member: ({commit, dispatch}, params) => service.member(params)
    .then(res => {
      commit('setMember', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  logs: ({commit, dispatch}, params) => service.logs(params)
    .then(res => {
      const {data, itemsCount} = res
      commit('setLogs', data)
      commit('setLogsCount', itemsCount)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  members: state => state.members,
  groups: state => state.groups,
  count: state => state.count,
  member: state => state.member,
  logs: state => state.logs,
  logsCount: state => state.logsCount,
  groupsLoaded: state => state.groupsLoaded
}

const teams = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default teams
