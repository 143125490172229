<template>
  <thead class="TableSortHead">
  <tr>
    <slot name="checkAll"></slot>
    <th :class="item.class ? item.class : ''" v-for="(item, index) of items" :key="index">
      <div class="d_inline_block sort_butt_tgus sort-item" data-toggle="tooltip"
           :title="$t(item.title) + (item.param ? item.param : '')" :data-original-title="$t(item.title) + (item.param ? item.param : '')">
        <div class="d_flex align_center" @click="handler(item.sort)">
          <span>{{ !item.param ? $t(item.label) : item.param }}</span>
          <div class="icon_sort_g" v-if="item.sort && item.sort !== current"></div>
          <div class="icon_sort_g_down" v-if="item.sort === current && type"></div>
          <div class="icon_sort_g_up" v-if="item.sort === current && !type"></div>
        </div>
      </div>
    </th>
  </tr>
  </thead>
</template>
<script>
import tooltipMixin from '@/mixins/tooltip-mixin'

export default {
  name: 'TableSortHead',
  props: ['items', 'value'],
  mixins: [tooltipMixin],
  computed: {
    type: function () {
      return this.value.toString().search('-') === -1
    },
    current: function () {
      return this.type ? this.value : this.value.substring(1)
    }
  },
  methods: {
    handler: function (sort) {
      if (sort) {
        this.hideTooltip()
        if (this.current === sort) {
          this.$emit('input', this.type ? `-${sort}` : sort)
        } else {
          this.$emit('input', `-${sort}`)
        }
      }
    }
  }
}
</script>
<style scoped>
.sort-item:hover .icon_sort_g_up {
  opacity: 0.7;
}
.sort-item:hover .icon_sort_g_down {
  opacity: 0.7;
}
</style>
