import Dates from './Dates.vue'
import Unique from './Unique.vue'
import Countries from './Countries.vue'
import Owners from './Owners.vue'
import Hosts from './Hosts.vue'

export default (meta, header) => {
  return [
    {
      path: 'statistics/dates',
      name: 'AdminStatisticsDates',
      components: {default: Dates, header},
      meta
    },
    {
      path: 'statistics/unique',
      name: 'AdminStatisticsUnique',
      components: {default: Unique, header},
      meta
    },
    {
      path: 'statistics/countries',
      name: 'AdminStatisticsCountries',
      components: {default: Countries, header},
      meta
    },
    {
      path: 'statistics/owners',
      name: 'AdminStatisticsOwners',
      components: {default: Owners, header},
      meta
    },
    {
      path: 'statistics/hosts',
      name: 'AdminStatisticsHosts',
      components: {default: Hosts, header},
      meta
    }
  ]
}
