<template>
  <modal
    id="admin-bot-locale-form-modal"
    ref="modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Створення локалі офера для #{{ owner }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group" :class="apiValidationErrors.region ? 'has-danger' : ''">
        <label for="region">Регіон</label>
        <select class="form-control" id="region" name="region" type="text" tabindex="1"
                v-model="region"
                :class="apiValidationErrors.region ? 'form-control-danger' : ''"
                @focus="clearError('region')">
          <option value="europe">{{ $t('bot.europe') }}</option>
          <option value="australiaAndOceania">{{ $t('bot.australiaAndOceania') }}</option>
          <option value="asia">{{ $t('bot.asia') }}</option>
          <option value="latinAmerica">{{ $t('bot.latinAmerica') }}</option>
          <option value="america">{{ $t('bot.america') }}</option>
        </select>
        <label v-if="apiValidationErrors.region" class="error mt-2 text-danger"
               for="region">{{ apiValidationErrors.region[0] }}</label>
      </div>
      <select2
        class="form-group"
        id="tracker"
        name="tracker"
        v-model="tracker"
        :options="select2FiltersOptions"
        :settings="select2FiltersSettings"
        inputClass="form-control"
        placeholder="Оберіть локаль трекера"
      >
        <template v-slot:before>
          <label for="whitePage">Локаль трекера</label>
        </template>
        <template v-slot:after>
          <label v-if="apiValidationErrors.tracker" class="error mt-2 text-danger" for="tracker">
            {{ apiValidationErrors.tracker[0] }}
          </label>
        </template>
      </select2>
      <select2
        class="form-group"
        id="whitePage"
        name="whitePage"
        v-model="whitePage"
        :options="whitePageOptions"
        inputClass="form-control"
        :placeholder="$t('trackers.linkSettings.whiteLangPlaceholder')"
      >
        <template v-slot:before>
          <label for="whitePage">{{ $t('trackers.linkSettings.whiteLang') }}</label>
        </template>
        <template v-slot:after>
          <label v-if="apiValidationErrors.whitePage" class="error mt-2 text-danger" for="whitePage">
            {{ apiValidationErrors.whitePage[0] }}
          </label>
        </template>
      </select2>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import {mapActions} from 'vuex'
import {whitePageLanguages} from '@pwa.group/pwa.dictionary'
import Select2 from '@/components/Select2.vue'
import trackerMixin from '@/mixins/tracker-mixin'

const defaultValues = () => ({
  owner: '',
  tracker: '',
  whitePage: '',
  region: ''
})

export default {
  name: 'AdminBotLocaleForm',
  mixins: [formMixin, trackerMixin],
  components: {Modal, SubmitModalForm, Select2},
  data () {
    return {
      processing: false,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    whitePageOptions () {
      return whitePageLanguages.map(lang => ({
        id: lang.id,
        text: this.$t(lang.text)
      }))
    }
  },
  watch: {
    tracker (n) {
      const index = this.whitePageOptions.findIndex(({id}) => id === n)
      if (index !== -1) {
        this.whitePage = n
      }
    }
  },
  methods: {
    ...mapActions({
      create: 'bot/adminCreateLocale'
    }),
    handlerOpen ({owner, region}) {
      this.skip()
      this.owner = owner
      this.region = region || 'europe'
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.$refs.modal.close()
      this.resolvePromise(false)
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.create({
          owner: this.owner,
          tracker: this.tracker,
          whitePage: this.whitePage,
          region: this.region
        })
        this.resetApiValidation()
        this.resolvePromise(true)
        this.processing = false
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
