<template>
  <date-picker
    :value="this.value"
    range
    :clearable="clearable"
    :placeholder="$t('pushes.filters.range.placeholder')"
    :open.sync="open"
    :lang="lang"
    @input="handler"
  >
    <template v-slot:footer="{ emit }">
      <button class="mx-btn mx-btn-text" @click="today(emit)">
        {{ $t('pushes.filters.range.today') }}
      </button>
      <button class="mx-btn mx-btn-text" @click="tomorrow(emit)">
        {{ $t('pushes.filters.range.tomorrow') }}
      </button>
      <button class="mx-btn mx-btn-text" @click="nextSevenDay(emit)">
        {{ $t('pushes.filters.range.nextSevenDay') }}
      </button>
    </template>
  </date-picker>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'
import 'vue2-datepicker/locale/en'
import 'vue2-datepicker/locale/pt'
import 'vue2-datepicker/index.css'

export default {
  name: 'RangeFilter',
  components: {DatePicker},
  props: {value: Array, clearable: {type: Boolean, default: false}},
  data () {
    return {
      open: false
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    }
  },
  methods: {
    nextSevenDay (emit) {
      this.open = false
      const start = new Date()
      const end = new Date()
      start.setTime(end.getTime() + (3600 * 7) * 1000 * 24)
      const date = [end, start]
      emit(date)
    },
    today (emit) {
      this.open = false
      const start = new Date()
      const end = new Date()
      end.setTime(end.getTime())
      const date = [start, end]
      emit(date)
    },
    tomorrow (emit) {
      this.open = false
      const start = new Date()
      const end = new Date()
      start.setTime(end.getTime() + 3600 * 1000 * 24)
      const date = [end, start]
      emit(date)
    },
    handler (range) {
      this.$emit('input', [this.formatDate(range[0]), this.formatDate(range[1])])
    },
    formatDate (date) {
      if (date == null) return ''
      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      const year = date.getFullYear()
      if (month.length < 2) { month = '0' + month }
      if (day.length < 2) { day = '0' + day }
      return [year, month, day].join('-')
    }
  }
}
</script>
<style>
.mx-datepicker-main .mx-calendar-time {
  border-radius: 3px;
  background: #2A2E32;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05);
  color: #A0A1A4;
}
.mx-datepicker-main .mx-time-column {
  border-color: #4A4D51;
}
.mx-datepicker-main .mx-time-header {
  border-color: #4A4D51;
}
.mx-time-column .mx-time-item.active {
  color: #fff;
  background: #21BF73;
}
body .mx-datepicker-main {
  margin-top: 3px;
  border-radius: 3px;
  border: 1px solid #4A4D51;
  background: #2A2E32;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05);
  color: #A0A1A4;
}
body .mx-calendar-content .cell.active {
  background-color: #21BF73;
  color: #fff !important;
}
body .mx-calendar-content .cell.in-range,
body .mx-calendar-content .cell.hover-in-range {
  color: #fff !important;
  background-color: #3b3e41 !important;
}
.mx-calendar-content .cell.in-range, .mx-calendar-content .cell.hover-in-range {
  background-color: rgba(33,191,115,.1) !important;
  color: #fff !important;
}
.mx-table-date .today {
  color: #21BF73 !important;
}
body .mx-calendar+.mx-calendar,
body .mx-datepicker-footer {
  border-color: #4A4D51;
}
body .mx-datepicker-content {
  display: flex;
  flex-direction: row-reverse;
}
body .mx-datepicker-footer {
  display: flex;
  flex-direction: column;
  padding: 9px 10px;
  text-align: right;
  border-right: 1px solid #4A4D51;
  border-top: 0;
}
body .mx-datepicker-footer .mx-btn-text {
  padding: 6px 0;
  margin-bottom: 10px;
  color: #ccc;
}
body .mx-btn {
  color: rgba(255,255,255,.4);
}
body .mx-btn:hover {
  color: #fff;
}
@media (max-width: 767px) {
  body .mx-datepicker-footer {
    display: none;
  }
  body .mx-calendar+.mx-calendar {
    display: none;
  }
}
</style>
