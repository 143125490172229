import middleware from './middleware'
import service from '@/store/services/users-service'

const state = () => ({
  cloudflare: []
})

const mutations = {
  setCloudflare (state, payload) {
    state.cloudflare = payload.filter((value, index, self) => self.findIndex(v => v.email === value.email) === index)
  }
}

const actions = {
  asyncCloudflare: ({commit}) => {
    return service.cloudflare()
      .then((res) => {
        commit('setCloudflare', res)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  cloudflare: state => state.cloudflare
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
