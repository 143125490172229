<template>
  <modal id="save-info" ref="confirmLeavePageModal" class="ConfirmLeavePageModal">
    <div class="info_blk_g_mod text-center">
      <div class="icon_sinf_g">
        <img src="/images/icon_info_attention.png" alt="">
      </div>
      <div class="title_inf_glbk">{{ $t('modalSave.title') }}</div>
      <div class="descr_inf_ico_g">
        {{ $t('modalSave.message') }}
      </div>
    </div>
    <div class="foot_inf_sg_g">
      <div class="in_to_foot_br_g">
        <div class="row has_big_rog">
          <div class="col-6 item_pwa_shs_g">
            <button @click="handlerCancel"
                    class="w-100 btn_simple_border d_flex align_center justify_center yell_col no_underline">
              {{ $t('modalSave.proceed') }}
            </button>
          </div>
          <div class="col-6 item_pwa_shs_g">
            <button @click="handlerClose"
                    class="w-100 btn_simple_border d_flex align_center justify_center no_underline full_plin yell_col">
              {{ $t('general.cancel') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from './Modal.vue'

export default {
  name: 'ConfirmLeavePageModal',
  components: {Modal},
  data () {
    return {
      // Private variables
      handlerConfirm: undefined,
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  methods: {
    confirm (handlerConfirm) {
      this.handlerConfirm = handlerConfirm
      this.$refs.confirmLeavePageModal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerClose () {
      this.$refs.confirmLeavePageModal.close()
    },
    async handlerCancel () {
      this.$refs.confirmLeavePageModal.close()
      this.resolvePromise(true)
    }
  }
}
</script>
<style scoped>
.title_inf_glbk {
  color: #F1C62D;
}
</style>
