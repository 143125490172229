<template>
  <div class="BotLayout page-full-fluid-width tariffs_wrapp bot-layout">
    <div class="row">
      <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
        <div class="card">
          <div class="card-body pt-0">
            <BaseRouterTabs :tabs="items" />
            <div v-if="isOfferPage" class="bot-layout__offer-add">
              <button @click="$router.push({name: 'BotOffersCreate'})" class="btn_green_g mobile-full-w">{{ $t('bot.addOffer')}}</button>
            </div>
            <div class="tab-content">
              <router-view/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import BaseRouterTabs from '@/components/base/BaseRouterTabs.vue'

export default {
  name: 'BotLayout',
  components: {BaseRouterTabs},
  computed: {
    ...mapGetters({
      'tariff': 'user/tariff'
    }),
    isOfferPage () {
      return this.$route.name === 'BotOffers'
    },
    items () {
      return [
        {
          label: this.$t('bot.menu.keys'),
          to: {name: 'BotKeys'}
        },
        {
          label: this.$t('bot.menu.offers'),
          to: {name: 'BotOffers'}
        }
      ]
    }
  },
  created () {
    if (this.tariff.bot === false) {
      this.$router.push({name: 'PWAs'})
    }
  }
}
</script>
<style scoped lang="sass">
.bot-layout
  &__offer-add
    display: flex
    justify-content: flex-end
    margin-bottom: 22px
    padding-bottom: 32px
    border-bottom: 1px solid rgba(255, 255, 255, .15)
</style>
