<template>
  <div class="style_form_lab">
    <div class="form-group">
      <div class="row d-block d-sm-flex align-items-center">
        <slot/>
        <div class="col-sm-6 mt-3 mt-sm-0 d-flex align-items-center justify-content-end flex-row-reverse flex-sm-row">
          <div class="split-testing-link-count ml-3 ml-sm-0 mr-sm-3">
            {{
              $tc('pwas.tracker.splitTesting.count-links', splitTestingLinks.length, {count: splitTestingLinks.length})
            }}
          </div>
          <div class="">
            <button
              @click="handlerAddLink(true)"
              class="btn_round_act add_one_mor_g d_flex align_center justify_center pull-right"
              type="button">
              <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.5 2V16" stroke="#21BF73" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M1.79102 9H15.2077" stroke="#21BF73" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="split-testing-link-container">
      <split-testing-link
        v-for="(splitTestingLink, id) of splitTestingLinks"
        :value="splitTestingLink"
        :id="id"
        :errors="errors"
        @input="handlerChangeLink($event, id)"
        :key="id"
        :endWeight="endWeight">
        <button class="btn_round_act remov_act_g d_flex align_center justify_center"
                type="button"
                v-on:click="handlerDeleteLink(id, splitTestingLink)">
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
              fill="#FD5E53" stroke="#2A2E32" stroke-width="0.5"/>
          </svg>
        </button>
      </split-testing-link>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import SplitTestingLink from './SplitTestingLink.vue'
import {mapActions} from 'vuex'

export default {
  name: 'SplitTestingForm',
  components: {Info, SplitTestingLink},
  props: {
    value: {
      type: Array,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      splitTestingLinks: []
    }
  },
  created () {
    this.splitTestingLinks = this.value
  },
  computed: {
    endWeight () {
      let weight = 100
      if (this.splitTestingLinks.length > 0) {
        weight -= this.splitTestingLinks.reduce((accumulator, object) => Number(accumulator) + Number(object.weight), 0)
      }
      return weight
    }
  },
  watch: {
    splitTestingLinks: {
      handler (n) {
        if (n.length <= 0) {
          this.handlerAddLink()
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      alertsSuccess: 'alerts/success',
      alertWarning: 'alerts/warning'
    }),
    handlerAddLink (alert = false) {
      this.splitTestingLinks.push({
        redirect: '',
        postback: null,
        weight: this.endWeight
      })
      if (alert) {
        this.alertsSuccess(this.$t('pwas.tracker.splitTesting.added'))
        this.save()
      }
    },
    handlerDeleteLink (id, value) {
      this.splitTestingLinks = this.splitTestingLinks.filter((n) => { return n.redirect !== value.redirect })
      if (this.splitTestingLinks.length === 0) {
        this.handlerAddLink()
      }
      this.alertWarning(this.$t('pwas.tracker.splitTesting.deleted'))
      this.save()
    },
    save () {
      this.$emit('input', this.splitTestingLinks)
    },
    handlerChangeLink (input, index) {
      this.splitTestingLinks[index] = input
      this.save()
    }
  }
}
</script>
<style scoped>
.split-testing-link-count {
  font-size: 12px;
}
</style>
