<template>
  <div class="content-wrapper">
    <MigrationIPsForm ref="form"/>
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Миграция IP</h4>
            <p class="card-description">Для перенесення доступно <strong class="text-success">{{ count }}</strong> PWA</p>
            <button
              @click="handleMigrate"
              type="button" class="btn btn-outline-success btn-fw mb-2">Перенести
            </button>
            <div class="row mb-2">
              <div class="col-md-6 col-lg">
                <input class="form-control" v-model="filter.domain" placeholder="Пошук за доменом"/>
              </div>
              <div class="col-md-6 col-lg">
                <input class="form-control" v-model="filter.ip" placeholder="Пошук за IP"/>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6 col-lg mb-2">
                <select class="form-control" v-model="filter.status">
                  <option value="" selected>Фільтр за статусом</option>
                  <option :key="status.id" v-for="status in statuses" :value="status.id">
                    {{ $t(status.label, 'uk') }}
                  </option>
                </select>
              </div>
              <owner-filter
                class="col-md-6 col-lg"
                v-model="filter.owner"
                placeholder="Фільтр за користувачем"
              />
              <select2
                v-model="filter.ip"
                :options="ipsOptions"
                :settings="{allowClear: true}"
                class="col-md-6 col-lg"
                id="ip"
                name="ip"
                inputClass="form-control"
                placeholder="Поиск по серверу"
              />
            </div>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th class="text-center align-middle">Домен</th>
                  <th class="text-center align-middle" style="min-width: 220px;">Владелец</th>
                  <th class="text-center align-middle">Статус</th>
                  <th class="text-center align-middle">IP</th>
                  <th class="text-center align-middle">Сервер</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model.id">
                  <td>
                    {{ model.domain }}
                  </td>
                  <td>
                    <owner :data="model.owner"/>
                  </td>
                  <td class="w-100"><span :class="`text-${model.status.class}`">{{
                      $t(model.status.label, 'uk')
                    }}</span></td>
                  <td>
                    {{ model.ip.id }}
                  </td>
                  <IpColumn v-model="filter.ip" :ip="model.ip"/>
                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import OwnerFilter from '@/pages/admin/components/OwnerFilter.vue'
import Select2 from '@/components/Select2.vue'
import Owner from '@/pages/admin/components/tables/Owner.vue'
import IpColumn from '@/components/admin/pwas/IpColumn.vue'
import MigrationIPsForm from './MigrationIPsForm.vue'

export default {
  name: 'System',
  components: {IpColumn, Owner, Select2, OwnerFilter, MigrationIPsForm},
  data () {
    return {
      loading: true,
      filter: {
        status: '',
        owner: '',
        domain: '',
        server: '',
        ip: ''
      }
    }
  },
  watch: {
    filter: {
      handler () {
        this.fetchPage()
      },
      deep: true
    }
  },
  created () {
    this.fetchPage()
  },
  computed: {
    ...mapGetters({
      models: 'adminSystem/migrationIPs',
      count: 'adminSystem/migrationIPsCount',
      ipsOptions: 'ips/options'
    }),
    statuses () {
      return this.$store.getters['pwas/statuses'].filter(status => status.id >= 2)
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'adminSystem/asyncMigrationIPs',
      fetchIPs: 'ips/adminFetchSelect2'
    }),
    debounceFetchData: _.debounce(async function () {
      await this.fetchIPs({
        page: 1,
        limit: 100,
        sort: 'id'
      })
      await this.fetchData({
        filter: this.filter,
        page: 1,
        limit: 20,
        sort: '-createdAt'
      })
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData()
    },
    async handleMigrate () {
      const ok = await this.$refs.form.handleOpen(this.filter)
      if (ok === true) {
        this.fetchPage()
      }
    }
  }
}
</script>
