<template>
  <modal
    ref="modalPrivacy"
    id="admin-users-privacy-modal"
    class="ModalPrivacy modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Privacy Policy</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>test</p>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'

export default {
  name: 'ModalPrivacy',
  components: {SubmitModalForm, Modal},
  mixins: [formMixin],
  data () {
    return {
      id: null,
      processing: false
    }
  },
  methods: {
    handlerOpen () {
      this.$refs.modalPrivacy.open()
    },
    handlerCancel () {
      this.$refs.modalPrivacy.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        // await this.$store.dispatch('adminUsers/update', {})
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modalPrivacy.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    }
  }
}
</script>
