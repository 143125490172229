import Proxies from './Proxies.vue'

export default (meta, header) => {
  return [
    {
      path: 'proxies',
      name: 'AdminProxies',
      components: {default: Proxies, header},
      meta
    }
  ]
}
