export default {
  notFound: 'Сторінку не знайдено',
  analyticsGroups: 'Статистика по днях',
  analyticsUniques: 'Статистика по униках',
  bills: 'Рахунки',
  transactions: 'Транзакції',
  pwa: {
    trash: 'Кошик PWA',
    trashBadge: 'Кошик порожній | В кошику одне PWA | В кошику {count} PWA | В кошику {count} PWA',
    app: 'Додаток PWA #{id}',
    comments: 'Коментарі PWA #{id}',
    create: 'Створення PWA',
    my: 'Мої PWA',
    setting: 'Налаштування PWA #{id}',
    tracker: 'Трекер PWA #{id}',
    domain: 'Домен PWA #{id}',
    vertical: 'Вертикаль PWA #{id}',
    templates: 'Шаблони PWA',
    analytics: 'Аналітика PWA #{id}'
  },
  referrals: 'Реф. програма',
  tariffs: 'Тарифи',
  teams: 'Командна робота',
  trackers: {
    my: 'Мої трекери',
    create: 'Створення трекера',
    edit: 'Редагування трекера #{id}'
  },
  pushes: {
    pushes: 'PUSH розсилка',
    create: 'Додавання розсилки',
    edit: 'Редагування розсилки {id}'
  },
  bot: {
    keys: 'Ключі бота',
    offers: 'Офери бота'
  },
  mirrors: {
    my: 'Мої Дзеркала',
    trash: 'Кошик Дзеркал',
    trashBadge: 'Кошик порожній | В кошику одне Дзеркало | В кошику {count} Дзеркал | В кошику {count} Дзеркал'
  }
}
