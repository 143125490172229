export default {
  all: 'Все',
  add: 'Добавить сотрудника',
  deleteEmployee: 'Вы уверены что хотите удалить сотрудника?',
  employeeDeleted: 'Запись удалена',
  employeeSaved: 'Запись сохраненна',
  accessData: 'Данные для доступа сотрудника',
  link: 'Ссылка для сотрудника:',
  password: 'Пароль:',
  name: 'Имя сотрудника',
  groups: 'Виберете или создайте группу',
  privacySettings: 'Настройки приватности',
  isPWAsOwner: 'Только свои PWA',
  customPWATag: 'Свой тег для PWA',
  isCompaniesOwner: 'Только свои PUSH',
  customCompaniesTag: 'Свой тег для PUSH',
  isLandingsOwner: 'Только свои Лендинги',
  customLandingsTag: 'Свой тег для Лендинга',
  isTrackersOwner: 'Только свои трекеры',
  customTrackerTag: 'Свой тег для трекера',
  accessSettings: 'Настройки доступа',
  view: 'Просмотр',
  edit: 'Изменение',
  delete: 'Удаление',
  tracker: 'Трекер',
  statistics: 'Статистика',
  landings: 'Лендинги',
  teams: 'Командная работа',
  finance: 'Тарифы и финансы',
  logs: {
    title: 'Логирование',
    country: 'Страна',
    device: 'Устройство',
    os: 'ОС',
    browser: 'Браузер',
    login: 'Дата логина'
  },
  validator: {
    username: {
      maxLength: 'Имя пользователя должно бы меньше 30 символов'
    },
    access: {
      isOneAccess: 'У сотрудника должен быть хотя бы один доступ'
    }
  },
  trash: {
    delete: 'Удаление сотрудника',
    deleteMessage: 'Вы уверены что хотите удалить сотрудника {username}?',
    deleteButton: 'Удалить'
  }
}
