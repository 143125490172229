<template>
  <div class="EmptyPage no_pwa_here">
    <div class="in_to_nothing_pwa text_center">
      <PersonReadyToWork/>
      <div class="texts_pwa_no">
        <div class="text_this_no_pw"><slot name="title" /></div>
        <div class="butt_nos_g">
          <slot name="link" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PersonReadyToWork from '@/components/animatedSvgs/PersonReadyToWork.vue'

export default {
  name: 'EmptyPage',
  components: {
    PersonReadyToWork
  }
}
</script>
<style scoped lang="sass">
</style>
