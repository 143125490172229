<template>
  <div class="pageTariffs page-full-fluid-width parent_tooltip" v-if="accesses.finance.view">
    <div class="row">
      <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
        <div class="card">
          <component v-bind:is="tariffType"></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import InstallationTariffs from './installations.vue'
import SubscribeTariffs from './subscribe.vue'
import accessesMixin from '@/mixins/accesses-mixin'

export default {
  name: 'Tariffs',
  components: {InstallationTariffs, SubscribeTariffs},
  mixins: [accessesMixin],
  computed: {
    ...mapGetters({
      user: 'identity'
    }),
    tariffType () {
      return this.user.tariffInstalled ? 'InstallationTariffs' : 'SubscribeTariffs'
    }
  }
}
</script>
