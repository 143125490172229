<template>
  <div class="d-md-flex analytics-filters">
    <div class="flex-grow-1 Filters">
      <form v-if="filters.length" class="forms-sample">
<!--        <div class="label_lf_to">{{ $t('analytics.filters') }}:</div>-->
        <div class="item_filt_g row_filters grid_items_filter">
          <component
            v-for="(item, index) of filters" :key="item.alias"
            v-bind:is="getComponentFilter(item.type)"
            :endpoint="item.endpoint"
            :value="getFilter(item.alias)"
            @input="setFilter(item.alias, $event)"
            :disabled="loading"
            :label="item.label"
            :filter-id="item.alias"
            :class="{ 'one-column-mobile': filters.length === 1 }"
            :id="`${(item.alias.replace('.', '-'))}-filter-${index}`"/>
        </div>
      </form>
    </div>
    <div
      class="align-items-center ml-md-2 mt-2 mt-md-0"
      :class="{ 'd-flex': !filters.length}"
    >
      <!-- <div class="mr-3 color_gray" v-if="!filters.length">Фильтр:</div> -->
      <div class="div button_save_g">
        <modal v-model="dialog" id="filters-setting-modal">
          <filters-setting-form v-model="filters"/>
        </modal>
        <button class="btn_green_g mobile-full-w" @click="handlerOpen">{{ $t('analytics.filters') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from './Modal.vue'
import FiltersSettingForm from './FiltersSettingForm.vue'
import CheckboxFilter from './filters/CheckboxFilter.vue'
import CountryFilter from './filters/CountryFilter.vue'
import TextFilter from './filters/TextFilter.vue'
import SelectFilter from './filters/SelectFilter.vue'
import UrlSelectFilter from './filters/UrlSelectFilter.vue'

export default {
  name: 'Filters',
  components: {
    Modal,
    FiltersSettingForm,
    CheckboxFilter,
    CountryFilter,
    SelectFilter,
    TextFilter,
    UrlSelectFilter
  },
  props: {
    value: Object,
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      dialog: false,
      filters: []
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (n, o) {
        let update = false
        const deleted = []
        const filter = JSON.parse(JSON.stringify(this.filter))
        for (const nFilter of n) {
          if (filter[nFilter.alias] == null) {
            filter[nFilter.alias] = ''
            update = true
          }
        }
        for (const oFilter of o) {
          const index = n.findIndex(nFilter => nFilter.alias === oFilter.alias)
          if (index === -1) {
            deleted.push(oFilter.alias)
          }
        }
        if (deleted.length) {
          for (const alias of deleted) {
            filter[alias] = undefined
            update = true
          }
        }
        if (update && deleted.some(item => !!this.value.filter[item])) {
          this.filter = JSON.parse(JSON.stringify(filter))
          this.$emit('filterChange')
        } else if (update) {
          this.filter = JSON.parse(JSON.stringify(filter))
        }
      }
    },
    availableFilters: function (newFilters) {
      if (this.filter != null && Object.keys(this.filter).length > 0) {
        this.filters = []
        for (const [prop] of Object.entries(this.filter)) {
          const index = newFilters.findIndex(filter => filter.alias === prop)
          if (index !== -1) {
            this.filters.push(newFilters[index])
          }
        }
      }
    }
  },
  computed: {
    filter: {
      get () {
        return this.value.filter
      },
      set (filter) {
        this.$emit('optionsChange', {filter})
      }
    },
    availableFilters () {
      return this.$store.getters['analytics/filters']
    }
  },
  methods: {
    handlerOpen: function () {
      this.dialog = true
    },
    setFilter (prop, value) {
      const filter = {}
      filter[prop] = value
      this.filter = {...this.filter, ...filter}
      this.$emit('filterChange')
    },
    getFilter (alias) {
      try {
        return this.filter[alias]
      } catch (e) {
        return ''
      }
    },
    getComponentFilter (type) {
      return type.substring(0, 1).toUpperCase() + type.substring(1) + 'Filter'
    }
  }
}
</script>

<style lang="scss" scoped>
.analytics-filters {
  ::v-deep {
    .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__search, .vs--disabled .vs__open-indicator{
      background-color: transparent;
    }
  }
}
</style>
