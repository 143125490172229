<template>
  <div>
    <div class="row">
      <owner-filter
        class="col-md-6 col-lg"
        v-model="owner"
        placeholder="Фільтр за користувачем"
      />
      <div class="col-md-6 col-lg mb-2">
        <select class="form-control" v-model="status">
          <option value="" selected>Фильтр по статусу</option>
          <option value="successful">Пройшов</option>
          <option value="error">Помилка</option>
        </select>
      </div>
      <div class="col-md-6 col-lg mb-2">
        <input v-model="host" class="form-control" placeholder="Пошук за ID уніка"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg mb-2">
        <input v-model="domain" class="form-control" placeholder="Пошук за доменом"/>
      </div>
      <datepicker
        id="date_begin"
        v-model="dateBegin"
        container=".main-panel"
        inputClass="form-control"
        class="col-md-6 col-lg mb-2 in_to_pickerd"
        useEndDate
        placeholder="Дата від"
      />
      <datepicker
        id="date_end"
        v-model="dateEnd"
        container=".main-panel"
        inputClass="form-control"
        class="col-md-6 col-lg mb-2 in_to_pickerd"
        useEndDate
        placeholder="Дата до"
      />
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {OwnerFilter} from '@/pages/admin/components'
import Datepicker from '@/components/Datepicker.vue'

export default {
  name: 'PostbackLogsFilter',
  components: {OwnerFilter, Datepicker},
  data () {
    return {
      filter: {}
    }
  },
  computed: {
    host: {
      get () {
        return this.$store.getters['adminPostbacks/logFilter'].host
      },
      set: _.debounce(function (input) {
        this.setterFilter('host', input)
      }, 500)
    },
    domain: {
      get () {
        return this.$store.getters['adminPostbacks/logFilter'].domain
      },
      set: _.debounce(function (input) {
        this.setterFilter('domain', input)
      }, 500)
    },
    owner: {
      get () {
        return this.$store.getters['adminPostbacks/logFilter'].owner
      },
      set: _.debounce(function (input) {
        this.setterFilter('owner', input)
      }, 500)
    },
    status: {
      get () {
        return this.$store.getters['adminPostbacks/logFilter'].status || ''
      },
      set: _.debounce(function (input) {
        this.setterFilter('status', input)
      }, 500)
    },
    dateBegin: {
      get () {
        return this.$store.getters['adminPostbacks/logFilter'].dateBegin
      },
      set: _.debounce(function (input) {
        this.setterFilter('dateBegin', input.toISOString().split('T')[0])
      }, 500)
    },
    dateEnd: {
      get () {
        return this.$store.getters['adminPostbacks/logFilter'].dateEnd
      },
      set: _.debounce(function (input) {
        this.setterFilter('dateEnd', input.toISOString().split('T')[0])
      }, 500)
    }
  },
  methods: {
    setterFilter (filter, input) {
      const payload = this.$store.getters['adminPostbacks/logFilter']
      payload[filter] = input
      this.$store.dispatch('adminPostbacks/setLogFilter', payload)
      this.$emit('change', true)
    }
  }
}
</script>
