<template>
  <div>
    <slot name="before"></slot>
    <input
      type="text"
      :id="id"
      :disabled="disabled"
      :required="required"
      :placeholder="placeholder"
      :class="inputClass">
    <slot name="after"></slot>
  </div>
</template>
<script>
export default {
  name: 'Datepicker',
  data () {
    return {
      datepicker: null
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String,
      default: 'form-control'
    },
    container: {
      type: String,
      required: true
    },
    format: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    value: null,
    useEndDate: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    const datepickerOptions = {
      maxViewMode: 0,
      format: this.format.toLowerCase(),
      container: this.container,
      autoclose: true,
      ignoreReadonly: true,
      allowInputToggle: true
    }
    if (this.useEndDate) {
      datepickerOptions.endDate = '+1d'
      datepickerOptions.datesDisabled = '+1d'
    }
    // eslint-disable-next-line no-undef
    this.datepicker = jQuery(`#${this.id}`).datepicker(datepickerOptions)
    this.datepicker.datepicker('setDate', new Date(this.value))
      .on('changeDate', () => {
        this.$emit('input', this.datepicker.datepicker('getUTCDate'))
        return true
      })
      .on('show', (ev) => {
        this.$emit('focus', ev)
        return true
      })
  },
  beforeDestroy () {
    this.datepicker.datepicker('destroy')
  }
}
</script>
<style>
.datepicker.datepicker-dropdown {
  z-index: 9999 !important;
}
</style>
