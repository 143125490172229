<template>
  <tr class="MirrorsTableItem mirrors-table-item">
    <td>{{ data.name }}</td>
    <td v-if="!trash">
      <BaseStatus
        :type="getStatus(data.status).class"
        :data="$t(getStatus(data.status).label)"
      />
    </td>
    <td>
      <img
        :src="imagePath"
        :alt="data?.pwa?.name"
        class="mirrors-table-item__image"
      >
      {{ data?.pwa?.name || '' }}
    </td>
    <td>{{ data.domain }}</td>
    <td>
      <BaseTags
        v-if="data?.tags?.length"
        class="mirrors-table-item__tags"
        :tags="data?.tags"
        clickable
        @tag-click="$emit('tag-click', $event)"
      />
    </td>
    <td>
      <div class="mirrors-table-item__buttons">
        <div class="item_butt_act_g" v-if="!trash">
          <button
            v-if="isCanPlay"
            @click="onPlayClick"
            class="d_flex align_center justify_center btn_icon_rounded"
            data-toggle="tooltip" :data-original-title="$t('mirrors.buttons.run')"
            type="button">
            <InlineSvg src="/images/svg-icons/play-icon.svg"/>
          </button>
          <button
            v-if="isCanStop"
            @click="onStopClick"
            class="d_flex align_center justify_center btn_icon_rounded"
            data-toggle="tooltip"
            :data-original-title="$t('mirrors.buttons.stop')"
            type="button"
          >
            <InlineSvg src="/images/svg-icons/pause-icon.svg"/>
          </button>
        </div>
        <div
          class="item_butt_act_g"
          v-if="isCanViewStatistics"
        >
          <button
            @click="onStatisticsClick"
            class="d_flex align_center justify_center btn_icon_rounded"
            data-toggle="tooltip" :data-original-title="$t('general.statistics')">
            <InlineSvg src="/images/svg-icons/chart-circle-icon.svg"/>
          </button>
        </div>
        <div class="item_butt_act_g" v-if="isCanViewEdit">
          <button
            @click="onEditClick"
            class="d_flex align_center justify_center btn_icon_rounded"
            data-toggle="tooltip" :data-original-title="$t('general.edit')">
            <InlineSvg src="/images/svg-icons/pen-icon.svg"/>
          </button>
        </div>
        <BaseDropdownToggle v-if="!trash">
          <button
            v-if="isCanAddMeta"
            @click="onAddMetaClick"
            class="item_action_menu"
            type="button"
          >
            <InlineSvg src="/images/svg-icons/meta-icon.svg"/>
            <span>{{ $t('mirrors.buttons.meta.buttonTitle') }}</span>
          </button>
          <button
            v-if="isCanAddJsScript"
            @click="onCustomScriptClick"
            class="item_action_menu"
            type="button"
          >
            <InlineSvg src="/images/svg-icons/js-icon.svg"/>
            <span>{{ $t('mirrors.buttons.customJs.buttonTitle') }}</span>
          </button>
          <button
            v-if="isCanDownloadJsScript"
            @click="onDownloadJsClick"
            class="item_action_menu"
            type="button"
          >
            <InlineSvg src="/images/svg-icons/upload-icon.svg"/>
            <span>{{ $t('mirrors.buttons.cloak.buttonTitle') }}</span>
          </button>
          <button
            class="item_action_menu"
            type="button"
            @click="onDeleteClick"
          >
            <InlineSvg src="/images/svg-icons/trash-icon.svg"/>
            <span>{{ $t('mirrors.trash.move') }}</span>
          </button>
        </BaseDropdownToggle>
        <div class="item_butt_act_g" v-if="trash">
          <button
            @click="onRefreshClick"
            class="d_flex align_center justify_center btn_icon_rounded"
            data-toggle="tooltip" :data-original-title="$t('general.refresh')">
            <InlineSvg class="button-trash-icon" src="/images/svg-icons/refresh-icon.svg"/>
          </button>
        </div>
        <div class="item_butt_act_g" v-if="trash">
          <button
            @click="onDeleteClick"
            class="d_flex align_center justify_center btn_icon_rounded"
            data-toggle="tooltip" :data-original-title="$t('general.delete')">
            <InlineSvg class="button-trash-icon" src="/images/svg-icons/trash-icon-new.svg"/>
          </button>
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
import GreenCheckboxIcon from '@/components/GreenCheckboxIcon.vue'
import InlineSvg from 'vue-inline-svg'
import tooltipMixin from '@/mixins/tooltip-mixin'
import BaseDropdownToggle from '@/components/base/BaseDropdownToggle.vue'
import BaseTags from '@/components/base/BaseTags/BaseTags.vue'
import BaseStatus from '@/components/base/BaseStatus.vue'
import { DOMAIN_STATUSES } from '@/config/statuses'
import { getImagePath } from '@/utils/url'
import {mapActions} from 'vuex'

export default {
  name: 'MirrorsTableItem',
  components: {
    GreenCheckboxIcon,
    InlineSvg,
    BaseDropdownToggle,
    BaseTags,
    BaseStatus
  },
  mixins: [tooltipMixin],
  props: {
    trash: Boolean,
    data: Object,
    user: Object,
    accesses: Object
  },
  data () {
    return {
      item: {
        checked: false
      },
      DOMAIN_STATUSES
    }
  },
  computed: {
    imagePath () {
      return this.getImagePath(this.data?.pwa?.images, this.data?.pwa?.gallery, this.data?.pwa?.vertical)
    },
    isCanAddJsScript () {
      return this.data.status > 2 && this.user?.customJs
    },
    isCanAddMeta () {
      return this.data.status === 4
    },
    isCanDownloadJsScript () {
      return this.data.status > 2
    },
    isCanPlay () {
      return this.data.status === 3 && this.accesses.pwas.update
    },
    isCanStop () {
      return this.data.status === 4 && this.accesses.pwas.update
    },
    isCanViewStatistics () {
      return this.accesses.statistics.view && !this.trash
    },
    isCanViewEdit () {
      return this.accesses.pwas.update && !this.trash
    }
  },
  created () {
    this.initTooltip()
  },
  methods: {
    ...mapActions('mirrorsStore', [
      'setActiveMirror'
    ]),
    getImagePath,
    getStatus (id) {
      return this.DOMAIN_STATUSES.find(item => item.id === id)
    },
    onEditClick () {
      this.setActiveMirror(this.data)
      this.$emit('edit')
    },
    async onStatisticsClick () {
      this.$emit('statistics', this.data)
    },
    onDeleteClick () {
      this.$emit('delete', this.data)
    },
    onRefreshClick () {
      this.$emit('refresh', this.data)
    },
    onPlayClick () {
      this.$emit('play', this.data)
    },
    onStopClick () {
      this.$emit('stop', this.data)
    },
    onCustomScriptClick () {
      this.$emit('add-custom-script', this.data)
    },
    onAddMetaClick () {
      this.setActiveMirror(this.data)
      this.$emit('add-meta', this.data)
    },
    onDownloadJsClick () {
      this.$emit('download-js', this.data)
    }
  }
}
</script>
<style scoped lang="sass">
.mirrors-table-item
  height: 56px
  &__buttons
    display: flex
    gap: 3px
    justify-content: flex-end
  &__tags
    width: 100%
  &__image
    margin-right: 10px
.button-trash-icon
  stroke: #272B2F
</style>
