import AdminBotLayout from './layout.vue'
import AdminBotKeys from './keys.vue'
import AdminBotLocales from './locales.vue'

export default (meta, header) => {
  return [
    {
      path: 'bot',
      name: 'AdminBotLayout',
      components: {default: AdminBotLayout, header},
      meta,
      redirect: '/',
      children: [
        {
          path: 'keys',
          name: 'AdminBotKeys',
          components: {default: AdminBotKeys, header},
          meta
        },
        {
          path: 'locales',
          name: 'AdminBotLocales',
          components: {default: AdminBotLocales, header},
          meta
        }
      ]
    }
  ]
}
