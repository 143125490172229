<template>
  <div class="head_action_stats_g d_flex align_center justify_between">
    <div>
      <div class="filters_hed_top">
        <form class="forms-sample">
          <div class="d_flex align_center in_to_djsbgdjg">
            <filters-tags v-model="tags"/>
            <filters-countries v-model="country" ajax-url="/statistics/groups/countries"/>
            <filters-pwas :pwas="groups.pwas" v-model="pwa"/>
          </div>
        </form>
      </div>
    </div>
    <div class="d_flex align_center right_act_sjg_g">
      <div class="item_picker_g">
        <filters-date name="date_begin" v-model="dateBegin"/>
      </div>
      <div class="item_picker_g">
        <filters-date name="date_end" v-model="dateEnd"/>
      </div>
      <div class="div button_save_g">
        <button class="btn_green_g" @click="handler">Скинуть</button>
      </div>
    </div>
  </div>
</template>
<script>
import Countries from './Countries.vue'
import Tags from './Tags.vue'
import PWAs from './PWAs.vue'
import * as components from '@/components'

export default {
  name: 'Groups',
  props: ['value', 'groups'],
  components: {
    'filters-tags': Tags,
    'filters-countries': Countries,
    'filters-pwas': PWAs,
    'filters-date': components.filters.Date
  },
  data: () => ({
    country: '',
    pwa: '',
    tracker: '',
    dateBegin: '',
    dateEnd: ''
  }),
  created: function () {
    for (const [prop, value] of Object.entries(this.value)) {
      if (this[prop] != null) {
        this[prop] = value
        this.$watch(prop, function () {
          this.$emit('input', {
            country: this.country,
            pwa: this.pwa,
            tracker: this.tracker,
            dateBegin: this.dateBegin,
            dateEnd: this.dateEnd
          })
        })
      }
    }
  },
  methods: {

  }
}
</script>
