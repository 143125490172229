export default {
  openTicket: 'Открыть тикет',
  fillForm: 'Заполните форму',
  theme: 'Тема',
  themeInfo: 'Введите тему обращения',
  department: 'Отдел',
  departmentInfo: 'Выберите одно из направлений проблемы',
  departmentList: ['PWA', 'Биллинг', 'Статистика', 'Трекер', 'Реферальная программа', 'Ошибка'],
  message: 'Сообщение',
  messageInfo: 'Введите Ваше сообщение, которое раскрывает суть проблемы',
  manager: 'Менеджер',
  status: 'Статус',
  ticketOpened: 'Тикет открыт, ожидайте ответа',
  waiting: 'Ожидаем ответа',
  ticketCorrespondence: 'Переписка по тикету #{ticketID}',
  success: 'Сообщения отправлено, ожидайте ответа',
  statusList: ['Новый', 'Назначен менеджер', 'Дан ответ', 'Новый вопрос', 'Закрытый', 'Не решенный', 'Отклонен']
}
