<template>
  <div>
    <label class="mb-2 label-style">{{ $t("pushes.actions.interval.title") }}</label>
    <div class="row">
      <div
        :class="apiValidationErrors.interval ? 'has-danger' : ''"
        class="form-group col">
        <input
          :value="count"
          :class="apiValidationErrors.interval ? 'form-control-danger' : ''"
          :placeholder="countPlaceholder"
          :min="minCount"
          @focus="clearError('interval')"
          @input="handlerInput"
          type="number"
          name="count"
          id="count"
          class="form-control w-100">
        <label v-if="apiValidationErrors.interval" class="error mt-2 text-danger" for="count">
          {{ apiValidationErrors.interval[0] }}
        </label>
      </div>
      <div class="form-group col">
        <select
          :value="type"
          name="type"
          @input="handlerInput"
          class="form-control w-100">
          <option value="minutes">{{ $tc("pushes.actions.interval.type.minutes", count) }}</option>
          <option value="hours">{{ $tc("pushes.actions.interval.type.hours", count) }}</option>
          <option value="days">{{ $tc("pushes.actions.interval.type.days", count) }}</option>
          <option value="months">{{ $tc("pushes.actions.interval.type.months", count) }}</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'

const defaultValues = () => ({
  count: 1,
  type: 'hours'
})

export default {
  name: 'ActionInterval',
  mixins: [formMixin],
  props: {
    value: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ...defaultValues()
    }
  },
  computed: {
    countPlaceholder () {
      return this.$tc(`pushes.actions.interval.type.${this.type}`, this.count)
    },
    minCount () {
      return this.type === 'minutes' ? 10 : 1
    }
  },
  watch: {
    errors: {
      handler (n) {
        if (Object.keys(n).length > 0) {
          this.apiValidationErrors = n
        }
      },
      deep: true
    }
  },
  created () {
    if (this.value) {
      const [count, type] = this.value.split(' ')
      this.count = count
      this.type = type
    } else {
      const {count, type} = defaultValues()
      this.count = count
      this.type = type
    }
  },
  methods: {
    handlerInput ($event) {
      const {name, value} = $event.target
      this[name] = value
      this.resetApiValidation()
      if (this.count != null && this.count !== '') {
        this.$emit('input', `${this.count} ${this.type}`)
      } else {
        this.setApiValidation([{param: 'interval', msg: this.$t('general.notEmpty')}])
      }
    }
  }
}
</script>
