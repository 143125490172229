<template>
  <div id="content-pwa" class="tab-pane fade in active" v-if="accesses.statistics.view">
    <div class="head_action_stats_g d_flex align_center justify_between" v-if="!loading">
      <div class="filters_hed_top">
        <form class="forms-sample">
          <div class="d_flex align_center in_to_djsbgdjg">
            <filters-tags v-model="filters.groups.tags"/>
            <filters-countries v-model="filters.groups.country"/>
            <filters-pwas v-model="filters.groups.pwa"/>
          </div>
        </form>
      </div>
      <div class="d_flex align_center right_act_sjg_g">
        <div class="item_picker_g">
          <filters-date name="date_begin" v-model="filters.groups.dateBegin"/>
        </div>
        <div class="item_picker_g">
          <filters-date name="date_end" v-model="filters.groups.dateEnd"/>
        </div>
        <div class="div button_save_g">
          <button class="btn_green_g" @click="clear">Скинуть</button>
        </div>
      </div>
    </div>
    <filters-pepper v-model="filters.pepper" :groups="{...pepper}" v-if="!loading && user.id === 1516381314"/>
    <filters-welcomep v-model="filters.welcomep" :groups="{...welcomep}" v-if="!loading && user.id === 731778160"/>
    <div class="row">
      <div class="col-sm-12">
        <div class="loading_cont_go" v-if="loading">
          <div class="in_to_load_sf d_flex justify_center">
            <img src="/images/loader.svg" alt="" width="100">
          </div>
        </div>
        <div class="table-responsive" v-if="!loading">
          <table id="pwa-statistics-listing" class="table table_border_style">
            <table-sort-head :items="heads" v-model="sort"/>
            <tbody>
            <tr role="row" class="odd" v-for="item in data" :key="item.date">
              <td>{{ item.date | date }}</td>
              <td class="text-center">{{ item.uniques }}</td>
              <td class="text-center">{{ item.hits }}</td>
              <td class="text-center">{{ item.installs }}</td>
              <td class="text-center">{{ item.pushes }}</td>
              <td class="text-center">{{ item.registrations }}</td>
              <td class="text-center">{{ item.deposits }}</td>
              <td class="text-center">{{ uniquesToInstalls(item.uniques, item.installs) | percent }}</td>
              <td class="text-center">{{ uniquesToPush(item.uniques, item.pushes) | percent }}</td>
              <td class="text-center">{{ installsToPush(item.installs, item.pushes) | percent }}</td>
              <td class="text-right pr-0">{{ item.opens }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tooltipMixin from '@/mixins/tooltip-mixin'
import statisticsMixin from '@/mixins/statistics-mixin'
import titleMixin from '@/mixins/title-mixin'
import * as mixins from '../mixins'
import accessesMixin from '@/mixins/accesses-mixin'

export default {
  name: 'Dates',
  title: 'Статистика по дням',
  mixins: [tooltipMixin, statisticsMixin, titleMixin, mixins.groups, accessesMixin],
  data: () => {
    return {
      loading: true,
      heads: [
        {label: 'Дата', title: 'Дата статистики', sort: 'date'},
        {label: 'Уники', title: 'Уникальные переходы', sort: 'uniques'},
        {label: 'Хосты', title: 'Неуникальные переходы', sort: 'hits'},
        {label: 'Установки', title: 'Количество установок PWA', sort: 'installs'},
        {label: 'Пуш подписки', title: 'Количество подписок на Push', sort: 'pushes'},
        {label: 'Реги', title: 'Количество регистраций', sort: 'registrations'},
        {label: 'Депы', title: 'Количество депозитов', sort: 'deposits'},
        {
          label: 'CR% уник/инстал',
          title: 'Процентное соотношение уник/инстал. Рассчитывается по формуле CR = Инстал/Уник*100'
        },
        {label: 'CR% уник/пуш', title: 'Процентное соотношение уник/пуш. Рассчитывается по формуле CR = Пуш/Уник*100'},
        {
          label: 'CR% инстал/пуш',
          title: 'Процентное соотношение инстал/пуш. Рассчитывается по формуле CR = Пуш/Инстал*100'
        },
        {label: 'Входы в PWA', title: 'Количество входов в PWA', sort: 'opens', class: 'text-right last_tbs_gg pr-0'}
      ]
    }
  },
  created: function () {
    if (this.$route.params.filterPWA) {
      this.filters.groups.pwa = this.$route.params.filterPWA
    }
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = ['tracker', 'pwa']
    for (const param of params) {
      if (urlSearchParams.has(param)) {
        this.filters.groups[param] = urlSearchParams.get(param)
      }
    }
  },
  methods: {
    asyncData: async function () {
      try {
        await this.$store.dispatch('statistics/dates', {
          ...this.filter,
          sort: this.sort
        })
        const list = this.$store.getters['statistics/dates']
        this.data = list.data
        this.pepper = list.pepper
        this.welcomep = list.welcomep
        if (this.loading === false) {
          await this.$store.dispatch('alerts/success', 'Статистика обновлена')
        } else {
          this.loading = false
        }
      } catch (e) {
        await this.$store.dispatch('alerts/error', e.response.data)
      }
    }
  }
}
</script>
