import System from '@/pages/admin/system/Queues.vue'
import MigrationIPs from '@/pages/admin/system/MigrationIPs.vue'

export default (meta, header) => {
  return [
    {
      path: 'system/queues',
      name: 'AdminSystem',
      components: {default: System, header},
      meta
    },
    {
      path: 'system/migrationIPs',
      name: 'AdminMigrationIPs',
      components: {default: MigrationIPs, header},
      meta
    }
  ]
}
