<script>
import {mapActions, mapGetters} from 'vuex'
import formMixin from '@/pages/landing-pages/form.mixin'

export default {
  mixins: [formMixin],
  title: function () {
    return this.$t('landingPages.editLandingTitle')
  },
  computed: {
    ...mapGetters({
      model: 'landingPages/model'
    }),
    type () {
      return this.$store.getters['landingPages/types'].filter(type => type.id === this.formData.type)[0]
    },
    theme () {
      return this.$store.getters['landingPages/themes'].filter(theme => theme.id === this.formData.theme)[0]
    }
  },
  async created () {
    this.processing = true
    await this.init()
    await this.view(this.$route.params.id)
    for (const [prop, value] of Object.entries(this.model)) {
      this.formData[prop] = value
    }
    await this.updatePreview(this.formData)
    this.$nextTick(async () => { this.processing = this.loading = false })
  },
  methods: {
    ...mapActions({
      view: 'landingPages/view',
      update: 'landingPages/update',
      alertError: 'alerts/error'
    }),
    async handleSubmit () {
      try {
        this.resetApiValidation()
        const {id} = this.formData
        await this.update({
          id,
          payload: {
            ...this.formData,
            type: this.type,
            theme: this.theme
          }
        })
        await this.$router.push({name: 'LandingPages'})
        this.resetApiValidation()
      } catch (e) {
        await this.alertError(this.$t(e.response.data.errors[0].msg))
        this.setApiValidation(e.response.data.errors)
      }
    }
  }
}
</script>

<template>
  <Loading class="card-body pt-0" v-model="loading" v-if="accesses.landings.update">
    <div class="style_descr_g">
      <h4 class="title_sf_site mt-4">Редагування лендінгу</h4>
    </div>
    <div class="blk_tabs_geg style_form_lab">
      <LandingForm
        v-model="formData"
        :errors="apiValidationErrors"
        @submit="handleSubmit"
      />
    </div>
  </Loading>
</template>
