import api from '../api'

export default {
  index: () => api.get('admin/payment-methods'),
  list: () => api.get('admin/payment-methods/list'),
  sort: payload => api.patch(`admin/payment-methods`, payload),
  create: payload => api.post('admin/payment-methods', payload),
  update: ({_id, ...payload}) => api.patch(`admin/payment-methods/${_id}`, payload),
  delete: id => api.delete(`admin/payment-methods/${id}`)
}
