<template>
<!--  <div class="d_inline_block item_butt_acat_g">-->
  <div class="mr-md-4 mb-4 mb-md-0 one-column-mobile">
    <modal ref="modal" id="pwas-templates-app-template" class="fade modal_center_g pwas-template-modal"
           classContent="modal-content" classDialog="modal-dialog">
      <form v-if="!loading" class="style_form_lab" @submit.prevent="handlerSave">
        <button type="button" class="close_sjgs_to d_flex align_center" @click="handlerCancel"><span aria-hidden="true">&times;</span>
        </button>
        <div class="modal_title_g">{{$t('templates.apps.title')}}</div>
        <BaseFromGroup
          id="pwas-templates-app-template-template"
          :label="$t('templates.apps.your')"
          :has-error="apiValidationErrors.template"
          :error-text="apiValidationErrors?.template ? apiValidationErrors?.template[0] : ''"
        >
          <BaseSelect
            v-model="template"
            id="clLang"
            sort="-createdAt"
            endpoint="/pwas/templates/filter"
            :placeholder="$t('templates.filters.name')"
          />
        </BaseFromGroup>
        <div class="modal_foot_this translator_foot">
          <div class="in_topshgdjf_g d_flex align_center">
            <div class="item_avt_butt_jg">
              <button type="submit" class="ful_butt_green d_flex align_center justify_center">
                {{ $t('general.apply') }}
              </button>
            </div>
            <div class="item_avt_butt_jg">
              <a href="javascript:void(0);" type="button" class="border_butt_green d_flex align_center justify_center"
                 @click="handlerCancel">
                {{ $t('general.cancel') }}
              </a>
            </div>
          </div>
        </div>
      </form>
    </modal>
    <a class="simple_butt_afg mobile-full-w" href="javascript:void(0);"
       @click="handlerOpen">{{ $t('templates.title') }}</a>
  </div>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import templateMixins from './template-mixins'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  name: 'PwasTemplatesVerticalTemplate',
  mixins: [formMixin, templateMixins, select2AjaxMixin],
  components: {BaseSelect, BaseFromGroup, Select2, Modal},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      models: [],
      template: '',
      loading: true,
      templateSettings: {}
    }
  },
  watch: {
    source: function (n) {
      this.initTemplateSettings(n)
    }
  },
  methods: {
    initTemplateSettings () {
      this.templateSettings = {
        ...this.getSelect2AjaxSettings(`pwas/templates/filter`, '-createdAt'),
        allowClear: true
      }
    },
    async handlerOpen () {
      this.initTemplateSettings()
      this.$nextTick(() => {
        this.loading = false
      })
      this.resetApiValidation()
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerSave () {
      if (this.template.length > 0) {
        this.resetApiValidation()
        await this.$store.dispatch('pwasTemplates/view', this.template)
        const template = this.$store.getters['pwasTemplates/model']
        this.setMedia(template, this.value, this.$parent.changed)
        this.$emit('input', this.value)
        await this.$store.dispatch('pwas/updateVertical', {...this.value, pwaId: this.$route.params.id})
          .then(() => {
            setTimeout(() => {
              this.$parent.pwaCreated = false
              this.replaceMedia(template, this.value)
              this.$nextTick(() => {
                this.$parent.pwaCreated = true
              })
            }, 1000)
          })
        await this.$parent.sync()
        this.$refs.modal.close()
        this.resolvePromise(true)
      } else {
        this.setApiValidation([{param: 'template', msg: this.$t('templates.apps.notSelected')}])
      }
    },
    handlerCancel () {
      this.template = ''
      this.$refs.modal.close()
      this.resolvePromise(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.pwas-template-modal {
  ::v-deep .vs__selected-options * {
    max-width: 325px;
  }
}

</style>
