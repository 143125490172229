<template>
  <div class="item_silter_sjhf_g">
    <input type="text" class="form-control" :id="id" v-model="filter"
           :placeholder="$t(`analytics.filtersLabel.${id.split('-')[0]}` )" v-on:keydown.enter.prevent :disabled="disabled"/>
  </div>
</template>
<script>
export default {
  name: 'TextFilter',
  props: ['value', 'endpoint', 'label', 'id', 'disabled'],
  computed: {
    filter: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
