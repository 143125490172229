import Installations from './Installations.vue'

export default (meta, header) => {
  return [
    {
      path: 'tariffs/installations',
      name: 'AdminTariffsInstallations',
      components: {default: Installations, header},
      meta
    }
  ]
}
