<template>
  <modal
    ref="modal"
    id="member-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    <div class="item_row_usr_add">
      <div class="clearfix row_halff_g">
        <div class="row">
          <div class="item_half_thss col" :class="apiValidationErrors.username ? 'has-danger' : ''">
            <input type="text" class="form-control" id="username" name="username"
                    :placeholder="$t('teams.name')"
                    v-model="username" @focus="clearError('username')"
                    :class="apiValidationErrors.username ? 'form-control-danger' : ''">
            <label v-if="apiValidationErrors.username" class="error mt-2 text-danger" for="username">
              {{ $t(apiValidationErrors.username[0]) }}
            </label>
          </div>
          <div class="item_half_thss col">
            <select class="form-control" :class="apiValidationErrors.group ? 'has-danger' : ''" id="group" v-model="group" autofocus>
              <option v-bind:value="group" v-for="group of groups" v-bind:key="group">{{ group }}</option>
            </select>
            <label v-if="apiValidationErrors.group" class="error mt-2 text-danger" for="group">
              {{ apiValidationErrors.group[0] }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="selections_data_usr mt-2">
      <div class="title_this_sel_g">{{ $t('teams.privacySettings') }}</div>
      <div class="item_data_sel_us clearfix">
        <div class="row">
          <div class="item_check_usr_flt col">
            <div class="checkbox_ios_style">
              <label class="checkbpx_style_ios">
                <span class="txt_tit_this_g">{{ $t('teams.isPWAsOwner') }}</span>
                <input type="checkbox" v-model="isPWAsOwner">
                <span class="icon_checkb_stye"></span>
              </label>
            </div>
            <div v-if="isPWAsOwner" class="blk_more_optionss">
              <div class="pr-2 pl-2" :class="apiValidationErrors.customPWATag ? 'has-danger' : ''">
                <input type="text" class="form-control" id="customPWATag" name="customPWATag"
                        :placeholder="$t('teams.customPWATag')"
                        v-model="customPWATag" @focus="clearError('customPWATag')"
                        :class="apiValidationErrors.customPWATag ? 'form-control-danger' : ''">
                <label v-if="apiValidationErrors.customPWATag" class="error mt-2 text-danger" for="customPWATag">
                  {{ apiValidationErrors.customPWATag[0] }}
                </label>
              </div>
            </div>
          </div>
          <div class="item_check_usr_flt col">
            <div class="checkbox_ios_style">
              <label class="checkbpx_style_ios">
                <span class="txt_tit_this_g">{{ $t('teams.isCompaniesOwner') }}</span>
                <input type="checkbox" v-model="isCompaniesOwner">
                <span class="icon_checkb_stye"></span>
              </label>
            </div>
            <div v-if="isCompaniesOwner" class="blk_more_optionss">
              <div class="pr-2 pl-2" :class="apiValidationErrors.customPWATag ? 'has-danger' : ''">
                <input type="text" class="form-control" id="customCompaniesTag" name="customCompaniesTag"
                        :placeholder="$t('teams.customCompaniesTag')"
                        v-model="customCompaniesTag" @focus="clearError('customCompaniesTag')"
                        :class="apiValidationErrors.customCompaniesTag ? 'form-control-danger' : ''">
                <label v-if="apiValidationErrors.customCompaniesTag" class="error mt-2 text-danger" for="customCompaniesTag">
                  {{ apiValidationErrors.customCompaniesTag[0] }}
                </label>
              </div>
            </div>
          </div>
          <div class="item_check_usr_flt">
            <div class="checkbox_ios_style">
              <label class="checkbpx_style_ios">
                <span class="txt_tit_this_g">{{ $t('teams.isLandingsOwner') }}</span>
                <input type="checkbox" v-model="isLandingsOwner">
                <span class="icon_checkb_stye"></span>
              </label>
            </div>
            <div v-if="isLandingsOwner" class="blk_more_optionss">
              <div class="pr-2 pl-2" :class="apiValidationErrors.customLandingsTag ? 'has-danger' : ''">
                <input type="text" class="form-control" id="customLandingsTag" name="customLandingsTag"
                       :placeholder="$t('teams.customLandingsTag')"
                       v-model="customLandingsTag" @focus="clearError('customLandingsTag')"
                       :class="apiValidationErrors.customLandingsTag ? 'form-control-danger' : ''">
                <label v-if="apiValidationErrors.customLandingsTag" class="error mt-2 text-danger" for="customLandingsTag">
                  {{ apiValidationErrors.customLandingsTag[0] }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <!--
                        <div class="item_check_usr_flt">
                          <div class="checkbox_ios_style">
                            <label class="checkbpx_style_ios">
                              <span class="txt_tit_this_g">{{ $t('teams.isTrackersOwner') }}</span>
                              <input type="checkbox" v-model="isTrackersOwner">
                              <span class="icon_checkb_stye"></span>
                            </label>
                          </div>
                          <div v-if="isTrackersOwner" class="blk_more_optionss">
                            <div class="pr-2 pl-2" :class="apiValidationErrors.customTrackerTag ? 'has-danger' : ''">
                              <input type="text" class="form-control" id="customTrackerTag" name="customTrackerTag" :placeholder="$t('teams.customTrackerTag')"
                                      v-model="customTrackerTag" @focus="clearError('customTrackerTag')"
                                      :class="apiValidationErrors.customTrackerTag ? 'form-control-danger' : ''">
                              <label v-if="apiValidationErrors.customTrackerTag" class="error mt-2 text-danger" for="customTrackerTag">
                                {{ apiValidationErrors.customTrackerTag[0] }}
                              </label>
                            </div>
                          </div>
                        </div>
                        -->
      </div>
    </div>
    <div class="selections_data_usr mt-2">
      <div class="title_this_sel_g">{{ $t('teams.accessSettings') }}</div>
      <div class="item_data_sel_us clearfix row mt-2">
        <div class="item_check_usr_flt col">
          <div class="checkbox_ios_style">
            <label class="checkbpx_style_ios">
              <span class="txt_tit_this_g">PWA</span>
              <input type="checkbox" v-model="accessPwas">
              <span class="icon_checkb_stye"></span>
            </label>
          </div>
          <div class="blk_more_optionss">
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.pwas.view">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.view') }}</span>
              </label>
            </div>
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.pwas.update">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.edit') }}</span>
              </label>
            </div>
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.pwas.delete">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.delete') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="item_check_usr_flt col">
          <div class="checkbox_ios_style">
            <label class="checkbpx_style_ios">
              <span class="txt_tit_this_g">PUSH</span>
              <input type="checkbox" v-model="accessPushes">
              <span class="icon_checkb_stye"></span>
            </label>
          </div>
          <div class="blk_more_optionss">
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.pushes.view">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.view') }}</span>
              </label>
            </div>
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.pushes.update">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.edit') }}</span>
              </label>
            </div>
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.pushes.delete">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.delete') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="item_check_usr_flt col">
          <div class="checkbox_ios_style">
            <label class="checkbpx_style_ios">
              <span class="txt_tit_this_g">{{ $t('teams.statistics') }}</span>
              <input type="checkbox" v-model="accessStatistics">
              <span class="icon_checkb_stye"></span>
            </label>
          </div>
          <div class="blk_more_optionss">
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.statistics.view">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.view') }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="item_data_sel_us clearfix row mt-2">
        <div class="item_check_usr_flt col">
          <div class="checkbox_ios_style">
            <label class="checkbpx_style_ios">
              <span class="txt_tit_this_g">{{ $t('teams.teams') }}</span>
              <input type="checkbox" v-model="accessTeams">
              <span class="icon_checkb_stye"></span>
            </label>
          </div>
          <div class="blk_more_optionss">
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.teams.view">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.view') }}</span>
              </label>
            </div>
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.teams.update">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.edit') }}</span>
              </label>
            </div>
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.teams.delete">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.delete') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="item_check_usr_flt col">
          <div class="checkbox_ios_style">
            <label class="checkbpx_style_ios">
              <span class="txt_tit_this_g">{{ $t('teams.finance') }}</span>
              <input type="checkbox" v-model="accessFinance">
              <span class="icon_checkb_stye"></span>
            </label>
          </div>
          <div class="blk_more_optionss">
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.finance.view">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.view') }}</span>
              </label>
            </div>
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.finance.update">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.edit') }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="item_check_usr_flt col">
          <div class="checkbox_ios_style">
            <label class="checkbpx_style_ios">
              <span class="txt_tit_this_g">{{ $t('teams.landings') }}</span>
              <input type="checkbox" v-model="accessLandings">
              <span class="icon_checkb_stye"></span>
            </label>
          </div>
          <div class="blk_more_optionss">
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.landings.view">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.view') }}</span>
              </label>
            </div>
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.landings.update">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.edit') }}</span>
              </label>
            </div>
            <div class="item_inline_checkbxx">
              <label class="inline_checkbox_fff">
                <input type="checkbox" v-model="access.landings.delete">
                <span class="icon_checkb_inl"></span>
                <span>{{ $t('teams.delete') }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <h2 class="h5">Privacy Policy</h2>
      <div
        :class="apiValidationErrors['settings.privacyPolicy.notUsePublicPersonInAds'] ? 'has-danger' : ''"
        class="form-group">
        <div class="form-check">
          <label for="member-form-settings-privacyPolicy-notUsePublicPersonInAds" class="form-check-label">
            <input
              type="checkbox"
              class="form-check-input"
              id="member-form-settings-privacyPolicy-notUsePublicPersonInAds"
              name="member-form-settings-privacyPolicy-notUsePublicPersonInAds"
              v-model="settings.privacyPolicy.notUsePublicPersonInAds"
            >
            Not Use Public Person In Ads
            <i class="input-helper"></i>
          </label>
        </div>
      </div>
    </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'

const defaultAccess = {
  pwas: {
    view: false,
    update: false,
    delete: false
  },
  // trackers: {
  //   view: false,
  //   update: false,
  //   delete: false
  // },
  statistics: {
    view: false
  },
  teams: {
    view: false,
    update: false,
    delete: false
  },
  finance: {
    view: false,
    update: false
  },
  pushes: {
    view: false,
    update: false,
    delete: false
  },
  landings: {
    view: false,
    update: false,
    delete: false
  }
}

const defaultValues = () => ({
  username: '',
  group: '',
  isPWAsOwner: false,
  customPWATag: '',
  isCompaniesOwner: false,
  customCompaniesTag: '',
  // isTrackersOwner: false,
  // customTrackerTag: '',
  isLandingsOwner: false,
  customLandingsTag: '',
  id: false,
  accessPwas: false,
  accessPushes: false,
  // accessTrackers: false,
  accessStatistics: false,
  accessTeams: false,
  accessFinance: false,
  access: defaultAccess,
  settings: {
    privacyPolicy: {
      notUsePublicPersonInAds: false
    }
  }
})

export default {
  name: 'MemberForm',
  mixins: [formMixin],
  components: {SubmitModalForm, Modal},
  props: ['groups'],
  data () {
    return {
      allUpdate: true,
      ...defaultValues(),
      accessLandings: false,
      processing: false,
      isCreate: true,
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    title () {
      return this.isCreate ? 'Добавление мембера' : `Редактирование ${this.username}`
    }
  },
  methods: {
    async handlerCreate () {
      this.skip()
      this.isCreate = true
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerUpdate ({_id,
      username,
      password,
      group,
      customTrackerTag,
      isLandingsOwner,
      customLandingsTag,
      isPWAsOwner,
      isCompaniesOwner,
      customPWATag,
      customCompaniesTag,
      access,
      settings
    }) {
      this.skip()
      this.isCreate = false
      this.id = _id
      this.username = username
      this.password = password
      this.group = group
      this.customTrackerTag = customTrackerTag
      this.isPWAsOwner = isPWAsOwner
      this.isCompaniesOwner = isCompaniesOwner
      this.customPWATag = customPWATag
      this.customCompaniesTag = customCompaniesTag
      this.isLandingsOwner = isLandingsOwner
      this.customLandingsTag = customLandingsTag
      this.access = access
      this.settings = {
        privacyPolicy: {
          notUsePublicPersonInAds: false
        }
      }
      if (settings != null && settings.privacyPolicy != null) {
        this.settings.privacyPolicy = settings.privacyPolicy
      }
      const self = this
      const accesses = ['pwas', 'statistics', 'teams', 'finance', 'pushes']
      for (const access of accesses) {
        const accessFilter = 'access' + access.charAt(0).toUpperCase() + access.slice(1)
        self[accessFilter] = this.isCheckedAll(access)
        this.$watch(accessFilter, function (n) {
          this.updateAllCheck(access, n)
        })
        for (const k of Object.keys(self.access[access])) {
          this.$watch('access.' + access + '.' + k, function () {
            self.allUpdate = false
            self[accessFilter] = this.isCheckedAll(access)
            setTimeout(() => {
              self.allUpdate = true
            }, 500)
          })
        }
      }

      this.$refs.modal.open()
      if (this.owner != null) {
        await this.$store.dispatch('adminUsers/read', this.owner)
        this.users.push(this.$store.getters['adminUsers/user'])
      }
      setTimeout(() => {
        self.initSelect2()
      }, 500)
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.$store.dispatch(this.isCreate ? 'adminMembers/create' : 'adminMembers/update', {
          _id: this.id,
          username: this.username,
          password: this.password,
          group: this.group,
          customTrackerTag: this.customTrackerTag,
          isPWAsOwner: this.isPWAsOwner,
          isCompaniesOwner: this.isCompaniesOwner,
          customPWATag: this.customPWATag,
          customCompaniesTag: this.customCompaniesTag,
          isLandingsOwner: this.isLandingsOwner,
          customLandingsTag: this.customLandingsTag,
          access: this.access,
          settings: this.settings
        })
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    destroySelect2: function () {
      // eslint-disable-next-line no-undef
      if (jQuery('#group').hasClass('select2-hidden-accessible')) {
        // eslint-disable-next-line no-undef
        jQuery('#group').select2('destroy')
      }
    },
    initSelect2: function () {
      const self = this
      const select2Options = {
        placeholder: self.$t('teams.groups'),
        tags: true,
        escapeMarkup: function (m) {
          return m
        }
      }
      // eslint-disable-next-line no-undef
      jQuery('#group').select2(select2Options).on('select2:select', e => {
        self.group = e.params.data.id
      })
    },
    isCheckedAll: function (prop) {
      let test = true
      for (const value of Object.values(this.access[prop]).sort().reverse()) {
        if (value === false) {
          test = false
        }
      }
      return test
    },
    updateAllCheck: function (prop, value) {
      if (this.allUpdate) {
        for (const access of Object.keys(this.access[prop])) {
          this.access[prop][access] = value
        }
      }
    }
  }
}
</script>
