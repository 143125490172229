export default {
  data () {
    return {
      changedData: false
    }
  },
  beforeDestroy () {
    this.removeListener()
  },
  watch: {
    changedData: {
      handler: 'watcher',
      immediate: true
    }
  },
  methods: {
    watcher () {
      if (this.changedData) {
        this.setListener()
        if (this.changedData) this.$store.dispatch('autoSave/beenChanges', true)
      } else {
        this.removeListener()
        if (!this.changedData) this.$store.dispatch('autoSave/beenChanges', false)
      }
    },
    setListener () {
      this.removeListener()
      window.addEventListener('beforeunload', this.handler)
    },
    removeListener () {
      window.removeEventListener('beforeunload', this.handler)
    },
    handler (e) {
      e.preventDefault()
      e.returnValue = ''
    }
  }
}
