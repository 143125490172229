export default {
  actions: {
    nothing: 'Выбрать действие'
  },
  remove: {
    action: 'Удалить',
    title: 'Удаление выделенных',
    message: 'Вы действительно хотите удалить выделенные?',
    okButton: 'Удалить'
  },
  copyPwa: {
    action: 'Копировать',
    title: 'Копировать выделенные',
    message: `Скопировать можно только <span class="text-success">оплаченные</span>. Вы действительно хотите копировать выделенные?`,
    okButton: 'Копировать',
    tracker: {
      message: 'Копировать c трекером или без?',
      okButton: 'C трекером',
      cancelButton: 'Без трекера'
    }
  },
  copy: {
    action: 'Копировать',
    title: 'Копировать выделенные',
    message: `Вы действительно хотите копировать выделенные?`,
    okButton: 'Копировать'
  },
  restore: {
    action: 'Восстановить',
    title: 'Восстановить выделенные',
    message: 'Вы действительно хотите восстановить выделенные?',
    okButton: 'Восстановить'
  },
  trash: {
    action: 'В корзину',
    title: 'Переместить выделенные в корзину',
    message: 'Вы действительно хотите переместить в корзину выделенные PWA? На восстановление у вас будет 2 дня',
    messageRelation: 'Вы действительно хотите переместить в корзину выделенные PWA? На восстановление у вас будет 2 дня. Эти PWA также будут удалены из Топ игр!',
    okButton: 'В корзину'
  },
  play: {
    action: 'Запустить',
    title: 'Запустить выделенные',
    message: 'Вы уверены что хотите запустить выделенные PWA?',
    okButton: 'Запустить'
  },
  stop: {
    action: 'Остановить',
    title: 'Остановить выделенные',
    message: 'Вы уверены что хотите остановить выделенные PWA?',
    okButton: 'Остановить'
  },
  meta: {
    action: 'Пользовательский META tags',
    title: 'Вставьте свои META tags',
    message: 'Эти META tags будут добавлены на ваших PWA',
    okButton: 'Сохранить'
  },
  customJs: {
    action: 'Пользовательский JS',
    title: 'Вставьте свой JS скрипт',
    message: 'Этот JS код будет добавлен в ваших PWA',
    okButton: 'Сохранить'
  },
  pagination: 'Массовые действия'
}
