<template>
  <modal
    ref="modal"
    id="text-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group" :class="apiValidationErrors.alias ? 'has-danger' : ''">
        <label for="percent">Alias</label>
        <div class="input-group">
          <input type="text" id="alias" placeholder="wheels" v-model="alias"
                 class="form-control"
                 :class="apiValidationErrors.alias ? 'form-control-danger' : ''"
                 @focus="clearError('alias')">
        </div>
        <small class="form-text text-muted">Укажите алиас темы</small>
        <label v-if="apiValidationErrors.alias" class="error mt-2 text-danger" for="percent">
          {{ apiValidationErrors.alias[0] }}
        </label>
      </div>
      <label for="percent">Название</label>
      <div class="form-group" :class="apiValidationErrors['name.en'] ? 'has-danger' : ''">
        <div class="input-group">
          <input type="text" id="name.en" placeholder="Wheels" v-model="en"
                 class="form-control"
                 :class="apiValidationErrors['name.en'] ? 'form-control-danger' : ''"
                 @focus="clearError('name.en')">
        </div>
        <small class="form-text text-muted">Укажите название темы для EN</small>
        <label v-if="apiValidationErrors['name.en']" class="error mt-2 text-danger" for="percent">
          {{ apiValidationErrors['name.en'][0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors['name.ru'] ? 'has-danger' : ''">
        <div class="input-group">
          <input type="text" id="name.ru" placeholder="Колесо" v-model="ru"
                 class="form-control"
                 :class="apiValidationErrors['name.ru'] ? 'form-control-danger' : ''"
                 @focus="clearError('name.ru')">
        </div>
        <small class="form-text text-muted">Укажите название темы для RU</small>
        <label v-if="apiValidationErrors['name.ru']" class="error mt-2 text-danger" for="percent">
          {{ apiValidationErrors['name.ru'][0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors['name.pt'] ? 'has-danger' : ''">
        <div class="input-group">
          <input type="text" id="name.pt" placeholder="Rodas" v-model="pt"
                 class="form-control"
                 :class="apiValidationErrors['name.pt'] ? 'form-control-danger' : ''"
                 @focus="clearError('name.pt')">
        </div>
        <small class="form-text text-muted">Укажите название темы для PT</small>
        <label v-if="apiValidationErrors['name.pt']" class="error mt-2 text-danger" for="percent">
          {{ apiValidationErrors['name.pt'][0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors['name.uk'] ? 'has-danger' : ''">
        <div class="input-group">
          <input type="text" id="name.uk  " placeholder="Колесо" v-model="uk"
                 class="form-control"
                 :class="apiValidationErrors.name ? 'form-control-danger' : ''"
                 @focus="clearError('name.uk')">
        </div>
        <small class="form-text text-muted">Укажите название темы для UK</small>
        <label v-if="apiValidationErrors['name.uk']" class="error mt-2 text-danger" for="percent">
          {{ apiValidationErrors['name.uk'][0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.preview ? 'has-danger' : ''">
        <label for="percent">Превью</label>
        <div class="input-group">
          <input type="text" id="preview" placeholder="/wheels/dogsHouse.webp" v-model="preview"
                 class="form-control"
                 :class="apiValidationErrors.preview ? 'form-control-danger' : ''"
                 @focus="clearError('preview')">
        </div>
        <small class="form-text text-muted">Укажите превью темы</small>
        <label v-if="apiValidationErrors.preview" class="error mt-2 text-danger" for="percent">
          {{ apiValidationErrors.preview[0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.type ? 'has-danger' : ''">
        <label for="type">Тип темы</label>
        <select id="type" v-model="type"
                class="form-control"
                :class="apiValidationErrors.type ? 'form-control-danger' : ''"
                @focus="clearError('type')">
          <option v-for="type in types" :value="type.id" :key="type.id">{{ type.name }}</option>
        </select>
        <small class="form-text text-muted">Укажите тип темы</small>
        <label v-if="apiValidationErrors.type" class="error mt-2 text-danger" for="type">
          {{ apiValidationErrors.type[0] }}
        </label>
      </div>
    </div>

    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import {mapActions, mapGetters} from 'vuex'

const defaultValues = () => ({
  id: '',
  alias: '',
  uk: '',
  en: '',
  pt: '',
  ru: '',
  preview: '',
  type: ''
})

export default {
  name: 'themesForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      isCreate: true,
      processing: false,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    ...mapGetters({theme: 'landingPages/theme', types: 'landingPages/types'}),
    title () {
      return this.isCreate ? 'Создание темы' : 'Редактирование темы'
    }
  },
  methods: {
    ...mapActions({
      create: 'landingPages/createThemes',
      update: 'landingPages/updateThemes',
      getTheme: 'landingPages/fetchTheme'
    }),
    handlerCreate () {
      this.skip()
      this.isCreate = true
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerUpdate ({id, type}) {
      this.skip()
      this.isCreate = false
      await this.getTheme(id)
      this.id = id
      this.alias = this.theme.alias
      this.uk = this.theme.name.uk
      this.ru = this.theme.name.ru
      this.en = this.theme.name.en
      this.pt = this.theme.name.pt
      this.preview = this.theme.preview
      this.type = type
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        this.isCreate ? await this.create({
          name: {
            uk: this.uk,
            en: this.en,
            pt: this.pt,
            ru: this.ru
          },
          alias: this.alias,
          type: this.type,
          preview: this.preview
        }) : await this.update({
          id: this.id,
          name: {
            uk: this.uk,
            en: this.en,
            pt: this.pt,
            ru: this.ru
          },
          alias: this.alias,
          type: this.type,
          preview: this.preview
        })
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
