import service from '@/store/services/admin/config-service'
import middleware from '../middleware'

const state = () => ({
  billing: {
    expired: {
      wait: 0,
      pay: 0
    },
    rates: {
      uah: {
        value: 0,
        url: null,
        auto: false,
        timer: 0
      },
      rub: {
        value: 0,
        url: null,
        auto: false,
        timer: 0
      },
      usd: {
        value: 0,
        url: null,
        auto: false,
        timer: 0
      },
      btc: {
        value: 0,
        url: null,
        auto: false,
        timer: 0
      }
    },
    activationCost: 0,
    domainCost: 0,
    minInstalledBalance: 0,
    minInstalledAmount: 0,
    royalties: 0,
    amounts: [],
    trcWallet: ''
  },
  pwas: {
    domain_checker_count: 0,
    domain_checker_limit: 0
  },
  tariffs: {
    newUserTariffType: 'subscribers'
  },
  sdk: {
    url: '',
    secret: ''
  },
  monit: {}
})

const mutations = {
  setData: (state, payload) => {
    state.billing = payload.billing
    state.pwas = payload.pwas
    state.tariffs = payload.tariffs
  },
  socket_SDKConfig (state, payload) {
    state.sdk = payload
  },
  setMonit (state, payload) {
    state.monit = payload
  }
}

const actions = {
  async fetchData ({commit, dispatch}, params) {
    try {
      const data = await service.index(params)
      commit('setData', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async update ({commit, dispatch}, payload) {
    try {
      await service.update(payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async fetchMonit ({commit}) {
    try {
      const data = await service.monit()
      commit('setMonit', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  billing: state => state.billing,
  tariffs: state => state.tariffs,
  pwas: state => state.pwas,
  sdk: state => state.sdk,
  monit: state => state.monit
}

const adminConfig = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminConfig
