<template>
  <div>
    <div class="card grid-margin">
      <div class="card-body">
        <h4 class="card-title">Суммы пополнения</h4>
        <p class="card-description">Суммы пополнения, дробные числа в $</p>
        <form class="form-inline repeater" @submit.prevent="handlerSubmit">
          <div>
            <div class="row d-flex mb-2" v-for="(amount, index) in amounts"
                 :key="index">
              <div class="input-group mb-2 mr-sm-2">
                <input type="number" class="form-control" placeholder="10" v-model="amounts[index]"
                       name="amounts[]" :id="`amount-${index}`" min="1" max="1000" step="0.01"
                       :class="apiValidationErrors[`amounts[${index}]`] ? 'form-control-danger' : ''"
                       @focus="clearError(`amounts[${index}]`)">
                <div class="input-group-append">
                  <button class="input-group-text bg-danger icon text-white"
                          type="button" v-on:click="handlerDelete(index)">
                    <i class="icon-close"></i>
                  </button>
                </div>
              </div>
              <label v-if="apiValidationErrors[`amounts[${index}]`]" class="error mt-2 text-danger"
                     :for="`amount-${index}`">
                {{ apiValidationErrors[`amounts[${index}]`][0] }}
              </label>
            </div>
          </div>
          <button v-on:click="handlerAdd" type="button" class="btn btn-info btn-sm icon-btn ml-2 mb-2">
            <i class="icon-plus"></i>
          </button>
          <button type="submit" class="btn btn-success mr-2">Сохранить</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'AmountsForm',
  mixins: [formMixin],
  data () {
    return {
      amounts: []
    }
  },
  created () {
    this.amounts = this.billing.amounts
    if (!this.amounts.length) {
      this.handlerAdd()
    }
  },
  computed: {
    ...mapGetters({
      billing: 'adminConfig/billing'
    })
  },
  methods: {
    ...mapActions({
      update: 'adminConfig/update',
      alertSuccess: 'alerts/success'
    }),
    async handlerSubmit () {
      try {
        this.resetApiValidation()
        await this.update({
          billing: {
            amounts: this.amounts
          }
        })
        this.resetApiValidation()
        await this.alertSuccess('Суммы пополнения обновлены')
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    handlerAdd: function () {
      this.amounts.push(0)
    },
    handlerDelete: function (index) {
      if (confirm('Для окнчательного удаления не забывайте сохранять. Удалить сумму?')) {
        this.amounts.splice(index, 1)
      }
    }
  }
}
</script>
