import PWAsPush from './_form.vue'

export default (meta, header) => {
  return [
    {
      path: 'pwas/pushes/create',
      name: 'PWAsPushCreate',
      components: {default: PWAsPush, header: header},
      meta: meta
    },
    {
      path: 'pwas/pushes/:id',
      name: 'PWAsPushUpdate',
      components: {default: PWAsPush, header: header},
      meta: meta
    }
  ]
}
