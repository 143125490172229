export default {
  menu: {
    keys: 'Ключі',
    offers: 'Офери'
  },
  heads: {
    key: {label: 'Ключ', title: 'Ключ для активації бота'},
    member: {label: 'Веб', title: 'Веб який використовує даний ключ для доступу'},
    date: {label: 'Дата', title: 'Дата останнього оновлення ключа'}
  },
  alert: {
    copy: 'Ключ {key} додано в буфер обміну',
    delete: 'Ключ <strong>{key}</strong> для веба <strong>{username}</strong> видалено'
  },
  confirm: {
    delete: {
      title: 'Видалити ключ',
      message: 'Ви дійсно бажаєте видалити ліцензій ключ <strong>{key}</strong> для веба <strong>{username}</strong>?',
      button: 'Видалити'
    }
  },
  latinAmerica: 'Латинська Америка',
  america: 'Америка',
  europe: 'Європа',
  asia: 'Азія',
  australiaAndOceania: 'Австралія і Океанія',
  addOffer: 'Додати офер'
}
