export default {
  mounted () {
    const script = document.createElement('script')
    script.src = '/vendors/summernote/dist/summernote-bs4.min.js'
    script.id = 'summernote-script-1'
    document.head.appendChild(script)
  },
  destroyed () {
    let elem = document.getElementById('summernote-script-1')
    elem.parentNode.removeChild(elem)
    elem = document.getElementById('summernote-script-2')
    elem.parentNode.removeChild(elem)
  }
}
