<template>
  <div class="table table-responsive">
    <table class="table table-borderless">
      <tbody>
      <tr>
        <th>Ссылка</th>
        <td>
          <div class="form-group" >
            <div class="input-group">
              <div class="input-group-append">
                <button type="button" class="btn btn-info btn-clipboard mr-2" data-clipboard-action="copy"
                        data-clipboard-target="#redirect">Копировать
                </button>
              <input class="form-control" id="redirect" name="redirect" type="text" tabindex="1"
                    v-model="value.redirect" readonly style="width:70%;height:100%;">
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>Шаблон Postback</th>
        <td>{{ getTemplateName(value.template) }}</td>
      </tr>
      <tr>
        <th>Прокидывать GET</th>
        <td>{{ value.forwardingGet | formatBoolean }}</td>
      </tr>
      <tr>
        <th>Открывать Fullscreen</th>
        <td>{{ value.fullScreen | formatBoolean }}</td>
      </tr>
      <tr>
        <th>Включить анимацию</th>
        <td>{{ value.loadButton | formatBoolean }}</td>
      </tr>
      <tr>
        <th>Кнопка назад</th>
        <td>{{ value.bindPrevButton | formatBoolean}}</td>
      </tr>
      <tr>
        <th>Использование cookies</th>
        <td>{{ value.useCookies | formatBoolean}}</td>
      </tr>
      <tr>
        <th>Использовать клоаку</th>
        <td>{{ value.clEnable | formatBoolean }}</td>
      </tr>
      <tr>
        <th>Отправлять всех на вайт</th>
        <td>{{ value.clGeoWhite | formatBoolean }}</td>
      </tr>
      <tr>
        <th>URL для редиректа по ГЕО</th>
        <td v-if="value.clGeoRedirect">
          <div class="form-group" >
            <div class="input-group">
              <div class="input-group-append">
                <button type="button" class="btn btn-info btn-clipboard mr-2" data-clipboard-action="copy"
                        data-clipboard-target="#clGeoRedirect">Копировать
                </button>
                <input class="form-control" id="clGeoRedirect" name="clGeoRedirect" type="text" tabindex="1"
                      v-model="value.clGeoRedirect" readonly style="width:70%;height:100%;">
              </div>
            </div>
          </div>
        </td>
        <td v-else>-</td>
      </tr>
      <tr>
        <th>Язык white page</th>
        <td>{{ value.clLang }}</td>
      </tr>
      <tr>
        <th>Ссылка на приложение</th>
        <td>
          <div class="form-group" >
              <div class="input-group">
                <div class="input-group-append">
                  <button type="button" class="btn btn-info btn-clipboard mr-2" data-clipboard-action="copy"
                          data-clipboard-target="#clUrl">Копировать
                  </button>
                <input class="form-control" id="clUrl" name="clUrl" type="text" tabindex="1"
                      v-model="value.clUrl" readonly style="width:70%;height:100%;">
                </div>
              </div>
            </div>
        </td>
      </tr>
      <tr>
        <th>Сплит тестирование</th>
        <td>{{ value.useSplitTesting | formatBoolean }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'LinkSetting',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    templates () {
      return this.$store.getters['adminPWAs/trackerTemplates']
    }
  },
  methods: {
    getTemplateName: function (id) {
      const index = this.templates.findIndex(template => String(template._id) === String(id))
      if (index !== -1) {
        return this.templates[index].name
      } else {
        return '( нима )'
      }
    }
  }
}
</script>
