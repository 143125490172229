import AdminLandingPagesLayout from './layout.vue'
import AdminLandingPagesThemes from './themes.vue'
import AdminLandingPagesTypes from './types.vue'

export default (meta, header) => {
  return [
    {
      path: 'landing-pages',
      name: 'AdminLandingPagesLayout',
      components: {default: AdminLandingPagesLayout, header},
      meta,
      redirect: '/',
      children: [
        {
          path: 'themes',
          name: 'AdminLandingPagesThemes',
          components: {default: AdminLandingPagesThemes, header},
          meta
        },
        {
          path: 'types',
          name: 'AdminLandingPagesTypes',
          components: {default: AdminLandingPagesTypes, header},
          meta
        }
      ]
    }
  ]
}
