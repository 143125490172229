<template>
  <td>
    <router-link class="text-white" :to="{name: 'TrackerUpdate', params: {id: value.id}}">{{ value.name }}</router-link>
  </td>
</template>
<script>
export default {
  name: 'TrackerColumn',
  props: {value: Object}
}
</script>
