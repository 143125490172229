<template>
  <tr>
    <td class="td" v-if="isAdmin">
      <div class="dropdown d-inline ml-1 mr-1">
        <button class="btn btn-icons btn-inverse-success" type="button" data-toggle="dropdown">
          <i class="icon-options"></i>
        </button>
        <div class="dropdown-menu">
          <a href="javascript:void(0);" class="dropdown-item" @click="handlerEdit">
            <i class="icon-pencil"></i> Редагування</a>
          <router-link class="dropdown-item"
                       :to="{name: 'AdminUser', params: {id: value.id}}"><i class="icon-eye"></i> Перегляд
          </router-link>
          <a href="javascript:void(0);" class="dropdown-item" @click="handlerRemove(value)">
            <i class="icon-trash"></i> Видалити</a>
          <a href="javascript:void(0);" class="dropdown-item" @click="$parent.$parent.$refs.mail.write({user: value})"><i
            class="icon-envelope"></i> Написать в TG</a>
          <router-link class="dropdown-item"
                       :to="{name: 'AdminUserTariffs', params: {id: value.id}}"><i class="icon-briefcase"></i> Підписки
          </router-link>
          <a href="javascript:void(0);" class="dropdown-item" @click="handleLogin()"><i
            class="icon-login"></i> Вхід</a>
        </div>
      </div>
    </td>
    <td :class="value.online ? 'bg-inverse-success' : ''">
      <table-owner :data="{...value, id: value.id}"/>
    </td>
    <td v-if="isAdmin">{{ value.balance | amount }}</td>
    <td @dblclick="handlerEditMetrics">
      <AdminFormFieldMetrics
        v-if="showEditMetrics"
        :value="value"
        @input="handlerUpdate"
        @skip="showEditMetrics = false"/>
      <span v-else v-html="$options.filters.formatNull(value.metrics.manual)"></span>
    </td>
    <td v-if="isAdmin" :class="tariffClass" v-html="tariffInfo"></td>
    <td>{{ value.auth_date * 1000 | dateFromNow }}</td>
    <td>{{ value.createdAt | dateFromNow }}</td>
    <td>{{ value.updatedAt | dateFromNow }}</td>
    <td v-if="isAdmin">{{ getLabelPermissionLevel(value.permissionLevel) }}</td>
    <td v-if="isAdmin"><a :href="value.ref_link | refLink" target="_blank">{{ value.ref_link }}</a></td>
  </tr>
</template>
<script>
import usersMixin from '../../mixins/users-mixin'
import * as adminComponents from '@/pages/admin/components'
import textMixin from '@/mixins/text-mixin'
import {mapActions, mapGetters} from 'vuex'
import AdminFormFieldMetrics from '@/components/admin/form/field/Metrics.vue'
import {setI18nLanguage} from '@/plugins/i18n'

export default {
  name: 'Row',
  props: ['value'],
  components: {TableOwner: adminComponents.tables.Owner, AdminFormFieldMetrics},
  mixins: [usersMixin, textMixin],
  filters: {
    refLink: value => `${window.location.protocol}//${window.location.host}/r/${value}`
  },
  data () {
    return {
      showEditMetrics: false
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      authData: 'adminUsers/auth'
    }),
    expired () {
      const days = Math.ceil(Math.abs(new Date() - new Date(this.value.tariff.expires)) / (1000 * 60 * 60 * 24))
      if (new Date(this.value.tariff.expires) > Date.now()) {
        return `Закончится через ${days} ${this.countForm(days, ['день', 'дня', 'дней'])}`
      } else {
        return `Закончился ${days} ${this.countForm(days, ['день', 'дня', 'дней'])} назад`
      }
    },
    tariffInfo () {
      if (this.value.installing) return 'Льет за инсталы'
      if (this.value.tariff.expired != null) {
        return `${this.expired}<br>
        Заплатил ${this.$options.filters.amount(this.value.tariff.total)}<br>
        ${this.value.tariff.tariff.type}=>${this.value.tariff.tariff.name.ru}<br>
        Полная стоимость ${this.$options.filters.amount(this.value.tariff.tariff.cost)}`
      }
      return this.$options.filters.formatNull(null)
    },
    tariffClass () {
      if (this.value.installing) return 'bg-inverse-success'
      if (this.value.tariff.expired == null) return ''
      if (!this.value.tariff.expired) return 'bg-inverse-success'
      if (this.value.tariff.expired) {
        return 'bg-inverse-warning'
      }
    }
  },
  methods: {
    ...mapActions({
      alertsSuccess: 'alerts/success',
      delete: 'adminUsers/delete',
      authUser: 'adminUsers/authUser'
    }),
    async handlerRemove (item) {
      if (confirm('Удалить пользователя?')) {
        await this.delete(item.id)
        await this.alertsSuccess(`Пользователь <strong>${item.username}</strong> успешно удалён`)
        await this.$emit('input', true)
      }
    },
    async handlerEdit () {
      const ok = await this.$parent.$parent.$refs.adminUserForm.handlerUpdate(JSON.parse(JSON.stringify(this.value)))
      if (ok) {
        await this.alertsSuccess(`Пользователь <strong>${this.value.username}</strong> успешно изменён`)
        this.$emit('input', true)
      }
    },
    handlerEditMetrics () {
      if (this.showEditMetrics === false) {
        this.showEditMetrics = true
      }
    },
    handlerUpdate () {
      this.$emit('input', true)
      this.showEditMetrics = false
      this.alertsSuccess(`Пользователь <strong>${this.value.username}</strong> успешно изменён`)
    },
    async handleLogin () {
      await this.authUser(this.value._id)
      localStorage.setItem('identity', JSON.stringify({
        id: this.authData._id,
        username: this.authData.username,
        photo_url: this.value.photo_url,
        ref_link: this.authData.ref_link,
        isAdmin: false,
        lang: this.authData.lang,
        tariffInstalled: this.authData.tariffInstalled,
        pushesEnable: this.authData.pushesEnable,
        team: this.authData.teamEnable,
        customJs: this.authData.customJs,
        showTrackerBypass: this.authData.showTrackerBypass != null ? this.authData.showTrackerBypass : false
      }))
      setI18nLanguage(this.authData.lang)
      localStorage.setItem('vue-authenticate.vueauth_accessToken', this.authData.accessToken)
      window.location.href = '/'
    },
    handlerPolicyModal () {
      console.log('open modal')
      this.$emit('open-privacy')
    }
  }
}
</script>
<style scoped>
.td {
  text-align: center;
}
.dropdown-item {
  padding: 10px 15px;
  display: flex;
  align-items: center
}
.dropdown-item i {
  margin-right: 10px;
}
.dropdown-item:hover {
  color: #fff;
}
</style>
