<template>
  <div class="Dashboard page-dashboard parent_tooltip">
    <AccessDenied v-if="isMember && !isHaveAccess"/>
    <div v-else>
      <Onlines class="page-dashboard__onlines"/>
      <hr class="page-dashboard__hr">
      <div class="page-dashboard__activities">
        <Activity class="page-dashboard__activity stretch-card"/>
        <UniquesAndInstalls class="page-dashboard__chart stretch-card"/>
      </div>
      <hr class="page-dashboard__hr">
      <div class="page-dashboard__bottom-row">
        <transactions class="stretch-card"/>
        <top-onlines class="stretch-card"/>
      </div>
    </div>
  </div>
</template>

<script>
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import * as components from './components'
export default {
  name: 'Dashboard',
  title: 'Dashboard',
  mixins: [titleMixin, accessesMixin],
  components: {...components},
  data () {
    return {
      data: {
        onlines: [],
        uniquesCount: 0,
        topOnlines: [],
        activity: {
          push: 0,
          open: 0,
          install: 0
        },
        progress: {labels: [], installs: [], uniques: []},
        transactions: []
      }
    }
  },
  computed: {
    isHaveAccess () {
      return this.accesses.statistics.view
    },
    isMember () {
      return !!this.$store.state.auth.identity.memberId
    }
  }
}
</script>
<style lang="sass">
.page-dashboard__loading
  margin: 0 auto
</style>

<style scoped lang="sass">
@import '@/styles/_variables.sass'
.page-dashboard
  width: 100%
  display: flex
  flex-direction: column
  padding-right: 20px
  height: 100%
  max-width: 1600px
  &__loading
    height: 100%
    min-height: 80vh
    display: flex
    align-items: center
    justify-content: center
  &__activities,
  &__bottom-row
    display: flex
    flex-direction: row
    @media screen and (max-width: 767px)
      flex-direction: column
  &__activities
    padding-bottom: 30px
  &__activity
    width: 100%
    max-width: 310px
    padding: 20px 30px 20px 30px
    padding-bottom: 0
    @media screen and (max-width: 767px)
      padding-left: 20px
      padding-right: 0
      max-width: 500px
  &__chart
    width: 100%
  &__hr
    border-bottom: solid 1px $grey-outer-space
    height: 1px
    width: calc(100% - 60px)
    margin-top: 20px
    margin-bottom: 20px
    margin-left: 30px

</style>
