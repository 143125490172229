<template>
  <form class="form-inline admin_form_field_metrics_form" @submit.prevent="handlerSubmit">
    <label class="sr-only" for="admin-form-field-metrics-manual">Metrics</label>
    <input
      v-model="metrics.manual"
      :class="apiValidationErrors['metrics.manual'] ? 'form-control-danger' : ''"
      name="admin-form-field-metrics-manual"
      type="text"
      class="form-control mr-sm-2"
      id="admin-form-field-metrics-manual"
      placeholder="TGGroup"
      @focus="clearError('metrics.manual')"
      autofocus>
    <button :disabled="processing" type="submit" class="btn btn-icons btn-inverse-success mr-sm-2"><i class="icon-pencil"></i></button>
    <button @click="handlerSkip" :disabled="processing" type="button" class="btn btn-icons btn-inverse-warning"><i class="icon-ban"></i></button>
    <label v-if="apiValidationErrors['metrics.manual']" class="error mt-2 text-danger"
           for="admin-form-field-metrics-manual">{{ apiValidationErrors['metrics.manual'][0] }}</label>
  </form>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import {mapActions} from 'vuex'

export default {
  name: 'AdminFormFieldMetrics',
  mixins: [formMixin],
  props: ['value'],
  data () {
    return {
      processing: false,
      metrics: {
        manual: null
      }
    }
  },
  created () {
    this.metrics = JSON.parse(JSON.stringify(this.value.metrics))
  },
  methods: {
    ...mapActions({
      update: 'adminUsers/patchUpdate'
    }),
    async handlerSubmit () {
      this.processing = true
      try {
        if (this.metrics.manual === '') {
          this.metrics.manual = null
        }
        await this.update({
          id: this.value.id,
          metrics: this.metrics
        })
        this.$emit('input', true)
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    handlerSkip () {
      this.$emit('skip', true)
    }
  }
}
</script>
<style scoped>
.admin_form_field_metrics_form {
  min-width: 249px;
}
</style>
