<template>
  <div class="modal fade" id="mail-modal">
    <div class="modal-dialog mt-0">
      <form class="modal-content" @submit.prevent="handler">
        <div class="modal-header">
          <h5 class="modal-title">{{ header }}</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group" :class="apiValidationErrors.title ? 'has-danger' : ''">
            <label for="title">Тайтл</label>
            <input class="form-control" id="title" name="title" type="text" tabindex="1"
                   placeholder="Введите тайтл сообщения" v-model="title"
                   :class="apiValidationErrors.title ? 'form-control-danger' : ''"
                   @focus="clearError('title')" autofocus>
            <label v-if="apiValidationErrors.title" id="title-error" class="error mt-2 text-danger"
                   for="title">{{ apiValidationErrors.title[0] }}</label>
              <div v-else-if="count" class="input-group-append">
                <small class="form-text text-muted">Сообщения получат {{ count }}</small>
              </div>
          </div>
          <div class="form-group" :class="apiValidationErrors.body ? 'has-danger' : ''">
            <label for="body">Сообщения</label>
            <textarea class="form-control" id="body" name="body" rows="10" tabindex="2"
                      placeholder="Введите текст сообщения" v-model="body"
                      :class="apiValidationErrors.body ? 'form-control-danger' : ''"
                      @focus="clearError('body')"></textarea>
            <label v-if="apiValidationErrors.body" id="body-error" class="error mt-2 text-danger"
                   for="body">{{ apiValidationErrors.body[0] }}</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success">Отправить</button>
          <a href="javascript:void(0);" class="btn btn-light" data-dismiss="modal">Отмена</a>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'

export default {
  name: 'Mail',
  mixins: [formMixin],
  data: () => ({
    header: 'Отправить сообщения',
    title: '',
    body: '',
    userId: '',
    filters: {
      permissionLevel: '',
      _id: '',
      online: '',
      state: '',
      tariff: '',
      createdAt: ''
    },
    count: 0
  }),
  methods: {
    handler: async function () {
      try {
        this.resetApiValidation()
        let filters = {}
        for (const [key, value] of Object.entries(this.filters)) {
          if (value) {
            filters[key] = value
          }
        }
        if (this.userId != null && this.userId !== '') {
          await this.$store.dispatch('messages/send', {
            id: this.userId,
            body: this.body,
            title: this.title
          })
        } else {
          await this.$store.dispatch('messages/sendAll', {
            body: this.body,
            title: this.title,
            filter: filters
          })
        }
        this.resetApiValidation()

        await this.$store.dispatch(
          'alerts/success',
          'Сообщения отправлено'
        )
        this.title = ''
        this.body = ''
        this.userId = null
        this.filters = {}
        this.count = 0
        // eslint-disable-next-line no-undef
        jQuery('#mail-modal').modal('hide')
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    write: function ({user = null, filters = {}, count = 0}) {
      if (user === null) {
        this.userId = null
        this.header = 'Отправить сообщения'
        this.filters = filters
        this.count = count
      } else {
        this.header = `Отослать @${user.username} письмецо в конверте`
        this.userId = user._id
      }
      this.title = ''
      this.body = ''
      // eslint-disable-next-line no-undef
      jQuery('#mail-modal').modal('show')
    }
  }
}
</script>
