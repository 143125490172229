export default {
  home: 'Principal',
  statistics: 'Estatística',
  trackers: 'Trackers',
  bills: 'Finanças',
  tariffs: 'Planos',
  referrals: 'Programa de afiliados',
  teams: 'Trabalho em equipe',
  templates: 'Modelos',
  landing: 'Landing',
  mirrors: 'Espelhos',
  vacancies: 'Vagas'
}
