export default {
  methods: {
    uniquesToInstalls: (uniques, installs) => uniques === 0 ? 0 : installs / uniques,
    uniquesToPush: (uniques, push) => uniques === 0 ? 0 : push / uniques,
    installsToPush: (installs, push) => push === 0 ? 0 : push / installs,
    checkIsNewColumns (newArr, oldArr, excludeColumns = []) {
      const filteredNewArr = newArr.filter(item => !excludeColumns.includes(item))
      return filteredNewArr.some(item => {
        return !oldArr.includes(item)
      })
    },
    checkIsNewFilters (newFilters, oldFilters) {
      return Object.keys(newFilters).some(item => {
        return !Object.keys(oldFilters).includes(item)
      })
    },
    sortByNumber () {
      if (this.isSortByDecrease) {
        this.sortedModels.sort((a, b) => a[this.sortValue] - b[this.sortValue])
      } else {
        this.sortedModels.sort((a, b) => b[this.sortValue] - a[this.sortValue])
      }
    },
    sortByText () {
      this.sortedModels.sort((a, b) => {
        if (this.isSortByDecrease) {
          return (a[this.sortValue] < b[this.sortValue]) ? -1 : (a[this.sortValue] > b[this.sortValue]) ? 1 : 0
        } else {
          return (a[this.sortValue] < b[this.sortValue]) ? 1 : (a[this.sortValue] > b[this.sortValue]) ? -1 : 0
        }
      })
    },
    sortByDate () {
      this.sortedModels.sort((a, b) => {
        if (this.isSortByDecrease) {
          return new Date(a.id) - new Date(b.id)
        } else {
          return new Date(b.id) - new Date(a.id)
        }
      })
    },
    sortByPWA () {
      this.sortedModels.sort((a, b) => {
        if (this.isSortByDecrease) {
          return (a[this.sortValue].name < b[this.sortValue].name) ? -1 : (a[this.sortValue].name > b[this.sortValue].name) ? 1 : 0
        } else {
          return (a[this.sortValue].name < b[this.sortValue].name) ? 1 : (a[this.sortValue].name > b[this.sortValue].name) ? -1 : 0
        }
      })
    }
  }
}
