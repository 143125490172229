<template>
  <select2
    class=""
    inputClass="form-control select_white_g"
    id="user-metrics"
    name="user-metrics"
    placeholder="Фільтр за міткою"
    :value="value"
    :settings="settings"
    @change="handlerInput"
  />
</template>
<script>
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'

export default {
  name: 'AdminFilterMetrics',
  components: {Select2},
  mixins: [select2AjaxMixin],
  props: ['value'],
  data () {
    return {
      settings: {}
    }
  },
  created () {
    this.settings = {...this.getSelect2AjaxSettings('admin/users/metrics', 'id'), allowClear: true}
  },
  methods: {
    handlerInput ($event) {
      this.$emit('input', $event)
    }
  }
}
</script>
