<template>
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="custom_head_sg d_flex align_center justify_between">
              <div><h4 class="card-title">Финансы</h4></div>
              <div class="finance_af_add d_flex align_center">
                <div class="add_finance">
                  <div class="d_flex align_center">
                    <div class="summs_grg d_flex align_center">
                      <div><a href="#">$10</a></div>
                      <div><a href="#">$20</a></div>
                      <div><a href="#">$30</a></div>
                      <div><a href="#">$40</a></div>
                      <div><a href="#">$100</a></div>
                    </div>
                    <div class="inp_summ_g">
                      <input type="number" class="form-control" placeholder="Сумма">
                    </div>
                  </div>
                </div>
                <div class="button_righ_shg">
                  <button class="btn_green_g" data-toggle="modal" data-target="#modal_payment">Пополнить</button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-responsive" v-if="finance_table">
                <div id="data_table_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                  <div class="row">
                    <div class="col-sm-12">
                      <table id="finance-listing" class="table_border_style table">
                        <thead>
                        <tr role="row">
                          <th class="item_fina_col_1"></th>
                          <th class="item_fina_col_2">
                            <div class="d_inline_block sort_butt_tgus">
                              <div class="d_flex align_center">
                                <span>Имя</span>
                                <div class="icon_sort_g"></div>
                              </div>
                            </div>
                          </th>
                          <th class="item_fina_col_3">
                            <div class="d_inline_block sort_butt_tgus">
                              <div class="d_flex align_center">
                                <span>Сумма</span>
                                <div class="icon_sort_g"></div>
                              </div>
                            </div>
                          </th>
                          <th class="item_fina_col_4">
                            <div class="d_inline_block sort_butt_tgus">
                              <div class="d_flex align_center">
                                <span>Способ оплаты</span>
                                <div class="icon_sort_g"></div>
                              </div>
                            </div>
                          </th>
                          <th class="text-right item_fina_col_5 pr-0">
                            <div class="d_inline_block sort_butt_tgus">
                              <div class="d_flex align_center">
                                <span>Статус</span>
                                <div class="icon_sort_g"></div>
                              </div>
                            </div>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <label class="text-warning">Платеж в обработке</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <label class="text-success">Оплачено</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <label class="text-danger">Ошибка платежа</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <div class="d_inline_block">
                              <button class="btn_green_g" data-toggle="modal" data-target="#modal_payment">Оплатить</button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <label class="text-warning">Платеж в обработке</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <label class="text-warning">Платеж в обработке</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <label class="text-warning">Платеж в обработке</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <label class="text-warning">Платеж в обработке</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <label class="text-warning">Платеж в обработке</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="item_fina_col_1">
                            <img src="https://via.placeholder.com/40x40" alt="image">
                          </td>
                          <td class="item_fina_col_2">MonkeyBoom</td>
                          <td class="item_fina_col_3">10$</td>
                          <td class="item_fina_col_4">Bitcoin</td>
                          <td class="text-right pr-0 item_fina_col_5">
                            <label class="text-warning">Платеж в обработке</label>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" v-else>
                <div class="no_pwa_here">
                  <div class="in_to_nothing_pwa text_center">
                    <div class="image_no_pw">
                      <img class="img_responsive" src="/images/image_no_finance.png" alt="">
                    </div>
                    <div class="texts_pwa_no">
                      <div class="text_this_no_pw">Здесь напишем про то что страница пока пустая и <br>нужно нажать на кнопку “Пополнить”</div>
                      <div class="finance_af_add d_flex align_center justify_center">
                        <div class="add_finance">
                          <div class="d_flex align_center">
                            <div class="summs_grg d_flex align_center">
                              <div><a href="#">$10</a></div>
                              <div><a href="#">$20</a></div>
                              <div><a href="#">$30</a></div>
                              <div><a href="#">$40</a></div>
                              <div><a href="#">$100</a></div>
                            </div>
                            <div class="inp_summ_g">
                              <input type="number" class="form-control" placeholder="Сумма">
                            </div>
                          </div>
                        </div>
                        <div class="button_righ_shg">
                          <button class="btn_green_g" data-toggle="modal" data-target="#modal_payment">Пополнить</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade modal_center_g" id="modal_payment" tabindex="-1">
      <div class="modal-dialog big_modal_w" role="document">
        <div class="modal-content modal_padd_normal">
          <div class="modal_title_g">Внесите оплату на указанный счет</div>
          <div class="sumb_tot_g">
            <div class="d_flex justify_between">
              <div>
                <div class="subtit_sub_g">Сумма</div>
                <div class="num_bitcoins_g">
                  <div class="d_flex align_center">
                    <div class="total_bitg_g"><span id="bitcoins_tot">0,00020000202 BTC</span></div>
                    <div class="icon_cop_mrg">
                      <button class="btn_simple_copy btn-clipboard" data-clipboard-target="#bitcoins_tot" data-clipboard-action="copy"><span class="icon_copu_smg"></span></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right_sumshg_">
                <div class="subtit_sub_g">Подтверждений</div>
                <div class="stepshjs_g">0/3</div>
              </div>
            </div>
          </div>
          <div class="link_copy_cos">
            <div class="d_flex align_center">
              <div class="flex_grow_1 info_cosjf_bord d_flex align_center">
                <div class="w-100">
                  <div class="subtit_sub_g">Кошелек</div>
                  <div class="num_code_cos">
                    <div class="in_to_num_cod_cos">
                      <input type="text" id="number_bitc" readonly="" value="154EQ6sYZrtB8Wbx8HewiiZCZ3ZZEDXeqe">
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex_grow_0 right_but_cop_g">
                <button class="copy_butt_bord d_flex align_center justify_center btn-clipboard" data-clipboard-action="copy" data-clipboard-target="#number_bitc">
                  <span>
                    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.75 8.675C22.737 8.56017 22.7118 8.44704 22.675 8.3375V8.225C22.6149 8.09648 22.5347 7.97833 22.4375 7.875L14.9375 0.375C14.8342 0.27777 14.716 0.197601 14.5875 0.1375C14.5502 0.1322 14.5123 0.1322 14.475 0.1375C14.348 0.0646766 14.2078 0.0179303 14.0625 0H9C8.00544 0 7.05161 0.395088 6.34835 1.09835C5.64509 1.80161 5.25 2.75544 5.25 3.75V5H4C3.00544 5 2.05161 5.39509 1.34835 6.09835C0.645088 6.80161 0.25 7.75544 0.25 8.75V21.25C0.25 22.2446 0.645088 23.1984 1.34835 23.9017C2.05161 24.6049 3.00544 25 4 25H14C14.9946 25 15.9484 24.6049 16.6517 23.9017C17.3549 23.1984 17.75 22.2446 17.75 21.25V20H19C19.9946 20 20.9484 19.6049 21.6517 18.9017C22.3549 18.1984 22.75 17.2446 22.75 16.25V8.75C22.75 8.75 22.75 8.75 22.75 8.675ZM15.25 4.2625L18.4875 7.5H16.5C16.1685 7.5 15.8505 7.3683 15.6161 7.13388C15.3817 6.89946 15.25 6.58152 15.25 6.25V4.2625ZM15.25 21.25C15.25 21.5815 15.1183 21.8995 14.8839 22.1339C14.6495 22.3683 14.3315 22.5 14 22.5H4C3.66848 22.5 3.35054 22.3683 3.11612 22.1339C2.8817 21.8995 2.75 21.5815 2.75 21.25V8.75C2.75 8.41848 2.8817 8.10054 3.11612 7.86612C3.35054 7.6317 3.66848 7.5 4 7.5H5.25V16.25C5.25 17.2446 5.64509 18.1984 6.34835 18.9017C7.05161 19.6049 8.00544 20 9 20H15.25V21.25ZM20.25 16.25C20.25 16.5815 20.1183 16.8995 19.8839 17.1339C19.6495 17.3683 19.3315 17.5 19 17.5H9C8.66848 17.5 8.35054 17.3683 8.11612 17.1339C7.8817 16.8995 7.75 16.5815 7.75 16.25V3.75C7.75 3.41848 7.8817 3.10054 8.11612 2.86612C8.35054 2.6317 8.66848 2.5 9 2.5H12.75V6.25C12.75 7.24456 13.1451 8.19839 13.8483 8.90165C14.5516 9.60491 15.5054 10 16.5 10H20.25V16.25Z" fill="#21BF73"/>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="bitcoin_cgl_g d_flex">
            <div class="qr_cod_g">
              <img class="img-fluid" src="/images/image_qr.jpg" alt="">
            </div>
            <div class="info_bit_g">
              <div class="icon_bit_g"><img src="/images/icon_bitcoin.svg" alt=""></div>
              <div class="tit_bit_g">Bitcoin</div>
              <div class="subtit_sub_g">Обратный отсчет: <span class="yel_col_g">02 часа 15 минут 12 секунд</span></div>
              <div class="pric_eis_g d_flex justify_between">
                <div class="subb_bit_g">$10,679.55</div>
                <div class="percent_s_g">-4.54%</div>
              </div>
              <div class="actions_aus_g">
                <div class="d_flex align_center">
                  <div class="d_inline_block item_butt_acat_g">
                    <!-- <button class="ful_butt_green d_flex align_center justify_center" data-dismiss="modal">Оплачено</button> -->
                    <a href="" class="ful_butt_green d_flex align_center justify_center" data-dismiss="modal">Оплачено</a>
                  </div>
                  <div class="d_inline_block item_butt_acat_g">
                    <button class="border_butt_green d_flex align_center justify_center">Проверить</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<button class="btn_green_g" data-toggle="modal" data-target="#modal_info_1">Modal 1</button>
    <button class="btn_green_g" data-toggle="modal" data-target="#modal_info_2">Modal 2</button>
    <button class="btn_green_g" data-toggle="modal" data-target="#modal_info_3">Modal 3</button> -->

    <div class="modal fade modal_center_g" id="modal_info_1" tabindex="-1">
      <div class="modal-dialog modal_info" role="document">
        <div class="modal-content modal_padd_normal">
          <div class="info_blk_g_mod text-center">
            <div class="icon_sinf_g">
              <img src="/images/icon_info_fast.png" alt="">
            </div>
            <div class="title_inf_glbk" style="color: #FD5E53;">Срочный</div>
            <div class="descr_inf_ico_g">Здесь будет написан текст про то что <br>уведомление карпец какое срочное</div>
          </div>
          <div class="foot_inf_sg_g">
            <div class="in_to_foot_br_g">
              <div class="row has_big_rog">
                <div class="col-6 item_pwa_shs_g">
                  <a href="#" class="btn_simple_border d_flex align_center justify_center no_underline red_col">Кнопка</a>
                </div>
                <div class="col-6 item_pwa_shs_g">
                  <a href="#" class="btn_simple_border d_flex align_center justify_center no_underline full_plin red_col">Кнопка</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade modal_center_g" id="modal_info_2" tabindex="-1">
      <div class="modal-dialog modal_info" role="document">
        <div class="modal-content modal_padd_normal">
          <div class="info_blk_g_mod text-center">
            <div class="icon_sinf_g">
              <img src="/images/icon_info_attention.png" alt="">
            </div>
            <div class="title_inf_glbk" style="color: #F1C62D;">Требует внимания</div>
            <div class="descr_inf_ico_g">Здесь будет написан текст про то что <br>уведомление карпец какое срочное</div>
          </div>
          <div class="foot_inf_sg_g">
            <div class="in_to_foot_br_g">
              <div class="row has_big_rog">
                <!--<div class="col-6 item_pwa_shs_g">
                  <a href="#" class="btn_simple_border d_flex align_center justify_center no_underline yell_col">Кнопка</a>
                </div> -->
                <div class="col-12 item_pwa_shs_g">
                  <a href="#" class="btn_simple_border d_flex align_center justify_center no_underline full_plin yell_col">Кнопка</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade modal_center_g" id="modal_info_3" tabindex="-1">
      <div class="modal-dialog modal_info" role="document">
        <div class="modal-content modal_padd_normal">
          <div class="info_blk_g_mod text-center">
            <div class="icon_sinf_g">
              <img src="/images/icon_info.png" alt="">
            </div>
            <div class="title_inf_glbk" style="color: #21BF73;">Уведомляемый</div>
            <div class="descr_inf_ico_g">Здесь будет написан текст про то что <br>уведомление карпец какое срочное</div>
          </div>
          <div class="foot_inf_sg_g">
            <div class="in_to_foot_br_g">
              <div class="row has_big_rog">
                <!--<div class="col-6 item_pwa_shs_g">
                  <a href="#" class="btn_simple_border d_flex align_center justify_center no_underline yell_col">Кнопка</a>
                </div> -->
                <div class="col-12 item_pwa_shs_g">
                  <a href="#" class="btn_simple_border d_flex align_center justify_center no_underline full_plin">Кнопка</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars,no-undef
const clipboard = new Clipboard('.btn-clipboard')

export default {
  name: 'Finances',
  data () {
    return {
      finance_table: true
    }
  }
}
</script>
