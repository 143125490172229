<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Система</h4>
            <p class="card-description">Список очередей</p>
            <button
                @click="fetchPage()"
                type="button" class="btn btn-outline-success btn-fw mb-2"><i
                class="icon-reload"></i>Обновить
            </button>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>Название очереди</th>
                  <th>Active worker</th>
                  <th>Waiting Jobs</th>
                  <th>Succeeded Jobs</th>
                  <th>Failed Jobs</th>
                  <th>Delayed Jobs</th>
                  <th>Newest Jobs</th>
                </tr>
                </thead>
                <tbody id="dragula">
                <tr v-for="model in models" :key="model.name" :data-id="model.name">
                  <td>{{ model.name }}</td>
                  <td>{{ model.active }}</td>
                  <td>{{ model.waiting }}</td>
                  <td>{{ model.succeeded }}</td>
                  <td>{{ model.failed }}</td>
                  <td>{{ model.delayed }}</td>
                  <td>{{ model.newestJob }}</td>
                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'System',
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchPage()
  },
  computed: {
    ...mapGetters({
      models: 'adminSystem/queues'
    })
  },
  methods: {
    ...mapActions({
      fetchData: 'adminSystem/asyncDataQueues'
    }),
    debounceFetchData: _.debounce(async function () {
      await this.fetchData()
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData()
    }
  }
}
</script>
