<template>
  <form class="finance_af_add d-flex" @submit.prevent="handler">
    <div class="add_finance flex-grow-1 flex-md-grow-0">
      <div class="d-flex align_center">
        <div class="summs_grg d-none d-lg-flex align-items-center">
          <div v-for="item in amounts" v-bind:key="item">
            <a href="javascript:void(0);" @click="amount = item">${{ item }}</a>
          </div>
        </div>
        <div
          class="form-group ml-md-3 position-relative mb-0 flex-grow-1"
          :class="apiValidationErrors.amount ? 'has-danger' : ''"
        >
          <input
            type="number"
            id="amount"
            class="form-control"
            step="1"
            :placeholder="$t('finance.bills.amountTopUp')"
            v-model="amount"
            name="amount"
            max="50000"
            :class="apiValidationErrors.amount ? 'form-control-danger' : ''"
            @focus="clearError('amount')"
          />
          <label
            v-if="apiValidationErrors.amount"
            id="name-error"
            class="error mt-2 text-danger mr-n5"
            for="amount"
            >{{ apiValidationErrors.amount[0] }}</label
          >
        </div>
      </div>
    </div>
    <div class="ml-2">
      <button class="btn_green_g">{{ $t("finance.bills.topUp") }}</button>
    </div>
    <payment-methods-form v-bind:amount="amount" ref="modal" />
  </form>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import PaymentMethodsForm from './PaymentMethodsForm.vue'

export default {
  name: 'AmountsForm',
  components: { PaymentMethodsForm },
  mixins: [formMixin],
  data: function () {
    return {
      amount: ''
    }
  },
  computed: {
    amounts () {
      const amounts = this.$store.getters['billing/amounts']
      const tariffInstalled = this.$store.getters['identity'].tariffInstalled
      const minInstalledAmount = this.$store.getters['billing/minInstalledAmount']
      if (tariffInstalled) {
        return amounts.filter(v => +v >= +minInstalledAmount)
      }
      return amounts
    }
  },
  methods: {
    handler: function () {
      try {
        this.resetApiValidation()
        if (Number(this.amount) < Number(this.amounts[0])) {
          throw new Error(
            this.$t('finance.payment.minAmount', { num: this.amounts[0] })
          )
        }
        const maxAmount = 50000
        if (Number(this.amount) > maxAmount) {
          throw new Error(
            this.$t('finance.payment.maxAmount', { num: maxAmount })
          )
        }
        this.resetApiValidation()
        this.$refs.modal.show()
      } catch (error) {
        this.setApiValidation([{ param: 'amount', msg: error.message }])
      }
    }
  }
}
</script>
