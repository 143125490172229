<template>
  <div class="content-wrapper">
    <member-form ref="memberForm" :groups="$store.getters['adminMembers/groups']"/>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Мемберы</h4>
            <p class="card-description">Управления мемберами пользователей</p>
            <member-filter @change="asyncData"/>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th>Имя</th>
                  <th>Група</th>
                  <th>Пользователь</th>
                  <th>Последний логин</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model._id">
                  <td class="td">
                    <div class="dropdown d-inline ml-1 mr-1">
                      <button class="btn btn-icons btn-inverse-success" type="button" data-toggle="dropdown">
                        <i class="icon-options"></i>
                      </button>
                      <div class="dropdown-menu">
                        <a @click="handlerUpdate(model)"
                           class="dropdown-item"
                        >
                          <i class="icon-pencil"></i>
                          Редагування
                        </a>
                        <a
                          @click="$refs.modalAuthData.show(model)"
                          class="dropdown-item"
                        >
                          <i class="icon-key"></i>
                          Доступ
                        </a>
                        <a
                          @click="handlerDelete(model)"
                          class="dropdown-item"
                        >
                          <i class="icon-trash"></i>
                          Видалити
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item" @click="handleLogin(model)"><i
                          class="icon-login"></i> Вхід</a>
                      </div>
                    </div>
                  </td>
                  <td>{{ model.username }}</td>
                  <td>{{ model.group }}</td>
                  <td><table-owner :data="model.owner"/></td>
                  <td v-if="model.lastLogin">{{ model.lastLogin | formatDate }}</td>
                  <td v-else>Не входил</td>
                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-auth-data ref="modalAuthData" v-if="!loading"/>
  </div>
</template>
<script>
import _ from 'lodash'
import {Owner} from '@/pages/admin/components/tables'
import MemberForm from './MemberForm.vue'
import MemberFilter from './MemberFilter.vue'
import AuthData from './AuthData.vue'
import {setI18nLanguage} from '@/plugins/i18n'

export default {
  name: 'Members',
  components: {TableOwner: Owner,
    MemberForm,
    MemberFilter,
    'modal-auth-data': AuthData},
  data () {
    return {
      loading: true,
      page: 1,
      limit: 20,
      count: 0,
      models: []
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  methods: {
    async asyncData () {
      this.loading = true
      await this.$store.dispatch('adminMembers/asyncData',
        {
          limit: this.limit,
          page: this.page,
          sort: '-createdAt',
          filter: this.$store.getters['adminMembers/filter']
        })
      this.models = this.$store.getters['adminMembers/models']
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async handlerPagination (page) {
      this.page = page
      await this.asyncData()
    },
    async handlerUpdate (model) {
      if (await this.$refs.memberForm.handlerUpdate(model)) {
        await this.handlerPagination(1)
      }
    },
    async handlerDelete (model) {
      if (confirm(`Удалить мембера ${model._id}`)) {
        await this.$store.dispatch('adminMembers/delete', model._id)
        await this.handlerPagination(1)
      }
    },
    async handleLogin (model) {
      await this.$store.dispatch('adminUsers/authMember', {owner: model.owner._id, member: model._id})
      const {accessToken, member, ...user} = this.$store.getters['adminUsers/auth']
      localStorage.setItem('identity', JSON.stringify({
        isAdmin: false,
        memberId: member._id,
        lang: member.lang || user.settings.lang,
        id: user._id,
        username: user.username,
        photo_url: model.owner.photo_url,
        ref_link: user.ref_link,
        team: user.teamEnable,
        tariffInstalled: user.tariffInstalled,
        pushesEnable: user.pushesEnable,
        customJs: user.customJs,
        showTrackerBypass: false,
        isCompaniesOwner: member.isCompaniesOwner
      }))
      setI18nLanguage(member.lang)
      localStorage.setItem('vue-authenticate.vueauth_accessToken', accessToken)
      window.location.href = '/'
    }
  }
}
</script>
<style scoped>
.td {
  text-align: center;
}
.dropdown-item {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dropdown-item i {
  margin-right: 10px;
}
.dropdown-item:hover {
  color: #fff;
}
.table-responsive {
  min-height: 300px
}
</style>
