export default {
  send: 'Відправити',
  cancel: 'Скасувати',
  copy: 'Копіювати',
  nothingHere: 'Тут поки що нічого немає',
  save: 'Зберегти',
  yes: 'Так',
  no: 'Ні',
  on: 'Вкл',
  off: 'Викл',
  not: 'Немає',
  edit: 'Редагувати',
  delete: 'Видалити',
  statistics: 'Статистика',
  apply: 'Застосувати',
  next: 'Далі',
  read: 'Прочитано',
  familiarized: 'Ознайомлений',
  notEmpty: 'Це поле обов\'язкове',
  recordsPerPage: 'Записів на сторінці',
  gotoPage: 'Перейти до сторінки',
  close: 'Закрити',
  refresh: 'Оновити',
  alerts: {
    success: {
      heading: 'Успішно'
    },
    warning: {
      heading: 'Попередження'
    },
    error: {
      heading: 'Помилка'
    }
  },
  logout: {
    title: 'Ви хочете вийти?',
    message: 'Ви точно хочете вийти зі свого акаунта?',
    okButton: 'Вийти'
  }
}
