<template>
  <div class="PWAsCreate content-wrapper parent_tooltip" v-if="accesses.pwas.update">
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body pt-0 ">
            <div class="descrs_shgh_pwa_ pwa-create-form-wrapper">
              <h4 class="title_sf_site">{{ $t('pwas.create.title') }}</h4>
              <p class="descr_site_shg" v-html="$t('pwas.create.body')"></p>
              <domain-form ref="domainForm"/>
            </div>
            <CreatePWAImage />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import CreatePWAImage from '@/components/animatedSvgs/CreatePWAImage.vue'
import DomainForm from './forms/DomainForm/DomainForm.vue'

export default {
  name: 'PWAsCreate',
  title () {
    return this.$t('titles.pwa.create')
  },
  mixins: [titleMixin, accessesMixin],
  components: {DomainForm, CreatePWAImage},
  data () {
    return {}
  },
  async mounted () {
    await this.$store.dispatch('pwas/asyncDomainPage')
    const isCreate = await this.$refs.domainForm.handlerCreate()
    if (isCreate) {
      await this.$store.dispatch(
        'alerts/success',
        this.$t('pwas.create.success')
      )
      const pwa = this.$store.getters['pwas/PWA']
      await this.$router.push({name: 'PWAsUpdate', params: {id: pwa._id}})
    } else {
      await this.$router.push({name: 'PWAs'})
    }
  }
}
</script>
<style lang="sass" scoped>
.pwa-create-form-wrapper
  position: relative
  z-index: 2
</style>
