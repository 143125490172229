<template>
  <div class="col-6 grid-margin">
    <div class="card">
      <div class="card-body">
        <breadcrumb :id="pwa._id">
          <arrow-button v-model="shs"/>
        </breadcrumb>
        <h4 class="card-title">Трекинг</h4>
        <div class="table table-responsive" v-if="shs">
          <table class="table table-borderless">
            <tbody>
            <tr>
              <th>Трекер</th>
              <td>
                <router-link v-if="pwa.tracker" :to="{name: 'AdminTracker', params: {id: pwa.tracker}}">
                  {{ pwa.tracker }}
                </router-link>
                <span v-else class="text-gray">( нет )</span></td>
            </tr>
            <tr v-for="(ga, index) of pwa.googleAnalytics" :key="index">
              <th>Google Conversion ID</th>
              <td>
                <ga :data="ga"/>
              </td>
            </tr>
            <tr v-for="(ga, index) of pwa.googleAnalytics" :key="index">
              <th>Google Conversion ID</th>
              <td>
                <ga :data="ga"/>
              </td>
            </tr>
            <tr v-for="(fbp, index) of pwa.FBPs" :key="index">
              <th>Facebook Pixel</th>
              <td>
                <fbp :data="fbp"/>
              </td>
            </tr>
            <tr v-for="(ttq, index) of pwa.ttqs" :key="index">
              <th>TikTok Pixel</th>
              <td>
                <ttq :data="ttq"/>
              </td>
            </tr>
            <tr>
              <th>Yandex.Metrika</th>
              <td>{{ pwa.YM }}</td>
            </tr>
            <tr>
              <th>Push One Signal API</th>
              <td>{{ pwa.POS }}</td>
            </tr>
            <tr>
              <th>Postback</th>
              <td>
                <table>
                  <tr>
                    <td>{{ pwa.postback.enable | formatBoolean }}</td>
                    <td>{{ pwa.postback.url }}</td>
                    <td>{{ pwa.postback.method }}</td>
                  </tr>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '../Breadcrumb.vue'
import ArrowButton from '../ArrowButton.vue'
import * as components from './components'

export default {
  name: 'Tracker',
  props: ['pwa'],
  components: {Breadcrumb, ArrowButton, ga: components.GA, fbp: components.FBP, ttq: components.TTQ},
  data: () => ({shs: true})
}
</script>
