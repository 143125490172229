export default {
  title: 'Лендинги',
  addLandingTitle: 'Добавление лендинга',
  editLandingTitle: 'Редактирование лендинга',
  filters: {
    name: 'Фильтр по названию',
    type: 'Фильтр по типу',
    tag: 'Фильтр по тегу',
    addTag: 'Добавить новый тег',
    design: 'Фильтр по названию дизайна'
  },
  buttons: {
    create: 'Создать новый'
  },
  table: {
    headers: {
      name: 'Название',
      type: 'Тип',
      tags: 'Теги',
      designName: 'Название дизайна'
    }
  },
  action: {
    removeLanding: 'Удаление лендинга "{name}"',
    removeMessage: 'После удаления лендинга все PWA, где он использовался, больше не будут отображать прелендинг или постлендинг. Так же вы больше не сможете восстановить данные',
    successfullRemove: 'Лендинг "{name}" успешно удален'
  },
  mainForm: {
    header: 'Основное',
    info: 'Основная информация о лендинге',
    name: 'Название прелендинга',
    tags: 'Теги',
    tagsTips: 'Добавить новый тег',
    themeSearsh: 'Поиск по названию игры',
    type: 'Тип',
    wheels: 'Колесо',
    slots: 'Слоты'
  },
  paramsForm: {
    header: 'Параметры',
    info: 'Дополнительная информация о лендинге',
    body: 'Текст',
    buttonGo: 'Текст на кнопке',
    buttonMore: 'Текст на кнопке для повторного вращения',
    revolutions: 'Количество оборотов',
    logo: 'Логотип',
    download: 'Загрузить'
  },
  themesForm: {
    header: 'Дизайн игрового поля',
    info: 'Выберите дизайн для игрового поля'
  },
  sectorForm: {
    header: 'Название секторов',
    info: 'Введите название каждого сектора',
    sector: 'Сектор',
    sectorTips: 'Надпись для сектора'
  },
  modalForm: {
    header: 'Модальное окно',
    info: 'Введите текст для модального окна',
    modalHeader: 'Заголовок в окне бонусов',
    buttonTitle: 'Текст в окне бонусов',
    modalButton: 'Текст на кнопке в окне бонусов'
  },
  create: {
    title: 'Создать новый прелендинг'
  },
  validate: {
    name: {
      notEmpty: 'Имя обязательно',
      wrongFormat: 'Неверный формат',
      minLength: 'Имя должно быть длиннее 2 символов',
      maxLength: 'Имя должно быть короче 50 символов'
    },
    tags: {
      wrongFormat: 'Неверный формат',
      maxSize: 'Нельзя добавить более 5 тегов',
      maxLength: 'Максимальная длина тега 40 символов'
    },
    type: {
      notEmpty: 'Тип обязателен',
      wrongFormat: 'Неверный формат'
    },
    theme: {
      notEmpty: 'Тема обязательна',
      wrongFormat: 'Неверный формат'
    },
    logo: {
      notEmpty: 'Логотип обязателен',
      wrongFormat: 'Неверный формат'
    },
    body: {
      notEmpty: 'Текст обязателен',
      wrongFormat: 'Неверный формат',
      minLength: 'Текст должен быть длиннее 2 символов',
      maxLength: 'Текст должен быть короче 99 символов'
    },
    buttons: {
      go: {
        notEmpty: 'Текст обязателен',
        wrongFormat: 'Неверный формат',
        minLength: 'Текст должен быть длиннее 2 символов',
        maxLength: 'Текст должен быть короче 8 символов'
      },
      more: {
        notEmpty: 'Текст обязателен',
        wrongFormat: 'Неверный формат',
        minLength: 'Текст должен быть длиннее 2 символов',
        maxLength: 'Текст должен быть короче 8 символов'
      }
    },
    revolutions: {
      notEmpty: 'Обороты обязательны',
      wrongFormat: 'Неверный формат'
    },
    sector: {
      label: {
        notEmpty: 'Текст обязателен',
        wrongFormat: 'Неверный формат',
        minLength: 'Текст должен быть длиннее 2 символов',
        maxLength: 'Текст должен быть короче 24 символов'
      },
      isWin: {
        notEmpty: 'Победный сектор обязателен',
        wrongFormat: 'Неверный формат'
      }
    },
    modal: {
      header: {
        notEmpty: 'Текст обязателен',
        wrongFormat: 'Неверный формат',
        minLength: 'Текст должен быть длиннее 2 символов',
        maxLength: 'Текст должен быть короче 16 символов'
      },
      body: {
        notEmpty: 'Текст обязателен',
        wrongFormat: 'Неверный формат',
        minLength: 'Текст должен быть длиннее 2 символов',
        maxLength: 'Текст должен быть короче 45 символов'
      },
      button: {
        notEmpty: 'Текст обязателен',
        wrongFormat: 'Неверный формат',
        minLength: 'Текст должен быть длиннее 2 символов',
        maxLength: 'Текст должен быть короче 8 символов'
      }
    }
  }
}
