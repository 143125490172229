export default {
  notFound: 'Página não foi encontrada',
  analyticsGroups: 'Estatística por dias',
  analyticsUniques: 'Estatística por únicos',
  bills: 'Contas',
  transactions: 'Transação',
  pwa: {
    trash: 'Cesto de PWA',
    trashBadge: 'Cesto está vazio | Cesto tem uma PWA | Cesto tem {count} PWA | Cesto tem {count} PWA',
    app: 'Aplicação de PWA #{id}',
    comments: 'Comentário de PWA #{id}',
    create: 'Criação de PWA',
    my: 'Meus PWA',
    setting: 'Configuração de PWA #{id}',
    tracker: 'Tracker de PWA #{id}',
    domain: 'Domínio de PWA #{id}',
    vertical: 'Âmbito do PWA #{id}',
    templates: 'Modelos de PWA',
    analytics: 'Análise de PWA #{id}'
  },
  referrals: 'Programa de afiliados',
  tariffs: 'Planos',
  teams: 'Trabalho de equipe',
  trackers: {
    my: 'Meus trackers',
    create: 'Criação de tracker',
    edit: 'Edição de tracker #{id}'
  },
  pushes: {
    pushes: 'PUSH subscrição',
    create: 'Adicionar um envio em massa',
    edit: 'Editar um envio em massa {id}'
  },
  bot: {
    keys: 'Chaves de bot',
    offers: 'Ofertas de bot'
  },
  mirrors: {
    my: 'Meus Espelhos',
    trash: 'Cesto Espelhos',
    trashBadge: 'Cesto está vazio | Cesto tem uma Espelho | Cesto tem {count} Espelhos | Cesto tem {count} Espelhos'
  }
}
