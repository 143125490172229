<template>
  <div
    class="BaseSwitch checkbpx_style_ios base-switch"
    :class="{'is--inline': inline, 'is--checked': toggleState}"
  >
    <label class="base-switch__label" :for="id">
      {{ label }}
      <slot />
    </label>
    <input
      type="checkbox"
      class="base-switch__input"
      :id="id"
      v-model="toggleState"
      @change="$emit('input', toggleState)"
    >
    <span class="base-switch__toggle" @click="setNewToggleState"></span>
  </div>
</template>
<script>
export default {
  name: 'BaseSwitch',
  model: {
    event: 'change'
  },
  props: {
    value: Boolean,
    name: String,
    label: String,
    inline: Boolean
  },
  data () {
    return {
      toggleState: this.value,
      id: null
    }
  },
  watch: {
    value: {
      handler () {
        this.toggleState = this.value
      },
      immediate: true
    },
    toggleState (val) {
      this.$emit('change', val)
    }
  },
  mounted () {
    this.id = `BaseSwitch.${this._uid}`
  },
  methods: {
    setNewToggleState () {
      this.toggleState = !this.toggleState
      this.$emit('change', this.toggleState)
      this.$emit('input', this.toggleState)
    }
  }
}
</script>
<style scoped lang="sass">
/* TODO змінити потім на sass і добавити кольори зі змінних */
.base-switch
  cursor: auto
  &.is--inline
    display: flex
    flex-direction: row
    align-items: center
    .base-switch__label
      margin-bottom: 0
      font-size: 14px
      font-weight: 400
      color: #A0A1A4
      margin-left: 8px
      order: 2
  &.is--checked
    .base-switch__label
      color: #45B172
.base-switch__label
  margin-bottom: 12px
  font-size: 16px
  cursor: pointer

.base-switch__toggle
  background: #FFFFFF
  border: 1px solid #FFFFFF
  border-radius: 99px
  width: 38px
  height: 20px
  display: block
  position: relative
  cursor: pointer

.base-switch__toggle:before
  content: ""
  width: 15px
  height: 15px
  background: #F7F7FA
  border: 1px solid rgba(0,0,0,.1)
  box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.15), 0px 2px 4px rgba(50, 50, 71, 0.06)
  border-radius: 99px
  position: absolute
  left: 2px
  top: 0
  bottom: 0
  margin: auto
  transition: all .25s

.base-switch input[type="checkbox"]:checked + .base-switch__toggle:before
  left: 19px

.base-switch input[type="checkbox"]:checked + .base-switch__toggle
  background: #21BF73
  border-color: #21BF73

</style>
