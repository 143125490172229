import service from '@/store/services/ips-service'
import middleware from './middleware'

const state = () => ({
  models: [],
  count: 0,
  statuses: [
    {id: 'new', text: 'Новий'},
    {id: 'working', text: 'Працює'},
    {id: 'deleted', text: 'Видалений'},
    {id: 'monitoring', text: 'Моніторинг'},
    {id: 'default', text: 'Основний'}
  ],
  options: []
})

const mutations = {
  setPageData (state, {models, count}) {
    state.models = models
    state.count = count
  },
  setSelect2 (state, {results}) {
    state.options = results
  }
}

const actions = {
  async adminCreate ({commit, dispatch}, options) {
    try {
      return await service.adminCreate(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminFetchData ({commit, dispatch}, options) {
    try {
      const data = await service.adminGetPages(options)
      commit('setPageData', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminFetchSelect2 ({commit, dispatch}, options) {
    try {
      const data = await service.adminGetSelect2(options)
      commit('setSelect2', data)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminUpdate ({commit, dispatch}, options) {
    try {
      await service.adminUpdate(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  },
  async adminDelete ({commit, dispatch}, options) {
    try {
      await service.adminDelete(options)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  statuses: state => state.statuses,
  options: state => state.options
}

const ips = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default ips
