<template>
  <form class="DomainForm forms-sample" @submit.prevent="handlerSave" @change="onChange">
    <BaseRadioButton
      :value="modes.self"
      :label="$t('pwas.create.self')"
      :name="modes.self"
      v-model="mode"
      v-bind="$attrs"
    />
    <BaseInput
      v-if="isModeSelf"
      :class="apiValidationErrors.domain ? 'has-danger' : ''"
      name="domain"
      :error="Boolean(apiValidationErrors.domain) ? apiValidationErrors.domain[0] : ''"
      :is-error="Boolean(apiValidationErrors.domain)"
      @focus="clearError('domain')"
      @blur="checkDomain"
      v-model="domain"
      :value="domain"
      :message="$t('pwas.create.domainTitle')"
      :placeholder="$t('pwas.create.domain')"
    />
    <div
      v-if="isModeSelf && isCloudflare && !showAdd"
      class="form-group mb-4"
      :class="apiValidationErrors.key ? 'has-danger' : ''"
    >
      <div class="flex-grow-1 d-flex align-items-end">
            <BaseSelect
              class="flex-grow-1"
              v-model="savedCredentials"
              id="savedCredentials"
              :options="cloudflareOptions"
              :clearable="false"
              @update:modelValue="clearError('key')"
              :error="apiValidationErrors.key"
            />
        <div class="ml-2">
          <DomainFormDeleteButton @click="handlerDelete()"/>
        </div>
        <div class="d-flex flex-grow-0 ml-2">
          <DomainFormAddButton @click="handlerAdd" />
          <info
            class="mx_6 mr-0"
            :message="$t('pwas.create.pairTitle')"/>
        </div>
      </div>
      <label
        v-if="apiValidationErrors.key"
        id="saved-credentials-error"
        class="error mt-2 text-danger"
        for="savedCredentials">{{ apiValidationErrors.key[0] }}
      </label>
    </div>
    <BaseInput
      v-if="isModeSelf && showAdd"
      :class="apiValidationErrors.email ? 'has-danger' : ''"
      name="email"
      :error="Boolean(apiValidationErrors.email) ? apiValidationErrors.email[0] : ''"
      :is-error="Boolean(apiValidationErrors.email)"
      @focus="clearError('email')"
      v-model="email"
      :value="email"
      :message="$t('pwas.create.apiEmailTitle')"
      :placeholder="$t('pwas.create.apiEmail')"
    />
    <BaseInput
      v-if="isModeSelf && showAdd"
      :class="apiValidationErrors.key ? 'has-danger' : ''"
      name="email"
      :error="Boolean(apiValidationErrors.key) ? apiValidationErrors.key[0] : ''"
      :is-error="Boolean(apiValidationErrors.key)"
      @focus="clearError('key')"
      v-model="key"
      :value="key"
      :message="$t('pwas.create.apiKeyTitle')"
      :placeholder="$t('pwas.create.apiKey')"
    />
    <div v-if="isModeSelf && showAdd" class="cont_ama_max">
      <p
        class="card-description"
        v-html="$t('pwas.create.apiHelp')">
      </p>
    </div>
    <button
      v-if="isModeSelf && showAdd && isCloudflare"
      type="button"
      @click="handlerReturn"
      class="ful_butt_green small_btn d_flex align_center justify_center mb-3"
    >{{ $t('pwas.create.chooseAccount') }}</button>
    <BaseRadioButton
      v-if="boughtDomains.length > 0"
      v-bind="$attrs"
      :value="modes.bought"
      :label="$t('pwas.create.bought')"
      :name="modes.bought"
      v-model="mode"
    />
    <template v-if="isModeBought">
      <BaseFromGroup
        id="domainBought"
        :has-error="apiValidationErrors.domain"
        :error-text="apiValidationErrors?.domain ? apiValidationErrors?.domain[0] : ''"
      >
        <BaseSelect
          v-model="domain"
          id="domainBought"
          filter-id="status"
          filter-id-value="5"
          sort="-createdAt"
          endpoint="/domains/select2"
        />
      </BaseFromGroup>
      <div
        class="cont_ama_max mb-2"
        v-html="$t('pwas.create.boughtInfo')">
      </div>
    </template>
    <BaseRadioButton
      v-if="domains.length > 0"
      v-bind="$attrs"
      :value="modes.marker"
      :label="$t('pwas.create.buyDomain', {pwaDomainCost})"
      :name="modes.marker"
      v-model="mode"
    />
    <template v-if="isModeMarker">
      <BaseFromGroup
        id="domain"
        :has-error="apiValidationErrors.domain"
        :error-text="apiValidationErrors?.domain ? apiValidationErrors?.domain[0] : ''"
      >
        <BaseSelect
          v-model="domain"
          id="domain"
          :key="isModeMarker"
          filter-id="status"
          filter-id-value="2"
          sort="createdAt"
          custom-filter="filter[text]"
          endpoint="/domains/select2"
        />
      </BaseFromGroup>
      <div
        class="cont_ama_max"
        v-html="$t('pwas.create.marketInfo', {pwaDomainCost})">
      </div>
    </template>
    <div>
      <div class="d-md-flex align-items-center mt-4 mobile-grid-2">
        <div class="mr-md-4 mb-2 mb-md-0">
          <button
            type="submit"
            class="ful_butt_green d_flex align_center justify_center mobile-full-w"
            :disabled="processing">
            <span v-if="!processing">{{ $t('general.save') }}</span>
            <hollow-dots-spinner
              v-if="processing"
              :animation-duration="1000"
              :dot-size="15"
              :dots-num="3"
              :color="'#ff1d5e'"
            />
          </button>
        </div>
        <div class="mr-md-4 mb-2 mb-md-0">
          <button @click="handlerCancel" class="simple_butt_afg mobile-full-w">
            {{ $t('general.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import domainMixins from '@/mixins/domain-mixin'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import {HollowDotsSpinner} from 'epic-spinners'
import Info from '@/components/Info.vue'
import Select2 from '@/components/Select2.vue'
import BaseRadioButton from '@/components/base/BaseRadioButton.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import DomainFormAddButton from './DomainFormAddButton.vue'
import DomainFormDeleteButton from './DomainFormDeleteButton.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import confirmMixin from '@/mixins/confirm-leave-mixin'

const modes = {
  self: 'self',
  bought: 'bought',
  marker: 'market'
}

const defaultValues = () => ({
  id: null,
  domain: '',
  key: '',
  email: '',
  savedCredentials: '',
  mode: 'self'
})

export default {
  name: 'DomainForm',
  mixins: [formMixin, domainMixins, select2AjaxMixin, confirmMixin],
  components: {
    BaseFromGroup,
    BaseSelect,
    BaseRadioButton,
    BaseInput,
    Info,
    HollowDotsSpinner,
    Select2,
    DomainFormAddButton,
    DomainFormDeleteButton
  },
  data () {
    return {
      modes,
      ...defaultValues(),
      showAdd: false,
      processing: false,
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    cloudflare () {
      return this.$store.getters['pwas/domainPage'].cloudflare
    },
    pwaDomainCost () {
      return this.$store.getters['pwas/domainPage'].pwaDomainCost
    },
    domains () {
      return this.$store.getters['pwas/domainPage'].domains
    },
    boughtDomains () {
      return this.$store.getters['pwas/domainPage'].boughtDomains
    },
    isModeSelf () {
      return this.mode === modes.self
    },
    isModeBought () {
      return this.mode === modes.bought
    },
    isModeMarker () {
      return this.mode === modes.marker
    },
    isCloudflare () {
      return this.cloudflare?.length > 0
    },
    cloudflareOptions () {
      return this.cloudflare.map(account => { return {id: (account.email + '###' + account.key), text: `${account.email}(${account.key})`} })
    }
  },
  watch: {
    mode () {
      this.domain = ''
      this.resetApiValidation()
    }
  },
  methods: {
    skip () {
      this.showAdd = false
      this.processing = false
      this.resetApiValidation()
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    async handlerCreate () {
      this.skip()
      if (this.isCloudflare) {
        this.showAdd = false
        this.savedCredentials = `${this.cloudflare[0].email}###${this.cloudflare[0].key}`
      } else this.showAdd = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerUpdate (id) {
      this.skip()
      this.id = id
      if (this.isCloudflare) {
        this.showAdd = false
        this.savedCredentials = `${this.cloudflare[0].email}###${this.cloudflare[0].key}`
      } else this.showAdd = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.skip()
      this.resolvePromise(false)
    },
    checkDomain () {
      const isValid = this.validateDomain(this.domain)
      if (!isValid) this.setApiValidation([{param: 'domain', msg: this.$t('validations.domain.wrongFormat')}])
    },
    async onHandlerSelfMode () {
      const isValid = this.validateDomain(this.domain)
      if (!isValid) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: {
              errors: [{
                param: 'domain',
                msg: this.$t('validations.domain.wrongFormat'),
                value: this.domain,
                location: 'body'
              }]
            }
          }
        }
      }
      const payload = {
        domain: this.sanitizerDomain(this.domain),
        email: this.email,
        key: this.key
      }
      if (this.savedCredentials !== '') {
        const [email, key] = this.savedCredentials.split('###')
        payload.email = email
        payload.key = key
      } else {
        if (this.showAdd === false) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: {
              data: {
                errors: [{
                  param: 'savedCredentials',
                  msg: this.$t('pwas.create.apiRequired'),
                  value: '',
                  location: 'body'
                }]
              }
            }
          }
        }
      }
      if (this.id != null) {
        await this.$store.dispatch('pwas/updateDomainSelf', {
          ...payload,
          id: this.id
        })
      } else await this.$store.dispatch('pwas/createSelf', payload)
    },
    async onHandleMarkerMode () {
      const payload = { domain: this.domain }
      if (this.id != null) {
        await this.$store.dispatch('pwas/updateDomainMarket', {
          ...payload,
          id: this.id
        })
      } else await this.$store.dispatch('pwas/createMarket', payload)
      await this.$store.dispatch('alerts/success', this.$t('pwas.create.buySuccess'))
    },
    async onHandlerBoughtMode () {
      const payload = { domain: this.domain }
      if (this.id != null) {
        await this.$store.dispatch('pwas/updateDomainBought', {
          ...payload,
          id: this.id
        })
      } else {
        await this.$store.dispatch('pwas/createBought', payload)
      }
    },
    async handlerSave () {
      try {
        this.processing = true
        this.resetApiValidation()
        if (this.isModeSelf) await this.onHandlerSelfMode()
        if (this.isModeMarker) await this.onHandleMarkerMode()
        if (this.isModeBought) await this.onHandlerBoughtMode()
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.changedData = false
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    handlerAdd () {
      this.resetApiValidation()
      this.showAdd = true
      this.savedCredentials = ''
      this.email = ''
      this.key = ''
    },
    handlerReturn () {
      this.resetApiValidation()
      this.showAdd = false
      this.savedCredentials = `${this.cloudflare[0].email}###${this.cloudflare[0].key}`
    },
    async handlerDelete () {
      if (confirm(this.$t('pwas.create.deleteCloudflare'))) {
        const [email, key] = this.savedCredentials.split('###')
        await this.$store.dispatch('pwas/deleteCloudflare', {email, key})
        await this.$store.dispatch('pwas/asyncDomainPage')
        if (this.isCloudflare) {
          this.showAdd = false
          this.savedCredentials = `${this.cloudflare[0].email}###${this.cloudflare[0].key}`
        } else {
          this.showAdd = true
        }
      }
    },
    onChange () {
      this.changedData = true
    }
  }
}
</script>
