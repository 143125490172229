import service from '@/store/services/admin/merchants-service'
import middleware from '../middleware'

const state = {
  merchants: [],
  merchant: {}
}

const mutations = {
  setMerchants: (state, payload) => {
    state.merchants = payload
  },
  setMerchant: (state, payload) => {
    state.merchant = payload
  }
}

const actions = {
  index: ({commit, dispatch}, params) => service.index(params)
    .then(res => {
      commit('setMerchants', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  create: ({commit, dispatch}, params) => service.create(params)
    .then(res => {
      commit('setMerchant', res)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  update: ({commit, dispatch}, params) => service.update(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  delete: ({commit, dispatch}, params) => service.delete(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  merchants: state => state.merchants,
  merchant: state => state.merchant
}

const adminMerchants = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminMerchants
