export default {
  notFound: 'Страница не найдена',
  analyticsGroups: 'Статистика по дням',
  analyticsUniques: 'Статистика по уникам',
  bills: 'Счета',
  transactions: 'Транзакции',
  pwa: {
    trash: 'Корзина PWA',
    trashBadge: 'Корзина пустая | В корзине одно PWA | В корзине {count} PWA | В корзине {count} PWA',
    app: 'Приложение PWA #{id}',
    comments: 'Комментарии PWA #{id}',
    create: 'Создание PWA',
    my: 'Мои PWA',
    setting: 'Настройки PWA #{id}',
    tracker: 'Трекер PWA #{id}',
    domain: 'Домен PWA #{id}',
    vertical: 'Вертикаль PWA #{id}',
    templates: 'Шаблоны PWA',
    analytics: 'Аналитика PWA #{id}'
  },
  referrals: 'Реф. программа',
  tariffs: 'Тарифы',
  teams: 'Командная работа',
  trackers: {
    my: 'Мои трекеры',
    create: 'Создание трекера',
    edit: 'Редактирование трекера #{id}'
  },
  pushes: {
    pushes: 'PUSH рассылка',
    create: 'Добавление рассылки',
    edit: 'Редактирование рассылки {id}'
  },
  bot: {
    keys: 'Ключи бота',
    offers: 'Офферы бота'
  },
  mirrors: {
    my: 'Мои Зеркала',
    trash: 'Корзина Зеркал',
    trashBadge: 'Корзина пустая | В корзине одно Зеркало | В корзине {count} Зеркал | В корзине {count} Зеркал'
  }
}
