export default {
  navigation: 'Domínio',
  title: 'Configuração de domínio do PWA #{id}',
  settingTitle: 'Para trabalhar com sistema o domínio deve estar associado ao CloudFlare e estar configurado de seguinte forma',
  settings: [
    'Indicar junto ao registador de NS de Cloudflare',
    'Indicar na configuração o deх domínio da А-anotação'
  ],
  change: 'Alterar',
  warningCloudflare: 'Para trabalhar com sistema o domínio deve estar associado ao <a href="https://www.cloudflare.com/" target="_blank" class="link_green">CloudFlare</a>. Caso a anotação de NS não visualizar-se-á ou o PWA está a funcionar de forma incorreta tente mais uma ativar o processo com botão',
  getNS: 'Receber NS',
  noCloudflare: 'Domínio não coberto de <a href="https://www.cloudflare.com/" target="_blank">CloudFlare</a>, sem este procedimento não é possível usar a aplicação',
  checkInfo: 'O domínio substituído sujeito a uma verificação',
  ns: {
    success: 'Pedido de receber anotações NS foi feito com sucesso',
    failed: 'Pedido de receber anotações NS falhou. Contacto o serviço de apoio'
  }
}
