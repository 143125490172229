import service from '@/store/services/pixels-service'
import middleware from './middleware'

const state = () => ({
  createPayload: {
    country: '',
    registrations: 50,
    deposits: 50,
    conversion: 'meta'
  },
  conversions: ['meta', 'api']
})

const mutations = {
}

const actions = {
  async create ({commit, dispatch}, payload) {
    try {
      await service.create(payload)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  createPayload: state => state.createPayload,
  listConversions: state => state.conversions.map(conversion => ({id: conversion, text: `pixels.conversions.${conversion}`}))
}

const pixels = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default pixels
