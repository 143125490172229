<template>
  <div class="content-wrapper">
    <bill-form ref="billForm"/>
    <div class="row grid-margin">
      <bills-statistics/>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Рахунки</h4>
            <p class="card-description">Керування рахунками</p>
            <div class="mb-2">
              <button
                :disabled="!filters.owner"
                @click="handlerCreate"
                type="button" class="btn btn-outline-primary btn-fw">
                <i class="icon-plus"></i> Створити рахунок
              </button>
            </div>
            <div class="row">
              <owner-filter v-model="filters.owner" class="col-lg-4 col-md-4 col-sm-6 mb-2"/>
              <div class="col-lg-4 col-md-4 col-sm-6 mb-2">
                <select class="form-control" v-model="filters.status">
                  <option value="" selected>Фильтр по статусу</option>
                  <option :key="id" v-for="(name, id) in statuses" :value="id">{{ name }}</option>
                </select>
              </div>
              <datepicker
                id="date_begin"
                v-model="filterDateBegin"
                container=".main-panel"
                inputClass="form-control"
                class="col-lg-4 col-md-4 col-sm-6 mb-2 in_to_pickerd"
                useEndDate
              />
              <admin-filter-metrics v-model="filters.metrics" class="col-lg-4 col-md-4 col-sm-6 mb-2"/>
              <div v-if="loading === false" class="col-lg-4 col-md-4 col-sm-6 mb-2">
                <select class="form-control" v-model="filters.method">
                  <option value="" selected>Фильтр по методу оплаты</option>
                  <option :key="item._id" v-for="(item) in paymentMethods"
                          :value="item._id">{{ item.name }}
                  </option>
                </select>
              </div>
              <datepicker
                id="date_end"
                v-model="filterDateEnd"
                container=".main-panel"
                inputClass="form-control"
                class="col-lg-4 col-md-4 col-sm-6 mb-2 in_to_pickerd"
                useEndDate
              />
              <div class="col-lg-4 col-md-4 col-sm-6 mb-2">
                <input v-model="filters.merchant_id" class="form-control" placeholder="Пошук за Хешем"/>
              </div>
            </div>
            <loading v-model="loading" class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>
                    <button
                      @click="fetchPage()"
                      class="btn btn-icons btn-inverse-light">
                      <i class="icon-refresh"></i>
                    </button>
                  </th>
                  <th>Статус</th>
                  <th>Сумма</th>
                  <th>Метод оплаты</th>
                  <th style="min-width: 220px;">Пользователь</th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                  <th>Истекает</th>
                  <th>Шлюз</th>
                  <th>Сумма в валюте</th>
                  <th>Под. для BTC</th>
                  <th>ID у нас</th>
                  <th>ID у кассы</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in models" :key="item.id">
                  <td>
                    <button class="btn btn-icons btn-inverse-success" @click="handlerApprove(item.id)"
                            v-if="[0, 1].includes(item.status)"><i
                      class="icon-check"></i>
                    </button>
                    <button class="btn btn-icons btn-inverse-danger" @click="handlerReject(item.id)"
                            v-if="[0, 1, 2].includes(item.status)"><i
                      class="icon-close"></i>
                    </button>
                  </td>
                  <td>{{ filterStatus(item.status) }}</td>
                  <td>{{ item.amount | amount }}</td>
                  <td><img :src="item.method.image" :alt="item.method.name"> {{ item.method.name }}</td>
                  <td>
                    <table-owner :data="item.owner"/>
                  </td>
                  <td>{{ item.createdAt | dateFromNow }}</td>
                  <td>{{ item.updatedAt | dateFromNow }}</td>
                  <td v-if="[0, 1].includes(item.status)">через {{ item.expired | dateAgo }}</td>
                  <td v-else></td>
                  <td>{{ filterGateway(item.gateway) }}</td>
                  <td>{{
                      (new Intl.NumberFormat('ru-RU', {style: 'currency', currency: item.rate})).format(item.total)
                    }}
                  </td>
                  <td>{{ item.rate === 'btc' ? `${item.confirmation}/6` : '' }}</td>
                  <td>{{ item.id }}</td>
                  <td v-html="merchantLink(item)"></td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td>{{ total | amount }}</td>
                </tr>
                </tfoot>
              </table>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import _ from 'lodash'
import Loading from '@/pages/admin/components/Loading.vue'
import {OwnerFilter} from '../components'
import * as adminComponents from '@/pages/admin/components'
import BillsStatistics from './components/BillsStatistics.vue'
import Datepicker from '@/components/Datepicker.vue'
import AdminFilterMetrics from '@/components/admin/filter/Metrics.vue'
import BillForm from '@/components/admin/billing/BillForm.vue'

export default {
  name: 'AdminBilling',
  components: {
    Loading,
    OwnerFilter,
    TableOwner: adminComponents.tables.Owner,
    BillsStatistics,
    Datepicker,
    AdminFilterMetrics,
    BillForm
  },
  data () {
    return {
      loading: true,
      limit: 20,
      page: 1,
      filters: {
        owner: '',
        status: '',
        merchant_id: '',
        method: '',
        // eslint-disable-next-line no-undef
        dateBegin: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        // eslint-disable-next-line no-undef
        dateEnd: moment().format('YYYY-MM-DD'),
        metrics: ''
      },
      statuses: {
        '0': 'Новый',
        '1': 'Оплачивается',
        '2': 'Оплачен',
        '-1': 'Просрочен',
        '-2': 'Отменён'
      }
    }
  },
  computed: {
    ...mapGetters({
      models: 'adminBilling/models',
      count: 'adminBilling/count',
      total: 'adminBilling/total',
      paymentMethods: 'adminPaymentMethods/paymentMethods',
      gateways: 'adminGateways/list'
    }),
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    filter () {
      let filter = {}
      for (const index in this.filters) {
        if (this.filters[index] !== '') {
          filter[index] = this.filters[index]
        }
      }
      return filter
    },
    filterDateBegin: {
      get () {
        return this.filters.dateBegin
      },
      set (input) {
        // eslint-disable-next-line no-undef
        this.filters.dateBegin = moment(input).format('YYYY-MM-DD')
      }
    },
    filterDateEnd: {
      get () {
        return this.filters.dateEnd
      },
      set (input) {
        // eslint-disable-next-line no-undef
        this.filters.dateEnd = moment(input).format('YYYY-MM-DD')
      }
    }
  },
  watch: {
    'filters.merchant_id' (newVal) {
      if (/^(http|https):\/\/[^ "]+$/.test(newVal)) {
        this.filters.merchant_id = newVal.substring(
          newVal.lastIndexOf('/') + 1
        )
      } else {
        this.filters.merchant_id = newVal
      }
    }
  },
  async created () {
    for (const prop in this.filters) {
      this.$watch(`filters.${prop}`, function () {
        this.handlerPagination()
      })
    }
    this.handlerPagination()
  },
  methods: {
    ...mapActions({
      alertSuccess: 'alerts/success',
      alertError: 'alerts/error',
      fetchData: 'adminBilling/fetchData',
      fetchPaymentMethods: 'adminPaymentMethods/index',
      approve: 'adminBilling/approve',
      reject: 'adminBilling/reject'
    }),
    debounceFetchData: _.debounce(async function (options) {
      await this.fetchData(options)
      await this.fetchPaymentMethods()
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        limit: this.limit,
        page: this.page,
        sort: '-updatedAt',
        filter: this.filter
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    filterStatus (value) {
      return this.statuses[value.toString()]
    },
    filterGateway (gateway) {
      const index = this.gateways.findIndex(value => value._id === gateway)
      return index === -1 ? 'Старая' : this.gateways[index].name
    },
    async handlerApprove (_id) {
      if (confirm('Одобрить счет?')) {
        try {
          await this.approve({_id})
          await this.alertSuccess('Счет одобрен')
          this.handlerPagination()
        } catch (e) {
          await this.alertError(e.message)
        }
      }
    },
    async handlerReject (_id) {
      if (confirm('Отклонить счет?')) {
        try {
          await this.reject({_id})
          await this.alertSuccess('Счет отклонён')
          this.handlerPagination()
        } catch (e) {
          await this.alertError(e.message)
        }
      }
    },
    merchantLink (bill) {
      if (bill.rate === 'usd' && bill.merchant_id) {
        return `<a href="https://tronscan.org/#/transaction/${bill.merchant_id}" target="_blank">Подробности</a>`
      }
      return bill.merchant_id
    },
    async handlerCreate () {
      const ok = await this.$refs.billForm.handlerOpen(this.filters.owner)
      if (ok) {
        this.handlerPagination()
      }
    }
  }
}
</script>
