<template>
  <div class="modal fade" id="voucher-modal">
    <div class="modal-dialog mt-0">
      <form class="modal-content" @submit.prevent="handler">
        <div class="modal-header">
          <h5 class="modal-title">{{ id != null ? id : 'Создания ваучера' }}</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group col">
              <div class="form-check form-check-primary">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" v-model="fields.enable">
                  {{ fields.enable ? 'Вкл.' : 'Выкл.' }}
                  <i class="input-helper"></i></label>
              </div>
            </div>
            <div class="form-group col">
              <div class="form-check form-check-primary">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" v-model="fields.firstBuy">
                  {{ fields.firstBuy ? 'Первая' : 'Любая' }} покупка
                  <i class="input-helper"></i></label>
              </div>
            </div>
          </div>
          <div class="form-group" :class="apiValidationErrors._id ? 'has-danger' : ''" v-if="id === null">
            <label for="percent">Код ваучера</label>
            <div class="input-group">
              <input type="text" id="_id" placeholder="SupperSale" v-model="fields._id"
                     class="form-control"
                     :class="apiValidationErrors._id ? 'form-control-danger' : ''"
                     @focus="clearError('_id')">
            </div>
            <small class="form-text text-muted">Укажите код ваучера, желательно литиницей и без пробелов</small>
            <label v-if="apiValidationErrors._id" class="error mt-2 text-danger" for="percent">
              {{ apiValidationErrors._id[0] }}
            </label>
          </div>
          <div class="form-group" :class="apiValidationErrors.expires ? 'has-danger' : ''">
            <label for="expires">Окончания действия</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">2021-30-09</span>
              </div>
              <input type="text" id="expires" placeholder="30" v-model="fields.expires"
                     class="form-control" :class="apiValidationErrors.expires ? 'form-control-danger' : ''"
                     @focus="clearError('expires')">
            </div>
            <small class="form-text text-muted">День, включительно, работы ваучера</small>
            <label v-if="apiValidationErrors.expires" class="error mt-2 text-danger" for="expires">
              {{ apiValidationErrors.expires[0] }}
            </label>
          </div>
          <div class="form-group" :class="apiValidationErrors.amount ? 'has-danger' : ''">
            <label for="amount">Сумма скидки</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <div class="input-group-prepend">
                <span class="input-group-text">0.00</span>
              </div>
              <input type="number" step="0.01" id="amount" placeholder="15" v-model="fields.amount"
                     class="form-control" min="0" max="10000"
                     :class="apiValidationErrors.amount ? 'form-control-danger' : ''"
                     @focus="clearError('cost')">
            </div>
            <small class="form-text text-muted">Фиксированая сумма скидки при покупке</small>
            <label v-if="apiValidationErrors.amount" class="error mt-2 text-danger" for="amount">
              {{ apiValidationErrors.cost[0] }}
            </label>
          </div>
          <div class="form-group" :class="apiValidationErrors.percent ? 'has-danger' : ''">
            <label for="percent">% от суммы</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">%</span>
              </div>
              <div class="input-group-prepend">
                <span class="input-group-text">0</span>
              </div>
              <input type="number" step="1" id="percent" placeholder="5" v-model="fields.percent"
                     class="form-control" min="0" max="100"
                     :class="apiValidationErrors.pwas ? 'form-control-danger' : ''"
                     @focus="clearError('percent')">
            </div>
            <small class="form-text text-muted">Процент скидки на сумму покупки</small>
            <label v-if="apiValidationErrors.percent" class="error mt-2 text-danger" for="percent">
              {{ apiValidationErrors.percent[0] }}
            </label>
          </div>
          <div class="form-group" :class="apiValidationErrors.limit ? 'has-danger' : ''">
            <label for="limit">Лимиты</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Шт.</span>
              </div>
              <div class="input-group-prepend">
                <span class="input-group-text">0</span>
              </div>
              <input type="number" step="1" id="limit" placeholder="5" v-model="fields.limit"
                     class="form-control" min="1"
                     :class="apiValidationErrors.limit ? 'form-control-danger' : ''"
                     @focus="clearError('limit')">
            </div>
            <small class="form-text text-muted">Кл. активаций</small>
            <label v-if="apiValidationErrors.limit" class="error mt-2 text-danger" for="limit">
              {{ apiValidationErrors.limit[0] }}
            </label>
          </div>
          <div class="form-group" :class="apiValidationErrors.tariffs ? 'has-danger' : ''">
            <label for="tariffs">Тарифы</label>
            <select class="form-control" id="tariffs" v-model="fields.tariffs">
              <option v-bind:value="tariff.id" v-for="tariff of $store.getters['adminVouchers/tariffs']"
                      v-bind:key="tariff.id">{{ tariff.text }}
              </option>
            </select>
            <small class="form-text text-muted">Если оставить поле пустым будет применяться ко всем тарифам</small>
            <label v-if="apiValidationErrors.tariffs" class="error mt-2 text-danger" for="tariffs">
              {{ apiValidationErrors.tariffs[0] }}
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success">Сохранить</button>
          <a href="javascript:void(0);" class="btn btn-light" data-dismiss="modal">Отмена</a>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'

export default {
  name: 'EditForm',
  mixins: [formMixin],
  data: () => ({fields: {}, id: null}),
  methods: {
    getDefaultFields: () => ({
      enable: false,
      firstBuy: false,
      limit: 1,
      expires: new Date(),
      amount: 0,
      percent: 0,
      tariffs: [],
      _id: ''
    }),
    handler: async function () {
      try {
        this.resetApiValidation()
        if (this.id) {
          await this.$store.dispatch('adminVouchers/update', {
            id: this.id,
            ...this.fields
          })
          await this.$store.dispatch(
            'alerts/success',
            'Ваучер успешно обновлён'
          )
        } else {
          await this.$store.dispatch('adminVouchers/create', this.fields)
          await this.$store.dispatch(
            'alerts/success',
            'Ваучер успешно создан'
          )
        }
        this.resetApiValidation()
        await this.$parent.asyncData()
        // eslint-disable-next-line no-undef
        jQuery('#voucher-modal').modal('hide')
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    open: async function (id = null) {
      this.id = id
      this.fields = this.getDefaultFields()
      if (this.id != null) {
        await this.$store.dispatch('adminVouchers/view', id)
        this.fields = this.$store.getters['adminVouchers/voucher']
      }
      const self = this
      // eslint-disable-next-line no-undef
      jQuery('#voucher-modal').modal('show')
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        const $expires = jQuery('#expires')
        $expires.datepicker('destroy')
        // eslint-disable-next-line no-undef
        $expires.datepicker({
          maxViewMode: 0,
          format: 'yyyy-mm-dd',
          autoclose: true,
          container: '.main-panel',
          ignoreReadonly: true,
          allowInputToggle: true
        })
          // eslint-disable-next-line no-undef
          .datepicker('setDate', moment(this.fields.expires).format('YYYY-MM-DD'))
          .on('changeDate', function () {
            // eslint-disable-next-line no-undef
            self.fields.expires = $expires.datepicker('getFormattedDate')
          })
        const select2Options = {
          placeholder: 'Выбирите или создайте группу',
          multiple: true,
          escapeMarkup: function (m) {
            return m
          }
        }
        // eslint-disable-next-line no-undef
        const $tariffs = jQuery('#tariffs')
        if ($tariffs.hasClass('select2-hidden-accessible')) {
          $tariffs.select2('destroy')
        }
        $tariffs.select2(select2Options)
          .on('select2:select', e => {
            self.fields.tariffs.push(e.params.data.id)
          })
          .on('select2:unselect', e => {
            const index = self.fields.tariffs.findIndex(value => value === e.params.data.id)
            if (index !== -1) {
              self.fields.tariffs.splice(index, 1)
            }
          })
      }, 500)
    }
  }
}
</script>
