export default {
  analyticsGroups: 'Groups',
  analyticsUniques: 'Uniques',
  columns: 'Columns',
  filters: 'Filters',
  filtersSettingTitle: 'Set Filters',
  columnsSettingTitle: 'Set Columns',
  date: 'Date',
  clicks: {
    title: 'Clicks',
    installs: 'Installs',
    pushes: 'Push Subscriptions',
    opens: 'PWA Logins',
    reopens: 'Reopens'
  },
  postbacks: {
    title: 'Postbacks',
    registrations: 'Registration',
    deposits: 'Deposits'
  },
  users: {
    title: 'User',
    ip: 'IP',
    country: 'Countries',
    userAgentDeviceType: 'Device Type',
    userAgentDeviceVendor: 'Device Brand',
    userAgentDeviceModel: 'Device Model',
    userAgentOs: 'OS',
    userAgentBrowserName: 'Browser'
  },
  trackers: {
    title: 'Tracker',
    tracker: 'Tracker',
    referer: 'Referral',
    redirect: 'Redirect',
    tag: 'Tag',
    host: 'Domain',
    vertical: 'Vertical'
  },
  params: {
    title: 'Settings',
    param: 'Parameter'
  },
  filtersLabel: {
    installs: 'Filter by installs',
    pushes: 'Filter by subscriptions',
    opens: 'Filter by openings',
    reopens: 'Filter by reopens',
    registrations: 'Filter by registrations',
    deposits: 'Filter by deposits',
    ip: 'Filter by IP',
    id: 'Filter by ID',
    country: 'Filter by country',
    userAgentDeviceType: 'Filter by device type',
    userAgentDeviceVendor: 'Filter by device brand',
    userAgentDeviceModel: 'Filter by device model',
    userAgentOs: 'Filter by OS',
    userAgentBrowserName: 'Filter by Browser',
    tracker: 'Filter by tracker',
    referer: 'Filter by referral',
    redirect: 'Filter by redirect',
    pwa: 'Filter by PWA',
    tag: 'Filter by tag',
    host: 'Filter by domain',
    vertical: 'Vertical filter',
    verticals: ['Nutra', 'Betting', 'Dating', 'Gambling', 'Trading', 'Crypto'],
    params: 'Filter by {param}',
    date: 'Filter by date',
    today: 'Today',
    yesterday: 'Yesterday',
    prevSevenDay: 'Previous week',
    prevMonth: 'Previous month',
    prev3Month: 'Previous 3 months',
    thisYear: 'This year',
    preLandingPage: 'Pre-landing',
    postLandingPage: 'Post-landing',
    preLandingPageUnique: 'Unique pre-landing',
    postLandingPageUnique: 'Unique post-landing'
  },
  columnsLabels: {
    cpi: 'CPI',
    analyticsBy: 'Analytics by',
    uniqueLabel: 'User Data',
    actionsLabel: 'Actions',
    postbackLabel: 'Postback info',
    pwaLabel: 'Tracking',
    calculateLabel: 'Calculations',
    uniques: 'Unique',
    hits: 'Clicks',
    installs: 'Installs',
    pushes: 'PUSH subscriptions',
    registrations: 'Registrations',
    deposits: 'Deposits',
    opens: 'Opens',
    reopens: 'Reopens',
    country: 'Countries',
    createdAt: 'Created',
    uniquesToInstalls: 'CR% unique/install',
    uniquesToPush: 'CR% unique/push',
    installsToPush: 'CR% install/push',
    date: 'Date',
    tracker: 'Tracker',
    host: 'Domain',
    referer: 'Referral link',
    vertical: 'Vertical',
    redirect: 'Redirect',
    userAgentDeviceType: 'Device Type',
    userAgentDeviceVendor: 'Device Brand',
    userAgentDeviceModel: 'Device Model',
    userAgentBrowserName: 'Browser',
    userAgentOs: 'OS',
    uniquesTitle: 'Unique Transitions',
    hitsTitle: 'Non-unique transitions',
    installsTitle: 'Number of PWA installations',
    pushesTitle: 'Push subscriptions',
    registrationsTitle: 'Number of registrations',
    depositsTitle: 'Number of deposits',
    opensTitle: 'Number of PWA logins',
    reopensTitle: 'Number of PWA re-entries',
    createdAtTitle: 'Date of first click',
    uniquesToInstallsTitle: 'Unique/install percentage Calculated using the formula CR = Install/Unique*100',
    uniquesToPushTitle: 'Percentage of uniques/push. Calculated using the formula CR = Push/Unique*100',
    installsToPushTitle: 'Percentage install/push. Calculated using the formula CR = Push/Instal*100',
    landingsLabel: 'Landing',
    preLandingPage: 'Pre-landing',
    postLandingPage: 'Post-landing',
    preLandingPageUnique: 'Unique pre-landing',
    postLandingPageUnique: 'Unique post-landing'
  },
  groupsLabels: {
    date: 'Group By Date',
    tag: 'Group by Tag',
    host: 'Group by Domain',
    country: 'Group by Country',
    pwa: 'Group by PWA',
    vertical: 'Group by Vertical',
    userAgentDeviceType: 'Group by Device',
    userAgentDeviceVendor: 'Group By Brand',
    userAgentDeviceModel: 'Group By Model',
    userAgentBrowserName: 'Group by Browser',
    userAgentOs: 'Group by OS',
    param: 'Group by "{param}"',
    by: 'By',
    dateTitle: 'Date Analytics',
    trackerTitle: 'Tracker Analytics',
    hostTitle: 'Domain Analytics',
    tagTitle: 'Tag Analytics',
    countryTitle: 'Country Analytics',
    pwaTitle: 'PWA Analytics',
    verticalTitle: 'Vertical Analytics',
    userAgentDeviceTypeTitle: 'Device Analytics',
    userAgentDeviceVendorTitle: 'Brand Analytics',
    userAgentDeviceModelTitle: 'Analytics by Model',
    userAgentBrowserNameTitle: 'Browser Analytics',
    userAgentOsTitle: 'OS Analytics',
    preLandingPageTitle: 'Pre-landing analytics',
    postLandingPageTitle: 'Post-landing analytics',
    dateLabel: 'Date',
    trackerLabel: 'Tracker',
    hostLabel: 'Domain',
    countryLabel: 'Country',
    pwaLabel: 'PWA',
    verticalLabel: 'Vertical',
    userAgentDeviceTypeLabel: 'Device',
    userAgentDeviceVendorLabel: 'Brand',
    userAgentDeviceModelLabel: 'Model',
    userAgentBrowserNameLabel: 'Browser',
    userAgentOsLabel: 'OS',
    landingsLabel: 'Landings',
    preLandingPage: 'Group by Pre-landing',
    postLandingPage: 'Group by Post-landing'
  },
  report: {
    button: 'Report',
    title: 'Generate a report',
    body: {
      open: 'To the existing analytics, we will add the costs of buying domains in our system and the profit from Postback. The report will be available here in CSV format with UTF-8 encoding',
      successful: `Your report is available at <a download="report.csv" class="link_green" href="{csv}" target="_blank">the link</a>`
    },
    hint: {
      open: 'To start generating the report, click the "Run" button',
      loading: 'The report is being generated...',
      successful: 'Generation is guaranteed',
      error: 'An error occurred while creating a report'
    }
  },
  landings: {
    title: 'Landings',
    preLandingPage: 'Pre-landing',
    postLandingPage: 'Post-landing',
    preLandingPageUnique: 'Unique pre-landing',
    postLandingPageUnique: 'Unique post-landing'
  },
  empty: 'Statistics are not available or are not currently generated',
  pwaRemoved: 'Removed PWA'
}
