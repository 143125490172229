<template>
  <div>
    <div class="dashboard-error" v-if="isError403">
      <div class="dashboard-error__text">
        <InlineSvg class="button-refresh-icon" src="/images/svg-icons/icon-lock.svg"/>
        <p>{{ error403 }}</p>
      </div>
    </div>
    <div class="dashboard-error" v-else-if="isError504">
      <div class="dashboard-error__text">
        <InlineSvg class="button-refresh-icon" src="/images/svg-icons/icon-warning-banner.svg"/>
        <p>{{ error504 }}</p>
      </div>
    </div>
    <div class="dashboard-error" v-else>
      <div class="dashboard-error__text">
        <InlineSvg class="button-refresh-icon" src="/images/svg-icons/icon-warning-banner.svg"/>
        <p>{{ errorMessage || defaultError }}</p>
      </div>
      <button class="d_flex align_center justify_center" @click="$emit('reload')">
        <InlineSvg class="button-refresh-icon" src="/images/svg-icons/refresh-icon.svg"/>
        {{ $t('general.refresh') }}
      </button>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
  components: {
    InlineSvg
  },
  props: {
    error: {
      type: Number
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      error403: this.$t('validations.dashboardAccessDenied'),
      error504: this.$t('validations.requestError504'),
      defaultError: this.$t('validations.requestError')
    }
  },
  computed: {
    isError403 () {
      return this.error === 403
    },
    isError504 () {
      return this.error === 504
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/styles/_variables.sass'
.dashboard-error
  margin: 0 auto
  color: $yellow
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  flex-wrap: wrap
  gap: 24px
  margin-top: 34px
  font-size: 14px

  &__text
    display: flex
    align-items: center
    gap: 12px
    color: $yellow

    svg
      width: 50px
  button
    background-color: transparent
    border: 1px solid $yellow
    color: $yellow
    padding: 5px 10px
    border-radius: 3px
</style>
