<template>
  <div
    v-if="isLoaded"
    class="BaseAccordion base-accordion item_quest_ths_s"
  >
    <div class="head_click_wueshs_g transition d_flex_center">
      <slot name="title" />
      <svg
        class="icon_arr_fq transition"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.76575 12.8284L8.76575 2.85938C8.76575 2.71338 8.71891 2.59354 8.62525 2.49988C8.53158 2.40621 8.41175 2.35938 8.26575 2.35938C8.11975 2.35938 7.99991 2.40621 7.90625 2.49988C7.81258 2.59354 7.76575 2.71338 7.76575 2.85938L7.76575 12.8284L4.12525 8.95337C4.02091 8.84904 3.90108 8.79688 3.76575 8.79688C3.63041 8.79688 3.51325 8.85154 3.41425 8.96087C3.31525 9.07021 3.26575 9.19254 3.26575 9.32788C3.26575 9.46321 3.31258 9.58821 3.40625 9.70288L7.90625 14.4844C8.01058 14.5887 8.13041 14.6409 8.26575 14.6409C8.40108 14.6409 8.52091 14.5887 8.62525 14.4844L13.1252 9.70288C13.2189 9.58821 13.2657 9.46321 13.2657 9.32788C13.2657 9.19254 13.2162 9.07021 13.1172 8.96088C13.0182 8.85154 12.9011 8.79688 12.7657 8.79688C12.6304 8.79688 12.5106 8.84904 12.4062 8.95338L8.76575 12.8284Z" fill="white"/>
      </svg>
    </div>
    <div class="content_dropdown_gf">
      <div class="base-accordion__content in_to_Contr_ishg style_cont_ggg transition">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BaseAccordion',
  props: {
    delay: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isLoaded: false
    }
  },

  mounted () {
    this.isLoaded = true
  },
  updated () {
    this.initAccordion()
  },
  methods: {
    initAccordion () {
      const { delay } = this
      // eslint-disable-next-line no-undef
      $('.head_click_wueshs_g').off('click').on('click', function () {
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          $(this).toggleClass('active')
          // eslint-disable-next-line no-undef
          $(this).next('.content_dropdown_gf').slideToggle()
        }, delay)
      })
    }
  }
}
</script>

<style scoped>
.base-accordion {
  position: relative;
}

.item_quest_ths_s:not(:last-child) {
  border-bottom: 1px solid #3F4347;
}
.head_click_wueshs_g {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  position: relative;
  cursor: pointer;
  padding: 19px 20px;
  padding-right: 60px;
}
.head_click_wueshs_g.active {
  background: #272B2F;
}
.head_click_wueshs_g.active + .content_dropdown_gf .in_to_Contr_ishg {
  background: #25292D;
}
.d_flex_center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.head_click_wueshs_g.active .icon_arr_fq {
  transform: rotate(180deg);
}
.head_click_wueshs_g.active .icon_arr_fq path {
  fill: #21BF73;
}
.content_dropdown_gf {
  display: none;
}
.in_to_Contr_ishg {
  padding: 30px 30px;
  overflow: hidden;
}
.table,
.in_to_Contr_ishg p {
  margin-bottom: 20px;
}
.style_cont_ggg {
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #FFFFFF;
}
.style_cont_ggg h2,h3,h4,h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  margin-bottom: 20px;
  /*margin-top: 30px;*/
}
.style_cont_ggg b {
  font-weight: 400;
}
.style_cont_ggg img {
  max-width: 100%;
  height: auto !important;
  border: 1px solid #3F4347;
  border-radius: 5px;
}
.icon_arr_fq {
  position: absolute;
  right: 20px;
}
@media(min-width: 1200px) {
  .head_click_wueshs_g:hover {
    color: #21BF73;
  }
}
@media only screen and (max-width: 1199px) {
  .header_faq_sjg > div {
    display: block;
  }
}
@media only screen and (max-width: 479px) {
  .head_click_wueshs_g {
    padding-left: 0;
    font-size: 16px;
  }
  .icon_arr_fq {
    right: 0;
  }
  .style_cont_ggg h2, h3, h4, h5 {
    font-size: 16px;
  }
  .style_cont_ggg {
    font-size: 13px;
  }
  .head_click_wueshs_g.active,
  .head_click_wueshs_g.active + .content_dropdown_gf .in_to_Contr_ishg {
    background: transparent;
  }
  .in_to_Contr_ishg {
    padding: 20px 0;
    padding-top: 10px;
  }
}
</style>
