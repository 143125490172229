import service from '@/store/services/interval-push-service'
import middleware from './middleware'

const state = () => ({
  count: 0,
  models: [],
  statuses: [
    {id: 'suspended', label: 'pushes.statuses.suspended', class: 'info'},
    {id: 'launched', label: 'pushes.statuses.launched', class: 'primary'}
  ],
  cronLocales: {
    en: {
      every: 'Every',
      mminutes: 'minute(s)',
      hoursOnMinute: 'hour(s) on minute',
      daysAt: 'day(s) at',
      at: 'at',
      onThe: 'On the',
      dayOfEvery: 'day, of every',
      monthsAt: 'month(s), at',
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun',
      minutes: 'Minutes',
      hourly: 'Hourly',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      advanced: 'ADVANCED'
    },
    uk: {
      every: 'Кожен(кожні)',
      daily: 'Щодня',
      weekly: 'Щотижня',
      monthly: 'Щомісяця',
      daysAt: 'день о',
      at: 'в',
      onThe: 'На',
      dayOfEvery: 'день кожен',
      monthsAt: 'місяць о',
      mon: 'Пн',
      tue: 'Вт',
      wed: 'Ср',
      thu: 'Чт',
      fri: 'Пт',
      sat: 'Сб',
      sun: 'Нд'
    },
    ru: {
      every: 'Каждый(каждые)',
      daily: 'Ежедневно',
      weekly: 'Еженедельно',
      monthly: 'Ежемесячно',
      daysAt: 'день в',
      at: 'в',
      onThe: 'На',
      dayOfEvery: 'день каждый',
      monthsAt: 'месяц в',
      mon: 'Пн',
      tue: 'Вт',
      wed: 'Ср',
      thu: 'Чт',
      fri: 'Пт',
      sat: 'Сб',
      sun: 'Вс'
    },
    pt: {
      every: 'A cada',
      mminutes: 'minuto(s)',
      hoursOnMinute: 'horas(s) deste minuto',
      daysAt: 'dias(s) às',
      at: 'às',
      onThe: 'No ',
      dayOfEvery: 'dia, de todos',
      monthsAt: 'mês(es), às',
      mon: 'Seg',
      tue: 'Ter',
      wed: 'Qua',
      thu: 'Qui',
      fri: 'Sex',
      sat: 'Sab',
      sun: 'Dom',
      hasToBeBetween: 'Deve ser entre',
      and: 'e',
      minutes: 'MINUTOS',
      hourly: 'HORAS',
      daily: 'DIÁRIO',
      weekly: 'SEMANAL',
      monthly: 'MENSAL',
      advanced: 'AVANÇADO',
      cronExpression: 'Expressão cron:'
    }
  }
})

const mutations = {
  setData (state, { models, count }) {
    state.models = models
    state.count = count
  },
  socket_PWAPushCompanyUpdate (state, {company, ...payload}) {
    const index = state.models.findIndex(input => input.id === company)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        if (prop === 'status') {
          const statusIndex = state.statuses.findIndex(input => input.id === value)
          state.models[index][prop] = state.statuses[statusIndex]
        } else {
          state.models[index][prop] = value
        }
      }
    }
  },
  socket_PWAPushCompanyInc (state, {company, ...payload}) {
    const index = state.models.findIndex(input => input.id === company)
    if (index !== -1) {
      for (const [prop, value] of Object.entries(payload)) {
        if (prop === 'status') {
          const statusIndex = state.statuses.findIndex(input => input.id === value)
          state.models[index][prop] = state.statuses[statusIndex]
        } else {
          state.models[index][prop] += value
        }
      }
    }
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create ({commit, dispatch}, params) {
    return service.create(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => {
    const models = []
    for (const model of state.models) {
      const index = state.statuses.findIndex(value => value.id === model.status)
      models.push({
        ...model,
        status: state.statuses[index]
      })
    }
    return models
  },
  count: state => state.count,
  statuses: state => state.statuses,
  cronLocales: state => state.cronLocales
}

const adminDomains = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminDomains
