<template>
  <div v-if="isAuthenticated" class="container-fluid page-body-wrapper DashboardLayout dashboard-layout">
    <div class="main-panel dashboard-layout__main-panel">
      <router-view name="header"></router-view>
      <div class="dashboard-layout__container">
        <HeaderAlert />
        <router-view/>
      </div>
      <div class="telegram_sjg_go">
        <div class="link_item_nr_g">
          <div class="d_flex align_center">
            <a href="https://t.me/pwa_group" target="_blank" class="inline_ajhg_styl">{{ $t('dashboardLayout.chanel') }}</a>
          </div>
        </div>
        <div class="link_item_nr_g2">
          <div class="d_flex align_center">
            <a :href="`https://t.me/${tgHelpdesk}`" target="_blank" class="inline_ajhg_styl">{{ $t('dashboardLayout.support') }}</a>
          </div>
        </div>
        <div class="circl_teleg_g d_flex align_center justify_center" data-toggle-telegram>
          <div class="in_to_telf_g d_flex align_center justify_center">
            <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.0785 10.4463C3.68723 9.02074 6.59926 7.83087 9.32013 6.63494C14.0011 4.67611 18.7006 2.75123 23.4476 0.959186C24.3712 0.653858 26.0306 0.355301 26.1934 1.71316C26.1042 3.63528 25.7377 5.54614 25.4863 7.457C24.8482 11.6592 24.1106 15.8469 23.3914 20.0353C23.1436 21.4304 21.382 22.1526 20.2549 21.2598C17.5461 19.4445 14.8165 17.6469 12.1423 15.7895C11.2663 14.9065 12.0787 13.6383 12.861 13.0076C15.092 10.8263 17.4581 8.97301 19.5725 6.67895C20.1429 5.31253 18.4576 6.4641 17.9017 6.81698C14.8474 8.90515 11.8679 11.1208 8.64771 12.956C7.00285 13.8543 5.08573 13.0867 3.4416 12.5854C1.96747 11.9799 -0.192727 11.3698 1.07835 10.4465L1.0785 10.4463Z"
                fill="white"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <ConfirmLeavePageModal ref="confirmLeavePageModal"/>
    <telegram-modal />
  </div>
</template>

<script>
import TelegramModal from '@/components/modal/Telegram.vue'
import HeaderAlert from '@/components/banners/HeaderAlert.vue'
import ConfirmLeavePageModal from '@/components/modal/ConfirmLeavePageModal.vue'
import {mapActions} from 'vuex'
import HeaderMessages from '@/components/banners/HeaderMessages.vue'

export default {
  name: 'DashboardLayout',
  components: { TelegramModal, HeaderAlert, ConfirmLeavePageModal, HeaderMessages },
  computed: {
    tgHelpdesk () {
      return import.meta.env.VITE_TELEGRAM_HELPDESK
    },
    isAuthenticated () {
      return this.$store.state.auth.isAuthenticated
    }
  },
  async created () {
    await this.getUserData()
  },
  methods: {
    ...mapActions('user', ['getUserData']),
    telegramToggle: function () {
      (function ($) {
        'use strict'
        $(function () {
          $('[data-toggle-telegram]').on('click', function () {
            $('.telegram_sjg_go').toggleClass('active')
          })
        })
        // eslint-disable-next-line no-undef
      })(jQuery)
    }
  },
  mounted () {
    this.telegramToggle()
    this.$store.dispatch('autoSave/init', this.$refs.confirmLeavePageModal)
  }
}
</script>
<style scoped lang="sass">
.dashboard-layout
  overflow: hidden
  &__main-panel
    display: flex
    flex-direction: row
    height: 100vh
    position: relative
    left: 0
    top: 0
  &__container
    width: 100%
    background-color: #121214
    height: 100%
    overflow-y: scroll
    overflow-x: hidden
    @media screen and (min-width: 992px)
      padding-top: 20px
      padding-left: 20px
      width: calc(100% - 240px)
    .content-wrapper
      padding: 0
      width: 100%
      max-width: 1140px
      margin-left: 0
</style>
