export default {
  form: {
    title: 'Передача подій',
    subtitle: 'Події для відстуку',
    info: 'Максимальна попередня кількість подій для відправки - 50 на кожну подію',
    hint: 'Відстук почнеться миттєво, але події будуть відстуковуватися протягом 6-24 годин, іноді можливо триватиме довше',
    country: {
      placeholder: 'Виберіть країну',
      title: 'Гео',
      errors: {
        required: 'Необхідно вибрати країну для відстуку'
      }
    },
    accessToken: {
      errors: {
        required: `У пікселя <strong>{pixel}</strong> відсутній Access Token. Налаштуйте всі пікселі перед використанням API Conversions`
      }
    },
    conversion: {
      title: 'Тип конверсії'
    },
    registrations: {
      title: 'Реєстрація'
    },
    deposits: {
      title: 'Депозит'
    },
    success: {
      create: 'Події для відстуку успішно поставлені в чергу'
    }
  },
  conversions: {
    meta: 'Meta pixel',
    api: 'API Conversion'
  }
}
