import service from '@/store/services/admin/statistics-service'
import middleware from '../middleware'

const state = {
  dates: {
    data: [],
    count: 0
  },
  uniques: {
    data: [],
    count: 0
  },
  countries: {
    data: [],
    count: 0
  },
  owners: {
    data: [],
    count: 0
  },
  hosts: {
    data: [],
    count: 0
  }
}

const mutations = {
  setIndex: (state, payload) => {
    state.dates = {
      data: payload.data,
      count: payload.count
    }
  },
  setUniques: (state, payload) => {
    state.uniques = {
      data: payload.data,
      count: payload.count
    }
  },
  setCountries: (state, payload) => {
    state.countries = {
      data: payload.data,
      count: payload.count
    }
  },
  setOwners: (state, payload) => {
    state.owners = {
      data: payload.data,
      count: payload.count
    }
  },
  setHosts: (state, payload) => {
    state.hosts = {
      data: payload.data,
      count: payload.count
    }
  }
}

const actions = {
  index: ({commit, dispatch}, params) => service.index(params)
    .then(response => {
      commit('setIndex', response)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  uniques: ({commit, dispatch}, params) => service.uniques(params)
    .then(response => {
      commit('setUniques', response)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  countries: ({commit, dispatch}, params) => service.countries(params)
    .then(response => {
      commit('setCountries', response)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  owners: ({commit, dispatch}, params) => service.owners(params)
    .then(response => {
      commit('setOwners', response)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),
  hosts: ({commit, dispatch}, params) => service.hosts(params)
    .then(response => {
      commit('setHosts', response)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  dates: state => state.dates,
  uniques: state => state.uniques,
  countries: state => state.countries,
  owners: state => state.owners,
  hosts: state => state.hosts
}

const adminStatistics = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminStatistics
