<template>
  <div class="item_silter_sjhf_g this_is_selector_count">
    <BaseSelect
      v-model="tags"
      id="tags"
      :multiple="true"
      :taggable="true"
      placeholder="По тегам"
      :options="tags"
      :create-option="option => option"
    />
  </div>
</template>
<script>
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  name: 'Tags',
  components: {
    BaseSelect
  },
  props: {
    value: Array
  },
  data () {
    return {
      tags: this.value
    }
  },
  watch: {
    value: function () {
      this.tags = this.value
    }
  },
  methods: {
    addTag (newTag) {
      this.tags.push(newTag)
    },
    removeTag (removedOption) {
      this.$emit('input', this.tags.filter(f => f !== removedOption))
    }
  }
}
</script>
