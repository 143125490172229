<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Уники</h4>
            <p class="card-description">Поиск по полю находится под заголовком</p>
            <button
              @click="fetchPage()"
              type="button" class="btn btn-outline-success btn-fw mb-2"><i
              class="icon-reload"></i>Оновити
            </button>
            <button
              @click="handlerSkip"
              type="button" class="btn btn-outline-info btn-fw mb-2"><i
              class="icon-refresh"></i>Скинути
            </button>
            <div v-if="viewFilters" class="row mb-2">
              <div class="col-lg-3 col-md-3 col-sm-6">
                <input class="form-control" id="uniques-filter" v-model="filters.groups.unique" placeholder="По унику"/>
              </div>
            </div>
            <div v-if="viewFilters" class="row mb-2">
              <owner-filter
                v-model="filters.groups.owner"
                :data="ownerData"
                class="col-lg-3 col-md-3 col-sm-6"
              />
              <div class="col-lg-3 col-md-3 col-sm-6">
                <select class="form-control" id="install-filter" v-model="filters.groups.installs">
                  <option value="" selected>Фильтр по установкам</option>
                  <option value="0">Не установили</option>
                  <option value="1">Установили</option>
                </select>
              </div>
              <filters-countries v-model="filters.groups.country" class="col-lg-3 col-md-3 col-sm-6"
                                 ajax-url="/admin/statistics/groups/countries"/>
              <datepicker
                id="date_begin"
                v-model="filterDateBegin"
                container=".main-panel"
                inputClass="form-control"
                class="col-lg-3 col-md-3 col-sm-6 in_to_pickerd"
                useEndDate
              />
            </div>
            <div v-if="viewFilters" class="row mb-2">
              <filters-pwas
                v-model="filters.groups.pwa"
                ajax-url="/admin/statistics/groups/pwas"
                :data="pwaData"
                class="col-lg-3 col-md-3 col-sm-6"
              />
              <div class="col-lg-3 col-md-3 col-sm-6">
                <input class="form-control" id="host-filter" v-model="filters.groups.host" placeholder="По домену"/>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6">
                <input class="form-control" id="redirect-filter" v-model="filters.groups.redirect"
                       placeholder="Пошук за редиректом"/>
              </div>
              <datepicker
                id="date_end"
                v-model="filterDateEnd"
                container=".main-panel"
                inputClass="form-control"
                class="col-lg-3 col-md-3 col-sm-6 mb-2 in_to_pickerd"
                useEndDate
              />
            </div>
            <loading v-model="loading" class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>Дата</th>
                  <th>Страна</th>
                  <th>Устройство</th>
                  <th>ОС</th>
                  <th>Браузер</th>
                  <th>Смож. уста</th>
                  <th>Хитов</th>
                  <th>Установок</th>
                  <th>Открытий</th>
                  <th>Повторно</th>
                  <th>Пушей</th>
                  <th>Онлайн</th>
                  <th>Рег</th>
                  <th>Деп</th>
                  <th style="min-width: 200px;">PWA</th>
                  <th style="min-width: 220px;">Владелец</th>
                  <th>Редирект</th>
                  <th>Первая рефка</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in data" :key="item._id">
                  <td>{{ item.createdAt | formatDate }}</td>
                  <td v-html="$options.filters.formatFlag(item.country)"></td>
                  <td :class="successDeviceClass(item.ua)">{{ item.ua | formatDevice }}</td>
                  <td :class="successOSClass(item.ua)">{{ item.ua | formatOS }}</td>
                  <td :class="successBrowserClass(item.ua)">{{ item.ua | formatBrowser }}</td>
                  <td :class="item.canInstall ? 'bg-success' : ''">{{ item.canInstall | formatBoolean }}</td>
                  <td>{{ item.hits }}</td>
                  <td>{{ item.installs }}</td>
                  <td>{{ item.opens }}</td>
                  <td>{{ item.reopens }}</td>
                  <td>{{ item.pushes }}</td>
                  <td>{{ item.online | formatBoolean }}</td>
                  <td>{{ item.registration | formatBoolean }}</td>
                  <td>{{ item.deposit | formatBoolean }}</td>
                  <td v-if="item.pwa">
                    <div class="row">
                      <div class="col-md-4">
                        <a
                          @click="handlerFiltersGroupsPWA(item.pwa)"
                          href="javascript:void(0);">
                          <img :src="getPWALogo(item.pwa.images, item.pwa.vertical, item.pwa.gallery)">
                        </a>
                      </div>
                      <div class="col-md-8">
                        <a
                          @click="handlerFiltersGroupsPWA(item.pwa)"
                          href="javascript:void(0);"
                          class="d-block">{{ item.host }}</a>
                        <span class="text-gray mt-2 d-block">{{ item.pwa.name }}</span>
                      </div>
                    </div>
                  </td>
                  <td v-else><span class="text-gray">( нет )</span></td>
                  <td v-if="item.owner">
                    <div class="row">
                      <div class="col-md-4">
                        <a
                          @click="handlerFiltersGroupsOwner(item.owner)"
                          href="javascript:void(0);">
                          <img :src="item.owner.photo_url ? item.owner.photo_url : '/images/no-avatar.jpg'">
                        </a>
                      </div>
                      <div class="col-md-8">
                        <a
                          @click="handlerFiltersGroupsOwner(item.owner)"
                          href="javascript:void(0);" class="d-block">@{{
                            item.owner.username | truncate(13, '...')
                          }}</a>
                        <span class="text-gray mt-2 d-block">{{
                            item.owner.first_name | truncate(7)
                          }} {{ item.owner.last_name | truncate(7) }}</span>
                      </div>
                    </div>
                  </td>
                  <td v-else><span class="text-gray">( нет )</span></td>
                  <td>{{ item.redirect }}</td>
                  <td>{{ item.referer }}</td>
                </tr>
                </tbody>
              </table>
            </loading>
            <nav v-if="pageCount > 1">
              <paginate ref="paginate"
                        v-model="page"
                        :page-count="pageCount"
                        :click-handler="handlerPagination"
                        :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                        :next-text="'<i class=\'icon-arrow-right\'></i>'"
                        :container-class="'pagination mt-3'"
                        :page-class="'page-item'"
                        :page-link-class="'page-link'"
                        :prev-class="'page-item'"
                        :next-class="'page-item'"
                        :prev-link-class="'page-link'"
                        :next-link-class="'page-link'"
                        :active-class="'active'">
              </paginate>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import Loading from '@/pages/admin/components/Loading.vue'
import statisticsMixin from '@/mixins/statistics-mixin'
import UAParser from 'ua-parser-js'
import * as components from '../components'
import pwasMixin from '@/mixins/pwas-mixin'
import * as adminComponents from '@/pages/admin/components'
import {OwnerFilter} from '@/pages/admin/components'
import Datepicker from '@/components/Datepicker.vue'
import filtersCountries from '@/pages/statistics/components/filters/Countries.vue'
import filtersPWAs from '@/pages/statistics/components/filters/PWAs.vue'
import {mapActions} from 'vuex'

const defaultFiltersGroups = () => ({
  // eslint-disable-next-line no-undef
  dateBegin: moment().subtract(14, 'd').format('YYYY-MM-DD'),
  // eslint-disable-next-line no-undef
  dateEnd: moment().format('YYYY-MM-DD'),
  country: '',
  pwa: '',
  owner: '',
  installs: '',
  host: '',
  unique: '',
  redirect: ''
})

export default {
  name: 'Unique',
  components: {
    'owner-filter': OwnerFilter,
    'pwa-filter': components.filters.PWA,
    TableOwner: adminComponents.tables.Owner,
    Datepicker,
    Loading,
    'filters-countries': filtersCountries,
    'filters-pwas': filtersPWAs
  },
  mixins: [statisticsMixin, pwasMixin],
  data () {
    return {
      limit: 30,
      page: 1,
      count: 0,
      filters: {
        groups: {
          ...defaultFiltersGroups()
        }
      },
      loading: true,
      viewFilters: true,
      ownerData: [],
      pwaData: []
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.limit)
    },
    filterDateBegin: {
      get () {
        return this.filters.groups.dateBegin
      },
      set (input) {
        // eslint-disable-next-line no-undef
        this.filters.groups.dateBegin = moment(input).format('YYYY-MM-DD')
      }
    },
    filterDateEnd: {
      get () {
        return this.filters.groups.dateEnd
      },
      set (input) {
        // eslint-disable-next-line no-undef
        this.filters.groups.dateEnd = moment(input).format('YYYY-MM-DD')
      }
    },
    filter () {
      let filter = {}
      for (const index in this.filters) {
        for (const index2 in this.filters[index]) {
          if (this.filters[index][index2] !== '') {
            filter[index2] = this.filters[index][index2]
          }
        }
      }
      return filter
    }
  },
  created () {
    this.handlerPagination(1)
    for (const filter in this.filters) {
      for (const prop in this.filters[filter]) {
        this.$watch(`filters.${filter}.${prop}`, async function () {
          await this.handlerPagination(1)
        })
      }
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'adminStatistics/uniques',
      alertsError: 'alerts/error'
    }),
    debounceFetchData: _.debounce(async function (options) {
      try {
        await this.fetchData(options)
        const list = this.$store.getters['adminStatistics/uniques']
        this.count = list.count
        this.data = list.data
      } catch (error) {
        this.loading = false
        await this.alertsError(error.response.data)
      }
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData({
        limit: this.limit,
        page: this.page,
        sort: '-createdAt',
        filter: this.filter
      })
    },
    handlerPagination (page = 1) {
      this.page = page
      this.fetchPage()
    },
    successDeviceClass: (ua) => {
      const uaParser = new UAParser(ua)
      return uaParser.getDevice().type === 'mobile' || uaParser.getDevice().type === 'tablet' ? 'bg-success' : ''
    },
    successOSClass: (ua) => {
      const uaParser = new UAParser(ua)
      return uaParser.getOS().name === 'Android' ? 'bg-success' : ''
    },
    successBrowserClass: (ua) => {
      const uaParser = new UAParser(ua)
      return uaParser.getBrowser().name === 'Chrome' ? 'bg-success' : ''
    },
    handlerSkip () {
      this.viewFilters = false
      this.ownerData = []
      this.pwaData = []
      this.filters.groups = defaultFiltersGroups()
      this.$nextTick(() => { this.viewFilters = true })
    },
    handlerFiltersGroupsOwner (owner) {
      this.viewFilters = false
      this.filters.groups.owner = owner.id
      this.ownerData.push({_id: owner.id, ...owner})
      this.$nextTick(() => { this.viewFilters = true })
    },
    handlerFiltersGroupsPWA (pwa) {
      this.viewFilters = false
      this.filters.groups.pwa = pwa.id
      this.pwaData.push({id: pwa.id, text: pwa.name})
      this.$nextTick(() => { this.viewFilters = true })
    }
  }
}
</script>
