export default {
  methods: {
    initTooltip () {
      (function ($) {
        'use strict'
        $(function () {
          $('[data-toggle="tooltip"]').tooltip({
            placement: 'top',
            trigger: 'hover',
            container: '.parent_tooltip'
          })
        })
        // eslint-disable-next-line no-undef
      })(jQuery)
    },
    hideTooltip () {
      (function ($) {
        'use strict'
        $(function () {
          $('[role="tooltip"]').tooltip('hide')
        })
        // eslint-disable-next-line no-undef
      })(jQuery)
    }
  }
}
