import Bills from './Bills.vue'
import Transactions from './Transactions.vue'

export default (meta, header) => {
  return [
    {
      path: 'bills',
      name: 'Bills',
      components: {default: Bills, header: header},
      meta: meta
    },
    {
      path: 'transactions',
      name: 'Transactions',
      components: {default: Transactions, header: header},
      meta: meta
    }
  ]
}
