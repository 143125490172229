import dictionaryMixin from '@/mixins/dictionary-mixin'

export default {
  mixins: [dictionaryMixin],
  computed: {
    options () {
      const options = []
      for (const [id, text] of Object.entries(this.countries)) {
        options.push({id, text: this.$t(text)})
      }
      return options
    },
    settings () {
      const format = state => {
        if (!state.id) {
          return state.text
        }
        return `${state.id.toUpperCase()} - ${state.text}`
      }
      return {
        placeholder: 'Выберете страну',
        templateResult: format,
        templateSelection: format,
        escapeMarkup: function (m) {
          return m
        }
      }
    }
  }
}
