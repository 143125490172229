export default {
  title: 'Шаблоны PWA',
  create: 'Создать шаблон',
  deleteTitle: 'Удаления шаблона PWA',
  deleteMessage: 'Вы уверены что хотите удалить шаблон PWA?',
  name: 'Название',
  owner: 'Пользователь',
  googlePlayURL: 'Play Market URL',
  pwaId: 'PWA',
  createdAt: 'Создан',
  updatedAt: 'Обновлен',
  idTitle: 'ID шаблона',
  nameTitle: 'Названия шаблона в списках',
  ownerTitle: 'Владелец шаблона',
  googlePlayURLTitle: 'URL из которого делали импорт',
  pwaIdTitle: 'PWA из которого делали импорт',
  createdAtTitle: 'Дата создания шаблона',
  updatedAtTitle: 'Дата обновления шаблона',
  actionsTitle: 'Действия над шаблоном',
  deleteButton: 'Удалить',
  deleted: 'Шаблон удален',
  filters: {
    id: 'Поиск по ID',
    name: 'Поиск по названию',
    owner: 'Поиск по владельцу',
    googlePlayURL: 'Поиск по URL',
    pwaId: 'Поиск по PWA'
  },
  form: {
    createTitle: 'Создание шаблона PWA',
    editTitle: 'Редактирование шаблона {name}',
    name: 'Названия шаблона',
    namePlaceholder: 'Введите название шаблона',
    owner: 'Владелец шаблона',
    ownerPlaceholder: 'Укажите владельца шаблона',
    pwaPlaceholder: 'Укажите PWA для импорта',
    googlePlayURL: 'URL Play Market',
    googlePlayURLPlaceholder: 'Укажите URL для импорта',
    lang: 'Язык приложения',
    langPlaceholder: 'Укажите язык приложения'
  },
  apps: {
    title: 'Применить шаблон',
    your: 'Ваши шаблоны',
    notSelected: 'Шаблон не выбран'
  },
  success: 'Шаблон успешно сохранен'
}
