<template>
  <div class="split-testing-link">
    <div class="form-group" :class="apiValidationErrors.redirect ? 'has-danger' : ''">
      <label :for="`${id}-split-testing-link-redirect`">{{ $t('trackers.linkSettings.redirect') }}</label>
      <div class="d_flex">
        <div class="flex_grow_1">
          <input
            :value="redirect"
            @input="handlerSave($event, 'redirect')"
            @focus="clearError('redirect')"
            :class="apiValidationErrors.redirect ? 'form-control-danger' : ''"
            :id="`${id}-split-testing-link-redirect`"
            class="form-control"
            type="text"/>
        </div>
        <div class="flex_grow_0 butt_lish_gr">
          <slot/>
        </div>
      </div>
      <label v-if="apiValidationErrors.redirect" :id="`${id}-split-testing-link-redirect-error`"
             class="error mt-2 text-danger"
             :for="`${id}-split-testing-link-redirect-error`">{{ apiValidationErrors.redirect[0] }}</label>
    </div>
    <div class="form-group" :class="apiValidationErrors.weight ? 'has-danger' : ''">
      <label :for="`${id}-split-testing-weight`">{{ $t('pwas.tracker.splitTesting.weight') }}</label>
      <input
        @focus="clearError('weight')"
        :value="weight"
        @input="handlerSave($event, 'weight')"
        :class="apiValidationErrors.weight ? 'form-control-danger' : ''"
        :id="`${id}-split-testing-weight`"
        class="form-control"
        type="number"
        min="0"
        :max="endWeight"
        step="1"/>
      <label v-if="apiValidationErrors.weight" :id="`${id}-split-testing-weight-error`" class="error mt-2 text-danger"
             :for="`${id}-split-testing-weight-error`">{{ apiValidationErrors.weight[0] }}</label>
    </div>
    <postback-field
      :value="postback"
      @input="handlerSave($event, 'postback')"
      :id="`split-testing-template-${id}`"/>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import formMixin from '@/mixins/form-mixin'
import PostbackField from '../PostbackField.vue'
import trackerMixin from '@/mixins/tracker-mixin'

export default {
  name: 'SplitTestingLink',
  components: {Info, PostbackField},
  mixins: [formMixin, trackerMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    endWeight: {
      type: Number,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      postback: null,
      weight: 0,
      redirect: ''
    }
  },
  computed: {
    templates () {
      return this.$store.getters['pwas/trackerPage'].templates
    }
  },
  watch: {
    errors: {
      handler (n) {
        if (Object.keys(n).length > 0) {
          const errors = {}
          for (const [prop, value] of Object.entries(n)) {
            const param = prop.split(`splitTestingLinks[${this.id}].`)
            if (param.length > 1) {
              errors[param[1]] = value
            }
          }
          this.apiValidationErrors = errors
        }
      },
      deep: true
    },
    postback (n, o) {
      if (this.redirect !== '') {
        let url = this.redirect
        let oldParams = ''
        let newParams = ''
        let index = this.templates.findIndex(value => value._id === o)
        if (index > -1) {
          oldParams = this.templates[index].params
        }
        index = this.templates.findIndex(value => value._id === n)
        if (index !== -1) {
          const template = this.templates[index]
          newParams = template.params
        }
        url = this.applyTemplate(url, oldParams, true)
        url = this.applyTemplate(url, newParams)
        this.redirect = url
        this.handlerSave(url, 'redirect')
      }
    },
    value: {
      handler (n) {
        this.redirect = n.redirect
        this.weight = n.weight
        this.postback = n.postback
      },
      deep: true
    }
  },
  created () {
    this.postback = this.value.postback
    this.weight = this.value.weight
    this.redirect = this.value.redirect
  },
  methods: {
    handlerSave ($event, prop) {
      this[prop] = $event.target != null ? $event.target.value : $event
      const value = this.value
      value[prop] = this[prop]
      this.$emit('input', value)
    }
  }
}
</script>
<style scoped>
.split-testing-link + .split-testing-link {
  padding-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, .03);
}
</style>
