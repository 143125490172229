<template>
  <modal
    class="modal_g_small"
    id="pwa-analytics-facebook-pixel-modal"
    ref="dialog"
  >
    <button type="button" data-dismiss="modal"
            class="close_sjgs_to d_flex align_center"
            v-on:click="$refs.dialog.close()"><span aria-hidden="true">×</span>
    </button>
    <div class="mb-5">
      <div class="modal_title_go">{{ $t('pixels.form.title') }}</div>
    </div>
    <div v-if="false === loading">
      <div class="d-flex mb-4">
        <div class="flex-grow-0">
          <div class="title_inf_ls left_align">
            {{ $t('pixels.form.conversion.title') }}
          </div>
        </div>
        <div class="flex-grow-1">
          <BaseSelect
            v-model="defaultPayload.conversion"
            class="wrap_countries"
            id="pwa-analytics-facebook-pixel-modal-conversion"
            name="conversion"
            :clearable="false"
            inputClass="countries"
            :options="conversionOptions"
          />
        </div>
      </div>
      <div class="d-flex mb-4">
        <div class="flex-grow-0">
          <div class="title_inf_ls left_align">
            {{ $t('pixels.form.country.title') }}
          </div>
        </div>
        <div class="flex-grow-1">
          <BaseSelect
            v-model="defaultPayload.country"
            class="wrap_countries"
            id="pwa-analytics-facebook-pixel-modal-country"
            name="country"
            :clearable="false"
            showIcon
            :aria-placeholder="$t('pixels.form.country.placeholder')"
            :options="countryOptions"
          />
        </div>
      </div>
      <div class="mb-3 subtitle_modal">{{ $t('pixels.form.subtitle') }}</div>
      <div class="d-flex mb-4">
        <div class="flex-grow-0">
          <div class="title_inf_ls left_align">
            {{ $t('pixels.form.registrations.title') }}
          </div>
        </div>
        <div class="flex-grow-1">
          <range-slider
            v-model="registrations"
            id="pwa-analytics-facebook-pixel-modal-registrations"
            name="registrations"
            :max="50"/>
        </div>
      </div>
      <div class="d-flex mb-4">
        <div class="flex-grow-0">
          <div class="title_inf_ls left_align">
            {{ $t('pixels.form.deposits.title') }}
          </div>
        </div>
        <div class="flex-grow-1">
          <range-slider
            v-model="deposits"
            id="pwa-analytics-facebook-pixel-modal-deposits"
            name="deposits"
            :max="50"/>
        </div>
      </div>
      <div class="info_text">{{ $t('pixels.form.info') }}
      </div>
    </div>
    <div class="footer_modal mt-5">
      <div class="d-flex align-items-center">
        <div class="flex-grow-0">
          <button type="button"
                  class="ful_butt_green d_flex align_center justify_center"
                  :disabled="processing" v-on:click="handleSetupPixBack">
            <span v-if="!processing">{{ $t('general.save') }}</span>
            <hollow-dots-spinner
              v-if="processing"
              :animation-duration="1000"
              :dot-size="15"
              :dots-num="3"
              :color="'#ff1d5e'"
            />
          </button>
        </div>
        <div class="flex-grow-1 pl-2">
          <div class="info_text_smaller">{{ $t('pixels.form.hint') }}
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import * as dictionary from '@pwa.group/pwa.dictionary'
import {mapGetters} from 'vuex'
import Modal from '@/components/modal/Modal.vue'
import Select2 from '@/components/Select2.vue'
import RangeSlider from '@/components/RangeSlider.vue'
import {HollowDotsSpinner} from 'epic-spinners'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  name: 'PixelsForm',
  props: ['value', 'pixels'],
  components: {BaseSelect, Modal, Select2, RangeSlider, HollowDotsSpinner},
  data () {
    return {
      ...this.defaultPayload,
      processing: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      defaultPayload: 'pixels/createPayload',
      listConversions: 'pixels/listConversions'
    }),
    countryOptions () {
      return dictionary.countries.map(({id, text}) => ({id, text: this.$t(text)}))
    },
    conversionOptions () {
      return this.listConversions.map(el => ({id: el.id, text: this.$t(el.text)}))
    }
  },
  methods: {
    open () {
      this.processing = false
      this.loading = true
      Object.entries(this.defaultPayload).map(([prop, value]) => {
        this[prop] = value
      })
      this.$refs.dialog.open()
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async handleSetupPixBack () {
      this.processing = true
      const pixels = this.pixels.filter(pixel => this.value.includes(String(pixel._id)))
      if (this.defaultPayload.country == null || this.defaultPayload.country.length !== 2) {
        await this.$store.dispatch('alerts/error', this.$t('pixels.form.country.errors.required'))
        this.$nextTick(() => {
          this.processing = false
        })
        return
      }

      if (this.defaultPayload.conversion === 'api') {
        for (const pixel of pixels) {
          if (pixel.accessToken == null || pixel.accessToken.length <= 0) {
            await this.$store.dispatch('alerts/error', this.$t('pixels.form.accessToken.errors.required', {pixel: pixel._id}))
            this.$nextTick(() => {
              this.processing = false
            })
            return
          }
        }
      }
      try {
        await this.$store.dispatch('pixels/create', {
          pixels: pixels.map(pixel => ({
            id: String(pixel._id),
            accessToken: pixel.accessToken || null,
            registration: pixel.registration || 'CompleteRegistration',
            deposit: pixel.deposit || 'Purchase'
          })),
          country: this.defaultPayload.country,
          registrations: this.registrations,
          deposits: this.deposits,
          conversion: this.defaultPayload.conversion,
          pwa: this.$route.params.id
        })
        await this.$store.dispatch(
          'alerts/success',
          this.$t('pixels.form.success.create')
        )
        this.$refs.dialog.close()
      } catch (error) {
        if (error.response != null) {
          if (error.response.status === 400) {
            await this.$store.dispatch(
              'alerts/error',
              error.response.data.errors[0].msg
            )
          } else if (error.response.data != null) {
            await this.$store.dispatch(
              'alerts/error',
              error.response.data
            )
          }
        } else {
          await this.$store.dispatch('alerts/error', error.message)
        }
        this.$nextTick(() => {
          this.processing = false
        })
      }
    }
  }
}
</script>
