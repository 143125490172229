<template>
  <div>
    <button type="button" class="btn btn-info btn-block" data-toggle="modal" :data-target="'#ga-modal' + data._id">
      {{ data._id }}
    </button>
    <div class="modal fade" :id="'ga-modal' + data._id" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Настройка Google Conversion</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-for="event of events" :key="event.label" v-if="data[event.event]">
              <div class="col-12"><p class="card-description">{{ event.label }}</p></div>
              <div class="form-group col-8">
                <label>Ярлык конверсии</label>
                <input type="text" class="form-control form-control-lg" v-model="data[event.event].code">
              </div>
              <div class="form-group col-2">
                <label>Ценность</label>
                <input type="text" class="form-control form-control-lg" v-model="data[event.event].value">
              </div>
              <div class="form-group col-2">
                <label>Валюта</label>
                <input type="text" class="form-control form-control-lg" v-model="data[event.event].currency">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GA',
  props: ['data'],
  data: () => ({
    events: [
      {event: 'install', label: 'Установка'},
      {event: 'open', label: 'Открытия'},
      {event: 'push', label: 'PUSH подписка'},
      {event: 'registration', label: 'Регистрация'},
      {event: 'deposit', label: 'Депозит'}
    ]
  })
}
</script>
