import service from '@/store/services/mails-service'
import middleware from './middleware'

const state = {}

const mutations = {}

const actions = {

  update: ({commit, dispatch}, params) => service
    .update(params)
    .then()
    .catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {}

const mails = {

  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default mails
