<template>
  <div class="content-wrapper parent_tooltip PWAsApp pwas-app" v-if="accesses.pwas.update">
    <navigation name="PWAsApp"/>
    <div class="row pwas-app__row">
      <div class="col-xl-8 col-lg-7 col-sm-12 grid-margin grid-margin-md-0">
        <div class="card">
          <div class="card-body pt-0 ">
            <div class="style_descr_g">
              <h4 class="title_sf_site">{{ $t('pwas.components.app.title', {id: PWAId}) }}</h4>
              <p class="descr_site_shg mb_small">
                {{ $t('pwas.components.app.message') }}
              </p>
            </div>
            <pwas-translator-menu v-if="pwaCreated" v-model="pwa" route="PWAsApp">
              <pwas-translator-app v-model="pwa"/>
            </pwas-translator-menu>
            <div class="blk_tabs_geg style_form_lab">
              <form v-if="pwaCreated" class="forms-sample" @submit.prevent="handlerUpdate" @change="onChange">
                <main-app
                  v-if="pwa.view === 'index.ejs'"
                  v-model="pwa"
                  :errors="apiValidationErrors" />
                <new-main-app
                  v-else
                  v-model="pwa"
                  :errors="apiValidationErrors" />
                <button-install-app
                  v-model="pwa"
                  :errors="apiValidationErrors" />
                <description-app
                  v-if="pwa.view === 'index.ejs'"
                  v-model="pwa"
                  :errors="apiValidationErrors" />
                <new-description-app
                  v-else
                  v-model="pwa"
                  :errors="apiValidationErrors" />
                <rating-app
                  v-if="pwa.view === 'index.ejs'"
                  v-model="pwa"
                  :errors="apiValidationErrors"
                  @change="onChange"/>
                <new-rating-app
                  v-else
                  v-model="pwa"
                  :errors="apiValidationErrors" />
                <addition-info-app
                  v-if="pwa.view === 'index.ejs'"
                  v-model="pwa"
                  :errors="apiValidationErrors" />
                <new-addition-info-app
                  v-else
                  v-model="pwa"
                  :errors="apiValidationErrors" />
                <div class="item_toggsl_g">
                  <div class="head_tit_sl_togg">
                    <div class="in_to_togg_sj_f d_flex align_center">
                      <span>{{ $t('pwas.components.app.ttPreland.title') }}</span>
                      <info :message="$t('pwas.components.app.ttPreland.titleInfo')"/>
                    </div>
                  </div>
                  <div class="content_dropd_g">
                    <div class="row row_mob_sg">
                      <div class="form-group col">
                        <label for="ttTpl">{{ $t('pwas.components.app.ttPreland.template') }}
                          <info :message="$t('pwas.components.app.ttPreland.templateInfo')"/>
                        </label>
                        <select id="ttTpl" v-model="pwa.ttTpl" name="ttTpl" class="form-control">
                          <option value="1">{{ $t('pwas.components.app.ttPreland.variant', {num: 1}) }}</option>
                          <option value="2">{{ $t('pwas.components.app.ttPreland.variant', {num: 2}) }}</option>
                          <option value="3">{{ $t('pwas.components.app.ttPreland.variant', {num: 3}) }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="row row_mob_sg">
                      <div class="form-group col-12 col-xl-3 col_half_mob"
                           :class="apiValidationErrors.ttHeader ? 'has-danger' : ''">
                        <label for="ttHeader">{{ $t('pwas.components.app.ttPreland.ttHeader') }}
                          <info :message="$t('pwas.components.app.ttPreland.ttHeaderInfo')"/>
                        </label>
                        <input type="text" id="ttHeader" :placeholder="$t('pwas.components.app.ttPreland.ttHeader')"
                               v-model="pwa.languages[lang].ttHeader" class="form-control"
                               :class="apiValidationErrors.ttHeader ? 'form-control-danger' : ''"
                               @focus="clearError('ttHeader')" v-if="lang">
                        <input type="text" id="ttHeader" :placeholder="$t('pwas.components.app.ttPreland.ttHeader')"
                               v-model="pwa.ttHeader"
                               class="form-control"
                               :class="apiValidationErrors.ttHeader ? 'form-control-danger' : ''"
                               @focus="clearError('ttHeader')" v-else>
                        <label v-if="apiValidationErrors.ttHeader" class="error mt-2 text-danger" for="ttHeader">
                          {{ apiValidationErrors.ttHeader[0] }}
                        </label>
                      </div>
                      <div class="form-group col-md-6 col-xl-3 col_half_mob" :class="apiValidationErrors.ttYes ? 'has-danger' : ''">
                        <label for="ttYes">{{ $t('general.yes') }}
                          <info :message="$t('pwas.components.app.ttPreland.ttYes')"/>
                        </label>
                        <input type="text" id="ttYes" :placeholder="$t('general.yes')"
                               v-model="pwa.languages[lang].ttYes" class="form-control"
                               :class="apiValidationErrors.ttYes ? 'form-control-danger' : ''"
                               @focus="clearError('ttYes')" v-if="lang">
                        <input type="text" id="ttYes" :placeholder="$t('general.yes')" v-model="pwa.ttYes"
                               class="form-control"
                               :class="apiValidationErrors.ttYes ? 'form-control-danger' : ''"
                               @focus="clearError('ttYes')" v-else>
                        <label v-if="apiValidationErrors.ttYes" class="error mt-2 text-danger" for="ttYes">
                          {{ apiValidationErrors.ttYes[0] }}
                        </label>
                      </div>
                      <div class="form-group col-md-6 col-xl-3 col_half_mob" :class="apiValidationErrors.ttNo ? 'has-danger' : ''">
                        <label for="ttNo">{{ $t('general.no') }}
                          <info :message="$t('pwas.components.app.ttPreland.ttNo')"/>
                        </label>
                        <input type="text" id="ttNo" :placeholder="$t('general.no')"
                               v-model="pwa.languages[lang].ttNo" class="form-control"
                               :class="apiValidationErrors.ttNo ? 'form-control-danger' : ''"
                               @focus="clearError('ttNo')" v-if="lang">
                        <input type="text" id="ttNo" :placeholder="$t('general.no')" v-model="pwa.ttNo"
                               class="form-control"
                               :class="apiValidationErrors.ttNo ? 'form-control-danger' : ''"
                               @focus="clearError('ttNo')" v-else>
                        <label v-if="apiValidationErrors.ttNo" class="error mt-2 text-danger" for="ttNo">
                          {{ apiValidationErrors.ttNo[0] }}
                        </label>
                      </div>
                    </div>

                    <div class="row row_mob_sg">
                      <div class="form-group col">
                        <label class="labek_check_sg" for="ttForce">
                          <input type="checkbox" class="form-check-input" id="ttForce"
                                 name="ttForce"
                                 v-model="pwa.ttForce">
                          <div class="check_icd_G"></div>
                          <span class="txt_chek_xg">{{ $t('pwas.components.app.ttPreland.ttForce') }}</span>
                        </label>
                        <info
                          :message="$t('pwas.components.app.ttPreland.ttForceInfo')"/>
                      </div>
                    </div>

                  </div>
                </div>
                <cookies-app
                  v-model="pwa"
                  :errors="apiValidationErrors" />
                <div class="foot_actions_shg app_butts_gd">
                  <div class="d-xl-flex justify-content-between">
                    <div class="d-md-flex align-items-center mobile-grid-2">
                      <div class="mr-md-4 mb-4 mb-md-0">
                        <button
                          type="submit"
                          class="ful_butt_green d_flex align_center justify_center mobile-full-w"
                        >
                          {{ $t('general.save') }}
                        </button>
                      </div>
                      <div class="mr-md-4 mb-4 mb-md-0">
                        <router-link :to="{name: 'PWAsComments', params: {id: PWAId}}"
                                     class="border_butt_green d_flex align_center justify_center mobile-full-w">{{ $t('general.next') }}
                        </router-link>
                      </div>
                      <pwas-templates-app-template v-model="pwa"/>
                      <translator v-if="!$route.params.language" v-model="pwa" @translate="handlerTranslate"/>
                      <div class="mr-md-4 mb-4 mb-md-0" :class="{ 'one-column-mobile': !lang }">
                        <router-link :to="{name: 'PWAs'}" class="simple_butt_afg mobile-full-w">{{
                            $t('general.cancel')
                          }}
                        </router-link>
                      </div>
                    </div>
                    <div class="d-flex align-items-center mt-md-4 mt-xl-0" v-if="lang">
                      <button type="button" class="simple_butt_afg color-danger mobile-full-w" @click="removeTranslate(pwa._id)">
                        {{ $t('pwas.components.translate.delete') }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-5 col-sm-12 grid-margin grid-margin-md-0 preview_this_blk">
        <div class="button_back_to_edit" data-hide-preview>
          <div class="green_cirs_g d_flex align_center justify_center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <preview :id="PWAId" :previewURL="previewURL" ref="preview"/>
      </div>
      <div class="button_preview_sh" data-show-preview>
        <div class="show_preview_green d_flex align_center justify_center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.9192 10.6531C20.6047 10.4832 17.9084 8.91875 16.8967 8.32989L16.8938 8.32789L4.63891 1.25275C4.43013 1.1253 4.19602 1.04501 3.95297 1.01749C3.70992 0.989977 3.46379 1.0159 3.2318 1.09345C3.20562 1.10285 3.18102 1.11231 3.15557 1.12396C3.09648 1.14322 3.03872 1.16634 2.98266 1.19318C2.74834 1.33323 2.55611 1.53385 2.42619 1.77393C2.29627 2.01401 2.23346 2.28468 2.24438 2.55744V21.5428C2.23694 21.7969 2.29713 22.0484 2.41878 22.2717C2.54043 22.4949 2.71918 22.6818 2.93676 22.8133C2.98694 22.8378 3.03864 22.8591 3.09155 22.877C3.12048 22.8904 3.14806 22.902 3.17791 22.9125C3.32112 22.9645 3.47195 22.9923 3.62426 22.9949C3.91675 22.9918 4.2033 22.912 4.45532 22.7635C4.84399 22.5379 16.8938 15.5809 16.8938 15.5809L20.9143 13.2596C21.1629 13.1351 21.3727 12.945 21.5211 12.7099C21.6694 12.4747 21.7507 12.2035 21.7561 11.9256C21.7439 11.6578 21.6597 11.3983 21.5124 11.1744C21.3651 10.9505 21.1602 10.7704 20.9192 10.6531ZM4.24438 19.8391V4.10199L12.1845 11.9611L4.24438 19.8391ZM9.26238 17.6774L13.6059 13.3679L14.7554 14.5057C13.4908 15.236 11.3541 16.4697 9.26238 17.6775V17.6774ZM13.6042 10.5524L9.20631 6.19946L14.76 9.40576L13.6042 10.5524ZM16.551 13.4691L15.0256 11.9594L16.5533 10.4435C17.2736 10.8618 18.3961 11.5129 19.1692 11.9578L16.551 13.4691Z"
              fill="white"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import pwaMixin from './mixins/pwa-mixin'
import * as components from './components'
import Preview from '@/components/Preview.vue'
import Info from '@/components/Info.vue'
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import {PwasTemplatesAppTemplate} from './templates'
import PWAsTranslatorMenu from './components/translator/Menu.vue'
import PWAsTranslatorApp from './components/translator/App.vue'
import Translator from './components/translator/Translator.vue'
import * as appComponents from './components/app'
import confirmMixin from '@/mixins/confirm-leave-mixin'
// eslint-disable-next-line no-unused-vars,no-undef
const clipboard = new Clipboard('.btn-clipboard')

export default {
  name: 'PWAsApp',
  mixins: [formMixin, pwaMixin, titleMixin, accessesMixin, confirmMixin],
  components: {
    navigation: components.Navigation,
    preview: Preview,
    Info,
    'pwas-translator-menu': PWAsTranslatorMenu,
    'pwas-translator-app': PWAsTranslatorApp,
    PwasTemplatesAppTemplate,
    Translator,
    ...appComponents
  },
  title () {
    return this.$t('titles.pwa.app', {id: this.PWAId})
  },
  computed: {
    previewURL () {
      return `${import.meta.env.VITE_APP_PREVIEW_BASE_URL}/${this.PWAId}`
    },
    handlerConfirm () {
      return this.$store.getters['autoSave/handlerConfirm']
    }
  },
  watch: {
    handlerConfirm: function (newVal, oldVal) {
      if (!newVal && oldVal) {
        this.changedData = false
        this.pwa = JSON.parse(JSON.stringify(this.cashedPWA))
        this.changed = {}
      }
    }
  },
  methods: {
    handlerTranslate (data) {
      for (const [prop, value] of Object.entries(data)) {
        this.pwa[prop] = value
        this.changed[prop] = value
      }
      this.$store.dispatch('alerts/success', this.$t('pwas.components.translate.success'))
      this.onChange()
    },
    toggleAccordion () {
      (function ($) {
        'use strict'
        $(function () {
          $('.head_tit_sl_togg').on('click', function () {
            const parent = $(this).parent('.item_toggsl_g')
            parent.toggleClass('active')
            if (parent.hasClass('active')) {
              $(this).next('.content_dropd_g').slideDown(200)
            } else {
              $(this).next('.content_dropd_g').slideUp(200)
            }
          })
        })
        // eslint-disable-next-line no-undef
      })(jQuery)
    },
    async removeTranslate (pwa) {
      try {
        await this.$store.dispatch('translator/remove', {
          pwa,
          lang: this.lang
        })
        this.pwa.languages[this.lang] = undefined
        await this.$router.push({name: 'PWAsApp', id: pwa})
        await this.$store.dispatch('alerts/success', this.$t('pwas.components.app.translateDeleted'))
      } catch ({message}) {
        await this.$store.dispatch('alerts/error', message)
      }
    },
    onChange () {
      this.changedData = true
    }
  }
}
</script>
<style scoped lang="sass">
.pwas-app
  @media screen and (min-width: 992px)
    padding-right: 60px !important
</style>
