import api from './api'
import { getSearchParams } from '../list'

export default {
  getAll: payload => api.get('landing-pages?' + getSearchParams(payload)),
  create: payload => api.post('landing-pages/', payload),
  view: id => api.get(`landing-pages/${id}`),
  update: (id, payload) => api.put(`landing-pages/${id}`, payload),
  delete: id => api.delete(`landing-pages/${id}`),
  getTypes: payload => api.get('landing-pages/types?' + getSearchParams(payload)),
  getThemes: payload => api.get('landing-pages/themes?' + getSearchParams(payload)),
  createTypes: payload => api.post('landing-pages/types', payload),
  createThemes: payload => api.post('landing-pages/themes', payload),
  updateTypes: (id, payload) => api.put(`landing-pages/types/${id}`, payload),
  updateThemes: (id, payload) => api.put(`landing-pages/themes/${id}`, payload),
  deleteTypes: id => api.delete(`landing-pages/types/${id}`),
  deleteThemes: id => api.delete(`landing-pages/themes/${id}`),
  getType: id => api.get(`landing-pages/types/${id}`),
  getTheme: id => api.get(`landing-pages/themes/${id}`)
}
