import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import routes from './routes'

Vue.use(VueRouter)
Vue.use(VueMeta)

// configure router
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: to => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }
  }
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory (context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) {
    return context.next
  }

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({...context, next: nextMiddleware})
  }
}

router.beforeEach((to, from, next) => {
  if (from.meta.before) {
    const before = Array.isArray(from.meta.before) ? from.meta.before : [from.meta.before]
    const context = {from, next, to, router}
    const nextMiddleware = nextFactory(context, before, 1)

    if (typeof before[0] === 'undefined') return next()

    return before[0]({...context, next: nextMiddleware})
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = {from, next, to, router}
    const nextMiddleware = nextFactory(context, middleware, 1)

    if (typeof middleware[0] === 'undefined') return next()

    return middleware[0]({...context, next: nextMiddleware})
  }

  return next()
})

export default router
