<template>
  <div id="events-form" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="title_mod_uplo">{{ $t('pwas.components.google.settings.title') }}</div>
        <div class="link_ths_ig">{{ code }}</div>
        <form @submit.prevent="handle">
          <div class="div" v-for="event of events" :key="event.event">
            <div class="subtit_esgsgg">{{ $t(event.label) }}</div>
            <div class="row">
              <div class="form-group item_eve_col_ol event_col_nr_1">
                <label :for="event.event + 'Code'">{{ $t('pwas.components.google.settings.code') }}<info :message="$t('pwas.components.google.settings.code')"/></label>
                <input class="form-control" :id="event.event + 'Code'" :name="event.event + 'Code'" type="text"
                       v-model="$data[event.event].code">
              </div>
              <div class="form-group item_eve_col_ol event_col_nr_2">
                <label :for="event.event + 'Value'">{{ $t('pwas.components.google.settings.value') }}
                  <!-- <info message="Title tooltip"/> -->
                  </label>
                <input class="form-control" :id="event.event + 'Value'" :name="event.event + 'Value'" type="number"
                       v-model="$data[event.event].value">
              </div>
              <div class="form-group item_eve_col_ol event_col_nr_3">
                <label :for="event.event + 'Currency'">{{ $t('pwas.components.google.settings.currency') }}<info :message="$t('pwas.components.google.settings.currencyInfo')"/></label>
                <input class="form-control" :id="event.event + 'Currency'" :name="event.event + 'Currency'" type="text"
                       v-model="$data[event.event].currency" min="3" max="3">
              </div>
            </div>
          </div>
          <div class="footer_modl_akdhg">
            <div class="form-group">
              <div class="in_topshgdjf_g d_flex align_center">
                <div class="item_avt_butt_jg">
                  <button type="submit" class="ful_butt_green d_flex align_center justify_center">{{ $t('general.save') }}</button>
                </div>
                <div class="item_avt_butt_jg">
                  <a href="javascript:void(0);" data-dismiss="modal" class="border_butt_green d_flex align_center justify_center">{{ $t('general.cancel') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'

export default {
  name: 'Events',
  data: () => ({
    code: '',
    index: 0,
    events: [
      {event: 'install', label: 'pwas.components.google.events[0]'},
      {event: 'open', label: 'pwas.components.google.events[1]'},
      {event: 'push', label: 'pwas.components.google.events[2]'},
      {event: 'registration', label: 'pwas.components.google.events[3]'},
      {event: 'deposit', label: 'pwas.components.google.events[4]'}
    ],
    install: {
      code: '',
      value: 0,
      currency: 'USD'
    },
    open: {
      code: '',
      value: 0,
      currency: 'USD'
    },
    push: {
      code: '',
      value: 0,
      currency: 'USD'
    },
    registration: {
      code: '',
      value: 0,
      currency: 'USD'
    },
    deposit: {
      code: '',
      value: 0,
      currency: 'USD'
    }
  }),
  components: {Info},
  methods: {
    show: function () {
      // eslint-disable-next-line no-undef
      jQuery('#events-form').modal('show')
    },
    hide: function () {
      // eslint-disable-next-line no-undef
      jQuery('#events-form').modal('hide')
    },
    handle: function () {
      const events = ['install', 'open', 'push', 'registration', 'deposit']
      for (const event of events) {
        if (this[event].code || this[event].code !== '') {
          this[event].currency = this[event].currency.toUpperCase()
          this.$parent.googleAnalytics[this.index][event] = this[event]
        } else {
          this.$parent.googleAnalytics[this.index][event] = null
        }
      }
      this.hide()
    }
  }
}
</script>
