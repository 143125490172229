const mapComment = (comment, templateId, pwaId) => {
  if (comment.avatar != null) {
    comment.avatar = comment.avatar.replace(
      `pwastemplates/${templateId}`,
      `pwas/${pwaId}`) + '?v=' + new Date().getTime()
  }
  return comment
}

export default {
  methods: {
    replaceMedia (template, pwa) {
      pwa.gallery = pwa.gallery.map(src => src.replace(
        `pwastemplates/${template._id}`,
        `pwas/${this.$route.params.id}`
      ))
      return pwa
    },
    replaceComments (template, pwa) {
      pwa.comments = pwa.comments.map(comment => mapComment(comment, template._id, this.$route.params.id))
      for (const [lang] of Object.entries(pwa.languages)) {
        if (pwa.languages[lang].comments != null) {
          pwa.languages[lang].comments = pwa.languages[lang].comments.map(comment => mapComment(comment, template._id, this.$route.params.id))
        }
      }
      return pwa
    },
    setTexts (template, pwa, changed) {
      const languages = {}
      for (const [lang, data] of Object.entries(pwa.languages)) {
        languages[lang] = {}
        if (data.comments != null) {
          languages[lang].comments = data.comments
        }
      }
      for (const text of template.texts) {
        for (const [prop, value] of Object.entries(text)) {
          if (prop === '_id') {
            continue
          }
          if (text._id === 'default') {
            pwa[prop] = value
            changed[prop] = value
          } else {
            if (!languages[text._id]) languages[text._id] = {}
            languages[text._id][prop] = value
          }
        }
      }
      pwa.languages = languages
      changed.languages = languages
    },
    setMedia (template, pwa, changed) {
      const {youtube, muted, vertical, images, gallery} = template.media
      for (const [prop, value] of Object.entries({youtube, muted, vertical, images, gallery})) {
        pwa[prop] = value
        changed[prop] = value
      }
    },
    setComments (template, pwa, changed) {
      const languages = pwa.languages
      for (const [lang] of Object.entries(languages)) {
        languages[lang].comments = []
      }
      for (const data of template.comments) {
        if (data._id === 'default') {
          pwa.comments = data.comments
          changed.comments = data.comments
        } else {
          if (languages[data._id] == null) {
            languages[data._id] = {}
          }
          if (languages[data._id] != null) {
            languages[data._id].comments = data.comments
          }
        }
      }
      pwa.languages = languages
      changed.languages = languages
    },
    setContent (template, pwa, changed) {
      const languages = {}
      for (const text of template.texts) {
        if (text._id !== 'default') {
          languages[text._id] = {}
        }
        for (const [prop, value] of Object.entries(text)) {
          if (prop === '_id') {
            continue
          }
          if (text._id === 'default') {
            pwa[prop] = value
            changed[prop] = value
          } else {
            languages[text._id][prop] = value
          }
        }
      }
      for (const data of template.comments) {
        if (data._id === 'default') {
          pwa.comments = data.comments
          changed.comments = data.comments
        } else {
          languages[data._id].comments = data.comments
        }
      }
      pwa.languages = languages
      changed.languages = languages
    }
  }
}
