<template>
  <div class="content-wrapper parent_tooltip">
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body pt-0 ">
            <BaseRouterTabs :tabs="menuItems" />
            <router-view/>
            <anime-person-in-chair/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AnimePersonInChair from '@/components/animatedSvgs/PersonInChair.vue'
import BaseRouterTabs from '@/components/base/BaseRouterTabs.vue'

export default {
  name: 'BotOffersLayout',
  components: {BaseRouterTabs, AnimePersonInChair},
  computed: {
    menuItems () {
      let items = [{label: 'Офери', to: {name: 'BotOffers'}}]
      if (this.$route.params.id == null) {
        items.push({label: 'Новий офер', to: {name: 'BotOffersCreate'}})
      } else {
        items = [
          ...items,
          {label: 'Офер', to: {name: 'BotOffersUpdate', params: {id: this.$route.params.id}}},
          {label: 'Креативи', to: {name: 'BotOffersImages', params: {id: this.$route.params.id}}},
          {label: 'ГЕО', to: {name: 'BotOffersCountries', params: {id: this.$route.params.id}}}
        ]
      }
      return items
    }
  }
}
</script>
