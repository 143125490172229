export default {
  navigation: 'App',
  title: 'PWA App #{id}',
  message: 'Perform PWA page text settings',
  main: {
    title: 'Basic',
    titleInfo: 'Priority settings that are done at the very beginning',
    name: 'Name',
    nameInfo: 'Enter a name for your PWA',
    author: 'Author',
    authorInfo: 'Enter PWA developer',
    category: 'Category',
    categoryInfo: 'Select the category your PWA belongs to',
    categoryInscription: 'Category(inscription)',
    categoryInscriptionInfo: 'If you need a different category name, for example for localization. If left blank will be taken from category name',
    ad: 'Advertisement',
    adInfo: 'Does your PWA have ads',
    age: 'Age',
    ageInfo: 'What age is your PWA for?',
    ratingM: 'Rating text(mobile)',
    ratingMInfo: 'The word "Rating" is displayed in the mobile version',
    sizeM: 'Size(mobile)',
    sizeMInfo: 'Application size in MB shown in mobile version',
    ageM: 'age text(mobile)',
    ageMInfo: 'The word "Age" in mobile version',
    recommendationD: 'Recommendation(PC)',
    recommendationDInfo: 'Recommendation from the store. Shown in PC version',
    wishlistD: 'Wishlist(PC)',
    wishlistDInfo: 'The phrase "Add to wishlist". Shown in PC version',
    commentLabel: 'Reviews',
    commentLabelInfo: 'Translation of the word "reviews"',
    canInstall: 'Application Availability',
    canInstallInfo: 'Application Availability'
  },
  install: {
    title: 'Install Button',
    titleInfo: 'Text settings for labels on the button and on load',
    install: 'Install',
    installInfo: 'Button label text',
    installing: 'Initializing',
    installingInfo: 'The text that is shown after clicking the install button',
    download: 'Download',
    downloadInfo: 'Text shown after "Initialize"',
    open: 'Open',
    openInfo: 'Text shown after "Loading"'
  },
  description: {
    title: 'Description',
    titleInfo: 'Your PWA description settings',
    aboutM: 'About App(Mobile)',
    aboutMInfo: 'About app phrase in mobile version',
    readMore: 'More',
    readMoreInfo: 'Clickable text "More"',
    hide: 'Hide',
    hideInfo: 'Hide clickable text. Appears after the text "More" is pressed',
    description: 'Description',
    descriptionInfo: 'Description of your PWA',
    descriptionPlaceholder: 'Enter PWAs descriptions (for users)',
    update: 'Updates(PC)',
    updateInfo: 'The phrase "What\'s new". Shown in PC version',
    updateDesc: 'Update Descriptions (PC)',
    updateDescInfo: 'Last update description',
    dataSecurity: 'Data Security',
    dataSecurityInfo: 'Label',
    dataSecurityText: 'Description "Data security"',
    dataSecurityTextInfo: 'Description "Data security"',
    dataSecurityLink: 'Underlined text in "Data Security"',
    dataSecurityLinkInfo: 'Underlined text in "Data Security"',
    noneInfo: 'Text in "Data Security"',
    noneInfoInfo: 'Text in "Data Security"'
  },
  rating: {
    title: 'Rating',
    titleInfo: 'Settings and adjusting rankings in PWA',
    reviews: 'Reviews',
    reviewsInfo: 'Text "Ratings and Reviews"',
    ratingInfo: 'Rate your PWA on a 5-point scale',
    countComments: 'Cl. reviews',
    countCommentsInfo: 'Number of ratings for your PWA',
    total: 'Total(pc)',
    totalInfo: 'The word "Total". Shown in PC version',
    count: 'Number of ratings {num}',
    reviewsVerified: 'The inscription "Ratings and reviews are verified"',
    reviewsVerifiedInfo: 'The inscription "Ratings and reviews are verified"',
    reviewHelp: 'The caption "people marked this review as helpful."',
    reviewHelpInfo: 'The caption "people marked this review as helpful."',
    reviewHelpTitle: 'Did this information help you?',
    reviewHelpTitleInfo: 'Did this information help you?',
    reviewHelpYes: 'Reply "Yes"',
    reviewHelpYesInfo: '"Yes" to previous question',
    reviewHelpNo: 'Reply "No"',
    reviewHelpNoInfo: 'Answer "No" to the previous question',
    reviewAll: 'Inscription "All reviews"',
    reviewAllInfo: 'View all reviews button text'
  },
  additionalInfo: {
    title: 'Ext. information',
    titleInfo: 'Additional information displayed at the bottom of the PWA page',
    addInfo: 'Inscription "Additional information"',
    author: 'Developer',
    authorInfo: 'Inscription "Developer"',
    updated: 'Updated',
    updatedInfo: 'Inscription "Updated"',
    updatedDate: 'Update date',
    updatedDateInfo: 'Enter update date',
    size: 'Size',
    sizeInfo: 'Inscription "Size"',
    installs: 'Installs',
    installsInfo: 'Installation text',
    countInstall: 'Number of installations',
    countInstallInfo: 'Enter the number of installs',
    currentVersion: 'Current Version',
    currentVersionLabelInfo: 'Label "Current version"',
    currentVersionInfo: 'Enter the current version',
    complain: 'The inscription "Complain"',
    complainInfo: 'The inscription "Complain"',
    developerContact: 'Contact Developer',
    developerContactInfo: 'Contact Developer',
    developerSite: 'The inscription "Website"',
    developerSiteInfo: 'The inscription "Website"',
    developerUrl: 'Website',
    developerUrlInfo: 'Link to company website',
    developerEmailLabel: 'The inscription "Email"',
    developerEmailLabelInfo: 'The inscription "Email"',
    developerEmail: 'Email',
    developerEmailInfo: 'Company Email',
    developerAddressLabel: 'The inscription "Address"',
    developerAddressLabelInfo: 'The inscription "Address"',
    developerAddress: 'Company Address',
    developerAddressInfo: 'Company Address',
    politicsPrivacy: 'The inscription "Privacy Policy"',
    politicsPrivacyInfo: 'The inscription "Privacy Policy"',
    politicsPrivacyUrl: 'Policy URL',
    politicsPrivacyUrlInfo: 'Policy URL'
  },
  ttPreland: {
    title: 'Tiktok preland',
    titleInfo: 'TikTok pre-lander captions',
    template: 'Template',
    templateInfo: 'Select a preland template',
    variant: 'Variant {num}',
    ttHeader: 'Would you like to go to',
    ttHeaderInfo: 'TikTok page question',
    ttYes: '"Yes" answer on the "TikTok" page',
    ttNo: '"No" answer on the "TikTok" page',
    ttForce: 'Required preland for any hosts',
    ttForceInfo: 'If not set then the preland is shown only for Andriod Chrome WebView hosts'
  },
  cookies: {
    title: 'Cookies',
    titleInfo: 'Use of cookies',
    cookiesText: 'Request cookies',
    cookiesTextInfo: 'Text "Do you agree to the use of cookies?"',
    cookiesAgree: 'Agree',
    cookiesAgreeInfo: 'Agree button text',
    cookiesDisagree: 'Disagree',
    cookiesDisagreeInfo: 'Disagree button text'
  },
  translateDeleted: 'Translation deleted'
}
