<template>
  <modal
    ref="modal"
    id="cloudflare-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group" :class="apiValidationErrors.enable ? 'has-danger' : ''">
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" id="enable" name="enable"
                   v-model="enable">
            {{ enable ? 'Активный' : 'Не активный' }}
            <i class="input-helper"></i>
          </label>
        </div>
        <label v-if="apiValidationErrors.enable" id="enable-error" class="error mt-2 text-danger"
               for="enable">{{ apiValidationErrors.enable[0] }}</label>
      </div>
      <div v-if="isCreate" class="form-group" :class="apiValidationErrors['_id'] ? 'has-danger' : ''">
        <label for="email">E-mail от аккаунта</label>
        <input class="form-control" id="email" name="email" type="text" tabindex="1"
               v-model="email"
               :class="apiValidationErrors['_id'] ? 'form-control-danger' : ''"
               placeholder="Введите E-mail от аккаунта"
               @focus="clearError('_id')">
        <label v-if="apiValidationErrors['_id']" class="error mt-2 text-danger"
               for="email">{{ apiValidationErrors['_id'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['key'] ? 'has-danger' : ''">
        <label for="key">API ключ</label>
        <input class="form-control" id="key" name="key" type="text" tabindex="1"
               v-model="key"
               :class="apiValidationErrors['key'] ? 'form-control-danger' : ''"
               placeholder="Введите API ключ"
               @focus="clearError('key')">
        <label v-if="apiValidationErrors['key']" class="error mt-2 text-danger"
               for="key">{{ apiValidationErrors['key'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['token'] ? 'has-danger' : ''">
        <label for="token">API токен (БЕТА)</label>
        <input class="form-control" id="token" name="token" type="text" tabindex="1"
               v-model="token"
               :class="apiValidationErrors['token'] ? 'form-control-danger' : ''"
               placeholder="Введите API токен"
               @focus="clearError('token')">
        <label v-if="apiValidationErrors['token']" class="error mt-2 text-danger"
               for="token">{{ apiValidationErrors['token'][0] }}</label>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'

const defaultValues = () => ({
  email: '',
  key: '',
  token: '',
  enable: true
})

export default {
  name: 'AdminCloudflareForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      isCreate: true,
      processing: false,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    title () {
      return this.isCreate ? 'Добавление аккаунта' : `Редактирование ${this.email}`
    }
  },
  methods: {
    handlerCreate () {
      this.skip()
      this.isCreate = true
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerUpdate ({_id, key, token, enable}) {
      this.skip()
      this.isCreate = false
      this.email = _id
      this.key = key
      this.token = token
      this.enable = enable
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.$store.dispatch(this.isCreate ? 'adminCloudflares/create' : 'adminCloudflares/update', {
          _id: this.email,
          key: this.key,
          token: this.token,
          enable: this.enable
        })
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
