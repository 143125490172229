import service from '@/store/services/comments-service'
import middleware from './middleware'

const actions = {
  delete: ({commit, dispatch}, params) => {
    return service.delete(params)
      .then(() => {
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  lastPath: state => state.lastPath
}

const comments = {
  namespaced: true,
  getters,
  actions
}

export default comments
