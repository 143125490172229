<template>
  <div>
    <themesForm ref="themesForm"/>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Темы</h4>
            <p class="card-description">Управление темами</p>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>
                    <button
                      @click="handlerCreate"
                      class="btn btn-icons btn-inverse-primary"
                    ><i class="icon-plus"></i></button>
                    <button
                      @click="fetchPage()"
                      class="btn btn-icons btn-inverse-light">
                      <i class="icon-refresh"></i>
                    </button>
                  </th>
                  <th>Название темы</th>
                  <th>Алиас темы</th>
                  <th>Тип темы</th>
                  <th>Id</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="theme in models" :key="theme.id">
                  <td>
                    <button @click="handlerUpdate(theme)"
                            class="btn btn-icons btn-inverse-primary">
                      <i class="icon-pencil"></i>
                    </button>
                    <button @click="handlerDelete(theme)" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                  </td>
                  <td>{{ theme.name | truncate(20) }}</td>
                  <td>{{ theme.alias | truncate(20) }}</td>
                  <td>{{ (types.find(type => type.id === theme.type).alias) | truncate(20) }}</td>
                  <td>{{ theme.id }}</td>

                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import themesForm from '@/pages/admin/landing-pages/components/themesForm.vue'

export default {
  name: 'AdminLandingPagesThemes',
  components: {themesForm},
  data () {
    return {
      loading: true,
      count: 0,
      models: [],
      limit: 20,
      page: 1,
      types: []
    }
  },
  computed: {
    ...mapGetters({
      themesData: 'landingPages/themesData',
      typesData: 'landingPages/types'
    }),
    pageCount: function () {
      return Math.ceil(this.count / this.limit)
    }
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.fetchDataTypes({
      limit: this.limit,
      page: 1,
      sort: 'id'
    })
    this.types = this.typesData
    await this.asyncData()
  },
  methods: {
    ...mapActions({
      fetchDataTypes: 'landingPages/fetchDataTypes',
      fetchDataThemes: 'landingPages/fetchDataThemes',
      deleteThemes: 'landingPages/deleteThemes'
    }),
    async fetchData () {
      await this.fetchDataThemes({
        limit: this.limit,
        page: this.page,
        sort: 'id'
      })
      this.models = this.themesData.models
      this.count = this.themesData.count
    },
    async fetchPage () {
      this.loading = true
      await this.fetchData()
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async asyncData () {
      this.loading = true
      await this.fetchData()
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async handlerPagination (page) {
      this.page = page
      await this.asyncData()
    },
    async handlerCreate () {
      if (await this.$refs.themesForm.handlerCreate()) {
        await this.handlerPagination(1)
      }
    },
    async handlerUpdate (model) {
      if (await this.$refs.themesForm.handlerUpdate(model)) {
        await this.handlerPagination(1)
      }
    },
    async handlerDelete (model) {
      if (confirm(`Удалить тему?`)) {
        await this.deleteThemes(model.id)
        await this.handlerPagination(1)
      }
    }
  }
}
</script>
