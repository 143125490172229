<template>
  <div class="d-flex flex-grow-1">
    <div class="flex-grow-1">
      <div class="form-group mb-0">
        <label :for="`facebook-pixel-${index}`">Facebook Pixel
          <info
            :message="$t('pwas.components.facebook.pixelInfo')"/>
        </label>
        <div class="position-relative d-flex align-items-center">
          <slot></slot>
          <input
            :id="`facebook-pixel-${index}`"
            placeholder="111111111111"
            v-model="pixel"
            type="number"
            class="form-control pixel-input"
            @change="$emit('input', $event.target.value)"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'

export default {
  name: 'Config',
  props: ['index', '_id'],
  components: {Info},
  data () {
    return {
      pixel: ''
    }
  },
  created: function () {
    this.pixel = this._id
  }
}
</script>
<style lang="sass" scoped>
.pixel-input
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    display: none
    -webkit-appearance: none
    margin: 0
</style>
