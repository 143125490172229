<template>
  <ToggleItem
    :header="$t('landingPages.mainForm.header')"
    :info="$t('landingPages.mainForm.info')"
  >
    <div class="row row_mob_sg">
      <div class="form-group col-12 col-xl-6" :class="apiValidationErrors[`name`] ? 'has-danger' : ''">
        <label for="name">{{ $t('landingPages.mainForm.name') }}</label>
        <input
          type="text"
          v-model="handleName"
          class="form-control"
          id="name"
          name="name"
        />
        <label v-if="apiValidationErrors[`name`]"
               class="error mt-2 text-danger"
               :for="`name`">
          {{ $t(apiValidationErrors[`name`][0]) }}
        </label>
      </div>
      <BaseFromGroup
        id="tags"
        class="col-12 col-xl-6"
        :label="$t('landingPages.mainForm.tags')"
        :has-error="apiValidationErrors[`tags`]"
        :error-text="apiValidationErrors[`tags`] ? $t(apiValidationErrors[`tags`][0]) : ''"
      >
        <BaseSelect
          v-model="handleTags"
          id="tags"
          :multiple="true"
          :taggable="true"
          :placeholder="$t('landingPages.mainForm.tagsTips')"
          :options="handleTags"
          :create-option="option => option"
        />
      </BaseFromGroup>
      <BaseFromGroup
        id="type"
        name="type"
        :label="$t('landingPages.mainForm.type')"
        class="form-group col-12 col-xl-6"
        :has-error="apiValidationErrors.type"
        :error-text="apiValidationErrors?.type ? apiValidationErrors?.type[0] : ''"
      >
        <BaseSelect
          v-model="handleType"
          id="type"
          name="type"
          :clearable="false"
          :options="typesOptions"
        />
      </BaseFromGroup>
      <BaseFromGroup
        id="theme"
        name="theme"
        :label="$t('landingPages.mainForm.themeSearsh')"
        class="form-group col-12 col-xl-6"
        :has-error="apiValidationErrors.theme"
        :error-text="apiValidationErrors?.theme ? apiValidationErrors?.theme[0] : ''"
      >
        <BaseSelect
          v-model="handleTheme"
          id="theme"
          name="theme"
          :clearable="false"
          @change="$emit('update:theme', $event.target.value)"
          :options="themesOptions"
        />
      </BaseFromGroup>
    </div>
  </ToggleItem>
</template>
<script>
import ToggleItem from '@/components/landing-pages/ToggleItem.vue'
import formMixin from '@/mixins/form-mixin'
import {mapGetters} from 'vuex'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  components: {BaseSelect, BaseFromGroup, ToggleItem},
  mixins: [formMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      default () {
        return []
      }
    },
    theme: {
      type: String,
      required: true
    },
    errors: Object
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({types: 'landingPages/types'}),
    themes () {
      return this.$store.getters['landingPages/themes'].filter(theme => theme.type === this.type)
    },
    themesOptions () { return this.themes.map(theme => ({...theme, text: theme.name})) },
    typesOptions () { return this.types.map(type => ({...type, text: type.name})) },
    handleName: {
      get () {
        return this.name
      },
      set (input) {
        this.$emit('update:name', input)
      }
    },
    handleType: {
      get () {
        return this.type
      },
      set (input) {
        this.$emit('update:type', input)
      }
    },
    handleTags: {
      get () {
        return this.tags
      },
      set (input) {
        this.$emit('update:tags', input)
      }
    },
    handleTheme: {
      get () {
        return this.theme
      },
      set (input) {
        this.$emit('update:theme', input)
      }
    }
  }
}
</script>
