<template>
  <div class="col-12 grid-margin">
    <div class="card card-statistics">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="d-flex justify-content-between border-right card-statistics-item">
              <div>
                <h1>{{ count }}</h1>
                <p class="text-muted mb-0">Всего пользователей</p>
              </div>
              <i class="icon-people text-primary icon-lg"></i>
            </div>
          </div>
          <div :class="{active : isClickedUsers}" class="card col-md-6 col-lg-3" role="button">
            <button @click="handlerCreatedAt" class="card__btn d-flex justify-content-between border-right card-statistics-item">
              <div><h1>{{ countNew }}</h1>
                <p class="text-muted mb-0">Новых пользователей</p>
              </div>
              <i class="icon-user-follow text-primary icon-lg"></i>
            </button>
          </div>
          <div :class="{active : isRateActive}" class="card col-md-6 col-lg-3" role="button">
            <button @click="handlerStateActive(isRateActive)" class="card__btn d-flex justify-content-between border-right card-statistics-item">
              <div><h1>{{ countActive }}</h1>
                <p class="text-muted mb-0">Тариф активен</p>
              </div>
              <i class="icon-user-following text-primary icon-lg"></i>
            </button>
          </div>
          <div :class="{active : isRateExpired}" class="card col-md-6 col-lg-3" role="button">
            <button @click="handlerStateExpired" class="card__btn d-flex justify-content-between card-statistics-item">
              <div>
                <h1>{{ countExpired }}</h1>
                <p class="text-muted mb-0">Тариф истек</p>
              </div>
              <i class="icon-user-unfollow text-primary icon-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UsersStatistics',
  data () {
    return {
      isClickedUsers: false,
      isRateActive: false,
      isRateExpired: false
    }
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    count () {
      return this.$store.getters['adminUsers/countAll']
    },
    countNew () {
      return this.$store.getters['adminUsers/countNew']
    },
    countActive () {
      return this.$store.getters['adminUsers/countActive']
    },
    countExpired () {
      return this.$store.getters['adminUsers/countExpired']
    }
  },
  methods: {
    handlerStateActive () {
      this.filters.state = this.filters.state === 0 ? '' : 0
      this.isRateActive = !this.isRateActive
      this.isRateExpired = false
      this.isClickedUsers = false
    },
    handlerStateExpired () {
      this.filters.state = this.filters.state === 1 ? '' : 1
      this.isRateExpired = !this.isRateExpired
      this.isRateActive = false
      this.isClickedUsers = false
    },
    handlerCreatedAt () {
      // eslint-disable-next-line no-undef
      this.filters.createdAt = this.filters.createdAt ? '' : moment().date(1).format('YYYY-MM-DD')
      this.isClickedUsers = !this.isClickedUsers
      this.isRateActive = false
      this.isRateExpired = false
    }
  }
}
</script>

<style scoped>
  button[disabled] {
    opacity: 0.5;
  }
  .card {
    padding: 0;
    border: solid 1px transparent;

  }
  .card__btn {
    background: transparent;
    border: none;

  }
  .active {
    border: solid 1px #246ec3;
  }
</style>
