<template>
  <modal
    ref="modal"
    id="installation-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <select2
        ref="select2"
        class="form-group"
        inputClass="form-control select_white_g"
        id="user"
        name="user"
        v-model="user"
        placeholder="Пользователь"
        :options="ownerOptions"
        :settings="userSettings"
      >
        <template v-slot:before>
          <div class="sibmti_sdf">Пользователь</div>
        </template>
        <template v-slot:after>
          <label v-if="apiValidationErrors.user" class="error mt-2 info_warning_gdg" for="user">
            {{ apiValidationErrors.user[0] }}
          </label>
        </template>
      </select2>
      <div class="form-group" :class="apiValidationErrors.tier ? 'has-danger' : ''">
        <label for="tier">Тир</label>
        <select class="form-control" id="tier" v-model="tier" @input="handlerOneUpdate($event, model, 'tier')">
          <option :key="item" v-for="item in tiers" :value="item">
            {{ item }}
          </option>
        </select>
        <label v-if="apiValidationErrors.tier" class="error mt-2 text-danger" for="tier">
          {{ apiValidationErrors.tier[0] }}
        </label>
      </div>
      <div class="form-group">
        <label for="name">Название</label>
        <input type="text" id="name.ru" v-model="name.ru"
               class="form-control" min="3" max="150"
               :class="apiValidationErrors['name.ru'] ? 'form-control-danger' : ''"
               @focus="clearError('name.ru')">
        <small class="form-text text-muted">Название тарифа на русском</small>
        <label v-if="apiValidationErrors['name.ru']" class="error mt-2 text-danger" for="name.ru">
          {{ apiValidationErrors['name.ru'][0] }}
        </label>
        <input type="text" id="name.en" v-model="name.en"
               class="form-control" min="3" max="150"
               :class="apiValidationErrors['name.en'] ? 'form-control-danger' : ''"
               @focus="clearError('name.en')">
        <small class="form-text text-muted">Название тарифа на английском</small>
        <label v-if="apiValidationErrors['name.en']" class="error mt-2 text-danger" for="name.en">
          {{ apiValidationErrors['name.en'][0] }}
        </label>
        <input type="text" id="name.uk" v-model="name.uk"
               class="form-control" min="3" max="150"
               :class="apiValidationErrors['name.uk'] ? 'form-control-danger' : ''"
               @focus="clearError('name.uk')">
        <small class="form-text text-muted">Название тарифа на украинском</small>
        <label v-if="apiValidationErrors['name.uk']" class="error mt-2 text-danger" for="name.uk">
          {{ apiValidationErrors['name.uk'][0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.rate ? 'has-danger' : ''">
        <label for="rate">Ставка {{ user ? 'пользователя' : '' }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <input type="number" step="0.01" id="rate" placeholder="0.07" v-model="rate"
                 class="form-control" min="0.01" max="1"
                 :class="apiValidationErrors.rate ? 'form-control-danger' : ''"
                 @focus="clearError('rate')">
        </div>
        <small class="form-text text-muted">Ставка за установку</small>
        <label v-if="apiValidationErrors.rate" class="error mt-2 text-danger" for="rate">
          {{ apiValidationErrors.rate[0] }}
        </label>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import formMixin from '@/mixins/form-mixin'
import {countries} from '@pwa.group/pwa.dictionary'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'

const defaultValues = () => ({
  id: null,
  name: {},
  tier: 1,
  rate: null
})

export default {
  name: 'InstallationForm',
  mixins: [formMixin, select2AjaxMixin],
  components: {Modal, SubmitModalForm, Select2},
  data () {
    return {
      tiers: [1, 2, 3],
      processing: false,
      ...defaultValues(),
      user: undefined,
      userSettings: {},
      ownerOptions: [],
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  async created () {
    this.userSettings = {...this.getSelect2AjaxSettings('users/filter/installing'), allowClear: true}
  },
  computed: {
    title () {
      if (this.id == null) {
        return ''
      }
      const index = countries.findIndex(country => country.id === this.id)
      return this.$t(countries[index].text)
    }
  },
  methods: {
    async handlerUpdate ({_id, name, rate, userRate, tier}) {
      if (this.user) this.ownerOptions = await this.getSelect2AjaxOptions(this.user, 'users/filter')
      this.skip()
      this.id = _id
      this.name = name
      this.tier = tier
      this.rate = userRate || rate
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        const payload = {
          name: this.name,
          rate: this.rate,
          tier: this.tier,
          id: this.id,
          user: this.user
        }
        await this.$store.dispatch('adminInstallations/update', payload)
        await this.$store.dispatch('alerts/success', 'Успешно обновлён')
        this.resetApiValidation()
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
      this.processing = false
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    setUserId (id) {
      this.user = id
    }
  }
}
</script>
