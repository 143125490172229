<template>
  <div class="PageBills page-full-fluid-width parent_tooltip" v-if="accesses.finance.view">
    <div class="row">
      <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card bills_class_g">
        <div class="card">
          <div class="card-body pt-0">
            <FinanceTabs />
            <div class="amount-form" v-if="accesses.finance.update">
              <amounts-form v-if="data.length >= 1"/>
            </div>
            <div class="row" v-if="!loading">
              <div class="col-12" v-if="!loading && data.length >= 1">
                <div class="wrapper-table no-hover">
                  <div class="table-responsive">
                    <div id="data_table_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                      <div class="row">
                        <div class="col-sm-12">
                          <table id="finance-listing" class="table_border_style table">
                            <table-sort-head :items="heads" v-model="sort"/>
                            <tbody>
                            <bill-field v-for="item in data" v-bind:key="item._id" v-bind:item="item"/>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <nav v-if="pageCount > 1">
                  <paginate ref="paginate"
                            v-model="page"
                            :page-count="pageCount"
                            :click-handler="pagination"
                            :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                            :next-text="'<i class=\'icon-arrow-right\'></i>'"
                            :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                            :page-class="'page_item_gd'"
                            :page-link-class="'page_link_g d_flex justify_center align_center'"
                            :prev-class="'page-item_go'"
                            :next-class="'page-item_go'"
                            :prev-link-class="'page-link_go d_flex justify_center align_center'"
                            :next-link-class="'page-link_go d_flex justify_center align_center'"
                            :active-class="'active'">
                  </paginate>
                </nav>
              </div>
              <div class="col-12" v-else>
                <div class="no_pwa_here no_pwa_transac">
                  <div class="in_to_nothing_pwa text_center">
                    <div class="image_no_pw anim_mobs_go">
                      <person-in-chair-with-cigar/>
                    </div>
                    <div class="texts_pwa_no">
                      <div class="text_this_no_pw">{{ $t('finance.bills.noHave') }}</div>
                      <amounts-form class="finance_af_add d_flex align_center justify_center" v-if="accesses.finance.update"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="loading_cont_go" v-if="loading">
              <div class="in_to_load_sf d_flex justify_center">
                <img src="/images/loader.svg" alt="" width="100">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bitcoin-form id="bitcoin-form" ref="bitcoin-form"/>
    <trc-form id="trc-form" ref="trc-form"/>
    <info-modal id="bill-info" ref="modal-info"/>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import * as components from '@/components'
import tooltipMixin from '@/mixins/tooltip-mixin'
import {AmountsForm, BitcoinForm, BillField, TrcForm} from './components'
import titleMixin from '@/mixins/title-mixin'
import accessesMixin from '@/mixins/accesses-mixin'
import InfoModal from '@/components/modal/Info.vue'
import PersonInChairWithCigar from '@/components/animatedSvgs/PersonInChairWithCigar.vue'
import FinanceTabs from './components/FinanceTabs.vue'

export default {
  name: 'Bills',
  title: function () {
    return this.$t('titles.bills')
  },
  components: {
    PersonInChairWithCigar,
    AmountsForm,
    BillField,
    TableSortHead: components.TableSortHead,
    InfoModal,
    BitcoinForm,
    TrcForm,
    FinanceTabs
  },
  mixins: [formMixin, tooltipMixin, titleMixin, accessesMixin],
  watch: {
    sort: {
      handler: async function () {
        await this.getData()
      }
    }
  },
  data () {
    return {
      loading: false,
      finance_table: false,
      amount: 0,
      bill: false,
      limit: 10,
      itemsCount: 0,
      page: 1,
      data: [],
      timer: '',
      sort: '-createdAt',
      heads: [
        {label: 'finance.bills.status', title: 'finance.bills.statusTitle', sort: 'status'},
        {label: 'finance.bills.amount', title: 'finance.bills.amountTitle', sort: 'amount'},
        {label: 'finance.bills.total', title: 'finance.bills.totalTitle'},
        {label: 'finance.bills.method', title: 'finance.bills.methodTitle', sort: 'method'},
        {label: 'finance.bills.created', title: 'finance.bills.createdTitle', sort: 'createdAt'},
        {label: 'finance.bills.actions', class: 'text-right'}
      ]
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.itemsCount / this.limit)
    }
  },
  async created () {
    await this.getData()
    // this.timer = setInterval(this.getData, 30000)
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  },
  methods: {
    async getData () {
      this.loading = true
      await this.$store.dispatch('billing/index', {
        limit: this.limit,
        page: this.page,
        sort: this.sort
      })
      this.itemsCount = this.$store.getters['billing/count']
      this.data = this.$store.getters['billing/bills']
      this.loading = false
      this.initTooltip()
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
    async pagination (page) {
      this.page = page
      await this.getData()
    },
    async handlerPaid (_id) {
      if (await this.check(_id)) {
        this.hideForm()
        await this.$store.dispatch('alerts/success', this.$t('finance.bills.paidSuccess'))
      } else {
        await this.getData()
        this.showForm()
      }
    },
    async handlerInfo (_id) {
      if (await this.check(_id)) {
        this.hideForm()
        await this.$store.dispatch('alerts/success', this.$t('finance.bills.paidSuccess'))
      } else {
        await this.getData()
        // eslint-disable-next-line no-undef
        jQuery('#modal_info').modal('show')
      }
    }
  }
}
</script>
<style scoped lang="sass">
.amount-form
  display: flex
  justify-content: flex-end
  margin-bottom: 22px
  padding-bottom: 32px
  border-bottom: 1px solid rgba(255, 255, 255, .15)
</style>
