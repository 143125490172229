import service from '@/store/services/admin/payment-methods-service'
import middleware from '../middleware'

const state = {
  paymentMethods: [],
  paymentMethod: {},
  list: []
}

const mutations = {
  setPaymentMethods: (state, payload) => {
    state.paymentMethods = payload
  },
  setPaymentMethod: (state, payload) => {
    state.paymentMethod = payload
  },
  setList: (state, payload) => {
    let list = {}
    payload.forEach(value => {
      list[value._id] = value.name
    })
    state.list = list
  }
}

const actions = {
  index: ({commit, dispatch}, params) => service.index(params)
    .then(res => {
      commit('setPaymentMethods', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  list: ({commit, dispatch}, params) => service.list(params)
    .then(res => {
      commit('setList', res)
    })
    .catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  sort: ({commit, dispatch}, params) => service.sort(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  create: ({commit, dispatch}, params) => service.create(params)
    .then(res => {
      commit('setPaymentMethod', res)
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  update: ({commit, dispatch}, params) => service.update(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    }),

  delete: ({commit, dispatch}, params) => service.delete(params)
    .then(() => {
    }).catch(error => {
      middleware.validateAut(error)
      throw error
    })
}

const getters = {
  paymentMethods: state => state.paymentMethods,
  paymentMethod: state => state.paymentMethod,
  list: state => state.list
}

const adminPaymentMethods = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminPaymentMethods
