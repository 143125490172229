<template>
  <div class="flex-grow-0 mb-0 ml-2 d-flex">
    <modal ref="modal" :id="`s2s-postback-${event}-modal`" class="fade"
           classContent="modal-content" classDialog="modal-dialog">
      <div class="title_mod_uplo">{{ $t(`pwas.tracker.s2sPostback.${event}.setting`) }}</div>
      <form @submit.prevent="handlerSubmit">
        <div class="checkbox_item_g mt-4 mb-4">
          <label class="labek_check_sg">
            <input type="checkbox" class="form-check-input" v-model="enable">
            <div class="check_icd_G"></div>
            <span class="txt_chek_xg" v-if="enable">{{ $t('trackers.form.active') }}</span>
            <span class="txt_chek_xg" v-else>{{ $t('trackers.form.notActive') }}</span>
          </label>
        </div>
        <div class="form-group">
          <BaseFromGroup
            :id="`s2s-postback-${event}-method`"
            :name="`s2s-postback-${event}-method`"
            :label="$t(`pwas.tracker.s2sPostback.method`)"
          >
            <BaseSelect
              v-model="method"
              :id="`s2s-postback-${event}-method`"
              :name="`s2s-postback-${event}-method`"
              :options="methodOptions"
              :clearable="false"
            />
          </BaseFromGroup>
        </div>
        <div class="footer_modl_akdhg">
          <div class="form-group">
            <div class="in_topshgdjf_g d_flex align_center">
              <div class="item_avt_butt_jg">
                <button type="submit" class="ful_butt_green d_flex align_center justify_center">{{ $t('general.save') }}</button>
              </div>
              <div class="item_avt_butt_jg">
                <a href="javascript:void(0);" @click="handlerCancel"
                   class="border_butt_green d_flex align_center justify_center">{{ $t('general.cancel') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </modal>
    <button class="btn_round_act butt_ba_ps d_flex align_center justify_center" type="button" @click="handlerOpen">
      <i class="icon-settings"></i>
    </button>
  </div>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'

export default {
  name: 'EventForm',
  components: {BaseFromGroup, BaseSelect, Modal},
  data () {
    return {
      enable: this.value.enable,
      method: this.value.method
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    apiValidationErrors: {
      type: Object,
      required: true
    },
    event: {
      type: String,
      required: true
    }
  },
  computed: {
    methodOptions () {
      return [{id: 'GET', text: 'GET'}, {id: 'POST', text: 'POST'}]
    }
  },
  methods: {
    clearError (attribute) {
      if (this.apiValidationErrors[attribute] !== undefined) {
        this.apiValidationErrors[attribute] = undefined
      }
    },
    handlerSubmit () {
      this.value.method = this.method
      this.value.enable = this.enable
      this.$emit('input', this.value)
      this.$emit('save', true)
      this.handlerCancel()
    },
    handlerOpen () {
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
    }
  }
}
</script>
