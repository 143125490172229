import api from './api'
import { getSearchParams } from '../list'

export default {
  list: (payload) => api.get('pwas/pushes/templates?' + getSearchParams(payload)),
  create: payload => api.post('pwas/pushes/templates', payload),
  read: id => api.get(`pwas/pushes/templates/${id}`),
  update: ({id, ...payload}) => api.put(`pwas/pushes/templates/${id}`, payload),
  delete: id => api.delete(`pwas/pushes/templates/${id}`),
  copy: ({ id, payload }) => api.post(`pwas/pushes/templates/${id}/copy`, payload),
  upload: ({ type, payload }) => api.file(`pwas/pushes/templates/upload/${type}`, payload),
  removeImage: ({ name, type }) => api.patch(`pwas/pushes/templates/upload/${type}`, { name }),
  filter: (payload) => api.get('pwas/pushes/templates/filter?' + getSearchParams(payload))
}
