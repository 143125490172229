export default {
  analyticsGroups: 'Групування',
  analyticsUniques: 'Уніки',
  columns: 'Колонки',
  filters: 'Фільтри',
  filtersSettingTitle: 'Налаштування фільтрів',
  columnsSettingTitle: 'Налаштування колонок',
  date: 'Дата',
  clicks: {
    title: 'Кліки',
    installs: 'Встановлення',
    pushes: 'Пуш підписки',
    opens: 'Входи до PWA',
    reopens: 'Повторні входи'
  },
  postbacks: {
    title: 'Постбеки',
    registrations: 'Реєстрація',
    deposits: 'Депозити'
  },
  users: {
    title: 'Користувач',
    ip: 'IP',
    country: 'Країни',
    userAgentDeviceType: 'Тип пристрою',
    userAgentDeviceVendor: 'Бренд пристрою',
    userAgentDeviceModel: 'Модель пристрою',
    userAgentOs: 'ОС',
    userAgentBrowserName: 'Браузер'
  },
  trackers: {
    title: 'Трекер',
    tracker: 'Трекер',
    referer: 'Реферал',
    redirect: 'Редірект',
    tag: 'Тег',
    host: 'Домен',
    vertical: 'Вертикаль'
  },
  params: {
    title: 'Параметри',
    param: 'Параметр'
  },
  filtersLabel: {
    installs: 'Фільтр за встановленням',
    pushes: 'Фільтр за підписками',
    opens: 'Фільтр за відкриттями',
    reopens: 'Фільтр повторних входів',
    registrations: 'Фільтр за реєстраціями',
    deposits: 'Фільтр за депозитами',
    ip: 'Фільтр за IP',
    id: 'Фільтр за ID',
    country: 'Фільтр за країнами',
    userAgentDeviceType: 'Фільтр за типом пристрою',
    userAgentDeviceVendor: 'Фільтр за брендом пристрою',
    userAgentDeviceModel: 'Фільтр за моделлю пристрою',
    userAgentOs: 'Фільтр за ОС',
    userAgentBrowserName: 'Фільтр за браузером',
    tracker: 'Фільтр за трекером',
    referer: 'Фільтр за рефералом',
    redirect: 'Фільтр за редиректом',
    pwa: 'Фільтр за PWA',
    tag: 'Фільтр за тегом',
    host: 'Фільтр за доменом',
    vertical: 'Фільтр за вертикаллю',
    verticals: ['Нутра', 'Бетинг', 'Дейтинг', 'Гемблінг', 'Трейдинг', 'Крипта'],
    params: 'Фільтр за {param}',
    date: 'Фільтр за датою',
    today: 'Сьогодні',
    yesterday: 'Вчора',
    prevSevenDay: 'Попередній тиждень',
    prevMonth: 'Попередній місяць',
    prev3Month: 'Попередні 3 місяці',
    thisYear: 'Цей рік',
    preLandingPage: 'Прелендiнг',
    postLandingPage: 'Постлендiнг',
    preLandingPageUnique: 'Унiк прелендiнг',
    postLandingPageUnique: 'Унiк постлендiнг'
  },
  columnsLabels: {
    cpi: 'CPI',
    analyticsBy: 'Аналітика по',
    uniqueLabel: 'Дані користувача',
    actionsLabel: 'Дії',
    postbackLabel: 'Інформація з Postback',
    pwaLabel: 'Трекінг',
    calculateLabel: 'Обчислення',
    uniques: 'Унікальні',
    hits: 'Кліки',
    installs: 'Встановлення',
    pushes: 'PUSH підписки',
    registrations: 'Реєстрації',
    deposits: 'Депозити',
    opens: 'Відкриття',
    reopens: 'Повторний запуск',
    country: 'Країни',
    createdAt: 'Створено',
    uniquesToInstalls: 'CR% унік/інстал',
    uniquesToPush: 'CR% унік/пуш',
    installsToPush: 'CR% інстал/пуш',
    date: 'Дата',
    tracker: 'Трекер',
    host: 'Домен',
    referer: 'Реферальне посилання',
    vertical: 'Вертикаль',
    redirect: 'Редірект',
    userAgentDeviceType: 'Тип пристрою',
    userAgentDeviceVendor: 'Бренд пристрою',
    userAgentDeviceModel: 'Модель пристрою',
    userAgentBrowserName: 'Браузер',
    userAgentOs: 'ОС',
    uniquesTitle: 'Унікальні переходи',
    hitsTitle: 'Неунікальні переходи',
    installsTitle: 'Кількість установок PWA',
    pushesTitle: 'Кількість підписок на Push',
    registrationsTitle: 'Кількість реєстрацій',
    depositsTitle: 'Кількість депозитів',
    opensTitle: 'Кількість входів у PWA',
    reopensTitle: 'Кількість повторних входів у PWA',
    createdAtTitle: 'Дата першого кліка',
    uniquesToInstallsTitle: 'Відсоткове співвідношення унік/інстал. Розраховується за формулою CR = Інстал/Унік*100',
    uniquesToPushTitle: 'Відсоткове співвідношення унік/пуш. Розраховується за формулою CR = Пуш/Унік*100',
    installsToPushTitle: 'Відсоткове співвідношення інстал/пуш. Розраховується за формулою CR = Пуш/Інстал*100',
    landingsLabel: 'Лендінги',
    preLandingPage: 'Прелендінг',
    postLandingPage: 'Постлендінг',
    preLandingPageUnique: 'Унік прелендінг',
    postLandingPageUnique: 'Унік постлендінг'
  },
  groupsLabels: {
    date: 'Групування за Датою',
    tag: 'Групування за Тегом',
    host: 'Групування за Доменом',
    country: 'Групування за Країною',
    pwa: 'Групування за PWA',
    vertical: 'Групування за Вертикаллю',
    userAgentDeviceType: 'Групування за Пристроєм',
    userAgentDeviceVendor: 'Групування за Брендом',
    userAgentDeviceModel: 'Групування за Моделлю',
    userAgentBrowserName: 'Групування за Браузером',
    userAgentOs: 'Групування за ОС',
    param: 'Групування за "{param}"',
    by: 'По',
    dateTitle: 'Аналітика за Датою',
    trackerTitle: 'Аналітика за Трекером',
    hostTitle: 'Аналітика за Доменом',
    tagTitle: 'Аналітика за Тегом',
    countryTitle: 'Аналітика за Країною',
    pwaTitle: 'Аналітика за PWA',
    verticalTitle: 'Аналітика за Вертикаллю',
    userAgentDeviceTypeTitle: 'Аналітика за Пристроєм',
    userAgentDeviceVendorTitle: 'Аналітика за Брендом',
    userAgentDeviceModelTitle: 'Аналітика за Моделлю',
    userAgentBrowserNameTitle: 'Аналітика за Браузером',
    userAgentOsTitle: 'Аналітика за ОС',
    preLandingPageTitle: 'Аналітика за прелендингом',
    postLandingPageTitle: 'Аналітика за постлендингом',
    dateLabel: 'Дата',
    trackerLabel: 'Трекер',
    hostLabel: 'Домен',
    countryLabel: 'Країна',
    pwaLabel: 'PWA',
    verticalLabel: 'Вертикаль',
    userAgentDeviceTypeLabel: 'Пристрій',
    userAgentDeviceVendorLabel: 'Бренд',
    userAgentDeviceModelLabel: 'Модель',
    userAgentBrowserNameLabel: 'Браузер',
    userAgentOsLabel: 'ОС',
    landingsLabel: 'Лендінги',
    preLandingPage: 'Групування за прелендінгом',
    postLandingPage: 'Групування за постлендінгом'
  },
  report: {
    button: 'Звіти',
    title: 'Генерація звіту',
    body: {
      open: 'До вже існуючої аналітики будуть додані витрати на купівлю доменів, в нашій системі, та прибуток із Postback. Звіт буде доступний за посиланням в форматі CSV кодуванням UTF-8',
      successful: `Ваш звіт доступний за <a download="report.csv" class="link_green" href="{csv}" target="_blank">посиланням</a>`
    },
    hint: {
      open: 'Для початку генерації звіта натисніть кнопку "Запустити"',
      loading: 'Триває генерація звіту...',
      successful: 'Генерація заверешена',
      error: 'Під час створення звіту виникла помилка'
    }
  },
  landings: {
    title: 'Лендiнги',
    preLandingPage: 'Прелендiнг',
    postLandingPage: 'Постлендiнг',
    preLandingPageUnique: 'Унiк прелендiнг',
    postLandingPageUnique: 'Унiк постлендiнг'
  },
  empty: 'Статистика відсутня або несформована в даний момент',
  pwaRemoved: 'Видалене PWA'
}
