import api from './api'

const getSearchParams = ({filter, page, limit, sort}) => {
  const searchParams = new URLSearchParams()
  searchParams.set('page', page)
  searchParams.set('limit', limit)
  searchParams.set('sort', sort)
  if (filter != null && Object.keys(filter).length) {
    for (const [prop, value] of Object.entries(filter)) {
      if (value != null && value !== '') {
        searchParams.append(`filter[${prop}]`, value)
      }
    }
  }
  return decodeURI(searchParams.toString())
}

export default {
  create: payload => api.post('pwas/templates', payload),
  update: ({id, ...payload}) => api.put(`pwas/templates/${id}`, payload),
  delete: id => api.delete(`pwas/templates/${id}`),
  view: id => api.get(`pwas/templates/${id}`),
  list: payload => api.get('pwas/templates?' + getSearchParams(payload)),
  filter: payload => api.get('pwas/templates/filter?' + getSearchParams(payload))
}
