export default {
  title: 'Campaigns',
  add: 'Create Campaign',
  noHave: 'You don\'t have any campaigns created in the tracker system yet. Click "Add Campaign"',
  companyInfo: 'Campaign ID',
  enable: 'On/Off',
  enableInfo: 'Campaign status',
  name: 'Name',
  nameInfo: 'Campaign name',
  country: 'Countries',
  countryInfo: 'GEO used in this campaign',
  pwaInfo: 'PWA that uses this campaign',
  tag: 'Tags',
  tagInfo: 'Tags assigned to Tracker',
  filters: {
    name: 'Search by name',
    id: 'Search by ID',
    enable: 'Filter by state',
    country: 'Filter by country',
    pwa: 'Filter by PWA',
    tag: 'Filter by tag',
    noHave: 'Search failed, try changing filter options'
  },
  form: {
    title: 'Company',
    setting: 'Campaign Settings',
    nameInfo: 'The name of your campaign. Enter for your convenience',
    link: 'Link to the offer',
    linkDefault: 'Link for inappropriate traffic',
    linkInfo: 'Enter a link for a redirect that will be used if the Geo is not suitable',
    active: 'Active',
    notActive: 'Not active',
    activeInfo: 'Campaign Status',
    tags: 'Tracker Tags',
    tagsInfo: 'A set of tags for filtering the tracker',
    newTag: 'Add new tag',
    filters: 'Link',
    filtersInfo: 'Select a GEO and assign a redirect link for this country',
    info: 'For a redirect, specify the pair "country - redirect"',
    country: 'Select country',
    created: 'Campaign successfully saved',
    deletePair: 'Delete a pair?'
  },
  linkSettings: {
    link: 'Filter settings {country}({locale})',
    title: 'Advanced settings',
    postback: 'Postbacks',
    postbackTemplate: 'Postback Template',
    postbackTemplateSelect: 'Select Postback Template',
    postbackLink: 'Postback Link',
    postbackInfo: 'Insert this URL into your affiliate',
    postbackRegLink: 'Registration postback link',
    postbackRegInfo: 'If you don\'t understand what this is, contact {name} support',
    postbackDepositLink: 'Deposit postback link',
    postbackDepositInfo: 'If you don\'t understand what this is, contact {name} support',
    get: 'Throw GET',
    getInfo: 'If you want to pass all PWA GET parameters to a link',
    fullscreen: 'Open Fullscreen',
    fullscreenInfo: 'PWA startup selection',
    bindPrevButton: 'Traffic back',
    bindPrevButtonInfo: 'If no link is provided, the one generated for the redirect will be used. The user will be directed to this URL if they want to leave the page by pressing the back button',
    bindPrevButtonPlaceholder: 'URL for traffic back',
    redirect: 'Redirect',
    landingSettings: 'Landing Settings',
    locale: 'Landing locale',
    localeInfo: 'Specify the locale of the landing. PWA that has the desired translation will also be displayed with the translation',
    cloakTitle: 'cloaca',
    cloak: 'Use cloak',
    cloakInfo: 'Enable/Disable cloak usage',
    android: 'Android only',
    androidInfo: 'All traffic that is not Android OS will be flagged by the bot. As a consequence, the white page will be displayed if it is configured',
    white: 'Send other GEOs to the white',
    whiteInfo: 'If enabled, send everyone who does not match the specified GEOs to the White page',
    whiteRedirect: 'Redirect to your white',
    whiteRedirectInfo: 'If enabled, send everyone who does not match the specified GEO to the specified url',
    geoRedirect: 'URL for GEO redirect',
    whiteLang: 'Language of the white page',
    whiteLangInfo: 'White page generation language',
    whiteLangPlaceholder: 'Select language for white page generation',
    app: 'App Link',
    appInfo: 'App link from play.google.com/store/apps/',
    footer: 'footer text block',
    footerInfo: 'A B BR P STRONG tags are allowed',
    url: 'URL',
    clUrl: 'Application URL',
    useCookies: 'Cookie files',
    useCookiesInfo: 'Show users an inscription to agree to the use of cookies. This is required as a fake click on the page to activate some scripts',
    ignoreCloak: 'Ignore the cloak',
    ignoreCloakInfo: 'If you enable this option, it becomes available to ignore the built-in cloak using the GET parameter <strong>?ignore_cloak=1</strong>. Use this function for testing only. It is not recommended to use during the advertising campaign!'
  },
  delete: {
    title: 'Delete tracker',
    message: 'Are you sure you want to delete tracker {name}?',
    button: 'Delete',
    success: 'Tracker removed'
  },
  copy: {
    title: 'Copy Tracker',
    message: 'Are you sure you want to copy tracker {name}?',
    button: 'Copy',
    success: 'Tracker copied'
  },
  wp: {
    generate: 'Generate',
    success: 'White page generated',
    failed: 'White page not generated',
    notTracker: 'The cesspool settings will become available only after the company is created'
  }
}
