export default {
  logout: 'Logout',
  mail: 'You have no new messages | You have one new message | You have {count} new messages | You have {count} new messages',
  notifications: 'You have no new notifications | You have one new notification | You have {count} new notifications | You have {count} new notifications',
  banners: {
    telegram: {
      title: 'Subscribe to our telegram channel <br>and telegram chat',
      desc: 'Stay up to date with the latest news from PWA GROUP. Take an active part in the software discussion!',
      chanelButton: 'Telegram chanel',
      chatButton: 'Telegram chat'
    }
  }
}
