<template>
  <div class="BaseRouterTabs tabs-container">
    <ul class="tabs" :class="{'is--small': small}" id="container" ref="container">
      <li
        v-for="(tab) of tabs"
        :key="tab.name"
        class="tab"
        :class="{'active': setActive(tab), 'is--small': small, 'is--icon': showStepIcon}">
        <router-link
          :to="tab.to"
          class="tab__link"
        >
          <InlineSvg
            v-if="showStepIcon"
            class="tab__link-icon"
            src="/images/svg-icons/icon-check.svg"
          />
          {{ withTranslators ? $t(tab.label) :  tab.label}}
        </router-link>
      </li>
      <slot />
    </ul>
  </div>
</template>
<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'BaseRouterTabs',
  components: {InlineSvg},
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    showStepIcon: {
      type: Boolean,
      default: false
    },
    withTranslators: {
      type: Boolean,
      default: true
    },
    params: String,
    additionalName: String,
    small: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    const element = this.$refs.container
    element.addEventListener('wheel', (event) => {
      event.preventDefault()
      element.scrollBy({
        left: event.deltaY < 0 ? -30 : 30
      })
    })
  },
  methods: {
    setActive (tab) {
      if (this.params && tab.to.params) {
        return this.$route.params[this.params] === tab.to.params[this.params]
      }
      return this.$route.name === tab.to.name || tab.to.name === this.additionalName
    }
  }
}
</script>
<style lang="sass" scoped>
.tabs-container
  position: relative
  z-index: 3
  .tabs
    display: flex
    border-bottom: 1px solid rgba(255, 255, 255, .15)
    gap: 38px
    margin-bottom: 30px
    overflow-x: scroll
    align-items: center
    padding-right: 5px
    scrollbar-width: none
    @media screen and (max-width: 991px)
      gap: 18px
    &.is--small
      gap: 24px
      @media screen and (max-width: 991px)
        gap: 14px
    &::-webkit-scrollbar
      display: none
    .tab:has(~ .active)
      ::v-deep .tab__link-icon path
        stroke: #45B172
    .tab.is--icon:has(~ .active)
      .tab__link
        color: #45B172
      &:hover
        ::v-deep .tab__link-icon path
          stroke: #707175
        .tab__link
          color: #fff
    .tab
      position: relative
      cursor: pointer
      font-size: 18px
      font-weight: 400
      font-family: Rubik
      white-space: nowrap
      @media screen and (max-width: 991px)
        font-size: 14px
      &.is--small
        font-size: 16px
        .tab__link
          padding: 16px 0
        @media screen and (max-width: 991px)
          font-size: 12px
      &:hover
        &::after
          content: ''
          position: absolute
          width: 100%
          height: 2px
          left: 0
          bottom: 0
          background-color: #fff

        .tab__link
          color: #fff
      &.active::after
        content: ''
        position: absolute
        width: 100%
        height: 2px
        left: 0
        bottom: 0
        background-color: #21BF73
      &.active .tab__link
        color: #fff
      &__link
        color: #707175
        display: flex
        align-items: center
        gap: 8px
        padding: 20px 0
</style>
