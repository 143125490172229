export default {
  navigation: 'Comentário',
  title: 'Comentário de PWA #{id}',
  message: 'Acrescenta o número ilimitado de comentários e respostas',
  comment: {
    title: 'Comentário',
    avatar: 'Avatar',
    avatarInfo: 'Carregar o avatar do cliente',
    loadAvatar: 'Carregar o avatar',
    deleteAvatar: 'Tem certeza de que deseja excluir o avatar?',
    author: 'Autor',
    authorInfo: 'Introduza o nome do autor do comentário',
    rating: 'Classificação',
    ratingInfo: 'Indica a classificação do autor',
    likes: 'Gostos',
    likesInfo: 'Número de gostos para este comentário',
    date: 'Data',
    dateInfo: 'Escolher a data de criação de comentário',
    commentInfo: 'Escreva o comentário que deixou este autor'
  },
  answer: {
    title: 'Resposta',
    author: 'Autor',
    authorInfo: 'Indica o nome de pessoa a responder ao comentário (normalmente é o Serviço de apoio ao cliente da aplicação)',
    date: 'Data',
    dateInfo: 'Escolher a data de resposta ao comentário',
    commentInfo: 'Escreva uma resposta ao comentário'
  },
  add: 'Adicionar um comentário',
  deletedTranslate: 'Tradução de comentários foi apagada',
  validate: {
    dateFormat: 'Introduza a data em formato DD.MM.YY'
  }
}
