<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Ваучеры</h4>
            <p class="card-description"></p>
            <div class="mb-2">
              <button type="button" class="btn btn-outline-success btn-fw" @click="$refs.form.open()"><i
                class="icon-plus"></i> Добавить
              </button>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th>Код</th>
                  <th></th>
                  <th>Вкл./Выкл.</th>
                  <th>Первая покупка</th>
                  <th>Кл. активаций</th>
                  <th>Кл. активировали</th>
                  <th>Истекает</th>
                  <th>Сумма скидки</th>
                  <th>Процент скидки</th>
                  <th>Тарифы</th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                </tr>
                </thead>
                <tbody>
                <field :item="item" v-for="item in data.items" :key="item._id"/>
                </tbody>
                <nav v-if="page.count > 1">
                  <paginate ref="paginate"
                            :page-count="page.count"
                            :click-handler="pagination"
                            :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                            :next-text="'<i class=\'icon-arrow-right\'></i>'"
                            :container-class="'pagination mt-3'"
                            :page-class="'page-item'"
                            :page-link-class="'page-link'"
                            :prev-class="'page-item'"
                            :next-class="'page-item'"
                            :prev-link-class="'page-link'"
                            :next-link-class="'page-link'"
                            :active-class="'active'">
                  </paginate>
                </nav>
              </table>
            </div>
            <edit-form ref="form"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as components from './components'

export default {
  name: 'Vouchers',
  components,
  data: function () {
    return {
      page: {
        size: 30,
        index: 1
      },
      data: {
        items: [],
        count: 0
      },
      voucher: {}
    }
  },
  computed: {
    'page.count': function () {
      return Math.ceil(this.data.count / this.page.size)
    }
  },
  created: async function () {
    await this.asyncData()
  },
  methods: {
    asyncData: async function () {
      await this.$store.dispatch('adminVouchers/index')
      this.data.items = this.$store.getters['adminVouchers/vouchers']
      this.data.count = this.$store.getters['adminVouchers/count']
    },
    pagination: async function (index) {
      this.page.index = index
      await this.asyncData()
    }
  }
}
</script>
