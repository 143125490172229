<template>
  <div class="d_inline_block item_butt_acat_g">
    <modal ref="modal" id="push-templates-modal" class="fade modal_center_g"
           classContent="modal-content modal_padd_normal" classDialog="modal-dialog">
      <form v-if="!loading" class="style_form_lab" @submit.prevent="handlerSave">
        <button type="button" class="close_sjgs_to d_flex align_center" @click="handlerCancel"><span aria-hidden="true">&times;</span>
        </button>
        <div class="modal_title_g">{{ title }}</div>
        <div class="form-group" :class="apiValidationErrors.template ? 'has-danger' : ''">
          <label for="push-templates">{{ sourceLabel }}</label>
          <div class="item_silter_sjhf_g w-100">
            <select2
                class="form-group mt-2"
                inputClass="form-control select_white_g"
                id="push-templates"
                name="push-templates"
                autofocus :class="apiValidationErrors.source ? 'form-control-danger' : ''"
                @focus="clearError('source')"
                v-model="source"
                :settings="templateSettings"
                :placeholder="$t('pushes.filters.name')"
            />
          </div>
          <label v-if="apiValidationErrors.source" class="error mt-2 info_warning_gdg"
                 for="push-templates">
            {{ apiValidationErrors.source[0] }}
          </label>
        </div>
        <div class="modal_foot_this translator_foot">
          <div class="in_topshgdjf_g d_flex align_center">
            <div class="item_avt_butt_jg">
              <button type="submit" class="ful_butt_green d_flex align_center justify_center">
                {{ $t('general.apply') }}
              </button>
            </div>
            <div class="item_avt_butt_jg">
              <a href="javascript:void(0);" type="button" class="border_butt_green d_flex align_center justify_center"
                 @click="handlerCancel">
                {{ $t('general.cancel') }}
              </a>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'

export default {
  name: 'PushTemplateModal',
  mixins: [formMixin, select2AjaxMixin],
  components: {Select2, Modal},
  data () {
    return {
      models: [],
      source: '',
      loading: true,
      module: '',
      templateSettings: {}
    }
  },
  computed: {
    title () {
      if (this.module === 'pwasPushes') {
        return this.$t('pushes.templates.template.title')
      } else {
        return this.$t('pushes.template.title')
      }
    },
    sourceLabel () {
      if (this.module === 'pwasPushes') {
        return this.$t('pushes.templates.template.source')
      } else {
        return this.$t('pushes.template.source')
      }
    }
  },
  watch: {
    source: function (n) {
      this.initTemplateSettings(n)
    }
  },
  methods: {
    async handlerTemplate (module) {
      this.module = module
      this.initTemplateSettings()
      this.$nextTick(() => {
        this.loading = false
      })
      this.resetApiValidation()
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    initTemplateSettings () {
      this.templateSettings = {
        ...this.getSelect2AjaxSettings(`pwas/pushes/templates/filter`, '-createdAt'),
        allowClear: true
      }
    },
    async handlerSave () {
      if (this.source.length > 0) {
        this.resetApiValidation()
        await this.$store.dispatch(`${this.module}/read`, this.source)
        this.$refs.modal.close()
        this.resolvePromise(this.$store.getters[`${this.module}/model`])
      } else {
        this.setApiValidation([{param: 'template', msg: this.$t('templates.apps.notSelected')}])
      }
    },
    handlerCancel () {
      this.source = ''
      this.$refs.modal.close()
      this.resolvePromise(false)
    }
  }
}
</script>
