<template>
  <div class="BaseFromGroup base-form-group form-group">
    <label v-if="label" :for="id">
      {{ label }}
      <info v-if="infoText" :message="infoText"/>
    </label>
    <div class="base-form-group__wrapper">
      <div class="base-form-group__field">
        <slot />
      </div>
      <info v-if="fieldInfoText" :message="fieldInfoText"/>
    </div>
    <span v-if="hasError" class="error mt-2 info_warning_gdg">
      {{ errorText }}
    </span>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'

export default {
  name: 'BaseFromGroup',
  components: { Info },
  props: {
    id: String,
    label: String,
    errorText: String,
    hasError: Boolean,
    infoText: String,
    fieldInfoText: String
  }
}
</script>
<style scoped>
.base-form-group {
  margin-bottom: 23px;
}
.base-form-group label {
  font-size: 12px;
  color: #A0A1A4;
  margin-bottom: 8px;
}
.base-form-group .info_warning_gdg {
  font-size: 12px;
  color: #FD5E53;
}
.base-form-group__wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.base-form-group__field {
  width: 100%;
}
</style>
