export default {
  mirrorsList: [],
  mirrorsListCount: 0,
  mirrorsListLoading: false,
  mirrorsListLoaded: false,
  mirrorsListTrash: 0,
  activeMirror: {},
  mirrorsTrashList: [],
  mirrorsTrashListCount: 0,
  mirrorsTrashListLoading: false,
  mirrorsTrashListLoaded: false
}
