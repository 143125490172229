<template>
  <div class="content-wrapper">
    <cloudflare-form ref="cloudflareForm"/>
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Аккаунты Cloudflare</h4>
            <p class="card-description">Управления аккаунтами от Cloudflare</p>
            <div class="mb-2">
              <button @click="handlerCreate" type="button" class="btn btn-outline-success btn-fw" id="add-cloudflare-account">
                <i class="icon-plus"></i> Добавить
              </button>
            </div>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th>Активный</th>
                  <th>E-mail</th>
                  <th>Ключ</th>
                  <th>Токен</th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model._id">
                  <td>
                    <button @click="handlerUpdate(model)"
                            class="btn btn-icons btn-inverse-primary">
                      <i class="icon-pencil"></i>
                    </button>
                    <button @click="handlerDelete(model)" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                  </td>
                  <td>{{ model.enable | formatBoolean }}</td>
                  <td>{{ model._id }} ({{ model.countDomains }})</td>
                  <td>{{ model.key }}</td>
                  <td v-html="$options.filters.formatNull(model.token)"></td>
                  <td>{{ model.createdAt | dateFromNow }}</td>
                  <td>{{ model.updatedAt | dateFromNow }}</td>
                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import CloudflareForm from './_form.vue'

export default {
  name: 'AdminCloudflare',
  components: {CloudflareForm},
  data () {
    return {
      limit: 20,
      count: 0,
      models: [],
      page: 1,
      loading: true
    }
  },
  computed: {
    pageCount: function () {
      return Math.ceil(this.count / this.limit)
    }
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  methods: {
    async asyncData () {
      this.loading = true
      await this.$store.dispatch('adminCloudflares/asyncData', {
        limit: this.limit,
        page: this.page,
        sort: '-createdAt'
      })
      this.models = this.$store.getters['adminCloudflares/models']
      this.count = this.$store.getters['adminCloudflares/count']
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async handlerPagination (page) {
      this.page = page
      await this.asyncData()
    },
    async handlerCreate () {
      if (await this.$refs.cloudflareForm.handlerCreate()) {
        await this.handlerPagination(1)
      }
    },
    async handlerUpdate (model) {
      if (await this.$refs.cloudflareForm.handlerUpdate(model)) {
        await this.handlerPagination(1)
      }
    },
    async handlerDelete (model) {
      if (model.countDomains > 0) {
        alert('К этому аккаунту уже подвязаны домены, его нельзя удалять!')
      } else {
        if (confirm(`Удалить аккаунт ${model._id}`)) {
          await this.$store.dispatch('adminCloudflares/delete', model._id)
          await this.handlerPagination(1)
        }
      }
    }
  }
}
</script>
