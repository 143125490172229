<template>
  <modal
    ref="modal"
    id="domain-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group" :class="apiValidationErrors['enable'] ? 'has-danger' : ''">
        <div class="form-check">
          <label id="enable" class="form-check-label">
            <input type="checkbox" class="form-check-input" id="enable" name="enable"
                   v-model="enable">
            {{ enable ? 'Включен' : 'Выключен' }}
            <i class="input-helper"></i>
          </label>
        </div>
        <label v-if="apiValidationErrors['enable']" id="tenable-error" class="error mt-2 text-danger"
               for="enable">{{ apiValidationErrors['enable'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['name'] ? 'has-danger' : ''">
        <label for="name">Назва</label>
        <input class="form-control" id="name" name="name" type="text" tabindex="1"
               v-model="name"
               :class="apiValidationErrors['name'] ? 'form-control-danger' : ''"
               placeholder="Введите названия шаблона"
               @focus="clearError('name')">
        <label v-if="apiValidationErrors['name']" class="error mt-2 text-danger"
               for="name">{{ apiValidationErrors['name'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['alias'] ? 'has-danger' : ''">
        <label for="alias">Алиас</label>
        <input class="form-control" id="alias" name="alias" type="text" tabindex="1"
               v-model="alias"
               :class="apiValidationErrors['alias'] ? 'form-control-danger' : ''"
               placeholder="Введите алиас"
               @focus="clearError('alias')">
        <label v-if="apiValidationErrors['alias']" class="error mt-2 text-danger"
               for="param">{{ apiValidationErrors['alias'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['params'] ? 'has-danger' : ''">
        <label for="params">Параметры</label>
        <input class="form-control" id="params" name="params" type="text" tabindex="1"
               v-model="params"
               :class="apiValidationErrors['params'] ? 'form-control-danger' : ''"
               placeholder="Введите параметры"
               @focus="clearError('params')">
        <label v-if="apiValidationErrors['params']" class="error mt-2 text-danger"
               for="param">{{ apiValidationErrors['params'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['postback'] ? 'has-danger' : ''">
        <label for="postback">Постбек</label>
        <input class="form-control" id="postback" name="postback" type="text" tabindex="1"
               v-model="postback"
               :class="apiValidationErrors['postback'] ? 'form-control-danger' : ''"
               placeholder="Введите постбек"
               @focus="clearError('postback')">
        <label v-if="apiValidationErrors['postback']" class="error mt-2 text-danger"
               for="postback">{{ apiValidationErrors['postback'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['replaceKeys.event'] ? 'has-danger' : ''">
        <label for="replaceKeys.event">Параметр ивента</label>
        <input class="form-control" id="replaceKeys.event" name="replaceKeys.event" type="text" tabindex="1"
               v-model="replaceKeys.event"
               :class="apiValidationErrors['replaceKeys.event'] ? 'form-control-danger' : ''"
               placeholder="event"
               @focus="clearError('replaceKeys.event')">
        <label v-if="apiValidationErrors['replaceKeys.event']" class="error mt-2 text-danger"
               for="replaceKeys.event">{{ apiValidationErrors['replaceKeys.event'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['replaceKeys.user_id'] ? 'has-danger' : ''">
        <label for="replaceKeys.user_id">Параметр user_id</label>
        <input class="form-control" id="replaceKeys.user_id" name="replaceKeys.user_id" type="text" tabindex="1"
               v-model="replaceKeys.user_id"
               :class="apiValidationErrors['replaceKeys.user_id'] ? 'form-control-danger' : ''"
               placeholder="user_id"
               @focus="clearError('replaceKeys.user_id')">
        <label v-if="apiValidationErrors['replaceKeys.user_id']" class="error mt-2 text-danger"
               for="replaceKeys.user_id">{{ apiValidationErrors['replaceKeys.user_id'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['replaceKeys.amount'] ? 'has-danger' : ''">
        <label for="replaceKeys.amount">Параметр суммы</label>
        <input class="form-control" id="replaceKeys.amount" name="replaceKeys.amount" type="text" tabindex="1"
               v-model="replaceKeys.amount"
               :class="apiValidationErrors['replaceKeys.amount'] ? 'form-control-danger' : ''"
               placeholder="amount"
               @focus="clearError('replaceKeys.amount')">
        <label v-if="apiValidationErrors['replaceKeys.amount']" class="error mt-2 text-danger"
               for="replaceKeys.amount">{{ apiValidationErrors['replaceKeys.amount'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['replace.registration'] ? 'has-danger' : ''">
        <label for="replace.registration">Значение ивента регистрации</label>
        <input class="form-control" id="replace.registration" name="replace.registration" type="text" tabindex="1"
               v-model="replace.registration"
               :class="apiValidationErrors['replace.registration'] ? 'form-control-danger' : ''"
               placeholder="registration"
               @focus="clearError('replace.registration')">
        <label v-if="apiValidationErrors['replace.registration']" class="error mt-2 text-danger"
               for="replace.registration">{{ apiValidationErrors['replace.registration'][0] }}</label>
      </div>
      <div class="form-group" :class="apiValidationErrors['replace.deposit'] ? 'has-danger' : ''">
        <label for="replace.deposit">Значение ивента депозита</label>
        <input class="form-control" id="replace.deposit" name="replace.deposit" type="text" tabindex="1"
               v-model="replace.deposit"
               :class="apiValidationErrors['replace.deposit'] ? 'form-control-danger' : ''"
               placeholder="deposit"
               @focus="clearError('replace.deposit')">
        <label v-if="apiValidationErrors['replace.deposit']" class="error mt-2 text-danger"
               for="replace.deposit">{{ apiValidationErrors['replace.deposit'][0] }}</label>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'

const defaultValues = () => ({
  id: null,
  name: '',
  enable: true,
  postback: '',
  params: '',
  alias: '',
  replace: { registration: 'registration', deposit: 'deposit' },
  replaceKeys: { event: 'event', user_id: 'user_id', amount: 'amount' }
})

export default {
  name: 'PostbackForm',
  mixins: [formMixin],
  components: {SubmitModalForm, Modal},
  data () {
    return {
      processing: false,
      isCreate: true,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    title () {
      return this.isCreate ? 'Добавление шаблона' : `Редактирование ${this.name}`
    }
  },
  methods: {
    async handlerCreate () {
      this.skip()
      this.isCreate = true
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerUpdate ({_id, name, enable, postback, params, alias, replace, replaceKeys}) {
      this.skip()
      this.isCreate = false
      this.id = _id
      this.name = name
      this.enable = enable
      this.postback = postback
      this.params = params
      this.alias = alias
      this.replace = replace
      this.replaceKeys = replaceKeys
      this.$refs.modal.open()
      if (this.owner != null) {
        await this.$store.dispatch('adminUsers/read', this.owner)
        this.users.push(this.$store.getters['adminUsers/user'])
      }
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        const action = this.isCreate ? 'adminPostbacks/create' : 'adminPostbacks/update'
        const payload = {
          name: this.name,
          enable: this.enable,
          postback: this.postback,
          params: this.params,
          alias: this.alias,
          replace: this.replace,
          replaceKeys: this.replaceKeys
        }
        if (this.isCreate === false) {
          payload['id'] = this.id
        }
        await this.$store.dispatch(action, payload)
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
