<template>
  <div class="form-group" :class="apiValidationErrors.youtube ? 'has-danger' : ''">
    <label for="name">{{ $t('pwas.components.vertical.youtube.title') }}</label>
    <input type="text" id="name" :placeholder="$t('pwas.components.vertical.youtube.placeholder')" v-model="youtube"
           class="form-control"
           :class="apiValidationErrors.youtube ? 'form-control-danger' : ''"
           @focus="clearError('youtube')">
    <label v-if="apiValidationErrors.youtube" class="error mt-2 text-danger" for="youtube">
      {{ apiValidationErrors.youtube[0] }}
    </label>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'

export default {
  name: 'YouTubeControl',
  mixins: [formMixin],
  props: {
    errors: {
      type: Object,
      required: true
    },
    value: String
  },
  computed: {
    youtube: {
      get () {
        return this.value
      },
      set (input) {
        input = input.trim()
        if (input.search('https://youtu.be/') === 0) {
          input = input.replace('https://youtu.be/', 'https://www.youtube.com/embed/')
        } else if (input.search('https://www.youtube.com/watch') === 0) {
          input = input.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')
        } else {
          const matches = input.match(new RegExp(/\bhttps?:\/\/\S+/gi))
          if (matches != null) {
            input = matches[0].replace('"', '')
          }
        }
        this.$emit('input', input)
      }
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  }
}
</script>
