<template>
  <ul class="DomainFormNs domain-form-ns list_wrapper_go">
    <li class="pr-0 pr-lg-5 pr-xl-0" v-if="nsExists">
      <p class="descr_site_shg mb_small">
        <span v-html="$t('pwas.components.domain.warningCloudflare')"></span>&nbsp;&nbsp;
        <span class="d-block d-md-inline-block mt-2 mt-md-0">
          <button
            type="button"
            class="ful_butt_green small_btn d_flex align_center justify_center"
            @click="onRebuild"
          >
            <span v-if="!loading">{{ $t('pwas.components.domain.getNS') }}</span>
            <hollow-dots-spinner
              v-if="loading"
              :animation-duration="1000"
              :dot-size="15"
              :dots-num="3"
              :color="'#ff1d5e'"
            />
          </button>
        </span>
      </p>
    </li>
    <li>
      <div class="form-check form-check-flat">
        <p class="domain-form-ns__title">{{ $t('pwas.components.domain.settings[0]') }}</p>
        <div>
          <p><strong>{{ localData.ns1 }}</strong></p>
          <p><strong>{{ localData.ns2 }}</strong></p>
        </div>
        <p
          v-if="!isCloudActivate"
          class="mt-2 info_warning_gdg"
          v-html="$t('pwas.components.domain.noCloudflare')"
        />
      </div>
      <i :class="isCloudActivate ? 'status-icons text-success icon-check' : ''" />
    </li>
  </ul>
</template>
<script>
import {HollowDotsSpinner} from 'epic-spinners'
import {mapActions} from 'vuex'
export default {
  name: 'DomainFormNs',
  props: {
    data: Object,
    status: [String, Number],
    domain: String,
    id: String
  },
  components: { HollowDotsSpinner },
  data () {
    return {
      loading: false,
      localData: this.data
    }
  },
  computed: {
    nsExists () {
      return this.localData.ns1 != null && this.localData.ns2 != null
    },
    isCloudActivate () {
      return this.status > 1
    }
  },
  methods: {
    ...mapActions('mirrorsStore', [
      'rebuildMirror'
    ]),
    ...mapActions({
      error: 'alerts/error',
      success: 'alerts/success'
    }),
    async onRebuild () {
      this.loading = true
      const params = {
        id: this.id,
        domain: this.domain,
        email: this.data?.cloudflareApi?.auth?.email,
        key: this.data?.key
      }
      try {
        const data = await this.rebuildMirror(params)
        this.localData = data?.ns
        this.test = data
        this.$nextTick(async () => {
          this.loading = false
          await this.success(this.$t('pwas.components.domain.ns.success'))
          this.$emit('rebuild')
        })
      } catch (e) {
        if (e.response && e.response.data.errors[0].msg) return this.error(e.response.data.errors[0].msg)
        this.loading = false
        await this.error(this.$t('pwas.components.domain.ns.failed'))
      }
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.domain-form-ns
  font-size: 14px
  &__title
    margin-bottom: 10px
</style>
