import api from '../api'
import {getSearchParams} from '../../list'

export default {
  list: (payload) => {
    return api.get('admin/domains?' + getSearchParams(payload))
  },
  create: payload => api.post('admin/domains', payload),
  update: ({_id, ...payload}) => api.put(`admin/domains/${_id}`, payload),
  delete: _id => api.delete(`admin/domains/${_id}`)
}
