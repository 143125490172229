<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-12">
        <correction-form ref="correction"/>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Транзакции</h4>
            <p class="card-description">
              Поиск по полю находится под заголовком
            </p>
            <div class="row">
                <owner-filter class="col-lg-3 col-md-4 col-sm-6 mb-2" v-model="filters.owner" />
                <select2
                  inputClass="form-control"
                  id="pwaId"
                  class="col-lg-3 col-md-4 col-sm-6  mb-2"
                  name="pwaId"
                  v-model="filters.pwa"
                  :settings="pwaIdSettings"
                  placeholder="Фильтр по PWA"
                ></select2>

              <div class="col-lg-3 col-md-4 col-sm-6  mb-2">
                <select class="form-control" name="type" id="type" v-model="filters.type">
                    <option value="">Фильтр по типу</option>
                    <option value="pwa">Оплата за PWA</option>
                    <option value="domain">Покупка домена</option>
                    <option value="bill">Пополнения баланса</option>
                    <option value="tariff">Подписка на тариф</option>
                    <option value="referral">Рефералка</option>
                    <option value="install">Установка</option>
                    <option value="correction">Коррекция</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
                <input class="form-control" name="domain" id="domain" placeholder="Фильтр по домену" v-model="filters.domain">
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 mb-2">
                <input class="form-control" name="bill" id="bill" placeholder="Фильтр по счёту" v-model="filters.bill">
              </div>
              <owner-filter class="col-lg-3 col-md-4 col-sm-6  mb-2" id="referral"  v-model="filters.referral" placeholder="Фильтр по рефералу"/>
              <div class="col-lg-3 col-md-4 col-sm-6">
                <select class="form-control" name="host" id="host" v-model="filters.host">
                    <option value="">Показать установки</option>
                    <option :value="true">Скрыть установки</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6  mb-2">
                <button type="button" style="width: 100%; padding: 0;" class="btn btn-outline-success btn-fw btn-outline-height-fix" @click="handlerCorrection">
                  <i class="icon-plus"></i> Коррекция
                </button>
              </div>
            </div>
            <div v-if="!loading" class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Действия</th>
                    <th>Создан</th>
                    <th style="min-width: 220px">Владелец</th>
                    <th>Баланс</th>
                    <th>Сумма</th>
                    <th>Тип</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="model in models" :key="model._id">
                    <td>
                      <button
                        class="btn btn-icons btn-inverse-danger"
                        @click="handlerRemove(model)"
                      >
                        <i class="icon-trash"></i>
                      </button>
                    </td>
                    <td>{{ model.createdAt | formatDate }}</td>
                    <td>
                      <table-owner
                        :data="{ ...model.owner, id: model.owner._id }"
                      />
                    </td>
                    <td>
                      {{ model.balance | amount }}
                    </td>
                    <td>
                      {{ model.amount | amount }}
                    </td>
                    <td>
                      {{ model.purpose }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td style="border: none;">Всего: <strong>{{ count }}</strong></td>
                </tr>
                </tfoot>
              </table>
              <nav v-if="pageCount > 1">
                <paginate
                  ref="paginate"
                  v-model="page"
                  :page-count="pageCount"
                  :click-handler="pagination"
                  :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                  :next-text="'<i class=\'icon-arrow-right\'></i>'"
                  :container-class="'pagination mt-3'"
                  :page-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :active-class="'active'"
                >
                </paginate>
              </nav>
            </div>
            <div v-else class="dot-opacity-loader">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { OwnerFilter } from '../components'
import * as adminComponents from '@/pages/admin/components'
import pwasMixin from '@/mixins/pwas-mixin'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import _ from 'lodash'
import CorrectionForm from './CorrectionForm.vue'

export default {
  name: 'AdminTransactions',
  mixins: [pwasMixin, select2AjaxMixin],
  components: {
    OwnerFilter,
    TableOwner: adminComponents.tables.Owner,
    Select2,
    CorrectionForm
  },
  data () {
    return {
      limit: 10,
      count: 0,
      page: 1,
      models: [],
      filters: {
        owner: '',
        pwa: '',
        type: '',
        domain: '',
        bill: '',
        referral: '',
        host: true
      },
      loading: true,
      pwaIdSettings: {}
    }
  },
  watch: {
    filters: {
      handler () {
        if (this.filters.type === 'install') this.filters.host = ''
        this.asyncData()
      },
      deep: true
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.count / this.limit)
    }
  },
  async created () {
    this.pwaIdSettings = {...this.getSelect2AjaxSettings('pwas/filter'), allowClear: true}
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  methods: {
    async asyncData () {
      this.loading = true
      let filters = {}
      for (const [key, value] of Object.entries(this.filters)) {
        if (value) {
          filters[key] = value
        }
      }
      await this.$store.dispatch('adminTransactions/index', {
        limit: this.limit,
        page: this.page,
        sort: '-createdAt',
        filter: filters
      })
      this.models = this.$store.getters['adminTransactions/models']
      this.count = this.$store.getters['adminTransactions/count']
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async pagination (page) {
      this.page = page
      await this.asyncData()
    },
    async handlerRemove (model) {
      if (confirm('Удалить Транзакцию?')) {
        await this.$store.dispatch('adminTransactions/delete', {id: model._id, owner: model.owner._id})
        await this.asyncData()
        await this.$store.dispatch(
          'alerts/success',
          `Транзакция успешно удалёна`
        )
      }
    },
    async handlerCorrection () {
      if (await this.$refs.correction.handlerCreate()) {
        await this.asyncData()
      }
    }
  }
}
</script>
<style scoped>
.btn-outline-height-fix {
  height: 32px;
}
@media only screen and (max-width : 575px) {
  .btn-outline-height-fix {
    margin-top: 12px;
  }
}
</style>
