<template>
  <div class="content-wrapper AdminPWATemplates admin-pwa-templates">
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">PWA Шаблони</h4>
            <div class="mb-2">
              <button @click="onCreateTemplate" type="button" class="btn btn-outline-success btn-fw">
                <i class="icon-plus"></i> Добавити
              </button>
            </div>
            <div class="table-responsive">
              <table v-if="templatesLoaded" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>ID</th>
                  <th style="min-width: 170px;">Назва</th>
                  <th>Вкл./Викл.</th>
                  <th>Картинка</th>
                  <th>Опис</th>
                </tr>
                </thead>
                <tbody id="dragula">
                  <tr
                    v-for="model in templatesList"
                    :key="model.id"
                    :data-id="model.id"
                  >
                    <td><i class="icon-options-vertical"></i></td>
                    <td>
                      <button
                        @click="onEditTemplate(model)"
                        class="btn btn-icons btn-inverse-primary"
                      >
                        <i class="icon-pencil"></i>
                      </button>
                      <!--
                      <button
                        @click="onDeleteTemplate(model.id)"
                        class="btn btn-icons btn-inverse-danger"
                      >
                        <i class="icon-trash"></i>
                      </button>
                      -->
                    </td>
                    <td>{{model.id}}</td>
                    <td>{{ model.name.uk }}</td>
                    <td>{{ model.enable ? 'Увімкнений' : 'Вимкнений' }}</td>
                    <td>
                      <img class="admin-pwa-templates__item-image" :src="model.image" alt="">
                    </td>
                    <td>
                      <p class="admin-pwa-templates__item-description">
                        {{model.description.uk}}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalCreateUpdateTemplate
      ref="modalTemplate"
      :data="activeTemplate"
      @success="onSuccess"
      @cancel="activeTemplate = {}"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import {mapActions, mapGetters} from 'vuex'
import ModalCreateUpdateTemplate from './ModalCreateUpdateTemplate.vue'
import {nextTick} from 'vue'
export default {
  name: 'AdminPWATemplates',
  components: { ModalCreateUpdateTemplate },
  data () {
    return {
      page: 1,
      limit: 100,
      enable: true,
      name: '',
      activeTemplate: {},
      showModal: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters('adminPwasStore', [
      'templatesList',
      'templatesListCount',
      'templatesListLoading',
      'templatesLoaded'
    ])
  },
  async created () {
    await this.fetchPage()
  },
  methods: {
    ...mapActions('adminPwasStore', [
      'getTemplatesList',
      'deleteTemplate'
    ]),
    dragula () {
      const self = this
      // eslint-disable-next-line no-undef
      dragula([document.getElementById('dragula')])
        .on('drop', async function () {
          const rows = document.querySelectorAll('tr[data-id]')
          let ids = []
          for (const row of rows) {
            if (row.className !== 'gu-mirror') {
              ids.push(row.getAttribute('data-id'))
            }
          }
          await self.$store.dispatch('adminPwasStore/updateOrderTemplate', {ids})
        })
    },
    async fetchPage () {
      this.loading = true
      await this.getTemplatesList({
        page: this.page,
        limit: this.limit,
        sort: 'order'
      })
      this.loading = false
      this.$nextTick(() => {
        this.dragula()
      })
    },
    onSuccess () {
      this.fetchPage()
      this.activeTemplate = {}
    },
    async onCreateTemplate () {
      await this.$refs.modalTemplate.openModal()
    },
    async onEditTemplate (model) {
      this.activeTemplate = model
      await this.$refs.modalTemplate.openModal()
    },
    async onDeleteTemplate (id) {
      try {
        await this.deleteTemplate(id)
        await this.$store.dispatch(
          'alerts/success',
          'Шаблон видалено'
        )
        this.fetchPage()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style scoped lang="sass">
.admin-pwa-templates
  &__item-description
    max-width: 500px
    width: 100%
    white-space: normal
  &__item-image
    width: 100px
    height: 200px
    border-radius: 3px
    object-fit: contain
</style>
