<template>
  <div class="content-wrapper">
    <comment-form ref="commentForm"/>
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Комментарии для PWA</h4>
            <p class="card-description">Управления комментариями для PWA</p>
            <div class="mb-2">
              <button @click="handlerCreate" type="button" class="btn btn-outline-success btn-fw" id="add-text-account">
                <i class="icon-plus"></i> Добавить
              </button>
            </div>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th>Комментарий</th>
                  <th>Ответ</th>
                  <th>Пол</th>
                  <th>Вертикаль</th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model._id">
                  <td>
                    <button @click="handlerUpdate(model)"
                            class="btn btn-icons btn-inverse-primary">
                      <i class="icon-pencil"></i>
                    </button>
                    <button @click="handlerDelete(model)" class="btn btn-icons btn-inverse-danger">
                      <i class="icon-trash"></i>
                    </button>
                  </td>
                  <td>{{ model.comment | truncate(20) }}</td>
                  <td v-html="answerComment(model.answer_comment)"></td>
                  <td>{{ model.sex | sex }}</td>
                  <td>{{ vertical(model.vertical) }}</td>
                  <td>{{ model.createdAt | dateFromNow }}</td>
                  <td>{{ model.updatedAt | dateFromNow }}</td>
                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <nav v-if="pageCount > 1">
                <paginate v-model="page"
                          :page-count="pageCount"
                          :click-handler="handlerPagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          container-class="pagination mt-3"
                          page-class="page-item"
                          page-link-class="page-link"
                          prev-class="page-item"
                          next-class="page-item"
                          prev-link-class="page-link"
                          next-link-class="page-link"
                          active-class="active"/>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import CommentForm from './CommentForm.vue'

export default {
  name: 'Comments',
  components: {CommentForm},
  data () {
    return {
      limit: 20,
      count: 0,
      models: [],
      page: 1,
      loading: true
    }
  },
  computed: {
    pageCount: function () {
      return Math.ceil(this.count / this.limit)
    }
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    await this.asyncData()
  },
  filters: {
    sex (v) { return v === 'male' ? 'Мужской' : 'Женский' }
  },
  methods: {
    async asyncData () {
      this.loading = true
      await this.$store.dispatch('adminComments/asyncData', {
        limit: this.limit,
        page: this.page,
        sort: '-createdAt'
      })
      this.models = this.$store.getters['adminComments/models']
      this.count = this.$store.getters['adminComments/count']
      this.$nextTick(() => {
        this.loading = false
      })
    },
    async handlerPagination (page) {
      this.page = page
      await this.asyncData()
    },
    async handlerCreate () {
      if (await this.$refs.commentForm.handlerCreate()) {
        await this.handlerPagination(1)
      }
    },
    async handlerUpdate (model) {
      if (await this.$refs.commentForm.handlerUpdate(model)) {
        await this.handlerPagination(1)
      }
    },
    async handlerDelete (model) {
      if (confirm(`Удалить комментарий?`)) {
        await this.$store.dispatch('adminComments/delete', model.id)
        await this.handlerPagination(1)
      }
    },
    vertical (value) {
      if (value == null) {
        return null
      }
      const verticals = this.$store.getters['verticals/verticals']
      const index = verticals.findIndex(vertical => vertical.id === value)
      return this.$t(verticals[index].label)
    },
    answerComment (input = null) {
      if (input != null) {
        return this.$options.filters.truncate(input, 20)
      } else {
        return this.$options.filters.formatNull(input)
      }
    }
  }
}
</script>
