import api from '../api'
import {getSearchParams} from '../../list'

export default {
  list: (payload) => {
    return api.get('admin/proxies?' + getSearchParams(payload))
  },
  create: payload => api.post('admin/proxies', payload),
  update: ({id, ...payload}) => api.patch(`admin/proxies/${id}`, payload),
  delete: id => api.delete(`admin/proxies/${id}`),
  check: id => api.post(`admin/proxies/${id}/check`)
}
