<template>
  <div v-if="images.length > 0" class="pwa-gallery">
    <img :src="src | APIImage" alt="" v-for="(src, index) of images" :key="index" class="img-fluid rounded float-left">
  </div>
</template>
<script>
export default {
  name: 'Images',
  props: ['data'],
  data: () => ({images: []}),
  created: async function () {
    if (this.data.images === -1) {
      this.images = this.data.gallery
    } else {
      await this.$store.dispatch('verticals/read', this.data.vertical)
      this.images = this.$store.getters['verticals/vertical'][this.data.images]
    }
  }
}
</script>
<style scoped>
.pwa-gallery img {
  height: auto !important;
  width: 100px !important;;
}
.pwa-gallery img + img {
  margin-left: 5px !important;;
}
</style>
