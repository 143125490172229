<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center text-center error-page bg-primary">
      <div class="row flex-grow">
        <div class="col-lg-7 mx-auto text-white">
          <div class="row align-items-center d-flex flex-row">
            <div class="col-lg-6 text-lg-right pr-lg-4">
              <h1 class="display-1 mb-0 text-white">404</h1>
            </div>
            <div class="col-lg-6 error-page-divider text-lg-left pl-lg-4">
              <h2 class="text-white">{{ $t('notFound.title') }}</h2>
              <h3 class="font-weight-light text-white">{{ $t('notFound.body') }}</h3>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 text-center mt-xl-2">
              <router-link class="text-white font-weight-medium" :to="{name: 'PWAs'}">{{ $t('notFound.toMain') }}</router-link>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 mt-xl-2">
              <p class="text-white font-weight-medium text-center">{{ $t('notFound.copyright') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMixin from '@/mixins/title-mixin'

export default {
  name: 'NotFound',
  title: function () {
    return this.$t('titles.notFound')
  },
  mixins: [titleMixin]
}
</script>
