import api from './api'
import {getSearchParams} from '../list'

export default {
  createSelf: payload => api.post('pwas/domain/self', payload),
  createMarket: payload => api.post('pwas/domain/market', payload),
  createBought: payload => api.post('pwas/domain/bought', payload),
  updateDomainSelf: ({id, ...payload}) => api.put(`pwas/${id}/domain/self`, payload),
  patch: ({id, ...payload}) => api.patch(`pwas/${id}`, payload),
  updateDomainMarket: ({id, ...payload}) => api.put(`pwas/${id}/domain/market`, payload),
  updateDomainBought: ({id, ...payload}) => api.put(`pwas/${id}/domain/bought`, payload),
  rebuildDomain: ({id, ...payload}) => api.patch(`pwas/${id}/domain`, payload),
  read: id => api.get(`pwas/${id}`),
  update: ({_id, ...payload}) => api.put(`pwas/${_id}`, payload),
  gallery: ({_id, payload}) => api.file(`pwas/${_id}/gallery`, payload),
  galleryDelete: ({_id, payload}) => api.delete(`pwas/${_id}/gallery?file=${payload}`),
  delete: id => api.delete(`pwas/${id}`),
  getList: ({
    trash,
    ...payload
  }) => api.get('pwas?' + getSearchParams(payload) + (trash != null ? `&trash=${trash}` : '')),
  comment: ({_id, commentId}) => api.delete(`pwas/${_id}/comments/${commentId}`),
  verification: payload => api.post(`pwas/${payload._id}/verification`, payload),
  fixCloudflare: payload => api.post(`pwas/${payload._id}/fix-cloudflare`, payload),
  paidCheck: (id) => api.get(`pwas/${id}/paidCheck`),
  paidTariff: ({id, ...payload}) => api.post(`pwas/${id}/paid/tariff`, payload),
  paidBalance: ({id, ...payload}) => api.post(`pwas/${id}/paid/balance`, payload),
  paidInstalls: ({id, ...payload}) => api.post(`pwas/${id}/paid/installs`, payload),
  play: _id => api.post(`pwas/${_id}/play`),
  playInstalls: _id => api.post(`pwas/${_id}/play/installs`),
  stop: _id => api.post(`pwas/${_id}/stop`),
  copy: ({id, ...payload}) => api.post(`pwas/${id}/copy`, payload),
  getDomainPage: (id = null) => api.get(id != null ? `pwas/${id}/domain` : 'pwas/domain'),
  deleteCloudflare: (payload) => api.patch(`pwas/domain/self`, payload),
  getTrackerPage: id => api.get(`pwas/${id}/tracker`),
  updateTracker: ({pwaId, ...payload}) => api.put(`pwas/${pwaId}/tracker`, payload),
  getSettingPage: id => api.get(`pwas/${id}/settings`),
  updateSettings: ({pwaId, ...payload}) => api.put(`pwas/${pwaId}/settings`, payload),
  getAnalyticsPage: id => api.get(`pwas/${id}/analytics`),
  updateAnalytics: ({pwaId, ...payload}) => api.put(`pwas/${pwaId}/analytics`, payload),
  getVerticalPage: id => api.get(`pwas/${id}/vertical`),
  updateVertical: ({pwaId, ...payload}) => api.put(`pwas/${pwaId}/vertical`, payload),
  getCommentsPage: id => api.get(`pwas/${id}/comments`),
  updateComments: ({pwaId, ...payload}) => api.put(`pwas/${pwaId}/comments`, payload),
  pwaGenerate: ({id, payload}) => api.post(`pwas/${id}/generate`, payload),
  updateCustomJs: ({id, ...payload}) => api.patch(`pwas/${id}/customJs`, payload),
  updateMeta: ({id, ...payload}) => api.patch(`pwas/${id}/meta`, payload),
  bulkCopy: (payload) => api.post('pwas/bulk/copy', payload),
  bulkRemove: (ids) => api.delete('pwas/bulk', {ids}),
  bulkTrash: (payload) => api.patch(`pwas/bulk/trash`, payload),
  bulkRestore: (payload) => api.patch(`pwas/bulk/restore`, payload),
  bulkPlay: (ids) => api.patch('pwas/bulk/play', {ids}),
  bulkStop: (ids) => api.patch('pwas/bulk/stop', {ids}),
  bulkMeta: (payload) => api.patch('pwas/bulk/meta', payload),
  bulkCustomJs: (payload) => api.patch('pwas/bulk/customJs', payload),
  bulkPaymentChecking: (payload) => api.post(`pwas/bulk/payment/checking`, payload),
  bulkPaymentInstalls: (payload) => api.post(`pwas/bulk/payment/installs`, payload),
  bulkPaymentTariffs: (payload) => api.post(`pwas/bulk/payment/tariffs`, payload),
  bulkPaymentBalance: (payload) => api.post(`pwas/bulk/payment/balance`, payload)
}
