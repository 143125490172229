<template>
  <div class="additional_serv" v-if="tariffs.length">
    <div class="title_adit">{{ $t('tariffs.additionalServices') }}</div>
    <div class="blk_adit_g clearfix">
      <div class="item_additional_gg" v-for="tariff of tariffs" :key="tariff.id">
        <div class="in_to_item_Aditt d_flex align_center justify_between">
          <div class="left_tit_adidd flex_grow_1">
            <div class="totlt_adidd_g">{{ tariff.name[$i18n.locale] }}</div>
          </div>
          <div class="right_tit_adidd d_flex align_center flex_grow_0">
            <div class="price_adigg_g">{{ tariff.cost | amount }}</div>
            <div class="butt_pay_gghhd_g">
              <div class="d_inline_block">
                <button @click="activate(tariff)" class="border_butt_green d_flex align_center justify_center">{{ $t('tariffs.pay') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UpSells',
  data: () => ({
    tariffs: []
  }),
  created () {
    this.tariffs = this.$store.getters['tariffs/upsells']
  },
  methods: {
    activate (tariff) {
      this.$parent.$parent.$refs.activate.show(tariff)
    }
  }
}
</script>
