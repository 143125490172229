<template>
  <div class="Tabs tabs-container" v-if="tabs.length">
    <ul
      class="tabs"
      :class="{'is--small': small}"
      ref="container"
    >
      <li class="tab" :class="{'active': value === index, 'is--small': small}" v-for="(tab, index) of tabs" :key="tab" @click="setActive(index)">
        {{ tab }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    value: {
      type: Number,
      default: 0
    },
    small: Boolean
  },
  mounted () {
    const element = this.$refs.container
    element.addEventListener('wheel', (event) => {
      event.preventDefault()
      element.scrollBy({
        left: event.deltaY < 0 ? -30 : 30
      })
    })
  },
  methods: {
    setActive (index) {
      this.$emit('input', index)
    }
  }
}
</script>
<style lang="scss" scoped>
.tabs-container {
  .tabs {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, .15);
    gap: 38px;
    margin-bottom: 30px;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &.is--small {
      gap: 24px
    }
    .tab {
      font-size: 18px;
      font-weight: 400;
      font-family: Rubik;
      white-space: nowrap;
      position: relative;
      cursor: pointer;
      padding: 20px 0;
      color: #707175;
      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
      &.is--small {
        padding: 16px 0;
        font-size: 16px;
      }
      &:hover {
        color: #fff;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          bottom: 0;
          background-color: #fff;
        }
      }
      &.active {
        color: #fff;
      }
      &.active::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 0;
        background-color: #21BF73;
      }
    }
  }
}
</style>
