import api from '@/store/services/api'

import { SET_ALL } from './mutation-types'

export default {
  async getTemplatesList ({ commit }) {
    return api.get('admin/white-pages/template')
      .then((res) => {
        commit(SET_ALL, { templates: res })
      }).catch(error => {
        throw error
      })
  },
  async createAndDownload ({ commit }, data) {
    return api.post(`admin/white-pages`, data)
      .then((res) => {
        commit(SET_ALL, { data: res })
      }).catch(error => {
        throw error
      })
  }
}
