export default {
  methods: {
    removeWWW: domain => {
      const parts = domain.toString().split('.')
      if (parts.length > 0) {
        if (parts[0] === 'www') {
          return parts.splice(1).join('.')
        } else {
          return parts.join('.')
        }
      } else {
        return null
      }
    },
    sanitizerDomain (domain) {
      domain = domain.toLocaleLowerCase()
      const tmp = document.createElement('a')
      tmp.href = domain
      if (tmp.hostname !== window.location.hostname) {
        domain = tmp.hostname
      }
      return this.removeWWW(domain)
    },
    validateDomain (domain) {
      const regexDomain = /^(https?:\/\/)?(www\.)?([a-z0-9-]{1,63}\.)?[a-z0-9-]{1,63}\.[a-z]+(?:\.[a-z]{2,})?$/
      return regexDomain.test(domain)
    }
  }
}
