<template>
  <div class="modal modal_center_g" id="payment-methods-form" tabindex="-1">
    <div class="modal-dialog big_modal_w" role="document">
      <form class="modal-content modal_padd_normal" @submit.prevent="handler">
        <div class="modal_title_g">{{ $t('finance.payment.title') }}</div>
        <div class="methods_payment_g">
          <div class="sub_Tti_Ekshg">{{ $t('finance.payment.method') }}</div>
          <div class="all_payment_skshgh_g clearfix">
            <div class="item_payment_skshg" v-for="item of $store.getters['billing/paymentMethods']"
                 v-bind:key="item._id">
              <label class="d_flex align_center justify_center">
                  <span class="icon_this_payemntg">
                    <img v-bind:src="item.image" v-bind:alt="item.name">
                  </span>
                <input type="radio" name="paymentMethod" v-model="paymentMethod" v-bind:value="item._id">
                <span class="selection_oghs_fg"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="foot_payment_to_go">
          <div class="d_flex align_center dj_djghjd_g clearfix">
            <div class="item_jwejweffew">
              <div class="tit_soghs">{{ $t('finance.payment.amount') }}</div>
              <div class="final_paym_jsg_G">&#36; {{ amount }} <span class="bitcoin_gfd">{{ paymentAmount }}</span>
              </div>
            </div>
            <div class="item_jwejweffew">
              <div class="in_topshgdjf_g d_flex align_center">
                <div class="item_avt_butt_jg">
                  <button class="ful_butt_green d_flex align_center justify_center" type="submit" :disabled="processing">
                    {{ $t('finance.payment.pay') }}
                  </button>
                </div>
                <div class="item_avt_butt_jg">
                  <a href="javascript:void(0);" class="border_butt_green d_flex align_center justify_center" data-dismiss="modal">
                    {{ $t('general.cancel') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PaymentMethodsForm',
  props: ['amount'],
  data: function () {
    return {
      paymentMethod: this.$store.getters['billing/paymentMethods'][0]._id,
      processing: false
    }
  },
  computed: {
    paymentMethodInfo () {
      const index = this.$store.getters['billing/paymentMethods'].findIndex(value => value._id === this.paymentMethod)
      return this.$store.getters['billing/paymentMethods'][index]
    },
    paymentAmount () {
      const paymentMethodInfo = this.paymentMethodInfo
      if (paymentMethodInfo.rate === 'usd') {
        return ''
      }
      const rate = this.$store.getters['billing/rates'][paymentMethodInfo.rate]
      let total = Number(this.amount * rate)
      if (paymentMethodInfo.rate === 'btc') {
        total = total.toFixed(8)
      } else {
        total = total.toFixed(2)
      }
      return `(${total} ${paymentMethodInfo.rate})`
    }
  },
  methods: {
    show  () {
      // eslint-disable-next-line no-undef
      jQuery('#payment-methods-form').modal('show')
    },
    hide () {
      // eslint-disable-next-line no-undef
      jQuery('#payment-methods-form').modal('hide')
    },
    async handler  () {
      try {
        this.processing = true
        await this.$store.dispatch('billing/create', {
          amount: this.amount,
          method: this.paymentMethod
        })
        const payUrl = this.$store.getters['billing/payUrl']
        const paymentMethodInfo = this.paymentMethodInfo
        if (paymentMethodInfo.rate === 'btc') {
          const bitcoinForm = this.$parent.$parent.$refs['bitcoin-form']
          await this.$parent.$parent.getData()
          bitcoinForm.bill = this.$parent.$parent.data[0]
          bitcoinForm.bill.total = bitcoinForm.bill.total.toFixed(8)
          this.hide()
          bitcoinForm.show()
        } else if (paymentMethodInfo.name === 'Tether') {
          const trcForm = this.$parent.$parent.$refs['trc-form']
          await this.$parent.$parent.getData()
          const bill = this.$parent.$parent.data[0]
          this.hide()
          trcForm.show({...bill, total: bill.total.toFixed(2)})
        } else {
          window.location = payUrl
        }
      } catch (error) {
        await this.$store.dispatch('alerts/error', error.response.data.errors[0].msg)
      }
      this.$nextTick(() => {
        this.processing = false
      })
    }
  }
}
</script>
