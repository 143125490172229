import api from './api'
import {getSearchParams} from '../list'

export default {
  deleteKey: id => api.delete(`bot/keys/${id}`),
  deleteOffer: id => api.delete(`bot/offers/${id}`),
  getListKey: (payload) => api.get('bot/keys?' + getSearchParams(payload)),
  getListOffer: (payload) => api.get('bot/offers?' + getSearchParams(payload)),
  getOffer: (payload) => api.get('bot/offers/' + payload),
  createOffer: (payload) => api.post('bot/offers', payload),
  updateOffer: ({id, ...payload}) => api.put(`bot/offers/${id}`, payload),
  uploadImage: ({id, file}) => api.file(`bot/offers/${id}/images`, file),
  deleteImage: ({id, image}) => api.delete(`bot/offers/${id}/images/${image}`),
  adminGetListKeys: (payload) => api.get('admin/bot/keys?' + getSearchParams(payload)),
  adminCreateKey: (payload) => api.post('admin/bot/keys', payload),
  adminDeleteKey: id => api.delete(`admin/bot/keys/${id}`),
  adminGetListLocales: (payload) => api.get('admin/bot/locales?' + getSearchParams(payload)),
  adminCreateLocale: (payload) => api.post('admin/bot/locales', payload),
  adminDeleteLocale: id => api.delete(`admin/bot/locales/${id}`),
  getBotLocales: () => api.get('bot/locales')
}
