<template>
  <div class="head_action_stats_g d_flex align_center justify_between mt-1">
    <div class="filters_hed_top">
      <form class="forms-sample">
        <div class="d_flex align_center in_to_djsbgdjg">
          <select2 id="a" :data="groups.a" v-model="a" label="Фильтр по a"/>
          <select2 id="o" :data="groups.o" v-model="o" label="Фильтр по o"/>
          <select2 id="link_id" :data="groups.link_id" v-model="link_id" label="Фильтр по link_id"/>
          <select2 id="sub_id3" :data="groups.sub_id3" v-model="sub_id3" label="Фильтр по sub_id3"/>
          <select2 id="sub_id4" :data="groups.sub_id4" v-model="sub_id4" label="Фильтр по sub_id4"/>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Select2 from './Select2.vue'

export default {
  name: 'Pepper',
  props: ['value', 'groups'],
  components: {Select2},
  data: () => ({
    a: '',
    o: '',
    link_id: '',
    sub_id3: '',
    sub_id4: ''
  }),
  created: function () {
    for (const [prop, value] of Object.entries(this.value)) {
      if (this[prop] != null) {
        this[prop] = value
        this.$watch(prop, function () {
          this.$emit('input', {
            a: this.a,
            o: this.o,
            link_id: this.link_id,
            sub_id3: this.sub_id3,
            sub_id4: this.sub_id4
          })
        })
      }
    }
  }
}
</script>
