import api from '@/store/services/api'

import {
  SET_ALL,
  ADD_FAQ_CONTENT_BY_ID
} from './mutation-types'

export default {
  async getFaqCategories ({ commit }) {
    return api.get('faq/categories')
      .then((res) => {
        commit(SET_ALL, {categories: res})
      }).catch(error => {
        throw error
      })
  },
  async getFaqByCategory ({ commit }, params) {
    commit(SET_ALL, { faqListLoading: true, faqListLoaded: false })
    return api.get(`faq?page=${params.page}&limit=${params.limit}&sort=${params.sort}&filter[id]=${params.filter}`)
      .then((res) => {
        commit(SET_ALL, {
          faqList: res.models,
          faqListCount: res.count,
          faqListLoading: false,
          faqListLoaded: true
        })
      }).catch(error => {
        throw error
      })
  },
  async getFaqItemContentById ({ commit }, id) {
    return api.get(`faq/${id}`)
      .then((res) => {
        commit(ADD_FAQ_CONTENT_BY_ID, { content: res, id })
      }).catch(error => {
        throw error
      })
  }
}
