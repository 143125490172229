export default {
  navigation: 'Tracker',
  title: 'Tracker de PWA #{id}',
  message: '',
  tracker: 'Tracker',
  trackerInfo: 'Escolher uma campanha de Tracker, que irá operar com este PWA',
  trackerPlaceholder: 'Escolher um tracker',
  geo: 'Multi-GEO',
  geoTooltip: 'Multi-GEO funciona para distribuir o seu tráfego por diferentes GEOs. Se estiver a usar um sistema de camuflagem, é aconselhável ativar esta função para evitar a perda de tráfego.',
  clGeoRedirect: 'Indica o URL para redireccionamento no seu site',
  validator: {
    redirect: {
      notEmpty: 'Link para redireccionamento é obrigatório'
    }
  }
}
