<template>
  <div class="modal modal_center_g modal_medium_w" :id="id">
    <div v-if="value" class="modal-dialog" role="document">
      <div class="modal-content style_form_lab">
        <button type="button" data-dismiss="modal" class="close_sjgs_to d_flex align_center"><span
          aria-hidden="true">×</span>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    value: Boolean,
    id: String
  },
  watch: {
    value (n) {
      // eslint-disable-next-line no-undef
      jQuery('#' + this.id)
        .modal(n ? 'show' : 'hide')
        .on('hidden.bs.modal', () => this.$nextTick(() => this.$emit('input', false)))
    }
  }
}
</script>
