export default {
  online: {
    title: 'Unique online',
    info: 'Unique users who are in the app at the moment',
    table: ['Date', 'Country', 'Device', 'OS', 'Installations', 'Opens', 'PUSH'],
    deviceUnrecognized: 'Unrecognized'
  },
  activity: {
    title: 'Activity',
    info: 'User action ratio (PWA Installs, PWA Opens, Push Notification Subscriptions)',
    uniques: 'There are no uniques | Unique | Uniques',
    uniquesInfo: 'Number of uniques for the last 3 month',
    installations: 'Installations',
    installationsFull: 'Installations',
    opens: 'Opens'
  },
  uniquesAndInstalls: {
    title: 'Uniques and Installations',
    info: 'Number of unique users and PWA installs in the last 14 working days',
    uniques: 'There are no uniques | Unique | Uniques',
    uniquesInfo: 'Number of uniques in the last 14 working days',
    unique: 'Uniques',
    installations: 'Installations'
  },
  transactions: {
    title: 'Finance',
    info: 'A brief summary of your income and expenses'
  },
  topOnline: {
    title: 'Top Online',
    info: 'Top Pwa by amount of traffic at the moment'
  }
}
