import api from './api'

export default {
  index: payload => api.get('teams?' + new URLSearchParams(payload).toString()),
  create: payload => api.post('teams/members', payload),
  update: ({id, ...payload}) => api.put(`teams/members/${id}`, {id, ...payload}),
  delete: id => api.delete(`teams/members/${id}`),
  member: id => api.get(`teams/members/${id}`),
  logs: payload => api.get('logs/members?' + new URLSearchParams(payload).toString())
}
