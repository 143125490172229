<template>
  <div>
    <slot name="before"></slot>
    <input
        :class="inputClass"
        :id="id"
        :name="name"
        :disabled="disabled"
        :required="required"
        :data-min="min"
        :data-max="max"
        :data-from="max"
        :data-to="value"
    />
    <slot name="after"></slot>
  </div>
</template>
<script>
export default {
  name: 'RangeSlider',
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    inputClass: {
      type: String,
      default: 'form-control'
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: Number,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      slider: null
    }
  },
  mounted () {
    // eslint-disable-next-line no-undef
    this.slider = jQuery(`#${this.id}`).ionRangeSlider({
      onChange: data => {
        this.$emit('change', data.from)
      }
    })
  },
  beforeDestroy () {
    this.slider.ionRangeSlider('destroy')
  }
}
</script>
