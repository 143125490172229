export default {
  navigation: 'Коментарі',
  title: 'Коментарі PWA #{id}',
  message: 'Додайте необмежену кількість коментарів та відповідей до них',
  comment: {
    title: 'Коментар',
    avatar: 'Аватар',
    avatarInfo: 'Завантажте аватар клієнта',
    loadAvatar: 'Завантажте аватар',
    deleteAvatar: 'Ви впевнені що хочете видалити аватар?',
    author: 'Автор',
    authorInfo: 'Введіть ім\'я автора коментаря',
    rating: 'Рейтинг',
    ratingInfo: 'Поставте рейтинг, який виставив цей автор',
    likes: 'Лайки',
    likesInfo: 'Кількість лайків на цей коментар',
    date: 'Дата',
    dateInfo: 'Виберіть дату створення коментаря',
    commentInfo: 'Напишіть коментар, який залишив цей автор'
  },
  answer: {
    title: 'Відповідь',
    author: 'Автор',
    authorInfo: 'Вкажіть ім\'я автора, що відповідає на коментар (зазвичай це Служба підтримки програми)',
    date: 'Дата',
    dateInfo: 'Виберіть дату відповіді на коментар',
    commentInfo: 'Напишіть відповідь на коментар'
  },
  add: 'Додати коментар',
  deletedTranslate: 'Переклад коментарів видалений',
  validate: {
    dateFormat: 'Введіть дату в форматі DD.MM.YY'
  }
}
