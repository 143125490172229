import {useStore} from '@/store'

export default function admin ({next}) {
  const store = useStore()
  if (!store.getters['isAdmin'] || !store.getters['isManager']) {
    const user = JSON.parse(localStorage.getItem('identity'))
    store.dispatch('admin', user.id || user._id).then(() => next())
  } else {
    next()
  }
}
