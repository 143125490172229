<script>
import ToggleItem from '@/components/landing-pages/ToggleItem.vue'
import formMixin from '@/mixins/form-mixin'
import TrashIcon from '@/components/icons/TrashСanIcon.vue'

export default {
  components: {ToggleItem, TrashIcon},
  props: ['value', 'errors'],
  mixins: [formMixin],
  data () {
    return {
      showDeleteButton: false
    }
  },
  watch: {
    errors: {
      handler (n) {
        this.apiValidationErrors = n
      },
      deep: true
    }
  },
  methods: {
    handleFileChange (event) {
      const file = event.target.files[0]
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onload = (event) => {
        const img = new Image()
        img.src = event.target.result.toString()

        img.onload = () => {
          const height = 52
          const elem = document.createElement('canvas')
          const scaleFactor = height / img.height
          elem.height = height
          elem.width = img.width * scaleFactor

          const ctx = elem.getContext('2d')
          ctx.drawImage(img, 0, 0, img.width * scaleFactor, height)

          this.logo = ctx.canvas.toDataURL()
        }
      }
    },
    handlerDeleteLogo (event) {
      event.preventDefault()
      this.logo = null
    }
  },
  computed: {
    logo: {
      get () {
        return this.value
      },
      set (input) {
        this.$emit('input', input)
      }
    }
  }
}
</script>

<template>
  <div class="form-group" :class="apiValidationErrors[`logo`] ? 'has-danger' : ''">
    <label class="label mb-0">
      {{ $t('landingPages.paramsForm.logo') }}
      <div class="mt-2">
        <form enctype="multipart/form-data" id="logo">
          <label
            class="upload_herE_verg file_upload_g d_flex align_center justify_center addIcon mb-0">
            <input
              type="file"
              accept="image/png, image/jpeg"
              @change="handleFileChange"
            >
            <div v-if="!logo" class="d-flex flex-column">
              <div class="icon_uploas_g small_ic_g no_marg"></div>
              <span>{{ $t('landingPages.paramsForm.download') }}</span>
            </div>
            <div v-if="logo" class="d_flex align_center justify_center flex-column image-container">
              <img :src="logo" class="uploaded-image"/>
              <button @click="handlerDeleteLogo" class="close_log_g">
                <TrashIcon />
              </button>
            </div>
          </label>
        </form>
        <label v-if="apiValidationErrors[`logo`]" class="error mt-2 text-danger" for="logo">
          {{ $t(apiValidationErrors[`logo`][0]) }}
        </label>
      </div>
    </label>
  </div>
</template>

<style scoped>
.label {
  width: 100%;
}

.addIcon {
  height: 118px;
}

.addIcon input {
  cursor: pointer;
}

.close_log_g {
  z-index: 4;
  margin-bottom: 0;
  margin-top: -4px;
}

.image-container {
  width: 200px;
  height: 120px;
}

.uploaded-image {
  width: 55%;
  height: 70%;
  object-fit: contain;
}
</style>
