import AdminLayout from '../layouts/AdminLayout.vue'
import * as users from './users'
import * as pwas from './pwas'
import * as billing from './billing'
import * as tariffs from './tariffs'
import * as bot from './bot'
import domainsRouter from './domains/router'
import * as postbacks from './postbacks'
import * as members from './members'
import * as proxies from './proxies'
import * as transactions from './transactions'
import * as statistics from './statistics'
import * as faq from './faq'
import ipsRouter from './ips/router'
import cloudflareRouter from './cloudflare/router'
import AdminDashboard from './dashboard/index.vue'
import * as landingPages from './landing-pages'
import * as system from './system'

export default (meta, header) => {
  return [
    {
      path: '/admin',
      name: 'AdminLayout',
      components: {default: AdminLayout, header},
      meta,
      redirect: '/admin',
      children: [
        {
          path: '/',
          name: 'Admin',
          components: {default: AdminDashboard, header},
          meta
        },
        ...users.router(meta, header),
        ...pwas.router(meta, header),
        ...billing.router(meta, header),
        ...domainsRouter(meta, header),
        ...postbacks.router(meta, header),
        ...members.router(meta, header),
        ...proxies.router(meta, header),
        ...tariffs.router(meta, header),
        ...system.router(meta, header),
        ...bot.router(meta, header),
        ...transactions.router(meta, header),
        ...statistics.router(meta, header),
        ...faq.router(meta, header),
        ...cloudflareRouter(meta, header),
        ...ipsRouter(meta, header),
        ...landingPages.router(meta, header)
      ]
    }
  ]
}
