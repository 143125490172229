<template>
  <div class="UniquesAndInstalls card uniques-and-installs">
    <div class="uniques-and-installs__body">
      <h4 class="uniques-and-installs__title">{{ $t('dashboard.uniquesAndInstalls.title') }}
        <Info :message="$t('dashboard.uniquesAndInstalls.info')"/>
      </h4>
      <Loading v-if="loading" :value="loading" class="page-dashboard__loading" />
      <Error v-else-if="error" :error="error" @reload="getData"/>
      <div v-else-if="progress?.labels?.length">
        <div class="uniques-and-installs__numbers">
          <div class="uniques-and-installs__numbers-title">{{ uniques }}</div>
          <div class="uniques-and-installs__numbers-sub-title">{{ $tc('dashboard.uniquesAndInstalls.uniques', uniques) }}
            <Info :message="$t('dashboard.uniquesAndInstalls.uniquesInfo')"/>
          </div>
        </div>
        <div class="uniques-and-installs__canvas" v-if="progress?.labels?.length">
          <canvas id="chart-activity" class="mt-auto" ref="canvas" style="width:100%; height:100%"></canvas>
        </div>
        <div v-else class="text-center">
          <p class="text-muted">{{$t('general.nothingHere')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import textMixin from '@/mixins/text-mixin'
import Loading from '@/components/Loading.vue'
import Error from '@/components/RequestError.vue'
import {i18n} from '@/plugins/i18n'

export default {
  name: 'UniquesAndInstalls',
  mixins: [textMixin],
  components: {Info, Loading, Error},
  data () {
    return {
      activityChart: null,
      progress: null,
      loading: true,
      error: null
    }
  },
  computed: {
    localizedLabels () {
      // eslint-disable-next-line no-undef
      moment.locale(i18n.locale)
      // eslint-disable-next-line no-undef
      return this.progress?.labels.map(label => moment(label).format('D, MMM'))
    },
    uniques: function () {
      return this.progress.uniques.reduce((a, b) => a + b, 0)
    },
    areaData () {
      return {
        labels: this.localizedLabels,
        datasets: [
          {
            label: this.$t('dashboard.uniquesAndInstalls.unique'),
            data: this.progress?.uniques,
            backgroundColor: ['rgba(33,191,115,.2)'],
            borderColor: ['rgba(33, 191, 115, 1)'],
            pointBorderColor: '#2A2E32',
            pointBackgroundColor: '#21BF73',
            pointStyle: 'circle',
            fill: true,
            borderWidth: 3,
            pointRadius: 0,
            lineTension: 0.2
          },
          {
            label: this.$t('dashboard.uniquesAndInstalls.installations'),
            data: this.progress?.installs,
            backgroundColor: ['rgba(253,94,83,.2)'],
            borderColor: ['#FD5E53'],
            pointBorderColor: '#2A2E32',
            pointBackgroundColor: '#FD5E53',
            pointStyle: 'circle',
            fill: true,
            borderWidth: 3,
            pointRadius: 0,
            lineTension: 0.2
          }
        ]
      }
    },
    areaOptions () {
      return {
        interaction: {
          intersect: false,
          mode: 'index'
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          filler: {
            propagate: false
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          intersect: false
        },
        layout: {
          padding: {
            top: 10,
            left: 0,
            right: 0
          }
        },
        elements: {
          line: {
            tension: 1
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: true,
              color: 'rgba(53, 56, 58, 0.4)'
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: false,
              color: 'rgba(53, 56, 58, 0.4)'
            }
          }]
        }
      }
    }
  },
  updated () {
    this.initChart()
  },
  async mounted () {
    await this.getData()
    this.initChart()
  },
  methods: {
    getCanvas () {
      return this.$refs.canvas?.getContext('2d')
    },
    initChart () {
      const canvas = this.getCanvas()
      // eslint-disable-next-line no-unused-vars,no-undef
      const chart = new Chart(canvas, {
        type: 'line',
        data: this.areaData,
        options: this.areaOptions
      })
      window.addEventListener('resize', function () {
        chart.resize()
      })
    },
    async getData () {
      this.loading = true
      this.error = null
      try {
        this.progress = await this.$store.dispatch('dashboardStore/progress')
      } catch (error) {
        if (error.response.status === 504 || error.response.status === 403) {
          this.error = error.response.status
        }
      }
      this.loading = false
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.uniques-and-installs
  padding: 20px 20px 0px 50px
  @media screen and (max-width: 767px)
    padding-left: 20px
    padding-right: 0
  &__title
    margin-bottom: 10px
    font-size: 18px
    +font(400)
  &__numbers
    display: flex
    align-items: flex-end
  &__numbers-title
    font-size: 24px
    +font(500)
  &__numbers-sub-title
    +font(400)
    font-size: 14px
    margin-left: 5px
  &__canvas
    margin-top: 30px
    height: 300px
    padding-bottom: 0
</style>
