<template>
  <div class="block_auth_log">
    <div class="title_log_sist">{{ $t('auth.title') }}</div>
    <div class="telegramdhs_gio">
      <vue-telegram-login
        mode="callback"
        :telegram-login="botName"
        request-access="write"
        @callback="handleCallback"/>
    </div>
  </div>
</template>
<script>
import {vueTelegramLogin} from 'vue-telegram-login'

export default {
  name: 'Auth',
  components: {
    vueTelegramLogin
  },
  computed: {
    botName: function () {
      return import.meta.env.VITE_TELEGRAM_BOT_NAME
    }
  },
  methods: {
    async handleCallback (user) {
      const requestOptions = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
      try {
        if (document.cookie.search('utm_uid') >= 0) {
          user.utm_uid = document.cookie.substring(document.cookie.search('utm_uid')).split(';')[0].split('=')[1]
        }
        await this.$store.dispatch('auth', {user, requestOptions})
      } catch (error) {
        await this.$store.dispatch('alerts/error', this.$t('auth.error'))
      }
    }
  }
}
</script>
