<template>
  <component v-bind:is="tag">
    <slot v-if="!value"/>
    <div v-else class="loading_cont_go">
      <div class="in_to_load_sf d_flex justify_center">
        <img v-if="white" class="loading-image" src="/images/loader_white.svg" alt="">
        <img v-else src="/images/loader.svg" alt="" width="100">
      </div>
    </div>
  </component>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    tag: {
      type: String,
      default: 'div'
    },
    white: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-image {
  min-width: 25px;
  min-height: auto;
}
</style>
