export default {
  navigation: 'Comments',
  title: 'PWA Comments #{id}',
  message: 'Add an unlimited number of comments and replies to them',
  comment: {
    title: 'Comment',
    avatar: 'Avatar',
    avatarInfo: 'Upload client avatar',
    loadAvatar: 'Load an avatar',
    deleteAvatar: 'Are you sure you want to delete the avatar?',
    author: 'Author',
    authorInfo: 'Enter the name of the author of the comment',
    rating: 'Rating',
    ratingInfo: 'Rate this author',
    likes: 'likes',
    likesInfo: 'Number of likes for this comment',
    date: 'Date',
    dateInfo: 'Select the date the comment was created',
    commentInfo: 'Write a comment that this author left'
  },
  answer: {
    title: 'Answer',
    author: 'Author',
    authorInfo: 'Specify the name of the person responding to the comment (usually Application Support)',
    date: 'Date',
    dateInfo: 'Select a comment reply date',
    commentInfo: 'Write a reply to a comment'
  },
  add: 'Add comment',
  deletedTranslate: 'Translation of comments deleted',
  validate: {
    dateFormat: 'Input date in format DD.MM.YY'
  }
}
