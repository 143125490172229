export default {
  navigation: 'Застосунок',
  title: 'Застосунок PWA #{id}',
  message: 'Проведіть текстові налаштування сторінки PWA',
  main: {
    title: 'Основне',
    titleInfo: 'Пріоритетні налаштування, які проводяться на самому початку',
    name: 'Назва',
    nameInfo: 'Введіть назву вашого PWA',
    author: 'Автор',
    authorInfo: 'Введіть розробника PWA',
    category: 'Категорія',
    categoryInfo: 'Виберіть категорію, до якої належить Ваш PWA',
    categoryInscription: 'Категорія(напис)',
    categoryInscriptionInfo: 'Якщо потрібна інша назва категорії, наприклад, для локалізації. Якщо залишити порожнім буде взято з назви категорії',
    ad: 'Реклама',
    adInfo: 'Чи є у Вашому PWA реклама',
    age: 'Вік',
    ageInfo: 'Для осіб якого віку Ваше PWA',
    ratingM: 'Рейтинг текст(мобільне)',
    ratingMInfo: 'Слово "Рейтинг" виводиться в мобільній версії',
    sizeM: 'Розмір(мобільне)',
    sizeMInfo: 'Розмір програми в Мб, який показується в мобільній версії',
    ageM: 'Вік текст(мобільне)',
    ageMInfo: 'Слово "Вік" у мобільній версії',
    recommendationD: 'Рекомендація(ПК)',
    recommendationDInfo: 'Рекомендація від магазину. Показується у ПК версії',
    wishlistD: 'Список бажань(ПК)',
    wishlistDInfo: 'Фраза "Додати до бажаного". Показується у ПК версії',
    commentLabel: 'Відгуків',
    commentLabelInfo: 'Переклад слова відгуків',
    canInstall: 'Доступність програми',
    canInstallInfo: 'Доступність програми'
  },
  install: {
    title: 'Кнопка встановлення',
    titleInfo: 'Текстові налаштування написів на кнопці та при завантаженні',
    install: 'Встановити',
    installInfo: 'Текст напису на кнопці',
    installing: 'Ініціалізація',
    installingInfo: 'Текст, який відображається після натискання на кнопку "Встановити"',
    download: 'Завантаження',
    downloadInfo: 'Текст, який відображається після "Ініціалізація"',
    open: 'Відкрити',
    openInfo: 'Текст, який відображається після "Завантаження"'
  },
  description: {
    title: 'Опис',
    titleInfo: 'Налаштування опису вашого PWA',
    aboutM: 'Про програму(Мобільний)',
    aboutMInfo: 'Фраза "Про програму" в мобільній версії',
    readMore: 'Докладніше',
    readMoreInfo: 'Клікабельний текст "Докладніше"',
    hide: 'Приховати',
    hideInfo: 'Клікабельний текст "Приховати". З\'являється після натискання тексту "Докладніше"',
    description: 'Опис',
    descriptionInfo: 'Опис Вашого PWA',
    descriptionPlaceholder: 'Введіть опис PWAs (для користувачів)',
    update: 'Оновлення(ПК)',
    updateInfo: 'Фраза "Що нового". Показується у ПК версії',
    updateDesc: 'Опис оновлення (ПК)',
    updateDescInfo: 'Опис останнього оновлення',
    dataSecurity: 'Безпека даних',
    dataSecurityInfo: 'Напис',
    dataSecurityText: 'Опис "Безпека даних"',
    dataSecurityTextInfo: 'Опис "Безпека даних"',
    dataSecurityLink: 'Підкреслений текст у "Безпеці даних"',
    dataSecurityLinkInfo: 'Підкреслений текст у "Безпеці даних"',
    noneInfo: 'Текст у "Безпеці даних"',
    noneInfoInfo: 'Текст у "Безпеці даних"'
  },
  rating: {
    title: 'Рейтинг',
    titleInfo: 'Налаштування та регулювання рейтингу в PWA',
    reviews: 'Відгуки',
    reviewsInfo: 'Текст "Оцінки та Відгуки"',
    ratingInfo: 'Поставте рейтинг Вашого PWA за 5-бальною шкалою',
    countComments: 'К-ть відгуків',
    countCommentsInfo: 'Кількість оцінок Вашого PWA',
    total: 'Всього(ПК)',
    totalInfo: 'Слово "Всього". Показується у ПК версії',
    count: 'Кількість оцінок {num}',
    reviewsVerified: 'Напис "Оцінки та відгуки перевірені"',
    reviewsVerifiedInfo: 'Напис "Оцінки та відгуки перевірені"',
    reviewHelp: 'Напис "людини позначили цей відгук як корисний"',
    reviewHelpInfo: 'Напис "людини позначили цей відгук як корисний"',
    reviewHelpTitle: 'Напис "Вам допомогла ця інформація?"',
    reviewHelpTitleInfo: 'Напис "Вам допомогла ця інформація?"',
    reviewHelpYes: 'Відповідь "Так"',
    reviewHelpYesInfo: 'Відповідь "Так" на попереднє запитання',
    reviewHelpNo: 'Відповідь "Ні"',
    reviewHelpNoInfo: 'Відповідь "Ні" на попереднє запитання',
    reviewAll: 'Напис "Всі відгуки"',
    reviewAllInfo: 'Текст кнопки перегляду всіх відгуків'
  },
  additionalInfo: {
    title: 'Доп. інформація',
    titleInfo: 'Додаткова інформація, яка відображається внизу сторінки PWA',
    addInfo: 'Напис "Додаткова інформація"',
    author: 'Розробник',
    authorInfo: 'Напис "Розробник"',
    updated: 'Поновлено',
    updatedInfo: 'Напис "Оновлено"',
    updatedDate: 'Дата оновлення',
    updatedDateInfo: 'Введіть дату оновлення',
    size: 'Розмір',
    sizeInfo: 'Напис "Розмір"',
    installs: 'Встановлено',
    installsInfo: 'Напис "Встановлено"',
    countInstall: 'Кількість встановлень',
    countInstallInfo: 'Введіть кількість установок',
    currentVersion: 'Поточна версія',
    currentVersionLabelInfo: 'Напис "Поточна версія"',
    currentVersionInfo: 'Введіть поточну версію',
    complain: 'Напис "Поскаржитися"',
    complainInfo: 'Напис "Поскаржитися"',
    developerContact: 'Напис "Зв\'язок із розробником"',
    developerContactInfo: 'Напис "Зв\'язок з розробником"',
    developerSite: 'Напис "Сайт"',
    developerSiteInfo: 'Напис "Сайт"',
    developerUrl: 'Сайт',
    developerUrlInfo: 'Посилання на сайт компанії',
    developerEmailLabel: 'Напис "Електронна пошта"',
    developerEmailLabelInfo: 'Напис "Електронна пошта"',
    developerEmail: 'Електронна пошта',
    developerEmailInfo: 'Електронна пошта компанії',
    developerAddressLabel: 'Напис "Адреса"',
    developerAddressLabelInfo: 'Напис "Адреса"',
    developerAddress: 'Адреса компанії',
    developerAddressInfo: 'Адреса компанії',
    politicsPrivacy: 'Напис "Політика конфіденційності"',
    politicsPrivacyInfo: 'Напис "Політика конфіденційності"',
    politicsPrivacyUrl: 'Посилання на політику',
    politicsPrivacyUrlInfo: 'Посилання на політику'
  },
  ttPreland: {
    title: 'Тікток Преленд',
    titleInfo: 'Написи на преленді для TikTok',
    template: 'Шаблон',
    templateInfo: 'Виберіть шаблон для преленду',
    variant: 'Варіант {num}',
    ttHeader: 'Ви хочете перейти в',
    ttHeaderInfo: 'Питання на сторінці "TikTok"',
    ttYes: 'Відповідь "Так" на сторінці "TikTok"',
    ttNo: 'Відповідь "Ні" на сторінці "TikTok"',
    ttForce: 'Обов\'язковий преленд для будь-яких хостів',
    ttForceInfo: 'Якщо не варто, то преленд показується тільки для Andriod Chrome WebView хостів'
  },
  cookies: {
    title: 'Cookies',
    titleInfo: 'Використання файлів cookies',
    cookiesText: 'Запит cookies',
    cookiesTextInfo: 'Текст "Ви згодні з використанням файлів cookies?"',
    cookiesAgree: 'Підтвердження',
    cookiesAgreeInfo: 'Текст кнопки підтвердження',
    cookiesDisagree: 'Відмова',
    cookiesDisagreeInfo: 'Текст кнопки відмови'
  },
  translateDeleted: 'Переклад видалено'
}
