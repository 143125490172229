import Vue from 'vue'
import Vuex from 'vuex'

import alerts from './modules/alerts-module'
import referrals from './modules/referrals-module'
import auth from './modules/auth'
import autoSave from './modules/auto-save'
import verticals from './modules/verticals-module'
import pwas from './modules/pwas-module'
import users from './modules/users-module'
import statistics from './modules/statistics-module'
import adminBilling from './modules/admin/billing-module'
import adminSystem from './modules/admin/system-module'
import adminUsers from './modules/admin/users-module'
import bills from './modules/bills-module'
import avatars from './modules/avatars-module'
import comments from './modules/comments-module'
import adminPWAs from './modules/admin/pwas-module'
import adminTransactions from './modules/admin/transactions-module'
import adminStatistics from './modules/admin/statistics-module'
import messages from './modules/messages-module'
import mails from './modules/mails-module'
import adminFAQ from './modules/admin/faq-module'
import adminFAQItems from './modules/admin/faq-items-module'
import config from './modules/config-module'
import adminConfig from './modules/admin/config-module'
import adminMerchants from './modules/admin/merchants-module'
import adminGateways from './modules/admin/gateways-module'
import adminPaymentMethods from './modules/admin/payment-methods-module'
import billing from './modules/billing-module'
import adminLogs from './modules/admin/logs-module'
import adminReferrals from './modules/admin/referrals-module'
import faq from './modules/faq-module'
import adminAds from './modules/admin/ads-module'
import translator from './modules/translator-module'
import adminTariff from './modules/admin/tariffs-module'
import tariffs from './modules/tariffs-module'
import teams from './modules/teams-module'
import adminVouchers from './modules/admin/vouchers-module'
import analytics from './modules/analytics-module'
import pwasTemplates from './modules/pwas-templates-module'
import adminDomains from './modules/admin/domains-module'
import adminCloudflares from './modules/admin/cloudflares-module'
import adminTexts from './modules/admin/texts-module'
import adminComments from './modules/admin/comments-module'
import adminInstallations from './modules/admin/installations-module'
import localStorage from './modules/local-storage'
import pwasPushes from './modules/pwas-pushes-module'
import pushTemplates from './modules/push-templates-module'
import intervalPush from './modules/interval-push-module'
import adminProxies from './modules/admin/proxies-module'
import adminPostbacks from './modules/admin/postbacks-module'
import actionsPushes from './modules/actions-push-module'
import adminMembers from './modules/admin/members-module'
import adminUserTariffs from './modules/admin/user-tariffs-module'
import bot from './modules/bot-module'
import ips from './modules/ips-module'
import landingPages from './modules/landing-pages-module'
import pixels from '@/store/modules/pixels-module'
import faqStore from '@/store/faqStore'
import transactions from '@/store/transactions'
import bannersStore from '@/store/bannersStore'
import mirrorsStore from '@/store/mirrorsStore'
import whitePagesStore from '@/store/whitePagesStore'
import user from '@/store/user'
import adminPwasStore from '@/store/adminPwasStore'
import dashboardStore from '@/store/dashboardStore'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    bot,
    auth,
    autoSave,
    alerts,
    referrals,
    verticals,
    pwas,
    users,
    statistics,
    adminBilling,
    adminSystem,
    bills,
    transactions,
    avatars,
    comments,
    adminUsers,
    adminPWAs,
    adminTransactions,
    adminStatistics,
    messages,
    mails,
    adminFAQ,
    adminFAQItems,
    config,
    adminConfig,
    adminMerchants,
    adminGateways,
    adminPaymentMethods,
    billing,
    adminLogs,
    adminReferrals,
    faq,
    adminAds,
    translator,
    adminTariff,
    tariffs,
    teams,
    adminVouchers,
    analytics,
    pwasTemplates,
    adminDomains,
    adminCloudflares,
    adminTexts,
    adminComments,
    adminInstallations,
    localStorage,
    pwasPushes,
    pushTemplates,
    intervalPush,
    adminProxies,
    adminPostbacks,
    actionsPushes,
    adminMembers,
    adminUserTariffs,
    ips,
    landingPages,
    pixels,
    faqStore,
    bannersStore,
    mirrorsStore,
    whitePagesStore,
    user,
    adminPwasStore,
    dashboardStore
  }
})
export default store
export const useStore = () => store
