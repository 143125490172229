<template>
  <modal
    ref="modal"
    id="tariff-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="form-check form-check-primary">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" v-model="enable">
            {{ enable ? 'Вкл.' : 'Выкл.' }}
            <i class="input-helper"></i></label>
        </div>
      </div>
      <div class="form-group" :class="apiValidationErrors.type ? 'has-danger' : ''">
        <label for="type">Тип тарифа</label>
        <select id="type" v-model="type"
                class="form-control"
                :class="apiValidationErrors.type ? 'form-control-danger' : ''"
                @focus="clearError('type')">
          <option value="Индивидуальный">Индивидуальный</option>
          <option value="Командный">Командный</option>
          <option value="Коммерческий">Коммерческий</option>
        </select>
        <small class="form-text text-muted">Тип тарифа</small>
        <label v-if="apiValidationErrors.type" class="error mt-2 text-danger" for="type">
          {{ apiValidationErrors.type[0] }}
        </label>
      </div>
      <div class="form-group">
        <label for="name">Названия</label>
        <input type="text" id="name.ru" placeholder="Бизнес+" v-model="name.ru"
               class="form-control" min="3" max="150"
               :class="apiValidationErrors['name.ru'] ? 'form-control-danger' : ''"
               @focus="clearError('name.ru')">
        <small class="form-text text-muted">Название тарифа на русском</small>
        <label v-if="apiValidationErrors['name.ru']" class="error mt-2 text-danger" for="name.ru">
          {{ apiValidationErrors['name.ru'][0] }}
        </label>
        <input type="text" id="name.en" placeholder="Business+" v-model="name.en"
               class="form-control" min="3" max="150"
               :class="apiValidationErrors['name.en'] ? 'form-control-danger' : ''"
               @focus="clearError('name.en')">
        <small class="form-text text-muted">Название тарифа на английском</small>
        <label v-if="apiValidationErrors['name.en']" class="error mt-2 text-danger" for="name.en">
          {{ apiValidationErrors['name.en'][0] }}
        </label>
        <input type="text" id="name.uk" placeholder="Бізнес+" v-model="name.uk"
               class="form-control" min="3" max="150"
               :class="apiValidationErrors['name.uk'] ? 'form-control-danger' : ''"
               @focus="clearError('name.uk')">
        <small class="form-text text-muted">Название тарифа на украинском</small>
        <label v-if="apiValidationErrors['name.uk']" class="error mt-2 text-danger" for="name.uk">
          {{ apiValidationErrors['name.uk'][0] }}
        </label>
        <input type="text" id="name.pt" placeholder="Business+" v-model="name.pt"
               class="form-control" min="3" max="150"
               :class="apiValidationErrors['name.pt'] ? 'form-control-danger' : ''"
               @focus="clearError('name.pt')">
        <small class="form-text text-muted">Название тарифа на португальском</small>
        <label v-if="apiValidationErrors['name.pt']" class="error mt-2 text-danger" for="name.pt">
          {{ apiValidationErrors['name.pt'][0] }}
        </label>
      </div>
      <div class="form-group">
        <label for="description">Описания</label>
        <textarea id="description.ru" placeholder="Улучшенный Бизнес" v-model="description.ru"
                  class="form-control" rows="4"
                  :class="apiValidationErrors['description.ru'] ? 'form-control-danger' : ''"
                  @focus="clearError('description.ru')">
            </textarea>
        <small class="form-text text-muted">Описание тарифа на русском</small>
        <label v-if="apiValidationErrors['description.ru']" class="error mt-2 text-danger" for="description.ru">
          {{ apiValidationErrors['description.ru'][0] }}
        </label>
        <textarea id="description.en" placeholder="Superior Business" v-model="description.en"
                  class="form-control" rows="4"
                  :class="apiValidationErrors['description.en'] ? 'form-control-danger' : ''"
                  @focus="clearError('description.un')">
            </textarea>
        <small class="form-text text-muted">Описание тарифа на английском</small>
        <label v-if="apiValidationErrors['description.en']" class="error mt-2 text-danger" for="description.en">
          {{ apiValidationErrors['description.en'][0] }}
        </label>
        <textarea id="description.uk" placeholder="Покращений бізнес" v-model="description.uk"
                  class="form-control" rows="4"
                  :class="apiValidationErrors['description.uk'] ? 'form-control-danger' : ''"
                  @focus="clearError('description.uk')">
            </textarea>
        <small class="form-text text-muted">Описание тарифа на украинском</small>
        <label v-if="apiValidationErrors['description.uk']" class="error mt-2 text-danger" for="description.uk">
          {{ apiValidationErrors['description.uk'][0] }}
        </label>
        <textarea id="description.uk" placeholder="Superior Business" v-model="description.pt"
                  class="form-control" rows="4"
                  :class="apiValidationErrors['description.pt'] ? 'form-control-danger' : ''"
                  @focus="clearError('description.pt')">
            </textarea>
        <small class="form-text text-muted">Название тарифа на португальском</small>
        <label v-if="apiValidationErrors['description.pt']" class="error mt-2 text-danger" for="description.pt">
          {{ apiValidationErrors['description.pt'][0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.cost ? 'has-danger' : ''">
        <label for="cost">Стоимость</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">0.00</span>
          </div>
          <input type="number" step="0.01" id="cost" placeholder="69" v-model="cost"
                 class="form-control" min="1" max="10000"
                 :class="apiValidationErrors.cost ? 'form-control-danger' : ''"
                 @focus="clearError('cost')">
        </div>
        <small class="form-text text-muted">Стоимость за весь период</small>
        <label v-if="apiValidationErrors.cost" class="error mt-2 text-danger" for="cost">
          {{ apiValidationErrors.cost[0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.pwas ? 'has-danger' : ''">
        <label for="pwas">Кл. PWA</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Шт.</span>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">0</span>
          </div>
          <input type="number" step="1" id="pwas" placeholder="5" v-model="pwas"
                 class="form-control" min="0" max="10000"
                 :class="apiValidationErrors.pwas ? 'form-control-danger' : ''"
                 @focus="clearError('pwas')">
        </div>
        <small class="form-text text-muted">Кл. активаций и/или смен домена за период</small>
        <label v-if="apiValidationErrors.pwas" class="error mt-2 text-danger" for="pwas">
          {{ apiValidationErrors.pwas[0] }}
        </label>
      </div>
      <div class="form-group" :class="apiValidationErrors.limit ? 'has-danger' : ''">
        <label for="limit">Лимиты</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Шт.</span>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">0</span>
          </div>
          <input type="number" step="1" id="limit" placeholder="5" v-model="limit"
                 class="form-control" min="0" max="10000"
                 :class="apiValidationErrors.limit ? 'form-control-danger' : ''"
                 @focus="clearError('limit')">
        </div>
        <small class="form-text text-muted">Кл. одновременно работающих PWA</small>
        <label v-if="apiValidationErrors.limit" class="error mt-2 text-danger" for="limit">
          {{ apiValidationErrors.limit[0] }}
        </label>
      </div>
      <div class="form-group">
        <div class="form-check form-check-primary">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" v-model="bot">
            Бот в тарифі {{ bot ? '' : 'відсутній' }}
            <i class="input-helper"></i></label>
        </div>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import formMixin from '@/mixins/form-mixin'

const defaultValues = () => ({
  id: null,
  enable: false,
  type: '',
  name: {},
  description: {},
  cost: 1,
  limit: 0,
  pwas: 0,
  bot: false
})

export default {
  name: 'TariffForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      processing: false,
      ...defaultValues()
    }
  },
  computed: {
    title () {
      return this.isCreate ? 'Добавления тарифа' : `Редактирования ${this.name.ru}`
    },
    isCreate () {
      return this.id == null
    }
  },
  methods: {
    async handlerCreate () {
      this.skip()
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerUpdate ({id, enable, type, name, description, cost, limit, pwas, bot}) {
      this.skip()
      this.id = id
      this.enable = enable
      this.type = type
      this.name = name
      this.description = description
      this.cost = cost
      this.limit = limit
      this.pwas = pwas
      this.bot = bot
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        const payload = {
          enable: this.enable,
          type: this.type,
          name: this.name,
          description: this.description,
          cost: this.cost,
          limit: this.limit,
          pwas: this.pwas,
          bot: this.bot
        }
        if (!this.isCreate) {
          payload.id = this.id
        }
        await this.$store.dispatch(this.isCreate ? 'adminTariff/create' : 'adminTariff/update', payload)
        await this.$store.dispatch(
          'alerts/success',
          this.isCreate ? 'Тариф успешно создан' : 'Тариф успешно обновлён'
        )
        this.resetApiValidation()
        this.processing = false
        this.resolvePromise(true)
        this.$refs.modal.close()
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
