import api from '../api'

export default {
  list: ({page, limit, sort}) => {
    const searchParams = new URLSearchParams()
    searchParams.set('page', page)
    searchParams.set('limit', limit)
    searchParams.set('sort', sort)
    return api.get('admin/domains/cloudflares?' + decodeURI(searchParams.toString()))
  },
  create: payload => api.post('admin/domains/cloudflares', payload),
  update: ({_id, ...payload}) => api.put(`admin/domains/cloudflares/${_id}`, payload),
  delete: _id => api.delete(`admin/domains/cloudflares/${_id}`)
}
