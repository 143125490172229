import api from '../api'

export default {
  list: payload => api.get('admin/tariffs?' + new URLSearchParams(payload).toString()),
  sort: payload => api.patch(`admin/tariffs`, payload),
  create: payload => api.post('admin/tariffs', payload),
  update: ({id, ...payload}) => api.put(`admin/tariffs/${id}`, payload),
  patch: ({id, ...payload}) => api.patch(`admin/tariffs/${id}`, payload),
  delete: id => api.delete(`admin/tariffs/${id}`)
}
