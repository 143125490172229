import service from '@/store/services/avatars-service'
import middleware from './middleware'

const state = {
  lastPath: ''
}

const mutations = {
  setLastPath: (state, payload) => {
    state.lastPath = payload
  }
}

const actions = {
  create: ({commit, dispatch}, params) => {
    return service.create(params)
      .then(response => {
        commit('setLastPath', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  delete: ({commit, dispatch}, params) => {
    return service.delete(params)
      .then(() => {
        commit('setLastPath', '')
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  lastPath: state => state.lastPath
}

const avatars = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default avatars
