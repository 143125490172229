export default {
  navigation: 'Трекер',
  title: 'Трекер PWA #{id}',
  message: '',
  tracker: 'Трекер',
  trackerInfo: 'Выберите кампанию из Трекера, которая будет работать с данным PWA',
  trackerPlaceholder: 'Выберите трекер',
  geo: 'Мульти-ГЕО',
  geoTooltip: 'Мульти-ГЕО предназначен для распределения вашего трафика по различным ГЕО. Если вы используете систему маскировки, рекомендуется включить эту функцию, чтобы избежать потери трафика.',
  clGeoRedirect: 'Укажите URL для редиректа на свой вайт',
  validator: {
    redirect: {
      notEmpty: 'Ссылка для редиректа обязательна'
    }
  }
}
