export default {
  data () {
    return {
      processing: false,
      apiValidationErrors: {}
    }
  },
  methods: {
    /* extract API server validation errors and assigns them to local mixin data */
    setApiValidation (serverErrors, refs = null) {
      this.apiValidationErrors = serverErrors.reduce(
        (accumulator, errorObject) => {
          const errorDetail = (accumulator[errorObject.param] || []).concat(
            errorObject.msg
          )

          return {
            ...accumulator,
            [errorObject.param]: errorDetail
          }
        },
        {}
      )
    },

    resetApiValidation () {
      this.apiValidationErrors = {}
    },

    clearError (attribute) {
      if (this.apiValidationErrors[attribute] !== undefined) {
        this.apiValidationErrors[attribute] = undefined
      }
    }
  }
}
