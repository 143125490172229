<template>
  <div class="content-wrapper parent_tooltip PWAsTracker" v-if="accesses.pwas.update">
    <navigation />
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body pt-0">
            <div class="style_descr_g">
              <div class="container_w_sm_la">
                <h4 class="title_sf_site">{{
                    $t('pwas.components.tracker.title', {id: $route.params.id})
                  }}</h4>
                <p class="descr_site_shg mb_small">
                  {{ $t('pwas.components.tracker.message') }}
                </p>
              </div>
            </div>
            <div class="style_form_lab mt-4 pwa-tracker-container">
              <div class="container_w_sm_la">
                <form @change="onChange" v-if="!loading" class="forms-sample position-relative">
                  <div
                      v-if="user.showTrackerBypass === true"
                      class="checkbox_item_g">
                    <label class="labek_check_sg">
                      <input type="checkbox" class="form-check-input"
                             name="bypass" v-model="bypass">
                      <div class="check_icd_G"></div>
                      <span class="txt_chek_xg">Bypass</span>
                    </label>
                    <info message="Пропускати весь трафік відразу на редірект"/>
                  </div>
                  <div class="checkbox_item_g">
                    <label class="labek_check_sg">
                      <input type="checkbox" class="form-check-input"
                             name="useMultiGEO" v-model="useMultiGEO">
                      <div class="check_icd_G"></div>
                      <span class="txt_chek_xg">{{
                          $t('pwas.components.tracker.geo')
                        }}</span>
                    </label>
                    <info :message="$t('pwas.components.tracker.geoTooltip')"/>
                  </div>
                  <div v-if="useMultiGEO"
                       class="d-md-flex mt-4 mb-4">
                    <div class="flex-grow-1">
                      <div class="d-flex mb-3 flex-grow-1"
                           v-for="(filter, index) in filters"
                           :key="filter._id">
                        <div class="row flex-grow-1">
                          <div class="col-md-5 mb-2 mb-md-0">
                            <BaseSelect
                              v-model="filter._id"
                              :id="`countries-${index}`"
                              showIcon
                              @input="onChange"
                              :clearable="false"
                              :placeholder="$t('trackers.form.country')"
                              :options="countryOptions"
                              :checkDisabled="checkDisabled"
                            />
                          </div>
                          <div class="col-md-7 mb-2 mb-md-0 d-flex">
                            <div class="flex-grow-1 form-group mb-0"
                                 :class="checkMultiGeoError(index) ? 'has-danger' : ''">
                              <input type="text"
                                     class="form-control flex_grow_1"
                                     :id="`redirect-${index}`"
                                     name="filters[redirect]"
                                     :placeholder="$t('trackers.form.link')"
                                     v-model="filter.redirect"
                                     @focus="clearError(`filters[${index}].redirect`)"
                                     :class="checkMultiGeoError(index) ? 'form-control-danger' : ''">
                              <label
                                  v-if="checkMultiGeoError(index)"
                                  class="error mt-2 text-danger"
                                  :for="`redirect-${index}`">
                                {{
                                  checkMultiGeoError(index)[0]
                                }}
                              </label>
                            </div>
                            <div class="d-flex flex-grow-0">
                              <div class="ml-2">
                                <button
                                    class="btn_round_act butt_ba_ps d_flex align_center justify_center"
                                    type="button"
                                    @click="handlerLinkSetting({...filter, target: index, whitePageSource})">
                                  <i class="icon-settings"></i>
                                </button>
                              </div>
                              <div class="ml-2">
                                <button
                                    class="btn_round_act remov_act_g flex_grow_0 d_flex align_center justify_center"
                                    type="button"
                                    v-on:click="handlerDeleteFilter(index, filter)">
                                  <svg width="14" height="16"
                                       viewBox="0 0 14 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.5 12.5C5.69891 12.5 5.88968 12.421 6.03033 12.2803C6.17098 12.1397 6.25 11.9489 6.25 11.75V7.25C6.25 7.05109 6.17098 6.86032 6.03033 6.71967C5.88968 6.57902 5.69891 6.5 5.5 6.5C5.30109 6.5 5.11032 6.57902 4.96967 6.71967C4.82902 6.86032 4.75 7.05109 4.75 7.25V11.75C4.75 11.9489 4.82902 12.1397 4.96967 12.2803C5.11032 12.421 5.30109 12.5 5.5 12.5ZM13 3.5H10V2.75C10 2.15326 9.76295 1.58097 9.34099 1.15901C8.91903 0.737053 8.34674 0.5 7.75 0.5H6.25C5.65326 0.5 5.08097 0.737053 4.65901 1.15901C4.23705 1.58097 4 2.15326 4 2.75V3.5H1C0.801088 3.5 0.610322 3.57902 0.46967 3.71967C0.329018 3.86032 0.25 4.05109 0.25 4.25C0.25 4.44891 0.329018 4.63968 0.46967 4.78033C0.610322 4.92098 0.801088 5 1 5H1.75V13.25C1.75 13.8467 1.98705 14.419 2.40901 14.841C2.83097 15.2629 3.40326 15.5 4 15.5H10C10.5967 15.5 11.169 15.2629 11.591 14.841C12.0129 14.419 12.25 13.8467 12.25 13.25V5H13C13.1989 5 13.3897 4.92098 13.5303 4.78033C13.671 4.63968 13.75 4.44891 13.75 4.25C13.75 4.05109 13.671 3.86032 13.5303 3.71967C13.3897 3.57902 13.1989 3.5 13 3.5ZM5.5 2.75C5.5 2.55109 5.57902 2.36032 5.71967 2.21967C5.86032 2.07902 6.05109 2 6.25 2H7.75C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V3.5H5.5V2.75ZM10.75 13.25C10.75 13.4489 10.671 13.6397 10.5303 13.7803C10.3897 13.921 10.1989 14 10 14H4C3.80109 14 3.61032 13.921 3.46967 13.7803C3.32902 13.6397 3.25 13.4489 3.25 13.25V5H10.75V13.25ZM8.5 12.5C8.69891 12.5 8.88968 12.421 9.03033 12.2803C9.17098 12.1397 9.25 11.9489 9.25 11.75V7.25C9.25 7.05109 9.17098 6.86032 9.03033 6.71967C8.88968 6.57902 8.69891 6.5 8.5 6.5C8.30109 6.5 8.11032 6.57902 7.96967 6.71967C7.82902 6.86032 7.75 7.05109 7.75 7.25V11.75C7.75 11.9489 7.82902 12.1397 7.96967 12.2803C8.11032 12.421 8.30109 12.5 8.5 12.5Z"
                                        fill="#FD5E53" stroke="#2A2E32"
                                        stroke-width="0.5"/>
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div
                              class="add-more-button"
                            >
                              <button
                                v-show="index === filters.length - 1"
                                class="btn_round_act add_one_mor_g add-icon-plus d_flex align_center justify_center mobile-full-w"
                                v-on:click="handlerCreateFilter()"
                                type="button">
                                <svg width="17" height="18" viewBox="0 0 17 18"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8.5 2V16" stroke="#21BF73"
                                        stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                  <path d="M1.79102 9H15.2077" stroke="#21BF73"
                                        stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <redirect-field
                      v-model="redirect"
                      :errors="apiValidationErrors"
                      :clGeoWhite="clGeoWhite"
                      :useMultiGEO="useMultiGEO"
                  >
                    <button
                        @click="handlerLinkSetting()"
                        class="btn_round_act butt_ba_ps d_flex align_center justify_center"
                        type="button"><i class="icon-settings"></i></button>
                  </redirect-field>
                  <BaseFromGroup
                    :label="$t('pwas.tracker.whitePageSource.label')"
                    id="whitePageSource"
                    :info-text="$t('pwas.tracker.whitePageSource.message')"
                    :has-error="apiValidationErrors.whitePageSource"
                    :error-text="apiValidationErrors?.whitePageSource ? apiValidationErrors?.whitePageSource[0] : ''"
                  >
                    <BaseSelect
                      v-model="whitePageSource"
                      :clearable="false"
                      id="whitePageSource"
                      :options="whitePageSources"
                      @input="onChange"
                    />
                  </BaseFromGroup>
                  <div v-if="whitePageSource !== ''" class="row">
                    <div class="item_group_label_g col">
                      <div class="checkbox_item_g">
                        <label class="labek_check_sg" for="notAndroidIsBot">
                          <input type="checkbox" class="form-qcheck-input"
                                 id="notAndroidIsBot" name="notAndroidIsBot"
                                 v-model="notAndroidIsBot">
                          <div class="check_icd_G"></div>
                          <span class="txt_chek_xg">{{
                              $t('trackers.linkSettings.android')
                            }}</span>
                        </label>
                        <info
                            :message="$t('trackers.linkSettings.androidInfo')"/>
                      </div>
                    </div>
                    <div v-if="useMultiGEO" class="checkbox_item_g col">
                      <label class="labek_check_sg">
                        <input type="checkbox" class="form-check-input"
                               name="clGeoWhite" v-model="clGeoWhite">
                        <div class="check_icd_G"></div>
                        <span class="txt_chek_xg">{{
                            $t('trackers.linkSettings.white')
                          }}</span>
                      </label>
                      <info
                          :message="$t('trackers.linkSettings.whiteInfo')"/>
                    </div>
                  </div>
                  <div v-if="whitePageSource === 'redirect'" class="form-group"
                       :class="apiValidationErrors.clGeoRedirect ? 'has-danger' : ''">
                    <label for="clGeoRedirect">{{
                        $t('trackers.linkSettings.geoRedirect')
                      }}
                      <info :message="$t('trackers.linkSettings.geoRedirect')"/>
                    </label>
                    <input type="text" class="form-control" name="clGeoRedirect"
                           v-model="clGeoRedirect">
                    <label v-if="apiValidationErrors.clGeoRedirect"
                           class="error mt-2 text-danger" for="clGeoRedirect">
                      {{ apiValidationErrors.clGeoRedirect[0] }}
                    </label>
                  </div>
                  <BaseFromGroup
                    v-if="whitePageSource === 'generatePage'"
                    id="clLang"
                    :label="$t('trackers.linkSettings.whiteLang')"
                    :info-text="$t('trackers.linkSettings.whiteLangInfo')"
                    :has-error="Boolean(apiValidationErrors.clLang)"
                    :error-text="apiValidationErrors?.clLang ? apiValidationErrors?.clLang[0] : ''"
                  >
                    <BaseSelect
                      v-model="clLang"
                      id="clLang"
                      :clearable="false"
                      :options="whitePageLanguagesOptions"
                      :placeholder="$t('trackers.linkSettings.whiteLangPlaceholder')"
                    />
                  </BaseFromGroup>
                  <div v-if="whitePageSource === 'generatePage'"
                       class="form-group"
                       :class="apiValidationErrors.clUrl ? 'has-danger' : ''">
                    <label for="clUrl">{{ $t('trackers.linkSettings.clUrl') }}
                      <info :message="$t('trackers.linkSettings.appInfo')"/>
                    </label>
                    <div class="d_flex">
                      <div class="flex_grow_1">
                        <input type="text" id="clUrl" v-model="clUrl"
                               class="form-control"
                               required
                               :class="apiValidationErrors.clUrl ? 'form-control-danger' : ''"
                               @input="clearError('clUrl')">
                      </div>
                      <div class="flex_grow_0 ml_10">
                        <button
                            @click="handlerGenWP"
                            type="button"
                            class="border_butt_green d_flex align_center justify_center"
                            :disabled="processing"
                        ><span v-if="!processing">{{
                            $t('trackers.wp.generate')
                          }}</span>
                          <hollow-dots-spinner
                              v-if="processing"
                              :animation-duration="1000"
                              :dot-size="15"
                              :dots-num="3"
                              :color="'#ff1d5e'"
                          />
                        </button>
                      </div>
                    </div>
                    <label v-if="apiValidationErrors.clUrl"
                           class="error mt-2 text-danger" for="clUrl">
                      {{ apiValidationErrors.clUrl[0] }}
                    </label>
                    <label
                        v-if="!apiValidationErrors.clUrl && wpExists && clUrl.length > 0 && serverClUrl === clUrl"
                        class="mt-2 text-success"
                        for="clUrl">{{ $t('trackers.wp.success') }}</label>
                    <label
                        v-if="!apiValidationErrors.clUrl && serverClUrl !== clUrl || !apiValidationErrors.clUrl && !clUrl"
                        class="mt-2 text-warning"
                        for="clUrl">{{ $t('trackers.wp.failed') }}</label>
                  </div>
                  <div v-if="whitePageSource === 'generatePage'"
                       class="form-group"
                       :class="apiValidationErrors.clFooter ? 'has-danger' : ''">
                    <label for="clFooter">{{
                        $t('trackers.linkSettings.footer')
                      }}
                      <info :message="$t('trackers.linkSettings.footerInfo')"/>
                    </label>
                    <textarea id="clFooter" v-model="clFooter" rows="5"
                              class="form-control"
                              :class="apiValidationErrors.clFooter ? 'form-control-danger' : ''"
                              @focus="clearError('clFooter')">
                    </textarea>
                    <label v-if="apiValidationErrors.clFooter"
                           class="error mt-2 text-danger" for="clFooter">
                      {{ apiValidationErrors.clFooter[0] }}
                    </label>
                  </div>
                  <div class="form-group">
                    <label for="bindPrevButton">{{
                        $t('trackers.linkSettings.bindPrevButton')
                      }}
                      <info
                          :message="$t('trackers.linkSettings.bindPrevButtonInfo')"/>
                    </label>
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <input
                            v-model="bindPrevButton"
                            :placeholder="$t('trackers.linkSettings.bindPrevButtonPlaceholder')"
                            class="form-control"
                            id="bindPrevButton"
                            name="bindPrevButton"
                            type="text">
                      </div>
                      <div class="flex-grow-0 ml-2">
                        <button
                            @click="handlerTrafficBackSetting()"
                            class="btn_round_act butt_ba_ps d_flex align_center justify_center"
                            type="button"><i class="icon-settings"></i></button>
                      </div>
                    </div>
                    <label v-if="apiValidationErrors.bindPrevButton"
                           id="bindPrevButton-error"
                           class="error mt-2 text-danger"
                           for="bindPrevButton">{{
                        apiValidationErrors.bindPrevButton[0]
                      }}</label>
                  </div>
                  <div class="row">
                    <div class="checkbox_item_g col">
                      <label class="labek_check_sg" for="showAdditionSetting">
                        <input type="checkbox" class="form-check-input"
                               id="showAdditionSetting"
                               name="showAdditionSetting"
                               v-model="showAdditionSetting">
                        <div class="check_icd_G"></div>
                        <span class="txt_chek_xg">{{
                            $t('trackers.linkSettings.title')
                          }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="row d-block d-md-flex" v-if="showAdditionSetting">
                    <div class="checkbox_item_g col">
                      <label class="labek_check_sg" for="forwardingGet">
                        <input type="checkbox" class="form-check-input"
                               id="forwardingGet" name="forwardingGet"
                               v-model="forwardingGet">
                        <div class="check_icd_G"></div>
                        <span class="txt_chek_xg">{{
                            $t('trackers.linkSettings.get')
                          }}</span>
                      </label>
                      <info :message="$t('trackers.linkSettings.getInfo')"/>
                    </div>
                    <div class="checkbox_item_g col">
                      <label class="labek_check_sg" for="fullScreen">
                        <input type="checkbox" class="form-check-input"
                               id="fullScreen" name="fullScreen"
                               v-model="fullScreen">
                        <div class="check_icd_G"></div>
                        <span class="txt_chek_xg">{{
                            $t('trackers.linkSettings.fullscreen')
                          }}</span>
                      </label>
                      <info
                          :message="$t('trackers.linkSettings.fullscreenInfo')"/>
                    </div>
                  </div>
                  <div v-if="showAdditionSetting" class="row">
                    <div class="item_group_label_g col">
                      <div class="checkbox_item_g">
                        <label class="labek_check_sg" for="useCookies">
                          <input type="checkbox" class="form-qcheck-input"
                                 id="useCookies" name="useCookies"
                                 v-model="useCookies">
                          <div class="check_icd_G"></div>
                          <span class="txt_chek_xg">{{
                              $t('trackers.linkSettings.useCookies')
                            }}</span>
                        </label>
                        <info
                            :message="$t('trackers.linkSettings.useCookiesInfo')"/>
                      </div>
                    </div>
                    <div class="item_group_label_g col">
                      <div class="checkbox_item_g">
                        <label class="labek_check_sg" for="ignoreCloak">
                          <input type="checkbox" class="form-qcheck-input"
                                 id="ignoreCloak" name="ignoreCloak"
                                 v-model="ignoreCloak">
                          <div class="check_icd_G"></div>
                          <span class="txt_chek_xg">{{
                              $t('trackers.linkSettings.ignoreCloak')
                            }}</span>
                        </label>
                        <info
                            :message="$t('trackers.linkSettings.ignoreCloakInfo')"/>
                      </div>
                    </div>
                  </div>
                  <div
                      class="d-md-flex align-items-center mt-sm-3 mobile-grid-2">
                    <div class="mr-md-4 mb-4 mb-md-0">
                      <button
                          @click="handlerSave(null)"
                          type="button"
                          class="ful_butt_green d_flex align_center justify_center mobile-full-w"
                      >{{ $t('general.save') }}
                      </button>
                    </div>
                    <div class="mr-md-4 mb-4 mb-md-0">
                      <router-link
                          :to="{name: 'PWAsVertical', params: {id: $route.params.id}}"
                          class="border_butt_green d_flex align_center justify_center mobile-full-w">
                        {{ $t('general.next') }}
                      </router-link>
                    </div>
                    <div class="mr-md-4 mb-4 mb-md-0 one-column-mobile">
                      <button type="button"
                              class="simple_butt_afg mobile-full-w"
                              @click="handlerGenerator">
                        {{ this.$t('pwas.components.generator.button') }}
                      </button>
                    </div>
                    <div class="mr-md-4 mb-4 mb-md-0 one-column-mobile">
                      <router-link :to="{name: 'PWAs'}"
                                   class="simple_butt_afg mobile-full-w">{{
                          $t('general.cancel')
                        }}
                      </router-link>
                    </div>
                  </div>
                </form>
                <div v-else class="loading_cont_go">
                  <div class="in_to_load_sf d_flex justify_center">
                    <img src="/images/loader.svg" alt="" width="100">
                  </div>
                </div>
                <generator-form
                  v-if="!loading"
                  ref="generator"
                  :filters="filters"
                  :pwa="pwa"
                />
              </div>
            </div>
            <anime-person-in-chair/>
          </div>
        </div>
      </div>
    </div>
    <link-setting-form :errors="apiValidationErrors" ref="linkSettingForm"/>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'
import titleMixin from '@/mixins/title-mixin'
import * as components from '../components'
import accessesMixin from '@/mixins/accesses-mixin'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import AnimePersonInChair from '@/components/animatedSvgs/PersonInChair.vue'
import {whitePageLanguages} from '@pwa.group/pwa.dictionary'
import {HollowDotsSpinner} from 'epic-spinners'
import trackerMixin from '@/mixins/tracker-mixin'
import mainConnector from '@/connectors/main.connector'
import LinkSettingForm from './components/LinkSettingForm.vue'
import GeneratorForm from './components/GeneratorForm.vue'
import RedirectField from './components/RedirectField.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import confirmMixin from '@/mixins/confirm-leave-mixin'

const defaultFields = () => ({
  // Global Options
  forwardingGet: false,
  fullScreen: false,
  bindPrevButton: '',
  bindPrevTemplate: '',
  whitePageSource: '',
  ignoreCloak: false,
  // Links
  redirect: '',
  clGeoWhite: false,
  clGeoRedirect: '',
  notAndroidIsBot: false,
  clLang: '',
  clUrl: '',
  clFooter: '',
  useCookies: false,
  // Link setting
  template: '',
  // GEO links
  filters: [],
  // Split testing
  useSplitTesting: false,
  splitTestingLinks: [],
  bypass: false
})

export default {
  name: 'PWAsTracker',
  mixins: [formMixin, titleMixin, accessesMixin, select2AjaxMixin, trackerMixin, confirmMixin],
  components: {
    BaseFromGroup,
    BaseSelect,
    AnimePersonInChair,
    navigation: components.Navigation,
    Info,
    Select2,
    HollowDotsSpinner,
    LinkSettingForm,
    GeneratorForm,
    RedirectField
  },
  title () {
    return this.$t('titles.pwa.tracker', {id: this.$route.params.id})
  },
  data () {
    return {
      ...defaultFields(),
      loading: true,
      useMultiGEO: false,
      showAdditionSetting: false,
      processing: false
    }
  },
  computed: {
    wpExists () {
      return this.$store.getters['pwas/trackerPage'].checkedWP['default']
    },
    templates () {
      return this.$store.getters['pwas/trackerPage'].templates
    },
    pwa () {
      return this.$store.getters['pwas/trackerPage'].pwa
    },
    user () {
      return this.$store.getters['identity']
    },
    serverClUrl () {
      return this.$store.getters['pwas/trackerPage'].tracker?.clUrl
    },
    whitePageSources () {
      return [
        {id: '', text: this.$t('pwas.tracker.whitePageSource.notUse')},
        {
          id: 'generatePage',
          text: this.$t('pwas.tracker.whitePageSource.generatePage')
        },
        {id: 'redirect', text: this.$t('pwas.tracker.whitePageSource.redirect')}
      ]
    },
    whitePageLanguagesOptions () {
      return whitePageLanguages.map(lang => ({
        id: lang.id,
        text: this.$t(lang.text)
      }))
    },
    selectedCountries () {
      const filteredCountries = this.countryOptions.filter(country => {
        return this.filters.map(item => item._id).includes(country.id)
      })

      return filteredCountries.map(item => item.id.split('_')[1])
    }
  },
  watch: {
    apiValidationErrors: {
      handler (n) {
        if (Object.keys(n).length > 0 && this.$refs.linkSettingForm.open === false) {
          const index = Object.keys(n).findIndex(key => key.search('filters') !== -1)
          if (index !== -1) {
            let target = Object.keys(n)[index]
            target = +target.split('.')[0].replace('filters[', '').replace(']', '')
            if (this.filters[target].redirect !== '') {
              this.handlerLinkSetting({
                ...this.filters[target],
                target,
                whitePageSource: this.whitePageSource
              })
            }
          }
        }
      },
      deep: true
    },
    useMultiGEO (n) {
      if (n) {
        if (this.filters.length === 0) {
          this.handlerCreateFilter()
        }
      } else {
        this.filters = []
      }
      if (n === false && this.clGeoWhite === true) {
        this.clGeoWhite = false
      }
    },
    redirect (n) {
      const index = this.templates.findIndex(value => value._id === this.template)
      if (index !== -1) {
        this.redirect = this.applyTemplate(n, this.templates[index].params)
      }
    },
    bindPrevButton (n) {
      const index = this.templates.findIndex(value => value._id === this.bindPrevButton)
      if (index !== -1) {
        this.bindPrevButton = this.applyTemplate(n, this.templates[index].params)
      }
    },
    filters: {
      handler: function (n) {
        for (const index in n) {
          const template = this.templates.findIndex(value => value._id === this.filters[index].template)
          if (template !== -1) {
            this.filters[index].redirect = this.applyTemplate(this.filters[index].redirect, this.templates[template].params)
          }
        }
      },
      deep: true
    },
    whitePageSource (n) {
      if (n === '') {
        this.clGeoWhite = false
      }
    }
  },
  async created () {
    await this.$store.dispatch('pwas/asyncTrackerPage', this.$route.params.id)
    for (const [prop, value] of Object.entries(this.$store.getters['pwas/trackerPage'].tracker)) {
      if (this[prop] != null) {
        if ([
          'bindPrevButton',
          'bindPrevTemplate',
          'redirect',
          'clGeoRedirect',
          'clLang',
          'clUrl',
          'clFooter',
          'template',
          'whitePageSource'
        ].includes(prop) && value == null) {
          this[prop] = ''
        } else {
          this[prop] = value
        }
      }
    }
    if (this.bindPrevButton === 'full_screen') {
      this.bindPrevButton = ''
    }
    if (this.filters.length > 0) {
      this.useMultiGEO = true
    }
    if (this.forwardingGet || this.fullScreen || this.useCookies || this.ignoreCloak) {
      this.showAdditionSetting = true
    }
    this.loading = false
  },
  methods: {
    checkMultiGeoError (index) {
      return this.apiValidationErrors[`filters[${index}].redirect`] || this.apiValidationErrors[`filters[${index}]._id`]
    },
    handlerCreateFilter () {
      const freeCountries = this.select2FiltersOptions.filter(country => this.filters.findIndex(filter => country.id === filter._id) === -1)
      this.filters.push({
        _id: freeCountries[0].id,
        redirect: '',
        template: '',
        clUrl: '',
        clFooter: '',
        clLang: '',
        notAndroidIsBot: false,
        splitTestingLinks: [],
        useSplitTesting: false
      })
    },
    handlerDeleteFilter (index, filter) {
      if (confirm(this.$t('trackers.form.deletePair'))) {
        this.filters.splice(index, 1)
        if (filter.redirect) this.onChange()
        if (this.filters.length === 0) {
          this.handlerCreateFilter()
        }
      }
    },
    handlerLinkSetting (payload = null) {
      if (this.clGeoWhite === false && (this.redirect == null || this.redirect === '')) {
        this.setApiValidation([{
          location: 'body',
          msg: this.$t('pwas.components.tracker.validator.redirect.notEmpty'),
          param: 'redirect',
          value: ''
        }])
      } else {
        for (let target = 0; this.filters.length > target; target++) {
          if (this.filters[target].redirect == null || this.filters[target].redirect === '') {
            this.setApiValidation([{
              location: 'body',
              msg: this.$t('pwas.components.tracker.validator.redirect.notEmpty'),
              param: `filters[${target}].redirect`,
              value: ''
            }])
            return
          }
        }

        if (payload != null) {
          this.$refs.linkSettingForm.handlerLink(payload)
        } else {
          this.$refs.linkSettingForm.handlerTracker({
            redirect: this.redirect,
            template: this.template,
            useSplitTesting: this.useSplitTesting,
            splitTestingLinks: this.splitTestingLinks,
            whitePageSource: this.whitePageSource
          })
        }
      }
    },
    handlerTrafficBackSetting () {
      if (this.bindPrevButton != null && this.bindPrevButton !== '') {
        this.$refs.linkSettingForm.handlerTrafficBack({
          bindPrevButton: this.bindPrevButton,
          bindPrevTemplate: this.bindPrevTemplate
        })
      }
    },
    async handlerGenWP () {
      this.processing = true
      try {
        this.resetApiValidation()
        const data = await mainConnector.post(`white-pages`, {
          target: 'default',
          clUrl: this.clUrl,
          clLang: this.clLang,
          clFooter: this.clFooter,
          tracker: this.$store.getters['pwas/trackerPage'].tracker._id,
          pwa: this.$route.params.id
        })
        const checkedWP = {...this.$store.getters['pwas/trackerPage'].checkedWP, ...data}
        await this.$store.dispatch('pwas/setCheckedWP', checkedWP)
        if (data.default === true) await this.$store.dispatch('pwas/setClUrl', this.clUrl)
        this.resetApiValidation()
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
      this.$nextTick(() => {
        this.processing = false
      })
    },
    async handlerSave (payload = null) {
      if (payload != null) {
        const {target, ...datum} = payload
        for (const [prop, value] of Object.entries(datum)) {
          if (target != null) {
            this.filters[target][prop] = value
          } else {
            this[prop] = value
          }
        }
      }
      if (this.bindPrevButton === '') {
        this.bindPrevButton = null
      }
      const data = {}
      for (const [prop] of Object.entries(defaultFields())) {
        data[prop] = this[prop]
      }
      try {
        this.resetApiValidation()
        await this.$store.dispatch('pwas/updateTracker', {
          ...data,
          id: this.$store.getters['pwas/trackerPage'].tracker._id,
          pwaId: this.$route.params.id
        })
        this.resetApiValidation()
        await this.$store.dispatch(
          'alerts/success',
          this.$t('pwas.save')
        )
        this.changedData = false
        return true
      } catch (error) {
        const errors = error.response.data.errors
        let delay = 100
        if (errors[0].param.startsWith('filters')) {
          const target = +errors[0].param.match(new RegExp(/\[([0-9]{1,2})/))[1]
          const regex = new RegExp(`filters\\[${target}\\].\\S+`, 'gm')
          error.response.data.errors = errors.filter(value => regex.test(value.param))
          if (this.$refs.linkSettingForm.target !== target) {
            this.$refs.linkSettingForm.handlerCancel()
            delay = 500
          }
        } else {
          if (this.$refs.linkSettingForm.open) {
            this.$refs.linkSettingForm.handlerCancel()
            delay = 500
          }
        }
        setTimeout(() => this.setApiValidation(error.response.data.errors), delay)
        return false
      }
    },
    async handlerGenerator () {
      const save = await this.handlerSave()
      if (save) {
        this.$refs.generator.handlerOpen()
      }
    },
    onChange () {
      this.changedData = true
    },
    checkDisabled (option) {
      return !this.selectedCountries.includes(option.id.split('_')[1])
    }
  }
}
</script>
<style scoped>
.add-more-button {
  width: 38px;
  height: 38px;
  margin-left: 0.5rem;
  flex-shrink: 0;
}
.pwa-tracker-container {
  position: relative;
  z-index: 4;
}

textarea {
  padding: 10px;
}
</style>
