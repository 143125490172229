/* eslint-disable no-template-curly-in-string */
export default {
  title: 'Create PWA',
  newPwa: 'New PWA',
  body: 'To create new PWAs, you need to add a domain on which the applications will run. The domain must be "wrapped" in <a href="https://www.cloudflare.com/" target="_blank">CloudFlare</a>',
  domain: 'Enter domain',
  domainTitle: 'Enter your domain, which should be https protocol and wrapped in Cloudflare',
  auto: 'Auto tuning',
  pair: 'Saved Permissions',
  pairTitle: 'Select a saved email(apikey) pair to add a domain or add a new pair',
  apiEmail: 'Enter Cloudflare email',
  apiEmailTitle: 'Enter your Cloudflare email to use for setup',
  apiKey: 'Enter Cloudflare Api Key',
  apiKeyTitle: 'Enter your Cloudflare api key to be used for setup',
  apiHelp: 'To set up a domain, you need to get an API Key in your Cloudflare profile, to do this, follow the link <a href="https://dash.cloudflare.com/profile/api-tokens" target="_blank" class="link_green ">API Keys</a> and get the Global API Key there',
  success: 'PWA created',
  self: 'Pin your own',
  chooseAccount: 'Return to account selection',
  buyDomain: 'Buy domain (${pwaDomainCost})',
  buySuccess: 'Domain successfully paid',
  bought: 'Bought domains',
  boughtInfo: '<p class="card-description">Domains that you bought before, but do not use now</p>',
  marketInfo: '<p class="card-description">Cost of 1 domain = <strong>${pwaDomainCost}</strong><br><Strong>Important!</Strong><br>By purchasing our domains, you agree to that in case of domain blocking by <strong>registrar</strong> or <strong>Cloudflare</strong>, no claims are made against the service</p>',
  apiRequired: 'CloudFlare APIs are required',
  deleteCloudflare: 'Delete Cloudflare account?'
}
