<template>
  <div class="modal fade modal_center_g modal_teamwork" id="modal_logging">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal_padd_normal style_form_lab">
        <button type="button" data-dismiss="modal" class="close_sjgs_to d_flex align_center"><span
          aria-hidden="true">x</span>
        </button>
        <div class="title_mod_centr_g">{{ $t('teams.logs.title') }}</div>
        <table class="table table_border_style">
          <thead>
          <tr>
            <th class="text-center">{{ $t('teams.logs.country') }}</th>
            <th class="text-center">IP</th>
            <th class="text-center">{{ $t('teams.logs.device') }}</th>
            <th class="text-center">{{ $t('teams.logs.os') }}</th>
            <th class="text-center">{{ $t('teams.logs.browser') }}</th>
            <th class="text-center">{{ $t('teams.logs.login') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in rows" :key="item._id">
            <td class="text-center"><i class="flag-icon" :class="`flag-icon-${item.country}`" style="cursor: pointer"></i></td>
            <td class="text-center" v-if="item.ip">{{ item.ip }}</td>
            <td class="text-center text-muted" v-else>({{ $t('general.not') }})</td>
            <td class="text-center">{{ item.ua | formatDevice }}</td>
            <td class="text-center">{{ item.ua | formatOS }}</td>
            <td class="text-center">{{ item.ua | formatBrowser }}</td>
            <td class="text-center">{{ item.createdAt | dateFromNow }}</td>
          </tr>
          </tbody>
        </table>
          <nav v-if="pageCount > 1">
            <paginate ref="paginate"
                      v-model="pageIndex"
                      :page-count="pageCount"
                      :click-handler="pagination"
                      :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                      :next-text="'<i class=\'icon-arrow-right\'></i>'"
                      :container-class="'mt-3 pagination_gd_go d_flex justify_center align_center'"
                      :page-class="'page_item_gd'"
                      :page-link-class="'page_link_g d_flex justify_center align_center'"
                      :prev-class="'page-item_go'"
                      :next-class="'page-item_go'"
                      :prev-link-class="'page-link_go d_flex justify_center align_center'"
                      :next-link-class="'page-link_go d_flex justify_center align_center'"
                      :active-class="'active'">
            </paginate>
          </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Logs',
  data: () => ({
    id: '',
    pageIndex: 1,
    pageSize: 10,
    count: 0,
    rows: [],

    loading: false
  }),
  computed: {
    pageCount: function () {
      return Math.ceil(this.count / this.pageSize)
    }
  },
  methods: {
    show: function (id) {
      this.id = id
      this.asyncData()
      // eslint-disable-next-line no-undef
      jQuery('#modal_logging').modal('show')
    },
    hide: function () {
      // eslint-disable-next-line no-undef
      jQuery('#modal_logging').modal('hide')
      this.destroySelect2()
    },
    pagination: async function (pageIndex) {
      this.pageIndex = pageIndex
      await this.asyncData()
    },
    asyncData: async function () {
      this.loading = true
      await this.$store.dispatch('teams/logs', {
        member: this.id,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex
      })
      this.rows = this.$store.getters['teams/logs']
      this.count = this.$store.getters['teams/logsCount']
      this.loading = false
    }
  }
}
</script>
