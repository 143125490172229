<template>
  <div class="card">
    <div class="card-body has_big_pt">
      <div class="descr_ref_g">
        <h4 class="title_refer_sf">{{$t('referrals.myReferrals')}}</h4>
      </div>
      <div class="wrapper-table">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>
                {{$t('referrals.referral')}}
              </th>
              <th>
                {{$t('referrals.inMonth')}}
              </th>
              <th class="text-right">
                {{$t('referrals.total')}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="referral in referrals" :key="referral._id">
              <td>
                <div class="d-flex align-items-center">
                  <img :src="referral.photo_url || '/images/no-avatar.jpg'" alt="profile">
                  <div class="ml-2">
                    <p>{{ referral.username }}</p>
                  </div>
                </div>
              </td>
              <td>
                ${{ referral.month ? referral.month.toFixed(2) : 0 }}
              </td>
              <td class="text-right">
                ${{ referral.all ? referral.all.toFixed(2) : 0 }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Badge} from '@/components'

export default {
  name: 'ReferralsList',
  components: {
    Badge
  },
  props: {
    referrals: []
  }
}
</script>
