export default (input) => {
  if (input === null || input.length === 0) {
    return ''
  }
  let api_url = import.meta.env.VITE_APP_API_BASE_URL
  if (input.includes('blob')) {
    return input
  }
  if (api_url.substring(-1) === '/') {
    api_url = api_url.substring(0, api_url.length - 1)
  }
  if (input.substring(0, 1) === '/') {
    input = input.substring(1)
  }
  return api_url + '/' + input
}
