import service from '@/store/services/admin/proxies-service'
import middleware from '../middleware'

const state = () => ({
  count: 0,
  models: [],
  filter: {
    proxy: '',
    status: ''
  }
})

const mutations = {
  setData (state, payload) {
    const {models, count} = payload
    state.models = models
    state.count = count
  },
  setFilter (state, payload) {
    const {proxy, status} = payload
    state.filter = {proxy, status}
  }
}

const actions = {
  asyncData ({commit, dispatch}, params) {
    return service.list(params)
      .then((res) => {
        commit('setData', res)
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  create ({commit, dispatch}, params) {
    return service.create(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  update ({commit, dispatch}, params) {
    return service.update(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  setFilter ({commit}, payload) {
    commit('setFilter', payload)
  },
  delete ({commit}, params) {
    return service.delete(params)
      .then(() => {
      }).catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  async check ({commit}, params) {
    try {
      return await service.check(params)
    } catch (error) {
      middleware.validateAut(error)
      throw error
    }
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  statuses: () => ({
    new: 'Новый',
    checking: 'Проверяется',
    work: 'Рабочий',
    banned: 'В бане'
  }),
  filter: state => state.filter
}

const adminProxies = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminProxies
