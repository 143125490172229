export default {
  title: 'Кампании',
  add: 'Создать кампанию',
  noHave: 'У Вас еще нет созданных кампаний в трекер системе. Нажмите "Добавить кампанию"',
  companyInfo: 'Идентификатор кампании',
  enable: 'Вкл/Выкл',
  enableInfo: 'Состояние кампании',
  name: 'Название',
  nameInfo: 'Название кампании',
  country: 'Страны',
  countryInfo: 'ГЕО которое используется в данной кампании',
  pwaInfo: 'PWA в котором используется данная кампания',
  tag: 'Теги',
  tagInfo: 'Теги назначенные Трекеру',
  filters: {
    name: 'Поиск по названию',
    id: 'Поиск по ID',
    enable: 'Фильтр по состоянию',
    country: 'Фильтр по стране',
    pwa: 'Фильтр по PWA',
    tag: 'Фильтр по тегу',
    noHave: 'Поиск не дал результата, попробуйте изменить параметры фильтра'
  },
  form: {
    title: 'Кампания',
    setting: 'Настройки кампании',
    nameInfo: 'Название Вашей кампании. Вводите для своего удобства',
    link: 'Ссылка на оффер',
    linkDefault: 'Ссылка для неподходящего трафика',
    linkInfo: 'Введите ссылку для редиректа, которые будет задействован если Гео не подходит',
    active: 'Активна',
    notActive: 'Не активна',
    activeInfo: 'Состояние кампании',
    tags: 'Теги трекера',
    tagsInfo: 'Набор тегов для фильтрации трекера',
    newTag: 'Добавить новый тег',
    filters: 'Ссылка',
    filtersInfo: 'Выберите ГЕО и назначьте ссылку на перенаправления для это страны',
    info: 'Для перенаправления укажите пару "страна - редирект"',
    country: 'Виберете страну',
    created: 'Кампания успешно сохранена',
    deletePair: 'Удалить пару?'
  },
  linkSettings: {
    link: 'Настройки фильтра {country}({locale})',
    title: 'Дополнительные настройки',
    postback: 'Постбеки',
    postbackTemplate: 'Шаблон постбека',
    postbackTemplateSelect: 'Выберите шаблон Postback',
    postbackLink: 'Ссылка постбека',
    postbackInfo: 'Вставте этот URL в свою партнерку',
    postbackRegLink: 'Ссылка постбека для регистрации',
    postbackRegInfo: 'Если вы не понимаете, что это, обратитесь к поддержке {name}',
    postbackDepositLink: 'Ссылка постбека для депозита',
    postbackDepositInfo: 'Если вы не понимаете, что это, обратитесь к поддержке {name}',
    get: 'Прокидывать GET',
    getInfo: 'Если нужно прокинуть все GET параметры PWA в ссылку',
    fullscreen: 'Открывать Fullscreen',
    fullscreenInfo: 'Выбор отображения PWA при запуске',
    bindPrevButton: 'Traffic back',
    bindPrevButtonInfo: 'Если ссылка не указана, будет использоваться сгенерированная для перенаправления. Пользователя перенаправит на этот URL, если он захочет покинуть страницу, нажав кнопку \'назад\'',
    bindPrevButtonPlaceholder: 'URL для traffic back',
    redirect: 'Редирект',
    landingSettings: 'Настройки Landing',
    locale: 'Локаль лендинга',
    localeInfo: 'Укажите локализацию лейддинга. PWA, которое имеет нужный перевод, будет так же отображаться с переводом',
    cloakTitle: 'Клоака',
    cloak: 'Использовать клоаку',
    cloakInfo: 'Включить/выключить использование клоаки',
    android: 'Только Android',
    androidInfo: 'Весь трафик, который не является ОС Android, будет отмечаться ботом. Как следствия будет показываться white page, если он настроен',
    white: 'Отправлять другие ГЕО на вайт',
    whiteInfo: 'Если включено, всех кто не подходит под указанные ГЕО, отправлять на White page',
    whiteRedirect: 'Редирект на свой вайт',
    whiteRedirectInfo: 'Если включено, всех кто не подходит под указанные ГЕО, отправлять на указанный урл',
    geoRedirect: 'URL для редиректа по ГЕО',
    whiteLang: 'Язык white page',
    whiteLangInfo: 'Язык генерации white page',
    whiteLangPlaceholder: 'Выберите язык генерации white page',
    app: 'Ссылка на приложение',
    appInfo: 'Ссылка на приложение из play.google.com/store/apps/',
    footer: 'Блок текста в футере',
    footerInfo: 'Допускаются теги A B BR P STRONG',
    url: 'URL',
    clUrl: 'Ссылка на приложение',
    useCookies: 'Файлы cookies',
    useCookiesInfo: 'Показывать пользователям надпись для согласия на использование файлов cookies. Это нужно как фиктивный клик по странице для активации некоторых скриптов',
    ignoreCloak: 'Игнорирование клоаки',
    ignoreCloakInfo: 'Если включить эту опцию, то будет доступно игнорирование встроенной клоаки с помощью GET параметра <strong>?ignore_cloak=1</strong>. Используйте эту функцию только для тестирования. Не рекомендуется использовать при работе рекламной кампании!'
  },
  delete: {
    title: 'Удалить трекер',
    message: 'Вы уверены что хотите удалить трекер {name}?',
    button: 'Удалить',
    success: 'Трекер удален'
  },
  copy: {
    title: 'Копировать трекер',
    message: 'Вы уверены что хотите скопировать трекер {name}?',
    button: 'Копировать',
    success: 'Трекер скопирован'
  },
  wp: {
    generate: 'Сгенерировать',
    success: 'Вайтпейдж сгенерирован',
    failed: 'Вайтпейдж не сгенерирован',
    notTracker: 'Настройки клоаки станут доступными только после создания компании'
  }
}
