<template>
  <div class="TopOnlines card text-white top-online-card">
    <div class="card-body has_big_pt">
      <h4 class="top-online-card__title">{{ $t('dashboard.topOnline.title') }}
        <info icon_class="icon_inf_sg white_icon_g" :message="$t('dashboard.topOnline.info')"/>
      </h4>
      <Loading v-if="loading" :value="loading" class="page-dashboard__loading" />
      <Error v-else-if="error" :error="error" @reload="getData"/>
      <div v-else-if="items?.length" class="table-responsive">
        <table class="table">
          <tbody>
          <tr v-for="(item, index) of items" :key="index">
            <td class="py-1 pl-0">
              <div class="d-flex align-items-center">
                <img :src="item.images" alt="profile">
                <div class="ml-3">
                  <p>{{ item.name }}</p>
                </div>
              </div>
            </td>
            <td>
              {{ item.count }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="text-center">
        <p>{{$t('general.nothingHere')}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Info from '@/components/Info.vue'
import Loading from '@/components/Loading.vue'
import Error from '@/components/RequestError.vue'

export default {
  name: 'TopOnlines',
  components: {Info, Loading, Error},
  data () {
    return {
      items: [],
      loading: true,
      error: null
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      this.loading = true
      this.error = null
      try {
        const response = await this.$store.dispatch('dashboardStore/topPWAOnline')
        this.items = response
        if (this.items.length) {
          for (const index in this.items) {
            const image = this.items[index].images + 1
            if (this.items[index].images === -1) {
              this.items[index].images = import.meta.env.VITE_APP_API_BASE_URL + this.items[index].gallery[0]
            } else {
              this.items[index].images = `${import.meta.env.VITE_APP_API_BASE_URL}/verticals/${this.items[index].vertical}/${image}/${image}.jpg`
            }
          }
        }
      } catch (error) {
        if (error.response.status === 504 || error.response.status === 403) {
          this.error = error.response.status
        }
      }
      this.loading = false
    }
  }
}
</script>
<style scoped lang="sass">
@import '@/styles/_variables.sass'
.mlm10px
  margin-left: -10px
.top-online-card
  width: 100%
  max-width: 360px
  @media screen and (max-width: 767px)
    max-width: 100%
  &__title
    margin-bottom: 20px
    font-size: 18px
    +font(400)
</style>
