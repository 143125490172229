import * as dictionary from '@pwa.group/pwa.dictionary'

export default {
  computed: {
    countries () {
      if (import.meta.env.TRANSLATOR != null && import.meta.env.TRANSLATOR.toLowerCase() === 'yandex') {
        return dictionary.yandexLanguages.reduce((o, c) => {
          if (c.name != null) {
            o[c.code] = c.name
          }
          return o
        }, {})
      }
      return dictionary.locales
    }
  },
  methods: {
    getCountryName (code) {
      return this.$t(this.countries[code])
    }
  }
}
