<template>
  <div>
    <slot v-if="!value"/>
    <div v-else class="dot-opacity-loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>
