<template>
  <div class="modal fade" id="merchant-modal">
    <div class="modal-dialog mt-0">
      <form class="modal-content" @submit.prevent="handler">
        <div class="modal-header">
          <h5 class="modal-title">Форма Кассы</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group" :class="apiValidationErrors.enable ? 'has-danger' : ''">
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" id="enable" name="enable" v-model="enable">
                Вкл./Выкл.
                <i class="input-helper"></i>
              </label>
            </div>
            <label v-if="apiValidationErrors.enable" id="enable-error" class="error mt-2 text-danger"
                   for="name">{{ apiValidationErrors.enable[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.name ? 'has-danger' : ''">
            <label for="name">Название</label>
            <input class="form-control" id="name" name="name" type="text" tabindex="1" v-model="name"
                   :class="apiValidationErrors.name ? 'form-control-danger' : ''"
                   placeholder="Введите название кассы"
                   @focus="clearError('name')" autofocus>
            <label v-if="apiValidationErrors.name" id="name-error" class="error mt-2 text-danger"
                   for="name">{{ apiValidationErrors.name[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.url ? 'has-danger' : ''">
            <label for="url">Идентификатор по URL</label>
            <input class="form-control" id="url" name="url" type="text" tabindex="1" v-model="url"
                   :class="apiValidationErrors.url ? 'form-control-danger' : ''"
                   placeholder="Введите идентификатор по URL"
                   @focus="clearError('url')" autofocus>
            <label v-if="apiValidationErrors.url" id="url-error" class="error mt-2 text-danger"
                   for="url">{{ apiValidationErrors.url[0] }}</label>
          </div>
          <div class="form-group" :class="apiValidationErrors.data ? 'has-danger' : ''">
            <label for="data">Данные кассы</label>
            <div id="data">
            </div>
            <label v-if="apiValidationErrors.data" id="data-error" class="error mt-2 text-danger"
                   for="data">{{ apiValidationErrors.data[0] }}</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-success">Сохранить</button>
          <a href="javascript:void(0);" class="btn btn-light" data-dismiss="modal">Отмена</a>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import aceEditorMixin from '@/mixins/ace-editor-mixin'

export default {
  name: 'MerchantForm',
  mixins: [formMixin, aceEditorMixin],
  data: function () {
    return {
      id: false,
      name: '',
      enable: false,
      data: '',
      url: ''
    }
  },
  created: function () {
    this.initAceEditor()
  },
  methods: {
    handler: async function () {
      try {
        this.resetApiValidation()
        if (this.id) {
          await this.$store.dispatch('adminMerchants/update', {
            _id: this.id,
            enable: this.enable,
            name: this.name,
            data: this.data,
            url: this.url
          })
          await this.$store.dispatch(
            'alerts/success',
            'Касса успешно обновлёна'
          )
        } else {
          await this.$store.dispatch('adminMerchants/create', {
            enable: this.enable,
            name: this.name,
            data: this.data,
            url: this.url
          })
          await this.$store.dispatch(
            'alerts/success',
            'Касса успешно создана'
          )
        }
        this.resetApiValidation()
        await this.$parent.getData()
        this.enable = false
        this.name = ''
        this.data = ''
        this.url = ''
        this.id = false
        // eslint-disable-next-line no-undef
        jQuery('#merchant-modal').modal('hide')
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    }
  }
}
</script>
