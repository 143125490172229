<template>
  <div class="content-wrapper">
    <installation-form ref="installationForm"/>
    <tier-form ref="tierForm"/>
    <div class="row grid-margin">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Тарифы</h4>
            <p class="card-description">Управления тарифами</p>
            <div class="mb-2">
              <button @click="handlerChangeTierRate" type="button" class="btn btn-outline-success btn-fw">
                <i class="icon-layers"></i> Цена для tier
              </button>
            </div>
            <div class="row mb-2">
              <div class="col-3">
                <select class="form-control" id="countries-filter" v-model="filters.id">
                  <option value="" selected>Страна</option>
                  <option v-for="country in countries" :key="country.id" :value="country.id">{{ $t(country.text) }}</option>
                </select>
              </div>
              <div class="col-3">
                <select2
                  class="form-group"
                  id="user"
                  name="user"
                  v-model="filters.user"
                  :settings="userSettings"
                  placeholder="Фильтр по пользователю"
                />
              </div>
              <div class="col-3">
                <select class="form-control" v-model="filters.tier">
                  <option value="" selected>Фильтр по тиру</option>
                  <option :key="item" v-for="item in tier" :value="item">
                        {{ item }}
                      </option>
                </select>
              </div>
            </div>
            <div class="table-responsive">
              <table v-if="!loading" class="table table-bordered table-hover">
                <thead>
                <tr>
                  <th></th>
                  <th>Страна</th>
                  <th>Тир</th>
                  <th>Ставка</th>
                  <th v-if="filters.user">Ставка пользователя</th>
                  <th>Создан</th>
                  <th>Обновлен</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="model in models" :key="model._id">
                  <td>
                    <button @click="handlerUpdate(model)" class="btn btn-icons btn-inverse-primary">
                      <i class="icon-pencil"></i>
                    </button>
                  </td>
                  <td>{{ country(model._id) }}</td>
                  <td>
                    <select class="form-control" v-model="model.tier" @input="handlerOneUpdate($event, model, 'tier')">
                      <option :key="item" v-for="item in tier" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </td>
                  <td v-if="model.rate">{{ model.rate | amount }}</td>
                  <td v-else><span class="text-gray">( нет )</span></td>
                  <th v-if="filters.user && model.userRate">{{ model.userRate | amount }}</th>
                  <td v-else-if="filters.user"><span class="text-gray">( нет )</span></td>
                  <td>{{ model.createdAt | dateFromNow }}</td>
                  <td>{{ model.updatedAt | dateFromNow }}</td>
                </tr>
                </tbody>
              </table>
              <div v-else class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <nav v-if="pageCount > 1">
                <paginate ref="paginate"
                          v-model="page"
                          :page-count="pageCount"
                          :click-handler="pagination"
                          :prev-text="'<i class=\'icon-arrow-left\'></i>'"
                          :next-text="'<i class=\'icon-arrow-right\'></i>'"
                          :container-class="'pagination mt-3'"
                          :page-class="'page-item'"
                          :page-link-class="'page-link'"
                          :prev-class="'page-item'"
                          :next-class="'page-item'"
                          :prev-link-class="'page-link'"
                          :next-link-class="'page-link'"
                          :active-class="'active'">
                </paginate>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import * as components from '../../components'
import InstallationForm from './InstallationForm.vue'
import TierForm from './TierForm.vue'
import { countries } from '@pwa.group/pwa.dictionary'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'

export default {
  name: 'Installations',
  mixins: [select2AjaxMixin],
  components: {InstallationForm, TierForm, 'owner-filter': components.OwnerFilter, Select2},
  data () {
    return {
      loading: true,
      models: [],
      count: 0,
      tier: [1, 2, 3],
      page: 1,
      limit: 50,
      filters: {
        tier: '',
        id: '',
        user: ''
      },
      userSettings: {}
    }
  },
  async created () {
    this.asyncData = _.debounce(this.asyncData, 500)
    setTimeout(() => {
      this.countriesSelect()
    }, 500)
    this.userSettings = {...this.getSelect2AjaxSettings('users/filter/installing'), allowClear: true}
    await this.asyncData()
  },
  watch: {
    filters: {
      handler: function (n) {
        let filter = {}
        for (const [key, value] of Object.entries(n)) {
          if (value !== '') {
            filter[key] = value
          }
        }
        this.asyncData()
        this.$refs.installationForm.setUserId(filter.user)
        this.$refs.tierForm.setUserId(filter.user)
      },
      deep: true
    }
  },
  computed: {
    pageCount: function () {
      return Math.ceil(this.count / this.limit)
    },
    countries () {
      return countries
    }
  },
  methods: {
    async handlerChangeTierRate () {
      if (await this.$refs.tierForm.handlerChangeTierRate()) {
        await this.asyncData()
      }
    },
    async handlerUpdate (model) {
      if (await this.$refs.installationForm.handlerUpdate(model)) {
        await this.asyncData()
      }
    },
    async asyncData () {
      this.loading = true
      this.installations = []
      await this.$store.dispatch('adminInstallations/asyncData', {page: this.page, limit: this.limit, sort: 'createdAt', filter: this.filters})
      this.models = this.$store.getters['adminInstallations/models']
      this.count = this.$store.getters['adminInstallations/count']

      this.loading = false
    },
    async handlerOneUpdate ($event, model, prop) {
      const payload = {id: model._id}
      payload[prop] = $event.target.value
      await this.$store.dispatch('adminInstallations/oneUpdate', payload)
      const alerts = {
        type: `Тариф отнесен к типу ${model.type}`
      }
      await this.$store.dispatch('alerts/success', alerts[prop])
    },
    country (id) {
      const index = countries.findIndex((v) => v.id === id)
      return this.$t(countries[index].text)
    },
    pagination: async function (pageIndex) {
      this.pageIndex = pageIndex
      await this.asyncData()
    },
    countriesSelect: function () {
      const select2Class = '#countries-filter'
      const format = state => {
        if (!state.id) {
          return state.text
        }
        return `<i class="flag-icon flag-icon-${state.id.toLowerCase()}"></i>&nbsp;&nbsp;${state.text}`
      }
      const select2Options = {
        allowClear: true,
        placeholder: 'Фильтр по странам',
        templateResult: format,
        templateSelection: format,
        escapeMarkup: function (m) {
          return m
        }
      }
      // eslint-disable-next-line no-undef
      jQuery(select2Class).select2(select2Options).on('change', event => {
        this.filters.id = event.target.value
      })
    }
  }
}
</script>
