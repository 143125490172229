<template>
  <div class="content-wrapper parent_tooltip PWAsAnalytics" v-if="accesses.pwas.update">
    <confirm-modal ref="confirmModal" id="pwas-analytics-confirm-modal"></confirm-modal>
    <navigation active="PWAsVertical"/>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body pt-0 ">
            <div class="style_descr_g">
              <div class="container_w_sm_la">
                <h4 class="title_sf_site">{{ $t('pwas.components.analytics.title', {id: $route.params.id}) }}</h4>
                <p class="descr_site_shg mb_small">
                  {{ $t('pwas.components.analytics.message') }}
                </p>
              </div>
            </div>
            <div v-if="!loading" class="style_form_lab mt-4 pwa-analytics-container">
              <div class="container_w_sm_la">
                <form class="forms-sample position-relative" @change="onChange" @submit.prevent="handlerSave">
                  <google-analytics
                    v-if="googleAnalytics"
                    :googleAnalytics="googleAnalytics"
                    v-model="googleAnalytics"
                    @deleteAnalytics="onChange"
                  />
                  <hr class="mt-0">
                  <facebook-pixels v-if="FBPs" v-model="FBPs" @change="handlerSave" @deletePixel="onChange"/>
                  <hr class="mt-0">
                  <TTQPixel v-model="ttqs" @deletePixel="onChange" @change="onChange"/>
                  <hr class="mt-0">
                  <div class="form-group" :class="apiValidationErrors.YM ? 'has-danger' : ''">
                    <label for="YM">Yandex.Metrika
                      <info
                        :message="$t('pwas.components.analytics.YMInfo')"/>
                    </label>
                    <input type="text" id="YM" placeholder="12345678" v-model="YM" class="form-control"
                           :class="apiValidationErrors.YM ? 'form-control-danger' : ''" @focus="clearError('YM')">
                    <label v-if="apiValidationErrors.YM" class="error mt-2 text-danger" for="YM">
                      {{ apiValidationErrors.YM[0] }}
                    </label>
                  </div>
                  <div class="push-placement">
                    <BaseSwitch
                      v-if="$store.getters['identity'].pushesEnable"
                      class="push-placement__switch"
                      :label="$t('pwas.components.analytics.nativePush')"
                      v-model="useNativePUSH"
                      inline
                    >
                      <info :message="$t('pwas.components.analytics.nativePushInfo')"/>
                    </BaseSwitch>
                    <BaseFromGroup
                      v-if="!useNativePUSH"
                      label="Push One Signal API"
                      :info-text="$t('pwas.components.analytics.POSInfo')"
                      :has-error="apiValidationErrors.POS"
                      :error-text="apiValidationErrors.POS ? apiValidationErrors.POS[0] : ''"
                    >
                      <BaseInput
                        v-model="POS"
                        @focus="clearError('POS')"
                        placeholder="b52n01c5-c9g1-201e-hbea-1c4x06c13h9n"
                      />
                    </BaseFromGroup>
                    <hr class="line-hr mt-0" />
                    <div v-if="POS || useNativePUSH" class="checkbox-row">
                      <div
                        v-for="item in PWA_PUSH_PLACEMENT"
                        :key="item"
                        class="checkbox_item_g checkbox-item-single"
                      >
                        <label :for="item" class="labek_check_sg">
                          <input
                            type="radio"
                            class="form-check-input"
                            :id="item"
                            :value="item"
                            v-model="pushPlacement"
                          >
                          <div class="check_icd_G"></div>
                          <span class="txt_chek_xg">
                            {{ $t(`pwas.pushPlacement.${item}`) }}
                            <info :message="$t(`pwas.pushPlacementTooltips.${item}`)"/>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <s2s-postback
                    v-model="s2sPostback"
                    :apiValidationErrors="apiValidationErrors"
                    @save="handlerSaveS2sPostback"/>
                  <div class="d-md-flex align-items-center mt-sm-3 mobile-grid-2">
                    <div class="mr-md-4 mb-4 mb-md-0">
                      <button
                        type="submit"
                        class="ful_butt_green d_flex align_center justify_center mobile-full-w"
                      >
                        {{ $t('general.save') }}
                      </button>
                    </div>
                    <div class="mr-md-4 mb-4 mb-md-0">
                      <router-link :to="{ name: 'PWAsSetting', params: {id: $route.params.id} }"
                         class="border_butt_green d_flex align_center justify_center mobile-full-w">{{ $t('general.next') }}
                      </router-link>
                    </div>
                    <div class="mr-md-4 mb-4 mb-md-0 one-column-mobile">
                      <router-link :to="{name: 'PWAs'}" class="simple_butt_afg mobile-full-w">{{
                          $t('general.cancel')
                        }}
                      </router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <PersonInChair />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import Info from '@/components/Info.vue'
import titleMixin from '@/mixins/title-mixin'
import * as components from '../components'
import FacebookPixels from './facebook/Pixels.vue'
import accessesMixin from '@/mixins/accesses-mixin'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import PersonInChair from '@/components/animatedSvgs/PersonInChair.vue'
import S2sPostback from '../components/s2s-postback/S2sPostback.vue'
import confirmMixin from '@/mixins/confirm-leave-mixin'
import ConfirmModal from '@/components/modal/Confirm.vue'
import { PWA_PUSH_PLACEMENT } from '@/config/constans.js'
import BaseSwitch from '@/components/base/BaseSwitch.vue'
import BaseFromGroup from '@/components/base/BaseFromGroup/BaseFromGroup.vue'
import BaseInput from '@/components/base/BaseInput.vue'
const defaultFields = () => ({
  googleAnalytics: [],
  FBPs: [],
  ttqs: [],
  YM: '',
  s2sPostback: {
    install: { url: '', method: 'POST', enable: false },
    push: { url: '', method: 'POST', enable: false },
    open: { url: '', method: 'POST', enable: false },
    reopen: { url: '', method: 'POST', enable: false },
    registration: { url: '', method: 'POST', enable: false },
    deposit: { url: '', method: 'POST', enable: false }
  },
  oldValues: {}
})

export default {
  name: 'PWAsAnalytics',
  mixins: [formMixin, titleMixin, accessesMixin, select2AjaxMixin, confirmMixin],
  components: {
    ConfirmModal,
    S2sPostback,
    navigation: components.Navigation,
    Info,
    'google-analytics': components.google.Analytics,
    FacebookPixels,
    TTQPixel: components.tiktok.Pixels,
    PersonInChair,
    BaseFromGroup,
    BaseSwitch,
    BaseInput
  },
  title () {
    return this.$t('titles.pwa.analytics', {id: this.$route.params.id})
  },
  data () {
    return {
      ...defaultFields(),
      postbackMethod: 'post',
      postbackUrl: '',
      postbackEnable: false,
      loading: true,
      useNativePUSH: true,
      POS: '',
      PWA_PUSH_PLACEMENT,
      pushPlacement: PWA_PUSH_PLACEMENT.AFTER_INSTALL
    }
  },
  async created () {
    await this.$store.dispatch('pwas/asyncAnalyticsPage', this.$route.params.id)
    const entries = this.$store.getters['pwas/analyticsPage']
    this.oldValues = JSON.parse(JSON.stringify(entries))
    for (const [prop, value] of Object.entries(entries)) {
      if (this[prop] != null) {
        if (prop === 'POS' && value === null) {
          this[prop] = ''
        } else {
          this[prop] = value
        }
      }
    }
    this.$nextTick(() => {
      this.loading = false
    })
  },
  watch: {
    postbackEnable: function (n) {
      if (!this.changed.postback) {
        this.changed.postback = this.pwa.postback
      }
      this.changed.postback.enable = n
    },
    postbackMethod: function (n) {
      if (!this.changed.postback) {
        this.changed.postback = this.pwa.postback
      }
      this.changed.postback.method = n
    },
    postbackUrl: function (n) {
      if (!this.changed.postback) {
        this.changed.postback = this.pwa.postback
      }
      this.changed.postback.url = n
    },
    postback: function (n) {
      this.postbackMethod = n.method
      this.postbackUrl = n.url
      this.postbackEnable = n.enable
    }
  },
  methods: {
    async handlerSave () {
      try {
        this.resetApiValidation()
        const payload = {
          googleAnalytics: this.googleAnalytics,
          FBPs: this.FBPs,
          ttqs: this.ttqs,
          YM: this.YM,
          useNativePUSH: this.useNativePUSH,
          POS: this.POS,
          s2sPostback: this.s2sPostback,
          pushPlacement: this.pushPlacement
        }
        await this.$store.dispatch('pwas/updateAnalytics', {
          pwaId: this.$route.params.id,
          ...payload
        })
        this.resetApiValidation()
        await this.$store.dispatch(
          'alerts/success',
          this.$t('pwas.save')
        )
        this.oldValues = payload
        this.changedData = false
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    handlerSaveS2sPostback () {
      this.handlerSave()
    },
    onChange () {
      this.changedData = true
    }
  }
}
</script>
<style scoped>
.yt {
  position: relative;
  display: block;
  width: 100%; /* width of iframe wrapper */
  height: 0;
  margin: auto;
  padding: 0 0 56.25%; /* 16:9 ratio */
  overflow: hidden;
}

.yt iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.pwa-analytics-container {
  position: relative;
  z-index: 4;
}
.push-placement {
  border-radius: 5px;
  border: 1px solid #35383A;
  padding: 30px 30px 10px 30px;
  margin-bottom: 40px;
}
.push-placement__switch {
  margin-bottom: 20px;
}
.line-hr {
  margin: 20px 0;
}
.checkbox-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.checkbox-item-single {
  width: 50%;
}
@media screen and (max-width: 640px) {
  .checkbox-item-single {
    width: 100%;
  }
  .push-placement {
    padding: 20px 20px 0px 20px;
  }
}
</style>
