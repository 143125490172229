
export default {
  state: {
    identity: JSON.parse(localStorage.getItem('identity'))
  },

  mutations: {
    setItem: (state, {key, item}) => {
      state[key] = item
    }
  },

  actions: {
    setItem: ({ commit, dispatch }, { key, payload }) => {
      localStorage.setItem(key, JSON.stringify(payload))
      const item = JSON.parse(localStorage.getItem(key))
      commit('setItem', {item, key})
    }
  },

  getters: {
    identity: state => state.identity
  }
}
