const state = () => ({
  balance: 0,
  accesses: {},
  tariff: {
    bot: false
  },
  currentTariffType: ''
})

const mutations = {
  setConfig: (state, payload) => {
    state.billing = payload.billing
    state.notifications = payload.notifications
    state.mails = payload.mails
  },
  socket_config: (state, payload) => {
    state.accesses = payload
  },
  socket_tariff: (state, payload) => {
    state.tariff = payload
  },
  socket_currentTariffType: (state, payload) => {
    state.currentTariffType = payload
  }
}

const actions = {}

const getters = {
  currentTariffType: state => state.currentTariffType || 'Индивидуальный',
  balance: state => state.balance,
  accesses: state => {
    const accesses = {
      pwas: {
        view: true,
        update: true,
        delete: true
      },
      pushes: {
        view: true,
        update: true,
        delete: true
      },
      trackers: {
        view: true,
        update: true,
        delete: true
      },
      statistics: {
        view: true
      },
      teams: {
        view: true,
        update: true,
        delete: true
      },
      finance: {
        view: true,
        update: true
      },
      landings: {
        view: true,
        update: true,
        delete: true
      }
    }
    if (Object.keys(state.accesses).length > 0) {
      for (const [access, rules] of Object.entries(state.accesses)) {
        for (const [rule, value] of Object.entries(rules)) {
          if (accesses[access] != null) {
            accesses[access][rule] = value
          }
        }
      }
    }
    return accesses
  },
  tariff: state => state.tariff
}

const config = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default config
