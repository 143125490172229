export default {
  send: 'Enviar',
  cancel: 'Cancelar',
  copy: 'Copiar',
  nothingHere: 'Ainda não tem nada aqui',
  save: 'Salvar',
  yes: 'Sim',
  no: 'Não',
  on: 'Ligar',
  off: 'Desligar',
  not: 'Não',
  edit: 'Editar',
  delete: 'Apagar',
  statistics: 'Estatística',
  apply: 'Aplicar',
  next: 'Seguinte',
  read: 'Lido',
  familiarized: 'Familiarizado',
  notEmpty: 'Campo de preenchimento obrigatório',
  recordsPerPage: 'Anotações por página',
  gotoPage: 'Aceder a página',
  close: 'Fechar',
  refresh: 'Atualizar',
  alerts: {
    success: {
      heading: 'Sucesso'
    },
    warning: {
      heading: 'Aviso'
    },
    error: {
      heading: 'Erro'
    }
  },
  logout: {
    title: 'Deseja terminar a sessão?',
    message: 'Tem a certeza de que pretende terminar a sessão da sua conta?',
    okButton: 'Terminar sessão'
  }
}
