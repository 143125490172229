<template>
  <div class="content-wrapper">
    <div class="row grid-margin">
      <div class="col-md-6">
        <merchants/>
      </div>
      <div class="col-md-6">
        <payment-methods/>
      </div>
    </div>
  </div>
</template>
<script>
import Merchants from './components/Merchants.vue'
import PaymentMethods from './components/PaymentMethods.vue'

export default {
  name: 'AdminBillingMerchants',
  components: {Merchants, PaymentMethods}
}
</script>
