import Vue from 'vue'
import {
  SET_ALL,
  SET_USER_PRIVACY_READ
} from './mutation-types'

export default {
  [SET_ALL] (state, data) {
    Object.assign(state, data)
    return state
  },
  [SET_USER_PRIVACY_READ] (state, data) {
    Vue.set(state.banners, 'notUsePublicPersonInAds', true)
  },
  hideMessage (state, id) {
    const index = state.messages.findIndex(item => item.id === id)
    state.messages[index].shown = true
  }
}
