<template>
  <div class="item_gish_g">
    <button class="button_gmsn_mod" @click="handlerOpen">
      <img class="d_flex" src="/images/icon_plus.svg" alt="">
    </button>
    <modal ref="modal" id="app-translate-modal" class="fade"
           classContent="modal-content modal_padd_normal" classDialog="modal-dialog">
      <form class="style_form_lab" @submit.prevent="handlerSave">
        <button v-if="!creating" type="button" class="close_sjgs_to d_flex align_center" @click="handlerCancel"><span
          aria-hidden="true">&times;</span>
        </button>
       <div class="modal_title_g" v-html="$t('pwas.components.translate.newApp', {id: $route.params.id})"></div>
        <select2 v-model="sourceLanguage" :options="options" :settings="settings" id="sourceLanguage"
                 name="sourceLanguage" class="form-group">
          <template v-slot:before>
            <label for="langFrom">{{ $t('pwas.components.translate.from') }}</label>
          </template>
        </select2>
        <select2 v-model="newLocalization" :options="options" :settings="settings" id="newLocalization" name="newLocalization" class="form-group">
          <template v-slot:before>
            <label for="langTo">{{ $t('pwas.components.translate.to') }}</label>
          </template>
        </select2>
        <div class="modal_foot_this translator_foot">
          <div class="in_topshgdjf_g d_flex align_center">
            <div class="item_avt_butt_jg">
              <button type="submit" class="ful_butt_green d_flex align_center justify_center" name="translate"
                      v-if="!creating">
                {{ $t('pwas.components.translate.translate') }}
              </button>
              <button type="button" class="ful_butt_green d_flex align_center justify_center" name="translate"
                      v-else>
                <hollow-dots-spinner
                  :animation-duration="1000"
                  :dot-size="15"
                  :dots-num="3"
                  :color="'#ff1d5e'"
                />
              </button>
            </div>
            <div class="item_avt_butt_jg" v-if="!creating">
              <a href="javascript:void(0);" class="border_butt_green d_flex align_center justify_center"
                 @click="handlerCancel">
                {{ $t('general.cancel') }}
              </a>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import translatorFormMixin from './translator-form.mixin'
import Select2 from '@/components/Select2.vue'
import {HollowDotsSpinner} from 'epic-spinners'

const defaultFields = () => ({
  sourceLanguage: 'ru',
  newLocalization: 'en'
})

export default {
  name: 'App',
  mixins: [translatorFormMixin],
  components: {Modal, HollowDotsSpinner, Select2},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ...defaultFields(),
      creating: false
    }
  },
  methods: {
    skip () {
      const fields = defaultFields()
      for (const [prop, value] of Object.entries(fields)) {
        this[prop] = value
      }
    },
    async handlerOpen () {
      this.skip()
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
    },
    async handlerSave () {
      this.creating = true
      let translate = {}
      try {
        await this.$store.dispatch('translator/pwa', {
          langFrom: this.sourceLanguage,
          langTo: this.newLocalization,
          rows: {
            name: this.value.name,
            author: this.value.author,
            author_label: this.value.author_label,
            category_label: !this.value.category_label || this.value.category_label === '' ? this.value.category : this.value.category_label,
            ad: this.value.ad,
            rating_label: this.value.rating_label,
            age_label: this.value.age_label,
            version: this.value.version,
            wish_list: this.value.wish_list,
            install: this.value.install,
            installing: this.value.installing,
            download: this.value.download,
            open: this.value.open,
            about: this.value.about,
            read_more: this.value.read_more,
            hide: this.value.hide,
            text: this.value.text,
            changes_label: this.value.changes_label,
            changes_recent: this.value.changes_recent,
            reviews_label: this.value.reviews_label,
            total: this.value.total,
            addition_info: this.value.addition_info,
            updated: this.value.updated,
            updated_at: this.value.updated_at,
            size_label: this.value.size_label,
            installed_label: this.value.installed_label,
            current_version_label: this.value.current_version_label,
            ttHeader: this.value.ttHeader,
            ttYes: this.value.ttYes,
            ttNo: this.value.ttNo,
            commentLabel: this.value.commentLabel,
            canInstall: this.value.canInstall,
            dataSecurity: this.value.dataSecurity,
            dataSecurityText: this.value.dataSecurityText,
            dataSecurityLink: this.value.dataSecurityLink,
            noneInfo: this.value.noneInfo,
            reviewsVerified: this.value.reviewsVerified,
            reviewHelp: this.value.reviewHelp,
            reviewHelpTitle: this.value.reviewHelpTitle,
            reviewHelpYes: this.value.reviewHelpYes,
            reviewHelpNo: this.value.reviewHelpNo,
            reviewAll: this.value.reviewAll,
            complain: this.value.complain,
            developerContact: this.value.developerContact,
            developerSite: this.value.developerSite,
            developerUrl: this.value.developerUrl,
            developerEmailLabel: this.value.developerEmailLabel,
            developerEmail: this.value.developerEmail,
            developerAddressLabel: this.value.developerAddressLabel,
            developerAddress: this.value.developerAddress,
            politicsPrivacy: this.value.politicsPrivacy,
            politicsPrivacyUrl: this.value.politicsPrivacyUrl,
            cookiesText: this.value.cookiesText,
            cookiesAgree: this.value.cookiesAgree,
            cookiesDisagree: this.value.cookiesDisagree
          }
        })
        translate = this.$store.getters['translator/pwa']
        this.value.languages[this.newLocalization] = {
          comments: this.value.languages[this.newLocalization]?.comments,
          ...translate
        }
        await this.$parent.$parent.$parent.$set(this.$parent.$parent.$parent.changed, 'languages', this.value.languages)
        this.creating = false
        await this.$parent.$parent.$parent.handlerUpdate(false)
        this.handlerCancel()
        await this.$store.dispatch('alerts/success', this.$t('pwas.components.translate.success'))
        await this.$router.push({name: 'LPWAsApp', params: {id: this.$route.params.id, language: this.newLocalization}})
      } catch (error) {
        if (error.response) {
          await this.$store.dispatch('alerts/error', error.response.data.errors[0].msg)
        } else {
          await this.$store.dispatch('alerts/error', error.message)
        }
        this.creating = false
      }
    }
  }
}
</script>
