export default {
  subscribe: 'Subscription to tariff "{name}"',
  contains: 'Tariff "{name}" contains:',
  pwas: '0 activations | One activation | {num} activations | {num} activations',
  limit: '{num} active PWAs',
  month: 'Monthly subscription',
  onDays: 'Subscribe for {num} days',
  forStart: 'For Start',
  pay: 'Pay',
  messages: [
    'Activation means transferring a valid PWA from <span class="color_red_go">"Not paid"</span> to <span class="color_green_go">"Working"</span>',
    'The number of active PWAs means the number of PWAs that have the status "Working"'
  ],
  promoCode: 'Promo code',
  amount: 'Amount due',
  success: 'You have subscribed to a new plan',
  voucherRequired: 'Enter promo code',
  beforeDate: 'Tariff info until {date}',
  contained: 'Tariff "{name}" contained:',
  paid: 'You paid:',
  info: 'Information',
  your: 'Your rate',
  active: '{used} from {pwa} PWA is running',
  activations: 'Activations available: {num}',
  expired: '0 | One day before the end of the subscription | {num} days until the end of the subscription | {num} days until the end of the subscription',
  upCost: 'Go for {num} $',
  auto: 'Auto-subscription active',
  noTariffs: 'This tariff range is under development',
  individual: 'Individual',
  command: 'Command',
  commercial: 'Commercial',
  history: 'History',
  historyTitle: 'History of tariffs',
  additionalServices: 'Additional services',
  installations: {
    tier1: 'Tier 1',
    tier2: 'Tier 2',
    tier3: 'Tier 3',
    title: 'Pricing for installs',
    header: 'Pricing for one PWA install',
    text: 'If you have any questions or misunderstandings about pricing, contact customer <a href="https://t.me/{tgHelpdesk}" target="_blank">support</a>'
  }
}
