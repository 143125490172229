<template>
  <div class="row">
    <button @click="$router.go(-1)" class="btn btn-icons btn-rounded btn-outline-success col-1"><i class="icon-action-undo"></i></button>
    <nav aria-label="breadcrumb" :class="navClass">
      <ol class="breadcrumb bg-dark">
        <li class="breadcrumb-item">
          <router-link :to="{name: 'Admin'}">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{name: 'AdminPWAs'}">PWAs</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page"><span>{{ id }}</span></li>
      </ol>
    </nav>
    <slot/>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: {
    id: String,
    navClass: {
      type: String,
      default: 'col-10'
    }
  }
}
</script>
