import Referrals from './Referrals.vue'

export default (meta, header) => {
  return [
    {
      path: 'referrals',
      name: 'Referrals',
      components: {default: Referrals, header: header},
      meta: meta
    }
  ]
}
