import service from '@/store/services/admin/user-tariffs-service'
import middleware from '../middleware'

const state = () => ({
  models: [],
  count: 0,
  tariffs: [],
  tariff: null
})

const mutations = {
  setTariffs: (state, {models, count}) => {
    state.models = models
    state.count = count
  },
  setListTariffs: (state, payload) => {
    state.tariffs = payload
  },
  setTariff: (state, payload) => {
    state.tariff = payload
  }
}

const actions = {
  getTariffs: ({commit, dispatch}, options) => {
    return service.getTariffs(options)
      .then(response => {
        commit('setTariffs', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  getListTariffs: ({commit, dispatch}, options) => {
    return service.getListTariffs(options)
      .then(response => {
        commit('setListTariffs', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  getTariff: ({commit, dispatch}, options) => {
    return service.getTariff(options)
      .then(response => {
        commit('setTariff', response)
      })
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  createTariff: ({commit, dispatch}, data) => {
    return service.createTariff(data)
      .then()
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  updateTariff: ({commit, dispatch}, data) => {
    return service.updateTariff(data)
      .then()
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  },
  deleteTariff: ({commit, dispatch}, data) => {
    return service.deleteTariff(data)
      .then()
      .catch(error => {
        middleware.validateAut(error)
        throw error
      })
  }
}

const getters = {
  models: state => state.models,
  count: state => state.count,
  tariffs: state => state.tariffs,
  tariff: state => state.tariff
}

const adminUserTariffs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default adminUserTariffs
