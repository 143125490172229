export default {
  all: 'Всi',
  add: 'Додати співробітника',
  deleteEmployee: 'Ви впевнені, що хочете видалити співробітника?',
  employeeDeleted: 'Запис видалено',
  employeeSaved: 'Запис збережено',
  accessData: 'Дані для доступу співробітника',
  link: 'Посилання для співробітника:',
  password: 'Пароль:',
  name: 'Ім\'я співробітника',
  groups: 'Виберіть або створіть групу',
  privacySettings: 'Налаштування приватності',
  isPWAsOwner: 'Тільки свої PWA',
  customPWATag: 'Свій тег для PWA',
  isCompaniesOwner: 'Тільки свої PUSH',
  customCompaniesTag: 'Свій тег для PUSH',
  isLandingsOwner: 'Тільки свої Лендінги',
  customLandingsTag: 'Свій тег для Лендінгу',
  isTrackersOwner: 'Тільки свої трекери',
  customTrackerTag: 'Свій тег для трекера',
  accessSettings: 'Налаштування доступу',
  view: 'Перегляд',
  edit: 'Зміна',
  delete: 'Видалення',
  tracker: 'Трекер',
  statistics: 'Статистика',
  landings: 'Лендінги',
  teams: 'Командна робота',
  finance: 'Тарифи та фінанси',
  logs: {
    title: 'Логування',
    country: 'Країна',
    device: 'Пристрій',
    os: 'ОС',
    browser: 'Браузер',
    login: 'Дата логіна'
  },
  validator: {
    username: {
      maxLength: 'Ім\'я користувача має бути менше 30 символів'
    },
    access: {
      isOneAccess: 'У співробітника має буду хочаб один доступ'
    }
  },
  trash: {
    delete: 'Видалити співробітника',
    deleteMessage: 'Ви впевнені, що хочете видалити співробітника {username}?',
    deleteButton: 'Видалити'
  }
}
