export default {
  title: 'PUSH envios em massa',
  add: 'Adicionar envio em massa',
  reload: 'Atualizar',
  types: {
    postponed: 'Suspenso',
    interval: 'Periódica'
  },
  filters: {
    name: 'Busca por nome',
    status: 'Filtro por estatuto',
    range: {
      placeholder: 'Filtro por data',
      today: 'Hoje',
      tomorrow: 'Amanhã',
      nextSevenDay: '7 dias a seguir'
    },
    pwa: 'Filtro por PWA',
    domains: 'Filtro por domínios',
    country: 'Filtro por país',
    conversions: {
      placeholder: 'Filtro por conversão',
      options: ['Com conversões', 'Sem conversões']
    },
    type: 'Filtro por tipo'
  },
  form: {
    formTitle: 'Forma de configuração de envios em massa',
    message: 'Configuração de envios em massa e conteúdo para enviar',
    success: 'PUSH subscrição foi guardada com sucesso',
    preview: 'Miniatura',
    tags: {
      title: 'Tags de envios em massa',
      info: 'Conjunto de tags para filtração de envios em massa'
    },
    name: {
      title: 'Nome',
      info: 'Nome de sistema de envios em massa',
      placeholder: 'Introduza o nome de envio em massa'
    },
    publishedAt: {
      title: 'Início',
      info: 'Data de início de envios em massa',
      placeholder: 'Introduza a data'
    },
    title: {
      title: 'Cabeçalho',
      info: 'Cabeçalho de PUSH mensagem. Comprimento recomendado é de até 37 símbolos. Suporta spintax',
      placeholder: 'Introduza o cabeçalho'
    },
    body: {
      title: 'Texto',
      info: 'Texto de PUSH mensagem. Comprimento recomendado é de até 50 símbolos. Suporta spintax',
      placeholder: 'Introduza o texto'
    },
    url: {
      title: 'Link',
      info: 'Link a abrir quando clicar. Caso não indicar automaticamente será aberto URL instalado mo PWA',
      placeholder: 'Introduza o link'
    },
    badge: {
      title: 'Sinal',
      info: 'Imagem, usada para apresentar notificações, quando falta espaço para a própria notificação. Adaptamos a imagem para a dimensão recomendada (96 x 96)',
      placeholder: 'Carregar o sinal'
    },
    icon: {
      title: 'Ícone',
      info: 'Imagem usada como ícone de notificações. Adaptamos a imagem para a dimensão recomendada (192 x 192)',
      placeholder: 'Carregar o ícone'
    },
    image: {
      title: 'Imagem',
      info: 'Imagem que visualizar-se-á como uma parte de notificações. Adaptamos a imagem para a dimensão recomendada (492 x 225)',
      placeholder: 'Carregar a imagem'
    },
    dir: {
      title: 'Direção do texto',
      info: 'Indica direção do texto de PUSH notificações',
      options: ['Automático', 'De esquerda à direita', 'De direita à esquerda']
    },
    renotify: {
      title: 'Notificar novamente',
      info: 'Informar o utilizador depois da nova notificação substituir antiga'
    },
    requireInteraction: {
      title: 'Esperar o clique',
      info: 'Notificação deve se manter ativa até utilizador clicar na mesma ou apaga-a, em vez de apagar-se automaticamente'
    },
    silent: {
      title: 'Modo silencioso',
      info: 'Indica, a notificação deve ser silenciosa, ou seja, sem som ou vibração, indecentemente das configurações do dispositivo'
    },
    segmentation: {
      title: 'Segmentação de PUSH',
      message: 'Caso não preencher nada a subscrição será à base de todos os seus dados',
      total: 'Total',
      showedCount: 'Visualizações',
      clickedCount: 'Cliques',
      cappedCount: 'Encerramentos',
      registeredCount: 'Registos',
      depositedCount: 'Depósitos',
      pwas: {
        placeholder: 'Escolher uma ou várias PWA'
      },
      domains: {
        title: 'Domínios',
        placeholder: 'Selecionar um ou mais domínios'
      },
      tags: {
        title: 'Tags de PWA',
        placeholder: 'Escolher um ou vários tags de PWA'
      },
      countries: {
        title: 'Países',
        placeholder: 'Escolher um ou vários países'
      },
      registered: {
        title: 'Registrar-se',
        info: 'Enviar aos utilizadores que fizeram registo na aplicação oi que não fizeram o registo',
        options: ['Não se aplica', 'Registados', 'Não registados']
      },
      deposited: {
        title: 'Depósito',
        info: 'Enviar aos utilizadores que fizeram um depósito na aplicação ou que não fizeram',
        options: ['Não se aplica', 'Fizeram o depósito', 'Não fizeram']
      },
      showed: {
        title: 'Visualizações',
        info: 'Enviar aos utilizadores que já tinham recebido notificações ou que não receberam',
        options: ['Não se aplica', 'Receberam', 'Não receberam']
      },
      capped: {
        title: 'Encerramentos',
        info: 'Enviar aos utilizadores que fecharam notificações ou que não fecharam',
        options: ['Não se aplica', 'Encerraram', 'Não encerraram']
      },
      clicked: {
        title: 'Cliques',
        info: 'Enviar aos utilizadores que clicaram em notificações ou que não clicaram',
        options: ['Não se aplica', 'Clicaram', 'Não clicaram']
      }
    },
    timezone: {
      label: 'Fuso horário',
      info: 'Corretor de fuso horário'
    },
    country: {
      label: 'País',
      info: 'Selecionar um país para filtrar os fusos horários',
      error: 'Não foi possível encontrar o fuso horário, seleccione-o você mesmo'
    }
  },
  heads: {
    count: {
      label: 'Classificação de envios em massa',
      title: 'Número geral de todos os envios em massa'
    },
    name: {
      label: 'Nome',
      title: 'Nome de sistema de envios em massa'
    },
    tags: {
      label: 'Tags',
      title: 'Tags para filtração de envios em massa'
    },
    status: {
      label: 'Estatuto',
      title: 'Estatuto de envios em massa'
    },
    type: {
      label: 'Tipo',
      title: 'Tipo de envios em massa'
    },
    publishedAt: {
      label: 'Início',
      title: 'Data de início de envios em massa'
    },
    interval: {
      label: 'Seguinte',
      title: 'Seguinte lançamento de envios em massa'
    },
    total: {
      label: 'Total',
      title: 'Total'
    },
    remaining: {
      label: 'Falta',
      title: 'Falta'
    },
    delivered: {
      label: 'Entregue',
      title: 'Entregue'
    },
    showed: {
      label: 'Visualizações',
      title: 'Visualizações'
    },
    clicked: {
      label: 'Cliques',
      title: 'Cliques'
    },
    ctr: {
      label: 'CTR',
      title: 'Relação entre cliques/entregue. Calcula-se por fórmula CTR = cliques/entregue*100/доставлено*100'
    },
    unsubscribed: {
      label: 'Anularam subscrição',
      title: 'Anularam subscrição'
    },
    registered: {
      label: 'Registos',
      title: 'Registaram-se'
    },
    deposited: {
      label: 'Depósitos',
      title: 'Fizeram depósito'
    },
    countries: {
      label: 'Países',
      title: 'Países onde feito o envio em massa',
      all: 'Todos'
    }
  },
  statuses: {
    suspended: 'Suspenso',
    launched: 'Lançado',
    postponed: 'Suspenso',
    sending: 'Envio',
    done: 'Concluído'
  },
  copy: {
    title: 'Cópia de PUSH envios em massa',
    message: 'Mesmo quer copiar o envio em massa {name}',
    button: 'Copiar',
    success: 'PUSH subscrição foi copiada'
  },
  delete: {
    title: 'Apagar PUSH envios em massa',
    message: 'Mesmo quer apagar o envio em massa {name}',
    button: 'Apagar',
    success: 'PUSH subscrição foi apagada'
  },
  template: {
    title: 'Modelos PUSH envios em massa',
    source: 'Modelo',
    button: 'Modelo de envios em massa'
  },
  templates: {
    title: 'Modelos',
    add: 'Adicionar modelo',
    success: 'Modelo de envios em massa foi guardada com sucesso',
    filters: {
      name: 'Busca por nome',
      title: 'Busca por cabeçalho',
      body: 'Busca por texto'
    },
    form: {
      formTitle: 'Forma de configuração do modelo de envios em massa',
      message: 'Configuração de envios em massa e conteúdo para enviar',
      tags: {
        title: 'Tags para envios em massa',
        info: 'Conjunto de tags para filtração de envios em massa'
      },
      name: {
        title: 'Nome',
        info: 'Nome de sistema de modelo',
        placeholder: 'Introduza o nome de modelo'
      },
      title: {
        title: 'Cabeçalho',
        info: 'Cabeçalho da PUSH mensagem. Comprimento recomendado é de até 37 símbolos. Suporta spintax',
        placeholder: 'Introduza o cabeçalho'
      },
      body: {
        title: 'Texto',
        info: 'Texto de PUSH mensagem. Comprimento recomendado é de até 50 símbolos. Suporta spintax',
        placeholder: 'Introduza o texto'
      },
      url: {
        title: 'Link',
        info: 'Link a abrir quando clicar. Caso não indicar automaticamente será aberto URL instalado no PWA',
        placeholder: 'Introduza o link'
      },
      badge: {
        title: 'Sinal',
        info: 'Imagem, usada para apresentar notificações, quando falta espaço para a própria notificação. Adaptamos a imagem para a dimensão recomendada (96 x 96)',
        placeholder: 'Carregar o sinal'
      },
      icon: {
        title: 'Ícone',
        info: 'Imagem usada como ícone de notificações. Adaptamos a imagem para a dimensão recomendada (192 x 192)',
        placeholder: 'Carregar o ícone'
      },
      image: {
        title: 'Imagem',
        info: 'Imagem que visualizar-se-á como uma parte de notificações. Adaptamos a imagem para a dimensão recomendada (492 x 225)',
        placeholder: 'Carregar a imagem'
      },
      dir: {
        title: 'Direção do texto',
        info: 'Indica direção do texto de PUSH notificações',
        options: ['Automático', 'De esquerda à direita', 'De direita à esquerda']
      },
      renotify: {
        title: 'Notificar novamente',
        info: 'Informar o utilizador depois da nova notificação substituir antiga'
      },
      requireInteraction: {
        title: 'Esperar o clique',
        info: 'Notificação deve se manter ativa até utilizador clicar na mesma ou apaga-a, em vez de apagar-se automaticamente'
      },
      silent: {
        title: 'Modo silencioso',
        info: 'Indica, a notificação deve ser silenciosa, ou seja, sem som ou vibração, indecentemente das configurações do dispositivo'
      }
    },
    heads: {
      name: {
        label: 'Nome',
        title: 'Nome de sistema de modelo'
      },
      tags: {
        label: 'Tags',
        title: 'Tags para filtração de modelos'
      },
      title: {
        label: 'Cabeçalho',
        title: 'Cabeçalho do modelo'
      },
      body: {
        label: 'Texto',
        title: 'Texto do modelo'
      },
      createdAt: {
        label: 'Criado',
        title: 'Data de criação do modelo'
      }
    },
    delete: {
      title: 'Apagar o modelo de PUSH envios em massa',
      message: 'Mesmo quer apagar o modelo {name}',
      button: 'Apagar',
      success: 'Modelo de envios em massa foi apagado'
    },
    template: {
      title: 'PUSH envios em massa',
      source: 'Subscrição',
      button: 'De envios em massa'
    }
  },
  intervals: {
    title: 'Subscrição temporária',
    intervalError: 'Escolher pelo menos um dia',
    chooseCountry: 'Por favor, escolha um país!',
    status: 'Estatuto',
    heads: {
      name: {
        label: 'Nome',
        title: 'Nome de sistema de envios em massa (Não á envios em massa)'
      }
    }
  },
  statistics: {
    count: {
      title: 'Número de PUSH',
      info: 'Número de PUSH subscrições ativas recolhidas durante todo o tempo'
    },
    registered: {
      title: 'Número de registos',
      info: 'Número de únicos que se registaram tendo uma subscrição de PUSH'
    },
    deposited: {
      title: 'Número de depósitos',
      info: 'Número de únicos que realizaram um depósito tendo uma subscrição de PUSH'
    }
  },
  actions: {
    title: 'Por ação',
    interval: {
      title: 'Dentro de algum tempo',
      type: {
        minutes: 'Minutos | Minuto | Minutos | Minutos',
        hours: 'Horas | Hora | Horas | Horas',
        days: 'Dias | Dia | Dias | Dias',
        months: 'Meses | Mês | Mês | Meses'
      }
    },
    heads: {
      after: {
        label: 'Depois de Movimentos',
        title: 'Subscrição ativar-se-á depois de confirmar o Movimento definido'
      }
    },
    after: {
      minutes: 'Dentro de {count} minutos | Dentro de minutos | Dentro de {count} minutos | Dentro de {count} minutos',
      hours: 'Dentro de {count} horas | Dentro de hora | Dentro de {count} hora | Dentro de {count} horas',
      days: 'Dentro de {count} dias | Dentro de dia | Dentro de {count} dia | Dentro de {count} dias',
      months: 'Dentro de {count} meses | Dentro de mês | Dentro de {count} mês | Dentro de {count} meses'
    }
  },
  group: {
    successRemove: 'PUSH subscrição foi apagada',
    successCopy: 'PUSH subscrição foi copiada'
  },
  table: {
    publishedAt: 'Hora de envio do servidor em UTC 0 {time}'
  }
}
