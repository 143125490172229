<template>
  <div class="modal fade modal_center_g modal_teamwork" id="modal_add_user">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal_padd_normal style_form_lab">
        <button type="button" data-dismiss="modal" class="close_sjgs_to d_flex align_center"><span
          aria-hidden="true" @click="handlerCancel">×</span>
        </button>
        <div class="title_mod_centr_g">{{ $t('teams.add') }}</div>
        <div>
          <form @submit.prevent="handle">
            <div class="mb-4">
              <div class="row">
                <div class="col-6" :class="apiValidationErrors.username ? 'has-danger' : ''">
                  <input type="text" class="form-control" id="username" name="username"
                         :placeholder="$t('teams.name')"
                         v-model="username" @focus="clearError('username')"
                         :class="apiValidationErrors.username ? 'form-control-danger' : ''">
                  <label v-if="apiValidationErrors.username" class="error mt-2 info_warning_gdg" for="username">
                    {{ apiValidationErrors.username[0] }}
                  </label>
                </div>
                <div class="col-6 select-2-component">
                  <BaseSelect
                    taggable
                    taggable-text=""
                    v-model="group"
                    id="tags"
                    :placeholder="$t('teams.groups')"
                    :options="groups"
                    :create-option="option => option"
                    @input="clearError('group')"
                  />
                  <label v-if="apiValidationErrors.group" class="error mt-2 info_warning_gdg" for="group">
                    {{ apiValidationErrors.group[0] }}
                  </label>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="title_this_sel_g mb-4">{{ $t('teams.privacySettings') }}</div>
              <div class="row">
                <div class="col-6 col-sm-4">
                  <BaseSwitch
                    :label="$t('teams.isPWAsOwner')"
                    v-model="isPWAsOwner"
                  />
                  <div v-if="isPWAsOwner" class="mb-3">
                    <div class="mt-3" :class="apiValidationErrors.customPWATag ? 'has-danger' : ''">
                      <input type="text" class="form-control" id="customPWATag" name="customPWATag"
                             :placeholder="$t('teams.customPWATag')"
                             v-model="customPWATag" @focus="clearError('customPWATag')"
                             :class="apiValidationErrors.customPWATag ? 'form-control-danger' : ''">
                      <label v-if="apiValidationErrors.customPWATag" class="error mt-2 info_warning_gdg" for="customPWATag">
                        {{ apiValidationErrors.customPWATag[0] }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-4">
                  <BaseSwitch
                    :label="$t('teams.isCompaniesOwner')"
                    v-model="isCompaniesOwner"
                  />
                  <div v-if="isCompaniesOwner" class="mb-3">
                    <div class="mt-3" :class="apiValidationErrors.customCompaniesTag ? 'has-danger' : ''">
                      <input type="text" class="form-control" id="customCompaniesTag" name="customCompaniesTag"
                             :placeholder="$t('teams.customCompaniesTag')"
                             v-model="customCompaniesTag" @focus="clearError('customCompaniesTag')"
                             :class="apiValidationErrors.customCompaniesTag ? 'form-control-danger' : ''">
                      <label v-if="apiValidationErrors.customCompaniesTag" class="error mt-2 info_warning_gdg" for="customCompaniesTag">
                        {{ apiValidationErrors.customCompaniesTag[0] }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 mt-4 mt-sm-0">
                  <BaseSwitch
                    :label="$t('teams.isLandingsOwner')"
                    v-model="isLandingsOwner"
                  />
                  <div v-if="isLandingsOwner" class="mb-3">
                    <div class="mt-3" :class="apiValidationErrors.customLandingsTag ? 'has-danger' : ''">
                      <input type="text" class="form-control" id="customLandingsTag" name="customLandingsTag"
                             :placeholder="$t('teams.customLandingsTag')"
                             v-model="customLandingsTag" @focus="clearError('customLandingsTag')"
                             :class="apiValidationErrors.customLandingsTag ? 'form-control-danger' : ''">
                      <label v-if="apiValidationErrors.customLandingsTag" class="error mt-2 info_warning_gdg" for="customLandingsTag">
                        {{ apiValidationErrors.customLandingsTag[0] }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="title_this_sel_g" :class="apiValidationErrors.access ? 'mb-1' : 'mb-5'">{{ $t('teams.accessSettings') }}</div>
              <label v-if="apiValidationErrors.access" class="error mt-1 mb-3 info_warning_gdg" for="access">
                {{ apiValidationErrors.access[0] }}
              </label>
              <div class="row">
                <div class="col-6 col-sm-4">
                  <BaseSwitch
                    class="checkbox_ios_style"
                    label="PWA"
                    v-model="accessPwas"
                    @change="clearError('access')"
                  />
                  <div class="blk_more_optionss">
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.pwas.view" @click="clearError('access')" @change="checkView('pwas', 'view')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.view') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.pwas.update" @click="clearError('access')" @change="checkView('pwas', 'update')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.edit') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.pwas.delete" @click="clearError('access')" @change="checkView('pwas', 'delete')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.delete') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-4">
                  <BaseSwitch
                    class="checkbox_ios_style"
                    label="PUSH"
                    v-model="accessPushes"
                    @change="clearError('access')"
                  />
                  <div class="blk_more_optionss">
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.pushes.view" @click="clearError('access')" @change="checkView('pushes', 'view')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.view') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.pushes.update" @click="clearError('access')" @change="checkView('pushes', 'update')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.edit') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.pushes.delete" @click="clearError('access')" @change="checkView('pushes', 'delete')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.delete') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-4">
                  <BaseSwitch
                    class="checkbox_ios_style"
                    :label="$t('teams.statistics')"
                    v-model="accessStatistics"
                    @change="clearError('access')"
                  />
                  <div class="blk_more_optionss">
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.statistics.view" @click="clearError('access')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.view') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <div class="row">
                <div class="col-6 col-sm-4">
                  <BaseSwitch
                    class="checkbox_ios_style"
                    :label="$t('teams.teams')"
                    v-model="accessTeams"
                    @change="clearError('access')"
                  />
                  <div class="blk_more_optionss">
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.teams.view" @click="clearError('access')" @change="checkView('teams', 'view')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.view') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.teams.update" @click="clearError('access')" @change="checkView('teams', 'update')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.edit') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.teams.delete" @click="clearError('access')" @change="checkView('teams', 'delete')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.delete') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-4">
                  <BaseSwitch
                    class="checkbox_ios_style"
                    :label="$t('teams.finance')"
                    v-model="accessFinance"
                    @change="clearError('access')"
                  />
                  <div class="blk_more_optionss">
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.finance.view" @click="clearError('access')" @change="checkView('finance', 'view')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.view') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.finance.update" @click="clearError('access')" @change="checkView('finance', 'update')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.edit') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-4">
                  <BaseSwitch
                    class="checkbox_ios_style"
                    :label="$t('teams.landings')"
                    v-model="accessLandings"
                    @change="clearError('access')"
                  />
                  <div class="blk_more_optionss">
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.landings.view" @click="clearError('access')" @change="checkView('landings', 'view')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.view') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.landings.update" @click="clearError('access')" @change="checkView('landings', 'update')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.edit') }}</span>
                      </label>
                    </div>
                    <div class="item_inline_checkbxx">
                      <label class="inline_checkbox_fff">
                        <input type="checkbox" v-model="access.landings.delete" @click="clearError('access')" @change="checkView('landings', 'delete')">
                        <span class="icon_checkb_inl"></span>
                        <span>{{ $t('teams.delete') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="logir_ff_form d_flex justify_center">
              <button class="ful_butt_green d_flex align_center justify_center" type="submit">
                {{ $t('general.save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from '@/mixins/form-mixin'
import BaseSwitch from '@/components/base/BaseSwitch.vue'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

const defaultValues = () => ({
  id: false,
  allUpdate: true,
  username: '',
  group: '',
  isPWAsOwner: false,
  customPWATag: '',
  isCompaniesOwner: false,
  customCompaniesTag: '',
  isLandingsOwner: false,
  customLandingsTag: '',
  accessPwas: false,
  accessPushes: false,
  accessStatistics: false,
  accessTeams: false,
  accessFinance: false,
  accessLandings: false,
  access: defaultAccess
})

const defaultAccess = {
  pwas: {
    view: false,
    update: false,
    delete: false
  },
  statistics: {
    view: false
  },
  teams: {
    view: false,
    update: false,
    delete: false
  },
  finance: {
    view: false,
    update: false
  },
  pushes: {
    view: false,
    update: false,
    delete: false
  },
  landings: {
    view: false,
    update: false,
    delete: false
  }
}

export default {
  name: 'Form',
  props: ['groups'],
  components: { BaseSelect, BaseSwitch },
  mixins: [formMixin],
  data: () => ({
    ...defaultValues()
  }),
  methods: {
    skip () {
      this.resetApiValidation()
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    handlerCancel () {
      this.skip()
    },
    checkView (accessTo, field) {
      if (field === 'view' && !this.access[accessTo][field]) {
        // if 'view' is false than set another fields to false as well
        for (let key of Object.keys(this.access[accessTo])) {
          this.access[accessTo][key] = false
        }
      } else if (this.access[accessTo][field]) {
        // if 'update' or 'delete' is true than set 'view' to true as well
        this.access[accessTo].view = true
      }
    },
    show: function (payload = null) {
      const self = this
      if (payload != null) {
        self.username = payload.username
        self.group = payload.group
        self.isPWAsOwner = payload.isPWAsOwner
        self.customPWATag = payload.customPWATag
        self.isCompaniesOwner = payload.isCompaniesOwner
        self.customCompaniesTag = payload.customCompaniesTag
        self.isLandingsOwner = payload.isLandingsOwner
        self.customLandingsTag = payload.customLandingsTag
        self.access = JSON.parse(JSON.stringify(payload.access))
        self.id = payload._id
      } else {
        this.skip()
      }
      for (const access of Object.keys(defaultAccess)) {
        const accessFilter = 'access' + access.charAt(0).toUpperCase() + access.slice(1)
        self[accessFilter] = this.isCheckedAll(access)
        this.$watch(accessFilter, function (n) {
          this.updateAllCheck(access, n)
        })
        for (const k of Object.keys(self.access[access])) {
          this.$watch('access.' + access + '.' + k, function () {
            self.allUpdate = false
            self[accessFilter] = this.isCheckedAll(access)
            this.$nextTick(() => {
              self.allUpdate = true
            })
          })
        }
      }
      // eslint-disable-next-line no-undef
      jQuery('#modal_add_user').modal('show').on('hidden.bs.modal', function () {
        self.destroySelect2()
      })
    },
    hide: function () {
      // eslint-disable-next-line no-undef
      jQuery('#modal_add_user').modal('hide')
    },
    handle: async function () {
      try {
        this.resetApiValidation()
        let isOneAccess = false
        for (const permission of Object.keys(this.access)) {
          if (Object.values(this.access[permission]).includes(true)) { isOneAccess = true }
        }
        if (!isOneAccess) {
          this.setApiValidation([{param: 'access', msg: this.$t('teams.validator.access.isOneAccess')}])
          return
        }
        const payload = {
          username: this.username,
          group: this.group,
          isPWAsOwner: this.isPWAsOwner,
          customPWATag: this.customPWATag,
          isCompaniesOwner: this.isCompaniesOwner,
          isLandingsOwner: this.isLandingsOwner,
          customLandingsTag: this.customLandingsTag,
          customCompaniesTag: this.customCompaniesTag,
          access: this.access
        }
        if (this.id) {
          await this.$store.dispatch('teams/update', {...payload, id: this.id})
        } else {
          await this.$store.dispatch('teams/create', {...payload})
        }
        this.$store.dispatch('alerts/success', this.$t('teams.employeeSaved'))
        this.hide()
        await this.$parent.asyncData()
        if (!this.id) {
          this.$parent.$refs.modalAuthData.show(this.$store.getters['teams/member'])
        }
        this.skip()
      } catch (error) {
        this.setApiValidation(error.response.data.errors)
      }
    },
    isCheckedAll: function (prop) {
      let test = true
      for (const value of Object.values(this.access[prop]).sort().reverse()) {
        if (value === false) {
          test = false
        }
      }
      return test
    },
    updateAllCheck: function (prop, value) {
      if (this.allUpdate) {
        for (const access of Object.keys(this.access[prop])) {
          this.access[prop][access] = value
        }
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.select-2-component
  position: relative
  :deep(.select2)
    width: 100% !important
  .hidden-select
    width: 0px
    height: 0px
    padding: 0
    border: none
</style>
