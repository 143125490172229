<template>
  <modal
    id="admin-users-settings-form-modal"
    ref="modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Налаштування користувачів</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div v-if="!loading" class="modal-body">
      <div class="form-group" :class="apiValidationErrors.royalties ? 'has-danger' : ''">
        <label for="royalties">Реферальная программа</label>
        <div class="input-group-append">
          <input type="number" step="0" id="royalties" placeholder="Введите процент" v-model="royalties"
                 class="form-control" min="1" max="100"
                 :class="apiValidationErrors.royalties ? 'form-control-danger' : ''"
                 @focus="clearError('royalties')">
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <small class="form-text text-muted">Вознаграждения от пополнения баланса рефералом</small>
        <label v-if="apiValidationErrors.royalties" class="error mt-2 text-danger" for="royalties">
          {{ apiValidationErrors.royalties[0] }}
        </label>
      </div>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import _ from 'lodash'
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import {mapActions, mapGetters} from 'vuex'

const defaultValues = () => ({
  royalties: 0
})

export default {
  name: 'AdminPWAsSettingsForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      loading: true,
      processing: false,
      ...defaultValues()
    }
  },
  computed: {
    ...mapGetters({
      billing: 'adminConfig/billing'
    })
  },
  methods: {
    ...mapActions({
      fetchData: 'adminConfig/fetchData',
      update: 'adminConfig/update',
      alertSuccess: 'alerts/success'
    }),
    debounceFetchData: _.debounce(async function () {
      await this.fetchData()
      this.royalties = this.billing.royalties
      this.loading = false
    }, 200),
    fetchPage () {
      this.loading = true
      this.debounceFetchData()
    },
    handlerOpen () {
      this.fetchPage()
      this.skip()
      this.$refs.modal.open()
    },
    handlerCancel () {
      this.$refs.modal.close()
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.update({
          billing: {
            royalties: this.royalties
          }
        })
        this.resetApiValidation()
        this.processing = false
        this.$refs.modal.close()
        await this.alertSuccess('Налаштування користувачів збережено')
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
