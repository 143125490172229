<template>
  <td>
    <div class="pwa_user">
      <router-link class="d-flex align-items-center" :to="{ name: 'PWAsUpdate', params: { id: value?.id } }"
        data-toggle="tooltip" :title="value?.name">
        <img v-if="logo" :src="logo" alt="image" class="mr-2">
        <InlineSvg v-else class="mr-2" src="/images/svg-icons/icon-info-banner.svg" />
        {{ !!value.name ? value?.name : defaultName }}
      </router-link>
    </div>
  </td>
</template>
<script>
import pwasMixin from '@/mixins/pwas-mixin'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'PwaColumn',
  mixins: [pwasMixin],
  components: {InlineSvg},
  props: ['value'],
  computed: {
    logo () {
      if (this.value?.images && this.value?.vertical && this.value?.gallery) {
        return this.getPWALogo(this.value?.images, this.value?.vertical, this.value?.gallery)
      } else {
        return null
      }
    },
    defaultName () {
      return this.$t('analytics.pwaRemoved')
    }
  }
}
</script>

<style lang="scss" scoped>
.pwa_user {
  svg {
    width: 19px;
    height: 19px;
  }
}
</style>
