<template>
  <modal
    ref="modal"
    id="domain-form-modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <select2
        v-model="ip"
        :options="ipsOptions"
        class="form-group"
        id="ip"
        name="ip"
        inputClass="form-control"
      >
        <template v-slot:before>
          <label for="ip">IP</label>
        </template>
      </select2>
      <div class="form-group" :class="apiValidationErrors['cloudflare'] ? 'has-danger' : ''">
        <label for="cloudflare">Сloudflare аккаунт</label>
        <select class="form-control" id="cloudflare" name="cloudflare" type="text" tabindex="1"
                v-model="cloudflare"
                :class="apiValidationErrors['cloudflare'] ? 'form-control-danger' : ''"
                :disabled="processing"
                @focus="clearError('cloudflare')">
          <option v-bind:value="model._id" v-for="model in cloudflares"
                  v-bind:key="model._id">{{ model._id }}
          </option>
        </select>
        <label v-if="apiValidationErrors['cloudflare']" class="error mt-2 text-danger"
               for="cloudflare">{{ apiValidationErrors['cloudflare'][0] }}</label>
      </div>
      <div v-if="status > 1" class="form-group" :class="apiValidationErrors['status'] ? 'has-danger' : ''">
        <label for="status">Статус</label>
        <select class="form-control" id="status" name="status" type="text" tabindex="1"
                v-model="status"
                :disabled="processing"
                :class="apiValidationErrors['status'] ? 'form-control-danger' : ''"
                @focus="clearError('status')">
          <option v-bind:value="id" v-for="(label, id) in this.$store.getters['adminDomains/statuses']"
                  v-bind:key="id">{{ label }}
          </option>
        </select>
        <label v-if="apiValidationErrors['status']" class="error mt-2 text-danger"
               for="status">{{ apiValidationErrors['status'][0] }}</label>
      </div>
      <select2
        ref="select2"
        class="form-group"
        inputClass="form-control select_white_g"
        id="owner"
        name="owner"
        v-model="owner"
        placeholder="Пользователь"
        :options="ownerOptions"
        :settings="ownerSettings"
      >
        <template v-slot:before>
          <div class="sibmti_sdf">Пользователь</div>
        </template>
        <template v-slot:after>
          <label v-if="apiValidationErrors.owner" class="error mt-2 info_warning_gdg" for="owner">
            {{ apiValidationErrors.owner[0] }}
          </label>
        </template>
      </select2>
      <div v-if="isCreate" class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" id="isBulkCreate" name="isBulkCreate"
                   v-model="isBulkCreate">
            Списком
            <i class="input-helper"></i>
          </label>
        </div>
      </div>
      <div v-if="!isBulkCreate && isCreate" class="form-group" :class="apiValidationErrors['_id'] ? 'has-danger' : ''">
        <label for="name">Домен</label>
        <input class="form-control" id="name" name="name" type="text" tabindex="1"
               v-model="name"
               :class="apiValidationErrors['_id'] ? 'form-control-danger' : ''"
               placeholder="Введите названия домена"
               @focus="clearError('_id')">
        <label v-if="apiValidationErrors['_id']" class="error mt-2 text-danger"
               for="name">{{ apiValidationErrors['_id'][0] }}</label>
      </div>
      <div v-if="isBulkCreate && isCreate" class="form-group">
        <label for="domains">Домены</label>
        <textarea
          v-model="domains"
          :disabled="processing"
          class="form-control" id="domains" name="domains" type="text" tabindex="1"
          placeholder="Введите домены, каждый с новой строки"
          rows="7">
        </textarea>
      </div>
      <div v-if="!isCreate" class="form-group">
        <label for="name">NS домена</label>
        <textarea class="form-control" id="NS" name="NS" type="text" tabindex="1"
                  v-model="NS" rows="2" readonly>
        </textarea>
      </div>
      <div v-if="!isCreate" class="form-group" :class="apiValidationErrors['subs'] ? 'has-danger' : ''">
        <label for="name">Сабаккаунты домена</label>
        <textarea class="form-control" id="subs" name="subs" type="text" tabindex="1"
                  v-model="subs" rows="5"
                  :class="apiValidationErrors['subs'] ? 'form-control-danger' : ''"
                  placeholder="Введите subs для домена с новой строки"
                  @focus="clearError('subs')" :readonly="!isCreate">
        </textarea>
        <label v-if="apiValidationErrors['subs']" class="error mt-2 text-danger"
               for="name">{{ apiValidationErrors['subs'][0] }}</label>
      </div>
      <template v-if="processing">
        <div class="d-flex justify-content-between">
          <small>Добавлено доменов</small>
          <small>{{ row }}/{{ total }}</small>
        </div>
        <div class="progress progress-lg mt-2">
          <div class="progress-bar bg-success"
               role="progressbar"
               :style="{width: percent + '%'}"
               :aria-valuenow="percent"
               aria-valuemin="0" aria-valuemax="100">
            {{ percent }}%
          </div>
        </div>
      </template>
    </div>
    <submit-modal-form
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import {OwnerFilter} from '@/pages/admin/components'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import {mapActions, mapGetters} from 'vuex'

const defaultValues = () => ({
  isBulkCreate: false,
  domainsArray: [],
  domains: '',
  name: '',
  status: 0,
  subs: '',
  ip: '185.163.45.172',
  owner: null,
  register: null,
  cloudflare: null,
  NS: [],
  row: 1,
  percent: 0,
  added: 0
})

export default {
  name: 'AdminDomainForm',
  mixins: [formMixin, select2AjaxMixin],
  components: {SubmitModalForm, Modal, OwnerFilter, Select2},
  data () {
    return {
      processing: false,
      isCreate: true,
      ...defaultValues(),
      ownerSettings: {},
      ownerOptions: [],
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    ...mapGetters({
      ipsOptions: 'ips/options'
    }),
    title () {
      return this.isCreate ? 'Добавление домена' : `Редактирование ${this.name}`
    },
    cloudflares () {
      return this.$store.getters['adminDomains/cloudflares']
    },
    total () {
      return this.domainsArray.length
    }
  },
  async created () {
    this.ownerSettings = {...this.getSelect2AjaxSettings('users/filter'), allowClear: true}
  },
  methods: {
    ...mapActions({
      create: 'adminDomains/create',
      update: 'adminDomains/update'
    }),
    async handlerCreate () {
      if (!this.cloudflares.length) return alert('Сначала добавьте хотя бы один аккаунт cloudflare')
      this.skip()
      this.isCreate = true
      this.cloudflare = this.cloudflares[0]._id
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async handlerUpdate ({_id, status, subs, ip, owner, register, cloudflare, NS}) {
      this.skip()
      this.isCreate = false
      this.name = _id
      this.status = status
      this.subs = subs.join('\n')
      this.ip = ip
      this.owner = owner != null ? owner._id : null
      this.register = register
      this.cloudflare = cloudflare != null ? cloudflare._id : null
      this.NS = NS.join('\n')
      this.$refs.modal.open()
      if (this.owner != null) {
        this.ownerOptions = await this.getSelect2AjaxOptions(this.owner, 'users/filter')
      }
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.resolvePromise(false)
      this.$refs.modal.close()
    },
    async handlerSave () {
      this.resetApiValidation()
      try {
        if (this.isCreate && this.isBulkCreate) {
          let wrongDomain = []
          const errorsPull = []
          this.domainsArray = this.domains.split('\n')
          this.domainsArray = this.domainsArray.filter(domain => domain.length > 0)
          this.percent = Math.ceil(100 / this.total * this.row)
          for (const domain of this.domainsArray) {
            try {
              this.checkTopDomain(domain)
              await this.create({
                _id: domain.trim(),
                cloudflare: this.cloudflare,
                owner: this.owner,
                ip: this.ip
              })
            } catch (error) {
              const errors = error.response.data.errors || []
              errors.filter(v => v.param === 'domain')
              errorsPull.push(`${errors[0].value} - ${errors[0].msg}`)
              wrongDomain.push(domain)
            }
            this.row++
            this.percent = Math.ceil(100 / this.total * this.row)
          }
          if (wrongDomain.length) {
            this.domains = wrongDomain.join('\n')

            this.added = this.total - wrongDomain.length
            const ok = confirm(`Добавлено ${this.added} из ${this.total} доменов.\nДомены которые ещё не добавлены остались в поле\nПоказать ошибки?`)
            if (ok) {
              alert(errorsPull.join('\n'))
            }
            this.processing = false
            this.row = 1
            this.percent = 0
          } else {
            this.resetApiValidation()
            this.$refs.modal.close()
            this.resolvePromise(true)
          }
        } else {
          this.checkTopDomain(this.name)
          const payload = {
            _id: this.name,
            status: this.status,
            ip: this.ip,
            owner: this.owner,
            register: this.register,
            cloudflare: this.cloudflare
          }
          if (this.isCreate) {
            await this.create(payload)
          } else {
            await this.update(payload)
          }
          this.resetApiValidation()
          this.processing = false
          this.resolvePromise(true)
          this.$refs.modal.close()
        }
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    async skip () {
      this.resetApiValidation()
      this.processing = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    },
    checkTopDomain (domain) {
      if (['cf', 'ga', 'gq', 'ml', 'tk'].includes(
        domain.substr(-2, 2)
      )) alert('You cannot use this API for domains with a .cf, .ga, .gq, .ml, or .tk TLD (top-level domain). To configure the DNS settings for this domain, use the Cloudflare Dashboard.')
    }
  }
}
</script>
