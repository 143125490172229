<template>
  <form class="forms-sample">
    <div class="item_filt_g row_filters grid_items_filter">
      <div v-if="user.isAdmin" class="form-group mb-0">
        <input type="text" class="form-control" id="id" tabindex="0" name="id" v-model="id"
               :placeholder="$t('templates.filters.id')" v-on:keydown.enter.prevent/>
      </div>
      <div class="form-group mb-0">
        <input type="text" class="form-control" id="name" tabindex="1" name="name" v-model="name"
               :placeholder="$t('templates.filters.name')" v-on:keydown.enter.prevent/>
      </div>
      <BaseSelect
        v-if="user.isAdmin"
        v-model="owner"
        id="owner"
        :placeholder="$t('templates.filters.owner')"
        endpoint="/users/filter"
        sort="-createdAt"
      />
      <div class="form-group mb-0">
        <input type="text" class="form-control" id="googlePlayURL" tabindex="3" name="googlePlayURL"
               v-model="googlePlayURL"
               :placeholder="$t('templates.filters.googlePlayURL')" v-on:keydown.enter.prevent/>
      </div>
      <BaseSelect
        v-model="pwaId"
        id="pwaId"
        :placeholder="$t('templates.filters.pwaId')"
        endpoint="/pwas/select"
        sort="-createdAt"
      />
    </div>
  </form>
</template>
<script>
import Select2 from '@/components/Select2.vue'
import select2AjaxMixin from '@/mixins/select2-ajax-mixin'
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue'

export default {
  name: 'PWATemplateFilter',
  mixins: [select2AjaxMixin],
  components: {BaseSelect, Select2},
  props: {
    value: {
      type: Object,
      default () {
        return {
          id: '',
          name: '',
          owner: '',
          googlePlayURL: '',
          pwaId: ''
        }
      }
    }
  },
  data () {
    return {
      pwaIdSettings: {},
      ownerSettings: {}
    }
  },
  created () {
    this.pwaIdSettings = {...this.getSelect2AjaxSettings('pwas/filter'), allowClear: true}
    this.ownerSettings = {...this.getSelect2AjaxSettings('users/filter'), allowClear: true}
  },
  computed: {
    id: {
      get () {
        return this.value.id
      },
      set (id) {
        this.$emit('input', {...this.value, id})
      }
    },
    name: {
      get () {
        return this.value.name
      },
      set (name) {
        this.$emit('input', {...this.value, name})
      }
    },
    owner: {
      get () {
        return this.value.owner
      },
      set (owner) {
        this.$emit('input', {...this.value, owner})
      }
    },
    googlePlayURL: {
      get () {
        return this.value.googlePlayURL
      },
      set (googlePlayURL) {
        this.$emit('input', {...this.value, googlePlayURL})
      }
    },
    pwaId: {
      get () {
        return this.value.pwaId
      },
      set (pwaId) {
        this.$emit('input', {...this.value, pwaId})
      }
    },
    user () {
      return this.$store.getters['identity']
    }
  }
}
</script>
