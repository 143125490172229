export default {
  navigation: 'Aplicação',
  title: 'Aplicação PWA #{id}',
  message: 'Faça configuração de texto da página de PWA',
  main: {
    title: 'Principal',
    titleInfo: 'Configuração prioritária feita no início',
    name: 'Nome',
    nameInfo: 'Introduza o nome do seu PWA',
    author: 'Autor',
    authorInfo: 'Introduza o desenvolvedor do PWA',
    category: 'Categoria',
    categoryInfo: 'Escolher a categoria do seu PWA',
    categoryInscription: 'Categoria(inscrição)',
    categoryInscriptionInfo: 'Quando é necessário outro nome da categoria, por exemplo, para localização. Caso não preencher seja aplicado o nome da categoria',
    ad: 'adPublicidade',
    adInfo: 'Há publicidade no seu PWA',
    age: 'Idade',
    ageInfo: 'Para qual idade o seu PWA',
    ratingM: 'Classificação texto(móvel)',
    ratingMInfo: 'Palavra "Classificação" aparece na versão móvel',
    sizeM: 'Dimensão(móvel)',
    sizeMInfo: 'Dimensão da aplicação em MB que aparece na versão móvel',
    ageM: 'Idade texto(móvel)',
    ageMInfo: 'Palavra "Idade" na versão móvel',
    recommendationD: 'Recomendação(PC)',
    recommendationDInfo: 'Recomendação da loja. Aparece na versão de PC',
    wishlistD: 'Lista de desejos(PC)',
    wishlistDInfo: 'Frase "Adicionar aos favoritos". Aparece na versão de PC',
    commentLabel: 'Referências',
    commentLabelInfo: 'Tradução da palavra "referências"',
    canInstall: 'Acessibilidade da aplicação',
    canInstallInfo: 'Acessibilidade da aplicação'
  },
  install: {
    title: 'Botão de definições',
    titleInfo: 'Configuração de texto das inscrições no botão e com download',
    install: 'Instalar',
    installInfo: 'Texto da inscrição no botão',
    installing: 'Inicialização',
    installingInfo: 'Texto, que aparece depois de clicar no botão "Instalar"',
    download: 'Download',
    downloadInfo: 'Texto, que aparece depois de "Inicialização"',
    open: 'Abrir',
    openInfo: 'Texto, que aparece depois de "Download"'
  },
  description: {
    title: 'Descrição',
    titleInfo: 'Configuração da descrição do seu PWA',
    aboutM: 'Sobre aplicação(Móvel)',
    aboutMInfo: 'Frase "Sobre aplicação" na versão móvel',
    readMore: 'Mais detalhado',
    readMoreInfo: 'Texto de clique "Mais detalhado"',
    hide: 'Ocultar',
    hideInfo: 'Texto de clique "Ocultar". Aparece depois de clicar em texto "Mais detalhado"',
    description: 'Descrição',
    descriptionInfo: 'Descrição do seu PWA',
    descriptionPlaceholder: 'Introduza a descrição de PWA (para utilizadores)',
    update: 'Atualizações(PC)',
    updateInfo: 'Frase "Novidades". Aparece na versão de PC',
    updateDesc: 'Descrição atualizações(PC)',
    updateDescInfo: 'Descrição da última atualização',
    dataSecurity: 'Segurança de dados',
    dataSecurityInfo: 'Inscrição',
    dataSecurityText: 'Descrição "Segurança de dados"',
    dataSecurityTextInfo: 'Descrição "Segurança de dados"',
    dataSecurityLink: 'Texto sublinhado em "Segurança de dados"',
    dataSecurityLinkInfo: 'Texto sublinhado em "Segurança de dados"',
    noneInfo: 'Texto em "Segurança de dados"',
    noneInfoInfo: 'Texto em "Segurança de dados"'
  },
  rating: {
    title: 'Classificação',
    titleInfo: 'Configuração e ajuste da classificação no PWA',
    reviews: 'Referências',
    reviewsInfo: 'Texto "Avaliações e Referências"',
    ratingInfo: 'Classifica o seu PWA na escala de 5 pontos',
    countComments: 'Classificação de referências',
    countCommentsInfo: 'Número de avaliações do seu PWA',
    total: 'Total(PC)',
    totalInfo: 'Palavra "Total". Aparece na versão de PC',
    count: 'Número de avaliações {num}',
    reviewsVerified: 'Inscrição "Avaliações e referências estão verificadas"',
    reviewsVerifiedInfo: 'Inscrição "Avaliações e referências estão verificadas"',
    reviewHelp: ' Inscrição "pessoas marcaram esta referência como útil."',
    reviewHelpInfo: ' Inscrição "pessoas marcaram esta referência como útil."',
    reviewHelpTitle: 'Inscrição "Esta informação foi útil?"',
    reviewHelpTitleInfo: 'Inscrição "Esta informação foi útil?"',
    reviewHelpYes: 'Resposta «Sim»',
    reviewHelpYesInfo: 'Resposta «Sim» na pergunta anterior',
    reviewHelpNo: 'Resposta «Não»',
    reviewHelpNoInfo: 'Resposta «Não» na pergunta anterior',
    reviewAll: 'Inscrição "Todas as referências"',
    reviewAllInfo: 'Texto do botão de visualização de todas as referências'
  },
  additionalInfo: {
    title: 'Informação complementar',
    titleInfo: 'Informação complementar, que aparece na parte inferior da página de PWA',
    addInfo: 'Inscrição "Informação complementar"',
    author: 'Desenvolvedor',
    authorInfo: 'Inscrição "Desenvolvedor"',
    updated: 'Atualizado',
    updatedInfo: 'Inscrição "Atualizado"',
    updatedDate: 'Data de atualizações',
    updatedDateInfo: 'Introduza a data de atualizações',
    size: 'Dimensão',
    sizeInfo: 'Inscrição "Dimensão"',
    installs: 'Instalações',
    installsInfo: 'Inscrição "Instalações"',
    countInstall: 'Número de instalações',
    countInstallInfo: 'Introduza o número de instalações',
    currentVersion: 'Versão corrente',
    currentVersionLabelInfo: 'Inscrição "Versão corrente"',
    currentVersionInfo: 'Introduza a versão corrente',
    complain: 'Inscrição "Reclamar"',
    complainInfo: 'Inscrição "Reclamar"',
    developerContact: 'Inscrição "Contactar o desenvolvedor"',
    developerContactInfo: 'Inscrição "Contactar o desenvolvedor"',
    developerSite: 'Inscrição "Site"',
    developerSiteInfo: 'Inscrição "Site"',
    developerUrl: 'Site',
    developerUrlInfo: 'Link do site da empresa',
    developerEmailLabel: 'Inscrição "Correio eletrónico"',
    developerEmailLabelInfo: 'Inscrição "Correio eletrónico"',
    developerEmail: 'Correio eletrónico',
    developerEmailInfo: 'Correio eletrónico da empresa',
    developerAddressLabel: 'Inscrição "Endereço"',
    developerAddressLabelInfo: 'Inscrição "Endereço"',
    developerAddress: 'Endereço da empresa',
    developerAddressInfo: 'Endereço da empresa',
    politicsPrivacy: 'Inscrição "Política de confidencialidade"',
    politicsPrivacyInfo: 'Inscrição "Política de confidencialidade"',
    politicsPrivacyUrl: 'Link da política',
    politicsPrivacyUrlInfo: 'Link da política'
  },
  ttPreland: {
    title: 'TikTok pré-landing',
    titleInfo: 'Inscrições de pré-landing para TikTok',
    template: 'Modelo',
    templateInfo: 'Escolher o modelo para pré-landing',
    variant: 'Opção {num}',
    ttHeader: 'Quer ir a',
    ttHeaderInfo: 'Pergunta na página de "TikTok"',
    ttYes: 'Resposta «Sim» na página de "TikTok"',
    ttNo: 'Resposta «Não» na página de "TikTok"',
    ttForce: 'Pré-landing obrigatório para qualquer host',
    ttForceInfo: 'Caso não indicar o pré-landing aparece apenas para Andriod Chrome WebView host'
  },
  cookies: {
    title: 'Cookies',
    titleInfo: 'Uso de ficheiros cookies',
    cookiesText: 'Pedido de cookies',
    cookiesTextInfo: 'Texto "Concorda o uso de ficheiros cookies?"',
    cookiesAgree: 'Confirmado',
    cookiesAgreeInfo: 'Texto do botão de confirmação',
    cookiesDisagree: 'Indeferido',
    cookiesDisagreeInfo: 'Texto do botão de recusa'
  },
  translateDeleted: 'Tradução foi apagada'
}
