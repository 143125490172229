<template>
  <modal
    id="admin-billing-bill-form-modal"
    ref="modal"
    class="modal fade"
    classDialog="modal-dialog"
    classContent="modal-content"
  >
    <div class="modal-header">
      <h5 class="modal-title">Створення рахунка на #{{ owner }}</h5>
      <button v-if="!processing" type="button" class="close" @click="handlerCancel">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div v-if="result" class="form-group" >
        <div class="input-group">
          <div class="input-group-append w-100">
            <button type="button" class="btn btn-info btn-clipboard mr-2" data-clipboard-action="copy"
                    data-clipboard-target="#payUrl">Копировать
            </button>
            <input class="form-control h-100" id="payUrl" name="payUrl" type="text" tabindex="1"
                   :value="payUrl">
          </div>
        </div>
      </div>
      <div
        v-if="!result"
        :class="apiValidationErrors.amount ? 'has-danger' : ''"
        class="form-group">
        <label for="amount">Сумма рахунка</label>
        <div class="input-group-append">
          <input type="number" step="0" id="amount" placeholder="Введіть сумму" v-model="amount"
                 class="form-control" min="1" max="100000"
                 :class="apiValidationErrors.amount ? 'form-control-danger' : ''"
                 @focus="clearError('amount')">
          <div class="input-group-append">
            <span class="input-group-text">$</span>
          </div>
        </div>
        <small class="form-text text-muted">Сумма в $ яка буде зарахована на баланс</small>
        <label v-if="apiValidationErrors.amount" class="error mt-2 text-danger" for="method">
          {{ apiValidationErrors.amount[0] }}
        </label>
      </div>
      <div
        v-if="!result"
        :class="apiValidationErrors.method ? 'has-danger' : ''"
        class="form-group row">
        <label class="col-sm-12 col-form-label">Спосіб оплати</label>
        <div
          v-for="paymentMethod of paymentMethods"
          :key="paymentMethod._id"
          class="col-sm-4">
          <div class="form-check">
            <label class="form-check-label" :for="`method-${paymentMethod._id}`">
              <input
                v-model="method"
                :id="`method-${paymentMethod._id}`" :value="paymentMethod._id"
                type="radio"
                class="form-check-input"
                name="method">
              <i class="input-helper"></i>
              {{ paymentMethod.name }}
            </label>
          </div>
        </div>
        <small class="col-sm-12 form-text text-muted">Оберіть один із способів оплати</small>
        <label v-if="apiValidationErrors.method" class="col-sm-12 error mt-2 text-danger" for="method">
          {{ apiValidationErrors.method[0] }}
        </label>
      </div>
    </div>
    <submit-modal-form
      v-if="!result"
      v-model="processing"
      @save="handlerSave"
      @cancel="handlerCancel"
    />
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
import formMixin from '@/mixins/form-mixin'
import SubmitModalForm from '@/pages/admin/components/forms/SubmitModalForm.vue'
import {mapActions, mapGetters} from 'vuex'

const defaultValues = () => ({
  owner: '',
  amount: 0,
  method: ''
})

export default {
  name: 'AdminBillingBillForm',
  mixins: [formMixin],
  components: {Modal, SubmitModalForm},
  data () {
    return {
      result: false,
      processing: false,
      ...defaultValues(),
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    ...mapGetters({
      paymentMethods: 'adminBilling/paymentMethods',
      payUrl: 'billing/payUrl'
    })
  },
  methods: {
    ...mapActions({
      create: 'billing/create',
      alertSuccess: 'alerts/success'
    }),
    handlerOpen (owner) {
      this.skip()
      this.owner = owner
      this.method = this.paymentMethods[0]._id
      this.$refs.modal.open()
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    handlerCancel () {
      this.$refs.modal.close()
      this.resolvePromise(this.result)
    },
    async handlerSave () {
      try {
        this.resetApiValidation()
        await this.create({
          owner: this.owner,
          amount: this.amount,
          method: this.method
        })
        this.resetApiValidation()
        this.processing = false
        this.result = true
        await this.alertSuccess(`Рахунок на #${this.owner} успішно створено`)
      } catch (error) {
        this.processing = false
        this.setApiValidation(error.response.data.errors)
      }
    },
    skip () {
      this.resetApiValidation()
      this.processing = false
      this.result = false
      const values = Object.entries(defaultValues())
      for (const [prop, value] of values) {
        this[prop] = value
      }
    }
  }
}
</script>
